import _ from 'lodash';
import moment from 'moment';
import debug from 'debug';
import { getGuarBasedOnWorkLoc } from 'utils/wtcWeekUtils';
import { dtsTimecardValidate } from 'utils/timecardValidationUtils';

import { doesDayAllowTimes, calculateHoursDiff } from 'utils/weekUtils';
import { makeMiniDeal } from 'utils/helperFunctions';

import DisplayOnlyCell from 'feature/DTS/EditableCells/DisplayOnlyCell';

import TimeValidator from 'utils/TimeValidator';
import { ACCOUNT_FIELDS_MAP } from 'components/Shared/constants';

export const db = debug('dts');

export const DATE_FORMAT_LONG = 'YYYY-MM-DDT00:00:00';

//This is kept in sync with the redux store by a useEffect in DTS.js
let _dealMemos = [];

// THIS MODIFIES THE INCOMING DATA

// Handles adding new day to timecard by modifying input data
export const parseTableData = ({
  data,
  effectiveDate,
  tableColumns,
  setHiddenColumns,
}) => {
  const tableData = [];
  const timecards = data.timecards;
  const dtsDateString = effectiveDate.format(DATE_FORMAT_LONG);

  if (!timecards) return [];

  timecards.forEach((timecard, tcIndex) => {
    const {
      employee,
      dealMemo,
      userFriendlyTimecardStatus,
      departmentName,
      timecardEntryHeaderId,
      batch,
      errors,
      batchTemplateId,
      templateLocType,
    } = timecard;
    const [last, first] = employee.name.split(',');
    const employeeId = employee.id;
    const firstName = first?.trim() || '';
    const lastName = last?.trim() || '';

    const batchWorksightId = batch && batch.id;
    const htgBatchNumber = batch && batch.htgBatchNumber;

    const editable = isTimecardEditable(timecard);

    const newRow = {
      firstName,
      lastName,
      employeeId,
      tcIndex,
      editable,
      dealMemoTC: dealMemo,
      userFriendlyTimecardStatus: userFriendlyTimecardStatus || '',
      department: departmentName,

      timecardEntryHeaderId,
      batchWorksightId,
      htgBatchNumber,
      errors: errors || {},
      warnings: {},
      batchTemplateId,
      templateLocType,
      touched: false,
      updated: false,
    };

    const hasDetails =
      timecard && timecard.details && timecard.details.length > 0;

    if (!hasDetails) {
      timecard.details = [];
    }

    //find entry that matches the current effective date
    let detail = timecard.details.find(
      detail =>
        moment(detail.effectiveDate).format(DATE_FORMAT_LONG) === dtsDateString,
    );

    if (!detail) {
      detail = createDay(timecard, effectiveDate);
    }

    tableColumns.forEach(col => {
      const accessor = col.accessor;

      if (detail.hasOwnProperty(accessor)) {
        newRow[accessor] = _.cloneDeep(detail[accessor]);
      }
    });

    const { errors: validationErrors } = dtsTimecardValidate({
      timecard: newRow,
      columns: tableColumns,
    });

    if (_.isEmpty(newRow.errors)) {
      newRow.errors = { ...validationErrors };
    }
    if ('callTime' in newRow && 'wrapTime' in newRow) {
      newRow.hoursWorked = +parseFloat(calculateDayHours(newRow)).toFixed(2);
    }

    tableData.push(newRow);
  });

  return tableData;
};

const createDay = (timecard, effectiveDate) => {
  const dtsDateString = effectiveDate.format(DATE_FORMAT_LONG);

  // Day doesn't exist in timecard yet, add it in
  const newDetails = _.cloneDeep(timecard.details);
  const dealMemo = _.cloneDeep(timecard.dealMemo);
  const workState = _.cloneDeep(timecard.workState);
  const workCity = _.cloneDeep(timecard.workCity);
  const workCountry = _.cloneDeep(timecard.workCountry);
  const workSubdivision = _.cloneDeep(timecard.workSubdivision);

  const newDay = {
    effectiveDate: dtsDateString,
    dealMemo,
    workCountry,
    workState,
    workCity,
    workSubdivision,
    combineCheck: 'Y',
    rate: getRateFromDeal({ dealMemo }),
    newDay: true,
    dayType: {},
  };

  const hasDailyAllowance =
    dealMemo?.dealMemoAllowances?.filter(
      a => a.frequency === 'D' && (a.payCode1 || a.payCode2),
    ).length > 0;
  if (hasDailyAllowance) {
    newDay.isAutoDailyAllowance = true;
  } else {
    newDay.isAutoDailyAllowance = null;
  }

  ACCOUNT_FIELDS_MAP.forEach(field => {
    const { name, dealName } = field;
    if (dealMemo && dealMemo[dealName]) {
      newDay[name] = dealMemo[dealName];
    }
  });

  applyOccSchedToDay({ day: newDay, dealMemo });

  newDetails.push(newDay);

  const dateFormats = ['MM-DD-YYY', 'MM-DD-YYYYT00:00:00']; //possible date formats for effective date

  newDetails.sort((a, b) => {
    if (
      moment(b.effectiveDate, dateFormats).isBefore(
        moment(b.effectiveDate, dateFormats),
      )
    ) {
      return 1;
    } else {
      return -1;
    }
  });
  timecard.details = _.cloneDeep(newDetails);

  const day = timecard.details.find(
    detail =>
      moment(detail.effectiveDate).format(DATE_FORMAT_LONG) === dtsDateString,
  );

  if (!day) {
    console.error(`New Day (${dtsDateString} not found in timecard`, timecard);
  }

  return day;
};

export const update = args => {
  const {
    liveData,
    tableData,
    rowIndex,
    newValues,
    effectiveDate,
    columns,
    cta,
  } = args;

  if (isUpdateValid(args) === false) return [liveData, tableData, 0];

  //all day.updated flags are expected to be false here
  tableData.forEach(day => {
    if (day.updated) {
      throw new Error(
        `Day.updated not falsy before update. Found ${day.updated} on ${day}.`,
      );
    }
  });

  let showCTARoundingWarning = false;

  if (cta) {
    //copyToAll
    const roundingTypes = new Set();
    tableData.forEach(day => {
      updateSingle({
        day,
        newValues,
        columns,
        liveData,
        effectiveDate,
      });
      if (day.updated) {
        roundingTypes.add(day.dealMemo.roundTo);
      }
    });
    if (roundingTypes.size > 1) {
      const updatedFields = Object.keys(newValues);
      const wasTimeFieldUpdated = DTS_TIME_FIELDS.some(TIME_FIELD =>
        updatedFields.includes(TIME_FIELD),
      );
      if (wasTimeFieldUpdated) showCTARoundingWarning = true;
    }
  } else {
    //single update

    updateSingle({
      day: tableData[rowIndex],
      newValues,
      columns,
      liveData,
      effectiveDate,
    });
  }

  //count updated timecards
  const updateCount = tableData.reduce((acc, tc) => {
    if (tc.updated) {
      acc += 1;
      tc.updated = false;
    }
    return acc;
  }, 0);
  const newTable = updateCount ? _.cloneDeep(tableData) : tableData;

  return [liveData, newTable, { showCTARoundingWarning, updateCount }];
};

const isUpdateValid = ({ tableData, liveData }) => {
  //data is same size
  const liveLen = liveData.timecards.length;
  const tableLen = tableData.length;
  if (liveLen !== tableLen) {
    console.error(
      `Table and live data length mismatch: Table:(${tableLen})Live(${liveLen})`,
    );
    return false;
  }

  return true;
};

const updateSingle = ({ day, newValues, columns, liveData, effectiveDate }) => {
  if (day.editable === false) return;
  const { dayType } = day;
  const missingDayType = !(dayType && dayType.id);
  if (missingDayType && !newValues.dayType) return;

  // Get live day
  const { liveDay, liveTimecard } = getLiveDay({
    liveData,
    day,
    effectiveDate,
  });
  // isEmpWorkZoneEligible flag used to check whether employee is eligible for template work zone or not in BE
  day.isEmpWorkZoneEligible = liveTimecard.isEmpWorkZoneEligible;
  if (newValues.dayType) {
    soloCascadeDayType({ newValues, day });
  }

  if (newValues.locationType) {
    soloCascadeLocType({ newValues, day });
  }

  if (newValues.unrounded) {
    applyCtaRounding({ newValues, day });
  }

  for (const columnId in newValues) {
    if (Object.hasOwnProperty.call(newValues, columnId)) {
      updateValue({ day, liveDay, liveTimecard, columnId, newValues });
    }
  }

  if (newValues.locationType) {
    //rate is unique per employeeId
    //ensure it doesn't persist to the next one
    delete newValues.rate;
    delete newValues.accountCode;
  }

  let keys = Object.keys(newValues);
  let timeFieldChange = false;
  keys.forEach(key => {
    if (DTS_TIME_FIELDS.includes(key)) {
      timeFieldChange = true;
    }
  });

  if (timeFieldChange) {
    let originalDay = _.cloneDeep(day);
    if ('callTime' in day && 'wrapTime' in day) {
      if (
        day.callTime === undefined ||
        day.callTime === '' ||
        day.wrapTime === undefined ||
        day.wrapTime === ''
      ) {
        delete day.hoursWorked;
      } else {
        day.hoursWorked = +parseFloat(calculateDayHours(originalDay)).toFixed(
          2,
        );
      }
    } else if ('hoursWorked' in day) {
      delete day.hoursWorked;
    }
  }

  if ((liveDay.newDay || liveTimecard.draftCandidate) && !day?.dayType?.id) {
    // dayType is set to blank for draftCandidate, or a newDay
    // do not save changes to this day/timecard
    day.touched = false;
    liveTimecard.touched = false;
  }

  const { errors, warnings } = validateDay({ day, columns });
  day.errors = errors;
  day.warnings = warnings;
};

const getLiveDay = ({ liveData, day, effectiveDate }) => {
  const liveTimecard = liveData.timecards.find(
    tc => tc.employee.id === day.employeeId && isTimecardEditable(tc),
  );
  if (!liveTimecard) {
    console.error(
      `Employee: ${day.lastName}, ${day.firstName} not found in liveData`,
    );
    return;
  }
  const liveDay = liveTimecard.details.find(
    detail =>
      moment(detail.effectiveDate).format(DATE_FORMAT_LONG) ===
      effectiveDate.format(DATE_FORMAT_LONG),
  );

  if (!liveDay) {
    console.error(
      'Timecard has no entry for current effective date:',
      effectiveDate.format(DATE_FORMAT_LONG),
      liveTimecard,
    );
    return;
  }
  return { liveDay, liveTimecard };
};

const updateValue = ({ day, liveDay, liveTimecard, columnId, newValues }) => {
  //get value of column before update
  const before = _.cloneDeep(liveDay[columnId]);

  if (shouldRemoveTimeValues(columnId, day)) {
    delete day[columnId];
    delete liveDay[columnId];
  } else {
    let newVal = newValues[columnId];

    if (DTS_TIME_FIELDS.includes(columnId) && newVal) {
      newVal = Number(newVal);
    }

    day[columnId] = newVal;
    liveDay[columnId] = newVal;

    //fill in deal for draftCandidates:
    if (liveTimecard.draftCandidate && columnId === 'dealMemo') {
      day.dealMemoTC = newVal;
      liveTimecard.dealMemo = newVal;
      const hasDailyAllowances =
        newVal?.dealMemoAllowances?.filter(
          a => a.frequency === 'D' && (a.payCode1 || a.payCode2),
        )?.length > 0;
      if (hasDailyAllowances) {
        day.isAutoDailyAllowance = true;
        liveDay.isAutoDailyAllowance = true;
      } else {
        day.isAutoDailyAllowance = null;
        liveDay.isAutoDailyAllowance = null;
      }
    }
  }

  //set flags if value changed
  const after = _.cloneDeep(liveDay[columnId]);

  if (!_.isEqual(before, after)) {
    day.touched = true;
    day.updated = true;
    liveTimecard.touched = true;
  }
};

//do we need to remove this time field from the cell?
const shouldRemoveTimeValues = (columnId, day) => {
  if (DTS_TIME_FIELDS.includes(columnId)) {
    const dayTypeCode = day?.dayType?.code;
    const allowTimes = doesDayAllowTimes(dayTypeCode);
    if (!allowTimes) return true;
  }
  return false;
};

export const setDealMemos = dealMemos => (_dealMemos = dealMemos);

const soloCascadeDayType = ({ newValues, day }) => {
  const { dayType } = newValues;

  if (!dayType) return;

  if (day.templateLocType && day.isEmpWorkZoneEligible) {
    newValues.locationType = _.cloneDeep(day.templateLocType);
  } else {
    newValues.locationType = null;
  }
};

const soloCascadeLocType = ({ day, newValues }) => {
  const { dealMemo, dealMemoTC } = day;
  const { locationType } = newValues;

  // get guarantees
  // dealMemos is kept in sync with the value in the store by a useEffect in DTS
  // by setDealMemos()
  const dealMemos = _dealMemos;
  let fullDeal = dealMemos.find(dm => dm.id === dealMemo.id);

  // if the deal isn't in the dealMemos list, its a CTA - if the day deal is the same as the timecard one we can
  // use the timecard dealmemo.  If the deal has been updated during this session, the day will have the full deal
  // Otherwise blank out the rate so it gets updated when this timecard gets to WTC
  if (!fullDeal) {
    if (dealMemo.id === dealMemoTC.id) {
      fullDeal = dealMemoTC;
    } else if (dealMemo.guarantees) {
      fullDeal = dealMemo;
    } else {
      newValues.rate = ''; //we dont have enough date to get the rate so blank it out
      return;
    }
  }

  const accountCodes = getCascadingChangesFromDeal(fullDeal, locationType);

  if (accountCodes) {
    newValues.rate = accountCodes.rate === undefined ? '' : accountCodes.rate;
    newValues.accountCode = accountCodes.accountCode
      ? accountCodes.accountCode
      : '';
  }
};

const applyCtaRounding = ({ newValues, day }) => {
  const dealMemo = day.dealMemo;
  const unrounded = newValues.unrounded;
  let columnId = '';
  for (const field in newValues) {
    if (Object.hasOwnProperty.call(newValues, field)) {
      if (DTS_TIME_FIELDS.includes(field)) {
        if (columnId !== '') {
          throw new Error(`Should only update 1 time-field at a time`);
        }
        columnId = field;
      }
    }
  }

  const roundTo = dealMemo.roundTo;

  const tv = new TimeValidator(roundTo);
  newValues[columnId] = tv.parse(`${unrounded}`);
};

export const checkRoundingOnNewDeal = (newValues, original) => {
  return (
    newValues?.dealMemo?.roundTo &&
    newValues?.dealMemo?.roundTo !== original?.dealMemo?.roundTo
  );
};

export const getCascadingChanges = ({
  columnId,
  original,
  newVal,
  unrounded,
}) => {
  const newValues = {};
  newValues[columnId] = newVal;

  if (unrounded) {
    newValues.unrounded = unrounded;
  }

  switch (columnId) {
    case 'dealMemo':
      applyDealToDay({ day: newValues, original });
      break;
    case 'workCountry':
      newValues.workState = {};
      newValues.workCity = {};
      newValues.workCounty = {};
      newValues.workSubdivision = {};
      break;
    case 'workState': {
      newValues.workCity = {};
      newValues.workCounty = {};
      newValues.workSubdivision = {};
      break;
    }
    case 'dayType': {
      const dayTypeCode = newVal && newVal.code;
      const allowTimes = doesDayAllowTimes(dayTypeCode);
      if (!allowTimes) {
        DTS_TIME_FIELDS.forEach(timeField => (newValues[timeField] = ''));
      }

      break;
    }
    case 'episode': {
      if (newVal.series) newValues.series = newVal.series;
      if (newVal.location) newValues.location = newVal.location;
      break;
    }
    case 'locationType': {
      // this is handled in updateSingle since it the rate values it changes are unique for each timecard
      // will be handled better next release, but this will work for the moment. -Sam's famous last words Oct 1st, 2021
      break;
    }
    default:
      break;
  }

  return newValues;
};

const applyDealToDay = ({ day, original }) => {
  const { locationType } = original;
  const { dealMemo } = day;
  const accountCodes = getCascadingChangesFromDeal(dealMemo, locationType);

  for (const key in accountCodes) {
    if (Object.hasOwnProperty.call(accountCodes, key)) {
      const acctVal = accountCodes[key];
      if (acctVal !== undefined) day[key] = acctVal;
    }
  }

  applyOccSchedToDay({ day, dealMemo });

  applyRoundingToDay({
    day,
    original,
  });
};

const applyOccSchedToDay = ({ day, dealMemo }) => {
  if (dealMemo?.occupationCode && !_.isEmpty(dealMemo.occupationCode)) {
    day.occupationCode = dealMemo.occupationCode;
  }

  if (dealMemo?.workSchedule && !_.isEmpty(dealMemo.workSchedule)) {
    day.schedule = dealMemo.workSchedule;
  }
};

function getCascadingChangesFromDeal(dealMemo, locationType) {
  const guar = getGuarBasedOnWorkLoc(dealMemo, locationType);
  let cascades = {};
  if (guar) {
    cascades.rate = getRateFromDeal({ dealMemo, locationType, guar });
  } else {
    cascades.rate = '';
  }

  ACCOUNT_FIELDS_MAP.forEach(field => {
    const { name, dealName } = field;
    cascades[name] = dealMemo?.[dealName] || undefined;
  });
  return cascades;
}

const applyRoundingToDay = ({ day, original }) => {
  const roundTo = day?.dealMemo?.roundTo;

  if (roundTo === undefined) return;

  const tv = new TimeValidator(roundTo);

  DTS_TIME_FIELDS.forEach(field => {
    const oldValue = original[field];

    const roundedValue = tv.round(oldValue);

    if (roundedValue !== oldValue && Number.isNaN(roundedValue === false)) {
      day[field] = roundedValue;
    }
  });
};

const getRateFromDeal = ({
  dealMemo,
  locationType = undefined,
  guar = undefined,
}) => {
  guar = guar ? guar : getGuarBasedOnWorkLoc(dealMemo, locationType);

  if (!guar) return '';

  const rate =
    guar.payAtScale && (guar.payAtScale === 'C' || guar.payAtScale === 'Y')
      ? ''
      : guar.rate || guar.rate === 0
      ? guar.rate
      : undefined;

  return rate;
};

//showBlanks option will be first in the list and have id of ''
export const shouldAddBlanksToList = list => {
  if (list.length === 0) return true;

  const firstItem = list[0];
  if (firstItem.id === '') return false;

  return true;
};

const isTimecardEditable = timecard =>
  !!(
    timecard.draftCandidate === true ||
    timecard.userFriendlyTimecardStatus === 'Draft'
  );

export const getMaxWeekEnding = () => {
  const today = moment();

  const maxWeekEnd = today.clone().endOf('week').add(3, 'week');

  return maxWeekEnd;
};

//Validation utilities

const validateDay = ({ day, columns }) => {
  // const exampleCol = {
  //   Header: 'Call Time',
  //   accessor: 'callTime',
  //   default: true,
  //   id: 'callTime',
  //   position: 7,
  //   sortType: 'num',
  //   type: 'time',
  // };

  const validations = dtsTimecardValidate({ timecard: day, columns });

  return validations;
};

export const getErrors = ({ columnId, errors, warnings }) => {
  let isWarning = false;
  let errorMsg = '';
  if (errors && errors[columnId]) {
    errorMsg = errors[columnId];
  } else if (warnings && warnings[columnId]) {
    isWarning = true;
    errorMsg = warnings[columnId];
  }

  return { isWarning, errorMsg };
};

export const scanStates = (timecards = [], setHiddenColumns) => {
  if (timecards.length === 0 || !setHiddenColumns) return;

  const states = {};
  let specialOptions = '';
  timecards?.forEach(tc => {
    tc?.details.forEach(day => {
      if (day?.workState?.id && !states[day.workState.id]) {
        states[day.workState.id] = true;
        specialOptions += day.workState.specialOptions;
      }
    });
  });
  const fieldsToShow = [];
  if (specialOptions.includes('U')) fieldsToShow.push('workSubdivision');
  if (specialOptions.includes('C')) fieldsToShow.push('workCounty');
  if (specialOptions.includes('T')) fieldsToShow.push('workCity');

  if (fieldsToShow.length > 0) {
    setHiddenColumns(oldHiddenColumns => {
      let changed = false;
      const hiddenColumns = oldHiddenColumns.slice();
      fieldsToShow.forEach(fieldName => {
        const hiddenIndex = hiddenColumns.indexOf(fieldName);
        if (hiddenIndex !== -1) {
          hiddenColumns.splice(hiddenIndex, 1);
          changed = true;
        }
      });
      return changed ? hiddenColumns : oldHiddenColumns;
    });
  }
};

export const makeDropDownDisplay = (value, columnId) => {
  if (!value || _.isEmpty(value)) return '';

  switch (columnId) {
    case 'episode':
    case 'workSubdivision':
      return value.code;

    case 'combineCheck':
      return value;

    default:
      return value.name;
  }
};

//constants
export const MAX_EMPLOYEE_COUNT = 100;

export const MAX_TIMECARD_DELETE = 25;
export const MAX_TIMECARD_DELETE_ST = 150;
export const MAX_TIMECARD_SUBMIT_COUNT = 100;
export const MAX_TIMECARD_OPEN_COUNT = 100;

export const FIXED_COLUMNS = Object.freeze([
  {
    accessor: 'errors',
    id: 'errors',
    width: 25,
    Header: '',
    sticky: 'left',
    Cell: DisplayOnlyCell,
  },
  {
    accessor: 'lastName',
    id: 'lastName',
    Header: 'Last Name',
    sticky: 'left',
    width: 100,
    sortType: 'alpha',
    Cell: DisplayOnlyCell,
  },
  {
    accessor: 'firstName',
    id: 'firstName',
    Header: 'First Name',
    sticky: 'left',
    width: 100,
    sortType: 'alpha',
    Cell: DisplayOnlyCell,
  },
  {
    accessor: 'department',
    id: 'department',
    Header: 'Department',
    width: 100,
    sortType: 'alpha',
    Cell: DisplayOnlyCell,
  },
  {
    accessor: 'dealMemo.pensionUnion.name',
    id: 'union',
    Header: 'Union',
    width: 100,
    sortType: 'alpha',
    Cell: DisplayOnlyCell,
  },
]);

export const SORT_TYPE_MAP = Object.freeze({
  'auto-complete': 'alpha',
  checkbox: 'bool',
  float: 'num',
  time: 'num',
  label: 'alpha',
  mask: 'alpha',
  text: 'alpha',
});

export const DEFAULT_SORT = Object.freeze([
  { id: 'department' },
  { id: 'union' },
  { id: 'lastName' },
  { id: 'firstName' },
]);

//dropdown options that can be unique per employee
export const SOLO_OPTION_IDS = Object.freeze([
  'dayType',
  'occupationCode',
  'locationType', //AKA workLocation
  'workSubdivision',
  'workState',
  'workCity',
  'workCounty',
  // 'dealMemo', //technically this is one, but its handled uniquely with a modal
]);

//dropdown options that are the same for all employees
export const GROUP_OPTION_IDS = Object.freeze([
  'workCountry',
  'schedule',
  'combineCheck',
  'episode',
]);

//ideally this should be generated from the template
export const DTS_TIME_FIELDS = Object.freeze([
  'callTime',
  'ndbOut',
  'ndbIn',
  'meal1Out',
  'meal1In',
  'LastMan1In',
  'ndmOut',
  'ndmIn',
  'meal2Out',
  'meal2In',
  'meal3Out',
  'meal3In',
  'wrapTime',
]);

// subset of GROUP_OPTION_IDS that we make calls for IF they are in the template
export const OPTIONAL_GROUP_OPTION_IDS = Object.freeze([
  'workCountry',
  'schedule',
  'episode',
]);

export const ALL_AUTO_COMPETE_IDS = Object.freeze([
  ...SOLO_OPTION_IDS,
  ...GROUP_OPTION_IDS,
]);

export const OPTIONS_URLS = Object.freeze({
  schedule: { url: '/names/workSchedule', page: 1, pageSize: -1 },
  dayType: { url: '/names/dayType', page: 1, pageSize: -1 },
  locationType: { url: '/names/locationType', page: 1, pageSize: -1 },
  occupationCode: { url: '/names/occupationCode', page: 1, pageSize: 20 },
  episode: { url: '/names/episode', page: 1, pageSize: -1 },
  dealMemo: { url: '/projects/:projectId/employees/:employeeId/dealmemo' },
});

export const NUMERIC_CELLS = Object.freeze([
  ...DTS_TIME_FIELDS,
  'rate',
  'hoursWorked',
]);

export const baseCellStyles = Object.freeze({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: 100,
  whiteSpace: 'nowrap',
});

export const calculateDayHours = day => {
  var order = ['callTime'],
    hours = 0;

  _.times(3, index => {
    var inKey = `meal${index + 1}In`,
      outKey = `meal${index + 1}Out`;

    if (day[inKey] && day[outKey]) {
      order = order.concat([outKey, inKey]);
    }
  });

  order.push('wrapTime');
  while (order.length) {
    var fields = order.splice(0, 2);
    if ((day[fields[0]] || day[fields[0]] === 0) && day[fields[1]]) {
      hours += calculateHoursDiff(day[fields[0]], day[fields[1]]);
    }
  }

  return hours;
};

export const getOptionLoading = (columnId, loadingObj) => {
  let isLoading;
  if (columnId === 'locationType') {
    isLoading = loadingObj.dealMemo || loadingObj.locationType;
  } else {
    isLoading = !!loadingObj[columnId];
  }
  return isLoading;
};

export const splitTimecardsToBeDeleted = timecards => {
  const toEdit = [];
  const toDelete = [];

  timecards.forEach(tc => {
    if (tc.draftCandidate || tc.details.length > 1) {
      toEdit.push(tc);
      return;
    }

    const day = tc.details[0];

    if (day.dayType && !_.isEmpty(day.dayType)) {
      toEdit.push(tc);
      return;
    }
    toDelete.push(tc);
  });
  return { toEdit, toDelete };
};

let _effectiveDate;
export const formDtsPayload = (data, effectiveDate, projectAllowances) => {
  const timecards = _.cloneDeep(data);
  _effectiveDate = moment(effectiveDate).format(DATE_FORMAT_LONG);
  //timecards.forEach(timecard =>
  //  dtsTimecardPayload(timecard, projectAllowances),
  //);
  timecards.forEach(dtsTimecardPayload);

  return timecards;
};

const dtsTimecardPayload = (timecard, projectAllowances) => {
  if (timecard.dealMemo) {
    timecard.dealMemo = makeMiniDeal({
      dealMemo: timecard.dealMemo,
      includeGuar: true,
    });
  }

  const currentDay = timecard.details.find(d =>
    checkDateEqual(d.effectiveDate, _effectiveDate),
  );

  if (!currentDay) {
    throw new Error(
      `Current Effective date (${_effectiveDate}) not found on timecard`,
    );
  }
  timecard.details = [currentDay];

  dtsDetailPayload(timecard.details[0]);

  timecard.action = dtsAddActionFlag(timecard);
  //Comment code HOUR-11590
  //if (timecard.action === 'createTimecard') {
  //  addDealMemoAllowances(timecard, projectAllowances);
  //}
  const fieldsToRemove = [
    'userFriendlyTimecardStatus',
    'touched',
    'departmentName',
    'departmentId',
    'draftCandidate',
    'batchTemplateId',
    'htgCityId',
    'htgStateId',
  ];
  fieldsToRemove.forEach(fieldName => delete timecard[fieldName]);
};

const dtsDetailPayload = day => {
  for (const key in day) {
    if (Object.hasOwnProperty.call(day, key)) {
      const element = day[key];

      //remove empty object from payload
      if (typeof element === 'object' && _.isEmpty(element)) {
        delete day[key];
      }

      //enforce number typing + rate special case for emptyString
      if (NUMERIC_CELLS.includes(key)) {
        const oldVal = element;
        if (oldVal && typeof oldVal !== 'number') {
          const newVal = Number(oldVal);
          if (Number.isNaN(newVal)) {
            throw new Error(
              `Unable to convert numeric value '${oldVal}' to number.`,
            );
          }

          day[key] = newVal;
        } else if (!oldVal && key === 'rate') {
          // rate is emptyString - remove field
          delete day.rate;
        }
      }

      if (key === 'dealMemo' && day[key]) {
        const dealMemo = day[key];
        day.dealMemo = makeMiniDeal({ dealMemo, includeGuar: true });
      }

      if (key === 'effectiveDate') {
        const effectiveDate = day[key];
        if (effectiveDate.includes('T00:00:00') === false) {
          const formattedDate = moment(effectiveDate).format(DATE_FORMAT_LONG);
          day[key] = formattedDate;
        }
      }
    }
  }
};

// eslint-disable-next-line
//const addDealMemoAllowances = (timecard, projectAllowances) => {
//  let allowances = [];
//  const timecardDealMemo = timecard?.dealMemo;
//  if (timecardDealMemo?.dealMemoAllowances?.length > 0) {
//    timecardDealMemo?.dealMemoAllowances?.forEach(empAllowance => {
//      const payCode = !_.isEmpty(empAllowance.payCode1)
//        ? empAllowance.payCode1
//        : !_.isEmpty(empAllowance.payCode2)
//        ? empAllowance.payCode2
//        : null;
//      if (!_.isEmpty(payCode)) {
//        let allowance = {
//          rate: empAllowance?.allowancesAmount || empAllowance?.rate || 0,
//          htgAllowanceTypeId: payCode.id,
//          isDefaultAllowance: false,
//          combineCheck: empAllowance?.combineOnCheck,
//          accountCode: empAllowance?.account,
//          reason: {
//            id: payCode.id,
//            name: payCode.name,
//            code: payCode?.code,
//          },
//          hours: 1,
//          freeField1: timecardDealMemo?.customField1,
//          freeField2: timecardDealMemo?.customField2,
//          freeField3: timecardDealMemo?.customField3,
//          freeField4: timecardDealMemo?.customField4,
//          location: timecardDealMemo?.location,
//          series: timecardDealMemo?.series,
//          set: timecardDealMemo?.set,
//          insurance: timecardDealMemo?.insurance,
//          workState: timecard?.workState,
//          workCity: timecard?.workCity,
//          dealMemo: timecard.dealMemo,
//          occupationCode: timecard?.dealMemo?.occupationCode,
//        };
//        const projectAllowance = projectAllowances.find(
//          allow => allow.id === payCode.id,
//        );
//        if (!_.isEmpty(projectAllowance)) {
//          allowance = {
//            ...allowance,
//            isDefaultAllowance: projectAllowance.isDefaultAllowance,
//          };
//        }
//        Object.keys(allowance).forEach(
//          key => allowance[key] === undefined && delete allowance[key],
//        );
//        allowances.push(allowance);
//      }
//    });
//    timecard.allowances = allowances;
//  }
//};
const dtsAddActionFlag = timecard => {
  const day = timecard.details.find(d =>
    checkDateEqual(d.effectiveDate, _effectiveDate),
  );

  if (day.newDay) {
    delete day.newDay;
    return timecard.draftCandidate ? 'createTimecard' : 'addDays';
  }

  if (!day.dayType || _.isEmpty(day.dayType)) {
    return 'removeDays';
  }

  return 'editDays';
};

export const rateLessFilterParse = effectiveDate => tc => {
  const date = effectiveDate.format(DATE_FORMAT_LONG);

  const day = tc.details.find(d => checkDateEqual(d.effectiveDate, date));

  if (!day?.rate || day.rate === '0') {
    delete day.rate;
    return true;
  }

  return false;
};

export const checkDateEqual = (date1, date2) => {
  const date1String = moment(date1).format(DATE_FORMAT_LONG);
  const date2String = moment(date2).format(DATE_FORMAT_LONG);
  return date1String === date2String;
};
