import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { withStyleSheet } from 'shared/theme';
// components
import { timecardProps } from 'components/props/timecard';
import { EMPLOYEE_STATUS_TO_LABEL_MAP } from 'components/Shared/constants';

import { getTotalHours } from 'utils/weekUtils';

const style = ({ palette }) => ({
  row: {
    border: 'none',
    height: 'auto',
    padding: 1,
    margin: 1,
    color: palette.secondary.light,
    '& > *': {
      fontSize: 15,
    },
  },
  lastCol: {
    color: palette.secondary.light,
    textAlign: 'right',
    border: 'none',
    paddingRight: '10px !important',
  },
  btnExisting: {
    textTransform: 'none',
    marginTop: 8,
  },
});

class ExistingTimecards extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    timecard: timecardProps.isRequired,
    weekEnding: PropTypes.string.isRequired,
    onNavigate: PropTypes.func.isRequired,
  };

  render() {
    const timecard = this.props.timecard;
    const classes = this.props.classes;

    return (
      <Button
        variant="outlined"
        color="primary"
        onClick={this.props.onNavigate}
        className={classes.btnExisting}
      >
        <Table>
          <TableBody>
            <TableRow className={classes.row}>
              <TableCell className={classes.row}>
                <Typography color="primary">
                  <b>{EMPLOYEE_STATUS_TO_LABEL_MAP[timecard.status]}</b>
                </Typography>
              </TableCell>
              <TableCell className={classes.lastCol}>
                <Typography color="primary">
                  <b>{getTotalHours(timecard)}h</b>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.row}>
              <TableCell className={classes.row}>
                <Typography color="primary">
                  {timecard.dealMemo && timecard.dealMemo.jobDescription}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.row}>
              <TableCell className={classes.row}>
                <Typography color="primary">
                  Created by {timecard.createdBy && timecard.createdBy.fullName}
                </Typography>
              </TableCell>
              <TableCell className={classes.lastCol}>
                {timecard.createdDate &&
                  moment(timecard.createdDate).format('MM-DD-YYYY HH:mm a')}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Button>
    );
  }
}

export default withStyleSheet('existingTimecards', style)(ExistingTimecards);
