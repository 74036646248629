import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Paper, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import * as actions from 'actions/digitalEdits';

import {
  useDidMount,
  useWillUnmount,
  useObserveOutside,
} from 'utils/customHooks';

import Sidebar from './Sidebar';
import Header from './Header';
import RecordList from './RecordList';
import Footer from './Footer';

const useStyles = makeStyles(({ palette, breakpoints }) => {
  const sidebarWidth = '340px';
  const pageHeight = 'calc(100vh - 5px)'; //5px comes from FullWidthLayout
  return {
    DigitalEdits: { display: 'flex' },
    sidebarContainer: {
      width: sidebarWidth,
      height: pageHeight,
    },
    sidebarTop: {
      width: sidebarWidth,
    },
    sidebarFixed: {
      top: '0px',
      width: sidebarWidth,
    },
    mainContainer: {
      width: `calc(98vw - ${sidebarWidth})`,
    },
    [breakpoints.down(1300)]: {
      mainContainer: {
        overflowX: 'scroll',
        overflowY: 'visible',
      },
    },
  };
});

const mapState = state => ({});

const mapDispatch = dispatch => ({
  onInit: invoiceId => dispatch(actions.init({ invoiceId })),
  onUnmount: () => {
    dispatch(actions.unmount());
  },
});

const DigitalEdits = props => {
  const classes = useStyles();
  const {
    onInit,
    onUnmount,
    match: {
      params: { invoiceId },
    },
  } = props;

  useDidMount(() => onInit(invoiceId));

  useWillUnmount(() => {
    onUnmount();
  });

  const topRef = React.useRef();
  const [notFixed, setNotFixed] = React.useState(true);

  useObserveOutside(setNotFixed, topRef, null, null, 1);

  return (
    <Paper className={classes.DigitalEdits}>
      <Box className={classes.sidebarContainer}>
        <Box className={classes.sidebarTop} ref={topRef} />
        <Box
          sx={{ position: notFixed ? '' : 'fixed' }}
          className={classes.sidebarFixed}
        >
          <Sidebar />
        </Box>
      </Box>
      <Box className={classes.mainContainer}>
        <Header />
        <RecordList />
        <Footer />
      </Box>
    </Paper>
  );
};

DigitalEdits.propTypes = {
  onInit: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      invoiceId: PropTypes.string.isRequired,
    }),
  }),
};

export default compose(connect(mapState, mapDispatch))(DigitalEdits);
