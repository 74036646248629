import React, { useState, useEffect } from 'react';
import * as _cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// components
import WTCSettingsComponent from 'components/Admin/Projects/Settings/WTCSetting';
import { wtcEditableFields } from 'components/Employees/Reviews/WTC/tableFields';
import { settingsProps } from 'components/props/projects';

// selectors
import {
  getSettings,
  getLoading,
  getTimecardTemplates,
} from 'selectors/settings';
import { getProject } from 'selectors/routeParams';
// actions
import * as actions from 'actions/settings';

const mapState = state => ({
  settings: getSettings(state),
  loading: getLoading(state),
  projectId: getProject(state),
  timecardTemplateOptions: getTimecardTemplates(state),
});
const mapDispatch = dispatch => ({
  onSaveHandler: () => {
    dispatch(actions.updateWTCSetting());
  },
  onSetWtcEditable: (wtcEditable, projectId, wtcEditOption) => {
    dispatch(actions.setWtcEditable({ projectId, wtcEditable, wtcEditOption }));
  },
  onSetEditOptions: (projectId, editOptions) => {
    dispatch(actions.setEditOptions({ projectId, editOptions }));
  },
  onFetchTimecardTemplates: () => {
    dispatch(actions.fetchTimecardTemplates());
  },
  onSetWtcLayout: (projectId, wtcLayout) => {
    dispatch(actions.setWtcLayout({ projectId, wtcLayout }));
  },
});

const WTCSetting = props => {
  const {
    settings,
    onSetWtcEditable,
    onSetEditOptions,
    onSaveHandler,
    projectId,
    loading,
    timecardTemplateOptions,
    onFetchTimecardTemplates,
    onSetWtcLayout,
  } = props;
  const [wtcEditFields, setWtcEditFields] = useState(wtcEditableFields);

  useEffect(() => {
    if (settings.wtcEditable) {
      setWtcEditFields(old => {
        const wtcEditFields = _cloneDeep(old);
        for (const field in settings.wtcEditable) {
          if (settings.wtcEditable.hasOwnProperty(field)) {
            const fieldEditable = settings.wtcEditable[field];
            if (!wtcEditFields[field]) continue;
            wtcEditFields[field].editable = fieldEditable;
          }
        }

        const filtered = Object.keys(wtcEditFields).filter(
          item => item !== 'divider' && item !== 'selectAll',
        );
        const fieldsArr = filtered.map(field => ({
          editable: wtcEditFields[field].editable,
        }));
        const isAllFieldsChecked = fieldsArr.every(item => item.editable);
        if (isAllFieldsChecked) {
          wtcEditFields['selectAll'].editable = true;
        } else {
          wtcEditFields['selectAll'].editable = false;
        }
        return wtcEditFields;
      });
    }
  }, [settings]);

  const onEditableFieldChange = field => {
    const updatedFieldName = field.columnId;
    const updatedValue = !field.editable;
    const wtcEditable = _cloneDeep(settings.wtcEditable);
    const updatedWtcEditFields = _cloneDeep(wtcEditFields);

    if (updatedFieldName === 'selectAll') {
      //Toggle all setting in redux
      for (const field in wtcEditable) {
        if (wtcEditable.hasOwnProperty(field)) {
          wtcEditable[field] = updatedValue;
        }
      }
      //Toggle all settings locally
      for (const field in updatedWtcEditFields) {
        if (updatedWtcEditFields.hasOwnProperty(field)) {
          updatedWtcEditFields[field].editable = updatedValue;
        }
      }
    } else {
      //update settings in redux state
      wtcEditable[updatedFieldName] = updatedValue;

      //update local state
      updatedWtcEditFields[updatedFieldName].editable = updatedValue;
    }
    onSetWtcEditable(wtcEditable, projectId);
    setWtcEditFields(updatedWtcEditFields);
  };
  const onChangeHandler = e => {
    onSetEditOptions(projectId, e.target.value);
  };
  const onWtcLayoutHandler = (e, value) => {
    onSetWtcLayout(projectId, value);
  };
  return (
    <WTCSettingsComponent
      wtcEditFields={wtcEditFields}
      editOptions={settings.editOptions}
      wtcLayout={settings.wtcLayout}
      onSave={onSaveHandler}
      onEditableFieldChange={onEditableFieldChange}
      onChangeHandler={onChangeHandler}
      loading={loading}
      timecardTemplateOptions={timecardTemplateOptions}
      onFetchTimecardTemplates={onFetchTimecardTemplates}
      onWtcLayoutHandler={onWtcLayoutHandler}
    />
  );
};
WTCSetting.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSaveHandler: PropTypes.func.isRequired,
  settings: settingsProps.isRequired,
};
export default connect(mapState, mapDispatch)(WTCSetting);
