import React from 'react';
import { Field } from 'redux-form';
import { compose } from 'utils/helperFunctions';
import { LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
// actions
import { hide as hideModal } from 'actions/modalDialog';
import { studioPlusButtonDisabled } from 'actions/settings';

const useStyles = makeStyles(theme => ({
  content: {
    maxWidth: 500,
    height: 500,
    backgroundColor: '#eceeef',
    overflowX: 'scroll',
  },
  text: {
    fontSize: 12,
  },
  padding: {
    paddingLeft: 26,
  },
}));

function EditStudioPlusDestination(props) {
  const {
    handleSubmit,
    studioPlusDirectory,
    action,
    change,
    dispatch,
    studioPlusGroup,
    BRStudioEnabled,
    TCStudioEnabled,
    TCStudioPath,
    BRStudioPath,
    ...others
  } = props;

  const classes = useStyles();
  const [folderId, setFolderId] = React.useState(null);

  const onSelect = id => {
    setFolderId(id);
  };

  const modalSubmit = () => {
    change(`studio${studioPlusGroup}Path`, folderId);

    if (studioPlusGroup === 'Timecard') {
      if (TCStudioEnabled && BRStudioEnabled && folderId && !BRStudioPath) {
        dispatch(
          studioPlusButtonDisabled({
            studioPlusBtnDisabled: true,
          }),
        );
      } else {
        dispatch(
          studioPlusButtonDisabled({
            studioPlusBtnDisabled: false,
          }),
        );
      }
    }
    if (studioPlusGroup === 'Batch') {
      if (TCStudioEnabled && BRStudioEnabled && folderId && !TCStudioPath) {
        dispatch(
          studioPlusButtonDisabled({
            studioPlusBtnDisabled: true,
          }),
        );
      } else {
        dispatch(
          studioPlusButtonDisabled({
            studioPlusBtnDisabled: false,
          }),
        );
      }
    }
    dispatch(
      hideModal({
        dialog: 'editStudioPlusPath',
      }),
    );
  };

  const getTreeItemsFromData = treeItems => {
    return treeItems.map(treeItemData => {
      let children = undefined;
      let childlessPadding = undefined;
      if (
        treeItemData.childDirectories &&
        treeItemData.childDirectories.length > 0
      ) {
        children = getTreeItemsFromData(treeItemData.childDirectories);
      }
      if (children === undefined) {
        childlessPadding = classes.padding;
      }

      return (
        <TreeItem
          key={treeItemData.id}
          nodeId={treeItemData.id}
          label={treeItemData.name}
          children={children}
          onClick={() => {
            onSelect(treeItemData.id);
          }}
          classes={{
            label: classes.text,
            content: childlessPadding,
          }}
        />
      );
    });
  };

  const content =
    studioPlusDirectory && studioPlusDirectory.length > 0 ? (
      <div className={classes.content}>
        <TreeView
          defaultCollapseIcon={<ArrowDropDownIcon size="small" />}
          defaultExpandIcon={<ArrowRightIcon size="small" />}
        >
          {getTreeItemsFromData(studioPlusDirectory)}
        </TreeView>
      </div>
    ) : (
      <LinearProgress />
    );

  return (
    <form onSubmit={handleSubmit}>
      <Field
        component="input"
        name="group"
        type="hidden"
        value={studioPlusGroup}
      />
      <Field component="input" name="folderId" type="hidden" />
      <ConfirmModal
        content={content}
        title={'Select Studio+ Destination Directory'}
        buttonText="OK"
        buttonType="secondary"
        raisedButton
        hasSubmitAction
        customSubmit
        customOnSubmit={modalSubmit}
        {...others}
      />
    </form>
  );
}

export default compose(
  withModalDialog({ dialog: 'editStudioPlusPath', maxWidth: 'md' }),
)(EditStudioPlusDestination);
