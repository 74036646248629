import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Typography } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import { LinearProgress } from '@mui/material';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
//actions
import { removeScheduledReport } from 'actions/reports';
//selector
import { getEditingScheduledReport, getLoading } from 'selectors/reports';

const onSubmit = (values, dispatch) => {
  const { reportId } = values;
  dispatch(removeScheduledReport({ reportId }));
};

const mapStateToProps = state => ({
  initialValues: getEditingScheduledReport(state),
  report: getEditingScheduledReport(state),
  isLoading: getLoading(state),
});

const mapDispatchToProps = dispatch => ({});

const style = () => ({
  content: {
    maxWidth: 500,
  },
});

class RemoveScheduledReport extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { classes, handleSubmit, report } = this.props;
    const content = (
      <div className={classes.content}>
        <Typography color="inherit">
          {'Are you sure you want to delete this scheduled report? '}
        </Typography>
        {this.props.isLoading && <LinearProgress />}
      </div>
    );
    const title = (
      <Typography variant="h6" color="inherit">
        {`Delete Scheduled Department`}
      </Typography>
    );

    return (
      <form onSubmit={handleSubmit}>
        <Field
          component="input"
          name="reportId"
          type="hidden"
          value={report.reportId}
        />
        <ConfirmModal
          content={content}
          title={title}
          buttonText="Remove"
          buttonType="secondary"
          raisedButton={true}
          {...this.props}
        />
      </form>
    );
  }
}

export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: 'removeScheduledReport', maxWidth: 'md' }),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'DeleteScheduledReport',
    onSubmit,
  }),
)(RemoveScheduledReport);
