export const INVALID_EMAIL = 'invalid_email';
export const LOWER_CASE = 'lower_case';
export const MIN_LENGTH = 'min_length';
export const MIN_MAX_LENGTH = 'min_max_length';
export const NUMERICS = 'numerics';
export const REQUIRED = 'required';
export const SPECIAL_CHARS = 'special_chars';
export const UPPER_CASE = 'upper_case';
export const INVALID_SSN_FORMAT = 'invalid_ssn_format';
export const INVALID_SIN_FORMAT = 'invalid_sin_format';
export const INVALID_FORMAT = 'invalid_format';
export const EMAIL_DUPLICATE = 'email_duplicate';
export const NOT_UNIQUE = 'not_unique';
export const NAME_INVALID = 'name_invalid';
export const NOT_PRESENT = 'required';
export const LUHN_SIN_FORMAT = 'luhn_algorithm_sin_warning';

const Messages = {
  [INVALID_EMAIL]: 'is not a valid email address',
  [INVALID_SSN_FORMAT]: 'is not a valid SSN',
  [INVALID_SIN_FORMAT]: 'is not a valid SIN',
  [INVALID_FORMAT]: 'not a valid format',
  [LOWER_CASE]: 'Lower Case',
  [MIN_LENGTH]: 'should have a minimum length of {0}',
  [MIN_MAX_LENGTH]:
    'should have a min length of {min} and a max length of {max}',
  [NUMERICS]: 'Number',
  [REQUIRED]: 'is required',
  [SPECIAL_CHARS]: 'Special Characters',
  [UPPER_CASE]: 'Upper Case',
  [EMAIL_DUPLICATE]: 'Address already in use',
  [NOT_UNIQUE]: 'This field must be unique',
  [NAME_INVALID]: 'Numbers not allowed',
  [NOT_PRESENT]: 'is required',
  [LUHN_SIN_FORMAT]:
    'SIN does not conform to valid format. Double-check the number before proceeding?',

  get: key => Messages[key] || key,
};
// For string interpolation.
const template = (tpl, args) => tpl.replace(/\{(\w+)}/g, (_, v) => args[v]);

export const formatMessage = (key, opts) => template(Messages.get(key), opts);
export const formatMessages = keys =>
  keys.reduce((message, key) => message + formatMessage(key), ' ');

// export default {
//   formatMessage,
//   formatMessages,
// };
