import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Box, LinearProgress, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PencilPerson from 'assets/icons/PencilPerson.js';

import { TabHeader, TabPaper } from '../Shared/styledComponents';

import HistoryCard from '../Shared/HistoryCard';

import * as sel from '../selectors';
import { getSettings } from 'selectors/settings';

const useStyles = makeStyles(({ palette }) => ({
  historyContent: {
    padding: '20px',
    margin: '15px',
    backgroundColor: palette.gray.background,
    borderRadius: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  approver: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));
const mapState = state => {
  return {
    timecardHistory: sel.getTimecardHistory(state),
    timecardId: sel.getTimecardId(state),
    projectSettings: getSettings(state),
    loading: sel.getHistoryLoading(state),
  };
};

const mapDispatch = dispatch => ({});

const HistoryTab = props => {
  const { timecardHistory, projectSettings, loading } = props;
  const classes = useStyles();

  const filteredHistory = timecardHistory?.find(
    app => app.approvers?.length > 0,
  );

  let approverRows = null;
  if (!_.isEmpty(filteredHistory)) {
    approverRows = filteredHistory?.approvers?.map(approver => (
      <Box
        sx={{ color: 'primary.main', pl: '7px', textTransform: 'uppercase' }}
        key={approver.firstName}
      >
        {approver.fullName}
      </Box>
    ));
  }

  return (
    <TabPaper style={{ paddingBottom: '10px' }}>
      <TabHeader sx={{ fontWeight: 'bold', p: '12px 24px', flexWrap: 'wrap' }}>
        <Box>History</Box>
        <Box className={classes.approver}>
          {approverRows && (
            <>
              Current Approvers{' '}
              <span style={{ paddingLeft: '20px' }}>
                <PencilPerson color="primary" />
              </span>
              {approverRows}
            </>
          )}
        </Box>
      </TabHeader>
      {!!loading && <LinearProgress />}
      {!loading && timecardHistory.length === 0 ? (
        <Box sx={{ p: 3 }}>No history</Box>
      ) : (
        <Box className={classes.historyContent}>
          <Grid container spacing={2}>
            {timecardHistory?.map(history => (
              <HistoryCard
                key={history.id}
                history={history}
                projectSettings={projectSettings}
              />
            ))}
          </Grid>
        </Box>
      )}
    </TabPaper>
  );
};

HistoryTab.propTypes = {
  timecardHistory: PropTypes.array.isRequired,
  projectSettings: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default compose(connect(mapState, mapDispatch))(HistoryTab);
