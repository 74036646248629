import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { default as LeftIcon } from '@mui/icons-material/KeyboardArrowLeft';
import { default as RightIcon } from '@mui/icons-material/KeyboardArrowRight';
import { useLocation } from 'react-router-dom';

//selectors
import { getProjectDetails, getViewingYear } from 'selectors/project';
import { currentUser as activeUser } from 'selectors/session';

//actions
import { setViewingYear, switchYear } from 'actions/project';

//utils
import { getAltYearLandingPage } from 'utils/helperFunctions';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  YearPicker: {
    display: 'flex',
    fontSize: 20,
    marginLeft: 10,
  },
  arrows: {
    cursor: 'pointer',
    [breakpoints.down('md')]: {
      fontSize: 30,
    },
  },
  yearText: {
    [breakpoints.down('md')]: {
      padding: '0px 15px',
      fontSize: 24,
    },
  },
}));

const mapStateToProps = state => ({
  projectDetails: getProjectDetails(state),
  viewingYear: getViewingYear(state),
  currentUser: activeUser(state),
});

const mapDispatchToProps = dispatch => ({
  onSetYear: viewingYear => dispatch(setViewingYear({ viewingYear })),
  onSwitchYear: location => dispatch(switchYear({ location })),
});

export const YearPicker = props => {
  const classes = useStyles();
  const location = useLocation();

  const {
    className,
    viewingYear,
    projectDetails: {
      previousYearProjectId,
      canAccessNextYearProject,
      canAccessPreviousYearProject,
      nextYearProjectId,
    },
    onSetYear,
    currentUser: { role },
    hide,
    onSwitchYear,
  } = props;

  const prevYearRef = useRef(viewingYear);
  const locationRef = useRef(location);

  useEffect(() => {
    //don't trigger switch when initial year is set (project sagas).
    if (prevYearRef.current && prevYearRef.current !== viewingYear) {
      onSwitchYear(locationRef.current);
    }
    prevYearRef.current = viewingYear;
  }, [viewingYear, onSwitchYear]);

  //keep location upto date without triggering the year switch
  useEffect(() => {
    locationRef.current = location;
  }, [location]);

  const hasPrev = viewingYear === 2020 ? false : canAccessPreviousYearProject;

  const hasNext = canAccessNextYearProject;

  if (hide || isNaN(viewingYear)) {
    return null;
  }

  const handlePrevClick = () => {
    if (viewingYear === 2021) {
      const url = getAltYearLandingPage(location, previousYearProjectId, role);
      window.location.href = url;
    } else if (viewingYear === 2020) {
      //this shouldn't be shown for 2020 projects
      console.error(
        'Expected: 2020 project should not have a prev year option',
      );
    } else {
      onSetYear(viewingYear - 1);
    }
  };

  const handleNextClick = () => {
    if (viewingYear === 2020) {
      const url = getAltYearLandingPage(location, nextYearProjectId, role);
      window.location.href = url + '?year=2021';
    } else {
      onSetYear(viewingYear + 1);
    }
  };

  return (
    <div className={clsx(classes.YearPicker, className)}>
      {hasPrev && (
        <LeftIcon className={classes.arrows} onClick={handlePrevClick} />
      )}
      <span className={classes.yearText}>{viewingYear}</span>
      {hasNext && (
        <RightIcon className={classes.arrows} onClick={handleNextClick} />
      )}
    </div>
  );
};

YearPicker.propTypes = {
  projectDetails: PropTypes.object.isRequired,
  viewingYear: PropTypes.number,
  onSetYear: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(YearPicker);
