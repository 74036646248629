import React from 'react';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
const style = ({ palette }) => ({
  root: {},
  error: {
    margin: 16,
    color: palette.error.main,
  },
});

const ErrorMsg = ({ classes, variant = 'subtitle2', children, ...rest }) => {
  return (
    <Typography variant={variant} className={classes.error} {...rest}>
      {children}
    </Typography>
  );
};

export default withStyles(style)(ErrorMsg);
