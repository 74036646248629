import React from 'react';
import Settings from 'containers/Admin/Projects/Settings';
import {
  DefaultLayout,
  Header,
  ProjectHeader,
  AlertHeader,
} from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    admin
    authenticated
    key="/admin/projects/settings"
    path="/admin/projects/:projectId/settings"
    layout={DefaultLayout}
    headers={[Header, ProjectHeader, AlertHeader]}
    component={Settings}
    title="Admin Settings"
  />
);
