import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Box, Tooltip, styled, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format, formatDistanceToNow, parseISO } from 'date-fns';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {
  getCommentSaveFails,
  getUnsubmittedComment,
} from 'selectors/digitalEdits';

const useStyles = makeStyles(({ palette }) => ({
  LastSaved: { display: 'flex', alignItems: 'center' },
}));

const ErrorBox = styled(Box)(args => {
  const { theme } = args;
  return {
    color: theme.palette.error.main,
    fontWeight: 800,
  };
});

const mapState = state => ({
  saveFailCount: getCommentSaveFails(state),
  unsubmittedComment: getUnsubmittedComment(state),
});

const LastSaved = props => {
  const classes = useStyles();
  const {
    // lastSaveTime,
    savingComment,
    saveFailCount,
    commentText,
    unsubmittedComment,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = React.useState(0);
  const [copiedText, setCopiedText] = React.useState(false);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      //using this to re-render this comp to keep the 'last saved' time updated
      setCount(c => c + 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const { updatedBy, updatedDate } = unsubmittedComment || {};

  if (!updatedBy) return null;

  const bigFailMsg = (
    <ErrorBox>
      We’re unable to save your comment.
      <br /> Copy your text to an offline source and refresh the page.
      {!!commentText && (
        <Tooltip title={'Copy to clipboard'}>
          <ContentCopyIcon
            sx={{ color: 'text.disabled', pl: '4px' }}
            onClick={() => {
              setCopiedText(true);
              navigator.clipboard.writeText(commentText);
              setTimeout(() => {
                setCopiedText(false);
              }, 2000);
            }}
          />
        </Tooltip>
      )}
    </ErrorBox>
  );

  let saveText = '';
  if (savingComment) {
    saveText = 'Saving';
  } else if (copiedText) {
    saveText = 'Comment copied to clipboard!';
  } else if (saveFailCount === 0) {
    const timeSinceSave = formatDistanceToNow(parseISO(updatedDate), {
      includeSeconds: true,
      addSuffix: true,
    });

    saveText = `Saved ${timeSinceSave} by ${updatedBy}`;
  } else if (saveFailCount > 4) {
    saveText = bigFailMsg;
  } else if (saveFailCount === 1) {
    saveText = <ErrorBox>{`Save failed`}</ErrorBox>;
  } else {
    saveText = <ErrorBox>{`Save failed ${saveFailCount} times`}</ErrorBox>;
  }

  return (
    <Box className={classes.LastSaved}>
      {savingComment && <CircularProgress size={15} color={'primary'} />}
      <Tooltip title={format(parseISO(updatedDate), 'MM/dd/yyyy HH:mm:ss')}>
        <Box sx={{ color: 'text.disabled', padding: '0px 8px' }}>
          {saveText}
        </Box>
      </Tooltip>
    </Box>
  );
};

LastSaved.propTypes = {
  savingComment: PropTypes.bool.isRequired,
  saveFailCount: PropTypes.number.isRequired,
  unsubmittedComment: PropTypes.object.isRequired,
  commentText: PropTypes.string,
};

export default compose(connect(mapState))(LastSaved);
