import React from 'react';
import { EnhancedRoute } from 'containers/RoutingSupport';
import AllowanceDocument from 'containers/Employees/AllowanceDocument';

//TODO - is this component/route used anywhere?
// Maybe in wtc sagas?

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    key="/allowances/:token"
    path="/allowances/:token"
    authenticated
    component={AllowanceDocument}
  />
);
