import React from 'react';

export default function Check(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      style={props.style || {}}
      className={props.className}
    >
      <path
        d="M10 3L4.5 8.5L2 6"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
