import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { createReduxHistoryContext } from 'redux-first-history';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createBrowserHistory } from 'history';

import appReducer from 'reducers';
import createRootSaga from 'sagas';
import createAction from 'actions/createAction';

export const RESET_APP = createAction('reset/app');
export const RESET_APP_ALL = createAction('reset/app/all');

if (!localStorage.debug) {
  localStorage.debug = 'axios,sagas';
}

export default function configureStore({ initialState, api, cookie }) {
  const sagaMiddleware = createSagaMiddleware();
  const rootSaga = createRootSaga({ api, cookie });

  const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({
      history: createBrowserHistory(),
      //other options if needed
    });

  const enhancer = composeWithDevTools(
    applyMiddleware(routerMiddleware, sagaMiddleware),
  );
  // const persistConfig = {
  //   key: 'root',
  //   storage,
  // };

  // const persistedReducer = persistReducer(persistConfig, rootReducer(history));
  const rootReducer = routerReducer => (state, action) => {
    if (action.type === 'reset/app') {
      const { projects, session, router } = state;
      state = { projects, session, router };
      localStorage.removeItem('fromURI');
    }
    if (action.type === 'reset/app/all') {
      state = {};
      localStorage.removeItem('fromURI');
    }

    return appReducer(routerReducer)(state, action);
  };
  const store = createStore(rootReducer(routerReducer), initialState, enhancer);
  // const persistor = persistStore(store);
  const history = createReduxHistory(store);
  sagaMiddleware.run(rootSaga);

  return [store, history];
}
