import { default as List } from './List';
import { default as Events } from './Events';
import { default as InvoiceTemplate } from './InvoiceTemplate';
import { default as Settings } from './Settings';
import { default as DepartmentApprovers } from './DepartmentsApprovers';
import { default as CrewList } from './CrewList';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  List,
  Events,
  InvoiceTemplate,
  Settings,
  DepartmentApprovers,
  CrewList,
};
