import React, { Fragment } from 'react';
import _ from 'lodash';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import Done from '@mui/icons-material/Check';

import {
  ModalFooter,
  ModalContent,
  ModalTitle,
} from 'components/Shared/ModalDialog';
import ErrorMessages from './ErrorMessages';

import { useDidMount } from 'utils/customHooks';

const useStyles = makeStyles(theme => ({
  root: {},
  button: {
    marginRight: 10,
  },
  icon: {
    marginRight: 4,
  },
  message: {
    marginTop: 10,
    marginBottom: 30,
  },
  progress: {
    marginTop: 20,
    marginBottom: 30,
  },
  errorTitle: {
    margin: 5,
    fontWeight: 500,
  },
  successMessage: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& > *': {
      margin: 10,
    },
  },
}));

export default function StartProject({
  onModalHide,
  onSubmit,
  project,
  validating,
  validation,
  onValidateStart,
}) {
  const classes = useStyles();

  useDidMount(() => {
    onValidateStart();
  });

  return (
    <div>
      <ModalTitle>
        <Typography variant="h6" color="inherit">
          {'Checking Project'}
        </Typography>
      </ModalTitle>
      <ModalContent>
        {validating && (
          <div>
            <Typography
              color="inherit"
              variant="h6"
              className={classes.message}
            >
              {
                'We are checking your project to make sure everything is groovy...'
              }
            </Typography>
            <LinearProgress className={classes.message} />
          </div>
        )}
        {!validating && !_.isEmpty(validation) && (
          <div>
            <Typography color="inherit" className={classes.errorTitle}>
              {
                'We are sorry but your project is incomplete, please pay attention to the next items:'
              }
            </Typography>
            {_.map(validation, (errors, type) => {
              return <ErrorMessages key={type} type={type} errors={errors} />;
            })}
          </div>
        )}
        {!validating && _.isEmpty(validation) && (
          <div className={classes.successMessage}>
            <Done style={{ fontSize: 40 }} color="primary" />
            <Typography
              color="inherit"
              variant="h6"
              className={classes.message}
            >
              {'Your project looks awesome!'}
            </Typography>
          </div>
        )}
      </ModalContent>
      <ModalFooter>
        {!validating && _.isEmpty(validation) && (
          <Fragment>
            <Button
              className={classes.button}
              variant="outlined"
              name="cancel"
              onClick={onModalHide}
            >
              {'Cancel'}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={onSubmit}
            >
              {'Start'}
            </Button>
          </Fragment>
        )}
        {!validating && !_.isEmpty(validation) && (
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={onModalHide}
          >
            {'Got it'}
          </Button>
        )}
        {validating && (
          <Typography color="inherit" variant="subtitle1" style={{ margin: 5 }}>
            {`Checking ${project.name} Hours+ Setup`}
          </Typography>
        )}
      </ModalFooter>
    </div>
  );
}
