import _ from 'lodash';
import camelCase from 'camelcase-keys';
import { createSelector } from 'reselect';

// direct selectors...
export const getFromURI = () => {
  const uri = localStorage.getItem('fromURI');
  return uri ? uri : '';
};

export const getCurrentTimecard = state =>
  _.get(state, 'timecards.currentTimecard', '');

export const getCurrentDetails = state =>
  _.get(state, `timecards.currentDetails`, {});

export const getRecentlyAddedComments = state =>
  _.get(state, `timecards.recentlyAddedComments`, {});

export const getESignatureStatus = state =>
  _.get(state, 'timecards.enableESignature', false);
export const getTimecards = state => _.get(state, 'timecards.list', []);
export const getSearchedTimecards = state =>
  _.get(state, 'timecards.search', []);

// loading statuses
export const isSavingTimecard = state =>
  _.get(state, `timecards.savingTimecard`, false);
export const getLoading = state => _.get(state, 'timecards.loading', false);
export const getLoadingDetails = state =>
  _.get(state, 'timecards.loadingDetails', false);
export const getTimecardLoadingDetails = id => state =>
  _.get(state, `timecards.loadingTimecardDetails.${id}`, true);
export const reloadTimecard = state => _.get(state, `timecards.reload`, false);
export const isNotesProcessing = state =>
  _.get(state, 'timecards.processingNotes', false);
export const isAllowanceProcessing = state =>
  _.get(state, 'timecards.processingAllowances', false);

export const getDayTypes = state => _.get(state, 'timecards.dayTypes', []);

export const getEpisodes = state => _.get(state, 'timecards.episodes', []);
export const getWorkLocations = state =>
  _.get(state, 'timecards.workLocations', []);
// Allowances for MyTimecard Dropdown (Employee View)
export const getMyTimecardAllowances = state =>
  _.get(state, 'timecards.timecardAllowances', []);

export const getMyEmployeeAllowancePayCodes = state =>
  _.get(state, 'timecards.employeeAllowancePayCodes', []);

export const parsedAllowancePayCodes = createSelector(
  [getMyEmployeeAllowancePayCodes],
  allowancePayCodes => {
    const result = allowancePayCodes.map(payCode => ({
      value: payCode.id,
      label: `${payCode.code} - ${payCode.name}`,
      ref: payCode,
      subset: true,
      subsetVal: payCode.code,
    }));
    return result || [];
  },
);

// Timecard Details by ID:
export const getDetails = (state, id) =>
  _.get(state, `timecards.details.${id}`, {});

// Timecard Allowances by ID:
export const getTimecardAllowances = (state, id) =>
  _.get(state, `timecards.tcAllowances.${id}`, []);

export const getLocalNotes = state => _.get(state, 'timecards.localNotes', []);

export const getLocalAllowances = state => {
  let allowances = _.get(state, 'timecards.localAllowances', []);
  return camelCase(allowances, { deep: true });
};

export const getComment = state => _.get(state, 'timecards.comment', {});
