import { buildUrl } from '../api';

const URL_PROJECT_USERS = '/projects/:projectId/users';
const URL_CREW_STATUS_COUNT = '/projects/:projectId/users/count';
const URL_DELETE_USER = '/projects/:projectId/users/delete';
const URL_USER_DETAILS = '/projects/:projectId/users/email';
const URL_HOT_COSTS = '/projects/:projectId/users/updateHotCost';
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async users({ projectId }) {
    const url = buildUrl(URL_PROJECT_USERS, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
  async crewStatusCount({ projectId }) {
    const url = buildUrl(URL_CREW_STATUS_COUNT, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
  async deleteUser({ projectId, data }) {
    const url = buildUrl(URL_DELETE_USER, { projectId });
    const rsp = await clientV2.delete(url, { data });
    return rsp.data;
  },
  async fetchUser({ projectId, invites }) {
    const url = buildUrl(URL_USER_DETAILS, { projectId });
    const rsp = await clientV2.post(url, invites);
    return rsp.data;
  },
  async saveHotCost({ projectId, hotcosts }) {
    const url = buildUrl(URL_HOT_COSTS, { projectId });
    const rsp = await clientV2.put(url, hotcosts);
    return rsp.data;
  },
});
