import axios from 'axios';
import { myCncUrl } from 'constants/config/apiServer';

import './axiosdebug';

const URL_ME = '/me';
const URL_VALIDATE_SESSION = '/validateSession';
const REGISTER_AUTH_TICKET = '/me/register';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async validateSession() {
    const ssoClient = axios.create({
      baseURL: myCncUrl,
      withCredentials: true,
    });
    const resp = ssoClient.get(URL_VALIDATE_SESSION);

    return resp.data;
  },

  async revokeToken() {
    const rsp = await clientV2.delete(URL_ME);
    return rsp.data;
  },

  async me() {
    const rsp = await clientV2.get(URL_ME);
    return rsp.data;
  },

  async getAuthTicket() {
    const rsp = await clientV2.post(REGISTER_AUTH_TICKET);
    return rsp.data;
  },
});
