import createAction from 'actions/createAction';

export const init = createAction('init/employeeTimecard');

export const parseColumns = createAction('parseColumns/employeeTimecard');
export const storeColumns = createAction('storeColumns/employeeTimecard');

export const setLoading = createAction('setLoading/employeeTimecard');
export const reset = createAction('reset/employeeTimecard');

export const storeTimecard = createAction('storeTimecard/employeeTimecard');
export const storeAllowances = createAction('storeAllowances/employeeTimecard');
export const storeIsMobile = createAction('storeIsMobile/employeeTimecard');
export const saveTimecard = createAction('saveTimecard/employeeTimecard');
export const submitTimecard = createAction('submitTimecard/employeeTimecard');

export const saveAllowance = createAction('saveAllowance/employeeTimecard');

export const fetchDealMemos = createAction('fetchDealmemos/employeeTimecard');
export const changeDealMemo = createAction('changeDealMemo/employeeTimecard');

export const updateFieldVisibility = createAction(
  'updateFieldVisibility/employeeTimecard',
);
export const setFieldVisibilityPreReq = createAction(
  'setFieldVisibilityPreReq/employeeTimecard',
);
export const storeDealMemos = createAction('storeDealmemos/employeeTimecard');
export const setUseMilitary = createAction('setUseMilitary/employeeTimecard');
export const fetchMilitaryTime = createAction(
  'fetchMilitaryTime/employeeTimecard',
);
export const saveMilitaryTime = createAction(
  'saveMilitaryTime/employeeTimecard',
);
export const storeSelectedDay = createAction(
  'storeSelectedDay/employeeTimecard',
);
export const setTCCopyToOtherDays = createAction(
  'setTCCopyToOtherDays/employeeTimecard',
);

export const fetchSupportData = createAction(
  'fetchSupportData/employeeTimecard',
);

export const fetchOccCodes = createAction('fetchOccCodes/employeeTimecard');
export const storeOccCodes = createAction('storeOccCodes/employeeTimecard');

export const fetchDayTypes = createAction('fetchDayTypes/employeeTimecard');
export const storeDayTypes = createAction('storeDayTypes/employeeTimecard');
export const fetchAllowanceTypes = createAction(
  'fetchAllowanceTypes/employeeTimecard',
);
export const storeAllowanceTypes = createAction(
  'storeAllowanceTypes/employeeTimecard',
);
export const fetchWorkLocations = createAction(
  'fetchWorkLocations/employeeTimecard',
);
export const storeWorkLocations = createAction(
  'storeWorkLocations/employeeTimecard',
);
export const fetchEpisodes = createAction('fetchEpisodes/employeeTimecard');
export const storeEpisodes = createAction('storeEpisodes/employeeTimecard');

export const clearDay = createAction('clearDay/employeeTimecard');

export const updateAdditionalFields = createAction(
  'updateAdditionalFields/employeeTimecard',
);

export const fetchComments = createAction('fetchComments/employeeTimecard');
export const storeComments = createAction('storeComments/employeeTimecard');
export const saveComment = createAction('saveComment/employeeTimecard');

//TC History
export const fetchTimecardHistory = createAction(
  'fetchTimecardHistory/employeeTimecard',
);
export const storeTimecardHistory = createAction(
  'storeTimecardHistory/employeeTimecard',
);

// Delete Timecard
export const deleteTimecard = createAction('deleteTimecard/employeeTimecard');
export const deleteTimecardFromList = createAction(
  'deleteTimecardFromList/employeeTimecard',
);
export const setNewComment = createAction('setNewComment/employeeTimecard');

export const downloadDocument = createAction(
  'downloadDocument/employeeTimecard',
);

export const checkCompatibility = createAction(
  'checkCompatibility/employeeTimecard',
);
export const storeCompatibility = createAction(
  'storeCompatibility/employeeTimecard',
);
//delete allowances
export const deleteTimecardAllowance = createAction(
  'deleteTimecardAllowance/employeeTimecard',
);

export const fetchTimecardList = createAction(
  'fetchTimecardList/employeeTimecard',
);
export const storeTimecardList = createAction(
  'storeTimecardList/employeeTimecard',
);
export const copyFromPrevWeek = createAction(
  'copyFromPrevWeek/employeeTimecard',
);
export const printTimecard = createAction('printTimecard/employeeTimecard');

export const cancelEdit = createAction('cancelEdit/employeeTimecard');
export const selfReject = createAction('selfReject/employeeTimecard');
export const changeWeek = createAction('changeWeek/employeeTimecard');

export const changeDayType = createAction('changeDayType/employeeTimecard');
export const updateDailyAutoAllowances = createAction(
  'updateDailyAutoAllowances/employeeTimecard',
);

export const setSavedOnce = createAction('setSavedOnce/employeeTimecard');
export const checkProjectUser = createAction(
  'checkProjectUser/employeeTimecard',
);
export const setUserLoadingStatus = createAction(
  'setUserLoadingStatus/employeeTimecard',
);
export const setEndsOn = createAction('setEndsOn/employeeTimecard');
export const setDeletedTimecardId = createAction(
  'setDeletedTimecardId/employeeTimecard',
);
export const selectedCommentDay = createAction(
  'selectedCommentDay/employeeTimecard',
);
export const storeRoundTos = createAction('storeRoundTos/employeeTimecard');
