import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyleSheet } from 'shared/theme';
import { Paper, Typography } from '@mui/material';
import Notice from 'components/Shared/Text/Notice';
import { AutoComplete } from 'components/Shared/AutoComplete';
import { REJECTION_WORKFLOW_MAP } from 'components/Shared/constants';

const style = () => ({
  notice: {
    width: '90%',
  },
  root: {},
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > *': {
      minWidth: '50%',
      marginBottom: 8,
      marginTop: 8,
    },
    marginTop: 5,
  },
  fullWidth: {
    '& > *': {
      width: '100%',
    },
  },
  title: {
    fontWeight: 600,
  },
});

class RejectionWorkflow extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    hideLabel: PropTypes.bool,
    hideTitle: PropTypes.bool,
    onSelect: PropTypes.func,
    plain: PropTypes.bool,
    title: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    fullWidth: false,
    hideLabel: false,
    hideTitle: false,
    onSelect: undefined,
    plain: false,
    title: 'Rejection Workflows',
  };

  onSelect(value) {
    if (this.props.onSelect) {
      this.props.onSelect(value);
    }
  }

  renderRejectionWorkflow() {
    const { classes, disabled, fullWidth, hideLabel } = this.props;

    const label = hideLabel ? ' ' : 'Rejection Workflow';
    const rowClass = clsx(classes.row, {
      [classes.fullWidth]: fullWidth,
    });

    return (
      <div className={rowClass}>
        <AutoComplete
          list={REJECTION_WORKFLOW_MAP}
          name="rejectionWorkflow"
          label={label}
          disabled={disabled}
          onChange={(event, value) => this.onSelect(value)}
          required
        />
      </div>
    );
  }

  render() {
    const { classes, disabled, hideTitle, title } = this.props;

    return (
      <Paper elevation={0}>
        {!hideTitle && (
          <Typography gutterBottom className={classes.title}>
            {title}
          </Typography>
        )}
        {disabled && (
          <Notice type="info">
            <Typography color="inherit">
              {"The Rejection Workflow can't be changed until all Batches " +
                'are closed and Timecards are submitted/approved.'}
            </Typography>
          </Notice>
        )}
        {this.renderRejectionWorkflow()}
      </Paper>
    );
  }
}

export default withStyleSheet('RejectionWorkflow', style)(RejectionWorkflow);
