import {
  takeEvery,
  takeLatest,
  call,
  put,
  select,
  all,
  delay,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';
// selectors
import { getProject as currentProject } from 'selectors/routeParams';
import { currentUser, userIsOnlyIA } from 'selectors/session';
import {
  getProjectSearchText,
  getViewingYear,
  getDropDownOptions,
} from 'selectors/project';
import { getSettings } from 'selectors/settings';
import { getPagination } from 'selectors/pagination';
import { getSortParams } from 'selectors/sorting';
import { getInvoice } from 'selectors/digitalEdits';
//actions
import * as actions from 'actions/project';
import { showAlert } from 'actions/alert';
import { changeSize, initialize as enablePagination } from 'actions/pagination';
import { hide as hideModal } from 'actions/modalDialog';
import { navigate } from 'actions/pagination';
//clear data actions
import { reset as resetSearchTimecards } from 'actions/searchTimecards';
import { reset as resetSearchInvoices } from 'feature/SearchInvoices/actions';
import { reset as resetDTS } from 'actions/dts';
import { reset as resetBulkEdit } from 'actions/bulkEdit';
import { reset as resetProjectAllowances } from 'actions/projectAllowances';
import { reset as resetReviews } from 'actions/reviews';
import { reset as resetTimecards } from 'actions/timecards';
import { onUnmountWTC as resetWTC } from 'actions/wtc';
import { reset as resetUsers } from 'actions/users';
import { reset as resetDepartments } from 'actions/departments';
import { reset as resetDigitalEdits } from 'actions/digitalEdits';

//year switch actions
import {
  reset as timecardsReset,
  init as timecardsInit,
} from 'actions/searchTimecards';
import {
  reset as invoiceReset,
  init as invoiceInit,
} from 'feature/SearchInvoices/actions';

import { reset as reviewReset } from 'actions/reviews';

import { fetch as fetchTimecards } from 'actions/timecards';
import { fetchProjectUsers as fetchCrewList } from 'feature/CrewList/actions';

//utils
import {
  pageFromPath,
  getYearFromDbCode,
  delayOnValue,
} from 'utils/helperFunctions';
import { DH, PA, IA } from 'components/props/profiles';
import {
  CURRENT_YEAR,
  LANDING_PAGE_BY_ROLE,
  ProjectTypes,
} from 'components/Shared/constants';

import { START_PROJ_MODAL } from 'containers/Admin/Projects/Start';

let grid = 'admin-projects';
let type = 'projectType';
const buildPayload = ({
  page = 0,
  search = '',
  filters = [],
  pageSize = 100,
  sortBy = [],
}) => {
  // TODO filters
  const payload = {
    page: page,
    pageSize: pageSize,
    search: search,
    entityType: 'project',
    type: 'project',
    filters: filters,
    sortBy: sortBy,
  };
  return payload;
};
export function* fetch(api, debug) {
  try {
    yield put(actions.storeSearchText({ search: null }));
    const activeUser = yield select(currentUser);
    if (!activeUser.isAdmin) {
      grid = 'projects';
    }
    const payload = buildPayload({});
    yield put(
      actions.storeFilterOptions({
        filterName: 'projectType',
        options: ProjectTypes,
      }),
    );
    yield call(fetchProjects, api, debug, payload);
  } catch (e) {
    debug(e);
    yield put(actions.store({ projects: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

export function* fetchFilteredProjects(api, debug) {
  const selFilterOption = yield select(getDropDownOptions(type));
  const filters = selFilterOption.map(filter => {
    const filterObj = {
      field: filter.value,
      values: filter.selected ? ['Y'] : ['Y', 'N'],
    };
    return filterObj;
  });
  const search = yield select(getProjectSearchText);
  const sortParams = yield select(getSortParams(grid));
  let sortBy = [];
  if (sortParams?.orderBy) {
    sortBy.push({ id: sortParams.orderBy, order: sortParams.direction });
  }
  const payload = buildPayload({ search, filters, sortBy });
  yield call(fetchProjects, api, debug, payload);
}

export function* fetchDetails(api, debug, params) {
  try {
    const { currentPath } = params;
    yield put(actions.loading({ loading: true }));
    const project = yield fetchStoreDetails(api);

    //storing project data in session for pendo
    const projectObj = {
      clientName: project?.client?.name || '',
      clientCode: project?.client?.code || '',
      producerName: project?.producer?.name || '',
      producerCode: project?.producer?.code || '',
      dbCode: project?.dbCodeName || '',
    };
    sessionStorage.setItem('pendo_project', JSON.stringify(projectObj));

    yield redirectBasedOnSettings({ currentPath, project });
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeDetails({ project: {} }));
    yield put(showAlert());
    yield put(actions.loading({ loading: false }));
  }
}

//shared logic in initial fetch and yearSwitch
function* fetchStoreDetails(api) {
  const projectId = yield delayOnValue(currentProject);
  const year = yield select(getViewingYear);

  // Only the year version of this should get called repeatedly.  Disabling cache by default on initial project call
  // to prevent stale values coming in when changing settings.
  let project = year
    ? yield call(api.projects.projectYear, { projectId, year })
    : yield call(api.projects.project, { projectId });

  const dbYear = getYearFromDbCode(project?.dbCode);

  const hasYearMisMatch = dbYear && year !== dbYear;

  if (!year || hasYearMisMatch) {
    //set year from dbCode
    yield put(actions.setViewingYear({ viewingYear: dbYear }));
  }

  yield put(actions.storeDetails({ project }));

  return project;
}

export function* redirectBasedOnSettings({ currentPath, project }) {
  // * Please leave currentUser selector after storeDetails
  try {
    const checkList = ['/projects/:projectId/daily-timesheets'];
    const activeUser = yield select(currentUser);
    if (
      [PA, DH].includes(activeUser && activeUser.role) &&
      checkList.includes(currentPath)
    ) {
      const projectId = project && project.id;
      switch (currentPath) {
        case '/projects/:projectId/daily-timesheets':
          if (!project.dtsSettingEnabled) {
            yield put(push(`/projects/${projectId}/me/review/open`));
          }
          break;
        default:
          break;
      }
    }
  } catch (e) {
    throw e;
  }
}

export function* fetchStats(api, debug) {
  try {
    const projectId = yield delayOnValue(currentProject);
    const stats = yield call(api.projects.stats, { projectId });
    yield put(actions.storeStats({ stats }));
  } catch (e) {
    debug(e);
    yield put(actions.storeStats({ stats: {} }));
    yield put(showAlert());
  }
}

export function* toggleHide(api, debug) {
  try {
    const projectId = yield select(currentProject);
    yield call(api.projects.toggleHide, { projectId });
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(actions.fetchDetails());
  }
}

export function* fetchDepartments(api, debug) {
  try {
    const projectId = yield delayOnValue(currentProject);
    const departments = yield call(api.projects.departments, { projectId });
    yield put(actions.storeDepartments({ departments }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

export function* validateStartProject(api, debug) {
  try {
    const projectId = yield select(currentProject);
    yield call(api.projects.validateStartProject, {
      projectId,
      project: {},
    });

    yield put(actions.startProjectResult({}));
    yield put(actions.validatingStart({ validating: false }));
  } catch (e) {
    yield put(actions.startProjectResult({ result: e.data }));
    yield put(actions.validatingStart({ validating: false }));
    debug(e);
    if (e?.status !== 422) yield put(showAlert());
  }
}

export function* startProject(api, debug) {
  try {
    const projectId = yield select(currentProject);
    const settings = yield select(getSettings);
    const doesEditOptionExists = settings.hasOwnProperty('editOptions');
    const doestimeZoneExists = settings.hasOwnProperty('timeZone');
    let defaultSettings;
    if (doesEditOptionExists && doestimeZoneExists) {
      defaultSettings = settings;
    } else {
      defaultSettings = {
        ...settings,
        editOptions: 'CanEdit',
        timeZone: 'Pacific Standard Time',
      };
    }
    yield call(api.projects.startProject, {
      projectId,
      defaultSettings,
    });
    yield put(actions.startProjectResult({}));
    yield put(hideModal({ dialog: START_PROJ_MODAL }));
    yield put(actions.fetchDetails());
    yield delay(2000);
    yield put(fetchCrewList());
  } catch (e) {
    yield put(actions.startProjectResult({ result: e.data }));
    debug(e);
    yield put(showAlert());
  }
}

export function* fetchProjectUsers(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    yield put(actions.loadingProjectUsers({ loading: true }));
    const projectId = yield delayOnValue(currentProject);
    const { departmentId } = params;
    const users = yield call(api.projects.projectUsers, {
      projectId,
      departmentId,
    });
    yield put(actions.storeProjectUsers({ users }));
    yield put(actions.loading({ loading: false }));
    yield put(actions.loadingProjectUsers({ loading: false }));
  } catch (e) {
    yield put(actions.loading({ loading: false }));
    yield put(actions.loadingProjectUsers({ loading: false }));
    yield put(actions.storeProjectUsers({ users: [] }));
    debug(e);
    yield put(showAlert());
  }
}

export function* fetchProjectDepartments(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield delayOnValue(currentProject);
    const { departmentId } = params;
    const department = yield call(api.projects.projectDepartment, {
      projectId,
      departmentId,
    });
    yield put(actions.storeProjectDepartments({ department }));
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    yield put(actions.storeProjectDepartments({ departments: e.data }));
    debug(e);
    yield put(showAlert());
  }
}

export function* getProjectYear() {
  let year = yield select(getViewingYear);
  let count = 0;
  while (!year && count < 10) {
    yield delay(100);
    year = yield select(getViewingYear);
    count++;
  }

  //default value, hopefully never used
  if (count >= 10) year = CURRENT_YEAR;

  return year;
}

function* switchYear(api, debug, params) {
  const projectId = yield select(currentProject);

  yield fetchStoreDetails(api);
  const location = params.location;

  //clear existing project data for pages
  yield put(timecardsReset());
  yield put(invoiceReset());
  yield put(reviewReset());

  const currPage = pageFromPath(location.pathname);
  switch (currPage) {
    case 'timecards':
      yield put(timecardsInit());
      break;
    case 'invoice':
      yield put(invoiceInit());
      break;
    case 'myTimecards':
      yield put(fetchTimecards());
      break;
    case 'reports':
      yield put(push(`/projects/${projectId}/reports/ondemand`));
      break;
    case 'digitalEdits':
      const viewingYear = yield select(getViewingYear);
      const invoice = yield select(getInvoice);
      let dbYear = null;
      let url = `/projects/${projectId}/search-invoices`;
      if (invoice?.dbCode?.code) {
        dbYear = getYearFromDbCode(invoice?.dbCode?.code);
      }
      if (
        viewingYear === dbYear &&
        location.pathname.includes('digital-edits')
      ) {
        url = location.pathname;
      }
      yield put(push(url));
      break;
    default:
      //redirect to base project,
      // will redirect to search or myTimecard based on user role
      yield put(push(`/projects/${projectId}`));
      break;
  }
}

function* navigateTo(api, debug, params) {
  try {
    const { projectId } = params;
    const activeUser = yield call(api.projects.userInfo, { projectId });
    //active user will get selected project roles
    const role = userIsOnlyIA(activeUser) ? IA : activeUser.role;
    const toURL = LANDING_PAGE_BY_ROLE(role, projectId);
    yield put(push(toURL));
  } catch (e) {
    debug(e);
  }
}
function* fetchPaginationProjects(api, debug, params) {
  const { page = 0, pageSize = 100 } = params;
  const search = yield select(getProjectSearchText);
  let sortParams = yield select(getSortParams(grid));
  let sortBy = [];
  if (sortParams?.orderBy) {
    sortBy.push({ id: sortParams.orderBy, order: sortParams.direction });
  }
  const selFilterOption = yield select(getDropDownOptions(type));
  const filters = selFilterOption.map(filter => {
    const filterObj = {
      Field: filter.value,
      values: filter.selected ? ['Y'] : ['Y', 'N'],
    };
    return filterObj;
  });
  const payload = buildPayload({ page, search, filters, pageSize, sortBy });
  yield call(fetchProjects, api, debug, payload);
}

function* fetchProjects(api, debug, payload) {
  try {
    const { page, pageSize } = payload;
    const projectsResp = yield call(api.projects.list, {
      payload,
    });
    yield put(actions.store({ projects: [] }));
    let projects = projectsResp?.item2 || [];

    const activeUser = yield select(currentUser);
    if (!activeUser.isAdmin) {
      projects = projects.filter(
        project =>
          project.status === 'C' ||
          project.status === 'A' ||
          project.status === 'Z',
      );
    }
    yield put(actions.store({ projects }));
    yield put(
      actions.storeProjectsCount({ totalCount: projectsResp?.item1 || 0 }),
    );
    yield put(
      enablePagination({
        grid,
        totalCount: projectsResp?.item1 || 0,
      }),
    );
    yield put(changeSize({ grid, pageSize }));
    yield put(navigate({ grid, page }));
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.store({ projects: [] }));
    yield put(actions.storeProjectsCount({ totalCount: 0 }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}
function* projectSorting(api, debug, params) {
  const pagination = yield select(getPagination(grid));
  const { pageSize } = pagination;
  const selFilterOption = yield select(getDropDownOptions(type));
  const filters = selFilterOption.map(filter => {
    const filterObj = {
      Field: filter.value,
      values: filter.selected ? ['Y'] : ['Y', 'N'],
    };
    return filterObj;
  });

  const { orderBy, direction } = params;
  const sortBy = [
    {
      id: orderBy,
      order: direction,
    },
  ];
  const search = yield select(getProjectSearchText);
  const payload = buildPayload({ search, filters, pageSize, sortBy });
  yield call(fetchProjects, api, debug, payload);
}

//search
function* fetchProjectSearch(api, debug, params) {
  const { search } = params;

  yield put(actions.storeSearchText({ search }));
  const pagination = yield select(getPagination(grid));
  const { pageSize } = pagination;
  let sortParams = yield select(getSortParams(grid));
  let sortBy = [];
  if (sortParams?.orderBy) {
    sortBy.push({ id: sortParams.orderBy, order: sortParams.direction });
  }
  let filters = [];
  const selFilterOption = yield select(getDropDownOptions(type));
  filters = selFilterOption.map(filter => {
    const filterObj = {
      Field: filter.value,
      values: filter.selected ? ['Y'] : ['Y', 'N'],
    };
    return filterObj;
  });

  const payload = buildPayload({ search, filters, pageSize, sortBy });
  yield call(fetchProjects, api, debug, payload);
}

export function* clearProjectData(api, debug, params) {
  try {
    yield all([
      put(resetSearchTimecards()),
      put(resetSearchInvoices()),
      put(resetDTS()),
      put(resetBulkEdit()),
      put(resetProjectAllowances()),
      put(resetReviews()),
      put(resetTimecards()),
      put(resetWTC()),
      put(resetUsers()),
      put(resetDepartments()),
      put(resetDigitalEdits()),
    ]);
  } catch (e) {
    debug(e);
  } finally {
  }
}

export default function* projectsFlow({ api, debug }) {
  yield all([
    takeEvery(`${actions.fetch}`, fetch, api, debug),
    takeEvery(`${actions.fetchStats}`, fetchStats, api, debug),
    takeEvery(`${actions.fetchDetails}`, fetchDetails, api, debug),
    takeEvery(`${actions.toggleHide}`, toggleHide, api, debug),
    takeEvery(`${actions.fetchDepartments}`, fetchDepartments, api, debug),
    takeEvery(`${actions.startProject}`, startProject, api, debug),
    takeEvery(
      `${actions.validateStartProject}`,
      validateStartProject,
      api,
      debug,
    ),
    takeEvery(`${actions.fetchProjectUsers}`, fetchProjectUsers, api, debug),
    takeEvery(
      `${actions.fetchProjectDepartments}`,
      fetchProjectDepartments,
      api,
      debug,
    ),
    takeLatest(`${actions.switchYear}`, switchYear, api, debug),
    takeLatest(`${actions.navigateTo}`, navigateTo, api, debug),
    takeLatest(
      `${actions.fetchPaginationProjects}`,
      fetchPaginationProjects,
      api,
      debug,
    ),
    takeLatest(`${actions.fetchProjectSearch}`, fetchProjectSearch, api, debug),
    takeLatest(`${actions.projectSorting}`, projectSorting, api, debug),
    takeLatest(
      `${actions.fetchFilteredProjects}`,
      fetchFilteredProjects,
      api,
      debug,
    ),
    takeLatest(`${actions.clearProjectData}`, clearProjectData, api, debug),
  ]);
}
