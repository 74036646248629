import React from 'react';
import Invitations from 'containers/Employees/Profiles/Invitations';
import { EnhancedRoute } from 'containers/RoutingSupport';
import { MaxWidthLayout } from 'containers/Layout';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    authenticated
    key="/invitations/:token"
    path="/invitations/:token"
    layout={MaxWidthLayout}
    component={Invitations}
  />
);
