import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { TextField } from 'components/Shared/redux';

import { ConfirmModal } from 'components/Shared/Modals';
// actions
import { reviewApprovalFlow } from 'actions/reviews';
import { hide as hideModal } from 'actions/modalDialog';
import { INPUT_FIELD_CHARACTER_LIMIT } from 'components/Shared/constants';
const commentsLimit = INPUT_FIELD_CHARACTER_LIMIT.comments;

const formName = 'RejectTimecard';
const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: {
      timecardEntryHeaderId: ownProps.timecardEntryHeaderId,
    },
  };
};

const onSubmitSuccess = (_result, dispatch) => {
  setTimeout(() => dispatch(hideModal({ dialog: 'RejectTimecard' })), 1000);
};
const onSubmit = (values, dispatch, props) => {
  const { isTimecardDirty } = props;

  dispatch(
    reviewApprovalFlow({
      ...values,
      status: 'rejected',
      isTimecardDirty,
      formName,
    }),
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    maxWidth: 500,
  },
  commentField: {
    width: '95%',
  },
}));

// Crew timecard - employee reject modal
// DH view timecard reject modal
// possibly others as well
function RejectTimecard({
  handleSubmit,
  submitting,
  timecardEntryHeaderId,
  ...others
}) {
  const classes = useStyles();

  const content = (
    <div className={classes.content}>
      <Typography color="inherit">
        {'Please add rejection comment below'}
      </Typography>
      <br />
      <Field
        className={classes.commentField}
        component={TextField}
        name="comment"
        inputProps={{ maxLength: commentsLimit, autoFocus: true }}
        label="Comment"
        required
        multiline
      />
      <Field
        component="input"
        name="timecardEntryHeaderId"
        type="hidden"
        value={timecardEntryHeaderId}
      />
    </div>
  );

  return (
    <form onSubmit={handleSubmit}>
      <ConfirmModal
        content={content}
        title={'Required: Add a Rejection Comment'}
        buttonText="Submit"
        buttonType="primary"
        submitDisabled={submitting}
        raisedButton
        {...others}
      />
    </form>
  );
}

export default compose(
  withModalDialog({ dialog: 'RejectTimecard', maxWidth: 'md' }),
  connect(mapStateToProps),
  reduxForm({
    form: formName,
    onSubmit,
    onSubmitSuccess,
  }),
)(RejectTimecard);
