import _ from 'lodash';

import { REQUIRED, NOT_UNIQUE } from 'utils/messages';
import { formatMessage } from 'utils/messages';

let _formErrors, _addError;

export const getErrorText = (errorObj, fieldName) => {
  const retval =
    errorObj && errorObj[fieldName] ? _.startCase(errorObj[fieldName]) : null;

  return retval;
};

function initValidation() {
  _formErrors = { valid: true };
  _addError = addErrorFactory(_formErrors);
}

function addErrorFactory(formErrors) {
  return function addErrorFunc(fieldName, message, index = -1, subField) {
    formErrors.valid = false;
    if (index === -1) {
      formErrors[fieldName] = message;
    } else {
      if (formErrors[fieldName]) {
        const errObj = {};
        errObj[subField] = message;
        formErrors[fieldName][index] = errObj;
      } else {
        const errArr = [];
        const errObj = {};
        errObj[subField] = message;
        errArr[index] = errObj;
        formErrors[fieldName] = errArr;
      }
    }
  };
}

function validateDeptNames(values) {
  const { masterId, departmentId, name } = values;

  //Only check for new departments
  if (!masterId && departmentId === null) {
    _addError('masterId', formatMessage(REQUIRED));
  }

  if (name === '') {
    _addError('name', formatMessage(REQUIRED));
  }
}
function validateRejectionFlow(values) {
  const { rejectionWorkflow } = values;

  if (!rejectionWorkflow) {
    _addError('rejectionWorkflow', formatMessage(REQUIRED));
  }
}

function validateDepartmentHeads(values) {
  const { heads, headApprovalEnabled } = values;

  if (Array.isArray(heads) && headApprovalEnabled) {
    const hash = {};

    if (heads.length === 0 || Object.keys(heads[0]).length === 0) {
      _addError(`heads`, formatMessage(REQUIRED), 0, 'id');
    }

    heads.forEach((head, index) => {
      if (!head.id) {
        _addError(`heads`, formatMessage(REQUIRED), index, 'id');
      }

      if (head && hash[head.id] === undefined) {
        hash[head.id] = true;
      } else {
        _addError(`heads`, formatMessage(NOT_UNIQUE), index, 'id');
      }
    });
  }
}

export const departmentSettingsValidate = (values, formProps) => {
  initValidation();
  if (!values) return _formErrors;

  validateDeptNames(values);

  validateRejectionFlow(values);

  validateDepartmentHeads(values);

  return _formErrors;
};

function validateEditReviewers(values) {
  if (values.reviewers) {
    const hash = {};
    values.reviewers.forEach((reviewer, index) => {
      if (!reviewer.id) {
        _addError(`reviewers`, formatMessage(REQUIRED), index, 'id');
      }

      if (hash[reviewer.id] === undefined) {
        hash[reviewer.id] = true;
      } else {
        _addError(`reviewers`, formatMessage(NOT_UNIQUE), index, 'id');
      }
    });
  }
}

export const editReviewersValidate = (values, formProps) => {
  initValidation();
  if (!values) return _formErrors;

  validateEditReviewers(values);

  return _formErrors;
};

export const getFilteredUsers = (approvalFlow, approvers, users) => {
  let idsToFilter = [];

  if (approvalFlow.role === 'upm') {
    idsToFilter.push(...approvers.deptHeads, ...approvers.payAccts);
    if (approvalFlow.position === 2) {
      idsToFilter.push(...approvers.upm2s, ...approvers.upm3s);
    } else if (approvalFlow.position === 3) {
      idsToFilter.push(...approvers.upm1s, ...approvers.upm3s);
    } else if (approvalFlow.position === 4) {
      idsToFilter.push(...approvers.upm1s, ...approvers.upm2s);
    }
  }
  if (approvalFlow.role === 'payroll_accountant') {
    idsToFilter = [
      ...approvers.deptHeads,
      ...approvers.upm1s,
      ...approvers.upm2s,
      ...approvers.upm3s,
    ];
  }

  let filteredUsers = users.filter(user => {
    return !idsToFilter.includes(user.id);
  });
  return filteredUsers;
};
