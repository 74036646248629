import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'decorators/withRouter';
import { push } from 'redux-first-history';
import LinearProgress from '@mui/material/LinearProgress';
// components
import { timecardProps } from 'components/props/timecard';
import { ExistingTimecard as Component } from 'components/Employees/Timecards/Modal';
import {
  TIMECARD_HISTORY_STATUS,
  TIMECARD_PENDING_EMP_REVIEW,
  URL_MY_TIMECARD_HISTORY,
  URL_MY_TIMECARD_FINISH,
  URL_MY_TIMECARD_REVIEW,
} from 'components/Shared/constants/index';

// actions
import { fetchDetails } from 'actions/timecards';
// selectors
import { getDetails, getLoadingDetails } from 'selectors/timecard/common';
import { getProject } from 'selectors/routeParams';

const mapStateToProps = (state, { timecardId }) => ({
  timecard: getDetails(state, timecardId),
  loading: getLoadingDetails(state),
  projectId: getProject(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetchData: () => {
    const { timecardId } = ownProps;
    dispatch(fetchDetails({ timecardId }));
  },
  navigateTo: (projectId, timecard) => {
    const { status, activeApprovalFlowId, id } = timecard;

    let toURL = URL_MY_TIMECARD_FINISH(projectId, id);
    if (TIMECARD_PENDING_EMP_REVIEW === status) {
      toURL = URL_MY_TIMECARD_REVIEW(projectId, id, activeApprovalFlowId);
    } else if (TIMECARD_HISTORY_STATUS.indexOf(status) >= 0) {
      toURL = URL_MY_TIMECARD_HISTORY(projectId, id);
    }

    dispatch(push(toURL));
  },
});

class ExistingTimecard extends React.Component {
  static propTypes = {
    timecard: timecardProps.isRequired,
    timecardId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onFetchData: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    weekEnding: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.props.onFetchData();
  }

  render() {
    if (this.props.loading) return <LinearProgress style={{ marginTop: 8 }} />;
    const { timecard, projectId, navigateTo, weekEnding } = this.props;
    return (
      <Component
        timecard={timecard}
        weekEnding={weekEnding}
        onNavigate={() => navigateTo(projectId, timecard)}
      />
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExistingTimecard),
);
