const textfield = {
  MuiTextField: {
    styleOverrides: {},
    defaultProps: {
      variant: 'standard',
    },
  },
};

export default textfield;
