import React from 'react';
import {
  FullWidthLayout,
  Header,
  AlertHeader,
  ProjectHeader,
} from 'containers/Layout';

import { EnhancedRoute } from 'containers/RoutingSupport';
import { SearchTimecards } from 'containers/Employees/Reviews';
// import { SearchTimecards as SubNav } from 'containers/Nav/Pages'; //add subnav search bar down the road??

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <EnhancedRoute
      exact
      authenticated
      key="/projects/reviews/search-timecards"
      id="/projects/reviews/search-timecards"
      path="/projects/:projectId/review/search-timecards"
      layout={FullWidthLayout}
      headers={[Header, ProjectHeader, AlertHeader]}
      component={SearchTimecards}
      isLoadProjectActiveUser={true}
      title="Timecards"
    />
  );
};
