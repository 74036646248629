import { buildUrl } from '../api';

const URL_SEARCH_INVOICES = '/projects/:projectId/invoices/search';
const URL_SEARCH_INVOICES_FILTER = URL_SEARCH_INVOICES + '/filter';
const URL_FILTER_COUNT = URL_SEARCH_INVOICES + '/status/year/:year/count';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async getData({ projectId, dataParams }) {
    const url = buildUrl(URL_SEARCH_INVOICES, { projectId });
    const rsp = await clientV2.post(url, dataParams);
    return rsp.data;
  },
  async filter({ projectId, filterParams }) {
    const filterType = filterParams.type;
    const filterName = filterType.replace(/invoice/, '');
    const url = buildUrl(URL_SEARCH_INVOICES_FILTER + `?${filterName}`, {
      projectId,
    });
    const rsp = await clientV2.post(url, filterParams);
    return rsp.data;
  },
  async getFilterBadgesCount({ projectId, year }) {
    const url = buildUrl(URL_FILTER_COUNT, { projectId, year });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
});
