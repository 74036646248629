import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { InputAdornment, TextField } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  DealTextField: {
    marginBottom: 4,
    '& * > input': {
      textAlign: 'right',
    },
  },
  errorTextField: {
    backgroundColor: palette.misc.errorBackground,
  },
}));

const DealTextField = props => {
  const classes = useStyles();
  const {
    input: { value, name },
    meta: { error },
    disabled,
    launchDealMemoModal,
  } = props;

  const textVal = value?.code || '';

  return (
    <TextField
      id={`auto-complete-for-${name}`}
      className={clsx(classes.DealTextField, {
        [classes.errorTextField]: !!error,
      })}
      disabled={disabled}
      value={textVal}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <ArrowDropDownIcon />
          </InputAdornment>
        ),
      }}
      onKeyDown={e => {
        if (
          e.key === 'ArrowDown' ||
          e.key === 'ArrowUp' ||
          e.key === ' ' ||
          /^[a-z0-9]$/i.test(e.key)
        ) {
          launchDealMemoModal(e);
        }
      }}
    />
  );
};

DealTextField.propTypes = {
  launchDealMemoModal: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default DealTextField;
