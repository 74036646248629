import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { reduxForm } from 'redux-form';

//components
import ESignatureComponent from 'components/Admin/Projects/Settings/ESignature';

//selectors
import { getInitialSettings } from 'selectors/project';
import { updateESignature } from 'actions/settings';

const formName = 'ESignature';

const mapStateToProps = state => ({
  initialValues: getInitialSettings(state, formName),
});

const onSubmit = (values, dispatch) => {
  dispatch(updateESignature());
};

class ESignature extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <ESignatureComponent {...this.props} />
      </form>
    );
  }
}

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnChange: true,
    onSubmit,
  }),
)(ESignature);
