import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import * as DOMPurify from 'dompurify';
import { generateHTML } from '@tiptap/html';

import {
  sharedStyles,
  tryParseJSONObject,
  makeExtensions,
} from 'components/RichText/RichText';

const useStyles = makeStyles(({ palette }) => ({
  RichTextDisplay: {},
  ...sharedStyles,
}));

const RichTextDisplay = props => {
  const classes = useStyles();
  const { commentText = '', ...rest } = props;

  const safeHtml = useMemo(() => {
    const json = tryParseJSONObject(commentText);
    let returnHtml;
    if (json) {
      returnHtml = generateHTML(JSON.parse(commentText), [
        ...makeExtensions({ classes }),
      ]);
    } else {
      returnHtml = commentText;
    }

    return DOMPurify.sanitize(returnHtml);
  }, [classes, commentText]);

  return <div {...rest} dangerouslySetInnerHTML={{ __html: safeHtml }} />;
};

RichTextDisplay.propTypes = { commentText: PropTypes.string };

export default RichTextDisplay;
