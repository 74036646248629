import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Typography } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
// actions
import { remove } from 'actions/reviewFlows';
// selectors
import { getEditing } from 'selectors/reviewFlows';

const mapStateToProps = state => ({
  initialValues: getEditing(state),
});

const mapDispatchToProps = dispatch => ({});

const onSubmit = (values, dispatch) => {
  dispatch(remove({ reviewFlowId: values.id }));
};

const style = () => ({
  content: {
    maxWidth: 500,
  },
});

class RemoveApprovalFlow extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reviewFlowId: PropTypes.string,
  };

  static defaultProps = {
    reviewFlowId: '',
    stepId: '',
    userIds: [],
  };

  render() {
    const { classes, handleSubmit, reviewFlowId } = this.props;
    const content = (
      <div className={classes.content}>
        <Typography color="inherit">
          {'Please be careful, this action can not be undone. ' +
            'All the departments in this approval flow will be left without one.'}
        </Typography>
      </div>
    );
    const title = (
      <Typography variant="h6" color="inherit">
        {'Delete Review Flow'}
      </Typography>
    );

    return (
      <form onSubmit={handleSubmit}>
        <Field
          component="input"
          name="reviewFlowId"
          type="hidden"
          value={reviewFlowId}
        />
        <ConfirmModal
          content={content}
          title={title}
          buttonText="Remove"
          buttonType="secondary"
          raisedButton={true}
          {...this.props}
        />
      </form>
    );
  }
}

export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: 'removeApprovalFlow', maxWidth: 'md' }),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'RemoveApprovalFlow',
    onSubmit,
  }),
)(RemoveApprovalFlow);
