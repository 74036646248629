import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { FaIdCard } from 'react-icons/fa';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChatBubbleSharpIcon from '@mui/icons-material/ChatBubbleSharp';
import NavToWtcIcon from './NavToWtcIcon';

import {
  TableCell,
  TableRow,
  IconButton,
  Checkbox,
  Tooltip,
  Hidden,
  Badge,
  ClickAwayListener,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { BY_ROLE } from 'components/Shared/constants';
import { UPM, DH } from 'components/props/profiles';

import { TimecardDetail } from 'containers/Employees/Reviews/Reviewer';
import { getApproverList } from '../ApproverList';
import {
  EmergencyIcon,
  ForceResubmitIcon,
} from 'containers/Employees/Reviews/Shared/timecardUtils';
const formatAmt = amt =>
  amt.toLocaleString('US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
    paddingTop: 20,
  },
  tableHead: {
    paddingLeft: 20,
    paddingRight: 20,
    '& > *': {
      fontWeight: 700,
      fontSize: '0.8rem',
      textTransform: 'uppercase',
    },
  },
  iconBtn: {
    color: palette.primary.main,
    backgroundColor: palette.primary.background,
    borderRadius: 15,
    margin: 8,
  },
  chatBtn: {
    color: palette.primary.main,
    margin: 8,
  },
  noBorder: {
    border: 0,
  },
  tableCell: {},
  actionCell: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  smTableCell: {
    padding: 10,
  },
  skinnyBox: {
    padding: 3,
  },
  upmActions: {
    display: 'flex',
  },
  reviewLink: {
    color: palette.misc.link,
    cursor: 'pointer',
    textAlign: 'center',
  },
  mobileTableSpace: {
    minHeight: 20,
    marginTop: 15,
  },
  mobileTableSpaceForImage: {
    minHeight: 10,
    marginTop: 15,
  },
  mobileCutOffText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '80%',
  },
}));

export function getTimecardInfo(timecard) {
  const occupation = timecard.dealMemo && timecard.dealMemo.occupationCode;
  const brokenData = timecard.brokenData || {};
  let totalHours = brokenData.totalHours;
  let totalAllowances = brokenData.totalAllowances;
  let totalGrossWage = brokenData.totalGrossWage;
  const occupationLabel = occupation
    ? occupation.code + ' - ' + occupation.description
    : 'Pending';

  totalHours = totalHours ? formatAmt(totalHours) : '';
  totalAllowances = totalAllowances ? formatAmt(totalAllowances) : formatAmt(0);
  totalGrossWage = totalGrossWage ? formatAmt(totalGrossWage) : '';

  return {
    occupationLabel,
    totalHours,
    totalAllowances,
    totalGrossWage,
  };
}

function TimecardRow(props) {
  const {
    record,
    readOnly = false,
    enableGross = false,
    canSubmitAsRejected = false,
    reviewTimecard,
    onSelect,
    isSelected = false,
    role,
    showComment,
    showApprovers,
    batch,
  } = props;

  const { timecard, comments, currentApprovers } = record;

  const timecardInfo = getTimecardInfo(record);
  const classes = useStyles();
  const [showTimecard, toggleShowTimecard] = React.useState(false);
  const [showMobileToolTip, setShowMobileTooltip] = React.useState(false);

  const lowerCastStatus = (record?.status || '').toLowerCase();
  const isStatusPaid =
    lowerCastStatus === 'paid' || lowerCastStatus === 'processed';

  const showCommentIcon = role === UPM && (!isStatusPaid || comments > 0);

  function renderTimecardDetails() {
    if (showTimecard) {
      let colSpan = 12;
      if (readOnly) colSpan++;
      if (enableGross) colSpan++;
      return (
        <TableRow key={`rowDetail-${timecard.id}`}>
          <TableCell colSpan={colSpan}>
            <TimecardDetail timecardId={record.timecardId} />
          </TableCell>
        </TableRow>
      );
    }
    return null;
  }

  const tableCellCls = clsx(classes.tableCell, {
    [classes.noBorder]: showTimecard,
  });

  const statusMap = BY_ROLE[role];

  const tooltipForNullValue = (
    <Tooltip
      arrow
      placement="top"
      title="Totals will display once the timecard has been approved by the payroll accountant"
    >
      <span>--</span>
    </Tooltip>
  );

  const handleOpenToolTip = () => {
    setShowMobileTooltip(true);
    setTimeout(() => setShowMobileTooltip(false), 8000);
  };

  const handleCloseTooltip = () => {
    setShowMobileTooltip(false);
  };

  const mobileTooltipForNullValue = (
    <ClickAwayListener onClickAway={handleCloseTooltip}>
      <Tooltip
        arrow
        placement="top"
        title="Totals will display once the timecard has been approved by the payroll accountant"
        open={showMobileToolTip}
        disableFocusListener
        disableHoverListener
      >
        <span onClick={handleOpenToolTip}> --</span>
      </Tooltip>
    </ClickAwayListener>
  );

  return [
    <TableRow key={`row-${timecard.id}`}>
      {/* Responsive Desktop version */}
      <Hidden mdDown>
        <TableCell className={tableCellCls} align="center" width={'3%'}>
          {record.emergencyType ? (
            <EmergencyIcon />
          ) : record.workflowAction === 'resubmitToEmployee' ? (
            <ForceResubmitIcon />
          ) : (
            ''
          )}
        </TableCell>
        <TableCell padding="checkbox">
          {(!readOnly || canSubmitAsRejected) && record.status !== 'draft' && (
            <Checkbox
              checked={isSelected}
              inputProps={{ 'aria-labelledby': 'checkbox' + timecard.id }}
              onChange={onSelect}
              color="primary"
            />
          )}
        </TableCell>
        <TableCell className={tableCellCls}>
          {record.userAccountCode || '--'}
        </TableCell>
        <TableCell className={tableCellCls}>
          {timecard.user.fullName || '--'}
        </TableCell>
        <TableCell className={tableCellCls}>
          {_.isEmpty(record.occupationCode)
            ? '--'
            : `${record.occupationCode.code} - ${record.occupationCode.name}`}
        </TableCell>
        <TableCell className={tableCellCls}>
          {record.departmentName || ' -- '}
        </TableCell>
        {readOnly && (
          <TableCell className={tableCellCls}>
            {statusMap && statusMap.label
              ? statusMap.label[record.status]
              : '--'}
          </TableCell>
        )}
        {showApprovers && (
          <TableCell
            className={tableCellCls}
            align="left"
            style={{ paddingLeft: 0 }}
          >
            {getApproverList(currentApprovers)}
          </TableCell>
        )}
        <TableCell className={tableCellCls} align="center">
          {timecardInfo.totalAllowances}
        </TableCell>
        <TableCell className={tableCellCls} align="center">
          {timecardInfo.totalHours || tooltipForNullValue}
        </TableCell>
        {enableGross && (
          <TableCell className={tableCellCls} align="center">
            {timecardInfo.totalGrossWage || tooltipForNullValue}
          </TableCell>
        )}
        <TableCell className={tableCellCls} align="center">
          <div className={classes.actionCell}>
            {showCommentIcon && (
              <Tooltip title="Show Comment">
                <IconButton
                  onClick={showComment}
                  aria-label="Show Comment"
                  size="small"
                  className={clsx(
                    'pendo_DH-UPM_show_comments',
                    classes.chatBtn,
                  )}
                >
                  {comments > 0 ? (
                    <Badge color="error" variant="dot">
                      <ChatBubbleSharpIcon />
                    </Badge>
                  ) : (
                    <ChatBubbleSharpIcon />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {role === DH && (
              <Tooltip title="Review Timecard">
                <IconButton
                  onClick={reviewTimecard}
                  aria-label="Review Timecard"
                  size="small"
                  className={clsx('pendo_DH_review_timecard', classes.iconBtn)}
                >
                  <FaIdCard />
                </IconButton>
              </Tooltip>
            )}
            {role === UPM && (
              <div className={classes.upmActions}>
                <NavToWtcIcon
                  timecard={timecard}
                  batch={batch}
                  readOnly={readOnly}
                />
                <Tooltip title="Show Timecard">
                  <IconButton
                    onClick={() => toggleShowTimecard(!showTimecard)}
                    aria-label="Show Timecard"
                    size="small"
                    className={clsx(
                      'pendo_UPM_expand_timecard',
                      classes.iconBtn,
                    )}
                  >
                    <ExpandMore />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        </TableCell>
      </Hidden>

      {/* Responsive: Mobile Version */}
      <Hidden mdUp>
        <TableCell className={classes.smTableCell}>
          {' '}
          <div>
            {(!readOnly || canSubmitAsRejected) && (
              <Checkbox
                className={classes.skinnyBox}
                checked={isSelected}
                inputProps={{ 'aria-labelledby': 'checkbox' + timecard.id }}
                onChange={onSelect}
                color="primary"
              />
            )}
          </div>
        </TableCell>
        <TableCell className={classes.smTableCell} align="right">
          <div className={classes.mobileTableSpaceForImage}></div>
          <div>Account:</div>
          <div>Name:</div>
          <div>Occupation:</div>
          <div>Department:</div>
          <div>Allowances:</div>
          {readOnly && <div> Status:</div>}
          <div> Total Hours:</div>
          {enableGross && <div> Gross:</div>}
          <div className={classes.mobileTableSpace}></div>
        </TableCell>
        <TableCell className={classes.smTableCell}>
          <div className={classes.mobileCutOffText}>
            {record.emergencyType ? (
              <EmergencyIcon />
            ) : record.workflowAction === 'resubmitToEmployee' ? (
              <ForceResubmitIcon />
            ) : (
              '--'
            )}
          </div>
          <div className={classes.mobileCutOffText}>
            {record.userAccountCode || ' -- '}
          </div>
          <div className={classes.mobileCutOffText}>
            {timecard.user.fullName || ' -- '}
          </div>
          <div className={classes.mobileCutOffText}>
            {_.isEmpty(record.occupationCode)
              ? '--'
              : `${record.occupationCode.code} - ${record.occupationCode.name}
            `}
          </div>
          <div className={classes.mobileCutOffText}>
            {record.departmentName || ' -- '}
          </div>
          <div className={classes.mobileCutOffText}>
            {timecardInfo.totalAllowances || ' -- '}
          </div>
          {readOnly && (
            <div>
              {statusMap && statusMap.label
                ? statusMap.label[record.status]
                : '--'}
            </div>
          )}
          <div className={classes.mobileCutOffText}>
            {' '}
            {timecardInfo.totalHours || mobileTooltipForNullValue}
          </div>
          {enableGross && (
            <div className={classes.mobileCutOffText}>
              {timecardInfo.totalGrossWage || mobileTooltipForNullValue}
            </div>
          )}
          <div
            className={clsx(classes.reviewLink, classes.mobileTableSpace)}
            onClick={reviewTimecard}
            aria-label="View Timecard"
          >
            View Timecard
          </div>
        </TableCell>
      </Hidden>
    </TableRow>,
    renderTimecardDetails(),
  ];
}

export default TimecardRow;
