import React from 'react';
import clsx from 'clsx';
import { Badge } from '@mui/material/';

import makeStyles from '@mui/styles/makeStyles';

//components
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import GroupsIcon from '@mui/icons-material/Groups';
import Filters from './Filters';

//utils + constants
import {} from './searchUtils';

const useStyles = makeStyles(({ palette }) => {
  return {
    root: {
      height: '100%',
      marginLeft: -18,
    },
    content: {
      transition: 'width 0.3s;',
    },
    sidebarClosed: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 50,
      textAlign: 'center',
      alignItems: 'center',
      backgroundColor: palette.primary.background,
      height: '100%',
      cursor: 'pointer',
    },
    expandedWidth: { width: 350 },
    hiddenWidth: { width: 94 },
    iconCircle: {
      position: 'relative',
      width: '32px',
      height: '32px',
      backgroundColor: palette.primary.background,
      borderRadius: ' 50%',
      boxShadow: `1px 0px 3px  -1px ${palette.primary.main}`,
      cursor: 'pointer',
      top: '40%',
    },
    iconCircleExpanded: {
      left: 290,
    },
    iconCircleCollapsed: {
      left: '60%',
    },
    icon: {
      position: 'absolute',
      top: 4,
      left: 4,
    },
    iconRotated: {
      transform: 'rotate(180deg)',
    },
    refineCollapsed: {
      color: palette.primary.main,
    },
    sidebarBackGround: {
      height: '79vh',
      marginRight: 38,
    },
  };
});

const SearchSidebar = props => {
  const { onClearFilters, showFilters, setShowFilters } = props;
  const classes = useStyles();

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <div className={clsx(classes.root)}>
      <section
        className={clsx(classes.iconCircle, {
          [classes.iconCircleExpanded]: showFilters,
          [classes.iconCircleCollapsed]: !showFilters,
        })}
        onClick={toggleFilters}
      >
        <KeyboardDoubleArrowLeftIcon
          color="primary"
          className={clsx(classes.icon, {
            [classes.iconRotated]: !showFilters,
          })}
        />
      </section>
      <section
        className={clsx(classes.content, {
          [classes.expandedWidth]: showFilters,
          [classes.hiddenWidth]: !showFilters,
          [classes.sidebarBackGround]: !showFilters,
        })}
      >
        {showFilters ? (
          <Filters onClearFilters={onClearFilters} />
        ) : (
          <div className={classes.sidebarClosed} onClick={toggleFilters}>
            <Badge color={'primary'}>
              <GroupsIcon color="primary" />
            </Badge>
            <p className={classes.refineCollapsed}>Refine Your Search</p>
          </div>
        )}
      </section>
    </div>
  );
};

export default SearchSidebar;
