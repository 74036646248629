import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';

export const getSourceBatch = state => {
  return _.get(state, 'moveTimecards.sourceBatch', {});
};

export const getMoveDone = state => _.get(state, 'moveTimecards.moveDone', {});
export const getMoveLoading = state =>
  _.get(state, 'moveTimecards.moveLoading', {});
export const getMoveJobInfo = state =>
  _.get(state, 'moveTimecards.moveJobInfo', {});

export const getMoveBatchTcs = state =>
  _.get(state, 'moveTimecards.batchMoveTcs', []);

const getRawDestinationInvoices = state => {
  return _.get(state, 'moveTimecards.destinationInvoices', []);
};

export const getInitialValues = createSelector([getMoveBatchTcs], timecards => {
  const timecardIds = {};
  let setValue = false;
  if (timecards.length === 1) setValue = true;
  //if only 1 timecard in batch, select it automatically
  timecards.forEach(t => (timecardIds[t.entryHeaderId] = setValue));

  return { timecardIds, voidEmptyInvoice: true, deleteEmptyBatch: true };
});

export const getDestinationInvoices = createSelector(
  [getRawDestinationInvoices],
  invoices => {
    const result = _.cloneDeep(invoices);

    result.forEach(invoice => {
      const weekEndingDate = invoice.weekEndingDate;
      delete invoice.weekEndingDate;
      if (weekEndingDate) {
        const momentDate = moment(weekEndingDate);
        invoice.weekEnding = momentDate.format('MM/DD');
      }
    });

    return result;
  },
);

export const getDestinationBatches = state => {
  return _.get(state, 'moveTimecards.destinationBatches', []);
};
