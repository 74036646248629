import React from 'react';
import clsx from 'clsx';
import { TableCell, TableHead, TableRow } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { rightShadow } from 'utils/wtcWeekUtils';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  headerRow: {
    height: 60,
    backgroundColor: palette.misc.tableHeader,
    whiteSpace: 'nowrap',
  },
  headerCell: {
    color: palette.text.secondary,
    padding: '32px 8px 0px',
    border: 'none',
  },
  headerCorner: {
    height: 28,
  },
  rowHeader: {
    backgroundColor: palette.misc.tableHeader,
    position: 'sticky',
    left: -1,
    border: 0,
  },
  scrolling: {
    ...rightShadow,
  },
  //Mobile only CSS
  [breakpoints.down('lg')]: {
    headerRow: {
      whiteSpace: 'normal',
    },
    rowHeader: {
      whiteSpace: 'normal',
    },
    tableCell: {
      fontSize: 11,
      // maxWidth: 25,
    },
  },
}));

const SelectTableHeaderRow = ({ isScrolling, fields }) => {
  const classes = useStyles();

  const headerCells = fields.map((field, index) => {
    const cellClasses = clsx(classes.headerCell, {
      [classes.scrolling]: isScrolling & (index === 0),
      [classes.rowHeader]: index === 0,
      [classes.headerCorner]: index === 0,
    });

    return (
      <TableCell
        key={`${field.label}-${JSON.stringify(field.dataLoc)}`}
        className={cellClasses}
      >
        {field.label}
      </TableCell>
    );
  });

  return (
    <TableHead className={classes.tableHead}>
      <TableRow className={classes.headerRow}>{headerCells}</TableRow>
    </TableHead>
  );
};

export default SelectTableHeaderRow;
