import createAction from './createAction';

export const drawerInit = createAction('wtc/drawerInit');
export const batchInit = createAction('wtc/batchInit');
export const multiBatchInit = createAction('wtc/multiBatchInit');
export const initFields = createAction('wtc/initFields');
export const fetchAllowancePayCode = createAction('wtc/fetchAllowancePayCode');
export const fetchAllStateLists = createAction('wtc/fetchAllStateList');
export const fetchBreakdown = createAction('wtc/fetchBreakdown');
export const fetchComments = createAction('wtc/fetchComments');
export const fetchCurrentApprovers = createAction('wtc/fetchCurrentApprovers');
export const fetchDealMemos = createAction('wtc/fetchDealMemos');
export const fetchEpisodes = createAction('wtc/fetchEpisodes');
export const fetchNextApprovers = createAction('wtc/fetchNextApprovers');
export const fetchPaidHours = createAction('wtc/fetchPaidHours');
export const fetchSplitHourTypes = createAction('wtc/fetchSplitHourTypes');
export const loadTimecard = createAction('wtc/load/timecard');
export const fetchTimecard = createAction('wtc/fetch/timecard');
export const fetchTimecardsInBatch = createAction('wtc/fetchTimecardsInBatch');
export const fetchWTCHistory = createAction('fetch/WTC/history');
export const fetchWorkSchedules = createAction('wtc/fetchWorkSchedules');
export const fetchCalculateUpdate = createAction('wtc/fetchCalculateUpdate');
export const setPendingCalculation = createAction('wtc/setPendingCalculation');

export const printTimecardReport = createAction('wtc/printTimecardReport');

export const loading = createAction('wtc/loading');
export const setDMScaleRatesLoading = createAction(
  'wtc/setDMScaleRatesLoading',
);
export const processing = createAction('wtc/processing');
export const setStoreId = createAction('wtc/setStoreId');
export const loadingTimecardsInDrawer = createAction(
  'wtc/loadingTimecardsInDrawer',
);
export const loadingTimecard = createAction('wtc/loadingTimecard');
export const loadingWorkSchedules = createAction('wtc/loadingWorkingSchedules');
export const loadingComments = createAction('wtc/loadingComments');
export const loadingUndo = createAction('wtc/loadingUndo');
export const downloadingReport = createAction('wtc/downloadingReport');

export const reject = createAction('wtc/reject');
export const resubmit = createAction('wtc/resubmit');
export const recall = createAction('wtc/recall');
export const addComment = createAction('wtc/addComment');
export const deleteTimecard = createAction('wtc/deleteTimecard');
export const saveTimecard = createAction('wtc/saveTimecard');
export const setSavingTimecard = createAction('wtc/setSavingTimecard');
export const undo = createAction('wtc/undo');
export const print = createAction('wtc/print');
export const wtcDownloadTimecardsReport = createAction(
  'wtc/downloadTimecardReport',
);

export const setSortBy = createAction('wtc/setSortBy');

export const setTextFilter = createAction('wtc/setTextFilter');
export const rejectingTimecard = createAction('wtc/timecard/rejecting');
export const approvingTimecard = createAction('wtc/timecard/approving');
export const storeTimecardsInDrawer = createAction(
  'wtc/storeTimecardsInDrawer',
);
export const storeWorkSchedules = createAction('wtc/storeWorkSchedules');
export const storeTimecard = createAction('wtc/store/timecard');
export const storeParsedTimecard = createAction('wtc/storeParsedTimecard');
export const clearTimecard = createAction('wtc/clear/timecard');
export const clearOptions = createAction('wtc/clear/options');
export const storeTCBreakdown = createAction('wtc/storeTCBreakdown');
export const storeComments = createAction('wtc/storeComments');
export const storeNextApprovers = createAction('wtc/storeNextApprovers');
export const storeCurrentApprovers = createAction('wtc/storeCurrentApprovers');
export const storePaidHours = createAction('wtc/storePaidHours');
export const storeDealMemos = createAction('wtc/store/dealMemos');
export const storeEpisodes = createAction('wtc/storeEpisodes');
export const storeSplitHourTypes = createAction('wtc/storeSplitHourTypes');
export const storeAllowancePayCode = createAction('wtc/storeAllowancePayCode');
export const storeAllowanceTypes = createAction('wtc/storeAllowanceTypes');
export const storeWTCHistory = createAction('store/WTC/history');

export const toggleAllowanceField = createAction('wtc/toggleAllowanceFields');
export const allAdditionalFields = createAction('wtc/allAdditionalFields');
export const resetAllAdditionalFields = createAction(
  'wtc/resetAllAdditionalFields',
);

export const dayType = createAction('wtc/fetch/dayType');
export const storeDayTypes = createAction('wtc/store/dayType');
export const occupationCodes = createAction('wtc/fetch/occupationCodes');
export const storeOccupationCodes = createAction('wtc/store/occupationCodes');
export const workLocations = createAction('wtc/fetch/workLocations');
export const storeWorkLocations = createAction('wtc/store/workLocations');

export const initTableFields = createAction('wtc/initTableFields');
export const toggleTableFields = createAction('wtc/toggleTableFields');
export const resetTableFields = createAction('wtc/resetTableFields');

export const setProspectiveTimecard = createAction(
  'wtc/setProspectiveTimecard',
);

export const onProducersWWChange = createAction(
  'wtc/timecard/producerWWChange',
);
export const onEmployeesWWChange = createAction(
  'wtc/timecard/employeeWWChange',
);
export const setActionType = createAction('wtc/setActionType');

export const approveTimecard = createAction('wtc/approveTimecard');

export const fetchAndSetPayAtScale = createAction('wtc/fetchAndSetPayAtScale');
export const storeScaleRateError = createAction('wtc/storeScaleRate');
export const clearScaleRateError = createAction('wtc/clearScaleRateError');
export const loadingScaleRate = createAction('wtc/loadingScaleRate');
export const setAutoCoding = createAction('wtc/setAutoCoding');

export const setLoadingNewBatch = createAction('wtc/loadingNewBatch');

//drawer filter actions
export const setSearchFilter = createAction('/wtc/setSearchFilter');
export const onSelect = createAction('/wtc/onFilterSelect');
export const onSelectAll = createAction('/wtc/onFilterSelectAll');
export const getFiltersFromTimecards = createAction(
  '/wtc/getFiltersFromTimecards',
);
export const storeFilterOptions = createAction('/wtc/storeFilterOptions');
export const clearAllFilters = createAction('/wtc/clearAllFilters');

export const storeRounding = createAction('wtc/storeRounding');
export const setCurrentBatchWorksightId = createAction(
  'wtc/setCurrentBatchWorksightId',
);
export const setCurrentTimecardHeaderId = createAction(
  'wtc/setCurrentTimecardHeaderId',
);

export const setWTCTimecardHeaderIds = createAction(
  'wtc/setWTCTimecardHeaderIds',
);
export const setIsMultiBatch = createAction('wtc/setIsMultiBatch');
export const multiBatchInfoCheck = createAction('wtc/multiBatchInfoCheck');
export const storeBatchInfo = createAction('wtc/storeBatchInfo');
export const fetchBatchInfo = createAction('wtc/fetchBatchInfo');
export const updateWTCTimecardIdsPostMove = createAction(
  'wtc/updateWTCTimecardIdsPostMove',
);
export const reviewUPMTimecards = createAction('wtc/reviewUPMTimecards');
export const populateUnusedDay = createAction('wtc/populateUnusedDay');
export const populateTimeFields = createAction('wtc/populateTimeFields');
export const calcResubmitComment = createAction('wtc/calcResubmitComment');
export const storeResubmitComment = createAction('wtc/storeResubmitComment');
export const storePrevChangeComments = createAction(
  'wtc/storePrevChangeComments',
);

export const onUnmountWTC = createAction('wtc/onUnmountWTC');
export const fetchPayCodeById = createAction('wtc/fetchPayCodeById');
export const updateAutoAllowances = createAction('wtc/updateAutoAllowances');
export const setCanEditWorkTimes = createAction('wtc/setCanEditWorkTimes');
export const setHasWorkTimeChanges = createAction('wtc/setHasWorkTimeChanges');
export const scanForWorkTimeChanges = createAction(
  'wtc/scanForWorkTimeChanges',
);
