import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { getFormValues, change } from 'redux-form';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as sel from 'feature/EmployeeTimecard/selectors';
import * as actions from 'feature/EmployeeTimecard/actions';
import { show as showModal } from 'actions/modalDialog';

import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  DAY_LEVEL_COMMENT_MODEL,
  CONFIRM_MODAL,
} from 'feature/EmployeeTimecard/Modals/modalNames';
import { getTCDaysOptions } from 'feature/EmployeeTimecard/empTimecardUtils';

import { FORM_NAME } from 'feature/EmployeeTimecard/selectors';

import { Button } from 'feature/EmployeeTimecard/Shared/styledComponents';
import CommentIconFilled from 'components/images/CommentIconFilled';

const StyleIconBtn = styled(IconButton)(({ theme }) => ({
  padding: 5,
}));
const WideButton = styled(Button)(({ theme }) => ({
  width: '48%',
}));

const useStyles = makeStyles(() => ({
  menu: {
    '& .MuiPaper-root': {
      width: '250px',
    },
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '18px',
    alignItems: 'center',
  },
}));
const mapState = state => ({
  timecard: getFormValues(FORM_NAME)(state),
  selectedDay: sel.getSelectedDay(state).day || {},
  copyToOtherDays: sel.getCopyToOtherDays(state),
  activeDeal: sel.getActiveDealMemo(state),
  isTcEditable: sel.getIsTcEditable(state),
  comments: sel.getComments(state),
});

const mapDispatch = dispatch => ({
  dispatch,
  onClearDay: index => dispatch(actions.clearDay({ index })),
  onShowModal: onConfirm =>
    dispatch(
      showModal({
        dialog: CONFIRM_MODAL,
        modalParams: {
          actionText: 'Delete this day from the timecard?',
          onConfirm,
        },
      }),
    ),
  onSetTCCopyToOtherDays: copyToOtherDays =>
    dispatch(actions.setTCCopyToOtherDays({ copyToOtherDays })),
  onClose: () =>
    dispatch(actions.setTCCopyToOtherDays({ copyToOtherDays: [] })),
  onSetSelectedDay: day => dispatch(actions.storeSelectedDay({ day })),
  onUpdateDailyAutoAllowances: () =>
    dispatch(actions.updateDailyAutoAllowances()),
  onShowCommentModal: day => {
    dispatch(
      actions.selectedCommentDay({
        selectedCommentDay: day,
      }),
    );
    dispatch(
      showModal({
        dialog: DAY_LEVEL_COMMENT_MODEL,
      }),
    );
  },
});

const DisplayCell = props => {
  const {
    index,

    timecard,
    selectedDay,
    isTcEditable,
    copyToOtherDays = [],
    activeDeal,

    dispatch,
    onClearDay,
    onShowModal,
    onSetTCCopyToOtherDays,
    onClose,
    onSetSelectedDay,
    onUpdateDailyAutoAllowances,
    comments,
    onShowCommentModal,
  } = props;

  const classes = useStyles();
  const { date: selDate } = selectedDay; // selected day date
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const isEmptyDay = !timecard.days[index].htgDayTypeId;

  const handleOptionSelect = (event, option) => {
    let updatedOptions = [];
    const found = copyToOtherDays.some(item => item.value === option.value);
    if (found) {
      updatedOptions = copyToOtherDays.filter(
        item => item.value !== option.value,
      );
    } else {
      updatedOptions = [...copyToOtherDays, option];
    }
    onSetTCCopyToOtherDays(updatedOptions);
  };

  const openCopyMenu = () => {
    onSetSelectedDay(timecard.days[index]);
    setOpen(true);
  };

  const handleCopy = () => {
    const days = timecard.days;
    const referenceDay = days.find(day => moment(day.date).isSame(selDate));
    let dayTypeChange = false;
    copyToOtherDays.forEach(day => {
      const newDay = { ...referenceDay, date: day.value, id: day.id || null };
      days.forEach((tcDay, index) => {
        if (moment(tcDay.date).isSame(day.value)) {
          dispatch(change(FORM_NAME, `days[${index}]`, newDay));

          if (newDay.htgDayTypeId !== tcDay.htgDayTypeId) {
            dayTypeChange = true;
          }
        }
      });
    });
    if (dayTypeChange) {
      onUpdateDailyAutoAllowances();
    }
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  //menu list options
  const options = getTCDaysOptions(selDate, timecard, activeDeal);
  const isCopyBtnDisabled = !timecard.days[index]?.htgDayTypeId || isEmptyDay; //disable if no daytype selected

  const dayLevelComments = comments?.filter(p => p.type === 'day');
  const isCommentExist = dayLevelComments.some(s =>
    moment(s.referenceDate, 'YYYY-MM-DD').isSame(
      timecard.days[index].date,
      'YYYY-MM-DD',
    ),
  );
  const copyTitle = isCopyBtnDisabled
    ? ''
    : 'Copy values from this day to others';
  return (
    <Box sx={{ display: 'flex' }}>
      <Tooltip
        key={index}
        placement="top"
        title={
          isTcEditable
            ? isCommentExist
              ? 'Comment left'
              : 'Leave a comment'
            : isCommentExist
            ? 'Comment present'
            : ''
        }
      >
        <Box>
          <StyleIconBtn
            id={`day[${index}].commentIcon`}
            ref={anchorRef}
            onClick={() =>
              isTcEditable && onShowCommentModal(timecard.days[index])
            }
          >
            <CommentIconFilled isCommentExists={isCommentExist} />
          </StyleIconBtn>
        </Box>
      </Tooltip>
      {isTcEditable && (
        <>
          <Tooltip title={copyTitle}>
            <Box>
              <StyleIconBtn
                id={`day[${index}].copyIcon`}
                ref={anchorRef}
                disabled={isCopyBtnDisabled}
                onClick={openCopyMenu}
              >
                <ContentCopyIcon
                  sx={{ color: isEmptyDay ? 'text.disabled' : 'gray.primary' }}
                />
              </StyleIconBtn>
            </Box>
          </Tooltip>
          <Menu
            anchorEl={anchorRef.current}
            open={open}
            onClose={handleClose}
            className={classes.menu}
          >
            {options.map(option => {
              const disableCurrent = moment(option.value).isSame(selDate);
              return (
                <MenuItem
                  className={classes.menuItem}
                  key={option.value}
                  onClick={event => handleOptionSelect(event, option)}
                  disabled={disableCurrent}
                >
                  <Box>{option.label}</Box>
                  <Box>
                    {copyToOtherDays.some(
                      item => item.value === option.value,
                    ) ? (
                      <CheckCircleIcon color="primary" />
                    ) : null}
                  </Box>
                </MenuItem>
              );
            })}
            <Box sx={{ width: '100%', display: 'flex' }}>
              <WideButton onClick={handleClose} variant="outlined">
                Cancel
              </WideButton>
              <WideButton onClick={handleCopy}>Copy</WideButton>
            </Box>
          </Menu>
          <StyleIconBtn
            disabled={isEmptyDay}
            onClick={() => onShowModal(() => onClearDay(index))}
            id={`day[${index}].deleteIcon`}
          >
            <DeleteIcon
              sx={{ color: isEmptyDay ? 'text.disabled' : 'gray.primary' }}
            />
          </StyleIconBtn>
        </>
      )}
    </Box>
  );
};

DisplayCell.propTypes = {
  index: PropTypes.number.isRequired,
  onClearDay: PropTypes.func.isRequired,
  onShowModal: PropTypes.func.isRequired,
  timecard: PropTypes.object.isRequired,
  selectedDay: PropTypes.object.isRequired,
  copyToOtherDays: PropTypes.array.isRequired,
  activeDeal: PropTypes.object.isRequired,
  isTcEditable: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSetTCCopyToOtherDays: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSetSelectedDay: PropTypes.func.isRequired,
  onUpdateDailyAutoAllowances: PropTypes.func.isRequired,
};

export default compose(connect(mapState, mapDispatch))(DisplayCell);
