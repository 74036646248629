export const WHITE_LIST_TYPES = ['image/png', 'image/jpeg', 'application/pdf'];
export const SIZE_DENOMINATOR = 1024;
export const MAX_UPLOAD_SIZE_BYTES = 25 * SIZE_DENOMINATOR * SIZE_DENOMINATOR; // 25 MB

export const MAX_FILE_COUNT = 50;

export const calculateFileSize = (size, startUnit = 'byte') => {
  const SIZE_UNITS = ['byte', 'KB', 'MB', 'GB', 'TB'];
  let index = SIZE_UNITS.indexOf(startUnit);
  let calculatedSize = size;
  if (size < SIZE_DENOMINATOR) return `${size} byte`;
  while (calculatedSize >= SIZE_DENOMINATOR) {
    calculatedSize = calculatedSize / SIZE_DENOMINATOR;
    index++;
  }
  return `${calculatedSize.toFixed(2)} ${SIZE_UNITS[`${index}`]}`;
};

const generateRandomCharacters = (len = 34) => {
  // Create a string of all possible characters.
  const characters =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  // Generate 34 random characters.
  const randomCharacters = [];
  for (let i = 0; i < len; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomCharacters.push(characters[randomIndex]);
  }
  // Return the string of random characters.
  return randomCharacters.join('');
};

export const validateFile = (file, majorErrors = []) => {
  const fileErrors = [];
  if (file.bytes > MAX_UPLOAD_SIZE_BYTES) fileErrors.push('File Too Large');
  if (WHITE_LIST_TYPES.includes(file.type) === false) {
    fileErrors.push('Unsupported File Type');
  }

  if (file.fileName.length >= 150) {
    fileErrors.push('File name must be less than 150 characters');
  }

  // generate a temp ID to use until the BE gives us a proper one
  // needed for generating a react key since filename might not be unique
  if (!file.documentId) {
    file.tempDocId = generateRandomCharacters();
  }

  return majorErrors.concat(fileErrors);
};

/**
 *
 */
export class FilesBatches {
  constructor(pendingFiles = [], batchSizeLimit = MAX_UPLOAD_SIZE_BYTES) {
    this.blocked = [];
    this.batchLimit = batchSizeLimit;
    this.batches = [];

    pendingFiles.forEach(file => {
      this.addFile(file);
    });
  }

  createBatch() {
    const length = this.batches.push({ list: [], bytes: 0 });
    const i = length - 1;
    return this.batches[i];
  }

  addFile(file) {
    const fileBytes = file.bytes;
    const errors = file.errors;
    let batchedDone = false;

    if (errors.length > 0 || fileBytes > this.batchLimit) {
      this.blocked.push(file);
      return;
    }

    if (this.batches.length === 0) this.createBatch();

    for (let i = 0; i < this.batches.length; i++) {
      const batch = this.batches[i];
      const newBatchBytes = batch.bytes + fileBytes;
      if (newBatchBytes < this.batchLimit) {
        batch.list.push(file);
        batch.bytes = newBatchBytes;
        batchedDone = true;
        break;
      }
    }

    if (batchedDone === false) {
      const newBatch = this.createBatch();
      const newBatchBytes = newBatch.bytes + fileBytes;
      newBatch.list.push(file);
      newBatch.bytes = newBatchBytes;
      batchedDone = true;
    }
  }
}
