import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';

import { Typography, Box, styled } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import makeStyles from '@mui/styles/makeStyles';

import {
  getInvoice,
  getTaxColumnMap,
  getInvoiceCharges,
  getAnyPendingTimecards,
} from 'selectors/digitalEdits';

import DataTableDigitalEdits from './DataTableDigitalEdits';
import TaxDataCards from './TaxDataCards';

import {
  accountCodeSummaryHeaders,
  invoiceChargesHeaders,
  makeTaxesData,
} from 'feature/DigitalEdits/digitalEditsUtils';
import { getDataCardVisible } from 'selectors/digitalEdits';

const useStyles = makeStyles(({ palette }) => ({
  Footer: { margin: 24, gap: 16 },
}));

const MsgBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '3px',
  marginLeft: '16px',
  fontSize: '16px',
  fontWeight: 500,
}));

const WarningMsgBox = styled(MsgBox)(({ theme }) => ({
  color: theme.palette.warning.main,
}));

const TABLE_NAME = 'Account Code Summary';

const mapState = state => ({
  invoice: getInvoice(state),
  taxColumnMap: getTaxColumnMap(state),
  viewEnabled: getDataCardVisible(state, TABLE_NAME),
  invoiceCharges: getInvoiceCharges(state),
  anyPending: getAnyPendingTimecards(state),
});

const mapDispatch = {};

const Footer = props => {
  const { invoice, taxColumnMap, viewEnabled, invoiceCharges, anyPending } =
    props;
  const classes = useStyles();

  const invoiceAccountCodeSummary =
    invoice?.invoiceAccountCodeSummary?.slice() || [];

  const taxes = makeTaxesData(invoice?.invoiceGrandTotal, taxColumnMap);

  const totalBox = {
    label: 'Total',
    data: [
      { label: 'Invoice Total', value: invoice.invoiceTotal, isHeader: true },
      { label: 'Gross Pay', value: invoice.grossPay, isHeader: true },
      { label: 'Net Pay', value: invoice.netPay, isHeader: true },
    ],
    position: 100,
    column: 1,
  };

  taxes.col1?.unshift(totalBox);

  if (_.isEmpty(invoice)) return null;

  const title = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {TABLE_NAME}
      {anyPending && (
        <WarningMsgBox>
          <ErrorOutlineIcon color={'warning'} />
          Pending timecard calculation
        </WarningMsgBox>
      )}
    </Box>
  );

  return (
    <Box className={classes.Footer}>
      {invoiceCharges?.length > 0 && (
        <DataTableDigitalEdits
          viewEnabled={true} //Do we need to wire this up?
          headers={invoiceChargesHeaders}
          data={invoiceCharges}
          title={'Invoice Charges'}
        />
      )}
      <Box sx={{ px: 2 }} className={'PENDO_digitalEdits_invoice_grand_total'}>
        <Typography variant="h4" sx={{ fontWeight: 800 }}>
          INVOICE GRAND TOTAL
        </Typography>
        <TaxDataCards {...taxes} />
      </Box>
      <DataTableDigitalEdits
        viewEnabled={viewEnabled}
        headers={accountCodeSummaryHeaders}
        data={invoiceAccountCodeSummary}
        title={title}
        loading={anyPending}
      />
    </Box>
  );
};

Footer.propTypes = {
  invoice: PropTypes.object.isRequired,
  taxColumnMap: PropTypes.object.isRequired,
  viewEnabled: PropTypes.bool.isRequired,
  invoiceCharges: PropTypes.array.isRequired,
  anyPending: PropTypes.bool.isRequired,
};

export default compose(connect(mapState, mapDispatch))(Footer);
