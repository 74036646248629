import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { ModalContent, ModalTitle } from 'components/Shared/ModalDialog';
import { compose } from 'utils/helperFunctions';
import { meridianDateFormat } from 'utils/formatDate';
import { getInvoiceApprovalStatus } from './digitalEditsUtils';
import { connect } from 'react-redux';

import {
  Box,
  LinearProgress,
  Tooltip,
  IconButton,
  Button,
  Paper,
} from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
// decorators
import withModalDialog from 'decorators/withModalDialog';
//actions
import { hide as hideModal } from 'actions/modalDialog';
import {
  fetchEditReports,
  downloadEditReport,
  downloadSelectedFiles,
} from 'actions/digitalEdits';

//selectors
import {
  sortedEditReports,
  getCurrentInvoiceId,
  getDigitalEditsReportsLoading,
} from 'selectors/digitalEdits';

import ColorPill from 'components/Shared/ColorPill';

const MODAL_NAME = 'DownloadEditReports';
const mapState = state => ({
  currentInvoiceId: getCurrentInvoiceId(state),
  editReports: sortedEditReports(state),
  loading: getDigitalEditsReportsLoading(state),
});
const mapDispatch = dispatch => ({
  onCloseModal: () => {
    dispatch(hideModal({ dialog: MODAL_NAME }));
  },
  onInit: invoiceId => {
    dispatch(fetchEditReports({ invoiceId }));
  },
  onDownloadIconClick: report => {
    dispatch(
      downloadEditReport({
        endpoint: report.preSignedUrl,
        filename: report.fileName,
      }),
    );
  },
  onDownloadSelectedFiles: reports => {
    dispatch(downloadSelectedFiles({ reports }));
  },
});

const useStyles = makeStyles(({ palette }) => ({
  headerContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonText: {
    marginLeft: '5px',
  },

  reportsContainer: {
    border: `1px solid ${palette.text.disabled}`,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    width: '100%',
    margin: '10px 0px',
    '&:hover': {
      backgroundColor: palette.primary['+8'],
    },
  },
  selectedFile: {
    backgroundColor: palette.primary['+9'],
    '&:hover': {
      backgroundColor: palette.primary['+7'],
    },
  },
  statusPill: {
    background: palette.primary['+0'],
    borderRadius: '10px',
    color: palette.primary['+10'],
    padding: '5px 7px',
    width: 'max-content',
    fontSize: 12,
    marginLeft: 16,
  },
  leftSection: {
    display: 'flex',
    padding: '10px',
    flexGrow: 1,
  },
  fileName: {
    marginLeft: 8,
    maxWidth: 700,
    overflow: 'hidden',
    wordWrap: 'break-word',
  },
  dateContent: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 8,
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    flexGrow: 0,
  },
  fileIcon: {
    transform: 'scale(1.5)',
    marginTop: '15px',
    color: palette.text.secondary,
  },
  downloadIcon: {
    transform: 'scale(1.3)',
    cursor: 'pointer',
  },
  noReportsText: {
    textAlign: 'center',
  },
}));
const DownloadEditReports = ({
  loading,
  onCloseModal,
  onInit,
  currentInvoiceId,
  editReports = [],
  onDownloadIconClick,
  onDownloadSelectedFiles,
}) => {
  const classes = useStyles();

  const [editedReports, setEditedReports] = useState([]);
  const [selectedCount, setSelectedCount] = useState(null);

  useEffect(() => {
    onInit(currentInvoiceId);
  }, [onInit, currentInvoiceId]);

  useEffect(() => {
    setEditedReports(
      [...editReports.map(item => ({ ...item, selected: false }))] || [],
    );
  }, [editReports]);

  const handleSelectedReport = index => {
    const reports = editedReports;
    reports[index].selected = !reports[index].selected;
    setEditedReports([...reports]);
    const count = reports.filter(r => r.selected)?.length;
    setSelectedCount(count > 0 ? count : null);
  };

  const downloadSelectedFiles = () => {
    const selectedReports = editedReports.filter(item => item.selected);
    onDownloadSelectedFiles(selectedReports);
  };

  const buttonText =
    selectedCount > 0
      ? `Download (${selectedCount}) Selected`
      : 'Download Selected';
  return (
    <React.Fragment>
      <ModalTitle fullWidth onClose={onCloseModal} className={classes.title}>
        <span>Download Edit Report</span>
      </ModalTitle>
      <ModalContent shrinkTop>
        {loading && <LinearProgress />}
        {editedReports.length > 0 && (
          <div className={classes.headerContent}>
            <Tooltip title="Click report to select" placement="left">
              <span>
                <Button
                  onClick={downloadSelectedFiles}
                  disabled={
                    editedReports.filter(item => item.selected).length === 0
                  }
                >
                  <CloudDownloadIcon />
                  <span className={classes.buttonText}>{buttonText}</span>
                </Button>
              </span>
            </Tooltip>
          </div>
        )}
        <Box>
          {editedReports.map((report, index) => (
            <Paper
              className={clsx(classes.reportsContainer, {
                [classes.selectedFile]: report.selected,
              })}
              key={report.resourceId}
              onClick={() => handleSelectedReport(index)}
            >
              <section className={classes.leftSection}>
                <InsertDriveFileIcon className={classes.fileIcon} />
                <div>
                  <Box className={classes.fileName}>{report.fileName}</Box>
                  <Box className={classes.dateContent}>
                    Date Created: {meridianDateFormat(report.createdAt)}{' '}
                    <Box sx={{ fontSize: 12 }}>
                      <ColorPill
                        {...getInvoiceApprovalStatus(report.approvalStatus)}
                      />
                    </Box>
                  </Box>
                </div>
              </section>
              <section className={classes.rightSection}>
                <div>
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      onDownloadIconClick(report);
                    }}
                  >
                    <CloudDownloadIcon className={classes.downloadIcon} />
                  </IconButton>
                </div>
              </section>
            </Paper>
          ))}
          {editReports.length === 0 && !loading && (
            <Box className={classes.noReportsText}>No Reports</Box>
          )}
        </Box>
      </ModalContent>
    </React.Fragment>
  );
};

export default compose(
  withModalDialog({ dialog: MODAL_NAME, maxWidth: 'lg' }),
  connect(mapState, mapDispatch),
)(DownloadEditReports);
