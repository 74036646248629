import React from 'react';
import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const useStyles = makeStyles(({ palette }) => ({
  lockIcon: {
    color: palette.primary.main,
    marginBottom: '-4px',
  },
  grayLockIcon: {
    color: palette.gray['+6'],
    marginBottom: '-4px',
  },
}));

function LockStatusIcon(props) {
  const { lockStatus } = props;
  const classes = useStyles();

  const possibleLocks = {
    unlock: {
      title:
        'If new timecards are created for these departments, they will be automatically added to this batch.',
      icon: <LockOpenIcon className={classes.grayLockIcon} />,
    },
    softlock: {
      title:
        'If new timecards are created, they will be added to a different batch. You can move timecards in and out freely, until the batch has been fully submitted to C&C.',
      icon: <LockIcon className={classes.lockIcon} />,
    },
    hardlock: {
      title:
        'This batch is locked because all timecards have been submitted to C&C, or because a payroll coordinator has begun work on the invoice. Only a payroll coordinator can move timecards into or out of this batch.',
      icon: <LockIcon className={classes.grayLockIcon} />,
    },
  };

  const lock = possibleLocks[lockStatus];

  if (!lock) return null;

  return (
    <Tooltip arrow title={lock.title} placement="right">
      <span>{lock.icon}</span>
    </Tooltip>
  );
}

export default LockStatusIcon;
