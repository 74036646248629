import _ from 'lodash';
import { produce } from 'immer';
import * as actions from 'actions/settings';
import { SETTINGS } from 'components/props/projects';
import { CREW_MEMBER_TC_FIELDS } from '../containers/Admin/Projects/Settings/settingsUtils';

const settingState = {
  dhVisible: false,
  dhMandatory: false,
  crewVisible: false,
  crewMandatory: false,
};

const crewMemDefaultState = {
  employeeMandatory: false,
  employeeVisible: false,
  employeeAdditionalFields: false,
};

const initialState = {
  recipients: [],
  list: {
    accountCodesOpenByDefault: false,
    departmentApprove: false,
    splitsProject: false,
    splits: false,
  },
  initialSettings: {},
  crewMemberInitialSettings: {},
  loading: false,
  payrollSettings: null,
  emailTemplateLoading: false,
  studioPlusDirectory: [],
  editStudioPlusDestination: null,
  editStudioPlusGroup: null,
  timecardTemplateOptions: [],
  studioPlusBtnDisabled: true,
  crewMemberVisibleFields: {},
};

_.each(SETTINGS, (_, key) => (initialState.list[key] = settingState));

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.loading}`:
        draft.loading = action.loading;
        break;

      case `${actions.store}`:
        const settings = _.cloneDeep(action.settings);
        settings.cachedOn = action.cachedOn;
        settings.projectId = action.projectId;
        settings.splits = settings.splits || false;
        settings.splitsProject = settings.splitsProject || false;
        settings.rejectTcOnAllowanceChange =
          settings.rejectTcOnAllowanceChange || false;
        if (!settings.wtcEditable) {
          settings.wtcEditable = {
            ndbIn: true,
            lastMan1In: true,
            ndmOut: true,
            ndmIn: true,
          };
        }
        _.each(SETTINGS, (_, key) => {
          settings[key] = { ...settingState, ...settings[key] };
        });
        //setting key pair values
        Object.keys(CREW_MEMBER_TC_FIELDS).forEach(key => {
          settings[key] = {
            ...crewMemDefaultState, //during project setup setting initially values - false
            employeeDescription: CREW_MEMBER_TC_FIELDS[key].employeeDescription, //default description
            type: CREW_MEMBER_TC_FIELDS[key].type, //default type
            ...settings[key], // received values from api
          };
        });

        draft.list = {
          [action.projectId]: settings,
        };
        draft.crewMemberInitialSettings = {
          [action.projectId]: settings,
        };
        draft.initialSettings = {
          [action.projectId]: {
            myTimecardAutoAllowances: settings.myTimecardAutoAllowances,
            crewTimecardAutoAllowances: settings.crewTimecardAutoAllowances,
            dtsTimecardAutoAllowances: settings.dtsTimecardAutoAllowances,
          },
        };
        break;

      case `${actions.edit}`:
        if (action.prop) {
          draft.list[action.projectId][action.field][action.prop] =
            action.value;
        } else {
          draft.list[action.projectId][action.field] = action.value;
        }
        break;

      case `${actions.storePayrollSettings}`:
        draft.payrollSettings = {
          [action.projectId]: action.payrollSettings,
        };
        break;

      case `${actions.loadingEmailSettings}`:
        draft.emailTemplateLoading = action.loading;
        break;

      case `${actions.storeEmailSettings}`:
        if (action.templates && action.templates.length > 0) {
          draft.email_templates = action.templates;
        } else if (action.template) {
          const templates = draft.email_templates || [];
          const template = action.template;
          let index = _.findIndex(
            templates,
            t => t.emailType === template.emailType,
          );
          index = index < 0 ? templates.length : index;
          templates[index] = template;
          draft.email_templates = templates;
        }
        break;
      case `${actions.editStudioPlusGroup}`:
        draft.editStudioPlusGroup = action.group;

        break;
      case `${actions.storeStudioPlusDirectory}`:
        draft.studioPlusDirectory = [action.studioPlusDirectory];
        break;
      case `${actions.toggleAutoCoding}`:
        const autoCodingStatus = !state.list[action.projectId].autoCodingEnable;
        draft.list[action.projectId].autoCodingEnable = autoCodingStatus;
        break;
      case `${actions.setWtcEditable}`:
        draft.list[action.projectId].wtcEditable = action.wtcEditable;
        break;
      case `${actions.setEditOptions}`:
        draft.list[action.projectId].editOptions = action.editOptions;
        break;
      case `${actions.setWtcLayout}`:
        draft.list[action.projectId].wtcLayout = action.wtcLayout;
        break;
      case `${actions.storeTimecardTemplates}`:
        const data = action.data;

        const options = data.map(t => ({ label: t.name, value: t.id }));
        const sorted = _.sortBy(options, obj => obj.label);
        draft.timecardTemplateOptions = sorted;
        break;
      case `${actions.storeRecipients}`:
        draft.recipients = action.recipients;
        break;
      case `${actions.studioPlusButtonDisabled}`:
        draft.studioPlusBtnDisabled = action.studioPlusBtnDisabled;
        break;
      case `${actions.initCrewMemberVisibleFields}`:
        const projectSettings = draft.list[action.projectId];

        const additionalFields = Object.keys(CREW_MEMBER_TC_FIELDS).filter(
          key => CREW_MEMBER_TC_FIELDS[key].isAdditionalField,
        );
        const defaultFields = Object.keys(CREW_MEMBER_TC_FIELDS).filter(
          key => CREW_MEMBER_TC_FIELDS[key].isDefaultVisible,
        );

        const filteredVisibleFields = {};
        //Default fields to display
        defaultFields.forEach(key => {
          filteredVisibleFields[key] = CREW_MEMBER_TC_FIELDS[key];
        });

        //Pushing additional fields to filteredVisibleFields if atlease one value is true
        additionalFields.forEach(key => {
          if (
            projectSettings[key].employeeAdditionalFields ||
            projectSettings[key].employeeVisible ||
            projectSettings[key].employeeMandatory
          ) {
            filteredVisibleFields[key] = CREW_MEMBER_TC_FIELDS[key];
          }
        });

        draft.crewMemberVisibleFields = filteredVisibleFields;
        break;
      case `${actions.updateCrewMemberVisibleFields}`:
        let updatedFields = _.cloneDeep(draft.crewMemberVisibleFields);
        const key = action.key;
        const label = action.label; //selected label from additional fields dropdown
        const addtFieldsSettings = _.cloneDeep(draft.list[action.projectId]);
        const fields = Object.keys(updatedFields);
        if (fields.includes(key)) {
          delete updatedFields[key];
        } else {
          updatedFields = {
            ...updatedFields,
            [key]: { ...addtFieldsSettings[key], label: label },
          };
        }
        draft.crewMemberVisibleFields = updatedFields;
        break;
      case `${actions.updateCrewMemberFieldValues}`:
        const fieldValuesSettings = draft.list[action.projectId];
        const updatedValues = {
          ...fieldValuesSettings,
          [action.field]: {
            ...fieldValuesSettings[action.field],
            [action.prop]: action.value,
          },
        };
        draft.list[action.projectId] = updatedValues;
        break;
      default:
    }
  });
