import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { compose } from 'utils/helperFunctions';
import moment from 'moment';
import { MenuItem, Menu, LinearProgress, Box } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as actions from 'feature/EmployeeTimecard/actions';
import {
  getLoading,
  getTimecardList,
  FORM_NAME,
  getDisabledBtns,
  getIsMobile,
} from 'feature/EmployeeTimecard/selectors';
import { Button } from './styledComponents';
import { getFormValues, isDirty } from 'redux-form';

import { CONFIRM_MODAL } from 'feature/EmployeeTimecard/Modals/modalNames';
import { show as showModal } from 'actions/modalDialog';
import { NEW_TC_ID } from 'feature/EmployeeTimecard/empTimecardUtils';

const mapState = state => {
  return {
    loading: getLoading(state, 'timecard'),
    timecardList: getTimecardList(state),
    timecard: getFormValues(FORM_NAME)(state),
    isDirty: isDirty(FORM_NAME)(state),
    disabledBtns: getDisabledBtns(state),
    isMobile: getIsMobile(state),
  };
};

const mapDispatch = dispatch => ({
  onShowModal: (onConfirm, text) => {
    dispatch(
      showModal({
        dialog: CONFIRM_MODAL,
        modalParams: {
          actionText: text,
          onConfirm,
        },
      }),
    );
  },
  dispatch,
  onHandelConfirm: (tc, selectedTimecard) => {
    dispatch(actions.copyFromPrevWeek({ copyTimecard: tc, selectedTimecard }));
  },
});

const CopyPrevList = props => {
  const {
    loading,
    disabled,
    onShowModal,
    timecardList,
    timecard,
    isDirty,
    onHandelConfirm,
    disabledBtns,
    isMobile,
  } = props;

  const { disableActions } = disabledBtns;

  const tcList = timecardList
    ?.filter(tc => !tc.isAllowanceOnly)
    ?.sort((a, b) => new Date(b.startsOn) - new Date(a.startsOn))
    ?.slice(0, 12);
  const [isOpen, setIsOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  return (
    <>
      <Button
        variant="outlined"
        ref={anchorRef}
        startIcon={<ContentCopyIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled || disableActions}
      >
        Copy from
      </Button>

      <Menu
        anchorEl={anchorRef.current}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{ borderRadius: 15 }}
      >
        {!loading && tcList?.length === 0 && (
          <Box sx={{ width: 175, p: 2, color: 'text.disabled' }}>
            No previous timecards
          </Box>
        )}
        {!loading &&
          tcList?.map(tc => {
            return (
              <MenuItem
                key={tc.timecardId}
                onClick={() => {
                  setIsOpen(false);
                  if (isDirty && timecard.id === NEW_TC_ID) {
                    onShowModal(
                      () => onHandelConfirm(tc, timecard),
                      `This will overwrite your current timecard.`,
                    );
                  } else {
                    onHandelConfirm(tc, timecard);
                  }
                }}
                sx={{
                  border: isMobile ? '1px solid' : '',
                  borderColor: isMobile ? 'gray.+8' : '',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '12px',
                  maxWidth: '90vw',
                  width: '100%',
                  whiteSpace: isMobile ? 'wrap' : 'nowrap',
                  gap: 2,
                  alignItems: 'flex-start',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: '4px',
                    flex: 1,
                  }}
                >
                  <Box>{moment(tc.startsOn).format('MM/DD')} </Box>
                  {!isMobile && <Box>-</Box>}
                  <Box>{moment(tc.endsOn).format('MM/DD')}</Box>
                </Box>
                {'  '}
                <Box sx={{ flex: 4, lineHeight: 1.65 }}>
                  {tc.occupationCode}
                </Box>{' '}
                <Box
                  sx={{
                    flex: 1,
                    pl: '25px',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'flex-end',
                    gap: isMobile ? '' : '4px',
                  }}
                >
                  <Box>{tc.totalWorkedHours}</Box> <Box>hours</Box>
                </Box>
              </MenuItem>
            );
          })}
        {loading && (
          <Box sx={{ width: anchorRef?.current?.offsetWidth || 500 }}>
            <LinearProgress />
          </Box>
        )}
      </Menu>
    </>
  );
};

CopyPrevList.propTypes = {
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onShowModal: PropTypes.func.isRequired,
  timecardList: PropTypes.array.isRequired,
  timecard: PropTypes.object.isRequired,
  isDirty: PropTypes.bool.isRequired,
  onHandelConfirm: PropTypes.func.isRequired,
  disabledBtns: PropTypes.shape({
    disableActions: PropTypes.bool.isRequired,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default compose(connect(mapState, mapDispatch))(CopyPrevList);
