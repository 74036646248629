import React from 'react';
import AppSwitcher from 'containers/AppSwitcher';
import { DefaultLayout, Header } from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    path="/"
    key="/"
    authenticated
    layout={DefaultLayout}
    headers={[Header]}
    component={AppSwitcher}
  />
);
