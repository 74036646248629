import { produce } from 'immer';
import * as actions from 'actions/departments';

const initialState = {
  list: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.store}`:
        draft.list = action.departments;
        break;
      case `${actions.reset}`:
        for (const key in initialState) {
          if (Object.hasOwnProperty.call(initialState, key)) {
            draft[key] = initialState[key];
          }
        }
        break;

      default:
    }
  });
