import React from 'react';
import { connect } from 'react-redux';
import { canadianLabel, compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Box, IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';

//redux
import * as actions from '../actions';
import { hide as closeModal } from 'actions/modalDialog';

// import * as sel from '../selectors';
import { getSessionUser } from 'selectors/session';
import { getCurrentProject } from 'selectors/project';
import { weekEndingFormat } from 'utils/formatDate';
//local components
import {
  TitleBox,
  ContentBox,
  FooterBox,
  FooterButton,
  TextField,
} from '../Shared/styledComponents';
import { getFormValues } from 'redux-form';
import withModalDialog from 'decorators/withModalDialog';
import { SIGNATURE_MODAL } from '../Modals/modalNames';
import moment from 'moment';
import { FORM_NAME } from 'feature/EmployeeTimecard/selectors';
const ERR_MSG =
  'Signature does not match user name, please sign your full name';

const mapState = (state, ownProps) => ({
  currentUser: getSessionUser(state),
  project: getCurrentProject(state, ownProps),
  timecard: getFormValues(FORM_NAME)(state),
});

const mapDispatch = dispatch => ({
  onSubmit: electronicSignature => {
    dispatch(actions.submitTimecard({ electronicSignature }));
  },
  onCloseModal: () => dispatch(closeModal({ dialog: SIGNATURE_MODAL })),
});

const EmpSignature = props => {
  const { onSubmit, onCloseModal, currentUser, timecard } = props;

  //const todayDate = weekEndingFormat(new Date()); // moment().format('YYYY-MM-DD');
  const tcLastDay = timecard?.days
    ?.slice()
    .reverse()
    .find(day => day.htgDayTypeId !== null);
  const tcDate = weekEndingFormat(tcLastDay?.date);
  const isFutureDayExists = moment().isBefore(tcDate);

  const { firstName = '', lastName = '' } = currentUser;

  const fullName = `${firstName} ${lastName}`;

  const [electronicSignature, setSignature] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const WarningBox = styled(Box)(({ theme }) => ({
    color: theme.palette.warning.main,
    fontWeight: 500,
    fontSize: 16,
  }));
  const SummaryItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    gap: '8px',
    padding: 2,
  }));
  const didBlur = React.useRef(false);

  const disableSubmit =
    fullName.toLowerCase() !== electronicSignature.trim().toLowerCase();

  const handleSubmit = React.useCallback(() => {
    onSubmit(electronicSignature);
    onCloseModal();
  }, [onSubmit, electronicSignature, onCloseModal]);

  const laborSpellCheck = canadianLabel('labor');
  return (
    <Box>
      <TitleBox>
        <Box>Sign to Submit</Box>
        <IconButton onClick={onCloseModal}>
          <CloseIcon />
        </IconButton>
      </TitleBox>

      <ContentBox>
        {isFutureDayExists && (
          <SummaryItem sx={{ mr: 10, backgroundColor: 'background.default' }}>
            <WarningIcon sx={{ color: 'warning.main' }} />
            <WarningBox>This timecard contains times in the future.</WarningBox>
          </SummaryItem>
        )}
        By signing this form, you certify that you have reviewed the information
        on this record and it accurately reflects all of your start and stop
        times of work, including meal breaks, in this period. If no hours are
        indicated, time will be computed based on specific union or{' '}
        {laborSpellCheck} law minimums. You also agree that the employer may
        take deductions from your earnings to adjust previous overpayments if
        and when said overpayments occur.
        <TextField
          sx={{ backgroundColor: 'background.default' }}
          onChange={e => {
            if (
              fullName.toLowerCase() !== e.target.value.trim().toLowerCase() &&
              didBlur.current
            ) {
              setErrorMsg(ERR_MSG);
            } else {
              setErrorMsg('');
            }
            setSignature(e.target.value);
          }}
          autoFocus
          onBlur={() => {
            didBlur.current = true;
            if (disableSubmit) {
              setErrorMsg(ERR_MSG);
            } else {
              setErrorMsg('');
            }
          }}
          onKeyDown={e => {
            if (e.key === 'Enter' && !disableSubmit) {
              handleSubmit();
            }
          }}
        />
        <Box sx={{ height: '25px', color: 'error.main' }}>{errorMsg}</Box>
        {fullName}
      </ContentBox>
      <FooterBox>
        <FooterButton
          sx={{ backgroundColor: 'background.default' }}
          variant="outlined"
          onClick={onCloseModal}
        >
          Cancel
        </FooterButton>
        <FooterButton onClick={handleSubmit} disabled={disableSubmit}>
          Approve
        </FooterButton>
      </FooterBox>
    </Box>
  );
};

EmpSignature.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  timecard: PropTypes.object,
};

export default compose(
  withModalDialog({
    dialog: SIGNATURE_MODAL,
    maxWidth: 'md',
    roundedCorners: true,
  }),
  connect(mapState, mapDispatch),
)(EmpSignature);
