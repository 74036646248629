import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Box, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

//icons
import SortIcon from '@mui/icons-material/Sort';
import FilterListIcon from '@mui/icons-material/FilterList';

//redux bits
import {
  setSortBy,
  setSearchText,
  clearAllFilters,
} from 'actions/digitalEdits';
import * as sel from 'selectors/digitalEdits';

//utils
import { useSingleDoubleClick } from 'utils/customHooks';
import { SORT_BYS } from '../digitalEditsUtils';

import Search from './Search';
import Filters from './Filters';
import Sort from './Sort';

const useStyles = makeStyles(({ palette }) => ({
  SearchFilterSort: {},
  SearchFilterSortContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  filerIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: 24,
    width: 24,
    border: `1px solid ${palette.primary.main}`,
    borderRadius: 2,
    margin: '5px',
    cursor: 'pointer',
    color: palette.primary.main,
  },

  iconRotate: {
    transform: 'scaleY(-1)',
  },
  iconActive: {
    '& path': {
      fill: palette.primary.background,
    },
    backgroundColor: `${palette.primary.main}`,
  },
}));

const mapState = state => ({
  sortBy: sel.getSortBy(state),
  filtersActive: sel.getFiltersActive(state),
  searchText: sel.getSearchText(state),
});

const mapDispatch = dispatch => ({
  onSetSortBy: sortBy => dispatch(setSortBy({ sortBy })),
  onSetSearchText: searchText => dispatch(setSearchText({ searchText })),
  onClearFilters: () => dispatch(clearAllFilters()),
});

const SearchFilterSort = props => {
  const classes = useStyles();
  const {
    sortBy,
    onSetSortBy,
    onSetSearchText,
    filtersActive,
    onClearFilters,
    searchText,
  } = props;

  const filterAnchor = React.useRef(null);
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);

  const sortAnchor = React.useRef(null);
  const [isSortOpen, setIsSortOpen] = React.useState(false);

  const { anySelected } = filtersActive;

  const filterTitle = true ? 'Double Click to clear filters' : '';
  const singleFilterClick = () => {
    setIsFilterOpen(true);
  };
  const doubleFilterClick = () => {
    setIsFilterOpen(false);
    onClearFilters();
  };
  const handleClickFilter = useSingleDoubleClick(
    singleFilterClick,
    doubleFilterClick,
  );

  const sortTitle = sortBy.id !== 0 ? 'Double Click to set default sort' : '';

  const singleSortClick = () => {
    setIsSortOpen(true);
  };
  const doubleSortClick = () => {
    setIsSortOpen(false);
    onSetSortBy(SORT_BYS[0]);
  };

  const handleClickSort = useSingleDoubleClick(
    singleSortClick,
    doubleSortClick,
  );

  return (
    <Box className={classes.SearchFilterSort}>
      <div className={classes.SearchFilterSortContainer}>
        <Search setSearchText={onSetSearchText} searchText={searchText} />
        <div className={classes.filerIcons}>
          <Tooltip arrow title={filterTitle}>
            <FilterListIcon
              ref={filterAnchor}
              onClick={handleClickFilter}
              className={clsx('PENDO_invoiceEditsFilter', classes.icon, {
                [classes.iconActive]: anySelected,
              })}
            />
          </Tooltip>
          <Tooltip arrow title={sortTitle}>
            <SortIcon
              ref={sortAnchor}
              onClick={handleClickSort}
              className={clsx('PENDO_invoiceEditsSort', classes.icon, {
                [classes.iconSpaced]: true,
                [classes.iconRotate]: sortBy.sortOrder !== 'decSort',
                [classes.iconActive]: sortBy.id !== 0,
              })}
            />
          </Tooltip>

          {/* Double clicking highlights nearby text. Adding this to catch that offscreen*/}
          {/* Otherwise the name of the first record gets highlighted */}
          <span
            className="doubleClickDecoy"
            style={{ position: 'absolute', left: -14 }}
          >
            .
          </span>
        </div>
        {/* Popovers */}
        <Filters
          anchorElRef={filterAnchor}
          isOpen={isFilterOpen}
          setIsOpen={setIsFilterOpen}
          filtersActive={filtersActive}
        />
        <Sort
          anchorElRef={sortAnchor}
          isOpen={isSortOpen}
          setIsOpen={setIsSortOpen}
          sortBy={sortBy}
          onSetSortBy={onSetSortBy}
          sortOptions={SORT_BYS}
        />
      </div>
    </Box>
  );
};

SearchFilterSort.propTypes = {
  sortBy: PropTypes.object.isRequired,
  onSetSortBy: PropTypes.func.isRequired,
  onSetSearchText: PropTypes.func.isRequired,
  filtersActive: PropTypes.object.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  searchText: PropTypes.string,
};

export default compose(connect(mapState, mapDispatch))(SearchFilterSort);
