import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, InputAdornment } from '@mui/material';
import { DECIMAL_NUM_REGEX } from '../CrewListUtils';

//selectors

//utils

// Default editable cell renderer
const MoneyCell = props => {
  const {
    disabled,
    baseOnFocus,
    baseOnChange,
    baseOnBlur,
    localVal,
    // error,
    id,
    originalValue,
    field,
    setLocalVal,
    meta,
  } = props;

  // const { errorMsg } = error;
  useEffect(() => {
    if (originalValue === field && meta.pristine) {
      setLocalVal(originalValue);
    }
  }, [originalValue, field, setLocalVal, meta.pristine]);

  const onChange = e => {
    const t = e.target.value;
    // ignore any imput thats not a number or decimal
    if (t.match(DECIMAL_NUM_REGEX) === null) {
      if (t === '') {
        baseOnChange(t);
      } else if (localVal) baseOnChange(localVal);
    } else {
      if (t.includes('.')) {
        const numSplit = t.split('.');
        if (
          numSplit[0].length > 4 ||
          (numSplit[0].length === 4 && +numSplit[0] > 1000)
        ) {
          baseOnChange(localVal);
        } else if (numSplit[0].length === 4 && +numSplit[0] === 1000) {
          baseOnChange('1000');
        } else {
          // limit to 4 decimal points
          baseOnChange(
            t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 5),
          );
        }
      } else {
        if (t.length > 4 || +t > 1000) {
          baseOnChange(localVal);
        } else {
          baseOnChange(t);
        }
      }
    }
  };

  return (
    <div>
      <Input
        id={id}
        type="text"
        disabled={disabled}
        value={localVal || ''}
        variant="outlined"
        inputProps={{ autoComplete: 'off' }}
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        onFocus={baseOnFocus}
        onChange={onChange} //normalize handled here
        onBlur={() => {
          baseOnBlur();
        }}
      />
    </div>
  );
};

MoneyCell.propTypes = {
  baseOnFocus: PropTypes.func.isRequired,
  baseOnChange: PropTypes.func.isRequired,
  baseOnBlur: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  originalValue: PropTypes.number,
  field: PropTypes.number,
  setLocalVal: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
};

export default MoneyCell;
