import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'decorators/withRouter';
import { LinearProgress } from '@mui/material';
//component
import { PageHeader } from 'components/Nav';
//container
import { ReviewsHeader } from 'containers/Employees/Reviews';
//selector
import { isUserInfoLoaded as getUserLoading } from 'selectors/session';

const mapState = (state, { match, history }) => ({
  projectId: match.params.projectId,
  location: history.location,
  isUserLoaded: getUserLoading(state),
});

class ReviewsNav extends Component {
  static propTypes = {
    projectId: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
  };

  render() {
    const { isUserLoaded } = this.props;

    if (!isUserLoaded) {
      return <LinearProgress />;
    }
    return (
      <div>
        <PageHeader title="Reviews" />
        <ReviewsHeader />
      </div>
    );
  }
}

export default withRouter(connect(mapState)(ReviewsNav));
