import React from 'react';
import PropTypes from 'prop-types';

import { TextField, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles(theme => ({
  FilterTextField: {},
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 4,
  },
}));

const FilterTextField = props => {
  const classes = useStyles();
  const { className, value, setValue } = props;

  return (
    <TextField
      className={className}
      value={value}
      onChange={e => setValue(e.target.value)}
      placeholder="Type to Filter..."
      onKeyDown={e => {
        if (e.key === 'Escape') {
          e.stopPropagation();
          setValue('');
        }
      }}
      InputProps={{
        style: { backgroundColor: 'white' },
        endAdornment: value ? (
          <IconButton onClick={() => setValue('')} className={classes.icon}>
            <ClearIcon />
          </IconButton>
        ) : (
          <SearchIcon className={classes.icon} />
        ),
      }}
    />
  );
};

FilterTextField.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};

export default FilterTextField;
