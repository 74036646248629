import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import _ from 'lodash';

//actions
import {
  runUserAccessReport,
  fetchUserAccessDbDetails,
  fetchUserAccessClientDetails,
  loadAllDBs,
  storeUserAccessReportDetails,
  storeUserAccessClientDetails,
} from 'actions/reports';

//selectors
import {
  getLoading as isUpdating,
  getUserAccessReportDetails,
  getUserAccessClientDetails,
  getDBsList,
} from 'selectors/useraccessreport';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginTop: '0px',
  },
  tablestyle: {
    width: '100%',
  },
  leftContent: {
    width: '25%',
  },
  rightContent: {
    width: '75%',
  },
  accordion: {
    marginTop: 30,
  },
  selectBox: {
    width: '40%',
  },
  inputButton: {
    margin: '0px',
    marginTop: '5px',
  },
  heading: {
    fontSize: '1.1em',
  },
}));
const mapState = state => ({
  loading: isUpdating(state),
  userAccessClientDetails: getUserAccessClientDetails(state),
  dbsList: getDBsList(state),
  userAccessReportDetails: getUserAccessReportDetails(state),
});
const mapDispatch = dispatch => ({
  onLoadFetch: () => {
    dispatch(storeUserAccessReportDetails({ userAccessReportDetails: [] }));
    dispatch(storeUserAccessClientDetails({ userAccessClientDetails: {} }));
    dispatch(loadAllDBs());
  },
  onFetchUserAccessDbDetails: dbCode => {
    dispatch(storeUserAccessReportDetails({ userAccessReportDetails: [] }));
    dispatch(storeUserAccessClientDetails({ userAccessClientDetails: {} }));
    if (dbCode !== '0') {
      dispatch(fetchUserAccessDbDetails({ dbCode }));
    }
  },
  onFetchUserAccessClientDetails: clientCode => {
    dispatch(storeUserAccessClientDetails({ userAccessClientDetails: {} }));
    if (
      clientCode?.useraccessDBId !== '0' &&
      clientCode?.useraccessClientId !== '0'
    ) {
      dispatch(fetchUserAccessClientDetails({ clientCode }));
    }
  },
  onRunUserAccessReport: report => {
    dispatch(runUserAccessReport({ report }));
  },
});
const UserAccessReport = props => {
  const {
    loading,
    dbsList,
    onLoadFetch,
    onFetchUserAccessDbDetails,
    onFetchUserAccessClientDetails,
    userAccessReportDetails,
    userAccessClientDetails,
    onRunUserAccessReport,
  } = props;
  const [expand, setExpand] = useState(false);
  const classes = useStyles();
  const [userAccessReport, setUserAccessReport] = useState({
    buttonflag: false,
    dbValue: 0,
    clientValue: 0,
    producerId: 0,
    productCompanyId: 0,
    projectId: 0,
    productCompanyList: [],
    projectsList: [],
  });

  useEffect(() => {
    onLoadFetch();
  }, [onLoadFetch]);

  const SelectDBClientsOptions = ({ options }) => {
    if (options && options.length > 0) {
      return (
        <>
          <option id={0} value={'All'}>
            All
          </option>
          {_.chain(options)
            .sortBy('code')
            .value()
            .map(option => (
              <option
                key={option.id}
                id={option.id}
                value={option.code}
                name={option.name}
              >
                {`${option.code}-${option.name}`}
              </option>
            ))}
        </>
      );
    } else {
      return <option></option>;
    }
  };
  const SelectDBCodeOptions = ({ options }) => {
    if (options && options.length > 0) {
      const newItemAll = { id: 0, code: 0, name: 'Select' };
      let dbsList = [newItemAll, ...options];
      return dbsList.map(option => {
        return (
          <option
            key={option.id}
            id={option.id}
            value={option.code}
            name={option.name}
          >
            {option.name}
          </option>
        );
      });
    } else {
      return <option></option>;
    }
  };
  const handleDBChange = e => {
    let buttonstatus = true;
    const selectedDbValue = e.target.value || 0;
    if (selectedDbValue === '0') buttonstatus = false;

    setUserAccessReport({
      buttonflag: buttonstatus,
      dbValue: selectedDbValue,
      clientValue: 0,
      producerId: 0,
      productCompanyId: 0,
      projectId: 0,
    });
    onFetchUserAccessDbDetails({
      useraccessDBId: selectedDbValue,
      type: 'userAccessReport',
    });
  };

  const handleClientChange = e => {
    const dbValue = userAccessReport.dbValue;
    const clientValue = e.target.value;
    setUserAccessReport(oldValues => {
      return {
        ...oldValues,
        clientValue: clientValue,
        producerId: 0,
        productCompanyId: 0,
        projectId: 0,
        productCompanyList: [],
        projectsList: [],
      };
    });
    onFetchUserAccessClientDetails({
      useraccessDBId: dbValue || 0,
      useraccessClientId: clientValue || 0,
      type: 'clientProjects',
    });
  };
  const handleProducersChange = e => {
    const producerId = e.target.value;
    const productCompanyList =
      producerId === '0'
        ? []
        : userAccessClientDetails.productionCompany.filter(
            pd => pd.masterid === producerId,
          );
    setUserAccessReport(oldValues => {
      return {
        ...oldValues,
        producerId: e.target.value,
        productCompanyList: productCompanyList,
        projectsList: [],
        productCompanyId: 0,
        projectId: 0,
      };
    });
  };
  const handleProductCompanyChange = e => {
    const productCompanyId = e.target.value;
    const projectsList =
      productCompanyId === '0'
        ? []
        : userAccessClientDetails.projects.filter(
            p => p.masterid === productCompanyId,
          );

    setUserAccessReport(oldValues => {
      return {
        ...oldValues,
        productCompanyId: productCompanyId,
        projectId: 0,
        projectsList: projectsList,
      };
    });
  };

  const handleProjectsChange = e => {
    const projectId = e.target.value;

    setUserAccessReport(oldValues => {
      return {
        ...oldValues,
        projectId: projectId,
      };
    });
  };
  const runUserAccessReport = () => {
    const getName = (id, list, str, field) => {
      const value = document.getElementById(id).value;
      let obj = list?.find(item => item[str] === value);
      return obj?.[field] || '';
    };
    const dbName = getName('dbId', dbsList, 'code', 'name');
    const clientName = getName(
      'clientId',
      userAccessReportDetails || [],
      'code',
      'name',
    );
    const producerName = getName(
      'producers',
      userAccessClientDetails.producer || [],
      'id',
      'name',
    );
    const prodcoName = getName(
      'productcompany',
      userAccessReport.productCompanyList || [],
      'id',
      'name',
    );
    const projectName = getName(
      'projects',
      userAccessReport.projectsList || [],
      'id',
      'name',
    );
    const dbRegion = getName('dbId', dbsList, 'code', 'region');
    onRunUserAccessReport({
      dbValue: userAccessReport.dbValue,
      clientValue: userAccessReport.clientValue,
      producerValue: userAccessReport.producerId,
      productValue: userAccessReport.productCompanyId,
      projectValue: userAccessReport.projectId,
      dbName,
      clientName,
      producerName,
      prodcoName,
      projectName,
      dbRegion,
    });
  };

  const SelectControlOptions = ({ options }) => {
    if (options && options.length > 0) {
      return (
        <>
          <option id={0} value={0}>
            All
          </option>
          {_.chain(options)
            .sortBy('name')
            .value()
            .map(option => (
              <option
                key={option.id}
                id={option.id}
                value={option.id}
                name={option.name}
              >
                {option.name}
              </option>
            ))}
        </>
      );
    } else {
      return <option></option>;
    }
  };

  return (
    <Accordion expanded={expand} className={classes.root}>
      <AccordionSummary
        className={classes.accordion}
        expandIcon={<ExpandMoreIcon />}
        onClick={() => setExpand(!expand)}
      >
        <Typography className={classes.heading}>User Access Report</Typography>
      </AccordionSummary>
      {loading && <LinearProgress />}
      <AccordionDetails>
        <div className={classes.childRoot}>
          <table className={classes.tablestyle}>
            <tbody>
              <tr>
                <td className={classes.leftContent}>
                  {' '}
                  <Typography>DB</Typography>
                </td>
                <td className={classes.rightContent}>
                  <div>
                    <select
                      className={classes.selectBox}
                      value={userAccessReport.dbValue}
                      onChange={handleDBChange}
                      id="dbId"
                    >
                      <SelectDBCodeOptions options={dbsList || []} />
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td className={classes.leftContent}>
                  {' '}
                  <Typography>Client</Typography>
                </td>
                <td className={classes.rightContent}>
                  <div>
                    <select
                      className={classes.selectBox}
                      value={userAccessReport.clientValue}
                      onChange={handleClientChange}
                      id="clientId"
                    >
                      <SelectDBClientsOptions
                        options={userAccessReportDetails || []}
                      />
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td className={classes.leftContent}>
                  {' '}
                  <Typography>Producers</Typography>
                </td>
                <td className={classes.rightContent}>
                  <div>
                    <select
                      className={classes.selectBox}
                      value={userAccessReport.producerId}
                      onChange={handleProducersChange}
                      id="producers"
                    >
                      <SelectControlOptions
                        options={userAccessClientDetails.producer || []}
                      />
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td className={classes.leftContent}>
                  {' '}
                  <Typography>Prod Co.</Typography>
                </td>
                <td className={classes.rightContent}>
                  <div>
                    <select
                      className={classes.selectBox}
                      value={userAccessReport.productCompanyId}
                      onChange={handleProductCompanyChange}
                      id="productcompany"
                    >
                      <SelectControlOptions
                        options={userAccessReport.productCompanyList || []}
                      />
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td className={classes.leftContent}>
                  {' '}
                  <Typography>Projects</Typography>
                </td>
                <td className={classes.rightContent}>
                  <div>
                    <select
                      className={classes.selectBox}
                      value={userAccessReport.projectId}
                      onChange={handleProjectsChange}
                      id="projects"
                    >
                      <SelectControlOptions
                        options={userAccessReport.projectsList || []}
                      />
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td className={classes.leftContent}></td>
                <td className={classes.rightContent}>
                  <Button
                    className={classes.inputButton}
                    variant="contained"
                    color="primary"
                    onClick={runUserAccessReport}
                    disabled={loading || !userAccessReport.buttonflag}
                  >
                    Run
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
export default connect(mapState, mapDispatch)(UserAccessReport);
