export const CREW_MEMBER_TC_FIELDS = {
  ndbIn: {
    label: 'Non-Deductible Breakfast - In',
    type: 'time',
    employeeDescription:
      'If you got a breakfast, put the time that breakfast ended.',
    accessor: 'ndbIn',
    isDefaultVisible: true,
    isAdditionalField: false,
  },
  meal1: {
    label: 'Meal 1',
    type: 'time',
    employeeDescription: 'Lunch',
    accessor: 'meal1',
    isDefaultVisible: true,
    isAdditionalField: false,
  },
  lastMan1In: {
    label: 'Last Man In',
    type: 'time',
    employeeDescription: '---',
    accessor: 'lastMan1In',
    isDefaultVisible: true,
    isAdditionalField: false,
  },
  ndm: {
    label: 'Non-Deductible Meal',
    type: 'time',
    employeeDescription:
      'Use this to indicate when you got a walking 2nd meal (food, but no formal break)',
    accessor: 'ndm',
    isDefaultVisible: true,
    isAdditionalField: false,
  },
  meal2: {
    label: 'Meal 2',
    type: 'time',
    employeeDescription: 'Used to indicate when you took a break for 2nd meal',
    accessor: 'meal2',
    isDefaultVisible: true,
    isAdditionalField: false,
  },
  episode: {
    label: 'Episode ',
    type: 'autocomplete',
    employeeDescription:
      'The episode you were working on at the start of the day. Confirm with your department head if you’re uncertain.',
    accessor: 'episode',
    isDefaultVisible: true,
    isAdditionalField: false,
  },
  occupationCode: {
    label: 'Occupation',
    type: 'autocomplete',
    employeeDescription:
      'Use this for when you’re working a different occupation for the day (aka re-rates).',
    accessor: 'occupationCode',
    isDefaultVisible: true,
    isAdditionalField: false,
  },
  state: {
    label: 'State',
    caLabel: 'Province',
    type: 'autocomplete',
    employeeDescription: '---',
    accessor: 'state',
    isDefaultVisible: true,
    isAdditionalField: false,
  },
  city: {
    label: 'City',
    type: 'autocomplete',
    employeeDescription: '---',
    accessor: 'city',
    isDefaultVisible: true,
    isAdditionalField: false,
  },
  country: {
    label: 'Country',
    type: 'autocomplete',
    category: 'location',
    employeeDescription: '---',
    accessor: 'country',
    isDefaultVisible: false,
    isAdditionalField: true,
  },
  county: {
    label: 'County',
    type: 'autocomplete',
    category: 'location',
    employeeDescription: '---',
    accessor: 'county',
    isDefaultVisible: false,
    isAdditionalField: true,
  },
  workSubdivision: {
    label: 'PSD',
    type: 'autocomplete',
    category: 'location',
    employeeDescription: '---',
    accessor: 'workSubdivision',
    isDefaultVisible: false,
    isAdditionalField: true,
  },
  locationType: {
    label: 'Work Zone',
    type: 'autocomplete',
    category: 'location',
    employeeDescription: '---',
    accessor: 'locationType',
    isDefaultVisible: false,
    isAdditionalField: true,
  },
  accountCode: {
    label: 'Account Code',
    type: 'text',
    category: 'accountCoding',
    employeeDescription: '---',
    accessor: 'accountCode',
    isDefaultVisible: false,
    isAdditionalField: true,
  },
  series: {
    label: 'Series',
    type: 'text',
    category: 'accountCoding',
    accessor: 'series',
    isDefaultVisible: false,
    isAdditionalField: true,
  },
  location: {
    label: 'Location',
    type: 'text',
    category: 'accountCoding',
    employeeDescription: '---',
    accessor: 'location',
    isDefaultVisible: false,
    isAdditionalField: true,
  },
  set: {
    label: 'Set',
    type: 'text',
    category: 'accountCoding',
    accessor: 'set',
    isDefaultVisible: false,
    isAdditionalField: true,
  },
  insurance: {
    label: 'Insurance',
    type: 'text',
    category: 'accountCoding',
    employeeDescription: '---',
    accessor: 'insurance',
    isDefaultVisible: false,
    isAdditionalField: true,
  },
  freeField1: {
    label: 'Free Field 1',
    type: 'text',
    category: 'accountCoding',
    employeeDescription: '---',
    accessor: 'freeField1',
    isDefaultVisible: false,
    isAdditionalField: true,
  },
  freeField2: {
    label: 'Free Field 2',
    type: 'text',
    category: 'accountCoding',
    employeeDescription: '---',
    accessor: 'freeField2',
    isDefaultVisible: false,
    isAdditionalField: true,
  },
  freeField3: {
    label: 'Free Field 3',
    type: 'text',
    category: 'accountCoding',
    employeeDescription: '---',
    accessor: 'freeField3',
    isDefaultVisible: false,
    isAdditionalField: true,
  },
  freeField4: {
    label: 'Free Field 4',
    type: 'text',
    category: 'accountCoding',
    employeeDescription: '---',
    accessor: 'freeField4',
    isDefaultVisible: false,
    isAdditionalField: true,
  },
  meal3: {
    label: 'Meal 3',
    type: 'time',
    category: 'time',
    employeeDescription: '---',
    accessor: 'meal3',
    isDefaultVisible: false,
    isAdditionalField: true,
  },
  startTravel: {
    label: 'Start Travel ',
    type: 'time',
    category: 'time',
    employeeDescription: '---',
    accessor: 'startTravel',
    isDefaultVisible: false,
    isAdditionalField: true,
  },
  travelEnd: {
    label: 'Travel End',
    type: 'time',
    category: 'time',
    employeeDescription: '---',
    accessor: 'travelEnd',
    isDefaultVisible: false,
    isAdditionalField: true,
  },
};

export const CREW_MEMBER_DESCRIPTION_MAX_LENGTH = 250;

export const HIDE_CANADA_FIELDS = [
  'city',
  'county',
  'locationType',
  'workSubdivision',
];
