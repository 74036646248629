import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyleSheet } from 'shared/theme';
import { compose } from 'utils/helperFunctions';
import { Grid } from '@mui/material';
// containers
import Departments from './Departments';
import ApprovalFlow from './ApprovalFlow';
// actions
import * as actions from 'actions/reviewFlows';

const style = () => ({
  root: {
    marginTop: 10,
  },
});

const mapDispatchToProps = dispatch => ({
  onFetchData: reviewFlowId => {
    dispatch(actions.fetchSteps({ reviewFlowId }));
    dispatch(actions.fetchDepartments({ reviewFlowId }));
  },
});

class ReviewFlow extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onFetchData: PropTypes.func.isRequired,
    reviewFlow: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { onFetchData, reviewFlow } = this.props;
    onFetchData(reviewFlow.id);
  }

  render() {
    const { classes, reviewFlow } = this.props;

    return (
      <Grid
        container
        className={classes.root}
        spacing={5}
        alignItems="flex-start"
        direction="row"
        justifyContent="space-between"
      >
        <Grid item md={6} xs={12}>
          <Departments reviewFlowId={reviewFlow.id} />
        </Grid>
        <Grid item md={6} xs={12}>
          <ApprovalFlow reviewFlow={reviewFlow} />
        </Grid>
      </Grid>
    );
  }
}
export default compose(
  withStyleSheet('ReviewFlow', style),
  connect(null, mapDispatchToProps),
)(ReviewFlow);
