import React from 'react';
import { TableCell, TableRow, Typography, Paper, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InvoiceApproverIcon from '@mui/icons-material/AssignmentTurnedIn';
import { ROLE_LABELS, IA } from 'components/props/profiles';
import { Label } from 'components/Shared/Text';
import { TableList } from 'components/Shared/TableList';
import { readableUsers } from 'feature/CrewList/CrewListUtils';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 16,
    overflowX: 'auto',
  },
  empty: {
    textAlign: 'center',
  },
  invoiceIcon: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function ProjectsList(props) {
  const { projects = [] } = props;

  const classes = useStyles();
  const headers = [
    {
      columnId: 'projectId',
      label: 'Project Id',
      sortable: true,
      compact: true,
    },
    {
      columnId: 'projectName',
      label: 'Project Name',
      sortable: true,
      compact: true,
    },
    {
      columnId: 'productionCompanyName',
      label: 'Production Company Name',
      sortable: true,
      compact: true,
    },
    {
      columnId: 'profileName',
      label: 'User',
      sortable: true,
      compact: true,
    },
    { columnId: 'role', label: 'User Role', sortable: true, compact: true },
    {
      columnId: 'deptName',
      label: 'Department',
      sortable: true,
      compact: true,
    },
    {
      columnId: 'status',
      label: 'Status',
      sortable: true,
      compact: false,
    },
    { columnId: 'isDraft', label: 'Draft?', sortable: true, compact: true },
    { columnId: 'isHidden', label: 'Hide?', sortable: true, compact: true },
    {
      columnId: 'isArchived',
      label: 'Archived?',
      sortable: true,
      compact: true,
    },
  ];

  let contentRows = null;

  if (projects && projects.length <= 0) {
    contentRows = (
      <TableRow>
        <TableCell colSpan={10} rowSpan={3} className={classes.empty}>
          <Typography variant="h6">No results found</Typography>
        </TableCell>
      </TableRow>
    );
  } else {
    contentRows = readableUsers(
      projects.map(item => ({ ...item, worksightId: item.userWorksightid })),
    );
    contentRows = contentRows.map(user => (
      <TableRow hover key={user.projectId} className={classes.row}>
        <TableCell>{user.projectId}</TableCell>
        <TableCell>{user.projectName}</TableCell>
        <TableCell>{user.productionCompanyName}</TableCell>
        <TableCell>
          <Typography>{user.profileName}</Typography>
          <Typography>{user.profileEmail}</Typography>
        </TableCell>
        <TableCell>
          <div className={classes.invoiceIcon}>
            <Typography> {user.readableRole} </Typography>
            <Typography>
              {user?.roles?.includes(IA) && (
                <Tooltip title={ROLE_LABELS[IA]}>
                  <InvoiceApproverIcon sx={{ ml: 1 }} />
                </Tooltip>
              )}
            </Typography>
          </div>
        </TableCell>

        <TableCell>{user.deptName}</TableCell>
        <TableCell>
          <Label uppercase primary={user.status === 'accepted'}>
            {user.status}
          </Label>
        </TableCell>
        <TableCell size="small">{user.isDraft ? 'Yes' : 'No'}</TableCell>
        <TableCell size="small">{user.isHidden ? 'Yes' : 'No'}</TableCell>
        <TableCell size="small">{user.isArchived ? 'Yes' : 'No'}</TableCell>
      </TableRow>
    ));
  }
  return (
    <Paper className={classes.root}>
      <TableList headers={headers}>{contentRows}</TableList>
    </Paper>
  );
}
