import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { push } from 'redux-first-history';

import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ErrorIcon from '@mui/icons-material/Error';

//actions
import { setFromURL } from 'actions/timecards';
import { showAlert } from 'actions/alert';

//Selectors
import { currentUser } from 'selectors/session';

//utils
import { DH, PA, UPM } from 'components/props/profiles';
import { getProjectFromURL } from 'utils/helperFunctions';
import { makeDropDownDisplay } from 'feature/DTS/dtsUtils';
import { baseCellStyles, NUMERIC_CELLS } from 'feature/DTS/dtsUtils';
import { showToolTip } from 'components/Shared/ReactTable/reactTableUtils';

//comps
import CheckboxCell from './CheckboxCell';

const useStyles = makeStyles(({ palette }) => ({
  root: baseCellStyles,
  nameLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: palette.misc.link,
    },
  },
  errorIcon: { color: 'red', height: 18, width: 18, padding: 2 },
}));

const mapState = (state, { match }) => ({
  currentUserInfo: currentUser(state),
});

const mapDispatch = dispatch => ({
  onClickName: ({ currentUserRole, timecard }) => {
    const pathname = window.location.pathname;
    const projectId = getProjectFromURL(pathname);
    const { timecardEntryHeaderId, batchWorksightId } = timecard;
    let toURL = `/projects/${projectId}`;
    try {
      if (currentUserRole === DH) {
        toURL += `/navto/timecard?timecardEntryHeaderId=${timecardEntryHeaderId}`;
      } else if (currentUserRole === PA || currentUserRole === UPM) {
        toURL += `/review/ready/${batchWorksightId}/wtc?timecardHeaderId=${timecardEntryHeaderId}`;
      }
      dispatch(setFromURL({ fromURI: pathname }));
      dispatch(push(toURL));
    } catch (error) {
      console.error('Error creating URL for timecard view');
      dispatch(showAlert());
    }
  },
});

//custom styling for tooltip
const useStylesToolTip = makeStyles(theme => ({
  tooltip: {
    fontSize: 14,
  },
  numberCell: {
    textAlign: 'right',
    '& > input': {
      textAlign: 'right',
    },
  },
}));

const DisplayOnlyCell = props => {
  const {
    value,
    row,
    row: {
      index,
      original,
      original: { userFriendlyTimecardStatus },
    },
    column: { id: columnId, type: columnType },
    currentUserInfo: { role: currentUserRole },
    onClickName,
  } = props;

  const classes = useStyles();
  const toolTipClasses = useStylesToolTip();

  const isNameField = columnId === 'firstName' || columnId === 'lastName';
  const isHasError = columnId === 'errors';

  const isNumCell = NUMERIC_CELLS.includes(columnId);

  let displayValue;
  let toolTipText;
  switch (columnType) {
    case 'auto-complete':
      displayValue = makeDropDownDisplay(value, columnId);
      toolTipText = displayValue;
      break;

    case 'checkbox':
      displayValue = (
        <CheckboxCell
          id={`${index}.${columnId}`}
          baseOnChange={() => {
            console.warn('BaseOnChange for displayCell');
          }}
          baseOnBlur={() => {
            console.warn('baseOnBlur for displayCell');
          }}
          toggleCTA={() => {
            console.warn('toggleCTA for displayCell');
          }}
          disabled
          localVal={value || false}
          column={{ id: columnId }}
          row={row}
        />
      );
      break;

    case 'time':
    case 'mask':
    default:
      displayValue = value;
      toolTipText = displayValue;
      break;
  }

  let numClass = '';
  if (isNumCell) {
    numClass = classes.numberCell;
  }

  if (isNameField && userFriendlyTimecardStatus) {
    //userFriendlyStatus is '' for draft candidate timecards
    displayValue = (
      <span
        className={classes.nameLink}
        onClick={() => onClickName({ timecard: original, currentUserRole })}
      >
        {displayValue}
      </span>
    );
  }

  if (isHasError) {
    if (_.isEmpty(value)) displayValue = '';
    else {
      const errors = [];
      for (const key in value) {
        if (Object.hasOwnProperty.call(value, key)) {
          const errorText = value[key];
          const errorElm = <div key={key}>{errorText}</div>;
          errors.push(errorElm);
        }
      }
      displayValue = (
        <Tooltip arrow title={errors}>
          <ErrorIcon className={classes.errorIcon} />
        </Tooltip>
      );
    }
  }

  const divValue = (
    <div className={clsx(classes.root, numClass)} id={`${index}.${columnId}`}>
      {displayValue}
    </div>
  );

  if (toolTipText) {
    return (
      <Tooltip
        title={showToolTip(toolTipText) && !isHasError ? displayValue : ''}
        arrow
        // enterNextDelay={500} Not supported by MUI 4.2.0 need >4.9.4
        enterDelay={1200}
        classes={toolTipClasses}
      >
        {divValue}
      </Tooltip>
    );
  } else {
    return divValue;
  }
};

DisplayOnlyCell.propTypes = {
  value: PropTypes.any,
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.object.isRequired,
  }).isRequired,
  column: PropTypes.object.isRequired,
  currentUserInfo: PropTypes.object.isRequired,
  onClickName: PropTypes.func.isRequired,
};

export default compose(connect(mapState, mapDispatch))(DisplayOnlyCell);
