import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Play from '@mui/icons-material/PlayCircleOutline';

import { Label } from 'components/Shared/Text';

export default function StartProject({
  onStartProject,
  isStartProjectBtnEnabled,
  isAdminUser,
}) {
  return (
    <div>
      {isAdminUser && <Label transparent>Draft</Label>}
      {isStartProjectBtnEnabled && (
        <Button startIcon={<Play />} onClick={onStartProject}>
          Start Project
        </Button>
      )}
    </div>
  );
}

StartProject.propTypes = {
  onStartProject: PropTypes.func.isRequired,
  isStartProjectBtnEnabled: PropTypes.bool.isRequired,
  isAdminUser: PropTypes.bool.isRequired,
};
