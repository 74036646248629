import React from 'react';
import PropTypes from 'prop-types';

// import { useTheme } from '@mui/material';

//Need forward ref for tooltip to work
const DeliveryIcon = React.forwardRef((props, ref) => {
  //   const theme = useTheme();
  //   const { palette } = theme;

  return (
    <svg
      ref={ref}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10" cy="10" r="10" fill="#00DF68" />
      <path
        d="M15.6673 7.66659H13.9173V5.33325H5.75065C5.10898 5.33325 4.58398 5.85825 4.58398 6.49992V12.9166H5.75065C5.75065 13.8849 6.53232 14.6666 7.50065 14.6666C8.46898 14.6666 9.25065 13.8849 9.25065 12.9166H12.7507C12.7507 13.8849 13.5323 14.6666 14.5007 14.6666C15.469 14.6666 16.2507 13.8849 16.2507 12.9166H17.4173V9.99992L15.6673 7.66659ZM15.3757 8.54159L16.519 9.99992H13.9173V8.54159H15.3757ZM7.50065 13.4999C7.17982 13.4999 6.91732 13.2374 6.91732 12.9166C6.91732 12.5958 7.17982 12.3333 7.50065 12.3333C7.82148 12.3333 8.08398 12.5958 8.08398 12.9166C8.08398 13.2374 7.82148 13.4999 7.50065 13.4999ZM8.79565 11.7499C8.47482 11.3941 8.01982 11.1666 7.50065 11.1666C6.98148 11.1666 6.52648 11.3941 6.20565 11.7499H5.75065V6.49992H12.7507V11.7499H8.79565ZM14.5007 13.4999C14.1798 13.4999 13.9173 13.2374 13.9173 12.9166C13.9173 12.5958 14.1798 12.3333 14.5007 12.3333C14.8215 12.3333 15.084 12.5958 15.084 12.9166C15.084 13.2374 14.8215 13.4999 14.5007 13.4999Z"
        fill="#008006"
      />
    </svg>
  );
});

DeliveryIcon.propTypes = { className: PropTypes.string };

export default DeliveryIcon;
