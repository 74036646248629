import React, { useRef, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Tooltip, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { setCurrentRecordId, clearRecordHasErrors } from 'actions/digitalEdits';

import {
  getCurrentRecordId,
  getRecordListLoading,
  getHasPendingTimecards,
  getHasUnsavedErrors,
  getHasTimeoutErrors,
} from 'selectors/digitalEdits';

import { makeNameFromRecord, scrollToElement } from './digitalEditsUtils';

import CalculateIcon from '@mui/icons-material/Calculate';
import ErrorIcon from '@mui/icons-material/Error';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const useStyles = makeStyles(({ palette }) => ({
  // SidebarListItem: {},
  listItem: {
    minHeight: 24,
    backgroundColor: palette.background.paper,
    borderRadius: 24,
    cursor: 'pointer',
    margin: '0px 0px 8px 12px',
    paddingLeft: '12px',
    paddingRight: '12px',
    color: palette.common.black,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  letterHeader: {
    fontSize: 18,
    fontWeight: 800,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    margin: 12,
  },
  currentRecord: {
    color: palette.primary.main,
    background: palette.primary['+9'],
  },
  currentOutLine: {
    border: `1px solid ${palette.primary.main}`,
  },
  hasEdits: {
    background: palette.misc.cellHighlight,
  },
  hasPending: {
    background: palette.gray['+2'],
    color: palette.gray['+9'],
  },
  hasErrors: {
    background: palette.error.main,
    color: palette.error.contrastText,
  },
}));

const mapState = (state, ownProps) => {
  const currentRecordId = getCurrentRecordId(state);
  const recordId = ownProps.record.recordId;
  return {
    isCurrentRecord: currentRecordId === recordId,
    loading: getRecordListLoading(state),
    hasPending: getHasPendingTimecards(state, recordId),
    hasUnsavedError: getHasUnsavedErrors(state, recordId),
    hasTimeoutError: getHasTimeoutErrors(state, recordId),
  };
};

const mapDispatch = dispatch => ({
  onSetCurrentRecordId: currentRecordId => {
    dispatch(setCurrentRecordId({ currentRecordId }));
  },
  onClearHasErrors: recordId => {
    dispatch(clearRecordHasErrors({ recordId }));
  },
});

const SidebarListItem = props => {
  const classes = useStyles();
  const {
    record,
    sectionHeader,
    addSectionHeader,
    isCurrentRecord,
    listContainerRef,
    loading,
    onSetCurrentRecordId,
    onClearHasErrors,
    hasPending,
    hasUnsavedError,
    hasTimeoutError,
  } = props;

  const hasPendingRef = useRef(hasPending);

  React.useEffect(() => {
    if (hasPendingRef.current !== hasPending && hasPending) {
      onClearHasErrors(record.recordId);
    }
    hasPendingRef.current = hasPending;
  }, [hasPending, onClearHasErrors, record.recordId]);

  //if current record is set when the page fully loads, scroll to that record
  const initialLoadRef = useRef(false);
  const hasBeenClickedRef = useRef(false);
  useEffect(() => {
    if (loading === false) initialLoadRef.current = true;
  }, [loading]);
  useEffect(() => {
    if (initialLoadRef.current) {
      initialLoadRef.current = false;
      if (isCurrentRecord && hasBeenClickedRef.current) {
        scrollToElement(record.recordId);
      }
    }
  }, [isCurrentRecord, loading, record.recordId]);

  useEffect(() => {
    if (isCurrentRecord) {
      const parent = listContainerRef.current;
      const element = document.getElementById(
        `digital-edits-sidebar-${record.recordId}`,
      );
      const elmBounding = element?.getBoundingClientRect();
      const topOffset = 78;
      const elmTop = elmBounding.top - topOffset;
      const elmBtm = elmBounding.bottom;
      if (elmBounding) {
        if (elmTop <= 0) {
          parent.scrollBy({
            left: 0,
            top: elmTop - parent.clientHeight / 2,
            behavior: 'smooth',
          });
        } else if (elmBtm - parent.clientHeight >= 0) {
          parent.scrollBy({
            left: 0,
            top:
              elmTop -
              parent.clientHeight +
              elmBounding.height +
              parent.clientHeight / 2,
            behavior: 'smooth',
          });
        }
      }
    }
  }, [isCurrentRecord, record.recordId, listContainerRef]);

  const name = makeNameFromRecord(record);

  const { isEdited, hasErrors, recordId } = record;

  let variant = { endAdornment: null, classes: '' };

  if (hasPending) {
    variant.endAdornment = (
      <Tooltip title="Pending">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={17} sx={{ color: 'gray.+7' }} />
        </Box>
      </Tooltip>
    );
    variant.classes = variant.classes = clsx(classes.hasPending, {
      [classes.currentOutLine]: isCurrentRecord,
    });
  } else if (hasUnsavedError || hasErrors) {
    variant.endAdornment = (
      <Tooltip title="Error">
        <ErrorIcon
          sx={{ color: 'error.contrastText', height: 17, width: 17 }}
        />
      </Tooltip>
    );

    variant.classes = variant.classes = clsx(classes.hasErrors, {
      [classes.currentOutLine]: isCurrentRecord,
    });
  } else if (hasTimeoutError) {
    variant.endAdornment = (
      <Tooltip title="Timeout Error">
        <ReportProblemIcon
          sx={{ color: 'warning.main', height: 17, width: 17 }}
        />
      </Tooltip>
    );
    variant.classes = variant.classes = clsx(classes.hasErrors, {
      [classes.currentOutLine]: isCurrentRecord,
    });
  } else if (isEdited) {
    variant.classes = variant.classes = clsx(classes.hasEdits, {
      [classes.currentOutLine]: isCurrentRecord,
    });
    variant.endAdornment = (
      <Tooltip title="Recalculated">
        <CalculateIcon
          sx={{ color: isCurrentRecord ? 'primary.main' : 'warning.dark' }}
        />
      </Tooltip>
    );
  } else if (isCurrentRecord) {
    variant.classes = clsx(classes.currentOutLine);
  }

  return (
    <Fragment>
      {addSectionHeader && (
        <Box className={classes.letterHeader}>{sectionHeader}</Box>
      )}
      <Box
        id={`digital-edits-sidebar-${recordId}`}
        className={clsx(classes.listItem, variant.classes)}
        onClick={() => {
          onSetCurrentRecordId(recordId);
          hasBeenClickedRef.current = true;
          if (!loading) {
            scrollToElement(recordId);
          }
        }}
      >
        {name}
        {variant.endAdornment}
      </Box>
    </Fragment>
  );
};

SidebarListItem.propTypes = {
  record: PropTypes.object.isRequired,
  sectionHeader: PropTypes.string.isRequired,
  addSectionHeader: PropTypes.bool.isRequired,
  isCurrentRecord: PropTypes.bool.isRequired,
  listContainerRef: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onSetCurrentRecordId: PropTypes.func.isRequired,
};

export default connect(mapState, mapDispatch)(SidebarListItem);
