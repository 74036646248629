import React from 'react';

import {
  Typography,
  Accordion,
  //None of these are needed anymore, the panel doesn't expand...
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import WarningIcon from '@mui/icons-material/Warning';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Bell from 'components/images/Bell';

const style = ({ palette }) => ({
  root: {
    maxWidth: 375,
    maxHeight: 512,
    boxShadow: 'none',
  },
  notificationTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  notificationsCount: {
    color: palette.primary.main,
    backgroundColor: palette.primary.background,
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 6,
    marginRight: 10,
    padding: '1px 3px 1px 2px',
    borderRadius: 3,
  },
  expansionSummary: {
    display: 'flex',
    padding: 0,
    flexDirection: 'row',
    color: palette.text.secondary,
    fontSize: 18,
    marginBottom: 8,
  },
  AccordionDetails: {
    padding: 0,
  },
  container: {
    width: '100%',
  },
  emptyNotifications: {
    border: `1px dashed ${palette.gray['+7']} `,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: 400,
    color: palette.primary.main,
    fontWeight: 500,
    '& section': {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'space-around',
      alignItems: 'center',
    },
  },
  allNotifications: {
    maxHeight: 512,
    minHeight: 512,
    overflow: 'auto',
  },
  bodyGroup: {
    display: 'flex',
    marginTop: 8,
    marginBottom: 8,
    marginRight: 40,
  },
  icon: {
    alignSelf: 'center',
    marginLeft: 16,
    marginRight: 8,
  },
  message: {
    lineHeight: 0,
  },
  error: {
    // red
    color: palette.error.main,
  },
  warning: {
    // orange
    color: palette.warning.main,
  },
  information: {
    // blue
    color: palette.info.main,
  },
});

const Notifications = ({
  classes,
  count = 0,
  warnings = [],
  errors = [],
  infos = [],
}) => {
  let notificationsCount = count >= 10 ? count : '0' + count;

  const addDupCount = msgArray => {
    const obj = {};
    msgArray.forEach(msg => {
      obj[msg] = obj[msg] === undefined ? 1 : obj[msg] + 1;
    });
    return Object.keys(obj).map(msg => {
      const count = obj[msg] === 1 ? '' : ` (${obj[msg]})`;
      return `${msg}${count}`;
    });
  };

  errors = addDupCount(errors);
  warnings = addDupCount(warnings);
  infos = addDupCount(infos);

  const renderNotes = (array, setting) => {
    return array.map((message, i) => {
      let strippedMessage = message.replace(/(<([^>]+)>)/gi, '');
      return (
        <div
          key={strippedMessage}
          className={`${classes.bodyGroup} ${classes[setting]}`}
        >
          <div className={classes.icon}>
            {setting === 'information' ? <AccessTimeIcon /> : <WarningIcon />}
          </div>
          <Typography>{strippedMessage}</Typography>
        </div>
      );
    });
  };

  return (
    <Accordion className={classes.root} expanded={true}>
      <AccordionSummary
        classes={{
          root: classes.expansionSummary,
        }}
      >
        <Typography>
          <span className={classes.notificationTitle}>Notifications</span>
          <span className={classes.notificationsCount}>
            {notificationsCount}
          </span>
        </Typography>
      </AccordionSummary>

      <AccordionDetails className={classes.AccordionDetails}>
        <div className={classes.container}>
          <div className={classes.allNotifications}>
            {count > 0 ? (
              [
                renderNotes(errors, 'error'),
                renderNotes(infos, 'information'),
                renderNotes(warnings, 'warning'),
              ]
            ) : (
              <div className={classes.emptyNotifications}>
                <section>
                  <Bell />
                  <Typography>You have no notifications</Typography>
                </section>
              </div>
            )}
          </div>
        </div>
        <div />
      </AccordionDetails>
    </Accordion>
  );
};

export default withStyles(style)(Notifications);
