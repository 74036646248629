import createAction from './createAction';

export const loading = createAction('loading/events');
export const fetch = createAction('fetch/events');
export const store = createAction('store/events');
export const signalRNotification = createAction('signalR/notification');
export const signalRNotificationUpdates = createAction(
  'signalR/notificationUpdates',
);
export const signalRJobSearchDelete = createAction('signalR/SearchDelete');
export const signalRJobMoveComplete = createAction('signalR/JobMoveComplete');

export const invoiceStatusChangeComplete = createAction(
  'signalR/InvoiceStatusChangeComplete',
);
export const timecardDistributionImport = createAction(
  'signalR/timecardDistributionImport',
);
