import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material';

const Bell = ({ className, color }) => {
  const theme = useTheme();
  const { palette } = theme;

  let colorVal = _.get(palette, color, palette.gray.primary);

  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 12.0902C9.97907 11.3602 11.3378 10.8527 12.75 10.8527V6.05L8.53125 2H2.90625C2.13281 2 1.50703 2.6075 1.50703 3.35L1.5 14.15C1.5 14.8925 2.12578 15.5 2.89922 15.5L7.43672 15.5C7.37005 15.4059 7.16009 15.0882 7.01617 14.7755C7.43672 13.6538 8.12508 12.7425 9 12.0902ZM11.6578 6.81395L7.72573 3.04651V6.81395H11.6578ZM8.63411 15.5C8.48455 15.263 8.35569 15.0122 8.25 14.75C8.95773 12.994 10.7045 11.75 12.75 11.75C14.7955 11.75 16.5423 12.994 17.25 14.75C16.5423 16.506 14.7955 17.75 12.75 17.75C11.01 17.75 9.4861 16.8498 8.63411 15.5ZM12.75 12.8145C11.6308 12.8145 10.7225 13.7033 10.7225 14.7984C10.7225 15.0454 10.7687 15.2818 10.8531 15.5C11.1429 16.2492 11.8832 16.7823 12.75 16.7823C13.8692 16.7823 14.7775 15.8935 14.7775 14.7984C14.7775 13.7033 13.8692 12.8145 12.75 12.8145ZM11.7911 15.5C12.0157 15.7745 12.3614 15.95 12.75 15.95C13.4291 15.95 13.9773 15.414 13.9773 14.75C13.9773 14.086 13.4291 13.55 12.75 13.55C12.0709 13.55 11.5227 14.086 11.5227 14.75C11.5227 15.0341 11.6231 15.2947 11.7911 15.5Z"
        fill={colorVal}
      />
    </svg>
  );
};

Bell.propTypes = { className: PropTypes.string, color: PropTypes.string };

export default Bell;
