import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Hidden, Toolbar, Button } from '@mui/material';
import { compose } from 'utils/helperFunctions';
import { withStyleSheet } from 'shared/theme';

//components
import { AppBar, Brand } from 'components/Nav';
import { profileProps } from 'components/props/profiles';
import { default as UserMenu } from 'components/Shared/UserMenu';
import { AppDrawer, HeaderNav } from '../Nav';

import BackIcon from '@mui/icons-material/NavigateBefore';

// Actions
import { onTakeMeBack } from 'actions/timecards';
import { toggleTheme } from 'actions/flag';

// Selectors
import { isFlagEnabled } from 'selectors/flags';
import {
  currentUser,
  authenticated,
  getPromptOnLeavePage,
  getSessionUser,
} from 'selectors/session';

const style = ({ palette }) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: 'inherit',
  },

  navItems: {
    flexGrow: 1,
    order: 0,
    flex: '1 1 auto',
    alignSelf: 'auto',
  },
  userMenu: {
    order: 0,
    flex: '0 1 auto',
    alignSelf: 'auto',
  },
  button: {
    color: palette.primary.contrastText,
  },
});

const mapStateToProps = state => ({
  isLoggedIn: authenticated(state),
  currentUser: currentUser(state),
  promptOnLeavePage: getPromptOnLeavePage(state),
  canToggleTheme: isFlagEnabled(state, 'color_toggle'),
  projectsUser: getSessionUser(state),
});

const mapDispatchToProps = dispatch => ({
  onTakeMeBackAction: () => dispatch(onTakeMeBack()),
  onToggleTheme: () => dispatch(toggleTheme()),
});

class Header extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    currentUser: profileProps,
    isLoggedIn: PropTypes.bool,
    canToggleTheme: PropTypes.bool,
    onToggleTheme: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isLoggedIn: false,
    currentUser: undefined,
  };

  componentDidMount() {
    //set eventListener for closing browser with unsaved data
    this.shouldPromptOnLeavePage = this.shouldPromptOnLeavePage.bind(this); //bind so 'this' works as expected
    window.addEventListener('beforeunload', this.shouldPromptOnLeavePage);
  }

  componentWillUnmount() {
    //cleaning up after ourselves....we're not animals
    window.removeEventListener('beforeunload', this.shouldPromptOnLeavePage);
  }

  shouldPromptOnLeavePage(e) {
    const promptOnLeavePage = this.props.promptOnLeavePage;
    if (promptOnLeavePage) {
      // Cancel the event and show alert that the unsaved changes would be lost
      e.preventDefault();
      e.returnValue = '';
    }
  }

  render() {
    const {
      classes,
      currentUser: loggedInUser,
      isLoggedIn,
      showBackBtn,
      canToggleTheme,
      onToggleTheme,
      projectsUser, //straight data from non-project level /me call
    } = this.props;

    let isAdminUser = false;
    let isSuperAdminUser = false;
    let fullName = '';
    let email = '';
    let role = '';
    let userRole = '';
    let isAccessAdmin = false;

    if (isLoggedIn && loggedInUser) {
      fullName = `${loggedInUser.firstName} ${loggedInUser.lastName}`;
      email = loggedInUser.email;
      role = loggedInUser.isAdmin ? 'admin' : 'employee';
      isAdminUser = loggedInUser.isAdmin;
      isSuperAdminUser = loggedInUser.isSuperAdmin;
      userRole = loggedInUser.role;
      isAccessAdmin = loggedInUser.isAccessAdmin;
    }

    const isInvoiceApproverOnly = projectsUser?.isInvoiceApproverOnly;

    const backButton = showBackBtn ? (
      <Hidden smUp>
        <Grid item xs={2}>
          <Button
            variant="text"
            className={classes.button}
            onClick={() => this.props.onTakeMeBackAction()}
          >
            <BackIcon />
            Back
          </Button>
        </Grid>
      </Hidden>
    ) : (
      <Hidden smUp>
        <Grid item xs={2} />
      </Hidden>
    );

    return (
      <AppBar>
        <Toolbar>
          <Grid
            container
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            {backButton}
            <Grid item md={3} xs>
              <Brand
                showBackBtn={showBackBtn}
                isInvoiceApproverOnly={isInvoiceApproverOnly}
              />
            </Grid>
            <Hidden smDown>
              <Grid className={classes.navItems} item xs={8}>
                {isLoggedIn && (
                  <HeaderNav
                    isAdminUser={isAdminUser}
                    isSuperAdminUser={isSuperAdminUser}
                    isAccessAdmin={isAccessAdmin}
                  />
                )}
              </Grid>
            </Hidden>
            <Hidden lgDown>
              <Grid item xs className={classes.userMenu}>
                {isLoggedIn && (
                  <UserMenu
                    email={email}
                    fullName={fullName}
                    isAdminUser={isAdminUser}
                    userRole={userRole}
                    canToggleTheme={canToggleTheme}
                    onToggleTheme={onToggleTheme}
                  />
                )}
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Grid item>
                {isLoggedIn && (
                  <AppDrawer
                    role={role}
                    email={email}
                    fullName={fullName}
                    userRole={userRole}
                  />
                )}
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

//
// {isLoggedIn && <AppDrawer role={role} fullName={fullName} />}
//

const HeaderV1 = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyleSheet('Header', style),
)(Header);

HeaderV1.displayName = 'HeaderV1';

export default HeaderV1;
