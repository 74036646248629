import { produce } from 'immer';
import * as actions from 'actions/masterDepartments';

const initialState = {
  list: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.store}`:
        draft.list = action.departments.commonMasterData;
        break;

      default:
    }
  });
