/* eslint-disable react/prop-types */
import { Checkbox } from '@mui/material';
import {
  ROLE_LABELS,
  PC,
  DH,
  UPM,
  PA,
  IA,
  EMPLOYEE,
} from 'components/props/profiles';
import pluralize from 'pluralize';

const placeholderCss = {
  width: 24,
  height: 24,
};

export const PAGE_SIZE = 50;

export const TABLE_COLUMNS = [
  {
    id: 'selection',
    Header: props => {
      const { getToggleAllPageRowsSelectedProps, state } = props;
      if (!state.hoursPlusEnabled) return <div />;
      if (state.editingHotCost) return <div style={placeholderCss}></div>;
      return (
        <div>
          <Checkbox
            color="primary"
            {...(getToggleAllPageRowsSelectedProps &&
              getToggleAllPageRowsSelectedProps({ title: undefined }))}
            style={{ padding: '1px', zIndex: 10 }}
            disabled={state.editingHotCost}
          />
        </div>
      );
    },

    Cell: props => {
      const { row, state } = props;
      if (!state.hoursPlusEnabled) return <div />;
      if (state.editingHotCost) return <div style={placeholderCss}></div>;
      return (
        <div>
          <Checkbox
            disabled={state.editingHotCost}
            color="primary"
            {...row?.getToggleRowSelectedProps({ title: undefined })}
            style={{ padding: '1px' }}
          />
        </div>
      );
    },
  },
  {
    accessor: 'fullName',
    id: 'name',
    Header: 'Name',
  },
  {
    accessor: 'loanOutCorporation',
    id: 'loanOutCorporation',
    Header: 'Loan-Out',
    default: true,
  },
  {
    accessor: 'readableRole',
    id: 'role',
    Header: 'Role',
    default: true,
  },
  {
    //including as a hidden column so it show up in the global filter results
    accessor: 'isIA',
    id: 'isIA',
  },
  {
    accessor: 'department',
    id: 'department',
    Header: 'Department',
    default: true,
  },
  {
    accessor: 'occupation',
    id: 'occupation',
    Header: 'Occupation',
    default: true,
  },
  {
    id: 'payHourStudio',
    accessor: 'hotCostSettingReport.payHourStudio',
    Header: 'Pay Hrs - Stu',
    default: true,
  },
  {
    id: 'payHourDistant',
    accessor: 'hotCostSettingReport.payHourDistant',
    Header: 'Pay Hrs - Dis',
    default: true,
  },
  {
    id: 'mealPenaltyAmountStudio',
    accessor: 'hotCostSettingReport.mealPenaltyAmountStudio',
    Header: 'MPV $ - Stu',
    default: true,
  },
  {
    id: 'mealPenaltyAmountDistant',
    accessor: 'hotCostSettingReport.mealPenaltyAmountDistant',
    Header: 'MPV $ - Dis',
    default: true,
  },
  {
    id: 'fringePercentage',
    accessor: 'hotCostSettingReport.fringePercentage',
    Header: 'Est. Fringe %',
    default: true,
  },
  {
    accessor: 'startDate',
    id: 'startDate',
    Header: 'Start Date',
    default: true,
  },
  {
    accessor: 'email',
    id: 'email',
    Header: 'Email Address',
    default: true,
  },
  {
    accessor: 'status',
    id: 'status',
    Header: 'Status',
    filter: 'multiSelect',
    default: true,
  },
  {
    accessor: '',
    id: 'actionBtns',
    Header: '',
  },
  {
    id: 'departmentId',
    accessor: 'departmentId',
  },
  {
    id: 'id',
    accessor: 'id',
  },
  {
    id: 'firstName',
    accessor: 'firstName',
  },
  {
    id: 'lastName',
    accessor: 'lastName',
  },
  {
    id: 'worksightId',
    accessor: 'worksightId',
  },
];

export const SORTABLE_COLUMNS = [
  'name',
  'loanOutCorporation',
  'role',
  'department',
  'occupation',
  'startDate',
  'email',
  'status',
];

export const emailExist = (crew, invite) => {
  return crew.some(
    member =>
      member?.email?.toLowerCase() === invite?.email?.toLowerCase() &&
      member.id !== invite.id,
  );
};
export const countOccurrences = (arr, val) =>
  arr.reduce(
    (a, v) =>
      v?.email?.toLowerCase() === val?.email?.toLowerCase() ? a + 1 : a,
    0,
  );
export const emailUnique = (invites, invite) => {
  let occurenceCount = countOccurrences(invites, invite);
  if (occurenceCount === 1) {
    return true;
  } else {
    return false;
  }
};

export const STATUS_LIST = [
  {
    name: 'Uninvited',
    key: 'notinvited',
    selected: true,
  },
  {
    name: 'Invited',
    key: 'invited',
    selected: true,
  },
  {
    name: 'Accepted',
    key: 'accepted',
    selected: true,
  },
  {
    name: 'Deleted',
    key: 'deleted',
    selected: true,
  },
  // {
  //   name: 'Pending',
  //   key: 'pending',
  //   selected: false,
  // },
];

export const mergeCounts = array => {
  let output = [];
  array.forEach(function (item) {
    let existing = output.filter(function (v, i) {
      return v.key === item.key;
    });
    if (existing.length) {
      let matchIndex = output.indexOf(existing[0]);
      output[matchIndex] = { ...output[matchIndex], ...item };
    } else {
      output.push(item);
    }
  });

  return output;
};

export const PaginationCounts = (pageSize, total = [], activePage) => {
  return {
    total,
    pageSize,
    currentPage: activePage,
    lastPage: Math.ceil(total / pageSize),
    from: total === 0 ? 0 : (activePage - 1) * pageSize + 1,
    to: activePage * pageSize > total ? total : activePage * pageSize,
  };
};

export const canIDelete = (selectedList = []) => {
  return selectedList.every(
    item =>
      item.original.status === 'accepted' ||
      item.original.status === 'invited' ||
      item.original.status === 'pending',
  );
};
export const setSort = column => {
  let sort = null;
  if (!column.isSorted && !column.isSortedDesc) {
    sort = {
      id: column.id,
      desc: column.isSortedDesc ? true : false,
    };
  } else if (column.isSorted && !column.isSortedDesc) {
    sort = {
      id: column.id,
      desc: !column.isSortedDesc,
    };
  } else if (column.isSorted && column.isSortedDesc) {
    sort = {
      id: null,
      desc: false,
    };
  }

  return sort;
};
export const canIInvite = (selectedList = []) => {
  return selectedList.every(
    item =>
      item.original.status === 'notinvited' ||
      item.original.status === 'invited' ||
      item.original.status === 'deleted',
  );
};
export const readableUsers = (users = []) => {
  return users.map(user => {
    let readableRole;
    let isIA;
    if (user.worksightId === null && user.role === EMPLOYEE) {
      if (user.roles.includes(IA) && user.origin === 'studioplus') {
        readableRole = ROLE_LABELS[IA];
      } else {
        readableRole = 'Non-employee';
      }
    } else if (user.worksightId !== null && user.role === EMPLOYEE) {
      readableRole = ROLE_LABELS[user.role] || user.role;
    } else if (
      user.role === DH ||
      user.role === PA ||
      user.role === UPM ||
      user.role === PC
    ) {
      readableRole = ROLE_LABELS[user.role] || user.role;
    } else {
      readableRole = user.role;
    }

    //including as a hidden colum so it show up in the global filter results
    if (user?.roles?.includes(IA)) {
      isIA = ROLE_LABELS[IA];
    }
    return { ...user, readableRole, isIA };
  });
};

const INVITE_ERROR_CODES = {
  first_name_invalid: ' failed. First name is invalid for',
  last_name_invalid: ' failed. Last name is invalid for',
  not_unique: ' failed. Email is not unique for',
  email_duplicate: ' failed. Email exists in the system, choose another',
  email_invalid: ' failed. Email is invalid for',
  profile_mismatch: ' failed. Name or SSN does not match HTG',
  db_exception:
    ' failed because of an unknown error. If the issue persists, please contact support.',
  active_timecards: ' has active timecards, cannot edit department',
  department_invalid:
    ' failed because the department is invalid. If the issue persists, please contact support.',
  no_change: ' email or department should be updated',
  is_admin:
    ' This email belongs to an admin or superadmin. It cannot be added to a project',
};
const DELETE_ERROR_CODES = {
  not_deletable:
    ' could not be deleted. This user has a role in one or more approval flows. Replace them in the approval flow, then try deleting them again.',
  have_timecards:
    ' timecards in flight. Those timecards must be paid or deleted. ',
  db_exception:
    ' could not be deleted because of an unknown error. If the issue persists, please contact support. ',
};

const UPDATE_ERROR_CODES = {
  no_change: ' email or department should be updated',
  ssn_mismatch: ' failed. SSN does not match HTG',
  profile_mismatch: ' Name does not match HTG',
  not_unique: ' failed. Email is not unique for',
  email_duplicate: ' failed. Email exists in the system, choose another ',
  email_invalid: ' failed. Email is invalid for',
  active_timecards: ' has active timecards, cannot edit department',
  have_timecards:
    ' timecards in flight. Those timecards must be paid or deleted. ',
  not_valid: ' failed. SSN invalid',
  department_invalid: ' failed. Department Invalid',
  last_name_invalid: ' failed. Last name is invalid for',
  invalid_status: ' is already in accepted state not able to send invite',
  no_email: ' email not found in okta',
  active_okta: ' account is active, so email cannot be updated to',
};

export const errorMsgDecoder = e => {
  const messages = [];
  const errorCrew = Object.keys(e.errors);
  const errorObject = {};
  errorCrew.forEach(user => {
    e.errors[user].forEach(err => {
      err in errorObject
        ? errorObject[err].push(user)
        : (errorObject[err] = [user]);
    });
  });

  Object.keys(errorObject).forEach(err => {
    const emailSet = [...new Set(errorObject[err])];
    const userErrorCount = emailSet.length;

    let msgStart =
      e.type === 'delete'
        ? `${pluralize('user', userErrorCount, true)} ${
            err === 'have_timecards' ? pluralize('has', userErrorCount) : ''
          }`
        : e.type === 'invite'
        ? `${pluralize('invite', userErrorCount, true)}`
        : e.type === 'update'
        ? `${pluralize('user', userErrorCount, true)}`
        : 'Error';
    let decodedMsg =
      e.type === 'delete'
        ? DELETE_ERROR_CODES[err] || DELETE_ERROR_CODES.db_exception
        : e.type === 'invite'
        ? INVITE_ERROR_CODES[err] || INVITE_ERROR_CODES.db_exception
        : e.type === 'update'
        ? UPDATE_ERROR_CODES[err] || ''
        : '';

    messages.push(`${msgStart}${decodedMsg} [${emailSet.join(', ')}]`);
  });

  return messages;
};

export function resetScrollInTable(indexTable) {
  let tableBody = document.getElementsByClassName('crew-scroll')[indexTable];
  tableBody.scrollTop = 0;
}

export const ALWAYS_HIDDEN_COLUMNS = [
  'departmentId',
  'id',
  'firstName',
  'lastName',
  'worksightId',
  'isIA',
];
export const HOT_COST_COLUMNS = [
  'payHourStudio',
  'payHourDistant',
  'mealPenaltyAmountStudio',
  'mealPenaltyAmountDistant',
  'fringePercentage',
];

export const DECIMAL_NUM_REGEX = /^([0-9]*)$|^([0-9]*\.[0-9]*)$/;
// const regex = /^[1-9][\.\d]*(,\d+)?$/;

export const findNextValidInput = (currentIndex, shift = false, page) => {
  let pageLength = page.length;
  let validRow = true;
  let nextIndex = currentIndex;
  do {
    if (!shift) {
      ++nextIndex;
    } else {
      --nextIndex;
    }
    if (nextIndex < 0 && shift) {
      nextIndex = pageLength - 1;
    }
    if (nextIndex > pageLength - 1 && !shift) {
      nextIndex = 0;
    }
    if (page[nextIndex]?.original?.worksightId !== null) {
      validRow = false;
    }
  } while (validRow);
  return nextIndex;
};

//prevent string-nums from being used in localVal
export const PropTypeNumOrEmptyString = (props, propName, componentName) => {
  try {
    const val = props[propName];
    const type = typeof val;

    if ((type === 'string' && val === '') || type === 'number') {
      //expected types + value do nothing
    } else if (!val) {
      //allow null/undefined values
    } else {
      console.error(
        `PropType error in component:${componentName} Prop: ${propName} must be number or empty string: Found:${val}`,
      );
    }
  } catch (error) {
    //prevent propType from causing errors
    // can probably remove this after april 2022, but don't want to risk issues with hotcost
  }
};

export const getItemName = (fieldName, pageItem) => {
  const isHtgEmployee = !!pageItem?.original?.worksightId;

  if (isHtgEmployee) {
    const i = pageItem.index;
    const pageItemName = `editableCrew[${i}].hotCostSettingReport.${fieldName}`;
    return pageItemName;
  }
};

export const editableCountInPage = (page = []) => {
  let count = 0;
  page.forEach(pageItem => {
    if (!!pageItem?.original?.worksightId) count++;
  });
  return count;
};
