import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import * as actions from 'actions/digitalEdits';

import { scrollToElement } from './digitalEditsUtils';

const useStyles = makeStyles(({ palette }) => ({
  JumpToTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    gap: '16px',
  },
}));

const mapState = state => ({});

const mapDispatch = dispatch => ({
  onClearFilters: () => {
    dispatch(actions.setSearchText({ searchText: '' }));
    dispatch(actions.clearAllFilters());
  },
});

const JumpToTitle = props => {
  const { acEditRecord, action, onClearFilters } = props;
  const classes = useStyles();

  const [recordOnPage, setRecordOnPage] = React.useState(true);

  const [tick, setTick] = React.useState(0);

  React.useEffect(() => {
    const elementId = `digital-edits-record-${acEditRecord}`;
    const element = document.getElementById(elementId);
    if (element) {
      setRecordOnPage(true);
    } else {
      setRecordOnPage(false);
    }
  }, [acEditRecord, tick]);

  return (
    <Box className={classes.JumpToTitle}>
      {`You must finish editing the current record`}
      <br />
      {`before you can ${action}`}
      {recordOnPage ? ( //TODO - forcing current record to be visible, so this isn't being used now.
        <Button //               leaving it in case we want to use it in the future
          onClick={e => {
            scrollToElement(acEditRecord);
            e.stopPropagation();
          }}
        >
          Jump to Record
        </Button>
      ) : (
        <Box sx={{ fontWeight: 'bold' }}>
          <Box sx={{ fontSize: '20px', color: 'warning.main' }}>Warning:</Box>
          <br />
          {`Record is NOT visible -  please update filters or clear them to see the record.`}
          <Button
            onClick={() => {
              onClearFilters();
              setTick(tick + 1);
            }}
          >
            Clear all Filters
          </Button>
        </Box>
      )}
    </Box>
  );
};

JumpToTitle.propTypes = {
  acEditRecord: PropTypes.string.isRequired,
  action: PropTypes.string,
  onClearFilters: PropTypes.func.isRequired,
};

export default compose(connect(mapState, mapDispatch))(JumpToTitle);
