import React, { Component } from 'react';
import { PageHeader } from 'components/Nav';

class BatchesHeader extends Component {
  render() {
    return <PageHeader title="" />;
  }
}

export default BatchesHeader;
