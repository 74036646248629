import React from 'react';
import {
  MaxWidthLayout,
  Header,
  AlertHeader,
  ProjectHeader,
} from 'containers/Layout';

import { EnhancedRoute } from 'containers/RoutingSupport';
import { SearchInvoices } from 'feature/SearchInvoices';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <EnhancedRoute
      authenticated
      key="/projects/:projectId/search-invoices"
      id="/projects/:projectId/search-invoices"
      path="/projects/:projectId/search-invoices"
      layout={MaxWidthLayout}
      headers={[Header, ProjectHeader, AlertHeader]}
      component={SearchInvoices}
      isLoadProjectActiveUser={true}
      title="Invoices"
    />
  );
};
