import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'decorators/withRouter';

// components
import AccountCodes from 'components/Admin/Projects/Settings/AccountCodes';
import { settingsProps } from 'components/props/projects';
// actions
import * as actions from 'actions/settings';
// selectors
import { getSettings, getLoading } from 'selectors/settings';
import { getFlags } from 'selectors/flags';
import { getProject } from 'selectors/routeParams';

const mapState = state => ({
  flags: getFlags(state),
  settings: getSettings(state),
  loading: getLoading(state),
  projectId: getProject(state),
});

const mapDispatch = (dispatch, { match }) => ({
  onUpdateIndividual: field => (_, value) => {
    dispatch(actions.edit({ projectId: match.params.projectId, field, value }));
  },

  onUpdateSetting: (field, name) => (_, value) => {
    const update = prop =>
      dispatch(
        actions.edit({ projectId: match.params.projectId, field, prop, value }),
      );
    if (name === 'dhVisible') {
      if (!value) {
        update('dhMandatory');
        update('crewMandatory');
      }
      update('crewVisible');
    }
    if (name === 'dhMandatory') {
      update('crewMandatory');
    }
    if (name === 'crewVisible') {
      if (!value) {
        update('crewMandatory');
      }
    }
    update(name);
  },

  onToggleAutoCoding: () => {
    dispatch(actions.toggleAutoCoding(match.params));
  },
  onUpdate: () => {
    dispatch(actions.updateAccountCode());
  },
});

const AdminAccountCodes = props => {
  return <AccountCodes {...props} />;
};

AdminAccountCodes.propTypes = {
  loading: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateSetting: PropTypes.func.isRequired,
  settings: settingsProps.isRequired,
  flags: PropTypes.array,
};

export default withRouter(connect(mapState, mapDispatch)(AdminAccountCodes));
