import _ from 'lodash';
import { createSelector } from 'reselect';

// Utils
import { currentWorkWeek, getEditableFields } from 'utils/weekUtils';
// Selectors
import { getFormValues } from 'selectors/formValues';
import { currentUser } from 'selectors/session';
import { getPayrollSettings } from 'selectors/settings';
import {
  getProjectDetails,
  getProjectUsers,
  getLoading,
  projectUsersLoading,
} from 'selectors/project';
import { getProjectAllowancesLoading } from 'selectors/projectAllowances';

import { isSavingTimecard } from './timecard/common';
import { getDays, getEmptyDay } from './timecard/utils';

// constants
import { MEALS } from 'components/Shared/constants';
const FORM_NAME = 'teamTimecard';

export const isTeamTimecardLoading = state =>
  _.get(state, 'timecards.loadingTeamTimecard', false);

export const getTeamTimecard = state =>
  _.get(state, `timecards.teamTimecard.fresh-0`, {});

export const getTimecardAllowances = state =>
  _.get(state, `timecards.timecardAllowances`, []);

export const getCurrentCrewUsers = state =>
  _.get(state, `timecards.teamTimecard.users`, []);

export const getCrewMembers = createSelector(
  [getTeamTimecard, getProjectUsers],
  (timecard, users) => {
    let crewMembers = [];
    if (timecard && !_.isEmpty(timecard.users)) {
      crewMembers = _.map(timecard.users || [], user => {
        const userRecord = _.find(users, usr => usr.id === user.userId) || {};
        let status =
          user.timecardStatus === 'processing'
            ? 'incomplete'
            : user.timecardStatus;

        return {
          id: userRecord.id,
          fullName: userRecord.fullName,
          occupationCode: user.dealMemo && user.dealMemo.jobDescription,
          timecardStatus: status || 'blank',
          dealMemos: userRecord.dealMemos,
        };
      });
    }

    return crewMembers;
  },
);
export const getTeamAllowances = createSelector(
  [(state, formName) => getFormValues(formName)(state)],
  timecard => {
    return timecard?.allowances || [];
  },
);

export const getTeamTimecardInitialValues = createSelector(
  [getProjectDetails, getTeamTimecard, getPayrollSettings, currentUser],
  (project, timecard = {}, payrollSettings, activeUser) => {
    if (_.isEmpty(timecard)) return timecard;

    const editableFields = getEditableFields(
      timecard.status,
      activeUser.Role || 'employee',
    );

    const meal = _.find(MEALS, meal => meal.value === timecard.meals);
    const workWeek = currentWorkWeek(project, timecard.startsOn);
    const days = getDays(timecard.days, payrollSettings, workWeek);

    return {
      ...timecard,
      days: [...days],
      mealLabel: (meal && meal.label) || '',
      editableFields,
    };
  },
);

export const getCrewTimecard = createSelector(
  [state => getFormValues(FORM_NAME)(state)],
  formValues => {
    const timecard = _.cloneDeep(formValues);

    timecard.days = timecard.days || [];

    return timecard;
  },
);

export const isTeamTimecardProcessing = createSelector(
  [
    isTeamTimecardLoading,
    isSavingTimecard,
    getLoading,
    getProjectAllowancesLoading,
    projectUsersLoading,
  ],
  (
    isLoading,
    isSaving,
    isProjectLoading,
    isAllowanceLoading,
    useresLoading,
  ) => {
    return (
      isLoading ||
      isSaving ||
      isProjectLoading ||
      isAllowanceLoading ||
      useresLoading
    );
  },
);

export const getTeamTimecardFormValues = createSelector(
  [
    state => getFormValues(FORM_NAME)(state),
    (state, id) => getTeamTimecardInitialValues(state, id),
  ],
  (updates, initial) => {
    const timecard = _.isEmpty(updates) ? initial : updates;
    const days = _.map(timecard.days, day => {
      return day.htgDayTypeId && day.isActive ? day : getEmptyDay(day);
    });

    return {
      ...timecard,
      days,
    };
  },
);

export const isLoading = createSelector(
  [
    isTeamTimecardLoading,
    getLoading,
    getProjectAllowancesLoading,
    projectUsersLoading,
  ],
  (isLoading, isProjectLoading, isAllowanceLoading, usersLoading) => {
    return isLoading || isProjectLoading || isAllowanceLoading || usersLoading;
  },
);

export const getGroupRounding = state =>
  _.get(state, 'timecards.groupRounding', []);
