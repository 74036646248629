import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import withRouter from 'decorators/withRouter';

import Button from '@mui/material/Button';
import {
  getIsProjectArchived,
  getIsProjectCompleted,
  getProjectDetails,
} from 'selectors/project';
import { Tooltip } from '@mui/material';

import { db } from 'feature/EmployeeTimecard/empTimecardUtils';

const mapState = (state, { match, history }) => ({
  projectId: match.params.projectId,
  isProjectArchived: getIsProjectArchived(state),
  isProjectCompleted: getIsProjectCompleted(state),
  projectDetails: getProjectDetails(state),
});

let currPlace = 0;
const keyCombo = ['t', 'c', 'v', '1'];

class CreateTimecard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showV1: false,
    };

    this.listenForCombo = this.listenForCombo.bind(this);
  }

  static propTypes = {
    projectId: PropTypes.string.isRequired,
    isProjectArchived: PropTypes.bool.isRequired,
  };

  listenForCombo = e => {
    const key = e.key.toLowerCase();
    if (key === keyCombo[currPlace]) {
      currPlace++;
    } else {
      currPlace = 0;
    }
    if (currPlace === keyCombo.length) {
      this.setState({ showV1: true });
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.listenForCombo);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.listenForCombo);
  }

  render() {
    const {
      projectId,
      isUserDeleted,
      isProjectArchived,
      isProjectCompleted,
      projectDetails,
    } = this.props;
    // check if the project is archived to disable the create timecard button.
    const createTcTooltip = isProjectCompleted
      ? 'This project is complete, no new timecards can be created'
      : '';
    const isDraft = projectDetails?.isDraft;
    const isTimePlusEnabled = projectDetails?.timePlusEnabled;
    const canAccessNextYearProject = projectDetails?.canAccessNextYearProject;

    const disableCreate =
      isUserDeleted ||
      isProjectArchived ||
      isProjectCompleted ||
      isDraft ||
      !isTimePlusEnabled ||
      canAccessNextYearProject;

    if (disableCreate) {
      db('isUserDeleted', isUserDeleted);
      db('isProjectArchived', isProjectArchived);
      db('isProjectCompleted', isProjectCompleted);
      db('isDraft', isDraft);
      db('!isTimePlusEnabled', !isTimePlusEnabled);
      db('canAccessNextYearProject', canAccessNextYearProject);
    }

    return (
      <div
        style={this.props.style}
        key={`/projects/${projectId}/me/timecards/fresh-0`}
      >
        {this.state.showV1 && (
          <Tooltip title={createTcTooltip} placement="bottom-start">
            <NavLink
              to={`/projects/${projectId}/me/timecardsv1/create`}
              onClick={e => {
                if (disableCreate) {
                  e.preventDefault();
                }
              }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={disableCreate}
              >
                Create V1
              </Button>
            </NavLink>
          </Tooltip>
        )}
        <NavLink
          to={`/projects/${projectId}/me/timecards/fresh-0`}
          onClick={e => {
            if (disableCreate) {
              e.preventDefault();
            }
          }}
        >
          <Button variant="contained" color="primary" disabled={disableCreate}>
            Create
          </Button>
        </NavLink>
      </div>
    );
  }
}

export default withRouter(connect(mapState)(CreateTimecard));
