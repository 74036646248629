import _ from 'lodash';
import { createSelector } from 'reselect';
import { getProject } from './routeParams';
import { getFormValues } from './formValues';
import { getDirectoryPath } from 'utils/helperFunctions';
//General Settings
export const getSettingsList = state => _.get(state, 'settings.list', {});
export const getInitialSettingsList = state =>
  _.get(state, 'settings.initialSettings', {});

export const getCrewInitialList = state =>
  _.get(state, 'settings.crewMemberInitialSettings', {});
export const getCrewMemberInitialSettings = createSelector(
  [getProject, getCrewInitialList],
  (projectId, list) => {
    if (list) {
      return {
        ...list[projectId],
      };
    }
    return {};
  },
);
export const getLoading = state => _.get(state, 'settings.loading', {});

export const getPayrollSettings = state =>
  _.get(state, 'settings.payrollSettings', {});

export const getRecipients = state => _.get(state, 'settings.recipients', []);

export const getRecipientValues = createSelector(
  [getFormValues('addRecipients')],
  values => ({
    emails:
      values?.recipients && values.recipients.length > 0
        ? values.recipients.map(v => v.email)
        : null,
    event: 'batch_closed',
  }),
);

export const getSettings = createSelector(
  [getProject, getSettingsList],
  (projectId, list) => {
    if (list) {
      return {
        ...list[projectId],
      };
    }
    return {};
  },
);
export const getStudioPlusInitialSettings = createSelector(
  [state => getSettings(state)],
  details => {
    const settings = {
      studioTimecardEnabled: details?.studioTimecardEnabled || false,
      studioTimecardPath: details?.studioTimecardPath || '',
      studioBatchEnabled: details?.studioBatchEnabled || false,
      studioBatchPath: details?.studioBatchPath || '',
      studioEnabled: details?.studioEnabled || 'Disabled',
    };
    return settings;
  },
);

export const getInitialSettings = createSelector(
  [getProject, getInitialSettingsList],
  (projectId, list) => {
    return {
      ...list[projectId],
    };
  },
);

export const getDtsSetting = createSelector(
  [getFormValues('DtsSetting')],
  values => {
    return {
      dts_setting_enabled: values.dtsSettingEnabled,
    };
  },
);

export const getProjectPayrollSettings = createSelector(
  [getProject, getPayrollSettings],
  (projectId, payrollSettings) => {
    if (payrollSettings) {
      const settings = payrollSettings[projectId];

      return {
        ...settings,
      };
    }
    return {};
  },
);

//time settings
export const getTimeEntrySettings = createSelector(
  [getFormValues('TimeEntrySettings')],
  values => {
    return {
      military_time: values.militaryTime,
      time_interval: values.timeInterval,
      time_zone: values.timeZone,
    };
  },
);

export const getTimeEntryOptions = createSelector(
  [getFormValues('TimeEntrySettings')],
  values => {
    if (values.militaryTime) {
      return [
        {
          label: '0.10 hours',
          value: '0.10',
        },
        {
          label: '0.25 hours',
          value: '0.25',
        },
      ];
    }

    return [
      {
        label: '5 minutes',
        value: '5',
      },
      {
        label: '6 minutes',
        value: '6',
      },
      {
        label: '10 minutes',
        value: '10',
      },
      {
        label: '15 minutes',
        value: '15',
      },
      {
        label: '30 minutes',
        value: '30',
      },
    ];
  },
);

//e-signature
export const getESignature = createSelector(
  [getFormValues('ESignature')],
  values => {
    return {
      esignature_enabled: values.esignatureEnabled,
    };
  },
);

//email configuration settings
export const getEmailLoading = state =>
  _.get(state, 'settings.emailTemplateLoading', false);

export const getEmailTemplates = state =>
  _.get(state, 'settings.email_templates', []);

export const getCrewInviteTemplates = createSelector(
  [getEmailTemplates, getProject],
  (emailTemplates, projectId) => {
    const crewInviteTemplate = {
      emailBody: '',
      emailType: 'crew_invite',
    };

    const template = _.find(
      emailTemplates,
      t =>
        t.emailType === 'crew_invite' &&
        Number(t.projectId) === Number(projectId),
    );

    return template || crewInviteTemplate;
  },
);

//studio+ settings
export const getStudioPlusGroup = state =>
  _.get(state, 'settings.editStudioPlusGroup', undefined);

export const getStudioPlusDirectory = state =>
  _.get(state, 'settings.studioPlusDirectory', []);

export const getStudioPlusBtnDisabled = state =>
  _.get(state, 'settings.studioPlusBtnDisabled', false);

export const getStudioPlusTCfolder = state =>
  _.get(state, 'projects.current.studioTimecardPath', null);

export const getStudioPlusBRfolder = state =>
  _.get(state, 'projects.current.studioBatchPath', null);
export const getStudioPlusSettings = createSelector(
  [getFormValues('StudioPlus'), getStudioPlusDirectory],
  (values, studioPlusDirectory) => {
    const studioSettings = {};
    if (values.studioTimecardEnabled) {
      studioSettings.studio_timecard_enabled = values.studioTimecardEnabled;
      studioSettings.studio_timecard_path = values.studioTimecardPath;
      studioSettings.studio_timecard_studio_path = getDirectoryPath(
        studioPlusDirectory,
        values.studioTimecardPath,
      );
    }
    if (values.studioTimecardEnabled === false) {
      studioSettings.studio_timecard_enabled = values.studioTimecardEnabled;
      studioSettings.studio_timecard_path = null;
      studioSettings.studio_timecard_studio_path = null;
    }
    if (values.studioBatchEnabled) {
      studioSettings.studio_batch_enabled = values.studioBatchEnabled;
      studioSettings.studio_batch_path = values.studioBatchPath;
      studioSettings.studio_batch_studio_path = getDirectoryPath(
        studioPlusDirectory,
        values.studioBatchPath,
      );
    }
    if (values.studioBatchEnabled === false) {
      studioSettings.studio_batch_enabled = values.studioBatchEnabled;
      studioSettings.studio_batch_path = null;
      studioSettings.studio_batch_studio_path = null;
    }
    if (values.studioTimecardEnabled || values.studioBatchEnabled) {
      studioSettings.studio_middlelayer_id =
        studioPlusDirectory[0] && studioPlusDirectory[0].middleLayerId;
    } else {
      studioSettings.studio_middlelayer_id = null;
    }
    if (values.studioEnabled === 'New' || values.studioEnabled === 'Disabled') {
      studioSettings.studio_timecard_enabled = false;
      studioSettings.studio_timecard_path = null;
      studioSettings.studio_timecard_studio_path = null;
      studioSettings.studio_batch_enabled = false;
      studioSettings.studio_batch_path = null;
      studioSettings.studio_batch_studio_path = null;
    }
    studioSettings.studio_enabled = values.studioEnabled;

    return studioSettings;
  },
);

//DTS Edit settings
export const getDtsSettings = createSelector(
  [getFormValues('DtsSettings')],
  values => {
    return {
      dts_setting_enabled: values.dtsSettingEnabled,
      dts_template_id: values.dtsTemplateId,
    };
  },
);

export const getTimecardTemplates = state =>
  _.get(state, 'settings.timecardTemplateOptions', []);

//WTC Settings
export const getWTCSetting = createSelector([getSettings], settings => {
  return {
    wtc_editable: settings?.wtcEditable,
    edit_options: settings?.editOptions || 'cannotEdit',
    wtc_layout: settings?.wtcLayout || null,
  };
});

export const getAccountCodeSettings = createSelector(
  [getSettings],
  settings => {
    return {
      autoCodingEnable: settings?.autoCodingEnable,
      accountCodesOpenByDefault: settings?.accountCodesOpenByDefault,
      splits: settings?.splits,
      splitsProject: settings?.splitsProject,
      accountCode: settings?.accountCode,
      episode: settings?.episode,
      location: settings?.location,
      insurance: settings?.insurance,
      series: settings?.series,
      set: settings?.set,
      freeField1: settings?.freeField1,
      freeField2: settings?.freeField2,
      freeField3: settings?.freeField3,
      freeField4: settings?.freeField4,
    };
  },
);

export const getColumnSettings = createSelector([getSettings], settings => {
  const columns = {};

  Object.keys(settings).forEach(key => {
    if (settings[key]?.employeeMandatory !== undefined) {
      columns[key] = settings[key];
    }
  });
  return columns;
});

export const getCrewMemberVisibleFields = state =>
  _.get(state, 'settings.crewMemberVisibleFields', {});

//Crew Member Default Fields Settings
export const getCrewMemberSettings = createSelector([getSettings], settings => {
  return {
    country: settings?.country || null,
    county: settings?.county || null,
    workSubdivision: settings?.workSubdivision || null,
    locationType: settings?.locationType || null,
    accountCode: settings?.accountCode || null,
    series: settings?.series || null,
    location: settings?.location || null,
    set: settings?.set || null,
    insurance: settings?.insurance || null,
    freeField1: settings?.freeField1 || null,
    freeField2: settings?.freeField2 || null,
    freeField3: settings?.freeField3 || null,
    freeField4: settings?.freeField4 || null,
    meal3: settings?.meal3 || null,
    startTravel: settings?.startTravel || null,
    travelEnd: settings?.travelEnd || null,
    ndbIn: settings?.ndbIn || null,
    ndm: settings?.ndm || null,
    meal1: settings?.meal1 || null,
    lastMan1In: settings?.lastMan1In || null,
    meal2: settings?.meal2 || null,
    episode: settings?.episode || null,
    occupationCode: settings?.occupationCode || null,
    state: settings?.state || null,
    city: settings?.city || null,
  };
});
