import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';
import { Typography, LinearProgress } from '@mui/material';
import { compose } from 'utils/helperFunctions';
import { withStyleSheet } from 'shared/theme';
// decorators
import withModalDialog from 'decorators/withModalDialog';

// components
import { EditDepartment as Content } from 'components/Admin/Projects/DepartmentApprovers';
import { ConfirmModal } from 'components/Shared/Modals';
import { departmentProps } from 'components/props/departments';
import { departmentsProps } from 'components/props/departments';
import { departmentHeadsProps } from 'components/props/departments';
import { existingDepartmentsProps } from 'components/props/departments';
import { usersProps } from 'components/props/profiles';
// actions
import * as actions from 'actions/reviewFlows';
import {
  addDepartmentHead,
  clearDepartmentEdit,
  deleteDepartmentHead,
  updateDepartmentHead,
} from 'actions/admin/reviewFlows';
import { getDepartments as fetchDepartments } from 'selectors/department';
// selectors
import { getUsers } from 'selectors/users';
import { getList as getMasterDepartments } from 'selectors/masterDepartments';
import {
  getDepartmentHeads,
  getTimecardTemplates,
} from 'selectors/reviewFlows';
// import getFormValues from 'selectors/formValues';
// import { WORKFLOW_STANDARD } from 'components/Shared/constants';
import {
  getDepartments,
  getEditingDepartment,
  getDepartmentStats,
} from 'selectors/reviewFlows';
import { getProjectDetails } from 'selectors/project';
import { departmentSettingsValidate } from 'utils';

const headCount = state => 0;

const formName = 'editDepartment';

const mapStateToProps = state => {
  const department = getEditingDepartment(state);
  const { reviewFlowId } = department;
  return {
    department,
    departmentId: department.id,
    departments: getDepartments(state, reviewFlowId),
    existingDepartments: fetchDepartments(state),
    masterDepartments: getMasterDepartments(state),
    departmentHeadCount: headCount(state),
    departmentHeads: getDepartmentHeads(state),
    formSyncErrors: getFormSyncErrors(formName)(state),

    initialValues: {
      departmentHeadCount: headCount(state),
      departmentHeads: getDepartmentHeads(state),
      ...department,
    },
    reviewFlowId,
    reviewFlowDepartments: getDepartments(state, reviewFlowId),
    stats: getDepartmentStats(state, department.id),
    users: getUsers(state),
    enableApprovalFlow: getProjectDetails(state).departmentApprove,
    timecardTemplateOptions: getTimecardTemplates(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchData: () => dispatch(actions.fetchDeptStats()),
  onFetchTimecardTemplates: () => {
    dispatch(actions.fetchTimecardTemplates());
  },
  onAddDepartmentHead: () => dispatch(addDepartmentHead()),
  onRemoveDepartmentHead: key => dispatch(deleteDepartmentHead(key)),
  onDepartmentHeadChange: (key, val) =>
    dispatch(updateDepartmentHead(key, val)),
  onClose: () => {
    dispatch(clearDepartmentEdit());
  },
});

const onSubmit = (values, dispatch, props) => {
  const { timecardTemplateOptions } = props;
  const department = values;
  department.name = values.name.trim();
  if (!values?.headApprovalEnabled) {
    department.heads = [];
  }
  department.wtcLayoutName = null;
  if (values?.wtcLayoutId) {
    const obj = timecardTemplateOptions?.find(
      o => o.value === values.wtcLayoutId,
    );
    department.wtcLayoutName = obj.label;
  }
  if (department.name) {
    dispatch(actions.saveDepartment({ department, formName }));
  }
};

const style = () => ({
  content: {
    maxWidth: 500,
  },
});

class EditDepartment extends Component {
  static propTypes = {
    change: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    department: departmentProps,
    departmentId: PropTypes.number,
    departments: departmentsProps.isRequired,
    departmentHeads: departmentHeadsProps,
    existingDepartments: existingDepartmentsProps,
    enableApprovalFlow: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onAddDepartmentHead: PropTypes.func.isRequired,
    onDepartmentHeadChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onFetchData: PropTypes.func.isRequired,
    onRemoveDepartmentHead: PropTypes.func.isRequired,
    onFetchTimecardTemplates: PropTypes.func.isRequired,
    reviewFlowDepartments: departmentsProps,
    reviewFlowId: PropTypes.number,
    stats: PropTypes.object,
    users: usersProps.isRequired,
  };

  static defaultProps = {
    department: {},
    departmentId: null,
    reviewFlowDepartments: [],
    reviewFlowId: '',
    stats: {},
  };

  componentDidMount() {
    const { departmentId, onFetchData, onFetchTimecardTemplates } = this.props;
    if (departmentId && onFetchData) {
      onFetchData();
    }
    onFetchTimecardTemplates();
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  renderContent() {
    const {
      change,
      department,
      departments,
      masterDepartments,
      departmentHeads,
      departmentHeadCount,
      existingDepartments,
      enableApprovalFlow,
      onAddDepartmentHead,
      onRemoveDepartmentHead,
      onDepartmentHeadChange,
      reviewFlowDepartments,
      stats,
      users,
      formSyncErrors,
      timecardTemplateOptions,
    } = this.props;

    const hasOpenBatches = stats && stats.batches && stats.batches.open > 0;

    return (
      <Content
        change={change}
        department={department}
        departments={departments}
        masterDepartments={masterDepartments}
        departmentHeads={departmentHeads}
        enableApprovalFlow={enableApprovalFlow}
        existingDepartments={existingDepartments}
        hasOpenBatches={hasOpenBatches}
        departmentHeadCount={departmentHeadCount}
        reviewFlowDepartments={reviewFlowDepartments}
        addDepartmentHead={onAddDepartmentHead}
        removeDepartmentHead={onRemoveDepartmentHead}
        hideExisting={department.id > 0}
        onDepartmentHeadChange={onDepartmentHeadChange}
        users={users}
        formSyncErrors={formSyncErrors}
        timecardTemplateOptions={timecardTemplateOptions}
      />
    );
  }

  render() {
    const {
      classes,
      department,
      handleSubmit,
      initialValues,
      formSyncErrors,
      submitting,
      ...others
    } = this.props;

    const title = (
      <Typography variant="h6" color="inherit" className={classes.title}>
        {department.id
          ? `Edit Department - ${department.name}`
          : `Add Department`}
      </Typography>
    );

    const content = this.props.loading ? (
      <LinearProgress />
    ) : (
      this.renderContent()
    );
    return (
      <form onSubmit={handleSubmit}>
        <ConfirmModal
          content={content}
          title={title}
          buttonText="Save"
          raisedButton
          submitting={submitting}
          {...others}
        />
      </form>
    );
  }
}

export default compose(
  withStyleSheet('EditDepartment', style),
  withModalDialog({ dialog: 'editDepartment', maxWidth: 'md' }),
  connect(mapStateToProps, mapDispatchToProps),

  reduxForm({
    form: formName,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnChange: true,
    onSubmit,
    validate: departmentSettingsValidate,
    shouldValidate: () => true,
  }),
)(EditDepartment);
