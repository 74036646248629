import React from 'react';
import SortArrows from 'components/Shared/SortArrows';
import makeStyles from '@mui/styles/makeStyles';

import { PROJECT_REQ_FIELDS } from 'utils/wtcWeekUtils';

function HeaderCell(props) {
  const { column, currentProject } = props;

  const useStyles = makeStyles(theme => ({
    root: {},
    cell: {
      display: 'flex',
      //styles moved into component so can access column.width
      width: column.width,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    title: {
      paddingLeft: 16,
      alignSelf: 'center',
    },
  }));

  const classes = useStyles();

  const isRequiredField = (() => {
    const fieldLabelInProject = PROJECT_REQ_FIELDS[column.id];
    if (fieldLabelInProject) {
      const label = fieldLabelInProject.projectLabel;
      if (currentProject[label] === 'Y' || currentProject[label] === true) {
        return true;
      }
    }
  })();

  return (
    <span
      className={classes.cell}
      id={column.id === 'weekDay' ? 'BulkEditFixedBoundary' : column.id}
    >
      <span className={classes.title}>
        {isRequiredField ? '* ' : ''}
        {column.Header}
      </span>
      <SortArrows column={column} />
    </span>
  );
}

export default HeaderCell;
