import { buildUrl } from 'providers/api';

const URL_TIMECARD_COUNT =
  '/projects/:projectId/review/batches/year/:year/timecard-count';
const URL_REVIEW_BATCHES =
  '/projects/:projectId/review/batches/year/:year/:type';

const URL_TIMECARDS_BY_REVIEW_BATCH =
  '/projects/:projectId/review/batches/:reviewBatchId/timecards';
const URL_APPROVAL_FLOWS_BY_BATCH =
  '/projects/:projectId/review/batches/:reviewBatchId/approval-flows';

const URL_APPROVAL_FLOW =
  '/projects/:projectId/review/approval-flows/:approvalFlowId';

const URL_REVIEW_BATCH = '/projects/:projectId/review/approval-flows/review';

const URL_SUBMIT_AS_REJECTED_BULK =
  '/projects/:projectId/review/submit-as-rejected';

const URL_LOCK_STATUS_SWITCH =
  '/projects/:projectId/batches/:batchId?lockStatus=:lockStatus';

const URL_ADD_MODAL_COMMENT =
  '/projects/:projectId/timecards/:timecardEntryHeaderId/addComment';
const URL_COMMENTS =
  '/projects/:projectId/timecards/:timecardEntryHeaderId/comments';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async timecardCount({ projectId, year, type, showPaid }) {
    const URL = URL_TIMECARD_COUNT;
    const payload = {
      type,
      includeProcessed: showPaid,
    };
    const url = buildUrl(URL, { projectId, year });
    const response = await clientV2.post(url, payload);
    return response.data;
  },
  async reviewBatchesPA({ projectId, year, type, page, size, showPaid }) {
    const URL = URL_TIMECARD_COUNT;
    const payload = {
      type,
      includeProcessed: showPaid,
      page,
      pageSize: size,
    };
    const url = buildUrl(URL, { projectId, year });
    const response = await clientV2.post(url, payload);
    return response.data;
  },
  async reviewBatches({ projectId, year, type, page, size, showPaid }) {
    const URL = URL_REVIEW_BATCHES;
    const payload = {
      type,
      includeProcessed: showPaid,
      page,
      pageSize: size,
    };
    const url = buildUrl(URL, { projectId, year, type });
    const response = await clientV2.post(url, payload);
    return response.data;
  },

  async timecardsByReviewBatchId({ projectId, type, reviewBatchId, showPaid }) {
    const URL =
      URL_TIMECARDS_BY_REVIEW_BATCH +
      '?' +
      (type ? '&type=:type' : '') +
      (showPaid ? '&includeProcessed=:showPaid' : '');
    const url = buildUrl(URL, { projectId, type, reviewBatchId, showPaid });
    const response = await clientV2.get(url);
    return response.data;
  },
  async approvalFlows({ projectId, type, reviewBatchId, showPaid }) {
    const URL =
      URL_APPROVAL_FLOWS_BY_BATCH +
      '?' +
      (type ? '&type=:type' : '') +
      (showPaid ? '&includeProcessed=:showPaid' : '');
    const url = buildUrl(URL, { projectId, type, reviewBatchId, showPaid });
    const response = await clientV2.get(url);

    return {
      reviewBatchId,
      approvalFlow: [...response.data],
    };
  },

  async approvalFlowByID({ projectId, approvalFlowId }) {
    const url = buildUrl(URL_APPROVAL_FLOW, { projectId, approvalFlowId });
    const response = await clientV2.get(url);

    return response.data;
  },

  async reviewBatch({ projectId, data }) {
    const url = buildUrl(URL_REVIEW_BATCH, { projectId });
    const response = await clientV2.put(url, data);
    return response.data;
  },

  async bulkSubmitAsRejected({ projectId, batchId, data }) {
    const url = buildUrl(URL_SUBMIT_AS_REJECTED_BULK, { projectId, batchId });
    const response = await clientV2.post(url, data);

    return response.data;
  },
  async batchLockStatusSwitch({ projectId, batchId, lockStatus }) {
    const URL = URL_LOCK_STATUS_SWITCH;
    const url = buildUrl(URL, { projectId, batchId, lockStatus });
    const response = await clientV2.put(url);
    return response.data;
  },

  async addModalComment({ data, projectId }) {
    const timecardEntryHeaderId = data.timecardEntryHeaderId;
    const url = buildUrl(URL_ADD_MODAL_COMMENT, {
      projectId,
      timecardEntryHeaderId,
    });
    const response = await clientV2.post(url, data);
    return response.data;
  },

  async getTimecardComments({ projectId, timecardEntryHeaderId }) {
    const url = buildUrl(URL_COMMENTS, { projectId, timecardEntryHeaderId });
    const resp = await clientV2.get(url);
    return resp.data;
  },
});
