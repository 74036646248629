import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  LetterListNav: {
    width: '10%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: palette.gray['+9'],
    overflow: 'auto',
    lineHeight: 0.9,
  },
}));

const LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const getClosestId = (list, letterIndex) => {
  let offset = 0;
  let newRecord;
  while (!newRecord && offset + letterIndex >= 0) {
    const letter = LETTERS[letterIndex + offset].toLowerCase();
    newRecord = list.find(l => l.firstLetter === letter);
    offset--;
  }

  if (!newRecord) return list[0]?.recordId;

  return newRecord.recordId;
};

const onClickLetter = (list, i) => {
  const recordId = getClosestId(list, i);

  const recordElement = document.getElementById(
    `digital-edits-record-${recordId}`,
  );
  recordElement.scrollIntoView({ block: 'start' });
  setTimeout(() => {
    recordElement.scrollIntoView({ block: 'start' });
  }, 200);
  const listElement = document.getElementById(
    `digital-edits-sidebar-${recordId}`,
  );
  listElement.scrollIntoView({ block: 'center' });
};

const LetterListNav = props => {
  const classes = useStyles();
  const { list, headerHeight } = props;

  return (
    <Box
      className={classes.LetterListNav}
      sx={{ height: `calc(100vh - ${headerHeight}px)` }}
    >
      {LETTERS.map((letter, i) => (
        <React.Fragment key={letter}>
          {i !== 0 && (
            <Box sx={{ color: 'text.disabled', fontSize: 8 }}>{'\u2022'}</Box>
          )}
          <Box
            sx={{
              cursor: 'pointer',
              fontSize: 10,
              fontWeight: 800,
              padding: 1,
            }}
            onClick={() => onClickLetter(list, i)}
          >
            {letter}
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );
};

LetterListNav.propTypes = {
  list: PropTypes.array,
  headerHeight: PropTypes.number,
};

export default LetterListNav;
