import React, { Component } from 'react';
// HoC
import { connect } from 'react-redux';
// Actions
import { authenticate } from 'actions/authenticate';

// Providers
import { Provider } from './authenticationContext';

class AuthenticationProvider extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
  }

  componentDidMount() {
    this.authenticationCheck();
  }

  authenticationCheck = () => {
    const {
      checkAuthentication,
      authenticationCheckInProgress,
      authenticated,
      authenticationFailed,
    } = this.props;
    // Auth Check is happening now
    if (authenticationCheckInProgress) return;
    // Already logged in
    if (authenticated) return;
    // We already attempted login
    if (authenticationFailed) return;

    checkAuthentication();
  };

  render() {
    const {
      authenticationInProgress,
      authenticated,
      authenticationFailed,
      sessionCreated,
      children,
    } = this.props;
    const value = {
      authenticated,
      authenticationInProgress,
      authenticationFailed,
      sessionCreated,
    };

    return <Provider value={value}>{children}</Provider>;
  }
}

const mapStateToProps = state => ({
  authenticationCheckInProgress: state.auth.authenticationCheckInProgress,
  authenticationFailed: state.auth.authenticationFailed,
  authenticated: state.auth.authenticated,
});

const mapDispatchToProps = dispatch => ({
  checkAuthentication: () => {
    dispatch(authenticate());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthenticationProvider);
