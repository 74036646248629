import React from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import { CardContent as MuiCardContent } from '@mui/material';
import clsx from 'clsx';

const style = () => ({
  root: {
    '& > *': {
      fontFamily: 'inherit',
    },
    display: 'flex',
    marginTop: 16,
    marginBottom: 16,
    margin: 0,
  },
});

const CardContent = ({ classes, klass, children, ...props }) => (
  <MuiCardContent className={clsx(classes.root, klass)} {...props}>
    {children}
  </MuiCardContent>
);

CardContent.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
};

CardContent.defaultProps = {
  children: undefined,
};

export default withStyleSheet('CardContent', style)(CardContent);
