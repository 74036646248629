import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import { Box, Typography, LinearProgress } from '@mui/material';

import withModalDialog from 'decorators/withModalDialog';

import { hide as hideModal } from 'actions/modalDialog';
import { copyFromPrevWeek } from '../actions';
import { getModalParams } from 'selectors/modalDialog';
import * as sel from '../selectors';
import {
  ContentBox,
  FooterBox,
  FooterButton,
  TitleBox,
} from '../Shared/styledComponents';

import { COPY_PREVIOUS_WEEK_DIALOG } from '../Modals/modalNames';

const mapState = state => ({
  loading: sel.getLoading(state, 'timecard'),
  modalParams: getModalParams(state, COPY_PREVIOUS_WEEK_DIALOG),
});
const mapDispatch = dispatch => {
  return {
    onCancel: () => dispatch(hideModal({ dialog: COPY_PREVIOUS_WEEK_DIALOG })),
    onConfirm: params => dispatch(copyFromPrevWeek(params)),
  };
};

/**
 * //TODO - this is unused after changes in: https://castandcrew.atlassian.net/browse/HOUR-11348
 * keeping around for a bit in case we change our minds - but currently this file is unused
 */
const CopyTimeCard = props => {
  const { loading, onCancel, onConfirm, modalParams } = props;
  return (
    <Box>
      {loading && <LinearProgress />}
      <TitleBox>
        <Typography variant="h5">Copy Timecard</Typography>
      </TitleBox>
      <ContentBox sx={{ m: '30px 20px' }}>
        <Typography sx={{ fontSize: '18px' }}>
          This will overwrite your current timecard.
        </Typography>
      </ContentBox>
      <FooterBox sx={{ mb: '20px' }}>
        <FooterButton
          onClick={() => {
            onConfirm(modalParams);
          }}
          disabled={loading}
        >
          Confirm
        </FooterButton>
        <FooterButton variant="outlined" onClick={onCancel}>
          Cancel
        </FooterButton>
      </FooterBox>
    </Box>
  );
};

CopyTimeCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  modalParams: PropTypes.object.isRequired,
};

export default compose(
  connect(mapState, mapDispatch),
  withModalDialog({
    dialog: COPY_PREVIOUS_WEEK_DIALOG,
    maxWidth: 'md',
    roundedCorners: true,
  }),
)(CopyTimeCard);
