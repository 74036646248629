import React, { useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { Input, InputAdornment, Tooltip, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Portal from 'components/Shared/ReactTable/Portal';
import GroupSelect from './SelectContainers/GroupSelect';
import SoloSelect from './SelectContainers/SoloSelect';

import {
  SOLO_OPTION_IDS,
  GROUP_OPTION_IDS,
  ALL_AUTO_COMPETE_IDS,
} from '../dtsUtils';

import DtsDealMemoModal from './SelectContainers/DtsDealMemoModal';

import { makeDropDownDisplay } from 'feature/DTS/dtsUtils';

import { show as showModal, hide as hideModal } from 'actions/modalDialog';

const useStyles = makeStyles(theme => ({
  root: {},
  iconBtn: { padding: 0 },
  disabledIcon: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  errorIcon: {
    padding: '0px 5px 0px 0px',
  },
}));

const SelectCell = props => {
  const {
    column: { id: columnId },
    row: { original, values, index: rowIndex },
    //disabled - pulled out as a let var below
    baseOnBlur,
    localVal,
    toggleCTA,
    baseClasses,
    error,
    id,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const isDealMemo = columnId === 'dealMemo';
  const isValidSelect = ALL_AUTO_COMPETE_IDS.includes(columnId);
  const dealModalDialog = 'DtsDealMemoModal';
  const { errorMsg } = error;

  const displayValue = makeDropDownDisplay(localVal, columnId);

  const [focus, setFocus] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const ref = useRef(null);

  const clientWidth = ref.current ? ref.current.clientWidth : 75;

  const closeSelect = () => {
    setFocus(null);
    toggleCTA(false);
    if (isDealMemo) {
      dispatch(hideModal({ dialog: dealModalDialog }));
    }
  };

  const openSelect = () => {
    if (!isValidSelect && !isDealMemo) {
      console.warn(
        'Template Error: auto-complete field not recognized:',
        columnId,
      );
      return;
    }
    setFocus(ref.current);
    toggleCTA(true);
    if (isDealMemo) {
      dispatch(showModal({ dialog: dealModalDialog }));
    }
  };

  const updateSelectVal = useCallback(
    (newVal, options) => {
      baseOnBlur(newVal, options);
    },
    [baseOnBlur],
  );

  let { disabled } = props;

  if (columnId === 'workSubdivision') {
    if (original?.workState?.specialOptions?.includes('U')) {
      //do nothing, field can be enabled
    } else {
      disabled = true;
    }
  }

  //set so menu opens on focus for clicks, but not tab/enter focus
  const [didClick, setDidClick] = useState(false);

  return (
    <section>
      <Input
        id={id}
        type="text"
        ref={ref}
        classes={{
          root: baseClasses.input,
          disabled: baseClasses.disabled,
        }}
        value={displayValue || ''}
        onChange={() => {}}
        readyonly="true"
        placeholder={'Select...'}
        onClick={() => {
          setDidClick(true);
          setTimeout(() => {
            setDidClick(false);
          }, 400);
          if (isDealMemo) openSelect(true);
        }}
        onFocus={() => {
          if (isDealMemo === false) openSelect();
        }}
        inputProps={{
          tabIndex,
        }}
        onKeyDown={e => {
          if (isDealMemo) {
            if (
              e.key === 'ArrowDown' ||
              e.key === 'ArrowUp' ||
              e.key === ' ' ||
              /^[a-z0-9]$/i.test(e.key)
            ) {
              openSelect();
            }
          }
        }}
        disabled={disabled}
        error={!!errorMsg}
        variant="outlined"
        endAdornment={
          <InputAdornment position="end" disablePointerEvents>
            <IconButton
              tabIndex={-1}
              className={classes.iconBtn}
              onClick={() => {
                if (!disabled) {
                  openSelect();
                }
              }}
              size="large"
            >
              {!!errorMsg ? (
                <Tooltip arrow title={errorMsg}>
                  <ErrorIcon
                    className={clsx(baseClasses.errorIcon, classes.errorIcon)}
                  />
                </Tooltip>
              ) : (
                <ExpandMoreIcon
                  className={clsx({
                    [classes.disabledIcon]: disabled,
                  })}
                />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
      {focus && (
        <Portal targetDom={focus}>
          {SOLO_OPTION_IDS.includes(columnId) ? (
            <SoloSelect
              {...props}
              columnId={columnId}
              closeSelect={closeSelect}
              updateSelectVal={updateSelectVal}
              rowValues={values}
              value={localVal}
              original={original}
              setTabIndex={setTabIndex}
              rowIndex={rowIndex}
              clientWidth={clientWidth}
              didClick={didClick}
            />
          ) : GROUP_OPTION_IDS.includes(columnId) ? (
            <GroupSelect
              {...props}
              columnId={columnId}
              closeSelect={closeSelect}
              updateSelectVal={updateSelectVal}
              rowValues={values}
              value={localVal}
              setTabIndex={setTabIndex}
              rowIndex={rowIndex}
              clientWidth={clientWidth}
              didClick={didClick}
            />
          ) : isDealMemo ? (
            <DtsDealMemoModal
              customOnModalHide={() => {
                closeSelect();
              }}
              updateSelectVal={updateSelectVal}
              original={original}
            />
          ) : null}
        </Portal>
      )}
    </section>
  );
};

SelectCell.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  row: PropTypes.shape({
    original: PropTypes.object,
    values: PropTypes.object,
    index: PropTypes.number.isRequired,
  }).isRequired,
  baseOnBlur: PropTypes.func.isRequired,
  localVal: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  toggleCTA: PropTypes.func.isRequired,
  baseClasses: PropTypes.shape({
    input: PropTypes.string.isRequired,
    disabled: PropTypes.string.isRequired,
    errorIcon: PropTypes.string.isRequired,
  }).isRequired,
  error: PropTypes.shape({
    errorMsg: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default SelectCell;
