import React, { useState, useEffect } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FilterOption from './FilterOption';
// import PrevYearLink from 'components/Shared/PrevYearLink';
const newStyle = ({ palette }) => ({
  expansionSummary: {
    display: 'flex',
    height: 40,
    minHeight: 40,
    fontSize: 18,
    '&:hover': {
      cursor: 'pointer',
      color: palette.primary.main,
      backgroundColor: `${palette.primary['+9']} !important`, //overwrite MUI default
      '& $labelText': {
        color: palette.primary.main,
      },
    },
  },
  Accordion: {
    boxShadow: 'none',
    '&:first-child': {
      borderRadius: 0,
    },
  },
  summaryContent: {
    display: 'block',
  },
  selectedText: {
    fontSize: 10,
  },
  expansionDetails: {
    padding: '8px 8px 4px 8px',
    borderLeft: `4px ${palette.primary.main} solid`,
  },
  labelText: {
    color: palette.gray['-5'],
    fontSize: 14,
  },
  divider: {
    backgroundColor: palette.primary['+9'],
  },
  expand: {
    transform: 'rotate(180deg)',
  },
});

function FilterDropdown(props) {
  const {
    classes,
    expand,
    saving,
    searchFilter,
    setSearchFilter,
    onSelect,
    onSelectAll,
    onClearFilter,
    label, //filter name/label
    enableSearch,
    canSelectAll,
    dropdownSelection = [],
    selectedCount,
    calculating,
    variant,
  } = props;
  const useSelectAllV2 =
    variant === 'searchTimecards' ||
    variant === 'dts' ||
    variant === 'wtcDrawer' ||
    variant === 'digitalEdits';

  const selectedLength = dropdownSelection.filter(
    selection => selection.selected,
  ).length;
  const [selectAll, toggleSelectAll] = useState(true);

  const [expanded, toggleExpanded] = useState(false); //Initial value doesn't matter, must set in parent container

  function selectAllClicked() {
    if (selectAll || useSelectAllV2) {
      onSelectAll();
    } else {
      onClearFilter();
    }
    toggleSelectAll(!selectAll);
  }
  function toggleAccordion() {
    toggleExpanded(!expanded);
  }

  useEffect(() => {
    toggleExpanded(expand);
  }, [expand]);

  const disabled = saving || calculating;

  const selectAllChecked =
    selectedLength === dropdownSelection.length &&
    dropdownSelection.length !== 0
      ? true
      : false;

  const filterSelectedCount =
    selectedCount === undefined ? selectedLength : selectedCount;

  return (
    <Accordion
      classes={{
        root: classes.Accordion,
      }}
      expanded={expanded}
      disabled={disabled}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
        classes={{
          root: classes.expansionSummary,
          content: classes.summaryContent,
          expanded: classes.expanded,
        }}
        onClick={toggleAccordion}
      >
        <div className={classes.labelText}>{label}</div>
        <div className={classes.selectedText}>
          {filterSelectedCount > 0 ? `${filterSelectedCount} selected` : ''}
        </div>
      </AccordionSummary>
      <Divider className={classes.divider} />
      <AccordionDetails className={classes.expansionDetails}>
        <FilterOption
          enableSearch={enableSearch}
          variant={variant}
          setSearchFilter={setSearchFilter}
          searchFilter={searchFilter}
          canSelectAll={canSelectAll}
          selectAllChecked={selectAllChecked}
          selectAllClicked={selectAllClicked}
          dropdownSelection={dropdownSelection}
          label={label}
          onSelect={onSelect}
          expanded={expanded}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default withStyles(newStyle)(FilterDropdown);
