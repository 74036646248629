import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { Paper, Typography, Tooltip } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import { Switch, TextField } from 'components/Shared/redux';
import { UserSelect } from 'components/Admin/Projects/Shared';
import { AutoComplete } from 'components/Shared/AutoComplete';
import { RejectionWorkflow } from 'components/Admin/Projects/Shared';
import {
  departmentProps,
  departmentsProps,
  masterDepartmentsProps,
  departmentHeadsProps,
} from 'components/props/departments';

import { usersProps } from 'components/props/profiles';
import { EMPLOYEE, DH } from 'components/props/profiles';
import { getErrorText } from 'utils/';

import { formatAllowedChars } from 'utils/helperFunctions';
import { INPUT_FIELD_CHARACTER_LIMIT } from 'components/Shared/constants';
const deptLimit = INPUT_FIELD_CHARACTER_LIMIT.department;

const style = theme => ({
  root: {
    maxWidth: 500,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    marginBottom: 15,
    '& > *': {
      width: '100%',
    },
  },
  noPadding: {
    padding: 0,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    marginBottom: 8,
  },
  moreMargin: {
    marginTop: 18,
  },
  error: {
    fontSize: '1.1em',
    color: theme.palette.error.main,
  },
  departmentHeads: {
    marginTop: 8,
    marginBottom: 8,
  },
  field: {
    marginTop: 8,
    marginBottom: 8,
  },
  col: {
    minWidth: '47%',
    maxWidth: '100%',
  },
  title: {},
  titleBold: {
    fontWeight: 600,
  },
});

class EditDepartment extends Component {
  static propTypes = {
    change: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    department: departmentProps,
    departments: departmentsProps,
    masterDepartments: masterDepartmentsProps,
    departmentHeadCount: PropTypes.number,
    departmentHeads: departmentHeadsProps,
    enableApprovalFlow: PropTypes.bool,
    hasOpenBatches: PropTypes.bool,
    reviewFlowDepartments: departmentsProps,
    users: usersProps.isRequired,
    masterId: PropTypes.number,
    associationId: PropTypes.number,
    wtcLayoutId: PropTypes.number,
  };

  static defaultProps = {
    department: {},
    departments: [],
    departmentHeads: [],
    enableApprovalFlow: false,
    hasOpenBatches: false,
    reviewFlowDepartments: [],
  };

  constructor(props) {
    super(props);
    const { department } = this.props;

    this.state = {
      masterId: department.masterId,
      addExistingDepartment: !!department.id,
      headApprovalEnabled: !!department.headApprovalEnabled,
      existingDepartmentEnabled: false,
      wtcLayoutId: department.wtcLayoutId,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onToggleSwitch = this.onToggleSwitch.bind(this);
  }

  handleChange(id, field) {
    this.setState({ [field]: id });
  }

  /**
   * DEV NOTES
   * this set state call is throwing a warning when enabling department headsin the edit department modal
   * TODO: this component is a good target for a functional and react final form conversion
   *
   */

  onToggleSwitch(label, checked) {
    this.setState({ [label]: checked });
  }

  renderExistingDepartmentToggle() {
    const { classes } = this.props;

    return (
      <div className={classes.departmentHeads}>
        {this.renderSwitch('existingDepartmentEnabled', 'Existing Department')}
      </div>
    );
  }

  renderExistingDepartmentSection() {
    const { existingDepartments, classes } = this.props;
    const options = existingDepartments
      .filter(dept => dept.reviewFlowId === null)
      .map(department => ({
        value: department.id,
        label: department.name,
      }));

    return (
      <div className={classes.row}>
        <AutoComplete
          list={options}
          name="departmentId"
          className={classes.selectRow}
          onChange={(_event, id) => this.handleChange(id, 'id')}
        />
      </div>
    );
  }

  renderDepartmentHeadSection() {
    const { classes } = this.props;
    const { headApprovalEnabled } = this.state;

    return (
      <div className={classes.departmentHeads}>
        {this.renderSwitch('headApprovalEnabled', 'Department Heads', true)}
        {headApprovalEnabled && (
          <FieldArray
            name="heads"
            component={this.renderDepartmentHeads}
            classes={this.props.classes}
            departmentHeads={this.props.departmentHeads}
            users={this.props.users}
            errors={this.props.formSyncErrors.errors}
          />
        )}
      </div>
    );
  }

  renderDepartmentHeads(fields) {
    const { departmentHeads, users } = fields;

    const childFields = fields.fields;
    const name = childFields.name;

    const currentHeads = departmentHeads || [];
    const userIds = currentHeads.map(user => user.id);
    const userOptions = users.filter(
      user =>
        (user.role === EMPLOYEE ||
          user.role === DH ||
          userIds.indexOf(user.id) >= 0) &&
        user.status !== 'deleted' &&
        user.status !== 'notinvited',
    );

    const disableRemove = (childFields && childFields.length <= 1) || false;

    let dhList;
    if (childFields.length === 0 && departmentHeads.length === 0) {
      dhList = [`${name}[0]`];
    } else {
      dhList = childFields;
    }

    return dhList.map((user, index, { get }) => {
      let data;
      if (get) {
        data = get(index);
      }

      return (
        <UserSelect
          key={data?.id || index}
          addNext={() => childFields.push({})}
          removeUser={() => childFields.remove(index)}
          disableRemove={disableRemove}
          canAddNext={index === childFields.length - 1}
          user={user}
          showEmergencyCheck={true}
          userOptions={userOptions}
          noPadding
        />
      );
    });
  }

  renderSwitch(label, title, moreMargin) {
    const { classes } = this.props;
    const titleClass = clsx(classes.title, {
      [classes.titleBold]: true,
    });
    const containerCls = clsx(classes.container, {
      [classes.moreMargin]: moreMargin,
    });

    return (
      <div className={containerCls}>
        <Typography className={titleClass}>{title}</Typography>
        <Field
          name={label}
          component={Switch}
          color="primary"
          aria-label={label}
          disabled={false}
          onChange={(_, toggle) => this.onToggleSwitch(label, toggle)}
        />
      </div>
    );
  }

  renderMasterDepartmentSection() {
    const { masterDepartments, hideExisting, formSyncErrors } = this.props;
    const errors = formSyncErrors.errors;
    const error = getErrorText(errors, 'masterId') !== null;

    const options = masterDepartments.map(department => ({
      value: department.id,
      label: department.name,
    }));

    return (
      <AutoComplete
        name="masterId"
        placeholder="Master Department"
        list={options}
        disabled={hideExisting}
        error={error}
      />
    );
  }

  renderDepartmentSection() {
    const { classes, formSyncErrors } = this.props;
    const errors = formSyncErrors.errors;

    const departmentField = (
      <Field
        className={classes.field}
        component={TextField}
        label="Department Name"
        name="name"
        inputProps={{ maxLength: deptLimit }}
        error={getErrorText(errors, 'name') !== null}
        helperText={getErrorText(errors, 'name')}
        normalize={formatAllowedChars}
      />
    );

    return <div className={classes.row}>{departmentField}</div>;
  }

  renderWTCLayout() {
    const { timecardTemplateOptions } = this.props;
    const wtcLayoutTitle =
      timecardTemplateOptions?.find(
        template => template?.value === this.state.wtcLayoutId,
      )?.label || '';
    return (
      <div style={{ marginTop: '20px' }}>
        <Tooltip title={wtcLayoutTitle}>
          <span>
            <AutoComplete
              label={'Department WTC Layout'}
              name="wtcLayoutId"
              placeholder="Select an option"
              list={timecardTemplateOptions}
              onChange={(_event, id) => this.handleChange(id, 'wtcLayoutId')}
            />
          </span>
        </Tooltip>
      </div>
    );
  }

  render() {
    const {
      classes,
      enableApprovalFlow,
      hasOpenBatches,
      hideExisting = false,
    } = this.props;
    const { existingDepartmentEnabled } = this.state;

    return (
      <Paper className={classes.root} elevation={0}>
        {!hideExisting && this.renderExistingDepartmentToggle()}
        {!existingDepartmentEnabled && (
          <React.Fragment>
            {this.renderMasterDepartmentSection()}
            {this.renderDepartmentSection()}
          </React.Fragment>
        )}
        {existingDepartmentEnabled && this.renderExistingDepartmentSection()}
        <RejectionWorkflow disabled={hasOpenBatches} fullWidth hideLabel />

        {this.renderWTCLayout()}
        {enableApprovalFlow && this.renderDepartmentHeadSection()}
      </Paper>
    );
  }
}

export default withStyleSheet('EditDepartment', style)(EditDepartment);
