import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Box, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {
  TextField,
  TitleBox,
  ContentBox,
  FooterBox,
  FooterButton,
} from '../Shared/styledComponents';

import * as actions from '../actions';
import { hide as closeModal } from 'actions/modalDialog';

// import { getModalParams } from 'selectors/modalDialog';
import { getIsMobile, getLoading } from '../selectors';
import withModalDialog from 'decorators/withModalDialog';

import { CONFIRM_EDIT_MODAL } from '../Modals/modalNames';

const mapState = state => ({
  isMobile: getIsMobile(state),
  rejecting: getLoading(state, 'rejecting'),
});
const mapDispatch = dispatch => ({
  onSelfReject: (comment, url) =>
    dispatch(actions.selfReject({ comment, url })),
  onCloseModal: () => dispatch(closeModal({ dialog: CONFIRM_EDIT_MODAL })),
});

const ConfirmEditModal = props => {
  const { onSelfReject, onCloseModal, isMobile, rejecting } = props;

  const [comment, setComment] = React.useState('');

  const handleClose = () => {
    onCloseModal();
  };

  const handleConfirm = () => {
    onSelfReject(comment);
  };

  const contentText = 'Please add edit comment below';

  return (
    <Box>
      <TitleBox>
        <Box>Edit Timecard</Box>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </TitleBox>
      <ContentBox>
        {contentText}
        <TextField
          sx={{ width: isMobile ? '100%' : '400px' }}
          multiline
          minRows={3}
          placeholder={'Comment'}
          value={comment}
          disabled={rejecting}
          autoFocus
          onChange={e => setComment(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter' && e.ctrlKey) {
              handleConfirm();
            }
          }}
        />
      </ContentBox>
      <FooterBox>
        <FooterButton
          variant="outlined"
          disabled={rejecting}
          onClick={handleClose}
        >
          Cancel
        </FooterButton>
        <FooterButton onClick={handleConfirm} disabled={!comment || rejecting}>
          {rejecting ? <CircularProgress size={30} /> : 'Confirm'}
        </FooterButton>
      </FooterBox>
    </Box>
  );
};

ConfirmEditModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onSelfReject: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  rejecting: PropTypes.bool.isRequired,
};

export default compose(
  connect(mapState, mapDispatch),
  withModalDialog({
    dialog: CONFIRM_EDIT_MODAL,
    maxWidth: 'md',
    roundedCorners: true,
  }),
)(ConfirmEditModal);
