import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Popover, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import FilterDropdown from 'components/Shared/ReactTable/FilterDropdown';
import withProjectListFilter from './withProjectListFilter';

const useStyles = makeStyles(theme => ({
  FiltersComp: {},
  filterList: {
    width: 250,
    maxHeight: '65vh',
  },
  filterSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterTitle: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 700,
    padding: 10,
    lineHeight: '18px',
    letterSpacing: '1.15px',
  },
  divider: {
    margin: '0px 10px',
    height: 2,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ProjectListFilter = ({
  anchorElRef,
  isFilterOpen,
  setCloseFilterPopup,
}) => {
  const classes = useStyles();

  const ProjectTypesFilter = withProjectListFilter({
    filterName: 'projectType',
  })(FilterDropdown);

  return (
    <Popover
      anchorEl={anchorElRef.current}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      open={isFilterOpen}
      onClose={setCloseFilterPopup}
      className={classes.FiltersComp}
    >
      <div className={classes.filterList}>
        <section className={classes.filterSection}>
          <span className={classes.filterTitle}>Filter by</span>
        </section>
        <Divider className={classes.divider} />

        <ProjectTypesFilter
          label={`Project Type`}
          selectedCount={null}
          expand={true}
        />
      </div>
    </Popover>
  );
};

ProjectListFilter.propTypes = {
  anchorElRef: PropTypes.object.isRequired,
  isFilterOpen: PropTypes.bool.isRequired,
  setCloseFilterPopup: PropTypes.func.isRequired,
};

export default memo(
  ProjectListFilter,
  (prev, next) => prev.isFilterOpen === next.isFilterOpen,
);
