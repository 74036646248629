import React from 'react';
import { MaxWidthLayout } from 'containers/Layout';

import { EnhancedRoute } from 'containers/RoutingSupport';
import CommentWindow from 'feature/DigitalEdits/Comments/CommentWindow';

//BlankHeader handles setting projectId from url and making userinfo init call
import BlankHeader from 'feature/DigitalEdits/Comments/BlankHeader';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <EnhancedRoute
      authenticated
      key="/projects/reviews/comment-window"
      id="/projects/reviews/comment-window"
      path="/projects/:projectId/invoice-comments/:invoiceId"
      layout={MaxWidthLayout}
      headers={[BlankHeader]}
      component={CommentWindow}
      isLoadProjectActiveUser={true}
      title="Invoice Comments"
    />
  );
};
