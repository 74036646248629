import React, { useState } from 'react';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function Search({ textFilter, setTextFilter }) {
  const classes = useStyles();

  const [localValue, setLocalValue] = useState('');

  const setReduxFilter = filter => {
    setTextFilter({ filter });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setDebounced = React.useCallback(
    _.debounce(setReduxFilter, 200, { maxWait: 1000 }),
    [setTextFilter],
  );

  const handleChange = e => {
    const newValue = e.target.value;
    setLocalValue(newValue);
    setDebounced(newValue);
  };
  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl}>
        <Input
          id="drawer-search"
          placeholder="Search text"
          value={localValue}
          onChange={handleChange}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          inputProps={{ autoComplete: 'off' }}
        />
      </FormControl>
    </div>
  );
}
