import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Button, Tooltip, MenuItem, Menu, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PrintIcon from '@mui/icons-material/Print';
import { FaAngleDown } from 'react-icons/fa';
import { printTimecardReport } from 'actions/wtc';
import { getDownloadIconTooltip } from 'selectors/wtc';

const useStyles = makeStyles(({ palette }) => ({
  PrintMenu: {},
  icon: {
    marginRight: 8,
    backgroundColor: `${palette.primary.background}`,
    border: `3px ${palette.primary.background} solid`,
    borderRadius: 12,
    fontSize: 17,
  },
  printButton: {
    marginTop: 10,
    marginRight: 32,
  },
  printMenuItem: {
    color: palette.primary.main,
    fontWeight: 500,
  },
}));

const mapState = state => ({
  printBtnTooltipTitle: getDownloadIconTooltip(state),
});

const mapDispatch = dispatch => ({
  onPrintTimecard: param => {
    dispatch(printTimecardReport({ params: param }));
  },
});

const PrintMenu = props => {
  const classes = useStyles();
  const {
    onPrintTimecard,
    printBtnTooltipTitle,
    isMediumScreen = false,
  } = props;

  const [printMenuAnchorEl, setPrintMenuAnchorEl] = React.useState(null);
  const open = Boolean(printMenuAnchorEl);

  const openPrintMenu = event => {
    setPrintMenuAnchorEl(event.currentTarget);
  };

  const closePrintMenu = () => {
    setPrintMenuAnchorEl(null);
  };

  return (
    <div className="printMenu">
      <Button
        aria-controls="fade-menu"
        aria-haspopup="true"
        variant="text"
        color="primary"
        className={classes.printButton}
        onClick={openPrintMenu}
      >
        <PrintIcon className={classes.icon} />
        {!isMediumScreen && <span>Print</span>}
        <FaAngleDown />
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={printMenuAnchorEl}
        keepMounted
        open={open}
        onClose={closePrintMenu}
        TransitionComponent={Fade}
      >
        <MenuItem
          className={classes.printMenuItem}
          onClick={() => {
            closePrintMenu();
            onPrintTimecard('one');
          }}
        >
          <div>Print</div>
        </MenuItem>
        <MenuItem
          className={classes.printMenuItem}
          onClick={() => {
            closePrintMenu();
            onPrintTimecard('all');
          }}
        >
          <Tooltip arrow placement="top" title={printBtnTooltipTitle}>
            <div>Print Timecards</div>
          </Tooltip>
        </MenuItem>
      </Menu>
    </div>
  );
};

PrintMenu.propTypes = {
  isMediumScreen: PropTypes.bool,
  onPrintTimecard: PropTypes.func.isRequired,
  printBtnTooltipTitle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
};

export default compose(connect(mapState, mapDispatch))(PrintMenu);
