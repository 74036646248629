import validator from 'validator';

validator.hasUpperCases = value => /[A-Z]/.test(value);

validator.hasLowerCase = value => /[a-z]/.test(value);

validator.hasNumbers = value => /\d/.test(value);

validator.hasNonalphas = value => /\W/.test(value);

validator.isAlphaSpaceOnly = value =>
  /^[ a-zA-Z\p{Any}\\.\s\D\-']+$/i.test(value);

validator.isValidSsn = value => {
  const ssn = value.replace(/-/g, '');

  // Check for numerics.
  if (!validator.hasNumbers(ssn) || ssn.length !== 9) return false;

  // check for format also
  // Accepts 999-99-9999 or xxx-xx-9999 or XXX-XX-9999 format;
  const ssnPattern = /^([0-9]{3}-?[0-9]{2}|x{3}-?x{2}|X{3}-?X{2})-?[0-9]{4}$/;

  if (!ssnPattern.test(value)) return false;

  return true;
};

validator.isValidSin = value => {
  const sin = value.replace(/-/g, '');

  // Check for numerics.
  if (!validator.hasNumbers(sin) || sin.length !== 9) return false;

  // check for format also
  // Accepts 999-999-999 or xxx-xxx-999 or XXX-XXX-9999 format;
  const sinPattern = /^([0-9]{3}-?[0-9]{3}|x{3}-?x{3}|X{3}-?X{3})-?[0-9]{3}$/;

  if (!sinPattern.test(value)) return false;

  return true;
};

validator.isSame = (value, comparator) => value === comparator;

validator.isValidLuhnSin = sin => {
  if (sin && sin.includes(' ')) {
    sin = sin.replace(/\s/g, '');
  }
  if (sin.includes('-')) {
    sin = sin.replace(/-/g, '');
  }
  //Checking SIN validation
  let mulEveryOtherBy2 = (val, index) => val * ((index % 2) + 1);
  let sumValues = (acc, val) => acc + parseInt(val);

  if (sin.length !== 9) {
    return false;
  }

  // convert to an array & pop off the check digit
  sin = sin.split('');
  const check = parseInt(sin.pop());
  const sum =
    (sin
      .map(mulEveryOtherBy2)
      //To individual digits
      .join('')
      .split('')
      .reduce(sumValues, 0) *
      9) %
    10;
  return check === sum;
};
export default validator;
