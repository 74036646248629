import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { TextField } from 'components/Shared/redux';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles(({ palette }) => ({
  TimeCombo: {
    '&  p.MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: 33,
    },
  },
  clearIcon: {
    color: palette.gray['+8'],
    '&:hover': {
      color: palette.gray['+2'],
    },
  },
}));

export default function TimeCombo(props) {
  const {
    name,
    placeholder,
    disabled = false,
    value,
    viewOnly,
    change,
    timeValidator,
    error,
    helperText,
  } = props;
  const classes = useStyles();

  const focusRef = React.useRef(false);
  const [displayTime, setDisplayTime] = React.useState(
    timeValidator.parseFloatToMilitary(value),
  );

  React.useEffect(() => {
    if (focusRef.current === false) {
      setDisplayTime(timeValidator.parseFloatToMilitary(value));
    }
  }, [timeValidator, value]);

  const onFocus = () => {
    setDisplayTime(timeValidator.parseFloatToMilitary(value));
    focusRef.current = true;
  };

  const onBlur = (e, val, prevVal, name) => {
    e.preventDefault();
    let newValue = null;
    const decimal = timeValidator.parse(val);
    if (decimal !== undefined && decimal < 48) {
      newValue = decimal;
    }

    change(name, newValue);
    setDisplayTime(timeValidator.parseFloatToMilitary(newValue));
    focusRef.current = false;
  };

  const ref = React.useRef(null);
  const handleClear = React.useCallback(() => {
    const childInput = ref.current.getElementsByTagName('input');
    if (childInput && childInput[0] && childInput[0].focus) {
      childInput[0].focus();
    }
    change(name, null);
    setDisplayTime('');
  }, [change, name]);

  const handleKeyDown = React.useCallback(
    e => {
      if (e.key === 'Escape') handleClear();
    },
    [handleClear],
  );
  React.useEffect(() => {
    ref?.current?.addEventListener('keydown', handleKeyDown);
    const curr = ref?.current;
    return () => curr?.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  if (viewOnly) {
    return (
      <Typography color="inherit">
        {timeValidator.parseFloatToMilitary(value)}
      </Typography>
    );
  } else {
    return (
      <Field
        name={name}
        component={TextField}
        placeholder={placeholder}
        disabled={disabled}
        props={{
          value: displayTime ?? '',
          className: classes.TimeCombo,
          InputProps: {
            ref: ref,
            endAdornment: !disabled && (
              <ClearIcon className={classes.clearIcon} onClick={handleClear} />
            ),
          },
          error,
          helperText,
        }}
        onChange={(e, newVal) => {
          setDisplayTime(newVal);
        }}
        normalize={(val, prev) => (val === '' ? null : val)} //empty values need to be null for validation
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  }
}

TimeCombo.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  viewOnly: PropTypes.bool,
  change: PropTypes.func.isRequired,
  timeValidator: PropTypes.object.isRequired,
};
