import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Typography } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';

// actions
import { deletePhoneNumber } from 'actions/profile';

const mapDispatchToProps = dispatch => ({});

const onSubmit = (values, dispatch) => {
  dispatch(deletePhoneNumber());
};

const style = () => ({
  content: {
    maxWidth: 500,
  },
});

class DeletePhoneNumber extends Component {
  render() {
    const { classes, handleSubmit, defaultAllowanceId, ...others } = this.props;

    const content = (
      <div className={classes.content}>
        <Field
          component="input"
          name="DeletePhoneNumber"
          type="hidden"
          value={defaultAllowanceId}
        />
        <Typography color="inherit">
          {'Are you sure you want to delete this phone number?'}
        </Typography>
      </div>
    );
    const title = (
      <Typography variant="h6" color="inherit">
        {'Delete Phone Number'}
      </Typography>
    );

    return (
      <form onSubmit={handleSubmit}>
        <ConfirmModal
          content={content}
          title={title}
          buttonText="Continue"
          buttonType="secondary"
          raisedButton
          {...others}
        />
      </form>
    );
  }
}
export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: 'deletePhoneNumber', maxWidth: 'md' }),
  connect(mapDispatchToProps),
  reduxForm({
    form: 'DeletePhoneNumber',
    onSubmit,
  }),
)(DeletePhoneNumber);
