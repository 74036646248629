import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { Field, FieldArray } from 'redux-form';
import { FaCheck as Verified } from 'react-icons/fa';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from 'components/Shared/redux';
import InputAdornment from '@mui/material/InputAdornment';
import { normalizePhone } from 'utils/weekUtils';
import NotificationMobileRow from './NotificationMobileRow';

import {
  Divider,
  Button,
  Typography,
  Switch,
  AccordionActions,
  IconButton,
  LinearProgress,
  Chip,
  Avatar,
  Box,
} from '@mui/material';
const useStyles = makeStyles(({ palette }) => ({
  successMessage: {
    color: palette.primary.main,
  },
  failedMessage: {
    color: palette.error.main,
  },
  noPadding: {
    padding: 0,
  },
  textRightPadding: {
    margin: 5,
    display: 'flex',
    flexDirection: 'column',
    fontSize: 'small',
  },
  iconButton: {
    padding: 8,
    fontSize: '1em',
    display: 'inline',
    verticalAlign: 'bottom',
  },
  fieldColumn: {
    borderBottom: 0,
  },
  field: {
    width: '100%',
  },
  saveButton: {
    borderRadius: '8px',
    fontSize: '14px',
    margin: 10,
  },
}));
const NotificationMobile = props => {
  const {
    notifications,
    phoneNumbers,
    updateStatus = '',
    loadNotification,
    loadPhoneNumber,
    onSelectIndividual,
    onDeletePhoneNumber,
    phoneNumberVerified,
    onVerifyPhoneNumber,
    change,
  } = props;

  const classes = useStyles();
  const isVerified = phoneNumbers.verified === true;

  const [enableSMSNotification, setEnableSMSNotification] = useState(
    phoneNumberVerified || false,
  );
  const [isPhoneNumberEditable, setIsPhoneNumberEditable] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const isNumberPresent = (phoneNumbers.number || phoneNumber).length !== 0;

  const diffNumber =
    (phoneNumber || '').length > 11 && phoneNumber !== phoneNumbers.number;

  const toggleSMSNotifications = checked => {
    setEnableSMSNotification(checked);
    if (_.isEmpty(phoneNumbers.number)) {
      toggleEditMode();
    }
  };
  const toggleEditMode = () => {
    setIsPhoneNumberEditable(!isPhoneNumberEditable);
  };
  const enableToggle =
    !_.isEmpty(phoneNumbers.number) ||
    !_.isEmpty(phoneNumber) ||
    enableSMSNotification;

  let statusMessageCls = '';
  let updateStatusMessage = '';
  if (updateStatus === 'S') {
    statusMessageCls = classes.successMessage;
    updateStatusMessage = 'Notifications saved successfully';
  } else if (updateStatus === 'F') {
    statusMessageCls = classes.failedMessage;
    updateStatusMessage = 'Notifications save failed';
  }
  const onClearPhoneNumber = () => {
    change('phoneNumber', '');
    setPhoneNumber('');
  };
  const onPhoneNumberChange = e => {
    setPhoneNumber(e.target.value);
  };
  const cancelEditPhoneNumber = () => {
    toggleEditMode();
    change('phoneNumber', phoneNumbers.number);
    setPhoneNumber({
      phoneNumber: phoneNumbers.number,
    });
  };

  const sendVerification = () => {
    if (isPhoneNumberEditable) {
      toggleEditMode();
    }
    onVerifyPhoneNumber();
  };
  const renderPreferences = ({ fields, notifications, onSelectIndividual }) => {
    return fields?.map((member, index) => {
      const preference = notifications && notifications[index];
      return (
        <NotificationMobileRow
          key={preference.notificationtype}
          member={member}
          preference={preference}
          onSelectIndividual={onSelectIndividual}
        />
      );
    });
  };
  return (
    <Box>
      {(loadNotification || loadPhoneNumber) && <LinearProgress />}
      {!loadNotification && !loadPhoneNumber && (
        <Box>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              Enable SMS Notifications
              <Switch
                color="primary"
                onChange={(e, checked) => toggleSMSNotifications(checked)}
                checked={enableToggle}
              />
            </Box>
            <Divider />
            {enableToggle && (
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'left',
                  }}
                >
                  <Box>
                    <Field
                      className={classes.field}
                      component={TextField}
                      placeholder="Phone Number"
                      name={'phoneNumber'}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+1</InputAdornment>
                        ),
                      }}
                      normalize={normalizePhone}
                      disabled={!isPhoneNumberEditable}
                      onChange={onPhoneNumberChange}
                    />
                  </Box>
                  {isPhoneNumberEditable && (
                    <Box className={classes.fieldColumn}>
                      <IconButton
                        className={classes.iconButton}
                        onClick={onClearPhoneNumber}
                        aria-label="clear phone number"
                        size="large"
                      >
                        <ClearIcon size={18} />
                      </IconButton>
                    </Box>
                  )}
                  {isPhoneNumberEditable && isVerified && (
                    <Box>
                      <Button color="primary" onClick={cancelEditPhoneNumber}>
                        Cancel
                      </Button>
                    </Box>
                  )}
                  {isNumberPresent && !isPhoneNumberEditable && (
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <IconButton
                        className={classes.iconButton}
                        onClick={toggleEditMode}
                        size="large"
                      >
                        <EditIcon size={18} />
                      </IconButton>
                      <IconButton
                        className={classes.iconButton}
                        onClick={onDeletePhoneNumber}
                        size="large"
                      >
                        <DeleteIcon size={18} />
                      </IconButton>
                    </Box>
                  )}
                </Box>
                <Box sx={{ textAlign: 'end' }}>
                  {isPhoneNumberEditable && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.saveButton}
                      onClick={sendVerification}
                      disabled={!diffNumber}
                    >
                      {'Send Verification'}
                    </Button>
                  )}
                </Box>
                <Box sx={{ textAlign: 'end' }}>
                  {isNumberPresent && !isVerified && !isPhoneNumberEditable && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.saveButton}
                      onClick={sendVerification}
                    >
                      {'Send Verification'}
                    </Button>
                  )}
                  {isVerified && !diffNumber && !isPhoneNumberEditable && (
                    <Chip
                      color="primary"
                      label="Verified"
                      size="small"
                      avatar={
                        <Avatar>
                          <Verified />
                        </Avatar>
                      }
                    />
                  )}
                </Box>
                <Box className={classes.textRightPadding}>
                  Please Note: If you update this number for notifications it
                  will also be changed on your profile. Standard rates & charges
                  may apply.
                </Box>
                <Divider />
              </Box>
            )}
          </Box>
          <Box sx={{ top: 10 }}>
            <FieldArray
              name="notifications"
              component={renderPreferences}
              notifications={notifications}
              onSelectIndividual={onSelectIndividual}
            />
          </Box>
          <Box>{'*All times are in PST timezone'}</Box>
          <AccordionActions className={classes.noPadding}>
            <Box className={classes.spacer}>
              <Typography className={statusMessageCls}>
                {updateStatusMessage}
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.saveButton}
              >
                Save
              </Button>
            </Box>
          </AccordionActions>
        </Box>
      )}
    </Box>
  );
};
export default NotificationMobile;
