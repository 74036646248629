import _ from 'lodash';
import { defaultSorting } from 'reducers/sorting';
import { createSelector } from 'reselect';
import { getPagination } from './pagination';

export const getSortParams = grid => state =>
  _.get(state, `sorting.${grid}`, defaultSorting);

export const sortData = (selector, grid, tempPagination) =>
  createSelector(
    [selector, getSortParams(grid), getPagination(grid)],
    (data, params, pagination) => {
      let sortedList = data || [];
      const page = tempPagination
        ? tempPagination.activePage
        : pagination.activePage;
      const rowsPerPage = pagination.pageSize;

      if (params.orderBy) {
        sortedList = _.sortBy(data, [params.orderBy]);
        if (params.direction === 'desc') {
          sortedList = sortedList.reverse();
        }
      }

      if (pagination && pagination.disable) return sortedList;

      return sortedList.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      );
    },
  );

export const sortDataNoPagination = (selector, grid) =>
  createSelector([selector, getSortParams(grid)], (data, params) => {
    let sortedList = data || [];

    if (params.orderBy) {
      sortedList = _.sortBy(data, [params.orderBy]);
      if (params.direction === 'desc') {
        sortedList = sortedList.reverse();
      }
    }

    return sortedList;
  });
