import React from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import moment from 'moment-timezone';
import CommentIcon from 'components/images/CommentIcon';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import BackgroundLetterAvatars from 'components/Shared/BackgroundLetterAvatars';

import RichTextDisplay from 'components/RichText/RichTextDisplay';
import { ROLE_LABELS } from 'components/props/profiles';

import { TIMEZONE_OPTIONS } from 'components/Shared/constants';

const useStyles = makeStyles(({ palette }) => ({
  Comment: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
    padding: 24,
    backgroundColor: palette.gray['+9'],
    borderRadius: 25,
  },
  commentText: {
    overflowWrap: 'anywhere',
    fontSize: 16,
    display: '-webkit-box',
  },
  notExpanded: {
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    overflow: 'hidden',
  },
  avatar: {
    marginRight: 8,
  },
  headerText: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2px 8px 4px 0px',
    alignItems: 'start',
    fontSize: 14,
  },
  badge: {
    border: `1px solid ${palette.primary.main}`,
    backgroundColor: palette.contrastText,
    color: palette.primary.main,
    borderRadius: 20,
    padding: '5px',
    marginLeft: '5px',
    display: 'flex',
    alignItems: 'center',
    gap: 3,
  },
}));

const Comment = props => {
  const classes = useStyles();
  const { comment, project } = props;
  const {
    comment: commentText,
    userName,
    createdAt,
    role,
    type,
    referenceDate,
  } = comment;

  const displayRole = ROLE_LABELS[role] || role;

  const [expanded, setExpanded] = React.useState(false);

  const updatedDate = moment.utc(createdAt, 'YYYY-MM-DDTHH:mm:ss');

  const { timeZone } = project;

  let projectTz = TIMEZONE_OPTIONS.find(tz => tz.value === timeZone);

  if (!projectTz) {
    projectTz = TIMEZONE_OPTIONS[0];
  }

  const updatedDateStr = updatedDate
    .tz(projectTz.moment)
    .format('MM/DD/YYYY HH:mm:ss');
  const timezoneLabel = projectTz.lbl;
  const [replyDisabled, setReplyDisabled] = React.useState(false);

  React.useEffect(() => {
    if (replyDisabled) {
      setTimeout(() => {
        setReplyDisabled(false);
      }, 1000);
    }
  }, [replyDisabled]);

  return (
    <Box className={classes.Comment}>
      <BackgroundLetterAvatars className={classes.avatar} name={userName} />
      <Box
        sx={{ width: '100%' }}
        onClick={() => {
          setExpanded(v => !v);
        }}
      >
        <Box className={classes.headerText}>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              //   alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ fontWeight: '800' }}>{userName} </Box>
            <Box>Role: {displayRole} </Box>
            <Box>
              Date:
              {updatedDateStr} {`(${timezoneLabel})`}
            </Box>
          </Box>
          {type === 'day' && (
            <Box className={classes.badge}>
              <CommentIcon />
              {moment(referenceDate).format('dddd')}
            </Box>
          )}
        </Box>

        <Box
          className={clsx(classes.commentText, {
            [classes.notExpanded]: !expanded,
          })}
        >
          <RichTextDisplay commentText={commentText} />
        </Box>
      </Box>
    </Box>
  );
};

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
};

export default Comment;
