import React, { useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
// import FileCopyIcon from '@mui/icons-material/FileCopy';
import clsx from 'clsx';

import { ClickAwayListener } from '@mui/material';

// import BulkEditContext from './BulkEditContext';
import { ERROR_STATE_COLUMNS } from 'utils/bulkEditUtils';
import Portal from 'components/Shared/ReactTable/Portal';
import PortalDropDown from 'containers/Employees/Reviews/BulkEdit/PortalDropDown';
// import BulkDropDown from 'containers/Employees/Reviews/BulkEdit/BulkDropDown';
// // selector
// import { getTableDropdown } from 'selectors/bulkEdit';

// // action
// import { fetchDropdown } from 'actions/bulkEdit';

const style = theme => ({
  selectInput: {
    width: '-webkit-fill-available',
    height: '20px',
    fontSize: 12,
    padding: '0 6px 0 6px',
    border: 0,
  },
  errorInput: {
    backgroundColor: theme.palette.button.yellow.background,
    color: theme.palette.error.main,
  },
  inputWrapper: {
    borderBottom: '1px solid lightgray',
    backgroundColor: 'white',
  },
  errorWrapper: {
    border: `1px solid ${theme.palette.error.main} !important`,
    borderRadius: 4,
    backgroundColor: theme.palette.button.yellow.background,
  },
  downArrow: {
    padding: 3,
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    border: `solid ${theme.palette.text.secondary}`,
    transform: 'rotate(45deg)',
    marginBottom: 1,
  },
  errorArrow: {
    borderWidth: '0 2px 2px 0',
    border: `solid ${theme.palette.error.main}`,
  },
});

function CustomSelect(props) {
  const {
    value: tableValueString,
    column: { id: columnId = '', width: columnWidth },
    row: { original = {}, index: rowIndex },
    row,
    classes,
    updateMyData,
    copyToAll,
  } = props;

  const tableValue = original[columnId] || {};
  const timecardEntryHeaderId = original.timecardEntryHeaderId,
    effectiveDate = original.effectiveDate,
    rowErrors = original.errors;

  let cellHasError = false;

  if (ERROR_STATE_COLUMNS.includes(columnId)) {
    cellHasError = rowErrors.find(err => err.field === columnId);
    if (!tableValueString && !tableValue.id) cellHasError = true;
  }
  const [localValue, setValue] = React.useState(tableValue);

  useEffect(() => {
    setValue(tableValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableValueString]);

  const [tabIndex, setTabIndex] = React.useState(0);
  const [focus, setFocus] = React.useState(null);
  const selectRef = React.useRef(null);

  React.useEffect(() => {
    if (focus) {
      const fixedBoundary = document.querySelector('#BulkEditFixedBoundary');
      const spec = focus.getBoundingClientRect();
      const boundarySpec = fixedBoundary.getBoundingClientRect();
      const overlap = spec.left - boundarySpec.right;

      if (overlap <= 0) {
        setTimeout(() => {
          document.querySelector('#BulkEditTableContainer') &&
            document.querySelector('#BulkEditTableContainer').scrollBy({
              behavior: 'smooth',
              top: 0,
              left: overlap - 10,
            });
        });
      }
    }
  }, [focus]);

  const paths = [`${timecardEntryHeaderId}`, `${effectiveDate}`, `${columnId}`];
  const onBlur = () => {
    setFocus(null);
  };

  const onKeyPress = e => {
    if (e.nativeEvent.key === 'Enter') {
      e.currentTarget.parentNode.focus();
    }
  };
  const onFocus = e => {
    setFocus(selectRef.current);
  };
  return (
    <ClickAwayListener
      onClickAway={() => {
        setFocus(null);
      }}
      mouseEvent="onMouseDown"
    >
      <div>
        <div
          className={clsx({
            [classes.inputWrapper]: true,
            [classes.errorWrapper]: !!cellHasError,
          })}
          style={{ width: columnWidth }}
        >
          <input
            id={`${rowIndex}.${columnId}`}
            className={clsx(classes.selectInput, {
              [classes.errorInput]: !!cellHasError,
            })}
            style={{ width: columnWidth - 26 }}
            value={
              columnId === 'episode'
                ? (localValue || '') && (localValue.code || '')
                : (localValue || '') && (localValue.name || '')
            }
            onChange={() => {}}
            tabIndex={tabIndex}
            ref={selectRef}
            onKeyDown={onKeyPress}
            onFocus={onFocus}
            readyonly="true"
            placeholder={'Select...'}
          />
          <i
            className={clsx({
              [classes.downArrow]: true,
              [classes.errorArrow]: !!cellHasError,
            })}
            onClick={onFocus}
          />
        </div>
        {focus && (
          <Portal targetDom={focus} variant="bulkEdit" dropdown>
            <PortalDropDown
              paths={paths}
              setTabIndex={setTabIndex}
              value={localValue}
              tableValue={tableValue}
              setValue={setValue}
              onBlur={onBlur}
              columnId={columnId}
              rowIndex={rowIndex}
              copyToAll={copyToAll}
              updateMyData={updateMyData}
              cellHasError={cellHasError}
              htgContractId={original.htgContractId}
              htgUnionId={original.htgUnionId}
              row={row}
            />
          </Portal>
        )}
      </div>
    </ClickAwayListener>
  );
}

export default withStyles(style)(CustomSelect);
