import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
import { Warning } from '@mui/icons-material';
// actions

const useStyles = makeStyles(theme => ({
  content: {
    maxWidth: 500,
  },
  [theme.breakpoints.down('lg')]: {
    content: {
      maxWidth: 344,
    },
  },
  icon: {
    verticalAlign: 'middle',
    paddingRight: 5,
    color: '#FF7800', //hardCoded Colors
  },
}));

function TakeMeBack({ handleSubmit, timecardId, ...others }) {
  const classes = useStyles();

  const title = (
    <div>
      <Warning className={classes.icon} />
      {'Unsaved Changes'}
    </div>
  );

  const content = (
    <div className={classes.content}>
      <Typography color="inherit">
        {'Your new changes will be lost.'} <br />
        {'Are you sure you want to leave this page?'}
      </Typography>
    </div>
  );

  return (
    <ConfirmModal
      content={content}
      title={title}
      buttonText="Leave this Page"
      buttonType="default"
      cancelText="Stay on this Page"
      raisedButton
      {...others}
    />
  );
}

export default withModalDialog({ dialog: 'TakeMeBack', maxWidth: 'md' })(
  TakeMeBack,
);
