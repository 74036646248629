import React from 'react';
import Typography from '@mui/material/Typography';

export default function ReadOnlyField({ title, value }) {
  return (
    <React.Fragment>
      <Typography variant="caption" color="inherit">
        {title}
      </Typography>
      <Typography variant="subtitle1" color="inherit">
        {value}
      </Typography>
    </React.Fragment>
  );
}
