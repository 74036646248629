import { all, takeEvery, call, put, select, delay } from 'redux-saga/effects';
import camelCase from 'camelcase-keys';
import { startSubmit, stopSubmit } from 'redux-form';
// actions
import * as actions from 'actions/reviewFlows';
import { showAlert } from 'actions/alert';
import { hide as hideModal } from 'actions/modalDialog';
import { fetch as fetchProjectDeparments } from 'actions/departments';
import { fetch as refreshProjectUsers } from 'actions/users';
// selectors
import { getProject as project } from 'selectors/routeParams';
import { getDepartments as departments } from 'selectors/department';
import {
  getEditingUser,
  getEditingDepartment,
  getEditApprovalFlowForm,
} from 'selectors/reviewFlows';
import { fetchDetails } from 'actions/project';

export function* add(api, debug) {
  try {
    const projectId = yield select(project);
    const flowData = yield call(api.reviewFlows.addApprovalFlow, { projectId });
    yield put(actions.setNewFlowId({ id: flowData.id }));
    yield put(actions.fetch());
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

export function* fetch(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const reviewFlows = yield call(api.reviewFlows.list, { projectId });
    yield put(actions.store({ reviewFlows }));
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.store({ reviewFlows: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

export function* remove(api, debug, params) {
  try {
    const projectId = yield select(project);
    const reviewFlowId = params.reviewFlowId;

    yield call(api.reviewFlows.removeApprovalFlow, { projectId, reviewFlowId });
    yield put(actions.fetch());
    yield put(fetchProjectDeparments());
    yield put(hideModal({ dialog: 'removeApprovalFlow' }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

export function* fetchSteps(api, debug, params) {
  const id = params.reviewFlowId; // Think of any alternative?
  try {
    const projectId = yield select(project);
    const steps = yield call(api.reviewFlows.reviewFlowSteps, {
      projectId,
      reviewFlowId: id,
    });
    yield put(actions.storeSteps({ steps, id }));
  } catch (e) {
    debug(e);
    yield put(actions.storeSteps({ steps: [], id }));
    yield put(showAlert());
  }
}

export function* fetchStepUsers(api, debug, params) {
  const { reviewFlowId: id, stepId } = params;
  try {
    const projectId = yield select(project);
    const users = yield call(api.reviewFlows.reviewFlowStepUsers, {
      projectId,
      reviewFlowId: id,
      stepId,
    });

    yield put(actions.storeStepUsers({ id, stepId, users }));
  } catch (e) {
    debug(e);
    yield put(actions.storeStepUsers({ id, stepId, users: [] }));
    yield put(showAlert());
  }
}

export function* removeStepUser(api, debug) {
  try {
    const projectId = yield select(project);
    const { reviewFlowId, stepId, userId } = yield select(getEditingUser);
    yield call(api.reviewFlows.removeReviewer, {
      projectId,
      reviewFlowId,
      stepId,
      userId,
    });
    yield put(actions.fetchSteps({ reviewFlowId }));
    yield put(actions.fetchStepUsers({ reviewFlowId, stepId }));
    yield put(hideModal({ dialog: 'removeReviewer' }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

export function* saveEditingStepUser(api, debug) {
  try {
    yield put(actions.saving({ saving: true }));
    const projectId = yield select(project);
    const reviewFlowForm = yield select(getEditApprovalFlowForm);
    const reviewFlowId = reviewFlowForm.reviewFlowId;
    let stepId = reviewFlowForm.stepId;
    const role = reviewFlowForm.role;
    const users = reviewFlowForm.reviewers;
    if (stepId) {
      yield call(api.reviewFlows.updateReviewers, {
        projectId,
        reviewFlowId,
        stepId,
        users,
      });
    } else {
      const data = yield call(api.reviewFlows.saveReviewers, {
        projectId,
        reviewFlowId,
        role,
        users,
      });
      stepId = data.id;
    }
    yield delay(3000);
    yield put(refreshProjectUsers());
    yield put(hideModal({ dialog: 'editApprovalFlow' }));
    yield put(actions.fetchSteps({ reviewFlowId }));
    yield put(actions.fetchStepUsers({ reviewFlowId, stepId }));
    yield put(actions.saving({ saving: false }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
    yield put(actions.saving({ saving: false }));
  }
}

export function* fetchDepartments(api, debug, params) {
  const id = params.reviewFlowId;
  try {
    const projectId = yield select(project);
    const data = yield call(api.reviewFlows.departments, {
      projectId,
      reviewFlowId: id,
    });
    const departments = camelCase(data, { deep: true });
    yield put(actions.storeDepartments({ departments, id }));
  } catch (e) {
    debug(e);
    yield put(actions.storeDepartments({ departments: [], id }));
    yield put(showAlert());
  }
}

export function* fetchDeptStats(api, debug) {
  let departmentId;
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const department = yield select(getEditingDepartment);
    departmentId = department.id;
    const stats = yield call(api.departments.stats, {
      projectId,
      departmentId,
    });
    yield put(actions.storeDeptStats({ departmentId, stats }));
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeDeptStats({ departmentId, stats: {} }));
    yield put(showAlert());
    yield put(actions.loading({ loading: false }));
  }
}

export function* saveDepartment(api, debug, params) {
  const department = params.department;
  const formName = params.formName;
  try {
    const projectId = yield select(project);
    if (formName) yield put(startSubmit(formName));
    if (department && department.departmentId) {
      const existingDepts = yield select(departments);
      const result = existingDepts.filter(
        dept => dept.id === department.departmentId && !dept.reviewFlowId,
      );

      if (result && result.length > 0) {
        department.masterId = department.masterId || result['0'].masterId;
        department.associationId =
          department.associationId || result['0'].associationId;
        department.name = department.name || result['0'].name;

        const existingData = {
          data: {
            departmentIds: [department.departmentId],
            id: department.reviewFlowId,
            projectId: projectId,
          },

          reviewFlowId: department.reviewFlowId,
          projectId,
        };

        yield call(api.reviewFlows.addExistingDepartment, existingData);
      }
    }

    delete department.departmentHeads;
    const reviewFlowId = department.reviewFlowId;
    const data = { projectId, reviewFlowId, department };

    if (department && department.departmentId) {
      yield call(api.reviewFlows.updateDepartment, data);
    } else {
      yield call(api.reviewFlows.saveDepartment, data);
    }

    yield put(actions.fetchDepartments({ reviewFlowId }));
    yield put(fetchProjectDeparments());
    yield delay(3000);
    yield put(refreshProjectUsers());
    yield put(hideModal({ dialog: 'editDepartment' }));
  } catch (e) {
    debug(e);
    const errors = e.data ? camelCase(e.data, { deep: true }) : null;
    const description = errors && errors.description;
    if (description) {
      for (let key in description) {
        yield put(
          showAlert({
            message: description[key],
            variant: 'error',
          }),
        );
      }
    } else {
      yield put(showAlert());
    }
  } finally {
    if (formName) yield put(stopSubmit(formName));
  }
}

export function* removeDepartment(api, debug, params) {
  try {
    const projectId = yield select(project);
    const { reviewFlowId, departmentId } = params;
    const data = { projectId, reviewFlowId, departmentId };
    yield call(api.reviewFlows.deleteDepartment, data);
    yield put(actions.fetchDepartments({ reviewFlowId }));
    yield put(hideModal({ dialog: 'removeDepartment' }));
  } catch (e) {
    debug(e);
  }
}

export function* toggleApproval(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const settings = { department_approve: params.enabled };
    yield call(api.projects.updateSettings, { projectId, settings });
    yield put(fetchDetails());
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

export function* toggleDepartmentHeadViewGross(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const settings = { dh_gross_enabled: params.enabled };
    yield call(api.projects.updateSettings, { projectId, settings });
    yield put(fetchDetails());
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

export function* togglePayrollAccountantDeleteTimecards(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const settings = { pa_delete_timecards: params.enabled };
    yield call(api.projects.updateSettings, { projectId, settings });
    yield put(fetchDetails());
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}
export function* reviewLevels(api, debug) {
  try {
    const projectId = yield select(project);
    const flowData = yield call(api.reviewFlows.reviewLevels, { projectId });
    yield put(actions.storeReviewLevels({ levels: flowData }));
    yield put(actions.fetch());
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}
function* fetchTimecardTemplates(api, debug) {
  try {
    const rsp = yield call(api.projects.getTimecardTemplates);
    const data = rsp.documents;
    yield put(actions.storeTimecardTemplates({ data }));
  } catch (e) {
    debug(e);
  }
}

export default function* reviewFlowsFlow({ api, debug }) {
  yield all([
    takeEvery(`${actions.add}`, add, api, debug),
    takeEvery(`${actions.fetch}`, fetch, api, debug),
    takeEvery(`${actions.remove}`, remove, api, debug),
    takeEvery(`${actions.fetchSteps}`, fetchSteps, api, debug),
    takeEvery(`${actions.fetchStepUsers}`, fetchStepUsers, api, debug),
    takeEvery(`${actions.removeStepUser}`, removeStepUser, api, debug),
    takeEvery(
      `${actions.saveEditingStepUser}`,
      saveEditingStepUser,
      api,
      debug,
    ),
    takeEvery(`${actions.fetchDepartments}`, fetchDepartments, api, debug),
    takeEvery(`${actions.fetchDeptStats}`, fetchDeptStats, api, debug),
    takeEvery(
      `${actions.fetchTimecardTemplates}`,
      fetchTimecardTemplates,
      api,
      debug,
    ),
    takeEvery(`${actions.saveDepartment}`, saveDepartment, api, debug),
    takeEvery(`${actions.removeDepartment}`, removeDepartment, api, debug),
    takeEvery(`${actions.setHeadApproval}`, toggleApproval, api, debug),
    takeEvery(
      `${actions.setHeadViewGross}`,
      toggleDepartmentHeadViewGross,
      api,
      debug,
    ),
    takeEvery(
      `${actions.setPaDeleteTimecards}`,
      togglePayrollAccountantDeleteTimecards,
      api,
      debug,
    ),
    takeEvery(`${actions.fetchReviewLevels}`, reviewLevels, api, debug),
  ]);
}
