import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// decorators
import withDataGrid from 'decorators/withDataGrid';
// components
import ProjectList from 'components/Employees/Projects';
// selectors
import { getProjects, getLoading, multiDBCheck } from 'selectors/project';
import { getSessionUser } from 'selectors/session';
// actions
import {
  fetch as fetchProjects,
  setViewingYear,
  storeDetails,
  navigateTo,
  fetchPaginationProjects,
  projectSorting,
  clearProjectData,
} from 'actions/project';
import { apply } from 'actions/sorting';
import { LinearProgress } from '@mui/material';

const grid = 'projects';

const mapStateToProps = state => ({
  projects: getProjects(state),
  currentUser: getSessionUser(state),
  loading: getLoading(state),
  isMultiDBs: multiDBCheck(state),
});

const mapDispatchToProps = (dispatch, { onChangePageSize, pagination }) => ({
  onFetchData: () => {
    dispatch(clearProjectData());
    dispatch(fetchProjects());
    dispatch(storeDetails({ project: {} }));
    dispatch(setViewingYear({ viewingYear: null }));
  },
  addTen: () => {
    onChangePageSize(pagination.pageSize + 10);
  },
  onNavigate: projectId => {
    dispatch(navigateTo({ projectId }));
  },
  onNavigateToNextPage: page => {
    dispatch(fetchPaginationProjects({ page, pageSize: pagination.pageSize }));
  },
  onRowsChange: pageSize => {
    dispatch(fetchPaginationProjects({ page: 0, pageSize }));
  },
  onProjectListApplySorting: (orderBy, direction) => {
    dispatch(apply({ grid, orderBy, direction }));
    dispatch(projectSorting({ grid, orderBy, direction }));
  },
});

class AdminProjects extends Component {
  static propTypes = {
    onFetchData: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.onFetchData();
  }

  render() {
    if (this.props.loading) return <LinearProgress />;

    const navigate = (projectId, region) => () => {
      localStorage.setItem('region', region);
      this.props.onNavigate(projectId);
    };

    return <ProjectList navigate={navigate} {...this.props} />;
  }
}

export default withDataGrid(grid)(
  connect(mapStateToProps, mapDispatchToProps)(AdminProjects),
);
