import React, { useRef, useState, useEffect } from 'react';

import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

//actions
import * as actions from './actions';
import { setInvoiceFilter, clearFilters } from 'actions/searchTimecards';

//selectors
import {
  getData,
  getTotalCount,
  getStatusWithRole,
  getFilterBadgesCount,
  getSortBy,
  getCount,
  getLoading,
} from './selectors';
import { getLimitedIAAccess } from 'selectors/session';

//components
import Sidebar from './Sidebar';
import Table from './Table';

//utils
import { getProjectFromURL } from 'utils/helperFunctions';

import DigitalEditsCommentsLite from 'feature/DigitalEdits/Comments/DigitalEditsCommentsLite';

// import { DH, PA, UPM } from 'components/props/profiles';

// let currentUserRole;

const mapState = (state, { match }) => ({
  data: getData(state),
  count: getCount(state),
  totalCount: getTotalCount(state),
  status: getStatusWithRole(state),
  sortBy: getSortBy(state),
  filterBadgeCountObj: getFilterBadgesCount(state),
  hasLimitedIAAccess: getLimitedIAAccess(state),
  loading: getLoading(state),
});

const mapDispatch = (dispatch, { location }) => ({
  onInitFetch: () => {
    dispatch(actions.init());
  },
  onStatusFilterSelect: index => {
    dispatch(actions.onSelect({ index, filterName: 'status' }));
    dispatch(actions.fetch({ filterName: 'status' }));
  },
  onAllInvoiceFilterSelect: () => {
    dispatch(actions.onSelectAll({ filterName: 'status' }));
    dispatch(actions.fetch({ filterName: 'status' }));
  },
  onClearFilters: () => {
    dispatch(actions.clearFilters());
    dispatch(actions.fetch({ init: true }));
  },
  onClickInvoice: ({ invoiceId, selectedInvoiceCount }) => {
    const projectId = getProjectFromURL(location.pathname);
    let toURL = `/projects/${projectId}/review/search-timecards`;
    // console.log('The invoice id', invoiceId);
    try {
      dispatch(
        actions.setSelectedInvoice({
          selectedInvoice: invoiceId,
          selectedInvoiceCount: selectedInvoiceCount,
        }),
      );
      dispatch(clearFilters());
      dispatch(setInvoiceFilter({ invoice: invoiceId }));
      // dispatch(setFromURL({ fromURI: location.pathname }));
      dispatch(push(toURL));
    } catch (error) {
      console.error('Error creating URL for search timecards view');
      // dispatch(showAlert());
    }
  },
  onLoadMore: () => {
    dispatch(actions.loadMore());
    dispatch(actions.fetch());
  },
  onStoreSort: sortBy => dispatch(actions.storeSort({ sortBy })),
  onSearchFetch: () => dispatch(actions.fetch()), //fetch data only ( to be used on sort change)
});
const SearchInvoices = props => {
  const {
    data,
    loading,
    status,
    sortBy,
    count,
    totalCount,
    onInitFetch,
    filterBadgeCountObj,
    onClearFilters,
    onClickInvoice,
    onSearchFetch,
    onStatusFilterSelect,
    onStoreSort,
    onAllInvoiceFilterSelect,
    onLoadMore,
    hasLimitedIAAccess,
  } = props;
  const [showFilters, setShowFilters] = useState(true);
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current === false) {
      onInitFetch();
      didMountRef.current = true;
    }
  }, [onInitFetch]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '78vh',
      }}
    >
      <Sidebar
        onClearFilters={onClearFilters}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
      />
      <Table
        data={data}
        loading={loading}
        status={status}
        count={count}
        totalCount={totalCount}
        showFilters={showFilters}
        sortBy={sortBy}
        onStoreSort={onStoreSort}
        onStatusFilterSelect={onStatusFilterSelect}
        onAllInvoiceFilterSelect={onAllInvoiceFilterSelect}
        filterBadgeCountObj={filterBadgeCountObj}
        onClickInvoice={onClickInvoice}
        onLoadMore={onLoadMore}
        onSearchFetch={onSearchFetch}
        hasLimitedIAAccess={hasLimitedIAAccess}
      />
      <DigitalEditsCommentsLite searchInvoicePage={true} />
    </div>
  );
};

export default compose(connect(mapState, mapDispatch))(SearchInvoices);
