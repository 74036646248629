import { all, takeLatest, call, put, select } from 'redux-saga/effects';

//actions
import * as actions from './actions';
import { showAlert } from 'actions/alert';

//selectors
import { getProject } from 'selectors/routeParams';
import { getFilterDbCode, getRouteYear } from 'selectors/project';
import {
  getAllFilters,
  getPageSize,
  getSortByForReq,
  getRemovedSelectedOption,
} from './selectors';

//constant + utils
import { makeFilterArgFactory } from './searchUtils';
import { delayOnValue } from 'utils/helperFunctions';

function* init() {
  yield put(actions.fetch({ init: true }));
}

export function* fetch(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(getProject);
    const filters = yield select(getAllFilters);

    const statusFilter = filters.find(f => f.field === 'status');
    const activeFilter = params.filterName;
    const init = params.init;
    const shouldUpdateFilters = activeFilter === 'status' || init;

    const sortBy = yield select(getSortByForReq);

    const makeFilterArgs = makeFilterArgFactory({
      fetchFilter,
      api,
      debug,
      statusFilter,
      projectId,
    });

    // filters to refresh with the current filter arguments
    const names = [
      'invoiceEditsSent',
      'invoiceDescription',
      'invoiceId',
      'invoiceWeekendingDate',
      'invoiceType',
      'futureReleaseDate',
    ];
    if (shouldUpdateFilters) {
      yield all([
        ...names.map(name => call(...makeFilterArgs(name))), //filter calls
        call(fetchData, api, debug, { filters, projectId, sortBy }), //Data Call
        call(fetchFiltersCount, api, debug, { filters, projectId, sortBy }), // badges filter count call
      ]);
    } else {
      yield call(fetchData, api, debug, { filters, projectId, sortBy });
    }

    const removedSelected = yield select(getRemovedSelectedOption);
    if (removedSelected) {
      // The last filter change removed a selected option.
      // Need to re-fetch data with the current filters
      const newFilters = yield select(getAllFilters);
      yield put(
        actions.setRemovedSelectedOption({ removedSelectedOption: false }),
      );
      yield call(fetchData, api, debug, {
        filters: newFilters,
        projectId,
        sortBy,
      });
    }
  } catch (e) {
    debug(e);
    showAlert();
  } finally {
    yield put(actions.loading({ loading: false }));
    yield put(
      actions.setRemovedSelectedOption({ removedSelectedOption: false }),
    );
  }
}

function* fetchFilter(api, debug, params) {
  try {
    const projectId = params.projectId;
    const filterName = params.filterName;
    let filters = params.filters.slice();

    yield addDbCodeFilter(filters);

    const filterParams = {
      filters,
      type: filterName,
      page: 0,
      pageSize: filterName === 'invoiceId' ? 2000 : 150,
    };

    const data = yield call(api.searchInvoices.filter, {
      projectId,
      filterParams,
    });
    yield put(actions.storeFilterOptions({ filterName, data }));
  } catch (e) {
    debug(e);
  }
}

function* fetchData(api, debug, params) {
  try {
    const projectId = params.projectId;
    const filters = params.filters.slice();
    const pageSize = yield select(getPageSize);
    const sortBy = params.sortBy;

    yield addDbCodeFilter(filters);

    const dataParams = {
      filters,
      sortBy,
      page: 0,
      pageSize,
    };

    const data = yield call(api.searchInvoices.getData, {
      projectId,
      dataParams,
    });
    const removedSelected = yield select(getRemovedSelectedOption);
    if (!removedSelected) {
      //Only store data if we don't have another data fetch pending because we removed a selected filter option
      yield put(actions.store({ data }));
    }
  } catch (e) {
    debug(e);
    // yield put(showAlert({ message: 'Error updating search data' }));
    yield put(showAlert());
  }
}

function* addDbCodeFilter(filters) {
  const dbCode = yield delayOnValue(getFilterDbCode);

  const dbCodeFilter = {
    field: 'dbCode',
    type: 'key',
    values: [dbCode],
  };

  filters.push(dbCodeFilter);
}

function* fetchFiltersCount(api, debug, params) {
  try {
    const projectId = params.projectId;
    const year = yield delayOnValue(getRouteYear);
    const filterCount = yield call(api.searchInvoices.getFilterBadgesCount, {
      projectId,
      year,
    });
    yield put(actions.fetchFilterBadgesCount({ filterCount }));
  } catch (e) {
    debug(e);
  }
}

export default function* searchInvoices({ api, debug }) {
  yield all([
    takeLatest(`${actions.init}`, init, api, debug),
    takeLatest(`${actions.fetch}`, fetch, api, debug),
  ]);
}
