import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import CallSplit from '@mui/icons-material/CallSplit';

const useStyles = makeStyles(({ palette }) => ({
  SplitButton: {
    height: 20,
    width: 20,
    color: palette.common.black,
  },
  icon: {
    height: 18,
    width: 18,
  },
}));

const SplitButton = props => {
  const classes = useStyles();
  const { api, id, disabled } = props;

  const [tempDisable, setDisabled] = React.useState(false);

  React.useEffect(() => {
    let timeoutVal;

    if (tempDisable) {
      timeoutVal = setTimeout(() => setDisabled(false), 1000);
    }

    return () => clearTimeout(timeoutVal);
  }, [tempDisable]);

  const title = disabled ? '' : 'Split Allowance';

  return (
    <Tooltip title={title} placement="top">
      <span>
        <IconButton
          className={classes.SplitButton}
          disabled={tempDisable || disabled}
          onClick={e => {
            e.stopPropagation();
            setDisabled(true);
            api.splitAllowance({ api, id });
          }}
        >
          <CallSplit className={classes.icon} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

SplitButton.propTypes = {
  api: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default SplitButton;
