import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

import SortArrows from 'components/Shared/SortArrows';

import // DEFAULT_SORT,
// handleMultiSortBy,
// STYLING,
'./searchUtils';
import { handleSortBy } from 'utils/reactTableUtils';

const useStyles = makeStyles(({ palette }) => ({
  root: { height: 32 },
  tableHeaderCell: {
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    position: 'sticky',
    top: 0,
    zIndex: 100,
    backgroundColor: palette.misc.tableHeader,
    textAlign: 'left',
    padding: '0 24px 0 0',
    height: 32,
    fontWeight: '500',
    fontStyle: 'normal',
    '&:first-child': {
      paddingLeft: 23,
    },
  },
  tableHeaderRow: {},
  cell: {
    cursor: 'pointer',
  },

  endColumn: {
    width: '100%',
  },
}));

const noSortColumns = [
  'status',
  'numOfUnapprvdTcs',
  'batchCount',
  'totalTimecards',
  'actions',
];

const HeaderRow = props => {
  const { headerGroups, setSortBy } = props;
  const classes = useStyles();
  return (
    <thead className={classes.root}>
      {headerGroups.map(headerGroup => (
        <tr
          className={classes.tableHeaderRow}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map(column => {
            const isSortable = !noSortColumns.includes(column.id);
            return (
              <th
                {...column.getHeaderProps()}
                onClick={() => {
                  if (isSortable) handleSortBy(column, setSortBy, []);
                }}
                className={clsx(classes.tableHeaderCell, {
                  [classes.endColumn]: column.id === 'comments',
                })}
              >
                {column.render(props => (
                  <span
                    className={clsx({ [classes.cell]: isSortable })}
                    id={column.id}
                  >
                    <span className={classes.title}>{column.Header}</span>
                    {isSortable && <SortArrows column={column} />}
                  </span>
                ))}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

export default HeaderRow;
