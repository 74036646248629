import PropTypes from 'prop-types';
import { profileProps } from './profiles';

//
// constants
//
export const ACTION_CREATE = 'create';
export const ACTION_UPDATE = 'update';
export const ACTION_DELETE = 'delete';
export const ACTION_SUBMIT = 'submit';
export const ACTION_SUBMIT_EMERGENCY = 'submit_emergency';
export const ACTION_APPROVE = 'approve';
export const ACTION_REJECT = 'reject';
export const ACTION_START = 'start';
export const ACTION_CLOSE = 'close';
export const ACTION_OPEN = 'open';
export const ACTION_PRIVILEGE_UPDATE = 'privilege_update';

export const ACTIONS = [
  ACTION_CREATE,
  ACTION_UPDATE,
  ACTION_DELETE,
  ACTION_SUBMIT,
  ACTION_SUBMIT_EMERGENCY,
  ACTION_APPROVE,
  ACTION_REJECT,
  ACTION_START,
  ACTION_CLOSE,
  ACTION_OPEN,
  ACTION_PRIVILEGE_UPDATE,
];

export const ACTION_LABELS = {
  [ACTION_CREATE]: 'Created',
  [ACTION_UPDATE]: 'Updated',
  [ACTION_DELETE]: 'Deleted',
  [ACTION_SUBMIT]: 'Submitted',
  [ACTION_SUBMIT_EMERGENCY]: 'Emergency Submitted',
  [ACTION_APPROVE]: 'Approved',
  [ACTION_REJECT]: 'Rejected',
  [ACTION_START]: 'Started',
  [ACTION_CLOSE]: 'Closed',
  [ACTION_OPEN]: 'Opened',
  [ACTION_PRIVILEGE_UPDATE]: 'Updated Privilege',
};

export const eventProps = PropTypes.shape({
  id: PropTypes.number,
  action: PropTypes.string,
  subjectType: PropTypes.string,
  message: PropTypes.string,
  createdAt: PropTypes.string,
  profile: profileProps,
});

export const eventsProps = PropTypes.arrayOf(eventProps);
