import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

// Utilities
import clsx from 'clsx';

// MuiComponents
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/';

const styles = ({ palette }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  fullScreenRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    flexDirection: 'column',
    background: palette.background.default,
  },
  progress: {
    padding: 15,
    boxSizing: 'content-box',
  },
  message: {
    padding: 15,
  },
});

const Loader = props => {
  const theme = useTheme();

  const { palette } = theme;

  const {
    color = palette.secondary.main,
    variant,
    value,
    thickness,
    message,
    messageProps = {},
    className,
    classes,
    fullScreen = false,
  } = props;

  const circularProgressProps = { variant, value, thickness };
  // Pull the className out to combine with our className
  const { className: messageClassName } = messageProps;
  let updatedMessageProps = { ...messageProps };
  delete updatedMessageProps.className;

  const rootClass = clsx(
    {
      [classes.root]: !fullScreen,
      [classes.fullScreenRoot]: fullScreen,
    },
    className,
  );

  return (
    <div className={rootClass} data-test-id={`Loader-root`}>
      <CircularProgress
        className={classes.progress}
        style={{ color }}
        {...circularProgressProps}
      />
      {message && (
        <Typography
          className={clsx(classes.message, messageClassName)}
          variant={'caption'}
          data-test-id={`Loader-message`}
          {...updatedMessageProps}
        >
          {message}
        </Typography>
      )}
    </div>
  );
};

Loader.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.string,
  thickness: PropTypes.string,
  message: PropTypes.string,
  messageProps: PropTypes.object,
  className: PropTypes.string,
};

export default withStyles(styles)(Loader);
