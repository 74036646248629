import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import { compose } from 'utils/helperFunctions';
import { withStyleSheet } from 'shared/theme';
import _ from 'lodash';
import pluralize from 'pluralize';
//actions
import {
  updateTeamTimecardUser,
  createTeamTimecard,
  fetchGroupRounding,
} from 'actions/timecards';
import { showAlert } from 'actions/alert';

// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
import GroupTimecardComp from 'components/Employees/CrewTimecard/Modal/GroupTimecard';
//selectors
import { getLoading, getTeamTimecardUsers } from 'selectors/timecards';
import { getGroupRounding } from 'selectors/teamTimecard';

const mapStateToProps = (state, { match }) => ({
  weekEndingdate: match.params.weekEndingdate,
  departmentId: match.params.departmentId,
  projectId: match.params.projectId,
  teamTimecardUsers: getTeamTimecardUsers(state),
  loading: getLoading(state),
  groupRounding: getGroupRounding(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onRemoveUser: (key, users) => {
    dispatch(
      updateTeamTimecardUser({
        key,
        users,
      }),
    );
  },
  onSubmit: selectedUsers => {
    const weekEndingdate = ownProps.match.params.weekEndingdate;
    const departmentId = ownProps.match.params.departmentId;
    const projectId = ownProps.match.params.projectId;

    dispatch(
      createTeamTimecard({
        weekEndingdate,
        departmentId,
        projectId,
        selectedUsers,
      }),
    );
  },
  onFetchGroupRounding: dealMemoIds =>
    dispatch(fetchGroupRounding({ dealMemoIds })),
  onShowAlert: params => dispatch(showAlert(params)),
});

const style = ({ palette }) => ({
  title: {
    fontSize: 11,
    fontWeight: 700,
  },
  colorPrimary: {
    color: palette.secondary.light,
  },
});

class GroupTimecard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupTimecardUsers: this.props.teamTimecardUsers,
      rounding: null,
      roundingTimeout: null,
      occupationValue: _.map(
        this.props.teamTimecardUsers,
        (user, index) =>
          user.dealMemos &&
          user.user.id +
            '_' +
            user.dealMemos[0].id +
            '_' +
            user.dealMemos[0].occupationCode.id +
            '_' +
            index,
      ),
    };
  }

  setOccupationValue(value) {
    this.setState({
      occupationValue: value,
    });
  }

  onRemove(key) {
    //update timecard user state
    const tempTimecardUsers = [].concat(this.state.groupTimecardUsers);
    const indexUserToRemove = tempTimecardUsers.findIndex(
      user => user.key === key,
    );
    tempTimecardUsers.splice(indexUserToRemove, 1);
    this.setState({
      groupTimecardUsers: tempTimecardUsers,
    });

    //update selected occupation state
    let tempOccupationValue = [].concat(this.state.occupationValue);
    let newOccupationValue = [];
    const splitUser = _.split(key, '-');
    if (splitUser && splitUser.length >= 0) {
      let indexOccupationToRemove = null;
      _.map(tempOccupationValue, (occupation, index) => {
        const occupationSplit = _.split(occupation, '_');
        if (occupationSplit && occupationSplit.length >= 3) {
          if (occupationSplit[0] === splitUser[0]) {
            indexOccupationToRemove = index;
          } else {
            const newIndex =
              indexOccupationToRemove !== null ? index - 1 : index;
            newOccupationValue.push(
              occupationSplit[0] +
                '_' +
                occupationSplit[1] +
                '_' +
                occupationSplit[2] +
                '_' +
                newIndex,
            );
          }
        }
      });
      this.setState({
        occupationValue: newOccupationValue,
      });
    }
  }

  componentDidMount() {
    const dealMemoIds = [];

    this.props.teamTimecardUsers.forEach(user =>
      user.dealMemos.forEach(dealMemo => dealMemoIds.push(dealMemo.id)),
    );

    this.props.onFetchGroupRounding(dealMemoIds);
    const roundingTimeout = setTimeout(() => {
      if (dealMemoIds.length > 0) {
        console.error('Error loading time rounding information');
        this.props.onShowAlert();
      }
    }, 20000);
    this.setState({ roundingTimeout });
  }

  componentDidUpdate() {
    const groupRounding = this.props.groupRounding || [];

    if (!groupRounding || _.isEmpty(groupRounding)) {
      //rounding not loaded yet
    } else if (this.state.roundingTimeout) {
      clearTimeout(this.state.roundingTimeout);
      this.setState({ roundingTimeout: null });
    }
  }

  renderContent(hasSameRounding) {
    const { teamTimecardUsers, classes, ...others } = this.props;

    //Only show warnings when its a hard false. Will be undefined if the rounding call hasn't come back yet
    hasSameRounding = hasSameRounding === false ? false : true;

    return (
      <GroupTimecardComp
        teamTimecardUsers={this.state.groupTimecardUsers}
        onRemove={this.onRemove.bind(this)}
        onChangeOccupationValue={this.setOccupationValue.bind(this)}
        occupationValue={this.state.occupationValue}
        weekEndingdate={this.state.weekEndingdate}
        hasSameRounding={hasSameRounding}
        {...others}
      />
    );
  }

  checkRounding() {
    const groupRounding = this.props.groupRounding || [];

    const selectedDealMemos = [];
    this.state.occupationValue.forEach(value => {
      const split = value.split('_');
      selectedDealMemos.push(split[1]);
    });

    let isRoundingUndefined;
    const selectedRoundings = selectedDealMemos.map(dealId => {
      const result = groupRounding.find(round => round.id === dealId);

      if (!result) {
        isRoundingUndefined = true;
      }

      return result?.roundTo;
    });

    if (isRoundingUndefined) return undefined;

    let hasSameRounding = selectedRoundings.every(
      (val, i, arr) => val === arr[0],
    );
    return hasSameRounding;
  }

  render() {
    const { classes, onSubmit, setOccupationValue, loading, ...others } =
      this.props;

    const title = (
      <div>
        <Typography variant="h6" color="inherit">
          Create Group Timecards
        </Typography>
        <Typography color="inherit" className={classes.title}>
          {`YOU ARE ABOUT TO CREATE TIMECARDS FOR `}
          <span className={classes.colorPrimary}>
            {pluralize(
              'CREW MEMBER',
              this.state.groupTimecardUsers.length,
              true,
            )}
          </span>
        </Typography>
      </div>
    );

    const hasSameRounding = this.checkRounding();

    return (
      <ConfirmModal
        content={this.renderContent(hasSameRounding)}
        title={title}
        buttonText={`Create ${pluralize(
          'timecard',
          this.state.groupTimecardUsers.length,
        )}`}
        submitDisabled={
          this.state.groupTimecardUsers.length <= 0 ||
          loading ||
          !hasSameRounding
        }
        raisedButton
        hasSubmitAction
        onSubmit={() => onSubmit(this.state.occupationValue)}
        setOccupationValue={setOccupationValue}
        {...others}
      />
    );
  }
}

export default compose(
  withStyleSheet('GroupTimecard', style),
  withModalDialog({ dialog: 'groupTimecard', maxWidth: 'lg' }),
  connect(mapStateToProps, mapDispatchToProps),
)(GroupTimecard);
