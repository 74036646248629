import { produce } from 'immer';
import * as actions from 'actions/invoiceTemplate';

const initialState = {
  invoiceTemplateOption: '',
  loading: false,
  invoiceTypes: [],
  systemLocations: [],
  templates: {
    editing: {},
    list: [],
  },
};

const invoiceTemplate = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.loading}`:
        draft.loading = action.loading;
        break;
      case `${actions.storeTemplates}`:
        draft.templates.list = action.templates;
        break;
      case `${actions.editingTemplate}`:
        draft.templates.editing =
          draft.templates.list.find(
            template => template.id === action.templateId,
          ) || {};
        break;
      case `${actions.storeSystemLocations}`:
        draft.systemLocations = action.systemLocations;
        break;
      case `${actions.storeLocationTypes}`:
        draft.locationTypes = action.locationTypes;
        break;
      case `${actions.storeTemplateOption}`:
        draft.invoiceTemplateOption = action.templateOption;
        break;

      default:
    }
  });
export default invoiceTemplate;
