import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { TextField, IconButton, InputAdornment } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
  },
  searchField: {
    '& > .MuiInputBase-root': {
      borderRadius: '25px',
    },
    '& * > .MuiInputBase-input': {
      padding: 8,
    },
  },
}));

export default function Search(props) {
  const classes = useStyles();
  const { setSearchText, searchText } = props;
  const [localValue, setLocalValue] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setDebounced = React.useCallback(
    _.debounce(setSearchText, 200, { maxWait: 1000 }),
    [setSearchText],
  );

  const handleChange = newVal => {
    setLocalValue(newVal);
    setDebounced(newVal);
  };

  React.useEffect(() => {
    setLocalValue(searchText);
  }, [searchText]);

  return (
    <TextField
      className={clsx('PENDO_digitalEditsSearch', classes.searchField)}
      value={localValue || ''}
      onChange={e => handleChange(e.target.value)}
      variant="outlined"
      placeholder="Search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: localValue ? (
          <InputAdornment position="end">
            <IconButton onClick={() => handleChange('')}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      onKeyDown={e => {
        if (e.key === 'Escape') {
          handleChange('');
        }
      }}
    />
  );
}

Search.propTypes = {
  setSearchText: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
};
