import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import { Box, InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import _ from 'lodash';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { TextField } from 'feature/EmployeeTimecard/Shared/styledComponents';

//redux
import * as actions from 'feature/EmployeeTimecard/actions';
import { showAlert } from 'actions/alert';
import { getProjectDetails } from 'selectors/project';
import {
  getTimecardList,
  getEndsOn,
  getIsTcEditable,
} from 'feature/EmployeeTimecard/selectors';

import { FORM_NAME } from 'feature/EmployeeTimecard/selectors';
import { NEW_TC_ID } from 'feature/EmployeeTimecard/empTimecardUtils';

const useStyles = makeStyles(({ palette }) => ({
  WeekPicker: {
    '& > .MuiDayCalendar-weekContainer': {},
  },

  textFieldInput: {
    width: '130px',
    color: `${palette.text.primary} !important`,
    padding: '8px !important', //match height of buttons
  },
  disabledText: {
    color: `${palette.text.disabled} !important`,
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: `${palette.primary.main} !important`,
  },
  isDuplicateTc: {
    border: `3px solid ${palette.primary.main}`,
    borderRadius: '4px',
  },
}));

const mapState = (state, ownProps) => ({
  project: getProjectDetails(state),
  formValues: getFormValues(FORM_NAME)(state) || {},
  timecards: getTimecardList(state),
  endsOn: getEndsOn(state),
  isTCEditable: getIsTcEditable(state),
});

const mapDispatch = dispatch => ({
  onChangeWeek: newDate => {
    dispatch(actions.changeWeek({ newDate }));
  },
  onShowAlert: (variant, message) => {
    dispatch(showAlert({ variant, message }));
  },
});
const WeekPicker = props => {
  const classes = useStyles();
  const {
    project,
    formValues,
    disabled,
    onChangeWeek,
    timecards,
    onShowAlert,
    endsOn,
    isTCEditable,
  } = props;
  const timecardId = formValues?.id || NEW_TC_ID;

  const [projectStart, setProjectStart] = React.useState(
    moment().subtract(1, 'year'),
  );

  React.useEffect(() => {
    if (!_.isEmpty(project)) {
      const projStart = moment(project.start);
      const yearAgo = moment().subtract(1, 'year');
      if (projStart.isAfter(yearAgo)) {
        setProjectStart(projStart);
      }
    }
  }, [project]);

  const [date, setDate] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [isDuplicateTc, setIsDuplicateTc] = React.useState(false);
  const [displayAlert, setDisplayAlert] = React.useState(false);

  const [displayWeek, setDisplayWeek] = React.useState('');

  React.useEffect(() => {
    if (endsOn) {
      setDate(moment(endsOn));
      setDisplayAlert(false);
      setDisplayWeek(() => {
        const endsOnDate = moment(endsOn);
        const startOfWeek = endsOnDate.clone().startOf('week');
        const endOfWeek = endsOnDate.clone().endOf('week');
        return `${startOfWeek.format('MM/DD')} - ${endOfWeek.format('MM/DD')}`;
      });
    }
  }, [endsOn]);

  React.useEffect(() => {
    const isDuplicate = timecards.some(tc => {
      const tcEndsOn = moment(tc.endsOn);
      return tcEndsOn.isSame(date, 'week');
    });
    if (
      isDuplicate &&
      timecardId === NEW_TC_ID &&
      isTCEditable &&
      !displayAlert
    ) {
      setIsDuplicateTc(isDuplicate);
      setDisplayAlert(true);
      onShowAlert('warning', 'Another timecard exists for this work week');
    } else {
      setIsDuplicateTc(false);
    }
  }, [date, timecards, onShowAlert, timecardId, isTCEditable, displayAlert]);

  const handleChangeWeek = newDate => {
    //set/unset local date to allow user to cancel in warning popup
    setDate(newDate);
    setTimeout(() => {
      setDate(moment(endsOn));
      onChangeWeek(newDate);
    });
  };

  return (
    <Box>
      <InputLabel sx={{ fontSize: '14px', fontWeight: 600 }}>
        Work week*
      </InputLabel>
      <DesktopDatePicker
        className={classes.WeekPicker}
        value={date}
        showDaysOutsideCurrentMonth={true}
        open={open}
        // format=// see `setDisplayWeek` above
        minDate={projectStart}
        maxDate={moment().endOf('week').add(3, 'week')}
        onClose={() => {
          setOpen(false);
        }}
        onChange={handleChangeWeek}
        slotProps={{
          textField: {
            sx: { maxWidth: 0 }, //hide default textfield
            InputProps: { endAdornment: null },
          },
          popper: {
            sx: {
              '.MuiDayCalendar-weekContainer:hover': {
                //hover week
                backgroundColor: 'primary.table',
              },
              '.MuiDayCalendar-weekContainer:has(.Mui-selected)': {
                //selected week
                backgroundColor: 'primary.main',
                borderRadius: '20px',
                '.MuiPickersDay-root': {
                  color: 'primary.contrastText',
                  opacity: 1,
                },
              },
              '.MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
                backgroundColor: 'transparent ',
              },
              '.MuiPickersDay-root': {
                fontWeight: 'bold',
              },
            },
          },
        }}
      />

      <TextField
        value={displayWeek}
        variant="outlined"
        onClick={() => {
          if (!disabled) setOpen(true);
        }}
        className={'PENDO_timecard_week_picker'}
        label={''}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <CalendarTodayIcon
              sx={{
                color: disabled ? 'text.disabled' : 'text.primary',
                height: '20px',
                width: '20px',
              }}
            />
          ),
          classes: {
            input: clsx(classes.textFieldInput, {
              [classes.disabledText]: disabled,
            }),
            notchedOutline: clsx({
              [classes.notchedOutline]: !disabled,
              [classes.isDuplicateTc]: isDuplicateTc,
            }),
          },
        }}
      />
    </Box>
  );
};

WeekPicker.propTypes = {
  project: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChangeWeek: PropTypes.func.isRequired,
  timecards: PropTypes.array.isRequired,
  onShowAlert: PropTypes.func.isRequired,
  endsOn: PropTypes.string.isRequired,
};

export default connect(mapState, mapDispatch)(WeekPicker);
