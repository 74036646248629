import { produce } from 'immer';
import * as actions from 'actions/reports';
import _ from 'lodash';
import moment from 'moment';

const initialState = {
  loading: false,
  rejectedLoading: false,
  scheduledReports: [],
  editingScheduledReport: null,
  userAccessReportDetails: [],
  incorrectEmailProjects: [],
  correctEmailProjects: [],
  adminUsers: [],
  sortedAdminUsers: [],
  selectedUser: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.loading}`:
        draft.loading = action.loading;
        break;

      case `${actions.rejectedLoading}`:
        draft.rejectedLoading = action.rejectedLoading;
        break;

      case `${actions.storeUserAccessReportDetails}`:
        draft.userAccessReportDetails = action.userAccessReportDetails || [];
        break;

      case `${actions.storeClientDetailsById}`:
        draft.clientDetails = action.clientDetails || [];
        break;

      case `${actions.storeScheduledReports}`:
        draft.scheduledReports = action.scheduledReports || [];
        break;

      case `${actions.storeAllCounts}`:
        draft.allClients = action.allClients || [];
        break;

      case `${actions.editingScheduledReport}`:
        const report = draft.scheduledReports.find(
          report => report.id === action.reportId,
        );
        draft.editingScheduledReport = {
          ...action,
          ...(report || []),
        };
        break;

      case `${actions.storeCorrectEmailProjects}`:
        draft.correctEmailProjects = action.projects || [];
        break;

      case `${actions.storeIncorrectEmailProjects}`:
        draft.incorrectEmailProjects = action.projects || [];
        break;

      case `${actions.storeUserAccessClientDetails}`:
        draft.userAccessClientDetails = action.userAccessClientDetails || [];
        break;

      case `${actions.storeAllDBs}`:
        draft.allDBs = action.allDBs || [];
        break;
      case `${actions.storeAdminUsers}`:
        const { adminUsers = [] } = action;
        draft.adminUsers = adminUsers;
        adminUsers.forEach(element => {
          element.fullName = `${element.lastName}, ${element.firstName}`;
        });
        draft.sortedAdminUsers = adminUsers;
        break;
      case `${actions.sortAdminUsers}`:
        //default sortby fullName
        let sortedTcs = _.cloneDeep(state.adminUsers);
        const { order, property } = action;
        if (order === 'asc') {
          if (property === 'date') {
            sortedTcs = sortedTcs.sort((a, b) =>
              moment.utc(a.dateAdded).diff(moment.utc(b.dateAdded)),
            );
          } else {
            sortedTcs = sortedTcs.sort((a, b) =>
              a[property].localeCompare(b[property]),
            );
          }
        } else if (order === 'desc') {
          if (property === 'date') {
            sortedTcs = sortedTcs.sort((a, b) =>
              moment.utc(b.dateAdded).diff(moment.utc(a.dateAdded)),
            );
          } else {
            sortedTcs = sortedTcs.sort((a, b) =>
              b[property].localeCompare(a[property]),
            );
          }
        }
        draft.sortedAdminUsers = sortedTcs;
        break;
      case `${actions.editAdminUser}`:
        draft.selectedUser = action.user;
        break;
      default:
    }
  });
