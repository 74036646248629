import React, { useRef, useState, useCallback, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Field } from 'react-final-form';

//local components
import HoursCell from './HoursCell';
import PercentageCell from './PercentageCell';
import MoneyCell from './MoneyCell';

import { baseCellStyles } from 'feature/DTS/dtsUtils';
import {
  findNextValidInput,
  getItemName,
  editableCountInPage,
} from 'feature/CrewList/CrewListUtils';

import Portal from 'components/Shared/ReactTable/Portal';
import CopyToAll from 'components/Shared/ReactTable/CopyToAll';

const useStyles = makeStyles(({ palette }) => ({
  root: baseCellStyles,
}));

// Default editable cell renderer
const BaseCell = props => {
  const {
    value,
    rowIndex,
    id,
    change,
    batch,
    field,
    editingHotCost,
    name,
    page,
    formValue,
  } = props;

  //variables
  const isMoneyCell =
    id === 'mealPenaltyAmountStudio' || id === 'mealPenaltyAmountDistant';
  const isHoursCell = id === 'payHourStudio' || id === 'payHourDistant';
  const isPercentageCell = id === 'fringePercentage';

  const classes = useStyles();
  //data hooks
  const outerRef = useRef(null);
  const [localVal, setLocalVal] = useState(value);
  const [focus, setFocus] = useState(false);

  const baseOnBlur = () => {
    if (value !== localVal) {
      if (localVal === '') {
        change(`${name}.hotCostSettingReport.${id}`, null);
      } else {
        change(`${name}.hotCostSettingReport.${id}`, +localVal);
      }
    }
    setFocus(false);
  };

  const baseOnChange = val => {
    setLocalVal(val);
  };

  const baseOnFocus = () => {
    setFocus(outerRef.current);
  };

  useEffect(() => {
    if (+localVal !== formValue) {
      setLocalVal(formValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue]);

  const copyToAll = useCallback(
    ({ fieldName, localVal }) => {
      batch(() => {
        for (let i = 0; i < page.length; i++) {
          const pageItem = page[i];
          const pageItemName = getItemName(fieldName, pageItem);
          if (pageItemName) {
            change(pageItemName, +localVal);
          }
        }
      });
    },
    [batch, change, page],
  );

  if (editingHotCost) {
    let Cell;
    if (isHoursCell) {
      Cell = HoursCell;
    }
    if (isMoneyCell) {
      Cell = MoneyCell;
    }
    if (isPercentageCell) {
      Cell = PercentageCell;
    }

    const onKeyPress = e => {
      if (e.nativeEvent.key === 'Enter') {
        e.preventDefault();
        let nextIndex = findNextValidInput(rowIndex, e.shiftKey, page);
        document
          .getElementById(`${nextIndex}.hotCostSettingReport.${id}`)
          .select();
      }
      if (
        (e.metaKey || e.ctrlKey) &&
        e.shiftKey &&
        e.key.toLowerCase() === 'a'
      ) {
        e.preventDefault();
        batch(() => {
          for (let i = 0; i < page.length; i++) {
            const pageItemName = getItemName(id, page[i]);
            if (pageItemName) {
              change(pageItemName, +localVal);
            }
          }
        });
      }
    };

    return (
      <div className={classes.root} ref={outerRef} onKeyDown={onKeyPress}>
        <Field
          id={`${rowIndex}.hotCostSettingReport.${id}`}
          component={Cell}
          label="Full Name"
          name={`${name}.hotCostSettingReport.${id}`}
          value={localVal}
          row={props.row}
          column={props.column}
          // disabled={disabled}
          baseOnFocus={baseOnFocus}
          baseOnChange={baseOnChange}
          baseOnBlur={baseOnBlur}
          localVal={localVal}
          field={field?.hotCostSettingReport?.[id]}
          originalValue={value}
          setLocalVal={setLocalVal}
          // error={error}
        />
        {focus && (
          <Portal targetDom={focus} variant="crewList">
            <CopyToAll
              variant="crewList"
              rowCount={editableCountInPage(page)}
              copyToAll={() =>
                copyToAll({
                  fieldName: id,
                  localVal,
                })
              }
            />
          </Portal>
        )}
      </div>
    );
  } else {
    if (value === undefined) return null;
    let printValue;
    if (isHoursCell) {
      printValue = value;
    }
    if (isMoneyCell) {
      printValue = `$`;
      const numberString = String(value);
      if (value % 1 === 0 || numberString.split('.')[1].length === 1) {
        printValue += parseFloat(value).toFixed(2);
      } else {
        printValue += value;
      }
    }
    if (isPercentageCell) {
      printValue = value;
      printValue += '%';
    }
    return printValue;
  }
};

BaseCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowIndex: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  batch: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  editingHotCost: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  page: PropTypes.array.isRequired,
  pageIndex: PropTypes.number.isRequired,
};

export default compose(connect(null, null))(BaseCell);
