import React from 'react';
import InputBase from '@mui/material/InputBase';
import { alpha } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import SearchIcon from '@mui/icons-material/Search';

const style = theme => ({
  root: {
    width: '100%',
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  disabledSearch: {
    color: theme.palette.gray['+7'],
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    height: 'inherit',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
      '&:focus': {
        marginBottom: 0,
      },
    },
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    border: 1,
    [theme.breakpoints.up('sm')]: {
      width: 'inherit',
      '&:focus': {
        marginBottom: 0,
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    searchIcon: {
      width: theme.spacing(5),
      color: 'rgb(176, 176, 176)',
    },
    inputInput: {
      paddingLeft: theme.spacing(6),
    },
  },
});

// disable chrome autocomplete for search
/**
 * This is the code I tested on some online CC forms and out
 * of everything this is what worked.  Chrome has been
 * removing the ability to programmatically prevent autocomplete
 * with every update.
 */
const inputProps = {
  type: 'search',
  autoComplete: 'off',
};

const SearchBar = ({
  classes,
  onFilter,
  onFieldFocus,
  onFieldBlur,
  placeholder,
  autoFocus,
  useAsyncDebounce,
  globalFilter,
  setGlobalFilter,
  editingHotCost,
  gotoPage,
  pageIndex,
}) => {
  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value?.trimEnd() || undefined);
    if (pageIndex !== 0) {
      gotoPage(0);
    }
  }, 200);

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon className={editingHotCost ? classes.disabledSearch : ''} />
      </div>
      <InputBase
        placeholder={
          placeholder ||
          'Search Users by Name, Department, Role, Email, Occupation or Loan out'
        }
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={inputProps}
        fullWidth
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        onFocus={onFieldFocus}
        onBlur={onFieldBlur}
        autoFocus={autoFocus}
        value={value || ''}
        disabled={editingHotCost}
      />
    </div>
  );
};
export default withStyles(style)(SearchBar);
