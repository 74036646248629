const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1145,
  xl: 1920,
};

const breakpoints = { values: breakpointValues };

export default breakpoints;
