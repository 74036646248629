import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box, Tooltip } from '@mui/material';
import * as sel from '../../selectors';

import DayActions from './DayActions';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const mapState = (state, ownProps) => ({
  hours: sel.getDayHours(state),
  activeDeal: sel.getActiveDealMemo(state),
});

const mapDispatch = dispatch => ({});

const DisplayCell = props => {
  const {
    column: { type, accessor },
    input: { value },
    index,
    hours,
    activeDeal,
  } = props;

  let displayValue = null;

  if (type === 'special') {
    if (accessor === 'date') {
      const isDateBefore = moment(value).isBefore(activeDeal.start);
      const isDateAfter = moment(value).isAfter(activeDeal.end);
      let tooltipText = '';
      if (isDateBefore) {
        tooltipText = activeDeal.start
          ? `Deal Memo’s start date is ${moment(activeDeal.start).format(
              'MMM Do',
            )}`
          : 'No valid deals exist for the selected work week';
      } else if (isDateAfter) {
        tooltipText = activeDeal.end
          ? `Deal Memo’s end date is ${moment(activeDeal.end).format('MMM Do')}`
          : 'No valid deals exist for the selected work week';
      }
      const date = moment(value);
      const dow = date.format('ddd');
      const monthDate = date.format('MMM DD');
      displayValue = (
        <Box
          sx={{
            fontSize: '14px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            <Box sx={{ fontWeight: 'bold' }}>{dow}</Box>
            <Box sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
              {monthDate}
            </Box>
          </Box>
          <Box>
            {tooltipText && (
              <Tooltip title={tooltipText} arrow placement="top">
                <InfoOutlinedIcon
                  sx={{ color: 'gray.primary', fontSize: '20px' }}
                />
              </Tooltip>
            )}
          </Box>
        </Box>
      );
    } else if (accessor === 'workedHours') {
      let hoursVal = hours[index];
      // display upto 2 decimal places if hoursVal has any decimal content, otherwise just show the integer value
      // hoursVal = hoursVal % 1 === 0 ? hoursVal : hoursVal.toFixed(2);

      displayValue = (
        <Box
          id={`day[${index}].${accessor}`}
          sx={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}
        >
          {hoursVal}
        </Box>
      );
    } else if (accessor === 'actions') {
      displayValue = <DayActions index={index} />;
    }
  } else if (typeof value === 'object') {
    displayValue = value.name;
  } else {
    displayValue = value;
  }

  return displayValue;
};

DisplayCell.propTypes = {
  column: PropTypes.object,
  input: PropTypes.object,
  index: PropTypes.number,
  hours: PropTypes.array,
  onClearDay: PropTypes.func,
  activeDeal: PropTypes.object,
};

export default compose(connect(mapState, mapDispatch))(DisplayCell);
