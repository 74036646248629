import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import UndoIcon from '@mui/icons-material/Undo';
import EditIcon from '@mui/icons-material/Edit';
import DrawSharpIcon from '@mui/icons-material/DrawSharp';
import UnarchiveSharpIcon from '@mui/icons-material/UnarchiveSharp';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { DATE_FORMAT_HOUR_MIN } from 'components/Shared/constants';
import {
  formatDateTimezone,
  getAbbreviationFromBackendTimezoneName,
} from 'utils/formatDate';

const useStyles = makeStyles(theme => ({
  historyItem: {
    padding: '15px 0px',
    borderBottom: `solid 1px ${theme.palette.gray['+7']}`,
    display: 'flex',
    alignItems: 'start',
    margin: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      margin: '10px 30px',
    },
  },
  fullName: {
    color: theme.palette.text.disabled,
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  timeZone: {
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px',
      fontSize: '15px',
    },
  },
  icon: {
    padding: '10px',
    borderRadius: '10px',
    marginRight: '10px',
    marginTop: '6px',
  },
}));
const HistoryCard = props => {
  const { history, projectSettings } = props;
  const { id, label, createdAt, actor } = history;
  const { timeZone } = projectSettings;
  const classes = useStyles();

  let icon = null,
    backgroundColor = null;

  switch (label) {
    case 'Signed':
      icon = <DrawSharpIcon color="primary" />;
      backgroundColor = 'primary.table';
      break;
    case 'Submitted For Approval':
      icon = <UnarchiveSharpIcon color="warning" />;
      backgroundColor = 'button.yellow.background';
      break;
    case 'Approved':
      icon = <AssignmentTurnedInIcon color="primary" />;
      backgroundColor = 'primary.table';
      break;
    case 'Rejected':
      icon = <DisabledByDefaultIcon color="error" />;
      backgroundColor = 'button.lightRed.background';
      break;
    case 'Corrections Made':
      icon = <EditIcon color="error" />;
      backgroundColor = 'button.lightRed.background';
      break;
    case 'Resubmitted to Employee':
      icon = <UndoIcon color="error" />;
      backgroundColor = 'button.lightRed.background';
      break;
    case 'Recalled':
      icon = <CheckCircleIcon color="error" />;
      backgroundColor = 'button.lightRed.background';
      break;
    default:
      break;
  }

  return (
    <Grid item sm={1} md={3} key={id}>
      <Box className={classes.historyItem}>
        <Box sx={{ backgroundColor: backgroundColor }} className={classes.icon}>
          {icon}
        </Box>
        <Box sx={{ lineHeight: '2' }}>
          <Box sx={{ color: 'text.primary', fontWeight: 'bold' }}>{label}</Box>
          <Box className={classes.fullName}>{actor?.fullName || null}</Box>
          <Box className={classes.timeZone}>
            {formatDateTimezone(createdAt, DATE_FORMAT_HOUR_MIN, timeZone)}
            <span>{` (${getAbbreviationFromBackendTimezoneName(
              timeZone,
            )})`}</span>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

HistoryCard.propTypes = {
  history: PropTypes.object.isRequired,
  projectSettings: PropTypes.object.isRequired,
};

export default HistoryCard;
