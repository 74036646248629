import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyleSheet } from 'shared/theme';
import { Paper, Chip, Typography } from '@mui/material';
import { FaPlusCircle as PlusIcon } from 'react-icons/fa';
import { ROLES } from 'components/props/profiles';

const style = ({ palette, breakpoints }) => ({
  flowRow: {
    padding: 12,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  anchor: {
    cursor: 'pointer',
  },
  chips: {
    marginLeft: 5,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  flowColumn: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 2,
    marginLeft: 10,
    whiteSpace: 'nowrap',
    '&:first-child': {
      flexBasis: 0,
      paddingLeft: 25,
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 8,
        border: `solid 2px ${palette.gray['+7']}`,
        borderRadius: '50%',
        marginRight: 17,
        width: 8,
        height: 8,
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        width: 2,
        top: 19,
        left: 5,
        bottom: -32,
        zIndex: 1,
        backgroundColor: palette.gray['+7'],
      },
    },
  },
  flowRowLast: {
    '&:first-child': {
      '&::after': {
        display: 'none',
      },
    },
  },
  labelText: {
    verticalAlign: 'text-bottom',
  },
  root: {},
  lastChild: {
    '&::after': {
      display: 'none',
    },
  },
  icon: {
    paddingRight: 15,
    paddingLeft: 10,
    width: 24,
    height: 24,
  },
  colorIcon: {
    paddingRight: 15,
    paddingLeft: 10,
    width: 24,
    height: 24,
    color: palette.primary.main,
  },
  chip: {
    marginLeft: 5,
    height: 24,
    color: palette.secondary.contrastText,
    backgroundColor: palette.secondary.light,
    // '& > svg': {  // This was for the X to delete, but its been removed.
    //   height: 20, // Leaving this here in case we want to add it back in
    //   margin: {
    //     top: 1,
    //     right: 2,
    //     bottom: 1,
    //     left: -4,
    //     // [1, 2, 1, -4]
    //   },
    // },
  },
  chipEdit: {
    fontWeight: 500,
  },
  [breakpoints.down('md')]: {
    flowColumn: { alignItems: 'flex-start' },
    chips: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
    chip: { marginBottom: 5 },
  },
});

class ApprovalStep extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    icon: PropTypes.func,
    role: PropTypes.oneOf(ROLES),
    label: PropTypes.string.isRequired,
    users: PropTypes.array,
    lastChild: PropTypes.bool,
    onRemoveUser: PropTypes.func,
    allowEdit: PropTypes.bool,
    onEditStep: PropTypes.func,
  };

  static defaultProps = {
    allowEdit: false,
    icon: PlusIcon,
    lastChild: false,
    onEditStep: undefined,
    onRemoveUser: undefined,
    role: undefined,
    users: [],
  };

  renderUsers() {
    const { classes, onEditStep, users } = this.props;

    if (!users) return null;

    let userArray = null;

    userArray = users.map(user => (
      <Chip className={classes.chip} key={user.id} label={user.fullName} />
    ));

    if (users.length > 0) {
      userArray.push(
        <Chip
          className={clsx(classes.chip, classes.chipEdit)}
          key="edit"
          label="Edit"
          onClick={event => onEditStep(event)}
        />,
      );
    }

    return <div className={classes.chips}>{userArray}</div>;
  }

  render() {
    const {
      classes,
      icon: Icon,
      label,
      lastChild,
      allowEdit,
      onEditStep,
      iconColor,
    } = this.props;
    const columnClasses = clsx(classes.flowColumn, {
      [classes.flowRowLast]: lastChild,
      [classes.anchor]: allowEdit,
    });
    let colorIconChange = iconColor ? classes.colorIcon : classes.icon;

    return (
      <Paper elevation={0} className={classes.flowRow}>
        <div className={columnClasses} onClick={allowEdit ? onEditStep : null}>
          {Icon && <Icon className={colorIconChange} />}
          <Typography className={classes.labelText}>{label}</Typography>
          {this.renderUsers()}
        </div>
      </Paper>
    );
  }
}

export default withStyleSheet('ApprovalStep', style)(ApprovalStep);
