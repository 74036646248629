import React from 'react';
import _ from 'lodash';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import WarningIcon from '@mui/icons-material/Warning';
import ProcessedIcon from '@mui/icons-material/CheckCircle';
import { PA_STATUS_TO_LABEL_MAP } from 'components/Shared/constants';
import { getApproverList } from '../ApproverList';
import {
  EmergencyIcon,
  ForceResubmitIcon,
} from 'containers/Employees/Reviews/Shared/timecardUtils';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
  },
  statusIncompleteColor: {
    color: palette.warning.main,
  },
  statusProcessedColor: {
    color: palette.success.main,
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.action.hover,
    },
  },
}));

export default function TimecardSection({
  timecards,
  reviewType,
  onNavToWTC,
  batch,
}) {
  const classes = useStyles();

  function renderStatus(status) {
    const label = PA_STATUS_TO_LABEL_MAP[status];
    let statusDisplay = null;
    switch (label) {
      case 'Incomplete':
        statusDisplay = (
          <div className={clsx(classes.statusIncompleteColor, classes.root)}>
            <WarningIcon /> {label}
          </div>
        );
        break;
      case 'Processed':
        statusDisplay = (
          <div className={clsx(classes.statusProcessedColor, classes.root)}>
            <ProcessedIcon /> {label}
          </div>
        );
        break;
      case 'Paid':
        statusDisplay = (
          <div className={clsx(classes.statusProcessedColor, classes.root)}>
            <ProcessedIcon /> {label}
          </div>
        );
        break;
      default:
        statusDisplay = <div>{label}</div>;
    }
    return statusDisplay;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={'3%'}></TableCell>
          <TableCell width={'10%'}>Crew Member</TableCell>
          <TableCell width={'20%'}>Status</TableCell>
          <TableCell width={'70%'}>Current Approvers</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {_.chain(timecards)
          .sortBy(['userLastName', 'userFirstName'])
          .map(timecard => (
            <TableRow
              key={timecard.id}
              id={timecard.id}
              className={classes.row}
              onClick={() => {
                const batchWorksightId =
                  batch && batch.details && batch.details.worksightId;
                const batchHtgId =
                  batch && batch.details && batch.details.htgId;
                onNavToWTC(timecard, batchWorksightId, batchHtgId);
              }}
            >
              <TableCell>
                {timecard.emergencyType ? (
                  <EmergencyIcon />
                ) : timecard.workflowAction === 'resubmitToEmployee' ? (
                  <ForceResubmitIcon />
                ) : (
                  ''
                )}
              </TableCell>
              <TableCell>
                {_.get(timecard, 'timecard.user.fullName', '--')}
              </TableCell>
              <TableCell>{renderStatus(timecard.status)}</TableCell>
              <TableCell>
                {getApproverList(timecard.currentApprovers)}
              </TableCell>
            </TableRow>
          ))
          .value()}
      </TableBody>
    </Table>
  );
}
