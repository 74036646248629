import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';

import {
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TableCell,
  TableRow,
  CircularProgress,
  Tooltip,
  Button,
  Grid,
  Box,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import ExpandMore from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { FaDownload as DownloadIcon } from 'react-icons/fa';

import { show as showModal } from 'actions/modalDialog';
import { ErrorMsg } from 'components/Shared/Text';

import TimecardSection from './TimecardSection';
import { getProjectDetails } from 'selectors/project';
import { getDownloadingByBatchId } from 'selectors/reviews';

const style = ({ palette }) => ({
  root: {
    width: '100%',
    padding: 0,
  },
  titleColumn: {
    fontWeight: 700,
    flexBasis: '42%',
    color: palette.getDefaultColor,
    verticalAlign: 'middle',
    paddingTop: 10,
    paddingBottom: 10,
    padding: 0,
    display: 'flex',
  },
  countColumn: {
    fontSize: 16,
    fontWeight: 'regular',
    flexBasis: '5%',
    color: palette.gray.primary,
    textAlign: 'center',
    verticalAlign: 'middle',
    paddingTop: 10,
    paddingBottom: 10,
    padding: 0,
    '& > *': {
      backgroundColor: `${palette.primary.main}10`,
      padding: '7px 11px',
    },
  },
  hasReadyTimecards: {
    '& > *': {
      backgroundColor: palette.primary['+7'],
      borderRadius: 5,
    },
  },
  actionColumn: {
    verticalAlign: 'middle',
    display: 'flex',
    flexBasis: '52%',
    justifyContent: 'flex-end',
  },
  expansionContent: {
    boxShadow: `4px 0 0 ${palette.primary.main} inset`,
    backgroundColor: palette.background.paper,
  },

  downloadIcon: {
    marginRight: 5,
  },
  progress: {
    marginLeft: 8,
    color: `${palette.primary.main}`,
  },
  downloadProgress: {
    padding: `6px 54px`,
  },
  lockIcon: {
    color: `${palette.primary.main}`,
    marginBottom: '-4px',
  },
  unlockIcon: {
    color: palette.action.disabled,
    marginBottom: '-4px',
  },
  hardLockIcon: {
    color: palette.action.disabled,
    marginBottom: '-4px',
  },
  batchStatusIcon: {
    width: 16,
    height: 21,
    marginLeft: 10,
  },
});

const mapStateToProps = (state, ownProps) => ({
  downloading: getDownloadingByBatchId(state, ownProps?.batch?.details?.id),
  initialValues: getProjectDetails(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onMoveTimecard: batch => {
    const sourceBatch = { ...batch, htgBatchNumber: batch.htgId };
    dispatch(
      showModal({
        dialog: 'moveTimecardsToBatch',
        modalParams: { sourceBatch },
      }),
    );
  },
});

function BatchRow({
  batch = {},
  projectId,
  downloading,
  reviewType,
  onApproveBatch, //used to access iFrame HTG if needed.
  onWTCApproveBatch,
  onBulkEditBatch,
  onDownloadTimecardsReport,
  onMoveTimecard,
  onNavToWTC,
  onFetchBatchTimecards,
  onSetBatchLockStatus,
  timecardLoadingByBatch,
  classes,
  initialValues,
}) {
  const batchDetails = batch.details;
  const stats = batchDetails.stats;
  const [expanded, setExpanded] = React.useState(false);

  const sourceTCs = useMemo(
    () => batch.timecards || batch.approvalFlows || [],
    [batch],
  );

  const onDownloadBatch = React.useCallback(
    e => {
      e.stopPropagation();

      const tcEntryHeaderIds = sourceTCs.map(tc => tc.entryHeaderId);
      onDownloadTimecardsReport({
        timecards: tcEntryHeaderIds,
        batchId: batchDetails.id,
        htgId: batchDetails.htgId,
        fetchBatch: !expanded && tcEntryHeaderIds.length === 0,
      });
    },
    [sourceTCs, onDownloadTimecardsReport, batchDetails, expanded],
  );

  function wtcApproveBatch(projectId, worksightId, reviewBatchId, htgId) {
    onWTCApproveBatch(projectId, worksightId, reviewBatchId, htgId);
  }
  const [batchStatus, setBatchStatus] = React.useState(batchDetails.lockStatus);

  useEffect(() => {
    setBatchStatus(batchDetails.lockStatus);
  }, [batchDetails.lockStatus]);

  const lockedTooltipCopy =
    'If new timecards are created, they will be added to a different batch. You can move timecards in and out freely, until the batch has been fully submitted to C&C.';
  const unlockedTooltipCopy =
    'If new timecards are created for these departments, they will be automatically added to this batch.';
  const hardLockedTooltipCopy =
    'This batch is locked because all timecards have been submitted to C&C, or because a payroll coordinator has begun work on the invoice. Only a payroll coordinator can move timecards into or out of this batch.';
  let batchStatusIcon;
  if (batchStatus === 'unlock') {
    batchStatusIcon = (
      <Tooltip title={unlockedTooltipCopy} placement="right">
        <div>
          <LockOpenIcon className={classes.unlockIcon} />
        </div>
      </Tooltip>
    );
  } else if (batchStatus === 'softlock') {
    batchStatusIcon = (
      <Tooltip
        title={lockedTooltipCopy}
        className={classes.lockIcon}
        placement="right"
      >
        <div>
          <LockIcon />
        </div>
      </Tooltip>
    );
  } else if (batchStatus === 'hardlock') {
    batchStatusIcon = (
      <Tooltip
        title={hardLockedTooltipCopy}
        className={classes.hardLockIcon}
        placement="right"
      >
        <div>
          <LockIcon />
        </div>
      </Tooltip>
    );
  }
  function setBatchLockStatus(hoursBatchId, reviewBatchLockStatus) {
    if (reviewBatchLockStatus === 'unlock') {
      setBatchStatus('softlock');
      onSetBatchLockStatus(hoursBatchId, 'softlock');
    } else if (reviewBatchLockStatus === 'softlock') {
      setBatchStatus('unlock');
      onSetBatchLockStatus(hoursBatchId, 'unlock');
    }

    if (reviewBatchLockStatus === 'hardlock') return;
  }

  const areTimecardsLoading =
    timecardLoadingByBatch && timecardLoadingByBatch[batchDetails.id];

  const batchError =
    timecardLoadingByBatch && timecardLoadingByBatch[batchDetails.id] === null;

  function handleExpand() {
    if (!expanded) {
      onFetchBatchTimecards(batch?.details?.id);
    }
    setExpanded(!expanded);
  }

  const showReadyForReview = stats.readyForReview > 0;

  //Download timecard button tooptip title
  const items = [
    { left: 'Timecards:', right: batchDetails?.totalTimecards },
    {
      left: 'Download: ',
      right: `${batchDetails?.stats?.total} of ${batchDetails?.stats?.total}`,
    },
  ];
  if (batchDetails?.stats?.total !== batchDetails?.totalTimecards) {
    items.splice(1, 0, {
      left: 'My workflow:',
      right: `${batchDetails?.stats?.total} of ${batchDetails?.totalTimecards} `,
    });
  }
  const downloadBtnTooltipTitle = (
    <div>
      {items.map(item => (
        <Grid
          key={item.left}
          container
          spacing={1}
          style={{ whiteSpace: 'nowrap' }}
        >
          <Grid item xs={5}>
            {item.left}
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={5}>
            {item.right}
          </Grid>
        </Grid>
      ))}
    </div>
  );
  const batchTooltipTitle = batchDetails?.departmentName
    ? `Timecards from the department “${batchDetails?.departmentName}” will be added to this batch.`
    : '';
  return (
    <TableRow className={classes.root}>
      <TableCell colSpan={3}>
        <Accordion expanded={expanded}>
          <AccordionSummary expandIcon={<ExpandMore />} onClick={handleExpand}>
            <div className={classes.titleColumn}>
              <Tooltip arrow placement="top-end" title={batchTooltipTitle}>
                <span>{batchDetails.htgId + ' - ' + batchDetails.name}</span>
              </Tooltip>
              <span
                onClick={e => {
                  e.stopPropagation();
                  setBatchLockStatus(batchDetails.id, batchStatus);
                }}
                className={classes.batchStatusIcon}
              >
                {batchStatusIcon}
              </span>
              {areTimecardsLoading && (
                <CircularProgress
                  className={classes.progress}
                  color="primary"
                  size={14}
                  thickness={6}
                />
              )}
              {batchError && (
                <ErrorMsg display={'inline'}>
                  Loading error. Please contact support.
                </ErrorMsg>
              )}
            </div>
            <div
              className={clsx(classes.countColumn, {
                [classes.hasReadyTimecards]: showReadyForReview,
              })}
            >
              <span>{batch.timecards?.length || batchDetails.stats.total}</span>
            </div>
            <div className={classes.actionColumn}>
              {reviewType === 'complete' && (
                <React.Fragment>
                  {batchDetails.eSigned && (
                    <Button
                      variant="contained"
                      onClick={onDownloadBatch}
                      disabled={downloading}
                      key={'verifiedBatch'}
                    >
                      {downloading ? (
                        <CircularProgress
                          className={classes.downloadProgress}
                          color="primary"
                          size={14}
                          thickness={6}
                        />
                      ) : (
                        <>
                          <DownloadIcon className={classes.downloadIcon} />
                          Verified Batch
                        </>
                      )}
                    </Button>
                  )}
                  {!batchDetails.eSigned && (
                    <Tooltip
                      arrow
                      placement="top"
                      title={downloadBtnTooltipTitle}
                    >
                      <Box display="inherit">
                        <Button
                          variant="contained"
                          onClick={onDownloadBatch}
                          disabled={downloading}
                          key={'downloadBatch'}
                        >
                          {downloading ? (
                            <CircularProgress
                              className={classes.downloadProgress}
                              color="primary"
                              size={14}
                              thickness={6}
                            />
                          ) : (
                            <>
                              <DownloadIcon className={classes.downloadIcon} />
                              Download Timecards
                            </>
                          )}
                        </Button>
                      </Box>
                    </Tooltip>
                  )}
                </React.Fragment>
              )}

              <Button
                variant="outlined"
                onClick={e => {
                  e.stopPropagation();
                  onMoveTimecard(batch.details);
                }}
                key={'moveBatch'}
                disabled={areTimecardsLoading || batchStatus === 'hardlock'}
              >
                Move Timecards
              </Button>

              {reviewType === 'open' && (
                <Button
                  variant="outlined"
                  onClick={e => {
                    e.stopPropagation();
                    onBulkEditBatch(
                      projectId,
                      batchDetails.worksightId,
                      batchDetails.id,
                      batchDetails.htgId,
                      batchDetails.endsOn,
                    );
                  }}
                  key={'bulk-edit'}
                  // disabled={true}
                >
                  Batch bulk edit
                </Button>
              )}

              <Button
                variant="contained"
                onClick={e => {
                  e.stopPropagation();
                  wtcApproveBatch(
                    projectId,
                    batchDetails.worksightId,
                    batchDetails.id,
                    batchDetails.htgId,
                  );
                }}
                key={'WTCapprove'}
              >
                View all
              </Button>
            </div>
          </AccordionSummary>
          <Divider />
          <AccordionDetails className={classes.expansionContent}>
            <TimecardSection
              timecards={batch.timecards}
              batch={batch}
              reviewType={reviewType}
              onNavToWTC={onNavToWTC}
            />
          </AccordionDetails>
        </Accordion>
      </TableCell>
    </TableRow>
  );
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(style),
)(BatchRow);
