import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import MovieCreation from '@mui/icons-material/TheatersRounded'; //MovieCreationOutlined
import PeopleIcon from '@mui/icons-material/People';
import Extenstion from '@mui/icons-material/ExtensionSharp';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { NavBar } from 'components/Shared/Nav';

import NavItem from '../Shared/NavItem';

const style = () => ({
  root: {
    width: '100%',
    height: 64,
    lineHeight: '62px',
  },
  iconAlign: {
    // transform: 'rotate(90deg)',
    marginBottom: 2,
    verticalAlign: 'middle',
  },
});

class HeaderNav extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    isAdminUser: PropTypes.bool,
  };

  static defaultProps = {
    isAdminUser: false,
  };

  render() {
    const { classes, isAdminUser, isSuperAdminUser, isAccessAdmin } =
      this.props;
    const tabItems = [];

    if (isAdminUser) {
      tabItems.push(
        <NavItem
          key="/admin/projects"
          linkTo="/admin/projects"
          secondaryLinks={['/projects']}
          navIcon={<MovieCreation className={classes.iconAlign} />}
          navLabel="Projects"
        />,
        <NavItem
          key="/admin/users-all-projects"
          linkTo="/admin/users-all-projects"
          navIcon={<PeopleIcon className={classes.iconAlign} />}
          navLabel="Users"
        />,
      );
    } else {
      if (!isAccessAdmin && !isSuperAdminUser) {
        tabItems.push(
          <NavItem
            key="/projects"
            linkTo="/projects"
            active
            navIcon={<MovieCreation className={classes.iconAlign} />}
            navLabel="Projects"
          />,
        );
      }
    }
    if (isSuperAdminUser) {
      tabItems.push(
        <NavItem
          key="/admin/feature-flags"
          linkTo="/admin/feature-flags"
          navIcon={<Extenstion className={classes.iconAlign} />}
          navLabel="Feature flags"
        />,
        <NavItem
          key="/admin/superadmin"
          linkTo="/admin/superadmin"
          navIcon={<ArrowDownwardIcon className={classes.iconAlign} />}
          navLabel="Super admin"
        />,
      );
    }
    if (isAccessAdmin) {
      tabItems.push(
        <NavItem
          key="/admin/accessadmin"
          linkTo="/admin/accessadmin"
          navIcon={<ArrowDownwardIcon className={classes.iconAlign} />}
          navLabel="Access Admin"
        />,
      );
    }

    return (
      <div className={classes.root}>
        <NavBar>{tabItems}</NavBar>
      </div>
    );
  }
}

export default withStyleSheet('NavHeader', style)(HeaderNav);
