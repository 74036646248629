import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'utils/helperFunctions';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
//actions
import { getUserClickedSave } from 'selectors/bulkEdit';
const style = theme => {
  return {
    root: {},
  };
};

const mapStateToProps = state => ({
  userClickedSave: getUserClickedSave(state),
});

const mapDispatchToProps = dispatch => ({});

class ClearConfirmModal extends Component {
  componentDidMount() {}

  render() {
    const { clearChanges, userClickedSave, ...others } = this.props;

    return (
      <ConfirmModal
        content={
          'This will reset all unapplied changes to their previous values.'
        }
        title={'Clear unapplied changes'}
        buttonText="Clear values"
        raisedButton
        hasSubmitAction
        onSubmit={() => clearChanges(userClickedSave)}
        {...others}
      />
    );
  }
}

export default compose(
  withStyles(style),
  withModalDialog({ dialog: 'ConfirmClear', maxWidth: 'sm' }),
  connect(mapStateToProps, mapDispatchToProps),
)(ClearConfirmModal);
