const URL_FLAGS = '/feature-flags';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async list() {
    const rsp = await clientV2.get(URL_FLAGS);
    return rsp.data;
  },

  async update({ flags }) {
    const rsp = await clientV2.put(URL_FLAGS, { flags });
    return rsp.data;
  },
});
