import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Tooltip } from '@mui/material';

//components
import Filters from './Filters';

//Icons
import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';
import FilterListIcon from '@mui/icons-material/FilterList';

const useStyles = makeStyles(({ palette }) => {
  return {
    root: {
      height: '100%',
      borderRight: `solid 1px ${palette.gray['+8']}`,
      // marginLeft: -18,
    },
    content: {
      transition: 'width 0.3s;',
    },
    sidebarClosed: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 50,
      textAlign: 'center',
      alignItems: 'center',
      backgroundColor: palette.primary.background,
      height: '100%',
      cursor: 'pointer',
    },
    expandedWidth: { width: 350 },
    hiddenWidth: {
      width: 75,
    },
    iconCircle: {
      display: 'flex',
      // width: '32px',
      height: '45px',
      backgroundColor: palette.background.paper,
      // borderRadius: ' 50%',
      borderBottom: `solid 1px ${palette.gray['+8']}`,
      cursor: 'pointer',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingRight: 15,
      // top: '40%',
    },
    iconCircleExpanded: {
      //   position: 'absolute',
    },
    iconCircleCollapsed: {
      justifyContent: 'center',
      // left: '60%',
      paddingRight: 0,
    },
    iconRotated: {
      transform: 'rotate(180deg)',
    },
    sidebarBackGround: {
      height: '76vh',
    },
  };
});

const SearchSidebar = props => {
  const { onClearFilters, showFilters, setShowFilters } = props;
  const classes = useStyles();

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <div className={clsx(classes.root)}>
      <div
        className={clsx(classes.iconCircle, {
          [classes.iconCircleExpanded]: showFilters,
          [classes.iconCircleCollapsed]: !showFilters,
        })}
        onClick={toggleFilters}
      >
        {showFilters ? (
          <Tooltip title="Hide Filters">
            <KeyboardDoubleArrowLeft color="primary" />
          </Tooltip>
        ) : (
          <Tooltip title="Show Filters">
            <FilterListIcon color="primary" />
          </Tooltip>
        )}
      </div>
      <section
        className={clsx(classes.content, {
          [classes.expandedWidth]: showFilters,
          [classes.hiddenWidth]: !showFilters,
          [classes.sidebarBackGround]: !showFilters,
        })}
      >
        {showFilters ? (
          <Filters onClearFilters={onClearFilters} />
        ) : (
          <div className={classes.sidebarClosed} onClick={toggleFilters}></div>
        )}
      </section>
    </div>
  );
};

export default SearchSidebar;
