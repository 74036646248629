import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import makeStyles from '@mui/styles/makeStyles';

import ExistingComment from './ExistingComment';

const useStyles = makeStyles(({ palette }) => ({
  ExistingComments: {
    flexGrow: 0,
  },
}));

const ExistingComments = props => {
  const classes = useStyles();
  const { comments, defaultExpanded = false } = props;

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      className={classes.ExistingComments}
      disabled={comments.length === 0}
    >
      <AccordionSummary
        variant={'iconOnFarRight'}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>Comment History ({comments.length})</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Box>
          {comments.map(comment => (
            <ExistingComment key={comment.commentId} comment={comment} />
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

ExistingComments.propTypes = {
  comments: PropTypes.array.isRequired,
  defaultExpanded: PropTypes.bool,
};

export default ExistingComments;
