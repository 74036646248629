import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from 'decorators/withRouter';
import { withStyleSheet } from 'shared/theme';
//actions
import { fetchTimecardCount } from 'actions/reviews';
// components
import { userInfoProps } from 'components/props/profiles';
import { REVIEW_TABS_BY_ROLE } from 'components/Shared/constants';
import ReviewsHeaderTab from 'components/Shared/Reviews/ReviewsHeaderTab';
//selectors
import { currentUser } from 'selectors/session';
import { reviews, getCountLoading } from 'selectors/reviews';

const style = theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    border: 'none',
    '& > *': {
      width: '100%',
      marginBottom: 25,
      marginRight: 10,
      marginLeft: 10,
      paddingTop: 10,
      paddingBottom: 5,
      border: 0,
    },
  },
});

const mapState = (state, { match, history }) => ({
  projectId: match.params.projectId,
  activeUser: currentUser(state),
  reviews: reviews(state),
  location: history.location,
  loadingCounts: getCountLoading(state),
});

const mapDispatch = (dispatch, { match }) => ({
  onFetchData: () => {
    dispatch(fetchTimecardCount());
  },
});

class ReviewsHeader extends Component {
  static propTypes = {
    activeUser: userInfoProps.isRequired,
    projectId: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { onFetchData } = this.props;

    onFetchData();
  }

  renderTabs(projectId) {
    const { activeUser, reviews, loadingCounts } = this.props;
    const tabs = REVIEW_TABS_BY_ROLE(activeUser.role, projectId);
    return _.map(tabs, tab => {
      let count = 0;
      const loading = loadingCounts[tab.key];

      if (
        !_.isEmpty(reviews) &&
        !_.isEmpty(reviews[tab.key]) &&
        reviews[tab.key].timecardCount
      ) {
        count = reviews[tab.key].timecardCount;
      }
      return (
        <ReviewsHeaderTab
          key={tab.key}
          label={tab.caption}
          count={count}
          icon={tab.icon}
          defaultActive={tab.defaultActive}
          linkTo={tab.linkTo}
          loading={loading}
        />
      );
    });
  }

  render() {
    const { classes, projectId } = this.props;

    return <div className={classes.row}>{this.renderTabs(projectId)}</div>;
  }
}

export default withRouter(
  connect(
    mapState,
    mapDispatch,
  )(withStyleSheet('Reviews', style)(ReviewsHeader)),
);
