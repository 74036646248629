import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Box, Button, styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DownloadIcon from '@mui/icons-material/Download';
//actions
import {
  updateInProgressUploads,
  batchPendingFilesForUpload,
  deleteFileFromDB,
  downloadExpressForm,
  deleteFile,
} from 'actions/digitalEdits';
import { showAlert } from 'actions/alert';

//selectors
import { getUploadedDeliveryFiles } from 'selectors/digitalEdits';
import DragDropUploadArea from '../UploadFilesComps/DragDropUploadArea';

import { calculateFileSize } from '../UploadFilesComps/FileUploadUtils';
import File from '../UploadFilesComps/File';
import RichText from 'components/RichText/RichText';

//utils
import { MODAL_NAME } from './ApproveInvoiceModal';
import { fileNameChange } from 'utils/helperFunctions';
import { deliveryMethodProp } from 'feature/DigitalEdits/DigitalEditProps';

const useStyles = makeStyles(({ palette }) => ({
  DeliveryMethodNotes: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 16,
    height: 38,
  },
  errorText: { fontWeight: 800, color: palette.error.main },
}));

const NotesBox = styled(Box)(({ theme }) => ({}));
const RichTextBox = styled(Box)(({ theme }) => ({
  height: '90px',
  resize: 'vertical',
  overflow: 'auto',
  border: `1px solid ${theme.palette.text.primary}`,
  margin: '0px 0px 12px 4px',
}));

const mapState = state => ({
  uploadedFiles: getUploadedDeliveryFiles(state),
});

const mapDispatch = dispatch => ({
  onUpdateProgress: (uploadId, percentComplete) =>
    dispatch(
      updateInProgressUploads({
        action: 'update',
        uploadId,
        percentComplete,
      }),
    ),
  onUploadFileToDB: options => {
    dispatch(batchPendingFilesForUpload(options));
  },
  onDeleteFileFromDB: ({ documentId }) => {
    dispatch(deleteFileFromDB({ documentId }));
  },
  onDeleteFile: () => {
    dispatch(deleteFile({ isApproval: true }));
  },
  onMultiFileAlert: () => {
    dispatch(showAlert({ message: 'Only one file can be uploaded' }));
  },
  onDownloadExpressForm: code => {
    dispatch(downloadExpressForm({ code }));
  },
});

const DeliveryMethodNotes = props => {
  const classes = useStyles();
  const {
    deliveryMethod,
    noteText,
    setNoteText,
    onUpdateProgress,
    uploadedFiles,
    onDownloadExpressForm,
    onUploadFileToDB,
    onDeleteFile,
    onDeleteFileFromDB,
    onMultiFileAlert,
  } = props;

  const dmFile = uploadedFiles[0] || null;

  const onHandleFile = React.useCallback(
    filesArray => {
      if (filesArray.length > 1) {
        onMultiFileAlert();
      }
      const file = fileNameChange(filesArray[0]);
      const newFile = {
        type: file.type,
        fileName: file.name,
        size: calculateFileSize(file.size),
        File: file,
        bytes: file.size,
      };

      onUploadFileToDB({
        files: [newFile],
        onUpdateProgress,
        isApproval: true,
      });
    },
    [onMultiFileAlert, onUpdateProgress, onUploadFileToDB],
  );

  const onHandleDelete = React.useCallback(
    ({ documentId, fileName }) => {
      if (documentId) {
        onDeleteFileFromDB({ documentId });
      } else {
        onDeleteFile();
      }
    },
    [onDeleteFile, onDeleteFileFromDB],
  );

  const fileHelperText = deliveryMethod?.fileHelper;

  const instructionsPrefix = deliveryMethod?.label || deliveryMethod.value;

  return (
    <NotesBox>
      <Box className={classes.DeliveryMethodNotes}>
        <Box sx={{ fontWeight: 800 }}>{instructionsPrefix} Instructions:</Box>
        {deliveryMethod?.formLabel && (
          <Button
            sx={{ height: '30px', mb: 1 }}
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={() => {
              onDownloadExpressForm(deliveryMethod.code);
            }}
          >
            {deliveryMethod?.formLabel}
          </Button>
        )}
      </Box>
      <RichTextBox>
        <RichText
          value={noteText}
          onChange={setNoteText}
          placeholder={deliveryMethod?.notePlaceholder}
        />
      </RichTextBox>
      <Box>
        {!dmFile ? (
          <DragDropUploadArea
            onHandleFile={onHandleFile}
            loading={false}
            variant={MODAL_NAME}
            helperText={fileHelperText}
          />
        ) : (
          <Box sx={{ ml: 1 }}>
            <File
              file={dmFile}
              onDeleteFile={onHandleDelete}
              canDelete
              onDownloadFile={() => {}}
            />
          </Box>
        )}
      </Box>
    </NotesBox>
  );
};

DeliveryMethodNotes.propTypes = {
  deliveryMethod: deliveryMethodProp.isRequired,
  noteText: PropTypes.string.isRequired,
  setNoteText: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  onDownloadExpressForm: PropTypes.func.isRequired,
  onUpdateProgress: PropTypes.func.isRequired,
  onUploadFileToDB: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  onDeleteFileFromDB: PropTypes.func.isRequired,
  onMultiFileAlert: PropTypes.func.isRequired,
};

export default compose(connect(mapState, mapDispatch))(DeliveryMethodNotes);
