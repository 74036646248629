export const TABLE_COLUMN_HEADERS = [
  { id: 'checkbox', label: '', sortable: false },
  { id: 'name', label: 'Name', sortable: true },
  { id: 'department', label: 'Dept', sortable: true },
  { id: 'status', label: 'Status', sortable: true },
];

export const moveTcsFilterHandler = (e, timecardList) => {
  const searchVal = e.target.value?.toLowerCase() || '';
  if (searchVal === '') return timecardList;
  const filteredTimecards = timecardList?.filter(
    timecard =>
      timecard?.timecard?.user?.fullName?.toLowerCase().includes(searchVal) ||
      timecard?.departmentName?.toLowerCase().includes(searchVal) ||
      timecard?.userFriendlyTimecardStatus?.toLowerCase().includes(searchVal),
  );
  return filteredTimecards;
};
