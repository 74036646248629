import React from 'react';
import { EnhancedRoute } from 'containers/RoutingSupport';
import ProjectLander from 'containers/ProjectLander';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    key="/projects/:projectId"
    path="/projects/:projectId"
    authenticated
    component={ProjectLander}
  />
);
