import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { Field } from 'redux-form';
import AutoCompleteField from './AutoCompleteField';

const style = () => ({
  root: {
    // display: 'flex',
    // alignItems: 'flex-start',
  },
});

const HTGAutoCompleteAsync = ({
  classes,
  disabled,
  fieldLabel = 'label',
  fieldValue = 'value',
  klass,
  label,
  list,
  name,
  placeholder,
  isRequired = false,
  defaultSort = false,
  ...others
}) => {
  let options;
  if (defaultSort) {
    // just in case there are duplicate employees in the data set
    // we make the data unique before it returns to the client.
    const newOps = _.uniqBy(list, 'id');
    options = _.chain(newOps)
      .sortBy(option => option[fieldLabel])
      .map(state => ({
        value: state[fieldValue],
        label: state[fieldLabel],
        email: state.email,
      }))
      .value();
  } else {
    const newOps = _.uniqBy(list, 'id');
    options = _.chain(newOps)
      .map(state => ({
        value: state[fieldValue],
        label: state[fieldLabel],
        email: state.email,
        decimal: state.decimal,
      }))
      .value();
  }
  return (
    <Field
      className={clsx(classes.root, klass)}
      component={AutoCompleteField}
      name={name}
      label={label}
      placeholder={placeholder || 'Select...'}
      disabled={disabled}
      options={options}
      inputName={name}
      required={isRequired}
      props={{ wtc: true }}
      {...others}
    />
  );
};

HTGAutoCompleteAsync.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  fieldLabel: PropTypes.string,
  fieldValue: PropTypes.string,
  label: PropTypes.string,
  klass: PropTypes.any,
  list: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
  loadOptions: PropTypes.func,
};

export default withStyles(style)(HTGAutoCompleteAsync);
