import React, { useState, useEffect } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FilterOption from './FilterOption';

const newStyle = ({ palette }) => ({
  root: {
    boxShadow: 'none',
    '&:first-child': {
      borderRadius: 0,
    },
  },
  expansionSummary: {
    display: 'flex',
    height: 40,
    minHeight: 40,
    fontSize: 18,
    '&:hover': {
      cursor: 'pointer',
      color: palette.primary.main,
      backgroundColor: `${palette.primary['+9']} !important`, //overwrite MUI default
      '& $labelText': {
        color: palette.primary.main,
      },
    },
    flexDirection: 'row',
  },
  summaryContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 10,
    alignItems: 'center',
  },
  selectedText: {
    fontSize: 14,
    backgroundColor: palette.primary.background,
    padding: 5,
    borderRadius: 2,
    color: palette.primary.main,

    marginRight: 10,
  },
  expansionDetails: {
    padding: '8px 8px 4px 8px',
    borderLeft: `4px ${palette.primary.main} solid`,
  },
  labelText: {
    color: palette.gray['-5'],
    fontSize: 14,
  },
  divider: {
    backgroundColor: palette.primary['+7'],
  },
  expand: {
    transform: 'rotate(180deg)',
  },
});

function FilterDropdown(props) {
  const {
    classes,
    expand,
    saving,
    searchFilter,
    setSearchFilter,
    onSelect,
    onSelectAll,
    // onClearFilter,
    label, //filter name/label
    enableSearch,
    canSelectAll,
    dropdownSelection = [],
    selectedCount,
    calculating,
    variant,
  } = props;

  const selectedLength = dropdownSelection.filter(
    selection => selection.selected,
  ).length;
  const [selectAll, toggleSelectAll] = useState(true);

  const [expanded, toggleExpanded] = useState(false); //Initial value doesn't matter, must set in parent container

  function selectAllClicked() {
    onSelectAll();

    toggleSelectAll(!selectAll);
  }
  function toggleAccordion() {
    toggleExpanded(!expanded);
  }

  useEffect(() => {
    toggleExpanded(expand);
  }, [expand]);

  // useEffect(() => {
  //   if (variant === 'searchTimecards' && label === 'Status') {
  //     setTimeout(() => {
  //       toggleExpanded(true);
  //     }, 750);
  //   }
  // }, []);

  const disabled = saving || calculating;
  // const sAOffset = canSelectAll ? 1 : 0;

  const selectAllChecked =
    selectedLength === dropdownSelection.length &&
    dropdownSelection.length !== 0
      ? true
      : false;

  const filterSelectedCount =
    selectedCount === undefined ? selectedLength : selectedCount;
  return (
    <Accordion
      classes={{
        root: classes.root,
      }}
      expanded={expanded}
      disabled={disabled}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
        classes={{
          root: classes.expansionSummary,
          content: classes.summaryContent,
          expanded: classes.expanded,
        }}
        onClick={toggleAccordion}
      >
        <div className={classes.labelText}>{label}</div>

        {filterSelectedCount > 0 ? (
          <div className={classes.selectedText}>{filterSelectedCount}</div>
        ) : (
          ''
        )}
      </AccordionSummary>
      <Divider className={classes.divider} />
      <AccordionDetails className={classes.expansionDetails}>
        <FilterOption
          enableSearch={enableSearch}
          variant={variant}
          setSearchFilter={setSearchFilter}
          searchFilter={searchFilter}
          canSelectAll={canSelectAll}
          selectAllChecked={selectAllChecked}
          selectAllClicked={selectAllClicked}
          dropdownSelection={dropdownSelection}
          label={label}
          onSelect={onSelect}
          expanded={expanded}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default withStyles(newStyle)(FilterDropdown);
