import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Popper from '@mui/material/Popper';

export const useStyles = makeStyles(theme => ({
  popper: {
    maxWidth: 'fit-content',
    width: 'fit-content !important',
  },
}));

const PopperMy = function (props) {
  const classes = useStyles();
  return (
    <Popper {...props} className={classes.popper} placement="bottom-start" />
  );
};

export default PopperMy;
