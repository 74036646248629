import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TableRow,
  TableCell,
  Paper,
  LinearProgress,
  Hidden,
  List,
} from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import { projectsProps } from 'components/props/projects';
import {
  US_CURRENCY_TEXT,
  CANADIAN_CURRENCY_TEXT,
} from 'components/Shared/constants';
// import { EMPLOYEE, DH, PA, UPM } from 'components/props/profiles';
import { ProjectsListMobile } from 'components/Admin/Projects/Shared';
import { TableList } from 'components/Shared/TableList';

//region icons
import usFlag from 'assets/icons/usFlag.png';
import canadaFlag from 'assets/icons/canadaFlag.png';
import { getWorkflowStatus, getPayrollStatus } from 'utils/helperFunctions';

const style = ({ palette, breakpoints }) => ({
  root: {},
  paper: {
    width: '80%',
    margin: '30px auto 0',
    overflowX: 'auto',
  },
  tableEmpty: {
    textAlign: 'center',
  },
  tableRow: {
    cursor: 'pointer',
  },
  actionColumn: {
    overflow: 'visible',
    paddingLeft: 10,
    paddingRight: 10,
  },
  actionContainer: {
    display: 'inline-flex',
    padding: 0,
  },

  addMore: {
    backgroundColor: palette.primary.background,
    height: '87px',
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  list: {
    paddingTop: 0,
  },
  button: {
    padding: '6px 70px',
  },
  [breakpoints.down('sm')]: {
    paper: {
      width: '100%',
      marginTop: 0,
      overflowX: 'auto',
    },
  },
  iconText: {
    position: 'relative',
    left: '5px',
    fontSize: '10px',
  },
});

class ProjectList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    projects: projectsProps.isRequired,
    loading: PropTypes.bool,
  };

  render() {
    const {
      classes,
      projects,
      loading,
      history,
      navigate,
      pagination,
      addTen,
      isMultiDBs,
      currentUser,
      onNavigate, // onNavigate, onChangePageSize, onApplySorting aren't being used and are just being pulled off the {...others} prop to be passed to the TableList
      onChangePageSize,
      onApplySorting,
      onNavigateToNextPage,
      onRowsChange,
      onProjectListApplySorting,
      ...others
    } = this.props;
    let contentRows = null;
    const showAddMore =
      projects.length >= 10 && pagination.totalCount > pagination.pageSize;

    const headers = [
      { columnId: 'hProjectId', label: 'ID', sortable: true, compact: true },
      {
        columnId: 'name',
        label: 'Project',
        sortable: true,
        compact: true,
      },
      {
        columnId: 'productionCompany',
        label: 'Production Company',
        sortable: true,
        compact: true,
      },
    ];
    //if all the projects belongs to same DB's no need to show regions and dollar columns
    // isMultiDBs will check whether the projects belongs to same DBs or not - return boolean value
    if (isMultiDBs) {
      let region = {
        columnId: 'region',
        label: 'Region',
        sortable: true,
        compact: true,
      };
      let dollar = {
        columnId: 'dbCode',
        label: '$',
        sortable: true,
        compact: true,
      };
      headers.splice(0, 0, region, dollar);
    }
    if (currentUser?.projectRoles.includes('invoice_approver')) {
      headers.push(
        {
          columnId: 'isDraft',
          label: 'Workflow',
          sortable: true,
          compact: true,
        },
        {
          columnId: 'studioPlusEnabled',
          label: 'Edits',
          sortable: true,
          compact: true,
        },
      );
    }
    headers.push({
      columnId: 'status',
      label: 'PR Status',
      sortable: true,
      compact: true,
    });
    if (projects && projects.length <= 0) {
      contentRows = (
        <TableRow>
          <TableCell colSpan={7} className={classes.tableEmpty}>
            {loading ? 'Loading Projects...' : 'No Projects Found'}
          </TableCell>
        </TableRow>
      );
    } else {
      contentRows = projects.map((project, index) => {
        let flag = null;
        let iconText = null;
        let currency = US_CURRENCY_TEXT;

        //currency
        if (
          project.dbCodeName === 'PAYTORCA' ||
          project.dbCodeName === 'PAYVANCA'
        ) {
          currency = CANADIAN_CURRENCY_TEXT;
        } else if (project.dbCodeName === 'None') {
          currency = '-';
        }

        //logo
        if (project.dbCodeName === 'PAY' || project.dbCodeName === 'PAYPA') {
          flag = usFlag;
          iconText = 'US';
        } else if (
          project.dbCodeName === 'PAYTORCA' ||
          project.dbCodeName === 'PAYTORUS'
        ) {
          flag = canadaFlag;
          iconText = 'ON';
        } else if (
          project.dbCodeName === 'PAYVANCA' ||
          project.dbCodeName === 'PAYVANUS'
        ) {
          flag = canadaFlag;
          iconText = 'BC';
        }

        let workflowStatus;
        if (currentUser?.projectRoles.includes('invoice_approver')) {
          workflowStatus = getWorkflowStatus(project);
        }
        return (
          <TableRow
            hover
            key={project.id}
            className={classes.tableRow}
            onClick={navigate(project.id, index, project.region)}
          >
            {isMultiDBs && (
              <TableCell>
                <div className={classes.region}>
                  {flag ? (
                    <img
                      src={flag}
                      alt="RegionIcon"
                      className={classes.regionIcon}
                    />
                  ) : (
                    '-'
                  )}
                  <span className={classes.iconText}>{iconText}</span>
                </div>
              </TableCell>
            )}
            {isMultiDBs && <TableCell>{currency}</TableCell>}
            <TableCell size="small">{project.id}</TableCell>
            <TableCell>{project.name}</TableCell>
            <TableCell>{project.productionCompanyName}</TableCell>
            {currentUser?.projectRoles.includes('invoice_approver') && (
              <>
                <TableCell>{workflowStatus}</TableCell>
                <TableCell>
                  {project?.isStudioPlusEnabled ? 'Enabled' : 'Disabled'}
                </TableCell>
              </>
            )}
            <TableCell size="small">{getPayrollStatus(project)}</TableCell>
          </TableRow>
        );
      });
    }

    return (
      <Paper className={classes.paper}>
        {loading && projects.length <= 0 && <LinearProgress />}
        <Hidden mdUp>
          <List className={classes.list}>
            <ProjectsListMobile
              projects={projects}
              navigate={navigate}
              loading={loading}
            />
          </List>
          {showAddMore && (
            <div className={classes.addMore}>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={addTen}
              >
                {'Load More'}
              </Button>
            </div>
          )}
        </Hidden>
        <Hidden mdDown>
          <TableList
            enablePagination
            headers={headers}
            pagination={pagination}
            onNavigate={onNavigateToNextPage}
            onChangePageSize={onRowsChange}
            onApplySorting={onProjectListApplySorting}
            {...others}
          >
            {contentRows}
          </TableList>
        </Hidden>
      </Paper>
    );
  }
}

export default withStyleSheet('ProjectList', style)(ProjectList);
