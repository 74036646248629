import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Field, getFormValues } from 'redux-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as sel from '../../selectors';
import TimeField from './TimeField';
import { doesDayAllowTimes } from 'utils/weekUtils';

import { FORM_NAME } from 'feature/EmployeeTimecard/selectors';

const mapState = state => ({
  formValues: getFormValues(FORM_NAME)(state),
  isTcEditable: sel.getIsTcEditable(state),
});

const mapDispatch = {};
const TimeCell = props => {
  const {
    name,
    timeV,
    isDayActive,
    member,
    formValues,
    isTcEditable,
    isPartialDealMemo,
  } = props;
  const dayTypeCode = _.get(formValues, `${member}.dayType.code`);
  const allowsTimes = doesDayAllowTimes(dayTypeCode);

  const disabled = !isDayActive || !allowsTimes || isPartialDealMemo;

  return (
    <Field
      name={name}
      component={TimeField}
      timeV={timeV}
      disabled={disabled}
      isTcEditable={isTcEditable}
    />
  );
};

TimeCell.propTypes = {
  name: PropTypes.string.isRequired,
  timeV: PropTypes.object.isRequired,
  isDayActive: PropTypes.bool.isRequired,
  member: PropTypes.string.isRequired,
  isTcEditable: PropTypes.bool.isRequired,
  isPartialDealMemo: PropTypes.bool.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default compose(connect(mapState, mapDispatch))(TimeCell);
