import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';

class FlagButtons extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
  };

  render() {
    const { loading, onUpdate } = this.props;

    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={onUpdate}
            disabled={loading}
          >
            {'Save Flags'}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default FlagButtons;
