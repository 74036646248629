import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Typography } from '@mui/material';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';

// actions
import {
  removeTimecardAllowance,
  deleteTeamAllowances,
} from 'actions/timecards';

const onSubmit = (values, dispatch, props) => {
  if (props.isTeam) {
    dispatch(
      deleteTeamAllowances({
        weekEnding: props.weekEnding,
        index: props.index,
      }),
    );
  } else {
    dispatch(
      removeTimecardAllowance({
        timecardId: props.timecardId,
        worksightId: props.worksightId,
      }),
    );
  }
};

const mapState = (_state, { timecardId, worksightId, index }) => ({
  initialValues: { timecardId, worksightId },
});

class DeleteTimecardAllowance extends Component {
  render() {
    const { handleSubmit, worksightId, ...others } = this.props;
    const content = (
      <div>
        <Typography color="inherit">
          {'Are you sure you want to delete this allowance?'}
        </Typography>
      </div>
    );
    return (
      <form onSubmit={handleSubmit}>
        <Field
          component="input"
          name="worksightId"
          type="hidden"
          value={worksightId}
        />
        <ConfirmModal
          content={content}
          title={'Delete Allowance'}
          buttonText="Yes, Delete"
          buttonType="secondary"
          raisedButton
          {...others}
        />
      </form>
    );
  }
}
export default compose(
  withModalDialog({ dialog: 'DeleteTimecardAllowance', maxWidth: 'md' }),
  connect(mapState, null),
  reduxForm({
    form: 'DeleteTimecardAllowance',
    onSubmit,
  }),
)(DeleteTimecardAllowance);
