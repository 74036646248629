import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
// Utilities
import clsx from 'clsx';
import { Link } from 'react-router-dom';

// MUI Components
import { Typography, Button, IconButton, Tooltip } from '@mui/material/';
import {
  FaUserCircle as AccountCircleIcon,
  FaAngleDown as KeyboardArrowDownIcon,
} from 'react-icons/fa';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import { myCncUrl } from 'constants/config/apiServer';
import { PA, UPM } from 'components/props/profiles';

import ColorLensIcon from '@mui/icons-material/ColorLens';

const styles = theme => ({
  root: {
    maxWidth: '100%',
    justifySelf: 'end',
    backgroundColor: ' rgba(0, 0, 0, 0)',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: ' rgba(0, 0, 0, 0.1)',
      boxShadow: 'none',
    },
  },
  userName: {
    color: 'inherit',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    paddingLeft: 5,
    paddingRight: 5,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    verticalAlign: 'middle',
    maxWidth: '70%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  userIcon: {
    fontSize: 20,
  },
  arrowIcon: {
    fontSize: 16,
  },
  menuUserAvatar: {
    fontSize: 56,
    gridArea: 'avatar',
    color: theme.palette.primary.dark,
  },
  menuUserName: {
    gridArea: 'name',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '85%',
    marginBottom: 15,
    paddingTop: 0,
    paddingBottom: 4,
    verticalAlign: 'middle',
    fontSize: '1.3em',
  },
  menuUserEmail: {
    gridArea: 'email',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '85%',
    paddingTop: 2,
  },
  menuItemUser: {
    height: 40,
    display: 'grid',
    placeItems: 'center',
    gridTemplateRows: '45% 1fr 1fr',
    paddingTop: '7%',
    paddingBottom: '10%',
    gridTemplateAreas: `
    "avatar"
    "name"
    "email"`,
  },
  menuItem: {
    placeItems: 'center',
    display: 'grid',
    justifyContent: 'stretch',
    padding: 0,
  },
  menuLink: {
    display: 'grid',
    placeSelf: 'stretch',
    width: '86%',
    padding: '10px 16px',
  },
  menu: {
    width: 225,
    '& ul:first-child': {
      padding: 0,
    },
  },
});

class UserMenu extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string,
    isAdminUser: PropTypes.bool,
    hideTitle: PropTypes.bool,
  };

  static defaultProps = {};

  state = {
    open: false,
  };

  toggleMenu = () =>
    this.setState(({ open }) => ({
      open: !open,
    }));

  closeMenu = () => this.setState({ open: false });

  render() {
    const {
      classes = {},
      email,
      fullName,
      isAdminUser = false,
      className,
      userRole,
      canToggleTheme,
      onToggleTheme,
    } = this.props;
    const { open } = this.state;
    const rootClass = clsx(className, classes.root);
    const adjustmentUrl =
      window.location.origin === 'https://hoursplus.castandcrew.com'
        ? 'https://my.castandcrew.com/adjustment-request'
        : 'https://mycnc-web-qa1.dev.aws.castandcrew.com/adjustment-request';

    const content = [
      <Button
        ref={node => {
          this.anchorEl = node;
        }}
        aria-owns={open ? 'menu-appbar' : null}
        aria-haspopup="true"
        onClick={this.toggleMenu}
        color="inherit"
        className={rootClass}
        data-test-id={`UserMenu-button`}
        key={`UserMenu-button`}
      >
        <AccountCircleIcon className={classes.userIcon} />
        <Typography
          className={classes.userName}
          data-test-id={`UserMenu-userName`}
        >
          {fullName}
        </Typography>
        <KeyboardArrowDownIcon className={classes.arrowIcon} />
      </Button>,

      <Menu
        anchorEl={this.anchorEl}
        open={open}
        onClose={this.closeMenu}
        disableAutoFocusItem
        classes={{ paper: classes.menu }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        data-test-id={`UserMenu-menu`}
        key={`UserMenu-menu`}
      >
        <div className={classes.menuItemUser}>
          <Typography
            className={classes.menuUserName}
            data-test-id={`UserMenu-menuUserName`}
          >
            {fullName}
          </Typography>
          <Typography
            variant={'caption'}
            className={classes.menuUserEmail}
            data-test-id={`UserMenu-menuEmail`}
          >
            {email}
          </Typography>
        </div>
        <Divider />
        {!isAdminUser && (
          <MenuItem
            className={classes.menuItem}
            data-test-id={`UserMenu-Profile`}
          >
            <Link to="/me/profile" className={classes.menuLink}>
              {'Profile Details'}
            </Link>
          </MenuItem>
        )}
        {!isAdminUser && <Divider />}
        <MenuItem
          className={classes.menuItem}
          data-test-id={`UserMenu-contactSupport`}
        >
          <Typography className={classes.menuLink}>
            <a href={`${myCncUrl}`} target="_blank" rel="noopener noreferrer">
              MyCast&Crew
            </a>
          </Typography>
        </MenuItem>
        <Divider />
        {!isAdminUser && (
          <MenuItem
            className={classes.menuItem}
            data-test-id={`UserMenu-contactSupport`}
          >
            <Typography className={classes.menuLink}>
              <a
                href="https://edge.castandcrew.com/learning/cc"
                target="_blank"
                rel="noopener noreferrer"
              >
                C&C Edge Learning
              </a>
            </Typography>
          </MenuItem>
        )}
        <Divider />
        {!isAdminUser && (
          <MenuItem
            className={classes.menuItem}
            data-test-id={`UserMenu-contactSupport`}
          >
            <Typography className={classes.menuLink}>
              <a
                href="https://support.castandcrew.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Help Center
              </a>
            </Typography>
          </MenuItem>
        )}
        <Divider />
        {(userRole === PA || userRole === UPM) && (
          <MenuItem
            className={classes.menuItem}
            data-test-id={`UserMenu-contactSupport`}
          >
            <Typography className={classes.menuLink}>
              <a href={adjustmentUrl} target="_blank" rel="noopener noreferrer">
                Submit Adjustment
              </a>
            </Typography>
          </MenuItem>
        )}
        <Divider />
        <MenuItem className={classes.menuItem} data-test-id={`UserMenu-logout`}>
          <Link to={'/logout'} className={classes.menuLink}>
            Log out
          </Link>
        </MenuItem>
      </Menu>,
    ];

    if (canToggleTheme) {
      content.unshift(
        <Tooltip key="colorButton" title="Toggle Theme">
          <IconButton onClick={onToggleTheme} style={{ color: 'white' }}>
            <ColorLensIcon />
          </IconButton>
        </Tooltip>,
      );
    }

    return content;
  }
}

export default withStyles(styles)(UserMenu);
