import React, { useState } from 'react';
import { Table } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import SelectTableHeaderRow from './SelectTableHeaderRow';
import SelectTableRows from './SelectTableRows';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    overflow: 'auto',
  },
}));

const SelectTable = ({
  list,
  fields,
  selectedIndex,
  setSelectedIndex,
  readOnly = false,
  dmScaleRatesLoading,
}) => {
  const classes = useStyles();

  const [isScrolling, setIsScrolling] = useState(false);

  const checkScrollAtStart = () => {
    const table = document.getElementsByClassName(classes.tableContainer)[0];
    const scrollAmount = table && table.scrollLeft;
    if (scrollAmount === 0) {
      setIsScrolling(false);
    } else {
      setIsScrolling(true);
    }
  };

  const scrollCheck = () => {
    checkScrollAtStart();
  };

  const fieldSet = new Set();

  list.forEach(item => {
    fields.forEach((field, index) => {
      try {
        const text = field.renderFunc(item, field.dataLoc);
        if (text && text !== '') {
          fieldSet.add(index);
        }
      } catch (error) {
        console.error('Error during parsing deal memo');
      }
    });
  });

  const validIndex = Array.from(fieldSet).sort((a, b) => a - b);
  const validFields = validIndex.map(index => fields[index]);

  return (
    <div
      id="selectTable"
      className={classes.tableContainer}
      onScroll={scrollCheck}
    >
      <Table className={classes.table}>
        <SelectTableHeaderRow isScrolling={isScrolling} fields={validFields} />
        <SelectTableRows
          list={list}
          fields={validFields}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          isScrolling={isScrolling}
          readOnly={readOnly}
          dmScaleRatesLoading={dmScaleRatesLoading}
        />
      </Table>
    </div>
  );
};

export default SelectTable;
