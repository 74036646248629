import max from 'lodash/max';

export function getWidth() {
  return max([
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth,
  ]);
}

export function getFullHeight() {
  return max([
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight,
  ]);
}

export function getOnScreenHeight() {
  return document.documentElement.clientHeight;
}
