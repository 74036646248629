import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
// components
import Start from 'components/Admin/Projects/Start';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// selectors
import {
  getProjectDetails,
  validating,
  startProjectValidation,
} from 'selectors/project';
// actions
import { startProject, validateStartProject } from 'actions/project';

export const START_PROJ_MODAL = 'startProjectModal';
const mapStateToProps = state => ({
  project: getProjectDetails(state),
  validating: validating(state),
  validation: startProjectValidation(state),
});

const mapDispatchToProps = dispatch => ({
  onSubmit: () => {
    dispatch(startProject());
  },
  onValidateStart: () => {
    dispatch(validateStartProject());
  },
});

export default compose(
  withModalDialog({
    dialog: START_PROJ_MODAL,
    maxWidth: 'sm',
    skipUnmount: true,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(Start);
