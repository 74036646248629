import React from 'react';
import _ from 'lodash';
import {
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableHead,
  Paper,
  TablePagination,
  Typography,
  Hidden,
  Switch,
  FormControlLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import BatchRow from './BatchRow';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    padding: 10,
    [breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  tableHead: {
    fontWeight: 700,
    paddingLeft: 0,
    paddingRight: 0,
  },
  tableHeadRow: {
    '& > *': {
      paddingTop: 7,
      paddingBottom: 7,
      whiteSpace: 'nowrap',
    },
  },
  tableFirstCell: {
    fontWeight: 700,
    paddingLeft: 75,
    [breakpoints.down('sm')]: {
      paddingLeft: 50,
    },
    width: '44.7%',
  },
  emptyRow: {
    padding: 35,
    textAlign: 'center',
    color: palette.text.disabled,
  },
  mobileTable: {
    tableLayout: 'fixed',
  },
  showPaid: {
    float: 'right', //ya I know, using flexbox here doesn't play nice with MUI
  },
  showPaidLabel: {
    color: palette.text.primary,
    fontSize: 14,
  },
  mobileSpacerCell: {
    width: 20,
  },
}));

const Listing = ({
  projectId,
  projectDetails,
  batches,
  reviewType,
  role,
  onApproveBatch,
  onWTCApproveBatch,
  onRejectBatch,
  page,
  pageSize,
  onRefreshPage,
  onViewTimecard,
  onViewApprovalFlow,
  canSubmitAsRejected = false,
  onSubmitAsRejected,
  onDownloadTimecardsReport,
  timecardLoadingByBatch,
  displayComment,
  isProcessingApproval,
  showPaid,
  onTogglePaid,
  onFetchBatchTimecards,
}) => {
  const classes = useStyles();

  let totalCount = Object.keys(batches).length;

  if (!!batches && totalCount > 0) {
    const details = batches[Object.keys(batches)[0]].details;
    totalCount =
      details && details.pagination && details.pagination.total
        ? details.pagination.total
        : totalCount;
  }

  const isHistoryTab = reviewType === 'history';

  function handleChangeRowsPerPage(event) {
    onRefreshPage(0, event.target.value);
  }

  function handleChangePage(event, newPage) {
    onRefreshPage(newPage + 1, pageSize);
  }

  const showPaidSwitch = isHistoryTab ? (
    <section className={classes.showPaid}>
      <FormControlLabel
        label={<span className={classes.showPaidLabel}>Show paid</span>}
        labelPlacement="start"
        control={
          <Switch color="primary" onChange={onTogglePaid} checked={showPaid} />
        }
      />
    </section>
  ) : (
    <></>
  );

  let batchRows;

  if (totalCount <= 0) {
    batchRows = (
      <>
        <Paper className={classes.emptyRow}>
          <span>{showPaidSwitch}</span>

          <Typography variant="h3" color="inherit">
            {'No Batches for review'}
          </Typography>
        </Paper>
      </>
    );
  } else {
    batchRows = (
      <Paper className={classes.root}>
        <Hidden mdUp>{showPaidSwitch}</Hidden>
        <Table className={classes.mobileTable}>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.tableHeadRow}>
              <TableCell className={classes.tableFirstCell}>
                Batch Name
              </TableCell>
              <Hidden mdUp>
                <TableCell className={classes.mobileSpacerCell} />
              </Hidden>
              <TableCell className={classes.tableHead}>
                # of Timecards
              </TableCell>
              <Hidden mdDown>
                {/* desktop only */}
                <TableCell>{showPaidSwitch}</TableCell>
              </Hidden>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.sortBy(batches, batch => batch.details.endsOn)
              .reverse()
              .map(batch => (
                <BatchRow
                  key={batch.details.htgId}
                  projectId={projectId}
                  batch={batch}
                  reviewType={reviewType}
                  role={role}
                  onApproveBatch={onApproveBatch}
                  onWTCApproveBatch={onWTCApproveBatch}
                  onRejectBatch={onRejectBatch}
                  onViewTimecard={onViewTimecard}
                  onViewApprovalFlow={onViewApprovalFlow}
                  canSubmitAsRejected={canSubmitAsRejected}
                  onSubmitAsRejected={onSubmitAsRejected}
                  onDownloadTimecardsReport={onDownloadTimecardsReport}
                  timecardLoadingByBatch={timecardLoadingByBatch}
                  displayComment={displayComment}
                  isProcessingApproval={isProcessingApproval}
                  onFetchBatchTimecards={onFetchBatchTimecards}
                />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          rowsPerPageOptions={[20, 50, 100]}
          count={totalCount}
          rowsPerPage={pageSize}
          page={page > 0 ? page - 1 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    );
  }

  return batchRows;
};

export default Listing;
