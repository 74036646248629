export const ERROR_MESSAGES = {
  not_valid: 'Not valid',
  name_invalid: 'Numbers not allowed',
  not_decimal: 'A number is required',
  not_email: 'Invalid email format',
  not_present: 'Required',
  format: 'Invalid format',
  time_format: 'Input time in the form HH:MM AM/PM',
  time_required: 'Complete your time entry for the day',
  call_wrap_time_required: 'Call/Wrap times are required',
  not_unique: 'This field must be unique',
  email_duplicate: 'Email already in use',
  review_flow_in_progress:
    'This Approval Flow contains timecards being processed',
  department_in_progress: 'This Department contains timecards being processed',
  not_filled:
    'No day type information or allowance was entered and cannot be submitted',
  earlier_than_call: 'Time is earlier that Call',
  later_than_wrap: 'Time is later than Wrap',
  split_incomplete: 'Splits are incomplete',
  not_match_hours: 'Hours are incomplete',
  exceeds_8_hours: 'Work and sick hours can not be greater than 8 hours',
  exceeded_quantity: 'Splits can not exceed 8 lines',
  batch_not_empty: 'There are timecards created on this batch',
  open_batches: "Can't change Workflow when there are open Batches",
  invalid_code: 'Verification code invalid.',
  profile_mismatch: 'Information does not match',
  not_permitted: 'Timecard submission not permitted',
  ssn_htgmismatch: 'Information does not match',
};
