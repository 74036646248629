const INVOICE_TEMPLATE_HEADERS = [
  { columnId: 'name', label: 'Name', compact: true, hidden: false },
  {
    columnId: 'departments',
    label: 'Departments',
    compact: true,
    hidden: false,
  },
  {
    columnId: 'separateBatches',
    label: 'Separate Batch',
    compact: true,
    hidden: false,
  },
  {
    columnId: 'locationType',
    label: 'Work Zone',
    compact: true,
    hidden: false,
  },
  // { columnId: 'country.name', label: 'Country', compact: true },
  {
    columnId: 'state.name',
    label: 'Work St/Prov',
    compact: true,
    hidden: false,
  },
  // { columnId: 'county.name', label: 'County', compact: true },
  // { columnId: 'subdivision.name', label: 'PSD', compact: true },
  { columnId: 'city.name', label: 'City', compact: true, hidden: false },
  {
    columnId: 'producersWeek',
    label: "Producer's Week",
    compact: true,
    hidden: false,
  },

  {
    columnId: 'systemLocation',
    label: 'Studio+ Folder',
    compact: true,
    hidden: false,
  },
  { columnId: 'actions', label: ' ', compact: true, hidden: false },
];

export default INVOICE_TEMPLATE_HEADERS;
