import React from 'react';
import {
  MaxWidthLayout,
  Header,
  AlertHeader,
  ProjectHeader,
} from 'containers/Layout';

import { EnhancedRoute } from 'containers/RoutingSupport';
import { DTS } from 'feature/DTS';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <EnhancedRoute
      authenticated
      key="/projects/daily-timesheets"
      id="/projects/daily-timesheets"
      path="/projects/:projectId/daily-timesheets"
      layout={MaxWidthLayout}
      headers={[Header, ProjectHeader, AlertHeader]}
      component={DTS}
      isLoadProjectActiveUser={true}
      title="Daily Timesheets"
    />
  );
};
