import React from 'react';
import { ListItem } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

// ICONS
import USFlagIcon from 'assets/icons/usFlag.png';
import CanadianFlagIcon from 'assets/icons/canadaFlag.png';
// Utils
import { getCurrency, getPayrollStatus } from 'utils/helperFunctions';

const useStyles = makeStyles(({ palette }) => ({
  mobileListItem: {
    // display: 'flex',
    display: 'block',
    justifyContent: 'space-between',
    borderBottom: `1px ${palette.gray['+6']} solid`,
    minHeight: '87px',
    padding: '16px',
  },
  countryIcon: {
    marginRight: 8,
  },
  projectFields: {
    color: palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
    marginTop: 4,
    textTransform: 'capitalize',
  },
  project: {
    display: 'flex',
    alignItems: 'center',
    color: palette.text.secondary,
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'capitalize',
    paddingRight: 6,
  },
}));

const ProjectsListMobile = props => {
  const classes = useStyles();
  const { projects = [], navigate, loading } = props;

  if (projects.length <= 0) {
    return (
      <ListItem className={classes.mobileListItem}>
        {loading ? 'Loading Projects...' : 'No Projects Found'}
      </ListItem>
    );
  }
  const dbs = projects.map(item => item.dbCodeName) || []; //array of all dbs
  const uniqueDBs = [...new Set(dbs)] || []; //array of unique dbs
  return projects.map(project => {
    const hasRegion = project.region && project.region !== 'None';
    const currency = hasRegion
      ? getCurrency({ region: project.region, dbCode: project.dbCodeName })
      : undefined;
    return (
      <ListItem
        className={classes.mobileListItem}
        key={`mobile-${project.id}`}
        onClick={navigate(project.id)}
      >
        <div className={classes.project}>
          {hasRegion && uniqueDBs.length > 1 && (
            <img
              alt="USFlag"
              src={project.region === 'US' ? USFlagIcon : CanadianFlagIcon}
              className={classes.countryIcon}
            />
          )}{' '}
          {project.name}
        </div>
        <div className={classes.projectFields}>
          {project.productionCompanyName}
        </div>
        {hasRegion && uniqueDBs.length > 1 && (
          <div className={classes.projectFields}>
            {project.region}
            {currency ? ` - ${currency}` : ''}
          </div>
        )}
        <div className={classes.projectFields}>{getPayrollStatus(project)}</div>
      </ListItem>
    );
  });
};
export default ProjectsListMobile;
