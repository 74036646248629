import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import {
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { formatDateTimezone } from 'utils/formatDate';
import { PA, DH, ROLE_LABELS_WTC_COMMENTS } from 'components/props/profiles';

const style = ({ palette }) => ({
  root: {
    width: '100%',
    boxShadow: 'none',
  },
  commentTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  addCommentsBtn: {
    float: 'right',
    position: 'absolute',
    top: 16,
    right: 32,
  },
  commentCount: {
    color: palette.primary.main,
    backgroundColor: palette.primary.background,
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 6,
    marginRight: 10,
    padding: '1px 3px 1px 2px',
    borderRadius: 3,
  },
  divider: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  dateName: {
    color: palette.primary.main,
    fontSize: 15,
    display: 'flex',
    alignItem: 'flex-end',
  },
  commentBody: {
    color: palette.text.secondary,
    fontSize: 18,
    fontWeight: 500,
    whiteSpace: 'pre-wrap',
  },
  userRole_PA: {
    marginLeft: 13,
    marginRight: 22,
    color: palette.info.main,
  },
  userRole_crew: {
    marginLeft: 13,
    marginRight: 22,
    color: palette.warning.main,
  },
  userRole_DH: {
    marginLeft: 13,
    marginRight: 22,
    color: palette.common.black,
  },
  timecardDate: {
    color: palette.common.white,
    backgroundColor: palette.info.main,
    padding: '0 13px 0 13px',
    height: 21,
    fontSize: 15,
    fontWeight: 500,
    borderRadius: 12,
  },
  details: {
    display: 'block',
    color: palette.primary.main,
    backgroundColor: palette.primary.background,
    marginRight: 24, //This is get the comments padded background to line up with
    marginLeft: 24, //the other tables on the WTC page
  },
});

function Comments({
  classes,
  comments = [],
  onAddComment,
  project,
  hPlusStatus,
}) {
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  });
  const commentTypesWithWeekDay = ['resubmit', 'day', 'reject'];
  const commentTypeWithoutDate = ['corrections', 'timecard'];
  const renderComments = () => {
    let rendering = comments.reduce((render, comment) => {
      const userRole = comment.role;
      const date = moment(comment.referenceDate).format('MM-DD-YYYY');
      const day = moment(date).format('ddd');
      render.push(
        <AccordionDetails key={comment.id} className={classes.details}>
          <Typography className={classes.dateName}>
            {formatDateTimezone(
              comment.createdAt,
              'MM/DD/YYYY HH:mm:ss',
              project.timeZone,
            )}
            {' - '}
            {comment.userName}
            <span
              className={
                userRole === PA
                  ? classes.userRole_PA
                  : userRole === DH
                  ? classes.userRole_DH
                  : classes.userRole_crew
              }
            >
              {ROLE_LABELS_WTC_COMMENTS[userRole] || userRole}
            </span>
            {!commentTypeWithoutDate.includes(comment.commentType) &&
              comment.referenceDate !== '0001-01-01T00:00:00' && (
                <span className={classes.timecardDate}>
                  {commentTypesWithWeekDay.includes(comment.commentType)
                    ? `${day} ${date}`
                    : `${date}`}
                </span>
              )}
          </Typography>
          <Typography className={classes.commentBody}>
            {comment.comment}
          </Typography>
        </AccordionDetails>,
      );
      render.push(
        <Divider key={`Divider${comment.id}`} className={classes.divider} />,
      );
      return render;
    }, []);
    rendering.pop();
    return rendering;
  };

  const count = comments.length > 10 ? comments.length : '0' + comments.length;
  const [expanded, setExpanded] = useState('initial');

  const toggleExpand = () => {
    const newVal = expanded === 'open' ? 'closed' : 'open';
    setExpanded(newVal);
  };

  if (expanded === 'initial') {
    setTimeout(() => {
      //prevent state change on unmounted component
      if (mounted.current) setExpanded('open');
    }, 800);
  }
  return (
    <div>
      <Accordion className={classes.root} expanded={expanded === 'open'}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleExpand}
          variant="iconOnRight"
        >
          <Typography>
            <span className={classes.commentTitle}>Comments</span>
            <span className={classes.commentCount}>{count}</span>
          </Typography>
        </AccordionSummary>
        {comments.length ? renderComments() : []}
        {hPlusStatus !== 'processed' && (
          <Button
            variant="text"
            color="primary"
            className={classes.addCommentsBtn}
            onClick={() => onAddComment()}
          >
            <AddIcon className={classes.icon} />
            <span>Add Comment</span>
          </Button>
        )}
      </Accordion>
    </div>
  );
}

export default withStyles(style)(Comments);
