import { default as MyTimecards } from './MyTimecards';
import { default as Create } from './Create';
import { default as Timecard } from './Timecard';
import { default as TimecardV1 } from './TimecardV1';
import { default as NavTo } from './NavTo';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Create,
  MyTimecards,
  Timecard,
  TimecardV1,
  NavTo,
};
