import { createSelector } from 'reselect';
import _ from 'lodash';

const immutableEmptyArray = Object.freeze([]);

export const makeFilterSelectors = stateName => {
  const getCustomFilter = (state, filterName) =>
    _.get(state, `${stateName}.searchFilters.${filterName}`, '');

  const getListByFilterName = (state, filterName) =>
    _.get(state, `${stateName}.filters.${filterName}`, immutableEmptyArray);

  const getFilteredDropdown = createSelector(
    [getCustomFilter, getListByFilterName],
    (searchFilter, list) => {
      if (!searchFilter) return list;
      const filteredList = list.filter(option => {
        if (
          option.label === null ||
          typeof option.label !== 'string' ||
          typeof searchFilter !== 'string'
        ) {
          return -1;
        }

        return (
          option.label.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0
        );
      });

      return filteredList;
    },
  );

  const getSelectedCount = createSelector(
    [getListByFilterName],
    list => list.filter(f => f.selected === true).length,
  );

  return {
    getCustomFilter,
    getListByFilterName,
    getFilteredDropdown,
    getSelectedCount,
  };
};
