import { default as List } from './List';
import { default as Timecards } from './Timecards';
import { default as Reports } from './Reports';
import { default as Reviews } from './Reviews';
import { default as CrewTimecard } from './CrewTimecard';
import { default as DailyTimesheets } from './DailyTimesheets';
import { default as SearchInvoices } from './SearchInvoices';
import { default as DigitalEdits } from './DigitalEdits';
import { default as DigitalEditsComment } from './DigitalEditsComment';
import { default as DigitalEditsRedirect } from './DigitalEditsRedirect';
import { default as CrewList } from './CrewList';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  List,
  Timecards,
  Reports,
  Reviews,
  CrewTimecard,
  DailyTimesheets,
  SearchInvoices,
  CrewList,
  DigitalEditsRedirect, //must be before DigitalEdits
  DigitalEdits,
  DigitalEditsComment,
};
