import { buildUrl } from '../api';

const URL_STATES = '/:projectId/states';
const URL_COUNTRIES = '/countries';
const URL_CITIES = '/cities';
const URL_COUNTIES = '/counties';

const URL_SEARCH_BY_TYPE = '/names/:type';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async countriesV1() {
    const rsp = await clientV2.get(URL_COUNTRIES);
    return rsp.data;
  },
  async statesV1({ projectId, countryId }) {
    let URL = URL_STATES;
    if (countryId) {
      URL += `/${countryId}`;
    }
    const url = buildUrl(URL, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async citiesV1({ stateId }) {
    let URL = URL_CITIES;
    if (stateId) {
      URL += `/${stateId}`;
    }
    const rsp = await clientV2.get(URL);
    return rsp.data;
  },

  async countiesV1({ stateId }) {
    let URL = URL_COUNTIES;
    if (stateId) {
      URL += `/${stateId}`;
    }
    const rsp = await clientV2.get(URL);
    return rsp.data;
  },

  async subdivisionsV1({ search, page = 1, pageSize = 20 }) {
    const payload = {
      page,
      pageSize,
    };
    if (search) payload.search = search;
    const url = buildUrl(URL_SEARCH_BY_TYPE, { type: 'stateSubdivisionCode' });
    const rsp = await clientV2.post(url, payload);
    return rsp.data;
  },
});
