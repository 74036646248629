import PropTypes from 'prop-types';
import { ROLES } from './profiles';

export const reviewFlowProps = PropTypes.shape({
  id: PropTypes.number,
  projectId: PropTypes.number,
});

export const reviewFlowsProps = PropTypes.arrayOf(reviewFlowProps);

export const reviewFlowStep = PropTypes.shape({
  id: PropTypes.number,
  position: PropTypes.number,
  reviewFlowId: PropTypes.number,
  role: PropTypes.oneOf(ROLES),
});

export const reviewFlowSteps = PropTypes.arrayOf(reviewFlowStep);
