import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import { Box, Typography } from '@mui/material';

import withModalDialog from 'decorators/withModalDialog';

import { hide as hideModal, show as showModal } from 'actions/modalDialog';

import {
  ContentBox,
  FooterBox,
  FooterButton,
  TitleBox,
} from '../Shared/styledComponents';

import {
  CONFIRM_COPY_TO_ALL_DAYS_MODAL,
  SIGNATURE_MODAL,
} from '../Modals/modalNames';

const mapState = state => ({});
const mapDispatch = dispatch => {
  return {
    onCloseModal: () =>
      dispatch(hideModal({ dialog: CONFIRM_COPY_TO_ALL_DAYS_MODAL })),
    handleApprove: () => {
      dispatch(hideModal({ dialog: CONFIRM_COPY_TO_ALL_DAYS_MODAL }));
      dispatch(showModal({ dialog: SIGNATURE_MODAL }));
    },
  };
};
const CopyToOtherDays = props => {
  const { onCloseModal, handleApprove } = props;
  return (
    <Box>
      <TitleBox>
        <Typography variant="h5">Identical Times Found</Typography>
      </TitleBox>
      <ContentBox sx={{ m: '30px 20px' }}>
        <Typography sx={{ fontSize: '18px' }}>
          You've entered identical times for all days. Are you sure you want to
          submit?
        </Typography>
      </ContentBox>
      <FooterBox sx={{ mb: '20px' }}>
        <FooterButton variant="outlined" onClick={onCloseModal}>
          Cancel
        </FooterButton>
        <FooterButton onClick={handleApprove}>Yes, Approve</FooterButton>
      </FooterBox>
    </Box>
  );
};

CopyToOtherDays.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  handleApprove: PropTypes.func.isRequired,
};

export default compose(
  connect(mapState, mapDispatch),
  withModalDialog({
    dialog: CONFIRM_COPY_TO_ALL_DAYS_MODAL,
    maxWidth: 'sm',
    roundedCorners: true,
  }),
)(CopyToOtherDays);
