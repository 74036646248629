import React, { Component } from 'react';
import { connect } from 'react-redux';
//component
// import FilterDropdown from 'components/Employees/Reviews/BulkEdit/FilterDropdown';
//selector
import {
  getFilteredDropdown,
  getCustomFilter,
  // getFilterSelectedCount,
} from 'selectors/bulkEdit';
// action
import {
  setSearchFilter,
  onSelect,
  clearFilter,
  // onSelectFilter,
  // onSelectAllFilter,
  fetchSelectedCount,
  filterSelected,
} from 'actions/bulkEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ filterName, selector }) => {
  const mapState = state => {
    return {
      searchFilter: getCustomFilter(state, filterName),
      dropdownSelection: getFilteredDropdown(state, filterName, selector),
      // selectedCount: getFilterSelectedCount(filterName)(state),
    };
  };
  const mapDispatch = dispatch => {
    return {
      setSearchFilter: e => {
        const value = e.target.value;
        dispatch(setSearchFilter({ value: value, filterName }));
      },
      onSelectAll: () => {
        dispatch(onSelect({ listName: filterName }));
        dispatch(filterSelected());
        dispatch(fetchSelectedCount());
      },
      onSelect: ({ index }) => {
        dispatch(onSelect({ index, listName: filterName }));
        dispatch(filterSelected());
        dispatch(fetchSelectedCount());
      },
      onClearFilter: () => {
        dispatch(clearFilter({ filterName }));
        dispatch(fetchSelectedCount());
      },
    };
  };

  return BaseComponent => {
    class withBulkEditFilter extends Component {
      static displayName = `withBulkEditFilter(${filterName})`;
      render() {
        return <BaseComponent {...this.props} />;
      }
    }

    return connect(mapState, mapDispatch)(withBulkEditFilter);
  };
};
