import React, { Component } from 'react';
import { compose } from 'utils/helperFunctions';

import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  LinearProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { withStyleSheet } from 'shared/theme';
import { ModalFooter, ModalContent } from 'components/Shared/ModalDialog';
import Message from 'components/Message';
import { formatDateUTC } from 'utils/formatDate';
import { PA, DH, ROLE_LABELS_WTC_COMMENTS } from 'components/props/profiles';

const style = ({ palette }) => ({
  root: {
    width: '100%',
    minWidth: 500,
    boxShadow: 'none',
  },
  modal: {
    width: 600,
  },
  button: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 8,
    marginRight: 8,

    '&:last-child': {
      marginRight: 4,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    overflow: 'visible',
  },
  footer: {
    margin: '0',
    padding: '0 20px 42px 0',
  },
  commentCount: {
    color: palette.primary.main,
    backgroundColor: palette.primary.background,
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 6,
    marginRight: 10,
    padding: '1px 3px 1px 2px',
    borderRadius: 3,
  },
  dateName: {
    color: palette.primary.main,
    fontSize: 15,
    display: 'flex',
    fontWeight: '500',
  },
  commentTitle: {
    fontSize: 24,
    fontWeight: 400,
  },
  commentBody: {
    color: palette.text.primary,
    fontSize: 16,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  userRole_PA: {
    marginLeft: 13,
    marginRight: 22,
    color: palette.info.main,
  },
  userRole_crew: {
    marginLeft: 13,
    marginRight: 22,
    color: palette.warning.main,
  },
  userRole_DH: {
    marginLeft: 13,
    marginRight: 22,
    color: palette.common.black,
  },
  timecardDate: {
    color: palette.info.contrastText,
    backgroundColor: palette.info.main,
    padding: '0 13px 0 13px',
    height: 21,
    fontSize: 15,
    fontWeight: 500,
    borderRadius: 12,
  },
  details: {
    display: 'block',
    backgroundColor: palette.background.paper,
    padding: '24px 16px',
  },
  expansionDetails: {
    overflowY: 'scroll',
    maxHeight: '275px',
    maxWidth: '560px',
    margin: '0 20px',
  },
  header: {
    color: palette.text.secondary,
    paddingLeft: '32px',
    marginTop: 34,
    fontSize: 24,
    fontWeight: 400,
  },
});

/**
 * Re-usable Comment Modal dialog, works with reduxForm.
 */
class CommentModal extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    buttonText: PropTypes.string,
    buttonType: PropTypes.string,
    cancelText: PropTypes.string,
    content: PropTypes.node,
    onModalHide: PropTypes.func.isRequired,
    raisedButton: PropTypes.bool,
    submitting: PropTypes.bool,
    hasSubmitAction: PropTypes.bool,
    title: PropTypes.node,
    onSubmit: PropTypes.func,
    buttonHide: PropTypes.bool,
    customSubmit: PropTypes.bool,
    customOnSubmit: PropTypes.func,
  };

  static defaultProps = {
    buttonText: 'Continue',
    buttonType: 'primary',
    title: 'Please Confirm',
    cancelText: 'Cancel',
    raisedButton: false,
    submitting: false,
    hasSubmitAction: false,
    onSubmit: undefined,
    buttonHide: false,
    customSubmit: false,
    customOnSubmit: undefined,
  };
  constructor(props) {
    super(props);
    this.state = { expanded: false };
  }

  renderHeader() {
    const { classes, title } = this.props;

    return (
      <Typography className={classes.header} variant="h6">
        {title}
      </Typography>
    );
  }

  renderContent() {
    const { classes, content, submitting } = this.props;

    return (
      <ModalContent className={{ root: classes.content }} noDivider>
        {content}
        {submitting && <LinearProgress />}
      </ModalContent>
    );
  }

  renderFooter() {
    const {
      buttonText,
      cancelText,
      classes,
      onModalHide,
      submitting = false,
      hasSubmitAction = false,
      onSubmit,
      footerErrorMsg = null,
      buttonHide = false,
      customSubmit = false,
      customOnSubmit,
    } = this.props;

    return (
      <ModalFooter className={classes.footer}>
        <div>
          {footerErrorMsg && <Message error> {footerErrorMsg} </Message>}
        </div>
        <div>
          <Button
            className={classes.button}
            disabled={submitting}
            variant="outlined"
            color="primary"
            name="cancel"
            onClick={onModalHide}
          >
            {cancelText}
          </Button>
          {!hasSubmitAction && !buttonHide && (
            <Button
              className={classes.button}
              disabled={submitting}
              color="primary"
              type="submit"
              variant="contained"
            >
              {buttonText}
            </Button>
          )}
          {hasSubmitAction && !customSubmit && !buttonHide && (
            <Button
              className={classes.button}
              disabled={submitting}
              color="primary"
              variant="contained"
              onClick={onSubmit}
            >
              {buttonText}
            </Button>
          )}
          {hasSubmitAction && customSubmit && !buttonHide && (
            <Button
              className={classes.button}
              disabled={submitting}
              color="primary"
              variant="contained"
              onClick={customOnSubmit}
            >
              {buttonText}
            </Button>
          )}
        </div>
      </ModalFooter>
    );
  }

  renderComments() {
    const { classes, comments } = this.props;
    let rendering = comments.reduce((render, comment) => {
      const userRole = comment.role;
      render.push(
        <AccordionDetails
          key={`comment${comment.id}`}
          className={classes.details}
        >
          <div className={classes.dateName}>
            <span className={classes.dateName}>
              {formatDateUTC(comment.createdAt, 'MM/DD/YYYY HH:mm:ss')}
              {' - '}
              {comment.userName}
            </span>
            <span
              className={
                userRole === PA
                  ? classes.userRole_PA
                  : userRole === DH
                  ? classes.userRole_DH
                  : classes.userRole_crew
              }
            >
              {ROLE_LABELS_WTC_COMMENTS[userRole] || userRole}
            </span>
            {comment.commentType !== 'timecard' &&
              comment.referenceDate !== '0001-01-01T00:00:00' && (
                <span className={classes.timecardDate}>
                  {formatDateUTC(comment.referenceDate, 'MM/DD/YYYY')}
                </span>
              )}
          </div>
          <Typography className={classes.commentBody}>
            {comment.comment}
          </Typography>
        </AccordionDetails>,
      );
      render.push(<Divider key={`Divider${comment.id}`} />);
      return render;
    }, []);
    rendering.pop(); //Remove last divider
    return rendering;
  }

  render() {
    const { classes, comments } = this.props;
    const toggleExpand = () => {
      this.setState({
        expanded: !this.state.expanded,
      });
    };
    const count =
      comments.length >= 10 ? comments.length : '0' + comments.length;
    return (
      <div className={classes.modal}>
        <Accordion
          className={classes.root}
          expanded={this.state.expanded === false}
        >
          <AccordionSummary
            variant="iconOnRight"
            expandIcon={<ExpandMoreIcon />}
            onClick={toggleExpand}
          >
            <Typography>
              <span className={classes.commentTitle}>Comments</span>
              <span className={classes.commentCount}>{count}</span>
            </Typography>
          </AccordionSummary>

          <div className={classes.expansionDetails}>
            {comments.length ? this.renderComments() : []}
          </div>
        </Accordion>
        {this.renderHeader()}
        {this.renderContent()}
        {this.renderFooter()}
      </div>
    );
  }
}

export default compose(withStyleSheet('CommentModal', style))(CommentModal);
