import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, isDirty } from 'redux-form';
import { compose } from 'utils/helperFunctions';
import { Tabs, Box, styled, Tab as MuiTab } from '@mui/material';
import { CountChip } from '../Shared/styledComponents';
import TimecardTab from './TimecardTab';
import AllowanceTab from './AllowanceTab';
import CommentTab from '../Shared/CommentTab';
import HistoryTab from './HistoryTab';
import { useLocation } from 'react-router-dom';
import * as sel from '../selectors';

import { replace, push } from 'redux-first-history';

import {
  TIMECARD_INCOMPLETE,
  TIMECARD_PENDING_EMP_REVIEW,
} from 'components/Shared/constants';

import { UNSAVED_CHANGES_MODAL } from '../Modals/modalNames';
import { show as showModal } from 'actions/modalDialog';

import { TAB_NAV_MAP, TAB_NAV_MAP_REVERSE } from '../empTimecardUtils';

//This is order of precedence for the tabs
// so if url has comments and allowances, we'll take comments

const formSelector = formValueSelector(sel.FORM_NAME);

const mapState = state => ({
  allowanceCount: sel.getAllowanceCount(state),
  commentCount: sel.getCommentCount(state),
  tcStatus: sel.getTimecardStatus(state),
  prevRejected: !!formSelector(state, 'previouslyRejected'),
  disabledBtns: sel.getDisabledBtns(state),
  isFormDirty: isDirty(sel.FORM_NAME)(state),
  newComment: sel.getNewComment(state),
  loadingComments: sel.getLoading(state, 'comments'),
});

const mapDispatch = dispatch => ({
  onSetNav: url => dispatch(replace(url)),
  onChangeNav: url => dispatch(push(url)),
  onShowUnsavedModal: ({ oldTabName, newTabName }) =>
    dispatch(
      showModal({
        dialog: UNSAVED_CHANGES_MODAL,
        modalParams: { oldTabName, newTabName },
      }),
    ),
});
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tc-tab-panel-${index}`}
      aria-labelledby={`tc-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tc-tab-${index}`,
    'aria-controls': `tc-tab-panel-${index}`,
  };
}

const TabNavBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray.background,
  margin: '12px',
  borderRadius: '12px',
}));

export const Tab = styled(MuiTab)(({ theme }) => ({
  borderRadius: '12px',
  padding: '4px',
  margin: '10px',
  textTransform: 'capitalize',
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.table,
  },
}));

const makeTabLabel = (label, count) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    {label}
    {!!count && <CountChip label={count} />}
  </Box>
);

function TabNav(props) {
  const {
    allowanceCount,
    commentCount,
    tcStatus,
    prevRejected,
    onSetNav,
    onChangeNav,
    disabledBtns,
    isFormDirty,
    newComment,
    onShowUnsavedModal,
    loadingComments,
  } = props;

  const [currentTab, setCurrentTab] = React.useState(0);
  const { disableOnLoading } = disabledBtns;
  const location = useLocation();

  const changeTab = React.useCallback(
    tabName => {
      const oldTabName = TAB_NAV_MAP_REVERSE[currentTab];
      const newTabName = tabName;

      const isTimecard =
        oldTabName === TAB_NAV_MAP_REVERSE[TAB_NAV_MAP.timecard];
      const isComments =
        oldTabName === TAB_NAV_MAP_REVERSE[TAB_NAV_MAP.comments];

      if (isFormDirty && isTimecard) {
        onShowUnsavedModal({ newTabName, oldTabName });
      } else if (isComments && !!newComment) {
        onShowUnsavedModal({ newTabName, oldTabName });
      } else {
        const url = `${location.pathname}?${newTabName}`;
        onSetNav(url);
        setCurrentTab(TAB_NAV_MAP[newTabName]);
      }
    },
    [
      currentTab,
      isFormDirty,
      location.pathname,
      newComment,
      onSetNav,
      onShowUnsavedModal,
    ],
  );

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const path = location.pathname;

    let initTab,
      didUrlInit = false;

    for (const key in TAB_NAV_MAP) {
      if (queryParams.has(key)) {
        initTab = TAB_NAV_MAP[key];
        didUrlInit = true;
        break;
      }
    }
    if (didUrlInit) {
      setCurrentTab(initTab);
    } else {
      setCurrentTab(TAB_NAV_MAP.timecard);
      const url = `${path}?${TAB_NAV_MAP_REVERSE[TAB_NAV_MAP.timecard]}`;
      onSetNav(url);
    }
  }, [location.pathname, location.search, onSetNav]);

  const commentsLoadedRef = React.useRef(null);
  const checkedForCommentNavRef = React.useRef(false);

  React.useEffect(() => {
    if (loadingComments) {
      //currently loading
      commentsLoadedRef.current = false;
    }
    if (!loadingComments && commentsLoadedRef.current === false) {
      //were loading, now done
      commentsLoadedRef.current = true;
    }
  }, [loadingComments]);

  React.useEffect(() => {
    if (commentsLoadedRef.current && !checkedForCommentNavRef.current) {
      if (
        commentCount > 0 &&
        ((tcStatus === TIMECARD_INCOMPLETE && prevRejected) ||
          tcStatus === TIMECARD_PENDING_EMP_REVIEW)
      ) {
        const tabNum = TAB_NAV_MAP.comments;
        const tabName = TAB_NAV_MAP_REVERSE[tabNum];
        const url = `${location.pathname}?${tabName}`;
        onChangeNav(url);
      }
      checkedForCommentNavRef.current = true;
    }
  }, [commentCount, location.pathname, onChangeNav, prevRejected, tcStatus]);

  const handleChange = (e, newValue) => {
    const tabName = TAB_NAV_MAP_REVERSE[newValue];
    changeTab(tabName);
  };

  return (
    <TabNavBox>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          aria-label="timecard tabs"
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
        >
          <Tab disabled={disableOnLoading} label="Timecard" {...a11yProps(0)} />
          <Tab
            disabled={disableOnLoading}
            label={makeTabLabel('Allowances', allowanceCount)}
            {...a11yProps(1)}
          />
          <Tab
            disabled={disableOnLoading}
            label={makeTabLabel('Comments', commentCount)}
            {...a11yProps(2)}
          />
          <Tab disabled={disableOnLoading} label="History" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index={TAB_NAV_MAP.timecard}>
        <TimecardTab />
      </TabPanel>
      <TabPanel value={currentTab} index={TAB_NAV_MAP.allowances}>
        <AllowanceTab />
      </TabPanel>
      <TabPanel value={currentTab} index={TAB_NAV_MAP.comments}>
        <CommentTab />
      </TabPanel>
      <TabPanel value={currentTab} index={TAB_NAV_MAP.history}>
        <HistoryTab />
      </TabPanel>
    </TabNavBox>
  );
}

TabNav.propTypes = {
  allowanceCount: PropTypes.number.isRequired,
  commentCount: PropTypes.number.isRequired,
  tcStatus: PropTypes.string,
  prevRejected: PropTypes.bool.isRequired,
  onSetNav: PropTypes.func.isRequired,
  onChangeNav: PropTypes.func.isRequired,
  disabledBtns: PropTypes.object.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  newComment: PropTypes.string,
  onShowUnsavedModal: PropTypes.func.isRequired,
};

export default compose(connect(mapState, mapDispatch))(TabNav);
