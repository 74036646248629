import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Typography } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
// actions
import { removeDepartment, fetchDeptStats } from 'actions/reviewFlows';
// selectors
import {
  getEditingDepartment,
  getDepartmentStats,
  getLoading,
} from 'selectors/reviewFlows';

const onSubmit = (values, dispatch) => {
  const { reviewFlowId, departmentId } = values;
  dispatch(removeDepartment({ reviewFlowId, departmentId }));
};

const mapStateToProps = state => {
  const department = getEditingDepartment(state);

  return {
    initialValues: department,
    department: department,
    stats: getDepartmentStats(state, department.id),
    isLoading: getLoading(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchData: () => dispatch(fetchDeptStats()),
});

const style = () => ({
  content: {
    maxWidth: 500,
  },
});

class RemoveDepartment extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reviewFlowId: PropTypes.string,
    onFetchData: PropTypes.func.isRequired,
    stats: PropTypes.object,
  };

  static defaultProps = {
    reviewFlowId: '',
  };

  componentDidMount() {
    const { department, onFetchData } = this.props;
    if (department.id && onFetchData) {
      onFetchData();
    }
  }

  render() {
    const { classes, handleSubmit, department, stats, isLoading } = this.props;
    const hasAnyBatches = stats && stats.batches && stats.batches.total > 0;

    const contentText = !hasAnyBatches
      ? 'Please be careful, this action can not be undone. All employees in this department will be placed in the unassigned department.'
      : 'This Department contains timecards being processed and cannot be removed.';

    const content = (
      <div className={classes.content}>
        <Typography color="inherit">{contentText}</Typography>
      </div>
    );
    const title = (
      <Typography variant="h6" color="inherit">
        {`Delete ${department.name} Department`}
      </Typography>
    );

    return (
      <form onSubmit={handleSubmit}>
        <Field
          component="input"
          name="reviewFlowId"
          type="hidden"
          value={department.reviewFlowId}
        />
        <Field
          component="input"
          name="departmentId"
          type="hidden"
          value={department.id}
        />
        {!isLoading && (
          <ConfirmModal
            content={content}
            title={title}
            buttonText="Remove"
            buttonType="secondary"
            buttonHide={hasAnyBatches}
            raisedButton={true}
            {...this.props}
          />
        )}
      </form>
    );
  }
}

export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: 'removeDepartment', maxWidth: 'md' }),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'DeleteDepartment',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnChange: true,
    onSubmit,
  }),
)(RemoveDepartment);
