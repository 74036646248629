import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material';

const Max100 = ({ className }) => {
  const theme = useTheme();
  const { palette } = theme;

  const background = palette.primary.background;
  const primary = palette.primary.main;
  const highlight = palette.primary['+7'];

  return (
    <svg
      className={className}
      width="195"
      height="197"
      viewBox="0 0 195 197"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="97.5" cy="99.5" r="97.5" fill={`${background}`} />
      <ellipse cx="96.5" cy="166" rx="58.5" ry="3" fill={`${highlight}`} />
      <path
        d="M33.843 29.7442C33.3954 26.1631 36.1877 23 39.7967 23H156.044C159.708 23 162.517 26.2562 161.979 29.881L144.76 145.881C144.323 148.822 141.798 151 138.825 151H54.2967C51.2708 151 48.7183 148.747 48.343 145.744L33.843 29.7442Z"
        fill="white"
        stroke={`${primary}`}
        strokeWidth="5"
      />
      <path
        d="M65.1293 38.4436L53.4232 14.8021C52.6531 13.2466 53.3607 11.3807 54.9955 10.7978C61.7321 8.39588 78.9822 3 98 3C117.18 3 134.562 8.48845 141.175 10.8587C142.744 11.4211 143.469 13.1784 142.818 14.7126L133.012 37.8188C132.171 39.7996 129.449 40.3944 127.515 39.4509C114.86 33.2773 83.0693 33.2714 70.1763 39.8181C68.4015 40.7193 66.0125 40.2274 65.1293 38.4436Z"
        fill="white"
        stroke={`${primary}`}
        strokeWidth="5"
      />
      <path
        d="M127.748 11L118.482 35.9486L112.12 33.4186L127.748 11Z"
        fill={`${primary}`}
      />
      <rect
        x="96.1138"
        y="3.22949"
        width="2"
        height="7"
        transform="rotate(1.45154 96.1138 3.22949)"
        fill={`${primary}`}
      />
      <rect
        x="90.1133"
        y="3.17578"
        width="2"
        height="7"
        transform="rotate(-3.45324 90.1133 3.17578)"
        fill={`${primary}`}
      />
      <rect
        x="83.083"
        y="4.28149"
        width="2"
        height="7"
        transform="rotate(-9.55354 83.083 4.28149)"
        fill={`${primary}`}
      />
      <rect
        x="76.0557"
        y="5.26587"
        width="2"
        height="7"
        transform="rotate(-14.327 76.0557 5.26587)"
        fill={`${primary}`}
      />
      <path
        d="M68.0306 111.551H60.7461V75.104L50 78.9077V72.5162L67.0975 66H68.0306V111.551Z"
        fill={`${primary}`}
      />
      <path
        d="M143 92.8245C143 99.3312 141.786 104.242 139.357 107.558C136.95 110.853 133.276 112.5 128.337 112.5C123.48 112.5 119.817 110.894 117.348 107.682C114.899 104.45 113.643 99.6731 113.582 93.3529V85.5201C113.582 79.0134 114.785 74.1334 117.193 70.88C119.622 67.6267 123.316 66 128.275 66C133.194 66 136.857 67.5852 139.265 70.7557C141.693 73.9261 142.938 78.6715 143 84.9916V92.8245ZM135.499 84.37C135.499 80.122 134.922 77.0137 133.77 75.0451C132.618 73.0558 130.786 72.0612 128.275 72.0612C125.826 72.0612 124.026 73.004 122.873 74.8897C121.721 76.7547 121.114 79.6765 121.052 83.6551V93.9124C121.052 98.1397 121.638 101.289 122.811 103.362C123.984 105.434 125.826 106.47 128.337 106.47C130.745 106.47 132.525 105.517 133.677 103.61C134.83 101.683 135.437 98.6785 135.499 94.5963V84.37Z"
        fill={`${primary}`}
      />
      <path
        d="M107 92.8245C107 99.3312 105.786 104.242 103.357 107.558C100.95 110.853 97.2761 112.5 92.337 112.5C87.4803 112.5 83.8171 110.894 81.3476 107.682C78.8986 104.45 77.6433 99.6731 77.5815 93.3529V85.5201C77.5815 79.0134 78.7854 74.1334 81.1932 70.88C83.6216 67.6267 87.3156 66 92.2753 66C97.1938 66 100.857 67.5852 103.265 70.7557C105.693 73.9261 106.938 78.6715 107 84.9916V92.8245ZM99.4987 84.37C99.4987 80.122 98.9225 77.0137 97.77 75.0451C96.6176 73.0558 94.786 72.0612 92.2753 72.0612C89.8263 72.0612 88.0256 73.004 86.8732 74.8897C85.7207 76.7547 85.1136 79.6765 85.0519 83.6551V93.9124C85.0519 98.1397 85.6384 101.289 86.8114 103.362C87.9845 105.434 89.8263 106.47 92.337 106.47C94.7448 106.47 96.525 105.517 97.6774 103.61C98.8299 101.683 99.437 98.6785 99.4987 94.5963V84.37Z"
        fill={`${primary}`}
      />
      <path
        d="M74.2834 117.938L79.2053 131.016L84.1155 117.938H87.9475V135H84.9944V129.375L85.2873 121.852L80.2483 135H78.1272L73.0998 121.863L73.3928 129.375V135H70.4397V117.938H74.2834ZM101.385 131.027H94.7755L93.3927 135H90.3106L96.7559 117.938H99.4161L105.873 135H102.779L101.385 131.027ZM95.6075 128.637H100.553L98.0802 121.559L95.6075 128.637ZM113.85 124.102L117.435 117.938H120.857L115.701 126.398L120.975 135H117.518L113.85 128.742L110.17 135H106.725L112.01 126.398L106.842 117.938H110.264L113.85 124.102ZM124.826 132C125.357 132 125.767 132.152 126.056 132.457C126.345 132.754 126.49 133.129 126.49 133.582C126.49 134.027 126.345 134.398 126.056 134.695C125.767 134.984 125.357 135.129 124.826 135.129C124.318 135.129 123.916 134.984 123.619 134.695C123.322 134.406 123.174 134.035 123.174 133.582C123.174 133.129 123.318 132.754 123.607 132.457C123.896 132.152 124.302 132 124.826 132Z"
        fill={`${primary}`}
      />
    </svg>
  );
};

Max100.propTypes = { className: PropTypes.string };

export default Max100;
