import { takeEvery, call, put, select, all } from 'redux-saga/effects';
// import camelCase from 'camelcase-keys';
import { showAlert } from 'actions/alert';
import { getFlags as list } from 'selectors/flags';
import * as actions from 'actions/flag';
import { flagsReady } from 'actions/appReady';

export function* fetch(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const [flags] = yield all([call(api.flags.list)]);
    const allowance_type = flags?.find(item => item.name === 'allowance_types');
    if (allowance_type) {
      allowance_type.enabled = JSON.parse(allowance_type.enabled);
    }
    yield put(actions.store({ flags }));
  } catch (e) {
    yield put(showAlert());
    debug(e);
    yield put(actions.store({ flags: [] }));
  } finally {
    yield put(flagsReady());
    yield put(actions.loading({ loading: false }));
  }
}

export function* update(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const flags = yield select(list);
    yield call(api.flags.update, { flags });
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
    debug(e);
  }
}

export default function* flagsFlow({ api, debug }) {
  yield all([
    takeEvery(`${actions.fetch}`, fetch, api, debug),
    takeEvery(`${actions.update}`, update, api, debug),
  ]);
}
