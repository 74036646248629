import React from 'react';
import SuperAdmin from 'containers/Admin/SuperAdmin';
import { DefaultLayout, Header } from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    admin
    authenticated
    key="/admin/superadmin"
    path="/admin/superadmin"
    layout={DefaultLayout}
    headers={[Header]}
    component={SuperAdmin}
    title="Super Admin"
  />
);
