import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Button, Box, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { push } from 'redux-first-history';
import { currentUser as activeUser, getSessionUser } from 'selectors/session';
import { ROLE_LABELS } from 'components/props/profiles';

import Incognito from 'components/images/Incognito';

import { useIsMobile } from 'utils/customHooks';

const useStyles = makeStyles(({ palette }) => ({
  ImpersonateAlert: {
    backgroundColor: palette.error.main,
    color: palette.error.contrastText,
    padding: '4px 10px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: 1,
    borderRadius: '8px',
  },
  icon: {
    height: '25px',
    width: '25px',
    paddingRight: '5px',
  },
  empInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const mapState = state => ({
  currentUser: getSessionUser(state),
  activeUser: activeUser(state),
});

const mapDispatch = dispatch => ({
  onRemoveImpersonation: () => {
    dispatch(push('/removeImpersonate'));
  },
});

const ImpersonateAlert = props => {
  const classes = useStyles();
  const { currentUser, activeUser, onRemoveImpersonation } = props;

  const name = currentUser.fullName;
  const role = ROLE_LABELS[activeUser.role];

  const isMobile = useIsMobile();

  if (!currentUser || !currentUser.impersonating || currentUser.isAdmin) {
    return null;
  }

  return (
    <Tooltip placement={'top'} title="Impersonating, click to return to admin">
      <Button
        onClick={onRemoveImpersonation}
        className={classes.ImpersonateAlert}
      >
        <Incognito className={classes.icon} />
        {!isMobile && (
          <Box className={classes.empInfo}>
            <Box>{name}</Box>
            <Box>{role}</Box>
          </Box>
        )}
      </Button>
    </Tooltip>
  );
};

ImpersonateAlert.propTypes = {
  currentUser: PropTypes.object,
  activeUser: PropTypes.object,
  onRemoveImpersonation: PropTypes.func,
};

export default compose(connect(mapState, mapDispatch))(ImpersonateAlert);
