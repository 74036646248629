import { connect } from 'react-redux';
// components
import { Departments } from 'components/Admin/Projects/DepartmentApprovers';
// actions
import { show as showModal } from 'actions/modalDialog';
import { editingDepartment } from 'actions/reviewFlows';
// selectors
import { getUsers } from 'selectors/users';
import { getDepartmentsWithHeads } from 'selectors/reviewFlows';

const mapStateToProps = (state, ownProps) => ({
  departments: getDepartmentsWithHeads(state, ownProps.reviewFlowId),
  users: getUsers(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onEditDepartment: departmentId => {
    const { reviewFlowId } = ownProps;
    dispatch(editingDepartment({ reviewFlowId, departmentId }));
    dispatch(showModal({ dialog: 'editDepartment' }));
  },
  onAddDepartment: () => {
    const { reviewFlowId } = ownProps;
    const departmentId = null;
    dispatch(editingDepartment({ reviewFlowId, departmentId }));
    dispatch(showModal({ dialog: 'editDepartment' }));
  },
  onDeleteDepartment: departmentId => {
    const { reviewFlowId } = ownProps;
    dispatch(editingDepartment({ reviewFlowId, departmentId }));
    dispatch(showModal({ dialog: 'removeDepartment' }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Departments);
