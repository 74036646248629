import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

// eslint-disable-next-line import/no-anonymous-default-export
export default (name, style) => {
  return BaseComponent => {
    class StyledComponent extends Component {
      static displayName = `Styled${BaseComponent.name}`;

      static propTypes = {
        classes: PropTypes.object.isRequired,
      };

      componentDidMount() {}

      render() {
        return <BaseComponent {...this.props} />;
      }
    }

    return withStyles(style, { name })(StyledComponent);
  };
};
