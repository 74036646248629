import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaUserCircle as UserCircle } from 'react-icons/fa';
import { withStyleSheet } from 'shared/theme';

import { NavLink } from 'react-router-dom';
import { Typography, Button } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const style = theme => ({
  root: {
    // cursor: 'pointer',
  },

  anchor: {
    fontSize: 15,
    textAlign: 'center',
    verticalAlign: 'middle',
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 3,
  },
  backButton: {
    color: 'white',
    marginBottom: 25,
  },
  userCircle: {
    verticalAlign: 'middle',
    paddingRight: 8,
    fontSize: 42,
    float: 'left',
  },
  profileNav: {
    color: 'white',
    paddingLeft: 0,
    marginTop: 25,
    fontWeight: 500,
    fontSize: 14,
  },
  togglerText: {
    verticalAlign: 'middle',
    padding: 'inherit',
    '& > *': {
      overflow: 'hidden',
    },
  },
  closeBtn: {
    extend: 'togglerText',
    float: 'right',
    textAlign: 'right',
  },

  [theme.breakpoints.down('lg')]: {
    root: {
      paddingLeft: 10,
      padding: 5,
    },
    anchor: {
      padding: 8,
    },
    closeBtn: {
      padding: 8,
    },
  },
});

class UserAnchor extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    fullName: PropTypes.string.isRequired,
    showCloseBtn: PropTypes.bool,
    onClose: PropTypes.func,
    backButton: PropTypes.func,
  };

  static defaultProps = {
    showCloseBtn: false,
    onClose: undefined,
    backButton: undefined,
  };

  render() {
    const { email, classes, fullName, backButton } = this.props;

    return (
      <div className={classes.root}>
        <Button className={classes.backButton} onClick={backButton}>
          <ArrowBackIosIcon />
          Back
        </Button>

        <div className={classes.togglerText}>
          <UserCircle className={classes.userCircle} />
          <Typography align={'left'} noWrap={true} variant="body2">
            {fullName}
          </Typography>
          <Typography align={'left'} noWrap={true} variant="body2">
            {email}
          </Typography>
          <NavLink className={classes.profileNav} to="/me/profile">
            Edit Profile
          </NavLink>
        </div>
      </div>
    );
  }
}

export default withStyleSheet('UserAnchor', style)(UserAnchor);
