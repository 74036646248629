import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import GlobalStyleWrapper from './GlobalStyleWrapper';
import theme from './theme';
import { LicenseInfo } from '@mui/x-license';
import { keys } from 'constants/config/apiServer';

LicenseInfo.setLicenseKey(keys.muiProLicenseKey);

const ThemeProviderHPlus = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <GlobalStyleWrapper>{children}</GlobalStyleWrapper>
    </ThemeProvider>
  </StyledEngineProvider>
);

ThemeProviderHPlus.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProviderHPlus;
