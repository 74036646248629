import React from 'react';
import clsx from 'clsx';
import { PropTypes } from 'prop-types';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Chip, Tooltip, Box, IconButton, Badge } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
//icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BaseTimecard from 'components/images/BaseTimecard';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

import HPlus16 from 'components/images/Hplus16';

import { push } from 'redux-first-history';
import { show as showModal } from 'actions/modalDialog';

import { hasReviewerRole, activeUserProjectRoles } from 'selectors/session';
import { getHasRoleIA } from 'selectors/session';
import { getStudioPlusEnabled } from 'selectors/project';

import { searchDateFormat, DATE_COLUMNS } from './searchUtils';

let _palette;
const useStyles = makeStyles(({ palette }) => {
  _palette = palette;
  return {
    root: {
      zIndex: 0,
      height: 30,
      '&:hover': {
        backgroundColor: palette.action.hover,
      },
    },
    odd: {
      backgroundColor: palette.primary.table,
    },
    even: {
      backgroundColor: palette.background.paper,
    },
    tableCell: {
      textAlign: 'start',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 200,
    },
    numberCell: { paddingLeft: 15 },
    linkCell: {
      textDecoration: 'underline',
      color: palette.misc.link,
      fontWeight: 500,
      '&:hover': {
        backgroundColor: palette.gray['+9'],
        cursor: 'pointer',
      },
    },
    customTooltip: {
      fontSize: 12,
      height: 30,
      maxWidth: 500,
      paddingTop: 15,
    },
    futureReleaseIcon: {
      marginRight: '8px !important',
      color: `${palette.gray['+2']} !important`,
    },
    timecardIcon: {
      height: 16,
      width: 16,
      marginLeft: 4,
      marginRight: 4,
    },
    hPlusIcon: {
      minHeight: 16,
      minWidth: 16,
      marginLeft: 4,
      marginRight: 4,
    },
  };
});

function colorForStatus(status) {
  switch (status) {
    case 'Waiting Approval':
      return _palette.button.lightRed.color;
    case 'Future Release':
    case 'Invoice Approved':
    case 'In Progress':
    case 'Processed':
    case 'Resubmitted':
    case 'C&C Review':
      return _palette.button.yellow.color;
    case 'Edits':
      return _palette.button.lightGreen.color;
    case 'Paid':
    case 'Hold':
      return _palette.button.gray.color;
    case 'Draft':
      return _palette.button.lightRed.color;
    case 'Revisions':
      return _palette.button.orange.color;
    default:
      return _palette.button.gray.color;
  }
}
function backgroundForStatus(status) {
  switch (status) {
    case 'Waiting Approval':
      return _palette.button.lightRed.background;
    case 'Future Release':
    case 'Invoice Approved':
    case 'In Progress':
    case 'Processed':
    case 'Resubmitted':
    case 'C&C Review':
      return _palette.button.yellow.background;
    case 'Edits':
      return _palette.button.lightGreen.background;
    case 'Paid':
    case 'Hold':
      return _palette.button.gray.background;
    case 'Draft':
      return _palette.button.lightRed.background;
    case 'Revisions':
      return _palette.button.orange.background;
    default:
      return _palette.button.gray.background;
  }
}
function newStatusLabel(status) {
  switch (status) {
    case 'Waiting Approval':
      return 'Pending Re-Approval';
    case 'In Progress':
      return 'Submitted to C&C';
    case 'Processed':
      return 'Processing';
    case 'Draft':
      return 'Unsubmitted';
    default:
      return status;
  }
}

const mapState = state => ({
  activeUserProjectRoles: activeUserProjectRoles(state),
  hasRoleIA: getHasRoleIA(state),
  studioPlusEnabled: getStudioPlusEnabled(state),
});

const mapDispatch = dispatch => ({
  onNavToEdits: (projectId, htgInvoiceId) => {
    dispatch(push(`/projects/${projectId}/digital-edits/${htgInvoiceId}`));
  },
  setShowComments: (invoiceId, readOnly) => {
    dispatch(
      showModal({
        dialog: 'DigitalEditsCommentsLite',
        modalParams: { invoiceId, readOnly },
      }),
    );
  },
});

const Row = props => {
  const classes = useStyles();
  const { projectId } = useParams();

  const {
    prepareRow,
    row,
    rowIndex,
    onClickInvoice,
    activeUserProjectRoles,
    hasRoleIA,
    studioPlusEnabled,
    onNavToEdits,
    setShowComments,
  } = props;

  const {
    arrivalMethod,
    digitalEditEligible,
    hasSystemLocationAccess,
    htgInvoiceId,
    id: invoiceId,
    totalTimecards,
    hasComments = false,
    status,
  } = row?.original || {};

  const isDigitalEditEligible = digitalEditEligible === 'Y';

  const canNavToDigitalEdits =
    studioPlusEnabled &&
    hasRoleIA &&
    isDigitalEditEligible &&
    hasSystemLocationAccess;

  const navToDigitalEditsClick = () => {
    if (canNavToDigitalEdits) {
      onNavToEdits(projectId, htgInvoiceId);
    }
  };

  const isHPlusInvoice = arrivalMethod === 'H';

  const canNavToTimecards =
    activeUserProjectRoles.some(data => hasReviewerRole(data)) &&
    isHPlusInvoice;
  const navToTimecardsClick = () => {
    if (canNavToTimecards) {
      onClickInvoice({
        invoiceId: invoiceId,
        selectedInvoiceCount: totalTimecards,
      });
    }
  };

  prepareRow(row);

  return (
    <tr
      {...row.getRowProps()}
      className={clsx(classes.root, {
        [classes.odd]: rowIndex % 2 === 0,
        [classes.even]: rowIndex % 2 === 1,
      })}
    >
      {row.cells.map(cell => {
        if (cell.column.id === 'status') {
          return (
            <td cell={cell} key={cell.column.id} className={classes.tableCell}>
              <Chip
                label={newStatusLabel(cell.value)}
                style={{
                  backgroundColor: backgroundForStatus(cell.row.values.status),
                  color: colorForStatus(cell.row.values.status),
                  borderRadius: '12px',
                }}
                deleteIcon={
                  <Tooltip
                    title={`Invoice will be released on ${
                      searchDateFormat(cell?.row?.values?.futureReleaseDate) ||
                      ''
                    }`}
                    placement="top"
                  >
                    <InfoOutlinedIcon className={classes.futureReleaseIcon} />
                  </Tooltip>
                }
                onDelete={
                  cell.value === 'Future Release' ? () => {} : undefined
                }
              />
            </td>
          );
        } else if (cell.column.id === 'invoiceId') {
          return (
            <td
              cell={cell}
              key={cell.column.id}
              className={clsx(classes.nonLinkCell, {
                [classes.linkCell]: canNavToDigitalEdits,
              })}
              onClick={navToDigitalEditsClick}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  // mr: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '85%',
                    maxWidth: 85,
                  }}
                >
                  {isHPlusInvoice ? (
                    <HPlus16 className={classes.hPlusIcon} />
                  ) : (
                    <Box className={classes.hPlusIcon} />
                  )}
                  {cell.value}
                </Box>
              </Box>
            </td>
          );
        } else if (cell.column.id === 'totalTimecards') {
          return (
            <td
              cell={cell}
              key={cell.column.id}
              className={clsx(
                'PENDO_invoiceTimecardsCount',
                classes.nonLinkCell,
                classes.numberCell,
                {
                  [classes.linkCell]: canNavToTimecards,
                },
              )}
              onClick={navToTimecardsClick}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {cell.value}
                {canNavToTimecards && (
                  <BaseTimecard className={classes.timecardIcon} />
                )}
              </Box>
            </td>
          );
        } else if (DATE_COLUMNS.includes(cell.column.id)) {
          let date = searchDateFormat(cell.value);
          return (
            <td cell={cell} key={cell.column.id} className={classes.tableCell}>
              {date}
            </td>
          );
        } else if (cell.column.id === 'numOfUnapprvdTcs') {
          return (
            <td cell={cell} key={cell.column.id} className={classes.numberCell}>
              {cell.value}
            </td>
          );
        } else if (cell.column.id === 'actions') {
          return (
            <td cell={cell} key={cell.column.id} className={classes.tableCell}>
              {canNavToDigitalEdits && (
                <IconButton
                  sx={{ padding: '4px', color: 'primary.main' }}
                  className="PENDO_invoiceCommentIcon"
                  onClick={() => {
                    const readOnly = status !== 'Revisions';
                    setShowComments(htgInvoiceId, readOnly);
                  }}
                >
                  <Badge invisible={!hasComments} color="error" variant="dot">
                    <ChatBubbleIcon sx={{ fontSize: '1.3rem' }} />
                  </Badge>
                </IconButton>
              )}
            </td>
          );
        } else if (
          cell.column.id === 'invoiceDescription' &&
          cell.value?.length > 35
        ) {
          return (
            <td cell={cell} key={cell.column.id} className={classes.tableCell}>
              <Tooltip
                title={cell.value}
                placement="top"
                arrow
                classes={{ tooltip: classes.customTooltip }}
              >
                <span>{cell.value}</span>
              </Tooltip>
            </td>
          );
        } else {
          return (
            <td cell={cell} key={cell.column.id} className={classes.tableCell}>
              {cell.value}
            </td>
          );
        }
      })}
    </tr>
  );
};

Row.propTypes = {
  prepareRow: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  onClickInvoice: PropTypes.func.isRequired,
  activeUserProjectRoles: PropTypes.array.isRequired,
  hasRoleIA: PropTypes.bool.isRequired,
  studioPlusEnabled: PropTypes.bool.isRequired,
  onNavToEdits: PropTypes.func.isRequired,
  setShowComments: PropTypes.func.isRequired,
};

export default compose(connect(mapState, mapDispatch))(Row);
