import React from 'react';
import { connect } from 'react-redux';
import { canadianLabel, compose, isRegionCanada } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import {
  Box,
  Switch,
  FormControlLabel,
  IconButton,
  Checkbox,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
//redux
import * as actions from '../actions';
import { hide as closeModal } from 'actions/modalDialog';

import * as sel from '../selectors';

//local components
import {
  TitleBox,
  HeaderText,
  ContentBox,
  FooterBox,
  FooterButton,
} from '../Shared/styledComponents';

import withModalDialog from 'decorators/withModalDialog';
import { getCurrentProject } from 'selectors/project';

import { SETTINGS_DIALOG } from '../Modals/modalNames';
import { EMP_ADDITIONAL_FIELD_DESC_MAP } from '../empTimecardUtils';
const mapState = state => ({
  useMilitaryInit: sel.getUseMilitary(state),
  additionalFields: sel.getAdditionalFields(state),
  project: getCurrentProject(state),
});

const mapDispatch = dispatch => ({
  onSave: ({ useMilitary }) =>
    dispatch(actions.saveMilitaryTime({ useMilitary })),
  onCloseModal: () => dispatch(closeModal({ dialog: SETTINGS_DIALOG })),
  onUpdateAdditionalFields: fields =>
    dispatch(actions.updateAdditionalFields({ fields })),
});

const SettingsModal = props => {
  const {
    onSave,
    onCloseModal,
    useMilitaryInit,
    additionalFields,
    onUpdateAdditionalFields,
    project,
  } = props;

  const isCanada = isRegionCanada(project.region);
  const [useMilitary, setUseMilitary] = React.useState(useMilitaryInit);

  const [localAdditional, setLocalAdditional] =
    React.useState(additionalFields);

  React.useEffect(() => {
    if (isCanada) {
      const fields = additionalFields.map(field => {
        if (field.accessor === 'state') {
          return { ...field, label: canadianLabel(field.label) };
        }
        return field;
      });
      setLocalAdditional(fields);
    }
  }, [additionalFields, isCanada]);

  const handleUpdate = React.useCallback((accessor, checked) => {
    setLocalAdditional(prev => {
      const newLocal = prev.map(field => {
        if (field.accessor === accessor) {
          return { ...field, display: checked ? 'show' : 'additional' };
        }
        return field;
      });
      return newLocal;
    });
  }, []);

  const handleSave = React.useCallback(() => {
    if (useMilitary !== useMilitaryInit) {
      onSave({ useMilitary });
    }
    onUpdateAdditionalFields(localAdditional);
    onCloseModal();
  }, [
    onSave,
    useMilitary,
    onUpdateAdditionalFields,
    localAdditional,
    onCloseModal,
    useMilitaryInit,
  ]);
  const showDescription = accessor => {
    setLocalAdditional(prev => {
      const newLocal = prev.map(field => {
        if (field.accessor === accessor) {
          return {
            ...field,
            isDescriptionVisible: !field.isDescriptionVisible,
          };
        }
        return field;
      });
      return newLocal;
    });
  };

  return (
    <Box>
      <TitleBox>
        <Box>Timecard Settings</Box>
        <IconButton onClick={onCloseModal}>
          <CloseIcon />
        </IconButton>
      </TitleBox>
      <ContentBox>
        <HeaderText>Time Preference</HeaderText>
        <FormControlLabel
          control={
            <Switch
              checked={useMilitary}
              onChange={e => {
                setUseMilitary(e.target.checked);
              }}
            />
          }
          label={
            <Box sx={{ fontSize: '14px' }}>
              <Box sx={{ fontWeight: 'bold' }}>Military Time</Box>
              <Box sx={{ color: 'text.secondary' }}>
                Turn off to switch to standard time
              </Box>
            </Box>
          }
        />
        <HeaderText>Timecard Add-Ons</HeaderText>
        {localAdditional.map(
          ({
            label,
            pairLabel,
            accessor,
            display,
            description,
            isDescriptionVisible,
          }) => {
            const checked = display === 'show';
            let displayLabel = pairLabel ? pairLabel : label;
            const isLabelIncluded =
              Object.keys(EMP_ADDITIONAL_FIELD_DESC_MAP)?.includes(
                displayLabel,
              ) || false;
            if (isLabelIncluded) {
              displayLabel = EMP_ADDITIONAL_FIELD_DESC_MAP[displayLabel];
            }
            return (
              <Box key={accessor}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <FormControlLabel
                    key={displayLabel}
                    control={
                      <Checkbox
                        name={displayLabel}
                        checked={checked}
                        onChange={(e, value) => {
                          handleUpdate(accessor, !checked);
                        }}
                        sx={{ p: 0, px: 1 }}
                      />
                    }
                    label={<Box sx={{ fontSize: '14px' }}>{displayLabel}</Box>}
                  />
                  <Box
                    className={`PENDO_${accessor}`}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => showDescription(accessor)}
                  >
                    <HelpOutlineSharpIcon
                      sx={{
                        color: isDescriptionVisible
                          ? 'primary.main'
                          : 'primary.text',
                      }}
                    />
                  </Box>
                </Box>
                {isDescriptionVisible && (
                  <Box
                    sx={{
                      fontSize: '14px',
                      color: 'text.secondary',
                      padding: '5px 10px 5px 30px',
                      width: '250px',
                    }}
                  >
                    {description}
                  </Box>
                )}
              </Box>
            );
          },
        )}
      </ContentBox>
      <FooterBox
        sx={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'background.paper',
        }}
      >
        <FooterButton variant="outlined" onClick={onCloseModal}>
          Cancel
        </FooterButton>
        <FooterButton onClick={handleSave}>Save</FooterButton>
      </FooterBox>
    </Box>
  );
};

SettingsModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  useMilitaryInit: PropTypes.bool.isRequired,
  additionalFields: PropTypes.array.isRequired,
  onUpdateAdditionalFields: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};

export default compose(
  withModalDialog({
    dialog: SETTINGS_DIALOG,
    maxWidth: 'md',
    roundedCorners: true,
  }),
  connect(mapState, mapDispatch),
)(SettingsModal);
