import { buildUrl } from '../api';

const URL_OCCUPATION_CODES = '/occupationCodes';

const URL_SEARCH_BY_TYPE = '/names/:type';
const URL_PROJECT_SEARCH_BY_TYPE =
  '/projects/:projectId/timecards/:timecardId/:type';
const URL_TIMECARD = '/projects/:projectId/timecards/:timecardEntryHeaderId';
const URL_TIMECARD_WTC =
  '/projects/:projectId/timecards/:timecardEntryHeaderId/wtc';
const URL_TIMECARD_SAVE =
  '/projects/:projectId/timecards/:timecardEntryHeaderId/wtc/save';
const URL_BREAKDOWN =
  '/projects/:projectId/timecards/:timecardEntryHeaderId/broken/tcver';
const URL_COMMENTS =
  '/projects/:projectId/timecards/:timecardEntryHeaderId/comments';
const URL_PAIDHOURS =
  '/projects/:projectId/timecards/:timecardEntryHeaderId/:region/paidhours';
const URL_RESUBMIT =
  '/projects/:projectId/timecards/:timecardEntryHeaderId/resubmit';
const URL_RECALL =
  '/projects/:projectId/timecards/:timecardEntryHeaderId/recall';
const URL_ADDCOMMENT =
  '/projects/:projectId/timecards/:timecardEntryHeaderId/addComment';
const URL_TIMECARD_HOURSPLUS =
  '/projects/:projectId/timecards/:timecardEntryHeaderId/hoursplus';

const URL_EPISODES = '/projects/:projectId/episodes';
const URL_NEXT_APPROVERS =
  '/projects/:projectId/timecards/:timecardId/nextApprovers';
const URL_CURRENT_APPROVERS =
  '/projects/:projectId/review/:timecardId/currentApprovers';

const URL_REVIEW_BATCH_BY_WORKSIGHTID =
  '/projects/:projectId/review/batch/:worksightId';

const URL_TIMECARDS_FOR_DRAWER =
  '/projects/:projectId/timecards/search/wtc?drawerTimecards';
const URL_PAYCODE_BY_ID = '/paycode/:paycodeId';

const URL_TIMECARD_CALC =
  '/projects/:projectId/timecards/:timecardEntryHeaderId/wtc/calculate';

const URL_BULK_ALLOWANCE = `/projects/:projectId/timecards/:timecardId/allowances/nocalculations`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async occupationCodes() {
    const rsp = await clientV2.get(URL_OCCUPATION_CODES);
    return rsp.data;
  },

  async getEpisodes({ projectId }) {
    const url = buildUrl(URL_EPISODES, { projectId });
    const resp = await clientV2.get(url);
    return resp.data;
  },

  async searchByType({ type, params }) {
    const searchURL = buildUrl(URL_SEARCH_BY_TYPE, {
      type,
    });
    const { parentValue, search, options, page = 1, pageSize = -1 } = params;

    const payload = {
      page,
      pageSize,
    };
    if (parentValue) payload.parentValue = parentValue;
    if (search) payload.search = search;
    if (options) payload.options = JSON.stringify(options);
    const rsp = await clientV2.post(searchURL, payload);
    return rsp.data;
  },

  // Not using anywhere
  async projectSearchByType({ type, timecardId, projectId, params }) {
    const searchURL = buildUrl(URL_PROJECT_SEARCH_BY_TYPE, {
      projectId,
      timecardId,
      type,
    });
    const { parentValue, search, options, page = 1, pageSize = 20 } = params;
    let queryString = '?page=' + page + '&pageSize=' + pageSize;
    if (parentValue) queryString += '&parentValue=' + parentValue;
    if (search) queryString += '&search=' + search;
    if (options) queryString += '&options=' + options;

    const rsp = await clientV2.get(searchURL + queryString);
    return rsp.data;
  },

  async timecard({ projectId, timecardEntryHeaderId }) {
    const url = buildUrl(URL_TIMECARD, { projectId, timecardEntryHeaderId });
    const rsp = await clientV2.get(url + '?timecard');
    return rsp.data;
  },
  async fetchTimecard({ projectId, timecardEntryHeaderId }) {
    const url = buildUrl(URL_TIMECARD_WTC, {
      projectId,
      timecardEntryHeaderId,
    });
    const rsp = await clientV2.get(url + '?timecardv2');
    return rsp.data;
  },

  async getTimecardBreakdown({ projectId, timecardEntryHeaderId }) {
    const url = buildUrl(URL_BREAKDOWN, { projectId, timecardEntryHeaderId });
    const resp = await clientV2.get(url);
    return resp.data;
  },

  async getTimecardComments({ projectId, timecardEntryHeaderId }) {
    const url = buildUrl(URL_COMMENTS, { projectId, timecardEntryHeaderId });
    const resp = await clientV2.get(url);
    return resp.data;
  },

  async getHPlusTimecardInfo({ projectId, timecardEntryHeaderId }) {
    const url = buildUrl(URL_TIMECARD_HOURSPLUS, {
      projectId,
      timecardEntryHeaderId,
    });
    const resp = await clientV2.get(url);
    return resp.data;
  },

  async getTimecardNextApprovers({ projectId, timecardId }) {
    const url = buildUrl(URL_NEXT_APPROVERS, {
      timecardId,
      projectId,
    });
    const resp = await clientV2.get(url);
    return resp.data;
  },
  async getTimecardCurrentApprovers({ projectId, timecardId }) {
    const url = buildUrl(URL_CURRENT_APPROVERS, {
      timecardId,
      projectId,
    });
    const resp = await clientV2.get(url);
    return resp.data;
  },

  async saveTimecard({ projectId, timecardEntryHeaderId, timecard }) {
    const url = buildUrl(URL_TIMECARD_SAVE, {
      projectId,
      timecardEntryHeaderId,
    });
    const rsp = await clientV2.put(url, timecard);
    return rsp;
  },

  async resubmit({ data, projectId, timecardEntryHeaderId }) {
    const url = buildUrl(URL_RESUBMIT, { projectId, timecardEntryHeaderId });
    const rsp = await clientV2.post(url, data);
    return rsp;
  },
  async recall({ data, projectId, timecardEntryHeaderId }) {
    const url = buildUrl(URL_RECALL, { projectId, timecardEntryHeaderId });
    const rsp = await clientV2.post(url, data);
    return rsp;
  },
  async paidHours({ projectId, timecardEntryHeaderId, region }) {
    const url = buildUrl(URL_PAIDHOURS, {
      projectId,
      timecardEntryHeaderId,
      region,
    });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async addComment({ data, projectId }) {
    const timecardEntryHeaderId = data.timecardEntryHeaderId;
    const url = buildUrl(URL_ADDCOMMENT, { projectId, timecardEntryHeaderId });
    const rsp = await clientV2.post(url, data);
    return rsp.data;
  },
  async reviewBatch({ projectId, worksightId }) {
    const url = buildUrl(URL_REVIEW_BATCH_BY_WORKSIGHTID, {
      projectId,
      worksightId,
    });
    const response = await clientV2.get(url);
    return response.data;
  },

  async fetchDrawerTimecards({ data, projectId }) {
    const url = buildUrl(URL_TIMECARDS_FOR_DRAWER, { projectId });
    const rsp = await clientV2.post(url, data);
    return rsp.data;
  },
  async calculateTimecard({ projectId, timecardEntryHeaderId }) {
    const url = buildUrl(URL_TIMECARD_CALC, {
      projectId,
      timecardEntryHeaderId,
    });
    const rsp = await clientV2.post(url);
    return rsp.data;
  },
  async bulkAllowanceSave({ projectId, timecardId, data }) {
    const url = buildUrl(URL_BULK_ALLOWANCE, {
      projectId,
      timecardId,
    });
    const rsp = await clientV2.post(url, data);
    return rsp.data;
  },

  async getPaycodeById({ paycodeId }) {
    const url = buildUrl(URL_PAYCODE_BY_ID, { paycodeId });
    const resp = await clientV2.get(url);
    return resp.data;
  },
});
