import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';

//MUI
import makeStyles from '@mui/styles/makeStyles';
import { Tooltip, InputAdornment, Box } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { TextField } from '../styledComponents';

//redux
import * as sel from '../../selectors';
import { getCurrentProject } from 'selectors/project';

import { accountsFieldMaxLength } from 'utils/weekUtils';

const useStyles = makeStyles(({ palette }) => ({
  TextEntryField: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      color: palette.text.secondary,
      paddingRight: '6px',
    },
  },
  errorTextField: {
    backgroundColor: palette.misc.errorBackground,
  },
  errorIcon: {
    color: palette.error.main,
    fontSize: 14,
    cursor: 'default',
  },
  inActiveDay: {
    backgroundColor: palette.gray['+8'],
  },
}));

const mapState = state => ({
  useMilitary: sel.getUseMilitary(state),
  isMobile: sel.getIsMobile(state),
  project: getCurrentProject(state),
});

const mapDispatch = dispatch => ({
  dispatch,
});

const TextEntryField = props => {
  const classes = useStyles();
  const {
    input,
    meta: { error },
    isMobile,
    project,
    accessor,
    disabled,
    InputProps,
    isDayActive,
    isTcEditable,
  } = props;

  let endAdornment = null;

  if (error) {
    endAdornment = (
      <InputAdornment sx={{ ml: 0 }} position="end">
        <Tooltip arrow title={error}>
          <ErrorIcon className={classes.errorIcon} />
        </Tooltip>
      </InputAdornment>
    );
  }

  if (!isTcEditable) {
    return <Box>{input.value}</Box>;
  }

  return (
    <TextField
      id={input.name}
      className={clsx(classes.TextEntryField, {
        [classes.inActiveDay]: !isDayActive,
      })}
      error={!!error}
      helperText={isMobile ? error : ''}
      InputProps={{
        endAdornment,
        className: clsx({ [classes.errorTextField]: error }),
        ...input,
        ...InputProps,
      }}
      disabled={disabled}
      inputProps={{
        maxLength: accountsFieldMaxLength(project, accessor),
      }}
      onFocus={e => e.target.select()}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.target.blur();
        }
      }}
    />
  );
};

TextEntryField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  accessor: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isDayActive: PropTypes.bool.isRequired,
  isTcEditable: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  InputProps: PropTypes.object,
};

export default compose(connect(mapState, mapDispatch))(TextEntryField);
