import { useIdleTimer } from 'react-idle-timer';

import { connect } from 'react-redux';

import { IDLE_TIMEOUT } from 'components/Shared/constants/index';

// Actions
import { show as showModal } from 'actions/modalDialog';

const mapDispatchToProps = dispatch => ({
  onShowSessionTimeout: lastActive => {
    dispatch(
      showModal({
        dialog: 'SessionTimeout',
        modalParams: { lastActive, IDLE_TIMEOUT },
      }),
    );
  },
});

const IdleTimer = ({ onShowSessionTimeout }) => {
  useIdleTimer({
    timeout: IDLE_TIMEOUT * 1000,
    onIdle: (e, idleTimer) => {
      onShowSessionTimeout(idleTimer.getLastActiveTime());
    },
  });

  return null;
};

export default connect(null, mapDispatchToProps)(IdleTimer);
