import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Paper,
  styled,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Box,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { dataBoxValue } from './digitalEditsUtils';

const useStyles = makeStyles(({ palette }) => ({
  TcInfoDataCard: {
    borderRadius: 15,
    padding: 12,
    flex: 1,
  },
  headerText: {
    fontSize: 20,
    fontWeight: 700,
  },

  standardText: {
    fontSize: 14,
    fontWeight: 600,
    color: palette.gray.primary,
  },
  fieldValue: {
    width: '140px',
    display: 'inline',
    alignItems: 'center',
    textAlign: 'end',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const Cell = styled(TableCell)(({ theme }) => ({
  '&:not(:last-child)': {
    paddingRight: 12,
  },
  borderBottom: 'none',
  padding: 0,
}));

const DataCard = props => {
  const classes = useStyles();
  let { data, sx, className } = props;

  const makeRows = React.useCallback(
    data => {
      const rows = [];
      for (let i = 1; i < data.length; i += 2) {
        const leftCol = data[i];
        const rightCol = data[i + 1];

        const row = (
          <TableRow key={leftCol.label}>
            <Cell>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between' }}
                className={classes.standardText}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {leftCol.label}:
                </Box>
                <Tooltip title={dataBoxValue(leftCol.value)} placement="right">
                  <Box className={classes.fieldValue}>
                    {dataBoxValue(leftCol.value)}
                  </Box>
                </Tooltip>
              </Box>
            </Cell>
            <Cell>
              {rightCol && (
                <Box
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                  className={classes.standardText}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {rightCol.label}:
                  </Box>
                  <Tooltip
                    title={dataBoxValue(rightCol.value)}
                    placement="right"
                  >
                    <Box className={classes.fieldValue}>
                      {dataBoxValue(rightCol.value)}
                    </Box>
                  </Tooltip>
                </Box>
              )}
            </Cell>
          </TableRow>
        );
        rows.push(row);
      }

      return (
        <TableBody>
          <TableRow>
            <Cell className={classes.headerText} colSpan={2}>
              {data[0].label}
            </Cell>
          </TableRow>
          {rows}
        </TableBody>
      );
    },
    [classes.headerText, classes.standardText, classes.fieldValue],
  );

  if (!data) return null;
  if (Array.isArray(data) === false || data.length === 0) return null;

  return (
    <Paper
      sx={sx}
      className={clsx(classes.TcInfoDataCard, classes.mainCard, className)}
    >
      <Table>{makeRows(data)}</Table>
    </Paper>
  );
};

DataCard.propTypes = {
  data: PropTypes.array.isRequired,
  sx: PropTypes.object,
  className: PropTypes.string,
};

export default DataCard;
