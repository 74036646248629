import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import pluralize from 'pluralize';
import { compose } from 'utils/helperFunctions';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, LinearProgress } from '@mui/material';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { TextField } from 'components/Shared/redux';

import { ConfirmModal } from 'components/Shared/Modals';
// actions
import { reviewBatch } from 'actions/reviews';

// selectors
import { getSelectedApprovals, isProcessingApproval } from 'selectors/reviews';
import { INPUT_FIELD_CHARACTER_LIMIT } from 'components/Shared/constants';
const commentsLimit = INPUT_FIELD_CHARACTER_LIMIT.comments;

const mapStateToProps = state => ({
  selected: getSelectedApprovals(state),
  initialValues: {
    selected: getSelectedApprovals(state),
  },
  loading: isProcessingApproval(state),
});

const onSubmit = (values, dispatch) => {
  const comment = values.comment;
  dispatch(
    reviewBatch({
      comment,
      status: 'rejected',
      timecardEntryHeaderIds: values.selected,
    }),
  );
};

const showRejectSelected = (timecard, selected) => {
  if (timecard && !timecard.id) {
    return (
      <Typography color="inherit">
        <b>{pluralize('timecard', selected.length, true)} </b>
        {' is being Rejected'}
      </Typography>
    );
  }
};

const useStyles = makeStyles(theme => ({
  content: {
    maxWidth: 500,
  },
  customWidth: {
    width: 496,
  },
}));

function RejectApprovalFlow({ handleSubmit, selected, loading, ...others }) {
  const classes = useStyles();
  // check if there is a timecard loaded in initialValues to differentiate
  // where the modal is being viewed.
  const { initialValues } = others;
  const { timecard } = initialValues;
  const content = (
    <div className={classes.content}>
      {showRejectSelected(timecard, selected)}
      <br />
      <Field
        component={TextField}
        name="comment"
        label="Comment"
        variant="outlined"
        multiline
        rows="4"
        inputProps={{ maxLength: commentsLimit, autoFocus: true }}
        required
        placeholder="Please add rejection comment"
        className={classes.customWidth}
      />
      <Field component="input" name="selected" type="hidden" value={selected} />
      {loading && <LinearProgress />}
    </div>
  );

  return (
    <form onSubmit={handleSubmit}>
      <ConfirmModal
        content={content}
        title={'Required: Add a Rejection Comment'}
        buttonText="Submit"
        buttonType="primary"
        raisedButton
        submitDisabled={loading}
        {...others}
      />
    </form>
  );
}

export default compose(
  withModalDialog({ dialog: 'RejectApprovalFlow', maxWidth: 'md' }),
  connect(mapStateToProps),
  reduxForm({
    form: 'RejectApprovalFlow',
    onSubmit,
  }),
)(RejectApprovalFlow);
