import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { Field } from 'redux-form';
import { Button, Typography, useMediaQuery, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

//components
import AdditionalFields from 'components/Employees/Reviews/WTC/AdditionalFields/AdditionalFieldsContainer';
import PrintMenu from './WTCPrintMenu';
import WTCAutoV2 from './WTCAutoV2';
import WTCHeaderButtons from './WTCHeaderButtons';

//icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import DescriptionIcon from '@mui/icons-material/Description';
//utils
import { weekEndingFormat } from 'utils/formatDate';

const style = ({ palette }) => ({
  root: {
    display: 'block',
    color: palette.text.secondary,
    backgroundColor: palette.background.default,
  },
  backToListButton: {
    color: palette.text.secondary,
    margin: 0,
  },
  backToList: {
    margin: 5,
  },
  empName: {
    fontSize: 32,
    fontWeight: 300,
    color: palette.text.secondary,
  },
  briefInfo: {
    fontSize: 12,
    fontWeight: 500,
    width: '100%',
    marginBottom: 5,
  },
  rightBar: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 16,
  },
  dropdownAlignment: {
    marginTop: '-60px',
  },

  additionalField: {
    width: 184,
  },

  producerField: {
    marginRight: 24,
  },
  employeeField: {
    marginRight: 16,
    width: 165,
  },

  viewDealMemos: {
    marginLeft: '-3px',
    display: 'flex',
    cursor: 'pointer',
    color: palette.primary.main,
    fontSize: 14,
    fontWeight: 500,
    '& > span': {
      marginTop: '4px',
      marginLeft: '5px',
    },
  },
  multiBatchTitle: {
    color: palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  gridSpace: {
    marginBottom: 10,
  },
  rightContent: {
    justifyContent: 'flex-end',
  },
  headerContent: {
    marginLeft: 10,
    marginBottom: 10,
  },
});

const Header = props => {
  const {
    classes,
    timecard,
    onBackToList,
    canRenderTimecard,
    onProducerWWChange,
    // onEmployeeWWChange,
    isMultiBatch,
    onShowMemoModal,
    dealMemoId,
    wtcDisabled,
    loadSingleBatch,
    //HeaderBtn props
    onForceCommentAction,
    onSaveTimecard,
    onApprovalWithoutChange,
    onSetAutoCoding,
    onCalculateTimecard,
    isBulkApprovalProcessing,
  } = props;
  let name, weekEnding, batchCode, batchName;

  const datesOfWeekList = useMemo(() => {
    const list = [];
    for (let i = 0; i < 7; i++) {
      list.push(
        `${moment(timecard.weekStartingDate)
          .add(i, 'day')
          .format('YYYY-MM-DD')}`,
      );
    }
    return list;
  }, [timecard.weekStartingDate]);

  if (canRenderTimecard) {
    name = timecard.employee.name.includes(':')
      ? timecard.employee.name.split(':')[0]
      : timecard.employee.name;
    weekEnding = weekEndingFormat(timecard.weekEndingDate);
    batchCode = timecard.worksightHtgId;
    batchName = timecard.batch.name;
  }

  const isMediumScreen = useMediaQuery('(max-width:1650px)') ? true : false;
  const showDealMemoPopup = dealMemoId => {
    if (!isBulkApprovalProcessing) {
      onShowMemoModal({
        source: 'week',
        currentDeal: dealMemoId,
      });
    }
  };
  return (
    <div className={classes.root}>
      <Button
        variant="text"
        color="primary"
        className={classes.backToListButton}
        onClick={() => onBackToList()}
      >
        <ChevronLeftIcon />
        <span className={classes.backToList}>Back to List</span>
      </Button>
      {canRenderTimecard ? (
        <div className={classes.headerContent}>
          <Grid
            style={{ flexFlow: 'nowrap' }}
            className={classes.gridSpace}
            container
            direction="row"
            alignItems="center"
          >
            <Grid container item xs>
              <Typography className={classes.empName}>{name}</Typography>
            </Grid>
            <Grid container item xs>
              <WTCHeaderButtons
                timecard={timecard}
                onForceCommentAction={onForceCommentAction}
                onSaveTimecard={onSaveTimecard}
                onApprovalWithoutChange={onApprovalWithoutChange}
                onSetAutoCoding={onSetAutoCoding}
                isMediumScreen={isMediumScreen}
                onCalculateTimecard={onCalculateTimecard}
              />
            </Grid>
            <Grid container item xs>
              <div className={classes.rightBar}>
                <PrintMenu isMediumScreen={isMediumScreen} />
                <div className={classes.additionalField}>
                  <AdditionalFields />
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid
            className={classes.gridSpace}
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid container item xs>
              <Typography className={classes.briefInfo}>
                Week Ending: <span>{weekEnding}</span>
              </Typography>
              <Typography
                className={clsx(classes.briefInfo, {
                  [classes.multiBatchTitle]: isMultiBatch,
                })}
                onClick={() => (isMultiBatch ? loadSingleBatch() : {})}
              >
                Batch: {`${batchCode} - ${batchName}`}
              </Typography>
            </Grid>
            <Grid container item xs className={classes.rightContent}>
              <div className={classes.producerField}>
                <Field
                  name="producersWorkWeek"
                  component={WTCAutoV2}
                  options={datesOfWeekList}
                  disabled={wtcDisabled}
                  minWidth={120}
                  label="Producer's Week"
                  isClearable={false}
                  stringValue={true}
                  onChange={() => {
                    onProducerWWChange(true);
                  }}
                />
              </div>
              <div className={classes.employeeField}>
                <Field
                  name="employeesWorkWeek"
                  component={WTCAutoV2}
                  options={datesOfWeekList}
                  minWidth={120}
                  label="Employee's Week"
                  isClearable={false}
                  stringValue={true}
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            className={classes.gridSpace}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <div
              className={classes.viewDealMemos}
              onClick={() => showDealMemoPopup(dealMemoId)}
            >
              <DescriptionIcon />
              <span>View Deal Memos</span>
            </div>
          </Grid>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

Header.propTypes = {
  timecard: PropTypes.object.isRequired,
};

export default withStyles(style)(Header);
