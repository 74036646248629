import React from 'react';
// import Users from 'containers/Admin/Projects/Users';
import { CrewList } from 'feature/CrewList';
import {
  FullWidthLayout,
  Header,
  ProjectHeader,
  AlertHeader,
} from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';
// import { Users as SubNav } from 'containers/Nav/Pages';

// eslint-disable-next-line import/no-anonymous-default-export
const CrewListRouter = () => (
  <EnhancedRoute
    exact
    authenticated
    key="/projects/crew-list"
    id="/projects/crew-list"
    path="/projects/:projectId/crew-list"
    layout={FullWidthLayout}
    headers={[Header, ProjectHeader, AlertHeader]}
    component={CrewList}
    title="Crew List"
  />
);

export default CrewListRouter;
