import React from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Box,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UpdateIcon from '@mui/icons-material/Update';

import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash';

import { FieldArray } from 'redux-form';
import AllowanceRow from './AllowancesRow';
import { WTCTableCell as TableCell } from 'components/Shared/Table/';
import { canadianLabel, isRegionCanada } from 'utils/helperFunctions';
import { createNewWTCAllowance } from 'utils/wtcWeekUtils';

const style = ({ palette }) => ({
  root: {
    display: 'block !important',
    width: '100%',
    boxShadow: 'none',
  },
  allowanceTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  addRowRow: {
    float: 'right',
    position: 'absolute',
    top: 16,
    right: 32,
    display: 'flex',
  },
  tableHead: {
    backgroundColor: palette.misc.tableHeader,
    color: palette.text.secondary,
    fontSize: 12,
    fontWeight: 500,
  },
  table: {
    overflowX: 'auto',
  },
  tableBody: {
    '& > tr:nth-child(even)': {
      backgroundColor: palette.primary['+9'],
    },
  },
  expansionDetails: {
    paddingTop: 0,
  },

  noDisplay: { display: 'none' },
  showDisplay: { display: 'inherit' },
  occupationCodeSelect: {
    width: 184,
  },
  fadeToWhiteRight: {
    position: 'absolute',
    top: 56,
    right: 16,
    backgroundImage:
      ' linear-gradient(to right, rgba(255, 255, 255, 0),25%, white)',
    width: 35,
    //height set dynamically to that of the parent
    zIndex: 2,
    pointerEvents: 'none',
    transition: 'opacity 0.5s',
  },
  supportDocHeader: { lineHeight: 1 },
  supportDocHeaderText: { fontSize: 10, color: 'gray' },
});

const RenderBody = ({ fields, props }) => {
  const {
    classes,
    allowancesOrder,
    masterRowData,
    occupationCodes,
    loadOccupationCodes,
    workLocations,
    dealMemos,
    dealMemoId,
    loadDealMemo,
    episodes,
    loadEpisodes,
    payCodes,
    allowances = [],
    change,
    blur,
    error,
    allowanceDisabled,
    upmEnabled,
    maskingFunc,
    accountCodeMask,
    dateOptions,
    isUPM,
    //
    countries,
    stateOptions,
    countyOptions,
    cityOptions,
    subdivisions,
    locationFuncs,
    onShowAlert,
    project,
    pendingCalculation,
    timecard,
  } = props;

  const allowanceErrors = error.allowances || [];
  const filterArray = allowances?.filter(
    item => parseFloat(item?.factor || 1) === 1.0,
  );
  const hasFactorNot1 = filterArray.length === allowances?.length;

  function handleAddRow() {
    const locationTypeId = timecard?.locationTypeId || null;
    const newAllowance = createNewWTCAllowance(
      masterRowData,
      dealMemos,
      locationTypeId,
      workLocations,
    );
    fields.push(newAllowance);
  }

  function handleCopyRow(currentIndex, selectedRow) {
    selectedRow.copied = true;
    selectedRow.rowId = null;
    selectedRow.tempId = crypto.randomUUID();
    fields.splice(currentIndex + 1, 0, selectedRow);
  }
  const caRegion = isRegionCanada(project.region);

  return (
    <div className={classes.table}>
      <Box className={classes.addRowRow}>
        {pendingCalculation && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <UpdateIcon sx={{ height: 16, width: 16, color: 'primary.main' }} />
            <Typography key={'sub'} variant="caption">
              Allowance actions disabled during calculation{' '}
            </Typography>
          </Box>
        )}
        <Button
          variant="text"
          color="primary"
          onClick={handleAddRow}
          disabled={allowanceDisabled || pendingCalculation}
        >
          <AddIcon />
          <span>Add Row</span>
        </Button>
      </Box>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {_.map(allowancesOrder, (header, i) => {
              const iscaRegionLocationType =
                caRegion && header.columnId === 'locationType';
              const isFactorVisible =
                hasFactorNot1 && header.columnId === 'factor';
              return (
                <TableCell
                  key={i}
                  display={
                    iscaRegionLocationType || isFactorVisible
                      ? false
                      : header.visible
                  }
                  header
                >
                  {header.label !== 'Supporting Document' ? (
                    <span>{canadianLabel(header.label)}</span>
                  ) : (
                    <div className={classes.supportDocHeader}>
                      <span>{header.label}</span>
                      <br />
                      <span className={classes.supportDocHeaderText}>
                        (max size 10 MB, use PDF, JPG, PNG formats)
                      </span>
                    </div>
                  )}
                </TableCell>
              );
            })}
            <TableCell display sticky={true}></TableCell>
          </TableRow>
        </TableHead>

        <TableBody className={classes.tableBody}>
          {fields.map((allowanceName, index, { get }) => {
            const allowanceError = allowanceErrors[index];
            const allowance = get(index) || {};
            const key = allowance.rowId || allowance.tempId;

            const currentStateId = _.get(
              allowances,
              `[${index}].workState.id`,
              '',
            );
            const currentCountryId = _.get(
              allowances,
              `[${index}].workCountry.id`,
              '',
            );

            const supportDocument = _.get(
              allowances,
              `[${index}].comment`,
              '',
            ).replace(/<[^>]+>/g, '');
            // regex for removing some html tag

            return (
              <AllowanceRow
                onShowAlert={onShowAlert}
                removeRow={() => fields.remove(index)}
                key={key}
                allowancesOrder={allowancesOrder}
                allowanceName={allowanceName}
                allowanceData={allowance}
                workLocations={workLocations}
                dealMemos={dealMemos}
                dealMemoId={dealMemoId}
                loadDealMemo={loadDealMemo}
                episodes={episodes}
                loadEpisodes={loadEpisodes}
                payCodes={payCodes}
                supportDocument={supportDocument}
                index={index}
                occupationCodes={occupationCodes}
                loadOccupationCodes={loadOccupationCodes}
                change={change}
                blur={blur}
                allowanceError={allowanceError}
                masterRowData={masterRowData}
                allowanceDisabled={allowanceDisabled}
                upmEnabled={upmEnabled}
                isUPM={isUPM}
                maskingFunc={maskingFunc}
                accountCodeMask={accountCodeMask}
                dateOptions={dateOptions}
                handleCopyRow={handleCopyRow}
                currentStateId={currentStateId}
                currentCountryId={currentCountryId}
                countries={countries}
                stateOptions={stateOptions}
                countyOptions={countyOptions}
                cityOptions={cityOptions}
                subdivisions={subdivisions}
                locationFuncs={locationFuncs}
                caRegion={caRegion}
                hasFactorNot1={hasFactorNot1}
                project={project}
                pendingCalculation={pendingCalculation}
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

const Allowances = props => {
  const { classes, allowances } = props;
  const [expanded, setExpanded] = React.useState(true);
  React.useEffect(() => {
    if (allowances && allowances.length) setExpanded(true);
  }, [allowances]);
  const handleExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <div>
      <Accordion className={classes.root} expanded={expanded}>
        <AccordionSummary
          onClick={handleExpand}
          expandIcon={<ExpandMoreIcon />}
          variant="iconOnRight"
        >
          <Typography>
            <span className={classes.allowanceTitle}>Allowances</span>
          </Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.expansionDetails}>
          <FieldArray
            name="allowances"
            component={RenderBody}
            props={{
              props,
            }}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default withStyles(style)(Allowances);
