import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { push } from 'redux-first-history';
// actions
import {
  fetchProjectDepartments,
  fetchProjectUsers,
  storeProjectUsers,
} from 'actions/project';
import {
  fetchWeekendingTimecards,
  storeWeekendingTimecards,
  storeTeamTimecardUser,
} from 'actions/timecards';
import { show as showModal } from 'actions/modalDialog';
//container
import { GroupTimecard } from './Modals';
// components
import { Create as CrewTimecardCreate } from 'components/Employees/CrewTimecard';
// decorators
import withDataGrid from 'decorators/withDataGrid';
// selectors
import {
  getDepartmentById,
  getProjectDetails,
  getIsProjectCompleted,
} from 'selectors/project';
import {
  getCrewTimecardLoading,
  getFilteredWeekendingTimecards,
} from 'selectors/timecards';
import { currentUser } from 'selectors/session';
import { sortData } from 'selectors/sorting';

const grid = 'crew-weekending-timecard';
const mapState = (state, ownProps) => {
  const { match, pagination } = ownProps;
  const activeUser = currentUser(state);
  const role = activeUser?.role;
  const weekEnding = match.params.weekEndingdate;

  const tempWeekendingTimecards =
    getFilteredWeekendingTimecards(weekEnding)(role)(state);
  const gridPagination = {
    ...pagination,
    totalCount:
      pagination && pagination.totalCount !== tempWeekendingTimecards.length
        ? tempWeekendingTimecards.length
        : pagination.totalCount,
    activePage:
      tempWeekendingTimecards.length >
      pagination.activePage * pagination.pageSize
        ? pagination.activePage
        : 0,
  };

  const departmentId = match.params.departmentId;

  return {
    pagination: gridPagination,
    activeUser,
    weekEndingdate: weekEnding,
    departmentId,
    department: getDepartmentById(state, departmentId),
    project: getProjectDetails(state),
    loading: getCrewTimecardLoading(state),
    weekendingTimecards: sortData(
      getFilteredWeekendingTimecards(weekEnding)(role),
      grid,
      gridPagination,
    )(state),
    employees: getFilteredWeekendingTimecards(weekEnding)(role)(state),
    isProjectCompleted: getIsProjectCompleted(state),
  };
};

const mapDispatch = (dispatch, { match, location }) => ({
  onFetchData: () => {
    dispatch(
      fetchProjectDepartments({
        projectId: match.params.projectId,
        departmentId: match.params.departmentId,
      }),
    );
    dispatch(
      fetchWeekendingTimecards({
        weekEndingdate: match.params.weekEndingdate,
        departmentId: match.params.departmentId,
      }),
    );
    dispatch(fetchProjectUsers({ departmentId: match.params.departmentId }));
  },
  onTakeMeBack: projectId => {
    const fromURI = `/projects/${projectId}/timecards`;
    dispatch(push(fromURI));
  },
  onAddGroupTimecard: (weekEndingdate, selectedUsers) => {
    dispatch(
      storeTeamTimecardUser({ weekEndingdate: weekEndingdate, selectedUsers }),
    );
    dispatch(showModal({ dialog: 'groupTimecard' }));
  },
  onUnmount: () => {
    dispatch(storeWeekendingTimecards({ weekendingTimecards: [] }));
    dispatch(storeProjectUsers({ users: [] }));
  },
});

class CreateCrewTimecard extends Component {
  static propTypes = {
    onFetchData: PropTypes.func.isRequired,
    sortParams: PropTypes.object.isRequired,
    onApplySorting: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.onFetchData();
  }

  componentWillUnmount() {
    this.props.onUnmount();
  }

  render() {
    return (
      <div>
        <CrewTimecardCreate {...this.props} />
        {/* Crew create modal */}
        <GroupTimecard {...this.props} />
      </div>
    );
  }
}

export default compose(
  withDataGrid('crew-weekending-timecard'),
  connect(mapState, mapDispatch),
)(CreateCrewTimecard);
