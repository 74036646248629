import React from 'react';
import withStyles from '@mui/styles/withStyles';
// selector
import {
  getAccountCodes,
  getBatches,
  getDepartments,
  getEmployeess,
  getEpisodes,
  getSets,
  getStatus,
  getWeekDay,
} from 'selectors/bulkEdit';
// component
import FilterDropdown from 'components/Shared/ReactTable/FilterDropdown';
import withBulkEditFilter from 'decorators/withBulkEditFilter';

const DepartmentsFilter = withBulkEditFilter({
  filterName: 'departments',
  selector: getDepartments,
})(FilterDropdown);

const StatusFilter = withBulkEditFilter({
  filterName: 'status',
  selector: getStatus,
})(FilterDropdown);

const WeekDayFilter = withBulkEditFilter({
  filterName: 'weekDay',
  selector: getWeekDay,
})(FilterDropdown);
const BatchesFilter = withBulkEditFilter({
  filterName: 'batches',
  selector: getBatches,
})(FilterDropdown);

const EmployeeFilter = withBulkEditFilter({
  filterName: 'employees',
  selector: getEmployeess,
})(FilterDropdown);

const AccountCodeFilter = withBulkEditFilter({
  filterName: 'accountCodes',
  selector: getAccountCodes,
})(FilterDropdown);

const EpisodeFilter = withBulkEditFilter({
  filterName: 'episodes',
  selector: getEpisodes,
})(FilterDropdown);

const SetFilter = withBulkEditFilter({
  filterName: 'sets',
  selector: getSets,
})(FilterDropdown);

const style = () => ({
  accordion: {
    marginBottom: 8,
  },
});

function FiltersList({ classes, expandAll, saving }) {
  return (
    <>
      <div className={classes.accordion}>
        <WeekDayFilter
          canSelectAll
          label={`Select Days`}
          expand={expandAll}
          saving={saving}
        />
      </div>
      <div className={classes.accordion}>
        <DepartmentsFilter
          canSelectAll
          label={`Select Department`}
          enableSearch
          expand={expandAll}
          saving={saving}
        />
      </div>
      <div className={classes.accordion}>
        <StatusFilter
          canSelectAll
          label={`Select Status`}
          expand={expandAll}
          saving={saving}
        />
      </div>
      <div className={classes.accordion}>
        <BatchesFilter
          canSelectAll
          label={`Select Batches`}
          enableSearch
          expand={expandAll}
          saving={saving}
        />
      </div>

      <div className={classes.accordion}>
        <EmployeeFilter
          label={`Select Name`}
          enableSearch
          canSelectAll
          expand={expandAll}
          saving={saving}
        />
      </div>
      <div className={classes.accordion}>
        <AccountCodeFilter
          label={`Account Code`}
          canSelectAll
          enableSearch
          expand={expandAll}
          saving={saving}
        />
      </div>
      <div className={classes.accordion}>
        <EpisodeFilter
          label={`Episode`}
          canSelectAll
          enableSearch
          expand={expandAll}
          saving={saving}
        />
      </div>
      <div className={classes.accordion}>
        <SetFilter
          label={`Set`}
          canSelectAll
          enableSearch
          expand={expandAll}
          saving={saving}
        />
      </div>
    </>
  );
}

export default withStyles(style)(FiltersList);
