import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import debug from 'debug';

export const db = debug('aceTable');

const checkValObjOrStr = (value, original) => {
  if (value !== null && typeof value === 'object') {
    return value?.id !== original?.id;
  } else {
    if (!value && value !== 0 && !original && !original !== 0) return false;

    return value !== original;
  }
};

export const updateUnsavedChanges = ({ unsavedChangesRef, values, rows }) => {
  const { current: changes } = unsavedChangesRef;
  changes.original = changes.original || {};
  changes.unsaved = changes.unsaved || {};

  const { original, unsaved } = changes;

  rows.forEach(row => {
    const { id } = row;
    if (!original[id]) {
      original[id] = _cloneDeep(row);
    }

    for (const field of Object.keys(values)) {
      const value = values[field];

      const isChanged = checkValObjOrStr(value, original[id][field]);

      if (isChanged) {
        unsaved[id] = unsaved[id] || {};
        unsaved[id][field] = value;
      } else {
        if (unsaved[id]) {
          delete unsaved[id][field];
          if (_isEmpty(unsaved[id])) delete unsaved[id];
        }
      }
    }
  });
};
