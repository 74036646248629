import _ from 'lodash';
import {
  takeEvery,
  takeLatest,
  call,
  put,
  all,
  select,
} from 'redux-saga/effects';
import { reset } from 'redux-form';
import camelCase from 'camelcase-keys';
import snakeCase from 'snakecase-keys';

//selectors
import {
  getSettings,
  getESignature,
  getStudioPlusSettings,
  getTimeEntrySettings,
  getDtsSettings,
  getRecipientValues,
  getWTCSetting,
  getAccountCodeSettings,
  getCrewMemberSettings,
} from 'selectors/settings';

import { getProject as project } from 'selectors/routeParams';
import { getProjectDetails } from 'selectors/project';
//actions
import { hide as hideModal } from 'actions/modalDialog';
import { showAlert } from 'actions/alert';
import * as actions from 'actions/settings';
import { fetchDetails } from 'actions/project';

import { getElapsedTime } from 'utils/weekUtils';

import { CREW_MEMBER_DIALOG } from 'containers/Admin/Projects/Settings/CrewMemberTimecardSettings';

export function* fetch({ api, debug }, params) {
  try {
    const { studioPlusHardRefreshFlag = false } = params;
    yield put(actions.loading({ loading: true }));

    const projectId = yield select(project);

    //if the currentSettings null/empty, or they are for a different project,
    //or they were fetched less than 60 seconds ago, then no need to re-fetch from server
    const currentSettings = yield select(getSettings);

    if (
      !currentSettings ||
      _.isEmpty(currentSettings) ||
      currentSettings.projectId !== projectId ||
      getElapsedTime(currentSettings.cachedOn) >= 60000 ||
      studioPlusHardRefreshFlag
    ) {
      const settings = (yield call(api.projects.settings, { projectId })) || {};
      const cachedOn = new Date();
      yield put(actions.store({ projectId, settings, cachedOn }));
      yield put(actions.initCrewMemberVisibleFields({ projectId }));
    }

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.store({ settings: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

export function* updateAccountCode({ api, debug }) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const data = yield select(getAccountCodeSettings);
    const settings = snakeCase(data, { deep: true });
    yield call(api.projects.updateSettings, { projectId, settings });
    yield put(fetchDetails());

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

export function* saveCrewMemberTCFieldsSettings(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const data = yield select(getCrewMemberSettings);
    const settings = snakeCase(data, { deep: true });
    Object.keys(settings).forEach(key => {
      if (!settings[key]) {
        delete settings[key];
      }
    });
    yield call(api.projects.updateSettings, { projectId, settings });
    yield put(hideModal({ dialog: CREW_MEMBER_DIALOG }));
    yield put(actions.fetch());
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    yield put(hideModal({ dialog: CREW_MEMBER_DIALOG }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
    debug(e);
  }
}

export function* fetchPayrollSettings({ api, debug }) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const payrollSettings = yield call(api.projects.getPayrollSettings, {
      projectId,
    });
    yield put(actions.storePayrollSettings({ projectId, payrollSettings }));
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storePayrollSettings({ payrollSettings: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

//time settings
export function* updateTimeEntrySetting(api, debug) {
  try {
    const projectId = yield select(project);
    const settings = yield select(getTimeEntrySettings);
    yield call(api.projects.updateSettings, { projectId, settings });
    yield put(fetchDetails());
  } catch (e) {
    debug(e);
  }
}
//e-signature settings

function* updateESignature(api, debug) {
  try {
    const projectId = yield select(project);
    const settings = yield select(getESignature);
    yield call(api.projects.updateSettings, { projectId, settings });
    yield put(fetchDetails());
  } catch (e) {
    debug(e);
  }
}
//email configuration
function* fetchEmailSettings(api, debug) {
  try {
    yield put(actions.loadingEmailSettings({ loading: true }));
    const projectId = yield select(project);
    const data = yield call(api.projects.getEmailTemplates, { projectId });
    const result = camelCase(data, { deep: true });
    yield put(actions.storeEmailSettings({ templates: result }));
    yield put(actions.loadingEmailSettings({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
    yield put(actions.loadingEmailSettings({ loading: false }));
  }
}

function* submitEmailSettings(api, debug, params) {
  try {
    const projectId = yield select(project);
    const postBody = snakeCase(params.values);
    yield put(actions.loadingEmailSettings({ loading: true }));
    if (postBody.id) {
      const response = yield call(api.projects.updateEmailTempalte, {
        projectId,
        id: postBody.id,
        data: postBody,
      });
      const template = camelCase(response, { deep: true });
      yield put(actions.storeEmailSettings({ template }));
    } else {
      const response = yield call(api.projects.createEmailTemplate, {
        projectId,
        data: postBody,
      });
      const template = camelCase(response, { deep: true });
      yield put(actions.storeEmailSettings({ template }));
    }
    yield put(actions.loadingEmailSettings({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.loadingEmailSettings({ loading: false }));
    yield put(showAlert());
  }
}

//Studio +
export function* fetchStudioPlusDirectory({ api, debug }) {
  try {
    let studioPlusDirectory = {};
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const projectDeets = yield select(getProjectDetails);
    if (
      !(
        projectDeets &&
        projectDeets.dbCode &&
        projectDeets.productionCompany &&
        projectDeets.productionCompany.code &&
        projectDeets.code
      )
    ) {
      //projectDeets not ready yet
      return;
    }

    const externalId = `${projectDeets.dbCode}|${projectDeets.productionCompany.code}|${projectDeets.code}`;
    const data = yield call(api.projects.getStudioFolders, { externalId });
    if (!(data.data.getFolderByMiddleLayerId === null)) {
      studioPlusDirectory = camelCase(data.data.getFolderByMiddleLayerId, {
        deep: true,
      });
    }
    yield put(
      actions.storeStudioPlusDirectory({ projectId, studioPlusDirectory }),
    );
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

export function* updateStudioPlusSettings({ api, debug }) {
  try {
    const projectId = yield select(project);
    const settings = yield select(getStudioPlusSettings);
    if (settings?.studio_enabled === 'New') {
      const projectDtls = yield select(getProjectDetails);
      //external ID
      const externalId = `${projectDtls.dbCode}|${projectDtls.productionCompany.code}|${projectDtls.code}`;
      settings.external_id = externalId;
    }
    const data = yield call(api.projects.updateSettings, {
      projectId,
      settings,
    });
    if (!data) {
      yield put(
        showAlert({
          message:
            'Studio+ is not set up for this project. Please check Studio+ and make sure the project and organization are setup correctly.',
          variant: 'error',
        }),
      );
      yield put(reset('StudioPlus'));
    } else {
      yield put(fetchDetails());
      yield put(actions.fetch({ studioPlusHardRefreshFlag: true }));
    }
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}
//dts
function* updateDtsSettings(api, debug) {
  try {
    const projectId = yield select(project);
    const settings = yield select(getDtsSettings);
    yield call(api.projects.updateSettings, { projectId, settings });
    yield put(fetchDetails());
  } catch (e) {
    debug(e);
  }
}

function* fetchTimecardTemplates(api, debug) {
  try {
    const rsp = yield call(api.projects.getTimecardTemplates);
    const data = rsp.documents;
    yield put(actions.storeTimecardTemplates({ data }));
  } catch (e) {
    debug(e);
  }
}

export function* fetchRecipients(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const data = yield call(api.projects.notificationRecipients, { projectId });
    const recipients = camelCase(data, { deep: true });
    yield put(actions.storeRecipients({ recipients }));
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    yield put(actions.storeRecipients({ recipients: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
    debug(e);
  }
}

export function* updateWTCSetting(api, debug) {
  try {
    const projectId = yield select(project);
    const settings = yield select(getWTCSetting);
    yield call(api.projects.updateSettings, { projectId, settings });
    yield put(fetchDetails());
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

export function* saveRecipients(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const data = yield select(getRecipientValues);
    const recipients = snakeCase(data, { deep: true });
    yield call(api.projects.addRecipients, { projectId, recipients });
    yield put(actions.fetchRecipients());
    yield put(hideModal({ dialog: 'addRecipients' }));
  } catch (e) {
    yield put(hideModal({ dialog: 'addRecipients' }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
    debug(e);
  }
}

export function* removeRecipient(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const id = params.id;
    const projectId = yield select(project);
    yield call(api.projects.removeRecipient, { projectId, id });
    yield put(actions.fetchRecipients());
  } catch (e) {
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
    debug(e);
  }
}
export default function* settingsFlow({ api, debug }) {
  yield all([
    takeEvery(`${actions.fetch}`, fetch, { api, debug }),
    takeEvery(`${actions.updateAccountCode}`, updateAccountCode, {
      api,
      debug,
    }),
    takeEvery(`${actions.fetchPayrollSettings}`, fetchPayrollSettings, {
      api,
      debug,
    }),
    takeEvery(`${actions.updateESignature}`, updateESignature, api, debug),
    takeEvery(`${actions.fetchEmailSettings}`, fetchEmailSettings, api, debug),
    takeEvery(
      `${actions.submitEmailSettings}`,
      submitEmailSettings,
      api,
      debug,
    ),

    takeEvery(`${actions.fetchStudioPlusDirectory}`, fetchStudioPlusDirectory, {
      api,
      debug,
    }),
    takeEvery(`${actions.updateStudioPlusSettings}`, updateStudioPlusSettings, {
      api,
      debug,
    }),
    takeEvery(
      `${actions.updateTimeEntrySettings}`,
      updateTimeEntrySetting,
      api,
      debug,
    ),
    takeEvery(`${actions.updateDtsSettings}`, updateDtsSettings, api, debug),
    takeEvery(
      `${actions.fetchTimecardTemplates}`,
      fetchTimecardTemplates,
      api,
      debug,
    ),
    takeLatest(`${actions.fetchRecipients}`, fetchRecipients, api, debug),
    takeLatest(`${actions.saveRecipients}`, saveRecipients, api, debug),
    takeLatest(`${actions.removeRecipient}`, removeRecipient, api, debug),
    takeLatest(`${actions.updateWTCSetting}`, updateWTCSetting, api, debug),
    takeEvery(
      `${actions.saveCrewMemberTCFieldsSettings}`,
      saveCrewMemberTCFieldsSettings,
      api,
      debug,
    ),
  ]);
}
