import React from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
// components
import {
  ModalFooter,
  ModalContent,
  ModalTitle,
} from 'components/Shared/ModalDialog';
// decorators
import withModalDialog from 'decorators/withModalDialog';
import { withStyleSheet } from 'shared/theme';

// actions
import { save } from '../actions';
import { hide as hideModal } from 'actions/modalDialog';
const style = () => {};

const ConfirmEmailChange = param => {
  const dispatch = useDispatch();
  return (
    <div>
      <ModalTitle>Change Email Address</ModalTitle>
      <ModalContent>
        Before changing this user’s email address in Hours+, you must{' '}
        <b>first</b> change the user’s email in <b>Okta</b>. This will change
        this user’s email address across all projects in Hours+. This cannot be
        undone. Proceed?
      </ModalContent>
      <ModalFooter>
        <Button
          variant="outlined"
          color="primary"
          name="cancel"
          onClick={() => {
            dispatch(hideModal({ dialog: 'confirmEmailChange' }));
          }}
        >
          {'No, Take Me Back'}
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          onClick={() => {
            dispatch(save());
          }}
        >
          {'Change Email'}
        </Button>
      </ModalFooter>
    </div>
  );
};
export default withModalDialog({ dialog: 'confirmEmailChange' })(
  withStyleSheet('confirmEmailChange', style)(ConfirmEmailChange),
);
