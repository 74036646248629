import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => {
  const primaryBtn = {
    background: palette.button.primary.background,
    border: `solid 1px ${palette.button.primary.background}`,
    color: palette.button.primary.color,
  };
  const redBtn = {
    background: palette.button.lightRed.background,
    border: `solid 1px ${palette.button.lightRed.background}`,
    color: palette.button.lightRed.color,
  };
  const orangeBtn = {
    background: palette.button.orange.background,
    border: `solid 1px ${palette.button.orange.background}`,
    color: palette.button.orange.color,
  };
  const grayBtn = {
    background: palette.button.gray.background,
    border: `solid 1px ${palette.button.gray.background}`,
    color: palette.button.gray.color,
  };
  const yellowBtn = {
    background: palette.button.yellow.background,
    border: `solid 1px ${palette.button.yellow.background}`,
    color: palette.button.yellow.color,
  };
  const lightGreenBtn = {
    background: palette.button.lightGreen.background,
    border: `solid 1px ${palette.button.lightGreen.background}`,
    color: palette.button.lightGreen.color,
  };
  const deselectBtn = {
    background: palette.button.deselect.background,
    color: palette.button.deselect.color,
  };

  return {
    ColorPill: {
      paddingLeft: '15px !important',
      paddingRight: '15px !important',
      padding: '5px',
      borderRadius: '8px',
      marginLeft: '10px !important',
      marginRight: '10px !important',
      display: 'inline-block',
      marginTop: '5px !important',
      marginBottom: '5px !important',
      border: `solid 1px transparent`, //prevent resize on highlight
      whiteSpace: 'nowrap',
    },
    primary: primaryBtn,
    deselect: deselectBtn,
    //colors below correlate with pillColor value in src\feature\SearchInvoices\searchUtils.js
    red: redBtn,
    yellow: yellowBtn,
    orange: orangeBtn,
    gray: grayBtn,
    lightGreen: lightGreenBtn,
    isClickable: {
      cursor: 'pointer',
    },
  };
});

const ColorPill = props => {
  const classes = useStyles();
  const { color, label, pendoTag, count, selected = true, onClick } = props;

  const displayCount = count || count === 0 ? `(${count})` : '';

  const pendoClass = pendoTag ? `pendo_${pendoTag}` : '';

  return (
    <Box
      className={clsx(classes.ColorPill, pendoClass, {
        [classes[color]]: selected,
        [classes.deselect]: !selected,
        [classes.isClickable]: !!onClick,
      })}
      onClick={e => {
        if (onClick) onClick(e);
      }}
    >
      {label} {displayCount}
    </Box>
  );
};

ColorPill.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'deselect',
    'red',
    'yellow',
    'orange',
    'gray',
    'lightGreen',
  ]),
  label: PropTypes.string.isRequired,
  pendoTag: PropTypes.string,
  count: PropTypes.number,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ColorPill;
