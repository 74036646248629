import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

//actions
import {
  fetchUserAccessReportDetails,
  runRejectedTimecardCommentsReport,
  runTemplateReport,
  loadAllClients,
} from 'actions/reports';

//selectors
import {
  getLoading as isUpdating,
  getScheduledReports,
  getRejectedLoading,
  getClientsDetails,
  getClientsById,
} from 'selectors/useraccessreport';

//components
import SuperAdmincom from 'components/Admin/SuperAdmin';

const mapState = state => ({
  loading: isUpdating(state),
  reports: getScheduledReports(state),
  rejectedLoading: getRejectedLoading(state),
  clientsDetails: getClientsDetails(state),
  clientDetailsById: getClientsById(state),
});

const mapDispatch = dispatch => ({
  onFetchUserAccessReportDetails: clientCode => {
    dispatch(fetchUserAccessReportDetails({ clientCode }));
  },

  onRunRejectedTimecardCommentsReport: report => {
    dispatch(runRejectedTimecardCommentsReport({ report }));
  },
  onRunTemplateReport: report => {
    dispatch(runTemplateReport({ report }));
  },
  onLoadAllClients: report => {
    dispatch(loadAllClients({ report }));
  },
});

class SuperAdmin extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    onLoadAllClients: PropTypes.func.isRequired,
  };
  componentDidMount() {
    const { onLoadAllClients } = this.props;
    onLoadAllClients();
  }

  render() {
    const {
      loading,
      rejectedLoading,
      onFetchUserAccessReportDetails,
      reports,
      onRunRejectedTimecardCommentsReport,
      onRunTemplateReport,
      clientsDetails,
      clientDetailsById,
    } = this.props;

    return (
      <SuperAdmincom
        loading={loading}
        onFetchUserAccessReportDetails={onFetchUserAccessReportDetails}
        reports={reports}
        rejectedLoading={rejectedLoading}
        onRunRejectedTimecardCommentsReport={
          onRunRejectedTimecardCommentsReport
        }
        onRunTemplateReport={onRunTemplateReport}
        clientsDetails={clientsDetails}
        clientDetailsById={clientDetailsById}
      />
    );
  }
}

export default connect(mapState, mapDispatch)(SuperAdmin);
