import createAction from './createAction';

export const loading = createAction('loading/reviewFlows');
export const saving = createAction('saving/reviewFlows');
export const add = createAction('add/reviewFlow');
export const fetch = createAction('fetch/reviewFlows');
export const store = createAction('store/reviewFlows');
export const editing = createAction('editing/reviewFlow');
export const remove = createAction('remove/reviewFlow');

export const setNewFlowId = createAction('setNewFlowId/reviewFlow');

export const fetchSteps = createAction('fetch/reviewFlow/steps');
export const storeSteps = createAction('store/reviewFlow/steps');

export const fetchStepUsers = createAction('fetch/reviewFlow/step/users');
export const storeStepUsers = createAction('store/reviewFlow/step/users');
export const editingStepUser = createAction('editing/reviewFlow/step/user');
export const saveEditingStepUser = createAction(
  'editing/reviewFlow/step/user-save',
);
export const removeStepUser = createAction('remove/reviewFlow/step/user');

export const fetchDepartments = createAction('fetch/reviewFlow/departments');
export const storeDepartments = createAction('store/reviewFlow/departments');
export const editingDepartment = createAction('editing/reviewFlow/department');
export const saveDepartment = createAction('save/reviewFlow/department');
export const removeDepartment = createAction('remove/reviewFlow/department');
export const fetchDeptStats = createAction('fetch/reviewFlow/department/stats');
export const storeDeptStats = createAction('store/reviewFlow/department/stats');

export const setHeadApproval = createAction('set/reviewFlow/headApproval');
export const setHeadViewGross = createAction('toogle/reviewFlow/headViewGross');

export const setPaDeleteTimecards = createAction(
  'toogle/reviewFlow/paDeleteTimecards',
);

export const fetchReviewLevels = createAction('fetch/reviewFlow/levels');
export const storeReviewLevels = createAction('store/reviewFlow/levels');

export const fetchTimecardTemplates = createAction(
  'fetch/reviewFlow/timecardTemplates',
);
export const storeTimecardTemplates = createAction(
  'store/reviewFlow/storeTimecardTemplates',
);
