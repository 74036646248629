import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import {
  fetchComments,
  setCurrentInvoiceId,
  lockComment,
} from 'actions/digitalEdits';
import { useDidMount } from 'utils/customHooks';
import Comments from './Comments';

const useStyles = makeStyles(({ palette }) => ({
  CommentWindow: { height: '90vh' },
  titleBar: {
    height: '25px',
    backgroundColor: palette.primary.main,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  popInIcon: {
    color: palette.primary.contrastText,
    transform: 'rotate(180deg)',
  },
}));

const mapState = state => ({});

const mapDispatch = dispatch => ({
  onFetchComments: invoiceId => {
    dispatch(fetchComments({ invoiceId }));
    dispatch(lockComment({ invoiceId }));
    dispatch(setCurrentInvoiceId({ currentInvoiceId: invoiceId }));
  },
});

const CommentWindow = props => {
  const classes = useStyles();
  const { match, onFetchComments } = props;
  const {
    params: { invoiceId },
  } = match;

  const commentStateRef = React.useRef('closed');

  const onWindowClose = useCallback(() => {
    //TODO - update this function on window refresh
    window?.onCloseInvoiceComments(commentStateRef.current);
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', onWindowClose);
    return () => {
      window.removeEventListener('beforeunload', onWindowClose);
    };
  }, [onWindowClose]);

  useDidMount(() => {
    onFetchComments(invoiceId);
  });

  useEffect(() => {
    const opener = window.opener;

    //close if the window that opened us is closed
    const intervalId = setInterval(() => {
      // https://stackoverflow.com/a/55601462/901311
      if (typeof window.opener != 'undefined' && window.opener != null) {
        if (
          window.opener.closed ||
          `${window.opener.location.pathname}`.includes('digital-edits') ===
            false
        ) {
          window.close();
        }
      } else {
        window.close();
      }
    }, 750);

    if (opener === null) {
      //redirect to base url if we weren't opened by another window
      window.location.href = '/';
    }

    return () => {
      //close window if this component unmounts (logout etc)
      window.onCloseInvoiceComments(commentStateRef.current);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Box className={classes.CommentWindow}>
      <Box className={classes.titleBar}>
        <Tooltip placement="right" title="Pop In">
          <IconButton
            className="PENDO_digitalEditsModalPopIn"
            onClick={() => {
              commentStateRef.current = 'modal';
              setTimeout(() => {
                window.onCloseInvoiceComments(commentStateRef.current);
              }, 100);
            }}
          >
            <OpenInNewIcon className={classes.popInIcon} />
          </IconButton>
        </Tooltip>
      </Box>
      <Comments />
    </Box>
  );
};

CommentWindow.propTypes = {
  match: PropTypes.object.isRequired,
  onFetchComments: PropTypes.func.isRequired,
};

export default compose(connect(mapState, mapDispatch))(CommentWindow);
