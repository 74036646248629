import _ from 'lodash';
import { createSelector } from 'reselect';
// import { getFormValues } from './formValues';

export const getLoading = state => _.get(state, 'bulkEdit.loading', false);
export const getWeekEnding = state => _.get(state, 'bulkEdit.weekEnding', null);
export const getWeekEndings = state => _.get(state, 'bulkEdit.weekEndings', []);
export const getStatus = state => _.get(state, 'bulkEdit.status', []);
export const getBatches = state => _.get(state, 'bulkEdit.batches', []);
export const getDepartments = state => _.get(state, 'bulkEdit.departments', []);
export const getWeekDay = state => _.get(state, 'bulkEdit.weekDay', []);
export const getTimecardDays = state => _.get(state, 'bulkEdit.days', []);
export const getTimecardCount = state => _.get(state, 'bulkEdit.count', 0);

export const getEmployeess = state => _.get(state, 'bulkEdit.employees', []);
export const getAccountCodes = state =>
  _.get(state, 'bulkEdit.accountCodes', []);
export const getEpisodes = state => _.get(state, 'bulkEdit.episodes', []); // filter dropdown
export const getSets = state => _.get(state, 'bulkEdit.sets', []);
export const getWorkSchedule = state => _.get(state, 'bulkEdit.schedule', []);
export const getEpisode = state => _.get(state, 'bulkEdit.episode', []); // table dropdown
export const getOpenReviewBatches = state =>
  _.get(state, 'bulkEdit.batches', []);
export const getFilters = state => _.get(state, 'bulkEdit.filters', {});

export const getCustomFilter = (state, filterName) =>
  _.get(state, `bulkEdit.searchFilters.${filterName}`, '');

export const getTableDropdown = state =>
  _.get(state, 'bulkEdit.tableDropdown', {});

export const getFilteredDropdown = createSelector(
  [getCustomFilter, (state, filterName, selector) => selector(state)],
  (searchFilter, list) => {
    if (!searchFilter) return list;
    const filteredList = list.filter(option => {
      if (option.label === null) {
        return -1;
      }
      return (
        option.label.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0
      );
    });
    return filteredList;
  },
);
export const filteredDays = createSelector(
  [getTimecardDays, getFilters],
  (days, localFilters) => {
    const filterKeys = Object.keys(localFilters);
    if (filterKeys.length > 0) {
      const qualifiedFilters = filterKeys.filter(
        key => localFilters[key] && localFilters[key].length > 0,
      );
      if (qualifiedFilters.length > 0) {
        let filteredDays = days;
        qualifiedFilters.forEach(key => {
          filteredDays = days.filter(day => {
            const keyMap = {
              status: 'userFriendlyTimecardStatus',
            };
            return localFilters[key].indexOf(day[keyMap[key]]) >= 0;
          });
        });
        return filteredDays;
      }
    }
    return days;
  },
);
export const getInitBatchId = state => _.get(state, 'bulkEdit.batchId', null);
export const getInitBatchWeek = createSelector([getWeekEnding], weekEnding => {
  return { weekEnding: weekEnding };
});
export const getSearching = state => _.get(state, 'bulkEdit.searching', false);
export const getSaving = state => _.get(state, 'bulkEdit.saving', false);
export const getPendingCalculation = state =>
  _.get(state, 'bulkEdit.pendingCalculation', false);
export const getCalculating = state =>
  _.get(state, 'bulkEdit.calculating', false);
export const getDirtyFields = state => _.get(state, 'bulkEdit.dirtyFields', {});
export const isDirty = createSelector([getDirtyFields], dirtyFields => {
  return Object.keys(dirtyFields).length > 0;
});

export const getSortOrder = state => _.get(state, 'bulkEdit.sortOrder', []);

export const getEditedDays = state => _.get(state, 'bulkEdit.editedDays', []);
export const getEdits = state => _.get(state, 'bulkEdit.edits', {});
export const getEditableDays = state =>
  _.get(state, 'bulkEdit.editableDays', []);
export const getTcBeingSaved = state =>
  _.get(state, 'bulkEdit.tcBeingSaved', []);
export const getTcBeingCalculated = state =>
  _.get(state, 'bulkEdit.tcBeingCalculated', []);
export const getTcToBeCalculated = state =>
  _.get(state, 'bulkEdit.tcToBeCalculated', []);
export const getInitLoad = state => _.get(state, 'bulkEdit.initialLoad', false);
export const getCalculateResults = state =>
  _.get(state, 'bulkEdit.calculateResults', {});
export const getSaveResults = state => _.get(state, 'bulkEdit.saveResults', {});
export const getUserClickedSave = state =>
  _.get(state, 'bulkEdit.lastSave', false);
export const getWECount = state =>
  _.get(state, 'bulkEdit.weekEndingCount', null);
export const getSelectedCount = state =>
  _.get(state, 'bulkEdit.tcSelectedCount', 0);
export const getResultWE = state => _.get(state, 'bulkEdit.resultsWE', null);
export const getFilterSelected = state =>
  _.get(state, 'bulkEdit.filterSelected', false);
export const getListByFilterName = filterName => state => {
  return _.get(state, `bulkEdit.filters.${filterName}`, []);
};
export const getFilterSelectedCount = filterName =>
  createSelector(
    [getListByFilterName(filterName)],
    list => list.filter(f => f.selected === true).length,
  );
export const getProspectiveTimecard = state =>
  _.get(state, 'bulkEdit.prospectiveTimecard', {});
