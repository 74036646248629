import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Autocomplete, Popper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import CopyToAll from './CopyToAll';
import Portal from 'components/Shared/ReactTable/Portal';

import { db } from './DataGridTableUtils';

const useStyles = makeStyles(({ palette }) => ({
  // AceSelectCell: {},
  textField: {
    //Used to  remove the space reserved for the autocomplete clearX
    '& .MuiInput-root': {
      paddingRight: '10px !important',
    },
    //Used to hide the autocomplete clearX
    '& .MuiAutocomplete-clearIndicator': {
      display: 'none',
    },
  },
  textFieldInput: {
    fontSize: '14px',
  },
}));

const AceSelectCell = props => {
  const {
    api,
    value,
    id,
    field,
    episodeOptions,
    readOnly,
    row,
    episodeRequired,
  } = props;

  const { isEditable } = row;
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const highLightedRef = React.useRef(null);

  const { editable } = api;

  const anchorRef = React.useRef(null);
  const [ctaAnchor, setCtaAnchor] = React.useState(null);

  React.useEffect(() => {
    if (anchorRef.current && !ctaAnchor) {
      setCtaAnchor(anchorRef.current);
    }
  }, [ctaAnchor]);

  const textRef = React.useRef(null);
  React.useEffect(() => {
    if (!readOnly) {
      setOpen(true);
      textRef.current.focus();
    }
  }, [readOnly]);

  const AutoPopper = props => {
    const { style } = props;
    if (style?.width) {
      //Allow options popper to be wider than Autocomplete comp
      delete style.width;
    }
    return <Popper {...props} />;
  };

  AutoPopper.propTypes = {
    style: PropTypes.object,
  };

  const copyToAll = React.useCallback(() => {
    api.update({ id, field, value, cta: true });
  }, [api, field, id, value]);

  return (
    <>
      <Autocomplete
        options={episodeOptions}
        value={value || null}
        open={open}
        disableClearable={episodeRequired}
        clearOnEscape={!episodeRequired}
        sx={{ width: '100%' }}
        readOnly={readOnly || !isEditable}
        disabled={!editable}
        renderInput={params => {
          textRef.current = params.inputProps.ref.current;
          if (!isEditable) {
            delete params.InputProps.endAdornment;
          }
          params.inputProps.className = classes.textFieldInput;
          return (
            <TextField
              className={classes.textField}
              {...params}
              ref={textRef}
            />
          );
        }}
        PopperComponent={AutoPopper}
        getOptionLabel={o => {
          return o.code || '';
        }}
        onChange={(e, option, reason) => {
          const value = option;
          api.update({ id, field, value });
        }}
        onOpen={(e, reason) => {
          setOpen(true);
        }}
        onClose={(e, reason) => {
          db('onClose', reason);
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        onKeyDown={e => {
          if (e.key === 'Tab') {
            const value = highLightedRef.current;
            if (open) api.update({ id, field, value });
          }
          if (
            (e.metaKey || e.ctrlKey) &&
            e.shiftKey &&
            e.key.toLowerCase() === 'a'
          ) {
            e.preventDefault();
            copyToAll();
          }
        }}
        onHighlightChange={(e, option) => {
          highLightedRef.current = option;
        }}
      />
      <div ref={anchorRef} />
      {!readOnly && (
        <Portal
          variant="ace"
          targetDom={ctaAnchor}
          dropdown
          parentTableRef={api.rootElementRef}
        >
          <CopyToAll copyToAll={copyToAll} />
        </Portal>
      )}
    </>
  );
};

AceSelectCell.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
  }),
  api: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  episodeOptions: PropTypes.array.isRequired,
  episodeRequired: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  row: PropTypes.object,
};

export default AceSelectCell;
