import { produce } from 'immer';
import * as actions from 'actions/project';

export const initialState = {
  list: [],
  loading: false,
  departments: [],
  current: {},
  stats: {},
  startProject: {},
  validatingStart: false,
  loadingProjectUsers: false,
  viewingYear: null,
  totalCount: 0,
  searchText: null,
  filters: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.loading}`:
        draft.loading = action.loading;
        break;

      case `${actions.store}`:
        draft.list = action.projects;
        break;

      case `${actions.storeStats}`:
        draft.stats = action.stats;
        break;

      case `${actions.storeDetails}`:
        if (action?.project?.region) {
          localStorage.setItem('region', action.project.region);
        }
        draft.current = action.project;
        break;

      case `${actions.storeDepartments}`:
        draft.departments = action.departments;
        break;

      case `${actions.startProjectResult}`:
        draft.startProject = action.result;
        break;

      case `${actions.validatingStart}`:
        draft.validatingStart = action.validating;
        break;

      case `${actions.storeProjectUsers}`:
        draft.users = action.users;
        break;

      case `${actions.storeProjectDepartments}`:
        draft.departments.push(action.department);
        break;

      case `${actions.loadingProjectUsers}`:
        draft.loadingProjectUsers = action.loading;
        break;
      case `${actions.setViewingYear}`:
        draft.viewingYear = action.viewingYear;
        break;
      case `${actions.storeProjectsCount}`:
        draft.totalCount = action.totalCount;
        break;
      case `${actions.storeSearchText}`:
        draft.searchText = action.search;
        break;
      case `${actions.onSelect}`:
        const selected =
          draft.filters[action.filterName][action.index].selected;
        draft.filters[action.filterName][action.index].selected = !selected;
        break;
      case `${actions.storeFilterOptions}`:
        draft.filters[action.filterName] = action.options;
        break;
      default:
    }
  });
