import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { reduxForm } from 'redux-form';

//components
import TimeEntrySettingsComponent from 'components/Admin/Projects/Settings/TimeEntrySettings';

//selectors
import { getInitialSettings } from 'selectors/project';
import { getTimeEntryOptions } from 'selectors/settings';
import { updateTimeEntrySettings } from 'actions/settings';

const formName = 'TimeEntrySettings';

const mapStateToProps = state => ({
  initialValues: getInitialSettings(state, formName),
  options: getTimeEntryOptions(state),
});

const onSubmit = (values, dispatch) => {
  dispatch(updateTimeEntrySettings());
};

class TimeEntrySettings extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <TimeEntrySettingsComponent {...this.props} />
      </form>
    );
  }
}

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnChange: true,
    onSubmit,
  }),
)(TimeEntrySettings);
