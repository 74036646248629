import React from 'react';
import _ from 'lodash';
import pluralize from 'pluralize';
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Tooltip,
  // Paper,
} from '@mui/material';
import { FaTimes as RemoveIcon } from 'react-icons/fa';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'redux-form';
import {
  CardHeaderPlain as CardHeader,
  CardContent,
} from 'components/Shared/Card';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    marginBottom: 10,
    '& > *': {
      color: palette.getDefaultColor,
    },
  },
  colorPrimary: {
    color: palette.secondary.light,
  },
  tableBody: {
    border: 1,
    '& > tr:nth-child(even)': {
      backgroundColor: palette.primary.table,
    },
  },
  tableHead: {
    backgroundColor: palette.misc.tableHeader,
    textTransform: 'uppercase',
    fontWeight: 700,
    '& > *': {
      fontWeight: 700,
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  tableCell: {
    color: palette.text.primary,
    paddingTop: 5,
    paddingBottom: 5,
  },
  smallIcon: {
    width: 16,
    height: 16,
  },
  small: {
    width: 48,
    height: 48,
    padding: 8,
  },
  conent: {
    width: '100%',
    border: '1px solid',
    borderColor: palette.gray['+6'],
    borderBottom: 0,
  },
  partialWarning: {
    marginLeft: 12,
    '& > svg': {},
  },
  nameCell: { display: 'flex' },
}));

export default function CrewMembers({
  employees,
  onRemove,
  statusBadges,
  timecardStatus,
  crewPartialDays,
}) {
  const classes = useStyles();

  function renderEmployeeRows() {
    if (_.isEmpty(employees) || employees.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={4}>No Crew Selected</TableCell>
        </TableRow>
      );
    }

    return employees.map(employee => {
      if (employee && employee.id) {
        const activeDeal = employee.dealMemos.find(
          dm => employee.occupationCode === dm.jobDescription,
        );
        const empGuaranteedAllowances = activeDeal.dealMemoAllowances
          .filter(allow => allow.payCode1?.name || allow.payCode2?.name)
          ?.map((allow, index) => {
            const amount =
              allow.allowancesAmount === 0
                ? !!allow.rate
                  ? allow.rate
                  : 0
                : allow.allowancesAmount;
            const allowanceType =
              allow.payCode1?.name || allow.payCode2?.name || null;
            const payFrequency = allow.frequency === 'D' ? 'day' : 'week'; // payFreq will be week if frequency null or F
            return {
              id: index,
              amount,
              payFrequency,
              allowanceType,
            };
          });
        return (
          <TableRow key={employee.id}>
            <TableCell className={classes.tableCell}>
              <div className={classes.nameCell}>
                {employee.fullName}
                {crewPartialDays[employee.id] && (
                  <section className={classes.partialWarning}>
                    <Tooltip
                      title={
                        <span style={{ fontSize: 12 }}>
                          This employee cannot work some days this week as per
                          their deal memo. Their timecard will be created
                          without these days.
                        </span>
                      }
                    >
                      <ErrorOutlineIcon style={{ color: 'red' }} />
                    </Tooltip>
                  </section>
                )}
              </div>
            </TableCell>
            <TableCell className={classes.tableCell}>
              {employee.occupationCode || 'Pending'}
            </TableCell>
            <TableCell>
              {empGuaranteedAllowances?.map(allow => (
                <div key={allow.id}>
                  {`$${allow.amount}/${allow.payFrequency} - ${allow.allowanceType}`}
                </div>
              ))}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {statusBadges.label[employee.timecardStatus]}
            </TableCell>
            <TableCell className={classes.tableCell}>
              <IconButton
                className={classes.small}
                onClick={() => onRemove(employee.id)}
                disabled={timecardStatus !== 'incomplete'}
                size="large"
              >
                <RemoveIcon className={classes.smallIcon} />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      }
      return null;
    });
  }

  return (
    <Card className={classes.root}>
      <CardHeader>
        <Typography variant="h6" color="inherit">
          <b>{'Crew Members'}</b>
        </Typography>
        <Typography color="inherit">
          {'THIS TIMECARD WILL BE CREATED FOR '}
          <span className={classes.colorPrimary}>
            {pluralize('CREW MEMBER', employees.length, true)}
          </span>
        </Typography>
      </CardHeader>
      <CardContent>
        <Field component="input" type="hidden" name="users" value={employees} />
        <div className={classes.conent}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHead}>
                <TableCell>Employee</TableCell>
                <TableCell>{'Occupation'}</TableCell>
                <TableCell>{'Guaranteed Allowances'}</TableCell>
                <TableCell>{'Status'}</TableCell>
                <TableCell>{'Remove'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {renderEmployeeRows()}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
}
