import _ from 'lodash';

import moment from 'utils/moment';
import { makeMaskFunc, workableDay } from 'utils/weekUtils';

export function getFilteredNotes(data) {
  var timecardNotes = data;
  var noteType = note =>
    (note.type === 'timecard' ? note.createdAt : note.referenceDate) +
    note.userName +
    note.type;

  return _.chain(timecardNotes)

    .groupBy(noteType)
    .map(notes =>
      _.chain(notes)
        .groupBy(note => note.type)
        .reduce((memo, notes, type) => {
          memo = memo.concat(notes);
          return memo;
        }, [])
        .value(),
    )
    .reduce((memo, notes) => {
      return memo.concat(notes) || [];
    })
    .reject(
      note =>
        // Ignore usernames that start with “/” because they are WorkSight ID’s
        // like “/1444/3465/29/” that have been submitted from within WorkSight.
        // These comments are linked up to the user’s name later by Hours+, so
        // we display the comment with the human-readable name.
        note.rerateRemoved || note.userName[0] === '/' || note.comment === null,
    )
    .sortBy(note => {
      return parseInt(moment(note.createdAt).format('x'), 10);
    })
    .reverse()
    .value();
}

export const isSigned = item =>
  item.action === 'approve' &&
  item.actionable &&
  item.actionable.role === 'employee';

export const getDays = (days, payrollSettings, workWeek) => {
  const hasDaysData = _.find(days, day => !!day.htgDayTypeId);

  return _.map(days, day => {
    let accountCode = day.accountCode;
    if (payrollSettings && payrollSettings.accountCodeMask) {
      accountCode = makeMaskFunc(payrollSettings.accountCodeMask)(
        day.accountCode,
      );
    }

    const htgCityId = day ? day.htgCityId : null;

    const isActive = hasDaysData
      ? !!day.htgDayTypeId || !!day.isActive
      : workableDay(workWeek, day.date);

    return {
      ...day,
      accountCode,
      htgCityId,
      isActive,
    };
  });
};

const setAll = (obj, val) => Object.keys(obj).forEach(k => (obj[k] = val));

export const getInActiveDay = day => {
  const id = day.id;
  const date = day.date;
  setAll(day, null);

  return {
    ...day,
    id,
    date,
    splits: [],
    isActive: false,
  };
};

export const getEmptyDay = day => {
  const id = day.id;
  const date = day.date;

  return {
    id,
    date,
  };
};

export function createAllowanceData(allowance) {
  let data = {};
  const allowance_type = {
    value: allowance.isDefaultAllowance
      ? allowance.defaultAllowanceId
      : allowance.htgAllowanceTypeId,
    isDefaultAllowance: allowance.isDefaultAllowance || false,
  };
  if (allowance.document) {
    data = new FormData();
    data.append('id', allowance.id);
    data.append('amount', allowance.amount);
    data.append('allowance_type', allowance_type);
    data.append('document', allowance.document);
    data.append('isDefaultAllowance', allowance.isDefaultAllowance || false);
    data.append('htg_allowance_type_id', allowance.htgAllowanceTypeId);
    if (allowance.isDefaultAllowance) {
      data.append('default_allowance_id', allowance.defaultAllowanceId);
    }
  } else {
    data = {
      amount: allowance.amount,
      document: null,
      filename: allowance.filename,
      htg_allowance_type_id: allowance.htgAllowanceTypeId,
      isDefaultAllowance: allowance.isDefaultAllowance || false,
      s3_path: allowance.s3Path,
      token: allowance.token,
      allowance_type,
    };
    if (allowance.isDefaultAllowance) {
      data.default_allowance_id = allowance.defaultAllowanceId;
    }
  }
  return data;
}

export const getCity = (states = [], stateId, cityId) => {
  let city = null;

  if (states.length > 0 && stateId && cityId) {
    const state = _.find(states, record => record.id === stateId);
    if (state && state.cities) {
      city = _.find(state.cities, record => record.id === cityId);
    }
  }
  return city;
};
