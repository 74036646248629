import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip, LinearProgress, Paper } from '@mui/material';

import pluralize from 'pluralize';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const UploadProgressCard = props => {
  const { inProgress } = props;

  return (
    <Paper
      key={inProgress.uploadId}
      sx={{
        width: 100,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        mx: '10px',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <Tooltip title="Cancel Upload">
          <IconButton onClick={() => inProgress.cancel()}>
            <CancelOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>Uploading</Box>
      <Box>
        {inProgress.fileCount} {`${pluralize('file', inProgress.fileCount)}`}
      </Box>
      <Box sx={{ width: '90%', textAlign: 'center' }}>
        <LinearProgress
          variant="determinate"
          value={inProgress.percentComplete}
        />
        {inProgress.percentComplete}%
      </Box>
    </Paper>
  );
};

UploadProgressCard.propTypes = { inProgress: PropTypes.object.isRequired };

export default UploadProgressCard;
