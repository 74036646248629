import React from 'react';
import {
  MaxWidthLayout,
  Header,
  AlertHeader,
  ProjectHeader,
} from 'containers/Layout';

import { EnhancedRoute } from 'containers/RoutingSupport';
import { BulkEdit } from 'containers/Employees/Reviews/BulkEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <EnhancedRoute
      authenticated
      key="/projects/reviews/bulk-edit"
      id="/projects/reviews/bulk-edit"
      path="/projects/:projectId/review/bulk-edit"
      layout={MaxWidthLayout}
      headers={[Header, ProjectHeader, AlertHeader]}
      component={BulkEdit}
      isLoadProjectActiveUser={true}
      title="Bulk Edit"
    />
  );
};
