import { buildUrl } from 'providers/api';

const URL_REVIEW_FLOWS = '/projects/:projectId/review-flows';
const URL_APPROVAL_FLOW = '/projects/:projectId/review-flows/:reviewFlowId';
const URL_WORKFLOW_DEPARTMENTS =
  '/projects/:projectId/review-flows/:reviewFlowId/departments';
const URL_WORKFLOW_STEPS =
  '/projects/:projectId/review-flows/:reviewFlowId/steps';
const URL_EDIT_APPROVAL_FLOW_STEP =
  '/projects/:projectId/review-flows/:reviewFlowId/steps/:stepId';
const URL_WORKFLOW_STEP_USERS =
  '/projects/:projectId/review-flows/:reviewFlowId/steps/:stepId/users';
const URL_REMOVE_REVIEWER =
  '/projects/:projectId/review-flows/:reviewFlowId/steps/:stepId/users/:userId';
const URL_UPDATE_WORKFLOW_DEPARTMENTS =
  '/projects/:projectId/review-flows/:reviewFlowId/departments/:departmentId';
const URL_REVIEW_FLOW_LEVELS = '/projects/:projectId/review-flows/levels';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async list({ projectId }) {
    const url = buildUrl(URL_REVIEW_FLOWS, { projectId });
    const response = await clientV2.get(url);
    return response.data;
  },

  async approvalFlow({ projectId, reviewFlowId }) {
    const url = buildUrl(URL_APPROVAL_FLOW, { projectId, reviewFlowId });
    const response = await clientV2.get(url);
    return response.data;
  },

  async departments({ projectId, reviewFlowId }) {
    const url = buildUrl(URL_WORKFLOW_DEPARTMENTS, {
      projectId,
      reviewFlowId,
    });
    const response = await clientV2.get(url);
    return response.data;
  },

  async reviewFlowSteps({ projectId, reviewFlowId }) {
    const url = buildUrl(URL_WORKFLOW_STEPS, { projectId, reviewFlowId });
    const response = await clientV2.get(url);
    return response.data;
  },

  async reviewFlowStepUsers({ projectId, reviewFlowId, stepId }) {
    const url = buildUrl(URL_WORKFLOW_STEP_USERS, {
      projectId,
      reviewFlowId,
      stepId,
    });
    const response = await clientV2.get(url);
    return response.data;
  },

  async removeReviewer({ projectId, reviewFlowId, stepId, userId }) {
    const url = buildUrl(URL_REMOVE_REVIEWER, {
      projectId,
      reviewFlowId,
      stepId,
      userId,
    });

    const response = await clientV2.delete(url);

    return response.data;
  },

  async updateReviewers({ projectId, reviewFlowId, stepId, users }) {
    const url = buildUrl(URL_EDIT_APPROVAL_FLOW_STEP, {
      projectId,
      reviewFlowId,
      stepId,
    });
    const response = await clientV2.put(url, { users });

    return response.data;
  },

  async saveReviewers({ projectId, reviewFlowId, role, users }) {
    const url = buildUrl(URL_WORKFLOW_STEPS, { projectId, reviewFlowId });

    const response = await clientV2.post(url, { role, users });
    return response.data;
  },

  async removeApprovalFlow({ projectId, reviewFlowId }) {
    const url = buildUrl(URL_APPROVAL_FLOW, { projectId, reviewFlowId });

    const response = await clientV2.delete(url);

    return response.data;
  },

  async addApprovalFlow({ projectId }) {
    const url = buildUrl(URL_REVIEW_FLOWS, { projectId });

    const response = await clientV2.post(url);

    return response.data;
  },

  async saveDepartment({ projectId, reviewFlowId, department }) {
    const url = buildUrl(URL_WORKFLOW_DEPARTMENTS, {
      projectId,
      reviewFlowId,
    });

    const response = await clientV2.post(url, { ...department });

    return response.data;
  },

  async updateDepartment({ projectId, reviewFlowId, department }) {
    const departmentId = department.departmentId;
    const url = buildUrl(URL_UPDATE_WORKFLOW_DEPARTMENTS, {
      projectId,
      reviewFlowId,
      departmentId,
    });

    const response = await clientV2.put(url, { ...department });

    return response.data;
  },

  async deleteDepartment({ projectId, reviewFlowId, departmentId }) {
    const url = buildUrl(URL_UPDATE_WORKFLOW_DEPARTMENTS, {
      projectId,
      reviewFlowId,
      departmentId,
    });

    const response = await clientV2.delete(url);

    return response.data;
  },

  async addExistingDepartment({ projectId, reviewFlowId, data }) {
    const url = buildUrl(URL_APPROVAL_FLOW, {
      projectId,
      reviewFlowId,
    });

    const response = await clientV2.put(url, { ...data });

    return response.data;
  },
  async reviewLevels({ projectId }) {
    const url = buildUrl(URL_REVIEW_FLOW_LEVELS, {
      projectId: projectId,
    });

    const response = await clientV2.get(url);
    return response.data;
  },
});
