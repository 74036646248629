/*

  Usage:
  <AccordionSummary   variant="iconOnRight" />

*/

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  MuiAccordionSummary: {
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        const { palette } = theme;
        let newStyles = {
          display: 'flex',
          flexDirection: 'row-reverse',
          minHeight: 8 * 7,
          transition:
            ' 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          padding: '0 32px 0 16px',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: palette.action.hover,
          },
          '&.Mui-expanded': {
            minHeight: 56,
          },
          '&.Mui-focusVisible': {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.info.main}`,
          },
          '&.Mui-disabled': {
            opacity: 0.38,
          },
        };

        return newStyles;
      },
      content: {
        display: 'flex',
        flexGrow: 1,
        transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        margin: 0,
        paddingLeft: 16,

        '&.Mui-expanded': {
          margin: 0,
        },
      },
      expandIconWrapper: ({ ownerState, theme }) => {
        const { palette } = theme;

        return {
          backgroundColor: palette.primary.background,
          color: palette.primary.main,
          borderRadius: 25,
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&.Mui-expanded': {
            color: palette.primary.background,
            backgroundColor: palette.primary.main,
            transform: 'rotate(180deg)',
          },
        };
      },
    },
    variants: [
      {
        props: { variant: 'iconOnRight' },
        style: ({ theme: { palette } }) => ({
          display: 'flex',
          color: palette.text.secondary,
          flexDirection: 'row',
          width: 'fit-content',
          '&:hover': { backgroundColor: 'transparent' },
          '.MuiAccordionSummary-content': {
            padding: '0px 16px 0px 0px',
          },
        }),
      },
      {
        props: { variant: 'iconOnFarRight' },
        style: () => ({
          flexDirection: 'row',
          '.MuiAccordionSummary-content': {
            padding: '0px',
          },
        }),
      },
    ],
  },
};
