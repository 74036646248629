import React from 'react';
import { Switch, FormControlLabel, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  toggleBox: {
    padding: '16px 32px',
    width: '100%',
  },
  toggleSupplementText: {
    fontSize: 14,
  },
  customFormControlLabel: {
    // flexDirection: 'column-reverse',
    marginLeft: 0,
  },
  customText: {
    fontSize: '1.1rem',
    fontWeight: 500,
  },
}));

const FormControlSwitch = ({
  boxStyle,
  checked,
  onChange,
  label,
  supplementText,
}) => {
  const classes = useStyles();
  return (
    <div className={boxStyle ? boxStyle : classes.toggleBox}>
      <Typography className={classes.toggleSupplementText}>
        {`${label}`}
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={checked ? true : false}
            onChange={typeof onChange === 'function' ? onChange : () => {}}
            color="primary"
          />
        }
        label={'Enable'}
        classes={{
          root: classes.customFormControlLabel,
          label: classes.customText,
        }}
      />
      {/* <Typography className={classes.toggleSupplementText}>
        {`${supplementText}`}
      </Typography> */}
    </div>
  );
};

export default FormControlSwitch;
