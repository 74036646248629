import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { currentUser } from 'selectors/session';
import { LANDING_PAGE_BY_ROLE } from 'components/Shared/constants';
import { profileProps } from 'components/props/profiles';

const mapStateToProps = (state, { match }) => ({
  projectId: match.params.projectId,
  currentUser: currentUser(state),
});

class ProjectLander extends Component {
  static propTypes = {
    projectId: PropTypes.string.isRequired,
    currentUser: profileProps.isRequired,
  };

  render() {
    const { projectId, currentUser } = this.props;

    const redirectUrl = LANDING_PAGE_BY_ROLE(currentUser.role, projectId);

    return <Redirect to={{ pathname: redirectUrl }} />;
  }
}

export default connect(mapStateToProps)(ProjectLander);
