import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const ResizeBox = props => {
  const { enableResize } = props;

  return (
    <Box
      sx={{
        cursor: 'nwse-resize',
        height: 20,
        width: 20,
        position: 'absolute',
        right: 0,
        bottom: 0,
      }}
      onMouseDown={enableResize}
    >
      <Box
        sx={{
          rotate: '315deg',
          position: 'absolute',
          right: '22px',
          bottom: '8px',
        }}
      >
        <Box
          sx={{
            height: '1px',
            width: '20px',
            backgroundColor: 'gray.main',
            top: '10px',
            left: '0px',
            position: 'absolute',
          }}
        />
        <Box
          sx={{
            height: '1px',
            width: '12px',
            backgroundColor: 'gray.main',
            top: '13px',
            left: '4px',
            position: 'absolute',
          }}
        />
        <Box
          sx={{
            height: '1px',
            width: '6px',
            backgroundColor: 'gray.main',
            top: '16px',
            left: '7px',
            position: 'absolute',
          }}
        />
      </Box>
    </Box>
  );
};

ResizeBox.propTypes = { enableResize: PropTypes.func.isRequired };

export default ResizeBox;
