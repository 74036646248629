import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { Field } from 'redux-form';
import MoveTimecardsTable from './MoveTimecardTable';
import {
  Typography,
  LinearProgress,
  Checkbox,
  FormControlLabel,
  IconButton,
  FormGroup,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import HomeIcon from '@mui/icons-material/Home';

//components
import { TextField } from 'components/Shared/redux';
import ConfirmMoveTimecard from './ConfirmMoveTimecard';
import LockStatusIcon from 'components/Employees/Reviews/WTC/Drawer/LockStatusIcon';
import FilterTextField from 'components/Shared/FilterTextField';

import { PAYROLL_COMPANY_CODE_TO_TYPE } from 'components/Shared/constants';

const useStyles = makeStyles(({ palette }) => ({
  MoveTimecards: {},
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    color: palette.text.primary,
    maxHeight: 'calc(100vh - 350px)',
    minHeight: 'max(250px,min(500px,calc(50vh)))', //min height between 300 and 500
  },

  list: {
    width: 350,
    margin: '0px 8px 0px 8px ',
    display: 'flex',
    flexDirection: 'column',
  },
  timecardList: {
    justifyContent: 'flex-start',
    width: 450,
  },
  listOuter: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  listInner: {
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    flexWrap: 'nowrap',
  },
  destinationList: {
    justifyContent: 'space-between',
    overflow: 'hidden',
    marginTop: '50px',
  },

  checkbox: {
    display: 'block',
    marginLeft: 0,
  },
  addBatchSection: {
    display: 'flex',
    backgroundColor: palette.gray['+9'],
  },
  formControlRow: {
    fontSize: 14,
    height: 40,
    cursor: 'pointer',
    transition: 'background-color 1.5s',
    transitionTimingFunction: 'cubic-bezier(0.0, 1, 0.5, .85)',
    '& > span': {
      fontSize: 14,
    },
  },
  evenRow: {
    backgroundColor: palette.primary.table,
    borderTop: `1px solid ${palette.primary['+8']}`,
    borderBottom: `1px solid ${palette.primary['+8']}`,
  },
  label: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
    width: 300,
    padding: 10,
  },
  listTitle: {
    color: palette.primary.main,
    padding: '10px 5px',
    whiteSpace: 'nowrap',
    fontSize: 14,
  },
  newItemField: {
    flexGrow: 1,
    '& > .MuiInput-root': {
      margin: '0 !important',
    },
    '& > .MuiInputLabel-formControl:not(.Mui-focused)': {
      top: -5,
      left: 10,
    },
  },
  selected: {
    backgroundColor: palette.primary['+7'],
  },
  disabledItem: {
    color: palette.gray['+8'],
    cursor: 'default',
  },
  invoiceHover: {
    backgroundColor: palette.gray['+7'],
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: palette.primary['+8'],
    borderBottom: `1px solid ${palette.primary.main}`,
    minHeight: 45,
  },
  filterTextField: {
    '& .MuiInputBase-input': { fontSize: 14 },
    '& .MuiInputBase-root': { height: 20 },
    width: 150,
    padding: '10px 5px',
  },
  sourceBatch: {
    cursor: 'default',
  },
  sourceIcon: {
    margin: '-3px 3px -5px 0px',
    color: palette.primary.main,
  },
  invoiceDate: {
    color: palette.warning.main,
    fontWeight: 700,
  },
}));

const ALL_INVOICES_ID = -1;

const ALLOWED_CHARS = /^[A-Z0-9_&().,_/ -]*$/i;
const normalizeNames = (val, prev) => {
  if (val.length > 100) return prev;
  const isValid = !!val.match(ALLOWED_CHARS);
  if (isValid) {
    return val;
  }
  return prev;
};

const MoveTimecards = props => {
  const classes = useStyles();
  const {
    change,
    batchTimecards,
    formError,
    batches,
    invoices,
    moveLoading,
    formValues,
    sourceBatch,
    userLevel,
    isUPM,
    isPA,
  } = props;

  const {
    destinationBatchId,
    destinationInvoiceId,
    /*destinationInvoiceId will be:
        Number: 
          -1: for ALL batches
          0 for no invoice //HOUR-7196
        String:
          'NEW' for a new invoice 
          worksightId (UUID) for actual invoiceId
    */
    newInvoiceChargeDesc,
    newBatchName,
    timecardIds,
  } = formValues;

  const [hoveredBatchInvoiceId, setHoveredBatchInvoiceId] = useState(null);
  const [invoiceFilter, setInvoiceFilter] = useState('');
  const [batchFilter, setBatchFilter] = useState('');

  //invoice selected ensures the current invoice is on page on load
  const [invoiceSelected, setInvoiceSelected] = useState(false);

  const hoveredInvoiceRef = useRef(null);
  const hoveredInvoiceTimeout = useRef(null);
  const selectedInvoiceRef = useRef(null);
  const selectedBatchRef = useRef(null);

  useEffect(() => {
    if (hoveredInvoiceRef.current) {
      hoveredInvoiceRef.current.scrollIntoView();
    }
  });

  // onMouseLeave isn't getting fired if mouse moves quickly
  useEffect(() => {
    clearTimeout(hoveredInvoiceTimeout.current);
    if (hoveredBatchInvoiceId) {
      hoveredInvoiceTimeout.current = setTimeout(() => {
        setHoveredBatchInvoiceId(null);
      }, 1500);
    }
  }, [hoveredBatchInvoiceId]);

  //keep selected invoice in view when filter is cleared
  useEffect(() => {
    if (
      invoiceFilter === '' &&
      typeof destinationInvoiceId === 'string' &&
      selectedInvoiceRef.current
    ) {
      selectedInvoiceRef.current.scrollIntoView();
    }
  }, [destinationInvoiceId, invoiceFilter, invoiceSelected]);

  // keep selected batch in view when filter is cleared
  useEffect(() => {
    if (
      batchFilter === '' &&
      typeof destinationBatchId === 'number' &&
      selectedBatchRef.current
    ) {
      selectedBatchRef.current.scrollIntoView();
    }
  }, [batchFilter, destinationBatchId]);

  //sub components
  const renderCheckbox = ({ input, label, onCheck, index, disabled }) => {
    const onChange = onCheck
      ? e => {
          onCheck(e);
          input.onChange(e);
        }
      : input.onChange;

    return (
      <FormControlLabel
        label={label}
        className={clsx(classes.checkbox, classes.formControlRow)}
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={onChange}
            color="primary"
            disabled={disabled}
          />
        }
      />
    );
  };
  const ListLabel = ({ label, lockStatus, isSource = false }) => {
    const title = (
      <span>
        {!!isSource && '(Source) '}
        {label}
      </span>
    );

    return (
      <div className={classes.label}>
        {lockStatus !== 'unlock' && <LockStatusIcon lockStatus={lockStatus} />}
        <Tooltip arrow title={title} enterDelay={1250}>
          <span>
            {isSource && <HomeIcon className={classes.sourceIcon} />}
            {label}
          </span>
        </Tooltip>
      </div>
    );
  };

  ListLabel.propTypes = {
    label: PropTypes.node.isRequired,
    className: PropTypes.string,
    lockStatus: PropTypes.string,
    isSource: PropTypes.bool,
    suffix: PropTypes.string,
  };

  const scrollNewItemIntoView = (id, count = 5) => {
    const newItem = document.getElementById(id);
    if (newItem) {
      newItem.scrollIntoView();
      newItem.click();
    } else if (count > 0) {
      setTimeout(scrollNewItemIntoView, 300, id, count - 1);
    }
  };

  const makeBatchNameUnique = name => {
    let i = 1;
    let draftName = name;

    const findNameInBatchList = batch => {
      return batch.name === draftName;
    };

    let result = _.find(batchList, findNameInBatchList);
    while (result) {
      draftName = `${name}_${i}`;
      i++;
      result = _.find(batchList, findNameInBatchList);
    }
    return draftName;
  };

  const handleClickInvoice = invoiceId => {
    change('destinationInvoiceId', invoiceId);
    change('destinationBatchId', undefined);
    change('newBatchName', '');
    setBatchFilter('');
    setInvoiceFilter('');
  };
  const handleClickBatch = id => {
    change('destinationBatchId', id);
    setBatchFilter('');
    if (destinationInvoiceId === ALL_INVOICES_ID) {
      const batch = batches.find(b => b.id === id);
      change('destinationInvoiceId', batch.invoiceId);
      // change('destinationInvoiceId', batch.invoiceId || 0); //HOUR-7196
      setHoveredBatchInvoiceId(null);
    }
  };

  const handleAddInvoice = e => {
    const input = document.getElementById('newInvoiceChargeDescDraft');

    change('newInvoiceChargeDesc', input.value);
    //need both to clear out the field based on different ways of submitting
    change('newInvoiceChargeDescDraft', ''); //clear on + click
    input.value = ''; //clear on hit enter

    scrollNewItemIntoView('newInvoiceItem');
    document.getElementById('newBatchNameDraft').focus();
  };

  const handleAddBatch = e => {
    const input = document.getElementById('newBatchNameDraft');

    const date = moment(endsOn).format('MM-DD-YYYY');
    const uniqueName = makeBatchNameUnique(`${input.value} - ${date}`);
    change('newBatchName', uniqueName);

    //need both to clear out the field based on different ways of submitting
    change('newBatchNameDraft', ''); //clear on + click
    input.value = ''; //clear on hit enter

    scrollNewItemIntoView('newBatchItem');
    input.blur();
  };

  const invoiceList = invoices.filter(invoice => {
    let { name, remarks, chargeDescription, weekEnding } = invoice;
    let str = `${name}`;
    if (!!weekEnding) str += `- ${weekEnding} `;
    if (!!chargeDescription) str += `- ${chargeDescription}`;
    if (!!remarks) str += `- ${remarks}`;
    str = str.toLowerCase();
    const filter = invoiceFilter.toLowerCase();
    return str.includes(filter);
  });
  let sourceInvoiceWeekEnding = null;
  invoiceList.forEach(invoice => {
    if (invoice.id === sourceBatch.invoiceId) {
      invoice.isSource = true;
      sourceInvoiceWeekEnding = invoice.weekEnding;
    }
  });

  invoiceList.sort((a, b) => a.name - b.name);

  invoiceList.unshift({
    id: ALL_INVOICES_ID,
  });

  if (newInvoiceChargeDesc) {
    invoiceList.push({
      id: 'NEW',
      name: 'NEW', //display ID
      chargeDescription: newInvoiceChargeDesc,
    });
  }

  const endsOn = sourceBatch?.endsOn;
  const batchList = batches.filter(batch => {
    const { htgBatchNumber, name } = batch;
    const label = `${htgBatchNumber} - ${name}`.toLowerCase();
    const filter = batchFilter.toLowerCase();

    //Text Filter
    if (label.includes(filter) === false) return false;

    //All batches invoice selection
    switch (destinationInvoiceId) {
      case ALL_INVOICES_ID:
        return !!batch?.invoiceId === true;
      // case 0: //batches without invoice //HOUR-7196
      //   return !!batch?.invoiceId === false;
      default:
        return batch?.invoiceId === destinationInvoiceId;
    }
  });

  batchList.sort((a, b) => a.htgBatchNumber - b.htgBatchNumber);

  if (newBatchName) {
    batchList.push({
      id: 'NEW',
      htgBatchNumber: 'NEW', //display ID
      name: newBatchName,
      invoiceId: destinationInvoiceId,
      weekEndingDate: sourceBatch.endsOn,
    });
  }

  // prevent modal from shrinking when lists are filtered
  useEffect(() => {
    const containers = document.getElementsByClassName(classes.container);
    if (containers.length > 0) {
      const container = containers[0];
      const styles = window.getComputedStyle(container);
      if (styles.height > styles['min-height']) {
        container.style.minHeight = styles.height;
      }
    }
  }, [batchTimecards, batchList, invoiceList, classes.container]);

  if (sourceBatch) {
    return (
      <FormGroup>
        <div className={classes.container}>
          <section className={clsx(classes.list, classes.timecardList)}>
            <MoveTimecardsTable
              change={change}
              batchTimecards={batchTimecards}
              moveLoading={moveLoading}
              userLevel={userLevel}
              isUPM={isUPM}
              isPA={isPA}
              timecardIds={timecardIds}
            />
          </section>
          <section className={clsx(classes.list, classes.destinationList)}>
            <div className={classes.listOuter}>
              <div className={classes.header}>
                <Typography className={classes.listTitle}>Invoices</Typography>
                <FilterTextField
                  className={classes.filterTextField}
                  value={invoiceFilter}
                  setValue={setInvoiceFilter}
                />
              </div>
              <div className={classes.listInner}>
                {moveLoading.invoices ? (
                  <LinearProgress />
                ) : (
                  invoiceList.map((invoice, index) => {
                    const {
                      name,
                      id,
                      weekEnding,
                      remarks,
                      chargeDescription,
                      isSource,
                    } = invoice;
                    const isDiffWeekEnding =
                      weekEnding !== sourceInvoiceWeekEnding;
                    const selected = id === destinationInvoiceId;

                    const payrollCode = invoice?.payrollCompany?.code;
                    const invoiceType = payrollCode
                      ? PAYROLL_COMPANY_CODE_TO_TYPE[payrollCode]
                      : '';

                    let label;

                    if (id === ALL_INVOICES_ID) {
                      label = <span>Show All Batches</span>;
                    } else {
                      label = (
                        <span>
                          {name}
                          {!!weekEnding && (
                            <span>
                              {` - `}
                              <span
                                className={clsx({
                                  [classes.invoiceDate]: isDiffWeekEnding,
                                })}
                              >
                                {`${weekEnding}`}
                              </span>
                            </span>
                          )}
                          {!!chargeDescription?.trim() &&
                            ` - ${chargeDescription}`}
                          {!!remarks?.trim() && ` - ${remarks}`}
                          {!!invoiceType && ` - ${invoiceType}`}
                        </span>
                      );
                    }

                    if (selected && invoiceSelected === false) {
                      setInvoiceSelected(true);
                    }

                    return (
                      <div
                        id={id === 'NEW' ? 'newInvoiceItem' : null}
                        onClick={() => {
                          handleClickInvoice(id);
                        }}
                        key={`invoiceList${id}`}
                        className={clsx(classes.formControlRow, {
                          [classes.evenRow]: index % 2 === 0,
                          [classes.selected]: selected,
                          [classes.invoiceHover]: id === hoveredBatchInvoiceId,
                        })}
                        ref={
                          id === hoveredBatchInvoiceId
                            ? hoveredInvoiceRef
                            : selected === true
                            ? selectedInvoiceRef
                            : null
                        }
                      >
                        <ListLabel label={label} isSource={isSource} />
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <footer>
              <div className={classes.addBatchSection}>
                <Field
                  id="newInvoiceChargeDescDraft"
                  name={'newInvoiceChargeDescDraft'}
                  component={TextField}
                  normalize={normalizeNames}
                  props={{
                    className: classes.newItemField,
                    InputProps: {
                      endAdornment: (
                        <Tooltip
                          title={formError.addInvoiceError || ''}
                          placement="top"
                          arrow
                        >
                          <span>
                            <IconButton
                              onClick={handleAddInvoice}
                              disabled={!!formError.addInvoiceError}
                            >
                              <AddCircleOutlineIcon
                                color={
                                  !!formError.addInvoiceError
                                    ? 'disabled'
                                    : 'primary'
                                }
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      ),
                    },
                  }}
                  label="Add invoice"
                  onChange={e =>
                    change('newInvoiceChargeDescDraft', e.target.value)
                  }
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      if (!formError.addInvoiceError) {
                        handleAddInvoice();
                      }
                    }
                  }}
                />
              </div>
              <Field
                name={'voidEmptyInvoice'}
                component={renderCheckbox}
                label={'Delete invoice if empty after move'}
                disabled={true}
              />
            </footer>
          </section>
          <section className={clsx(classes.list, classes.destinationList)}>
            <div className={classes.listOuter}>
              <div className={classes.header}>
                <Typography className={classes.listTitle}>
                  Select Destination Batch
                </Typography>
                <FilterTextField
                  className={classes.filterTextField}
                  setValue={setBatchFilter}
                  value={batchFilter}
                />
              </div>
              <div className={classes.listInner}>
                {moveLoading.batches ? (
                  <LinearProgress />
                ) : (
                  batchList.map((batch, index) => {
                    const name = batch.name;
                    const id = batch.id;
                    const htgBatchNumber = batch.htgBatchNumber;
                    const isNewBatch = id === 'NEW';
                    const isSelected = id === destinationBatchId;
                    const isSource = id === sourceBatch.id;
                    const isSameWeekEnding =
                      sourceBatch.endsOn === batch.weekEndingDate;
                    const isDisabled =
                      batch.lockStatus === 'hardlock' ||
                      isSameWeekEnding === false;

                    return (
                      <div
                        id={isNewBatch ? 'newBatchItem' : null}
                        onClick={() => {
                          if (!isDisabled && !isSource) handleClickBatch(id);
                        }}
                        key={`batchList${id}`}
                        className={clsx(classes.formControlRow, {
                          [classes.evenRow]: index % 2 === 0,
                          [classes.selected]: isSelected,
                          [classes.disabledItem]: isDisabled,
                          [classes.sourceBatch]: isSource,
                        })}
                        onMouseEnter={() => {
                          if (destinationInvoiceId === ALL_INVOICES_ID) {
                            setHoveredBatchInvoiceId(batch?.invoiceId);
                          }
                        }}
                        onMouseLeave={() => setHoveredBatchInvoiceId()}
                      >
                        <ListLabel
                          label={`${htgBatchNumber} - ${name}`}
                          lockStatus={batch.lockStatus}
                          isSource={isSource}
                        />
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <footer>
              <div className={classes.addBatchSection}>
                <Field
                  id="newBatchNameDraft"
                  name={'newBatchNameDraft'}
                  component={TextField}
                  normalize={normalizeNames}
                  props={{
                    InputProps: {
                      endAdornment: (
                        <Tooltip
                          title={formError.addBatchError || ''}
                          placement="top"
                          arrow
                        >
                          <span>
                            <IconButton
                              onClick={handleAddBatch}
                              disabled={!!formError.addBatchError}
                            >
                              <AddCircleOutlineIcon
                                color={
                                  !!formError.addBatchError
                                    ? 'disabled'
                                    : 'primary'
                                }
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      ),
                    },
                  }}
                  label="Add batch"
                  onChange={e => change('newBatchNameDraft', e.target.value)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      if (!formError.addBatchError) {
                        handleAddBatch(e);
                      }
                    }
                  }}
                  className={classes.newItemField}
                />
              </div>
              <Field
                name={'deleteEmptyBatch'}
                component={renderCheckbox}
                label={'Delete batch if empty after move'}
                disabled={true}
              />
            </footer>
          </section>
        </div>

        {moveLoading.submitting && <LinearProgress />}
        <ConfirmMoveTimecard />
      </FormGroup>
    );
  } else return <></>;
};

MoveTimecards.propTypes = {
  change: PropTypes.func.isRequired,
  batchTimecards: PropTypes.array.isRequired,
  formError: PropTypes.object.isRequired,
  batches: PropTypes.array.isRequired,
  invoices: PropTypes.array.isRequired,
  moveLoading: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  sourceBatch: PropTypes.object.isRequired,
  userLevel: PropTypes.number.isRequired,
  isUPM: PropTypes.bool.isRequired,
  isPA: PropTypes.bool.isRequired,
};

export default MoveTimecards;
