import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  INITIAL_PAGE_SIZE,
  DEFAULT_SORT,
} from 'components/Employees/Reviews/SearchTimecards/searchUtils';
import { makeFilterSelectors } from './shared';

import { getFilterDbCode } from 'selectors/project';

export const getData = state => _.get(state, 'searchTimecards.data', []);
export const getTotalCount = state =>
  _.get(state, 'searchTimecards.totalCount', 0);
export const getSortBy = state => _.get(state, 'searchTimecards.sortBy', []);
export const getSelectAllFlag = state =>
  _.get(state, 'searchTimecards.selectAllFlag', []);

//translate sortBy in the format the H+ API needs it from React-Table
export const getSortByForReq = createSelector([getSortBy], (sortBy = []) => {
  if (sortBy.length === 0) return DEFAULT_SORT;
  const userSort = sortBy[0];
  const newSort = DEFAULT_SORT.slice();

  _.remove(newSort, s => s.id === userSort.id);

  newSort.unshift({
    id: userSort.id,
    order: userSort.desc ? 'desc' : 'asc',
  });

  return newSort;
});

export const getLoading = state =>
  _.get(state, 'searchTimecards.loading', false);

const getLocalPageSize = state =>
  _.get(state, 'searchTimecards.pageSize', INITIAL_PAGE_SIZE);
const getLocalPages = state => _.get(state, 'searchTimecards.pages', 0);

export const getPageSize = createSelector(
  [getLocalPageSize, getLocalPages],
  (size, pages) => {
    const val = size * pages;
    return Number.isInteger(val) ? val : INITIAL_PAGE_SIZE;
  },
);

export const getSubmitting = state =>
  _.get(state, 'searchTimecards.submittingTimecards', false);

export const getDeleting = state =>
  _.get(state, 'searchTimecards.deletingTimecards', false);

//filters

export const getFilters = state => _.get(state, 'searchTimecards.filters', []);

export const getDbCodeFilter = createSelector([getFilterDbCode], dbCode => {
  const dbCodeFilter = {
    field: 'dbCode',
    type: 'key',
    values: [dbCode],
  };

  return dbCodeFilter;
});

const filterTypes = {
  employee: 'key',
  weekEndingDate: 'key',
  status: 'key',
  department: 'key',
  invoice: 'key',
  union: 'search',
  accountCodeHeader: 'key',
  batch: 'key',
};

export const getAllFilters = createSelector(
  [getFilters, getDbCodeFilter],
  (filters, dbCodeFilter) => {
    const currentFilters = [];
    for (const filterName in filters) {
      if (Object.hasOwnProperty.call(filters, filterName)) {
        const array = filters[filterName];
        const apiFilter = {
          field: filterName,
          type: filterTypes[filterName],
          values: array.reduce((acc, val) => {
            if (val.selected) acc.push(val.value);
            return acc;
          }, []),
        };
        if (apiFilter.values.length > 0) currentFilters.push(apiFilter);
      }
    }

    currentFilters.push(dbCodeFilter);
    return currentFilters;
  },
);

export const {
  getCustomFilter,
  getListByFilterName,
  getFilteredDropdown,
  getSelectedCount,
} = makeFilterSelectors('searchTimecards');

export const getRemovedSelectedOption = state =>
  _.get(state, `searchTimecards.removedSelectedOption`, false);

export const getSelectedApprovals = state =>
  _.get(state, 'searchTimecards.selectedApprovalFlows', []);

export const getJobInfo = state => _.get(state, 'searchTimecards.jobInfo', {});
