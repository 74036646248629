import { components } from '@castandcrew/react-select';

export const showToolTip = (value, columnWidth = 100) => {
  const val = value ? `${value}` : '';
  const pxlPerLetter = 12;
  const pxlLength = val.length * pxlPerLetter;
  return columnWidth - pxlLength < 5;
};

/*
  why use a higher order function that returns the actual Menu component?
  When user re-open a rendered Select's menu, 
  it resolve an edge case by forcing the menu to re-render
  with the latest menu placement.
*/
export const CustomSelectMenu = () => props => {
  return <components.Menu {...props} />;
};
/**
 *
 * @param {*} focusedRef ref to store the currently focused option
 *
 * props.data is the option data for this option, so for most dropdowns its an object with {id,label,value}
 */
export const WatchOption = focusedRef => props => {
  const { isFocused, data } = props;

  if (isFocused) focusedRef.current = data;

  return <components.Option {...props} />;
};
