import React, { Component } from 'react';
import { Paper, Typography } from '@mui/material';
import { withStyleSheet } from 'shared/theme';

const style = ({ palette }) => ({
  root: {
    width: '100%',
    marginTop: 16,
    paddingBottom: 40,
  },
  projectContactBox: {
    padding: '20px 20px 0 20px',
  },
  projectContact: {
    color: palette.text.secondary,
    fontSize: 24,
    fontWeight: 400,
  },
  description: {
    color: palette.text.disabled,
    fontWeight: 400,
    fontSize: 14,
    paddingTop: 8,
  },
  title: {
    padding: '20px 20px 4px 20px',
    color: palette.text.secondary,
    fontSize: 14,
    fontWeight: 400,
  },
  email: {
    paddingLeft: 20,
    fontWeight: 400,
    fontSize: 15,
    color: palette.text.disabled,
  },
});

class PayrollCoordinators extends Component {
  render() {
    const {
      classes,
      payrollCoordinator = {},
      backupCoordinators = [],
    } = this.props;
    return (
      <Paper className={classes.root}>
        <div className={classes.projectContactBox}>
          <Typography className={classes.projectContact}>
            Project Contact
          </Typography>
          <Typography className={classes.description}>
            List of coordinators notified when timecards are ready for
            processing.
          </Typography>
        </div>
        <Typography className={classes.title}>Payroll Coordinators</Typography>
        {payrollCoordinator && payrollCoordinator.email ? (
          <Typography className={classes.email}>
            {payrollCoordinator.email}
          </Typography>
        ) : (
          <Typography className={classes.email}>
            {'No payroll coordinator email found'}
          </Typography>
        )}
        <Typography className={classes.title}>Backup Coordinators</Typography>
        {backupCoordinators.length > 0 ? (
          backupCoordinators.map(coordinator => {
            if (coordinator.email) {
              return (
                <Typography key={coordinator.email} className={classes.email}>
                  {coordinator.email}
                </Typography>
              );
            }
            return '';
          })
        ) : (
          <Typography className={classes.email}>
            No backup coordinators email found
          </Typography>
        )}
      </Paper>
    );
  }
}

export default withStyleSheet(
  'PayrollCoordinators',
  style,
)(PayrollCoordinators);
