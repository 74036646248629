import _ from 'lodash';

export const isRendered = (state, dialog) =>
  _.get(state, `modalDialog.${dialog}.isRendered`, false);

export const getModalParams = (state, dialog) => {
  if (!dialog) console.error('getModalParams: dialog is not defined');
  return _.get(state, `modalDialog.${dialog}.modalParams`, {});
};

export const getSource = (state, dialog) =>
  _.get(state, `modalDialog.${dialog}`, {});

export const projectId = (state, dialog) =>
  _.get(state, `modalDialog.${dialog}.projectId`, 0);

export const getModalVisible = state => {
  const modalState = _.get(state, 'modalDialog');
  for (const key in modalState) {
    if (Object.hasOwnProperty.call(modalState, key)) {
      const modal = modalState[key];
      if (modal.isRendered) return true;
    }
  }
  return false;
};
