import React, {
  // forwardRef,
  useState,
  useRef,
  useEffect,
  useContext,
} from 'react';
import withStyles from '@mui/styles/withStyles';

import { connect } from 'react-redux';
import Select from 'react-select';
import { useDispatch, batch } from 'react-redux';
import { setDirtyFields } from 'actions/bulkEdit';

// components
import CopyToAll from 'components/Shared/ReactTable/CopyToAll';
import { CustomSelectMenu } from 'components/Shared/ReactTable/reactTableUtils';
import { EditableRowsContext } from './BulkEditContext';
import { WatchOption } from 'components/Shared/ReactTable/reactTableUtils';

// selector
import {
  getTableDropdown,
  getEpisode,
  getWorkSchedule,
} from 'selectors/bulkEdit';

// action
import { fetchDropdown } from 'actions/bulkEdit';
import { findNextValidInput } from 'utils/reactTableUtils';

//utils
import { useDidMount } from 'utils/customHooks';

const mapState = state => ({
  tableDropdown: getTableDropdown(state),
  episode: getEpisode(state),
  workSchedule: getWorkSchedule(state),
});

const mapDispatch = dispatch => ({
  onFetchTableDropdown: args => {
    dispatch(fetchDropdown(args));
  },
});

const style = () => ({});

const PortalDropDown = props => {
  const {
    // classes,
    // from parents
    paths,
    // initialValue,
    value,
    tableValue,
    setValue,
    copyToAll,
    updateMyData,
    columnId, // from react table
    row,
    rowIndex,
    selectWidth = 130,
    htgContractId,
    htgUnionId,
    setTabIndex,
    onBlur = () => {},
    // map props from state
    episode,
    workSchedule,
    tableDropdown: {
      loading,
      options = [],
      dropdownType,
      params: storedParams,
    },
    cellHasError,
    onFetchTableDropdown,
    // containerRef,
  } = props;

  const { containerRef } = useContext(EditableRowsContext);
  const dispatch = useDispatch();

  const [triggerCTA, setTriggerCTA] = useState(false);
  useEffect(() => {
    if (triggerCTA) setTriggerCTA(false);
  }, [triggerCTA]);

  const optionsPointer =
    columnId === 'episode'
      ? episode
      : columnId === 'schedule'
      ? workSchedule
      : options;

  const defaultValue =
    value && value.id
      ? optionsPointer.find(option => option.value === value.id) || null
      : null;

  useDidMount(() => {
    const dropdownList = ['dayType', 'locationType'];
    if (dropdownList.includes(columnId)) {
      const params = (function () {
        switch (columnId) {
          case `locationType`:
            return {
              htgContractId: htgContractId,
              htgUnionId: htgUnionId,
            };
          case `dayType`:
            return {
              parentValue: htgContractId,
            };
          default:
            return {};
        }
      })();

      switch (dropdownType === columnId) {
        case true:
          for (let key in params) {
            const currentParam = params[key];
            const storedParam = storedParams[key];
            if (currentParam !== storedParam) {
              onFetchTableDropdown({ dropdownType: columnId, params });
              break;
            }
          }
          break;
        case false:
          onFetchTableDropdown({ dropdownType: columnId, params });
          break;
        default:
          // do nothing
          break;
      }
    }
  });

  const selectStyle = {
    menuList: base => ({
      ...base,
      width: 'fit-content !important',
      whiteSpace: 'nowrap',
      minWidth: 120,
      scrollbarWidth: 'thin',
      backgroundColor: 'white',
    }),
    container: base => ({
      ...base,
      minWidth: 88,
    }),
    placeholder: base => ({
      ...base,
      fontSize: 12,
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: '4px !important',
    }),
    control: base => ({
      ...base,
      minHeight: 10,
      maxHeight: 33,
    }),
    option: base => ({
      ...base,
      width: 'auto !important',
    }),
  };
  useEffect(() => {
    return () => {
      // component will unmount
      if (tableValue.id !== value.id) {
        window.TimeoutId = setTimeout(() => {
          if (cellHasError) {
            updateMyData(
              rowIndex,
              columnId,
              value,
              row.original.timecardEntryHeaderId,
              row.original.dayIndex,
            );
          } else {
            // updateMyData(rowIndex, columnId, value);
            updateMyData(
              rowIndex,
              columnId,
              value,
              row.original.timecardEntryHeaderId,
              row.original.dayIndex,
            );
          }
          batch(() => {
            if (columnId === 'episode') {
              const pathForSeries = [paths[0], paths[1], 'series'];
              dispatch(
                setDirtyFields({
                  paths: pathForSeries,
                  value: value.series,
                }),
              );
              const pathForLocation = [paths[0], paths[1], 'location'];
              dispatch(
                setDirtyFields({
                  paths: pathForLocation,
                  value: value.location,
                }),
              );
            }
            dispatch(
              setDirtyFields({
                paths,
                value: value,
                fieldToCompare: 'id',
              }),
            );
          });
        }, 100);
      }
    };
  });

  const [menuPlacement, setMenuPlacement] = useState('bottom');
  const [open, setOpen] = useState(true);
  const SelectRef = useRef(null);

  //only select highlighted value if user has used up/down arrows or typed in filter
  const enableTabToSelect = useRef(false);

  //keep track of the highlighted option in options list
  const focusedOptionRef = useRef();

  useEffect(() => {
    const select = SelectRef?.current?.getBoundingClientRect();
    const table = containerRef?.current?.getBoundingClientRect();
    const SELECT_POS_IN_TABLE = select?.top - table?.top;
    if (SELECT_POS_IN_TABLE / table.height >= 0.6) {
      setMenuPlacement('top');
    } else {
      setMenuPlacement('bottom');
    }
  }, [open, containerRef]);
  return (
    <>
      <div tabIndex={0} style={{ minWidth: 130, display: 'flex', height: 30 }}>
        <div
          style={{ minWidth: selectWidth }}
          ref={SelectRef}
          onClick={() => {
            setOpen(open => {
              return !open;
            });
          }}
        >
          <Select
            isLoading={loading}
            options={optionsPointer}
            styles={selectStyle}
            value={defaultValue}
            menuIsOpen={open}
            menuPlacement={menuPlacement}
            onChange={selected => {
              setValue(selected.ref);
            }}
            onKeyDown={e => {
              if (e.nativeEvent.key === 'Tab') {
                if (e.nativeEvent.shiftKey) {
                  setTabIndex(-1);
                  setTimeout(() => {
                    setTabIndex(0);
                  });
                }
                if (enableTabToSelect.current) {
                  setValue(focusedOptionRef.current.ref);
                }
                onBlur();
              }
              if (e.nativeEvent.key === 'Enter') {
                let nextElement;
                let params = {
                  rowIndex,
                  columnId,
                  direction: e.shiftKey ? 'up' : 'down',
                };

                nextElement = findNextValidInput(params);

                if (nextElement) {
                  nextElement.select();
                  onBlur();
                }
              }
              if (
                (e.metaKey || e.ctrlKey) &&
                e.shiftKey &&
                e.key.toLowerCase() === 'a'
              ) {
                e.preventDefault();
                if (enableTabToSelect.current) {
                  setTriggerCTA(focusedOptionRef.current.ref);
                } else {
                  setTriggerCTA(true);
                }
              }
              if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                enableTabToSelect.current = true;
              }
            }}
            tabSelectsValue={false}
            autoFocus={true}
            onInputChange={() => {
              enableTabToSelect.current = true;
            }}
            openMenuOnFocus={true}
            loadingMessage={() => 'loading...'}
            components={{
              Menu: CustomSelectMenu(),
              Option: WatchOption(focusedOptionRef),
            }}
          />
        </div>
        <CopyToAll
          isDropDown
          columnId={columnId}
          onBlur={onBlur}
          value={value}
          copyToAll={copyToAll}
          triggerCTA={triggerCTA}
        />
      </div>
    </>
  );
};

export default withStyles(style)(
  connect(mapState, mapDispatch)(PortalDropDown),
);
