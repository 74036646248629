import React, { Component } from 'react';
import { connect } from 'react-redux';

//selectors
import {
  getFilteredDropdown,
  getCustomFilter,
  getSelectedCount,
} from './selectors';
// actions
import { fetch, setSearchFilter, onSelect, onSelectAll } from './actions';

//utils

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ filterName, selector }) => {
  const mapState = state => {
    return {
      searchFilter: getCustomFilter(state, filterName),
      dropdownSelection: getFilteredDropdown(state, filterName),
      selectedCount: getSelectedCount(state, filterName),
    };
  };

  const mapDispatch = dispatch => {
    return {
      setSearchFilter: e => {
        const value = e.target.value;
        dispatch(setSearchFilter({ value: value, filterName }));
      },
      onSelectAll: () => {
        dispatch(onSelectAll({ filterName }));
        dispatch(fetch({ filterName }));
      },
      onSelect: ({ index }) => {
        dispatch(onSelect({ index, filterName }));
        dispatch(fetch({ filterName }));
      },
    };
  };

  return BaseComponent => {
    class withSearchInvoicesFilter extends Component {
      static displayName = `withSearchInvoicesFilter(${filterName})`;

      render() {
        return <BaseComponent {...this.props} />;
      }
    }

    return connect(mapState, mapDispatch)(withSearchInvoicesFilter);
  };
};
