import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { usersProps } from 'components/props/profiles';
import { EMPLOYEE, PA, IA } from 'components/props/profiles';
import { UserSelect } from 'components/Admin/Projects/Shared';

export default class EditApprovalStep extends Component {
  static propTypes = {
    classes: PropTypes.object,
    approvalFlow: PropTypes.object.isRequired,
    user: PropTypes.string,
    users: usersProps.isRequired,
    addNext: PropTypes.func.isRequired,
    canAddNext: PropTypes.bool.isRequired,
    removeUser: PropTypes.func.isRequired,
    disableRemove: PropTypes.bool.isRequired,
  };

  render() {
    const {
      approvalFlow,
      user,
      users,
      addNext,
      canAddNext,
      removeUser,
      disableRemove,
    } = this.props;
    const userIds = approvalFlow.userIds || [];

    const userOptions = users.filter(
      user =>
        (user.role === EMPLOYEE ||
          user.role === IA ||
          user.role === approvalFlow.role ||
          userIds.indexOf(user.id) >= 0) &&
        user.status !== 'notinvited' &&
        user.status !== 'deleted',
    );

    return (
      <UserSelect
        noPadding={approvalFlow.role === PA}
        addNext={addNext}
        canAddNext={canAddNext}
        removeUser={removeUser}
        disableRemove={disableRemove}
        user={user}
        showEmergencyCheck={approvalFlow.role === PA}
        userOptions={userOptions}
      />
    );
  }
}
