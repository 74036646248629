import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { LinearProgress } from '@mui/material';
import './Loader.css';
import { propRequiredIf } from 'utils/helperFunctions'; //Not sure if we're removing this or not

const useStyles = makeStyles(({ palette }) => ({
  root: {
    color: palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20%',
    fontStyle: 'normal',
    textAlign: 'center',
    width: '100%',
  },
  savingText: {
    fontSize: 14,
    fontWeight: 700,
  },
  ldsGridDiv: {
    '& > div': {
      background: palette.primary.main,
    },
  },
}));

// const distractText = [
//   'Staring at IMDB...',
//   'Wrangling cats...',
//   'Updating Flux capacitor....',
//   'Wondering what Steve is up to these days...',
//   'Circling craft services for a snack...',
//   'Hold for plane...',
//   '10-1...',
//   'What’s for lunch?',
//   'Pay no attention to the man behind the curtain...',
//   'Please count backwards from infinity...',
//   '99 bottles of beer on the wall...',
//   'What is the airspeed velocity of an unladen swallow?',
//   'You’re gonna lose me, Wayne...',
//   'When will then be now?',
//   'May the Force be with you...',
//   'Coffee is for closers...',
//   'Decorating for Festivus...',
//   'I’ll have what she’s having...',
//   'Show me the money...',
//   'You can’t handle the truth...',
//   'There’s no crying in baseball...',
//   'You’re gonna need a bigger boat...',
//   'I’ll be back...',
//   'Say hello to my little friend...',
//   'Plus. Hours Plus...',
//   'Hours+ is people!',
//   'There’s no place like home...',
//   'Rowsbud...',
//   'Nobody puts Hours+ in a corner...',
//   'I drink your milkshake...',
//   'You’re killin’ me, Smalls!',
//   'Everything in this universe is either a potato or not a potato...',
//   'Winter is coming...',
//   'Not Penny’s boat...',
//   'Do or do not...',
//   'Switching to channel 15...',
// ];

let intervalTracker;

/**
 *
 
 */
const LoadingSquare = props => {
  const {
    className: propClassName,
    isSaving,
    savingTotal,
    savingComplete,
  } = props;
  const classes = useStyles();

  const [renderTrigger, setRenderTrigger] = React.useState(0);

  const [savePercent, setSavePercent] = React.useState(0);

  let timer;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timer = setTimeout(() => {
      setRenderTrigger(renderTrigger + 1);
    }, 1500);

    return () => {
      clearTimeout(timer);

      if (savingTotal === 0) {
        clearInterval(intervalTracker);
      }
    };
  });

  const calcSavePercent = (savingTotal, savingComplete) => {
    const ratio = savingComplete / savingTotal;
    const percent = Math.round(ratio * 100);
    let calcVal = isNaN(percent) ? 100 : percent;
    calcVal = Math.min(100, calcVal);
    calcVal = Math.max(0, calcVal);
    return calcVal;
  };
  const upBy1 = () => {
    setSavePercent(val => {
      if (val < 97) return Math.min(97, val + 1);
      return val;
    });
  };

  useEffect(() => {
    if (savingTotal > 0) {
      clearInterval(intervalTracker);
      intervalTracker = setInterval(upBy1, 500);
      setSavePercent(calcSavePercent(savingTotal, savingComplete));
    } else {
      setSavePercent(100);
      clearInterval(intervalTracker);
    }
  }, [savingTotal, savingComplete]);

  const bottomText = isSaving ? (
    <span className={classes.savingText}>
      Saving...
      {savingTotal ? `${savePercent}% complete` : <LinearProgress />}
    </span>
  ) : (
    <span>{'Loading...'}</span>
    // distractText[Math.floor(Math.random() * distractText.length)]
  );

  return (
    <div className={clsx(classes.root, propClassName)}>
      <div className={clsx(classes.ldsGridDiv, 'lds-grid')}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {bottomText}
    </div>
  );
};

LoadingSquare.propTypes = {
  className: PropTypes.string,
  isSaving: PropTypes.bool,
  savingTotal: propRequiredIf('savingComplete', 'number'),
  savingComplete: propRequiredIf('savingTotal', 'number'),
};

export default LoadingSquare;
