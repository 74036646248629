import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';

// const useStyles = makeStyles(theme => ({
//   MuiSelectWrapper: {},
// }));

/** MuiSelectWrapper -
 *
 * Expected to be in a redux-form Field component
 *
 * @param {array} options of form:
 * @param {string} option.label
 * @param {string} option.value
 *
 * @param {func} onChange - passed value from selected option
 *
 */
const MuiSelectWrapper = props => {
  const {
    input: { onChange, onFocus, onBlur, value },
    options,
    className,
    label,
    disabled,
  } = props;

  const isOptionEqualToValue = (option, value) => {
    return option.value === value;
  };

  return (
    <Autocomplete
      renderInput={params => (
        <TextField className={className} label={label} {...params} />
      )}
      autoSelect
      openOnFocus
      options={options}
      disabled={disabled}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={(e, newValue) => {
        onChange(newValue?.value || '');
      }}
      value={value || null}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

MuiSelectWrapper.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default MuiSelectWrapper;
