import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';

// import CustomInput from 'containers/Employees/Reviews/BulkEdit/CustomInput';
// import BulkDropDown from 'containers/Employees/Reviews/BulkEdit/BulkDropDown';
// import Input from 'containers/Employees/Reviews/BulkEdit/Input';
// import { Input, HeaderCell } from 'containers/Employees/Reviews/BulkEdit';

import { Checkbox } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {
  BULK_EDIT_PENDING_CALC_TIMEOUT,
  BULK_EDIT_PENDING_CALC_IGNORE_TIMEOUT,
} from 'components/Shared/constants/index';

import { formatDate, makeMaskFunc, maskAutoFill } from 'utils/weekUtils';
import TimeValidator from 'utils/TimeValidator';

export const composeFilter = (FilterList, filterName) => {
  const filter = FilterList.filter(option => option.selected).reduce(
    (filtered, option) => {
      if (option.selected) {
        if (
          filterName === 'batch.id' ||
          filterName === 'employee' ||
          filterName === 'episode' ||
          filterName === 'accountCode' ||
          filterName === 'set' ||
          filterName === 'weekDay' ||
          filterName === 'status'
        ) {
          filtered.push(option.id);
        } else {
          filtered.push(option.value);
        }
      }
      return filtered;
    },
    [],
  );
  if (filter.length === 0) return null;

  return {
    field: filterName,
    type: `${filterName === 'weekday' ? 'lookup' : 'key'}`,
    values: filter,
  };
};

export const TableColumns = [
  {
    accessor: 'last',
    id: 'last',
    Header: 'Last Name',
    // Header: props => <HeaderCell {...props} />,
    editable: false,
    width: 100,
    sticky: 'left',
    sortType: 'alpha',
  },
  {
    accessor: 'first',
    id: 'first',
    Header: 'First Name',
    // Header: props => <HeaderCell {...props} />,
    editable: false,
    width: 95,
    sticky: 'left',
    sortType: 'alpha',
  },
  {
    accessor: 'batch',
    id: 'batch',
    Header: 'Batch Name',
    // Header: props => <HeaderCell {...props} />,
    editable: false,
    width: 102,
    sticky: 'left',
    sortType: 'alpha',
  },
  {
    accessor: 'day',
    id: 'weekDay',
    Header: 'Week Day',
    // Header: props => <HeaderCell {...props} />,
    editable: false,
    width: 92,
    sticky: 'left',
    sortType: 'num', //days are translated to num in bulkSort
  },
  {
    accessor: 'dealMemo',
    id: 'dealMemo',
    Header: 'Union',
    // Header: props => <HeaderCell {...props} />,
    editable: false,
    width: 105,
    sortType: 'alpha',
  },
  {
    accessor: 'occupationCode',
    id: 'occupationCode',
    Header: 'Occ Code',
    // Header: props => <HeaderCell {...props} />,
    editable: false,
    width: 96,
    sortType: 'alpha',
  },
  {
    accessor: 'department',
    id: 'department',
    Header: 'Department',
    // Header: props => <HeaderCell {...props} />,
    editable: false,
    width: 105,
    sortType: 'alpha',
  },

  {
    accessor: 'accountCode',
    id: 'accountCode',
    Header: 'Account',
    // Header: props => <HeaderCell {...props} />,
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    // Cell: props => <Input {...props} />,
    width: 90,
    hasBorder: true,
    numeric: true,
    sortable: true,
    // editor: (
    //   <CustomInput accountMask={accountMask} editableRows={editableRows} />
    // ),
    sortType: 'num',
  },
  {
    accessor: 'episode.code',
    id: 'episode',
    Header: 'Episode',
    // Header: props => <HeaderCell {...props} />,
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 88,
    // editor: (
    //   <BulkDropDown editableRows={editableRows} prefetchedOptions={episode} />
    // ),
    sortType: 'num',
  },
  {
    accessor: 'series',
    id: 'series',
    Header: 'Series',
    // Header: props => <HeaderCell {...props} />,
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 77,
    hasBorder: true,
    // editor: <CustomInput editableRows={editableRows} />,
    sortType: 'num',
  },
  {
    accessor: 'location',
    id: 'location',
    Header: 'Location',
    // Header: props => <HeaderCell {...props} />,
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 85,
    hasBorder: true,
    // editor: <CustomInput editableRows={editableRows} />,
    sortType: 'alpha',
  },
  {
    accessor: 'set',
    id: 'set',
    Header: 'Set',
    // Header: props => <HeaderCell {...props} />,
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 55,
    hasBorder: true,
    // editor: <CustomInput editableRows={editableRows} />,
    sortType: 'alpha',
  },
  {
    accessor: 'insurance',
    id: 'insurance',
    Header: 'Insurance',
    // Header: props => <HeaderCell {...props} />,
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 89,
    hasBorder: true,
    // editor: <CustomInput editableRows={editableRows} />,
    sortType: 'alpha',
  },
  {
    accessor: 'freeField1',
    id: 'freeField1',
    Header: 'FF1',
    // Header: props => <HeaderCell {...props} />,
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 75,
    hasBorder: true,
    // editor: <CustomInput editableRows={editableRows} />,
    sortType: 'alpha',
  },
  {
    accessor: 'freeField2',
    id: 'freeField2',
    Header: 'FF2',
    // Header: props => <HeaderCell {...props} />,
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 75,
    hasBorder: true,
    sortType: 'alpha',
  },
  {
    accessor: 'freeField3',
    id: 'freeField3',
    Header: 'FF3',
    // Header: props => <HeaderCell {...props} />,
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 75,
    hasBorder: true,
    sortType: 'alpha',
  },
  {
    accessor: 'freeField4',
    id: 'freeField4',
    Header: 'FF4',
    // Header: props => <HeaderCell {...props} />,
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 75,
    hasBorder: true,
    sortType: 'alpha',
  },
  {
    accessor: 'locationType.name',
    id: 'locationType',
    Header: 'Work Loc',
    // Header: props => <HeaderCell {...props} />,
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 85,
    sortType: 'alpha',
  },
  {
    accessor: 'onProduction',
    id: 'onProduction',
    Header: 'On Prod',
    // Header: props => <HeaderCell {...props} />,
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 78,
    isCheckbox: true,
    sortType: 'bool',
  },
  {
    accessor: 'dayType.name',
    id: 'dayType',
    Header: 'Day Type',
    // Header: props => <HeaderCell {...props} />,
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 87,
    sortType: 'alpha',
  },
  {
    accessor: 'callTime',
    id: 'callTime',
    Header: 'Call',
    // Header: props => <HeaderCell {...props} />,
    editable: false,
    width: 60,
    hasBorder: true,
    numeric: true,
    sortType: 'num',
  },
  {
    accessor: 'ndbIn',
    id: 'ndbIn',
    Header: 'NDB In',
    // Header: props => <HeaderCell {...props} />,
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 75,
    hasBorder: true,
    numeric: true,
    sortType: 'num',
  },
  {
    accessor: 'meal1Out',
    id: 'meal1Out',
    Header: 'Meal 1 Out',
    // Header: props => <HeaderCell {...props} />,
    editable: false,
    width: 92,
    hasBorder: true,
    numeric: true,
    sortType: 'num',
  },
  {
    accessor: 'meal1In',
    id: 'meal1In',
    Header: 'Meal 1 In',
    // Header: props => <HeaderCell {...props} />,
    editable: false,
    width: 90,
    hasBorder: true,
    numeric: true,
    sortType: 'num',
  },
  {
    accessor: 'lastMan1In',
    id: 'lastMan1In',
    Header: 'LMI',
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 65,
    hasBorder: true,
    numeric: true,
    sortType: 'num',
  },
  {
    accessor: 'ndmOut',
    id: 'ndmOut',
    Header: 'NDM Out',
    // Header: props => <HeaderCell {...props} />,
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 80,
    hasBorder: true,
    numeric: true,
    sortType: 'num',
  },
  {
    accessor: 'ndmIn',
    id: 'ndmIn',
    Header: 'NDM In',
    // Header: props => <HeaderCell {...props} />,
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 75,
    hasBorder: true,
    numeric: true,
    sortType: 'num',
  },
  {
    accessor: 'meal2Out',
    id: 'meal2Out',
    Header: 'Meal 2 Out',
    // Header: props => <HeaderCell {...props} />,
    editable: false,
    width: 95,
    hasBorder: true,
    numeric: true,
    sortType: 'num',
  },
  {
    accessor: 'meal2In',
    id: 'meal2In',
    Header: 'Meal 2 In',
    // Header: props => <HeaderCell {...props} />,
    editable: false,
    width: 85,
    hasBorder: true,
    numeric: true,
    sortType: 'num',
  },
  {
    accessor: 'wrapTime',
    id: 'wrapTime',
    Header: 'Wrap',
    // Header: props => <HeaderCell {...props} />,
    editable: false,
    width: 75,
    hasBorder: true,
    numeric: true,
    sortType: 'num',
  },
  {
    accessor: 'grace1',
    id: 'grace1',
    Header: 'Grace 1',
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 77,
    isCheckbox: true,
    sortType: 'bool',
  },
  {
    accessor: 'grace2',
    id: 'grace2',
    Header: 'Grace 2',
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 77,
    isCheckbox: true,
    sortType: 'bool',
  },
  {
    accessor: 'wrapProvision',
    id: 'wrapProvision',
    Header: 'Wrap Prov.',
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 96,
    isCheckbox: true,
    sortType: 'bool',
  },
  {
    accessor: 'NoCellAllow',
    id: 'NoCellAllow',
    Header: 'No Cell Allow',
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 112,
    isCheckbox: true,
    sortType: 'bool',
  },
  {
    accessor: 'schedule.name',
    id: 'schedule',
    Header: 'Schedule',
    // Header: props => <HeaderCell {...props} />,
    editable: function (rowData) {
      return rowData.status === 'Ready for me';
    },
    width: 90,
    sortType: 'alpha',
  },
  {
    accessor: 'status',
    id: 'status',
    Header: 'Status',
    // Header: props => <HeaderCell {...props} />,
    editable: false,
    width: 95,
    sortType: 'alpha',
  },
  {
    accessor: 'currentApprover',
    id: 'currentApprover',
    Header: 'Current Approver',
    // Header: props => <HeaderCell {...props} />,
    editable: false,
    width: 120,
    sortType: 'alpha',
  },
  {
    accessor: 'lastUpdated',
    id: 'lastUpdated',
    Header: 'Last Updated',
    // Header: props => <HeaderCell {...props} />,
    editable: false,
    width: 170,
    maxWidth: 170,
    sortType: 'date',
  },
  {
    accessor: 'validationErrors',
    id: 'validationErrors',
  },
];

export const parsedSortedDropdown = (list, completeLabel = false) => {
  return _.chain(list)
    .map(state => ({
      label: completeLabel ? `${state.code} - ${state.name}` : `${state.code}`,
      value: state.id,
      ref: state,
    }))
    .sortBy(option => option.label)
    .value();
};

//classes coming from BulkEditTable
export const getCellFormatter = (classes, updatedCells, onGridRowsUpdated) => {
  // const hasBeenUpdated = (row, columnId) => {
  //   if (
  //     updatedCells[row.tcIndex] === undefined ||
  //     updatedCells[row.tcIndex][row.dayIndex] === undefined
  //   )
  //     return false;

  //   return updatedCells[row.tcIndex][row.dayIndex].has(columnId);
  // };

  const parseDropDown = ({ type, object }) => {
    if (_.isEmpty(object)) return 'Select...';
    switch (type) {
      case 'locationType':
        return `${object.code} - ${object.name}`;
      case 'episode':
        return `${object.code} `;
      case 'dayType':
        return `${object.name}`;
      case 'schedule':
        return `${object.code}`;
      default:
        return 'Select...';
    }
  };

  return formatArgs => {
    const { row, column, value, rowIdx } = formatArgs;

    const dropdownTypes = ['locationType', 'episode', 'dayType', 'schedule'];
    const columnId = column.key;
    const frozen = column.frozen;
    const editable =
      typeof column.editable === 'function'
        ? column.editable(row)
        : column.editable;
    const hasBorder = column.hasBorder;
    const isCheckbox = column.isCheckbox;
    const isDropDown = dropdownTypes.includes(columnId);
    const numeric = column.numeric;
    let checked = value;

    let errorOrNot =
      row.parsedErrorFields.includes(column.key) && _.isEmpty(value);

    const content = isCheckbox ? (
      <div className={classes.checkBoxValue}>
        <Checkbox
          classes={{ root: classes.checkbox }}
          color={'primary'}
          checked={checked}
          disabled={row.status === 'Ready for me' ? false : true}
          // onChange={e => {
          //   const updated = {};
          //   updated[columnId] = !checked;
          //   onGridRowsUpdated({
          //     cellKey: columnId,
          //     fromRow: rowIdx,
          //     toRow: rowIdx,
          //     updated,
          //     action: 'CELL_UPDATE',
          //     fromRowData: row,
          //   });
          // }}
        />
        {/* Weird re-redners happening with the MUI checkbox, re-renders all checkboxes whever any value changes.
        Doesn't happen for normal checkbox, but they both re-render the whole grid when their value changes.
        Curious if its a performace issue once this gets in to a production build */}
        {/* <input
          type='checkbox'
          checked={checked}
          onChange={e => {
            const updated = {};
            updated[columnId] = !checked;
            onGridRowsUpdated({
              cellKey: columnId,
              fromRow: rowIdx,
              toRow: rowIdx,
              updated,
              action: 'CELL_UPDATE',
              fromRowData: formatArgs.row,
            });
          }}
          disabled={!!editable ? true : false}
        /> */}
      </div>
    ) : (
      <div
        className={clsx({
          [classes.cellValue]: true,
          [classes.dropDownCell]: isDropDown,
          [classes.errorDropdown]: isDropDown && errorOrNot,
        })}
      >
        <span
          className={clsx({
            [classes.selectDotDot]: value === undefined && isDropDown,
            [classes.dropdownText]: isDropDown,
          })}
        >
          {value === undefined && isDropDown
            ? 'Select...'
            : isDropDown
            ? parseDropDown({ type: columnId, object: value })
            : value}
        </span>
        {isDropDown && (
          <ArrowDropDownIcon
            className={`${classes.expandIcon} ${
              errorOrNot ? classes.errorColor : ''
            }`}
          />
        )}
      </div>
    );

    // frozen check
    return (
      <div
        className={clsx({
          [classes.outerCell]: true,
          [classes.frozenCell]: frozen && rowIdx % 2 === 1,
          [classes.evenRow]: frozen && rowIdx % 2 === 0,
          [classes.errorCell]: errorOrNot,
          [classes.errorColor]: errorOrNot,
        })}
      >
        <div
          className={clsx({
            // [classes.touchedCell]: touched,
            [classes.cellValueBorder]: hasBorder && !isCheckbox,
            [classes.notEditable]: !editable,
            [classes.numeric]: numeric,
          })}
        >
          {content}
        </div>
      </div>
    );
  };

  // return cellFormatter;
};

// update data in source that will eventually be sent off
// keeps it in sync with data displayed in grid
// currently the timecards array in memory from BulkEdit.js
export const createUpdater = timecards => {
  const updater = (updated, row) => {
    let day = timecards[row.tcIndex].details[row.dayIndex];
    const field = Object.keys(updated)[0];
    _.set(day, field, updated[field]);
  };
  return updater;
};

const parseName = fullName => {
  const regex = /^(.*),(.*)$/;
  const result = regex.exec(fullName);
  if (result) {
    const last = result[1].trim();
    const first = result[2].trim();
    return [first, last];
  } else {
    return ['_', fullName];
  }
};

export const processSettings = (columns, settings) => {
  const columnCopy = _.cloneDeep(columns);
  BULK_EDIT_TIME_FIELDS.forEach(field => {
    if (
      settings &&
      settings.wtcEditable &&
      settings.wtcEditable[field] === false
    ) {
      const result = columnCopy.find(c => c.accessor === field);
      if (result) result.editable = false;
    }
  });
  return columnCopy;
};

export const BulkEditFloatRowStyle = 'rgba(0, 0, 0, 0.28) 0px 2px 5px';
//day String ie 'Mon', 'Tue', 'Wed'
//activeDays: { day: 'Mon', active: true }
// const isDropDown = column.editor !== undefined;
// const isDayActive = (day, activeDays, accountMask) => {
//   if (!activeDays) return true;
//   const result = _.find(activeDays, checkDay => checkDay.day === day);
//   return result ? result.active : true;
// };
export const validErrorTexts = [
  'Timecard is missing the location',
  'you have not specified a day type',
];
export const ERROR_STATE_COLUMNS = ['locationType', 'dayType'];

//timecard - array of timecards in same format they go to WTC
export const parseTimecards = (
  timecards,
  accountMask,
  editableTimefields = [],
) => {
  const rows = [];
  let editableRowsCounts = 0;
  const maskingFunction = makeMaskFunc(accountMask);
  timecards.forEach((timecard, tcIndex) => {
    // forEach Timecard
    const {
      details = [],
      employee = {},
      timecardEntryHeaderId = '',
      batch = {},
      currentApproversStr = '',
      departmentName = '',
      userFriendlyTimecardStatus = '',
      lastUpdated = '',
    } = timecard;

    const [firstName = 'Moniker', lastName = 'Surname'] = parseName(
      employee ? employee.name : '',
    );

    details.forEach((weekDay, dayIndex) => {
      // forEach week day
      const day = _.cloneDeep(weekDay);
      const errors = [];

      const fieldsToCheck = [
        // 'episode',
        'locationType',
        'dayType',
        // 'series',
        // 'accountCode',
      ];

      fieldsToCheck.forEach(field => {
        const firstError = day.errors.find(error => {
          return (
            error.level === 'Error' &&
            error.field === field &&
            userFriendlyTimecardStatus === 'Ready for me'
          );
        });

        if (
          !!firstError &&
          validErrorTexts.includes(firstError.text) &&
          (_.isEmpty(day[field]) || !day[field].id) // field is empty and field's ID num is valid
        ) {
          errors.push(firstError);
        } else if (!firstError && (_.isEmpty(day[field]) || !day[field].id)) {
          // when both error and value in field are missing
          errors.push({
            field,
            id: `${timecardEntryHeaderId}.${day.effectiveDate}.${field}`,
            level: 'Error',
          });
        }
      });

      const accountCode = maskingFunction(day.accountCode) || undefined;
      const newRow = {
        timecardEntryHeaderId,
        last: lastName,
        first: firstName,
        accountCode,
        batch: batch && `${batch.htgBatchNumber} - ${batch.name}`,
        batchWorksightId: batch && batch.id,
        htgId: batch && batch.htgBatchNumber,
        batchStatus: batch && batch.status,
        callTime: day.callTime,
        currentApprover: currentApproversStr,
        day: formatDate(day.effectiveDate, 'ddd'),
        effectiveDate: day.effectiveDate,
        dayType: day.dayType || {},
        dealMemo: day.dealMemo
          ? day.dealMemo.pensionUnion
            ? day.dealMemo.pensionUnion.name
            : undefined
          : undefined,
        rounding: day?.dealMemo?.rounding,
        department: departmentName,
        episode: day.episode || {},
        lastMan1In: day.lastMan1In,
        lastUpdated: lastUpdated,
        locationType: day.locationType || {},
        set: day.set || '',
        series: day.series || '',
        insurance: day.insurance || '',
        freeField1: day.freeField1 || '',
        freeField2: day.freeField2 || '',
        freeField3: day.freeField3 || '',
        freeField4: day.freeField4 || '',
        location: day.location || '',
        meal1Out: day.meal1Out,
        meal1In: day.meal1In,
        meal2Out: day.meal2Out,
        meal2In: day.meal2In,
        ndbIn: day.ndbIn || '',
        ndmOut: day.ndmOut || '',
        ndmIn: day.ndmIn || '',
        occupationCode: day.occupationCode
          ? day.occupationCode.name
          : undefined,
        onProduction: day.onProduction ? true : false,
        grace1: day.grace1 ? true : false,
        grace2: day.grace2 ? true : false,
        wrapProvision: day.wrapProvision ? true : false,
        NoCellAllow: day['NoCellAllow'] ? true : false,
        status: userFriendlyTimecardStatus,
        schedule: day.schedule || {},
        wrapTime: day.wrapTime,
        tcIndex,
        dayIndex,
        errors,
        htgContractId: _.get(day, 'dealMemo.htgContract.id', ''),
        htgUnionId: _.get(day, 'dealMemo.htgUnion.id', ''),
      };

      if (userFriendlyTimecardStatus === 'Ready for me') {
        newRow.validationErrors = validateTimes(
          newRow,
          null,
          null,
          editableTimefields,
          true,
        ).errors;
        editableRowsCounts += 1;
      }
      rows.push(newRow);
    });
  });
  return {
    rows,
    editableRowsCounts,
  };
};

export const isObjectEmpty = (object = {}) => {
  return Object.keys(object).length === 0;
};

export const calculateDirtyFields = (
  paths = [],
  dirtyFields = {},
  { inputValue, originalValue, fieldToCompare },
) => {
  let _dirtyFields = _.cloneDeep(dirtyFields);
  let touched = false;
  let objectPointer = _dirtyFields;
  let currentPath = 0;

  while (currentPath < paths.length) {
    const propertyName = paths[currentPath];
    let property = objectPointer[propertyName];

    if (currentPath === paths.length - 1) {
      // reach the end of path
      const userInput = fieldToCompare
        ? inputValue && inputValue[fieldToCompare]
        : inputValue === ''
        ? `${undefined}`
        : `${inputValue}`;
      const initial = fieldToCompare
        ? originalValue && originalValue[fieldToCompare]
        : `${originalValue}`;

      // to do: may need to convert data type
      // number - convert string to number(or string)
      if (userInput !== initial) {
        // if (userInput !== initial && property !== true) {
        objectPointer[propertyName] = inputValue;
        touched = true;
      }
      if (userInput === initial && objectPointer.hasOwnProperty(propertyName)) {
        // if (userInput === initial && property === true) {
        // delete this property
        delete objectPointer[propertyName];
        touched = true;
      }

      // dirtyFields Object cleanup:
      // if a date contains no dirty field
      // then delete that date
      // if a timecard contains no dirty date
      ///then delete that timecard
      if (isObjectEmpty(objectPointer)) {
        delete _dirtyFields[paths[0]][paths[1]];
        if (isObjectEmpty(_dirtyFields[paths[0]])) {
          delete _dirtyFields[paths[0]];
        }
      }
      break;
    }

    if (!property) {
      objectPointer[propertyName] = {};
    }

    objectPointer = objectPointer[propertyName];
    currentPath++;
  }
  return { dirtyFields: _dirtyFields, touched };
};

export function normalizeValue(columnId, localValue, accountMask, rounding) {
  if (columnId === 'accountCode' && accountMask && localValue) {
    return maskAutoFill(accountMask)(localValue);
  }
  return localValue;
}

export function roundingValue({ unrounded, rounding }) {
  const tv = new TimeValidator(rounding);
  const roundedValue = tv.parse(`${unrounded}`);
  return roundedValue;
}

// Bulk Edit Copy To All function
export function calculateAllRows(
  oldRows,
  columnId,
  value,
  showErrors,
  editableTimefields = [],
) {
  const isErrStateColumn = ERROR_STATE_COLUMNS.includes(columnId);
  const isTimeField = BULK_EDIT_TIME_FIELDS.includes(columnId);
  const validValue = isErrStateColumn ? !!(value && value.id) : false; // for Error State
  const copyToErrorRowsOnly = showErrors;
  let roundedValue = null;

  return oldRows.map((row, rowIndex) => {
    // row is editable
    if (row.status === 'Ready for me') {
      const rounding = row.rounding;
      let validTime = { exist: true };
      if (isTimeField) {
        const tv = new TimeValidator(rounding);
        roundedValue = tv.parse(`${value || ''}`);
        validTime = validateTimes(
          oldRows[rowIndex],
          columnId,
          roundedValue,
          editableTimefields,
        );
        // rounding here
      }

      // column should have error state
      if (isErrStateColumn || isTimeField) {
        const rowErrors = row.errors;
        const errorIndex = isTimeField
          ? false
          : rowErrors && rowErrors.findIndex(err => err.field === columnId);
        const hasError = isTimeField ? false : errorIndex !== -1;

        if (copyToErrorRowsOnly) {
          if (row.errors.length > 0 || row.validationErrors.length > 0) {
            if (isErrStateColumn && !hasError && !validValue) {
              row.errors.push({
                field: columnId,
                level: 'Error',
                id: `localError_${row.timecardEntryHeaderId}_${row.effectiveDate}`,
              });
            }
            if (hasError && validValue) {
              row.errors.splice(errorIndex, 1);
            }
            if (columnId === 'episode') {
              row.series = value.series;
              row.location = value.location;
            }
            if (isTimeField) row.validationErrors = validTime.errors;

            return {
              ...row,
              [columnId]: isTimeField ? roundedValue || '' : value,
            };
          }
          return row;
        }
        if (isErrStateColumn && !hasError && !validValue) {
          row.errors.push({
            field: columnId,
            level: 'Error',
            id: `localError_${row.timecardEntryHeaderId}_${row.effectiveDate}`,
          });
        }
        if (hasError && validValue) {
          row.errors.splice(errorIndex, 1);
        }
        if (isTimeField) row.validationErrors = validTime.errors;

        if (columnId === 'episode') {
          row.series = value.series;
          row.location = value.location;
        }
        return {
          ...row,
          [columnId]: isTimeField ? roundedValue || '' : value,
        };
      } else {
        // column with no error state
        if (copyToErrorRowsOnly) {
          if (row.errors.length > 0 || row.validationErrors.length > 0) {
            if (isTimeField) {
              row.validationErrors = validTime.errors;
            }
            if (columnId === 'episode') {
              row.series = value.series;
              row.location = value.location;
            }
            return {
              ...row,
              [columnId]: isTimeField ? roundedValue || '' : value,
            };
          }
          return row;
        }
        if (isTimeField) {
          row.validationErrors = validTime.errors;
        }
        if (columnId === 'episode') {
          row.series = value.series;
          row.location = value.location;
        }
        return {
          ...row,
          [columnId]: isTimeField ? roundedValue || '' : value,
        };
      }
    }
    // non-editable rows
    return row;
  });
}

function parseValidationErrors(timeFieldsCheckQuene, valueMap = {}) {
  let errors = [];
  const { call, ndbIn, meal1Out, meal1In, lastMan1In, ndmOut, ndmIn, wrap } =
    valueMap;

  timeFieldsCheckQuene.forEach(fieldName => {
    let time = valueMap[fieldName];
    switch (fieldName) {
      case 'ndbIn':
        if (!!call && call >= time && time !== 0 && time !== '') {
          errors.push({
            field: 'ndbIn',
            level: 'error',
            text: 'NDB In must be greater than Call',
          });
        } else if (!!meal1Out && meal1Out <= time && time !== '') {
          errors.push({
            field: 'ndbIn',
            level: 'error',
            text: 'NDB In must be less than Meal 1 Out',
          });
        } else if (!!lastMan1In && lastMan1In <= time && time !== '') {
          errors.push({
            field: 'ndbIn',
            level: 'error',
            text: 'NDB In must be less than Last Man In',
          });
        } else if (!!ndmOut && ndmOut <= time && time !== '') {
          errors.push({
            field: 'ndbIn',
            level: 'error',
            text: 'NDB In must be less than NDM Out',
          });
        } else if (!!wrap && wrap <= time && time !== '') {
          errors.push({
            field: 'ndbIn',
            level: 'error',
            text: 'NDB In must be less than Wrap',
          });
        }

        break;
      case 'lastMan1In':
        if (!!call && call >= time && time !== 0 && time !== '') {
          errors.push({
            field: 'lastMan1In',
            level: 'error',
            text: 'Last Man In must be greater than Call',
          });
        } else if (!!ndbIn && ndbIn >= time && time !== '') {
          errors.push({
            field: 'lastMan1In',
            level: 'error',
            text: 'Last Man In must be greater than NDB In',
          });
        } else if (!!meal1Out && meal1Out >= time && time !== '') {
          errors.push({
            field: 'lastMan1In',
            level: 'error',
            text: 'Last Man In must be greater than Meal 1 Out',
          });
        } else if (!!meal1In && meal1In >= time && time !== '') {
          errors.push({
            field: 'lastMan1In',
            level: 'error',
            text: 'Last Man In must be greater than Meal 1 In',
          });
        } else if (!!wrap && wrap <= time && time !== '') {
          errors.push({
            field: 'lastMan1In',
            level: 'error',
            text: 'Last Man In must be less than Wrap',
          });
        }
        break;
      case 'ndmOut':
        if (!!call && call >= time && time !== 0 && time !== '') {
          errors.push({
            field: 'ndmOut',
            level: 'error',
            text: 'NDM Out must be greater than Call',
          });
        } else if (!!ndbIn && ndbIn >= time && time !== '') {
          errors.push({
            field: 'ndmOut',
            level: 'error',
            text: 'NDM Out must be greater than NDB In',
          });
        } else if (!!ndmIn && ndmIn <= time && time !== '') {
          errors.push({
            field: 'ndmOut',
            level: 'error',
            text: 'NDM Out must be less than NDM In',
          });
        } else if (!!wrap && wrap <= time && time !== '') {
          errors.push({
            field: 'ndmOut',
            level: 'error',
            text: 'NDM Out must be less than Wrap',
          });
        } else if (!time && !!ndmIn) {
          errors.push({
            field: 'ndmOut',
            level: 'error',
            text: 'Both NDM In and NDM out are required',
          });
        }
        break;
      case 'ndmIn':
        if (!!call && call >= time && time !== 0 && time !== '') {
          errors.push({
            field: 'ndmIn',
            level: 'error',
            text: 'NDM In must be greater than Call',
          });
        } else if (!!ndbIn && ndbIn >= time && time !== '') {
          errors.push({
            field: 'ndmIn',
            level: 'error',
            text: 'NDM In must be greater than NDB In',
          });
        } else if (!!ndmOut && ndmOut >= time && time !== '') {
          errors.push({
            field: 'ndmIn',
            level: 'error',
            text: 'NDM In must be greater than NDM Out',
          });
        } else if (!!wrap && wrap <= time && time !== '') {
          errors.push({
            field: 'ndmIn',
            level: 'error',
            text: 'NDM In must be less than Wrap',
          });
        } else if (!time && !!ndmOut) {
          errors.push({
            field: 'ndmIn',
            level: 'error',
            text: 'Both NDM In and NDM out are required',
          });
        }
        break;
      default:
        break;
    }
  });
  //cnslog('new Errors:', errors);
  return errors;
}

function validateTimes(
  row,
  timeField,
  userInput,
  editableTimefields = [], // [ndbIn, lastMan1In, ndmOut, ndmIn]
  initialLoad = false,
) {
  let valueMap = {
    call: row.callTime || null,
    ndbIn: row.ndbIn || null, // 1
    meal1Out: row.meal1Out || null,
    meal1In: row.meal1In || null,
    lastMan1In: row.lastMan1In || null, // 2
    ndmOut: row.ndmOut || null, // 3
    ndmIn: row.ndmIn || null, // 4
    wrap: row.wrapTime || null,
  };

  if (!initialLoad) valueMap[timeField] = userInput;

  let timeFieldsCheckQuene = BULK_EDIT_TIME_FIELDS.filter(
    timeField =>
      (valueMap[timeField] !== null &&
        editableTimefields.includes(timeField)) ||
      (timeField === 'ndmOut' && valueMap['ndmIn']) ||
      (timeField === 'ndmIn' && valueMap['ndmOut']),
  );

  return { errors: parseValidationErrors(timeFieldsCheckQuene, valueMap) };
}

// Bulk Edit update single row
export function calculateSingleRow(
  oldRows,
  { rowIndex, columnId, value, editableTimefields = [] },
) {
  const isErrStateColumn = ERROR_STATE_COLUMNS.includes(columnId);
  const validValue = isErrStateColumn ? !!(value && value.id) : false; // for Error State

  const timeField = BULK_EDIT_TIME_FIELDS.includes(columnId);

  let validTime = { exist: false };
  if (timeField) {
    validTime = validateTimes(
      oldRows[rowIndex],
      columnId,
      value,
      editableTimefields,
    );

    // lets swap the error field here
  }

  return oldRows.map((row, index) => {
    if (index === rowIndex) {
      if (isErrStateColumn) {
        const errorIndex =
          row.errors &&
          row.errors.length &&
          row.errors.findIndex(err => err.field === columnId);
        const hasError = errorIndex !== -1;
        if (hasError && validValue) row.errors.splice(errorIndex, 1);
      }

      if (columnId === 'episode') {
        row.series = value.series;
        row.location = value.location;
      }
      if (timeField) {
        row.validationErrors = validTime.errors;
      }

      return {
        ...row,
        // ...oldRows[rowIndex],
        [columnId]: value,
      };
    }
    return row;
  });
}

// must be in this order for validateTime method to work Properly
export const BULK_EDIT_TIME_FIELDS = ['ndbIn', 'lastMan1In', 'ndmOut', 'ndmIn'];
export const TIME_FIELDS_ERRORS_MESSAGES = {
  lastMan1In: 'Last Man In must be greater than ..., and less than ...',
  ndmIn: 'NDM In must be greater than ..., and less than ...',
  ndmOut: 'NDB out must be greater than ..., and less than ...',
  ndbIn: 'NDB In must be greater than ..., and less than ...',
};

export const ctaHasTimeFields = (copyAllFlag, edits) => {
  if (!copyAllFlag || !edits.all) return false;
  for (let i = 0; i < BULK_EDIT_TIME_FIELDS.length; i++) {
    const field = BULK_EDIT_TIME_FIELDS[i];
    if (`${field}` in edits.all) return true;
  }
  return false;
};

export const composeRoundedFields = (edits, timeValidator) => {
  const newEdits = {};
  for (let field in edits.all) {
    if (BULK_EDIT_TIME_FIELDS.includes(field)) {
      const rounded = timeValidator.parse(`${edits.all[field]}`) || '';
      newEdits[field] = rounded;
    } else {
      newEdits[field] = _.cloneDeep(edits.all[field]);
    }
  }

  return newEdits;
};

export const normalizeTimeEntry = (val, prevVal) => {
  const filteredVal = val.replace(/[^0-9:.]/g, '');

  if (!filteredVal.match(/^\d?\d?[:.]?\d?\d?$/)) return prevVal;

  return filteredVal;
};

export const createPendingCalculationsTimeout = (callback, id) => {
  window[`PendingCalculationsTimeoutId_${id}`] = setTimeout(() => {
    callback();
  }, BULK_EDIT_PENDING_CALC_TIMEOUT);
};

export const createPendingCalculationsIgnoreTimeout = (callback, id) => {
  window[`PendingCalculationsIgnoreTimeoutId_${id}`] = setTimeout(() => {
    callback();
  }, BULK_EDIT_PENDING_CALC_IGNORE_TIMEOUT);
};

export const clearPendingCalculationsTimeout = id => {
  if (window[`PendingCalculationsTimeoutId_${id}`]) {
    clearTimeout(window[`PendingCalculationsTimeoutId_${id}`]);
  }
};

export const clearPendingCalculationsIgnoreTimeout = id => {
  if (window[`PendingCalculationsIgnoreTimeoutId_${id}`]) {
    clearTimeout(window[`PendingCalculationsIgnoreTimeoutId_${id}`]);
  }
};

export const removeEmptyTimeFields = day => {
  BULK_EDIT_TIME_FIELDS.forEach(field => {
    if (day.hasOwnProperty(field)) {
      const timeVal = day[field];
      if (!timeVal && timeVal !== 0) {
        delete day[field];
      }
    }
  });
};

export const rowHasNoErrorsToShow = row => {
  try {
    if (row.values.status !== 'Ready for me') return true;

    if (row.original.errors.length + row.values.validationErrors.length > 0) {
      return false;
    }

    return true;
  } catch (error) {
    //throw error message if we hit an undefined in the chain
    console.error('Error in Bulk Edit Error Display');
    return true;
  }
};
