import {
  call,
  put,
  select,
  takeLatest,
  delay,
  takeLeading,
  throttle,
} from 'redux-saga/effects';
import * as _findIndex from 'lodash/findIndex';
import { showAlert } from 'actions/alert';
import * as actions from 'actions/location';
import { getProject as project } from 'selectors/routeParams';
import {
  getCountriesV1,
  getCacheStates,
  getCacheCities,
} from 'selectors/location';

export function* fetchCountriesV1(api, debug) {
  try {
    yield put(actions.setLoading({ variant: 'countries', loading: true }));
    let countries = yield select(getCountriesV1);
    if (countries) {
      if (
        countries[0] &&
        countries[0].code === 'US' &&
        countries[1] &&
        countries[1].code === 'CA'
      ) {
        // we have US and Canada countries, thats probably enough
        return;
      }
    }
    countries = yield call(api.locations.countriesV1);

    //rearrange list so USA is first and Canada is 2nd
    const caIndex = _findIndex(countries, { code: 'CA' });
    if (caIndex !== -1) {
      const ca = countries[caIndex];
      countries.splice(caIndex, 1);
      countries.unshift(ca);
    }
    const usIndex = _findIndex(countries, { code: 'US' });
    if (usIndex !== -1) {
      const usa = countries[usIndex];
      countries.splice(usIndex, 1);
      countries.unshift(usa);
    }

    yield put(actions.setLoading({ variant: 'countries', loading: true }));
    yield put(actions.storeCountriesV1({ countries }));
  } catch (e) {
    yield put(actions.storeCountriesV1({ countries: [] }));
    yield put(showAlert());
    debug(e);
  } finally {
    yield put(actions.setLoading({ variant: 'countries', loading: false }));
  }
}

export function* fetchStatesV1(api, debug, params) {
  try {
    yield put(actions.setLoading({ variant: 'states', loading: true }));

    const states = yield checkStates(api, debug, params);
    yield put(actions.storeStatesV1({ states }));
  } catch (e) {
    yield put(actions.storeStatesV1({ states: [] }));
    yield put(showAlert());
    debug(e);
  } finally {
    yield put(actions.setLoading({ variant: 'states', loading: false }));
  }
}
export function* fetchCitiesV1(api, debug, params) {
  try {
    yield put(actions.setLoading({ variant: 'cities', loading: true }));

    const cities = yield checkCities(api, debug, params);
    yield put(actions.storeCitiesV1({ cities }));
  } catch (e) {
    yield put(actions.storeCitiesV1({ cities: [] }));
    yield put(showAlert());
    debug(e);
  } finally {
    yield put(actions.setLoading({ variant: 'cities', loading: false }));
  }
}
export function* fetchCountiesV1(api, debug, params) {
  try {
    let { stateId } = params;
    const counties = yield call(api.locations.countiesV1, { stateId });
    yield put(actions.storeCountiesV1({ counties }));
  } catch (e) {
    yield put(actions.storeCountiesV1({ counties: [] }));
    yield put(showAlert());
    debug(e);
  }
}
export function* fetchSubdivisionsV1(api, debug, params) {
  try {
    const { search } = params;
    const subdivisions = yield call(api.locations.subdivisionsV1, { search });
    yield put(actions.storeSubdivisionsV1({ subdivisions }));
  } catch (e) {
    yield put(actions.storeSubdivisionsV1({ subdivisions: [] }));
    yield put(showAlert());
    debug(e);
  }
}

function* checkStates(api, debug, params) {
  const { countryId } = params;
  const projectId = yield select(project);

  const cacheStates = yield select(getCacheStates, projectId);
  let states;
  if (cacheStates && !countryId) {
    states = cacheStates;
  } else {
    states = yield call(api.locations.statesV1, { countryId, projectId });
    if (!countryId) {
      yield put(actions.storeCacheStates({ projectId, states }));
      yield put(actions.startCache());
    }
  }
  return states.slice();
}

function* checkCities(api, debug, params) {
  const { stateId } = params;

  const cacheCities = yield select(getCacheCities, stateId);
  let cities;
  if (cacheCities) {
    cities = cacheCities;
  } else {
    cities = yield call(api.locations.citiesV1, { stateId });
    yield put(actions.storeCacheCities({ stateId, cities }));
  }
  return cities.slice();
}

// delays for DELAY_TIME then clears the local location cache
// because this uses takeLeading, it will only run 1 at a time
// subsequent dispatching of the startCache action will be ignored.
function* startCache() {
  const DELAY_TIME = 3 * 60 * 1000;
  yield delay(DELAY_TIME);
  yield put(actions.clearCache());
}

export default function* fetchStatesFlow({ api, debug }) {
  yield takeLatest(`${actions.fetchCountriesV1}`, fetchCountriesV1, api, debug);
  yield takeLatest(`${actions.fetchStatesV1}`, fetchStatesV1, api, debug);
  yield takeLatest(`${actions.fetchCitiesV1}`, fetchCitiesV1, api, debug);
  yield takeLatest(`${actions.fetchCountiesV1}`, fetchCountiesV1, api, debug);
  yield throttle(
    750,
    `${actions.fetchSubdivisionsV1}`,
    fetchSubdivisionsV1,
    api,
    debug,
  );
  yield takeLeading(`${actions.startCache}`, startCache, api, debug);
}
