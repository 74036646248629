// Appends _REQUEST, _SUCCESS, _FAILURE - added this to reduce no of action consts.
export const REQUEST = actionType => `${actionType}_REQUEST`;
export const SUCCESS = actionType => `${actionType}_SUCCESS`;
export const FAILURE = actionType => `${actionType}_FAILURE`;
export const RESET = actionType => `${actionType}_RESET`;

export const HIDE = actionType => `HIDE_${actionType}`;
export const SHOW = actionType => `SHOW_${actionType}`;
export const INIT = actionType => `INIT_${actionType}`;
export const DESTROY = actionType => `DESTROY_${actionType}`;

export const ADD = actionType => `ADD_${actionType}`;
export const DELETE = actionType => `DELETE_${actionType}`;
export const UPDATE = actionType => `UPDATE_${actionType}`;

export const EDIT = actionType => `EDIT_${actionType}`;
export const FETCH = actionType => `FETCH_${actionType}`;
export const REMOVE = actionType => `REMOVE_${actionType}`;
export const SAVE = actionType => `SAVE_${actionType}`;

export const ADD_REQUEST = actionType => REQUEST(ADD(actionType));
export const ADD_SUCCESS = actionType => SUCCESS(ADD(actionType));
export const ADD_FAILURE = actionType => FAILURE(ADD(actionType));

export const DELETE_REQUEST = actionType => REQUEST(DELETE(actionType));
export const DELETE_SUCCESS = actionType => SUCCESS(DELETE(actionType));
export const DELETE_FAILURE = actionType => FAILURE(DELETE(actionType));

export const EDIT_REQUEST = actionType => REQUEST(EDIT(actionType));
export const EDIT_SUCCESS = actionType => SUCCESS(EDIT(actionType));
export const EDIT_FAILURE = actionType => FAILURE(EDIT(actionType));

export const FETCH_REQUEST = actionType => REQUEST(FETCH(actionType));
export const FETCH_SUCCESS = actionType => SUCCESS(FETCH(actionType));
export const FETCH_FAILURE = actionType => FAILURE(FETCH(actionType));

export const REMOVE_REQUEST = actionType => REQUEST(REMOVE(actionType));
export const REMOVE_SUCCESS = actionType => SUCCESS(REMOVE(actionType));
export const REMOVE_FAILURE = actionType => FAILURE(REMOVE(actionType));

export const SAVE_REQUEST = actionType => REQUEST(SAVE(actionType));
export const SAVE_SUCCESS = actionType => SUCCESS(SAVE(actionType));
export const SAVE_FAILURE = actionType => FAILURE(SAVE(actionType));

export const GET = actionType => `GET_${actionType}`;
export const POST = actionType => `POST_${actionType}`;
export const PUT = actionType => `PUT_${actionType}`;
// Imported already below ADD, keeping here to show full suite of actions
// export const DELETE = actionType => `DELETE_${actionType}`;

export const INVALIDATE = actionType => `INVALIDATE_${actionType}`;
export const REPLACE = actionType => `REPLACE_${actionType}`;
export const UPDATE_PROGRESS = actionType => `${actionType}_UPDATE__PROGRESS`;

export const PUSH = actionType => `PUSH_${actionType}`;
export const POP = actionType => `POP_${actionType}`;
export const PULL = actionType => `PULL_${actionType}`;
export const CLEAR = actionType => `CLEAR_${actionType}`;

export const ACCEPT = actionType => `ACCEPT_${actionType}`;
export const REJECT = actionType => `REJECT_${actionType}`;
