import React, { useState } from 'react';
import { Button, Tooltip } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

//components
import withDTSFilter from './withDTSFilter';
import FilterDropdown from 'components/Shared/ReactTable/FilterDropdown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: 273,
    margin: '30px 16px',
  },
  controls: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  filters: {
    width: 253,
    maxHeight: '67vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    boxShadow: '0px 2px 8px 2px rgb(82 82 82 / 10%)',
  },
  filterBtns: {
    width: 116,
  },
  title: {
    color: palette.text.secondary,
    fontSize: 24,
    marginBottom: 16,
  },
  titleInfoIcon: {
    position: 'relative',
    top: 2,
    margin: '0px 5px',
  },
}));

const DepartmentFilter = withDTSFilter({
  filterName: 'department',
})(FilterDropdown);

const BatchFilter = withDTSFilter({
  filterName: 'batch',
})(FilterDropdown);

const EmployeeFilter = withDTSFilter({
  filterName: 'employee',
})(FilterDropdown);

const AccountFilter = withDTSFilter({
  filterName: 'accountCode',
})(FilterDropdown);

const EpisodeFilter = withDTSFilter({
  filterName: 'episode',
})(FilterDropdown);

const SetFilter = withDTSFilter({
  filterName: 'set',
})(FilterDropdown);

const UnionFilter = withDTSFilter({
  filterName: 'union',
})(FilterDropdown);

const DTSFilters = props => {
  const classes = useStyles();
  const {
    onResetFilters,
    onApplyFilters,
    confirmModalIfDirty,
    filtersPristine,
  } = props;

  const [expandAll, setExpansion] = useState(false);

  function toggleExpansion() {
    setExpansion(!expandAll);
  }

  return (
    <div className={classes.root}>
      <div>
        <section className={classes.title}>
          Refine your search
          <Tooltip
            arrow
            title="Use the filters below to customize your search results."
          >
            <InfoOutlinedIcon className={classes.titleInfoIcon} />
          </Tooltip>
        </section>
        <div className={classes.controls}>
          {filtersPristine ? (
            <Button
              variant="text"
              color="primary"
              className={classes.filterBtns}
              onClick={() => {
                setExpansion(false);
                onResetFilters();
              }}
            >
              <span>Reset filters</span>
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.filterBtns}
              onClick={() => {
                confirmModalIfDirty(onApplyFilters, [{}]);
              }}
            >
              <span>Apply</span>
            </Button>
          )}

          <Button
            variant="text"
            color="primary"
            className={classes.filterBtns}
            onClick={toggleExpansion}
          >
            <span>{expandAll ? 'Collapse' : 'Expand'} all</span>
          </Button>
        </div>
      </div>
      <main className={classes.filters}>
        <DepartmentFilter
          canSelectAll
          enableSearch
          label={`Department`}
          expand={expandAll}
          variant={'dts'}
        />
        <BatchFilter
          canSelectAll
          enableSearch
          label={`Batch`}
          expand={expandAll}
          variant={'dts'}
        />
        <EmployeeFilter
          canSelectAll
          enableSearch
          label={`Employee`}
          expand={expandAll}
          variant={'dts'}
        />
        <AccountFilter
          canSelectAll
          canSelectBlanks
          enableSearch
          label={`Account`}
          expand={expandAll}
          variant={'dts'}
        />
        <EpisodeFilter
          canSelectAll
          canSelectBlanks
          enableSearch
          label={`Episode`}
          expand={expandAll}
          variant={'dts'}
        />
        <SetFilter
          canSelectAll
          canSelectBlanks
          enableSearch
          label={`Set`}
          expand={expandAll}
          variant={'dts'}
        />
        <UnionFilter
          canSelectAll
          enableSearch
          label={`Union`}
          expand={expandAll}
          variant={'dts'}
        />
      </main>
    </div>
  );
};

export default DTSFilters;
