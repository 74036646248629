import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material';

const HPlusIcon = ({ className }) => {
  const theme = useTheme();
  const { palette } = theme;

  const primary = palette.primary.main;
  const background = palette.primary.background;

  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill={primary} />
      <path
        d="M14 5.24675H12.7647V4H12.2353V5.24675H11V5.75325H12.2353V7H12.7647V5.75325H14V5.24675Z"
        fill={background}
      />
      <path
        d="M8.9027 7.56303H4.0973V4H3V12H4.0973V8.43697H8.9027V12H10V4H8.9027V7.56303Z"
        fill={background}
      />
    </svg>
  );
};

HPlusIcon.propTypes = { className: PropTypes.string };

export default HPlusIcon;
