import React, { Component } from 'react';

// components
import { PageHeader } from 'components/Nav';

class ProfileDetails extends Component {
  render() {
    return <PageHeader title="Settings" />;
  }
}

export default ProfileDetails;
