import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

import { isBottomEditCell } from 'feature/DigitalEdits/digitalEditsUtils';

const style = () => ({
  default: {
    position: 'absolute',
  },
});
class Portal extends React.Component {
  render() {
    const {
      classes,
      children,
      targetDom,
      dropdown,
      variant,
      isCheckbox,
      parentTableRef,
    } = this.props;

    if (!targetDom) return <div></div>;

    let styleObj = {};
    let DOM = targetDom.parentNode;
    switch (variant) {
      case 'bulkEdit':
        if (dropdown) {
          styleObj = {
            marginTop: '-24px',
          };
        } else if (isCheckbox) {
          styleObj = {
            marginTop: '-26px',
            marginLeft: '35px',
          };
        } else {
          styleObj = {
            marginTop: '-26px',
            marginLeft: '80px',
          };
        }
        break;
      case 'dts':
        styleObj = {
          marginLeft: '90px',
          marginTop: '0px',
        };
        break;
      case 'crewList':
        styleObj = {
          marginLeft: '80px',
          marginTop: '-30px',
        };
        break;
      case 'ace':
        const isBottomCell = isBottomEditCell(
          targetDom,
          parentTableRef.current,
        );
        DOM = targetDom;
        styleObj = {
          marginTop: isBottomCell ? '-40px' : '-10px',
        };
        break;
      default:
        break;
    }

    return createPortal(
      <div style={styleObj} className={classes.default} id="portalNode">
        {children}
      </div>,
      DOM,
    );
  }
}

Portal.propTypes = {
  classes: PropTypes.object.isRequired,
  targetDom: PropTypes.object,
  variant: PropTypes.string,
  dropdown: PropTypes.bool,
  children: PropTypes.element,
  isCheckbox: PropTypes.bool,
  parentTableRef: PropTypes.object,
};

export default withStyles(style)(Portal);
