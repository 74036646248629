import { default as ProfileDetails } from './ProfileDetails';
import { default as Preferences } from './Preferences';
import { default as Invitations } from './Invitations';
import { default as Settings } from './Settings';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ProfileDetails,
  Preferences,
  Invitations,
  Settings,
};
