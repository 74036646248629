import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { IconButton } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import { TextField } from 'components/Shared/redux';
import { FaPlus as AddIcon, FaTimes as DeleteIcon } from 'react-icons/fa';
import { formatMessages } from 'utils/messages';

const style = ({ palette }) => ({
  recipientRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  fieldContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  field: {
    margin: [8, 4],
  },
  errorField: {
    extend: 'field',
    '& > label': {
      color: palette.error.main,
    },
    '& > p': {
      color: palette.error.main,
    },
  },
  smallIcon: {
    width: 16,
    height: 16,
  },
  small: {
    width: 48,
    height: 48,
    padding: 8,
  },
});

class NotificationRecipient extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    error: PropTypes.object,
    recipient: PropTypes.string,
    addNext: PropTypes.func.isRequired,
    removeRecipient: PropTypes.func.isRequired,
    disableRemove: PropTypes.bool.isRequired,
    canAddNext: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    error: undefined,
  };

  render() {
    const {
      classes,
      addNext,
      recipient,
      error,
      removeRecipient,
      disableRemove,
      canAddNext,
    } = this.props;

    return (
      <div className={classes.recipientRow}>
        <div className={classes.fieldContainer}>
          <Field
            className={
              error && error.email ? classes.errorField : classes.field
            }
            component={TextField}
            label="Email"
            name={`${recipient}.email`}
            hinttext={error && error.email ? formatMessages(error.email) : null}
            required
          />
          <IconButton
            className={classes.small}
            disabled={disableRemove}
            onClick={removeRecipient}
            size="large"
          >
            <DeleteIcon className={classes.smallIcon} />
          </IconButton>
          {canAddNext && (
            <IconButton
              className={classes.small}
              onClick={addNext}
              size="large"
            >
              <AddIcon className={classes.smallIcon} />
            </IconButton>
          )}
        </div>
      </div>
    );
  }
}

export default withStyleSheet(
  'NotificationRecipient',
  style,
)(NotificationRecipient);
