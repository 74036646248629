import React from 'react';

import { useTheme } from '@mui/material';

const BulkEditStart = ({ className }) => {
  const theme = useTheme();
  const { palette } = theme;

  const background = palette.primary.background;
  const primary = palette.primary.main;
  const highlight = palette.primary['+7'];
  const table = palette.primary.table;

  return (
    <svg
      className={className}
      width="211"
      height="202"
      viewBox="0 0 211 202"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="211" height="202" fill={`${table}`} />
      <circle cx="107.5" cy="103" r="97.5" fill={`${background}`} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M202.042 126.37C191.894 169.384 153.258 201.396 107.145 201.396C67.3768 201.396 33.1697 177.587 18 143.446C27.7054 141.272 40.3012 141.22 51.1448 144.896C77.6448 153.879 99.6448 148.396 112.145 144.896C116.045 143.804 120.689 141.684 126.112 139.209C143.276 131.375 168.235 119.984 202.042 126.37Z"
        fill={`${highlight}`}
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="10"
        y="5"
        width="195"
        height="196"
      >
        <circle cx="107.5" cy="103" r="97.5" fill={`${background}`} />
      </mask>
      <g mask="url(#mask0)">
        <rect x="19" y="9.5" width="11" height="170" fill={`${primary}`} />
        <rect x="186" y="9.5" width="11" height="170" fill={`${primary}`} />
      </g>
      <rect x="19" y="9.5" width="11" height="121" fill={`${primary}`} />
      <rect x="186" y="9.5" width="11" height="121" fill={`${primary}`} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M210.398 0.113497L211 0L211 54.7961H210.5L210.64 55.2299C129.514 76.6392 46.7429 63.975 0.397931 55.2385L0 55.1635V0.103979H1V0.216868C72 15.5 166.5 14.5 210.398 0.113497Z"
        fill={`${highlight}`}
      />
      <g clipPath="url(#clip0)">
        <path
          d="M58.1257 45.4877C58.2914 44.0238 57.9028 42.8379 56.9539 41.93C56.0051 41.0221 54.2274 40.0121 51.6209 38.9057C49.0087 37.7992 46.9624 36.6587 45.4819 35.4898C42.6411 33.2428 41.395 30.593 41.7437 27.5289C42.0466 24.845 43.3956 22.7626 45.7963 21.2703C48.197 19.778 51.1522 19.2276 54.6675 19.6191C56.9996 19.8801 59.0288 20.5383 60.7607 21.5937C62.4927 22.6491 63.7902 24.0223 64.6533 25.7189C65.5164 27.4098 65.8423 29.2198 65.625 31.1377L59.8233 30.4909C60.0177 28.7546 59.6233 27.336 58.6401 26.2295C57.6513 25.1231 56.1422 24.4592 54.1016 24.2322C52.1982 24.0223 50.6778 24.2606 49.5346 24.9472C48.3914 25.6394 47.7397 26.7062 47.574 28.1531C47.4368 29.373 47.8884 30.4511 48.9344 31.3931C49.9804 32.335 51.7581 33.3336 54.2845 34.3947C56.8053 35.4558 58.8002 36.5622 60.2749 37.7254C61.7439 38.883 62.7785 40.1426 63.3672 41.4931C63.9617 42.8492 64.1618 44.3756 63.9674 46.0835C63.653 48.8582 62.3326 50.9406 60.0062 52.3421C57.6798 53.7437 54.6847 54.2373 51.015 53.8288C48.5914 53.5564 46.4136 52.8642 44.4759 51.7463C42.5325 50.6342 41.0921 49.227 40.1432 47.5304C39.1944 45.8338 38.8342 43.9443 39.0686 41.8562L44.8932 42.5031C44.6817 44.3926 45.1447 45.9246 46.2879 47.0992C47.4311 48.2737 49.1745 48.9944 51.5237 49.2554C53.5472 49.4823 55.1191 49.244 56.2337 48.5404C57.3426 47.8482 57.9713 46.8268 58.1257 45.4877Z"
          fill={`${primary}`}
        />
        <path
          d="M94.3364 28.0737L83.8419 27.3531L81.847 56.0758L76.0453 55.6786L78.0402 26.9559L67.6428 26.2466L67.9686 21.5654L94.6623 23.3925L94.3364 28.0737Z"
          fill={`${primary}`}
        />
        <path
          d="M110.101 49.4597L97.0402 49.0909L94.0851 56.8078L87.9976 56.6375L101.687 23.5287L106.946 23.6762L118.755 57.5L112.639 57.3298L110.101 49.4597ZM98.8179 44.4494L108.592 44.7218L104.099 30.7009L98.8179 44.4494Z"
          fill={`${primary}`}
        />
        <path
          d="M133.919 44.0182L127.392 44.2565L127.866 57.1255L122.007 57.3412L120.773 23.8805L132.628 23.4493C136.515 23.3074 139.55 24.0621 141.727 25.7189C143.905 27.3758 145.054 29.8441 145.174 33.1237C145.254 35.365 144.78 37.2545 143.751 38.8093C142.716 40.3583 141.247 41.5783 139.332 42.4578L147.415 56.1155L147.426 56.4163L141.15 56.6432L133.919 44.0182ZM127.22 39.5639L133.239 39.3426C135.217 39.2689 136.737 38.7185 137.812 37.6915C138.887 36.6644 139.39 35.2913 139.327 33.572C139.258 31.779 138.698 30.4115 137.635 29.4696C136.572 28.5277 135.023 28.0794 132.982 28.1191L126.803 28.3461L127.22 39.5639Z"
          fill={`${primary}`}
        />
        <path
          d="M174 24.8054L163.54 25.8892L166.558 54.5211L160.773 55.1226L157.755 26.4906L147.386 27.5687L146.9 22.9046L173.514 20.1412L174 24.8054Z"
          fill={`${primary}`}
        />
      </g>
      <g clipPath="url(#clip1)">
        <path
          d="M2.26819 5.14946V3.84033C2.26819 3.84033 2.71885 3.93459 3.57725 4.10739"
          stroke={`${primary}`}
          strokeMiterlimit="10"
        />
        <path
          d="M4.62878 4.31161C21.1476 7.52159 121.167 25.3415 205.902 4.2959"
          stroke={`${primary}`}
          strokeMiterlimit="10"
          strokeDasharray="5.01 5.01"
        />
        <path
          d="M206.434 4.17023C206.868 4.06027 207.297 3.9503 207.732 3.84033V5.14946"
          stroke={`${primary}`}
          strokeMiterlimit="10"
        />
        <path
          d="M207.731 6.20728V50.1102"
          stroke={`${primary}`}
          strokeMiterlimit="10"
          strokeDasharray="5.05 5.05"
        />
        <path
          d="M207.732 50.639V51.9482C207.732 51.9482 207.297 52.0791 206.444 52.3042"
          stroke={`${primary}`}
          strokeMiterlimit="10"
        />
        <path
          d="M205.403 52.5818C191.004 56.3312 111.097 74.7113 4.10828 52.3357"
          stroke={`${primary}`}
          strokeMiterlimit="10"
          strokeDasharray="5.02 5.02"
        />
        <path
          d="M3.58261 52.2257C3.14805 52.1315 2.70812 52.0424 2.26819 51.9482V50.639"
          stroke={`${primary}`}
          strokeMiterlimit="10"
        />
        <path
          d="M2.26819 49.5813V5.67834"
          stroke={`${primary}`}
          strokeMiterlimit="10"
          strokeDasharray="5.05 5.05"
        />
      </g>
      <mask
        id="mask1"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="10"
        y="5"
        width="195"
        height="197"
      >
        <ellipse
          cx="107.5"
          cy="103.5"
          rx="97.5"
          ry="98"
          fill={`${background}`}
        />
      </mask>
      <g mask="url(#mask1)">
        <path
          d="M155.066 128.5C162.342 134.96 173.655 147.323 158.5 159.739C145.335 170.529 134.456 175.252 132.869 196.165C130.98 221.13 164.002 251.061 164.002 251.061L73.0255 259.5C73.0255 259.5 60.8677 240.902 74.3723 203.336C85.6896 171.866 119.741 162.963 139.708 156.284C157.958 150.177 159.513 134.96 152.236 128.5H155.066Z"
          fill={`${background}`}
        />
        <path
          d="M157.38 135.174C158.522 137.191 159.26 139.03 159.656 140.716L160.862 140.777C160.335 138.796 159.42 136.914 158.3 135.221L157.38 135.174V135.174Z"
          fill={`${primary}`}
        />
        <path
          d="M157.442 150.525L159.748 150.641C161.346 147.757 161.651 144.783 161.132 141.968L159.887 141.905C160.409 145.433 159.378 148.239 157.442 150.525Z"
          fill={`${primary}`}
        />
        <path
          d="M157.434 134.005C156.482 132.75 155.431 131.628 154.366 130.675C155.242 131.818 155.998 132.912 156.654 133.966L157.434 134.005Z"
          fill={`${primary}`}
        />
        <path
          d="M116.929 201.584C116.889 198.427 117.198 195.204 117.931 191.929C119.173 186.379 121.227 181.95 123.806 178.294L111.717 177.685C107.322 182.856 104.71 188.708 102.696 195.816C102.217 197.506 101.788 199.165 101.4 200.796L116.93 201.579L116.929 201.584Z"
          fill={`${primary}`}
        />
        <path
          d="M154.131 153.465C151.372 155.382 147.971 156.842 144.669 158.073C143.367 158.559 142.104 159.043 140.885 159.523L148.759 159.92C150.218 159.1 151.645 158.258 153.021 157.375C154.883 156.18 156.379 154.926 157.567 153.633L154.131 153.46L154.131 153.465Z"
          fill={`${primary}`}
        />
        <path
          d="M134.294 162.298C127.44 165.376 122.11 168.473 117.892 171.792L129.09 172.357C133.478 168.372 138.563 165.493 143.573 162.765L134.294 162.298Z"
          fill={`${primary}`}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="135"
            height="38"
            fill="white"
            transform="translate(39 19.5)"
          />
        </clipPath>
        <clipPath id="clip1">
          <rect
            width="206"
            height="60"
            fill="white"
            transform="translate(2 3.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BulkEditStart;
