// import { createPalette } from '@mui/material/styles';
import { linearGradient, grayToGrey } from './themeUtils';

import cncUI from './cncui';

const primaryColors = cncUI.primary.blue;
const secondaryColors = cncUI.primary.oldBlue;

const grey = grayToGrey(cncUI.primary.gray);

const palette = {
  mode: 'light',
  primary: {
    ...primaryColors,
    main: primaryColors.primary,
    contrastText: cncUI.white,
  },
  secondary: {
    ...secondaryColors,
    main: secondaryColors.primary,
  },
  grey,
  gray: { ...cncUI.primary.gray, main: cncUI.primary.gray.primary },
  error: { main: cncUI.alert.red.primary, powder: cncUI.alert.red['+3'] },
  warning: {
    main: cncUI.alert.yellow.primary,
    dark: cncUI.accent.orange['-3'],
  },
  button: {
    primary: {
      color: cncUI.white,
      hover: cncUI.button.blue.hover,
      focus: cncUI.button.blue.focus,
      background: cncUI.button.blue.primary,
      disabled: cncUI.button.blue.disabled,
    },
    error: {
      color: cncUI.white,
      hover: cncUI.button.red.hover,
      focus: cncUI.button.red.focus,
      background: cncUI.button.red.primary,
      disabled: cncUI.button.red.disabled,
    },
    lightRed: {
      color: cncUI.alert.red.dark,
      background: cncUI.alert.red.light,
    },
    orange: {
      color: cncUI.accent.orange['-1'],
      background: cncUI.accent.orange['+3'],
    },
    lightGreen: {
      color: cncUI.alert.green.dark,
      background: cncUI.alert.green.light,
    },
    gray: {
      color: cncUI.primary.gray['+2'],
      background: cncUI.primary.gray['+8'],
    },
    yellow: {
      color: '#C0A248',
      background: cncUI.alert.yellow.light,
    },
    deselect: {
      color: cncUI.primary.gray['+7'],
      background: cncUI.primary.gray['+9'],
    },
  },
  success: { main: cncUI.alert.green.primary },
  misc: { ...cncUI.misc },
  badge: { ...cncUI.badge },
};

palette.getDefaultColor = () => cncUI.primary.gray.primary;
palette.brand = linearGradient(
  'to right',
  primaryColors.primary,
  primaryColors['-3'],
);
palette.dashboard = linearGradient(
  'to bottom',
  primaryColors.primary,
  primaryColors['-3'],
);

export default palette;
