import { createComponent, mapError } from 'utils/redux';
// import AutoComplete from './AutoCompletePlain';
import WTCAutoComplete from './WTCAutoCompletePlain';
import _ from 'lodash';

export default createComponent(
  WTCAutoComplete,
  ({
    input: { onChange, value, onBlur, ...inputProps },
    onChange: onChangeFromField,
    defaultValue,
    required,
    ...props
  }) => {
    return {
      ...mapError({ ...props }),
      ...inputProps,
      required,
      value: value,
      filterOption: props.filterOptions,
      onChange: (selected, isMultiSelect, objectSelect = false) => {
        if (isMultiSelect) {
          if (selected) {
            const values = _.map(selected, item => {
              return item.value;
            });
            onChange(values);

            if (onChangeFromField) {
              onChangeFromField(values);
            }
          }
        } else if (objectSelect) {
          if (selected) {
            onChange(selected);
          }
        } else {
          if (selected) {
            onChange(selected.value);

            if (onChangeFromField) {
              onChangeFromField(selected.value);
            }
          }
        }
        if (!selected) {
          onChange(null);
          if (onChangeFromField) {
            onChangeFromField();
          }
        }
      },
      onBlur: () => onBlur(value),
    };
  },
);
