import React from 'react';
import {
  FullWidthLayout,
  Header,
  AlertHeader,
  ProjectHeader,
} from 'containers/Layout';

import { EnhancedRoute } from 'containers/RoutingSupport';
import { ReviewTimecard } from 'containers/Employees/Reviews';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <EnhancedRoute
      exact
      authenticated
      key="/projects/reviews/timecards"
      id="/projects/reviews/timecards"
      path="/projects/:projectId/reviews/timecards/:timecardId"
      layout={FullWidthLayout}
      headers={[Header, ProjectHeader, AlertHeader]}
      component={ReviewTimecard}
      isLoadProjectActiveUser={true}
      title="Review Timecard"
    />
  );
};
