import React, { Component } from 'react';
import { connect } from 'react-redux';

//selectors
import {
  getFilteredDropdown,
  getCustomFilter,
  getSelectedCount,
} from 'selectors/dts';
// actions
import {
  setSearchFilter,
  onSelect,
  onSelectAll,
  addShowBlanksOption,
} from 'actions/dts';

//utils
import { shouldAddBlanksToList } from './dtsUtils';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ filterName }) => {
  const mapState = state => {
    return {
      searchFilter: getCustomFilter(state, filterName),
      dropdownSelection: getFilteredDropdown(state, filterName),
      selectedCount: getSelectedCount(state, filterName),
    };
  };

  const mapDispatch = dispatch => {
    return {
      setSearchFilter: e => {
        const value = e.target.value;
        dispatch(setSearchFilter({ value: value, filterName }));
      },
      onSelectAll: () => {
        dispatch(onSelectAll({ filterName }));
      },
      onSelect: ({ index }) => {
        dispatch(onSelect({ index, filterName }));
      },
      onAddShowBlanksOption: filterName =>
        dispatch(addShowBlanksOption({ filterName })),
    };
  };

  return BaseComponent => {
    class withDTSFilter extends Component {
      static displayName = `withDTSFilter(${filterName})`;

      componentDidUpdate() {
        if (
          this.props.canSelectBlanks &&
          this.props.searchFilter === '' &&
          shouldAddBlanksToList(this.props.dropdownSelection)
        ) {
          this.props.onAddShowBlanksOption(filterName);
        }
      }

      render() {
        return <BaseComponent {...this.props} />;
      }
    }

    return connect(mapState, mapDispatch)(withDTSFilter);
  };
};
