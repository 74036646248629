import { default as Projects } from './Projects';
import { default as FeatureFlags } from './FeatureFlags';
import { default as SuperAdmin } from './SuperAdmin';
import { default as UsersAllProjects } from './UsersAllProjects';
import { default as AccessAdmin } from './AccessAdmin';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Projects,
  FeatureFlags,
  SuperAdmin,
  UsersAllProjects,
  AccessAdmin,
};
