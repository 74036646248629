import {
  all,
  takeEvery,
  takeLatest,
  call,
  put,
  select,
} from 'redux-saga/effects';
import camelCase from 'camelcase-keys';

// actions
import * as actions from 'actions/users';
import { showAlert } from 'actions/alert';
import {
  initialize as enablePagination,
  navigate as navigatePagination,
} from 'actions/pagination';

// selectors
import { getProject as project } from 'selectors/routeParams';

function* fetch(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const { page } = params;
    const { form } = params;
    const projectId = yield select(project);
    const data = yield call(api.admin.users, { projectId });
    const users = camelCase(data.employeesList, { deep: true });
    yield put(actions.store({ users }));
    yield put(actions.loading({ loading: false }));
    const gridName = form === 'crew' ? 'crew-users' : 'admin-users';
    if (page) {
      yield put(
        navigatePagination({
          grid: gridName,
          totalCount: users.length,
          page: page,
        }),
      );
    } else {
      yield put(enablePagination({ grid: gridName, totalCount: users.length }));
    }

    /*
      This was causing extra API calls.  Keeping it here
      just in case someone had a reason for it.
    */
    // yield put(actions.fetchEmployees());
  } catch (e) {
    debug(e);
    yield put(actions.store({ users: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

function* fetchUsersAllProjects(api, debug, params) {
  try {
    const { page, emailOrFullName } = params;
    yield put(actions.loading({ loading: true }));
    if (emailOrFullName !== '') {
      const data = yield call(api.users.getUsersOfAllProjects, {
        emailOrFullName: emailOrFullName,
      });
      const allUsers = camelCase(data, { deep: true });
      yield put(actions.storeUsersAllProjects({ allUsers }));
      const gridName = 'users-all-projects';
      if (page) {
        yield put(
          navigatePagination({
            grid: gridName,
            totalCount: allUsers.length,
            page: page,
          }),
        );
      } else {
        yield put(
          enablePagination({ grid: gridName, totalCount: allUsers.length }),
        );
      }
    } else {
      yield put(actions.storeUsersAllProjects({ allUsers: [] }));
    }
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeUsersAllProjects({ allUsers: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

export default function* usersFlow({ api, debug }) {
  yield all([
    takeEvery(`${actions.fetch}`, fetch, api, debug),
    takeLatest(
      `${actions.fetchUsersAllProjects}`,
      fetchUsersAllProjects,
      api,
      debug,
    ),
  ]);
}
