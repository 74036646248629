import { produce } from 'immer';
import * as actions from 'actions/pagination';
import { DEFAUL_PAGE_SIZE } from 'components/Shared/constants';

const initialState = {};

export const defaultPagination = {
  pageSize: DEFAUL_PAGE_SIZE, // Let's fix the min size to 10 for better tesing.
  activePage: 0,
  totalCount: 0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.initialize}`:
        draft[action.grid] = {
          ...defaultPagination,
          totalCount: action.totalCount || 0,
          pageSize: action.pageSize || DEFAUL_PAGE_SIZE,
          disable: action.disable || false,
        };
        break;

      case `${actions.remove}`:
        delete draft[action.grid];
        break;

      case `${actions.navigate}`:
        draft[action.grid].activePage = action.page;
        break;

      case `${actions.changeSize}`:
        draft[action.grid].activePage = 0;
        draft[action.grid].pageSize = action.pageSize;
        break;

      default:
    }
  });
