import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import clsx from 'clsx';

const style = ({ palette }) => ({
  root: {
    // border: '1px solid',
    borderRadius: 8,
    whiteSpace: 'nowrap',
    display: 'inline-block',
    boxSizing: 'border-box',
    padding: '4px 7px',
    fontFamily: 'inherit',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    color: palette.common.white,
    textDecoration: 'none',
  },
  altWeight: {
    fontWeight: 200,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  ready: {
    color: palette.button.orange.color,
    backgroundColor: palette.button.orange.background,
  },
  processing: {
    color: palette.button.yellow.color,
    backgroundColor: palette.button.yellow.background,
  },
  primary: {
    color: palette.button.lightGreen.color,
    backgroundColor: palette.button.lightGreen.background,
  },
  incomplete: {
    color: palette.button.lightRed.color,
    backgroundColor: palette.button.lightRed.background,
  },
  submitted: {
    color: palette.primary.main,
    backgroundColor: palette.primary['+8'],
  },
  paid: {
    color: palette.button.gray.color,
    backgroundColor: palette.button.gray.background,
  },
});

class PillBadge extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    tertiary: PropTypes.bool,
    warning: PropTypes.bool,
    error: PropTypes.bool,
    uppercase: PropTypes.bool,
    altWeight: PropTypes.bool,
    status: PropTypes.string,
  };

  static defaultProps = {
    primary: false,
    uppercase: false,
    altWeight: false,
    status: '',
  };

  render() {
    const {
      classes,
      children,
      primary,
      secondary,
      tertiary,
      status,
      warning,
      error,
      uppercase,
      altWeight,
    } = this.props;
    // THe six statuses
    // primary == green
    // ready == orange
    // incomplete = red
    // paid = gray
    // processing = yellow
    // submitted = lighter green

    const labelCls = clsx(classes.root, {
      [classes.primary]: primary || status === 'primary',
      [classes.uppercase]: uppercase,
      [classes.altWeight]: altWeight,
      [classes.ready]: secondary || status === 'ready',
      [classes.processing]: tertiary || status === 'processing',
      [classes.incomplete]: warning || status === 'incomplete',
      [classes.submitted]: error || status === 'submitted',
      [classes.paid]: error || status === 'paid',
    });

    return <Typography className={labelCls}>{children}</Typography>;
  }
}

export default withStyleSheet('Badge', style)(PillBadge);
