import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import clsx from 'clsx';

const style = ({ palette }) => ({
  root: {
    border: '1px solid',
    borderRadius: '16px',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    boxSizing: 'border-box',
    padding: '5px 12px',
    fontFamily: 'inherit',
    fontSize: '12px',
    lineHeight: '1em',
    color: palette.action.disabled,
    borderColor: palette.action.disabled,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  primary: {
    backgroundColor: 'transparent',
    color: palette.secondary.light,
    borderColor: palette.secondary.light,
    marginRight: 5,
  },
  transparent: {
    backgroundColor: palette.primary['-3'],
    fontWeight: 500,
    color: palette.common.white,
    borderColor: palette.primary['-3'],
    textTransform: 'uppercase',
    margin: 5,
  },
});

class Label extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    primary: PropTypes.bool,
    uppercase: PropTypes.bool,
    transparent: PropTypes.bool,
  };

  static defaultProps = {
    primary: false,
    uppercase: false,
  };

  render() {
    const { classes, children, primary, uppercase, transparent } = this.props;

    const labelCls = clsx(classes.root, {
      [classes.primary]: primary,
      [classes.uppercase]: uppercase,
      [classes.transparent]: transparent,
    });

    return <Typography className={labelCls}>{children}</Typography>;
  }
}

export default withStyleSheet('Label', style)(Label);
