import React from 'react';
import PropTypes from 'prop-types';
import { useScrollTrigger, Fade } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    zIndex: 5,
  },
}));

const FadeInScroll = ({ children, customThreshold, target = window }) => {
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: customThreshold,
    target,
  });
  // For potential sticky scroll setup
  // window.onscroll = function() {};
  return (
    <Fade in={trigger}>
      <div className={classes.root}>{children}</div>
    </Fade>
  );
};

FadeInScroll.propTypes = {
  children: PropTypes.element.isRequired,
  customThreshold: PropTypes.number.isRequired,
  target: PropTypes.object,
};

export default FadeInScroll;
