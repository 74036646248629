import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, getEmployeeSIN as checkIsSIN } from 'utils/helperFunctions';
import { Field } from 'redux-form';
import _ from 'lodash';
import { withStyleSheet } from 'shared/theme';
import { ReactComponent as HoursCncLogoWhite } from 'components/images/logos/hours-logo-white.svg';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Button,
  Typography,
  Box,
  TextField as TextFieldComp,
} from '@mui/material';
import clsx from 'clsx';
import { normalizeSSN, normalizeSIN } from 'utils/weekUtils';
import { LUHN_SIN_FORMAT, formatMessage } from 'utils/messages';
import { Card, CardHeader, CardContent } from 'components/Shared/Card';
import { ERROR_MESSAGES } from 'components/Shared/constants/ErrorMessages';
import Message from 'components/Message';
import { ssnMasking, ssnFormat, sinMasking, sinFormat } from 'utils';
import { logout } from 'actions/authenticate';
import validator from 'utils/validator';

const style = ({ palette }) => ({
  root: {
    width: '100%',
    marginBottom: 0,
  },
  backToListContainer: {
    textAlign: 'left',
    marginLeft: '-10px',
  },
  xLBackToListContainer: {
    textAlign: 'left',
    marginLeft: '-10px',
  },
  backToListButton: {
    margin: 0,
  },
  backToList: {
    margin: 5,
  },
  cardStyle: {
    display: 'block',
    width: '100%',
    height: '90vh',
    overflowY: 'auto',
    borderRadius: 0,
  },
  cardHeader: {
    textAlign: 'center',
    '& > div': {
      backgroundImage: `linear-gradient(0deg, ${palette.primary['-7']} 0%, ${palette.primary['-3']} 100%);`,
    },
  },
  cardContent: {
    color: palette.text.disabled,
  },
  cardContentXL: {
    maxWidth: '25%',
    color: palette.text.disabled,
    margin: '-10px auto',
  },
  cardFooter: {
    display: 'flex',
    padding: '10px 15px 30px 15px',
  },
  cardFooterXL: {
    display: 'flex',
    margin: '0 auto 0 auto',
    maxWidth: '25%',
    padding: '5px 0 35px 0',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      width: '100%',
    },
  },
  or: {
    marginTop: 10,
  },
  infoRow: {
    display: 'flex',
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: '7px ',
    '& > *': {
      width: 'auto ',
    },
  },
  xLTitleRow: {
    paddingBottom: 1,
    paddingTop: 15,
    color: palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 400,
    '& > *': {
      width: '100%',
    },
  },

  belowTitle: {
    marginBottom: 15,
  },

  field: {
    marginTop: 8,
    marginBottom: 6,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '80vw',
  },
  xsVWLock: {
    paddingLeft: '6.1vw ',
    paddingRight: '6.1vw ',
  },
  fieldXL: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '25%',
  },
  input: {
    fontSize: '12px ',
    fontWeight: '400 ',
    marginLeft: '10px',
    marginTop: '-10px',
  },
  inputStyle: {
    fontSize: '12px ',
    fontWeight: '400 ',
  },
  inputContainer: {
    margin: '10px auto 0 auto',
    width: '100%',
    paddingBottom: '7%',
    paddingTop: '6%',
  },
  xLInputContainer: {
    width: '100vw',
    position: 'relative',
    left: '50%',
    right: '50%',
    marginLeft: '-50vw',
    marginRight: '-50vw',
    flexDirection: 'column',
    paddingBottom: '5%',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > *': {
      width: 'auto',
    },
    paddingBottom: '15px ',
  },
  personInfoXL: {
    color: palette.text.secondary,
    fontWeight: '500 ',
    textAlign: 'left ',
  },
  personInfo: {
    color: palette.text.secondary,
    fontWeight: '500 ',
  },
  personInfoXS: {
    textAlign: 'left ',
    fontWeight: '500 ',
  },
  personLabel: {
    marginLeft: 'auto',
    marginRight: 4,
    textAlign: 'right ',
    float: 'left ',
    minWidth: '90px ',
  },
  personLabelXS: {
    marginLeft: 'auto',
    marginRight: 4,
    textAlign: 'right ',
    float: 'left ',
    minWidth: '90px ',
  },
  buttonRight: {
    margin: '8px auto 6px auto',
    minHeight: '20px',
  },
  buttonLeft: {
    margin: '8px auto 8px auto',
    minHeight: '20px',
  },
  buttonXLLeft: {
    marginTop: '2px',
    marginBottom: 6,
    marginLeft: 0,
    marginRight: 18,
    width: '46%',
  },
  buttonXLRight: {
    marginTop: '2px',
    marginBottom: 6,
    marginLeft: 18,
    marginRight: 0,
    width: '46%',
  },
  logoIcon: {
    height: 75,
    lineHeight: 64,
    width: 'auto',
  },
  logoIconMobile: {
    height: '40px ',
  },
  warning: {
    color: palette.warning.main,
    margin: 0,
    fontSize: '0.75rem',
    textAlign: 'left',
    fontWeight: 400,
  },
  error: {
    color: palette.error.main,
    margin: 0,
    fontSize: '0.75rem',
    textAlign: 'left',
    fontWeight: 400,
  },
});

const mapDispatch = dispatch => ({
  onLogOut: () => {
    dispatch(logout());
  },
});

const TypographyCustom = props => {
  const { content, variant, className = undefined } = props;
  return (
    <Typography className={className} variant={variant}>
      {content}
    </Typography>
  );
};

TypographyCustom.propTypes = {
  content: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
};

const Invitations = props => {
  const { classes, error, synchronousError, anyTouched, onLogOut } = props;
  const { change } = props;

  let invalidUserError = null;
  let { invitation } = props;
  let isSin = true;
  if (invitation?.ssnMask && invitation.ssnMask.includes('-')) {
    isSin = checkIsSIN(invitation);
  } else {
    invalidUserError = 'Invalid User';
  }

  invitation = {
    ...invitation,
  };

  const checkWidth = width => {
    return width <= 718;
  };

  const checkXSWidth = width => {
    return width <= 610;
  };

  const ssnError =
    synchronousError && synchronousError.ssn ? synchronousError.ssn : null;
  const sinError =
    synchronousError && synchronousError.sin ? synchronousError.sin : null;

  const [inputSSN, setInputSSN] = useState('');
  const [inputSIN, setInputSIN] = useState('');
  const [desktop, setDesktop] = useState(
    !(
      checkWidth(document.documentElement.clientWidth) &&
      checkXSWidth(document.documentElement.clientWidth)
    ),
  );

  const [mobile, setMobile] = useState(
    checkWidth(document.documentElement.clientWidth),
  );

  const [xsMobile, setXSMobile] = useState(
    checkXSWidth(document.documentElement.clientWidth),
  );

  const [warning, setWarning] = useState(null);
  const getMobileState = () => {
    const clientWidth = document.documentElement.clientWidth;
    setMobile(checkWidth(clientWidth));
    setXSMobile(checkXSWidth(clientWidth));
    setDesktop(!(checkWidth(clientWidth) && checkXSWidth(clientWidth)));
  };

  const nonBlockingValidate = sin => {
    if (sin && !validator.isValidLuhnSin(sin)) {
      const sinWarning = formatMessage(LUHN_SIN_FORMAT);
      setWarning(sinWarning);
    }
  };
  const onSSNBlur = event => {
    if (event && event.target.value) {
      if (isSin) {
        const sinMasked = sinMasking(event.target.value);
        document.getElementById('sinMasking').value = sinMasked;
        change('anyTouched', true);
      } else {
        const ssnMasked = ssnMasking(event.target.value);
        document.getElementById('ssnMasking').value = ssnMasked;
        change('anyTouched', true);
      }
    }
  };

  const onSSNChange = event => {
    if (event && event.target.value) {
      if (isSin) {
        setWarning(null);
        setInputSIN(event.target.value);
        change('sin', event.target.value);
      } else {
        setInputSSN(event.target.value);
        change('ssn', event.target.value);
      }
    } else {
      if (isSin) {
        setWarning(null);
        setInputSIN(null);
        change('sin', null);
      } else {
        setInputSSN(null);
        change('ssn', null);
      }
    }
  };

  const onSSNFocus = event => {
    if (event) {
      isSin
        ? (document.getElementById('sinMasking').value = inputSIN)
        : (document.getElementById('ssnMasking').value = inputSSN);
    }
  };

  const onFormatSSN = event => {
    if (event) {
      const position = event.target.selectionStart;
      if (isSin) {
        document.getElementById('sinMasking').value = sinFormat(
          event.target.value,
        );
        let sin = event.target.value;
        if (sin && sin.includes('-')) {
          sin = sin.replace(/-/g, '');
          if (sin.length === 9) {
            nonBlockingValidate(sin);
          }
        }
      } else {
        document.getElementById('ssnMasking').value = ssnFormat(
          event.target.value,
        );
      }
      if (event.key === 'Delete') {
        event.target.selectionEnd = position;
        event.target.selectionStart = position;
      }
    }
  };

  window.addEventListener('resize', () => {
    getMobileState();
  });

  return (
    <Box
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Card className={classes.cardStyle}>
        <CardHeader className={classes.cardHeader}>
          <HoursCncLogoWhite
            className={
              mobile
                ? `${classes.logoIcon} ${classes.logoIconMobile}`
                : classes.logoIcon
            }
          />
        </CardHeader>
        <CardContent
          className={
            !mobile && !xsMobile ? classes.cardContentXL : classes.cardContent
          }
        >
          <div className={classes.root}>
            <div
              className={
                desktop
                  ? classes.xLBackToListContainer
                  : classes.backToListContainer
              }
            >
              <Button
                variant="text"
                color="primary"
                className={classes.backToListButton}
                align="left"
                href="/"
              >
                <ChevronLeftIcon />
                <span className={classes.backToList}>Back to Projects</span>
              </Button>
            </div>

            <div className={classes.infoContainer}>
              <div className={clsx(classes.row, classes.infoRow)}>
                <TypographyCustom
                  content={`First Name:`}
                  variant={'caption'}
                  className={
                    desktop ? classes.personLabel : classes.personLabelXS
                  }
                />
                <TypographyCustom
                  content={invitation.firstName}
                  variant={'caption'}
                  className={clsx({
                    [classes.personInfoXL]: desktop,
                    [classes.personInfo]: xsMobile,
                    [classes.personInfoXS]: xsMobile,
                  })}
                />
              </div>
              <div className={clsx(classes.row, classes.infoRow)}>
                <TypographyCustom
                  content={`Middle Name:`}
                  variant={'caption'}
                  className={
                    !xsMobile ? classes.personLabel : classes.personLabelXS
                  }
                />
                <TypographyCustom
                  content={
                    invitation.middleName ? invitation.middleName : 'n/a'
                  }
                  variant={'caption'}
                  className={clsx({
                    [classes.personInfoXL]: desktop,
                    [classes.personInfo]: xsMobile,
                    [classes.personInfoXS]: xsMobile,
                  })}
                />
              </div>
              <div className={clsx(classes.row, classes.infoRow)}>
                <TypographyCustom
                  content={`Last Name:`}
                  variant={'caption'}
                  className={
                    !xsMobile ? classes.personLabel : classes.personLabelXS
                  }
                />
                <TypographyCustom
                  content={invitation.lastName ? invitation.lastName : 'n/a'}
                  variant={'caption'}
                  className={clsx({
                    [classes.personInfoXL]: !xsMobile,
                    [classes.personInfo]: xsMobile,
                    [classes.personInfoXS]: xsMobile,
                  })}
                />
              </div>
              <div className={clsx(classes.row, classes.infoRow)}>
                <TypographyCustom
                  content={`Email:`}
                  variant={'caption'}
                  className={
                    !xsMobile ? classes.personLabel : classes.personLabelXS
                  }
                />
                <TypographyCustom
                  content={invitation.email ? invitation.email : 'n/a'}
                  variant={'caption'}
                  className={clsx({
                    [classes.personInfoXL]: !xsMobile,
                    [classes.personInfo]: xsMobile,
                    [classes.personInfoXS]: xsMobile,
                    [classes.emailGradient]: true,
                  })}
                />
              </div>
            </div>
            <div
              className={
                !mobile && desktop
                  ? classes.xLInputContainer
                  : classes.inputContainer
              }
            >
              <div
                className={clsx({
                  [classes.xLTitleRow]: !mobile && desktop,
                  [classes.fieldXL]: !mobile && desktop,
                  [classes.row]: mobile && !desktop,
                  [classes.xsVWLock]: xsMobile,
                })}
              >
                <TypographyCustom content={`Please Provide`} variant={'h6'} />
              </div>
              <div>
                {isSin ? (
                  <div>
                    <div className={classes.row}>
                      <TextFieldComp
                        id="sinMasking"
                        className={clsx({
                          [classes.field]: true,
                          [classes.fieldXL]: !mobile,
                        })}
                        label="Social Insurance Number"
                        onBlur={event => onSSNBlur(event)}
                        onChange={event => onSSNChange(event)}
                        onFocus={event => onSSNFocus(event)}
                        onKeyUp={event => onFormatSSN(event)}
                        helperText={sinError}
                        error={sinError && sinError.length > 0 && anyTouched}
                        InputLabelProps={{
                          className: classes.input,
                        }}
                        inputProps={{
                          maxLength: 11,
                          autoComplete: 'new-password',
                          className: classes.inputStyle,
                        }}
                      />
                      <Field
                        className={clsx({
                          [classes.field]: true,
                          [classes.fieldXL]: !mobile && desktop,
                        })}
                        component="input"
                        type="hidden"
                        label="Social Insurance Number"
                        name="sin"
                        value={inputSIN}
                        normalize={normalizeSIN}
                      />
                    </div>
                    <div
                      className={clsx({
                        [classes.field]: true,
                        [classes.fieldXL]: !mobile && desktop,
                      })}
                    >
                      <div className={classes.warning}>{warning}</div>
                      {invalidUserError && (
                        <div className={classes.error}>{invalidUserError}</div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={classes.row}>
                      <TextFieldComp
                        id="ssnMasking"
                        className={clsx({
                          [classes.field]: true,
                          [classes.fieldXL]: !mobile,
                        })}
                        label="Social Security Number"
                        onBlur={event => onSSNBlur(event)}
                        onChange={event => onSSNChange(event)}
                        onFocus={event => onSSNFocus(event)}
                        onKeyUp={event => onFormatSSN(event)}
                        helperText={ssnError}
                        error={ssnError && ssnError.length > 0 && anyTouched}
                        InputLabelProps={{
                          className: classes.input,
                        }}
                        inputProps={{
                          maxLength: 11,
                          autoComplete: 'new-password',
                          className: classes.inputStyle,
                        }}
                      />
                      <Field
                        className={clsx({
                          [classes.field]: true,
                          [classes.fieldXL]: !mobile && desktop,
                        })}
                        component="input"
                        type="hidden"
                        label="Social Security Number"
                        name="ssn"
                        value={inputSSN}
                        normalize={normalizeSSN}
                      />
                    </div>
                  </div>
                )}{' '}
              </div>
            </div>
            {error && (
              <Message error>
                {_.map(error.profileMismatch, key => ERROR_MESSAGES[key])}
              </Message>
            )}
          </div>
        </CardContent>
        <Box
          className={desktop ? classes.cardFooterXL : classes.cardFooter}
          flexDirection={!desktop ? 'column' : 'row'}
          justifyContent={'center'}
        >
          {!desktop ? (
            <>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={
                  !mobile && desktop ? classes.buttonXLLeft : classes.buttonLeft
                }
                fullWidth
              >
                Continue
              </Button>
              <Button
                color="primary"
                variant="outlined"
                className={
                  !mobile && desktop
                    ? classes.buttonXLRight
                    : classes.buttonRight
                }
                fullWidth
                onClick={onLogOut}
              >
                Log Out
              </Button>
            </>
          ) : (
            <>
              <Button
                color="primary"
                variant="outlined"
                className={
                  !mobile && desktop ? classes.buttonXLLeft : classes.buttonLeft
                }
                fullWidth
                onClick={onLogOut}
              >
                Log Out
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={
                  !mobile && desktop
                    ? classes.buttonXLRight
                    : classes.buttonRight
                }
                disabled={invalidUserError}
                fullWidth
              >
                Continue
              </Button>
            </>
          )}
        </Box>
      </Card>
    </Box>
  );
};

Invitations.propTypes = {
  classes: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  error: PropTypes.object,
  invitation: PropTypes.object,
  synchronousError: PropTypes.object,
  anyTouched: PropTypes.bool,
  onLogOut: PropTypes.func,
};

export default compose(
  connect(null, mapDispatch),
  withStyleSheet('Invitations', style),
)(Invitations);
