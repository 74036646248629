import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import { DropdownMenu, MenuItem, MenuTitle } from '../Shared/Menu';
import UserAnchor from './UserAnchor';

const style = () => ({
  root: {
    textAlign: 'center',
    width: '100%',
  },

  menuLink: {
    margin: [8, 0],
  },
});

class UserMenu extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    email: PropTypes.string,
    fullName: PropTypes.string.isRequired,
    isAdminUser: PropTypes.bool,
    hideTitle: PropTypes.bool,
  };

  static defaultProps = {
    isAdminUser: false,
    hideTitle: false,
  };

  render() {
    const { classes, fullName, email, isAdminUser, hideTitle, ...others } =
      this.props;

    const menuAnchorEl = <UserAnchor fullName={fullName} />;

    const titleElement = (
      <MenuItem>
        <MenuTitle primary>{fullName}</MenuTitle>
        <MenuTitle>{email}</MenuTitle>
      </MenuItem>
    );

    return (
      <DropdownMenu {...others} menuAnchorEl={menuAnchorEl}>
        {!hideTitle && titleElement}
        {!isAdminUser && (
          <MenuItem linkTo="/profile">
            <p className={classes.menuLink}>Profile Details</p>
          </MenuItem>
        )}
        {!isAdminUser && (
          <MenuItem url={'https://www.castandcrew.com/plustraining/'}>
            <p className={classes.menuLink}>Training+</p>
          </MenuItem>
        )}
        <MenuItem>
          <p className={classes.menuLink}>Logout</p>
        </MenuItem>
      </DropdownMenu>
    );
  }
}

export default withStyleSheet('UserMenu', style)(UserMenu);
