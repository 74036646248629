import {
  all,
  takeEvery,
  call,
  put,
  select,
  delay,
  takeLatest,
  spawn,
  cancel,
  fork,
} from 'redux-saga/effects';
import { cloneDeep } from 'lodash';
import camelCase from 'camelcase-keys';
// import snakeCase from 'snakecase-keys';
// import { startSubmit, stopSubmit, reset, initialize } from 'redux-form';
import moment from 'moment';

// selectors
import { getProject as project } from 'selectors/routeParams';
import {
  getInitBatchId,
  // getweekEnding
} from 'selectors/bulkEdit';
import { getFormValues } from 'selectors/formValues';
import {
  getAccountCodes,
  getBatches,
  getDepartments,
  getEmployeess,
  getEpisodes,
  getSets,
  getStatus,
  getWeekDay,
  getWeekEnding,
  getEditedDays,
  getEditableDays,
  getEdits,
  getTcBeingSaved,
  getInitLoad,
  getTcToBeCalculated,
  // getTcBeingCalculated,
  getSaving,
  getCalculating,
  // isDirty,
  getSaveResults,
  // getCalculateResults,
} from 'selectors/bulkEdit';
import {
  getCurrentProjectWorkSight,
  getCurrentProject,
} from 'selectors/project';
import { userInfoById } from 'selectors/session';
// utils
import { removeEmptyTimeFields, composeFilter } from 'utils/bulkEditUtils';
// import { timecards as DUMMY_DATA } from 'containers/Employees/Reviews/BulkEdit/dummyData';
// import { mockResponses } from 'containers/Employees/Reviews/BulkEdit/dummyData';
import { lowerCaseLastManIn } from 'utils/helperFunctions';
import TimeValidator from 'utils/TimeValidator';
import { ctaHasTimeFields, composeRoundedFields } from 'utils/bulkEditUtils';
//constants
import { BULK_EDIT_MAX_RESULTS } from 'components/Shared/constants';

// actions
import * as actions from 'actions/bulkEdit';
import { showAlert } from 'actions/alert';
import { signalRNotification } from 'actions/events';
import { fetchWeekendings } from 'actions/timecards';
const formName = 'bulkEdit';

function recentWeekending() {
  return moment().isoWeekday(-1).format('YYYY-MM-DD');
}

export function* initLoad(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    // const filterValues = yield select(getFormValues(formName));

    const recentWeekEnding = yield recentWeekending();
    const batchId = yield select(getInitBatchId);
    // const weekEnding = filterValues.weekEnding;
    const weekEnding = yield select(getWeekEnding);
    const initLoad = yield select(getInitLoad);
    yield put(fetchWeekendings());

    // const filterValues = yield select(getFormValues(formName));
    const accountCodes = yield select(getAccountCodes);
    const batches = yield select(getBatches);
    const departments = yield select(getDepartments);
    const employees = yield select(getEmployeess);
    const episodes = yield select(getEpisodes);
    const sets = yield select(getSets);
    const status = yield select(getStatus);
    const weekDay = yield select(getWeekDay);

    const accountCodesFilter = composeFilter(accountCodes, 'accountCode');
    const batchesFilter = composeFilter(batches, 'batch.id');
    const departmentFilter = composeFilter(departments, 'department');
    const employeesFilter = composeFilter(employees, 'employee');
    const episodesFilter = composeFilter(episodes, 'episode');
    const setsFilter = composeFilter(sets, 'set');
    const statusFilter = composeFilter(status, 'status');
    const weekDayFilter = composeFilter(weekDay, 'weekDay');

    //cnslog('accountCodes: ', accountCodes);
    //cnslog('batches: ', batches);
    //cnslog('departments: ', departments);
    //cnslog('employees: ', employees);
    //cnslog('episodes: ', episodes);
    //cnslog('sets: ', sets);
    //cnslog('status: ', status);
    //cnslog('weekDay: ', weekDay);
    //cnslog('-----------------------------');
    //cnslog('filterValues', filterValues);
    //cnslog('accountCodesFilter', accountCodesFilter);
    //cnslog('batchesFilter', batchesFilter);
    //cnslog('departmentFilter', departmentFilter);
    //cnslog('employeesFilter', employeesFilter);
    //cnslog('episodesFilter', episodesFilter);
    //cnslog('setsFilter', setsFilter);
    //cnslog('statusFilter', statusFilter);
    //cnslog('weekDayFilter', weekDayFilter);

    // if all filters are null then fetch everything
    if (
      // filterValues.weekEnding === null &&
      accountCodesFilter === null &&
      batchesFilter === null &&
      employeesFilter === null &&
      departmentFilter === null &&
      episodesFilter === null &&
      setsFilter === null
      // statusFilter === null &&
      // weekDayFilter === null
    ) {
      yield all([
        call(fetchEmployeeNames, api, debug),
        call(fetchDepartments, api, debug, { bulkEdit: true }),
        call(fetchAccountCodes, api, debug, { initialLoad: true }),
        call(fetchEpisodes, api, debug),
        call(fetchSets, api, debug),
        call(fetchBatches, api, debug),
        call(fetchStatuses, api, debug),
        call(fetchWeekdays, api, debug),
      ]);
    } else {
      //cnslog('we already have filters selected');
    }

    if (initLoad) {
      yield put(
        actions.onSelect({
          option: 'Ready for me',
          value: true,
          listName: 'status',
        }),
      );
      yield put(actions.resetFilterSelected());
      yield put(actions.storeWeekEnding({ weekEnding: recentWeekEnding }));
      yield put(actions.storeResultsWE({ weekEnding: recentWeekEnding }));

      const data = {
        filters: [
          {
            field: 'weekEndingDate',
            type: 'date',
            values: [recentWeekEnding],
          },
        ],
        page: 0,
        pageSize: 100, // hard coding for now for the release till we go dynamic
      };

      const WEcounts = yield call(api.bulkEdit.fetchCount, {
        projectId,
        data,
      });
      yield put(actions.storeWECount({ count: WEcounts }));
    } else {
      let filter = {
        filters: [],
        page: 0,
        pageSize: 100, // hard coding for now for the release till we go dynamic
      };
      yield put(actions.searching({ searching: true }));
      if (!!batchId) {
        filter.filters.push({
          field: 'batch.id',
          type: 'key',
          values: [batchId],
        });
        filter.filters.push({
          field: 'weekEndingDate',
          type: 'date',
          values: [weekEnding],
        });

        yield call(fetchBatches, api, debug);
        yield put(actions.resetFilterSelected());
        const data = yield call(api.bulkEdit.fetch, {
          projectId,
          data: filter,
        });
        if (data?.timecards) {
          data.timecards.forEach(timecard => {
            if (timecard?.details) {
              lowerCaseLastManIn(timecard.details);
            }
          });
          if (data?.timecards?.length > 0) {
            yield call(fetchRoundings, api, debug, {
              timecards: data.timecards,
              projectId,
            });
          }
        }
        yield call(fetchCount, api, debug, { weekEnding });
        const results = data.timecards;
        const count = data.count;
        yield put(actions.storeSearchCount({ count: count }));
        yield put(actions.store({ days: results, count }));
        yield put(actions.storeResultsWE({ weekEnding: weekEnding }));
      } else if (!!weekEnding) {
        filter.filters.push({
          field: 'weekEndingDate',
          type: 'date',
          values: [weekEnding],
        });
        if (accountCodesFilter) filter.filters.push(accountCodesFilter);
        if (batchesFilter) filter.filters.push(batchesFilter);
        if (departmentFilter) filter.filters.push(departmentFilter);
        if (employeesFilter) filter.filters.push(employeesFilter);
        if (episodesFilter) filter.filters.push(episodesFilter);
        if (setsFilter) filter.filters.push(setsFilter);
        if (statusFilter) filter.filters.push(statusFilter);
        if (weekDayFilter) filter.filters.push(weekDayFilter);

        const data = yield call(api.bulkEdit.fetch, {
          projectId,
          data: filter,
        });
        if (data?.timecards) {
          data.timecards.forEach(timecard => {
            if (timecard?.details) {
              lowerCaseLastManIn(timecard.details);
            }
          });
        }

        const selectedCount = yield call(api.bulkEdit.fetchCount, {
          projectId,
          data: filter,
        });
        yield put(actions.storeSearchCount({ count: selectedCount }));
        const results = data.timecards;
        const count = data.count;
        yield put(actions.store({ days: results, count }));
      }
      yield put(actions.searching({ searching: false }));
    }

    // after test in dev,
    // need to comment out dummy data and use real data

    // yield put(actions.store({ days: DUMMY_DATA, count: 12 }));

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.store({ days: [], count: 0 }));
    yield put(actions.loading({ loading: false }));
    yield put(actions.searching({ searching: false }));
    yield put(showAlert());
  }
}

export function* fetchRoundings(api, debug, params) {
  try {
    const { projectId, timecards } = params;
    const dealMemoSet = new Set();
    timecards.forEach(tc => {
      tc.details.forEach(day => {
        const dealMemoId = day?.dealMemo?.id;
        if (dealMemoId) dealMemoSet.add(dealMemoId);
      });
    });
    const dealMemoIds = Array.from(dealMemoSet);

    const res = yield call(api.employees.roundTo, { projectId, dealMemoIds });
    const roundingsMap = res.reduce((map, info) => {
      map[info.id] = info.roundTo;
      return map;
    }, {});
    timecards.forEach(tc => {
      tc.details.forEach(day => {
        const dealMemoId = day?.dealMemo?.id;
        const rounding = roundingsMap[dealMemoId];
        day.dealMemo.rounding = rounding;
      });
    });
  } catch (e) {
    debug(e);
  }
}

export function* fetch(api, debug, params) {
  try {
    yield put(actions.searching({ searching: true }));
    const projectId = yield select(project);
    const batchId = yield select(getInitBatchId);
    if (batchId && batchId.length > 0) {
      yield put(actions.clearInitBatchId({}));
    }
    const initFetch = yield select(getInitLoad);
    if (initFetch) {
      yield put(actions.setInitLoad());
    }

    // reset to deal with rendering parse lag
    yield put(actions.store({ days: [], count: 0 }));
    let filter = {
      filters: [],
      page: 0,
      pageSize: 100, // hard coding for now for the release till we go dynamic
    };
    // Lets get the values from the form store here
    // we'll add all the selected values and then send the payload
    const filterValues = yield select(getFormValues(formName));
    const accountCodes = yield select(getAccountCodes);
    const batches = yield select(getBatches);
    const departments = yield select(getDepartments);
    const employees = yield select(getEmployeess);
    const episodes = yield select(getEpisodes);
    const sets = yield select(getSets);
    const status = yield select(getStatus);
    const weekDay = yield select(getWeekDay);

    const accountCodesFilter = composeFilter(accountCodes, 'accountCode');
    const batchesFilter = composeFilter(batches, 'batch.id');
    const departmentFilter = composeFilter(departments, 'department');
    const employeesFilter = composeFilter(employees, 'employee');
    const episodesFilter = composeFilter(episodes, 'episode');
    const setsFilter = composeFilter(sets, 'set');
    const statusFilter = composeFilter(status, 'status');
    const weekDayFilter = composeFilter(weekDay, 'weekDay');

    if (accountCodesFilter) filter.filters.push(accountCodesFilter);
    if (batchesFilter) filter.filters.push(batchesFilter);
    if (departmentFilter) filter.filters.push(departmentFilter);
    if (employeesFilter) filter.filters.push(employeesFilter);
    if (episodesFilter) filter.filters.push(episodesFilter);
    if (setsFilter) filter.filters.push(setsFilter);
    if (statusFilter) filter.filters.push(statusFilter);
    if (weekDayFilter) filter.filters.push(weekDayFilter);

    const { weekEnding } = filterValues;
    const { week } = params;
    let weekDate;
    if (!!week) {
      weekDate = week;
    } else {
      weekDate = weekEnding;
    }
    // if (!!week || !!weekEnding) {
    filter.filters.push({
      field: 'weekEndingDate',
      type: 'date',
      values: [weekDate],
    });
    // }

    const data = yield call(api.bulkEdit.fetch, {
      projectId,
      data: filter,
    });

    const counts = yield call(api.bulkEdit.fetchCount, {
      projectId,
      data: filter,
    });

    const countData = {
      filters: [
        {
          field: 'weekEndingDate',
          type: 'date',
          values: [weekDate],
        },
      ],
      page: 0,
      pageSize: 100, // hard coding for now for the release till we go dynamic
    };

    const WEcounts = yield call(api.bulkEdit.fetchCount, {
      projectId,
      data: countData,
    });

    if (data?.timecards) {
      data.timecards.forEach(timecard => {
        if (timecard?.details) {
          lowerCaseLastManIn(timecard.details);
        }
      });
      if (data?.timecards?.length > 0) {
        yield call(fetchRoundings, api, debug, {
          timecards: data.timecards,
          projectId,
        });
      }
    }
    const results = data.timecards;
    const count = data.count;

    yield put(actions.store({ days: results, count }));

    yield put(actions.storeWECount({ count: WEcounts }));
    yield put(actions.storeSearchCount({ count: counts }));
    yield put(actions.storeResultsWE({ weekEnding: weekDate }));
    // yield put(actions.store({ days: DUMMY_DATA, count: 12 }));

    yield put(actions.searching({ searching: false }));
  } catch (e) {
    debug(e);
    yield put(actions.store({ days: [], count: 0 }));
    yield put(actions.searching({ searching: false }));
    yield put(showAlert());
  }
}

export function* fetchWeekEndings(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const data = yield call(api.timecards.weekendings, { projectId });
    const weekEndings = camelCase(data, { deep: true });

    yield put(actions.storeWeekEndings({ weekEndings }));

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeWeekEndings({ weekendings: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}
export function* fetchStatuses(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const weekEnding = yield select(getWeekEnding);
    const filter = {
      filters: [],
      search: '',
      type: 'status',
      page: 0,
      pageSize: BULK_EDIT_MAX_RESULTS,
      weekending: '',
    };

    if (weekEnding) {
      filter.filters.push({
        field: 'weekEndingDate',
        type: 'date',
        values: [weekEnding],
      });
    } else {
      const recentWeekEnding = yield recentWeekending();
      filter.filters.push({
        field: 'weekEndingDate',
        type: 'date',
        values: [recentWeekEnding],
      });
    }

    const data = yield call(api.bulkEdit.fetchFilters, {
      projectId,
      data: filter,
    });
    const statuses = camelCase(data, { deep: true });

    yield put(actions.storeStatuses({ statuses }));

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeDepartments({ departments: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}
export function* fetchWeekdays(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const weekEnding = yield select(getWeekEnding);
    const filter = {
      filters: [],
      search: '',
      type: 'details.dayOfWeek',
      page: 0,
      pageSize: BULK_EDIT_MAX_RESULTS,
      // weekending: '',
    };

    if (weekEnding) {
      filter.filters.push({
        field: 'weekEndingDate',
        type: 'date',
        values: [weekEnding],
      });
    } else {
      const recentWeekEnding = yield recentWeekending();
      filter.filters.push({
        field: 'weekEndingDate',
        type: 'date',
        values: [recentWeekEnding],
      });
    }

    const data = yield call(api.bulkEdit.fetchFilters, {
      projectId,
      data: filter,
    });
    const weekdays = camelCase(data, { deep: true });
    yield put(actions.storeWeekdays({ weekdays }));

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeDepartments({ departments: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

export function* fetchDepartments(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const weekEnding = yield select(getWeekEnding);

    const filter = {
      filters: [],
      search: '',
      type: 'department.code',
      page: 0,
      pageSize: BULK_EDIT_MAX_RESULTS,
    };

    if (weekEnding) {
      filter.weekending = weekEnding;
    } else {
      const recentWeekEnding = yield recentWeekending();
      filter.weekending = recentWeekEnding;
    }
    const data = yield call(api.bulkEdit.fetchFilters, {
      projectId,
      data: filter,
    });
    const departments = camelCase(data, { deep: true });

    yield put(actions.storeDepartments({ departments }));

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeDepartments({ departments: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

export function* fetchEmployeeNames(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const weekEnding = yield select(getWeekEnding);

    const filter = {
      filters: [],
      search: '',
      type: 'employee',
      page: 0,
      pageSize: BULK_EDIT_MAX_RESULTS,
    };

    if (weekEnding) {
      filter.weekending = weekEnding;
    } else {
      const recentWeekEnding = yield recentWeekending();
      filter.weekending = recentWeekEnding;
    }
    const data = yield call(api.bulkEdit.fetchFilters, {
      projectId,
      data: filter,
    });
    const users = camelCase(data, { deep: true });

    yield put(actions.storeEmployees({ users }));

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeEmployees({ users: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

export function* fetchBatches(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const weekEnding = yield select(getWeekEnding);

    const filter = {
      filters: [],
      search: '',
      type: 'batch',
      page: 0,
      pageSize: BULK_EDIT_MAX_RESULTS,
    };

    if (weekEnding) {
      filter.filters.push({
        field: 'weekEndingDate',
        type: 'date',
        values: [weekEnding],
      });
    } else {
      const recentWeekEnding = yield recentWeekending();
      filter.filters.push({
        field: 'weekEndingDate',
        type: 'date',
        values: [recentWeekEnding],
      });
    }

    const data = yield call(api.bulkEdit.fetchFilters, {
      projectId,
      data: filter,
    });

    const batches = camelCase(data, { deep: true });

    yield put(actions.storeBatches({ batches }));
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeBatches({ batches: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

export function* fetchAccountCodes(api, debug, params) {
  const { initialLoad } = params;
  try {
    if (initialLoad) yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const weekEnding = yield select(getWeekEnding);

    const filter = {
      filters: [],
      search: '',
      type: 'details.accountCode',
      page: 0,
      pageSize: BULK_EDIT_MAX_RESULTS,
    };

    if (weekEnding) {
      filter.weekending = weekEnding;
    } else {
      const recentWeekEnding = yield recentWeekending();
      filter.weekending = recentWeekEnding;
    }

    let data = yield call(api.bulkEdit.fetchFilters, {
      projectId,
      data: filter,
    });
    data =
      data &&
      data.reduce((list, option) => {
        if (option.id) list.push(option);
        return list;
      }, []);

    if (initialLoad === false) {
      const cachedACList = yield select(getAccountCodes);
      const accountCodesFilter = composeFilter(cachedACList, 'accountCode');
      if (accountCodesFilter) {
        const selectedFilter = cachedACList.filter(
          accountCode => accountCode.selected,
        );
        selectedFilter.forEach(option => {
          const match = data.find(newAC => newAC.id === option.value);
          if (match) match.selected = true;
        });
      }
    }
    const accountCodes = camelCase(data, { deep: true });

    yield put(actions.storeAccountCodes({ accountCodes }));

    if (initialLoad) yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeAccountCodes({ accountCodes: [] }));
    if (initialLoad) yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

export function* fetchEpisodes(api, debug, params) {
  const initialLoad = params && params.initialLoad;
  try {
    if (initialLoad) yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const weekEnding = yield select(getWeekEnding);
    const filter = {
      filters: [],
      search: '',
      type: 'details.episode.code',
      page: 0,
      pageSize: BULK_EDIT_MAX_RESULTS,
    };

    if (weekEnding) {
      filter.weekending = weekEnding;
    } else {
      const recentWeekEnding = yield recentWeekending();
      filter.weekending = recentWeekEnding;
    }
    const data = yield call(api.bulkEdit.fetchFilters, {
      projectId,
      data: filter,
    });
    if (initialLoad === false) {
      const cachedList = yield select(getEpisodes);
      const episodesFilter = composeFilter(cachedList, 'episode');
      if (episodesFilter) {
        const selectedFilter = cachedList.filter(
          accountCode => accountCode.selected,
        );
        selectedFilter.forEach(option => {
          const match = data.find(newAC => newAC.id === option.value);
          if (match) match.selected = true;
        });
      }
    }
    const episodes = camelCase(data, { deep: true });

    yield put(actions.storeEpisodes({ episodes }));

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeEpisodes({ episodes: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}
export function* fetchSets(api, debug, params) {
  const initialLoad = params && params.initialLoad;
  try {
    if (initialLoad) yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const weekEnding = yield select(getWeekEnding);

    const filter = {
      filters: [],
      search: '',
      type: 'details.set',
      page: 0,
      pageSize: BULK_EDIT_MAX_RESULTS,
    };

    if (weekEnding) {
      filter.weekending = weekEnding;
    } else {
      const recentWeekEnding = yield recentWeekending();
      filter.weekending = recentWeekEnding;
    }

    const data = yield call(api.bulkEdit.fetchFilters, {
      projectId,
      data: filter,
    });
    if (initialLoad === false) {
      const cachedList = yield select(getSets);
      const setsFilter = composeFilter(cachedList, 'set');
      if (setsFilter) {
        const selectedFilter = cachedList.filter(
          accountCode => accountCode.selected,
        );
        selectedFilter.forEach(option => {
          const match = data.find(newAC => newAC.id === option.value);
          if (match) match.selected = true;
        });
      }
    }
    const sets = camelCase(data, { deep: true });

    yield put(actions.storeSets({ sets }));

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeSets({ sets: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

export function* fetchTableDropdown(api, debug, params) {
  try {
    yield put(
      actions.storeDropdown({ dropdownType: '', params: {}, data: [] }),
    );
    yield put(actions.loadingDropdown({ loading: true }));
    const { dropdownType, params: options } = params;
    const parentValue = options.parentValue;

    const apiParams = {
      type: dropdownType,
      params: parentValue
        ? { parentValue }
        : { options, pageSize: dropdownType === 'locationType' ? 20 : -1 },
    };

    const data = yield call(api.wtc.searchByType, apiParams);
    yield put(actions.storeDropdown({ dropdownType, params: options, data }));
    yield put(actions.loadingDropdown({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(
      actions.storeDropdown({ dropdownType: '', params: {}, data: [] }),
    );
    yield put(actions.loadingDropdown({ loading: false }));
  }
}

export function* preFetchTableDropdown(api, debug, params) {
  try {
    const { dropdownType } = params;
    let apiParams = {};
    if (dropdownType === 'schedule') {
      apiParams = { type: 'workSchedule', params: {} };
    }
    if (dropdownType === 'episode') {
      const projectId = yield select(getCurrentProjectWorkSight);
      apiParams = { type: dropdownType, params: { parentValue: projectId } };
    }
    const data = yield call(api.wtc.searchByType, apiParams);
    yield put(actions.storeDropdown({ dropdownType, data }));
  } catch (e) {
    debug(e);
  }
}

let theSave;
export function* onSave(api, debug, params) {
  theSave = yield fork(save, api, debug, params);
}

export function* save(api, debug, params) {
  try {
    yield put(actions.saving({ saving: true }));
    const apiCall = api.notifications.save;
    yield put(actions.clearSaveResults());
    const project = yield select(getCurrentProject);
    let projectId = project.id;
    const user = yield select(userInfoById(projectId));
    const editedTCs = yield select(getEditedDays);
    const editabledTCs = yield select(getEditableDays);
    const edits = yield select(getEdits);
    const existingTcBeingSaved = yield select(getTcBeingSaved);
    const existingTcToBeCalculated = yield select(getTcToBeCalculated);

    const tcwedits = [];

    let copyAll = editedTCs.includes('all');
    let hasTimeFields = ctaHasTimeFields(copyAll, edits);
    let tc2edit = copyAll
      ? editabledTCs
      : editabledTCs.filter(tc => editedTCs.includes(tc.timecardEntryHeaderId));

    tc2edit.forEach(tc => {
      let editTC = cloneDeep(tc);
      let TCedits = edits[tc.timecardEntryHeaderId] || {};
      editTC.details.forEach((day, index) => {
        let roundedCTAFields = {};
        if (copyAll && hasTimeFields) {
          const rounding = day.dealMemo.rounding;
          const tv = new TimeValidator(rounding);
          roundedCTAFields = composeRoundedFields(edits, tv);
        }
        if (copyAll && index in TCedits) {
          editTC.details[index] = {
            ...day,
            ...edits.all,
            ...roundedCTAFields,
            ...TCedits[index],
          };
        } else if (!copyAll && index in TCedits) {
          editTC.details[index] = { ...day, ...TCedits[index] };
        } else if (copyAll) {
          editTC.details[index] = { ...day, ...edits.all, ...roundedCTAFields };
        }
        removeEmptyTimeFields(editTC.details[index]);
      });
      editTC.updatedBy = {
        name: user.fullName,
        email: user.email,
        oktaId: user.oktaId,
      };

      tcwedits.push(editTC);
    });
    let newTcBeingSaved = tcwedits.map(tc => tc.timecardEntryHeaderId);

    // storeTcBeingSaved for save process
    yield put(
      actions.storeTcBeingSaved({
        tcBeingSaved: [
          ...new Set([...existingTcBeingSaved, ...newTcBeingSaved]),
        ],
      }),
    );

    // storeTC to be calculated for cl process
    yield put(
      actions.storeTcToBeCalculated({
        tcToBeCalculated: [
          ...new Set([
            ...existingTcBeingSaved,
            ...existingTcToBeCalculated,
            ...newTcBeingSaved,
          ]),
        ],
      }),
    );
    let bulktcEdits = tcwedits;

    if (project.region === 'Canada') {
      //check locationtype "studio" is available in all tcs for canada region
      const isLocTypeAvailInAllTcs = tcwedits.every(timecard =>
        timecard?.details.every(day => day?.locationType?.name === 'Studio'),
      );

      if (!isLocTypeAvailInAllTcs) {
        const tcObj = tcwedits[0]?.details.find(
          t => t.dealMemo.htgContract.id && t.dealMemo?.htgUnion.id,
        );
        const dealMemo = tcObj.dealMemo;
        const htgContractId = dealMemo?.htgContract.id;
        const htgUnionId = dealMemo?.htgUnion.id;
        let options = {};
        if (htgContractId && htgUnionId) {
          options = { htgContractId, htgUnionId };
        }
        const locTypeArr = yield call(api.wtc.searchByType, {
          type: 'locationType',
          params: {
            page: 1,
            pageSize: -1,
            options,
          },
        });
        const locTypeObj =
          locTypeArr?.find(
            loctype => loctype?.name === 'Studio' && loctype?.code === 'S',
          ) || null;

        bulktcEdits = tcwedits.map(tc => {
          const details = tc.details.map(day => {
            return {
              ...day,
              locationType: locTypeObj, //Setting to studio for canada region
            };
          });
          const updatedTC = {
            ...tc,
            details,
          };
          return updatedTC;
        });
      }
    }

    yield all(
      bulktcEdits.map(tc => spawn(() => spawnSaveTC(apiCall, debug, tc))),
    );
    yield delay(120000);
    yield call(cancelSave);
  } catch (e) {
    debug(e);
    yield put(actions.saving({ saving: false }));
  }
}

function* spawnSaveTC(apiCall, debug, tc) {
  yield delay(1); //Delay slightly so timecards go in separate WS messages
  try {
    const project = yield select(getCurrentProject);
    const user = yield select(userInfoById(project.id));
    yield call(apiCall, tc.timecardEntryHeaderId, tc, {
      name: user.fullName,
      email: user.email,
      id: user.oktaId,
    });
  } catch (e) {
    debug(e);
  }
}
export function* cancelSave() {
  const saving = yield select(getSaving);
  if (saving) {
    yield put(actions.saving({ saving: false }));
    yield put(
      showAlert({
        message: 'The action you took did not complete. Try again.',
        variant: 'error',
      }),
    );
  }
}

export function* calculate(api, debug) {
  try {
    const apiCall = api.notifications.calculate;
    yield put(actions.calculating({ calculating: true }));
    yield put(actions.clearCalculateResults());

    const existingTcToBeCalculated = yield select(getTcToBeCalculated);

    //storeTcBeingCalculated
    yield put(
      actions.storeTcBeingCalculated({
        tcBeingCalculated: existingTcToBeCalculated,
      }),
    );
    yield all(
      existingTcToBeCalculated.map(tcId =>
        spawn(() => spawnCalculateTC(apiCall, debug, tcId)),
      ),
    );
    yield put(actions.storeTcToBeCalculated({ tcToBeCalculated: [] }));
    yield delay(2000);
    yield put(actions.calculating({ calculating: false }));
  } catch (e) {
    debug(e);
    yield put(actions.calculating({ calculating: false }));
  }
}

export function* cancelCalculate() {
  const calculating = yield select(getCalculating);
  if (calculating) {
    yield put(actions.calculating({ calculating: false }));
    yield put(
      showAlert({
        message: 'The calculation did not complete. Refresh and try again.',
        variant: 'error',
      }),
    );
  }
}

function* spawnCalculateTC(apiCall, debug, tcId) {
  try {
    const project = yield select(getCurrentProject);
    const user = yield select(userInfoById(project.id));
    yield call(apiCall, tcId, {
      name: user.fullName,
      email: user.email,
      id: user.oktaId,
    });
  } catch (e) {
    debug(e);
  }
}

export function* refetchFilters(api, debug) {
  try {
    yield all([
      call(fetchAccountCodes, api, debug, { initialLoad: false }),
      call(fetchEpisodes, api, debug, { initialLoad: false }),
      call(fetchSets, api, debug, { initialLoad: false }),
    ]);

    // if other filters needs refetch, put here
  } catch (e) {
    debug(e);
  }
}

export function* tcUpdates(api, debug, params) {
  try {
    const { timecards = [], requestType = '' } = params;

    const saving = yield select(getSaving);

    //TODO - should check in signalR Saga for bulkEdit vs DTS updates
    if (!saving) {
      return;
    }

    const sResults = yield select(getSaveResults);
    let savingResults = cloneDeep(sResults);
    if (requestType === 'Save') {
      const tcBeingSavedRef = yield select(getTcBeingSaved);
      const tcBeingSaved = [...tcBeingSavedRef];
      timecards.forEach(tc => {
        if (tc.newState === 'SavePending') {
          const index = tcBeingSaved.indexOf(tc.headerId);
          if (index >= 0) {
            tcBeingSaved.splice(index, 1);
            savingResults.error.push(tc.headerId);
            ++savingResults.count;
          }
        }
        if (tc.newState === 'SaveCompleted') {
          const index = tcBeingSaved.indexOf(tc.headerId);
          if (index >= 0) {
            tcBeingSaved.splice(index, 1);
            savingResults.successful.push(tc.headerId);
            ++savingResults.count;
          }
        }
      });
      if (tcBeingSaved.length === 0 && saving) {
        yield put(actions.saving({ saving: false }));
        yield put(actions.clearEditedDays());
        yield put(actions.clearEdits());
        yield put(actions.resetDirtyFields());
        yield cancel(theSave);

        // recall fetch with all filters after delay
        if (requestType === 'Save') {
          yield put(actions.setLastSaveAfterFetch({ clickSave: true }));
          yield delay(2100);
          yield spawn(refetchFilters, api, debug);
        }
      }

      yield put(actions.storeTcBeingSaved({ tcBeingSaved }));
    }
    if (requestType === 'Calculate') {
    }
  } catch (e) {
    debug(e);
  }
}

export function* fetchCount(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);

    let filter = {
      filters: [],
      page: 0,
      pageSize: 100, // hard coding for now for the release till we go dynamic
    };

    const { weekEnding } = params;

    if (!!weekEnding) {
      filter.filters.push({
        field: 'weekEndingDate',
        type: 'date',
        values: [weekEnding],
      });
    }

    const count = yield call(api.bulkEdit.fetchCount, {
      projectId,
      data: filter,
    });
    yield put(actions.storeWECount({ count: count }));
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

export function* fetchSelectedCount(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);

    let filter = {
      filters: [],
      page: 0,
      pageSize: 100, // hard coding for now for the release till we go dynamic
    };
    // Lets get the values from the form store here
    // we'll add all the selected values and then send the payload
    const filterValues = yield select(getFormValues(formName));
    const accountCodes = yield select(getAccountCodes);
    const batches = yield select(getBatches);
    const departments = yield select(getDepartments);
    const employees = yield select(getEmployeess);
    const episodes = yield select(getEpisodes);
    const sets = yield select(getSets);
    const status = yield select(getStatus);
    const weekDay = yield select(getWeekDay);

    const accountCodesFilter = composeFilter(accountCodes, 'accountCode');
    const batchesFilter = composeFilter(batches, 'batch.id');
    const departmentFilter = composeFilter(departments, 'department');
    const employeesFilter = composeFilter(employees, 'employee');
    const episodesFilter = composeFilter(episodes, 'episode');
    const setsFilter = composeFilter(sets, 'set');
    const statusFilter = composeFilter(status, 'status');
    const weekDayFilter = composeFilter(weekDay, 'weekDay');

    if (accountCodesFilter) filter.filters.push(accountCodesFilter);
    if (batchesFilter) filter.filters.push(batchesFilter);
    if (departmentFilter) filter.filters.push(departmentFilter);
    if (employeesFilter) filter.filters.push(employeesFilter);
    if (episodesFilter) filter.filters.push(episodesFilter);
    if (setsFilter) filter.filters.push(setsFilter);
    if (statusFilter) filter.filters.push(statusFilter);
    if (weekDayFilter) filter.filters.push(weekDayFilter);

    const { weekEnding } = filterValues;
    const { week } = params;
    let weekDate;
    if (!!week) {
      weekDate = week;
    } else {
      weekDate = weekEnding;
    }

    filter.filters.push({
      field: 'weekEndingDate',
      type: 'date',
      values: [weekDate],
    });

    const count = yield call(api.bulkEdit.fetchCount, {
      projectId,
      data: filter,
    });

    yield put(actions.storeSearchCount({ count: count }));

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}
function* onSelectFilter(params) {
  try {
    yield put(actions.onSelect(params));
    yield put(actions.filterSelected());
    yield call(fetchSelectedCount);
  } catch (e) {
    // debug(e);
  }
}

export default function* bulkEditFlow({ api, debug }) {
  yield all([
    takeEvery(`${actions.fetch}`, fetch, api, debug),
    takeEvery(`${actions.fetchWeekEndings}`, fetchWeekEndings, api, debug),
    // takeEvery(`${actions.onSave}`, onSave, api, debug),
    takeEvery(`${actions.fetchEmployeeNames}`, fetchEmployeeNames, api, debug),
    takeEvery(`${actions.fetchAccountCodes}`, fetchAccountCodes, api, debug),
    takeEvery(`${actions.fetchBatches}`, fetchBatches, api, debug),
    takeEvery(`${actions.fetchWeekdays}`, fetchWeekdays, api, debug),
    takeEvery(`${actions.fetchStatuses}`, fetchStatuses, api, debug),
    takeEvery(`${actions.fetchEpisodes}`, fetchEpisodes, api, debug),
    takeEvery(`${actions.fetchSets}`, fetchSets, api, debug),
    takeEvery(`${actions.initLoad}`, initLoad, api, debug),
    takeEvery(`${actions.fetchDropdown}`, fetchTableDropdown, api, debug),
    takeEvery(`${actions.preFetchDropdown}`, preFetchTableDropdown, api, debug),
    takeEvery(`${actions.fetchDepartments}`, fetchDepartments, api, debug),
    takeEvery(`${actions.save}`, onSave, api, debug),
    takeLatest(`${actions.calculate}`, calculate, api, debug),
    //this is used by Bulk Edit AND DTS currently
    takeEvery(`${signalRNotification}`, tcUpdates, api, debug),
    takeLatest(`${actions.fetchWECount}`, fetchCount, api, debug),
    takeLatest(`${actions.fetchSelectedCount}`, fetchSelectedCount, api, debug),
    takeEvery(`${actions.onSelectFilter}`, onSelectFilter),
    // takeEvery(`${actions.onSelectAllFilter}`, onSelectAllFilter),
  ]);
}
