import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from 'actions/digitalEdits';
import { getDataCardsView } from 'selectors/digitalEdits';
import MultiSelectDropdown from 'components/Shared/MultiSelectDropDown';

const mapState = state => ({
  dataCardsControlList: getDataCardsView(state),
});
const mapDispatch = dispatch => ({
  onSelect: ({ index, isDisplayEmptyColumns }) => {
    dispatch(actions.setDataCardsView({ index }));
    if (isDisplayEmptyColumns) {
      dispatch(actions.toggleHideEmptyCols());
    }
  },
  onSelectAllAdditionalFields: checked => {
    dispatch(actions.selectAllAdditionalFields({ checked }));
    dispatch(actions.toggleHideEmptyCols());
  },
});

//TODO rename this component to AdditionalFieldsContainer in 6.1.0+
function DataCardsControl(props) {
  const {
    dataCardsControlList,
    onSelect,
    useIntersectionObserver,
    onSelectAllAdditionalFields,
  } = props;
  return (
    <MultiSelectDropdown
      options={dataCardsControlList}
      onSelect={onSelect}
      onSelectAllAdditionalFields={onSelectAllAdditionalFields}
      useIntersectionObserver={useIntersectionObserver}
      canSelectAll={true}
    />
  );
}

DataCardsControl.propTypes = {
  dataCardsControlList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
      index: PropTypes.number.isRequired,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  useIntersectionObserver: PropTypes.bool,
};

export default connect(mapState, mapDispatch)(DataCardsControl);
