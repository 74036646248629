import { buildUrl } from '../api';

//timecard URLs
const URL_TIMECARDS = '/projects/:projectId/timecards';
const URL_TIMECARDS_LIST = '/projects/:projectId/timecards/list';
const URL_TIMECARDS_SEARCH = '/projects/:projectId/timecards/search';
const URL_HISTORY = '/projects/:projectId/timecards/:timecardId/history';
const URL_TIMECARD = '/projects/:projectId/timecards/:timecardId';
const URL_TIMECARD_ALLOWANCES =
  '/projects/:projectId/timecards/:timecardId/allowances';

const URL_APPROVAL_FLOW =
  '/projects/:projectId/timecards/:timecardId/approval-flow';

//weekending URLs
const URL_WEEKENDINGS = '/projects/:projectId/week-endings';
const URL_WEEKENDING_TIMECARD =
  '/projects/:projectId/week-endings/:weekEndingdate/departments/:departmentId/timecards';
const URL_WEEKENDINGS_STATS =
  '/projects/:projectId/week-endings/stats/department';

const URL_TEAM_TIMECARDS_SUBMIT =
  '/projects/:projectId/week-endings/:weekEnding/team-timecards/submit';

const URL_EPISODES = '/projects/:projectId/episodes';
const URL_ALLOWANCES_TIMECARD = '/projects/:projectId/timecard_allowances';
const URL_SEARCH_BY_TYPE = '/names/:type';

const URL_HTG_DAY_TYPE = '/lookupbyid/htgPayCode/:htgDayTypeId';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async list(projectId, payload) {
    const URL = URL_TIMECARDS_LIST;
    const url = buildUrl(URL, { projectId });
    const rsp = await clientV2.post(url, payload);
    return rsp.data;
  },
  //TODO - suspect this is unused
  async search({ projectId, data }) {
    const URL = URL_TIMECARDS_SEARCH;
    const url = buildUrl(URL, { projectId });
    const rsp = await clientV2.post(url, data);
    return rsp.data;
  },

  async weekendings({ projectId }) {
    const url = buildUrl(URL_WEEKENDINGS, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async weekendingsStats(projectId, payload) {
    const url = buildUrl(URL_WEEKENDINGS_STATS, {
      projectId,
    });
    const rsp = await clientV2.post(url, payload);
    return rsp.data;
  },

  async weekendingTimecard({ projectId, weekEndingdate, departmentId }) {
    const url = buildUrl(URL_WEEKENDING_TIMECARD, {
      projectId,
      weekEndingdate,
      departmentId,
    });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async details({ projectId, timecardId }) {
    const URL = URL_TIMECARD;
    const url = buildUrl(URL, { projectId, timecardId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async searchByTypes({ type, params }) {
    const searchURL = buildUrl(URL_SEARCH_BY_TYPE, {
      type,
    });
    const { parentValue, search, options, page = 1, pageSize = 20 } = params;

    const payload = {
      page,
      pageSize,
    };
    if (parentValue) payload.parentValue = parentValue;
    if (search) payload.search = search;
    if (options) payload.options = JSON.stringify(options);
    const rsp = await clientV2.post(searchURL, payload);
    return rsp.data;
  },

  async getEpisodes({ projectId }) {
    const url = buildUrl(URL_EPISODES, { projectId });
    const resp = await clientV2.get(url);
    return resp.data;
  },

  async history({ projectId, timecardId }) {
    const URL = URL_HISTORY;
    const url = buildUrl(URL, { projectId, timecardId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async deleteTimecard({ projectId, timecardId, comment }) {
    let url = buildUrl(URL_TIMECARD, { projectId, timecardId });

    if (comment) {
      const encodedComment = encodeURIComponent(comment);
      url += `/comment/${encodedComment}`;
    }

    const rsp = await clientV2.delete(url);

    return rsp.data;
  },

  async saveTimecard({ projectId, timecardId, timecard }) {
    const url = buildUrl(URL_TIMECARD, { projectId, timecardId });
    const rsp = await clientV2.put(url, timecard);

    return rsp.data;
  },

  async approvalFlow({ projectId, timecardId, data }) {
    const url = buildUrl(URL_APPROVAL_FLOW, { projectId, timecardId });
    const rsp = await clientV2.post(url, data);
    return rsp.data;
  },

  async loadTCAllowances({ projectId, timecardId }) {
    const URL = URL_TIMECARD_ALLOWANCES;
    const url = buildUrl(URL, { projectId, timecardId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
  async loadTimecardAllowances({ projectId }) {
    const url = buildUrl(URL_ALLOWANCES_TIMECARD, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async loadEmployeeAllowancePayCodes({ type, params }) {
    const searchURL = buildUrl(URL_SEARCH_BY_TYPE, {
      type,
    });
    const { options, page = 1, pageSize = 100 } = params;
    const payload = {
      page,
      pageSize,
    };
    if (options) payload.options = JSON.stringify(options);
    const rsp = await clientV2.post(searchURL, payload);
    return rsp.data;
  },

  async submitToEmployees({ projectId, weekEnding, data }) {
    const URL = URL_TEAM_TIMECARDS_SUBMIT;
    const url = buildUrl(URL, { projectId, weekEnding });
    const rsp = await clientV2.post(url, data);
    return rsp.data;
  },

  async submitDraftToEmployees({ projectId, data }) {
    const URL = URL_TIMECARDS + '/submit';
    const url = buildUrl(URL, { projectId });
    const rsp = await clientV2.put(url, data);
    return rsp.data;
  },
  async getDayTypeFromHTGId({ htgDayTypeId }) {
    const url = buildUrl(URL_HTG_DAY_TYPE, { htgDayTypeId });
    const rsp = await clientV2.get(url);
    const data = rsp.data;
    const dayType = {
      name: data.name,
      code: data.code,
      id: htgDayTypeId,
      type: 'day_type',
      splittable: data.isSplitable === 1 ? true : false,
      requiresTimes: true,
    };
    return dayType;
  },
});
