import React from 'react';
import { connect } from 'react-redux';
import { compose, canadianLabel } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  styled,
} from '@mui/material';

import * as sel from '../selectors';

import Day from './Day';
import { EMP_ADDITIONAL_FIELD_DESC_MAP } from '../empTimecardUtils';

const HeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.gray['+9'],
  color: theme.palette.text.secondary,
  paddingTop: '8px',
  paddingBottom: '8px',
  fontSize: '12px',
  whiteSpace: 'nowrap',
}));

const mapState = (state, ownProps) => ({
  columns: sel.getVisibleColumns(state, sel.FORM_NAME),
});

const mapDispatch = {};

const TimecardDays = props => {
  const { fields, columns } = props;

  const renderDay = React.useCallback((member, index, fields) => {
    return <Day key={member} member={member} index={index} fields={fields} />;
  }, []);

  const paddingWidth = 16 * 2 * columns.length;
  const tableWidth = columns.reduce(
    (acc, column) => acc + column.width,
    paddingWidth,
  );
  // return a MUI table
  return (
    <TableContainer component={Box}>
      <Table
        sx={{ width: tableWidth, tableLayout: 'fixed' }}
        aria-label="timecard-days"
      >
        <TableHead>
          <TableRow>
            {columns.map(column => {
              const isActions = column.accessor === 'actions';
              let displayLabel =
                column.label === 'Occ Code' ? 'Occupation' : column.label;
              const isLabelIncluded =
                Object.keys(EMP_ADDITIONAL_FIELD_DESC_MAP)?.includes(
                  displayLabel,
                ) || false;

              return (
                <HeaderCell
                  key={displayLabel}
                  sx={{
                    width: column.width,
                    position: isActions ? 'sticky' : undefined,
                    right: isActions ? 0 : undefined,
                  }}
                >
                  {isLabelIncluded ? (
                    <Tooltip
                      title={EMP_ADDITIONAL_FIELD_DESC_MAP[displayLabel]}
                    >
                      <span style={{ cursor: 'pointer' }}>
                        {canadianLabel(displayLabel)}
                      </span>
                    </Tooltip>
                  ) : (
                    canadianLabel(displayLabel)
                  )}
                </HeaderCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>{fields.map(renderDay)}</TableBody>
      </Table>
    </TableContainer>
  );
};

TimecardDays.propTypes = {
  fields: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
};

export default compose(connect(mapState, mapDispatch))(TimecardDays);
