import * as actionType from 'actions/actionTypes';
import createAction from '../createAction';

export const addApprovalFlow = createAction('add/approvalFlow');

export const REVIEW_FLOWS = 'REVIEW_FLOWS';
export const WF_DEPARTMENTS = 'WF_DEPARTMENTS';
export const WF_STEPS = 'WF_STEPS';
export const WF_STEP_USERS = 'WF_STEP_USERS';
export const TOGGLE_DEPT_HEAD_APPROVAL = 'TOGGLE_DEPT_HEAD_APPROVAL';
export const REVIEWER = 'REVIEWER';
export const REVIEWERS = 'REVIEWERS';
export const APPROVAL_FLOW = 'APPROVAL_FLOW';
export const DEPARTMENT = 'DEPARTMENT';
export const DEPARTMENT_STATS = 'DEPARTMENT_STATS';
export const DEPARTMENT_HEAD = 'DEPARTMENT_HEAD';
export const TOGGLE_HEAD_APPROVAL = 'TOGGLE_HEAD_APPROVAL'; // Edit department modal
export const TOGGLE_DEPT_HEAD_VIEW_GROSS = 'TOGGLE_DEPT_HEAD_VIEW_GROSS';

export const fetchReviewFlows = () => ({
  type: actionType.FETCH_REQUEST(REVIEW_FLOWS),
});

export const fetchReviewFlowsSuccess = reviewFlows => ({
  type: actionType.FETCH_SUCCESS(REVIEW_FLOWS),
  reviewFlows,
});

export const fetchReviewFlowsFailure = () => ({
  type: actionType.FETCH_FAILURE(REVIEW_FLOWS),
});

export const fetchWorkflowDepartments = reviewFlowId => ({
  type: actionType.FETCH_REQUEST(WF_DEPARTMENTS),
  reviewFlowId,
});

export const fetchWorkflowDepartmentsSuccess = (reviewFlowId, departments) => ({
  type: actionType.FETCH_SUCCESS(WF_DEPARTMENTS),
  reviewFlowId,
  departments,
});

export const fetchWorkflowDepartmentsFailure = () => ({
  type: actionType.FETCH_FAILURE(WF_DEPARTMENTS),
});

export const fetchWorkflowSteps = reviewFlowId => ({
  type: actionType.FETCH_REQUEST(WF_STEPS),
  reviewFlowId,
});

export const fetchWorkflowStepsSuccess = (reviewFlowId, steps) => ({
  type: actionType.FETCH_SUCCESS(WF_STEPS),
  reviewFlowId,
  steps,
});

export const fetchWorkflowStepsFailure = () => ({
  type: actionType.FETCH_FAILURE(WF_STEPS),
});

export const fetchWorkflowStepUsers = (reviewFlowId, stepId) => ({
  type: actionType.FETCH_REQUEST(WF_STEP_USERS),
  reviewFlowId,
  stepId,
});

export const fetchWorkflowStepUsersSuccess = (reviewFlowId, stepId, users) => ({
  type: actionType.FETCH_SUCCESS(WF_STEP_USERS),
  reviewFlowId,
  stepId,
  users,
});

export const fetchWorkflowStepUsersFailure = () => ({
  type: actionType.FETCH_FAILURE(WF_STEP_USERS),
});

// Actions to Toggle department head approval workflow
export const toggleDepartmentHeadApproval = enableToggle => ({
  type: actionType.REQUEST(TOGGLE_DEPT_HEAD_APPROVAL),
  enableToggle,
});

export const toggleDepartmentHeadApprovalSuccess = () => ({
  type: actionType.SUCCESS(TOGGLE_DEPT_HEAD_APPROVAL),
});

export const toggleDepartmentHeadApprovalFailure = () => ({
  type: actionType.FAILURE(TOGGLE_DEPT_HEAD_APPROVAL),
});

// Actions to Toggle department head approval workflow
export const toggleDepartmentLevelApproval = enableToggle => ({
  type: actionType.REQUEST(TOGGLE_HEAD_APPROVAL),
  enableToggle,
});

// TOGGLE_DEPT_HEAD_VIEW_GROSS
// Actions to Toggle department head approval workflow
export const toggleDepartmentHeadViewGross = enableToggle => ({
  type: actionType.REQUEST(TOGGLE_DEPT_HEAD_VIEW_GROSS),
  enableToggle,
});

export const toggleDepartmentHeadViewGrossSuccess = () => ({
  type: actionType.SUCCESS(TOGGLE_DEPT_HEAD_VIEW_GROSS),
});

export const toggleDepartmentHeadViewGrossFailure = () => ({
  type: actionType.FAILURE(TOGGLE_DEPT_HEAD_VIEW_GROSS),
});

export const editApprovalFlow = (
  form,
  reviewFlowId,
  role,
  position,
  stepId,
  userIds,
) => ({
  type: actionType.EDIT_REQUEST(REVIEWER),
  form,
  reviewFlowId,
  role,
  position,
  stepId,
  userIds,
});

export const clearEditValues = (form, reviewFlowId) => ({
  type: actionType.REMOVE_REQUEST(REVIEWER),
  form,
  reviewFlowId,
});

export const removeReviewer = (reviewFlowId, stepId, userId) => ({
  type: actionType.DELETE_REQUEST(REVIEWER),
  reviewFlowId,
  stepId,
  userId,
});

export const removeReviewerSuccess = () => ({
  type: actionType.DELETE_SUCCESS(REVIEWER),
});

export const removeReviewerFailure = () => ({
  type: actionType.DELETE_FAILURE(REVIEWER),
});

export const initEditApprovalFlow = users => ({
  type: actionType.INIT(REVIEWER),
  users,
});

export const addReviewer = () => ({
  type: actionType.ADD(REVIEWER),
});

export const deleteReviewer = key => ({
  type: actionType.REMOVE(REVIEWER),
  key,
});

export const removeApprovalFlow = reviewFlowId => ({
  type: actionType.DELETE_REQUEST(APPROVAL_FLOW),
  reviewFlowId,
});

export const removeApprovalFlowSuccess = () => ({
  type: actionType.DELETE_SUCCESS(APPROVAL_FLOW),
});

export const removeApprovalFlowFailure = () => ({
  type: actionType.DELETE_FAILURE(APPROVAL_FLOW),
});

export const saveReviewers = () => ({
  type: actionType.SAVE_REQUEST(REVIEWERS),
});

export const saveReviewersSuccess = () => ({
  type: actionType.SAVE_SUCCESS(REVIEWERS),
});

export const saveReviewersFailure = () => ({
  type: actionType.SAVE_FAILURE(REVIEWERS),
});

export const addDepartment = () => ({
  type: actionType.ADD(DEPARTMENT),
});

export const editDepartment = (reviewFlowId, departmentId, users) => ({
  type: actionType.EDIT(DEPARTMENT),
  reviewFlowId,
  departmentId,
  users,
});

export const saveDepartment = department => ({
  type: actionType.SAVE_REQUEST(DEPARTMENT),
  department,
});

export const saveDepartmentSuccess = () => ({
  type: actionType.SAVE_SUCCESS(DEPARTMENT),
});

export const saveDepartmentFailure = () => ({
  type: actionType.SAVE_FAILURE(DEPARTMENT),
});

export const deleteDepartment = (reviewFlowId, departmentId) => ({
  type: actionType.DELETE_REQUEST(DEPARTMENT),
  reviewFlowId,
  departmentId,
});

export const deleteDepartmentSuccess = () => ({
  type: actionType.DELETE_SUCCESS(DEPARTMENT),
});

export const deleteDepartmentFailure = () => ({
  type: actionType.DELETE_FAILURE(DEPARTMENT),
});

export const fetchDepartmentStats = () => ({
  type: actionType.FETCH_REQUEST(DEPARTMENT_STATS),
});

export const fetchDepartmentStatsSuccess = () => ({
  type: actionType.FETCH_SUCCESS(DEPARTMENT_STATS),
});

export const fetchDepartmentStatsFailure = () => ({
  type: actionType.FETCH_FAILURE(DEPARTMENT_STATS),
});

export const clearDepartmentEdit = () => ({
  type: actionType.REMOVE_REQUEST(DEPARTMENT),
});

export const deleteDepartmentHead = key => ({
  type: actionType.REMOVE(DEPARTMENT_HEAD),
  key,
});

export const addDepartmentHead = () => ({
  type: actionType.ADD(DEPARTMENT_HEAD),
});

export const updateDepartmentHead = (key, val) => ({
  type: actionType.UPDATE(DEPARTMENT_HEAD),
  key,
  val,
});

export const updateReviewer = (key, val) => ({
  type: actionType.UPDATE(REVIEWER),
  key,
  val,
});
