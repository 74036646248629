import React from 'react';
import { PropTypes } from 'prop-types';
import clsx from 'clsx';
import {
  ModalFooter,
  ModalContent,
  ModalTitle,
} from 'components/Shared/ModalDialog';
import { showAlert } from 'actions/alert';

import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Box,
  Button,
  styled,
  CircularProgress,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers-pro';

import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
// decorators
import withModalDialog from 'decorators/withModalDialog';
//actions
import {
  approveInvoice,
  fetchInvoiceFiles,
  deleteFileFromDB,
} from 'actions/digitalEdits';
import { hide as hideModal } from 'actions/modalDialog';

//selectors
import {
  getDeliveryMethodOptions,
  getIsApproving,
  getDeliveryNote,
  getUploadedDeliveryFiles,
  getUploadsInProgress,
} from 'selectors/digitalEdits';

//components
import InvoiceApproveRadioDropDown from '../InvoiceApproveRadioDropDown';
import DeliveryMethodNotes from './DeliveryMethodNotes';

//utils
import { useDidMount } from 'utils/customHooks';
import { weekEndingFormat } from 'utils/formatDate';

const useStyles = makeStyles(({ palette }) => ({
  dateTextField: {
    width: '140px',
    padding: '4px',
    height: '30px',
    marginLeft: '12px',
    cursor: 'pointer',
  },
  scheduleDate: {
    color: `${palette.gray['-9']} !important`,
    fontSize: '14px',
    fontWeight: 700,
    '&:focus': {
      color: `${palette.gray['-9']} !important`,
    },
    marginBottom: '8px',
  },
  labelSelected: {
    color: 'blue',
  },
  warningColor: {
    '& > .MuiFormHelperText-root': {
      color: palette.warning.main,
    },
    '& > .MuiInput-underline::before': {
      borderBottom: `2px solid ${palette.warning.main} !important`,
    },
  },
}));
const SummaryBox = styled(Box)(({ theme }) => ({ marginBottom: 12 }));
const DeliveryMethodBox = styled(Box)(({ theme }) => ({
  marginBottom: 12,
}));
const RadioOptionsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 40,
}));

const SummaryItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: 2,
}));
const WarningBox = styled(Box)(({ theme }) => ({
  color: theme.palette.warning.main,
  fontWeight: 700,
  fontSize: 18,
}));

const RadioLabel = styled(FormControlLabel)(({ theme }) => ({
  '& > .MuiRadio-root': {
    padding: '0px 8px',
  },
}));

export const MODAL_NAME = 'ApproveInvoice';
const mapState = state => {
  const uploadsInProgress = getUploadsInProgress(state);
  return {
    deliveryMethodOptions: getDeliveryMethodOptions(state),
    approving: getIsApproving(state),
    deliveryNote: getDeliveryNote(state),
    uploadedDmFiles: getUploadedDeliveryFiles(state),
    uploadInProgress: uploadsInProgress.length > 0,
  };
};
const mapDispatch = dispatch => ({
  onFetchFiles: () => dispatch(fetchInvoiceFiles()),

  onCloseModal: () => {
    dispatch(hideModal({ dialog: MODAL_NAME }));
  },
  onApproveInvoice: (deliveryMethod, checkDate, note) => {
    dispatch(
      approveInvoice({
        deliveryMethod,
        checkDate: checkDate?.format('YYYY-MM-DD') || null,
        note,
      }),
    );
  },
  onShowAlert: params => {
    dispatch(showAlert(params));
  },
  onDeleteFileFromDB: ({ documentId }) => {
    dispatch(deleteFileFromDB({ documentId }));
  },
});

const ApproveInvoiceModal = props => {
  const classes = useStyles();
  const {
    onCloseModal,
    invoice,
    deliveryMethodOptions,
    onApproveInvoice,
    onShowAlert,
    approving,
    onFetchFiles,
    deliveryNote,
    uploadInProgress,
    uploadedDmFiles,
    onDeleteFileFromDB,
  } = props;

  const [deliveryMethod, setDeliveryMethod] = React.useState(null);

  useDidMount(() => {
    onFetchFiles();
  });

  const [closeCount, setCloseCount] = React.useState(0);
  const onReqCloseModal = React.useCallback(
    e => {
      const file = uploadedDmFiles[0] || null;
      const onClose = () => {
        onCloseModal();
      };

      if (uploadInProgress) {
        onShowAlert({ message: 'Cannot close while upload in progress' });
      } else if (file && closeCount === 0) {
        onShowAlert({ message: 'Closing modal will delete uploaded file' });
        setCloseCount(v => v + 1);
      } else if (file && closeCount > 0) {
        onDeleteFileFromDB({ documentId: file.documentId });
        onClose();
      } else {
        onClose();
      }
    },
    [
      closeCount,
      onCloseModal,
      onDeleteFileFromDB,
      onShowAlert,
      uploadInProgress,
      uploadedDmFiles,
    ],
  );

  const [checkRadio, setCheckRadio] = React.useState('default');

  const [checkDate, setCheckDate] = React.useState();
  const [localCheckDate, setLocalCheckDate] = React.useState(
    invoice?.futureReleaseDate ? moment(invoice?.futureReleaseDate) : null,
  );
  const [dateError, setDateError] = React.useState('');
  const [dateWarning, setDateWarning] = React.useState('');

  const minDate = React.useMemo(() => moment().add(1, 'days'), []);
  const maxDate = React.useMemo(() => moment().add(14, 'days'), []);

  const [noteText, setNoteInternalText] = React.useState(
    deliveryNote?.commentText || '',
  );

  const [hasNote, setHasNote] = React.useState(!!deliveryNote?.commentText);

  const setNoteText = React.useCallback((text, rawText) => {
    setNoteInternalText(text);
    setHasNote(!!rawText);
  }, []);

  const validateDate = React.useCallback(
    localDate => {
      if (localDate?.isValid()) {
        if (
          localDate.isBefore(maxDate) &&
          localDate.isAfter(minDate.clone().subtract(1, 'days')) //date will have some hours so need to subtract one day to get before logic correct
        ) {
          setDateError('');
          setCheckDate(localDate);
        } else {
          setDateError(
            `Date must be between ${minDate.format(
              'MM/DD/YYYY',
            )} and ${maxDate.format('MM/DD/YYYY')}`,
          );
        }
        //set date warning if date is saturday or sunday
        if (localDate.day() === 0 || localDate.day() === 6) {
          setDateWarning(
            <Tooltip title="If the date selected falls on a weekend or holiday, the check date may reflect the next business day.">
              <span>
                Release date is set for a weekend.
                <InfoIcon
                  sx={{ color: 'black', height: '15px', width: '15px' }}
                />
              </span>
            </Tooltip>,
          );
        } else {
          let releaseIn = '';
          if (localDate) {
            var today = moment();

            const dayCount = localDate.from(today, 'days');

            releaseIn = <span>to be released in {dayCount}</span>;
          }
          setDateWarning(releaseIn);
        }
      } else {
        setDateError('');
        setDateWarning('');
        setCheckDate(null);
      }
    },
    [maxDate, minDate],
  );

  React.useEffect(() => {
    if (checkRadio !== 'future') {
      setDateError('');
      setDateWarning('');
      setCheckDate(null);
    } else if (checkRadio === 'future') {
      validateDate(localCheckDate);
    }
  }, [checkRadio, localCheckDate, validateDate]);

  const isSendToQ = deliveryMethod?.sendToQ;

  const submitDisabled =
    !deliveryMethod?.label ||
    (checkRadio === 'future' && (!checkDate || !!dateError)) ||
    (isSendToQ && !hasNote) ||
    uploadInProgress;

  let approveTooltip = '';

  if (isSendToQ && !hasNote) {
    approveTooltip = 'Shipping instructions required';
  }

  const datePickerDisabled = checkRadio !== 'future';
  const datePickerPendoClass = `PENDO_digitalEditsFutureDate_${weekEndingFormat(
    localCheckDate,
  )}`;

  const dateRadioRef = React.useRef(null);

  return (
    <Box sx={{ maxHeight: '900px' }}>
      <ModalTitle fullWidth>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '32px',
          }}
        >
          <Box sx={{ fontWeight: 'bold' }}>
            Approval - {invoice.code} - {invoice.chargeDescription}
          </Box>
          <IconButton
            onClick={onReqCloseModal}
            className="PENDO_digitalEditsApproveModalClose"
          >
            <CloseIcon sx={{ color: 'text.disabled' }} />
          </IconButton>
        </Box>
      </ModalTitle>
      <ModalContent shrinkTop>
        <Box sx={{}}>
          <Box sx={{ fontSize: 14, mb: '12px' }}>
            Please take a minute to look over the details of the invoice before
            approving:
          </Box>
          <SummaryBox>
            <Box sx={{ fontWeight: 'bold' }}>Invoice Summary:</Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Box sx={{ mr: 10 }}>
                {invoice.largePayment && (
                  <SummaryItem>
                    <WarningIcon sx={{ color: 'warning.main' }} />
                    <WarningBox>Invoice contains large payment</WarningBox>
                  </SummaryItem>
                )}
                <SummaryItem>
                  <CheckCircleIcon sx={{ color: 'primary.main' }} />
                  <span>Invoice Total:</span>
                  {Number(invoice.invoiceTotal || 0).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </SummaryItem>
                <SummaryItem>
                  <CheckCircleIcon sx={{ color: 'primary.main' }} />
                  <span>Gross Pay:</span>
                  {Number(invoice.grossPay || 0).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </SummaryItem>
                <SummaryItem>
                  <CheckCircleIcon sx={{ color: 'primary.main' }} />
                  <span>Net Pay:</span>
                  {Number(invoice.netPay || 0).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </SummaryItem>
              </Box>
              <Box>
                <SummaryItem>
                  <CheckCircleIcon sx={{ color: 'primary.main' }} />
                  {invoice.timecards || 0} Timecards
                </SummaryItem>
                <SummaryItem>
                  <CheckCircleIcon sx={{ color: 'primary.main' }} />
                  {invoice.employees || 0} Employees
                </SummaryItem>
                <SummaryItem>
                  <CheckCircleIcon sx={{ color: 'primary.main' }} />
                  {invoice.directDeposits || 0} Direct Deposits
                </SummaryItem>
              </Box>
            </Box>
          </SummaryBox>
          <DeliveryMethodBox>
            <RadioOptionsBox>
              <InvoiceApproveRadioDropDown
                deliveryMethod={deliveryMethod}
                setDeliveryMethod={setDeliveryMethod}
                deliveryMethodOptions={deliveryMethodOptions}
                printPriority={invoice?.printPriority}
              />
              <Box sx={{ width: '40px', height: '', marginLeft: '100px' }} />
              <FormControl>
                <FormLabel className={classes.scheduleDate}>
                  Schedule release for a future date?
                </FormLabel>
                <RadioGroup
                  defaultValue="default"
                  name="releaseDateRadio"
                  onChange={(e, newVal, c) => {
                    setCheckRadio(newVal);
                  }}
                >
                  <RadioLabel
                    value="default"
                    control={<Radio />}
                    label="No"
                    className={clsx('PENDO_digitalEditsFutureDateNo', {
                      [classes.labelSelected]: checkRadio === 'default',
                    })}
                  />
                  <RadioLabel
                    className={clsx('PENDO_digitalEditsFutureDateYes', {
                      [classes.labelSelected]: checkRadio === 'future',
                    })}
                    value="future"
                    control={<Radio ref={dateRadioRef} />}
                    label={
                      <Box
                        sx={{ display: 'flex', alignItems: 'center' }}
                        onClick={() => {
                          if (checkRadio === 'default') {
                            dateRadioRef.current.click();
                          }
                        }}
                      >
                        Yes
                        <DatePicker
                          clearable
                          minDate={minDate}
                          maxDate={maxDate}
                          format="MM/DD/YYYY"
                          className={clsx(datePickerPendoClass, {
                            [classes.warningColor]: dateWarning && !dateError,
                          })}
                          value={localCheckDate}
                          onChange={date => {
                            setLocalCheckDate(date);
                          }}
                          slotProps={{
                            textField: {
                              disabled: datePickerDisabled,
                              helperText: dateError || dateWarning,
                              className: classes.dateTextField,
                            },
                          }}
                        />
                      </Box>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </RadioOptionsBox>
            {isSendToQ && (
              <DeliveryMethodNotes
                deliveryMethod={deliveryMethod}
                noteText={noteText}
                setNoteText={setNoteText}
              />
            )}
          </DeliveryMethodBox>
        </Box>
      </ModalContent>
      <ModalFooter>
        <Box>
          <Button
            className={'PENDO_digitalEditsApproveModalBtnClose'}
            variant="outlined"
            disabled={uploadInProgress}
            onClick={onReqCloseModal}
          >
            Close
          </Button>
          <Tooltip title={approveTooltip}>
            <span>
              <Button
                className={'PENDO_digitalEditsApproveModalBtnApprove'}
                disabled={submitDisabled || approving}
                onClick={() => {
                  onApproveInvoice(deliveryMethod, checkDate, {
                    ...deliveryNote,
                    commentText: noteText,
                  });
                }}
              >
                {approving ? (
                  <CircularProgress sx={{ mx: 2 }} size={24} />
                ) : (
                  'Approve'
                )}
              </Button>
            </span>
          </Tooltip>
        </Box>
      </ModalFooter>
    </Box>
  );
};

ApproveInvoiceModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
  deliveryMethodOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onApproveInvoice: PropTypes.func.isRequired,
  approving: PropTypes.bool.isRequired,
  onFetchFiles: PropTypes.func.isRequired,
  deliveryNote: PropTypes.shape({
    commentText: PropTypes.string,
  }),
  uploadInProgress: PropTypes.bool.isRequired,
  uploadedDmFiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDeleteFileFromDB: PropTypes.func.isRequired,
  onShowAlert: PropTypes.func.isRequired,
};

export default compose(
  withModalDialog({ dialog: MODAL_NAME, maxWidth: 'lg', noEscClose: true }), //TODO - see if we can close with ESC while still preventing close during file upload
  connect(mapState, mapDispatch),
)(ApproveInvoiceModal);
