// decorators/withMenu.js
// This is a higher order component that will bind menu state to redux.
// This works similarly to redux-form.
//
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isOpen from '../selectors/menu';
import {
  initMenu,
  showMenu,
  hideMenu,
  destroyMenu,
} from '../actions/dropdownMenu';

// eslint-disable-next-line import/no-anonymous-default-export
export default menuName => {
  const mapStateToProps = state => ({
    menuOpen: isOpen(menuName)(state),
  });

  const mapDispatchToProps = dispatch => ({
    onMenuInit: () => dispatch(initMenu({ menu: menuName })),
    onMenuShow: () => dispatch(showMenu({ menu: menuName })),
    onMenuHide: () => dispatch(hideMenu({ menu: menuName })),
    onMenuDestroy: () => dispatch(destroyMenu({ menu: menuName })),
  });

  return BaseComponent => {
    class ComponentWithMenu extends Component {
      static displayName = `withMenu(${BaseComponent.displayName})`;

      static propTypes = {
        onMenuInit: PropTypes.func.isRequired,
        onMenuDestroy: PropTypes.func.isRequired,
      };

      componentDidMount() {
        this.props.onMenuInit();
      }

      componentWillUnmount() {
        this.props.onMenuDestroy();
      }

      render() {
        return <BaseComponent {...this.props} />;
      }
    }

    return connect(mapStateToProps, mapDispatchToProps)(ComponentWithMenu);
  };
};
