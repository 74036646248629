import React from 'react';
import {
  MaxWidthLayout,
  Header,
  AlertHeader,
  ProjectHeader,
} from 'containers/Layout';

import { EnhancedRoute } from 'containers/RoutingSupport';
import { WTC } from 'containers/Employees/Reviews';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <EnhancedRoute
      exact
      authenticated
      key="/projects/reviews/wtc"
      id="/projects/reviews/wtc"
      path="/projects/:projectId/review/:reviewType/:reviewBatchId/wtc"
      layout={MaxWidthLayout}
      headers={[Header, ProjectHeader, AlertHeader]}
      component={WTC}
      isLoadProjectActiveUser={true}
      title="WTC"
    />
  );
};
