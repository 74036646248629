import React from 'react';
import { Button, Paper, Hidden } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ErrorBar from './ErrorBar';
import { compose } from 'utils/helperFunctions';

import { ReactComponent as HoursLogoBlack } from 'components/images/logos/hours-logo-black.svg';

const styles = ({ palette, breakpoints }) => ({
  root: {
    width: '100%',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: palette.background.default,
    height: '92vh',
  },
  btnSection: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '56px 0',
  },
  container: {
    maxWidth: 900,
    color: palette.gray.main,
    margin: '70px auto auto auto',
    [breakpoints.down('sm')]: {
      margin: '25px 10px auto 10px',
      padding: 0,
      width: '100%',
    },
  },
  dashboardBtn: {
    color: 'white',
    backgroundColor: palette.primary.main,
    padding: '12px 30px',
    textTransform: 'none',
    fontSize: 16,
    borderRadius: 8,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  logoIcon: {
    width: 248,
    padding: 8,
    marginTop: 16,
    marginBottom: 16,
    [breakpoints.down('sm')]: {
      padding: 8,
      width: 183,
      height: 44,
    },
  },
  option1: {
    marginTop: 8,
    marginLeft: 8,
    color: palette.gray['+3'],
  },
  phoneIcon: {
    color: palette.secondary.main,
    fontSize: '1.1em',
  },
  phoneNum: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1.75em',
  },
  textSection1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    textAlign: 'center',
    padding: '0 50px',
    margin: '12px 50px 50px',
    [breakpoints.down('sm')]: {
      padding: 0,
      margin: '20px 15px',
    },
  },
  topText1: {
    fontSize: 32,
    fontWeight: 500,
    [breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  text1: {
    fontSize: 24,
    [breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  textSection2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    textAlign: 'center',
    padding: '0 50px',
    marginTop: 25,
    [breakpoints.down('sm')]: {
      marginTop: 20,
      padding: 0,
    },
  },
  topText2: {
    fontSize: 24,
    fontWeight: 500,
    padding: '0 20px',
    [breakpoints.down('sm')]: {
      fontSize: 16,
      padding: 0,
    },
  },
  subText2: {
    fontWeight: 400,
  },
  text2: {
    fontSize: 28,
    marginTop: 14,
    [breakpoints.down('sm')]: {
      marginTop: 24,
      fontSize: 18,
    },
  },
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
  }

  render() {
    if (this.state.hasError === false) {
      return this.props.children;
    } else {
      const { classes } = this.props;

      return (
        <div className={classes.root}>
          <ErrorBar />

          <div className={classes.content}>
            <Paper className={classes.container}>
              <header className={classes.header}>
                <HoursLogoBlack className={classes.logoIcon} />
              </header>
              <section className={classes.textSection1}>
                <span className={classes.topText1}>Something went wrong</span>
                <span className={classes.text1}>
                  We cannot process your request at this time.
                </span>
                <br />
                <div className={classes.textSection2}>
                  <div className={classes.topText2}>
                    Try refreshing your browser.
                    <div className={classes.subText2}>
                      If you continue experiencing problems, call the digital
                      products support team.
                    </div>
                  </div>
                  <div className={classes.text2}>
                    <Hidden smUp>
                      <a href="tel:8188607770">818-860-7770</a>
                    </Hidden>
                    <Hidden smDown>
                      <span>818-860-7770</span>
                    </Hidden>{' '}
                    <span>select option 1</span>
                  </div>
                </div>
              </section>
              <section className={classes.btnSection}>
                <Button
                  className={classes.dashboardBtn}
                  href="https://my.castandcrew.com"
                >
                  Go to MyC&C Dashboard
                </Button>
              </section>
            </Paper>
          </div>
        </div>
      );
    }
  }
}

export default compose(withStyles(styles))(ErrorBoundary);
