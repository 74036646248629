import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Box, Button } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import makeStyles from '@mui/styles/makeStyles';
import Notifications from '../Comments/Notifications';
import { getNotifications, getUploadsInProgress } from 'selectors/digitalEdits';

import UploadProgressCard from './UploadProgressCard';

const useStyles = makeStyles(({ palette }) => ({
  dragNDropArea: {
    width: '99%',
    border: `2px ${palette.gray['+2']} dashed`,
    borderRadius: '2px',
    backgroundColor: 'white',
    maxHeight: '100px',
    height: '100px',
  },
  dragActive: {
    border: `2px ${palette.primary.primary} dashed`,
    backgroundColor: 'rgba(0, 0, 255, 0.04)',
  },
  fileUploadIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  fileUploadText: {
    fontSize: '14px',
    color: palette.gray.primary,
    marginLeft: '16px',
  },
  approvalUpload: {
    maxHeight: '48px',
    height: '48px',
  },
}));

const mapState = state => ({
  notes: getNotifications(state),
  uploadsInProgress: getUploadsInProgress(state),
});

const DragDropUploadArea = props => {
  const classes = useStyles();
  const {
    loading,
    onHandleFile,
    variant = '',
    notes,
    uploadsInProgress,
    helperText = 'Drag and drop files here or click to browse for file',
  } = props;

  const uploadInputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      !loading && setDragActive(true);
    }
    if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const isApproval = variant === 'ApproveInvoice';

  const allowMultiple = isApproval ? false : true;
  let showDragBox = true;

  if (uploadsInProgress.length > 2) {
    showDragBox = false;
  }

  return (
    <Box>
      {notes.length > 0 && (
        <Box sx={{ marginBottom: '16px' }}>
          <Notifications variant={'error'} notes={notes} />
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {showDragBox && (
          <form
            id={`${variant}-drag-upload-control-container`}
            onDragEnter={handleDrag}
            onDragOver={handleDrag}
            onDragLeave={handleDrag}
            onDrop={e => {
              e.preventDefault();
              e.stopPropagation();
              const listArray = Array.from(e.dataTransfer.files);
              !loading && onHandleFile(listArray);
              setDragActive(false);
            }}
            className={clsx(classes.dragNDropArea, {
              [classes.dragActive]: dragActive,
              [classes.approvalUpload]: isApproval,
            })}
          >
            <input
              ref={uploadInputRef}
              type="file"
              id="input-file-upload"
              multiple={allowMultiple}
              style={{ display: 'none' }}
              accept="image/png, image/jpeg, .pdf"
              onChange={e => {
                const array = Array.from(e.target.files);
                !loading && onHandleFile(array);
              }}
              onClick={e => {
                e.target.value = null;
              }}
            />
            <div
              style={{
                height: '100%',
              }}
            >
              <Button
                sx={{ width: '100%', margin: 0, height: '100%' }}
                variant="text"
                onClick={e => {
                  e.preventDefault();
                  uploadInputRef.current.click();
                }}
              >
                <Box className={classes.fileUploadIcon}>
                  <FileUploadIcon
                    sx={{
                      color: 'gray.primary',
                      fontSize: '32px',
                    }}
                  />
                  <Box className={classes.fileUploadText}>{helperText}</Box>
                </Box>
              </Button>
            </div>
          </form>
        )}
        {uploadsInProgress.map(inProgress => (
          <UploadProgressCard
            key={inProgress.uploadId}
            inProgress={inProgress}
          />
        ))}
      </Box>
    </Box>
  );
};

DragDropUploadArea.propTypes = {
  loading: PropTypes.bool,
  onHandleFile: PropTypes.func.isRequired,
  variant: PropTypes.string,
  notes: PropTypes.array,
  uploadsInProgress: PropTypes.array,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default connect(mapState)(DragDropUploadArea);
