import { buildUrl } from '../api';

const URL_UPDATE_INVITATIONS_PROFILE = '/invitations/:token/profile';
const URL_INVITATIONS = '/invitations/:token';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async getInvitation(token) {
    const url = buildUrl(URL_INVITATIONS, token);
    const rsp = await clientV2.get(url, token);
    return rsp.data;
  },

  async saveInvitations(data, token) {
    const url = buildUrl(URL_UPDATE_INVITATIONS_PROFILE, token);
    const rsp = await clientV2.post(url, data);
    return rsp.data;
  },
});
