import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// decorators
import withSorting from 'decorators/withSorting';
// components
import EventsList from 'components/Admin/Projects/Events';
import { eventsProps } from 'components/props/events';
// actions
import { fetch as fetchEvents } from 'actions/events';
// selectors
import { sortData } from 'selectors/sorting';
import { getLoading, getEvents } from 'selectors/events';

const mapState = state => ({
  loading: getLoading(state),
  events: sortData(getEvents, 'admin-events')(state),
});

const mapDispatch = (dispatch, { match }) => ({
  onFetchData: () => {
    dispatch(fetchEvents());
  },
});

class AdminEvents extends Component {
  static propTypes = {
    events: eventsProps.isRequired,
    loading: PropTypes.bool,
    onFetchData: PropTypes.func.isRequired,
    sortParams: PropTypes.object.isRequired,
    onApplySorting: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { onFetchData } = this.props;
    onFetchData();
  }

  render() {
    const { events, sortParams, onApplySorting, loading } = this.props;

    return (
      <EventsList
        events={events}
        loading={loading}
        sortParams={sortParams}
        onApplySorting={onApplySorting}
      />
    );
  }
}

export default withSorting('admin-events')(
  connect(mapState, mapDispatch)(AdminEvents),
);
