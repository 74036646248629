import { createSelector } from 'reselect';
import { getFormValues } from './formValues';
import _ from 'lodash';
export const getProfileValues = createSelector(
  [getFormValues('ProfileDetails')],
  details => ({
    ...details,
    alternate_id: details.alternateIdMask || '',
    ssn: details.ssnMask || '',
  }),
);

export const getNotificationsLoading = state =>
  _.get(state, 'profile.notificationsLoading', false);

export const getPhoneNumberLoading = state =>
  _.get(state, 'profile.phoneNumberLoading', false);

export const getAllNotifications = state =>
  _.get(state, 'profile.preference.notifications.list', []);

export const getNotificationSelectedFlag = state =>
  _.get(state, 'profile.preference.notifications.selectedAll', '');

export const getAllPhoneNumbers = state =>
  _.get(state, 'profile.phoneNumber', {});

export const getPhoneNumberVerifiedStatus = state =>
  _.get(state, 'profile.phoneNumber.verified', false);

export const getUpdateStatus = state =>
  _.get(state, 'profile.notification.status', '');

export const getNotificationsFormValues = createSelector(
  [getFormValues('Notifications')],
  preferences => {
    return preferences;
  },
);

export const getAllowanceTypes = state =>
  _.get(state, 'profile.allowanceTypes', []);

export const downloadingSupportingDocument = state =>
  _.get(state, 'profile.downloadingSupportingDocument', false);
