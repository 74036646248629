import React, { useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

import Cell from './Cell';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    zIndex: 0,
    height: 30,
    '&:hover': {
      backgroundColor: palette.action.hover,
    },
  },
  odd: {
    backgroundColor: palette.primary.table,
  },
  even: {
    backgroundColor: palette.background.paper,
  },
  rowClicked: {
    backgroundColor: palette.primary['+7'],
    '&:hover': {
      backgroundColor: palette.primary['+7'],
    },
  },
}));

const Row = props => {
  const classes = useStyles();
  const {
    prepareRow,
    row,
    name,
    rowIndex,
    onClickTimecard,
    currentUserRole,
    currentUser,
    onEditCrewMember,
    form,
    ...others
  } = props;

  const [
    rowClicked,
    // setRowClicked
  ] = useState(false);

  prepareRow(row);
  return (
    <tr
      {...row.getRowProps()}
      className={clsx(classes.root, {
        [classes.odd]: rowIndex % 2 === 0 && !rowClicked,
        [classes.even]: rowIndex % 2 === 1 && !rowClicked,
        [classes.rowClicked]: rowClicked,
      })}
    >
      {row.cells.map((cell, cellIndex) => (
        <Cell
          cell={cell}
          key={cell.column.id}
          cellIndex={cellIndex}
          onClickTimecard={onClickTimecard}
          row={row}
          currentUserRole={currentUserRole}
          currentUser={currentUser}
          onEditCrewMember={onEditCrewMember}
          rowIndex={rowIndex}
          name={name}
          field={row.original}
          form={form}
          {...others}
        />
      ))}
    </tr>
  );
};

export default Row;
