import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { reduxForm, formValueSelector, getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'utils/helperFunctions';

// decorators
import withModalDialog from 'decorators/withModalDialog';

// components
import { ConfirmModal } from 'components/Shared/Modals';
import MoveTimecardsContent from './MoveTimecardsContent';

// actions
import {
  initMoveModal,
  moveTimecards,
  moveModalClose,
} from 'actions/moveTimecards';
import { show as showModal, hide as hideModal } from 'actions/modalDialog';
import { showAlert } from 'actions/alert';

// selectors
import {
  getMoveLoading,
  getSourceBatch,
  getMoveBatchTcs,
  getDestinationBatches,
  getDestinationInvoices,
  getInitialValues,
  getMoveDone,
} from 'selectors/moveTimecards';
import {
  isCurrentUserUPM,
  isCurrentUserPA,
  currentUserLevel,
} from 'selectors/wtc';

//helper function
import { useDidMount, useWillUnmount } from 'utils/customHooks';

const style = theme => {
  return {
    root: {},
    title: { fontSize: 35, color: theme.palette.text.primary },

    topCopy: {
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
    sourceItem: {
      marginRight: 5,
      marginLeft: 5,
      color: theme.palette.primary.main,
    },
  };
};

const formName = 'moveTimecardsToBatch';
export const MOVE_MODAL_DIALOG = 'moveTimecardsToBatch';

const mapStateToProps = state => {
  const formSelector = formValueSelector(formName);
  return {
    batchTimecards: getMoveBatchTcs(state),
    formError: getFormSyncErrors(formName)(state),
    batches: getDestinationBatches(state),
    invoices: getDestinationInvoices(state),
    moveLoading: getMoveLoading(state),
    sourceBatch: getSourceBatch(state),
    isUPM: isCurrentUserUPM(state),
    isPA: isCurrentUserPA(state),
    userLevel: currentUserLevel(state),
    initialValues: getInitialValues(state),
    formValues: formSelector(
      state,
      'newBatchName',
      'newInvoiceChargeDesc',
      'destinationBatchId',
      'destinationInvoiceId',
      'timecardIds',
    ),
    moveDone: getMoveDone(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onInitMoveModal: () => {
    dispatch(initMoveModal());
  },
  onMoveTimecard: params => {
    dispatch(moveTimecards(params));
  },
  onConfirmMove: data => {
    dispatch(showModal({ dialog: 'ConfirmMoveTimecard', modalParams: data }));
  },
  onCloseModal: () => {
    dispatch(hideModal({ dialog: 'moveTimecardsToBatch' }));
  },
  onCloseMoveModal: () => {
    dispatch(moveModalClose());
  },
  onShowAlert: params => dispatch(showAlert(params)),
});

const MoveTimecardsModal = props => {
  const {
    classes,
    formError,
    handleSubmit,
    moveLoading,
    onCloseModal,
    onInitMoveModal,
    onModalHide,
    onCloseMoveModal,
    formValues,
    change,
    sourceBatch,
    invoices,
    moveDone,
    // ...others All props are passed along to MoveTimecardContent
  } = props;

  useDidMount(() => {
    onInitMoveModal();
  });

  useWillUnmount(() => {
    onCloseMoveModal();
  });

  useEffect(() => {
    const destinationInvoiceId = formValues?.destinationInvoiceId;
    if (
      !destinationInvoiceId &&
      sourceBatch.id
      // && destinationInvoiceId !== 0 //HOUR-7196
    ) {
      // once the source batch has loaded, set destination invoice.
      // if no source invoice, set to 'All Invoices' option
      change('destinationInvoiceId', sourceBatch?.invoiceId || -1);
    }
  }, [
    change,
    formValues?.destinationInvoiceId,
    sourceBatch,
    sourceBatch?.invoiceId,
  ]);

  const sourceInvoice = invoices.find(
    invoice => invoice.id === sourceBatch.invoiceId,
  );

  let invoiceLabel = sourceInvoice ? `${sourceInvoice.name}` : '';
  invoiceLabel += sourceInvoice?.chargeDescription
    ? ` - ${sourceInvoice.chargeDescription}`
    : '';
  invoiceLabel += sourceInvoice ? '  ' : '';

  const batchLabel = `${sourceBatch.htgBatchNumber} - ${sourceBatch.name}`;

  const title = (
    <>
      <Typography className={classes.title}>Move Timecards</Typography>
      <Typography className={classes.topCopy}>
        Select timecards to move to another batch or invoice.
      </Typography>
      <Typography className={classes.topCopy}>
        <span>Timecard from:</span>
        <span className={classes.sourceItem}>{invoiceLabel}</span>{' '}
        <span className={classes.sourceItem}>{batchLabel}</span>
      </Typography>
      <Typography></Typography>
    </>
  );

  const content = <MoveTimecardsContent {...props} />;

  let isLoading = false;
  for (const loadType in moveLoading) {
    if (Object.hasOwnProperty.call(moveLoading, loadType)) {
      const value = moveLoading[loadType];
      if (value) isLoading = true;
    }
  }

  let cancelText = moveDone ? 'Close' : 'Cancel';

  return (
    <form onSubmit={handleSubmit}>
      <ConfirmModal
        content={content}
        title={title}
        buttonText="Move Timecard"
        cancelText={cancelText}
        raisedButton
        submitDisabled={!formError.moveValid || isLoading}
        moveLoading={moveLoading}
        onRequestClose={onCloseModal}
        onModalHide={onModalHide}
      />
    </form>
  );
};

MoveTimecardsContent.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  moveLoading: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onInitMoveModal: PropTypes.func.isRequired,
  onModalHide: PropTypes.func.isRequired,
  onCloseMoveModal: PropTypes.func.isRequired,

  //Props passed down to MoveModalContent
  change: PropTypes.func.isRequired,
  batchTimecards: PropTypes.array.isRequired,
  formError: PropTypes.object.isRequired,
  batches: PropTypes.array.isRequired,
  invoices: PropTypes.array.isRequired,
  sourceBatch: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  userLevel: PropTypes.number.isRequired,
  isUPM: PropTypes.bool.isRequired,
  isPA: PropTypes.bool.isRequired,
};

const validate = (values, ownProps) => {
  const errors = {
    timecardSelected: false,
    destinationSelected: false,
    addInvoiceError: '',
    addBatchError: '',
  };

  const areTimecardsEmpty = ownProps?.batchTimecards?.length === 0;

  if (values) {
    //Add invoice
    if (!values.newInvoiceChargeDescDraft) {
      errors.addInvoiceError = 'Enter an invoice name';
    } else if (areTimecardsEmpty) {
      errors.addInvoiceError = 'No timecards to move';
    }

    //Add Batch
    if (!values.newBatchNameDraft) {
      errors.addBatchError = 'Enter a batch name';
    } else if (areTimecardsEmpty) {
      errors.addBatchError = 'No timecards to move';
    } else if (
      values.destinationInvoiceId === -1 ||
      !values.destinationInvoiceId
    ) {
      errors.addBatchError = 'Select an invoice to add batch';
    }

    for (const timecardHeader in values.timecardIds) {
      if (Object.hasOwnProperty.call(values.timecardIds, timecardHeader)) {
        const selected = values.timecardIds[timecardHeader];
        if (selected) errors.timecardSelected = true;
      }
    }

    if (values.destinationBatchId) errors.destinationSelected = true;
  }

  errors.moveValid = errors.timecardSelected && errors.destinationSelected;

  return errors;
};

function getTimecardIdsFromList(timecardIds) {
  const selectedIds = [];
  for (const timecardHeaderId in timecardIds) {
    if (Object.hasOwnProperty.call(timecardIds, timecardHeaderId)) {
      const selected = timecardIds[timecardHeaderId];
      if (selected) {
        selectedIds.push(timecardHeaderId);
      }
    }
  }
  return selectedIds;
}

const onSubmit = (values, dispatch, ownProps) => {
  const { invoices, onMoveTimecard, sourceBatch } = ownProps;

  const {
    newBatchName,
    timecardIds,
    destinationInvoiceId,
    destinationBatchId,
    voidEmptyInvoice,
    deleteEmptyBatch,
    newInvoiceChargeDesc,
  } = values;

  const timecardEntryIds = getTimecardIdsFromList(timecardIds);
  const data = {
    timecardEntryIds,
    //need batchWorksight to refresh the batch when move is done
    sourceBatchWorksightId: sourceBatch.worksightId,
    voidEmptyInvoice,
    deleteEmptyBatch,
  };

  let isNewBatch = false;
  if (destinationBatchId === 'NEW') {
    data.batch = { name: newBatchName };
    isNewBatch = true;
  } else {
    const batchId = Number(destinationBatchId);
    data.batch = { id: batchId };
  }

  if (isNewBatch) {
    if (destinationInvoiceId === 'NEW') {
      data.invoice = {
        chargeDescription: newInvoiceChargeDesc,
      };
    } else {
      const invoice = invoices.find(
        invoice => invoice.id === destinationInvoiceId,
      );
      data.invoice = invoice ? _.cloneDeep(invoice) : { id: 0 };
      delete data.invoice?.payrollCompany;
    }
  }
  onMoveTimecard(data);
};

export default compose(
  withStyles(style),
  withModalDialog({ dialog: MOVE_MODAL_DIALOG, maxWidth: 'xl' }),

  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
    touchOnChange: true,
    validate,
    onSubmit,
  }),
)(MoveTimecardsModal);
