import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RecalcIconBubble from 'components/images/RecalcIconBubble';
import { makeNameFromRecord } from './digitalEditsUtils';

const useStyles = makeStyles(({ palette }) => ({
  RecordTitle: {
    display: 'flex',
    gap: '40px',
  },
  name: {
    fontSize: 32,
    fontWeight: 700,
    color: palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    lineHeight: 1.5,
  },
  recordProperties: {
    display: 'flex',
    fontWeight: 700,
    justifyContent: 'flex-end',
    lineHeight: '26px',
  },
}));

const RecordTitle = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { record, shouldRender } = props;

  const name = makeNameFromRecord(record);

  const { isEdited } = record;

  return (
    <Box className={classes.RecordTitle}>
      <Box ref={ref} className={classes.name}>
        {name}
        <Tooltip title="Copy name to clipboard">
          <IconButton
            sx={{ p: '4px' }}
            className="PENDO_digitalEditsCopyToClipBoard"
            onClick={() => {
              navigator.clipboard.writeText(name);
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
        {isEdited && (
          <Tooltip title={'Recalculated'}>
            <RecalcIconBubble />
          </Tooltip>
        )}
      </Box>

      {shouldRender && (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <Box className={classes.recordProperties}>
            <Box sx={{ color: 'error.main' }}>
              {record.duplicateTimecard ? 'A duplicate record exists.' : null}
            </Box>
            <Box sx={{ color: 'warning.main', pl: 1 }}>
              {record.largePayment ? 'Large payment!' : null}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
});

RecordTitle.propTypes = {
  record: PropTypes.object.isRequired,
  shouldRender: PropTypes.bool.isRequired,
};

export default RecordTitle;
