import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Box, Tooltip, IconButton } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  CopyToAll: {
    position: 'relative',
    color: palette.primary.contrastText,
    backgroundColor: palette.primary.main,
    zIndex: 5,
    borderRadius: 10,
  },
  copyToAllBtn: {
    position: 'absolute',
    borderRadius: 10,
    padding: '4px',
    backgroundColor: palette.primary.main,
    border: '1px solid',
    zIndex: 5,
    '&:hover': {
      backgroundColor: palette.gray['+8'],
    },
  },

  copyToAllIcon: {
    color: palette.primary.contrastText,
  },
}));

const mapState = state => ({});

const mapDispatch = {};

const CopyToAll = props => {
  const classes = useStyles();
  const { copyToAll } = props;

  return (
    <Box className={classes.CopyToAll}>
      <Tooltip
        title={
          <div>
            Copy to All Rows
            <br />
            <span>(Ctrl + Shift + A)</span>
          </div>
        }
      >
        <IconButton
          id="CopyToAllBtn"
          tabIndex={-1}
          color="primary"
          variant="contained"
          className={classes.copyToAllBtn}
          onMouseDown={e => {
            copyToAll();
          }}
        >
          <FileCopyIcon className={classes.copyToAllIcon} fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

CopyToAll.propTypes = { copyToAll: PropTypes.func.isRequired };

export default compose(connect(mapState, mapDispatch))(CopyToAll);
