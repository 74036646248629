import { all, takeEvery, call, put, select } from 'redux-saga/effects';
//import snakeCase from 'snakecase-keys';
import camelCase from 'camelcase-keys';
import { startSubmit, stopSubmit } from 'redux-form';
import { push } from 'redux-first-history';

//actions
import * as actions from 'actions/invitations';
import { fetchUserInfo } from 'sagas/userInfo';
//selectors
import { getProjectDetails } from 'selectors/project';

export function* fetchInvitations(api, debug, params) {
  try {
    yield put(actions.loadingInvitations({ loading: true }));
    const { token } = params;
    const result = yield call(api.invitations.getInvitation, { token });
    const invitation = camelCase(result, { deep: true });
    yield put(actions.storeInvitations({ invitation }));
    yield put(actions.loadingInvitations({ loading: false }));
  } catch (e) {
    debug(e);
  }
}

export function* saveInvitations(api, debug, params) {
  const formName = 'Invitations';
  try {
    const data = camelCase(params.values);
    const token = params.token;
    yield put(startSubmit(formName));
    yield call(api.invitations.saveInvitations, data, token);

    // Need to fetch user info here, reactivated users get stuck in
    // invite loop otherwise when they reactivate from email the first time.
    let projectId = params.projectId;
    if (!projectId) {
      const project = yield select(getProjectDetails);
      projectId = project.id;
    }
    if (projectId) yield call(fetchUserInfo, api, projectId);

    yield put(stopSubmit(formName));
    const toURI = '/';
    yield put(push(toURI));
  } catch (e) {
    if (e && e.status === 422 && e.data) {
      const errors = e.data ? camelCase(e.data, { deep: true }) : null;
      yield put(stopSubmit(formName, { _error: { ...errors } }));
    }
  }
}

export default function* invitations({ api, debug }) {
  yield all([
    takeEvery(`${actions.fetchInvitations}`, fetchInvitations, api, debug),
    takeEvery(`${actions.saveInvitations}`, saveInvitations, api, debug),
  ]);
}
