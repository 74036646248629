import React from 'react';
import PropTypes from 'prop-types';
import {
  ModalFooter,
  ModalContent,
  ModalTitle,
} from 'components/Shared/ModalDialog';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  Tooltip,
} from '@mui/material';
// import makeStyles from '@mui/styles/makeStyles';

// decorators
import withModalDialog from 'decorators/withModalDialog';

import { getIsLoadingUploads, getAnyUploading } from 'selectors/digitalEdits';
import { getModalParams } from 'selectors/modalDialog';

import { hide as hideModal } from 'actions/modalDialog';
import * as actions from 'actions/digitalEdits';

import DragDropUploadArea from './DragDropUploadArea';
import UploadedFiles from './UploadedFiles';
import SubmittedFiles from './SubmittedFiles';
import { calculateFileSize } from './FileUploadUtils';

import { useKeepMinHeight } from 'utils/customHooks';
import { fileNameChange } from 'utils/helperFunctions';

import { useDidMount } from 'utils/customHooks';

// const useStyles = makeStyles(({ palette }) => ({}));

const MODAL_NAME = 'EditUploadFiles';

const mapState = state => ({
  loading: getIsLoadingUploads(state),
  modalParams: getModalParams(state, MODAL_NAME),
  uploading: getAnyUploading(state),
});
const mapDispatch = dispatch => ({
  onCloseModal: () => {
    dispatch(hideModal({ dialog: `${MODAL_NAME}` }));
  },
  onFetchFiles: () => dispatch(actions.fetchInvoiceFiles()),
  onUploadFilesToDB: ({ files, onUpdateProgress }) => {
    dispatch(actions.batchPendingFilesForUpload({ files, onUpdateProgress }));
  },
  onUpdateProgress: (uploadId, percentComplete) =>
    dispatch(
      actions.updateInProgressUploads({
        action: 'update',
        uploadId,
        percentComplete,
      }),
    ),
});

const UploadFilesModal = props => {
  const {
    onCloseModal,
    loading,
    onFetchFiles,
    onUploadFilesToDB,
    modalParams,
    uploading,
    onUpdateProgress,
  } = props;

  const { readOnly } = modalParams;

  const onHandleFile = React.useCallback(
    filesArray => {
      // to-do: filter out oversized files and prohibited file types, then show alert?
      const files = filesArray.map(file => {
        const newFile = fileNameChange(file);
        return {
          type: newFile.type,
          fileName: newFile.name,
          size: calculateFileSize(newFile.size),
          File: newFile,
          bytes: newFile.size,
        };
      });
      onUploadFilesToDB({ files, onUpdateProgress });
    },
    [onUpdateProgress, onUploadFilesToDB],
  );

  const ref = useKeepMinHeight();

  useDidMount(() => {
    onFetchFiles();
  });

  return (
    <Box
      ref={ref}
      sx={{ display: 'flex', flexDirection: 'column', maxHeight: '900px' }}
    >
      {readOnly ? (
        <ModalTitle fullWidth>
          {loading && <LinearProgress />}
          <Typography sx={{ fontSize: '20px', fontWeight: '500' }}>
            Uploaded Files
          </Typography>
        </ModalTitle>
      ) : (
        <ModalTitle fullWidth>
          {(loading || uploading) && <LinearProgress />}
          <Typography sx={{ fontSize: '20px', fontWeight: '500' }}>
            Upload Supporting Files
          </Typography>

          <Typography sx={{ marginTop: '16px', fontSize: '14px' }}>
            Only .pdf, .jpg, and .png files accepted. 25 MB max file size
          </Typography>
        </ModalTitle>
      )}

      <ModalContent shrinkTop>
        <Box
          sx={{
            maxHeight: '600px',
            minHeight: '150px',
            width: '550px',
            overflow: 'auto',
          }}
        >
          {!readOnly && (
            <DragDropUploadArea
              onHandleFile={onHandleFile}
              loading={loading}
              variant={MODAL_NAME}
            />
          )}
          <UploadedFiles />
          <SubmittedFiles readOnly={readOnly} />
        </Box>
      </ModalContent>
      <ModalFooter>
        <Tooltip title={uploading ? 'Cannot close during upload' : ''}>
          <div>
            <Button
              variant="outlined"
              disabled={uploading}
              onClick={() => {
                onCloseModal();
              }}
            >
              Close
            </Button>
          </div>
        </Tooltip>
      </ModalFooter>
    </Box>
  );
};

UploadFilesModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onFetchFiles: PropTypes.func.isRequired,
  onUploadFilesToDB: PropTypes.func.isRequired,
  modalParams: PropTypes.object.isRequired,
  uploading: PropTypes.bool.isRequired,
  onUpdateProgress: PropTypes.func.isRequired,
};

export default compose(
  withModalDialog({ dialog: MODAL_NAME, maxWidth: 'lg', noEscClose: true }),
  connect(mapState, mapDispatch),
)(UploadFilesModal);
