import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';

//selectors
import { getModalAction } from 'selectors/dts';

//actions
import { hide as hideModal } from 'actions/modalDialog';
import { clearModalAction, setPostSaveAction } from 'actions/dts';

const modalDialog = 'ConfirmDTSActionModal';

const mapStateToProps = state => ({
  modalAction: getModalAction(state),
});

const mapDispatchToProps = dispatch => ({
  onModalHide: () => {
    dispatch(hideModal({ dialog: modalDialog }));
  },
  onUnmount: () => {
    dispatch(clearModalAction());
  },
  onSetPostSaveAction: func => {
    dispatch(setPostSaveAction({ func }));
  },
});

const ConfirmDTSActionModal = props => {
  const { modalAction, onModalHide, onUnmount, onSetPostSaveAction } = props;
  const { variant, save, hasError, onSetDirty } = modalAction;

  const willUnmountRef = useRef(false);
  useEffect(() => {
    return () => {
      willUnmountRef.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (willUnmountRef.current === true) {
        onUnmount();
      }
    };
  }, [onUnmount]);

  const onSubmit = () => {
    const func = modalAction.func;
    const args = modalAction.args ? modalAction.args : [];
    func(...args);
    onModalHide();
  };

  const contentObj = {
    default: {
      title: 'Please Confirm',
      content: <div>Do you want to continue?'</div>,
    },
    tcDirty: {
      title: 'Unsaved changes',
      content: (
        <div>
          You have <b>unsaved changes </b> in Daily Timesheets. <br />
          <br />
          {!hasError && (
            <span>Do you want to save change before proceeding?</span>
          )}
          {hasError && (
            <span style={{ color: 'red', fontWeight: 800 }}>
              Cannot save with errors on page
            </span>
          )}
        </div>
      ),
      buttonText: 'Save and continue',
      onSubmit: () => {
        save();
        onSetPostSaveAction(onSubmit);
        onModalHide();
      },
      textBtnText: 'Continue without saving',
      textBtnSubmit: () => {
        onSetDirty(false);

        //need a small delay so the setDirty can propagate
        setTimeout(() => {
          onSubmit();
        }, 150);
      },
      cancelText: 'Stay here',
    },
    cta: {
      title: 'Copy to all Warning',
      content: 'Sure you want to copy empty value to all?',
    },
  };

  const content = contentObj[variant]
    ? contentObj[variant]
    : contentObj['default'];

  return (
    <ConfirmModal
      title={content.title}
      content={content.content}
      buttonText={content.buttonText || 'Yes'}
      cancelText={content.cancelText || 'No'}
      raisedButton
      hasSubmitAction
      onModalHide={onModalHide}
      onSubmit={content.onSubmit || onSubmit}
      textBtnText={content.textBtnText}
      textBtnSubmit={content.textBtnSubmit}
      submitDisabled={hasError}
    />
  );
};

ConfirmDTSActionModal.propTypes = {
  modalAction: PropTypes.shape({
    func: PropTypes.func.isRequired,
    args: PropTypes.array.isRequired,
    variant: PropTypes.string,
    save: PropTypes.func,
  }),
  onModalHide: PropTypes.func.isRequired,
};

export default compose(
  withModalDialog({ dialog: modalDialog, maxWidth: 'sm' }),
  connect(mapStateToProps, mapDispatchToProps),
)(ConfirmDTSActionModal);
