import { buildUrl } from '../api';

const URL_TEMPLATES = '/projects/:projectId/batch-templates';
const URL_BATCH_TEMPLATE = '/projects/:projectId/batch-templates/:templateId';
const URL_TEMPLATE_TYPE = '/projects/:projectId/:templateType';

const invoiceTemplate = ({ clientV2 }) => ({
  async templates({ projectId }) {
    const url = buildUrl(URL_TEMPLATES, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async update({ projectId, batchTemplate }) {
    const templateId = batchTemplate.id;
    const url = buildUrl(URL_BATCH_TEMPLATE, { projectId, templateId });
    const rsp = await clientV2.put(url, batchTemplate);
    return rsp.data;
  },

  async create({ projectId, batchTemplate }) {
    const url = buildUrl(URL_TEMPLATES, { projectId });
    const rsp = await clientV2.post(url, batchTemplate);
    return rsp.data;
  },

  async deleteBatch({ projectId, templateId }) {
    const url = buildUrl(URL_BATCH_TEMPLATE, { projectId, templateId });
    const rsp = await clientV2.delete(url);
    return rsp.data;
  },

  async templateTypes({ projectId, templateType }) {
    const url = buildUrl(URL_TEMPLATE_TYPE, { projectId, templateType });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
});
export default invoiceTemplate;
