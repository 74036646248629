import React from 'react';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import {
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  LinearProgress,
  Grid,
  Alert,
  Box,
} from '@mui/material';
import LocalATM from '@mui/icons-material/LocalAtmRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { isFieldEnabled, checkGuaranteedAllowance } from 'utils/weekUtils';
import ContentRow from './AllowanceRow';

import { DH, EMPLOYEE } from 'components/props/profiles';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 15,
    marginBottom: 5,
  },
  icon: {
    verticalAlign: 'middle',
    paddingRight: 10,
  },
  noPadding: {
    padding: 0,
  },
  emptyRow: {
    textAlign: 'center',
  },
}));

export default function Allowances({
  allowances = [],
  allowanceInput,
  editableFields,
  allowanceEditable,
  isViewOnly = false,
  loading = false,
  isTeamTimecard,
  onDeleteAllowance,
  onDownloadDocument,
  onSaveAllowance,
  showAlert,
  timecard = {},
  timecardAllowances,
  onSelectAllowanceType,
  onSelectAllowanceAmount,
  onSelectAllowanceCombineCheckCode,
  onUploadDocument,
  onResetAllowance,
  setAllowanceInProgress,
  role,
  rejectTcOnAllowanceChange,
  isReviewing,
  isUserDeleted,
  editReview,
  onShowAlert,
  showWarningAlert = false,
  employees = [],
  onSelectAllowanceRate,
  onSelectAllowanceUnits,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);
  const dhAllowanceDisabled =
    role === DH && EMPLOYEE && rejectTcOnAllowanceChange;
  const empAllowanceDisableAddedByPA =
    role === EMPLOYEE && rejectTcOnAllowanceChange;

  const editable =
    isFieldEnabled(editableFields, 'allowances') &&
    !dhAllowanceDisabled &&
    !isUserDeleted;

  const renderContent = () => {
    const rows = [];

    if (editable && !isViewOnly && allowanceEditable) {
      rows.push(
        <ContentRow
          key={`allowanceInput`}
          allowanceInput={allowanceInput}
          onDeleteAllowance={onDeleteAllowance}
          onDownloadDocument={onDownloadDocument}
          onSaveAllowance={onSaveAllowance}
          timecardAllowances={timecardAllowances}
          isViewOnly={isViewOnly}
          loading={loading}
          showAlert={showAlert}
          canEdit={editable}
          timecardId={timecard.id}
          onSelectAllowanceType={onSelectAllowanceType}
          onSelectAllowanceAmount={onSelectAllowanceAmount}
          onSelectAllowanceCombineCheckCode={onSelectAllowanceCombineCheckCode}
          onUploadDocument={onUploadDocument}
          onResetAllowance={onResetAllowance}
          setAllowanceInProgress={setAllowanceInProgress}
          dhAllowanceDisabled={dhAllowanceDisabled}
          isReviewing={isReviewing}
          empAllowanceDisableAddedByPA={empAllowanceDisableAddedByPA}
          role={role}
          editReview={editReview}
          onShowAlert={onShowAlert}
          onSelectAllowanceRate={onSelectAllowanceRate}
          onSelectAllowanceUnits={onSelectAllowanceUnits}
          timecard={timecard}
        />,
      );
    }
    if (allowances.length === 0 && !loading && isViewOnly) {
      return (
        <Grid containter>
          <Grid item xs={12} className={classes.emptyRow}>
            <Typography>No Allowances</Typography>
          </Grid>
        </Grid>
      );
    }
    return _.map(allowances, (allowance, index) => {
      return (
        <ContentRow
          key={`allowance-${index}`}
          allowance={allowance}
          onDeleteAllowance={onDeleteAllowance}
          onDownloadDocument={onDownloadDocument}
          onSaveAllowance={onSaveAllowance}
          timecardAllowances={timecardAllowances}
          isViewOnly={isViewOnly}
          loading={loading}
          showAlert={showAlert}
          canEdit={editable}
          timecardId={timecard.id}
          index={index}
          onSelectAllowanceType={onSelectAllowanceType}
          onSelectAllowanceAmount={onSelectAllowanceAmount}
          onUploadDocument={onUploadDocument}
          onResetAllowance={onResetAllowance}
          setAllowanceInProgress={setAllowanceInProgress}
          dhAllowanceDisabled={dhAllowanceDisabled}
          isReviewing={isReviewing}
          isTeamTimecard={isTeamTimecard}
          empAllowanceDisableAddedByPA={empAllowanceDisableAddedByPA}
          role={role}
          editReview={editReview}
          onShowAlert={onShowAlert}
          onSelectAllowanceRate={onSelectAllowanceRate}
          onSelectAllowanceUnits={onSelectAllowanceUnits}
          onSelectAllowanceCombineCheckCode={onSelectAllowanceCombineCheckCode}
          timecard={timecard}
        />
      );
    }).concat(rows);
  };
  const isGuaranteedAllowanceExists = checkGuaranteedAllowance(employees);

  return (
    <Accordion
      className={classes.root}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <LocalATM color="primary" className={classes.icon} />
        <Typography color="primary">ALLOWANCES</Typography>
      </AccordionSummary>
      <Divider />
      {loading && <LinearProgress />}
      <AccordionDetails>
        {showWarningAlert && isGuaranteedAllowanceExists && (
          <Alert severity="warning">
            <Box sx={{ fontWeight: 700, color: 'text.primary' }}>
              Guaranteed allowances from an employee’s deal memo will be
              automatically added to each timecard upon submission
            </Box>
            <Box sx={{ color: 'text.primary' }}>
              Allowances entered here will be added in addition to guaranteed
              allowances
            </Box>
          </Alert>
        )}
        <Grid container>{renderContent()}</Grid>
      </AccordionDetails>
    </Accordion>
  );
}
