import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import { Box, Typography, LinearProgress } from '@mui/material';

import withModalDialog from 'decorators/withModalDialog';

import { hide as hideModal } from 'actions/modalDialog';
import { deleteTimecard } from '../actions';

import * as sel from '../selectors';
import {
  ContentBox,
  FooterBox,
  FooterButton,
  TitleBox,
} from '../Shared/styledComponents';

import { DELETE_TC_DIALOG } from '../Modals/modalNames';
const mapState = state => ({
  loading: sel.getTCDeleteLoading(state),
});
const mapDispatch = dispatch => {
  return {
    onCloseModal: () => dispatch(hideModal({ dialog: DELETE_TC_DIALOG })),
    handleDelete: () => dispatch(deleteTimecard()),
  };
};
const DeleteTimecard = props => {
  const { loading, onCloseModal, handleDelete } = props;
  return (
    <Box>
      {loading && <LinearProgress />}
      <TitleBox>
        <Typography variant="h5">Delete Timecard</Typography>
      </TitleBox>
      <ContentBox sx={{ m: '30px 20px' }}>
        <Typography sx={{ fontSize: '18px' }}>
          Are you sure you want to delete this timecard?
        </Typography>
      </ContentBox>
      <FooterBox sx={{ mb: '20px' }}>
        <FooterButton
          variant="outlined"
          onClick={onCloseModal}
          disabled={loading}
        >
          No, Go Back
        </FooterButton>
        <FooterButton
          className="PENDO_delete_timecard"
          onClick={handleDelete}
          disabled={loading}
        >
          Yes, Delete
        </FooterButton>
      </FooterBox>
    </Box>
  );
};

DeleteTimecard.propTypes = {
  loading: PropTypes.bool,
  onCloseModal: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default compose(
  connect(mapState, mapDispatch),
  withModalDialog({
    dialog: DELETE_TC_DIALOG,
    maxWidth: 'md',
    roundedCorners: true,
  }),
)(DeleteTimecard);
