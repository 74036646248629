import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, InputAdornment } from '@mui/material';
// import ErrorIcon from '@mui/icons-material/Error';
import { DECIMAL_NUM_REGEX } from '../CrewListUtils';

const PercentageCell = props => {
  const {
    disabled,
    baseOnFocus,
    baseOnChange,
    baseOnBlur,
    localVal,
    // error,
    id,
    originalValue,
    field,
    setLocalVal,
    meta,
  } = props;

  useEffect(() => {
    if (originalValue === field && meta.pristine) {
      setLocalVal(originalValue);
    }
  }, [originalValue, field, setLocalVal, meta.pristine]);

  const onChange = e => {
    const t = e.target.value;
    // ignore any imput thats not a number or decimal
    if (t.match(DECIMAL_NUM_REGEX) === null) {
      if (t === '') {
        baseOnChange(t);
      } else if (localVal) baseOnChange(localVal);
    } else {
      if (t.includes('.')) {
        const numSplit = t.split('.');
        // do not allow a number greater than 100 before the decimal
        if (
          numSplit[0].length > 3 ||
          (numSplit[0].length === 3 && +numSplit[0] > 100)
        ) {
          baseOnChange(localVal);
        } else if (numSplit[0].length === 3 && +numSplit[0] === 100) {
          // convert 100.XX to 100
          baseOnChange('100');
        } else {
          // limit to 2 decimal points
          baseOnChange(
            t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 3),
          );
        }
      } else {
        // do not allow a number longer than 3 digits or greater than 100
        if (t.length > 3 || +t > 100) {
          baseOnChange(localVal);
        } else {
          baseOnChange(t);
        }
      }
    }
  };

  return (
    <div>
      <Input
        id={id}
        type="text"
        disabled={disabled}
        value={localVal || ''}
        // error={!!errorMsg}
        variant="outlined"
        inputProps={{ autoComplete: 'off' }}
        endAdornment={<InputAdornment position="end">%</InputAdornment>}
        onFocus={baseOnFocus}
        onChange={onChange} //normalize handled here
        onBlur={() => {
          // toggleCTA(false);
          baseOnBlur();
        }}
      />
    </div>
  );
};

PercentageCell.propTypes = {
  baseOnFocus: PropTypes.func.isRequired,
  baseOnChange: PropTypes.func.isRequired,
  baseOnBlur: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  originalValue: PropTypes.number,
  field: PropTypes.number,
  setLocalVal: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
};

export default PercentageCell;
