import PropTypes from 'prop-types';

export const commentStateProps = PropTypes.oneOfType([
  PropTypes.shape({ close: PropTypes.func, focus: PropTypes.func }),
  PropTypes.oneOf(['closed', 'modal']),
]);

export const invoiceStatusProp = PropTypes.oneOf([
  'N',
  'R',
  'E',
  'W',
  'Q',
  'C',
  'O',
  'L',
  'M',
  'F',
  'A',
  '',
]);

export const deliveryMethodProp = PropTypes.shape({
  code: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  formLabel: PropTypes.string,
  notePlaceholder: PropTypes.string,
  fileHelper: PropTypes.string,
  sendToQ: PropTypes.bool,
});
