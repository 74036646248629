import React, { useRef, useState } from 'react';
import { Button, TextField } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

//constats
import { INPUT_FIELD_CHARACTER_LIMIT } from 'components/Shared/constants';

const useStyles = makeStyles(theme => ({
  selTimecardsText: {
    marginLeft: '10px',
    marginBottom: '10px',
  },
  button: {
    marginRight: 8,
    whiteSpace: 'nowrap',
  },

  commentsLabelText: {
    marginLeft: '10px',
    marginBottom: '5px',
    fontWeight: 500,
  },
  commentField: {
    marginTop: '0px !important',
    marginLeft: '10px',
    resize: 'vertical',
    width: '230px',
    '&>div': {
      marginLeft: '10px',
    },
    '&>p': {
      marginLeft: '10px',
    },
    '&>div:before': {
      borderBottom: 'none !important',
    },
    '&>div:after': {
      borderBottom: 'none !important',
    },
    '&>div:hover': {
      borderBottom: 'none !important',
    },
    '&>div>input': {
      border: 'solid 1px gray',
      padding: '5px',
      paddingRight: '45px',
      paddingBottom: '60px',
      overflow: 'auto',
    },
  },
  errorText: {
    marginLeft: '10px',
    fontSize: '11px',
    color: 'red',
  },
}));
const commentsLimit = INPUT_FIELD_CHARACTER_LIMIT.comments;

const ConfirmApproveReject = props => {
  const { bulkAction, resetBulkAction, selectedTCS, handleBulkAction } = props;
  const classes = useStyles();
  const [note, setNote] = useState('');

  const noteTouchedRef = useRef(false);
  const noteTouchedAndEmpty = !note && noteTouchedRef.current;

  const isRejection = bulkAction === 'reject';

  const confirmText = isRejection ? 'Confirm rejection' : 'Confirm approval';

  let confirmDisabled = selectedTCS.length === 0;
  if (isRejection) {
    confirmDisabled = confirmDisabled || !note;
  }

  return (
    <div>
      {isRejection && (
        <section>
          <div className={classes.commentsLabelText}>
            Please add a rejection comment
          </div>
          <TextField
            className={classes.commentField}
            minRows="5"
            placeholder={'Rejection comment required'}
            margin="normal"
            value={note}
            onChange={e => setNote(e.target.value)}
            onFocus={() => (noteTouchedRef.current = true)}
            maxLength={commentsLimit}
          />
          {noteTouchedAndEmpty && (
            <span className={classes.errorText}>
              Rejection comment required
            </span>
          )}
        </section>
      )}
      <div className={classes.selTimecardsText}>
        {selectedTCS.length} Timecards selected
      </div>
      <div style={{ display: 'flex' }}>
        <div>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={resetBulkAction}
          >
            Cancel
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              handleBulkAction(selectedTCS, bulkAction, note);
              resetBulkAction();
            }}
            disabled={confirmDisabled}
          >
            {confirmText}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ConfirmApproveReject;
