import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import clsx from 'clsx';
import { Autocomplete, Popper, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { AutoCompTextField } from '../styledComponents';

import { db } from '../../empTimecardUtils';

const useStyles = makeStyles(({ palette }) => ({
  errorTextField: {
    backgroundColor: palette.misc.errorBackground,
  },
  inActiveDay: {
    backgroundColor: palette.gray['+8'],
  },
}));

//util functions
const optionHash = {};
// handles the case where 2 options have the same label
// identical labels causes weird render/filter issues
// only changes the label, not the value of the option
const hashOptions = (str, option = {}) => {
  if (!optionHash[str]) {
    optionHash[str] = [option.id];
  } else {
    let index = optionHash[str].findIndex(i => i === option.id);
    if (index === -1) {
      optionHash[str].push(option.id);
      index = optionHash[str].findIndex(i => i === option.id);
    }
    if (index) {
      str += ` (${index})`;
    }
  }
  return str;
};

const makeStrFromObj = (value, accessor) => {
  let str = '';
  if (value?.name) {
    str = value.name;
  } else if (value?.code) {
    str = value.code;
  }

  return str;
};

const getOptionLabel = ({ value, isDate, accessor }) => {
  if (typeof value === 'string') {
    if (isDate && value) {
      return moment(value, 'YYYY-MM-DD').format('MM-DD-YYYY');
    } else {
      return value;
    }
  }

  const str = makeStrFromObj(value, accessor);

  return hashOptions(str, value);
};

const isOptionEqualToValue = (o, v) => {
  if (typeof o === 'string') {
    return o === v;
  } else {
    return o.id === v.id;
  }
};

const canvasContext = document.createElement('canvas').getContext('2d');
canvasContext.font = 'normal 12pt Roboto';

// const getDynamicTextWidth = text => {
//   const metrics = canvasContext.measureText(text);
//   const totalWidth = metrics.width + 15; //Add to account for arrow icon
//   return totalWidth;
// };

const AutoPopper = props => {
  const { style } = props;
  const width = style?.width;
  if (width) {
    //Allow options popper to be wider than Autocomplete comp
    delete style.width;
  }
  return <Popper {...props} />;
};

AutoPopper.propTypes = {
  style: PropTypes.object,
};

/**
 *
 * @param {*} props
 * @returns
 */
const AutoV2 = props => {
  const classes = useStyles();
  let { options = [] } = props;
  const {
    input: { onChange, onFocus, onBlur, value, name },
    meta: { error },
    disabled,
    async,
    loadOptions,
    // minWidth = 70,
    // maxWidth = 225,
    label = '',
    isClearable = true,
    onOpen = () => {},
    onClose = () => {},
    stringValue = false, //is options a list of strings ? otherwise assume objs
    isDate = false,
    isMasterRow = false,
    pendoClass = '',
    loading,
    isDayActive,
    isTcEditable,
  } = props;

  const accessor = name.split('.')[1];

  if (!stringValue && !loading) {
    const isValueInOptions = options.find(o => o.id === value?.id);
    if (!isValueInOptions && value?.id) {
      options = options.slice();
      options.unshift(_.cloneDeep(value));
    }
  }
  const [textVal, setTextVal] = useState(
    getOptionLabel({ value, isDate, accessor }),
  );
  const highLightedRef = useRef(null);
  const popperOpenRef = useRef(false);

  const isDisabled = disabled || !isDayActive;

  //update display value if value changes
  useEffect(() => {
    setTextVal(getOptionLabel({ value, isDate, accessor }));
  }, [accessor, isDate, value]);

  // fire onChange event even if they click on the same value for masterRow
  const componentsProps = isMasterRow
    ? {
        paper: {
          onClick: e => {
            const clickedIndex = e.target.getAttribute('data-option-index');
            const newValue = options[clickedIndex];
            if (newValue?.id && newValue.id === value?.id) {
              onChange(newValue);
            }
          },
        },
      }
    : {};

  if (!isTcEditable) {
    return <Box>{makeStrFromObj(value, accessor)}</Box>;
  }

  return (
    <Autocomplete
      value={value || null}
      id={`autocomplete-tc-${name}`}
      renderInput={params => {
        params.inputProps.value = textVal;
        params.inputProps.className = pendoClass;
        // let dynamicWidth = getDynamicTextWidth(textVal);
        // if (dynamicWidth < 30) dynamicWidth = '100%';

        return (
          <AutoCompTextField
            className={clsx({
              [classes.errorTextField]: !!error,

              [classes.inActiveDay]: !isDayActive,
            })}
            label={label}
            variant="outlined"
            // sx={{
            //   minWidth,
            //   width: dynamicWidth,
            //   maxWidth,
            //   // width: `max(calc(100%),${dynamicWidth}px)`,
            //   // width: '100%', need input width to be set on the largest one and the rest will follow suit
            // }}
            error={!!error}
            onFocus={e => {
              e.target.select();
            }}
            placeholder=""
            onChange={e => {
              setTextVal(e.target.value);
              if (async) loadOptions(e.target.value);
            }}
            {...params}
          />
        );
      }}
      loading={loading}
      openOnFocus={false}
      disabled={isDisabled}
      options={options}
      autoHighlight={true}
      componentsProps={componentsProps}
      PopperComponent={AutoPopper}
      getOptionLabel={value => getOptionLabel({ value, isDate, accessor })}
      isOptionEqualToValue={isOptionEqualToValue}
      onOpen={() => {
        popperOpenRef.current = true;
        db('open', name);
        onOpen();
      }}
      onClose={() => {
        popperOpenRef.current = false;
        db('close', name);
        onClose();
      }}
      onChange={(e, newValue) => {
        if (isOptionEqualToValue(value, newValue)) {
          //skip if value is not changing
          return;
        }
        onChange(newValue);
        setTextVal(getOptionLabel({ value: newValue, isDate, accessor }));
      }}
      onFocus={() => onFocus()}
      onBlur={() => {
        setTextVal(getOptionLabel({ value, isDate, accessor }));
        onBlur();
      }}
      onHighlightChange={(e, option) => {
        highLightedRef.current = option;
      }}
      onKeyDown={e => {
        if (e.key === 'Backspace' && isClearable) {
          if (textVal === getOptionLabel({ value, isDate, accessor })) {
            onChange(null);
            setTextVal('');
          }
        } else if (e.key === 'Tab') {
          if (popperOpenRef.current) {
            onChange(highLightedRef.current);
          }
        }
      }}
    />
  );
};

AutoV2.propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
  }).isRequired,
  error: PropTypes.bool,
  isDayActive: PropTypes.bool.isRequired,
  isTcEditable: PropTypes.bool.isRequired,
  async: PropTypes.bool,
  onOpen: PropTypes.func, //should be memoized/useCallback'ed
  onClose: PropTypes.func,
  stringValue: PropTypes.bool,
  isDate: PropTypes.bool,
  isMasterRow: PropTypes.bool,
  pendoClass: PropTypes.string,
  isClearable: PropTypes.bool,
  loadOptions: PropTypes.func,
  label: PropTypes.string,
  loading: PropTypes.bool,
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
};

export default React.memo(AutoV2);
