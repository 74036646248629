import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

// actions
import { fetch as fetchUserInfo } from 'actions/userInfo';
import { storeProject } from 'actions/routeParams';

import { useDidMount } from 'utils/customHooks';

const mapDispatchToProps = dispatch => ({
  onFetchUserInfo: projectId => {
    dispatch(storeProject({ projectId }));
    dispatch(fetchUserInfo());
  },
});

// Sets projectId from URL and makes initial userInfo call
// Usually projectId is set in ProfileHeader or ProjectHeader and
// AlertHeader makes the userInfo call

const BlankHeader = ({ onFetchUserInfo }) => {
  const { projectId } = useParams();

  useDidMount(() => {
    onFetchUserInfo(projectId);
  });

  return null;
};

export default connect(null, mapDispatchToProps)(BlankHeader);
