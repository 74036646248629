import PropTypes from 'prop-types';

export const cityProps = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

export const citiesProps = PropTypes.arrayOf(cityProps);

export const stateProps = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  cities: citiesProps,
});

export const statesProps = PropTypes.arrayOf(stateProps);
