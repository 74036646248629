import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';

const BaseTimecard = ({ className }) => {
  const theme = useTheme();
  const { palette } = theme;

  const primary = palette.primary.main;

  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.994 9C13.682 9 11 11.688 11 15C11 18.312 13.682 21 16.994 21C20.312 21 23 18.312 23 15C23 11.688 20.312 9 16.994 9ZM17 19.8C14.348 19.8 12.2 17.652 12.2 15C12.2 12.348 14.348 10.2 17 10.2C19.652 10.2 21.8 12.348 21.8 15C21.8 17.652 19.652 19.8 17 19.8Z"
        fill={`${primary}`}
      />
      <path
        d="M16.75 13H16V15.623L18.625 17L19 16.4623L16.75 15.2951V13Z"
        fill={`${primary}`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 4.98V8H21V4.97H3V19.02H11V21H3C1.9 21 1 20.1 1 19V4.98C1 3.88 1.9 3 3 3H21C22.1 3 23 3.88 23 4.98Z"
        fill={`${primary}`}
      />
      <path
        d="M4 12H6V10H4V12ZM4 16H6V14H4V16ZM4 8H6V6H4V8ZM8 12H11V10H8V12ZM8 16H10V14H8V16ZM8 6V8H19V6H8Z"
        fill={`${primary}`}
      />
    </svg>
  );
};

BaseTimecard.propTypes = { className: PropTypes.string };

export default BaseTimecard;
