import PropTypes from 'prop-types';

export const departmentHeadProps = PropTypes.shape({
  id: PropTypes.number,
  canSubmitEmergencyTimecard: PropTypes.bool,
});

export const departmentHeadsProps = PropTypes.arrayOf(departmentHeadProps);

export const departmentProps = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  associationId: PropTypes.number,
  masterId: PropTypes.number,
  batchTemplateId: PropTypes.number,
  headApprovalEnabled: PropTypes.bool,
  rejectionWorkflow: PropTypes.string,
  reviewFlowId: PropTypes.number,
  heads: PropTypes.arrayOf(departmentHeadProps),
});

export const departmentsProps = PropTypes.arrayOf(departmentProps);

export const masterDepartmentProps = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

export const masterDepartmentsProps = PropTypes.arrayOf(masterDepartmentProps);

export const existingDepartmentsProps = PropTypes.arrayOf(departmentProps);
