import React, { useRef, useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { reduxForm } from 'redux-form';
import { compose } from 'utils/helperFunctions';
import { connect, batch } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { push } from 'redux-first-history';
import BulkEditContext from './BulkEditContext';
import EmptyState from '../Shared/EmptyState';
import LoadingSquare from '../Shared/LoadingSquare';

import { getProjectFromURL } from 'utils/helperFunctions';
import { setFromURL } from 'actions/timecards';
import {
  TableColumns,
  // BULK_EDIT_TIME_FIELDS,
  processSettings,
} from 'utils/bulkEditUtils';

// actions
// import { fetchReviewBatchesOnly } from 'actions/reviews';
import {
  clearEditedDays,
  clearEdits,
  fetch,
  setLastSaveAfterFetch,
  // fetchWeekEndings,
  fetchAccountCodes,
  fetchBatches,
  fetchDepartments,
  fetchEmployeeNames,
  fetchStatuses,
  fetchWeekdays,
  fetchWECount,
  fetchEpisodes,
  fetchSets,
  initLoad,
  storeWeekEnding,
  clearFilter,
  preFetchDropdown,
  save,
  calculate,
  setProspectiveTimecard,
  storeEditedDays,
  storeEdits,
  storeSortOrder,
  resetDirtyFields,
  resetFilterSelected,
  fetchSelectedCount,
} from 'actions/bulkEdit';
import { hide as hideModal } from 'actions/modalDialog';
import { show as showModal } from 'actions/modalDialog';

//utils
import { parseTimecards } from 'utils/bulkEditUtils';

// selectors
import { reviewFromURL, getOpenReviewBatches } from 'selectors/reviews';
import {
  // getTimecardDays,
  getInitBatchWeek,
  // getLoading,
  getWeekEnding,
  filteredDays,
  getWorkSchedule,
  getEpisode,
  getSaving,
  getSearching,
  getCalculating,
  isDirty,
  getSortOrder,
  getEditedDays,
  getInitLoad,
  getTcToBeCalculated,
  getTimecardCount,
  // getSelectedCount,
  // getFilterSelected,
  // getUserClickedSave,
  // getWECount,
  // getResultWE,
} from 'selectors/bulkEdit';
import { getSettings } from 'selectors/settings';
import { getWeekendings } from 'selectors/timecards';

import { getCurrentProject } from 'selectors/project';

import Header from 'components/Employees/Reviews/BulkEdit/Header';
import Sidebar from 'components/Employees/Reviews/BulkEdit/Sidebar';
import BulkEditTable from './BulkEditTable';
import ConfirmTakeMeBack from './Modals/ConfirmTakeMeBack';

// utils
// import { timecards } from 'utils/bulkEditUtils';
// import { getWeek } from 'date-fns';

const style = theme => ({
  root: {},
  title: {
    fontSize: '4em',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  main: {
    width: 'calc(100% - 300px)',
    height: 'calc(100vh - 200px)',
  },
  mainCollapsedSideBar: {
    width: 'calc(100% - 50px)',
  },
  sideBar: {
    height: 'calc(100vh - 200px)',
    display: 'flex',
    position: 'sticky',
  },
  loadingContainer: {
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 200,
    fontStyle: 'normal',
    textAlign: 'center',
  },
});
const formName = 'bulkEdit';

const mapState = (state, { match }) => {
  return {
    batches: getOpenReviewBatches(state),
    currentProject: getCurrentProject(state),

    // days: getTimecardDays(state),
    days: filteredDays(state),
    tcCount: getTimecardCount(state),
    episode: getEpisode(state),
    editedDays: getEditedDays(state),
    // filteredDays: filteredDays(state),
    fromURI: reviewFromURL(match.params.reviewType)(match.params.reviewBatchId)(
      state,
    ),
    initLoad: getInitLoad(state),
    initialValues: { ...getInitBatchWeek(state) },
    // loading: getLoading(state),
    reviewBatchId: match.params.reviewBatchId,
    saving: getSaving(state),
    searching: getSearching(state),
    // selectedCount: getSelectedCount(state),
    settings: getSettings(state),
    storedSortOrder: getSortOrder(state),
    calculating: getCalculating(state),
    weekEndings: getWeekendings(state),
    weekEnding: getWeekEnding(state),
    schedule: getWorkSchedule(state),
    isDirty: isDirty(state),
    tcToBeCaluclated: getTcToBeCalculated(state),
    // weekEndingCount: getWECount(state),
    // resultWeekEnding: getResultWE(state),
    // filterSelected: getFilterSelected(state),
    // userClickedSave: getUserClickedSave(state),
  };
};

const mapDispatch = (dispatch, { location, history }) => ({
  onFetch: () => {
    dispatch(fetch({}));
    dispatch(setLastSaveAfterFetch({ clickSave: false }));
  },
  onFetchLoadData: () =>
    batch(() => {
      dispatch(initLoad());
      dispatch(preFetchDropdown({ dropdownType: 'schedule' }));
      dispatch(preFetchDropdown({ dropdownType: 'episode' }));
    }),

  onSave: () => {
    dispatch(save());
    // dispatch(setLastSaveAfterFetch({ clickSave: true }));
  },
  onCalc: () => {
    dispatch(calculate());
    dispatch(hideModal({ dialog: 'ConfirmCalculate' }));
  },
  onWeekendingChange: week => {
    dispatch(storeWeekEnding({ weekEnding: week }));
    dispatch(clearFilter({ filterName: 'batches' }));
    // dispatch(fetch({ week }));
    // or maybe we clear the batches here.
    // do we save a cpoy for reverting or not?
    dispatch(fetchBatches({ weekEnding: week }));
    dispatch(fetchStatuses());
    dispatch(fetchEpisodes());
    dispatch(fetchDepartments());
    dispatch(fetchAccountCodes());
    dispatch(fetchWeekdays());
    dispatch(fetchSets());
    dispatch(fetchEmployeeNames());
    dispatch(fetchSelectedCount({ week: week }));
    dispatch(fetchWECount({ weekEnding: week }));
    dispatch(resetFilterSelected());
  },
  showClearChangesModal: () => {
    dispatch(showModal({ dialog: 'ConfirmClear' }));
  },
  hideClearChangesModal: () => {
    dispatch(hideModal({ dialog: 'ConfirmClear' }));
  },
  showCalculateModal: () => {
    dispatch(showModal({ dialog: 'ConfirmCalculate' }));
  },
  hideCalculateModal: () => {},
  onBackToList: () => {
    history.goBack();
  },
  onStoreSort: sortOrder => {
    dispatch(storeSortOrder({ sortOrder }));
  },
  onResetDirtyField: () => {
    dispatch(resetDirtyFields());
    dispatch(clearEdits());
    dispatch(clearEditedDays());
  },
  onEditTimeCard: (editedTCs, edits) => {
    dispatch(storeEditedDays({ editedDays: editedTCs }));
    dispatch(storeEdits({ edits: edits }));
  },
  onNavToWTC: (timecardEntryHeaderId, batchWorksightId, batchHtgId) => {
    const projectId = getProjectFromURL(location.pathname);
    dispatch(setFromURL({ fromURI: location.pathname }));

    const toURL = `/projects/${projectId}/review/ready/${batchWorksightId}/wtc?timecardHeaderId=${timecardEntryHeaderId}`;
    dispatch(push(toURL));
  },
  onShowDirtyAlert: prospectiveTimecard => {
    dispatch(setProspectiveTimecard({ prospectiveTimecard }));
    dispatch(showModal({ dialog: 'TakeMeBackBulkEdit', maxWidth: 'md' }));
  },
});

const BulkEdit = props => {
  const {
    classes,
    //data
    batches,
    currentProject,
    days = [],
    tcCount,
    reviewBatchId,
    weekEnding,
    weekEndings,
    settings,
    tcToBeCaluclated,
    // weekEndingCount,
    // selectedCount,
    // resultWeekEnding,
    //functions
    change,
    dispatch,
    match,
    //dispatch functions
    showClearChangesModal,
    hideClearChangesModal,
    showCalculateModal,
    onBackToList,
    onFetch,
    onNavToWTC,
    onFetchLoadData,
    onSave,
    onCalc,
    onWeekendingChange,
    onResetDirtyField,
    onShowDirtyAlert,
    //flags
    saving,
    calculating,
    isDirty,
    isCalculated,
    searching,
    initLoad,
    // filterSelected,
    // userClickedSave,
    storedSortOrder,
    onStoreSort,
    onEditTimeCard,
  } = props;

  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current === false) {
      onFetchLoadData();
      didMountRef.current = true;
    }
  }, [onFetchLoadData]);

  const willUnmountRef = useRef(false);
  useEffect(() => {
    return () => {
      willUnmountRef.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (willUnmountRef.current === true) {
        dispatch(setLastSaveAfterFetch({ clickSave: false }));
      }
    };
  }, [dispatch]);

  const [collapsed, setCollapsed] = useState(false);

  // const activeDays = undefined;
  const accountMask = currentProject.accountMask;
  // const [updatedCells, setUpdatedCells] = useState({});
  const [tcRows, setTcRows] = useState([]);
  const [allEditableRows, setEditableRows] = useState(0);
  const { Provider } = BulkEditContext;
  // const methods = useForm();

  //for Canada region setting 'NDB In' to 'General Call'
  if (currentProject.region === 'Canada') {
    TableColumns.map(column => {
      if (column.accessor === 'ndbIn') {
        column.Header = 'General Call';
      }
      return column;
    });
  }
  const tableColumns = useMemo(
    () => processSettings(TableColumns, settings),
    [settings],
  );

  const editableTimefields = useMemo(() => {
    if (settings && settings.wtcEditable) {
      return Object.entries(settings.wtcEditable)
        .filter(field => field[1] === true)
        .map(field => field[0]);
    } else {
      return [];
    }
  }, [settings]);

  const clearChanges = userClickedSave => {
    if (userClickedSave) {
      hideClearChangesModal();
      onFetch();
    } else {
      const { rows } = parseTimecards(days, accountMask, editableTimefields);
      setTcRows(rows);
      onResetDirtyField();
      hideClearChangesModal();
    }
  };

  useEffect(() => {
    const { rows, editableRowsCounts = 0 } = parseTimecards(
      days,
      accountMask,
      editableTimefields,
    );
    setTcRows(rows);
    setEditableRows(editableRowsCounts);
    onResetDirtyField();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days, editableTimefields]);

  const loadTimecard = (
    timecardEntryHeaderId,
    batchWorksightId,
    batchHtgId,
  ) => {
    if (isDirty) {
      onShowDirtyAlert({
        timecardEntryHeaderId,
        batchWorksightId,
        batchHtgId,
        actionType: 'loadTimecard',
      });
    } else {
      onNavToWTC(timecardEntryHeaderId, batchWorksightId, batchHtgId);
    }
  };

  const onBulkLeave = () => {
    if (isDirty) {
      onShowDirtyAlert({
        actionType: 'backToList',
      });
    } else {
      onBackToList();
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.sideBar}>
        <Sidebar
          onFetch={onFetch}
          saving={saving}
          searching={searching}
          isDirty={isDirty}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      </div>
      <div
        className={clsx({
          [classes.main]: !collapsed,
          [classes.mainCollapsedSideBar]: collapsed,
        })}
      >
        <Provider
          value={{
            accountMask,
            onNavToWTC,
            loadTimecard,
          }}
        >
          <form>
            <Header
              //data
              batches={batches}
              reviewBatchId={reviewBatchId}
              weekEndings={weekEndings}
              weekEnding={weekEnding}
              tcCount={tcCount}
              //functions
              clearChanges={clearChanges}
              change={change}
              match={match}
              onBulkLeave={() => onBulkLeave()}
              onFetch={onFetch}
              onSave={onSave}
              onCalc={onCalc}
              onWeekendingChange={onWeekendingChange}
              showClearChangesModal={showClearChangesModal}
              showCalculateModal={showCalculateModal}
              //flags
              saving={saving}
              // userClickedSave={userClickedSave}
              calculating={calculating}
              isDirty={isDirty}
              isCalculated={isCalculated}
              tcToBeCaluclated={tcToBeCaluclated}
            />

            {initLoad ? (
              <EmptyState />
            ) : searching ? (
              <LoadingSquare />
            ) : (
              <BulkEditTable
                allEditableRows={allEditableRows}
                tcRows={tcRows}
                tcCount={tcCount}
                currentProject={currentProject}
                saving={saving}
                editableTimefields={editableTimefields}
                tableColumns={tableColumns}
                storedSortOrder={storedSortOrder}
                onStoreSort={onStoreSort}
                onEditTimeCard={onEditTimeCard}
              />
            )}
          </form>
          <ConfirmTakeMeBack
            hasSubmitAction
            onBackToList={onBackToList}
            onNavToWTC={onNavToWTC}
          />
        </Provider>
      </div>
    </div>
  );
};

export default compose(
  connect(mapState, mapDispatch),
  withStyles(style),

  reduxForm({
    form: formName,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnChange: true,
    // validate,
    shouldError: () => true,
  }),
)(BulkEdit);
