import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import { Box, Typography, LinearProgress } from '@mui/material';

import withModalDialog from 'decorators/withModalDialog';

import { hide as hideModal } from 'actions/modalDialog';
import { deleteTimecardAllowance } from '../actions';

import * as sel from '../selectors';
import { getModalParams } from 'selectors/modalDialog';
import {
  ContentBox,
  FooterBox,
  FooterButton,
  TitleBox,
} from '../Shared/styledComponents';

import { DELETE_TC_ALLOWANCE_DIALOG } from '../Modals/modalNames';

const mapState = state => ({
  loading: sel.getLoading(state, 'allowanceDelete'),
  modalParams: getModalParams(state, DELETE_TC_ALLOWANCE_DIALOG),
});
const mapDispatch = dispatch => {
  return {
    onCloseModal: () =>
      dispatch(hideModal({ dialog: DELETE_TC_ALLOWANCE_DIALOG })),
    onDelete: worksightId => {
      dispatch(deleteTimecardAllowance({ worksightId }));
    },
  };
};
const DeleteTimecardAllowance = props => {
  const { loading, onCloseModal, onDelete, modalParams } = props;
  const { worksightId } = modalParams;

  return (
    <Box>
      {loading && <LinearProgress />}
      <TitleBox>
        <Typography variant="h5">Delete Allowance</Typography>
      </TitleBox>
      <ContentBox sx={{ m: '30px 20px' }}>
        <Typography sx={{ fontSize: '18px' }}>
          {'Are you sure you want to delete this allowance from the timecard?'}
        </Typography>
      </ContentBox>
      <FooterBox sx={{ mb: '20px' }}>
        <FooterButton variant="outlined" onClick={onCloseModal}>
          No, Go Back
        </FooterButton>
        <FooterButton
          className="PENDO_delete_allowance"
          onClick={() => onDelete(worksightId)}
          disabled={loading}
        >
          Yes, Delete
        </FooterButton>
      </FooterBox>
    </Box>
  );
};

DeleteTimecardAllowance.propTypes = {
  loading: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  modalParams: PropTypes.object.isRequired,
};

export default compose(
  connect(mapState, mapDispatch),
  withModalDialog({
    dialog: DELETE_TC_ALLOWANCE_DIALOG,
    maxWidth: 'md',
    roundedCorners: true,
  }),
)(DeleteTimecardAllowance);
