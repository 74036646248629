import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography, IconButton } from '@mui/material';
import { FaUserAlt as UserIcon, FaUsers as GroupIcon } from 'react-icons/fa';
import { withStyleSheet } from 'shared/theme';
import {
  Card,
  CardHeaderPlain as CardHeader,
  CardContent,
} from 'components/Shared/Card';
import ApprovalStep from './ApprovalStep';
import DeleteIcon from '@mui/icons-material/Delete';

const style = ({ palette }) => ({
  root: {},
  header: {},
  content: {
    overflow: 'auto',
    padding: 0,
    '&:last-child': { paddingBottom: 0 },
  },
  tableBody: {
    padding: 0,
  },
  '@keyframes tc-approved': {
    from: { opacity: 1, backgroundColor: palette.primary.main },
    to: { opacity: 0, backgroundColor: palette.primary.main }, //hardCoded Colors
  },
  flash: {
    animation: '$tc-approved 2s 3',
    animationDuration: '250ms',
    animationDelay: '200ms',
  },
  actionButton: { width: '35%', fontSize: 20 },
});

class ApprovalFlow extends Component {
  static propTypes = {
    approvalFlows: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    departmentHeadApproval: PropTypes.bool,
    onRemoveFlow: PropTypes.func,
  };

  static defaultProps = {
    departmentHeadApproval: false,
    steps: undefined,
    onRemoveFlow: undefined,
    onEditStep: undefined,
    onRemoveUser: undefined,
  };

  renderApprovalFlow() {
    const { classes, departmentHeadApproval, approvalFlows } = this.props;

    return (
      <div className={classes.tableBody}>
        <ApprovalStep key="emp" icon={UserIcon} label="Employee" />
        {departmentHeadApproval && (
          <ApprovalStep key="dh" icon={GroupIcon} label="Department Head" />
        )}
        <div>{approvalFlows}</div>
      </div>
    );
  }

  render() {
    const { classes, onRemoveFlow, reviewFlowId, newFlowId } = this.props;

    let newFlash = false;
    if (newFlowId === reviewFlowId) {
      newFlash = true;
    }

    const removeFlow = (
      <IconButton onClick={onRemoveFlow}>
        <DeleteIcon color="primary" />
      </IconButton>
    );

    return (
      <Card className={clsx(classes.root, { [classes.flash]: newFlash })}>
        <CardHeader title buttons={removeFlow}>
          <Typography variant="h6" align="left">
            {'Approval Flow'}
          </Typography>
        </CardHeader>
        <CardContent className={classes.content}>
          {this.renderApprovalFlow()}
        </CardContent>
      </Card>
    );
  }
}

export default withStyleSheet('ApprovalFlow', style)(ApprovalFlow);
