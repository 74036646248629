import React, { useState } from 'react';

import { Button } from '@mui/material/';

import makeStyles from '@mui/styles/makeStyles';

//components
import withSearchTimecardsFilter from './withSearchTimecardsFilter';
import FilterDropdown from 'components/Shared/ReactTable/FilterDropdown';

const useStyles = makeStyles(({ palette }) => {
  return {
    root: {
      width: 273,
      marginLeft: 24,
    },
    controls: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 30,
    },
    filters: {
      width: 253,
      maxHeight: '67vh',
      overflowY: 'auto',
      overflowX: 'hidden',
      boxShadow: '0px 2px 8px 2px rgb(82 82 82 / 10%)',
    },
    filter: {
      width: 253,
      overflowX: 'hidden',
      borderBottom: `1px ${palette.primary['+7']} solid`,
      borderCollapse: 'collapse',
      '&:last-child': {
        borderBottom: 0,
      },
    },
    filterBtns: {
      color: palette.info.light,
      margin: 0,
      paddingLeft: 0,
    },
    title: {
      color: palette.gray.main,
      fontSize: 24,
    },
    subTitle: {
      color: palette.gray.main,
      fontSize: 14,
      marginBottom: 24,
      marginTop: 4,
    },
  };
});

const EmployeeFilter = withSearchTimecardsFilter({
  filterName: 'employee',
})(FilterDropdown);

const WeekendingFilter = withSearchTimecardsFilter({
  filterName: 'weekEndingDate',
})(FilterDropdown);

const DepartmentFilter = withSearchTimecardsFilter({
  filterName: 'department',
})(FilterDropdown);

const UnionFilter = withSearchTimecardsFilter({
  filterName: 'union',
})(FilterDropdown);

const InvoiceFilter = withSearchTimecardsFilter({
  filterName: 'invoice',
})(FilterDropdown);

const AccountCodeFilter = withSearchTimecardsFilter({
  filterName: 'accountCodeHeader',
})(FilterDropdown);

const BatchFilter = withSearchTimecardsFilter({
  filterName: 'batch',
})(FilterDropdown);

const SearchSidebar = props => {
  const { onClearFilters } = props;
  const classes = useStyles();
  const [expandAll, setExpansion] = useState(false);

  function toggleExpansion() {
    setExpansion(!expandAll);
  }

  return (
    <div className={classes.root}>
      <div>
        <section className={classes.title}>Refine your search</section>
        <section className={classes.subTitle}>
          Use the filters below to customize your search results.
        </section>
        <div className={classes.controls}>
          <Button
            variant="text"
            color="primary"
            className={classes.filterBtns}
            onClick={() => {
              setExpansion(false);
              onClearFilters();
            }}
          >
            <span>Clear filters</span>
          </Button>
          <Button
            variant="text"
            color="primary"
            className={classes.filterBtns}
            onClick={toggleExpansion}
          >
            <span>{expandAll ? 'Collapse' : 'Expand'} All</span>
          </Button>
        </div>
        <main className={classes.filters}>
          <section className={classes.filter}>
            <WeekendingFilter
              label={`Week Ending`}
              enableSearch
              canSelectAll
              expand={expandAll}
              variant={'searchTimecards'}
            />
          </section>
          <section className={classes.filter}>
            <DepartmentFilter
              label={`Department`}
              canSelectAll
              enableSearch
              expand={expandAll}
              variant={'searchTimecards'}
            />
          </section>
          <section className={classes.filter}>
            <EmployeeFilter
              label={`Employee`}
              enableSearch
              canSelectAll
              expand={expandAll}
              variant={'searchTimecards'}
            />
          </section>

          <section className={classes.filter}>
            <InvoiceFilter
              label={`Invoice`}
              canSelectAll
              enableSearch
              expand={expandAll}
              variant={'searchTimecards'}
            />
          </section>
          <section className={classes.filter}>
            <UnionFilter
              label={`Union`}
              canSelectAll
              enableSearch
              expand={expandAll}
              variant={'searchTimecards'}
            />
          </section>
          <section className={classes.filter}>
            <AccountCodeFilter
              label={`Account`}
              canSelectAll
              enableSearch
              expand={expandAll}
              variant={'searchTimecards'}
            />
          </section>
          <section className={classes.filter}>
            <BatchFilter
              label={`Batches`}
              canSelectAll
              enableSearch
              expand={expandAll}
              variant={'searchTimecards'}
            />
          </section>
        </main>
      </div>
    </div>
  );
};

export default SearchSidebar;
