import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import globalStyle from './globalStyle';

const useGlobalStyles = makeStyles(globalStyle);

const GlobalStyleWrapper = ({ children }) => {
  //Nothing special about this, just running the hook is enough
  useGlobalStyles();

  return <div>{children}</div>;
};

GlobalStyleWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalStyleWrapper;
