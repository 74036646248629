import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { LinearProgress } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import {
  getLoading,
  getWeekEnding,
  getSelectedCount,
  getFilterSelected,
  getWECount,
} from 'selectors/bulkEdit';

const style = ({ palette }) => ({
  TimecardCounts: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 15,
    height: 70,
  },
  sTotalContainer: {
    height: 34,
  },
  sTotal: {
    fontSize: 12,
    color: palette.button.yellow.color,
    textAlign: 'center',
    letterSpacing: '0.32px',
    width: 180,
    height: 24,
    borderRadius: 8,
    padding: '5px 10px',
  },
  sTotalUnder: {
    backgroundColor: palette.button.yellow.background,
  },
  sTotalOver: {
    color: palette.button.lightRed.color,
    backgroundColor: palette.button.lightRed.background,
  },
  weTotal: {
    width: 160,
    color: palette.gray['+3'],
    fontSize: 12,
    textAlign: 'center',
    letterSpacing: '0.32px',
  },
  progress: {
    width: 156,
  },
});

const mapState = state => ({
  loading: getLoading(state),
  selectedCount: getSelectedCount(state),
  weekEndingCount: getWECount(state),
  filterSelected: getFilterSelected(state),
  weekEnding: getWeekEnding(state),
});

const TimecardCounts = props => {
  const {
    classes,
    filterSelected,
    loading,
    selectedCount,
    weekEnding,
    weekEndingCount,
  } = props;

  const [showSCounter, setShowSCounter] = useState(false);

  useEffect(() => {
    if (filterSelected === true && loading === false) {
      setShowSCounter(true);
    } else {
      setShowSCounter(false);
    }
  }, [loading, filterSelected]);

  return (
    <div className={classes.TimecardCounts}>
      <div className={classes.sTotalContainer}>
        {showSCounter && (
          <span
            className={`${classes.sTotal} ${
              selectedCount <= 100 && false
                ? classes.sTotalUnder
                : classes.sTotalOver
            }`}
          >
            Selected {selectedCount} of 100 max.
          </span>
        )}
      </div>

      {true && (
        <div className={`${classes.weTotal}`}>
          {weekEndingCount} total timecards
          <br /> for week ending {weekEnding}
        </div>
      )}
      {loading && <LinearProgress className={classes.progress} />}
    </div>
  );
};

export default compose(connect(mapState), withStyles(style))(TimecardCounts);
