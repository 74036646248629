import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import DataCard, { DataCardWithControl } from './DataCard';

const useStyles = makeStyles(({ palette }) => ({
  taxColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
}));
const PERM_VIEW_TAX_CARDS = ['Others'];
const TaxDataCards = props => {
  const classes = useStyles();
  const { col1, col2, col3, other } = props;
  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Box className={classes.taxColumn}>
        {col1.map(tax => (
          <DataCardWithControl
            {...tax}
            key={tax.label}
            controlKey={tax.label}
          />
        ))}
      </Box>
      <Box className={classes.taxColumn}>
        {col2.map(tax => (
          <DataCardWithControl
            {...tax}
            key={tax.label}
            controlKey={tax.label}
          />
        ))}
      </Box>
      <Box className={classes.taxColumn}>
        {col3.map(tax => (
          <DataCardWithControl
            {...tax}
            key={tax.label}
            controlKey={tax.label}
          />
        ))}
      </Box>
      {other.length > 0 && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {other.map(tax =>
            PERM_VIEW_TAX_CARDS.includes(tax.label) ? (
              <DataCard {...tax} key={tax.label} />
            ) : (
              <DataCardWithControl
                {...tax}
                key={tax.label}
                controlKey={tax.label}
              />
            ),
          )}
        </Box>
      )}
    </Box>
  );
};

TaxDataCards.propTypes = {
  col1: PropTypes.array,
  col2: PropTypes.array,
  col3: PropTypes.array,
  other: PropTypes.array,
};

export default TaxDataCards;
