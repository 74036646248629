import React from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import { employeeProps, employeesProps } from 'components/props/employees';
import { HTGAutoCompleteAsync } from 'components/Shared/AutoComplete';

const style = () => ({
  root: {},
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > *': {
      width: '100%',
      marginBottom: 5,
      marginTop: 5,
    },
  },
  selectRow: {},
});

class EmployeeSelect extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    fieldClass: PropTypes.string,
    employees: employeesProps,
    name: PropTypes.string,
    selectEmp: employeeProps,
    onSelect: PropTypes.func,
    placeholder: PropTypes.string,
    plain: PropTypes.bool,
  };

  static defaultProps = {
    employees: [],
    name: 'employee',
    onSelect: undefined,
    placeholder: 'Employee',
    plain: false,
    fieldClass: '',
  };

  onSelect(value) {
    if (this.props.onSelect) {
      this.props.onSelect(value);
    }
  }

  render() {
    const { classes, employees, name, placeholder, label, ...others } =
      this.props;

    // require loadOptions for data refresh.
    const { loadOptions } = others;
    return (
      <div className={classes.row}>
        <HTGAutoCompleteAsync
          list={employees}
          name={name}
          label={label ? label : placeholder}
          placeholder={placeholder}
          fieldLabel="name"
          fieldValue="id"
          required
          /**
           * Enables reload when the list is empty.
           * If the value is null, we utilize load
           * options manually and pass in an empty
           * string to default the search.
           */
          onChange={value => {
            value ? this.onSelect(value) : loadOptions('');
          }}
          {...others}
        />
      </div>
    );
  }
}

export default withStyleSheet('EmployeeSelect', style)(EmployeeSelect);
