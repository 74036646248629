import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';

const style = ({ palette }) => ({
  root: {
    flex: 1,
    width: '100%',
    height: 'inherit',
    verticalAlign: 'middle',
    cursor: 'pointer',
    color: palette.gray['+9'],
    '& > *': {
      textAlign: 'center',
    },
  },
});

class NavBar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    children: undefined,
  };

  render() {
    const { classes, children } = this.props;

    return <div className={classes.root}>{children}</div>;
  }
}

export default withStyleSheet('NavBar', style)(NavBar);
