import { Tooltip, styled, Box } from '@mui/material';
import _ from 'lodash';
import { assertIsString } from 'utils/wtcWeekUtils';
import { isRegionCanada } from 'utils/helperFunctions';
import * as utils from './digitalEditsUtils'; //needed to mock internal functions
import debug from 'debug';
export const db = debug('digitalEdits');

//Constants
export const COMMENT_STATUS_MAP = {
  New: 0,
  Resolved: 1,
  Pending: 2,
  Submitted: 3,
  Unsubmitted: 4,
};

export const ALLOWANCE_TOTAL_ERROR = 'Allowance total does not match';

export const FILE_STATUS_MAP = {
  Uploaded: 0,
  // Submitted - anything not 0.
};

export const FILE_COMMENT_CATEGORY_MAP = {
  None: 0,
  InvoiceDelivery: 3, //approval modal - delivery method comment
};

export const INVOICE_STATUS_MAPPING = [
  {
    label: 'Unsubmitted',
    userFriendlyStatus: 'Draft',
    value: 'draft',
    countLabel: 'draft',
    code: ['N'],
    pillColor: 'red',
  },
  {
    label: 'Pending Re-Approval',
    userFriendlyStatus: 'Waiting Approval',
    value: 'waiting_approval',
    countLabel: 'waitingForApproval',
    code: ['N'],
    pillColor: 'red',
  },
  {
    label: 'Submitted to C&C',
    userFriendlyStatus: 'In Progress',
    value: 'in_progress',
    countLabel: 'inProgress',
    code: ['N'],
    pillColor: 'yellow',
  },
  {
    label: 'Processing',
    userFriendlyStatus: 'Processed',
    value: 'processed',
    countLabel: 'processed',
    code: ['N'],
    pillColor: 'yellow',
  },

  {
    label: 'Resubmitted',
    userFriendlyStatus: 'Resubmitted',
    value: 'resubmitted',
    countLabel: 'resubmitted',
    code: ['R'],
    pillColor: 'yellow',
  },
  {
    label: 'Edits',
    userFriendlyStatus: 'Edits',
    value: 'edits',
    countLabel: 'edits',
    code: ['E'],
    pillColor: 'lightGreen',
  },
  {
    label: 'Revisions',
    userFriendlyStatus: 'Revisions',
    value: 'revisions',
    countLabel: 'revisions',
    code: ['W'],
    pillColor: 'orange',
  },
  {
    label: 'C&C Review',
    userFriendlyStatus: 'C&C Review',
    value: 'cc_review',
    countLabel: 'ccReview',
    code: 'QCOLM'.split(),
    pillColor: 'yellow',
  },
  {
    label: 'Future Release',
    userFriendlyStatus: 'Future Release',
    value: 'future_release',
    countLabel: 'futureRelease',
    code: ['F'],
    pillColor: 'yellow',
  },
  {
    label: 'Invoice Approved',
    userFriendlyStatus: 'Invoice Approved',
    value: 'approved',
    countLabel: 'approved',
    code: ['A'],
    pillColor: 'yellow',
  },
  {
    label: 'Paid',
    userFriendlyStatus: 'Paid',
    value: 'paid',
    countLabel: 'paid',
    code: ['A'],
    pillColor: 'gray',
  },
  {
    label: 'Hold',
    userFriendlyStatus: 'Hold',
    value: 'hold',
    countLabel: 'hold',
    code: ['H'],
    pillColor: 'gray',
  },
];

export const NEW_ALLOWANCE_TAG = 'newAllowance';

export const HIDE_WHEN_EMPTY = ['department'];

export const CHARGE_DES_MAX = 30;
export const CHARGE_DES_MAX_STICKY = 16;

export const DATA_BOX_MAX_LEN = 20;

//Invoice Status groups
export const getPillInfo = userFriendlyStatus => {
  let pillInfo = INVOICE_STATUS_MAPPING.find(
    info => info.userFriendlyStatus === userFriendlyStatus,
  );

  return pillInfo || {};
};

export const POSSIBLE_INVOICE_STATUSES = ['W', 'R'];

export const CAN_APPROVE_STATUSES = ['W', 'E'];

const REQ_REVISIONS_DISABLED_STATUSES = ['N', 'R'];
const HIDE_RE_BTNS_STATUSES = ['Q', 'C', 'O', 'L', 'M', 'A', 'F', 'H'];

export const checkBtnStatus = invoiceStatus => {
  //Button disabled status calculations
  const revisionsDisabled =
    REQ_REVISIONS_DISABLED_STATUSES.includes(invoiceStatus);

  //hide resubmit and request revisions btns
  const showReBtns = HIDE_RE_BTNS_STATUSES.includes(invoiceStatus) === false;

  return { revisionsDisabled, showReBtns };
};

export const SORT_BYS = [
  {
    id: 0,
    field: 'employeeName',
    label: 'Name (asc)',
    sortOrder: 'ascSort',
  },
  {
    id: 1,
    field: 'employeeName',
    label: 'Name (desc)',
    sortOrder: 'decSort',
  },
  {
    id: 2,
    field: 'department',
    label: 'Department (asc)',
    sortOrder: 'ascSort',
  },
  {
    id: 3,
    field: 'department',
    label: 'Department (desc)',
    sortOrder: 'decSort',
  },
  {
    id: 4,
    field: 'dealMemoHeader.htgUnion',
    label: 'Union (asc)',
    sortOrder: 'ascSort',
  },
  {
    id: 5,
    field: 'dealMemoHeader.htgUnion',
    label: 'Union (desc)',
    sortOrder: 'decSort',
  },
  {
    id: 6,
    field: 'dealMemoHeader.wageAccount',
    label: 'Account Code (asc)',
    sortOrder: 'ascSort',
    // isNumber: true,
  },
  {
    id: 7,
    field: 'dealMemoHeader.wageAccount',
    label: 'Account Code (desc)',
    sortOrder: 'decSort',
    // isNumber: true,
  },
];

export const accountCodeSummaryHeaders = [
  { label: 'Account', accessor: 'accountCode' },
  { label: 'Epsd', accessor: 'episode' },
  { label: 'Ser', accessor: 'series' },
  { label: 'Loc', accessor: 'location' },
  { label: 'Set', accessor: 'set' },
  { label: 'Ins', accessor: 'insurance' },
  { label: 'FF1', accessor: 'freeField1' },
  { label: 'FF2', accessor: 'freeField2' },
  { label: 'FF3', accessor: 'freeField3' },
  { label: 'FF4', accessor: 'freeField4' },
  { label: 'Hours', accessor: 'units' },
  { label: 'Amount', accessor: 'amount', isCurrency: true },
];

export const RecordBreakdownHeaders = [
  {
    label: 'Date',
    accessor: 'earnedDate',
    isDate: true,
    width: 85,
  },
  { label: 'Deal', accessor: 'dealMemo', width: 51 },
  { label: 'Hours', accessor: 'units', width: 61 },
  { label: 'Pay Code', accessor: 'paycode', width: 85 },
  {
    label: 'Rate',
    accessor: 'baseRate',
    isCurrency: true,
    maxDecimal: 10,
    width: 85,
  },
  {
    label: 'Amount',
    accessor: 'amount',
    allowanceEditable: true,
    isCurrency: true,
    width: 90,
  },
  { label: 'Account', accessor: 'accountCode', editable: true, width: 84 },
  {
    label: 'Epsd',
    accessor: 'episode',
    type: 'auto-complete',
    editMinWidth: 85,
    editable: true,
    width: 60,
  },
  { label: 'Ser', accessor: 'series', editable: true, width: 50 },
  { label: 'Loc', accessor: 'location', editable: true, width: 50 },
  { label: 'Set', accessor: 'set', editable: true, width: 50 },
  { label: 'Ins', accessor: 'insurance', editable: true, width: 50 },
  { label: 'FF1', accessor: 'freeField1', editable: true, width: 50 },
  { label: 'FF2', accessor: 'freeField2', editable: true, width: 50 },
  { label: 'FF3', accessor: 'freeField3', editable: true, width: 50 },
  { label: 'FF4', accessor: 'freeField4', editable: true, width: 50 },
  { label: 'Occ', accessor: 'occupationCode', width: 65 },
  { label: 'WS', accessor: 'workschedule', width: 50 },
  {
    label: 'OL',
    accessor: 'onLocation',
    tooltip: 'On Location',
    width: 50,
  },
  { label: 'State', accessor: 'workState', width: 50 },
  {
    label: 'City',
    accessor: 'workCity',
    hideInCanada: true,
    width: 50,
  },
  {
    label: 'Timecard',
    accessor: 'ccTimecardNumber',
    width: 110,
  },
];

export const invoiceChargesHeaders = [
  { label: 'Pay Code', accessor: 'payCode' },
  { label: 'Description', accessor: 'description' },
  { label: 'Amount', accessor: 'amount', isCurrency: true },
  { label: 'Account', accessor: 'accountCode' },
  { label: 'Ser', accessor: 'seriesCode' },
  { label: 'Loc', accessor: 'locationCode' },
  { label: 'Set', accessor: 'setCode' },
  { label: 'Ins', accessor: 'insuranceCode' },
  { label: 'FF1', accessor: 'freeFieldCode1' },
  { label: 'FF2', accessor: 'freeFieldCode2' },
  { label: 'FF3', accessor: 'freeFieldCode3' },
  { label: 'FF4', accessor: 'freeFieldCode4' },
];

export const US_SELECTABLE_DELIVERY_METHODS = [7, 8, 9, 16, 25, 66]; //20 is a radio button option
export const CAN_SELECTABLE_DELIVERY_METHODS = [5, 7, 8, 9, 16, 66]; //20 is a radio button option

// "Special Handling" label is added to any methods with no label value.
export const DELIVERY_METHODS = [
  { de: 'N', code: 1, value: 'Manual Pre Edit' },
  { de: 'N', code: 2, value: "Pre Edit's" },
  { de: 'N', code: 3, value: 'Adjust. Invoices' },
  { de: 'N', code: 4, value: 'RESIDUALS 1' },
  {
    de: 'N',
    code: 5,
    value: 'Overnight Pckgng',
    label: 'Special Handling',
    labelCAN: 'In-Person Pickup',
    sendToQ: true,
    notePlaceholder:
      'Please provide any instructions regarding how you want your payroll delivered or transmitted',
    notePlaceholderCAN:
      'Provide when and who you expect to be picking up checks',
    fileHelper: 'Drag and drop files for delivery/transmission here',
    fileHelperCAN: 'Provide when and who you expect to be picking up checks',
  },
  { de: 'N', code: 6, value: "CROSSOVER'S ASAP" },
  {
    de: 'Y',
    code: 7,
    value: '*ASAP-FED EX*',
    label: 'Express Shipping',
    sendToQ: true,
    notePlaceholder: 'Add any additional notes here',
    fileHelper:
      'Upload your completed form here  OR  Upload your pre-filled shipper',
    formLabel: 'Express Form',
  },
  {
    de: 'Y',
    code: 8,
    value: '*ASAP-DLVRY*',
    label: 'Messenger',
    sendToQ: true,
    notePlaceholder: 'Add any additional notes here',
    fileHelper: 'Upload your completed messenger form here',
    formLabel: 'Messenger Form',
  },
  {
    de: 'Y',
    code: 9,
    value: '*ASAP-NOW*',
    label: 'Wire Transfer',
    sendToQ: true,
    notePlaceholder: 'Add any additional notes here',
    fileHelper: 'Upload your completed wire form here',
    formLabel: 'Wire Transfer Form',
  },
  { de: 'Y', code: 10, value: 'ASAP/Prnt Vncvr' },
  { de: 'Y', code: 11, value: 'ASAP/Prnt Toronto' },
  { de: 'Y', code: 12, value: 'ASAP/Prnt NY' },
  { de: 'Y', code: 13, value: 'BAD/HOLD', label: 'No Checks Printed' },
  { de: 'Y', code: 14, value: 'ASAP/PrntBurbank' },
  {
    de: 'Y',
    code: 16,
    value: 'SEAL Env-MAIL',
    label: 'USPS Mail', //Can be Canada Post or USPS depending on region
    labelCAN: 'Canada Post',
  },
  { de: 'Y', code: 17, value: 'ASAP/Prnt GA' },
  { de: 'N', code: 18, value: 'RESIDUALS 2' },
  { de: 'N', code: 19, value: '*Return by 4 PM*' },
  { de: 'Y', code: 20, value: 'ASAP On Site Cks', label: 'Print On-site' },
  { de: 'Y', code: 21, value: 'ASAP Print Laoff' },
  { de: 'N', code: 22, value: 'OMR STUFF ONLY' },
  { de: 'N', code: 23, value: 'OMR STUFF/SEAL' },
  { de: 'N', code: 24, value: 'OME SEAL/MAIL' },
  {
    de: 'Y',
    code: 25,
    value: 'ASAP/In Lobby',
    label: 'Pick Up in Burbank',
    sendToQ: true,
    notePlaceholder: 'Add any additional notes here',
    fileHelper: 'Provide when and who you expect to be picking up checks',
  },
  { de: 'N', code: 40, value: 'ASAP-No Stuff' },
  { de: 'N', code: 41, value: 'Ovrnght-No Stuff' },
  { de: 'N', code: 42, value: 'RESIDUALS 3' },
  { de: 'N', code: 43, value: 'OPS-RES ADJSTMNT' },
  { de: 'N', code: 44, value: 'RESIDUALS-RESADJ' },
  { de: 'N', code: 50, value: 'RESIDUALS 4' },
  { de: 'N', code: 54, value: 'SEAL-BACHELOR' },
  { de: 'N', code: 55, value: 'SEAL-JIMMY KIMML' },
  {
    de: 'Y',
    code: 66,
    value: 'OTHER',
    label: 'Other',
    sendToQ: true,
    notePlaceholder:
      'Please provide any instructions regarding how you want your payroll delivered or transmitted',
    fileHelper: 'Drag and drop files for delivery/transmission here',
  },
  { de: 'N', code: 86, value: 'RESIDUALS 5' },
  { de: 'N', code: 87, value: 'RESIDUALS 6' },
  { de: 'N', code: 88, value: 'RESIDUALS 7' },
  { de: 'N', code: 89, value: 'RESIDS-PRIORITY1' },
  { de: 'N', code: 90, value: 'RESIDS-PRIORITY2' },
  { de: 'N', code: 91, value: 'RESIDS-PRIORITY3' },
  { de: 'N', code: 95, value: 'DoNotMail-Spcial' },
  { de: 'N', code: 96, value: 'SEAL Env-RETURN' },
  { de: 'N', code: 97, value: 'ONSITE' },
  { de: 'N', code: 98, value: 'ONSITE' },
  { de: 'N', code: 99, value: 'SHRED CHCKS' },
];

export const DATE_TABLE = {
  Sunday: 'Su',
  Monday: 'M',
  Tuesday: 'T',
  Wednesday: 'W',
  Thursday: 'Th',
  Friday: 'F',
  Saturday: 'Sa',
};

//Helper Functions
export const makeTextFilter = textFilter => {
  let textSearchFilter = assertIsString(textFilter).toLowerCase();

  const func = record => {
    if (!textSearchFilter) return true;

    let { employee } = record;

    if (!employee) {
      employee = {};
    }
    let { name, code } = employee;

    name = assertIsString(name);
    const employeeSSN = assertIsString(code);

    const lastSsnDigits = employeeSSN?.replace(/[X-]/g, '') || '';

    return (
      name.toLowerCase().indexOf(textSearchFilter) >= 0 ||
      lastSsnDigits.indexOf(textSearchFilter) >= 0
    );
  };
  return func;
};

export const makePayCodeFilter = (sectionTitle, payCode) =>
  `${sectionTitle} - ${payCode}`;

export const makeOccCodeFilter = (occName, occCode) =>
  `${occCode} - ${occName}`;

export const makeTimecardsFilter = (
  activeFilters,
  errorsAndTimeouts,
  acEditRecord,
) => {
  const { department, union, payCode, recordProp, occCode } = activeFilters;

  const func = record => {
    const dept = record?.department;
    const timecardUnion = record?.dealMemoHeader?.htgUnion;
    const hasDept = !department.length || department.includes(dept);
    const hasStatus = !union.length || union.includes(timecardUnion);

    if (acEditRecord === record.recordId) return true;

    const headerIds = getTcHeadersFromRecordId(record.recordId);

    const hasErrOrTimeout = errorsAndTimeouts.some(err =>
      headerIds.includes(err),
    );

    let hasPayCode = true;
    let hasOccCode = true;
    if (payCode.length > 0) {
      for (let j = 0; j < record.taxes?.length; j++) {
        hasPayCode = false;
        const tax = record.taxes[j];

        const sectionTitle = tax.section;
        for (let i = 0; i < tax.details.length; i++) {
          const d = tax.details[i];
          const payCodeFilterText = makePayCodeFilter(sectionTitle, d.payCode);
          hasPayCode = payCode.includes(payCodeFilterText);

          if (hasPayCode) break;
        }

        if (hasPayCode) break;
      }
    }

    if (occCode.length > 0) {
      for (let i = 0; i < record.employeeOccupation.length; i++) {
        hasOccCode = false;
        const oc = record.employeeOccupation[i];
        const occCodeFilterText = makeOccCodeFilter(oc.name, oc.code);
        hasOccCode = occCode.includes(occCodeFilterText);
        if (hasOccCode) break;
      }
    }

    let hasRecordProp = true;
    if (recordProp.length > 0) {
      hasRecordProp = false;
      if (recordProp.includes('directDeposit') && record.directDeposit) {
        hasRecordProp = true;
      }

      if (recordProp.includes('largePayment') && record.largePayment) {
        hasRecordProp = true;
      }

      if (
        recordProp.includes('loanOut') &&
        record.employeeCorporation?.length > 0
      ) {
        hasRecordProp = true;
      }
      if (
        recordProp.includes('duplicateTimecard') &&
        record.duplicateTimecard
      ) {
        hasRecordProp = true;
      }
      if (recordProp.includes('isEdited') && record.isEdited) {
        hasRecordProp = true;
      }
      if (
        recordProp.includes('hasErrors') &&
        (record.hasErrors || hasErrOrTimeout)
      ) {
        hasRecordProp = true;
      }
    }

    return hasDept && hasStatus && hasPayCode && hasRecordProp && hasOccCode;
  };
  return func;
};

export const makeTaxesData = (data = [], columnMap) => {
  const taxes =
    data.map(tax => {
      const showUnits =
        tax.section === 'Earnings' || tax.section === 'Information Only';

      const data = tax.details.map(d => ({
        label: d.payCode,
        units: showUnits && (d.units === 0 ? ' ' : d.units),
        value: d.amount,
      }));

      const title = {
        label: tax.section,
        isHeader: true,
        units: showUnits && (tax.units === 0 ? ' ' : tax.units),
      };
      if (tax.total || tax.total === 0) title.value = tax.total;
      data.unshift(title);
      const taxData = { label: tax.section, data };
      if (columnMap[tax.section]) {
        taxData.position = columnMap[tax.section].position;
        taxData.column = columnMap[tax.section].column;
      }
      return taxData;
    }) || [];

  taxes.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    } else {
      return 1;
    }
  });

  const col1 = [];
  const col2 = [];
  const col3 = [];
  const other = [];

  taxes.forEach((tax, index) => {
    switch (tax.column) {
      case 1:
        col1.push(tax);
        break;
      case 2:
        col2.push(tax);
        break;
      case 3:
        col3.push(tax);
        break;
      default:
        utils.db('No Column info found for:', tax.label);
        other.push(tax);
        break;
    }
  });

  col1.sort((a, b) => a.position - b.position);
  col2.sort((a, b) => a.position - b.position);
  col3.sort((a, b) => a.position - b.position);

  return { col1, col2, col3, other };
};

export const makeRecordData = (record, taxColumnMap) => {
  const grossNetInfo = [
    { label: 'Gross Pay', value: record?.grossPay, isHeader: true },
    { label: 'Net Pay', value: record?.netPay, isHeader: true },
  ];

  const checkLabel = isRegionCanada() ? 'Cheque' : 'Check';
  const stateLabel = isRegionCanada() ? 'Prov' : 'St';

  const checkType =
    record?.checkType === 'ACH'
      ? 'Direct Deposit'
      : record?.checkType
      ? record.checkType
      : `Physical ${checkLabel}`;

  const timecardInfo = [
    { label: `${checkLabel} Overview`, isHeader: true },
    { label: 'Seq#', value: record?.checkSequence },
    { label: 'Combine', value: record?.combineCheck?.[0] },
    { label: 'Freq', value: record?.payFrequency },
    { label: 'TC#', value: record?.payrollTimecardNumber },
    { label: 'Type', value: checkType },
  ];

  const possibleValues = [
    { label: `Res ${stateLabel}`, accessor: 'residenceState' },
    { label: 'Res City', accessor: 'residenceCity' },
    { label: 'Res PSD', accessor: 'stateSubdivisionCode' },
    { label: 'Res County', accessor: 'residenceCounty' },
  ];
  if (record?.residenceCountry !== 'US') {
    possibleValues.unshift({
      label: 'Res Cntry',
      accessor: 'residenceCountry',
    });
  }
  possibleValues.forEach(f => {
    if (record[f.accessor] || record[f.accessor] === 0) {
      const newRow = { label: f.label, value: record[f.accessor] };
      timecardInfo.push(newRow);
    }
  });

  const timecardUnionInfo = [
    { label: 'W/E', value: record?.weekEndingDate },
    {
      label: 'Union',
      value: record?.dealMemoHeader?.htgUnion,
    },
  ];

  if (record?.employeeOccupation?.length > 0) {
    record?.employeeOccupation?.forEach((occ, index, arr) => {
      const num = arr.length > 1 ? ` ${index + 1}` : '';
      const occStr =
        occ?.name && occ?.code ? `${occ?.code} - ${occ?.name}` : '';

      timecardUnionInfo.push({
        label: `Occ${num}`,
        value: occStr,
      });
    });
  }
  timecardUnionInfo.push({
    label: 'Dept',
    value: record?.department,
  });

  let loanOut = [];
  if (record?.employeeCorporation?.length > 0) {
    record?.employeeCorporation?.forEach((corp, index, corps) => {
      const num = corps.length > 1 ? ` ${index + 1}` : '';
      loanOut.push({
        label: `Loan-Out${num}`,
        value: corp.name,
      });
      loanOut.push({
        label: `EIN${num}`,
        value: corp.taxId,
      });
    });
  }
  if (record?.dealmemoAgents?.length > 0) {
    record?.dealmemoAgents?.forEach((deal, index, deals) => {
      const num = deals.length > 1 ? ` ${index + 1}` : '';
      loanOut.push({
        label: `Agent${num}`,
        value: `${deal.agentNumber} - ${deal.agentName}`,
      });
    });
  }

  const checkComment = [
    {
      label: `${checkLabel} Comment`,
      value: record?.checkComment || (
        <Box sx={{ color: 'text.disabled', textStyle: 'italic' }}>none</Box>
      ),
    },
  ];

  const distributions = record?.distributions?.slice() || [];
  const accountCodeSummary = record?.accountCodeSummary?.slice() || [];

  const taxes = makeTaxesData(record?.taxes, taxColumnMap);

  return {
    grossNetInfo,
    timecardInfo,
    timecardUnionInfo,
    checkComment,
    loanOut,
    distributions,
    accountCodeSummary,
    taxes,
  };
};

export const openModalAfterWindowClose = (onSetCommentState, childRef) => {
  let count = 0;

  const recursiveCheck = () => {
    count++;
    setTimeout(() => {
      if (childRef.current.closed === true) {
        onSetCommentState('modal');
      } else if (count < 10) {
        recursiveCheck();
      }
    }, 500);
  };
  recursiveCheck();
};

export const DATA_CARD_COMPONENTS_CONTROL = [
  {
    label: 'Daily Breakdown',
    key: 'Daily Breakdown',
    selected: true,
    index: 0,
  },
  {
    label: 'Account Code Summary',
    key: 'Account Code Summary',
    selected: true,
    index: 1,
  },
  {
    label: 'Earnings',
    selected: true,
    index: 2,
  },
  {
    label: 'Employer Taxes',
    key: 'ER Taxes',
    selected: true,
    index: 3,
  },
  {
    label: 'Employee Taxes',
    key: 'EE Taxes',
    selected: true,
    index: 4,
  },
  {
    label: 'Deductions',
    selected: true,
    index: 5,
  },
  {
    label: 'Advances',
    selected: true,
    index: 6,
  },
  {
    label: 'Pension',
    selected: true,
    index: 7,
  },
  {
    label: 'IAP',
    key: 'IAP',
    selected: true,
    index: 8,
  },
  {
    label: 'Benefits',
    selected: true,
    index: 9,
  },
  {
    label: 'WCI',
    selected: true,
    index: 10,
  },
  {
    label: 'Handling',
    selected: true,
    index: 11,
  },
  {
    label: 'Reimbursements',
    selected: true,
    index: 12,
  },
  {
    label: 'Accrual',
    selected: true,
    index: 13,
  },
  {
    label: 'Tax Credits',
    selected: true,
    index: 14,
  },
  {
    label: 'Information Only',
    key: 'For Information Only',
    selected: true,
    index: 15,
  },
  {
    label: 'Display Empty Columns',
    selected: false,
    index: 16,
    isDisplayEmptyColumns: true,
  },
];

export const sortInvoiceCharges = (a, b) => {
  return a.description < b.description ? -1 : 1;
};

export const sortAccountCodeData = (a, b) => {
  for (let i = 0; i < accountCodeSummaryHeaders.length; i++) {
    const sortBy = accountCodeSummaryHeaders[i].accessor;

    if (a[sortBy] < b[sortBy]) return -1;
    if (a[sortBy] > b[sortBy]) return 1;
  }
};

export const makeNameFromRecord = record => {
  const ssnNum = record?.employee?.code?.split('-')[2];
  const employeeName = record?.employeeName || '';
  const name = `${employeeName}${ssnNum ? ` - ${ssnNum}` : ``}`;
  return name;
};

const INVOICE_APPROVAL_STATUS_MAPPING = [
  {
    code: 'E',
    label: 'Edit Reports',
    color: 'lightGreen',
  },
  {
    code: 'A',
    label: 'Approved Invoice',
    color: 'yellow',
  },
];
export const getInvoiceApprovalStatus = code => {
  const approvalObj = INVOICE_APPROVAL_STATUS_MAPPING.find(
    obj => obj.code === code,
  );

  if (!approvalObj) {
    const possibleList = INVOICE_APPROVAL_STATUS_MAPPING.map(
      obj => obj.code,
    ).join();
    console.warn(
      `Invalid report status:${code}. Expected to be one of: ${possibleList}`,
    );
  }
  return approvalObj || {};
};

export const calcChargeNoWrap = chargeDes => {
  if (!chargeDes) return [false, false];

  const words = chargeDes.split(' ');
  let clipCharge = false;
  let clipChargeSticky = false;
  words.forEach((word, index) => {
    if (word.length > CHARGE_DES_MAX) {
      clipCharge = true;
    }
    if (word.length > CHARGE_DES_MAX_STICKY) {
      clipChargeSticky = true;
    }
  });
  return [clipCharge, clipChargeSticky];
};

const DataValueBox = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const dataBoxValue = (value, options = {}) => {
  const { rowClass, noTruncate } = options;
  if (Array.isArray(value)) {
    value = (
      <Box sx={{ my: value.length > 1 ? 1 : '' }}>
        {value.map(v => (
          <DataValueBox key={v} className={rowClass}>
            {v}
          </DataValueBox>
        ))}
      </Box>
    );
  }
  let dataBox;

  if (noTruncate) {
    dataBox = <Box className={rowClass}>{value}</Box>;
  } else {
    dataBox = <DataValueBox className={rowClass}>{value}</DataValueBox>;
  }

  if (typeof value === 'string' && value.length >= 20) {
    return <Tooltip title={value}>{dataBox}</Tooltip>;
  }

  return dataBox;
};

export const scrollToElement = id => {
  const elementId = `digital-edits-record-${id}`;
  const element = document.getElementById(elementId);

  let position = element.getBoundingClientRect();
  const scrollTopMargin = 135;
  window.scrollTo(
    position.left,
    position.top + window.scrollY - scrollTopMargin,
  );
  setTimeout(() => {
    // timecards can resize as a result of the scroll event (usually if tables have been
    // shown/hidden). To fix we fire it again
    // to ensure we end up in the right place.  If the `jumpy` becomes noticeable, we can
    // only do the 2nd scroll if the additional fields have been updated
    let position = element.getBoundingClientRect();
    window.scrollTo(
      position.left,
      position.top + window.scrollY - scrollTopMargin,
    );
  }, 200);
};

/**
 * Given a timecard, return the key used to store the record in the DE
 * format of key is: `TIMECARD_HEADER1,TIMECARD_HEADER2__PAYROLL_TIMECARD1,PAYROLL_TIMECARD2`
 * @param {*} record
 */
export const makeRecordIdKey = record => {
  const { timecardEntryHeaderId, ccTimecardNumber, payrollTimecardNumber } =
    record;

  if (!ccTimecardNumber && !payrollTimecardNumber) {
    throw new Error(
      'Invalid timecard. Must have ccTimecardNumber OR payrollTimecardNumber',
    );
  }
  let payrollTimecardArr;

  if (ccTimecardNumber) {
    payrollTimecardArr = ccTimecardNumber
      .split(',')
      .map(item => Number(item))
      .sort();
  } else {
    payrollTimecardArr = payrollTimecardNumber;
  }

  const headerArr = timecardEntryHeaderId
    .split(',')
    .map(x => x.trim())
    .sort();

  const key = `${headerArr}__${payrollTimecardArr}`;

  return key;
};

export const removeFromCCTimecard = (recordId, toRemove) => {
  const regex = new RegExp(`${toRemove},?`, 'g');

  let newRecordId = recordId.replace(regex, '');
  newRecordId = newRecordId.replace(/,$/, '');

  return newRecordId;
};

export const getTcHeadersFromRecordId = recordId => {
  const [timecardEntryHeaderIds] = recordId.split('__');

  return timecardEntryHeaderIds.split(',').map(x => x.trim());
};
export const getCCFromRecordId = recordId => {
  // eslint-disable-next-line no-unused-vars
  const [timecardEntryHeaderIds, ccTimecards] = recordId.split('__');

  return ccTimecards.split(',').map(x => x.trim());
};

export const isRowReadOnly = row => {
  return false;
};

export const isBottomEditCell = (targetDom, parentTable) => {
  let isBottomCell = false;
  let rootBottom = parentTable.getBoundingClientRect().bottom;
  let cellBottom = targetDom.getBoundingClientRect().bottom;

  if (cellBottom + 35 > rootBottom) {
    isBottomCell = true;
  }
  return isBottomCell;
};

let alloCount = 0;
export const getChildAlloNumber = () => {
  alloCount++;
  return alloCount;
};

export const calcAllowParent = ({ unsavedChangesRef, id }) => {
  const parentRow = getCurrentRow(id, unsavedChangesRef);
  let { originalAllowanceAmount } = parentRow;
  const subRows =
    parentRow?.subRowIds?.map(subId =>
      getCurrentRow(subId, unsavedChangesRef),
    ) || [];
  let amount = Number(parentRow.amount);
  let hasFreshRow = false;
  if (Number.isNaN(amount)) {
    console.warn('Amount is not a number');
    amount = 0;
  }
  const childTotal = subRows.reduce((acc, subRow) => {
    if (subRow?.isFreshRow) {
      hasFreshRow = true;
    }
    return sumCurrency(acc, Number(subRow?.amount));
  }, 0);

  const total = sumCurrency(amount, childTotal);

  const diff = diffCurrency(total, originalAllowanceAmount, {
    allowNegative: true,
  });
  const result = {
    balanced: total === originalAllowanceAmount,
    total,
    diff,
    originalAllowanceAmount,
    childTotal,
    hasFreshRow,
  };

  return result;
};

export const getCurrentRow = (id, unsavedChangesRef) => {
  const { unsaved = {}, original = {} } = unsavedChangesRef.current;

  const row = _.cloneDeep(original[id]) || {};
  const unsavedRow = unsaved[id] || {};
  Object.keys(unsavedRow).forEach(field => {
    if (unsavedRow.hasOwnProperty(field)) {
      row[field] = _.cloneDeep(unsavedRow[field]);
    }
  });

  return row;
};

export const applyUnsavedChangesRefToData = (unsavedChangesRef, data) => {
  if (_.isEmpty(unsavedChangesRef.current.unsaved) === false) {
    const { unsaved, original } = unsavedChangesRef.current;

    const missingRows = [];
    Object.keys(unsaved).forEach(id => {
      let dataRow = data.find(r => {
        return r.id === id;
      });
      if (!dataRow && id.includes(NEW_ALLOWANCE_TAG)) {
        //row in unsaved doesn't exist in data, need to add it in.
        dataRow = _.cloneDeep(original[id]);
        const parentIndex = data.findIndex(d => d.id === dataRow.parentRowId);
        data.splice(parentIndex + 1, 0, dataRow);
      }

      if (!dataRow) {
        console.error('Row missing in data', id);
        // throw new Error('Row not found in data');
        missingRows.push(id);
        return;
      }

      Object.keys(unsaved[id]).forEach(field => {
        dataRow[field] = unsaved[id][field];
      });
    });
    missingRows.forEach(id => {
      //This should only happen when we've received a `DeletePayrollTimecard` action
      delete unsaved[id];
      delete original[id];
    });
  }
};

export const initUnsavedChangesRef = unsavedChangesRef => {
  const { current } = unsavedChangesRef;
  current.original = current.original || {};
  current.unsaved = current.unsaved || {};
};

export const getCurrentValue = ({ id, field, unsavedChangesRef, data }) => {
  const { unsaved = {}, original = {} } = unsavedChangesRef.current;

  let value = unsaved[id]?.[field] ?? original[id]?.[field] ?? null;

  if (value === null) {
    const row = data.find(row => row.id === id);
    value = row[field];
  }

  return value;
};

function capFirstLetter(string) {
  if (string.length === 0) {
    return string;
  } else {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

const makeErrorForField = (field, message) => {
  let errorMessage;
  if (field === 'baseRate') {
    errorMessage = message;
  } else {
    errorMessage = capFirstLetter(`${field} ${message}`);
  }

  return errorMessage;
};

export const createErrorMessage = errors => {
  const errorList = new Set();

  let hasRequired = false;

  Object.values(errors).forEach(error => {
    Object.keys(error).forEach(field => {
      const newError = makeErrorForField(field, error[field]);
      errorList.add(newError);
      if (newError.toLowerCase().includes('required')) {
        hasRequired = true;
      }
    });
  });

  const errorArray = Array.from(errorList);

  const totalErrorIndex = errorArray.findIndex(
    error => error === ALLOWANCE_TOTAL_ERROR,
  );

  if (hasRequired && totalErrorIndex === -1) {
    errorArray.unshift('Missing Required Fields');
  } else if (hasRequired && totalErrorIndex !== -1) {
    errorArray.splice(totalErrorIndex, 1);
    errorArray.unshift(ALLOWANCE_TOTAL_ERROR);
  }

  return errorArray;
};

const regex = /^(\d*)\.?(\d{0,2})$/m;
export const normalizeAmount = value => {
  if (regex.test(value)) {
    return value;
  }

  value = value.replace(/[^0-9.]/g, '');

  const hasPeriod = value.match(/\./g);
  if (hasPeriod && hasPeriod.length > 1) {
    //remove all but the first period
    value = value.replace(/\./g, (match, offset, string) =>
      offset === string.indexOf('.') ? match : '',
    );
  }

  //remove all but 2 decimal places
  const twoPlaceRegex = /\.(\d\d)(\d+)/;
  const result = twoPlaceRegex.exec(value);
  if (result) {
    value = value.replace(twoPlaceRegex, `.${result[1]}`);
  }

  return value;
};

export const diffCurrency = (a, b, options) => {
  const { allowNegative = false } = options || {};
  a = Number(a);
  b = Number(b);

  if (Number.isNaN(a) || Number.isNaN(b)) {
    console.warn('diffCurrency: a or b is not a number. a:', a, ' b: ', b);
    return 0;
  }

  if (!allowNegative && b > a) {
    console.warn('diffCurrency: b is greater than a');
    return 0;
  }

  let result = (a - b).toFixed(2);
  result = Number(result);
  if (!allowNegative && result < 0) {
    return 0;
  }

  return result;
};

export const sumCurrency = (a, b) => {
  a = Number(a);
  b = Number(b);
  if (Number.isNaN(a) || Number.isNaN(b)) {
    console.warn('sumCurrency: a or b is not a number. a:', a, ' b: ', b);
    return 0;
  }

  const result = (a + b).toFixed(2);
  return Number(result);
};

export const displayCurrencyFixed = (value, options) => {
  value = Number(value);
  return value.toFixed(2);
};

export const getTaskId = (headerId, pendingTasks) => {
  let currentId = null;
  Object.keys(pendingTasks).forEach(taskId => {
    const pendingTimecards = pendingTasks[taskId].timecards;
    if (pendingTimecards[headerId]) {
      currentId = taskId;
    }
  });

  return currentId;
};
