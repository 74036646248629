import { Typography, styled } from '@mui/material';
export const FilesTypography = styled(Typography)(({ theme, small }) => ({
  color: theme.palette.gray.primary,
  fontSize: small ? '11px' : '14px',
  fontWeight: small ? '500' : '400',
  fontFamily: 'Inter',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '400px',
  overflow: 'hidden',
}));
