import React, { Component } from 'react';
import withGridSorting from './withSorting';
import withGridPagination from './withPagination';

// eslint-disable-next-line import/no-anonymous-default-export
export default (grid, orderBy) => BaseComponent => {
  class ComponentWithDataGrid extends Component {
    static displayName = `withDataGrid(${BaseComponent.displayName})`;

    render() {
      return <BaseComponent {...this.props} />;
    }
  }

  return withGridPagination(grid)(
    withGridSorting(grid, orderBy)(ComponentWithDataGrid),
  );
};
