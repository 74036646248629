import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, formValueSelector, getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import LinearProgress from '@mui/material/LinearProgress';
import { formatMessage, REQUIRED } from 'utils/messages';
// containers
import InactiveUser from 'containers/Employees/InactiveUser';
import ConfirmExisting from 'containers/Employees/Timecards/Modals/ConfirmExisting';
// components
import CreateTimecard from 'components/Employees/Timecards/Create';
// selectors
import { currentUser } from 'selectors/session';
import {
  getLoading,
  getLoadingInit,
  getWeekendings,
  getFilteredDealMemos,
  getPreviousWeeks,
  existingTimecardForProject,
  createTimecardInitialValues,
} from 'selectors/timecards';

// actions
import { show as showModal } from 'actions/modalDialog';
import {
  setFlagCopyPrevious,
  fetchWeekendings,
  fetch as fetchTimecards,
  loading,
  setFromURL,
} from 'actions/timecards';
//utils
import { weekEndingFormat as format } from 'utils/formatDate';

const formName = 'createTimecard';
const selector = formValueSelector(formName);

const mapStateToProps = state => ({
  activeUser: currentUser(state),
  weekendings: getWeekendings(state),
  previousWeeks: getPreviousWeeks(formName)(state),
  dealMemos: getFilteredDealMemos(formName)(state),
  loading: getLoading(state),
  loadingInit: getLoadingInit(state),
  initialValues: createTimecardInitialValues(formName)(state),
  formSyncErrors: getFormSyncErrors(formName)(state),
  existingTimecards: existingTimecardForProject(state),
  weekendingSelected: selector(state, 'weekEnding'),
  occupationCodeSelected: selector(state, 'occupationCode'),
});

const mapDispatchToProps = (dispatch, { match }) => ({
  onFetchData: () => {
    dispatch(loading({ loading: true }));
    dispatch(fetchWeekendings());
    dispatch(fetchTimecards({ role: 'employee' }));
    dispatch(setFromURL({ fromURI: match.url }));
  },
  setCopyPrevious: () => {
    dispatch(setFlagCopyPrevious());
  },
});

const validate = values => {
  const errors = {};
  const { weekEnding, occupationCode } = values;

  if (!weekEnding) {
    errors.weekEnding = formatMessage(REQUIRED);
  }

  if (!occupationCode) {
    errors.occupationCode = formatMessage(REQUIRED);
  }

  return errors;
};

const onSubmit = (values, dispatch) => {
  // 1. See if there are any existing timecards for the weekEnding.
  // 1.1 If any open a modal to show the existing timecard listing.
  // 2. Submit the timecard if none.
  if (values.sourceTimecardId) dispatch(setFlagCopyPrevious({ flag: true }));
  dispatch(showModal({ dialog: 'confirmExisting', maxWidth: 'xs' }));
};

class TimecardCreate extends Component {
  static propTypes = {
    onFetchData: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.onFetchData();
  }

  render() {
    const activeUser = this.props.activeUser;
    if (this.props.loading || this.props.loadingInit) {
      return <LinearProgress />;
    }

    if (
      !activeUser ||
      !activeUser.hasWorksightAccount ||
      !activeUser.department
    ) {
      return <InactiveUser />;
    }

    const previousWeekOptions = this.props.existingTimecards
      .filter(tc => tc.isAllowanceOnly === false)
      .map(tc => {
        const dealMemo = tc.dealMemo ? tc.dealMemo.jobDescription : 'Pending';

        return {
          value: tc.timecardId,
          label: format(tc.endsOn) + ' - ' + dealMemo,
          date: format(tc.endsOn),
          dealMemoId: tc?.dealMemo?.id,
        };
      });

    return (
      <form onSubmit={this.props.handleSubmit}>
        <CreateTimecard
          previousWeekOptions={previousWeekOptions}
          {...this.props}
        />
        <ConfirmExisting />
      </form>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnChange: true,
    validate,
    onSubmit,
  }),
)(TimecardCreate);
