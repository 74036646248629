import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import {
  Grid,
  Divider,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
  LinearProgress,
  Autocomplete,
  TextField,
  Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FieldCheckboxDropDown from 'components/Shared/FieldCheckboxDropDown';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 30,
  },
  heading: {
    fontSize: '1.1em',
    fontFamily: 'inherit',
  },
  button: {
    margin: 20,
  },
  paddingLeft: {
    paddingLeft: '35px',
  },
  dropdowns: {
    width: '350px',
  },
}));

const WTCSettingsComponent = ({
  loading,
  wtcEditFields,
  editOptions = 'canEdit',
  onEditableFieldChange,
  onSave,
  onChangeHandler,
  timecardTemplateOptions,
  onFetchTimecardTemplates,
  wtcLayout = null,
  onWtcLayoutHandler,
}) => {
  const classes = useStyles();
  let selectedCount = 0;
  for (const key in wtcEditFields) {
    if (wtcEditFields.hasOwnProperty(key)) {
      const element = wtcEditFields[key];
      if (
        element.editable &&
        element?.columnId !== 'selectAll' &&
        element?.type !== 'divider'
      ) {
        selectedCount++;
      }
    }
  }
  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={onFetchTimecardTemplates}
        >
          <Typography className={classes.heading}> WTC </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.paddingLeft}>
          <div>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item className={classes.dropdowns}>
                <FieldCheckboxDropDown
                  fieldsList={wtcEditFields}
                  onClickField={onEditableFieldChange}
                  label={'NDBs and NDMs'}
                  selectedCount={selectedCount}
                  checkedProperty="editable"
                />
                <Tooltip title={wtcLayout?.label || ''}>
                  <span>
                    <Autocomplete
                      style={{ marginTop: 10 }}
                      value={wtcLayout}
                      options={timecardTemplateOptions || []}
                      getOptionLabel={option => option.label}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="WTC Layout"
                          variant="standard"
                        />
                      )}
                      onChange={(e, value) => onWtcLayoutHandler(e, value)}
                    />
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel>In and Out Times</FormLabel>
                  <RadioGroup
                    value={editOptions}
                    name="inAndOutTimes"
                    onChange={onChangeHandler}
                  >
                    <FormControlLabel
                      checked={editOptions === 'canEdit'}
                      value="canEdit"
                      control={<Radio />}
                      label="Can Edit, Force Resubmission (Default)"
                    />
                    <FormControlLabel
                      checked={editOptions === 'cannotEdit'}
                      value="cannotEdit"
                      control={<Radio />}
                      label="Cannot Edit (Legacy)"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
        <Divider />
        {loading && <LinearProgress />}
        <AccordionActions>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.button}
            onClick={onSave}
          >
            Save Settings
          </Button>
        </AccordionActions>
      </Accordion>
    </div>
  );
};

export default WTCSettingsComponent;
