import React from 'react';
import { IconButton, Badge } from '@mui/material/';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
//icons
import ChatBubbleSharpIcon from '@mui/icons-material/ChatBubbleSharp';

// actions
import { show as showModal } from 'actions/modalDialog';
import { fetchComments } from 'actions/reviews';
import { setCurrentTimecard } from 'actions/timecards';

//selectors
import { getRecentlyAddedComments } from 'selectors/timecard/common';

const useStyles = makeStyles(({ palette }) => ({
  commentIcon: {
    color: palette.primary.main,
    height: 16,
    width: 16,
  },
}));

const mapStateToProps = state => ({
  recentlyAddedComments: getRecentlyAddedComments(state),
});

const mapDispatchToProps = dispatch => ({
  onShowModal: (timecardEntryHeaderId, status) => {
    dispatch(fetchComments({ timecardEntryHeaderId }));
    dispatch(setCurrentTimecard({ timecardId: timecardEntryHeaderId }));
    dispatch(showModal({ dialog: 'AddComment', modalParams: { status } }));
  },
});

/**
 * Comment Icon that bring up comment modal
 * MUST HAVE COMMENT MODAL COMPONENT SOMEWHERE ON PAGE
 * src/containers/Employees/Reviews/Reviewer/Modals/AddComment.js
 *
 * @param {string} timecardHeaderId
 * @param {bool} hasComment
 * @param {string} iconClassName
 */

const TimecardCommentIcon = props => {
  const classes = useStyles();
  const {
    partialTimecard,

    onShowModal,
    recentlyAddedComments,
  } = props;
  const { timecardEntryHeaderId, status } = partialTimecard;
  let { hasComment = false } = partialTimecard;

  if (!hasComment) {
    hasComment = !!recentlyAddedComments[timecardEntryHeaderId];
  }

  return (
    <IconButton
      onClick={e => {
        e.stopPropagation();
        onShowModal(timecardEntryHeaderId, status);
      }}
      aria-label="Show Comment"
      size="small"
    >
      <Badge invisible={!hasComment} color="error" variant="dot">
        <ChatBubbleSharpIcon className={classes.commentIcon} />
      </Badge>
    </IconButton>
  );
};

TimecardCommentIcon.propTypes = {
  partialTimecard: PropTypes.object.isRequired,
  iconClassName: PropTypes.string,
  onShowModal: PropTypes.func.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TimecardCommentIcon,
);
