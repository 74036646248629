import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Checkbox,
  TextField,
  ClickAwayListener,
  Divider,
  Box,
  Paper,
} from '@mui/material';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';

import { TargetDetachedObserver } from 'utils/customHooks';

const useStyles = makeStyles(theme => ({
  displayTextfieldRoot: {
    cursor: 'pointer',
    padding: '4px 0 5px 5px',
  },
  searchTextfieldRoot: {
    padding: '8px 8px 0px 8px',
  },
  searchInputRoot: {
    backgroundColor: 'white',
  },
  divider: {
    borderTop: '1px solid',
    borderColor: theme.palette.grey[300],
    marginTop: '8px',
    marginBottom: '8px',
  },
  dropDownContainer: {
    marginTop: 8,
    position: 'absolute',
    right: 0,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    width: '210px',
    zIndex: 1000,
    transform: 'scale(1, 0)',
    transformOrigin: 'top',
    transition: `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut} 0ms`,
  },
  dropDownOpen: {
    transform: 'scale(1, 1)',
    transformOrigin: 'top',
  },
  expandIcons: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    transition: 'transform 0.2s',
  },
  expand: {
    transform: 'rotate(180deg)',
  },
  FormControlLabelRoot: {
    marginLeft: 0,
    marginRight: 0,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}30 !important`,
      color: theme.palette.primary.main,
    },
  },
}));

function MultiSelectDropDown(props) {
  const {
    options = [],
    onSelect,
    useIntersectionObserver = false,
    onSelectAllAdditionalFields,
    canSelectAll,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [textFilter, setTextFilter] = useState('');
  const searchRef = useRef(null);
  const displayFieldRef = useRef(null);

  useEffect(() => {
    if (open) searchRef.current.focus();
    if (!open) setTextFilter('');
  }, [open]);

  const selected = options.filter(opt => opt.selected);

  const list = useMemo(() => {
    const text = textFilter.toUpperCase();
    return options.filter(opt => opt.label?.toUpperCase()?.includes(text));
  }, [textFilter, options]);

  const selectAllChecked =
    selected.length === options.length && options.length !== 0 ? true : false;

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <Box sx={{ position: 'relative', alignSelf: 'center' }}>
          {useIntersectionObserver && (
            //Close the dropdown when the target outside the container
            <TargetDetachedObserver
              targetRef={displayFieldRef}
              callback={() => {
                setOpen(false);
              }}
              rootMargin="-10px 0px"
            />
          )}
          <TextField
            label="Additional Fields"
            InputProps={{
              inputRef: displayFieldRef,
              readOnly: true,
              className: 'PENDO_digitalEditsAdditionalFields',
              endAdornment: (
                <ExpandMoreIcon
                  className={clsx(classes.expandIcons, {
                    [classes.expand]: open,
                  })}
                />
              ),
              classes: {
                input: classes.displayTextfieldRoot,
              },
            }}
            value={
              selected.length === 0
                ? 'Show/Hide Info'
                : `${selected.length} selected`
            }
            sx={{ marginLeft: '8px', pointer: 'cursor' }}
            onClick={() => {
              setOpen(o => !o);
            }}
          />
          <Box
            className={clsx(classes.dropDownContainer, {
              [classes.dropDownOpen]: open,
            })}
            sx={{ position: 'absolute', right: 0 }}
          >
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'grey.50',
              }}
            >
              <TextField
                value={textFilter}
                onChange={e => {
                  setTextFilter(e.target.value);
                }}
                placeholder="Search..."
                InputProps={{
                  inputRef: searchRef,
                  classes: {
                    root: classes.searchInputRoot,
                  },
                  startAdornment: <SearchIcon />,
                }}
                classes={{
                  root: classes.searchTextfieldRoot,
                }}
                autoFocus
              />
              <Divider className={classes.divider} />
              <Box
                sx={{
                  paddingBottom: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '210px',
                  overflow: 'auto',
                }}
              >
                {canSelectAll && (
                  <FormControlLabel
                    className={'PENDO_digitalEditsSelectAll'}
                    classes={{
                      root: classes.FormControlLabelRoot,
                    }}
                    control={
                      <Checkbox
                        checked={selectAllChecked}
                        onClick={e => {
                          onSelectAllAdditionalFields(e.target.checked);
                        }}
                      />
                    }
                    label="Select all"
                  />
                )}
                {list.map((opt, i) => {
                  return (
                    <FormControlLabel
                      classes={{
                        root: classes.FormControlLabelRoot,
                      }}
                      className={`PENDO_digitalEditsAdditionalFieldsOption${opt.label?.replaceAll(
                        ' ',
                        '',
                      )}`}
                      control={
                        <Checkbox
                          checked={opt.selected}
                          onClick={e => {
                            onSelect({
                              index: opt.index,
                              isDisplayEmptyColumns: opt.isDisplayEmptyColumns,
                            });
                          }}
                        />
                      }
                      label={`${opt.label}`}
                      key={`${opt.index}`}
                    />
                  );
                })}
              </Box>
            </Paper>
          </Box>
        </Box>
      </ClickAwayListener>
    </>
  );
}

MultiSelectDropDown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      selected: PropTypes.bool,
      index: PropTypes.number,
    }),
  ),
  onSelect: PropTypes.func.isRequired,
  useIntersectionObserver: PropTypes.bool,
};

export default MultiSelectDropDown;
