import * as signalR from '@microsoft/signalr';
import debug from 'debug';
import moment from 'moment';
import { take, put, call, spawn, delay, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import * as actions from 'actions/events';

import { getMoveJobInfo } from 'selectors/moveTimecards';

export const db = debug('signalr');

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ endpoint, tokenProvider }) => {
  let hubConnection = placeHolderConnection();
  let ticketRefreshTimer = 0;
  let ticket = {};

  const events = ['OnTimecardUpdated', 'OnDTSUpdated'];
  const hasTimecards = n => n && n.timecards && n.timecards.length;

  function notifier(connection) {
    return eventChannel(emit => {
      const onNotification = n => emit(n);
      events.forEach(event => {
        connection.on(event, onNotification);
      });
      connection.onclose(() => emit(new Error('disconnected')));

      return () => {
        events.forEach(event => {
          connection.off(event, onNotification);
        });
      };
    });
  }

  function* handleOnNotifications(connection) {
    let channel = yield call(notifier, connection);

    while (true) {
      try {
        const notification = yield take(channel);
        db(notification);
        if (hasTimecards(notification)) {
          if (notification.streamEvent === events[0]) {
            yield put(actions.signalRNotification({ ...notification }));
          } else if (notification.streamEvent === events[1]) {
            //TODO - currently not using this - used to be for DTS save progress updates
            yield put(actions.signalRNotificationUpdates({ ...notification }));
          }
        } else if (notification.action === 'Delete') {
          //Delete response for DTS and search Timecard
          yield put(actions.signalRJobSearchDelete({ ...notification }));
        } else if (notification.action === 'Move') {
          const moveJobInfo = yield select(getMoveJobInfo);
          if (moveJobInfo.jobId === notification.jobId) {
            yield put(actions.signalRJobMoveComplete({ ...notification }));
          }
        } else if (notification.action === 'InvoiceStatus') {
          yield put(actions.invoiceStatusChangeComplete({ ...notification }));
        } else if (
          notification.action === 'TimecardDistributionImport' ||
          notification.action === 'PayrollTimecardCalculationError' ||
          notification.action === 'PayrollTimecardExportError' ||
          notification.action === 'TimecardSaveRollback' ||
          notification.action === 'TimecardSaveRollbackError' ||
          notification.action === 'RequestPayrollDistributions' ||
          notification.action === 'DeletePayrollTimecard'
        ) {
          //DE - ACE
          yield put(actions.timecardDistributionImport({ ...notification }));
        }
      } catch {
        break;
      }
    }
    yield call(createConnection);
  }

  function* setupLifetimeManagement(connection) {
    connection.onclose(_ => (hubConnection = placeHolderConnection()));
    yield call(setupAuthTicketRefresh);
    yield call(setupSagaChannel, connection);
  }

  async function getAuthTicket() {
    ticket = await tokenProvider();

    return ticket.authTicket;
  }

  async function connect() {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(endpoint, {
        accessTokenFactory: async () => (await getAuthTicket()).ticket,
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .configureLogging(signalR.LogLevel.Warning)
      .build();

    await connection.start();

    return connection;
  }

  function* createConnection() {
    if (hasConnection()) {
      return hubConnection;
    }

    var connection = yield call(connect);

    hubConnection = connection._connectionStarted
      ? connection
      : placeHolderConnection();

    if (connection._connectionStarted) {
      yield call(setupLifetimeManagement, connection);
    }

    return hubConnection;
  }

  function* invoke(method, data) {
    if (!hasConnection()) {
      yield delay(1000);
    }

    return hubConnection.invoke(method, data);
  }

  function disconnect() {
    hubConnection.off();
    hubConnection.stop();
    hubConnection = placeHolderConnection();
  }

  function hasConnection() {
    return hubConnection && hubConnection._connectionStarted;
  }

  function placeHolderConnection() {
    function notifyError() {
      console.error(
        'Cannot fulfill the request. Connection to Notifications Server is unavailable.',
      );
    }

    return {
      on: () => notifyError(),
      off: () => notifyError(),
      send: () => notifyError(),
      start: () => notifyError(),
      stop: () => notifyError(),
      invoke: () => notifyError(),
      connectionStarted: false,
    };
  }

  function* setupSagaChannel(connection) {
    yield spawn(handleOnNotifications, connection);
  }

  function setupAuthTicketRefresh() {
    var duration = durationUntilTicketExpiry(ticket.authTicket);
    var timeout = duration.asMilliseconds();

    clearTicketRefreshTimer();
    ticketRefreshTimer = setTimeout(disconnect, timeout <= 0 ? 3000 : timeout);
  }

  function clearTicketRefreshTimer() {
    ticketRefreshTimer && clearTimeout(ticketRefreshTimer);
  }

  function durationUntilTicketExpiry(authTicket) {
    return moment.duration(ticketExpiry(authTicket));
  }

  function ticketExpiry(authTicket) {
    return moment
      .unix(authTicket.expiryEpochTime)
      .diff(moment().add(1, 'minutes'));
  }

  return {
    connect: function* () {
      yield call(createConnection);
    },
    disconnect: function* () {
      yield call(disconnect);
    },
    invoke: function* (method, data) {
      yield call(invoke, method, data);
    },
  };
};
