const tooltip = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ ownerState }) => {
        let fontSize = 12;
        if (ownerState?.variant === 'largeText') {
          fontSize = 16;
        }
        return { fontSize, lineHeight: '16px' };
      },
    },
    defaultProps: {
      arrow: true,
    },
    variants: [
      // variants don't seem to work for tooltip for some reason,
      // prop just gets passed to the child
      // but using ownerstate in the style override does work...
    ],
  },
};

export default tooltip;
