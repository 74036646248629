import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, destroy, isDirty } from 'redux-form';
import { Prompt } from 'react-router-dom';
import { compose } from 'utils/helperFunctions';
import * as actions from './actions';
import * as sel from './selectors';
import { setPromptOnLeavePage } from 'actions/session';
import LoadingSquare from 'containers/Employees/Reviews/Shared/LoadingSquare';

import SettingModal from './Modals/SettingsModal';
import EmployeeDealMemo from './Modals/EmployeeDealMemo';
import AllowanceModal from './Modals/AllowanceModal';
import ConfirmStandAlone from './Modals/ConfirmModal';
import EmpSignature from './Modals/EmpSignature';
import ConfirmEditModal from './Modals/ConfirmEditModal';

import MobileEmpTimecard from './Mobile/EmployeeTimecard';
import DesktopEmpTimecard from './Desktop/EmployeeTimecard';

import { useDidMount, useWillUnmount, useIsMobile } from 'utils/customHooks';
import { db, validate } from './empTimecardUtils';

import DeleteTimecard from './Modals/DeleteTimecard';
import DeleteTimecardAllowance from './Modals/DeleteTimecardAllowance';
import CopyTimeCard from './Modals/CopyTimeCard';
// import ConfirmWeekModal from './Modals/ConfirmWeekChange';
import ConfirmCopyTimecardDays from './Modals/ConfirmCopyTimecardDays';
import SubmitReminderModal from './Modals/SubmitReminderModal';
import UnsavedChangesModal from './Modals/UnsavedChangesModal';
import InactiveUser from 'containers/Employees/InactiveUser';
import StickyFooter from './Shared/StickyFooter';
import DayLevelCommentModel from './Modals/DayLevelCommentModel';

const mapState = state => ({
  loading: sel.getLoading(state, 'timecard'),
  initialValues: sel.getInitialValues(state),
  isMobile: sel.getIsMobile(state),
  saving: sel.getLoading(state, 'saving'),
  submittingEmp: sel.getLoading(state, 'submittingEmp'),
  deleting: sel.getLoading(state, 'delete'),
  requiredFields: sel.getRequiredFields(state),
  isDirty: isDirty(sel.FORM_NAME)(state),
  comment: sel.getNewComment(state),
  userLoadingStatus: sel.getUserLoadingStatus(state),
});

const mapDispatch = dispatch => ({
  onInit: () => dispatch(actions.init()),
  onReset: () => {
    dispatch(destroy(sel.FORM_NAME));
    dispatch(actions.reset());
  },
  onSetIsMobile: isMobile => dispatch(actions.storeIsMobile({ isMobile })),
  onSetPromptOnLeavePage: displayPrompt =>
    dispatch(setPromptOnLeavePage({ promptOnLeavePage: displayPrompt })),
});

const onSubmit = (values, dispatch) => {
  //stub - submit done separately.
};

const EmployeeTimecard = props => {
  const {
    onInit,
    onReset,
    loading,
    saving,
    submittingEmp,
    handleSubmit,
    onSetIsMobile,
    isMobile,
    initialValues,
    touch,
    isDirty,
    comment,
    deleting,
    userLoadingStatus,
    onSetPromptOnLeavePage,
  } = props;

  const onMobile = useIsMobile();

  useDidMount(() => {
    onInit();
    onSetIsMobile(!!onMobile);
  });

  React.useEffect(() => {
    //trigger validation on load (after save)
    touch('timecard');
  }, [initialValues, touch]);

  useWillUnmount(() => {
    db('EmployeeTimecard will unmount');
    onReset();
  });

  const Timecard = isMobile ? MobileEmpTimecard : DesktopEmpTimecard;
  const displayPrompt =
    (isDirty || !!comment) && !saving && !submittingEmp && !deleting;

  React.useEffect(() => {
    onSetPromptOnLeavePage(displayPrompt);
  }, [displayPrompt, onSetPromptOnLeavePage]);

  if (userLoadingStatus === 'inactive') {
    return <InactiveUser />;
  }

  if (userLoadingStatus === 'loading') {
    return <LoadingSquare />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Prompt
        //This handles nav outside the timecard page.
        // Intertab navigation is handled in tabNav and AttachmentWarning components
        message={location => {
          const locCheck = /projects\/\d+\/me\/timecards\/(\d+|fresh-0)/.test(
            location.pathname,
          );
          if (!locCheck && displayPrompt) {
            return 'You have unsaved changes. All unsaved changes will be lost when you leave. Are you sure you want to navigate away?';
          }
          return true;
        }}
      />
      <Timecard
        loading={loading || saving || submittingEmp}
        formName={sel.FORM_NAME}
      />
      <StickyFooter />
      {/* Modals */}
      <SettingModal />
      <EmployeeDealMemo />
      <AllowanceModal />
      <EmpSignature />
      {/* Delete TC Modal*/}
      <DeleteTimecard />
      <ConfirmStandAlone />
      <DeleteTimecardAllowance />
      <CopyTimeCard />
      <ConfirmEditModal />
      {/* <ConfirmWeekModal /> //TODO - removed in HOUR-11950*/}
      <ConfirmCopyTimecardDays />
      <SubmitReminderModal />
      <UnsavedChangesModal />
      <DayLevelCommentModel />
    </form>
  );
};

EmployeeTimecard.propTypes = {
  onInit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  submittingEmp: PropTypes.bool.isRequired,
  onSetIsMobile: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  touch: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  comment: PropTypes.string.isRequired,
  deleting: PropTypes.bool.isRequired,
  userLoadingStatus: PropTypes.string.isRequired,
  onSetPromptOnLeavePage: PropTypes.func.isRequired,
};

export default compose(
  connect(mapState, mapDispatch),
  reduxForm({
    form: sel.FORM_NAME,
    enableReinitialize: true,
    destroyOnUnmount: false, //keep form data when unmount mobile modal
    //keepDirtyOnReinitialize: true,
    // touchOnChange: true,
    validate,
    onSubmit,
    shouldError: params => {
      // const { values, nextProps, props, initialRender, structure } = params;
      //TODO - optimize this if we're validating too much
      return true;
    },
  }),
)(EmployeeTimecard);
