import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Typography } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import _ from 'lodash';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
import { ERROR_MESSAGES } from 'components/Shared/constants/ErrorMessages';
import Message from 'components/Message';
// actions
import { removeTemplate } from 'actions/invoiceTemplate';
import { hide as hideModal } from 'actions/modalDialog';
//selectors
import { getEditingTemplate } from 'selectors/invoiceTemplate';

const mapStateToProps = state => ({
  invoiceTemplate: getEditingTemplate(state),
});

const mapDispatchToProps = dispatch => ({
  onRemoveTemplate: () => {
    dispatch(removeTemplate());
  },
  onModalHide: () => {
    dispatch(hideModal({ dialog: 'invoiceTemplateDelete' }));
  },
});

const style = () => ({
  content: {
    fontWeight: 500,
  },
});

const InvoiceTemplateDelete = props => {
  const { invoiceTemplate, error, classes, onRemoveTemplate, onModalHide } =
    props;

  const content = (
    <Typography component={'span'} color="inherit" className={classes.content}>
      {'Please be careful, this action can not be undone.'}
      {error && (
        <Message error>
          {_.map(error.batchTemplate, key => ERROR_MESSAGES[key])}
        </Message>
      )}
    </Typography>
  );
  const title = (
    <Typography variant="h6" color="inherit">
      {'Remove invoice template '}
      <b>{invoiceTemplate.name}</b>
    </Typography>
  );

  return (
    <ConfirmModal
      content={content}
      title={title}
      buttonText="Remove"
      buttonType="secondary"
      raisedButton={true}
      hasSubmitAction={true}
      onModalHide={onModalHide}
      {...props}
      onSubmit={onRemoveTemplate}
    />
  );
};

export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: 'invoiceTemplateDelete', maxWidth: 'md' }),
  connect(mapStateToProps, mapDispatchToProps),
)(InvoiceTemplateDelete);
