import _ from 'lodash';
import { makeMaskFunc } from 'utils/weekUtils';
import {
  calcAllowParent,
  ALLOWANCE_TOTAL_ERROR,
} from 'feature/DigitalEdits/digitalEditsUtils';
class AceValidator {
  constructor(project) {
    this.maskingFunc = makeMaskFunc(project.accountMask);
    this.accountMask = project.accountMask;
    this._required = {};
    const requiredFields = [
      'setRequired',
      'seriesRequired',
      'locationRequired',
      'insuranceRequired',
      'customField1Required',
      'customField2Required',
      'customField3Required',
      'customField4Required',
      'episodeRequired',
      'accountCodeRequired',
    ];

    requiredFields.forEach(field => {
      let value = project[field];
      if (value === 'Y') {
        value = true;
      } else if (value === 'N') {
        value = false;
      }

      let fieldName = field.replace(/Required$/, '');
      fieldName = fieldName.replace(/customField/, 'freeField');

      this._required[fieldName] = value;
    });
  }

  validate(unsavedChangesRef, data) {
    const errors = {};
    // const warning = {};

    const { unsaved = {}, original = {} } = unsavedChangesRef.current || {};

    Object.keys(unsaved).forEach(rowId => {
      const row = _.cloneDeep(unsaved[rowId]);
      const originalRow = _.cloneDeep(original[rowId]);

      const { isAllowance, originalAllowanceAmount } = originalRow;

      if (originalAllowanceAmount && isAllowance && row.amount === undefined) {
        //add amount for parent calc checks
        row.amount = originalRow.amount;
      }

      const addError = (field, message) => {
        errors[rowId] = errors[rowId] || {};
        errors[rowId][field] = message;
      };

      Object.keys(row).forEach(field => {
        const value = row[field];
        if (this._required[field] && !value) {
          addError(field, 'required');
        }
        if (field === 'accountCode') {
          if (
            field === 'accountCode' &&
            value &&
            this.accountMask &&
            value.length < this.accountMask.length
          ) {
            addError(field, `does not match expected format`);
          }
        }

        if (isAllowance && field === 'amount' && !row.isFreshRow) {
          const numVal = Number(value);

          if (!value) {
            addError(field, 'required');
          }
          if (numVal === 0) {
            addError(field, 'cannot be zero');
          }
          if (numVal < 0) {
            addError(field, 'cannot be negative');
          }
        }

        if (isAllowance && field === 'amount' && originalAllowanceAmount) {
          const result = calcAllowParent({ id: rowId, unsavedChangesRef });
          if (
            result.balanced === false &&
            row?.subRowIds?.length > 0 &&
            !result.hasFreshRow
          ) {
            addError('baseRate', ALLOWANCE_TOTAL_ERROR);
          }
        }
      });
    });

    //check initial data for required fields
    data.forEach(row => {
      const { id: rowId } = row;

      Object.keys(this._required).forEach(field => {
        if (this._required[field] === false) return;

        if (!row[field] && !unsaved[rowId]?.[field]) {
          errors[rowId] = errors[rowId] || {};
          errors[rowId][field] = 'required';
        }
      });
    });

    unsavedChangesRef.current.errors = errors;
  }
}

export default AceValidator;
