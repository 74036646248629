import React from 'react';
import Select, { components } from '@castandcrew/react-select';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { emphasize } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Chip,
  MenuItem,
  MenuList,
  NoSsr,
  Typography,
  Paper,
  FormHelperText,
  FormControl,
  useTheme,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TextField from 'components/Shared/redux/TextField';
import debounce from 'lodash/debounce';
// This is specific to Material UI 4.0.x,
// In case if we update MUI in future, please refer following link.
// https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/autocomplete/IntegrationReactSelect.js

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 26,
    width: 'auto',
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'visible',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === 'light'
        ? theme.palette.gray['+6']
        : theme.palette.gray['-3'],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    lineHeight: 1,
    fontSize: 14,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 14,
  },
  paper: {
    position: 'absolute',
    zIndex: 1280,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    width: 'fit-content',
  },
  rightMostPaper: {
    position: 'absolute',
    zIndex: 1280,
    marginTop: theme.spacing(1),
    right: 0,
    // border: "solid 1px green",
    width: 'fit-content',
  },
  divider: {
    height: theme.spacing(2),
  },
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ innerRef, ...props }) {
  return <div ref={innerRef} {...props} />;
}

inputComponent.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        // border: 'solid 1px purple',
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

function OptionList(props) {
  return (
    <MenuList
      style={
        {
          // border: 'solid 1px orange',
        }
      }
      {...props.innerProps}
    >
      {props.children}
    </MenuList>
  );
}

OptionList.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  const { children, data } = props;

  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {data && !children
        ? data && data.subset
          ? data.subsetVal
          : `${data.code ? `${data.code}` : ''}${
              data.name ? ` - ${data.name}` : ''
            }`
        : children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function DropdownIndicator(props) {
  return <ArrowDropDownIcon />;
}

DropdownIndicator.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  const { selectProps, innerProps } = props;
  return (
    <Paper
      square
      className={
        selectProps.id === 'schedule' || selectProps.id === 'occupationCode'
          ? selectProps.classes.rightMostPaper
          : selectProps.classes.paper
      }
      {...innerProps}
    >
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

function Input(props) {
  return (
    <div className="inputterson">
      <components.Input {...props} />
    </div>
  );
}

const componentss = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  OptionList,
  DropdownIndicator,
  Input,
};

export default function IntegrationReactSelect(props) {
  // Handler from Parent

  const classes = useStyles();
  const {
    disabled = false,
    inputName,
    label,
    id = '',
    multiSelect = false,
    options = [],
    placeholder = '',
    error,
    helperText,
    isDefaultSelected = false,
    required,
    filterOptions,
    isClearable = true,
    defaultSelectedValue,
    async = false,
    loadOptions,
    onMenuOpen = () => {},
    onMenuClose = () => {},
    value,
    onChange,
    objectSelect,
    showLoadingMsg = false, //show 'loading...' instead of 'no options' initially if list is empty
    onFocus = () => {},
  } = props;

  const theme = useTheme();

  const [isLoading, setIsLoading] = React.useState(false);
  const loadingRef = React.useRef(null);

  const enableLoadingMsg = () => {
    setIsLoading(true);
    clearTimeout(loadingRef.current);
    loadingRef.current = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const onMenuOpenWrapper = () => {
    onMenuOpen();
    if (showLoadingMsg && options.length === 0) {
      enableLoadingMsg();
    }
  };

  React.useEffect(() => {
    if (options.length > 0 && showLoadingMsg) {
      clearTimeout(loadingRef.current);
      setIsLoading(false);
    }
  }, [showLoadingMsg, options.length]);

  function onSelect(selected) {
    if (onChange) {
      onChange(selected, multiSelect, objectSelect);
    }
  }

  const inputId = 'auto-complete-for-' + inputName;
  let defaultValue = multiSelect ? [] : null;

  const errorBackground = error ? theme.palette.button.yellow.background : '';

  if (value) {
    if (multiSelect) {
      defaultValue = (value || []).map(val =>
        options.find(option => option.value === val),
      );
    } else if (objectSelect) {
      defaultValue = value;
    } else {
      defaultValue = options.find(option => option.value === value);
    }
  } else if (isDefaultSelected) {
    if (defaultSelectedValue) {
      defaultValue = options.find(
        option => option.value === defaultSelectedValue,
      );
    } else if (options.length === 1) {
      defaultValue = multiSelect ? options : options[0];
    }
  }

  const onInputChange =
    async && loadOptions
      ? debounce(
          newValue => {
            if (showLoadingMsg) enableLoadingMsg();
            loadOptions(newValue);
          },
          300,
          {
            leading: true,
            trailing: true,
            maxWait: 500,
          },
        )
      : () => {};

  const selectStyle = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    input: base => ({
      ...base,
      color: 'inherit',
      '& input': {
        font: 'inherit',
        fontSize: 14,
      },
    }),
    container: base => ({
      ...base,
      backgroundColor: errorBackground,
      borderRadius: 5,
    }),
    menuList: base => ({
      ...base,
      width: 'fit-content',
      minWidth: 85,
      scrollbarWidth: 'thin',
    }),
    multiValue: base => ({
      ...base,
      backgroundColor: 'green',
    }),
    option: base => ({
      ...base,
      width: 500,
    }),
    clearIndicator: base => ({
      ...base,
      display: 'none',
    }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none',
    }),
    indicatorsContainer: base => ({
      ...base,
      // padding: 4,
      width: 19,
    }),
    valueContainer: base => ({
      ...base,
      width: 50,
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 4,
    }),
    indicatorContainer: base => ({
      ...base,
      padding: 50,
    }),
  };
  return (
    <div className={classes.root}>
      <NoSsr>
        <FormControl fullWidth error={error} required={required}>
          <Select
            classes={classes}
            inputId={inputId}
            TextFieldProps={{
              label,
              InputLabelProps: {
                htmlFor: inputId,
                shrink: true,
                disabled: disabled,
              },
              disabled: disabled,
              error: error,
            }}
            id={id}
            isDisabled={disabled}
            isClearable={isClearable}
            isSearchable
            controlShouldRenderValue
            menuPlacement="auto"
            placeholder={placeholder}
            options={options}
            value={multiSelect ? [...defaultValue] : defaultValue}
            components={componentss}
            onChange={onSelect}
            isMulti={multiSelect || false}
            filterOption={filterOptions}
            styles={selectStyle}
            onInputChange={onInputChange}
            onMenuOpen={onMenuOpenWrapper}
            onMenuClose={onMenuClose}
            onFocus={onFocus}
            isLoading={isLoading}
            menuPortalTarget={document.body}
          />

          {error && (
            <FormHelperText id={`${inputId}-error-text`}>
              {helperText}
            </FormHelperText>
          )}
        </FormControl>
      </NoSsr>
    </div>
  );
}
