import React from 'react';

import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import { FaRegTimesCircle } from 'react-icons/fa';

import PopperMy from './PopperMy';

export const useStyles = makeStyles(({ palette }) => ({
  regTimesCircle: {
    fontSize: '1rem',
  },
}));
const InvoiceTemplateAutoComplete = props => {
  const {
    options,
    editTemplate,
    handleChange,
    field,
    stateId,
    countryId,
    locationFuncs,
    showLoadingMsg,
  } = props;

  const classes = useStyles();
  const {
    onFetchCountries,
    onFetchStates,
    onFetchCities,
    onFetchCounties,
    onFetchSubdivisions,
    onResetLoc,
  } = locationFuncs;

  const onFocus =
    field === 'htgCityId' && stateId
      ? () => onFetchCities({ stateId })
      : field === 'htgCountryId'
      ? () => onFetchCountries()
      : field === 'htgStateId'
      ? () => onFetchStates({ countryId })
      : field === 'htgCountyId'
      ? () => onFetchCounties({ stateId })
      : field === 'htgSubdivisionId'
      ? () => onFetchSubdivisions('', editTemplate.state)
      : () => {};

  const [isLoading, setIsLoading] = React.useState(false);
  const loadingRef = React.useRef(null);

  const onFocusWrapper = () => {
    onFocus();
    if (showLoadingMsg && options.length === 0) {
      setIsLoading(true);
      loadingRef.current = setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  React.useEffect(() => {
    if (options.length > 0 && showLoadingMsg) {
      clearTimeout(loadingRef.current);
      setIsLoading(false);
    }
  }, [showLoadingMsg, options.length]);

  let value = null;
  if (field === 'producersStartDayOfWeek') {
    value =
      editTemplate?.producersStartDayOfWeek &&
      options.length > 0 &&
      options.find(p => p.value.includes(editTemplate.producersStartDayOfWeek));
  } else if (field === 'invoiceType') {
    //selected invoice type
    value =
      editTemplate?.invoiceType &&
      options.length > 0 &&
      options.find(p => p.value === editTemplate.invoiceType);
  } else if (field === 'locationType') {
    value =
      editTemplate?.locationType &&
      options.length > 0 &&
      options.find(
        p =>
          p.id === editTemplate.locationType.id ||
          (p.code === editTemplate.locationType.code &&
            p.name === editTemplate.locationType.name),
      );
  } else if (field === 'systemLocation') {
    //selected system location
    value =
      editTemplate?.systemLocation &&
      options.length > 0 &&
      options.find(
        p =>
          p.id === editTemplate.systemLocation.id ||
          (p.value === editTemplate.systemLocation.value &&
            p.description === editTemplate.systemLocation.description),
      );
  } else if (field === 'htgCountryId') {
    //selected country
    value =
      (editTemplate?.htgCountryId &&
        options.length > 0 &&
        options.find(p => p.id === editTemplate?.htgCountryId)) ||
      editTemplate?.country ||
      null;
  } else if (field === 'htgStateId') {
    //selected state
    value =
      (editTemplate?.htgStateId &&
        options.length > 0 &&
        options.find(p => p.id === editTemplate?.htgStateId)) ||
      editTemplate?.state ||
      null;
  } else if (field === 'htgCountyId') {
    //selected city
    value =
      (editTemplate?.htgCountyId &&
        options.length > 0 &&
        options.find(p => p.id === editTemplate?.htgCountyId)) ||
      editTemplate?.county ||
      null;
  } else if (field === 'htgSubdivisionId') {
    value =
      (editTemplate?.htgSubdivisionId &&
        options.length > 0 &&
        options.find(p => p.id === editTemplate?.htgSubdivisionId)) ||
      editTemplate?.subdivision ||
      null;
  } else if (field === 'htgCityId') {
    //selected city
    value =
      (editTemplate?.htgCityId &&
        options.length > 0 &&
        options.find(p => p.id === editTemplate?.htgCityId)) ||
      editTemplate?.city ||
      null;
  }
  const getOptionLabel = (option, field) => {
    let label;
    switch (field) {
      case 'htgStateId':
        label = `${option?.code || ''} - ${option?.name || ''}`;
        break;
      case 'htgCountryId':
      case 'htgCountyId':
      case 'htgCityId':
        label = option?.name;
        break;
      case 'htgSubdivisionId':
        label = option?.code;
        break;
      case 'producersStartDayOfWeek':
        label = option?.value;
        break;
      case 'locationType':
        label = `${option.code} - ${option.name}`;
        break;
      case 'systemLocation':
        label = `${option.value} - ${option.description}`;
        break;
      case 'invoiceType':
        label = option?.value;
        break;
      default:
        break;
    }
    return label || '';
  };

  return (
    <Autocomplete
      PopperComponent={PopperMy}
      options={options || []}
      clearIcon={<FaRegTimesCircle className={classes.regTimesCircle} />}
      getOptionLabel={option => getOptionLabel(option, field)}
      value={value}
      onChange={(e, value) => handleChange(e, value, field)}
      autoComplete
      loading={isLoading}
      onFocus={onFocusWrapper}
      onBlur={
        field === 'htgCityId'
          ? () => onResetLoc('workCity')
          : field === 'htgStateId'
          ? () => onResetLoc('workState')
          : field === 'htgCountyId'
          ? () => onResetLoc('workCounty')
          : field === 'htgSubdivisionId'
          ? () => onResetLoc('workSubdivision')
          : () => {}
      }
      includeInputInList
      renderInput={params => (
        <TextField {...params} variant="standard" placeholder={'Search'} />
      )}
    />
  );
};
export default InvoiceTemplateAutoComplete;
