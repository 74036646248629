import {
  all,
  takeLatest,
  delay,
  put,
  call,
  select,
  takeEvery,
  spawn,
  race,
  take,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { Box, CircularProgress } from '@mui/material';
import moment from 'moment';
import { isValidGuid, getYearFromDbCode } from 'utils/helperFunctions';
import _ from 'lodash';
//actions
import * as actions from 'actions/digitalEdits';
import {
  timecardDistributionImport,
  invoiceStatusChangeComplete,
} from 'actions/events';
import { setViewingYear } from 'actions/project';
import { showAlert } from 'actions/alert';
import { hide as hideModal } from 'actions/modalDialog';

// selectors
import { getProject as currentProject } from 'selectors/routeParams';

import { getCurrentProjectWorkSight } from 'selectors/project';

import {
  getComments,
  getCurrentInvoiceId,
  getUploadedFiles,
  getCommentSaveFails,
  getInvoice,
  getUnsubmittedComment,
  getIsSavingComment,
  getBatchesOpened,
  getUploadedDeliveryFiles,
  getRecords,
  getPendingTasks,
  getUnsavedRecordEdits,
  getHasUnsavedErrors,
  getHasErrors,
} from 'selectors/digitalEdits';
import {
  validateFile,
  FilesBatches,
  MAX_FILE_COUNT,
} from 'feature/DigitalEdits/UploadFilesComps/FileUploadUtils';
import {
  db,
  POSSIBLE_INVOICE_STATUSES,
  COMMENT_STATUS_MAP,
  FILE_STATUS_MAP,
  FILE_COMMENT_CATEGORY_MAP,
  sortInvoiceCharges,
  sortAccountCodeData,
  makePayCodeFilter,
  makeOccCodeFilter,
  getTaskId,
  getTcHeadersFromRecordId,
  getCCFromRecordId,
} from 'feature/DigitalEdits/digitalEditsUtils';

import { delayOnValue } from 'utils/helperFunctions';

import { db as signalrDb } from 'providers/api/signalrApi';
import { db as dbACE } from 'feature/DigitalEdits/AceTable/DataGridTableUtils';

export function* init(api, debug, params) {
  try {
    let invoiceId = params?.invoiceId;

    if (invoiceId) {
      if (isValidGuid(invoiceId) === false) {
        throw new Error('Invalid invoiceId');
      }
      yield put(actions.fetchData({ invoiceId }));
      yield put(actions.setCurrentInvoiceId({ currentInvoiceId: invoiceId }));
    } else {
      console.error('No Invoice Id supplied to initialize Digital Edits');
    }
  } catch (e) {
    debug(e);
    yield put(showAlert());
    const projectId = yield select(currentProject);
    yield delay(3000);
    yield put(push(`/projects/${projectId}/search-invoices`));
    console.error(
      'Error fetching invoice data. Redirecting to search invoices page',
    );
  }
}

export function* fetchData(api, debug, params) {
  try {
    const { invoiceId } = params;
    yield put(actions.setCurrentInvoiceId({ currentInvoiceId: invoiceId }));

    yield put(actions.fetchTaxColumnMap());
    yield put(actions.fetchInvoice({ invoiceId }));
    yield put(actions.fetchComments({ invoiceId }));
    yield put(actions.fetchTimecardDistributions({ invoiceId }));
    yield put(actions.fetchTimecardSummary({ invoiceId }));
    yield put(actions.fetchInvoiceFiles());
    yield put(actions.fetchEpisodes());
    yield put(actions.startACETimer());
  } catch (e) {
    debug(e);
  }
}

function* fetchTaxColumnMap(api, debug, params) {
  try {
    const projectId = yield select(currentProject);

    const taxColumnMap = yield call(api.digitalEdits.fetchTaxColumnMap, {
      projectId,
    });

    yield put(actions.storeTaxColumnMap({ taxColumnMap }));
  } catch (e) {
    debug(e);
    console.error('Error fetching tax column map');
  }
}

function* fetchInvoice(api, debug, params) {
  try {
    const { invoiceId } = params;
    yield put(actions.setLoading({ variant: 'invoice', loading: true }));
    const projectId = yield select(currentProject);

    const invoice = yield call(api.digitalEdits.fetchInvoice, {
      projectId,
      invoiceId,
    });
    if (invoice?.dbCode?.code) {
      const dbYear = getYearFromDbCode(invoice?.dbCode?.code);
      yield put(setViewingYear({ viewingYear: dbYear }));
    }
    // some invoice data comes in on the timecardsSummary call
    yield put(actions.updateInvoice({ invoice }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
    const projectId = yield select(currentProject);
    yield delay(3000);
    yield put(push(`/projects/${projectId}/search-invoices`));
    console.error(
      'Error fetching invoice data. Redirecting to search invoices page',
    );
    yield delay(2000);
  } finally {
    yield put(actions.setLoading({ variant: 'invoice', loading: false }));
  }
}
export function* fetchComments(api, debug, params = {}) {
  try {
    yield put(actions.setLoading({ variant: 'comments', loading: true }));
    let invoiceId = params.invoiceId;

    if (!invoiceId) {
      invoiceId = yield select(getCurrentInvoiceId);
    }

    const projectId = yield select(currentProject);

    const comments = yield call(api.digitalEdits.fetchComments, {
      projectId,
      invoiceId,
    });

    yield put(actions.storeComments({ comments }));
  } catch (e) {
    debug(e);
  } finally {
    yield put(actions.setLoading({ variant: 'comments', loading: false }));
  }
}

export function* fetchTimecardSummary(api, debug, params) {
  try {
    yield put(actions.setLoading({ variant: 'summary', loading: true }));

    const { invoiceId } = params;
    const projectId = yield select(currentProject);

    const response = yield call(api.digitalEdits.fetchTimecardSummary, {
      projectId,
      invoiceId,
    });

    const timecardsData = response?.timecardsData || [];

    const invoice = { ...response };
    delete invoice.timecardsData;

    yield put(actions.updateInvoice({ invoice }));

    yield put(actions.parseFilters({ data: timecardsData }));

    yield put(actions.storeTimecardsInfo({ timecardsData }));
  } catch (e) {
    debug(e);
  } finally {
    yield put(actions.setLoading({ variant: 'summary', loading: false }));
  }
}

export function* parseFilters(api, debug, params) {
  try {
    const { data = [] } = params;

    const departments = [];
    const unions = [];
    const payCodes = [];
    const occCodes = [];
    data.forEach(record => {
      const hasDept = departments.find(d => d.id === record.department);
      if (!hasDept && record.department) {
        departments.push({
          label: record.department,
          id: record.department,
          selected: false,
          index: departments.length,
          value: record.department,
        });
      }
      const hasUnion = unions.find(
        u => u.id === record.dealMemoHeader?.htgUnion,
      );
      if (!hasUnion && record.dealMemoHeader?.htgUnion) {
        unions.push({
          label: record.dealMemoHeader.htgUnion,
          id: record.dealMemoHeader.htgUnion,
          selected: false,
          index: unions.length,
          value: record.dealMemoHeader.htgUnion,
        });
      }

      record.employeeOccupation.forEach(oc => {
        const occCode = makeOccCodeFilter(oc.name, oc.code);
        const hasOccCode = occCodes.find(p => p.id === occCode);
        if (!hasOccCode && oc.code) {
          occCodes.push({
            label: occCode,
            id: occCode,
            selected: false,
            index: occCodes.length,
            value: occCode,
          });
        }
      });

      record.taxes.forEach(tax => {
        const sectionTitle = tax.section;
        tax.details.forEach(d => {
          // const payCode = `${sectionTitle} - ${d.payCode}`;
          const payCode = makePayCodeFilter(sectionTitle, d.payCode);
          const hasPayCode = payCodes.find(p => p.id === payCode);
          if (!hasPayCode && d.payCode) {
            payCodes.push({
              label: payCode,
              id: payCode,
              selected: false,
              index: payCodes.length,
              value: payCode,
            });
          }
        });
      });
    });

    unions.sort((a, b) => (a.label < b.label ? -1 : 1));
    departments.sort((a, b) => (a.label < b.label ? -1 : 1));
    payCodes.sort((a, b) => (a.label < b.label ? -1 : 1));
    occCodes.sort((a, b) => (a.label < b.label ? -1 : 1));

    const maxLen = Math.max(
      unions.length,
      departments.length,
      payCodes.length,
      occCodes.length,
    );

    for (let i = 0; i < maxLen; i++) {
      if (unions[i]) {
        unions[i].index = i;
      }
      if (departments[i]) {
        departments[i].index = i;
      }
      if (payCodes[i]) {
        payCodes[i].index = i;
      }
      if (occCodes[i]) {
        occCodes[i].index = i;
      }
    }

    yield all([
      put(
        actions.storeFilterOptions({
          filterName: 'department',
          options: departments,
        }),
      ),
      put(actions.storeFilterOptions({ filterName: 'union', options: unions })),
      put(
        actions.storeFilterOptions({
          filterName: 'occCode',
          options: occCodes,
        }),
      ),
      put(
        actions.storeFilterOptions({
          filterName: 'payCode',
          options: payCodes,
        }),
      ),
    ]);
  } catch (e) {
    debug(e);
  }
}

export function* fetchTimecardDistributions(api, debug, params) {
  try {
    const { invoiceId } = params;
    yield put(actions.setLoading({ variant: 'details', loading: true }));

    const projectId = yield select(currentProject);

    const data = yield call(api.digitalEdits.fetchTimecardDistributions, {
      projectId,
      invoiceId,
    });

    const timecardsData = data?.timecards || [];

    prepDistributionData(timecardsData);

    yield put(actions.storeTimecardsInfo({ timecardsData }));

    const invoiceCharges = data?.invoiceCharges || [];
    invoiceCharges.sort(sortInvoiceCharges);

    const invoiceAccountCodeSummary = data?.invoiceAccountCodeSummary || [];
    invoiceAccountCodeSummary.sort(sortAccountCodeData);

    const invoice = {
      invoiceCharges,
      invoiceAccountCodeSummary,
    };

    if (data?.invoiceTotal) {
      invoice.invoiceTotal = data.invoiceTotal;
    }

    yield put(actions.updateInvoice({ invoice }));
  } catch (e) {
    debug(e);
  } finally {
    yield put(actions.setLoading({ variant: 'details', loading: false }));
  }
}

const prepDistributionData = timecards => {
  const htgIds = {};
  let anyHtgSplit = false;
  timecards.forEach(timecard => {
    if (timecard.accountCodeSummary?.length > 0) {
      timecard.accountCodeSummary.sort(sortAccountCodeData);
    }
    if (timecard.distributions?.length > 0) {
      timecard.distributions.forEach(d => {
        if (d.isAllowance && d.htgTimecardDistributionId) {
          d.originalAllowanceAmount = d.amount;
          if (htgIds[d.htgTimecardDistributionId]) {
            anyHtgSplit = true;
            htgIds[d.htgTimecardDistributionId]++;
          } else {
            htgIds[d.htgTimecardDistributionId] = 1;
          }
        } else if (d.isAllowance && !d.htgTimecardDistributionId) {
          d.isAllowance = false;
        }
      });
      if (anyHtgSplit) {
        const htgSplits = Object.keys(htgIds).filter(k => htgIds[k] > 1);
        timecard.distributions.forEach(d => {
          if (htgSplits.includes(d.htgTimecardDistributionId)) {
            d.isHtgSplit = true;
          }
        });
      }
    }
  });
};

export function* saveComment(api, debug, params) {
  try {
    const isSaving = yield select(getIsSavingComment);
    if (isSaving) {
      //possible if user resubmits before autosave kicks in.
      db('Comment is already saving, skipping second save');
      return;
    }

    yield put(actions.setLoading({ variant: 'savingComment', loading: true }));
    const projectId = yield select(currentProject);

    const invoiceId = yield select(getCurrentInvoiceId);

    const { commentText, commentId, category, plainText } = params;
    db('Saving comment ID:', commentId);

    if (plainText === '') {
      if (commentId) {
        yield put(actions.deleteComment({ commentId, noSnackbar: true }));
      }
      db('No comment text, skipping save');
      return;
    }

    const body = { containerId: invoiceId, commentText };
    if (category) {
      body.category = category;
    }
    let apiCall;
    if (commentId) {
      body.commentId = commentId;
      apiCall = api.digitalEdits.updateComment;
    } else {
      apiCall = api.digitalEdits.addComment;
    }
    const savedComment = yield call(apiCall, { projectId, body });
    yield put(actions.setCommentSaveFails({ count: 0 }));

    const oldComments = yield select(getComments);

    const comments = oldComments.slice();

    const commentIndex = comments.findIndex(
      c => c.commentId === savedComment.commentId,
    );
    if (commentIndex !== -1) {
      comments[commentIndex] = savedComment;
    } else {
      comments.unshift(savedComment);
    }
    yield put(actions.storeComments({ comments }));
    return savedComment;
  } catch (e) {
    debug(e);
    let count = yield select(getCommentSaveFails);
    count++;
    yield put(actions.setCommentSaveFails({ count }));

    if (e?.data?.Message?.includes('has been locked')) {
      yield put(actions.lockComment());
    }
  } finally {
    yield put(actions.setLoading({ variant: 'savingComment', loading: false }));
  }
}

export function* closeInvoiceComments(api, debug, params) {
  try {
    const { hasUnsavedChanges, commentId, commentText } = params;
    if (hasUnsavedChanges) {
      yield call(saveComment, api, debug, { commentId, commentText });
    }

    yield put(actions.unlockComment());
  } catch (e) {
    debug(e);
  }
}

export function* fetchInvoiceFiles(api, debug, params) {
  yield put(actions.setLoading({ variant: 'uploads', loading: true }));

  try {
    const projectId = yield select(currentProject);
    const invoiceId = yield select(getCurrentInvoiceId);
    const data = yield call(api.digitalEdits.fetchInvoiceFiles, {
      projectId,
      invoiceId,
    });

    const uploaded = [];
    const submitted = [];
    data.forEach(f => {
      if (f.status === FILE_STATUS_MAP['Uploaded']) uploaded.push(f);
      else submitted.push(f);
    });

    yield put(actions.storeFiles({ uploaded, submitted }));
  } catch (e) {
    debug(e);
    yield put(actions.storeFiles({ uploaded: [], submitted: [] }));
  } finally {
    yield put(actions.setLoading({ variant: 'uploads', loading: false }));
  }
}

function* batchPendingFiles(api, debug, params) {
  // if major errors, then storePendingFiles not fire anything
  try {
    let { files = [], onUpdateProgress, isApproval = false } = params;

    const curFilesList = yield select(getUploadedFiles);
    const majorErrors = [];
    if (files.length > 0) {
      const nonErrorFileCount = curFilesList.filter(
        f => !f.errors || f.errors?.length === 0,
      ).length;

      if (files.length + nonErrorFileCount > MAX_FILE_COUNT) {
        majorErrors.push('Exceeded maximum number of files');
      }
      const pendingFiles = files
        .map((file, i) => {
          const err = validateFile(file, majorErrors);
          return {
            ...file,
            index: i,
            documentId: null,
            uploading: err.length > 0 ? false : true,
            errors: err,
            category: isApproval
              ? FILE_COMMENT_CATEGORY_MAP['InvoiceDelivery']
              : FILE_COMMENT_CATEGORY_MAP['None'],
          };
        })
        .sort((a, b) => {
          return b.errors.length - a.errors.length;
        }); // show files with error at the top of the list

      const Batches = new FilesBatches(pendingFiles);

      if (Batches.batches.length > 0) {
        yield all(
          Batches.batches.map(batch => {
            return spawn(uploadFilesToDB, api, debug, {
              files: batch,
              onUpdateProgress,
              isApproval,
            });
          }),
        );
      }

      if (Batches.blocked.length > 0) {
        yield put(actions.storePendingFiles({ pendingFiles: Batches.blocked }));
      }
    }
  } catch (e) {
    debug(e);
  }
}

export function* uploadFilesToDB(api, debug, params) {
  let pendingFiles, uploadId;
  try {
    const { onUpdateProgress, isApproval } = params;
    pendingFiles = params.files.list;

    yield put(actions.storePendingFiles({ pendingFiles }));
    const projectId = yield select(currentProject);
    const invoiceId = yield select(getCurrentInvoiceId);

    uploadId = (function genId() {
      const now = new Date();
      const pad = Math.floor(Math.random() * 200);
      const padStr = `${pad}`.padStart(4, '0');
      const id = `${now.getTime()}-${padStr}`;
      return id;
    })();

    const abortController = new AbortController();

    const uploadProgress = {
      uploadId,
      percentComplete: 0,
      fileCount: pendingFiles.length,
      cancel: () => abortController.abort(),
    };

    function onProgress(percentComplete) {
      onUpdateProgress(uploadId, percentComplete);
    }

    yield put(
      actions.updateInProgressUploads({
        action: 'add',
        uploadProgress,
      }),
    );

    const formData = new FormData();

    formData.append('entityType', 'invoice');
    formData.append('entityId', invoiceId);
    if (isApproval) {
      formData.append('category', 'InvoiceDelivery');
    }

    pendingFiles.forEach(f => {
      const file = f.File;
      f.errors.length === 0 && formData.append('formCollection', file);
    });

    const rsp = yield call(api.digitalEdits.uploadFiles, {
      projectId,
      payload: formData,
      onProgress,
      abortController,
    });

    yield put(actions.updateFiles({ pendingFiles, responseFiles: rsp }));
  } catch (e) {
    let action;
    if (e.message === 'canceled') {
      action = 'cancel';
    } else {
      yield put(showAlert());
    }
    debug(e);
    yield put(
      actions.updateFiles({ pendingFiles, responseFiles: null, action }),
    );
  } finally {
    yield put(actions.updateInProgressUploads({ action: 'delete', uploadId }));
  }
}

function* deleteFileFromDB(api, debug, params) {
  const { documentId, path } = params;
  try {
    yield put(
      actions.updateFileField({
        documentId,
        path,
        fieldName: 'deleting',
        fieldValue: true,
      }),
    );
    const projectId = yield select(currentProject);

    const rsp = yield call(api.digitalEdits.deleteFile, {
      projectId,
      documentId,
    });
    if (rsp.status >= 200 && rsp.status < 300) {
      yield put(
        showAlert({
          variant: 'success',
          message: 'File is successfully deleted',
        }),
      );
      yield put(actions.deleteFile({ documentId, path }));
    }
  } catch (e) {
    yield put(showAlert('Delete failed, please contact support'));
    yield put(
      actions.updateFileField({
        documentId,
        path,
        fieldName: 'deleting',
        deleteField: true,
      }),
    );
  }
}

function* downloadFile(api, debug, params) {
  const { file, path } = params;
  try {
    yield put(
      actions.updateFileField({
        documentId: file.documentId,
        fieldName: 'downloading',
        fieldValue: true,
        path,
      }),
    );
    const projectId = yield select(currentProject);

    yield call(api.downloader.downloadInvoiceFile, {
      projectId,
      documentId: file.documentId,
      fileName: file.fileName,
    });
  } catch (e) {
    yield put(showAlert());
    debug(e);
  } finally {
    yield put(
      actions.updateFileField({
        documentId: file.documentId,
        fieldName: 'downloading',
        deleteField: true,
        path,
      }),
    );
  }
}

function* showProgressAlert({ title, message, displayTime = -1 }) {
  yield put(
    showAlert({
      message: (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ mr: '12px' }}>
            <Box sx={{ fontWeight: 800 }}>{title} </Box>
            <Box>{message}</Box>
          </Box>
          <CircularProgress />
        </Box>
      ),
      displayTime,
    }),
  );
}

function* statusChangeSuccessMsg() {
  yield put(
    showAlert({
      message: (
        <Box>
          <Box sx={{ fontWeight: 800 }}> Status Changed</Box>
          <Box>Status has been changed</Box>
        </Box>
      ),
      variant: 'success',
    }),
  );
}

function* statusChangeErrorMsg(e) {
  if (e?.data?.StatusChangeFailed) {
    const message = e?.data?.StatusChangeFailed;
    yield put(showAlert({ message, variant: 'error' }));
  } else {
    yield put(
      showAlert({
        message:
          'Invoice status could not be updated - try again in a few minutes.Please contact support if this problem continues.',
        variant: 'error',
      }),
    );
  }
}

export function* updateInvoiceStatus(api, debug, params) {
  let funcSuccess = true;
  try {
    yield put(
      actions.setLoading({ variant: 'updateInvoiceStatus', loading: true }),
    );
    yield call(showProgressAlert, {
      title: 'Status Change',
      message: 'Status change in progress',
    });
    const projectId = yield select(currentProject);
    const { invoiceStatus } = params;

    if (POSSIBLE_INVOICE_STATUSES.includes(invoiceStatus) === false) {
      throw new Error(
        `Invalid invoice status: ${invoiceStatus}  Must be one of:${POSSIBLE_INVOICE_STATUSES}`,
      );
    }

    const invoiceId = yield select(getCurrentInvoiceId);

    const response = yield call(api.digitalEdits.updateInvoice, {
      projectId,
      invoiceId,
      invoiceStatus,
    });

    if (!response.data) {
      // can't rely on the http status of the response, so need to check for the `data` item on the response.
      console.error(response);
      throw new Error(response);
    }
    //Manually update invoice status
    const invoiceReadOnly = yield select(getInvoice);
    let invoice = _.cloneDeep(invoiceReadOnly);

    const newData = {
      status: response.data.status,
      userFriendlyStatus: response.data.userFriendlyStatus,
    };

    invoice = { ...invoice, ...newData };

    invoice = Object.freeze(invoice);

    yield put(actions.updateInvoice({ invoice }));

    yield call(statusChangeSuccessMsg);
  } catch (e) {
    debug(e);
    funcSuccess = false;
    yield statusChangeErrorMsg(e);
  } finally {
    yield put(
      actions.setLoading({ variant: 'updateInvoiceStatus', loading: false }),
    );
  }
  return funcSuccess;
}

export function* resubmitInvoice(api, debug, params) {
  try {
    const { commentText } = params;
    if (commentText) {
      const unsubmittedComment = yield select(getUnsubmittedComment);

      const { commentId } = unsubmittedComment;

      yield call(saveComment, api, debug, { commentText, commentId });
    }
    yield delay(100); //wait for comment to be saved

    let count = 0;
    const countLimit = 5;
    let unSubmittedCommentId, comment, comments;

    //get the commentId of the unsubmitted comment
    while (!unSubmittedCommentId && count < countLimit) {
      comments = yield select(getComments);

      comment = comments.find(c => c.status === COMMENT_STATUS_MAP.Unsubmitted);
      unSubmittedCommentId = comment?.commentId;
      if (!unSubmittedCommentId) {
        yield delay(300);
      }
      count++;
    }
    if (count === countLimit) {
      throw new Error('No unsubmitted comments found');
    }

    yield call(updateInvoiceStatus, api, debug, { invoiceStatus: 'R' });
    yield put(
      actions.setLoading({ variant: 'resubmitInvoice', loading: true }),
    );
    yield delay(1000);

    count = 0;
    while (
      comment.status === COMMENT_STATUS_MAP.Unsubmitted &&
      count < countLimit
    ) {
      yield delay(1750);
      console.debug(
        `Polling for updated comments. Attempt:${count} of ${countLimit}`,
      );
      yield call(fetchComments, api, debug);
      comments = yield select(getComments);
      comment = comments.find(c => c.commentId === unSubmittedCommentId);
      count++;
    }
    if (count === countLimit) {
      throw new Error('Comment not submitted');
    }
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(
      actions.setLoading({ variant: 'resubmitInvoice', loading: false }),
    );
  }
}

export function* approveInvoice(api, debug, params) {
  try {
    yield put(actions.setLoading({ variant: 'approving', loading: true }));
    const projectId = yield select(currentProject);

    const { deliveryMethod, checkDate, note } = params;
    const files = yield select(getUploadedDeliveryFiles);
    const documentId = files[0]?.documentId || null;
    let preApprovalInvoice = yield select(getInvoice);
    const invoiceId = yield select(getCurrentInvoiceId);
    let savedComment;
    if (deliveryMethod?.sendToQ) {
      const { commentText, commentId } = note;

      savedComment = yield call(saveComment, api, debug, {
        commentText,
        commentId,
        category: 'InvoiceDelivery',
      });
    }
    if (
      documentId &&
      (deliveryMethod?.code === 20 || deliveryMethod?.code === 16)
    ) {
      const rsp = yield call(api.digitalEdits.deleteFile, {
        projectId,
        documentId,
      });
      if (rsp.status === 200 || rsp.status === 204) {
        //delete file from store
        yield put(actions.deleteFile({ documentId, path: 'uploaded' }));
      }
    }
    const response = yield call(api.digitalEdits.approveInvoice, {
      projectId,
      invoiceId,
      deliveryMethod: deliveryMethod.code,
      checkDate,
    });
    if (!response.data) {
      // can't rely on the http status of the response, so need to check for the `data` item on the response.
      console.error(response);
      throw new Error(response);
    }

    // manually update invoice
    let invoice = _.cloneDeep(preApprovalInvoice);
    const newData = {
      status: response.data.status,
      userFriendlyStatus: response.data.userFriendlyStatus,
    };
    if (response.data.futureReleaseDate) {
      newData.futureReleaseDate = response.data.futureReleaseDate;
    }
    if (response.data.printPriority) {
      newData.printPriority = `${response.data.printPriority}`;
    }
    invoice = { ...invoice, ...newData };
    yield put(actions.updateInvoice({ invoice }));

    yield call(statusChangeSuccessMsg);

    if (savedComment) {
      let count = 0;
      let countLimit = 5;
      yield call(fetchComments, api, debug);
      yield delay(5); //let fetch comments finish being stored

      let comments = yield select(getComments);
      let comment = comments.find(c => c.commentId === savedComment.commentId);
      while (
        comment?.status === COMMENT_STATUS_MAP.Unsubmitted &&
        count < countLimit
      ) {
        yield delay(2000);
        count++;
        console.debug(
          `Polling for updated comments. Attempt:${count} of ${countLimit}`,
        );
        yield call(fetchComments, api, debug);
        yield delay(5); //let fetch comments finish being stored
        comments = yield select(getComments);
        comment = comments.find(c => c.commentId === savedComment.commentId);
      }
    }
    yield put(hideModal({ dialog: 'ApproveInvoice' }));
  } catch (e) {
    debug(e);
    yield statusChangeErrorMsg(e);
  } finally {
    yield put(actions.setLoading({ variant: 'approving', loading: false }));
  }
}

function* fetchEditReports(api, debug, params) {
  try {
    yield put(actions.setLoading({ variant: 'viewReports', loading: true }));
    const { invoiceId } = params;
    const projectId = yield select(currentProject);
    const editReports = yield call(api.digitalEdits.fetchEditReports, {
      projectId,
      invoiceId,
    });
    if (!editReports || Array.isArray(editReports) === false) {
      throw new Error('Error fetching edit reports');
    }
    yield put(actions.storeEditReports({ editReports }));
    yield put(actions.setLoading({ variant: 'viewReports', loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.setLoading({ variant: 'viewReports', loading: false }));
    yield put(showAlert());
  }
}
function* downloadEditReport(api, debug, params) {
  try {
    const { endpoint, filename } = params;
    yield call(api.downloader.downloadFromURIGet, { endpoint, filename });
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

function* downloadSelectedFiles(api, debug, params) {
  try {
    const { reports = [] } = params;
    for (let i = 0; i < reports.length; i++) {
      let obj = reports[i];
      yield delay(100); //some files are missing so adding delay
      yield call(api.downloader.downloadFromURIGet, {
        endpoint: obj.preSignedUrl,
        filename: obj.fileName,
      });
    }
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

export function* lockComment(api, debug, params) {
  try {
    const projectId = yield select(currentProject);

    let { invoiceId } = params;
    if (!invoiceId) {
      invoiceId = yield select(getCurrentInvoiceId);
    }

    const lockData = yield call(api.digitalEdits.lockComment, {
      projectId,
      invoiceId,
    });
    yield put(actions.storeCommentLocked({ invoiceId, lockData }));
  } catch (e) {
    debug(e);
  }
}
export function* unlockComment(api, debug, params) {
  try {
    const projectId = yield select(currentProject);
    const invoiceId = yield select(getCurrentInvoiceId);
    const lockData = yield call(api.digitalEdits.unlockComment, {
      projectId,
      invoiceId,
    });
    yield put(actions.storeCommentLocked({ invoiceId, lockData }));
  } catch (e) {
    debug(e);
  }
}

export function* fetchRedirectInfo(api, debug, params) {
  const projectId = yield select(currentProject);
  try {
    const token = params.token;
    const data = yield call(api.digitalEdits.fetchRedirectInfo, {
      projectId,
      token,
    });
    const invoiceId = data.invoiceId;
    const url = `/projects/${projectId}/digital-edits/${invoiceId}`;
    yield put(push(url));
  } catch (e) {
    debug(e);
    yield put(
      showAlert({
        message: 'Invalid invoice Id. Redirecting to invoices page',
      }),
    );
    yield put(push(`/projects/${projectId}/search-invoices`));
  }
}

export function* navToStudioPlus(api, debug, params) {
  try {
    const projectId = yield select(currentProject);
    const invoiceId = yield select(getCurrentInvoiceId);
    const respObj = yield call(api.digitalEdits.getStudioUrl, {
      projectId,
      invoiceId,
    });

    //studio plus url
    if (respObj.directoryUrl && !respObj.errorMessage) {
      window.location.href = respObj.directoryUrl;
    } else {
      yield put(
        showAlert({
          message:
            'The folder in Studio+ could not be found. If the issue persists, contact support.',
        }),
      );
    }
  } catch (e) {
    debug(e);
  }
}

export function* downloadExpressForm(api, debug, params) {
  try {
    const projectId = yield select(currentProject);
    const { code } = params;
    yield call(api.downloader.downloadFixedFile, { projectId, code });
  } catch (e) {
    debug(e);
  }
}

export function* deleteComment(api, debug, params) {
  try {
    const projectId = yield select(currentProject);
    yield put(actions.setLoading({ variant: 'deleteComment', loading: true }));

    const { commentId, noSnackbar } = params;
    const invoiceId = yield select(getCurrentInvoiceId);

    yield call(api.digitalEdits.deleteComment, {
      projectId,
      invoiceId,
      commentId,
    });

    const comments = yield select(getComments);

    const newComments = _.cloneDeep(comments).filter(
      c => c.commentId !== commentId,
    );
    yield put(actions.storeComments({ comments: newComments }));
    if (!noSnackbar) {
      yield put(showAlert({ message: 'Comment deleted', variant: 'success' }));
    }
  } catch (e) {
    debug(e);
    yield put(
      showAlert({ message: 'Error deleting comment', variant: 'error' }),
    );
  } finally {
    yield put(actions.setLoading({ variant: 'deleteComment', loading: false }));
  }
}

//TODO - revisit this as it was a late addition and could probably be done better
// probably handle delete and add separately to avoid initial sync mis-match...
export function* syncUnsubmittedComment(api, debug, params) {
  try {
    const { newComment } = params;
    const oldComments = yield select(getComments);
    const comments = oldComments.slice();

    if (_.isEmpty(newComment)) {
      const commentIndex = comments.findIndex(
        c => c.status === COMMENT_STATUS_MAP.Unsubmitted,
      );
      if (commentIndex !== -1) {
        comments.splice(commentIndex, 1);
      }
    } else {
      const commentIndex = comments.findIndex(
        c => c.commentId === newComment.commentId,
      );
      if (commentIndex !== -1) {
        comments[commentIndex] = newComment;
      } else {
        comments.unshift(newComment);
      }
    }
    yield put(actions.storeComments({ comments }));
  } catch (e) {
    debug(e);
  }
}

export function* fetchEpisodes(api, debug, params) {
  try {
    const projectWorkSightId = yield delayOnValue(getCurrentProjectWorkSight);

    const type = 'episode';
    const parentValue = projectWorkSightId;
    const episodes = yield call(api.wtc.searchByType, {
      type,
      params: { ...params, parentValue },
    });
    yield put(actions.storeEpisodes({ episodes }));
  } catch (e) {
    yield put(showAlert());
    debug(e);
  }
}

export function* editAcFields(api, debug, params) {
  try {
    yield put(actions.setLoading({ variant: 'editFields', loading: true }));

    const { recordId } = params;

    const invoiceId = yield select(getCurrentInvoiceId);
    const batchesOpened = yield select(getBatchesOpened);

    const invoice = yield select(getInvoice);
    const invoiceStatus = invoice.status;
    if (invoiceStatus !== 'W') {
      const changeSuccess = yield call(updateInvoiceStatus, api, debug, {
        invoiceStatus: 'W',
      });
      signalrDb('Invoice status change success');
      if (!changeSuccess) {
        console.error('Error changing invoice status');
        return;
      }
      yield race({
        timeout: call(statusChangeTimeout),
        completed: take(`${actions.statusChangeSuccessful}`),
      });
    }

    if (!batchesOpened) {
      yield call(showProgressAlert, {
        title: 'Open Batches',
        message: 'Reopening batches for invoice',
      });
    }

    const projectId = yield select(currentProject);
    const resp = yield call(api.digitalEdits.reopenBatches, {
      projectId,
      invoiceId,
    });

    if (resp !== true) {
      throw new Error('Error reopening batches');
    }
    yield put(actions.setAcEditRecord({ acEditRecord: recordId }));

    if (!batchesOpened) {
      yield put(showAlert({ message: 'Batches reopened', variant: 'success' }));
    }
    yield put(actions.setBatchesOpened({ batchesOpened: true }));
    yield put(actions.setLoading({ variant: 'editFields', loading: false }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(actions.setLoading({ variant: 'editFields', loading: false }));
  }
}

export function* statusChangeComplete(api, debug, params) {
  try {
    const invoiceId = yield select(getCurrentInvoiceId);

    const { sourceId, isSuccessful } = params;
    db('statusChangeComplete', params);
    if (isSuccessful && sourceId === invoiceId) {
      yield put(actions.statusChangeSuccessful());
    }
  } catch (e) {
    debug(e);
  }
}

function* statusChangeTimeout() {
  yield delay(30000);
  console.error('Status change confirmation not received after 30 seconds');
}

export function* saveACE(api, debug, params) {
  try {
    yield put(actions.setLoading({ variant: 'savingACE', loading: true }));
    const projectId = yield select(currentProject);
    const invoiceId = yield select(getCurrentInvoiceId);
    const { data, recordId } = params;
    let timecardEntryHeaders = data.map(row => row.timecardEntryHeaderId);
    timecardEntryHeaders = Array.from(new Set(timecardEntryHeaders));

    const recordHasErrors = yield select(getHasErrors, recordId);
    const recordHasUnsavedError = yield select(getHasUnsavedErrors, recordId);

    const errorStateBeforeSave = recordHasErrors || recordHasUnsavedError;

    yield put(actions.clearRecordHasErrors({ recordId }));
    const headerIds = getTcHeadersFromRecordId(recordId);

    yield put(actions.storeErrorHeaderIds({ remove: headerIds }));
    yield put(actions.storeTimeoutHeaderIds({ remove: headerIds }));

    const payload = yield call(prepDataForSave, api, debug, data);

    signalrDb('Sending payload to HTG', payload);

    const singleHeaderPayload = payload.length === 1;
    let newSaveCCList = null;
    if (singleHeaderPayload && errorStateBeforeSave) {
      const singleCC = yield call(
        checkSingleHeaderForSingleCCTimecard,
        payload,
      );
      if (singleCC) {
        newSaveCCList = getCCFromRecordId(recordId);
      }
    }
    const saveResult = yield call(api.digitalEdits.saveACE, {
      projectId,
      invoiceId,
      timecards: payload,
    });

    const resTimecards = saveResult.timecards;
    const htgTransactionId = saveResult.htgTransactionId;
    const toRollback = new Set();
    const toError = new Set();

    resTimecards.forEach(result => {
      const { timecardEntryHeaderId, errors = [] } = result;
      let anyError = false;
      let anySplit = false;
      if (errors.length > 0) {
        errors.forEach(error => {
          if (error.isSplit) {
            anySplit = true;
          }
          if (error.isError) {
            anyError = true;
          }
        });
      }
      if (anyError) {
        toError.add(timecardEntryHeaderId);
      }
      if (anySplit) {
        toRollback.add(timecardEntryHeaderId);
      }
    });

    const error = Array.from(toError);
    const rollback = Array.from(toRollback);

    const addToPending = Array.from(timecardEntryHeaders).filter(
      tcHeader => error.includes(tcHeader) === false,
    );

    if (error.length) {
      yield put(actions.storeErrorHeaderIds({ add: error }));
    }

    yield call(rollbackSplits, rollback, debug);
    if (addToPending.length === 0) {
      console.error('Save failed for all records');
      yield call(displayErrorMsg, recordId);
    } else {
      yield put(
        actions.storePendingTimecards({
          add: addToPending,
          htgTransactionId,
          recordId,
          newSaveCCList,
        }),
      );

      db('ACE saved successfully');
    }
    yield put(actions.setAcEditRecord({ acEditRecord: '' }));

    yield put(actions.setLoading({ variant: 'savingACE', loading: false }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(actions.setLoading({ variant: 'savingACE', loading: false }));
  }
}

function prepDataForSave(api, debug, data) {
  const tcGroup = {};
  const aceData = _.cloneDeep(data);

  aceData.forEach((row, i) => {
    const { episode: fullEpisode, earnedDate, isDuplicateRow, id } = row;

    const headerId = row.timecardEntryHeaderId;
    const isAllowance = row.isAllowance;
    if (isDuplicateRow) return;

    delete row.ccTimecardNumber;
    delete row.timecardEntryHeaderId;
    delete row.isDuplicateRow;
    delete row.isAllowance;
    delete row.id;

    const episode = fullEpisode
      ? { id: fullEpisode.id, code: fullEpisode.code }
      : undefined;
    if (episode) row.episode = episode;
    row.earnedDate = earnedDate
      ? moment(earnedDate).format('YYYY-MM-DD')
      : null;
    if (tcGroup[headerId] === undefined) {
      tcGroup[headerId] = {
        timecardEntryHeaderId: headerId,
        details: [],
        allowances: [],
      };
    }
    if (isAllowance) {
      const allowanceRow = { ...row, id };
      tcGroup[headerId].allowances.push(allowanceRow);
    } else {
      tcGroup[headerId].details.push(row);
    }
  });

  const payload = Object.values(tcGroup).map(timecard => {
    //parse allowances
    const allowances = timecard.allowances;
    const allowanceGroup = {};
    allowances.forEach(allowance => {
      const {
        earnedDate, //fields to remove from allowance before save
        subRowIds, //fields to remove from allowance before save
        isChildRow, //fields to remove from allowance before save
        isFreshRow, //fields to remove from allowance before save
        id,
        ...alloPayload
      } = allowance;

      const groupId = id.split('-')[0];

      if (allowanceGroup[groupId] === undefined) {
        allowanceGroup[groupId] = [];
      }

      alloPayload.date = allowance.earnedDate;

      if (allowance.amount) {
        alloPayload.hours = 1;
        alloPayload.rate = alloPayload.amount;
      }
      allowanceGroup[groupId].push({
        ...alloPayload,
      });
    });
    const parsedAllowances = Object.keys(allowanceGroup).map(groupId => {
      const items = allowanceGroup[groupId];
      if (items.length === 1) {
        delete items[0].rowId;
      }
      const earnedDate = items[0].date;
      const htgTimecardDistributionId = items[0].htgTimecardDistributionId;

      items.forEach(item => {
        delete item.htgTimecardDistributionId;
      });

      return {
        htgTimecardDistributionId,
        earnedDate,
        items,
      };
    });
    timecard.allowances = parsedAllowances;
    return timecard;
  });

  return payload;
}

export function* rollbackSplits(rollback, debug) {
  try {
    for (let i = 0; i < rollback.length; i++) {
      const timecardEntryHeaderId = rollback[i];
      db('rollbackSplits for:', timecardEntryHeaderId);
      yield put(actions.triggerSplitRevert({ timecardEntryHeaderId }));
      yield delay(500);
    }
  } catch (e) {
    debug(e);
  } finally {
  }
}

//save/calc handler
export function* signalRNotificationHandler(api, debug, params) {
  try {
    let pendingTasks = yield select(getPendingTasks);

    const {
      jobId: timecardEntryHeaderId,
      isSuccessful,
      sourceId,
      action,
      htgTransactionId,
      checkSequenceInProgress,
      checkSequence,
    } = params;

    db('SignalR Notification:', timecardEntryHeaderId);
    db('Notification', params);
    db(JSON.stringify(pendingTasks));
    const taskId = getTaskId(timecardEntryHeaderId, pendingTasks);
    if (!!taskId) {
      const ccTimecardNumber = Number(sourceId);
      yield put(actions.resetACETimer({ taskId }));

      if (taskId !== htgTransactionId) {
        console.error('TaskId mismatch', taskId, htgTransactionId);
        // throw new Error('TaskId mismatch');
      }

      const task = pendingTasks[taskId];

      if (action === 'RequestPayrollDistributions') {
        //just reset the timer
        db('RequestPayrollDistributions task:', taskId);
        return;
      }

      if (!isSuccessful) {
        console.error('ACE Save Error:', params);

        if (action === 'TimecardSaveRollback') {
          db('Rollback Allowance Splits');
          yield put(actions.triggerSplitRevert({ timecardEntryHeaderId }));
        }

        yield put(actions.saveACEFailed({ taskId }));
        return;
      }

      if (
        action === 'TimecardDistributionImport' &&
        checkSequenceInProgress === null &&
        checkSequence &&
        task.newSaveCCList &&
        task.newSaveCCList.includes(sourceId)
      ) {
        dbACE('new save v2 complete');
        const timecards = pendingTasks[taskId].timecards;

        const pendingHeaders = Object.keys(timecards);

        for (let i = 0; i < pendingHeaders.length; i++) {
          const headerId = pendingHeaders[i];
          const ccTimecards = timecards[headerId];
          const remove = { [headerId]: ccTimecards };
          yield put(
            actions.storePendingTimecards({ remove, htgTransactionId: taskId }),
          );
        }
        yield delay(400); //let storePendingTimecards propagate
      }

      const remove = { [timecardEntryHeaderId]: [ccTimecardNumber] };
      yield put(
        actions.storePendingTimecards({ remove, htgTransactionId: taskId }),
      );
      yield put(actions.updateIsEdited({ timecardEntryHeaderId }));
      yield delay(400); //let storePendingTimecards propagate

      if (action === 'DeletePayrollTimecard') {
        yield put(
          actions.storePendingTimecards({
            ccToDelete: ccTimecardNumber,
            htgTransactionId: taskId,
          }),
        );
      }

      pendingTasks = yield select(getPendingTasks);

      db('After Removal', params);
      db(JSON.stringify(pendingTasks));

      const pending = pendingTasks[taskId];
      const pendingTimecards = pending?.timecards || {};

      let anyRemaining = false;
      Object.keys(pendingTimecards).forEach(tcHeader => {
        if (pendingTimecards[tcHeader].length > 0) {
          anyRemaining = true;
        }
      });
      db('anyRemaining', anyRemaining);
      if (!anyRemaining) {
        yield put(actions.saveACECompleted({ taskId }));
      }
    }
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

function* getName(recordId) {
  const records = yield select(getRecords);
  const record = records.find(r => r.recordId === recordId);
  return record?.employeeName || '';
}

export function* startACETimer(api, debug) {
  try {
    while (true) {
      yield delay(2000);

      const records = yield select(getRecords);
      if (_.isEmpty(records)) {
        continue;
      }

      const pendingTasks = yield select(getPendingTasks);

      const taskIds = Object.keys(pendingTasks);
      for (let i = 0; i < taskIds.length; i++) {
        const taskId = taskIds[i];
        const task = pendingTasks[taskId];
        const now = new Date().getTime();
        const diff = now - task.timestamp;
        if (diff > 90000) {
          let hasPending = false;
          const timecards = task.timecards;
          Object.keys(timecards).forEach(tcHeader => {
            const pendingCC = timecards[tcHeader];
            if (pendingCC.length > 0) {
              hasPending = true;
            }
          });

          if (hasPending) {
            console.error('ACE Save Timeout');
            yield put(actions.saveACEFailed({ taskId, reason: 'timeout' }));
          } else {
            db('ACE Save Completed triggered from timeout check');
            yield put(actions.saveACECompleted({ taskId }));
          }
        }
      }
    }
  } catch (e) {
    debug(e);
  }
}

export function* saveACECompleted(api, debug, params) {
  try {
    const { taskId } = params;
    const projectId = yield select(currentProject);
    const invoiceId = yield select(getCurrentInvoiceId);

    const pendingTasks = yield select(getPendingTasks);
    const pending = pendingTasks[taskId];
    const pendingTimecards = pending.timecards;
    const timecardHeaderIds = Object.keys(pendingTimecards);

    const deleteOnComplete = pending.deleteOnComplete;

    if (deleteOnComplete.length > 0) {
      yield put(actions.deleteCCTimecard({ deleteOnComplete }));
      yield delay(50);
    }

    const data = yield call(api.digitalEdits.refreshACE, {
      projectId,
      invoiceId,
      timecardHeaderIds,
    });

    prepDistributionData(data.timecards);

    const records = yield select(getRecords);
    const recordIds = records
      .map(r => r.recordId)
      .filter(recordId =>
        timecardHeaderIds.some(headerId => recordId.includes(headerId)),
      );
    //save success clear unsaved changes
    for (let i = 0; i < recordIds.length; i++) {
      const recordId = recordIds[i];
      yield put(actions.storeUnsavedEdits({ recordId }));
    }

    for (let i = 0; i < timecardHeaderIds.length; i++) {
      const tcHeader = timecardHeaderIds[i];
      yield put(actions.storePendingTimecards({ reset: tcHeader }));
    }

    yield delay(400); //let storeUnsavedEdits propagate
    yield put(actions.storeTimecardsUpdate({ data }));

    const name = yield call(getName, recordIds[0]);

    yield put(
      showAlert({
        message: `Record for ${name} successfully updated`,
        variant: 'success',
      }),
    );
  } catch (e) {
    const { taskId } = params;
    debug(e);
    yield put(actions.saveACEFailed({ error: e, taskId }));
  }
}

export function* saveACEFailed(api, debug, params) {
  try {
    const { taskId, reason = 'Error' } = params;
    const pendingTasks = yield select(getPendingTasks);
    const pendingTimecards = pendingTasks[taskId].timecards;

    const recordId = pendingTasks[taskId].recordId;
    if (reason !== 'timeout') {
      yield put(actions.updateLocalOriginal({ recordId }));

      yield call(displayErrorMsg, recordId);
    }
    console.error(
      `Save failed during ACE save transaction ${taskId}:`,
      '\nreason:',
      reason,
      '\nPending timecards:',
      pendingTimecards,
    );

    const deleteOnComplete = pendingTasks[taskId].deleteOnComplete;
    if (deleteOnComplete.length > 0) {
      yield put(actions.deleteCCTimecard({ deleteOnComplete }));
      yield delay(50);
    }

    const pendingHeaderIds = Object.keys(pendingTimecards);
    for (let i = 0; i < pendingHeaderIds.length; i++) {
      const tcHeader = pendingHeaderIds[i];
      yield put(actions.storePendingTimecards({ reset: tcHeader }));
    }

    if (reason === 'timeout') {
      yield put(actions.storeTimeoutHeaderIds({ add: pendingHeaderIds }));
    } else {
      yield put(actions.storeErrorHeaderIds({ add: pendingHeaderIds }));
    }
  } catch (e) {
    debug(e);
  }
}

function* checkSingleHeaderForSingleCCTimecard(payload) {
  const timecard = payload[0];
  const headerId = timecard.timecardEntryHeaderId;
  const ccTimecards = new Set();
  const records = yield select(getRecords);

  records.forEach(r => {
    if (r.recordId.includes(headerId)) {
      r.distributions
        .filter(d => d.timecardEntryHeaderId === headerId)
        .forEach(d => {
          ccTimecards.add(d.ccTimecardNumber);
        });
    }
  });

  const ccArray = Array.from(ccTimecards);

  return ccArray.length === 1;
}

function* displayErrorMsg(recordId) {
  const name = yield call(getName, recordId);
  yield put(
    showAlert({
      variant: 'error',
      message: (
        <Box>
          <Box>{`Update failed for ${name}.`}</Box>
          <Box>
            Review and try again. Contact support if this error persists.
          </Box>
        </Box>
      ),
    }),
  );
}

/**
 * Update local record with what should have been saved in HTG
 */
export function* updateLocalOriginal(api, debug, params) {
  try {
    const { recordId } = params;

    //Ensure we have enough time for split rollbacks to complete before updated local
    yield delay(1000);

    const unsavedEditsStr = yield select(getUnsavedRecordEdits, recordId);
    if (typeof unsavedEditsStr === 'string') {
      const unsavedEdits = JSON.parse(unsavedEditsStr);
      const { unsaved, original } = unsavedEdits;

      Object.keys(unsaved).forEach(rowId => {
        const unsavedRow = unsaved[rowId];
        const originalRow = original[rowId];
        Object.keys(unsavedRow).forEach(field => {
          originalRow[field] = unsavedRow[field];
        });
        delete unsaved[rowId];
      });

      const newUnsavedEdits = JSON.stringify(unsavedEdits);

      yield put(
        actions.storeUnsavedEdits({ recordId, unsavedEdits: newUnsavedEdits }),
      );
    }
  } catch (e) {
    debug(e);
  } finally {
  }
}

export default function* digitalEdits({ api, debug }) {
  yield all([
    takeLatest(`${actions.init}`, init, api, debug),
    takeLatest(`${actions.fetchData}`, fetchData, api, debug),
    takeLatest(`${actions.fetchInvoice}`, fetchInvoice, api, debug),
    takeLatest(
      `${actions.updateInvoiceStatus}`,
      updateInvoiceStatus,
      api,
      debug,
    ),
    takeEvery(`${actions.resubmitInvoice}`, resubmitInvoice, api, debug),
    takeLatest(`${actions.fetchComments}`, fetchComments, api, debug),
    takeLatest(
      `${actions.fetchTimecardSummary}`,
      fetchTimecardSummary,
      api,
      debug,
    ),
    takeLatest(`${actions.parseFilters}`, parseFilters, api, debug),
    takeLatest(
      `${actions.fetchTimecardDistributions}`,
      fetchTimecardDistributions,
      api,
      debug,
    ),
    takeLatest(`${actions.saveComment}`, saveComment, api, debug),
    takeLatest(
      `${actions.closeInvoiceComments}`,
      closeInvoiceComments,
      api,
      debug,
    ),
    takeLatest(`${actions.fetchInvoiceFiles}`, fetchInvoiceFiles, api, debug),
    takeEvery(`${actions.uploadFilesToDB}`, uploadFilesToDB, api, debug),
    takeEvery(`${actions.deleteFileFromDB}`, deleteFileFromDB, api, debug),
    takeEvery(`${actions.downloadFile}`, downloadFile, api, debug),

    takeEvery(
      `${actions.batchPendingFilesForUpload}`,
      batchPendingFiles,
      api,
      debug,
    ),
    takeEvery(`${actions.approveInvoice}`, approveInvoice, api, debug),
    takeEvery(`${actions.fetchTaxColumnMap}`, fetchTaxColumnMap, api, debug),
    takeEvery(`${actions.fetchEditReports}`, fetchEditReports, api, debug),
    takeEvery(`${actions.downloadEditReport}`, downloadEditReport, api, debug),
    takeEvery(
      `${actions.downloadSelectedFiles}`,
      downloadSelectedFiles,
      api,
      debug,
    ),
    takeEvery(`${actions.lockComment}`, lockComment, api, debug),
    takeEvery(`${actions.unlockComment}`, unlockComment, api, debug),
    takeEvery(`${actions.fetchRedirectInfo}`, fetchRedirectInfo, api, debug),
    takeEvery(`${actions.navToStudioPlus}`, navToStudioPlus, api, debug),
    takeEvery(
      `${actions.downloadExpressForm}`,
      downloadExpressForm,
      api,
      debug,
    ),
    takeEvery(`${actions.deleteComment}`, deleteComment, api, debug),
    takeEvery(
      `${actions.syncUnsubmittedComment}`,
      syncUnsubmittedComment,
      api,
      debug,
    ),
    takeEvery(`${actions.fetchEpisodes}`, fetchEpisodes, api, debug),
    takeEvery(`${actions.editAcFields}`, editAcFields, api, debug),
    takeEvery(`${actions.saveACE}`, saveACE, api, debug),
    takeEvery(
      `${timecardDistributionImport}`,
      signalRNotificationHandler,
      api,
      debug,
    ),
    takeLatest(`${actions.saveACECompleted}`, saveACECompleted, api, debug),
    takeLatest(`${actions.saveACEFailed}`, saveACEFailed, api, debug),
    takeLatest(`${actions.startACETimer}`, startACETimer, api, debug),
    takeEvery(
      `${invoiceStatusChangeComplete}`,
      statusChangeComplete,
      api,
      debug,
    ),
    takeLatest(
      `${actions.updateLocalOriginal}`,
      updateLocalOriginal,
      api,
      debug,
    ),
  ]);
}
