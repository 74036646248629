import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Divider, Paper, Hidden } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

// Utils
import {
  getActiveBlockedDays,
  formatDate,
  isFieldEnabled,
  allEditableFields,
} from 'utils/weekUtils';
import { isRegionCanada, hasTimeFieldValue } from 'utils/helperFunctions';

// actions
import { resetAllowanceInput } from 'actions/timecards';

// components
import TimecardHeader from './TimecardHeader';
import { projectProps } from 'components/props/projects';
import { timecardProps } from 'components/props/timecard';
import { userInfoProps } from 'components/props/profiles';
import TimecardActions from './TimecardActions';
import CalendarRow from './CalendarRow';
import TimecardDays from './Desktop/TimecardDays';
import TimecardDaysMobile from './Mobile/TimecardDays';
import NotesAndHistory from './NotesAndHistory';
import EmployeeSignature from './EmployeeSignature';
import Allowances from './Desktop/Allowances';
import AllowancesMobile from './Mobile/Allowances';

const style = theme => ({
  root: {
    marginBottom: 64, // Height of the bottom action bar.
  },
  divider: {
    marginBottom: 5,
    marginTop: 5,
  },
  footer: {
    display: 'flex',
    marginTop: 15,
  },
  buttons: { margin: theme.spacing(1) },
});

class Timecard extends Component {
  static propTypes = {
    activeUser: userInfoProps.isRequired,
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    onCopyToAll: PropTypes.func,
    project: projectProps.isRequired,
    projectSettings: PropTypes.object,
    timecard: timecardProps.isRequired,
    isViewOnly: PropTypes.bool,
    editReview: PropTypes.bool,
    userRole: PropTypes.string,
  };

  constructor(props) {
    super(props);
    const { project, timecard, projectSettings, isTeamTimecard } = this.props;
    const { activeDays, blockedDays } = getActiveBlockedDays(timecard, project);

    this.state = {
      activeDays,
      blockedDays,
      showAccountCodes:
        (!isTeamTimecard && projectSettings.accountCodesOpenByDefault) ||
        this.checkMandatoryAcctCodes(projectSettings, isTeamTimecard) ||
        false,
      showLastMan1In: hasTimeFieldValue(timecard, 'lastMan1In'),
      showNdm: hasTimeFieldValue(timecard, 'ndmIn'),
      showNdb: hasTimeFieldValue(timecard, 'ndbIn'),
    };

    this.onToggleDayActive = this.onToggleDayActive.bind(this);
    this.onToggleAccountCodes = this.onToggleAccountCodes.bind(this);

    this.setShowLastMan1In = this.setShowLastMan1In.bind(this);
    this.setShowNdm = this.setShowNdm.bind(this);
    this.setShowNdb = this.setShowNdb.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isReviewing, project, timecard, savingTimecard } = this.props;
    const previousViewOnly = prevProps.isViewOnly;
    const currentViewOnly = this.props.isViewOnly;

    //Remove active days with no day type selected.
    if (prevProps.savingTimecard === false && savingTimecard === true) {
      const days = timecard.days;
      const activeDays = {};
      days.forEach((day, i) => {
        const date = formatDate(day.date);
        const active = !!(day.isActive && day.htgDayTypeId);
        activeDays[date] = active;
      });
      this.setState({ activeDays: activeDays });
    }

    if (isReviewing && !previousViewOnly && currentViewOnly) {
      const { activeDays, blockedDays } = getActiveBlockedDays(
        timecard,
        project,
      );

      this.setState({
        activeDays,
        blockedDays,
      });
    }
  }

  checkMandatoryAcctCodes(projectSettings, isTeamTimecard) {
    const mandatoryFieldName = isTeamTimecard
      ? 'crewMandatory'
      : 'employeeMandatory';
    return _.some(projectSettings, mandatoryFieldName);
  }

  onToggleDayActive(date, active, index) {
    const { isViewOnly, timecard } = this.props;
    const editableFields = timecard.editableFields;
    const editableTimes = isFieldEnabled(editableFields, 'times');
    if (!isViewOnly || editableTimes) {
      const activeDays = this.state.activeDays;
      activeDays[formatDate(date)] = active;

      this.setState({ activeDays: activeDays });
      this.props.change(`days[${index}.isActive]`, active);
      if (!isViewOnly && !this.props.isUpdatingAutoAllow) {
        const cloneTimecard = _.cloneDeep(timecard);
        cloneTimecard.days[index].isActive = active;
        if (cloneTimecard.days[index]?.dayType) {
          this.props.onUpdateDailyAutoAllowances(cloneTimecard);
        }
      }
    }
  }

  onToggleAccountCodes(event, checked) {
    this.setState({
      showAccountCodes: checked,
    });
  }

  setShowLastMan1In(showLastMan1In) {
    this.setState({ showLastMan1In });
  }
  setShowNdm(showNdm) {
    this.setState({ showNdm });
  }
  setShowNdb(showNdb) {
    this.setState({ showNdb });
  }

  checkActiveDays(tc, activeDays) {
    tc.days.forEach((day, index) => {
      const date = formatDate(day.date);
      tc.days[index].isActive = activeDays[date];
    });
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetAllowanceInput());
  }

  render() {
    const {
      activeUser,
      projectSettings = {},
      allowances,
      allowanceInput,
      buttons,
      classes,
      department,
      allowanceEditable,
      editReview = false,
      enableESignature = false,
      isTeamTimecard,
      crewPartialDays = {},
      isViewOnly,
      loading,
      onCopyToAll,
      onSaveNote,
      project,
      showNotes = true,
      timecard,
      userRole,
      localNotes,
      loadDayTypes,
      setNoteEmpty,
      isReviewing = false,
      change,
      savingTimecard,
      isTimecardViewOnly,
      stateOptions,
      cityOptions,
      region,
      //
      onStateChange,
      employees,
    } = this.props;

    const {
      onFetchStates,
      onFetchCities,
      onResetLoc,
      onSelectAllowanceType,
      onSelectAllowanceAmount,
      onSelectAllowanceRate,
      onSelectAllowanceUnits,
      onSelectAllowanceCombineCheckCode,
      onUploadDocument,
      onResetAllowance,
      setAllowanceInProgress,
      onShowAlert,
    } = this.props;

    const { activeDays, blockedDays } = this.state;
    const comboErrors = _.merge(this.props.error, this.props.formSyncErrors);

    comboErrors?.days?.forEach(day => {
      if (_.isEmpty(day) === false) comboErrors.valid = false;
    });

    const editableFields = editReview
      ? allEditableFields()
      : timecard.editableFields;
    const editableTimes = isFieldEnabled(editableFields, 'times');
    let canDeleteTimecard =
      editReview || isTeamTimecard ? false : !isViewOnly && editableTimes;
    if (isTimecardViewOnly && editReview) {
      canDeleteTimecard = isTimecardViewOnly;
    }
    const checkActiveDays = (tc, activeDays) => {
      tc.days.forEach((day, index) => {
        const date = formatDate(day.date);
        tc.days[index].isActive = activeDays[date];
      });
    };
    checkActiveDays(timecard, activeDays);

    const isUserDeleted = activeUser.deletedAt !== null;
    const canBackTimecard =
      isTimecardViewOnly && !editReview ? true : !isTimecardViewOnly;

    const caRegion = isRegionCanada(project.region);
    let isCityVisible = true;
    const cityId = activeUser?.batchTemplate?.htgCityId;
    if (caRegion && timecard.days.length > 0) {
      const cityCheck = timecard.days.some(item => item.city) || !!cityId;
      if (!cityCheck) {
        isCityVisible = false;
      }
    }

    return (
      <React.Fragment>
        <Paper key={`timecard-${timecard.id}`} elevation={1}>
          <TimecardHeader
            caRegion={caRegion}
            department={department}
            editableFields={editableFields}
            enableESignature={enableESignature}
            error={this.props.error}
            isTeamTimecard={isTeamTimecard}
            isViewOnly={this.props.isViewOnly}
            onToggleAccountCodes={this.onToggleAccountCodes}
            project={project}
            role={userRole || activeUser.role}
            showAccountCodes={this.state.showAccountCodes}
            stateOptions={stateOptions}
            cityOptions={cityOptions}
            onFetchStates={onFetchStates}
            onFetchCities={onFetchCities}
            onResetLoc={onResetLoc}
            timecard={timecard}
            change={change}
            showLastMan1In={this.state.showLastMan1In}
            setShowLastMan1In={this.setShowLastMan1In}
            showNdm={this.state.showNdm}
            setShowNdm={this.setShowNdm}
            showNdb={this.state.showNdb}
            setShowNdb={this.setShowNdb}
          />
          <Divider className={classes.divider} />
          <CalendarRow
            key={`calendarRow-${timecard.id}`}
            className={classes.root}
            timecard={timecard}
            activeDays={activeDays}
            blockedDays={blockedDays}
            onChangeActive={this.onToggleDayActive}
            crewPartialDays={crewPartialDays}
            isUpdatingAutoAllow={this.props.isUpdatingAutoAllow}
          />
          <Hidden lgUp>
            <TimecardDaysMobile
              isCityVisible={isCityVisible}
              caRegion={caRegion}
              activeDays={activeDays}
              blockedDays={blockedDays}
              dayTypes={this.props.dayTypes}
              editableFields={editableFields}
              episodes={this.props.episodes}
              error={comboErrors}
              isViewOnly={this.props.isViewOnly}
              isTeamTimecard={isTeamTimecard}
              onCopyToAll={onCopyToAll}
              onSaveNote={onSaveNote}
              payrollSettings={this.props.payrollSettings}
              project={project}
              projectSettings={this.props.projectSettings}
              role={userRole || activeUser.role}
              showAccountCodes={this.state.showAccountCodes}
              stateOptions={stateOptions}
              cityOptions={cityOptions}
              timecard={timecard}
              workLocations={this.props.workLocations}
              loadDayTypes={loadDayTypes}
              change={this.props.change}
              setNoteEmpty={setNoteEmpty}
              showLastMan1In={this.state.showLastMan1In}
              isUserDeleted={isUserDeleted}
              onFetchStates={onFetchStates}
              onFetchCities={onFetchCities}
              onResetLoc={onResetLoc}
              region={region}
              showNdm={this.state.showNdm}
              showNdb={this.state.showNdb}
              onUpdateDailyAutoAllowances={
                this.props.onUpdateDailyAutoAllowances
              }
              isUpdatingAutoAllow={this.props.isUpdatingAutoAllow}
            />
          </Hidden>
          <Hidden lgDown>
            <TimecardDays
              isCityVisible={isCityVisible}
              caRegion={caRegion}
              activeDays={activeDays}
              blockedDays={blockedDays}
              dayTypes={this.props.dayTypes}
              editableFields={editableFields}
              episodes={this.props.episodes}
              error={comboErrors}
              isViewOnly={this.props.isViewOnly}
              isTeamTimecard={isTeamTimecard}
              onCopyToAll={onCopyToAll}
              onSaveNote={onSaveNote}
              payrollSettings={this.props.payrollSettings}
              project={project}
              projectSettings={this.props.projectSettings}
              role={userRole || activeUser.role}
              showAccountCodes={this.state.showAccountCodes}
              states={this.props.states}
              timecard={timecard}
              workLocations={this.props.workLocations}
              loadDayTypes={loadDayTypes}
              change={this.props.change}
              setNoteEmpty={setNoteEmpty}
              showLastMan1In={this.state.showLastMan1In}
              isUserDeleted={isUserDeleted}
              stateOptions={stateOptions}
              cityOptions={cityOptions}
              onStateChange={onStateChange}
              onFetchStates={onFetchStates}
              onFetchCities={onFetchCities}
              onResetLoc={onResetLoc}
              region={region}
              showNdm={this.state.showNdm}
              showNdb={this.state.showNdb}
              onUpdateDailyAutoAllowances={
                this.props.onUpdateDailyAutoAllowances
              }
              isUpdatingAutoAllow={this.props.isUpdatingAutoAllow}
            />
          </Hidden>
        </Paper>
        <Hidden lgUp>
          <AllowancesMobile
            allowances={allowances}
            allowanceInput={allowanceInput}
            className={classes.root}
            editableFields={editableFields}
            isViewOnly={isViewOnly}
            key="allowances"
            isTeamTimecard={isTeamTimecard}
            loading={this.props.isAllowanceProcessing}
            onDeleteAllowance={this.props.onDeleteAllowance}
            onDownloadDocument={this.props.onDownloadDocument}
            onSaveAllowance={this.props.onSaveAllowance}
            showAlert={this.props.onShowAlert}
            timecard={timecard}
            timecardAllowances={this.props.timecardAllowances}
            onSelectAllowanceType={onSelectAllowanceType}
            onSelectAllowanceAmount={onSelectAllowanceAmount}
            onSelectAllowanceRate={onSelectAllowanceRate}
            onSelectAllowanceUnits={onSelectAllowanceUnits}
            onSelectAllowanceCombineCheckCode={
              onSelectAllowanceCombineCheckCode
            }
            onUploadDocument={onUploadDocument}
            onResetAllowance={onResetAllowance}
            setAllowanceInProgress={setAllowanceInProgress}
            role={userRole || activeUser.role}
            rejectTcOnAllowanceChange={
              !!projectSettings.rejectTcOnAllowanceChange
            }
            showWarningAlert={projectSettings?.crewTimecardAutoAllowances}
            allowanceEditable={allowanceEditable}
            isReviewing={isReviewing}
            isUserDeleted={isUserDeleted}
            editReview={editReview}
            onShowAlert={onShowAlert}
            employees={employees}
          />
        </Hidden>
        <Hidden lgDown>
          <Allowances
            allowances={allowances}
            allowanceInput={allowanceInput}
            className={classes.root}
            editableFields={editableFields}
            isViewOnly={isViewOnly}
            key="allowances"
            isTeamTimecard={isTeamTimecard}
            loading={this.props.isAllowanceProcessing}
            onDeleteAllowance={this.props.onDeleteAllowance}
            onDownloadDocument={this.props.onDownloadDocument}
            onSaveAllowance={this.props.onSaveAllowance}
            showAlert={this.props.onShowAlert}
            timecard={timecard}
            timecardAllowances={this.props.timecardAllowances}
            onSelectAllowanceType={onSelectAllowanceType}
            onSelectAllowanceAmount={onSelectAllowanceAmount}
            onSelectAllowanceRate={onSelectAllowanceRate}
            onSelectAllowanceUnits={onSelectAllowanceUnits}
            onSelectAllowanceCombineCheckCode={
              onSelectAllowanceCombineCheckCode
            }
            onUploadDocument={onUploadDocument}
            onResetAllowance={onResetAllowance}
            setAllowanceInProgress={setAllowanceInProgress}
            role={userRole || activeUser.role}
            rejectTcOnAllowanceChange={
              !!projectSettings.rejectTcOnAllowanceChange
            }
            showWarningAlert={projectSettings?.crewTimecardAutoAllowances}
            allowanceEditable={allowanceEditable}
            isReviewing={isReviewing}
            isUserDeleted={isUserDeleted}
            editReview={editReview}
            onShowAlert={onShowAlert}
            employees={employees}
          />
        </Hidden>

        {showNotes && (
          <NotesAndHistory
            key="notes-history"
            className={classes.root}
            onSaveNote={onSaveNote}
            timecard={timecard}
            loading={loading}
            isViewOnly={this.props.isViewOnly}
            isNotesProcessing={this.props.isNotesProcessing}
            localNotes={localNotes}
            setNoteEmpty={setNoteEmpty}
            projectSettings={this.props.projectSettings}
          />
        )}
        <div style={{ marginBottom: 70 }} />

        <EmployeeSignature
          key="signature"
          timecard={timecard}
          open={enableESignature}
          onApproveTimecard={this.props.onApproveTimecard}
          onHideSignature={this.props.onHideSignature}
          isReadyForReview={this.props.isReadyForReview}
          isTimecardViewOnly={this.props.isTimecardViewOnly}
          savingTimecard={savingTimecard}
          comments={this.props.comments}
          project={project}
        />
        <TimecardActions
          buttons={buttons}
          key="actions"
          onTakeMeBack={this.props.onTakeMeBack}
          loading={this.props.loading}
          onDeleteTimecard={this.props.onDeleteTimecard}
          canDeleteTimecard={canDeleteTimecard}
          submitting={this.props.submitting || savingTimecard}
          canBackTimecard={canBackTimecard}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(style)(Timecard);
