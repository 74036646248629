import { produce } from 'immer';
import * as actions from 'actions/flag';

const initialState = {
  list: [],
  loading: false,
  toggleTheme: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.loading}`:
        draft.loading = action.loading;
        break;

      case `${actions.store}`:
        draft.list = action.flags;
        break;

      case `${actions.edit}`:
        draft.list.find(item => item.id === action.id).enabled = action.enabled;
        break;

      case `${actions.toggleTheme}`:
        draft.toggleTheme = !draft.toggleTheme;
        break;

      default:
    }
  });
