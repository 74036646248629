import { buildUrl } from '../api';

const URL_SEARCH_TIMECARDS = '/projects/:projectId/timecards/search';
const URL_SEARCH_TIMECARDS_FILTER = URL_SEARCH_TIMECARDS + '/filter';
const URL_REVIEW_BATCH = '/projects/:projectId/review/approval-flows/review';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async getData({ projectId, dataParams }) {
    const url = buildUrl(URL_SEARCH_TIMECARDS, { projectId });
    const rsp = await clientV2.post(url, dataParams);
    return rsp.data;
  },
  async filter({ projectId, filterParams }) {
    const type = filterParams.type;
    const url = buildUrl(URL_SEARCH_TIMECARDS_FILTER + `?${type}`, {
      projectId,
    });
    const rsp = await clientV2.post(url, filterParams);
    return rsp.data;
  },
  async deleteTimecards({ projectId, data }) {
    const url = buildUrl(URL_SEARCH_TIMECARDS, { projectId });
    const rsp = await clientV2.delete(url, { data });
    return rsp.data;
  },
  async reviewBatch({ projectId, data }) {
    const url = buildUrl(URL_REVIEW_BATCH, { projectId });
    const response = await clientV2.put(url, data);
    return response.data;
  },
});
