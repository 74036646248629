import React from 'react';

import { Chip, TextField, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import { FaTimes as RemoveIcon, FaRegTimesCircle } from 'react-icons/fa';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PopperMy from './PopperMy';

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

export const useStyles = makeStyles(({ palette }) => ({
  removeIcon: {
    height: '15px',
    color: palette.primary.main,
    margin: '2px 5px 0px -6px',
  },
  chipLabel: {
    color: palette.primary.main,
    background: palette.primary.background,
    marginBottom: '5px',
  },
  regTimesCircle: {
    fontSize: '1rem',
  },
  checked: {
    '&.Mui-checked': {
      color: palette.primary.main,
    },
  },
}));
const InvoiceTemplateAutoCompleteCheckboxes = props => {
  const {
    uniqueDepartments,
    editTemplate,
    departments,
    removeOption,
    handleChangeMultiSelectDepts,
  } = props;
  const classes = useStyles();

  //selected departments
  let selectedDepts = [];
  if (editTemplate?.departments) {
    selectedDepts = editTemplate.departments.map(id => {
      return departments.find(d => d.id === id);
    });
  }

  return (
    <Autocomplete
      PopperComponent={PopperMy}
      multiple
      clearIcon={<FaRegTimesCircle className={classes.regTimesCircle} />}
      id="checkboxes-tags-demo"
      disableCloseOnSelect
      options={uniqueDepartments}
      getOptionLabel={option => option?.name || ''}
      value={selectedDepts}
      renderTags={values => {
        return values.map(value => (
          <Chip
            className={classes.chipLabel}
            key={value.id}
            deleteIcon={<RemoveIcon className={classes.removeIcon} />}
            label={value.name}
            onDelete={() => removeOption(value.id)}
          />
        ));
      }}
      onChange={(e, values) => handleChangeMultiSelectDepts(e, values)}
      renderOption={(props, option, compState) => (
        <li {...props}>
          <Checkbox
            className={classes.checked}
            icon={icon}
            checkedIcon={checkedIcon}
            checked={compState.selected}
          />
          {option.name}
        </li>
      )}
      renderInput={params => <TextField {...params} placeholder="Search" />}
    />
  );
};
export default InvoiceTemplateAutoCompleteCheckboxes;
