import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { Field } from 'redux-form';
import WTCAutoCompleteField from './WTCAutoCompleteField';

const style = () => ({
  root: {
    // display: 'flex',
    // alignItems: 'flex-start',
    fontSize: 14,
  },
});

const WTCAutoComplete = ({
  classes,
  disabled,
  fieldLabel = 'label',
  fieldValue = 'value',
  klass,
  label,
  list,
  name,
  placeholder = ' ',
  isRequired = false,
  defaultSort = false,
  objectSelect = false,
  ...others
}) => {
  let mappingOptions = state =>
    objectSelect
      ? {
          ...state.ref,
          label: state[fieldLabel],
          // subset: state.subset,
          // subsetVal: state.subsetVal,
        }
      : {
          value: state[fieldValue],
          label: state[fieldLabel],
          subset: state.subset,
          subsetVal: state.subsetVal,
        };
  let options;
  if (defaultSort) {
    options = _.chain(list)
      .sortBy(option => option[fieldLabel])
      .map(mappingOptions)
      .value();
  } else {
    options = _.chain(list).map(mappingOptions).value();
  }
  return (
    <Field
      className={clsx(classes.root, klass)}
      component={WTCAutoCompleteField}
      name={name}
      label={label}
      placeholder=""
      // placeholder={placeholder.length ? 'Select...' : placeholder}
      disabled={disabled}
      options={options || []}
      inputName={name}
      required={isRequired}
      props={{ wtc: true }}
      objectSelect={objectSelect}
      {...others}
    />
  );
};

WTCAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  fieldLabel: PropTypes.string,
  fieldValue: PropTypes.string,
  label: PropTypes.string,
  klass: PropTypes.any,
  list: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
};

export default withStyles(style)(WTCAutoComplete);
