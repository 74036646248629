import React, { useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Prompt } from 'react-router-dom';

//actions
import * as actions from 'actions/dts';
import { show as showModal } from 'actions/modalDialog';
import { setPromptOnLeavePage } from 'actions/session';

//selectors
import * as sel from 'selectors/dts';

//components
import DTSFilters from 'feature/DTS/DTSFilters';
import DTSTable from 'feature/DTS/DTSTable';
import ConfirmDTSActionModal from './ConfirmDTSActionModal';

//util sync
import { setDealMemos } from 'feature/DTS/dtsUtils.js';

const mapState = (state, { match }) => ({
  filtersPristine: sel.getFiltersPristine(state),
  dtsDirty: sel.getIsDirty(state),
  dealMemos: sel.getDealMemos(state),
});

const mapDispatch = dispatch => ({
  onInit: () => {
    dispatch(actions.init());
  },
  onApplyFilters: () => {
    dispatch(actions.fetchData());
  },
  onSetDirty: isDirty => {
    dispatch(actions.setDirty({ isDirty }));
  },
  onSave: ({ data }) => {
    dispatch(actions.save({ data }));
  },
  onResetFilters: () => {
    dispatch(actions.resetFilters());
  },

  onConfirmModal: modalAction => {
    const { args } = modalAction;
    if (!Array.isArray(args)) {
      console.warn('confirmModal: args must be an array. Found:', args);
    }
    dispatch(
      actions.setModalAction({
        modalAction,
      }),
    );
    dispatch(showModal({ dialog: 'ConfirmDTSActionModal' }));
  },
  onCleanup: () => dispatch(actions.cleanup()),
  onSetLeavePrompt: isDirty =>
    dispatch(setPromptOnLeavePage({ promptOnLeavePage: isDirty })),
  onListTimecards: ({ data }) => dispatch(actions.listTimecards({ data })),
});

const DTS = props => {
  const {
    filtersPristine,
    dtsDirty,
    dealMemos,
    onInit,
    onSave,
    onApplyFilters,
    onResetFilters,
    onCleanup,
    onConfirmModal,
    onSetDirty,
    onSetLeavePrompt,
    onListTimecards,
  } = props;

  const didMountRef = useRef(false);
  const willUnmountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current === false) {
      onInit();
      didMountRef.current = true;
    }
  }, [onInit]);

  useEffect(() => {
    return () => {
      willUnmountRef.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (willUnmountRef.current === true) {
        onSetDirty(false);
        onCleanup();
      }
    };
  }, [onCleanup, onSetDirty]);

  useEffect(() => {
    setDealMemos(dealMemos);
  }, [dealMemos]);

  //set prompt on tab close/reload to sync with dtsDirty
  useEffect(() => {
    onSetLeavePrompt(dtsDirty);
    return () => {
      onSetLeavePrompt(false);
    };
  }, [dtsDirty, onSetLeavePrompt]);

  //data to be sent back to server on save
  const liveDataRef = useRef({});
  const [hasError, setHasError] = useState(false);
  const save = useCallback(
    () => onSave({ data: liveDataRef.current }),
    [onSave],
  );

  const listTimecards = () => onListTimecards({ data: liveDataRef.current });

  // Function confirmModal:
  //if DTS table has unsaved info, show modal before continuing
  /*
   * @param {func} func - function to call when confirm is clicked
   * @param {array} args - passed to func MUST BE AN ARRAY
   */
  const confirmModalIfDirty = useCallback(
    (func, args = [{}]) => {
      if (dtsDirty) {
        const variant = 'tcDirty';
        onConfirmModal({ func, args, save, variant, hasError, onSetDirty });
      } else {
        func(...args);
      }
    },
    [dtsDirty, hasError, onConfirmModal, onSetDirty, save],
  );

  return (
    <main style={{ display: 'flex' }}>
      <Prompt
        message="You have unsaved changes in Daily Timesheets. All unsaved changes will be lost when you leave. Are you sure you want to navigate away?"
        when={dtsDirty}
      />

      <section>
        <DTSFilters
          confirmModalIfDirty={confirmModalIfDirty}
          onApplyFilters={onApplyFilters}
          onResetFilters={onResetFilters}
          filtersPristine={filtersPristine}
        />
      </section>
      <section style={{ width: '100%' }}>
        <DTSTable
          liveDataRef={liveDataRef}
          save={save}
          listTimecards={listTimecards}
          dtsDirty={dtsDirty}
          confirmModalIfDirty={confirmModalIfDirty}
          onConfirmModal={onConfirmModal}
          onSetDirty={onSetDirty}
          setHasError={setHasError}
          hasError={hasError}
        />
      </section>
      <ConfirmDTSActionModal />
    </main>
  );
};

DTS.propTypes = {
  filtersPristine: PropTypes.bool,
  dtsDirty: PropTypes.bool,
  dealMemos: PropTypes.array,
  onInit: PropTypes.func,
  onSave: PropTypes.func,
  onApplyFilters: PropTypes.func,
  onResetFilters: PropTypes.func,
  onCleanup: PropTypes.func,
  onConfirmModal: PropTypes.func,
  onSetDirty: PropTypes.func,
  onSetLeavePrompt: PropTypes.func,
  onListTimecards: PropTypes.func,
};

export default compose(connect(mapState, mapDispatch))(DTS);
