import React from 'react';
import { Preferences } from 'containers/Employees/Profiles';
import { ProfileDetails as SubNav } from 'containers/Nav/Pages';

import { DefaultLayout, Header } from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    authenticated
    key="/me/settings"
    path="/me/settings"
    layout={DefaultLayout}
    headers={[Header, SubNav]}
    component={Preferences}
  />
);
