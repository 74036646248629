import React from 'react';
import _ from 'lodash';
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Project from '@mui/icons-material/TheatersRounded';
import Users from '@mui/icons-material/Group';
import Dollar from '@mui/icons-material/AttachMoneyRounded';
import User from '@mui/icons-material/Person';

import { ERRORS_DESCRIPTIONS, ERRORS_TITLES } from './messages';

const useStyles = makeStyles(theme => ({
  root: {},
  errorTitle: {
    margin: 5,
    fontWeight: 500,
  },
  tableCell: {
    verticalAlign: 'top',
    border: 'none',
  },
  errorCell: {
    verticalAlign: 'top',
    border: 'none',
    padding: 0,
  },
}));

export default function ErrorMessages({ errors, type }) {
  const classes = useStyles();

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className={classes.tableCell}>
            {type === 'project' && <Project style={{ fontSize: 40 }} />}
            {type === 'department_head' && <Users style={{ fontSize: 40 }} />}
            {type === 'payroll_accountant' && (
              <Dollar style={{ fontSize: 40 }} />
            )}
            {type === 'upm' && <User style={{ fontSize: 40 }} />}
          </TableCell>
          <TableCell className={classes.errorCell}>
            {_.map(errors, (error, index) => {
              return (
                <Table key={error}>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        <Typography color="inherit" variant="h6">
                          {ERRORS_TITLES[type][error]}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        <Typography color="inherit">
                          {ERRORS_DESCRIPTIONS[type][error]}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              );
            })}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
