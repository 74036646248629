import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from '@mui/material';

import CnCSvgLogo from 'components/images/logos/CnCSvgIcon';
const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    background: `linear-gradient(180deg, ${palette.primary['+3']} 0%, ${palette.primary['-3']} 100%);`,
    paddingLeft: 28,
  },
}));

const ErrorBar = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Link href="https://my.castandcrew.com">
        <CnCSvgLogo />
      </Link>
    </div>
  );
};

export default ErrorBar;
