import _ from 'lodash';
// import { createSelector } from 'reselect';

export const getCountriesV1 = state =>
  _.get(state, `locations.countriesV1`, []);
export const getStatesV1 = state => _.get(state, `locations.statesV1`, []);
export const getCitiesV1 = state => _.get(state, `locations.citiesV1`, []);
export const getCountiesV1 = state => _.get(state, `locations.countiesV1`, []);
export const getSubdivisionsV1 = state =>
  _.get(state, `locations.subdivisionsV1`, []);

export const getCacheStates = (state, projectId) =>
  _.get(state, `locations.cacheStates[${projectId}]`, undefined);
export const getCacheCities = (state, stateId) =>
  _.get(state, `locations.cacheCities[${stateId}]`, undefined);

export const getLoading = state => _.get(state, `locations.loading`, false);
