import React from 'react';
import { TableHead, TableCell, TableRow } from '@mui/material';

import CaretsUp from 'assets/icons/CaretsUp';
import CaretsDown from 'assets/icons/CaretsDown';
import Carets from 'assets/icons/Carets';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  tableHead: {
    color: palette.text.secondary,
    backgroundColor: palette.primary['+8'],
    fontWeight: 700,
  },
  tableCell: {
    cursor: 'pointer',
    padding: '10px',
    fontSize: 20,
    fontWeight: 500,
    position: 'sticky',
    top: 0,
    backgroundColor: palette.primary['+8'],
    borderBottom: `1px solid ${palette.primary.main}`,
    '& > svg': {
      verticalAlign: 'bottom',
    },
  },
  carrot: {
    height: '0.8em',
  },
}));
const TableHeader = props => {
  const classes = useStyles();
  const { columns, onSortAdminUsers } = props;
  const [sortOrder, setSortOrder] = React.useState('default'); //sorting with name as default
  const [sortBy, setSortBy] = React.useState('fullName');

  //sorting
  const handleRequestSort = property => {
    let order = sortOrder;
    if (property === sortBy) {
      if (sortOrder === 'asc') {
        order = 'desc';
      } else if (sortOrder === 'desc') {
        order = 'default';
      } else {
        order = 'asc';
      }
    } else {
      //if property is not the same as sortBy, set sortOrd to asc
      order = 'asc';
    }
    setSortOrder(order);
    setSortBy(property);
    onSortAdminUsers(order, property);
  };
  const headerRows = columns.map(column => {
    if (column.sortable) {
      return (
        <TableCell
          className={classes.tableCell}
          key={column?.columnId}
          onClick={() => handleRequestSort(column.columnId)}
        >
          {column.label}
          {/* sort caret icons for selected column */}
          {sortBy === column.columnId ? (
            sortOrder === 'desc' ? (
              <CaretsDown className={classes.carrot} /> //caret down for descending order
            ) : sortOrder === 'asc' ? (
              <CaretsUp className={classes.carrot} /> //caret up for ascending order
            ) : (
              <Carets className={classes.carrot} /> //default caret icon if no order
            )
          ) : (
            // default caret icons for other columns
            <Carets className={classes.carrot} />
          )}
        </TableCell>
      );
    }
    return (
      <TableCell
        className={classes.tableCell}
        id={column?.columnId}
        key={column?.columnId}
      >
        {column.label}
      </TableCell>
    );
  });
  return (
    <TableHead className={classes.tableHead}>
      <TableRow>{headerRows}</TableRow>
    </TableHead>
  );
};
export default TableHeader;
