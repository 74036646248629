import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { currentUser } from 'selectors/session';
import { LinearProgress } from '@mui/material';

import { profileProps } from 'components/props/profiles';

const mapStateToProps = state => ({
  currentUser: currentUser(state),
});

class AppSwitcher extends Component {
  static propTypes = {
    currentUser: profileProps.isRequired,
  };

  render() {
    const { currentUser } = this.props;
    const path = currentUser.isAdmin ? '/admin/projects' : 'projects';

    return currentUser.email ? <Redirect to={path} /> : <LinearProgress />;
  }
}

export default connect(mapStateToProps)(AppSwitcher);
