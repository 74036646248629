// eslint-disable-next-line import/no-anonymous-default-export
export default {
  MuiDataGrid: {
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        const { palette } = theme;
        return {
          '--DataGrid-containerBackground': `${palette.primary['+8']} !important`, //https://github.com/mui/mui-x/issues/12443
        };
      },
      columnHeaders: ({ ownerState, theme }) => {
        const { palette } = theme;
        return {
          backgroundColor: palette.misc.tableHeader,
        };
      },
      // row: {
      // set rowHeight with prop getRowHeight(), not css
      // },
    },
    defaultProps: {},
  },
};
