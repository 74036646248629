import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { FieldArray } from 'redux-form';
import { Box } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import * as sel from '../selectors';
import { show as showModal } from 'actions/modalDialog';

import TimecardDays from './TimecardDays';
import { Button, TabHeader, TabPaper } from '../Shared/styledComponents';

import { SETTINGS_DIALOG } from '../Modals/modalNames';
import AttachmentWarning from '../Shared/AttachmentWarning';

const mapState = state => ({
  totalHours: sel.getTotalHours(state),
});

const mapDispatch = dispatch => ({
  onShowSettings: () => dispatch(showModal({ dialog: SETTINGS_DIALOG })),
});

const TimecardTab = props => {
  const { totalHours, onShowSettings } = props;
  return (
    <TabPaper>
      <TabHeader>
        <Box sx={{ fontWeight: 'bold' }}>Timecard Total Hrs: {totalHours}</Box>
        <Button startIcon={<SettingsOutlinedIcon />} onClick={onShowSettings}>
          More Options
        </Button>
      </TabHeader>
      <AttachmentWarning />
      <FieldArray name="days" component={TimecardDays} />
    </TabPaper>
  );
};

TimecardTab.propTypes = {
  totalHours: PropTypes.number.isRequired,
  onShowSettings: PropTypes.func.isRequired,
};

export default compose(connect(mapState, mapDispatch))(TimecardTab);
