import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, IconButton } from '@mui/material';
import { FilesTypography } from './Shared';
import clsx from 'clsx';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeStyles from '@mui/styles/makeStyles';

import * as actions from 'actions/digitalEdits';
import File from './File';
import { getSubmittedFiles } from 'selectors/digitalEdits';

const useStyles = makeStyles(({ palette }) => ({
  listsBox: {
    marginTop: '8px',
    maxHeight: '100px',
    overflowY: 'auto',
    height: '100px',
    transition: 'all 0.2s ease',
  },
  readOnlyListBox: {
    height: 'inherit',
    maxHeight: 350,
  },
  boxTransitionIn: {},
  boxTransitionOut: {},
  listsBoxHide: {
    marginTop: '8px',
    overflowY: 'hidden',
    height: 0,
  },
  expandIcon: {
    color: palette.primary.primary,
    backgroundColor: palette.primary.table,
    padding: 0,
    transform: 'rotate(180deg)',
    transition: 'all 0.1s linear',
  },
  expandIconHide: {
    transform: 'rotate(0deg)',
  },
  submittedListContainer: {
    width: '100%',
    display: 'flex',
    marginLeft: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  previousListItem: {
    display: 'flex',
    padding: '4px 4px 4px 4px',
    alignItems: 'center',
    backgroundColor: 'white',
    border: `1px solid ${palette.gray['+2']}`,
    borderRadius: '2px',
  },
  emptyMsg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: palette.text.disabled,
  },
}));
const FILE_PATH = 'submitted';
const mapState = state => ({
  submittedFiles: getSubmittedFiles(state),
});
const mapDispatch = dispatch => ({
  onDownloadFile: ({ file }) => {
    dispatch(actions.downloadFile({ file, path: FILE_PATH }));
  },
});

const SubmittedFiles = props => {
  const classes = useStyles();
  const { submittedFiles, onDownloadFile, readOnly = false } = props;
  const [expand, setExpand] = useState(readOnly);

  let emptyMsg = null;

  if (submittedFiles.length === 0) {
    if (readOnly) {
      emptyMsg = <Box className={classes.emptyMsg}>No files</Box>;
    } else {
      return null;
    }
  }

  return (
    <Box sx={{ mt: '20px' }}>
      {!readOnly && (
        <Box className={classes.submittedListContainer}>
          <FilesTypography>Submitted Files</FilesTypography>
          <IconButton
            size="small"
            onClick={() => {
              setExpand(ex => !ex);
            }}
            className={clsx(classes.expandIcon, {
              [classes.expandIconHide]: !expand,
            })}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      )}
      {
        <Box
          className={clsx(classes.listsBox, {
            [classes.listsBoxHide]: !expand,
            [classes.readOnlyListBox]: readOnly,
          })}
        >
          {submittedFiles.map((file, i) => {
            return (
              <File
                key={file.documentId}
                file={file}
                index={i}
                canDownload
                onDownloadFile={onDownloadFile}
              />
            );
          })}
          {emptyMsg}
        </Box>
      }
    </Box>
  );
};

SubmittedFiles.propTypes = {
  submittedFiles: PropTypes.array.isRequired,
  onDownloadFile: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default connect(mapState, mapDispatch)(SubmittedFiles);
