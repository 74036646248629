import React from 'react';
import clsx from 'clsx';
//components
import { Input, InputAdornment, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
//utils
import { doesDayAllowTimes } from 'utils/weekUtils';
import { normalizeTimeEntry } from 'utils/bulkEditUtils';
import TimeValidator from 'utils/TimeValidator';

const TimeCell = props => {
  const {
    // column: { id: columnId, type: columnType },
    row: { original },
    disabled,
    baseOnChange,
    baseClasses,
    baseOnBlur,
    localVal,
    toggleCTA,
    error,
    hasFocus,
    id,
  } = props;

  const { isWarning, errorMsg } = error;
  const dayTypeCode = original?.dayType?.code;
  const allowsTimes = doesDayAllowTimes(dayTypeCode);

  const dealMemo = original?.dealMemo;

  const onChange = e => {
    const timeVal = normalizeTimeEntry(e.target.value, localVal);
    // currently having an issue where this returns an empty string and that triggers
    // dtsDirty since it was undefined before.  Not sure what the result should be
    if (timeVal !== e.target.value) {
      //Should we to put an info snackbar message here so its more clear why characters aren't appearing?
      // eslint-disable-next-line no-console
      console.log('INVALID TIME VALUE CORRECTED');
    }

    baseOnChange(timeVal);
  };

  // Update table data
  const onBlur = () => {
    const tv = new TimeValidator(dealMemo.roundTo);
    baseOnBlur(tv.parse(`${localVal}`));
  };

  let val = localVal ? localVal : localVal === 0 ? localVal : '';

  return (
    <Input
      id={id}
      classes={{
        root: `${baseClasses.input} ${baseClasses.numberCell}`,
        disabled: baseClasses.disabled,
        error: clsx({ [baseClasses.warningColor]: isWarning }),
        focused: baseClasses.focusColor,
      }}
      type={'text'}
      error={!!errorMsg}
      disabled={disabled || !allowsTimes}
      variant="outlined"
      value={val}
      onChange={onChange}
      onBlur={() => {
        toggleCTA(false);
        onBlur();
      }}
      inputProps={{ autoComplete: 'off' }}
      onFocus={() => toggleCTA(true)}
      endAdornment={
        !!errorMsg && !hasFocus ? (
          <InputAdornment position="end">
            <Tooltip arrow title={errorMsg}>
              <ErrorIcon
                className={clsx({
                  [baseClasses.errorIcon]: !isWarning,
                  [baseClasses.warnIcon]: isWarning,
                })}
              />
            </Tooltip>
          </InputAdornment>
        ) : (
          ''
        )
      }
    />
  );
};

export default TimeCell;
