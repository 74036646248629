import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { Field } from 'redux-form';
import { Typography, useMediaQuery, Tooltip } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

//icons
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FaAsterisk } from 'react-icons/fa';

//utils
import { formatDate } from 'utils/weekUtils';

const style = ({ palette, breakpoints }) => ({
  root: {
    border: 'none',
    display: 'block',
    fontWeight: 500,
    textTransform: 'uppercase',
    textDecoration: 'none',
    textAlign: 'center',
    position: 'relative',
    cursor: 'default',
    width: 180,
    margin: 15,
    color: palette.text.disabled,
    [breakpoints.down('md')]: {
      marginLeft: 5,
      marginRight: 5,
    },
  },
  interactive: {
    cursor: 'pointer',
  },
  active: {
    color: palette.primary.main,
  },
  weekDay: {
    margin: 'auto',
    padding: 25,
    marginTop: 5,
    backgroundColor: palette.action.disabledBackground,
    color: palette.text.disabled,
    [breakpoints.down('md')]: {
      padding: '8px 0',
    },
    [breakpoints.down('sm')]: {
      padding: '5px 0',
    },
  },
  weekDayActive: {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
  },

  dayOfWeek: {
    fontWeight: 500,
  },
  dayNumber: {
    fontWeight: 500,
    [breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
    [breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  dayMonth: {
    fontWeight: 500,
    [breakpoints.down('md')]: {
      fontSize: '.6rem',
    },
  },
  partialWarning: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

const DayButton = ({
  classes,
  day,
  index,
  active,
  blocked,
  onChangeActive,
  crewPartialDays = {},
  disabled,
}) => {
  const [date] = React.useState(moment(day.date));
  const interactive = !!onChangeActive;
  const monthFormat = useMediaQuery('(min-width:960px') ? 'MMMM' : 'MMM';
  const isActive = !blocked && active && !disabled;

  const rootClass = clsx(classes.root, {
    [classes.active]: isActive,
    // "calendar-row-day--expand": isExpand,
    [classes.interactive]: interactive,
  });
  const weekDayClass = clsx(classes.weekDay, {
    [classes.weekDayActive]: isActive,
  });

  const onButtonClick = () => {
    if (blocked === false || blocked === undefined) {
      onChangeActive(date, !isActive);
    }
  };

  const isPartial = !!crewPartialDays[formatDate(day.date)];

  const partialWarning = (
    <section className={classes.partialWarning}>
      <Tooltip
        title={
          <span style={{ fontSize: 12 }}>
            Some of the selected employees cannot work this day as per their
            deal memo. Their timecard will be created without this day.
          </span>
        }
      >
        <ErrorOutlineIcon style={{ color: 'red' }} />
      </Tooltip>
    </section>
  );

  return (
    <div className={rootClass} onClick={onButtonClick}>
      <Typography
        variant="subtitle2"
        color="inherit"
        className={classes.dayOfWeek}
      >
        {date.format('ddd')}
        {blocked && <FaAsterisk color="primary" />}
        {isPartial && partialWarning}
      </Typography>
      <div className={weekDayClass}>
        <Typography variant="h4" color="inherit" className={classes.dayNumber}>
          {date.format('D')}
        </Typography>
        <Typography
          variant="subtitle2"
          color="inherit"
          className={classes.dayMonth}
        >
          {date.format(monthFormat)}
        </Typography>
      </div>
      <Field
        component="input"
        name={`day[${index}].isActive`}
        type="hidden"
        value={isActive}
      />
    </div>
  );
};

DayButton.propTypes = {
  classes: PropTypes.object.isRequired,
  day: PropTypes.object.isRequired,
  active: PropTypes.bool,
  blocked: PropTypes.bool,
  onChangeActive: PropTypes.func,
};

export default withStyles(style)(DayButton);
