import React from 'react';
import PropTypes from 'prop-types';
import { Paper, styled, Box, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';

// utils

import TimecardActions from './TimecardActions';
import TabNav from '../Mobile/TabNav';

const useStyles = makeStyles(({ palette }) => ({}));

const TimecardBox = styled(Box)(({ theme }) => ({
  height: '100%',
  overflow: 'auto',
  padding: '0px',
  backgroundColor: theme.palette.gray['+9'],
}));

const Title = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontSize: '22px',
  fontWeight: 'bold',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const MainPaper = styled(Paper)(({ theme }) => ({
  padding: '12px',
  backgroundColor: theme.palette.gray['+9'],
  boxShadow: 'none',
}));

const mapState = state => ({});

const mapDispatch = dispatch => ({});

const MobileEmpTimecard = props => {
  const classes = useStyles();
  const { loading } = props;

  return (
    <TimecardBox className={classes.MobileEmpTimecard}>
      {loading && (
        <LinearProgress style={{ display: 'flex', alignItems: 'center' }} />
      )}
      <MainPaper>
        <Title>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ ml: '5px', fontSize: '18px' }}>Timecard</Box>
          </Box>
          <TimecardActions />
        </Title>
        <TabNav />
      </MainPaper>
    </TimecardBox>
  );
};

MobileEmpTimecard.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default compose(connect(mapState, mapDispatch))(MobileEmpTimecard);
