import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import {
  Divider,
  CardContent,
  Button,
  Typography,
  Tooltip,
} from '@mui/material';
import { FaRegCopy as CopyIcon } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { withStyleSheet } from 'shared/theme';
import { show as showModal } from 'actions/modalDialog';

import { Field } from 'redux-form'; // ES6

// Components
import {
  Card,
  CardHeaderPlain as CardHeader,
  CardFooter,
} from 'components/Shared/Card';
import { AutoComplete } from 'components/Shared/AutoComplete';
// Utils.
import { weekEndingFormat as format } from 'utils/formatDate';
import EmployeeDealMemoModal from 'components/Shared/Modals/EmployeeDealMemoModal';

const style = theme => ({
  root: {
    overflow: 'visible',
  },
  cardContent: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
  },
  content: {
    width: '25%',
    '& > *': {
      marginTop: 25,
      marginBottom: 15,
    },
  },
  gridItem: {
    width: 400,
  },
  footer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  occCode: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      padding: 15,
    },
  },
  occLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  occCodeSelText: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  occCodeBtns: {
    '& > *': {
      marginTop: 25,
      marginBottom: 15,
    },
  },
  occCodeSelButton: {
    height: 40,
  },
  [theme.breakpoints.down('lg')]: {
    content: {
      width: '95%',
      '& > *': {
        margin: 15,
      },
    },
  },
});

const modalDialog = 'EmployeeDealMemoModal';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  onShowMemoModal: () => dispatch(showModal({ dialog: modalDialog })),
});

const Create = props => {
  const {
    classes,
    dealMemos,
    weekendings,
    previousWeekOptions,
    change,
    weekendingSelected,
    occupationCodeSelected,
    submitting,
    invalid,
    onShowMemoModal,
    activeUser,
    formSyncErrors,
  } = props;
  const [enablePreviousWeeks, setEnablePreviousWeeks] = useState(false);

  const history = useHistory();

  const formNameMap = {
    weekEnding: 'Week ending',
    occupationCode: 'Occupation code',
  };

  const formInvalidMsg = (() => {
    let msg = [];

    for (const field in formSyncErrors) {
      if (Object.hasOwnProperty.call(formSyncErrors, field)) {
        const error = formSyncErrors[field];
        msg.push(<div key={field}>{`${formNameMap[field]} ${error}`}</div>);
      }
    }

    return msg.length > 0 ? msg : '';
  })();

  const occResultRef = useRef(null);

  const goBack = () => {
    history.goBack();
  };

  const updateSourceTimcardId = value => {
    const defaultValue = _.chain(previousWeekOptions || [])
      .filter(option => option.date < value)
      .sortBy(option => option.date)
      .reverse()
      .first()
      .value();
    if (defaultValue) {
      change('sourceTimecardId', defaultValue.value);
      change('sourceTimecardDealMemoId', defaultValue.dealMemoId);
    } else {
      change('sourceTimecardId', null);
    }
  };

  const togglePreviousWeeks = () => {
    setEnablePreviousWeeks(!enablePreviousWeeks);
    updateSourceTimcardId(enablePreviousWeeks ? null : weekendingSelected);
  };

  const updatePrevDealMemoId = timecardId => {
    const prevTC = previousWeekOptions.find(tc => tc.value === timecardId);
    change('sourceTimecardDealMemoId', prevTC.dealMemoId);
  };

  const weekendingOptions = weekendings.map(op => ({
    label: format(op.endsOn),
    value: format(op.endsOn),
  }));

  const dealMemoOptions = (dealMemos || []).map(dealMemo => ({
    label: dealMemo.jobDescription,
    value: dealMemo.id,
  }));

  occResultRef.current = occupationCodeSelected
    ? _.find(dealMemoOptions, dm => dm.value === occupationCodeSelected)
    : dealMemoOptions.length > 0
    ? dealMemoOptions[0]
    : undefined;

  useEffect(() => {
    if (dealMemoOptions && dealMemoOptions.length > 0) {
      const currDmInList = _.find(
        dealMemoOptions,
        dm => dm.value === occupationCodeSelected,
      );
      if (!occupationCodeSelected || !currDmInList) {
        change('occupationCode', dealMemoOptions[0].value);
        occResultRef.current = dealMemoOptions[0];
      }
    } else {
      change('occupationCode', '');
      occResultRef.current = undefined;
    }
  }, [change, dealMemoOptions, occupationCodeSelected]);

  const occCodeDisplay =
    occResultRef.current?.label ?? 'No Occupation Code Selected';

  return (
    <Card className={classes.root}>
      <CardHeader title>
        <Typography variant="body2" align="left">
          {'We just need a few important items to get your timecard started'}
        </Typography>
      </CardHeader>
      <CardContent className={classes.cardContent}>
        <div className={`${classes.content}`}>
          <AutoComplete
            list={weekendingOptions}
            name="weekEnding"
            label="Week Ending"
            placeholder="Select Week"
            error={formSyncErrors.weekEnding}
            helperText={formSyncErrors.weekEnding}
            isClearable={false}
            required
          />
          <Field //hidden field to register an empty value on redux-form
            component={'input'}
            name="occupationCode"
            type="hidden"
            style={{ height: 0 }}
          />
          <div className={classes.occCodeBtns}>
            {dealMemoOptions.length > 0 && (
              <div className={classes.occCode}>
                <section className={classes.occLabel}>
                  <Typography
                    key={'sub'}
                    variant="caption"
                    className={classes.occCodeSelText}
                  >
                    Current Occupation Code:
                  </Typography>
                  <div>{occCodeDisplay}</div>
                </section>

                <Button
                  className={classes.occCodeSelButton}
                  onClick={onShowMemoModal}
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  Select an Occupation Code
                </Button>
              </div>
            )}
            {dealMemoOptions.length === 0 && (
              <div>
                <Typography color="error">
                  No valid deal memo for the selected week ending date. Check
                  effective/expiration date on the desired deal, or select a new
                  week ending date.
                </Typography>
              </div>
            )}
            <Button
              className={classes.button}
              color="primary"
              variant={enablePreviousWeeks ? 'contained' : 'outlined'}
              disabled={previousWeekOptions.length <= 0}
              fullWidth
              onClick={togglePreviousWeeks}
            >
              <CopyIcon className={classes.leftIcon} />
              {'Copy from Previous Week'}
            </Button>
          </div>
          <AutoComplete
            list={previousWeekOptions}
            name="sourceTimecardId"
            label="Week Ending"
            disabled={!enablePreviousWeeks}
            onChange={updatePrevDealMemoId}
            placeholder="Select from Previous Week - Optional"
          />
        </div>
      </CardContent>
      <Divider key={`Card-devider`} />
      <CardFooter className={classes.footer}>
        <Button variant="outlined" onClick={goBack}>
          {'Take me back'}
        </Button>
        <Tooltip title={formInvalidMsg}>
          <span>
            <Button
              className={classes.button}
              disabled={submitting || invalid}
              color="primary"
              variant="contained"
              type="submit"
            >
              {`Looks Good, Let's enter my times`}
            </Button>
          </span>
        </Tooltip>
      </CardFooter>
      <EmployeeDealMemoModal
        change={change}
        activeUser={activeUser}
        weekendingSelected={weekendingSelected}
        occupationCodeSelected={occupationCodeSelected}
      />
    </Card>
  );
};

export default compose(
  withStyleSheet('CreateTimecard', style),
  connect(mapStateToProps, mapDispatchToProps),
)(Create);
