import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import snakeCase from 'snakecase-keys';
import camelCase from 'camelcase-keys';
import _ from 'lodash';
//actions
import * as actions from 'actions/profile';
import { hide as hideModal } from 'actions/modalDialog';
import { normalizePhone } from 'utils/weekUtils';
import { showAlert } from 'actions/alert';
//selectors
import { getSessionUser } from 'selectors/session';
import {
  getProfileValues,
  getNotificationsFormValues,
  getAllNotifications,
} from 'selectors/profileDetails';

import { getProject } from 'selectors/routeParams';
import { show as showModal } from 'actions/modalDialog';
import { startSubmit, stopSubmit, reset } from 'redux-form';

export function* updateProfileDetail(api, debug) {
  try {
    const data = yield select(getProfileValues);
    let finaldata = snakeCase(data);
    yield call(api.profileDetails.updateProfileDetails, finaldata);
  } catch (e) {
    debug(e);
  }
}

export function* fetchNotifications(api, debug) {
  try {
    yield put(actions.loadingNotifications({ loadNotifications: true }));
    const data = yield call(api.profileDetails.notifications);
    const activeUser = yield select(getSessionUser);
    const roleBasedNotifications = _.filter(data, notification =>
      _.find(notification.allowedRoles, aRoles =>
        _.find(activeUser.projectRoles, pr => pr === aRoles),
      ),
    );
    const enabled = _.filter(
      roleBasedNotifications,
      notification => notification.enabled,
    );
    const selectedAll = enabled.length === roleBasedNotifications.length;
    yield put(
      actions.storeNotifications({
        notifications: roleBasedNotifications,
        selectedAll,
      }),
    );
    yield put(actions.loadingNotifications({ loadNotifications: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeNotifications({ notifications: [] }));
    yield put(actions.loadingNotifications({ loadNotifications: false }));
  }
}

export function* fetchPhoneNumber(api, debug) {
  let phoneNumber = {};
  try {
    yield put(actions.loadingPhoneNumber({ loadPhoneNumber: true }));
    const data = yield call(api.profileDetails.phoneNumber);
    if (data.number) {
      let removeCountryCode = data.number.substring(2, 12);
      phoneNumber = {
        phoneNumber: normalizePhone(removeCountryCode),
        number: normalizePhone(removeCountryCode),
        sendVerificationCode: data.verified,
        verified: data.verified,
      };
    }
  } catch (e) {
    debug(e);
  } finally {
    yield put(actions.storePhoneNumber({ phoneNumber }));
    yield put(actions.loadingPhoneNumber({ loadPhoneNumber: false }));
  }
}

export function* updateNotifications(api, debug) {
  try {
    const formValues = yield select(getNotificationsFormValues);
    delete formValues.number;
    delete formValues.phoneNumber;
    delete formValues.selectAll;
    delete formValues.sendVerificationCode;
    delete formValues.verified;
    const allNotifications = yield select(getAllNotifications);
    const data = { ...allNotifications, ...formValues.notifications };
    yield call(api.profileDetails.updateNotifications, { notifications: data });
    yield put(actions.setUpdateStatus({ status: 'S' }));
    yield put(actions.fetchNotifications());
    yield put(reset('Notifications'));
  } catch (e) {
    debug(e);
    yield put(actions.setUpdateStatus({ status: 'F' }));
  }
}

export function* verifyPhoneNumber(api, debug) {
  try {
    const data = yield select(getNotificationsFormValues);
    const phoneNumbers = {
      number: data.phoneNumber,
      verified: data.verified,
      send_verification_code: true,
      phone_number: data.phoneNumber,
    };
    let phoneNumber = snakeCase(phoneNumbers, { deep: true });
    yield call(api.profileDetails.updatePhoneNumber, { phoneNumber });
    yield put(showModal({ dialog: 'verificationModal' }));
  } catch (e) {
    debug(e);
  }
}

export function* deletePhoneNumber(api, debug) {
  try {
    yield call(api.profileDetails.deletePhoneNumber);
    yield put(hideModal({ dialog: 'deletePhoneNumber' }));
    yield put(actions.storePhoneNumber({ phoneNumber: {} }));
  } catch (e) {
    debug(e);
  }
}

export function* resendVerificationCode(api, debug) {
  try {
    yield call(api.profileDetails.resendVerificationCode, {});
  } catch (e) {
    debug(e);
  }
}

export function* verifyCode(api, debug, params) {
  const formName = 'verificationModal';

  try {
    yield put(startSubmit(formName));
    const verificationCode = params.code;
    const response = yield call(api.profileDetails.verifyCode, {
      verification_code: verificationCode,
    });
    let removeCountryCode = response.number.substring(2, 12);
    const phoneNumber = {
      phoneNumber: normalizePhone(removeCountryCode),
      verified: response.verified,
    };
    yield put(
      showAlert({
        message: 'Success',
        variant: 'success',
      }),
    );
    yield put(stopSubmit(formName));
    yield put(hideModal({ dialog: formName }));
    yield put(actions.storePhoneNumber({ phoneNumber }));
    yield put(actions.fetchPhoneNumber());
    yield put(reset('Notifications'));
  } catch (e) {
    yield put(
      showAlert({
        message: 'Phone was not validated',
        variant: 'error',
      }),
    );
    const errors = e.data ? camelCase(e.data, { deep: true }) : null;
    yield put(stopSubmit(formName, { _error: { ...errors } }));
  }
}

export function* downloadDefaultAllowanceDocument(api, debug, params) {
  try {
    yield put(actions.downloadingSupportingDocument({ loading: true }));
    const { token, fileName } = params;

    const projectId = yield select(getProject);
    const endpoint = [
      `projects/${projectId}/allowances/${token}/downloadDocument`,
    ].join('');
    yield call(api.downloader.downloadFromURI, {
      endpoint,
      fileName,
    });
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(actions.downloadingSupportingDocument({ loading: false }));
  }
}

export default function* profileFlow({ api, debug }) {
  yield all([
    takeEvery(
      `${actions.updateProfileDetails}`,
      updateProfileDetail,
      api,
      debug,
    ),
    takeEvery(`${actions.fetchNotifications}`, fetchNotifications, api, debug),
    takeEvery(`${actions.fetchPhoneNumber}`, fetchPhoneNumber, api, debug),
    takeEvery(
      `${actions.updateNotifications}`,
      updateNotifications,
      api,
      debug,
    ),
    takeEvery(`${actions.verifyPhoneNumber}`, verifyPhoneNumber, api, debug),
    takeEvery(`${actions.deletePhoneNumber}`, deletePhoneNumber, api, debug),
    takeEvery(
      `${actions.resendVerificationCode}`,
      resendVerificationCode,
      api,
      debug,
    ),
    takeEvery(`${actions.verifyCode}`, verifyCode, api, debug),

    takeEvery(
      `${actions.downloadDefaultAllowanceDocument}`,
      downloadDefaultAllowanceDocument,
      api,
      debug,
    ),
  ]);
}
