import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { change as formChange } from 'redux-form';
import PropTypes from 'prop-types';
import { Tooltip, InputAdornment, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from '../styledComponents';

import ErrorIcon from '@mui/icons-material/Error';

import * as sel from '../../selectors';

const useStyles = makeStyles(({ palette }) => ({
  TimeField: {
    width: '100%',
    '& .MuiInputBase-root': {
      paddingRight: '6px ',
    },
    '& .MuiOutlinedInput-input': {
      color: palette.text.secondary,
      paddingRight: '6px ',
    },
  },
  errorTextField: {
    backgroundColor: palette.misc.errorBackground,
  },
  errorIcon: {
    color: palette.error.main,
    fontSize: 14,
    cursor: 'default',
  },
  disabled: {
    backgroundColor: palette.gray['+8'],
  },
}));

const mapState = state => ({
  useMilitary: sel.getUseMilitary(state),
  isMobile: sel.getIsMobile(state),
});

const mapDispatch = dispatch => ({
  dispatch,
});

const TimeField = props => {
  const classes = useStyles();
  const {
    input: { name, value },
    meta: { form, error },
    timeV,
    dispatch,
    useMilitary,
    isMobile,
    disabled,
    isTcEditable,
  } = props;

  const parseNumToDisplay = React.useCallback(
    val => {
      if (useMilitary) {
        return timeV?.parseFloatToMilitary(val);
      } else {
        return timeV?.parseFloatToAmPm(val);
      }
    },
    [useMilitary, timeV],
  );

  const change = React.useCallback(
    (field, value) => {
      dispatch(formChange(form, field, value));
    },
    [dispatch, form],
  );

  React.useEffect(() => {
    setDisplayValue(parseNumToDisplay(value));
  }, [parseNumToDisplay, useMilitary, value]);

  const [displayValue, setDisplayValue] = React.useState(
    parseNumToDisplay(value),
  );

  const onChange = React.useCallback(e => {
    setDisplayValue(e.target.value);
  }, []);

  const onBlur = React.useCallback(
    e => {
      let newVal = timeV.parse(e.target.value);
      if (!newVal && newVal !== 0) newVal = null;
      setDisplayValue(parseNumToDisplay(newVal));
      change(name, newVal);
    },
    [change, name, parseNumToDisplay, timeV],
  );

  if (!isTcEditable) {
    return <Box>{displayValue}</Box>;
  }

  let endAdornment = null;

  if (error) {
    endAdornment = (
      <InputAdornment sx={{ ml: 0 }} position="end">
        <Tooltip arrow title={error}>
          <ErrorIcon className={classes.errorIcon} />
        </Tooltip>
      </InputAdornment>
    );
  }

  return (
    <TextField
      id={name}
      className={clsx(classes.TimeField, {
        [classes.disabled]: disabled,
      })}
      value={displayValue || ''}
      onChange={onChange}
      onBlur={onBlur}
      error={!!error}
      disabled={disabled}
      helperText={isMobile ? error : ''}
      InputProps={{
        endAdornment,
        className: clsx({ [classes.errorTextField]: error }),
      }}
      onFocus={e => e.target.select()}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.target.blur();
        }
        if (e.key === 'Escape') {
          setDisplayValue('');
          change(name, '');
        }
      }}
    />
  );
};

TimeField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  timeV: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  useMilitary: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  isTcEditable: PropTypes.bool.isRequired,
};

export default compose(connect(mapState, mapDispatch))(TimeField);
