import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Tooltip, styled } from '@mui/material';

import SplitButton from './SplitButton';

import {
  calcAllowParent,
  displayCurrencyFixed,
} from 'feature/DigitalEdits/digitalEditsUtils';

import CancelIcon from '@mui/icons-material/Cancel';

const TooltipRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const AceCell = props => {
  const {
    api,
    api: { editable },
    id,
    value,
    formattedValue,
    field,
    row: {
      isAllowance,
      parentRowId,
      originalAllowanceAmount,
      subRowIds,
      isHtgSplit,
    },
  } = props;

  let showSplitIcon = false;
  let showSplitBuffer = false;
  if (editable && field === 'amount') {
    if (isAllowance && !parentRowId) {
      showSplitIcon = true;
    } else if (parentRowId) {
      showSplitBuffer = true;
    }
  }
  let displayVal =
    formattedValue || formattedValue === '' ? formattedValue : value;

  const currentErrors = api.unsavedChangesRef?.current?.errors || {};

  if (field === 'baseRate' && editable) {
    if (parentRowId) {
      //isChild row
      displayVal = (
        <Button
          sx={{
            color: 'error.main',
            p: 0,
            fontWeight: 'bold',
            '&.MuiButtonBase-root:hover': {
              bgcolor: 'transparent',
              border: 'none',
            },
          }}
          variant="text"
          onClick={() => api.deleteAllowance({ api, id })}
        >
          <CancelIcon
            sx={{ mr: '3px', height: '20px', width: '20px' }}
            color="error.main"
          />
          Delete
        </Button>
      );
    } else if (
      originalAllowanceAmount &&
      subRowIds?.length > 0 &&
      currentErrors[id] &&
      currentErrors[id][field]
    ) {
      const originalAmount = Number(originalAllowanceAmount).toLocaleString(
        'en-US',
        {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      );
      const unsavedChangesRef = api.unsavedChangesRef;
      const result = calcAllowParent({ unsavedChangesRef, id });
      const { balanced, diff, total } = result;
      if (balanced) {
        displayVal = '';
      } else {
        const addRemove = diff < 0 ? 'Add' : 'Remove';
        const title = (
          <Box>
            <Box>Allowance group must sum to original amount</Box>
            <TooltipRow>
              <span>Original Amount:</span>{' '}
              {displayCurrencyFixed(originalAllowanceAmount)}
            </TooltipRow>
            <TooltipRow>
              <span>Current Total:</span> {displayCurrencyFixed(total)}
            </TooltipRow>
            <TooltipRow>
              <Box sx={{ display: 'flex', justifyContent: 'center', m: 1 }}>
                {`${addRemove}`} {displayCurrencyFixed(Math.abs(diff))} to
                balance allowances
              </Box>
            </TooltipRow>
          </Box>
        );
        displayVal = (
          <Tooltip title={title} placement={'top'}>
            <Box sx={{ fontWeight: 800 }}>{originalAmount}</Box>
          </Tooltip>
        );
      }
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {displayVal}
      {showSplitIcon && <SplitButton api={api} id={id} disabled={isHtgSplit} />}
      {showSplitBuffer && <Box sx={{ width: '20px' }}></Box>}
    </Box>
  );
};

AceCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  formattedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  field: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  api: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default AceCell;
