import React, { useEffect, useState } from 'react';
import { compose } from 'utils/helperFunctions';
import pluralize from 'pluralize';

import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, LinearProgress } from '@mui/material';
import { withStyleSheet } from 'shared/theme';

// components
import { ModalFooter, ModalContent } from 'components/Shared/ModalDialog';
import { getDealMemoFields } from 'components/Shared/constants/DMTableFields';
import SelectTable from 'components/Shared/SelectTable/SelectTable';

// decorators
import withModalDialog from 'decorators/withModalDialog';

// actions
import { hide as hideModal } from 'actions/modalDialog';
import { fetchDealMemos } from 'actions/wtc';
import { removeDealMemoId, removeDealMemoCount } from '../actions';

// utilities
import { getCrewMemberId, getCrewMemberDMCount } from '../selectors';
import { getDealMemos, loadingChecks } from 'selectors/wtc';
import { currentUser } from 'selectors/session';
import { getSettings } from 'selectors/settings';

const style = ({ palette }) => ({
  content: {
    display: 'flex',
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    marginLeft: 24,
  },
  dealMemoAlert: {
    width: 20,
    height: 20,
    background: palette.warning.main,
    margin: 15,
    borderRadius: 10,
    color: palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    fontWeight: 'bold',
  },
  invalidNotice: {
    position: 'absolute',
    bottom: 40,
    left: 24,
    fontSize: 14,
    color: palette.gray.main,
  },
});

const modalDialog = 'DealMemoDetails';
const mapState = state => ({
  crewMemberId: getCrewMemberId(state),
  dealMemoCount: getCrewMemberDMCount(state),
  dealMemos: getDealMemos(state),
  loadings: loadingChecks(state),
  activeUser: currentUser(state),
  settings: getSettings(state),
});

const mapDispatch = dispatch => ({
  onModalHide: () => {
    dispatch(hideModal({ dialog: modalDialog }));
    dispatch(removeDealMemoId());
    dispatch(removeDealMemoCount());
  },
  onFetchDealMemos: (employeeId, startDate, endDate) => {
    dispatch(
      fetchDealMemos({
        employeeId,
        startDate,
        endDate,
        initialLoad: true,
        withPayroll: true,
      }),
    );
  },
});

const DealMemos = ({
  classes,
  dealMemos,
  onFetchDealMemos,
  crewMemberId,
  loadings,
  activeUser,
  settings,
  dealMemoCount,
  onModalHide,
}) => {
  const list = _.sortBy(dealMemos, dm => parseInt(dm.code, 10));
  const [filteredFields, setFilteredFields] = useState(
    getDealMemoFields('crew'),
  );
  const [inactiveDM, setInactiveDM] = useState(false);
  const [inactiveDMCount, setInactiveDMCount] = useState(
    dealMemoCount - dealMemos.length,
  );

  useEffect(() => {
    if (activeUser.role === 'department_head' && !settings.dhGrossEnabled) {
      setFilteredFields(
        getDealMemoFields('crew').filter(column => {
          if (column.label === 'Studio Hourly Rate') {
            return false;
          }
          if (column.label === 'Distant Hourly Rate') {
            return false;
          }
          return true;
        }),
      );
    }
  }, [settings, activeUser]);

  useEffect(() => {
    if (!_.isEmpty(crewMemberId)) onFetchDealMemos(crewMemberId);
  }, [onFetchDealMemos, crewMemberId]);
  useEffect(() => {
    setInactiveDMCount(dealMemoCount - dealMemos.length);
    setInactiveDM(dealMemoCount - dealMemos.length >= 1);
    return () => {
      setInactiveDMCount(0);
      setInactiveDM(false);
    };
  }, [dealMemos, dealMemoCount]);

  return (
    <div>
      <div className={classes.content}>
        <h2 className={classes.title}> Deal Memo Details</h2>
        {inactiveDM && !loadings && (
          <div className={classes.dealMemoAlert}>!</div>
        )}
      </div>
      <ModalContent>
        {loadings ? (
          <>
            <LinearProgress />
            <h3>Loading...</h3>
          </>
        ) : (
          <SelectTable list={list} fields={filteredFields} readOnly={true} />
        )}
      </ModalContent>
      {inactiveDM && !loadings && (
        <div className={classes.invalidNotice}>
          In addition, {inactiveDMCount} more invalid{' '}
          {pluralize('deal', inactiveDMCount, false)} exist for this user
        </div>
      )}
      <ModalFooter>
        <Button
          variant="contained"
          color="primary"
          name="cancel"
          onClick={onModalHide}
        >
          {'Close'}
        </Button>
      </ModalFooter>
    </div>
  );
};

export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: modalDialog, maxWidth: 'lg' }),
  connect(mapState, mapDispatch),
)(DealMemos);
