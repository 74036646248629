import React from 'react';
import Projects from 'containers/Employees/Projects';
import { FullWidthLayout, Header, AlertHeader } from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';
import { Projects as ProjectsNav } from 'containers/Nav/Pages';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    authenticated
    key="/projects"
    path="/projects"
    layout={FullWidthLayout}
    headers={[Header, ProjectsNav, AlertHeader]}
    component={Projects}
    title="Projects"
  />
);
