import React from 'react';
import { Field } from 'redux-form';
import makeStyles from '@mui/styles/makeStyles';

import { formatDate } from '../../../utils';
import TextField from '../../../components/Shared/redux/TextField';
import { IA } from 'components/props/profiles';

import { AutoComplete } from 'components/Shared/AutoComplete';
export const useStyles = makeStyles(theme => ({
  root: {
    width: '75%',
    marginBottom: 0,
    color: `${theme.palette.gray['-5']} !important`,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '15px',
    '& > *': {
      width: '100%',
    },
  },
  field: {
    marginTop: 8,
    marginBottom: 6,
    marginLeft: 4,
    marginRight: 4,
  },
  inviteText: {
    marginLeft: '5px',
    color: theme.palette.gray['-5'],
  },
}));
const UserEditFields = props => {
  const classes = useStyles();
  const { user = {}, departments } = props;

  let invitedText = '';
  const invitedOn =
    user.invitedOn && formatDate(user.invitedOn, 'MM-DD-YYYY, h:mm A');

  const isUserDeleted = !!user.deletedAt;
  const isStudioPlusOrigin = user.origin === 'studioplus';
  const isInvoiceApprover = user.roles.includes(IA);

  const isNonHtgUser = user.worksightId === null && user.status === 'accepted';

  if (invitedOn) invitedText = `Invite sent: ${invitedOn}`;

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <AutoComplete
          fieldClass={classes.departmentSelect}
          name={`departmentId`}
          label="Department"
          list={departments}
          fieldLabel={'name'}
          fieldValue={'id'}
          disabled={isUserDeleted || isNonHtgUser}
        />
      </div>
      <div className={classes.row}>
        <Field
          className={classes.field}
          component={TextField}
          disabled={isUserDeleted || isStudioPlusOrigin || isInvoiceApprover}
          name="email"
          label="Email"
        />
      </div>
      <div className={classes.row}>
        <div className={classes.inviteText}>{invitedText}</div>
      </div>
    </div>
  );
};
export default UserEditFields;
