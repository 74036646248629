import _ from 'lodash';
import { createSelector } from 'reselect';
import { getFormValues } from 'selectors/formValues';
import { getDepartments } from 'selectors/project';

export const getUsers = state => _.get(state, 'crewList.users', []);

// /** Util dev function for creating a manageable size user list
//  *  rename above getUsers to getUsersUTIL
//  */
// export const getUsers = createSelector([getUsersUTIL], users => {
//   const htbUsers = users.filter(user => user.worksightId).slice(0, 6);
//   const nonHtbUsers = users
//     .filter(user => user.worksightId === null)
//     .slice(0, 6);

//   const newUsers = [];

//   for (let i = 0; i < 6; i++) {
//     if (htbUsers[i]) newUsers.push(htbUsers[i]);
//     if (nonHtbUsers[i]) newUsers.push(nonHtbUsers[i]);
//   }

//   return newUsers;
// });

export const getLoading = state => _.get(state, 'crewList.loading', false);
export const getEditHostCostFlag = state =>
  _.get(state, 'crewList.editHostCostFlag', false);
export const getUserId = state => _.get(state, 'crewList.editing.id', null);
export const getEditingUser = state => _.get(state, 'crewList.editing', {});
export const getEditingUsers = state =>
  _.get(state, 'crewList.editingUsers', []);
export const getStatuses = state => _.get(state, 'crewList.filters.status', []);
export const getCrewMemberId = state =>
  _.get(state, 'crewList.dealMemoId', null);
export const getCrewMemberDMCount = state =>
  _.get(state, 'crewList.dealMemoCount', 0);

export const getUser = createSelector(
  [getUsers, getUserId],
  (users, userId) => {
    const user = _.find(users, user => user.id === userId);

    return user || {};
  },
);

export const getUserValues = getFormValues('UserEdit');
export const getSelectedUserEdit = state =>
  _.get(state, 'crewList.editUser', {});

export const getEditUserValues = createSelector([getUserValues], values => {
  let filteredValues = [];
  filteredValues.push(values);
  return filteredValues.map(user => {
    const { firstName, lastName, email, departmentId, worksightId, id } = user;
    return {
      firstName,
      lastName,
      email,
      id,
      departmentId: departmentId ? departmentId : 0,
      ...(!!worksightId && { worksightId: worksightId }),
    };
  });
});

export const getInviteValues = createSelector(
  [getFormValues('NonCrewInvite')],
  values => {
    let filteredValues = (values.invites || []).filter(invite => {
      return !_.isEmpty(invite);
    });
    return filteredValues.map(invite => {
      const { firstName, lastName, email, departmentId, worksightId, id } =
        invite;
      return {
        firstName,
        lastName,
        email,
        id,
        departmentId: departmentId ? departmentId : 0,
        ...(!!worksightId && { worksightId: worksightId }),
      };
    });
  },
);
export const getHotCostValues = getFormValues('HotCosts');

export const getSort = state => _.get(state, 'crewList.sort', {});

export const getLastProject = state =>
  _.get(state, 'crewList.lastProject', null);

export const getSortedDepartments = createSelector(
  [getDepartments],
  departments => {
    let list = _.sortBy(departments, [
      d => d && d.name && d.name.toLowerCase(),
    ]);
    if (list.find(opt => opt.id === 0) === undefined) {
      list.push({ id: 0 });
    }
    return list;
  },
);
export const getHiddenColumns = state =>
  _.get(state, 'crewList.hiddenColumns', []);
export const getEditedFields = state =>
  _.get(state, 'crewList.editedHotCostFields', []);
