import {
  all,
  takeEvery,
  call,
  put,
  select,
  takeLatest,
  race,
  take,
  delay,
} from 'redux-saga/effects';
import _ from 'lodash';
import { push } from 'redux-first-history';
import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
//components
import { DATE_FORMAT } from 'components/Shared/constants';
//actions
import { showAlert } from 'actions/alert';
import * as actions from 'actions/reports';
import { hide as hideModal, show as showModal } from 'actions/modalDialog';
import {
  setEffectiveDate,
  storeFilterOptions,
  resetFilters,
} from 'actions/dts';
import camelCase from 'camelcase-keys';

// selectors
import { getProject as project } from 'selectors/routeParams';
import { getCurrentProject } from 'selectors/project';
import {
  getIncorrectEmailProjects,
  getCorrectEmailProjects,
  getSelectedUser,
} from 'selectors/reports';

function* runReport(api, debug, params) {
  const projectId = yield select(project);
  const { report } = params;
  try {
    yield put(actions.loading({ loading: true }));
    if (report) {
      let format = DATE_FORMAT,
        dates = [];

      if (!!report.from) {
        dates.push(`from=${moment(report.from).format(format)}`);
      }

      if (!!report.to) {
        dates.push(`to=${moment(report.to).format(format)}`);
      }

      dates = dates.length > 0 ? '?' + dates.join('&') : '';
      const endpoint = `/projects/${projectId}/reports/${report.key}${dates}`;
      const filename = `report_${report.key.replace(
        '-',
        '_',
      )}_${Date.now()}.csv`;

      yield call(api.downloader.downloadFile, {
        endpoint,
        filename,
        type: undefined,
      });
      yield put(actions.loading({ loading: false }));
    }
  } catch (e) {
    yield put(actions.loading({ loading: false }));
    debug(e);
    yield put(showAlert({ variant: e.variant, message: e.message }));
  }
}

function* runHotHostReport(api, debug, params) {
  try {
    const projectId = yield select(project);
    const { report } = params;
    const { isSplitsIncluded = false } = report;
    yield put(actions.loading({ loading: true }));

    if (report) {
      const project = yield select(getCurrentProject);
      const dbCode = project.dbCode;
      const date = report.from && moment(report.from).format(DATE_FORMAT);
      const filename = `${date}_HotCostReport.xlsx`;
      const endpoint = `reports/projects/${projectId}/dbCode/${dbCode}/hotcostreport/${date}`;
      const reportEndPoint = `reports/projects/${projectId}/dbCode/${dbCode}/hotcostreport/${date}/includeSplit/${isSplitsIncluded}`;
      // call another api to check if any employees has missing fields
      // MRF - missing required fields
      const MRFEmployeeList = yield call(
        api.downloader.checkTcWithMissingFields,
        {
          endpoint,
        },
      );

      if (MRFEmployeeList?.length > 0) {
        yield call(showMRFModalAlert, api, {
          MRFEmployeeList,
          filename,
          endpoint: reportEndPoint,
          projectId,
          date: moment(report.from),
        });
      } else {
        yield call(api.downloader.downloadFileExcel, {
          endpoint: reportEndPoint,
          filename,
          type: 'HotCostReport',
        });
      }
    }

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    yield put(showAlert({ variant: e.variant, message: e.message }));
    yield put(hideModal({ dialog: 'MissingRequiredFields' }));
    yield put(actions.loading({ loading: false }));
  }
}

function* showMRFModalAlert(api, params) {
  const { MRFEmployeeList, filename, endpoint, projectId, date } = params;
  yield put(actions.loading({ loading: false }));
  yield put(
    showModal({
      dialog: 'MissingRequiredFields',
      modalParams: { MRFCount: MRFEmployeeList.length },
    }),
  );

  const userActions = yield race({
    [actions.onMRFCancel]: take(actions.onMRFCancel),
    [actions.onMRFRunDownload]: take(actions.onMRFRunDownload),
    [actions.onMRFToDts]: take(actions.onMRFToDts),
  });

  const type = Object.keys(userActions)[0];

  yield put(actions.loading({ loading: true }));
  switch (type) {
    case `${actions.onMRFCancel}`:
      break;
    case `${actions.onMRFRunDownload}`:
      yield call(api.downloader.downloadFileExcel, {
        endpoint,
        filename,
        type: 'HotCostReport',
      });
      break;
    case `${actions.onMRFToDts}`:
      // set filter then react-router push to DTS
      const data = MRFEmployeeList.map(id => ({ id }));

      yield put(setEffectiveDate({ date }));
      yield put(resetFilters());
      yield put(
        storeFilterOptions({ filterName: 'employee', data, preSelect: true }),
      );
      delay(500);

      yield put(push(`/projects/${projectId}/daily-timesheets`));
      break;
    default:
      break;
  }

  yield put(hideModal({ dialog: 'MissingRequiredFields' }));
}

function* fetchScheduledReports(api, debug) {
  try {
    const projectId = yield select(project);
    const data = yield call(api.reports.scheduledReports, {
      projectId,
    });
    yield put(actions.storeScheduledReports({ scheduledReports: data }));
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeScheduledReports({ scheduledReports: [] }));
    yield put(showAlert());
  }
}

function* fetchUserAccessReportDetails(api, debug, params) {
  try {
    const type = params?.clientCode?.type;
    const clientCode = params?.clientCode?.useraccessClientId;
    const data = yield call(api.reports.getUserAccessReportDetails, {
      clientCode,
    });
    if (type === 'templeteReport') {
      yield put(
        actions.storeClientDetailsById({
          clientDetails: data,
        }),
      );
    }
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeClientDetailsById({ clientDetails: [] }));
    yield put(showAlert());
  }
}

function* saveScheduleReport(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const reportId = params.report.id;
    if (!!reportId) {
      yield call(api.reports.updateReport, {
        projectId,
        reportId,
        report: params.report,
      });
    } else {
      yield call(api.reports.addReport, { projectId, report: params.report });
    }
    yield put(actions.fetchScheduledReports());
    yield put(actions.loading({ loading: false }));
    yield put(hideModal({ dialog: 'editScheduledReport' }));
  } catch (e) {
    debug(e);
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

function* removeScheduledReport(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const reportId = params.reportId;
    yield call(api.reports.deleteScheduledReport, { projectId, reportId });
    yield put(actions.fetchScheduledReports());
    yield put(actions.loading({ loading: false }));
    yield put(hideModal({ dialog: 'removeScheduledReport' }));
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

function* runUserAccessReport(api, debug, params) {
  // const projectId = yield select(project);
  const { report } = params;
  const {
    dbValue,
    clientValue,
    producerValue,
    productValue,
    projectValue,
    dbName,
    clientName,
    producerName,
    prodcoName,
    projectName,
    dbRegion,
  } = report;
  try {
    yield put(actions.loading({ loading: true }));
    if (report) {
      let data =
        'dbCode=' +
        dbValue +
        '&clientCode=' +
        (clientValue || 'All') +
        '&region=' +
        dbRegion +
        '&producerId=' +
        producerValue +
        '&productCompanyId=' +
        productValue +
        '&projectId=' +
        projectValue;

      let name = '';
      let regionName = '';
      if (dbRegion?.toUpperCase() === 'CANADA') {
        regionName = dbValue.includes('PAY') ? dbValue.replace('PAY', '') : '';
      }
      if (projectValue && projectValue !== '0') {
        name = projectName;
      } else if (productValue && productValue !== '0' && name === '') {
        name = prodcoName;
      } else if (producerValue && producerValue !== '0' && name === '') {
        name = producerName;
      } else if (
        clientValue &&
        clientValue !== '0' &&
        clientValue !== 'All' &&
        name === ''
      ) {
        name = clientName;
      } else if (regionName) {
        name = name + '_' + regionName;
      } else if (dbValue && dbValue !== '0' && name === '') {
        name = dbName;
      }
      const endpoint = `reports/usersAccessReport?` + data;
      const filename = `Hours_${name}.xlsx`;
      const result = yield call(api.downloader.downloadFileExcel, {
        endpoint,
        filename,
        type: 'UserAccessReport',
      });
      if (result?.status === 200) {
        yield put(
          showAlert({
            message: 'File downloaded successfully',
            variant: 'success',
          }),
        );
      }
      yield put(actions.loading({ loading: false }));
    }
  } catch (e) {
    yield put(actions.loading({ loading: false }));
    debug(e);
    yield put(showAlert());
  }
}

function* runRejectedTimecardCommentsReport(api, debug, params) {
  // const projectId = yield select(project);
  const { report } = params;
  try {
    yield put(actions.rejectedLoading({ rejectedLoading: true }));
    if (report) {
      let data =
        'fromDate=' +
        params.report.fromDate +
        '&toDate=' +
        params.report.toDate;
      const endpoint = `reports/RejectedTimecardCommentsReport?` + data;
      const filename = `RejectedTimecardComments_${Date.now()}.xlsx`;
      const result = yield call(api.downloader.downloadFileExcel, {
        endpoint,
        filename,
        type: 'RejectionTCComments',
      });

      if (result?.status === 200) {
        yield put(
          showAlert({
            message: 'File downloaded successfully',
            variant: 'success',
          }),
        );
      }
      yield put(actions.rejectedLoading({ rejectedLoading: false }));
    }
  } catch (e) {
    yield put(actions.rejectedLoading({ rejectedLoading: false }));
    debug(e);
    yield put(showAlert());
  }
}

function* runTemplateReport(api, debug, params) {
  // const projectId = yield select(project);
  const { report } = params;
  try {
    yield put(actions.loading({ loading: true }));
    if (report) {
      let id = '';
      if (!!params.report.ClientId) {
        id = params.report.ClientId;
      }

      let data =
        'clientCode=' +
        id +
        '&producerId=' +
        params.report.producerId +
        '&productCompanyId=' +
        params.report.productCompanyId +
        '&projectId=' +
        params.report.projectId +
        '&createdDate=' +
        params.report.createdDate;
      const endpoint = `reports/templateReport?` + data;
      const filename = `TemplateReport_${Date.now()}.xlsx`;
      const result = yield call(api.downloader.downloadFileExcel, {
        endpoint,
        filename,
        type: 'TemplateReport',
      });

      if (result?.status === 200) {
        yield put(
          showAlert({
            message: 'File downloaded successfully',
            variant: 'success',
          }),
        );
      }
      yield put(actions.loading({ loading: false }));
    }
  } catch (e) {
    yield put(actions.loading({ loading: false }));
    debug(e);
    yield put(showAlert());
  }
}

function* loadAllClients(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const type = 'client';
    const result = yield call(api.timecards.searchByTypes, {
      type,
      params: {
        page: 1,
        pageSize: 1000,
      },
    });
    yield put(actions.storeAllCounts({ allClients: result }));
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    yield put(actions.loading({ loading: false }));
    debug(e);
    yield put(showAlert());
  }
}

function* fetchUsersAllProjects(api, debug, params) {
  try {
    const { email, emailType } = params;
    yield put(actions.loading({ loading: true }));
    if (email !== '') {
      const data = yield call(api.reports.getUsersOfAllProjects, { email });
      const projects = camelCase(data, { deep: true });
      if (emailType === 'correct') {
        yield put(actions.storeCorrectEmailProjects({ projects }));
      } else {
        yield put(actions.storeIncorrectEmailProjects({ projects }));
      }
    } else {
      yield put(actions.storeCorrectEmailProjects({ projects: [] }));
      yield put(actions.storeIncorrectEmailProjects({ projects: [] }));
    }
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeCorrectEmailProjects({ projects: [] }));
    yield put(actions.storeIncorrectEmailProjects({ projects: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

function* mergeEmailProjects(api, debug, params) {
  try {
    const { correctEmail, incorrectEmail } = params;
    const incorrect = yield select(getIncorrectEmailProjects);
    const correct = yield select(getCorrectEmailProjects);
    const request = {
      incorrect,
      correct,
    };
    yield put(actions.loading({ loading: true }));
    const data = yield call(api.projects.mergeEmailProjects, { request });
    if (data?.success) {
      yield put(showAlert({ message: data.success, variant: 'success' }));
      const incorrectData = yield call(api.reports.getUsersOfAllProjects, {
        email: incorrectEmail,
      });
      const incorrectProjects = camelCase(incorrectData, { deep: true });
      yield put(
        actions.storeIncorrectEmailProjects({ projects: incorrectProjects }),
      );
      const correctData = yield call(api.reports.getUsersOfAllProjects, {
        email: correctEmail,
      });
      const correctProjects = camelCase(correctData, { deep: true });
      yield put(
        actions.storeCorrectEmailProjects({ projects: correctProjects }),
      );
    } else {
      yield put(showAlert({ message: data.warning, variant: 'error' }));
    }
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

function* fetchUserAccessDbDetails(api, debug, params) {
  try {
    const dbCode = params.dbCode.useraccessDBId;
    const data = yield call(api.reports.getUserAccessDbDetails, {
      dbCode,
    });
    yield put(
      actions.storeUserAccessReportDetails({ userAccessReportDetails: data }),
    );
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(
      actions.storeUserAccessReportDetails({ userAccessReportDetails: [] }),
      actions.storeClientDetailsById({ clientDetails: [] }),
    );
    yield put(showAlert());
  }
}

function* fetchUserAccessClientDetails(api, debug, params) {
  try {
    const type = params.clientCode.type;
    const dbCode = params.clientCode.useraccessDBId;
    const code = params.clientCode.useraccessClientId;
    const data = yield call(api.reports.getClientReports, {
      type,
      code,
      dbCode,
    });
    yield put(
      actions.storeUserAccessClientDetails({
        userAccessClientDetails: data,
      }),
    );

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(
      actions.storeUserAccessClientDetails({
        userAccessClientDetails: [],
      }),
    );
    yield put(showAlert());
  }
}
function* loadAllDBs(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const regions = yield call(api.reports.searchByTypes, {
      type: 'region',
      params: {
        page: 1,
        pageSize: 100,
      },
    });
    let dbCodes = [];
    for (const region of regions) {
      let data = yield call(api.reports.searchByTypes, {
        type: 'database',
        params: { page: 1, pageSize: 100, parentValue: region.id },
      });
      dbCodes.push(...data.map(item => ({ ...item, region: region.code })));
    }

    yield put(actions.storeAllDBs({ allDBs: dbCodes }));
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    yield put(actions.loading({ loading: false }));
    debug(e);
    yield put(showAlert());
  }
}
function* fetchAdminUsers(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const data = yield call(api.reports.getAdminUsers);
    yield put(actions.storeAdminUsers({ adminUsers: data }));
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    yield put(actions.loading({ loading: false }));
    debug(e);
    yield put(showAlert());
  }
}

function* saveAdminUser(api, debug, params) {
  const formName = 'AddAdminUser';
  try {
    yield put(actions.loading({ loading: true }));
    yield put(startSubmit(formName));
    const { user } = params;
    const { roles } = user;
    let payload = _.cloneDeep(user);
    const updatedRoles = [];
    if (roles.includes('Admin')) {
      updatedRoles.push('isAdmin');
    }
    if (roles.includes('Super Admin')) {
      updatedRoles.push('isSuperAdmin');
    }
    if (roles.includes('Access Admin')) {
      updatedRoles.push('isAccessAdmin');
    }
    if (roles.includes('None')) {
      updatedRoles.push('');
    }
    delete payload?.roles;
    payload = { ...payload, roles: updatedRoles };
    const existingUser = yield select(getSelectedUser);
    const isExistingUser = !!existingUser.email;
    if (isExistingUser) {
      yield call(api.reports.updateAdminUser, { payload });
    } else {
      delete payload?.firstName;
      delete payload?.lastName;
      yield call(api.reports.saveAdminUser, { payload });
    }
    yield put(stopSubmit(formName));
    yield put(actions.fetchAdminUsers());
    yield put(hideModal({ dialog: 'AddAdminUser' }));
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(stopSubmit(formName));
    const message = e?.data?.Message;
    yield put(actions.loading({ loading: false }));
    yield put(showAlert({ message, variant: 'error' }));
  }
}

function* runAdminUsersReport(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const filename = `AdminAccessReport_${moment(Date.now()).format(
      'yyyyMMDDHHmmss',
    )}.xlsx`;
    const endpoint = `access-admin/report`;
    yield call(api.downloader.downloadFileExcel, {
      endpoint,
      filename,
      type: 'AdminReport',
    });
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    yield put(showAlert());
    yield put(actions.loading({ loading: false }));
  }
}

export default function* reportsFlow({ api, debug }) {
  yield all([
    takeEvery(`${actions.runReport}`, runReport, api, debug),
    takeEvery(`${actions.runHotHostReport}`, runHotHostReport, api, debug),
    takeEvery(
      `${actions.fetchScheduledReports}`,
      fetchScheduledReports,
      api,
      debug,
    ),
    takeEvery(`${actions.saveScheduleReport}`, saveScheduleReport, api, debug),
    takeEvery(
      `${actions.removeScheduledReport}`,
      removeScheduledReport,
      api,
      debug,
    ),
    takeEvery(
      `${actions.runUserAccessReport}`,
      runUserAccessReport,
      api,
      debug,
    ),
    takeLatest(
      `${actions.fetchUserAccessReportDetails}`,
      fetchUserAccessReportDetails,
      api,
      debug,
    ),
    takeLatest(
      `${actions.runRejectedTimecardCommentsReport}`,
      runRejectedTimecardCommentsReport,
      api,
      debug,
    ),
    takeLatest(`${actions.runTemplateReport}`, runTemplateReport, api, debug),
    takeLatest(`${actions.loadAllClients}`, loadAllClients, api, debug),
    takeEvery(
      `${actions.fetchUsersAllProjects}`,
      fetchUsersAllProjects,
      api,
      debug,
    ),
    takeEvery(`${actions.mergeEmailProjects}`, mergeEmailProjects, api, debug),
    takeLatest(
      `${actions.fetchUserAccessDbDetails}`,
      fetchUserAccessDbDetails,
      api,
      debug,
    ),
    takeLatest(
      `${actions.fetchUserAccessClientDetails}`,
      fetchUserAccessClientDetails,
      api,
      debug,
    ),
    takeLatest(`${actions.loadAllDBs}`, loadAllDBs, api, debug),
    takeLatest(`${actions.fetchAdminUsers}`, fetchAdminUsers, api, debug),
    takeLatest(`${actions.saveAdminUser}`, saveAdminUser, api, debug),
    takeLatest(
      `${actions.runAdminUsersReport}`,
      runAdminUsersReport,
      api,
      debug,
    ),
  ]);
}
