// Planning to use it with both admin as well as normal user.
import createAction from './createAction';

export const loading = createAction('loading/timecards');
export const fetch = createAction('fetch/timecards');
export const store = createAction('store/timecards');

export const fetchDetailsOnly = createAction('fetch/timecards/detailsOnly');
export const storeCurrentDetails = createAction(
  'store/timecards/currentDetails',
);
export const addRecentComment = createAction(
  'store/timecards/addRecentComment',
);

export const fetchDetails = createAction('fetch/timecards/details');
export const storeDetails = createAction('store/timecards/details');
export const loadingDetails = createAction('loading/timecards/details');
export const loadingInitialize = createAction('loading/timecards/initialize');

export const fetchWeekendings = createAction('fetch/weekendings');
export const storeWeekendings = createAction('store/weekendings');

export const fetchWeekendingStats = createAction('fetch/weekendingStats');
export const storeWeekendingStats = createAction('store/weekendingStats');

export const fetchWeekendingTimecards = createAction(
  'fetch/weekendingTimecards',
);
export const storeWeekendingTimecards = createAction(
  'store/weekendingTimecards',
);
export const storeTeamTimecardUser = createAction('store/teamTimecard/user');
export const updateTeamTimecardUser = createAction(
  'update/store/teamTimecard/user',
);
export const createTeamTimecard = createAction('create/teamTimecard');
export const fetchTeamWorkLocations = createAction('fetch/teamWorkLocations');
export const fetchTeamDayTypes = createAction('fetch/fetchTeamDayTypes');

export const setFlagCopyPrevious = createAction('create/CopyPrevious');
export const createTimecard = createAction('create/timecard');

export const fetchDayTypes = createAction('fetch/dayTypes');
export const storeDayTypes = createAction('store/dayTypes');

export const fetchEpisodes = createAction('fetch/episodes');
export const storeEpisodes = createAction('store/episodes');

export const fetchWorkLocations = createAction('fetch/workLocations');
export const storeWorkLocations = createAction('store/workLocations');

export const fetchTimeCardComments = createAction('fetch/timecard/comments');
export const fetchTimecardHistory = createAction('fetch/timecard/history');
export const saveTimeCardNotes = createAction('save/timecard/notes');
export const storeTimecardNotes = createAction('store/timecard/notes');
export const storeTimecardHistory = createAction('store/timecard/history');
export const processingNotes = createAction('timecard/processingNotes');
export const storeLocalTCNote = createAction('store/timecard/localNote');
export const removeLocalTCNote = createAction('remove/timecard/localNote');
export const clearLocalTCNotes = createAction('clear/timecard/localNotes');

export const setCurrentTimecard = createAction('timecard/currentTimecard');
export const clearCurrentTimecard = createAction(
  'timecard/clearCurrentTimecard',
);
export const resetValidation = createAction('timecard/resetValidation');
export const clearTimecardDetails = createAction(
  'timecard/clearTimecardDetails',
);

export const setFromURL = createAction('timecard/fromURL');
export const onTakeMeBack = createAction('timecard/onTakeMeBack');
export const onDelete = createAction('timecard/delete');
export const onSave = createAction('timecard/save');
export const onSubmit = createAction('timecard/submit');
export const onApprove = createAction('timecard/approve');
export const onSelectEmployee = createAction('timecard/selectedEmployee');

export const savingTimecard = createAction('timecard/saving');
export const enableESignature = createAction('timecard/enble_esignature');
export const reloadTimecard = createAction('timecard/reloadTimecard');
export const printTimecard = createAction('timecard/printTimecard');

export const fetchTimecardDetails = createAction(
  'timecard/fetchTimecardDetails',
);

//timecard Allowances
export const fetchTCAllowances = createAction('fetch/timecard/allowances');
export const storeTCAllowances = createAction('store/timecard/allowances');
export const processingAllowances = createAction('timecard/processAllowances');
export const fetchTimecardAllowances = createAction(
  'fetch/timecard/timecard_allowances',
);
export const storeTimecardAllowances = createAction(
  'store/timecard/timecard_allowances',
);
export const storeEmployeeAllowancePayCodes = createAction(
  'store/timecard/employeeAllowancePayCodes',
);
export const fetchEmployeeAllowancePayCodes = createAction(
  'fetch/timecard/employeeAllowancePayCodes',
);
export const downloadSupportingDocument = createAction(
  'download/timecard/timecardAllowanceDocument',
);
export const removeTimecardAllowance = createAction(
  'remove/timecard/Allowance',
);
export const storeLocalTCAllowance = createAction(
  'store/timecard/localAllowances',
);
export const removeLocalTimecardAllowance = createAction(
  'remove/timecard/localAllowance',
);
export const clearLocalTimecardAllowance = createAction(
  'clear/timecard/localAllowance',
);

// save / update
export const saveTimecardAllowance = createAction(
  'save/timecard/timecardAllowance',
);

export const loadingTeamTimecard = createAction('loadingTeamTimecard');
export const fetchTeamTimecard = createAction('fetch/teamTimecard');
export const storeTeamTimecard = createAction('store/teamTimecard');
export const storeTeamTimecardAllowances = createAction(
  'store/teamTimecard/allowances',
);

export const saveTeamTimecard = createAction('save/teamTimecard');
export const submitTeamTimecard = createAction('submit/teamTimecard');
export const submitEmergencyTeamTimecard = createAction(
  'submit/emergency/teamTimecard',
);

export const submitToEmployees = createAction('submit/toEmployees');

export const removeEmployee = createAction('teamTimecard/removeEmployee');

export const removeLocalEmployee = createAction(
  'teamTimecard/removeLocalEmployee',
);

export const saveTeamAllowance = createAction('save/teamAllowance');
export const deleteTeamAllowances = createAction('delete/teamAllowances');
export const initTimecard = createAction('init/timecard');

export const fetchEmployees = createAction('fetch/employees');
export const storeEmployees = createAction('store/employees');

export const resetAllowanceInput = createAction('reset/timecard/allowance');
export const selectAllowanceType = createAction(
  'allowanceType/timecard/allowance',
);
export const selectAllowanceAmount = createAction('amount/timecard/allowance');
export const selectAllowanceRate = createAction('rate/timecard/allowance');
export const selectAllowanceUnits = createAction('units/timecard/allowance');
export const selectAllowanceCombineCheckCode = createAction(
  'combineCheckCode/timecard/allowance',
);
export const uploadAllowanceDocument = createAction(
  'uploadDocument/timecard/allowance',
);

export const setEditComment = createAction('set/Comment');
export const clearEditComment = createAction('clear/Comment');

export const navTo = createAction('timecard/navTo');

export const fetchGroupRounding = createAction('timecard/fetchGroupRounding');
export const storeGroupRounding = createAction('timecard/storeGroupRounding');

export const setCopyFromPrevRoundFlag = createAction(
  'timecard/setCopyFromPrevRoundFlag',
);

export const updateDailyAutoAllowances = createAction(
  'timecard/updateDailyAutoAllowances',
);
export const setUpdatingAutoAllowances = createAction(
  'timecard/setUpdatingAutoAllowances',
);

export const reset = createAction('timecard/reset');
