import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { getUploadedFiles, hasAnyFileErrors } from 'selectors/digitalEdits';
import { FilesTypography } from './Shared';
import * as actions from 'actions/digitalEdits';
import File from './File';

import { MAX_FILE_COUNT } from './FileUploadUtils';

const useStyles = makeStyles(({ palette }) => ({
  selectedListContainer: {
    backgroundColor: `${palette.gray['+9']}`,
    padding: '4px',
    maxHeight: '190px',
    overflow: 'auto',
    marginTop: '8px',
  },
  removeErrorsBtn: {
    cursor: 'pointer',
    color: palette.primary.primary,
    fontSize: 14,
    '&:hover': {
      textShadow: `2px 2px 2px ${palette.gray['+9']}`,
    },
  },
}));

const FILE_PATH = 'uploaded';
const mapState = state => ({
  uploadedFiles: getUploadedFiles(state),
  hasErrors: hasAnyFileErrors(state),
});
const mapDispatch = dispatch => ({
  onDeleteFileFromDB: ({ documentId }) => {
    dispatch(actions.deleteFileFromDB({ documentId, path: FILE_PATH }));
  },
  onDeleteFile: ({ index, fileName }) => {
    dispatch(actions.deleteFile({ index, fileName, path: FILE_PATH }));
  },
  onDownloadFile: ({ file }) => {
    dispatch(actions.downloadFile({ file, path: FILE_PATH }));
  },
  onClearAllErrorFiles: () => {
    dispatch(actions.clearAllErrorFiles());
  },
});

const UploadedFilesList = props => {
  const classes = useStyles();
  const {
    onDeleteFileFromDB,
    uploadedFiles,
    onDeleteFile,
    onDownloadFile,
    hasErrors,
    onClearAllErrorFiles,
  } = props;

  const onHandleDelete = ({ documentId, index, fileName }) => {
    if (documentId) onDeleteFileFromDB({ documentId });
    else onDeleteFile({ index, fileName });
  };
  if (uploadedFiles.length === 0) return null;

  const errorCount = uploadedFiles.filter(f => f.errors?.length > 0).length;
  const nonErrorCount = uploadedFiles.length - errorCount;

  return (
    <Box sx={{ mt: '20px' }}>
      {/* Selected */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex' }}>
          <FilesTypography>
            Uploaded Files {`(${nonErrorCount} of ${MAX_FILE_COUNT})`}{' '}
          </FilesTypography>
          <FilesTypography sx={{ color: 'error.main' }}>
            {errorCount > 0 ? `(${errorCount} errors)` : ''}
          </FilesTypography>
        </Box>
        {hasErrors && (
          <Typography
            className={classes.removeErrorsBtn}
            onClick={() => {
              onClearAllErrorFiles();
            }}
          >
            Remove all errored files
          </Typography>
        )}
      </Box>
      {uploadedFiles.length > 0 && (
        <Box className={classes.selectedListContainer}>
          {uploadedFiles.map((file, i) => {
            return (
              <File
                key={`${file.documentId}-${file.fileName}-${file.tempDocId}`}
                file={file}
                index={i}
                canDelete
                canDownload
                onDownloadFile={onDownloadFile}
                onDeleteFile={onHandleDelete}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

UploadedFilesList.propTypes = {
  onDeleteFileFromDB: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  onDownloadFile: PropTypes.func.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  onClearAllErrorFiles: PropTypes.func.isRequired,
};

export default connect(mapState, mapDispatch)(UploadedFilesList);
