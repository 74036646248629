import { takeEvery, call, put, select } from 'redux-saga/effects';
// actions
import * as actions from 'actions/events';
import { showAlert } from 'actions/alert';
import { initialize as enablePagination } from 'actions/pagination';
// selectors
import { getProject as project } from 'selectors/routeParams';

function* fetch(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const events = yield call(api.admin.events, { projectId });
    yield put(actions.store({ events }));
    yield put(
      enablePagination({
        grid: 'admin-events',
        disable: true,
        totalCount: events.length,
      }),
    );
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    yield put(actions.store({ events: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
    debug(e);
  }
}

export default function* eventsFlow({ api, debug }) {
  yield takeEvery(`${actions.fetch}`, fetch, api, debug);
}
