export const producersStartDayWeek = [
  {
    label: 'MO - Monday',
    value: 'MO - Monday',
    code: 'MO',
  },
  {
    label: 'TU - Tuesday',
    value: 'TU - Tuesday',
    code: 'TU',
  },
  {
    label: 'WE - Wednesday',
    value: 'WE - Wednesday',
    code: 'WE',
  },
  {
    label: 'TH - Thursday',
    value: 'TH - Thursday',
    code: 'TH',
  },
  {
    label: 'FR - FRIDAY',
    value: 'FR - FRIDAY',
    code: 'FR',
  },
  {
    label: 'SA - Saturday',
    value: 'SA - Saturday',
    code: 'SA',
  },
  {
    label: 'SU - Sunday',
    value: 'SU - Sunday',
    code: 'SU',
  },
];
