import createAction from './createAction';

export const init = createAction('dts/init');

export const fetchData = createAction('dts/fetchData');

export const save = createAction('dts/save');

export const store = createAction('dts/store');
export const storeTemplates = createAction('dts/storeTemplates');

export const loading = createAction('dts/loading');

export const setDirty = createAction('dts/setDirty');

export const fetchTemplates = createAction('dts/fetchTemplates');
export const setTemplateError = createAction('dts/setTemplateError');

export const fetchGroupOptions = createAction('dts/fetchGroupOptions');
export const fetchSoloOptions = createAction('dts/fetchSoloOptions');
export const clearSoloOptions = createAction('dts/clearSoloOptions');
export const storeDdOptions = createAction('dts/storeDdOptions');

export const copyToAllSoloSelect = createAction('dts/copyToAllSoloSelect');

export const fetchDealMemos = createAction('dts/fetchDealMemos');
export const storeDealMemos = createAction('dts/storeDealMemos');

export const optionsLoading = createAction('dts/optionsLoading');

export const cleanup = createAction('dts/cleanup');
export const reset = createAction('dts/reset');
export const storeSort = createAction('dts/storeSort');

//modal
export const setModalAction = createAction('dts/setModalAction');
export const clearModalAction = createAction('dts/clearModalAction');
export const setPostSaveAction = createAction('dts/setPostSaveAction');

//date actions
export const setEffectiveDate = createAction('dts/setEffectiveDate');

//nav
export const listTimecards = createAction('dts/listTimecards');

//FE filter actions
export const setSearchFilter = createAction('dts/setSearchFilter');
export const onSelect = createAction('dts/onSelect');
export const onSelectAll = createAction('dts/onSelectAll');
export const resetFilters = createAction('dts/resetFilters');

export const setFilterPristine = createAction('dts/setFilterPristine');
export const addShowBlanksOption = createAction('dts/addShowBlanksOption');

//BE Filter actions
export const storeFilterOptions = createAction('dts/storeFilterOptions');
export const fetchFilterOptions = createAction('dts/fetchFilterOptions');

//signalR / websocket
export const setSaveMetaData = createAction('dts/setSaveMetaData');
export const setSaveComplete = createAction('dts/setSaveComplete');

export const fetchRounding = createAction('dts/fetchRounding');
export const setRounding = createAction('dts/setRounding');

export const setDeleteJobId = createAction('dts/setDeleteJobId');
export const deleteTimecards = createAction('dts/deleteTimecards');
export const setDeletedEmployees = createAction('dts/setDeletedEmployees');
export const fetchDeleted = createAction('dts/fetchDeleted');
