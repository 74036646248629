import createAction from './createAction';

export const onNavToBatchInWTC = createAction('moveTimecards/navToBatchInWTC');

//moveTimecards actions
export const initMoveModal = createAction('moveTimecards/initMoveModal');
export const fetchDestinationBatches = createAction(
  'moveTimecards/fetchDestinationBatches',
);
export const storeDestinationBatches = createAction(
  'moveTimecards/storeDestinationBatches',
);
export const fetchDestinationInvoices = createAction(
  'moveTimecards/fetchDestinationInvoices',
);
export const storeDestinationInvoices = createAction(
  'moveTimecards/storeDestinationInvoices',
);

export const setMoveBatchTCs = createAction('moveTimecards/setMoveBatchTCs');
export const fetchTCbyBatchID = createAction('moveTimecards/fetchTCbyBatchID');

export const fetchSourceBatchInfo = createAction(
  'moveTimecards/fetchSourceBatchInfo',
);
export const storeSourceBatch = createAction('moveTimecards/storeSourceBatch');

export const setMoveLoading = createAction('moveTimecards/setMoveLoading');
export const moveTimecards = createAction('moveTimecards/moveTimecards');
export const setMoveJobInfo = createAction('moveTimecards/setMoveJobInfo');

export const moveModalClose = createAction('moveTimecards/moveModalClose');

export const setMoveDone = createAction('move/setMoveDone');

export const resetMoveModal = createAction('moveTimecards/resetMoveModal');
