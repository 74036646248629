import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// decorators
import withDataGrid from 'decorators/withDataGrid';

// components
import UsersAllProjectsList from 'components/Admin/UsersAllProjects';
// actions
import * as actions from 'actions/users';
// selectors
import { getLoading, getFilteredUsersAllProjects } from 'selectors/users';
import UserAccessReport from 'components/Admin/UsersAllProjects/UserAccessReport';
import { sortData } from 'selectors/sorting';

const grid = 'users-all-projects';
const mapState = (state, ownProps) => {
  const { pagination } = ownProps;
  const tempusers = getFilteredUsersAllProjects(state);

  const gridPagination = {
    ...pagination,
    totalCount:
      pagination && pagination.totalCount !== tempusers.length
        ? tempusers.length
        : pagination.totalCount,
    activePage:
      tempusers.length > pagination.activePage * pagination.pageSize
        ? pagination.activePage
        : 0,
  };

  return {
    pagination: gridPagination,
    loading: getLoading(state),
    allUsers: sortData(
      getFilteredUsersAllProjects,
      grid,
      gridPagination,
    )(state),
  };
};

const mapDispatch = (dispatch, { match }) => ({
  onFetchUsers: emailOrFullName => {
    dispatch(actions.fetchUsersAllProjects({ emailOrFullName }));
  },
});

class UsersAllProjects extends Component {
  static propTypes = {
    allUsers: PropTypes.array.isRequired,
    onFetchUsers: PropTypes.func.isRequired,
    sortParams: PropTypes.object.isRequired,
    onApplySorting: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { onFetchUsers } = this.props;
    onFetchUsers('');
  }

  render() {
    const { allUsers, ...others } = this.props;
    return (
      <div>
        <UsersAllProjectsList allUsers={allUsers} {...others} />
        <UserAccessReport />
      </div>
    );
  }
}

export default withDataGrid(
  'users-all-projects',
  'fullName',
)(connect(mapState, mapDispatch)(UsersAllProjects));
