import { Button, Tooltip } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  MenuButton: {
    minWidth: '30px',
    height: '30px',
    width: '30px',
    padding: 2,
    margin: 0,
    fontSize: 8,
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: palette.gray['+8'],
    '&:hover': {
      backgroundColor: palette.action.disabled,
      boxShadow: 'none',
    },
  },
  isActive: {
    backgroundColor: palette.gray['+6'],
  },
}));

const MenuButton = props => {
  const {
    Icon,
    isActive = false,
    tooltip = '',
    color = 'text.secondary',
    disabled,
    className,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <Tooltip title={tooltip}>
      <div>
        <Button
          className={clsx(
            `PENDOrichText${tooltip}`,
            classes.MenuButton,
            className,
            {
              [classes.isActive]: isActive,
            },
          )}
          disabled={disabled}
          {...rest}
        >
          <Icon sx={{ fontSize: 20, color: color && !disabled ? color : '' }} />
        </Button>
      </div>
    </Tooltip>
  );
};

MenuButton.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  isActive: PropTypes.bool,
  tooltip: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default MenuButton;
