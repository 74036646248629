import React from 'react';
// import PropTypes from 'prop-types';
import { Paper, styled, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FieldArray } from 'redux-form';

//components
import TimecardDays from './TimecardDays';
import Header from './Header';
import MobileDayModal from '../Modals/MobileDayModal';
import AttachmentWarning from '../Shared/AttachmentWarning';

const useStyles = makeStyles(({ palette }) => ({
  TimecardTab: {
    width: '100%',
    border: `solid 1px ${palette.gray['+9']}`,
    borderRadius: '10px',
    background: palette.gray['+10'],
    marginTop: '10px',
  },
}));

const SectionBox = styled(Box)(({ theme }) => ({
  margin: '15px',
  padding: '5px',
  borderRadius: '12px',
  backgroundColor: `${theme.palette.gray['+9']}`,
}));

const TimecardTab = props => {
  const { loading, saving, rejecting } = props;
  const classes = useStyles();
  return (
    <Paper className={classes.TimecardTab}>
      <SectionBox>
        <Header />
      </SectionBox>
      <AttachmentWarning />
      <SectionBox>
        {/* adding below conditions - 
        because in mobile delete swipe not working properly 
        if day level data is rerendered after save/reject/loading.
        It is working properly only on first render 
        */}
        {!loading && !saving && !rejecting && (
          <FieldArray name="days" component={TimecardDays} />
        )}
        <MobileDayModal />
      </SectionBox>
    </Paper>
  );
};

TimecardTab.propTypes = {};

export default TimecardTab;
