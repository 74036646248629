import React, {
  useCallback,
  forwardRef,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { setDirtyFields } from 'actions/bulkEdit';

import Portal from 'components/Shared/ReactTable/Portal';
import CopyToAll from 'components/Shared/ReactTable/CopyToAll';

import BulkEditContext from './BulkEditContext';
// import { EditableRowsContext } from './BulkEditContext';

import {
  makeMaskFunc,
  preventBlanks,
  accountsFieldMaxLength,
} from 'utils/weekUtils';
import {
  normalizeTimeEntry,
  BULK_EDIT_TIME_FIELDS,
  // TIME_FIELDS_ERRORS_MESSAGES,
  normalizeValue,
  roundingValue,
} from 'utils/bulkEditUtils';
import { findNextValidInput } from 'utils/reactTableUtils';

const style = ({ palette }) => ({
  checkBox: {
    marginLeft: 20,
  },
  input: {
    border: `1px solid lightgray`,
    height: '20px',
    borderRadius: 3,
    fontSize: 12,
    padding: '0 6px 0 6px',
    '&:focus': {
      outline: 'none',
      border: `2px solid ${palette.secondary['+2']}`,
      borderRadius: 3,
      color: palette.secondary['+2'],
    },
  },
  errorInput: {
    backgroundColor: palette.button.yellow.background,
    color: palette.error.main,
  },
  errorWrapper: {
    border: `1px solid ${palette.error.main} !important`,
    borderRadius: 4,
  },
  copyToAllBtn: {
    width: 'max-content',
    fontSize: 12,
    marginTop: 8,
    marginLeft: 0,
    borderRadius: 10,
    padding: '0 8px 0 4px',
  },
  copyToAllIcon: {
    margin: 4,
  },
});

const Input = (props, ref) => {
  // We need to keep and update the state of the cell normally
  const {
    classes,
    value: tableValue, // from react-table
    row,
    column: { id: columnId, width: columnWidth },
    editable,
    isCheckbox,
    updateMyData,
    copyToAll,
    currentProject,
  } = props;

  const { original, index: rowIndex } = row;
  const dispatch = useDispatch();
  const context = useContext(BulkEditContext);

  const [triggerCTA, setTriggerCTA] = useState(false);
  React.useEffect(() => {
    if (triggerCTA) setTriggerCTA(false);
  }, [triggerCTA]);

  const isTimeField = BULK_EDIT_TIME_FIELDS.includes(columnId);
  const rounding = isTimeField ? original.rounding ?? 0.1 : null;

  let validationErr = original.validationErrors;
  let cellHasError = false;
  let errorMsg = '';

  if (isTimeField) {
    if (validationErr.length > 0) {
      cellHasError = validationErr.map(x => x.field).includes(columnId);
    }
  }
  if (cellHasError) {
    let theError = validationErr.find(x => x.field === columnId);
    errorMsg = theError.text;
  }
  let accountMask;
  if (columnId === 'accountCode') {
    accountMask = context.accountMask;
  }

  const [localValue, setValue] = useState(tableValue);
  const [focus, setFocus] = useState(null);

  useEffect(() => {
    setValue(tableValue);
  }, [tableValue]);

  const paths = [
    original.timecardEntryHeaderId, // exp: 'asd21-1231qw-231233-qw212'
    original.effectiveDate, // exp: '11-30-2020'
    columnId, // exp: 'accountCode', 'FreeField1'
  ];
  // to-do: will examinate whether it's needed anymore in the future
  const clickOutside = useCallback(
    e => {
      if (
        focus &&
        !focus.contains(e.target) &&
        focus !== document.activeElement
      ) {
        setFocus(null);
      }
    },
    [focus],
  );

  useEffect(() => {
    if (focus) {
      window.addEventListener('click', clickOutside);

      const fixedBoundary = document.querySelector('#BulkEditFixedBoundary');
      const spec = focus.getBoundingClientRect();
      const boundarySpec = fixedBoundary.getBoundingClientRect();
      const overlap = spec.left - boundarySpec.right;

      if (overlap <= 0) {
        document.querySelector('#BulkEditTableContainer') &&
          document.querySelector('#BulkEditTableContainer').scrollBy({
            behavior: 'smooth',
            top: 0,
            left: overlap - 10,
          });
      }
    } else {
      window.removeEventListener('click', clickOutside);
    }
    return () => {
      window.removeEventListener('click', clickOutside);
    };
  }, [clickOutside, focus]);

  /*
  In case you are confused about code below:
  when tabbing between ndmIn/ ndmOut with value change,
  table will lose input's focus after forced re-render,
  this make sure it will refocus the element that just lost its focus.
  But we also need to make sure focus() won't be called on initial mount.
  Hence the solution:
  https://stackoverflow.com/questions/53179075/with-useEffect-how-can-i-skip-applying-an-effect-upon-the-initial-render
  */
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      if (['ndmIn', 'ndmOut'].includes(columnId) && !!focus) {
        setTimeout(() => {
          ref.current?.focus();
        });
      }
    } else {
      isMounted.current = true;
    }
  });

  const onBlur = e => {
    // time search fields and account mask auto fill and rounding here
    let normalizedNewValue = (function () {
      return isTimeField
        ? roundingValue({
            unrounded: localValue,
            rounding: rounding,
          })
        : normalizeValue(columnId, localValue, accountMask);
    })();

    if (normalizedNewValue !== localValue) {
      setValue(normalizedNewValue);
    }
    if (tableValue !== normalizedNewValue) {
      window.TimeoutId = setTimeout(() => {
        dispatch(
          setDirtyFields({
            paths,
            value: normalizedNewValue,
          }),
        );
        updateMyData(
          rowIndex,
          columnId,
          normalizedNewValue,
          row.original.timecardEntryHeaderId,
          row.original.dayIndex,
        );
      });
    }
    setFocus(null);
  };

  const onFocus = e => {
    setFocus(ref.current);
  };

  const onChange = e => {
    if (columnId === 'accountCode') {
      const maskingFunction = makeMaskFunc(accountMask);

      let newValue = maskingFunction(e.target.value);

      setValue(newValue);
    } else if (
      columnId === 'location' ||
      columnId === 'insurance' ||
      columnId === 'series' ||
      columnId === 'set' ||
      columnId === 'freeField1' ||
      columnId === 'freeField2' ||
      columnId === 'freeField3' ||
      columnId === 'freeField4'
    ) {
      let newValue = preventBlanks(e.target.value);
      setValue(newValue);
    } else if (BULK_EDIT_TIME_FIELDS.includes(columnId)) {
      const timeVal = normalizeTimeEntry(e.target.value, localValue);
      setValue(timeVal);
    } else {
      setValue(e.target.value);
    }
  };

  // please keep this
  const onKeyPress = e => {
    if (e.nativeEvent.key === 'Tab') {
      setFocus(null);
    }
    if (e.nativeEvent.key === 'Enter') {
      let nextElement;
      let params = {
        rowIndex,
        columnId,
        direction: e.shiftKey ? 'up' : 'down',
      };

      nextElement = findNextValidInput(params);

      if (nextElement) {
        setFocus(null);
        nextElement.select();
      }
    }
    if ((e.metaKey || e.ctrlKey) && e.shiftKey && e.key.toLowerCase() === 'a') {
      e.preventDefault();
      setTriggerCTA(true);
      //This is set to false on next render by useEffect next to setTriggerCTA declaration.
    }
  };
  const onCheckBoxChange = e => {
    setValue(e.target.checked);
  };

  return (
    <div>
      {isCheckbox ? (
        <input
          id={`${rowIndex}.${columnId}`}
          className={clsx(classes.input, classes.checkBox)}
          disabled={editable}
          ref={e => {
            ref.current = e;
          }}
          onChange={onCheckBoxChange}
          onBlur={onBlur}
          checked={localValue}
          type={'checkbox'}
          onKeyDown={onKeyPress}
          onFocus={onFocus}
        />
      ) : (
        <div
          className={clsx({
            [classes.errorWrapper]: cellHasError,
          })}
        >
          <Tooltip title={cellHasError ? errorMsg : ''} placement="top" arrow>
            <input
              id={`${rowIndex}.${columnId}`}
              className={clsx(classes.input, {
                [classes.errorInput]: cellHasError,
              })}
              autoComplete="off"
              style={{ width: columnWidth - 5 }}
              disabled={editable}
              ref={e => {
                ref.current = e;
              }}
              onChange={onChange}
              onBlur={onBlur}
              value={localValue || ''}
              type={'text'}
              onKeyDown={onKeyPress}
              onFocus={onFocus}
              maxLength={accountsFieldMaxLength(currentProject, columnId)}
              placeholder={columnId === 'accountCode' ? accountMask : ''}
            />
          </Tooltip>
        </div>
      )}
      {focus && (
        <Portal targetDom={focus} variant="bulkEdit" isCheckbox={isCheckbox}>
          <CopyToAll
            targetDom={focus}
            columnId={columnId}
            value={localValue}
            copyToAll={copyToAll}
            triggerCTA={triggerCTA}
          />
        </Portal>
      )}
    </div>
  );
};

export default withStyles(style)(forwardRef(Input));
