import { all, takeLatest, call, put, select } from 'redux-saga/effects';

// actions
import { showAlert } from 'actions/alert';
import { hide as hideModal } from 'actions/modalDialog';
import * as actions from 'actions/invoiceTemplate';
// selectors
import { getProject as project } from 'selectors/routeParams';
import { getEditingTemplate, getTemplates } from 'selectors/invoiceTemplate';

export function* fetchTemplates(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);

    const templates = yield call(api.invoiceTemplate.templates, { projectId });

    yield put(actions.storeTemplates({ templates }));
    yield put(actions.loading({ loading: false }));
    yield put(actions.storeTemplateOption({ templateOption: '' }));
  } catch (e) {
    yield put(actions.storeTemplates({ templates: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
    debug(e);
  }
}

export function* saveTemplate(api, debug, params) {
  try {
    const projectId = yield select(project);
    const { templateObj } = params;
    if (!templateObj.departments) templateObj.departments = [];

    if (!templateObj?.name) {
      return;
    }
    if (!templateObj?.producersStartDayOfWeek) {
      return;
    }
    let templates = yield select(getTemplates);

    let filteredTemplates = templates.filter(
      temp => temp.id !== templateObj.id,
    );

    let filterObj = filteredTemplates.filter(
      obj => obj.name.toLowerCase() === templateObj.name.toLowerCase(),
    );
    if (filterObj.length > 0) {
      yield put(
        showAlert({ message: 'Duplicate department name', variant: 'warning' }),
      );
      return;
    }
    const url = templateObj.id
      ? api.invoiceTemplate.update
      : api.invoiceTemplate.create;

    yield call(url, { projectId, batchTemplate: templateObj });
    yield put(actions.fetchTemplates());
    yield put(actions.storeTemplateOption({ templateOption: '' }));
  } catch (e) {
    yield put(showAlert());
    debug(e);
  }
}

export function* removeTemplate(api, debug) {
  try {
    const projectId = yield select(project);
    const template = yield select(getEditingTemplate);
    const templateId = template.id;
    yield call(api.invoiceTemplate.deleteBatch, { projectId, templateId });
    yield put(actions.fetchTemplates());
    yield put(hideModal({ dialog: 'invoiceTemplateDelete' }));
  } catch (e) {
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
    debug(e);
  }
}

export function* fetchSystemLocations(api, debug, params) {
  try {
    const templateType = 'SYSTEMLOCATION';
    yield put(actions.loading({ loading: true }));
    const projectId = yield select(project);
    const systemLocations = yield call(api.invoiceTemplate.templateTypes, {
      projectId,
      templateType,
    });

    yield put(actions.storeSystemLocations({ systemLocations }));

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    yield put(actions.storeSystemLocations({ systemLocations: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
    debug(e);
  }
}

export function* fetchLocationTypes(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const locationTypes = yield call(api.timecards.searchByTypes, {
      type: 'locationType',
      params: { page: 1, pageSize: 100, options: '' },
    });
    yield put(actions.storeLocationTypes({ locationTypes }));
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeLocationTypes({ locationTypes: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}
export default function* fetchTemplateFlow({ api, debug }) {
  yield all([
    takeLatest(`${actions.fetchTemplates}`, fetchTemplates, api, debug),
    takeLatest(
      `${actions.fetchSystemLocations}`,
      fetchSystemLocations,
      api,
      debug,
    ),
    takeLatest(`${actions.saveTemplate}`, saveTemplate, api, debug),
    takeLatest(`${actions.removeTemplate}`, removeTemplate, api, debug),
    takeLatest(`${actions.fetchLocationTypes}`, fetchLocationTypes, api, debug),
  ]);
}
