import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
//components
import FieldCheckboxDropDown from 'components/Shared/FieldCheckboxDropDown';

/**
 * Wrapper for additionalFields for DTS
 * @component
 * @param {array} hiddenColumns - hidden columns from react-table
 * @param {func} setHiddenColumns - update hidden columns. Must be passed a NEW array
 */
const AdditionalFieldsContainer = props => {
  const { setHiddenColumns, hiddenColumns, tableColumns } = props;

  const [additionalFields, setAdditionalFields] = useState([]);

  const [unsavedChanges, setUnsavedChanges] = useState({});
  const [disabled, setDisabled] = useState(false);

  // create additionalFields from hiddenColumn + tableColumn
  // keep in sync
  useEffect(() => {
    const activeFields = [];
    tableColumns.forEach((field, i) => {
      const visible = hiddenColumns.includes(field.id) === false;

      if (field.default === false) {
        activeFields.push({ id: field.id, label: field.Header, visible });
      }
    });

    activeFields.sort((a, b) => {
      if (a.position < b.position) return -1;
      else return 1;
    });

    setAdditionalFields(af => {
      return activeFields;
    });
    // Adding/Hiding columns in large table can cause render-lock
    // setting disabled on timeout so clicks that happen during that time
    // hit when the controls are still disabled
    setTimeout(() => setDisabled(false), 0);
  }, [hiddenColumns, tableColumns]);

  const setHiddenValue = useCallback((hiddenColumns, id, visible) => {
    const isHidden = hiddenColumns.includes(id);
    let changes = 0;

    if (isHidden && visible) {
      hiddenColumns.splice(hiddenColumns.indexOf(id), 1);
      changes++;
    }

    if (!isHidden && !visible) {
      hiddenColumns.push(id);
      changes++;
    }
    return changes;
  }, []);

  const updateHidden = useCallback(
    unsavedChanges => {
      setUnsavedChanges({});
      setDisabled(true);

      //needed so the setDisabled takes effect before the column changes
      setTimeout(() => {
        setHiddenColumns(old => {
          const hiddenColumns = old.slice();
          let changes = 0;
          for (const fieldId in unsavedChanges) {
            if (Object.hasOwnProperty.call(unsavedChanges, fieldId)) {
              const visible = unsavedChanges[fieldId];

              changes += setHiddenValue(hiddenColumns, fieldId, visible);
            }
          }
          if (changes === 0) {
            setDisabled(false);
            return old;
          } else {
            return hiddenColumns;
          }
        });
      }, 0);
    },
    // In order to debounce properly, updateHidden must be stable,
    // Use only render-stable items here.
    [setHiddenColumns, setHiddenValue],
  );

  const debouncedUpdate = useMemo(
    () => _.debounce(updateHidden, 600),
    [updateHidden],
  );

  //call debouncedUpdate every time a change is made
  useEffect(() => {
    if (!_.isEmpty(unsavedChanges)) {
      debouncedUpdate(unsavedChanges);
    }
  }, [debouncedUpdate, unsavedChanges]);

  const onClickField = (clickedField, newValue) => {
    if (disabled) return;
    setUnsavedChanges(old => {
      const unsavedChanges = _.cloneDeep(old);
      unsavedChanges[clickedField.id] = !!newValue;
      return unsavedChanges;
    });

    setAdditionalFields(oldFields => {
      const currentFields = oldFields.slice();
      const currField = currentFields.find(f => f.id === clickedField.id);
      currField.visible = newValue;
      return currentFields;
    });
  };

  const selectedCount =
    Object.keys(additionalFields).length - hiddenColumns.length;

  return (
    <FieldCheckboxDropDown
      fieldsList={additionalFields}
      selectedCount={selectedCount < 0 ? 0 : selectedCount}
      onClickField={onClickField}
      label="Additional Fields"
      disabled={disabled}
    />
  );
};

AdditionalFieldsContainer.propTypes = {
  setHiddenColumns: PropTypes.func.isRequired,
  hiddenColumns: PropTypes.array.isRequired,
  tableColumns: PropTypes.array.isRequired,
};

export default AdditionalFieldsContainer;
