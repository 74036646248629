import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

import { fileNameChange } from 'utils/helperFunctions';
import { ALLOWANCE_DOC_MAX_SIZE } from 'components/Shared/constants';

const useStyles = makeStyles(({ palette }) => ({
  DragDropFile: { display: 'flex', justifyContent: 'center' },
  filename: { display: 'flex', alignItems: 'center' },
  filenameOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '200px',
  },
}));

const DragDropFile = props => {
  const classes = useStyles();
  const {
    file,
    setFile,
    fileRequired,
    isTcEditable,
    disabled,
    isTcPending,
    setInitialFilename,
  } = props;

  const uploadInputRef = React.useRef(null);

  const onHandleFile = files => {
    const renamedFile = fileNameChange(files[0]);

    if (renamedFile.size > ALLOWANCE_DOC_MAX_SIZE) {
      setError('File must be less than 10MB');
      return;
    }

    if (
      renamedFile.type !== 'image/png' &&
      renamedFile.type !== 'image/jpeg' &&
      renamedFile.type !== 'application/pdf'
    ) {
      setError('File must be a png, jpeg, or pdf');
      return;
    }

    setFile(renamedFile);
  };
  const [dragActive, setDragActive] = React.useState(false);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('');
      }, 8000);
    }
  }, [error]);

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    }
    if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  if (!isTcEditable && !file && !isTcPending) {
    return null;
  }

  return (
    <Box className={classes.DragDropFile}>
      {file ? (
        <Box className={classes.filename}>
          <Tooltip title={file.name}>
            <span className={classes.filenameOverflow}>{file.name}</span>
          </Tooltip>
          {(isTcEditable || isTcPending) && (
            <Button
              sx={{ ml: 0 }}
              variant="text"
              onClick={e => {
                e.preventDefault();
                setFile(null);
                setInitialFilename(null); //allow user to re-upload the same filename
              }}
              disabled={disabled}
            >
              <DeleteIcon />
            </Button>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            border: dragActive ? `2px gray dashed` : `none`,
            display: 'flex',
            flexDirection: 'column',
          }}
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={e => {
            e.preventDefault();
            e.stopPropagation();
            const listArray = Array.from(e.dataTransfer.files);
            onHandleFile(listArray);
            setDragActive(false);
          }}
        >
          <input
            ref={uploadInputRef}
            type="file"
            id="input-file-upload"
            style={{ display: 'none' }}
            accept="image/png, image/jpeg, .pdf"
            onChange={e => {
              const array = Array.from(e.target.files);
              onHandleFile(array);
            }}
            onClick={e => {
              e.target.value = null;
            }}
          />
          <Button
            sx={{
              ml: 0,
              borderRadius: '5px',
              color: fileRequired ? 'primary.contrastText' : 'inherit',
            }}
            variant={fileRequired ? 'contained' : 'text'}
            startIcon={<AddCircleOutlineIcon />}
            onClick={e => {
              e.preventDefault();
              uploadInputRef.current.click();
            }}
            disabled={disabled}
          >
            {fileRequired ? 'Attach Required Document' : 'Upload Document'}
          </Button>
          <Box sx={{ color: 'error.main' }}>{error}</Box>
        </Box>
      )}
    </Box>
  );
};

DragDropFile.propTypes = {
  setFile: PropTypes.func.isRequired,
  fileRequired: PropTypes.bool.isRequired,
  isTcEditable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  file: PropTypes.object,
  isTcPending: PropTypes.bool.isRequired,
  setInitialFilename: PropTypes.func.isRequired,
};

export default DragDropFile;
