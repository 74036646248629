import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tooltip, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import ToggleCheckbox from './ToggleCheckbox';
import { getStatusBadge } from 'utils/helperFunctions';
import {
  EmergencyIcon,
  ForceResubmitIcon,
  checkTCSelection,
} from 'containers/Employees/Reviews/Shared/timecardUtils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const useStyles = makeStyles(({ palette }) => ({
  TimecardList: {},
  statusBadge: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 8px',
    borderBottom: `solid 1px ${palette.gray['+8']}`,
    '& > p': {
      margin: 0,
    },
  },
  selectAllLabel: {
    marginLeft: '-5px !important',
    color: palette.primary.main,
  },
  tcList: {
    overflow: 'scroll',
    height: '100%',
  },
  filterMsg: {
    display: 'flex',
    justifyContent: 'center',
    height: '50%',
    alignItems: 'center',
    textAlign: 'center',
  },
  employeeList: {
    display: 'flex',
    flexDirection: 'column',
    listStyleType: 'none',
    paddingLeft: 0,
  },
  timecard: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: palette.gray['+9'],
    },
  },
  selected: {
    backgroundColor: palette.gray['+8'],
  },
  checkboxContainer: {
    position: 'absolute',
    top: '8px',
    marginLeft: '-4px',
    marginTop: '2px',
    marginBottom: '2px',
  },

  status: {
    marginLeft: '-4px',
    marginTop: '2px',
    marginBottom: '2px',
  },
  displayFlex: {
    display: 'flex',
  },
  employeeName: {
    fontSize: 14,
    lineHeight: '18px',
    marginTop: '2px',
    marginBottom: '2px',
    fontWeight: 500,
    '& > span': {
      fontWeight: 'normal',
    },
  },
  employeeRole: {
    fontSize: 14,
    marginTop: '2px',
    marginBottom: '2px',
  },
  employeeListDisabled: {
    backgroundColor: palette.action.disabledBackground,
    color: palette.text.disabled,
    cursor: 'default',
  },
  tcStats: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 4,
  },
  columnPrimary: {
    flex: '80%',
  },
  emergencyType: {
    display: 'table-cell',
    verticalAlign: 'middle',
    width: 23,
  },
  infoIcon: {
    height: 24,
    width: 24,
    borderRadius: 2,
    cursor: 'pointer',
  },
}));

const TimecardList = props => {
  const classes = useStyles();
  const {
    timecards,
    currentTimecard,
    selectedTCS,
    setSelectedTCS,
    loadTimecard,
    wtcUpdating,
    userLevel,
    isPA,
    isUPM,
    bulkAction,
    filterCount,
  } = props;

  const showCheckboxes = !!bulkAction;

  const onSelectAll = checked => {
    if (checked) {
      const newSelected = [];
      timecards.forEach(tc => {
        /* PA checkbox selection conditions
         * TC should be in pending_pa_review status
         * If TC is emergency type or bulk action should not be reject
         */
        const isTCEligible = checkTCSelection(
          tc,
          userLevel,
          bulkAction,
          isUPM,
          isPA,
        );
        if (isTCEligible) {
          newSelected.push(tc.timecardEntryHeaderId);
        }
      });
      setSelectedTCS(newSelected);
    } else {
      setSelectedTCS([]);
    }
  };

  const checkboxSelectionHandler = (e, entryHeaderId) => {
    setSelectedTCS(oldSelected => {
      const newSelected = oldSelected.slice();
      const checked = e.target.checked;

      if (checked) {
        newSelected.push(entryHeaderId);
      } else {
        const idx = newSelected.indexOf(entryHeaderId);
        newSelected.splice(idx, 1);
      }
      return newSelected;
    });
  };

  const selectableCount = timecards.reduce((acc, t) => {
    const isTCEligible = checkTCSelection(
      t,
      userLevel,
      bulkAction,
      isUPM,
      isPA,
    );
    if (isTCEligible) acc++;
    return acc;
  }, 0);

  return (
    <div className={classes.tcList}>
      {showCheckboxes && (
        <FormGroup>
          <FormControlLabel
            className={classes.selectAllLabel}
            control={
              <Checkbox
                indeterminate={
                  selectedTCS.length > 0 &&
                  selectedTCS.length !== selectableCount
                }
                checked={
                  selectedTCS.length > 0 &&
                  selectedTCS.length === selectableCount
                }
                onChange={e => onSelectAll(e.target.checked)}
                onClick={e => e.stopPropagation()}
                inputProps={{ 'aria-label': 'Select All' }}
              />
            }
            label="Select All"
          />
        </FormGroup>
      )}
      {filterCount.total !== filterCount.showing && filterCount.showing === 0 && (
        <div className={classes.filterMsg}>
          No timecards <br />
          for this filter selection
        </div>
      )}
      <ul className={classes.employeeList}>
        {timecards.map(timecard => {
          const canSelect = checkTCSelection(
            timecard,
            userLevel,
            bulkAction,
            isUPM,
            isPA,
          );
          const {
            employee,
            employeeSSN,
            timecardEntryHeaderId,
            status,
            occupationName,
            lastModified,
            lastUpdatedBy,
            emergencyType,
            approvalFlowId,
            department,
            workflowAction,
          } = timecard;

          const lastSsnDigits = employeeSSN?.replace(/[X-]/g, '') || '';

          let modified = `Modified: ${moment(
            lastModified,
          ).fromNow()} by ${lastUpdatedBy}`;

          let infoTooltip = (
            <div>
              <section>{modified}</section>
              {department && <section>Department: {department}</section>}
            </div>
          );

          const listItem = (
            <li
              key={timecard.timecardEntryHeaderId}
              className={clsx(classes.statusBadge, {
                [classes.selected]: currentTimecard === timecardEntryHeaderId,
                [classes.employeeListDisabled]:
                  wtcUpdating && currentTimecard !== timecardEntryHeaderId,
                [classes.timecard]: !wtcUpdating,
              })}
            >
              <section
                className={classes.displayFlex}
                style={{ position: 'relative' }}
              >
                <div className={classes.checkboxContainer}>
                  <ToggleCheckbox
                    showCheckboxes={showCheckboxes}
                    selectedTCS={selectedTCS}
                    canSelect={canSelect}
                    approvalFlowId={approvalFlowId}
                    timecardEntryHeaderId={timecardEntryHeaderId}
                    checkboxSelectionHandler={checkboxSelectionHandler}
                  />
                </div>
              </section>
              <section
                style={{ marginLeft: showCheckboxes ? '20px' : '0px' }}
                className={classes.displayFlex}
                onClick={() => {
                  if (!wtcUpdating) loadTimecard(timecardEntryHeaderId);
                }}
              >
                <article className={classes.columnPrimary}>
                  <p className={classes.employeeName}>
                    {employee} - {lastSsnDigits}
                  </p>
                  <p className={classes.employeeRole}>{occupationName}</p>
                  <div className={classes.tcStats}>
                    <div className={classes.emergencyType}>
                      {emergencyType ? (
                        <EmergencyIcon />
                      ) : workflowAction === 'resubmitToEmployee' ? (
                        <ForceResubmitIcon />
                      ) : (
                        ''
                      )}
                    </div>
                    <div className={classes.status}>
                      {getStatusBadge(status, isPA, isUPM, userLevel)}
                    </div>
                    <div>
                      <Tooltip arrow title={infoTooltip}>
                        <InfoOutlinedIcon className={clsx(classes.infoIcon)} />
                      </Tooltip>
                    </div>
                  </div>
                </article>
              </section>
            </li>
          );
          return listItem;
        })}
      </ul>
    </div>
  );
};

TimecardList.propTypes = {
  bulkAction: PropTypes.string.isRequired,
  currentTimecard: PropTypes.string,
  filterCount: PropTypes.object.isRequired,
  isPA: PropTypes.bool.isRequired,
  isUPM: PropTypes.bool.isRequired,
  loadTimecard: PropTypes.func.isRequired,
  selectedTCS: PropTypes.array.isRequired,
  timecards: PropTypes.array.isRequired,
  userLevel: PropTypes.number.isRequired,
  wtcUpdating: PropTypes.bool.isRequired,
};

export default TimecardList;
