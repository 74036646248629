import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Paper,
  styled,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';

import { getDataCardVisible } from 'selectors/digitalEdits';

import {
  HIDE_WHEN_EMPTY,
  dataBoxValue,
  DATA_BOX_MAX_LEN,
} from './digitalEditsUtils';

const useStyles = makeStyles(({ palette }) => ({
  DataCard: {
    borderRadius: 15,
    padding: 12,
  },
  headerText: {
    fontSize: 20,
    fontWeight: 700,
  },
  mainCard: {
    flex: 1,
  },
  standardText: {
    fontSize: 14,
    fontWeight: 600,
    color: palette.gray.primary,
  },
  tooltip: {
    cursor: 'default',
    borderBottom: `1px dashed ${palette.gray.primary}`,
  },
}));

const Cell = styled(TableCell)(({ theme }) => ({
  '&:not(:last-child)': {
    paddingRight: 12,
  },
  borderBottom: 'none',
  padding: 0,
}));

const DataCard = props => {
  const classes = useStyles();
  let {
    data,
    isAllCurrency = true,
    sx,
    className,
    mainCard,
    viewEnabled = true,
    noTruncate,
  } = props;

  const makeRow = React.useCallback(
    (row, i) => {
      let { label, value, units, isHeader, isCurrencyRow } = row;

      if (
        HIDE_WHEN_EMPTY.includes(label?.toLowerCase()) &&
        !value &&
        value !== 0
      ) {
        return null;
      }

      let isCurrency =
        isCurrencyRow || isCurrencyRow === false
          ? isCurrencyRow
          : isAllCurrency;

      const toolTipMap = {
        'EE Taxes': 'Employee Taxes',
        'ER Taxes': 'Employer Taxes',
      };
      let tooltip = toolTipMap[label];

      const rowClass = isHeader ? classes.headerText : classes.standardText;

      const showColon = label && (value || value === 0);

      const showUnits = units || units === 0;

      if (isCurrency && (value || value === 0)) {
        value = Number(value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      }

      if (typeof units === 'number') {
        units = units.toFixed(2);
      }

      return (
        <TableRow key={i}>
          <Cell className={rowClass}>
            {tooltip ? (
              <>
                <Tooltip title={toolTipMap[label]} placement="top">
                  <span className={classes.tooltip}>{label}</span>
                </Tooltip>
              </>
            ) : (
              label
            )}
            {showColon ? ':' : ''}
          </Cell>
          {showUnits && (
            <Cell className={rowClass} align="right">
              {units}
            </Cell>
          )}
          <Cell
            sx={{ maxWidth: `${DATA_BOX_MAX_LEN}ch` }}
            className={rowClass}
            align="right"
          >
            {dataBoxValue(value, { noTruncate })}
          </Cell>
        </TableRow>
      );
    },
    [
      isAllCurrency,
      classes.headerText,
      classes.standardText,
      classes.tooltip,
      noTruncate,
    ],
  );

  if (!viewEnabled) return null;
  if (!data) return null;
  if (Array.isArray(data) === false || data.length === 0) return null;

  return (
    <Paper
      sx={sx}
      className={clsx(classes.DataCard, className, {
        [classes.mainCard]: mainCard,
      })}
    >
      <Table>
        <TableBody>{data.map(makeRow)}</TableBody>
      </Table>
    </Paper>
  );
};

DataCard.propTypes = {
  data: PropTypes.array.isRequired,
  isAllCurrency: PropTypes.bool,
  sx: PropTypes.object,
  className: PropTypes.string,
  mainCard: PropTypes.bool,
  viewEnabled: PropTypes.bool,
};

const mapState = (state, ownProps) => ({
  viewEnabled: getDataCardVisible(state, ownProps?.controlKey || ''),
});

export const DataCardWithControl = connect(mapState)(props => {
  return <DataCard {...props} />;
});

export default DataCard;
