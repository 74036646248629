import { takeEvery, call, put } from 'redux-saga/effects';
import { showAlert } from 'actions/alert';
import * as actions from 'actions/masterDepartments';

export function* fetch(api, debug) {
  try {
    const departments = yield call(api.departments.masterList);
    yield put(actions.store({ departments }));
  } catch (e) {
    debug(e);
    yield put(actions.store({ departments: [] }));
    yield put(showAlert());
  }
}

export default function* masterDepartmentsFlow({ api, debug }) {
  yield takeEvery(`${actions.fetch}`, fetch, api, debug);
}
