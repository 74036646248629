import _ from 'lodash';
import { createSelector } from 'reselect';
import { getFiltering } from './gridFiltering';

import { getYearFromDbCode } from 'utils/helperFunctions';

export const defaultProject = {
  isHidden: true,
  departmentApprove: false,
  dhGrossEnabled: false,
  paDeleteTimecards: false,
};

export const getProjects = state => _.get(state, 'projects.list', []);
export const getProjectsCount = state => _.get(state, 'projects.totalCount', 0);
export const getProjectSearchText = state =>
  _.get(state, 'projects.searchText', null);
export const getProjectDetails = state =>
  _.get(state, 'projects.current', defaultProject);
export const getPayrollCoordinator = state =>
  _.get(state, 'projects.current.payrollCoordinator', {
    id: null,
    email: null,
  });
export const getBackupCoordinators = state =>
  _.get(state, 'projects.current.backupPayrollCoordinator', []);
export const getCurrentDTSSetting = state =>
  _.get(state, 'projects.current.dtsSettingEnabled', false);

export const getLoading = state => _.get(state, 'projects.loading', false);
export const projectUsersLoading = state =>
  _.get(state, 'projects.loadingProjectUsers', false);
export const getStats = state => _.get(state, 'projects.stats', {});
export const getCurrentProject = state => _.get(state, 'projects.current', {});
export const getCurrentProjectWorkSight = state =>
  _.get(state, 'projects.current.worksightId', '');
export const getCurrentProjectDbCodeId = state =>
  _.get(state, 'projects.current.dbCodeId', '');

export const getDepartments = state => _.get(state, 'projects.departments', []);

export const getDropDownOptions = filterName => state =>
  _.get(state, `projects.filters.${filterName}`, []);

export const getSortedDepartments = createSelector(
  [getDepartments],
  departments =>
    _.sortBy(departments, [d => d && d.name && d.name.toLowerCase()]),
);

export const getDepartmentById = createSelector(
  [getDepartments, (state, departmentId) => departmentId],
  (departments, departmentId) => {
    return _.find(departments, ['id', Number(departmentId)]);
  },
);

export const getProjectUsers = state => _.get(state, 'projects.users', {});
export const getProjectsFilter = state => getFiltering(state, 'admin-projects');
export const getDepartmentApproval = state =>
  _.get(state, 'projects.current.departmentApprove', false);
export const validating = state =>
  _.get(state, 'projects.validatingStart', false);
export const startProjectValidation = state =>
  _.get(state, 'projects.startProject', {});

//To check projects belongs to multiple DBs
export const multiDBCheck = createSelector([getProjects], projects => {
  let multiDBs = false;
  const unique = [
    ...new Set(
      projects.map(item => item.dbCodeName !== null && item.dbCodeName),
    ),
  ];
  if (unique.length > 1) {
    multiDBs = true;
  }
  return multiDBs;
});
export const getCurrentDtsTemplate = state =>
  _.get(state, 'projects.current.dtsTemplateId', null);

export const getAccountMask = state =>
  _.get(state, 'projects.current.accountMask', undefined);

export const getViewingYear = state => {
  const year = _.get(state, 'projects.viewingYear', null);
  const numYear = Number(year);
  return Number.isNaN(numYear) ? null : numYear;
};

export const getIsProjectArchived = createSelector(
  [getProjectDetails],
  project => {
    return !!project.canAccessNextYearProject;
  },
);

export const getFilterDbCode = createSelector(
  [getViewingYear, getProjectDetails],
  (viewingYear, project) => {
    if (_.isEmpty(project)) return undefined; //can be empty on initial reset
    const dbCode = project.dbCode;
    const dbYear = getYearFromDbCode(dbCode);

    if (dbYear !== viewingYear) {
      console.warn(
        `dbCode (${dbCode}) does not match viewing year ${viewingYear}`,
      );
    }

    return dbCode;
  },
);

// could use viewingYear here, but this checks it against the DB code
export const getRouteYear = createSelector([getFilterDbCode], dbCode => {
  return getYearFromDbCode(dbCode);
});

export const getInitialSettings = createSelector(
  [state => getProjectDetails(state), (state, formName) => formName],
  (details, formName) => {
    const settings = {};
    const formToValueMap = {
      DtsSettings: ['dtsTemplateId', 'dtsSettingEnabled'],
      TimeEntrySettings: ['timeZone', 'timeInterval'],
      ESignature: ['esignatureEnabled'],
    };

    let valuesToCopy = formToValueMap[formName] || [];
    valuesToCopy.forEach(fieldName => {
      settings[fieldName] = details[fieldName];
    });
    return settings;
  },
);
export const getRegion = state => _.get(state, 'projects.current.region', null);
export const getIsRegionCAN = createSelector(
  [getRegion],
  region => region === 'Canada',
);

export const getIsProjectCompleted = createSelector(
  [getProjectDetails],
  project => {
    return project.status === 'C' || project.status === 'P';
  },
);

export const getStudioPlusEnabled = state =>
  _.get(state, 'projects.current.studioPlusEnabled', true);

export const getHoursPlusEnabled = state =>
  _.get(state, 'projects.current.timePlusEnabled', false);
