import _ from 'lodash';
import { createSelector } from 'reselect';
import { getFiltering } from './gridFiltering';
import { ROLE_LABELS } from 'components/props/profiles';

export const getLoading = state => _.get(state, 'users.loading', false);
export const getUsers = state => _.get(state, 'users.list', []);
export const getUsersAllProjectsFilter = state =>
  getFiltering(state, 'users-all-projects');

export const getUsersOfAllProjects = state =>
  _.get(state, 'users.usersAllProjectsList', []);

export const getFilteredUsersAllProjects = createSelector(
  [getUsersAllProjectsFilter, getUsersOfAllProjects],
  (gridFilter, users) => {
    const result = _.map(users, user => {
      const roleFilter = ROLE_LABELS[user.role];
      return {
        ...user,
        roleFilter,
      };
    });

    if (!gridFilter) return result;

    gridFilter = (gridFilter || '').toLowerCase();
  },
);
