import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LinearProgress } from '@mui/material';
// components
import ScheduleComp from 'components/Employees/Reports/Schedule';
//container
import { RemoveScheduledReport, EditScheduledReport } from './Modals';
// selectors
import { currentUser } from 'selectors/session';
//actions
import {
  fetchScheduledReports,
  loading,
  editingScheduledReport,
} from 'actions/reports';
import { show as showModal } from 'actions/modalDialog';
import { fetch as fetchProjects } from 'actions/project';
// selectors
import { getLoading, getScheduledReports } from 'selectors/reports';

const mapStateToProps = (state, { match }) => ({
  projectId: match.params.projectId,
  activeUser: currentUser(state),
  reports: getScheduledReports(state),
  isLoading: getLoading(state),
});

const mapDispatch = (dispatch, { match }) => ({
  onFetchData: () => {
    dispatch(loading({ loading: true }));
    dispatch(fetchScheduledReports());
    dispatch(fetchProjects());
  },
  onAddScheduledReport: reportId => {
    dispatch(
      editingScheduledReport({ projectId: match.params.projectId, reportId }),
    );
    dispatch(showModal({ dialog: 'editScheduledReport' }));
  },
  onEditScheduledReport: reportId => {
    dispatch(
      editingScheduledReport({ projectId: match.params.projectId, reportId }),
    );
    dispatch(showModal({ dialog: 'editScheduledReport' }));
  },
  onDeleteScheduledReport: reportId => {
    dispatch(
      editingScheduledReport({ projectId: match.params.projectId, reportId }),
    );
    dispatch(showModal({ dialog: 'removeScheduledReport' }));
  },
});

class Schedule extends Component {
  static propTypes = {
    reports: PropTypes.array.isRequired,
    projectId: PropTypes.string.isRequired,
    activeUser: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.onFetchData();
  }

  render() {
    if (this.props.isLoading) return <LinearProgress />;

    return (
      <div>
        <ScheduleComp {...this.props} />
        <RemoveScheduledReport />
        <EditScheduledReport />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatch)(Schedule);
