import { Divider, Typography } from '@mui/material';
import React from 'react';
import _ from 'lodash';
import { Label } from 'components/Shared/Text';

export function renderApprovers(history) {
  const historyFiltered = _.find(
    history,
    app => app.approvers && app.approvers.length > 0,
  );
  if (historyFiltered) {
    const approverRows =
      !!historyFiltered &&
      _.map(historyFiltered.approvers, (approver, index) => (
        <Label uppercase primary key={`${index}-approver`}>
          {approver.fullName}
        </Label>
      ));

    return (
      <div style={{ padding: 20 }}>
        <Divider />
        <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
          Current Approvers
        </Typography>
        <div style={{ marginTop: 15 }}>{approverRows}</div>
      </div>
    );
  }
  return null;
}
