import createAction from './createAction';

//Profile
export const updateProfileDetails = createAction('updateProfileDetails');

///notifications
export const fetchNotifications = createAction('fetch/profile/notifications');
export const loadingNotifications = createAction(
  'loading/profile/notifications',
);
export const storeNotifications = createAction('store/profile/notifications');
export const editPreferences = createAction('edit/profile/preferences');
export const updateNotifications = createAction('update/profile/notifications');
export const setUpdateStatus = createAction(
  'set/profile/updateStatus/notifications',
);

//phone number
export const fetchPhoneNumber = createAction('fetch/profile/phoneNumber');
export const loadingPhoneNumber = createAction('loading/profile/phoneNumber');
export const storePhoneNumber = createAction('store/profile/phoneNumber');
export const verifyPhoneNumber = createAction('verify/profile/phoneNumber');
export const resendVerificationCode = createAction(
  'resendVerificationCode/profile/code',
);
export const verifyCode = createAction('verifyCode/profile/code');
export const deletePhoneNumber = createAction('delete/profile/phoneNumber');

export const downloadDefaultAllowanceDocument = createAction(
  'download/profile/defaultAllowance',
);
export const downloadingSupportingDocument = createAction(
  'download/supporting-document',
);
