import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Table as MuiTable, TableBody } from '@mui/material';
import TableHeadWTC from './TableHeadWTC';
import { columnHeaderProps } from './TableHeadWTC';

const TableListWTC = props => {
  const { children, className, id } = props;
  const { headers, onShowMemoModal, dealMemoId, wtcDisabled } = props;

  return (
    <Paper square elevation={0} className={className} id={id}>
      <MuiTable>
        <TableHeadWTC
          columns={headers}
          onShowMemoModal={onShowMemoModal}
          dealMemoId={dealMemoId}
          wtcDisabled={wtcDisabled}
        />
        <TableBody>{children}</TableBody>
      </MuiTable>
    </Paper>
  );
};

TableListWTC.propTypes = {
  className: PropTypes.string,
  headers: columnHeaderProps.isRequired,
  id: PropTypes.string.isRequired,
  onShowMemoModal: PropTypes.func.isRequired,
  dealMemoId: PropTypes.string.isRequired,
  wtcDisabled: PropTypes.bool.isRequired,
};

export default TableListWTC;
