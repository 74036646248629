const button = {
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: 500,
        textTransform: 'none',
        marginLeft: 8,
        borderRadius: 2,
        whiteSpace: 'nowrap',
      },
      startIcon: {
        '& > .MuiSvgIcon-root': {
          fontSize: '1.5rem',
        },
      },
      containedPrimary: ({ theme }) => {
        const { palette } = theme;
        return {
          '&:hover': {
            backgroundColor: palette.button.primary.hover,
          },
        };
      },
      outlinedPrimary: ({ theme }) => {
        const { palette } = theme;
        return {
          '&:hover': {
            color: palette.button.primary.color,
            borderColor: palette.button.primary.focus,
            backgroundColor: palette.button.primary.hover,
          },
        };
      },
      textPrimary: ({ theme }) => {
        const { palette } = theme;
        return {
          border: '1px solid transparent',
          '&:hover': {
            border: `1px solid ${palette.button.primary.hover}`,
          },
        };
      },
      containedError: ({ theme }) => {
        const { palette } = theme;
        return {
          '&:hover': { backgroundColor: palette.button.error.hover },
        };
      },
    },
    defaultProps: {
      variant: 'contained',
    },
  },
};

export default button;
