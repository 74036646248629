import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'utils/helperFunctions';

// decorators
import withModalDialog from 'decorators/withModalDialog';

// components
import { ConfirmModal } from 'components/Shared/Modals';

// actions
import { moveTimecards } from 'actions/moveTimecards';

//selectors
import { getModalParams } from 'selectors/modalDialog';

const modalDialog = 'ConfirmMoveTimecard';

const style = theme => {
  return {
    content: {
      margin: '20px 0px',
    },
  };
};

const mapStateToProps = state => ({
  modalParams: getModalParams(state, modalDialog),
});

const mapDispatchToProps = dispatch => ({
  onMoveTimecard: data => {
    dispatch(moveTimecards(data));
  },
});

const ConfirmMoveTimecard = props => {
  const { classes, onMoveTimecard, modalParams = {}, onModalHide } = props;
  const { data, lockMessage } = modalParams;

  const message =
    lockMessage === 'new'
      ? 'Since all timecards being moved to the new batch are already Submitted to C&C, performing this move will cause the new batch to be locked on creation.'
      : 'Since all remaining timecards in the source batch are already Submitted to C&C, performing this move will cause the source batch to lock.';

  const content = (
    <div>
      <Typography className={classes.content}>{message}</Typography>
      <Typography className={classes.content}>
        This cannot be undone.
      </Typography>
    </div>
  );

  return (
    <form onSubmit={() => onMoveTimecard(data)}>
      <ConfirmModal
        content={content}
        buttonText="Move and Lock"
        raisedButton
        onModalHide={onModalHide}
      />
    </form>
  );
};

export default compose(
  withStyles(style),
  withModalDialog({ dialog: modalDialog, maxWidth: 'sm' }),

  connect(mapStateToProps, mapDispatchToProps),
)(ConfirmMoveTimecard);
