import React, { useRef, useEffect } from 'react';
import { Grid, LinearProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { connect } from 'react-redux';

// components
import InvoiceTemplateContent from 'components/Admin/Projects/InvoiceTemplate/InvoiceTemplate';
import InvoiceTemplateDelete from 'containers/Admin/Projects/InvoiceTemplate/InvoiceTemplateDelete';
// actions
import * as actions from 'actions/invoiceTemplate';
import { fetchDepartments } from 'actions/project';

import {
  fetchCountriesV1,
  fetchStatesV1,
  fetchCitiesV1,
  fetchCountiesV1,
  fetchSubdivisionsV1,
  storeStatesV1,
  storeCitiesV1,
  storeCountiesV1,
  storeSubdivisionsV1,
} from 'actions/location';
import { show as showModal } from 'actions/modalDialog';

// selectors
import { getSortedDepartments } from 'selectors/project';
import {
  getCityOptions,
  getStateOptions,
  // getSubdivisionOptions,
  getCountriesOptions,
  // getCountyOptions,
} from 'selectors/wtc';
import {
  getLoading,
  getTemplates,
  getSystemLocations,
  getTemplateOption,
  getLocationTypes,
} from 'selectors/invoiceTemplate';

import { producersStartDayWeek } from 'components/Admin/Projects/InvoiceTemplate/invoiceTemplateUtils';
import { getCurrentProject } from 'selectors/project';

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: 'inherit',
  },
  progressBar: {
    width: '100%',
  },
}));

const mapState = state => ({
  countryOptions: getCountriesOptions(state),
  cityOptions: getCityOptions(state),
  stateOptions: getStateOptions(state),
  templates: getTemplates(state),
  departments: getSortedDepartments(state),
  loading: getLoading(state),
  systemLocations: getSystemLocations(state),
  templateOption: getTemplateOption(state),
  // countyOptions: getCountyOptions(state),
  // subdivisionsOptions: getSubdivisionOptions(state),
  currentProject: getCurrentProject(state),
  locationTypes: getLocationTypes(state),
});

const mapDispatch = (dispatch, { match }) => ({
  onFetchData: () => {
    dispatch(actions.fetchTemplates());
    dispatch(actions.fetchSystemLocations());
    dispatch(actions.fetchLocationTypes());
    dispatch(fetchDepartments());
    dispatch(fetchCountriesV1());
  },
  onDeleteTemplate: templateId => {
    dispatch(actions.editingTemplate({ templateId }));
    dispatch(showModal({ dialog: 'invoiceTemplateDelete' }));
  },
  saveTemplateData: templateObj => {
    dispatch(actions.saveTemplate({ templateObj }));
  },
  onFetchCountries: () => dispatch(fetchCountriesV1()),
  onFetchStates: args => dispatch(fetchStatesV1(args)),
  onFetchCities: args => dispatch(fetchCitiesV1(args)),
  onFetchCounties: args => dispatch(fetchCountiesV1(args)),
  onFetchSubdivisions: args => dispatch(fetchSubdivisionsV1(args)),
  onResetLoc: variant => {
    switch (variant) {
      case 'workState':
        dispatch(storeStatesV1({ states: [] }));
        break;
      case 'workCity':
        dispatch(storeCitiesV1({ cities: [] }));
        break;
      case 'workCounty':
        dispatch(storeCountiesV1({ counties: [] }));
        break;
      case 'workSubdivision':
        dispatch(storeSubdivisionsV1({ subdivisions: [] }));
        break;

      default:
        break;
    }
  },
  saveInvoiceTemplateOption: val => {
    dispatch(actions.storeTemplateOption({ templateOption: val }));
  },
  storeUpdatedTemplates: updated => {
    dispatch(actions.storeTemplates({ templates: updated }));
  },
});
const InvoiceTemplate = ({
  templates,
  loading,
  departments,
  onDeleteTemplate,
  saveTemplateData,
  invoiceTypes,
  systemLocations,
  onFetchData,
  templateOption,
  saveInvoiceTemplateOption,
  storeUpdatedTemplates,
  countryOptions,
  stateOptions,
  cityOptions,
  // countyOptions,
  // subdivisionsOptions,
  onFetchCountries,
  onFetchStates,
  onFetchCities,
  onFetchCounties,
  onFetchSubdivisions,
  onResetLoc,
  currentProject,
  locationTypes,
}) => {
  const locationFuncs = {
    countryOptions,
    stateOptions,
    cityOptions,
    // countyOptions,
    // subdivisionsOptions,
    onFetchCountries,
    onFetchStates,
    onFetchCities,
    onFetchCounties,
    onFetchSubdivisions,
    onResetLoc,
  };

  const classes = useStyles();
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current === false) {
      onFetchData();
      didMountRef.current = true;
    }
  }, [onFetchData]);
  return (
    <Grid>
      {loading && (
        <Grid item className={classes.progressBar}>
          <LinearProgress />
        </Grid>
      )}
      {!loading && (
        <Grid item md={12}>
          <InvoiceTemplateContent
            templates={templates}
            departments={departments}
            producersStartDayWeek={producersStartDayWeek}
            systemLocations={systemLocations}
            deleteTemplate={onDeleteTemplate}
            saveTemplateData={saveTemplateData}
            saveInvoiceTemplateOption={saveInvoiceTemplateOption}
            templateOption={templateOption}
            storeUpdatedTemplates={storeUpdatedTemplates}
            currentProject={currentProject}
            locationFuncs={locationFuncs}
            locationTypes={locationTypes}
          />
        </Grid>
      )}
      <InvoiceTemplateDelete />
    </Grid>
  );
};
export default connect(mapState, mapDispatch)(InvoiceTemplate);
