import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TableRow, TableCell } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { TableList } from 'components/Shared/TableList';
import { FieldArray } from 'redux-form';
import { timecardProps } from 'components/props/timecard';
import { MEALS } from 'components/Shared/constants';
import WeekDay from './WeekDay';
import { enabledAccountCodes, getTotalHours } from 'utils/weekUtils';

const style = ({ palette }) => ({
  root: {},
  totalHoursCell: {
    paddingRight: 5,
    verticalAlign: 'middle',
    color: palette.primary.main,
    fontWeight: 700,
    fontSize: 16,
  },
  hoursCell: {
    paddingRight: 5,
    verticalAlign: 'middle',
    color: palette.primary.main,
    fontWeight: 700,
    fontSize: 16,
    textAlign: 'center',
  },
  totalHoursRow: {
    backgroundColor: palette.gray['+8'],
  },
});

class TimecardDays extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    timecard: timecardProps.isRequired,
    activeDays: PropTypes.object,
    onCopyToAll: PropTypes.func,
    showAccountCodes: PropTypes.bool,
  };
  state = {
    dropShadow: false,
  };

  mealColumns() {
    const { timecard, showLastMan1In, showNdm, showNdb, caRegion } = this.props;
    const mealContent = [];

    if (showNdb) {
      const ndbLabel = caRegion ? 'General Call' : 'NDB In';
      mealContent.push({ columnId: `ndb_in`, label: ndbLabel });
    }

    _.times(timecard.meals || 1, index => {
      const outKey = `meal${index + 1}_out`;
      const inKey = `meal${index + 1}_in`;
      mealContent.push({ columnId: inKey, label: `${MEALS[index].label} Out` });
      mealContent.push({ columnId: outKey, label: `${MEALS[index].label} In` });
      if (showLastMan1In && index === 0) {
        mealContent.push({ columnId: 'lastMan1In', label: `Last Man In` });
      }

      if (showNdm && index === 0) {
        mealContent.push(
          { columnId: `ndm_out`, label: `NDM Out` },
          { columnId: `ndm_in`, label: `NDM In` },
        );
      }
    });

    return mealContent;
  }

  renderDays({
    accountCodesEnabled,
    activeDays,
    colLength,
    editableFields,
    fields,
    states,
    timecard,
    classes,
    onCopyToAll,
    isViewOnly,
    dropShadow,
    loadDayTypes,
    cityOptions,
    stateLabel,
    isCityVisible,
    project,
    ...props
  }) {
    let firstDay = true;
    const daysError = (props.error && props.error.days) || [];

    const weekdays = fields.map((member, index) => {
      const day = timecard.days[index];
      const firstDayIndex = firstDay;
      const dayError = daysError[index];
      if (day && day.isActive) {
        firstDay = false;
        return (
          <WeekDay
            index={index}
            stateLabel={stateLabel}
            isCityVisible={isCityVisible}
            key={day.date}
            member={member}
            day={day}
            firstDay={firstDayIndex}
            meals={timecard.meals || 1}
            states={states}
            colLength={colLength}
            even={index % 2 === 0}
            timecard={timecard}
            accountCodesEnabled={accountCodesEnabled}
            onCopyToAll={onCopyToAll}
            viewOnly={isViewOnly}
            editableFields={editableFields}
            dayError={dayError}
            dropShadow={dropShadow}
            loadDayTypes={loadDayTypes}
            cityOptions={cityOptions}
            project={project}
            {...props}
          />
        );
      }

      return null;
    });

    return weekdays;
  }

  render() {
    const {
      classes,
      timecard,
      payrollSettings,
      projectSettings,
      onCopyToAll,
      role = 'employee',
      activeDays,
      loadDayTypes,
      isViewOnly,
      isCityVisible,
      caRegion,
      project,
    } = this.props;
    const { dropShadow } = this.state;
    const accountCodesEnabled = enabledAccountCodes(projectSettings, role);
    const stateLabel = caRegion ? 'Work Prov' : 'Work State';
    const headers = [
      { columnId: 'day', label: 'Day' },
      {
        columnId: 'workLocation',
        label: 'Work Location',
        optional: true,
      },
      { columnId: 'dayType', label: 'Day Type' },
      { columnId: 'workState', label: stateLabel },
      { columnId: 'workCity', label: 'Work City', hidden: !isCityVisible },
      { columnId: 'call', label: 'Call' },
      ...this.mealColumns(),
      { columnId: 'wrap', label: 'wrap' },
      { columnId: 'hours', label: 'Hours', alignRight: true },
      { columnId: 'actions', label: 'Actions', align: 'center' },
    ];

    let headerColumns = headers;
    if (caRegion) {
      headerColumns = headers.filter(
        column => column.label !== 'Work Location',
      );
    }
    const checkScroll = () => {
      const row = document.querySelector('#timecardRow');
      if (row) {
        if (row.getBoundingClientRect().left < 0 && !dropShadow) {
          this.setState({ dropShadow: !dropShadow });
        }
        if (row.getBoundingClientRect().left >= 0 && dropShadow) {
          this.setState({ dropShadow: !dropShadow });
        }
      }
    };

    return (
      <TableList
        headers={headerColumns}
        className={classes.root}
        isAlternateRowColor={false}
        overflow
        onScroll={checkScroll}
      >
        <FieldArray
          stateLabel={stateLabel}
          isCityVisible={isCityVisible}
          name="days"
          component={this.renderDays}
          activeDays={this.props.activeDays}
          states={this.props.states}
          timecard={timecard}
          colLength={headerColumns.length}
          payrollSettings={payrollSettings}
          projectSettings={projectSettings}
          accountCodesEnabled={accountCodesEnabled}
          onCopyToAll={onCopyToAll}
          rerenderOnEveryChange
          isViewOnly={isViewOnly}
          editableFields={this.props.editableFields}
          dropShadow={dropShadow}
          loadDayTypes={loadDayTypes}
          project={project}
          {...this.props}
        />

        <TableRow className={classes.totalHoursRow}>
          <TableCell
            className={classes.totalHoursCell}
            align="right"
            colSpan={headerColumns.length - 2}
          >
            Total Hours
          </TableCell>
          <TableCell className={classes.hoursCell}>
            {getTotalHours(timecard, activeDays)}
          </TableCell>
          <TableCell />
        </TableRow>
      </TableList>
    );
  }
}

export default withStyles(style)(TimecardDays);
