import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
//components
import { DH, UPM, IA } from 'components/props/profiles';
//selector
import {
  isUserInfoLoaded as getUserLoading,
  getCurrentUserRole,
  hasReviewerRole,
  getSessionUser,
  getProjUsers,
} from 'selectors/session';
import { getUseNewNavBar } from 'selectors/flags';

import { getProject } from 'selectors/routeParams';
import { LANDING_PAGE_BY_ROLE } from 'components/Shared/constants';
//actions
import { logout } from 'actions/authenticate';
import { removeImpersonation } from 'feature/CrewList/actions';
import { setViewingYear } from 'actions/project';
import { hideAlert } from 'actions/alert';

//utils
import { getYearFromQueryString, db } from 'utils/helperFunctions';
import { loggedInUserProps } from 'components/props/users';

//newHeaders =
import HeaderV2 from 'containers/Layout/HeaderV2';
import ProjectHeaderV2 from 'components/Layout/ProjectHeaderV2';

const mapStateToProps = state => ({
  isUserLoaded: getUserLoading(state),
  userRole: getCurrentUserRole(state),
  projectId: getProject(state),
  projUserInfo: getProjUsers(state),
  loggedInUser: getSessionUser(state),
  useNewNavBar: getUseNewNavBar(state),
});

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(logout()),
  onRemoveImpersonation: () => dispatch(removeImpersonation()),
  onSetViewingYear: viewingYear => dispatch(setViewingYear({ viewingYear })),
  onHideAlert: () => dispatch(hideAlert()),
});

const EnhancedRoute = props => {
  const {
    layout: Layout,
    component: Component,
    headers: incomingHeaders,
    isLoadProjectActiveUser = false,
    isUserLoaded,
    showBackBtn,
    userRole,
    admin,
    projectId,
    // computedMatch,
    projUserInfo,
    onLogout,
    onRemoveImpersonation,
    onSetViewingYear,
    onHideAlert,
    path,
    location,
    exact,
    loggedInUser,
    title = '',
    useNewNavBar,
  } = props;

  const headers = [];

  incomingHeaders.forEach(header => {
    //TODO HOUR-11661

    if (useNewNavBar) {
      const name = header.displayName;
      db('display name', name);
      if (name === 'HeaderV1') {
        headers.push(HeaderV2);
      } else if (name === 'ProjectHeaderV1') {
        headers.push(ProjectHeaderV2);
      } else if (name === 'AlertHeaderV1') {
        //do nothing, remove alertHeader
      } else {
        headers.push(header);
      }
    } else {
      headers.push(header);
    }
  });

  //Props that we're passing through to the <Route> component
  const routeProps = {
    path,
    location,
    exact,
  };
  const isAccessAdmin = loggedInUser.isAccessAdmin || false;
  const isAdmin = loggedInUser.isAdmin || false;
  const isSuperAdmin = loggedInUser.isSuperAdmin || false;
  const URL_ADMIN_ALL_PROJECTS = '/admin/users-all-projects';
  const URL_ACCESS_ADMIN = '/admin/accessadmin';
  const URL_ADMIN = '/admin/projects';
  const URL_SUPER_ADMIN = '/admin/superadmin';

  //dismiss alert on navigation
  useEffect(() => {
    onHideAlert();
  });

  useEffect(() => {
    if (title) {
      document.title = `Hours+  ${title}`;
    } else {
      document.title = 'Hours+';
    }
  }, [title]);
  const locationPath = props.location.pathname;
  const isLogout = locationPath === '/logout';
  const isRemoveImpersonate = locationPath === '/removeImpersonate';

  //role is IA when user is invoice approver ONLY. otherwise its in the roles array
  const isOnlyIA = userRole === IA;

  const history = useHistory();
  const queryParams = props.location.search;

  if (queryParams) {
    const year = getYearFromQueryString(queryParams);
    if (year) {
      onSetViewingYear(year);
      history.replace({ search: '' });
    }
  }
  if (isLogout) {
    onLogout();
    return <Redirect to={''} />;
  } else if (isRemoveImpersonate) {
    onRemoveImpersonation();
    return <Redirect to={URL_ADMIN_ALL_PROJECTS} />;
  }

  let redirectUrl = LANDING_PAGE_BY_ROLE(userRole, projectId);

  if (isAccessAdmin && locationPath !== URL_ACCESS_ADMIN) {
    return <Redirect to={URL_ACCESS_ADMIN} />;
  } else if (!isAccessAdmin && locationPath === URL_ACCESS_ADMIN) {
    if (isAdmin) {
      return <Redirect to={URL_ADMIN} />;
    } else if (isSuperAdmin) {
      return <Redirect to={URL_SUPER_ADMIN} />;
    } else {
      return <Redirect to={redirectUrl} />;
    }
  }

  if (isUserLoaded) {
    if (locationPath === URL_ADMIN_ALL_PROJECTS) {
      redirectUrl = URL_ADMIN_ALL_PROJECTS;
    }

    if ((admin && userRole !== 'admin') || (!admin && userRole === 'admin')) {
      return <Redirect to={{ pathname: redirectUrl }} />;
    }

    if (projUserInfo[projectId] && projUserInfo[projectId].inviteStatus) {
      const inviteStatus = projUserInfo[projectId].inviteStatus;
      const isUserDeleted = projUserInfo[projectId].deletedAt !== null;
      // redirect user to invitation page if they haven't validated their SSN.
      if (inviteStatus === 'invited') {
        if (projUserInfo[projectId].invitationToken === null) {
          return <Redirect to={'/projects'} />;
        }
        if (
          props.path !== '/projects' && //user may have other projects not in 'invited' status
          props.path !== '/' &&
          props.path !== '/invitations/:token' // or if they're on the right path already
        ) {
          const token = projUserInfo[projectId].invitationToken;
          const inviteURL = `/invitations/${token}`;
          return <Redirect to={inviteURL} />;
        }
      } else if (
        inviteStatus === 'accepted' &&
        props.path === '/invitations/:token'
      ) {
        return <Redirect to={'/'} />;
      } else if (isUserDeleted) {
        if (projUserInfo[projectId].invitationToken !== null) {
          // redirect deleted users to the invitation page if they have an invite token
          const token = projUserInfo[projectId].invitationToken;
          const inviteURL = `/invitations/${token}`;
          if (props.path !== '/invitations/:token') {
            return <Redirect to={inviteURL} />;
          }
        }
        if (props.path.includes('/projects/')) {
          //deleted users should only be able to visit these urls:
          const allowedUrlList = [
            '/projects/:projectId/me/timecards',
            '/projects/:projectId/me/timecards/:timecardId',
            '/invitations/:token',
          ];
          if (allowedUrlList.includes(props.path) === false) {
            return <Redirect to={'/'} />;
          }
        }
      }
    }

    const isReviewer = hasReviewerRole(userRole);
    if (!isReviewer) {
      const blockUrlList = [
        '/projects/invite',
        '/projects/users',
        '/projects/crew/timecards/weekEndings/department',
        '/projects/crew/timecards',
        '/projects/reports/ondemand',
        '/projects/reports/schedule',
        '/projects/reviews/htg',
        '/projects/reviews/approval-flow',
        '/projects/reviews',
        '/projects/reviews/timecards',
        '/projects/reviews/wtc',
        '/projects/reviews/bulk-edit',
      ];

      if (isOnlyIA) {
        blockUrlList.push('/projects/crew-list');
      }
      if (blockUrlList.includes(path)) {
        return <Redirect to={{ pathname: redirectUrl }} />;
      }
    } else if (isReviewer && userRole === DH) {
      const blockUrlList = [
        '/projects/reviews/wtc',
        '/projects/reviews/bulk-edit',
      ];
      if (blockUrlList.includes(path)) {
        return <Redirect to={{ pathname: redirectUrl }} />;
      }
    } else if (isReviewer && userRole === UPM) {
      const blockUrlList = ['/projects/reviews/bulk-edit'];
      if (blockUrlList.includes(path)) {
        return <Redirect to={{ pathname: redirectUrl }} />;
      }
    }
  }
  let renderMethod;
  let renderComponent = isLoadProjectActiveUser && !isUserLoaded ? false : true;

  if (Layout) {
    const renderLayouts = routeProps => (
      <Layout headers={headers} showBackBtn={showBackBtn} path={path}>
        {renderComponent && <Component {...routeProps} />}
      </Layout>
    );

    renderMethod = renderLayouts;
  } else {
    db('Route with no layout', path);
    renderMethod = routeProps => (
      <React.Fragment>
        {renderComponent && <Component {...routeProps} />}
      </React.Fragment>
    );
  }

  return <Route {...routeProps} render={renderMethod} />;
};

EnhancedRoute.propTypes = {
  layout: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  authenticated: PropTypes.bool,
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ),
  showBackBtn: PropTypes.bool,
  location: PropTypes.object.isRequired,
  onRemoveImpersonation: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onHideAlert: PropTypes.func.isRequired,
  onSetViewingYear: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  id: PropTypes.string,
  isLoadProjectActiveUser: PropTypes.bool,
  isUserLoaded: PropTypes.bool,
  userRole: PropTypes.string,
  projUserInfo: PropTypes.object,
  isLogout: PropTypes.bool,
  isRemoveImpersonate: PropTypes.bool,
  admin: PropTypes.bool,
  isOnlyIA: PropTypes.bool,
  exact: PropTypes.bool,
  title: PropTypes.string,
  useNewNavBar: PropTypes.bool,
  path: function (props, propName, componentName) {
    if (
      props.location.pathname !== '/removeImpersonate' &&
      !props.is404 &&
      !props[propName]
    ) {
      return new Error(
        `${propName} is required unless we're removing impersonation or on 404. In ${componentName}.`,
      );
    }
  },
  loggedInUser: loggedInUserProps,
};

EnhancedRoute.defaultProps = {
  headers: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedRoute);
