import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import withRouter from 'decorators/withRouter';
import { push } from 'redux-first-history';

import { Toolbar, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Warning } from '@mui/icons-material';

// components
import { AppBar } from 'components/Nav';
import { profileProps, ROLE_LABELS } from 'components/props/profiles';
// actions
import { fetch as fetchUserInfo } from 'actions/userInfo';
import { setViewingYear } from 'actions/project';

// selectors
import { currentUser as activeUser, getSessionUser } from 'selectors/session';
import { getProjectDetails } from 'selectors/project';

//utils
import {
  getYearFromDbCode,
  getAltYearLandingPage,
} from 'utils/helperFunctions';
import { CURRENT_YEAR } from 'components/Shared/constants';

const style = ({ breakpoints, palette }) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  toolBar: {
    minHeight: 48,
    fontWeight: 700,
    fontSize: 14,
    display: 'flex',
    flexDirection: 'row',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  warningIcon: {
    color: palette.warning.main,
    alignSelf: 'center',
    order: 0,
    [breakpoints.down('sm')]: {
      order: 0,
    },
  },
  alert: {
    order: 1,
    alignSelf: 'center',
    flexGrow: 1,
    display: 'flex',
    [breakpoints.down('sm')]: {
      order: 1,
    },
  },
  alertNoGrow: {
    flexGrow: 0,
  },
  alertText: {
    padding: 5,
  },
  actionText: {
    order: 2,
    alignSelf: 'center',
    [breakpoints.down('sm')]: {
      order: 2,
    },
  },
  button: {
    marginLeft: 8,
    color: palette.getDefaultColor(),
    border: 0,
    order: 3,
    [breakpoints.down('sm')]: {
      order: 3,
    },
  },
  blkButton: {
    backgroundColor: 'black',
    color: 'white',
  },
  whiteButton: {
    backgroundColor: 'white',
  },
});

const mapStateToProps = state => ({
  currentUser: getSessionUser(state),
  activeUser: activeUser(state),
  projectDetails: getProjectDetails(state),
});

const mapDispatchToProps = (dispatch, { match }) => ({
  onFetchData: () => {
    dispatch(fetchUserInfo());
  },
  onRemoveImpersonation: () => {
    dispatch(push('/removeImpersonate'));
  },
  onSetYear: viewingYear => dispatch(setViewingYear({ viewingYear })),
});

// Note: this comp used to be ImpersonationHeader.js
// It's been repurposed as a general alert bar.
class AlertHeader extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    currentUser: profileProps,
    onFetchData: PropTypes.func.isRequired,
    onRemoveImpersonation: PropTypes.func.isRequired,
  };

  static defaultProps = {
    project: undefined,
    isLoggedIn: false,
    currentUser: undefined,
    activeUser: undefined,
  };

  componentDidMount() {
    const { onFetchData } = this.props;
    onFetchData();
  }

  render() {
    const {
      classes,
      //impersonate props
      activeUser,
      currentUser,
      onRemoveImpersonation,
      // prev year props
      projectDetails,
      onSetYear,
    } = this.props;

    const alerts = [];

    //Impersonate alert
    if (!currentUser || !currentUser.impersonating || currentUser.isAdmin) {
      //Don't show Impersonate banner
    } else {
      const impersonateAlert = {
        alertText: `You are impersonating: ${currentUser.fullName}`,
        actionText: `User Role: ${ROLE_LABELS[activeUser.role]}`,
        buttonText: 'Return to Admin',
        buttonFunc: onRemoveImpersonation,
        alertVariant: 'impersonate',
      };
      alerts.push(impersonateAlert);
    }

    // view past year alert
    let currentlyInProject = false;
    //are we current in a specific project based on the URL ?
    if (this.props.match && this.props.match.url) {
      currentlyInProject = /projects\/\d+/.test(this.props.match.url);
    }

    if (
      projectDetails &&
      !!projectDetails.dbCode &&
      projectDetails.canAccessNextYearProject &&
      currentlyInProject &&
      this.props.location
    ) {
      const projYear = getYearFromDbCode(projectDetails.dbCode);

      const pastYearAlert = {
        alertText: `You are viewing a ${projYear} project`,
        actionText: ``,
        buttonText: `Return to current year`,

        alertVariant: 'prevYear',
      };

      if (projectDetails.dbCode === 'PAY20') {
        const nextYearProjectId = projectDetails.nextYearProjectId;
        const url = getAltYearLandingPage(
          this.props.location,
          nextYearProjectId,
          activeUser.role,
        );
        pastYearAlert.buttonFunc = () => {
          window.location.href = url;
        };
      } else {
        pastYearAlert.buttonFunc = () => {
          onSetYear(CURRENT_YEAR);
        };
      }

      alerts.push(pastYearAlert);
    }

    return (
      <AppBar impersonate>
        {alerts.map((alert, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Toolbar key={index} className={classes.toolBar}>
            <div
              className={clsx(classes.alert, {
                [classes.alertNoGrow]: alert.alertVariant === 'prevYear',
              })}
            >
              <Warning className={classes.warningIcon} />
              <span className={classes.alertText}>{alert.alertText}</span>
            </div>
            <div className={classes.actionText}>{alert.actionText}</div>
            <Button
              variant="outlined"
              className={clsx(classes.button, {
                [classes.blkButton]: alert.alertVariant === 'prevYear',
                [classes.whiteButton]: alert.alertVariant === 'impersonate',
              })}
              onClick={alert.buttonFunc}
            >
              {alert.buttonText}
            </Button>
          </Toolbar>
        ))}
      </AppBar>
    );
  }
}

const AlertHeaderV1 = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(AlertHeader)),
);

AlertHeaderV1.displayName = 'AlertHeaderV1';

export default AlertHeaderV1;
