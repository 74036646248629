export const linearGradient = (direction, ...colors) =>
  `linear-gradient(${direction}, ${colors.join(', ')})`;

export const grayToGrey = gray => {
  const grey = {
    50: gray['+9'],
    100: gray['+8'],
    200: gray['+7'],
    300: gray['+6'],
    400: gray['+3'],
    500: gray['+2'],
    600: gray['+0'],
    700: gray['-3'],
    800: gray['-5'],
    900: gray['-7'],
    A100: gray['+8'],
    A200: gray['+7'],
    A400: gray['+3'],
    A700: gray['-3'],
  };
  return grey;
};
