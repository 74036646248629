import PropTypes from 'prop-types';

export const employeeProps = PropTypes.shape({
  id: PropTypes.string,
  email: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  name: PropTypes.string,
  middle_initial: PropTypes.string,
  invitation_status: PropTypes.string,
  ssn: PropTypes.string,
  department_name: PropTypes.string,
  department_code: PropTypes.string,
  updated_by: PropTypes.string,
});

export const employeesProps = PropTypes.arrayOf(employeeProps);
