import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import {
  reduxForm,
  formValueSelector,
  getFormSyncErrors,
  reset,
} from 'redux-form';

//components
import StudioPlusComponent from 'components/Admin/Projects/Settings/StudioPlus';
import { StudioPlusDirModal } from 'components/Admin/Projects/Settings/Modals';
import StudioPlusInfoAlert from 'components/Admin/Projects/Settings/Modals/StudioPlusInfoAlert';

//actions
import * as actions from 'actions/settings';
import { show as showModal } from 'actions/modalDialog';
import { showAlert } from 'actions/alert';

//selectors
import {
  getStudioPlusGroup,
  getStudioPlusDirectory,
  getStudioPlusInitialSettings,
  getStudioPlusTCfolder,
  getStudioPlusBRfolder,
  getStudioPlusBtnDisabled,
} from 'selectors/settings';
import { getLoading, getProjectDetails } from 'selectors/project';
import { getDirectoryPath } from 'utils/helperFunctions';

const formName = 'StudioPlus';
const selector = formValueSelector(formName);

const mapStateToProps = state => ({
  initialValues: getStudioPlusInitialSettings(state),
  studioPlusGroup: getStudioPlusGroup(state),
  TCStudioFolder: selector(state, 'studioTimecardPath'),
  BRStudioFolder: selector(state, 'studioBatchPath'),
  StateTCFolder: getStudioPlusTCfolder(state),
  StateBRFolder: getStudioPlusBRfolder(state),
  TCStudioEnabled: selector(state, 'studioTimecardEnabled'),
  BRStudioEnabled: selector(state, 'studioBatchEnabled'),
  studioPlusDirectory: getStudioPlusDirectory(state),
  projectLoading: getLoading(state),
  formSyncErrors: getFormSyncErrors(formName)(state),
  projectDetails: getProjectDetails(state),
  studioEnabled: selector(state, 'studioEnabled') || 'Disabled',
  saveBtnDisbled: getStudioPlusBtnDisabled(state),
});
const mapDispatchToProps = dispatch => ({
  onGetStudioDir: () => {
    dispatch(actions.fetchStudioPlusDirectory());
  },
  onEditAction: group => {
    dispatch(actions.editStudioPlusGroup({ group }));
    dispatch(showModal({ dialog: 'editStudioPlusPath' }));
  },
  onShowAlert: params => {
    dispatch(showAlert(params));
  },
  onShowInfoAlert: params => {
    dispatch(reset('StudioPlus'));
  },
  onSelectSaveBtnDisabled: param => {
    dispatch(
      actions.studioPlusButtonDisabled({ studioPlusBtnDisabled: param }),
    );
  },
});

const onSubmit = (values, dispatch) => {
  dispatch(actions.updateStudioPlusSettings());
};

const validate = values => {
  const errors = { valid: true };
  if (
    values.studioBatchEnabled === true &&
    (values.studioBatchPath === undefined || values.studioBatchPath === '')
  ) {
    errors.studioBatchEnabled = 'Required';
    errors.valid = false;
  }

  if (
    values.studioTimecardEnabled === true &&
    (values.studioTimecardPath === undefined ||
      values.studioTimecardPath === '')
  ) {
    errors.studioTimecardEnabled = 'Required';
    errors.valid = false;
  }

  return errors;
};

const StudioPlus = props => {
  const {
    handleSubmit,
    TCStudioFolder,
    BRStudioFolder,
    TCStudioEnabled,
    BRStudioEnabled,
    studioPlusDirectory,
    formSyncErrors,
    change,
    projectDetails,
    onGetStudioDir,
    studioEnabled,
    onShowAlert,
    onShowInfoAlert,
    saveBtnDisbled,
    onSelectSaveBtnDisabled,
  } = props;

  const [openDialogue, setOpenDialogue] = useState(false);

  useEffect(() => {
    //Need to until projectDetails is filled to get studio dir info
    onGetStudioDir();
  }, [projectDetails, onGetStudioDir]);

  const TCStudioPath = getDirectoryPath(studioPlusDirectory, TCStudioFolder);
  const BRStudioPath = getDirectoryPath(studioPlusDirectory, BRStudioFolder);
  const syncErrorsValid = formSyncErrors.valid;

  const preSubmit = (...args) => {
    if (TCStudioEnabled === false) {
      change('studioTimecardPath', null);
    }
    if (BRStudioEnabled === false) {
      change('studioBatchPath', null);
    }
    handleSubmit(...args);
  };
  const handleClose = () => {
    setOpenDialogue(false);
  };
  const showInfoAlert = () => {
    setOpenDialogue(true);
    onShowInfoAlert();
  };

  return (
    <>
      <form onSubmit={preSubmit}>
        <StudioPlusComponent
          studioEnabled={studioEnabled}
          change={change}
          onShowAlert={onShowAlert}
          showInfoAlert={showInfoAlert}
          saveBtnDisbled={saveBtnDisbled}
          onSelectSaveBtnDisabled={onSelectSaveBtnDisabled}
          {...props}
          TCStudioPath={TCStudioPath}
          BRStudioPath={BRStudioPath}
          syncErrorsValid={syncErrorsValid}
        />
        <StudioPlusDirModal
          TCStudioPath={TCStudioPath}
          BRStudioPath={BRStudioPath}
          TCStudioEnabled={TCStudioEnabled}
          BRStudioEnabled={BRStudioEnabled}
          {...props}
        />
      </form>
      <StudioPlusInfoAlert
        open={openDialogue}
        title={''}
        content={
          'Legacy is strongly discouraged for new projects. Do NOT set legacy without explicit permission from H+ product management.'
        }
        handleClose={handleClose}
      />
    </>
  );
};

StudioPlus.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  TCStudioEnabled: PropTypes.bool,
  BRStudioEnabled: PropTypes.bool,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    touchOnChange: true,
    validate,
    onSubmit,
  }),
)(StudioPlus);
