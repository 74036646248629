import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import { Field } from 'redux-form';

import {
  Divider,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Switch from 'components/Shared/redux/Switch';

const style = () => ({
  root: {
    width: '100%',
    marginTop: 20,
  },
  switch: {
    paddingLeft: 10,
  },
  saveButton: {
    marginTop: 20,
  },
  space: {
    marginTop: 20,
  },
  heading: {
    fontSize: '1.1em',
    fontFamily: 'inherit',
  },
});

class ESignature extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
  };

  static defaultProps = {
    invalid: false,
    pristine: true,
    submitting: false,
  };

  render() {
    const { classes, invalid, pristine, submitting } = this.props;
    return (
      <div style={{ marginTop: 30 }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              Enable E-Signatures
            </Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails className={classes.noPadding}>
            <div className={classes.content}>
              <div>
                <div>
                  E-Signature are disabled by default. By enabling, timecard
                  batch reports include e-signatures.
                </div>
              </div>
              <div>
                <div>
                  <Field
                    className={classes.switch}
                    component={Switch}
                    name="esignatureEnabled"
                    color="primary"
                  />
                  Enabled
                </div>
              </div>
            </div>
          </AccordionDetails>
          <Divider />
          <AccordionActions className={classes.noPadding}>
            <Button
              disabled={pristine || submitting || invalid}
              variant="contained"
              color="primary"
              type="submit"
              className={classes.saveButton}
            >
              Save Settings
            </Button>
          </AccordionActions>
        </Accordion>
      </div>
    );
  }
}
export default withStyleSheet('ESignature', style)(ESignature);
