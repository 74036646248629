//constants
import { formatDateUTC } from 'utils/formatDate';
import _ from 'lodash';
export const STYLING = {
  cellPadding: '0px 12px 0px 12px',
};

export const INITIAL_PAGE_SIZE = 25;

export const STATUS_FILTERS = [
  {
    label: 'Unsubmitted',
    value: 'draft',
    countLabel: 'draft',
    selected: false,
    index: 0,
    pillColor: 'red',
  },
  {
    label: 'Pending Re-Approval',
    value: 'waiting_approval',
    countLabel: 'waitingForApproval',
    selected: true,
    index: 1,
    pillColor: 'red',
  },
  {
    label: 'Submitted to C&C',
    value: 'in_progress',
    countLabel: 'inProgress',
    selected: true,
    index: 2,
    pillColor: 'yellow',
  },
  {
    label: 'Processing',
    value: 'processed',
    countLabel: 'processed',
    selected: true,
    index: 3,
    pillColor: 'yellow',
  },

  {
    label: 'Resubmitted',
    value: 'resubmitted',
    countLabel: 'resubmitted',
    selected: true,
    index: 4,
    pillColor: 'yellow',
  },

  {
    label: 'Edits',
    value: 'edits',
    countLabel: 'edits',
    selected: true,
    index: 5,
    pillColor: 'lightGreen',
  },
  {
    label: 'Revisions',
    value: 'revisions',
    countLabel: 'revisions',
    selected: true,
    index: 6,
    pillColor: 'orange',
  },
  {
    label: 'C&C Review',
    value: 'cc_review',
    countLabel: 'ccReview',
    selected: true,
    index: 7,
    pillColor: 'yellow',
  },
  {
    label: 'Future Release',
    value: 'future_release',
    countLabel: 'futureRelease',
    selected: true,
    index: 8,
    pillColor: 'yellow',
  },
  {
    label: 'Invoice Approved',
    value: 'approved',
    countLabel: 'approved',
    selected: true,
    index: 9,
    pillColor: 'yellow',
  },
  {
    label: 'Paid',
    value: 'paid',
    countLabel: 'paid',
    selected: false,
    index: 10,
    pillColor: 'gray',
  },
  {
    label: 'Hold',
    value: 'hold',
    countLabel: 'hold',
    selected: false,
    index: 11,
    pillColor: 'gray',
  },
];

export const INITIAL_FILTERS = {
  invoiceType: [],
  invoiceWeekendingDate: [],
  status: STATUS_FILTERS,
  invoiceDescription: [],
  invoiceEditsSent: [],
  invoiceId: [],
  futureReleaseDate: [],
};

export const EMPTY_FILTERS = _.cloneDeep(INITIAL_FILTERS);

export const DEFAULT_HIDDEN_COLUMNS = [
  'postedDate',
  'batchCount',
  'remarks',
  'futureReleaseDate',
];

export const DEFAULT_SORT = [
  { id: 'invoiceWeekendingDate', order: 'desc' },
  { id: 'invoiceDescription', order: 'asc' },
  { id: 'invoiceId', order: 'asc' },
];

export const DATE_COLUMNS = [
  'invoiceWeekendingDate',
  'invoiceEditsSent',
  'postedDate',
  'futureReleaseDate',
];

export const TABLE_COLUMNS = [
  {
    accessor: 'id',
    id: 'invoiceId',
    Header: 'ID',
  },
  {
    accessor: 'weekEndingDate',
    id: 'invoiceWeekendingDate',
    Header: 'W/E',
  },
  {
    accessor: 'description',
    id: 'invoiceDescription',
    Header: 'Description',
    maxWidth: 275,
    width: 200,
  },
  {
    accessor: 'batchCount',
    id: 'batchCount',
    Header: 'Batch Count',
  },
  {
    accessor: 'remarks',
    id: 'remarks',
    Header: 'Remarks',
  },
  {
    accessor: 'status',
    id: 'status',
    Header: 'Invoice Status',
  },
  {
    accessor: 'totalTimecards',
    id: 'totalTimecards',
    Header: '# of TCs',
  },
  {
    accessor: 'totalUnApprovedTimecards',
    id: 'numOfUnapprvdTcs',
    Header: '#of Unappr TCs',
  },
  {
    accessor: 'studioPlusFolder',
    id: 'studioPlusFolder',
    Header: 'Studio+ Folder',
  },
  {
    accessor: 'editsSent',
    id: 'invoiceEditsSent',
    Header: 'Edits Last Sent',
  },
  {
    accessor: 'futureReleaseDate',
    id: 'futureReleaseDate',
    Header: 'Future Release Date',
  },
  {
    accessor: 'datePosted',
    id: 'postedDate',
    Header: 'Posted Date',
  },
  {
    accessor: 'actions',
    id: 'actions',
    Header: '',
  },
];

export const IA_TABLE_COLUMNS = TABLE_COLUMNS.map(c => c).filter(
  col => col.id !== 'numOfUnapprvdTcs',
);

/**
     * Handle Click on react-table header to change single sort
     * Used in both Bulk Edit and SearchTimecards
     * @param {object} column - react-table column obj
     * @param {func} setSortBy - callback to set the sort takes array
     * @param {array} defaultSort - default sort order. format each item: {id: string, desc: bool }

     */

/**
 * Create arguments for search filters
 * The only difference is the filter name
 * @params - All the arguments needed for filters and
 * @returns {func} create args for given filter name.
 */
export const makeFilterArgFactory = args => {
  const { fetchFilter, api, debug, statusFilter, projectId } = args;

  const makeFilterArgs = filterName => {
    const filters = statusFilter ? [statusFilter] : [];
    const params = { filters, projectId, filterName };
    return [fetchFilter, api, debug, params];
  };

  return makeFilterArgs;
};

export const setVisibleSelected = ({ fullList, visibleList, newValue }) => {
  fullList.forEach(option => {
    const isVisible = !!visibleList.find(
      visible => visible.index === option.index,
    );

    if (isVisible) option.selected = newValue;
  });
};

//input should be YYYY-MM-DD - using UTC to prevent TZ issues
export const searchDateFormat = str => formatDateUTC(str, 'MM-DD-YYYY');

export const sum = obj => {
  return Object.keys(obj).reduce(
    (sum, key) => sum + parseFloat(obj[key] || 0),
    0,
  );
};
