import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ErrorIcon from '@mui/icons-material/Error';
import { navToStudioPlus } from 'actions/digitalEdits';

const useStyles = makeStyles(({ palette }) => ({
  errorNote: {
    borderLeft: `5px solid ${palette.error.main}`,
    borderRadius: '4px',
    backgroundColor: palette.error.powder,
    padding: '8px 0px',
    boxShadow:
      '0px 1px 4px rgba(82, 82, 82, 0.1), 0px 2px 8px 2px rgba(82, 82, 82, 0.1)',
  },
  errorIcon: {
    color: palette.error.main,
    padding: '0px 8px',
    fontSize: '1.4rem',
  },
  errorTitle: {
    fontWeight: 700,
    fontSize: '14px',
    color: palette.gray.primary,
  },
  errorText: {
    fontSize: '14px',
    color: palette.gray.primary,
  },
  hyperlink: {
    color: palette.primary.primary,
  },
}));

const mapDispatch = dispatch => ({
  onNavToStudio: () => dispatch(navToStudioPlus()),
});

const Notifications = props => {
  const { variant, notes, onNavToStudio } = props;
  const classes = useStyles();
  if (variant === 'error') {
    return (
      <Box sx={{ display: 'flex' }} className={classes.errorNote}>
        <Box>
          <ErrorIcon className={classes.errorIcon} />
        </Box>{' '}
        <Box>
          {notes.map((note, i) => (
            <Box sx={{ marginTop: i === 0 ? '0px' : '8px' }} key={note.type}>
              <Typography className={classes.errorTitle}>
                {note.title}
              </Typography>
              <Typography className={classes.errorText}>
                {note.text}
                {note.studioPlusRedirect ? (
                  <a
                    className={classes.hyperlink}
                    href="/#"
                    onClick={e => {
                      e.preventDefault();
                      onNavToStudio();
                    }}
                  >
                    Studio+
                  </a>
                ) : (
                  ''
                )}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
  return null;
};

Notifications.propTypes = {
  variant: PropTypes.string,
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      studioPlusRedirect: PropTypes.bool,
    }),
  ),
  onNavToStudio: PropTypes.func.isRequired,
};

export default compose(connect(null, mapDispatch))(Notifications);
