import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Typography } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import { formatMessage, REQUIRED, INVALID_EMAIL } from 'utils/messages';
import validator from 'utils/validator';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal as Modal } from 'components/Shared/Modals';
import NotificationRecipient from 'components/Admin/Projects/Settings/Modals/NotificationRecipient';
// actions
import { saveRecipients } from 'actions/settings';

export const mapDispatchToProps = dispatch => ({});
const mapStateToProps = state => ({});

const validate = values => {
  const errors = {};
  const recipientArrayErrors = [];
  (values.recipients || []).forEach((recipient, index) => {
    const recipientErrors = {};
    if (!recipient.email) {
      recipientErrors.email = formatMessage(REQUIRED);
      recipientArrayErrors[index] = recipientErrors;
    } else if (!validator.isEmail(recipient.email)) {
      recipientErrors.email = formatMessage(INVALID_EMAIL);
      recipientArrayErrors[index] = recipientErrors;
    }
  });
  if (recipientArrayErrors.length) {
    errors.recipients = recipientArrayErrors;
  }
  return errors;
};

const onSubmit = (values, dispatch) => {
  dispatch(saveRecipients());
};

const style = () => ({
  root: {},
  content: {
    width: '100%',
  },
});

const renderFields = ({ fields }) => {
  if (fields.length === 0) {
    fields.push({});
  }
  return (
    <div>
      {fields.map((field, index) => {
        return (
          <NotificationRecipient
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            recipient={field}
            addNext={() => fields.push({})}
            removeRecipient={() => fields.remove(index)}
            disableRemove={(fields && fields.length <= 1) || false}
            canAddNext={fields.length === index + 1}
          />
        );
      })}
    </div>
  );
};

class AddRecipients extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.object,
  };

  static defaultProps = {
    errors: undefined,
    pristine: true,
    invalid: true,
    submitting: false,
  };

  render() {
    const { handleSubmit } = this.props;

    const title = (
      <Typography variant="h6" color="inherit">
        {'Add Recipients'}
      </Typography>
    );

    return (
      <form onSubmit={handleSubmit}>
        <Modal
          title={title}
          content={<FieldArray name="recipients" component={renderFields} />}
          buttonText="Save"
          raisedButton
          {...this.props}
        />
        <Field component="input" name="total" type="hidden" />
      </form>
    );
  }
}

export default compose(
  withModalDialog({ dialog: 'addRecipients', maxWidth: 'md' }),
  connect(mapStateToProps, mapDispatchToProps),
  withStyleSheet('AddRecipients', style),
  reduxForm({
    form: 'addRecipients',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnChange: true,
    validate,
    onSubmit,
  }),
)(AddRecipients);
