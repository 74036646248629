import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Box, LinearProgress, Grid, IconButton, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import PencilPerson from 'assets/icons/PencilPerson.js';
import CloseIcon from '@mui/icons-material/Close';

import { TitleBox, ContentBox } from '../Shared/styledComponents';

import HistoryCard from '../Shared/HistoryCard';

import withModalDialog from 'decorators/withModalDialog';

import { hide as hideModal } from 'actions/modalDialog';

import * as sel from '../selectors';
import { getSettings } from 'selectors/settings';

import { HISTORY_DIALOG } from '../Modals/modalNames';

const useStyles = makeStyles(({ palette }) => ({
  historyCard: {
    '& .MuiGrid-item': {
      paddingTop: '0px',
    },
  },
  approverName: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '15px',
  },
  approverTitle: {
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '5px 0px 15px 0px',
  },
}));
const mapState = state => {
  return {
    timecardHistory: sel.getTimecardHistory(state),
    timecardId: sel.getTimecardId(state),
    projectSettings: getSettings(state),
    loading: sel.getHistoryLoading(state),
  };
};
const mapDispatch = dispatch => ({
  hideModal: () => dispatch(hideModal({ dialog: HISTORY_DIALOG })),
});

const HistoryTabModal = props => {
  const { timecardHistory, projectSettings, loading, hideModal } = props;
  const classes = useStyles();

  const filteredHistory = timecardHistory?.find(
    app => app.approvers?.length > 0,
  );

  let approverRows = null;
  if (!_.isEmpty(filteredHistory)) {
    approverRows = filteredHistory?.approvers?.map(approver => (
      <Box
        sx={{
          color: 'primary.main',
          pl: '7px',
          textTransform: 'uppercase',
          fontSize: '14px',
        }}
        key={approver.firstName}
      >
        {approver.fullName}
      </Box>
    ));
  }
  return (
    <Box>
      <TitleBox>
        <Box sx={{ fontWeight: 'bold', pl: '20px' }}>Timecard History</Box>
        <IconButton onClick={hideModal}>
          <CloseIcon />
        </IconButton>
      </TitleBox>
      <Divider />
      <ContentBox>
        {!!loading && <LinearProgress sx={{ mb: '10px' }} />}
        {!loading && timecardHistory.length === 0 && (
          <Box sx={{ textAlign: 'center', p: '10px', mb: '20px' }}>
            No history
          </Box>
        )}
        <Grid container spacing={2} className={classes.historyCard}>
          {timecardHistory?.map(history => (
            <HistoryCard
              key={history.id}
              history={history}
              projectSettings={projectSettings}
            />
          ))}
        </Grid>
        {approverRows && (
          <>
            <Box className={classes.approverTitle}>Current Approvers</Box>
            <Box className={classes.divider} />
            <Box className={classes.approverName}>
              <PencilPerson color="primary" /> {approverRows}
            </Box>
          </>
        )}
      </ContentBox>
    </Box>
  );
};

HistoryTabModal.propTypes = {
  timecardHistory: PropTypes.array,
  projectSettings: PropTypes.object,
  loading: PropTypes.bool,
  hideModal: PropTypes.func,
};

export default compose(
  connect(mapState, mapDispatch),
  withModalDialog({
    dialog: HISTORY_DIALOG,
    maxWidth: 'md',
    roundedCorners: true,
    clickAwayClose: true,
  }),
)(HistoryTabModal);
