import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';

import { TIMEZONE_OPTIONS } from '../../../../components/Shared/constants';

import {
  Grid,
  Divider,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AutoComplete } from 'components/Shared/AutoComplete';
import {
  DEFAULT_MILITARY_TIME_ENTRY_ROUNDING,
  DEFAULT_TIME_ENTRY_ROUNDING,
} from 'components/Shared/constants/index';

const style = () => ({
  root: {
    width: '100%',
    marginTop: 30,
  },
  colorInherit: {
    color: 'inherit',
  },
  content: {
    padding: 20,
  },
  space: {
    marginTop: 20,
  },
  heading: {
    fontSize: '1.1em',
    fontFamily: 'inherit',
  },
  button: {
    margin: 20,
  },
  titleSection: {
    marginBottom: 10,
  },
  noPadding: {
    padding: 0,
  },
  selectRow: {
    width: '100%',
  },
  gridCheckbox: {
    paddingLeft: 0,
  },
  fieldContainer: {
    minWidth: 200,
  },
});

class TimeEntrySettings extends Component {
  constructor(props) {
    super(props);
    this.handleSelectTime = this.handleSelectTime.bind(this);
  }
  static propTypes = {
    classes: PropTypes.object.isRequired,
    options: PropTypes.arrayOf(PropTypes.object),
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
  };

  handleSelectTime = checked => {
    this.props.change(
      'timeInterval',
      checked
        ? DEFAULT_MILITARY_TIME_ENTRY_ROUNDING
        : DEFAULT_TIME_ENTRY_ROUNDING,
    );
  };

  static defaultProps = {
    invalid: false,
    pristine: true,
    submitting: false,
  };

  render() {
    const { classes, invalid, pristine, submitting } = this.props;
    return (
      <div style={{ marginBottom: 30 }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Time Settings</Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails className={classes.noPadding}>
            <div className={classes.content}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.content}
                spacing={2}
              >
                <Grid item>
                  <div className={classes.fieldContainer}>
                    <AutoComplete
                      list={TIMEZONE_OPTIONS}
                      name="timeZone"
                      label="Select Time Zone"
                      required
                      isClearable={false}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </AccordionDetails>
          <Divider />
          <AccordionActions className={classes.noPadding}>
            <Button
              disabled={pristine || submitting || invalid}
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
            >
              Save Settings
            </Button>
          </AccordionActions>
        </Accordion>
      </div>
    );
  }
}
export default withStyleSheet('TimeEntrySettings', style)(TimeEntrySettings);
