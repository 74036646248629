import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'components/Shared/redux/TextField';

import { Field } from 'redux-form';
import {
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  LinearProgress,
  Grid,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//components
import {
  EmailNotifications,
  PayrollCoordinators,
} from 'components/Admin/Projects/Batches';
import AddRecipients from 'containers/Admin/Projects/Batches/AddRecipients';

import { INPUT_FIELD_CHARACTER_LIMIT } from 'components/Shared/constants';
const emailBodylimit = INPUT_FIELD_CHARACTER_LIMIT.emailBody;

const style = () => ({
  root: {
    width: '100%',
  },
  introText: {
    marginTop: 8,
    marginBottom: 32,
  },
  textField: {
    width: '50%',
  },
  heading: {
    fontSize: '1.1em',
    fontFamily: 'inherit',
  },
});

const EmailConfig = ({ classes, loading, ...props }) => {
  const {
    recipients,
    onAddRecipient,
    onRemoveRecipient,
    payrollCoordinator,
    backupCoordinators,
  } = props;
  return (
    <div style={{ marginTop: 30 }}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            Email Configuration
          </Typography>
        </AccordionSummary>
        {loading && <LinearProgress />}
        <AccordionDetails>
          <div className={classes.root}>
            <Typography className={classes.introText}>
              Customize the invite email text or use the default text provided.
            </Typography>
            <div>
              <Field
                component={TextField}
                name="emailBody"
                multiline
                className={classes.textField}
                rows="10"
                inputProps={{ maxLength: emailBodylimit }}
                variant="outlined"
              />
              <Field component="input" name="id" type="hidden" />
              <Field component="input" name="emailType" type="hidden" />
              <AccordionActions>
                <Button variant="contained" color="primary" type="submit">
                  Save Settings
                </Button>
              </AccordionActions>
            </div>
            {/* Email Notification and Project Contact */}
            <Grid item md={12} container spacing={8}>
              <Grid item md={4}>
                <EmailNotifications
                  recipients={recipients}
                  onAddRecipient={onAddRecipient}
                  onRemoveRecipient={onRemoveRecipient}
                />
              </Grid>
              <Grid item md={4}>
                <PayrollCoordinators
                  payrollCoordinator={payrollCoordinator}
                  backupCoordinators={backupCoordinators}
                />
              </Grid>
            </Grid>
            <AddRecipients />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

EmailConfig.PropType = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default withStyles(style)(EmailConfig);
