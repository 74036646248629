import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FaPlusCircle as PlusIcon } from 'react-icons/fa';
// containers
import ApprovalFlowStep from './ApprovalFlowStep';
// components
import { ApprovalFlow } from 'components/Admin/Projects/DepartmentApprovers';
import { ApprovalStep } from 'components/Admin/Projects/DepartmentApprovers';
import { ORDINALS } from 'components/Shared/constants';
import { PA, UPM, ROLE_LABELS } from 'components/props/profiles';
// actions
import * as actions from 'actions/reviewFlows';
import { show as showModal } from 'actions/modalDialog';
// selectors
import { getProjectDetails } from 'selectors/project';
import { getSteps, getNewFlowId } from 'selectors/reviewFlows';

// might need this for QA to test HOUR-3775
// import { steps as fakeSteps } from './dummySteps';

const mapStateToProps = (state, ownProps) => ({
  departmentHeadApproval: getProjectDetails(state).departmentApprove,
  steps: getSteps(state, ownProps.reviewFlow.id),
  newFlowId: getNewFlowId(state),
});

const mapDispatchToProps = dispatch => ({
  onEditApprovalFlow: (reviewFlowId, role, position, stepId, userIds = []) => {
    const form = 'editApprovalFlow';
    dispatch(
      actions.editingStepUser({
        form,
        reviewFlowId,
        role,
        position,
        stepId,
        userIds,
      }),
    );
    dispatch(showModal({ dialog: form }));
  },
  onRemoveApprovalFlow: reviewFlowId => {
    dispatch(actions.editing({ id: reviewFlowId }));
    dispatch(showModal({ dialog: 'removeApprovalFlow' }));
  },
  onScrollIntoView: () => {
    setTimeout(() => dispatch(actions.setNewFlowId({ id: null })), 5000);
  },
});

class ApprovalFlowContainer extends Component {
  static propTypes = {
    departmentHeadApproval: PropTypes.bool,
    reviewFlow: PropTypes.object.isRequired,
    steps: PropTypes.array,
    onEditApprovalFlow: PropTypes.func.isRequired,
    onRemoveApprovalFlow: PropTypes.func.isRequired,
  };

  static defaultProps = {
    departmentHeadApproval: false,
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.onEditRequest = this.onEditRequest.bind(this);
  }

  //scollIntoView when a new flow is created
  componentDidMount() {
    const { reviewFlow, newFlowId } = this.props;
    if (
      reviewFlow &&
      reviewFlow.id &&
      newFlowId &&
      newFlowId === reviewFlow.id
    ) {
      this.myRef.current.scrollIntoView({
        block: 'center',
      });
      this.props.onScrollIntoView();
    }
  }

  onEditRequest(currentStep, role = UPM) {
    const { onEditApprovalFlow, reviewFlow } = this.props;
    const position = currentStep + 1;
    onEditApprovalFlow(reviewFlow.id, role, position);
  }

  onRemoveFlow() {
    const { onRemoveApprovalFlow, reviewFlow } = this.props;

    onRemoveApprovalFlow(reviewFlow.id);
  }

  renderApprovalFlow() {
    const {
      onEditApprovalFlow,
      reviewFlow,
      steps: propSteps = [],
    } = this.props;
    const steps = propSteps.slice();
    let approvalFlows = [];
    let currentStep = 0;
    let key = PA;
    let label = ROLE_LABELS[PA];
    const PAExists = steps.some(step => step.role === 'payroll_accountant');

    if (steps.length === 0 || steps[0].role === PA) {
      approvalFlows.push(
        <ApprovalStep
          key={key}
          label={label}
          role={key}
          allowEdit
          onEditStep={() => this.onEditRequest(currentStep, key)}
          position={currentStep + 1}
        />,
      );
    }

    if (steps.length > 0) {
      // check order of steps and fix before rendering
      if (PAExists) {
        const flowOrder = {
          payroll_accountant: 0,
          upm: 1,
        };
        steps.sort((a, b) => flowOrder[a.role] - flowOrder[b.role]);
      }
      steps.sort((a, b) => {
        if (a.role === 'upm') {
          if (a.position > b.position) return 1;
          if (a.position < b.position) return -1;
        }
        return 0;
      });
      approvalFlows = steps.map(step => {
        if (step.role !== PA) {
          key = `${step.role} - ${ORDINALS[currentStep]}`;
          label = `${ORDINALS[currentStep]} Level Approvers`;
          currentStep += 1;
        }

        return (
          <ApprovalFlowStep
            key={key}
            label={label}
            step={step}
            reviewFlowId={reviewFlow.id}
            role={step.role}
            position={step.role !== PA ? currentStep + 1 : 1}
            lastChild={currentStep === 3}
            onEditApprovalFlow={onEditApprovalFlow}
          />
        );
      });
      if (!PAExists) {
        approvalFlows.unshift(
          <ApprovalStep
            key={PA}
            label={ROLE_LABELS[PA]}
            role={PA}
            allowEdit
            onEditStep={() => this.onEditRequest(0, PA)}
            position={1}
          />,
        );
      }
    }
    if (steps.length <= 3) {
      approvalFlows.push(
        <ApprovalStep
          key={currentStep}
          icon={PlusIcon}
          iconColor={true}
          label={`${ORDINALS[currentStep]} Level Approvers`}
          lastChild
          allowEdit
          onEditStep={() => this.onEditRequest(currentStep + 1)}
        />,
      );
    }

    return <div ref={this.myRef}>{approvalFlows}</div>;
  }

  render() {
    const { departmentHeadApproval, reviewFlow, steps, ...others } = this.props;

    return (
      <ApprovalFlow
        departmentHeadApproval={departmentHeadApproval}
        approvalFlows={this.renderApprovalFlow()}
        reviewFlowId={reviewFlow.id}
        onRemoveFlow={() => this.onRemoveFlow()}
        {...others}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApprovalFlowContainer);
