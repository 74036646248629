import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Popover,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import SortIcon from '@mui/icons-material/Sort';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles(theme => ({
  SortComp: {},
  sortList: {
    width: 250,
    maxHeight: '65vh',
  },
  sortTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 700,
    padding: 10,
    lineHeight: '18px',
    letterSpacing: '1.15px',
  },
  divider: {
    margin: '0px 10px',
    height: 2,
    backgroundColor: theme.palette.primary.main,
  },
  iconRotate: {
    transform: 'scaleY(-1)',
  },
}));

const SortComp = ({
  anchorElRef,
  isOpen,
  setIsOpen,
  sortBy,
  onSetSortBy,
  sortOptions,
}) => {
  const classes = useStyles();

  return (
    <Popover
      anchorEl={anchorElRef.current}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className={classes.SortComp}
    >
      <div className={classes.sortList}>
        <section className={classes.sortTitle}>
          <span>Sort by</span>
        </section>
        <Divider className={classes.divider} />
        <List component="nav" className={classes.root}>
          {sortOptions.map(sort => (
            <ListItem key={sort.id} button onClick={() => onSetSortBy(sort)}>
              <ListItemIcon>
                <SortIcon
                  className={clsx({
                    [classes.iconRotate]: sort.sortOrder === 'ascSort',
                  })}
                />
              </ListItemIcon>
              <ListItemText primary={sort.label} />
              {sort.id === sortBy.id && <CheckIcon />}
            </ListItem>
          ))}
        </List>
      </div>
    </Popover>
  );
};

SortComp.propTypes = {
  anchorEl: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  sortBy: PropTypes.object.isRequired,
  onSetSortBy: PropTypes.func.isRequired,
};

export default SortComp;
