import React, { useState } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import {
  FormControlLabel,
  Checkbox,
  TextField,
  ClickAwayListener,
  Divider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles(({ palette, transitions }) => ({
  input: {
    display: 'flex',
    padding: 0,
    height: '2em',
    '&:hover': {
      cursor: 'pointer',
    },
    fontSize: 14,
    color: palette.gray['+6'],
    fontStyle: 'italic',
  },
  container: {
    position: 'relative',
  },
  dropDown: {
    position: 'absolute',
    width: '200px',
    maxHeight: '270px',
    right: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    zIndex: 1000,
    border: `1px solid ${palette.primary.main}`,
    backgroundColor: palette.gray.table,
    boxShadow: '2px 2px 2px gray',
    padding: 5,

    '&::-webkit-scrollbar': {
      display: 'none',
    },

    transform: 'scale(1, 0)',
    transformOrigin: 'top',
    transition: `all ${transitions.duration.standard}ms ${transitions.easing.easeInOut} 0ms`,
  },
  dropDownOpen: {
    transform: 'scale(1, 1)',
    transformOrigin: 'top',
  },
  underline: {
    '&::after': {
      borderBottom: `2px solid ${palette.primary.main} !important`,
    },
  },
  CheckBox: {
    color: palette.primary.main,
  },
  childCheckBox: {
    width: `100%`,
    paddingLeft: 16,
    '&:hover': {
      backgroundColor: `${palette.primary.main}50 !important`,
      color: palette.primary.main,
    },
  },
  indent: {
    paddingLeft: 40,
  },
  textfield: {
    display: 'flex',
  },
  expandIcons: {
    marginTop: 16,
    marginLeft: `-32px`,
    color: palette.primary.main,
    transition: 'transform 0.2s',
  },
  expand: {
    transform: 'rotate(180deg)',
  },
  selected: {
    backgroundColor: palette.primary['+9'],
  },
}));

/**
 * Display a dropdown of names with checkboxes next to them
 * @param {int} [selectedCount=0]
 * @param {string} [checkedProperty="visible"]  - property for checked or not
 * @param {func} onClickField called with field object as parameter when checkbox clicked
 * @param {object/array} fieldsList { fieldId: { see below } } fieldId must match columnId
 * @param {string} fieldsList.fieldId.label Required - label (displayed in capitalize)
 * @param {bool}  fieldsList.fieldId.indent Optional - Indent this field
 * @param {string} fieldsList.fieldId.type Optional - if 'divider' will display a break in the field list
 */

const FieldCheckboxDropDown = props => {
  const {
    fieldsList,
    selectedCount = 0,
    onClickField,
    label,
    checkedProperty = 'visible',
    // disabled = false,
  } = props;

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    if (!isOpen) setIsOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className={classes.container}>
        <div className={classes.textfield}>
          <TextField
            fullWidth
            label={label}
            InputProps={{
              classes: {
                underline: classes.underline,
              },
              inputProps: {
                className: clsx(
                  'pendo_toggle_invoice_additional_fields',
                  classes.input,
                ),
                readOnly: true,
              },
            }}
            onClick={toggleMenu}
            value={selectedCount === 0 ? '' : `${selectedCount} Columns Shown`}
          />

          <ExpandMoreIcon
            className={clsx(classes.expandIcons, { [classes.expand]: isOpen })}
          />
        </div>

        <div
          className={clsx(classes.dropDown, {
            [classes.dropDownOpen]: isOpen,
          })}
        >
          {_.map(fieldsList, (field, i) => {
            const indent = field && field.indent;
            if (field && field.type === 'divider') {
              return <Divider key={i} />;
            } else {
              return (
                <FormControlLabel
                  key={i}
                  className={clsx(classes.childCheckBox, {
                    [classes.indent]: indent,
                    [classes.selected]: field[checkedProperty] ? true : false,
                  })}
                  control={
                    <Checkbox
                      onClick={() => onClickField(field)}
                      checked={field[checkedProperty] ? true : false}
                      color="primary"
                    />
                  }
                  label={field.label}
                />
              );
            }
          })}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default FieldCheckboxDropDown;
