import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';

import {
  Button,
  Box,
  LinearProgress,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InputIcon from '@mui/icons-material/Input';

import RichText from 'components/RichText/RichText';
import ConfirmDeleteBtn from 'components/Shared/ConfirmDeleteBtn';
//actions
import {
  saveComment,
  closeInvoiceComments,
  lockComment,
  fetchComments,
  deleteComment,
} from 'actions/digitalEdits';
//selectors
import {
  getExistingComments,
  getUnsubmittedComment,
  getIsSavingComment,
  getIsLoadingComments,
  getCommentLockStatus,
  getCurrentInvoiceId,
  getIsDeletingComment,
} from 'selectors/digitalEdits';

//utils
import {
  useAutoSave,
  useWillUnmount,
  useBroadcastChannel,
} from 'utils/customHooks';

//components
import ExistingComments from './ExistingComments';
import LastSaved from './LastSaved';
import Notice from 'components/Shared/Text/Notice';

const useStyles = makeStyles(({ palette }) => ({
  Comments: {
    padding: 12,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  textArea: {
    backgroundColor: palette.gray['+7'],
  },
  unsubmittedComment: {
    flexGrow: 3,
    minHeight: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    flexGrow: 5,
    maxHeight: '80%',
  },
  editor: {
    border: `2px solid ${palette.gray['+7']}`,
  },
  notice: {
    width: '100%',
  },
}));

const mapState = state => ({
  comments: getExistingComments(state),
  unsubmittedComment: getUnsubmittedComment(state),
  savingComment: getIsSavingComment(state),
  loadingComments: getIsLoadingComments(state),
  commentLock: getCommentLockStatus(state),
  currentInvoiceId: getCurrentInvoiceId(state),
  isDeletingComment: getIsDeletingComment(state),
});

const mapDispatch = {
  onSaveComment: saveComment,
  onCloseInvoiceComments: closeInvoiceComments,
  onLockComment: lockComment,
  onFetchComments: fetchComments,
  onDeleteComment: deleteComment,
};

const Comments = props => {
  const classes = useStyles();
  const {
    comments,
    onSaveComment,
    onCloseInvoiceComments,
    unsubmittedComment,
    savingComment,
    loadingComments,
    commentLock,
    onLockComment,
    onFetchComments,
    currentInvoiceId,
    onDeleteComment,
    isDeletingComment,
  } = props;

  const commentLockBySomeoneElse = commentLock.lockOwner === 'other';

  const updatedDate = unsubmittedComment.updatedDate || null;

  const [currentTCName, setCurrentTCName] = useState('');

  const bc = useBroadcastChannel(currentInvoiceId);

  React.useEffect(() => {
    if (bc) {
      bc.onmessage = ev => {
        //This works like a mini-redux.  The message is the action, and the payload is the data.
        // Its important that each action has a unique name, so that we can handle it properly and that each action is
        // handled in ONLY 1 place.
        if (ev.data?.action === 'setTCName') {
          setCurrentTCName(ev.data.payload);
        }
        if (ev.data?.action === 'heartbeat') {
          bc.postMessage({
            action: 'heartbeat-response',
            payload: commentLock,
          });
        }
        if (ev.data?.action === 'insertReplyText') {
          setInsertText({ text: ev.data.payload, position: 'end' });
          commentInputRef.current.scrollIntoView();
        }
      };
    }
  }, [bc, commentLock, currentInvoiceId, unsubmittedComment?.commentText]);

  // Request TC name from BroadCastChannel if we don't have it yet
  React.useEffect(() => {
    let interval = null;
    if (bc && !currentTCName) {
      clearInterval(interval);
      interval = setInterval(() => {
        bc.postMessage({ action: 'getTCName' });
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [bc, currentTCName]);

  //comment sync
  React.useEffect(() => {
    if (bc) {
      bc.postMessage({
        action: 'syncComment',
        payload: unsubmittedComment || {},
      });
    }
  }, [bc, unsubmittedComment, unsubmittedComment?.commentText]);

  const commentInputRef = React.useRef(null);
  const [commentText, setCommentText] = useState('');
  const [plainText, setPlainText] = useState('');

  const [insertText, setInsertText] = useState({ text: '', position: 'end' });

  const SaveComment = useCallback(() => {
    if (commentText) {
      onSaveComment({
        commentText,
        commentId: unsubmittedComment.commentId,
        plainText,
      });
      return true;
    } else {
      return false;
    }
  }, [commentText, onSaveComment, plainText, unsubmittedComment.commentId]);

  const initLastSave = updatedDate ? new Date(updatedDate) : null;

  const { setLastChangeTime, onAutoSave, hasUnsavedChanges } = useAutoSave(
    SaveComment,
    initLastSave,
  );

  const setCommentUpdateTime = React.useCallback(
    (newVal, newPlainText) => {
      setCommentText(newVal);
      setLastChangeTime(new Date());
      setPlainText(newPlainText.trim());
    },
    [setLastChangeTime],
  );

  const saveDisabled = savingComment || loadingComments || !hasUnsavedChanges;

  useWillUnmount(() => {
    onCloseInvoiceComments({
      commentText,
      commentId: unsubmittedComment.commentId,
      hasUnsavedChanges,
    });
  });

  const onWindowClose = useCallback(
    async e => {
      if (hasUnsavedChanges) {
        onAutoSave();
        e.preventDefault();
        e.returnValue = '';
      }
    },
    [hasUnsavedChanges, onAutoSave],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', onWindowClose);
    return () => {
      window.removeEventListener('beforeunload', onWindowClose);
    };
  }, [onWindowClose]);

  useEffect(() => {
    setCommentText(unsubmittedComment.commentText);
  }, [unsubmittedComment]);

  const scrollMainPage = _.throttle(direction => {
    bc.postMessage({ action: direction });
  }, 150);

  return (
    <Box
      className={classes.Comments}
      onKeyDown={e => {
        if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === 'c') {
          e.preventDefault();
          e.stopPropagation();
          navigator.clipboard.writeText(currentTCName);
        }
        if (e.altKey && e.shiftKey && e.key.toLowerCase() === 'v') {
          e.preventDefault();
          e.stopPropagation();
          setInsertText({ text: currentTCName, position: 'cursor' });
        }
        if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === 'd') {
          e.preventDefault();
          e.stopPropagation();
          scrollMainPage('scrollDown');
        }
        if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === 'e') {
          e.preventDefault();
          e.stopPropagation();
          scrollMainPage('scrollUp');
        }
        if (e.ctrlKey && e.key.toLowerCase() === 's') {
          e.preventDefault();
          e.stopPropagation();
          onAutoSave();
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '32px',
          flexGrow: 0,
          justifyContent: 'space-between',
        }}
      >
        {currentTCName && (
          <React.Fragment>
            <Box sx={{ display: 'inline-flex' }}>
              <Box sx={{ display: 'inline', color: 'text.disabled' }}>
                Current Record:
              </Box>{' '}
              {currentTCName}
            </Box>
            <Box sx={{ display: 'inline-flex' }}>
              <Tooltip title="Copy to clipboard. Ctrl+Shift+C">
                <IconButton
                  sx={{ p: '4px' }}
                  onClick={() => navigator.clipboard.writeText(currentTCName)}
                  className="PENDO-comment-copy-record"
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Insert at Cursor. Alt+Shift+V">
                <IconButton
                  className="PENDO-comment-copy-record"
                  sx={{ p: '4px' }}
                  onClick={() => {
                    setInsertText({ text: currentTCName, position: 'cursor' });
                  }}
                >
                  <InputIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </React.Fragment>
        )}
      </Box>
      <Box ref={commentInputRef} className={classes.unsubmittedComment}>
        <RichText
          className={classes.input}
          editorClassName={classes.editor}
          menuBarClassName={classes.menuBar}
          value={commentText}
          disabled={loadingComments || commentLockBySomeoneElse}
          onChange={(newVal, plainText) =>
            setCommentUpdateTime(newVal, plainText)
          }
          insertText={insertText}
        />
        {commentLockBySomeoneElse ? (
          <Box sx={{ flexGrow: 0, my: '2px', display: 'flex' }}>
            <Notice className={classes.notice} type="warning">
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Tooltip title="They will need to close the comment window before it can be edited by someone else.">
                  <Typography sx={{ fontWeight: 700, fontSize: '1.1em' }}>
                    This comment is currently being edited by: <br />
                    {commentLock.lock.lockedBy}
                  </Typography>
                </Tooltip>
                <IconButton
                  onClick={() => {
                    onLockComment();
                    onFetchComments();
                  }}
                >
                  <RefreshIcon />
                </IconButton>
              </Box>
            </Notice>
          </Box>
        ) : (
          <Box
            sx={{
              flexGrow: 0,
              display: 'flex',
              justifyContent: 'flex-end',
              my: '10px',
            }}
          >
            <LastSaved
              commentText={commentText}
              savingComment={savingComment}
            />
            {updatedDate && (
              <ConfirmDeleteBtn
                isDeleting={isDeletingComment}
                onClick={() => {
                  onDeleteComment({
                    commentId: unsubmittedComment.commentId,
                  });
                }}
              />
            )}
            <Button
              disabled={saveDisabled}
              onClick={() => {
                onAutoSave();
              }}
            >
              Save
            </Button>
          </Box>
        )}
        {loadingComments && <LinearProgress />}
      </Box>
      <ExistingComments comments={comments} />
    </Box>
  );
};

Comments.propTypes = {
  comments: PropTypes.array.isRequired,
  onSaveComment: PropTypes.func.isRequired,
  onCloseInvoiceComments: PropTypes.func.isRequired,
  unsubmittedComment: PropTypes.object.isRequired,
  savingComment: PropTypes.bool.isRequired,
  loadingComments: PropTypes.bool.isRequired,
  onLockComment: PropTypes.func.isRequired,
  onFetchComments: PropTypes.func.isRequired,
  currentInvoiceId: PropTypes.string.isRequired,
  commentLock: PropTypes.shape({
    lockOwner: PropTypes.string,
    lock: PropTypes.shape({
      lockedBy: PropTypes.string,
      lockedAt: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  onDeleteComment: PropTypes.func.isRequired,
  isDeletingComment: PropTypes.bool.isRequired,
};

export default compose(connect(mapState, mapDispatch))(Comments);
