import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { reduxForm, getFormSyncErrors } from 'redux-form';

//components
import DtsSettingsComponent from 'components/Admin/Projects/Settings/DtsSettings';

//selectors
import { getInitialSettings } from 'selectors/project';
import { getTimecardTemplates } from 'selectors/settings';

//actions
import { updateDtsSettings, fetchTimecardTemplates } from 'actions/settings';

const formName = 'DtsSettings';

const mapStateToProps = state => ({
  initialValues: getInitialSettings(state, formName),
  timecardTemplateOptions: getTimecardTemplates(state),
  errors: getFormSyncErrors(formName)(state),
});

const mapDispatchToProps = dispatch => ({
  onFetchTimecardTemplates: () => {
    dispatch(fetchTimecardTemplates());
  },
});

const onSubmit = (values, dispatch) => {
  dispatch(updateDtsSettings());
};

const validate = values => {
  const errors = {};

  if (values.dtsSettingEnabled) {
    if (!values.dtsTemplateId || values.dtsTemplateId === 'null') {
      errors.dtsTemplateId = 'Template required';
    }
  }

  return errors;
};
class DtsSettings extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit, errors } = this.props;

    if (errors.dtsTemplateId) {
      //Errors won't register unless the field is 'touched'
      this.props.touch('dtsTemplateId');
    }

    return (
      <form onSubmit={handleSubmit}>
        <DtsSettingsComponent {...this.props} />
      </form>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnChange: true,
    onSubmit,
    validate,
  }),
)(DtsSettings);
