import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { styled, Box } from '@mui/material';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CopyFromPrevList from '../Shared/CopyPrevList';

import { Button } from '../Shared/styledComponents';

import { show as showModal } from 'actions/modalDialog';

import * as sel from '../selectors';
import { DEALMEMO_DIALOG } from 'feature/EmployeeTimecard/Modals/modalNames';
import WeekPicker from '../Shared/WeekPicker';

import { NEW_TC_ID } from '../empTimecardUtils';

const HeaderBox = styled(Box)(({ theme }) => ({
  padding: '5px',
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '10px',
  paddingRight: '8px',
}));

const WorkWeekBox = styled(Box)(({ theme }) => ({
  display: 'block',
  width: '100%',
  '& .MuiTextField-root': {
    width: '100%',
  },
}));

const mapState = state => ({
  timecardId: sel.getTimecardId(state),
  disabledBtns: sel.getDisabledBtns(state),
});

const mapDispatch = dispatch => {
  return {
    showDealMemoModal: () => {
      dispatch(showModal({ dialog: DEALMEMO_DIALOG }));
    },
  };
};

const Header = props => {
  const { showDealMemoModal, timecardId, disabledBtns } = props;

  const isNewTc = timecardId === NEW_TC_ID;

  const { disableTopActions } = disabledBtns;
  return (
    <Fragment>
      <HeaderBox>
        <WorkWeekBox>
          <WeekPicker disabled={disableTopActions || !isNewTc} />
        </WorkWeekBox>
        <ButtonBox>
          {timecardId === NEW_TC_ID && (
            <CopyFromPrevList disabled={disableTopActions} />
          )}
          <Button
            variant="outlined"
            startIcon={<DescriptionOutlinedIcon />}
            onClick={() => showDealMemoModal()}
          >
            Deal
          </Button>
        </ButtonBox>
      </HeaderBox>
    </Fragment>
  );
};

Header.propTypes = {
  showDealMemoModal: PropTypes.func.isRequired,
  timecardId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([NEW_TC_ID]),
  ]),
  disabledBtns: PropTypes.shape({
    disableOnLoading: PropTypes.bool.isRequired,
    disableActions: PropTypes.bool.isRequired,
    disableTopActions: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapState, mapDispatch)(Header);
