import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'decorators/withRouter';
import { push } from 'redux-first-history';
import { batch } from 'react-redux';
import moment from 'moment';
//actions
import {
  fetchReviewBatches,
  setReviewFromURL,
  setBatchLockStatus,
  fetchTimecardsByBatchId,
} from 'actions/reviews';
import { clearFilter, setInitLoad } from 'actions/bulkEdit';

import { setFromURL } from 'actions/timecards';

//components
import { Listing } from 'components/Employees/Reviews/PayrollAccountant';
import LinearProgress from '@mui/material/LinearProgress';
//selector
import {
  reviewBatchesByReviewType,
  getTimecardLoadingByBatchID,
  getBatchesLoading,
} from 'selectors/reviews';
import { setInitBatch } from 'actions/bulkEdit';
//util
import { getProjectFromURL } from 'utils/helperFunctions';
import { BATCH_REVIEW_DEFAULT_PAGE_SIZE } from 'components/Shared/constants';

const reviewType = 'open';
const pageSize = BATCH_REVIEW_DEFAULT_PAGE_SIZE;

const mapState = (state, { match }) => ({
  projectId: match.params.projectId,
  batches: reviewBatchesByReviewType(reviewType)(state),
  reviewType: reviewType,
  timecardLoadingByBatch: getTimecardLoadingByBatchID(state),
  loading: getBatchesLoading(state),
});

const mapDispatch = (dispatch, { location }) => ({
  onFetchData: () => {
    dispatch(
      fetchReviewBatches({
        reviewType: reviewType,
        page: 1,
        size: pageSize,
      }),
    );
  },
  onApproveBatch: (projectId, id) => {
    dispatch(
      setReviewFromURL({
        reviewType,
        reviewBatchId: id,
        fromURI: location.pathname,
      }),
    );
    const toURL = `/projects/${projectId}/review/${reviewType}/${id}/htg`;

    dispatch(push(toURL));
  },
  onWTCApproveBatch: (projectId, id, reviewBatchId, htgId) => {
    dispatch(setFromURL({ fromURI: location.pathname }));

    dispatch(
      setReviewFromURL({
        reviewType,
        reviewBatchId: reviewBatchId,
        fromURI: location.pathname,
        worksightId: id,
      }),
    );
    const toURL = `/projects/${projectId}/review/${reviewType}/${id}/wtc`;
    dispatch(push(toURL));
  },
  onBulkEditBatch: (
    projectId,
    batchWorksight,
    reviewBatchId,
    htgIG,
    weekEnding,
  ) => {
    batch(() => {
      dispatch(clearFilter({ filterName: 'departments' }));
      dispatch(clearFilter({ filterName: 'status' }));
      dispatch(clearFilter({ filterName: 'weekDay' }));
      dispatch(clearFilter({ filterName: 'batches' }));
      dispatch(clearFilter({ filterName: 'employees' }));
      dispatch(clearFilter({ filterName: 'accountCodes' }));
      dispatch(clearFilter({ filterName: 'episodes' }));
      dispatch(clearFilter({ filterName: 'sets' }));
    });
    batch(() => {
      dispatch(setInitLoad());
      dispatch(
        setReviewFromURL({
          reviewType,
          reviewBatchId: reviewBatchId,
          fromURI: location.pathname,
        }),
      );
      dispatch(
        setInitBatch({
          batchId: batchWorksight,
          weekEnding: moment(weekEnding).format('YYYY-MM-DD'),
        }),
      );
    });
    const toURL = `/projects/${projectId}/review/bulk-edit`;
    dispatch(push(toURL));
  },
  refreshData: (page, size) => {
    dispatch(fetchReviewBatches({ reviewType, page, size }));
  },
  onSetBatchLockStatus: (hoursBatchId, reviewBatchLockStatus) => {
    dispatch(setBatchLockStatus({ hoursBatchId, reviewBatchLockStatus }));
  },
  onNavToWTC: (timecard, batchWorksightId, batchHtgId) => {
    const projectId = getProjectFromURL(location.pathname);
    dispatch(setFromURL({ fromURI: location.pathname }));

    const toURL = `/projects/${projectId}/review/ready/${batchWorksightId}/wtc?timecardHeaderId=${timecard.entryHeaderId}`;
    dispatch(push(toURL));
  },
  onFetchBatchTimecards: batchId => {
    const reviewBatches = [{ id: batchId }];
    dispatch(fetchTimecardsByBatchId({ reviewType, reviewBatches }));
  },
});

class Open extends Component {
  state = {
    page: 0,
    pageSize: pageSize,
  };

  onRefreshPage(page, pageSize) {
    this.setState({
      page,
      pageSize,
    });

    this.props.refreshData(page, pageSize);
  }

  componentDidMount() {
    this.props.onFetchData();
  }

  render() {
    if (this.props.isLoading) {
      return <LinearProgress />;
    }

    return (
      <Listing
        {...this.props}
        page={this.state.page}
        pageSize={this.state.pageSize}
        onRefreshPage={this.onRefreshPage.bind(this)}
      />
    );
  }
}
export default withRouter(connect(mapState, mapDispatch)(Open));
