import moment from 'moment-timezone';
import { BACKEND_TIMEZONES } from '../components/Shared/constants';

export const weekEndingFormat = date => moment(date).format('MM-DD-YYYY');

export const weekEndingFormatUTC = date => moment.utc(date).format();

//Default export
const formatDate = (date, format = 'MM-DD-YYYY HH:mm') =>
  !!date ? moment(date).format(format) : null;

export const formatDateUTC = (date, format = 'MM-DD-YYYY HH:mm') =>
  !!date ? moment.utc(date).format(format) : null;

export const customFormatDate = (date, inputFormat, outputFormat) =>
  !!date ? moment(date, inputFormat).format(outputFormat) : null;

export const formatDateTimezone = (date, format, backendTimezone) =>
  !!date
    ? moment
        .utc(date)
        .tz(convertBackendToMomentTimezoneName(backendTimezone))
        .format(format)
    : null;

export const dateSubtract = (date, days) => {
  let startdate = moment(date);
  startdate = startdate.subtract(days, 'days');
  return moment(startdate).format('MM-DD-YYYY');
};

export const getTimeZone = () => {
  var offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? '+' : '-') +
    ('00' + Math.floor(o / 60)).slice(-2) +
    ':' +
    ('00' + (o % 60)).slice(-2)
  );
};

export const convertBackendToMomentTimezoneName = backendTimezoneName => {
  switch (backendTimezoneName) {
    case BACKEND_TIMEZONES.gmtStandardTime:
      return 'Etc/GMT';
    case BACKEND_TIMEZONES.hawaiianStandardTime:
      return 'US/Hawaii';
    case BACKEND_TIMEZONES.alaskanStandardTime:
      return 'US/Alaska';
    case BACKEND_TIMEZONES.pacificStandardTime:
      return 'US/Pacific';
    case BACKEND_TIMEZONES.usMountainStandardTime:
      return 'America/Phoenix';
    case BACKEND_TIMEZONES.mountainStandardTime:
      return 'US/Mountain';
    case BACKEND_TIMEZONES.centralStandardTime:
      return 'US/Central';
    case BACKEND_TIMEZONES.easternStandardTime:
      return 'US/Eastern';
    case BACKEND_TIMEZONES.usEasternStandardTime:
      return 'US/East-Indiana';
    default:
      return 'UTC'; //if unrecognized, default to UTC
  }
};

export const getAbbreviationFromBackendTimezoneName = backendTimezoneName => {
  switch (backendTimezoneName) {
    case BACKEND_TIMEZONES.gmtStandardTime:
      return 'GMT';
    case BACKEND_TIMEZONES.hawaiianStandardTime:
      return 'HST';
    case BACKEND_TIMEZONES.alaskanStandardTime:
      return 'AST';
    case BACKEND_TIMEZONES.pacificStandardTime:
      return 'PST';
    case BACKEND_TIMEZONES.usMountainStandardTime:
      return 'PNT'; //Phoenix
    case BACKEND_TIMEZONES.mountainStandardTime:
      return 'MST';
    case BACKEND_TIMEZONES.centralStandardTime:
      return 'CST';
    case BACKEND_TIMEZONES.easternStandardTime:
      return 'EST';
    case BACKEND_TIMEZONES.usEasternStandardTime:
      return 'IET'; //Indiana-East
    default:
      return 'UTC'; //if unrecognized, default to UTC
  }
};

export default formatDate;

export const meridianDateFormat = date => {
  return date ? moment(date)?.format('MM/DD/YYYY, h:mm A') : null;
};

export const weekDayCharFormat = weekDay => {
  const labelMap = {
    Sunday: 'Su',
    Monday: 'M',
    Tuesday: 'T',
    Wednesday: 'W',
    Thursday: 'Th',
    Friday: 'F',
    Saturday: 'Sa',
  };
  return labelMap[weekDay] || null;
};
