import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FaDollarSign as DollarIcon } from 'react-icons/fa';
import { FaUserAlt as UserIcon } from 'react-icons/fa';
// components
import { ApprovalStep } from 'components/Admin/Projects/DepartmentApprovers';
import { usersProps } from 'components/props/profiles';
import { reviewFlowStep } from 'components/props/reviewFlows';
import { PA, ROLES } from 'components/props/profiles';
// actions
import { fetchStepUsers, editingStepUser } from 'actions/reviewFlows';
import { show as showModal } from 'actions/modalDialog';
// selectors
import { getUsers } from 'selectors/reviewFlows';

const mapStateToProps = (state, ownProps) => ({
  users: getUsers(state, ownProps.reviewFlowId, ownProps.step.id),
});

const mapDispatchToProps = dispatch => ({
  onInit: (reviewFlowId, stepId) => {
    dispatch(fetchStepUsers({ reviewFlowId, stepId }));
  },
  onRemoveUserRequest: (reviewFlowId, role, position, stepId, userId) => {
    dispatch(editingStepUser({ reviewFlowId, role, position, stepId, userId }));
    dispatch(showModal({ dialog: 'removeReviewer' }));
  },
});

class ApprovalFlowStep extends Component {
  static propTypes = {
    users: usersProps,
    label: PropTypes.string.isRequired,
    onEditApprovalFlow: PropTypes.func.isRequired,
    onInit: PropTypes.func.isRequired,
    onRemoveUserRequest: PropTypes.func.isRequired,
    position: PropTypes.number,
    reviewFlowId: PropTypes.number.isRequired,
    step: reviewFlowStep,
    role: PropTypes.oneOf(ROLES).isRequired,
  };

  static defaultProps = {
    position: 1,
    users: [],
    step: {},
  };

  constructor(props) {
    super(props);

    this.onRemoveUser = this.onRemoveUser.bind(this);
    this.onEditRequest = this.onEditRequest.bind(this);
  }

  componentDidMount() {
    const { onInit, reviewFlowId, step } = this.props;

    onInit(reviewFlowId, step.id);
  }

  onEditRequest() {
    const { onEditApprovalFlow, position, reviewFlowId, role, step, users } =
      this.props;

    if (step && users && users.length > 0) {
      const userIds = users.reduce((ids, user) => {
        ids.push(user.id);
        return ids;
      }, []);
      onEditApprovalFlow(reviewFlowId, role, position, step.id, userIds);
    } else if (!users || users.length === 0) {
      onEditApprovalFlow(reviewFlowId, role, position);
    }
  }

  onRemoveUser(userId) {
    //TODO removeReviewer is unused now
    const { onRemoveUserRequest, position, reviewFlowId, role, step } =
      this.props;

    onRemoveUserRequest(reviewFlowId, role, position, step.id, userId);
  }

  render() {
    const { users, label, step, role, ...others } = this.props;

    const icon = role === PA ? DollarIcon : UserIcon;
    const allowEdit = !users || users.length === 0;

    return (
      <div>
        <ApprovalStep
          users={users}
          onEditStep={this.onEditRequest}
          onRemoveUser={this.onRemoveUser}
          label={label}
          icon={icon}
          role={role}
          allowEdit={allowEdit}
          {...others}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalFlowStep);
