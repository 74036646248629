import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import {
  Paper,
  Box,
  Typography,
  LinearProgress,
  Tooltip,
  IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ExistingComment from 'feature/DigitalEdits/Comments/ExistingComment';

//Redux imports
import {
  getCommentState,
  getInvoiceStatusCode,
  getCurrentInvoiceId,
} from 'selectors/digitalEdits';
import { setCommentState } from 'actions/digitalEdits';
import { show as openModal } from 'actions/modalDialog';

import { commentStateProps, invoiceStatusProp } from './DigitalEditProps';

const useStyles = makeStyles(({ palette }) => ({
  HeaderCommentCard: {
    borderRadius: 15,
    padding: 12,
    display: 'flex',
    fontSize: 14,
    fontWeight: 600,
    color: palette.gray.primary,
    flexDirection: 'column',
    maxWidth: '50%',
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    color: palette.text.primary,
    marginBottom: 8,
  },
  noComments: {
    textAlign: 'center',
    color: palette.text.disabled,
  },
  headerComment: {
    display: 'flex',
    marginTop: '12px',
    '&:first-of-type': { marginTop: 0 },
  },

  commentText: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
}));

const mapState = state => ({
  commentState: getCommentState(state),
  invoiceStatus: getInvoiceStatusCode(state),
  currentInvoiceId: getCurrentInvoiceId(state),
});
const mapDispatch = dispatch => ({
  onSetCommentState: commentState =>
    dispatch(setCommentState({ commentState })),
  onOpenModal: params => dispatch(openModal(params)),
});

const HeaderCommentCard = props => {
  const classes = useStyles();
  let {
    comments,
    resubmitLoading,
    commentState,
    onSetCommentState,
    invoiceStatus,
    onOpenModal,
    currentInvoiceId,
  } = props;

  let btnTooltip = '';
  switch (commentState) {
    case 'modal':
      break;
    case 'closed':
      btnTooltip = 'View Comments';
      break;
    default:
      btnTooltip = 'Focus Comment Window';
      break;
  }

  comments = comments.slice(0, 2);

  const isRevisions = invoiceStatus === 'W';
  const hasComments = comments.length > 0;

  const openModalAction = isRevisions
    ? () => {
        if (commentState?.focus) commentState.focus();
        else {
          onSetCommentState('modal');
        }
      }
    : () => {
        onOpenModal({
          dialog: 'DigitalEditsCommentsLite',
          modalParams: { readOnly: true, invoiceId: currentInvoiceId },
        });
      };

  return (
    <Paper
      className={classes.HeaderCommentCard}
      sx={{ flexGrow: hasComments ? 4 : 1 }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography className={classes.title}>Comments</Typography>
        {commentState !== 'modal' && (
          <Tooltip title={btnTooltip}>
            <IconButton onClick={openModalAction}>
              <OpenInFullIcon color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {resubmitLoading && <LinearProgress />}
      {comments.map(comment => {
        return (
          <ExistingComment
            key={comment.commentId}
            comment={comment}
            showReply={isRevisions}
          />
        );
      })}
    </Paper>
  );
};

HeaderCommentCard.propTypes = {
  comments: PropTypes.array.isRequired,
  resubmitLoading: PropTypes.bool.isRequired,
  commentState: commentStateProps.isRequired,
  onSetCommentState: PropTypes.func.isRequired,
  invoiceStatus: invoiceStatusProp,
  onOpenModal: PropTypes.func.isRequired,
  currentInvoiceId: PropTypes.string.isRequired,
};

export default connect(mapState, mapDispatch)(HeaderCommentCard);
