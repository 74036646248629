import { buildUrl } from '.';

const URL_USERS = '/users/:userId';
const URL_USERS_SEARCH = '/users/search/:emailOrFullName';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async worksightId({ userId }) {
    const url = buildUrl(URL_USERS, { userId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async getUsersOfAllProjects({ emailOrFullName }) {
    const url = buildUrl(URL_USERS_SEARCH, {
      emailOrFullName,
    });
    const response = await clientV2.get(url);
    return response.data;
  },
});
