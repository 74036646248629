import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TimecardHistory from '../../Timecards/Timecard/TimecardHistory';
import { renderApprovers } from '../../Timecards/Timecard/TimecardApprovers';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import * as actions from 'actions/wtc';
import { getHistoryStatusAndLabel } from '../../../../selectors/timecard/form';

const style = ({ palette }) => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    overflowX: 'auto',
  },
  breakdownTitle: {
    fontSize: 24,
    fontWeight: 500,
  },
  noPadding: {
    padding: 0,
    '& > *': {
      width: '100%',
      marginBottom: 0,
      marginTop: 0,
    },
  },
});

const WtcHistory = ({
  classes,
  fetchTCHistory,
  projectSettings,
  history,
  timecardId,
  loadingWtcHistory,
}) => {
  const [showHistory, setShowHistory] = useState(false);
  const [wtcHistory, setWtcHistory] = useState([]);
  useEffect(() => {
    setWtcHistory(getHistoryStatusAndLabel(history));
  }, [history]);

  const handleOnClick = open => {
    setShowHistory(open);
    if (open) {
      fetchTCHistory(timecardId);
    } else {
      setWtcHistory([]);
    }
  };

  return (
    <div>
      <Accordion className={classes.root} expanded={showHistory}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => handleOnClick(!showHistory)}
          variant="iconOnRight"
        >
          <Typography>
            <span className={classes.breakdownTitle}>Timecard History</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.noPadding}>
          <div>
            <TimecardHistory
              history={wtcHistory}
              projectSettings={projectSettings}
              false={false}
              loadingWtcHistory={loadingWtcHistory}
            />

            {renderApprovers(wtcHistory)}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const mapState = (state, { match }) => {
  return { history: state.wtc.history };
};

const mapDispatch = (dispatch, { match }) => ({
  fetchTCHistory: timecardId =>
    dispatch({
      type: `${actions.fetchWTCHistory}`,
      timecardId,
    }),
});

export default compose(
  connect(mapState, mapDispatch),
  withStyles(style),
)(WtcHistory);
