import React from 'react';
import { MaxWidthLayout } from 'containers/Layout';

import { EnhancedRoute } from 'containers/RoutingSupport';
import RedirectComp from 'feature/DigitalEdits/Redirect';

//BlankHeader handles setting projectId from url and making userinfo init call
import BlankHeader from 'feature/DigitalEdits/Comments/BlankHeader';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <EnhancedRoute
      authenticated
      key="/projects/digitalEdit/token/:token"
      id="/projects/digitalEdit/token/:token"
      path="/projects/:projectId/digitalEdit/tokens/:token"
      layout={MaxWidthLayout}
      headers={[BlankHeader]}
      component={RedirectComp}
      isLoadProjectActiveUser={true}
    />
  );
};
