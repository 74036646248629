import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
// actions
import * as actions from 'actions/timecards';
// selectors
import { getCurrentTimecard } from 'selectors/timecard/common';

const mapStateToProps = state => ({
  timecardId: getCurrentTimecard(state),
  initialValues: { timecardId: getCurrentTimecard(state) },
});

const onSubmit = (values, dispatch, ownProps) => {
  const timecardId = ownProps.timecardId;
  const action = ownProps.action || 'save';
  if (action === 'save') {
    dispatch(actions.setCurrentTimecard({ timecardId }));
    dispatch(actions.onSave());
  }
  if (action === 'approve') {
    dispatch(actions.setCurrentTimecard({ timecardId }));
    dispatch(actions.onSubmit());
  }
};

const useStyles = makeStyles(theme => ({
  content: {
    maxWidth: 500,
  },
}));

function ConfirmSubmit({ handleSubmit, timecardId, action, ...others }) {
  const classes = useStyles();
  const content = (
    <div className={classes.content}>
      <Typography color="inherit">
        {
          "You've copied identical times to all selected days. Are you sure you want to approve?"
        }
      </Typography>
    </div>
  );

  return (
    <form onSubmit={handleSubmit}>
      <Field
        component="input"
        name="timecardId"
        type="hidden"
        value={timecardId}
      />
      <ConfirmModal
        content={content}
        title={'Identical Times Found'}
        buttonText="Yes, Approve"
        buttonType="secondary"
        raisedButton
        {...others}
      />
    </form>
  );
}

export default compose(
  withModalDialog({ dialog: 'ConfirmSubmit', maxWidth: 'md' }),
  connect(mapStateToProps),
  reduxForm({
    form: 'ConfirmSubmit',
    onSubmit,
  }),
)(ConfirmSubmit);
