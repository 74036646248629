import React from 'react';
import PropTypes from 'prop-types';
import { ListItemText, ListSubheader } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import YearPicker from 'containers/Layout/YearPickerV2';

const useStyles = makeStyles(({ palette }) => ({
  MobilMenuYearPicker: {
    width: '100%',
  },
  subHeader: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 'normal',
  },
  text: {
    width: 120,
    color: palette.text.secondary,
    '& > .MuiTypography-root': {
      fontWeight: 'bold',
      fontSize: '14px',
    },
  },
}));

const MobilMenuYearPicker = props => {
  const classes = useStyles();

  return (
    <ListSubheader className={classes.subHeader}>
      <ListItemText className={classes.text} primary={'Project Date:'} />
      <YearPicker />
    </ListSubheader>
  );
};

MobilMenuYearPicker.propTypes = { children: PropTypes.element };

export default MobilMenuYearPicker;
