import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import DeleteIcon from '@mui/icons-material/Delete';

import * as actions from '../actions';
import { show as showModal } from 'actions/modalDialog';

import * as sel from '../selectors';

import Day from './Day';

import { CONFIRM_MODAL } from 'feature/EmployeeTimecard/Modals/modalNames';

const mapState = state => {
  return {
    isTcEditable: sel.getIsTcEditable(state),
  };
};
const mapDispatch = dispatch => ({
  onClearDay: index => {
    dispatch(actions.clearDay({ index, saveAfter: true }));
  },
  onShowModal: onConfirm =>
    dispatch(
      showModal({
        dialog: CONFIRM_MODAL,
        modalParams: {
          actionText: 'Delete this day from the timecard?',
          onConfirm,
        },
      }),
    ),
});
const useStyles = makeStyles(({ palette }) => ({
  swipeItem: {
    '& .swipeable-list-item__content': {
      display: 'block',
    },
    '& .swipeable-list-item__trailing-actions': {
      marginTop: '10px',
      color: palette.error.contrastText,
      background: palette.error.main,
      height: '88px',
      borderRadius: '0px 8px 8px 0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .test-actions-opened': {
      width: '55px !important',
    },
  },
}));
const TimecardDays = props => {
  const { fields, onClearDay, onShowModal, isTcEditable } = props;

  const classes = useStyles();
  const trailingActions = index => (
    <TrailingActions>
      <SwipeAction
        destructive={false}
        onClick={() => onShowModal(() => onClearDay(index))}
      >
        <DeleteIcon />
      </SwipeAction>
    </TrailingActions>
  );
  return (
    <SwipeableList fullSwipe={false} type={ListType.IOS}>
      {fields.map((member, index) => {
        const isNonEmptyDay = fields.get(index)?.htgDayTypeId;
        return (
          <SwipeableListItem
            className={classes.swipeItem}
            key={member}
            maxSwipe={0.5}
            trailingActions={
              isTcEditable && isNonEmptyDay ? trailingActions(index) : null
            }
          >
            <Day key={member} member={member} index={index} fields={fields} />
          </SwipeableListItem>
        );
      })}
    </SwipeableList>
  );
};

TimecardDays.propTypes = {
  fields: PropTypes.object.isRequired,
  onClearDay: PropTypes.func.isRequired,
  onShowModal: PropTypes.func.isRequired,
  isTcEditable: PropTypes.bool.isRequired,
};

export default compose(connect(mapState, mapDispatch))(TimecardDays);
