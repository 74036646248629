import React from 'react';

import {
  IconButton,
  TableCell,
  TableRow,
  Typography,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from '@mui/icons-material/Check';
import clsx from 'clsx';
import {
  FaPencilAlt,
  // FaTrash HOUR-7299
} from 'react-icons/fa';

export const useStyles = makeStyles(({ palette }) => ({
  row: {
    height: '64px',
    '& > *': {
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  actionColumn: {
    overflow: 'visible',
    padding: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  actionContainer: {
    display: 'inline-flex',
    padding: 0,
  },
  iconButton: {
    padding: 8,
    fontSize: '1.2em',
    display: 'inline',
    verticalAlign: 'bottom',
    cursor: 'pointer',
  },
  faIcons: {
    color: palette.primary.main,
  },
  disabledIcon: {
    color: palette.action.disabled,
  },
  separateBatchesSpace: {
    width: 100,
    height: 50,
  },
}));

const InvoiceTemplateTableRow = props => {
  const {
    template,
    departments,
    editBatchTemplate,
    // deleteTemplate, HOUR-7299
    index,
    templateOption,
    canadaRegion,
  } = props;
  const classes = useStyles();

  //selected country options
  // const country = template?.country?.name || '';

  //selected state options
  const state = template?.state?.name || '';

  //selected county options
  // const county = template?.county?.name || '';

  //selected subdivisions options
  // const subdivision = template?.subdivision?.code || '';

  //selected city option
  const city = template?.city?.name || '';
  const separateBatches = template?.separateBatches ? (
    <CheckIcon color="primary" />
  ) : (
    <div className={classes.separateBatchesSpace}></div>
  );
  const separateBatchesTip = template?.separateBatches
    ? 'Each department will get its own batch under this invoice'
    : 'All these departments will be combined into a single batch in this invoice';

  const systemLocationStr = template?.systemLocation?.value
    ? `${template?.systemLocation?.value} - ${template?.systemLocation?.description}`
    : '';

  const locationType = template?.locationType?.code
    ? `${template?.locationType?.code} - ${template?.locationType?.name}`
    : '';

  return (
    <TableRow hover className={classes.row}>
      <TableCell>
        <Typography>{template?.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {template?.departments &&
            template.departments.length > 0 &&
            template.departments
              .map(id => {
                const department =
                  departments.length > 0 && departments.find(d => d.id === id);
                return department && department.name;
              })
              .join(', ')}
        </Typography>
      </TableCell>
      <TableCell>
        <Tooltip title={separateBatchesTip} placement="bottom-start" arrow>
          <div>{separateBatches}</div>
        </Tooltip>
      </TableCell>
      {!canadaRegion && (
        <TableCell>
          <Typography>{locationType}</Typography>
        </TableCell>
      )}
      {/* <TableCell>
        <Typography>{country}</Typography>
      </TableCell> */}
      <TableCell>
        <Typography>{state}</Typography>
      </TableCell>
      {/* <TableCell>
        <Typography>{county}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{subdivision}</Typography>
      </TableCell> */}
      <TableCell>
        <Typography>{city}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{template?.producersStartDayOfWeek}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{systemLocationStr}</Typography>
      </TableCell>
      <TableCell className={classes.actionColumn}>
        <div className={classes.actionContainer}>
          <IconButton
            className={classes.iconButton}
            disabled={templateOption === 'new' || templateOption === 'edit'}
            onClick={() => editBatchTemplate(index, template)}
            size="large"
          >
            <FaPencilAlt
              className={clsx(classes.faIcons, {
                [classes.disabledIcon]: templateOption,
              })}
            />
          </IconButton>
          {/* <IconButton HOUR-7299 - disabling of template deletion
            disabled={templateOption === 'new' || templateOption === 'edit'}
            className={classes.iconButton}
            onClick={() => deleteTemplate(template?.id)}
            size="large"
          >
            <FaTrash
              className={clsx(classes.faIcons, {
                [classes.disabledIcon]: templateOption,
              })}
            />
          </IconButton> */}
        </div>
      </TableCell>
    </TableRow>
  );
};
export default InvoiceTemplateTableRow;
