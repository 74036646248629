import { produce } from 'immer';
import { authenticate, success, failure, logout } from 'actions/authenticate';

export const initialState = {
  authenticated: false,
  authenticationCheckInProgress: false,
  authenticationFailed: false,
  authenticationError: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${authenticate}`:
        draft.authenticated = false;
        draft.authenticationFailed = false;
        draft.authenticationCheckInProgress = true;
        break;

      case `${success}`:
        draft.authenticated = true;
        draft.authenticationFailed = false;
        draft.authenticationCheckInProgress = false;
        break;

      case `${failure}`:
        draft.authenticated = false;
        draft.authenticationFailed = true;
        draft.authenticationCheckInProgress = false;
        draft.authenticationError = action.error;
        break;

      case `${logout}`:
        draft.authenticated = false;
        draft.authenticationFailed = true;
        draft.authenticationCheckInProgress = false;
        draft.authenticationError = '';
        break;

      default:
    }
  });
