import React from 'react';
import { connect } from 'react-redux';
import Notifications from './Notifications';

class Preferences extends React.Component {
  render() {
    return (
      <div style={{ marginBottom: 30 }}>
        <Notifications />
      </div>
    );
  }
}

export default connect(null, null)(Preferences);
