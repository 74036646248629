//Deal Memo Modal table display fields
import _ from 'lodash';
import React from 'react';
import { isRegionCanada, canadianLabel } from 'utils/helperFunctions';

import { PAYROLL_COMPANY_CODE_TO_TYPE } from 'components/Shared/constants';

const renderText = (item, dataLoc) => {
  let data = _.get(item, dataLoc);
  if (typeof data === 'boolean') data = data === true ? 'Y' : 'N';
  let str = data || data === 0 ? data.toString() : '';
  if (dataLoc === 'frequency') {
    str = str === 'D' ? '/Day' : '/Week';
  }
  return str;
};

const renderCombo = (item, dataLoc, divider = ' - ') => {
  let str = '';
  for (let i = 0; i < dataLoc.length; i++) {
    const data = dataLoc[i];
    if (data === '$') {
      str += ` ${data}`;
      continue;
    }
    const newText = renderText(item, data);
    if (newText === '') return '';
    str += newText;
    if (i !== dataLoc.length - 1) {
      str += divider;
    }
  }
  return str;
};

const renderAllowance = (item, dataLoc, divider = ' - $') => {
  let retVal = [];
  const allowances = _.get(item, dataLoc.array);
  if (Array.isArray(allowances)) {
    allowances.forEach((allowance, index) => {
      for (let i = 1; i < 3; i++) {
        // There will be only payCode1 and payCode2 fields and we want the 1st one
        // blank rate/amount in payroll will come as 0
        const base = dataLoc.subLoc + `${i}.name`;
        const hasAmount = allowance.allowancesAmount > 0;
        const hasRate = !!allowance.rate;

        let allowanceCopy = _.cloneDeep(allowance);
        let rateDataLoc;
        if (hasAmount) {
          rateDataLoc = 'allowancesAmount';
        } else if (hasRate) {
          rateDataLoc = 'rate';
        } else {
          rateDataLoc = 'allowancesAmount';
          allowanceCopy.allowancesAmount = 0;
        }

        let lineText = renderCombo(allowanceCopy, [base, rateDataLoc], divider);

        if (lineText) {
          const freq = renderText(allowanceCopy, 'frequency');
          lineText += freq;
          const withBreak = (
            // eslint-disable-next-line react/no-array-index-key
            <span key={index}>
              {lineText}
              <br />
            </span>
          );
          retVal.push(withBreak);
          break;
        }
      }
    });
    return retVal.length > 0 ? retVal : '';
  } else {
    if (allowances !== undefined) {
      console.error(
        'Array expected in dealMemoAllowances. Found:',
        allowances,
        item,
      );
    }
    return '';
  }
};

const renderGuarantee = (item, dataLoc) => {
  let guar;
  guar = _.find(item.guarantees, g => {
    if (dataLoc.code === 'Other') {
      return !g.onLocation;
    } else {
      return g.onLocation && g.onLocation.code === dataLoc.code;
    }
  });

  if (guar) {
    return Array.isArray(dataLoc.subLoc)
      ? renderCombo(guar, dataLoc.subLoc)
      : renderText(guar, dataLoc.subLoc);
  } else {
    return '';
  }
};

const renderWithMap = map => {
  const func = (item, dataLoc) => {
    let code = renderText(item, dataLoc);
    const name = map[code];
    if (name) {
      code = `${code} - ${name}`;
    }
    return code;
  };
  return func;
};
export const dmStudioScaleRatesFields = [
  'Studio Hourly Rate',
  'Studio Gross Amount',
];

export const dmDistantScaleRatesFields = [
  'Distant Hourly Rate',
  'Distant Gross Amount',
];

const fields = [
  {
    label: 'ID #: Occupation Code',
    dataLoc: ['code', 'occupationCode.code', 'occupationCode.name'],
    renderFunc: renderCombo,
  },
  {
    label: 'Indicator',
    dataLoc: 'description',
    renderFunc: renderText,
  },
  {
    label: 'Work Schedule',
    dataLoc: 'workSchedule.code',
    renderFunc: renderText,
  },
  {
    label: 'Vacation/Fringe %',
    dataLoc: 'epfPercentage',
    renderFunc: renderText,
    fringeDisplay: 'show',
  },
  {
    label: 'Studio Hourly Rate',
    dataLoc: { code: 'S', subLoc: ['$', 'rate'] },
    renderFunc: renderGuarantee,
    fringeDisplay: 'hide',
  },
  {
    label: 'Studio Rate Exclusive',
    dataLoc: { code: 'S', subLoc: ['$', 'rate'] },
    renderFunc: renderGuarantee,
    fringeDisplay: 'show',
  },
  {
    label: 'Studio Rate Inclusive',
    dataLoc: { code: 'S', subLoc: ['$', 'inclusiveHourlyRate'] },
    renderFunc: renderGuarantee,
    fringeDisplay: 'show',
  },
  {
    label: 'Scale',
    dataLoc: { code: 'S', subLoc: 'payAtScale' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Studio Gross Amount',
    dataLoc: { code: 'S', subLoc: ['$', 'gross', 'type'] },
    renderFunc: renderGuarantee,
    fringeDisplay: 'hide',
  },
  {
    label: 'Studio Gross Exclusive',
    dataLoc: { code: 'S', subLoc: ['$', 'exclusiveGross', 'type'] },
    renderFunc: renderGuarantee,
    fringeDisplay: 'show',
  },
  {
    label: 'Studio Gross Inclusive',
    dataLoc: { code: 'S', subLoc: ['$', 'inclusiveGross', 'type'] },
    renderFunc: renderGuarantee,
    fringeDisplay: 'show',
  },
  {
    label: 'Studio Guar Days',
    dataLoc: { code: 'S', subLoc: 'days' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Studio Guar Hours',
    dataLoc: { code: 'S', subLoc: 'hours' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Studio Series',
    dataLoc: { code: 'S', subLoc: 'series' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Studio Location',
    dataLoc: { code: 'S', subLoc: 'location' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Studio Set',
    dataLoc: { code: 'S', subLoc: 'set' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Studio FF1',
    dataLoc: { code: 'S', subLoc: 'customField1' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Studio FF2',
    dataLoc: { code: 'S', subLoc: 'customField2' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Studio FF3',
    dataLoc: { code: 'S', subLoc: 'customField3' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Studio FF4',
    dataLoc: { code: 'S', subLoc: 'customField4' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Distant Hourly Rate',
    dataLoc: { code: 'D', subLoc: ['$', 'rate'] },
    renderFunc: renderGuarantee,
    fringeDisplay: 'hide',
  },
  {
    label: 'Distant Rate Exclusive',
    dataLoc: { code: 'D', subLoc: ['$', 'rate'] },
    renderFunc: renderGuarantee,
    fringeDisplay: 'show',
  },
  {
    label: 'Distant Rate Inclusive',
    dataLoc: { code: 'D', subLoc: ['$', 'inclusiveHourlyRate'] },
    renderFunc: renderGuarantee,
    fringeDisplay: 'show',
  },
  {
    label: 'Scale',
    dataLoc: { code: 'D', subLoc: 'payAtScale' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Distant Gross Amount',
    dataLoc: { code: 'D', subLoc: ['$', 'gross', 'type'] },
    renderFunc: renderGuarantee,
    fringeDisplay: 'hide',
  },
  {
    label: 'Distant Gross Exclusive',
    dataLoc: { code: 'D', subLoc: ['$', 'exclusiveGross', 'type'] },
    renderFunc: renderGuarantee,
    fringeDisplay: 'show',
  },
  {
    label: 'Distant Gross Inclusive',
    dataLoc: { code: 'D', subLoc: ['$', 'inclusiveGross', 'type'] },
    renderFunc: renderGuarantee,
    fringeDisplay: 'show',
  },
  {
    label: 'Distant Guar Days',
    dataLoc: { code: 'D', subLoc: 'days' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Distant Guar Hours',
    dataLoc: { code: 'D', subLoc: 'hours' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Distant Series',
    dataLoc: { code: 'D', subLoc: 'series' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Distant Location',
    dataLoc: { code: 'D', subLoc: 'location' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Distant Set',
    dataLoc: { code: 'D', subLoc: 'set' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Distant FF1',
    dataLoc: { code: 'D', subLoc: 'customField1' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Distant FF2',
    dataLoc: { code: 'D', subLoc: 'customField2' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Distant FF3',
    dataLoc: { code: 'D', subLoc: 'customField3' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Distant FF4',
    dataLoc: { code: 'D', subLoc: 'customField4' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Epi/Pgm Rate',
    dataLoc: { code: 'Other', subLoc: ['$', 'rate'] },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Epi/Pgm Gross Amount',
    dataLoc: { code: 'Other', subLoc: ['$', 'gross', 'type'] },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Epi/Pgm Guar Days',
    dataLoc: { code: 'Other', subLoc: 'days' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Epi/Pgm Guar Hours',
    dataLoc: { code: 'Other', subLoc: 'hours' },
    renderFunc: renderGuarantee,
  },
  {
    label: 'Set',
    dataLoc: 'set',
    renderFunc: renderText,
  },
  {
    label: 'Series',
    dataLoc: 'series',
    renderFunc: renderText,
  },
  {
    label: 'Location',
    dataLoc: 'location',
    renderFunc: renderText,
  },
  {
    label: 'Insurance',
    dataLoc: 'insurance',
    renderFunc: renderText,
  },
  {
    label: 'FF1',
    dataLoc: 'customField1',
    renderFunc: renderText,
  },
  {
    label: 'FF2',
    dataLoc: 'customField2',
    renderFunc: renderText,
  },
  {
    label: 'FF3',
    dataLoc: 'customField3',
    renderFunc: renderText,
  },
  {
    label: 'FF4',
    dataLoc: 'customField4',
    renderFunc: renderText,
  },
  {
    label: 'Effective Date',
    dataLoc: 'effectiveDate',
    renderFunc: renderText,
  },
  {
    label: 'Hire State',
    dataLoc: 'hireState.code',
    renderFunc: renderText,
  },
  {
    label: 'Loan-Out',
    dataLoc: 'corporation.name',
    renderFunc: renderText,
  },
  {
    label: 'Wage Account',
    dataLoc: 'wageAccount',
    renderFunc: renderText,
  },
  {
    label: 'Pension Union',
    dataLoc: 'pensionUnion.name',
    renderFunc: renderText,
  },
  {
    label: 'Allowances',
    dataLoc: { array: 'dealMemoAllowances', subLoc: 'payCode' },
    renderFunc: renderAllowance,
  },
  {
    label: 'Invoice Type',
    dataLoc: 'payrollCompany.code',
    renderFunc: renderWithMap(PAYROLL_COMPANY_CODE_TO_TYPE),
  },
  {
    label: 'Expiration Date',
    dataLoc: 'end',
    renderFunc: renderText,
  },
];

// List of fields we're suppressing from certain dealMemo modal displays

const BLOCK_WTC = ['Expiration Date'];

const BLOCK_LIST_NON_WTC = [
  ...BLOCK_WTC,
  'Set',
  'Series',
  'Location',
  'Insurance',
  'FF1',
  'FF2',
  'FF3',
  'FF4',
];

const BLOCK_LIST_RATE = [
  'Studio Hourly Rate',
  'Studio Gross Amount',
  'Distant Hourly Rate',
  'Distant Gross Amount',
  'Epi/Pgm Rate',
  'Epi/Pgm Gross Amount',
  //Canadian Fringe fields
  'EPF',
  'Studio Rate Inclusive',
  'Studio Rate Exclusive',
  'Distant Rate Inclusive',
  'Distant Rate Exclusive',
  'Studio Gross Inclusive',
  'Studio Gross Exclusive',
  'Distant Gross Inclusive',
  'Distant Gross Exclusive',
];

const BLOCK_LIST_EMPLOYEE = [
  ...BLOCK_LIST_NON_WTC,
  'Hire State',
  'Wage Account',
  'Allowances',
];

const BLOCK_LIST_DTS = [...BLOCK_LIST_NON_WTC, ...BLOCK_LIST_RATE];

const BLOCK_LIST_CREW = [
  ...BLOCK_LIST_NON_WTC,
  ...BLOCK_LIST_RATE,
  'Hire State',
  'Wage Account',
  'Allowances',
];

const FIELD_VARIANT_MAP = {
  wtc: [],
  dts: BLOCK_LIST_DTS,
  crew: BLOCK_LIST_CREW,
  emp: BLOCK_LIST_EMPLOYEE,
};

export const getDealMemoFields = (variant, fringeEnabledDeal = false) => {
  const isFringeEnabled = fringeEnabledDeal && isRegionCanada();

  let subFields = fields
    .filter(f => {
      let fringeCheck = true;
      if (f.fringeDisplay) {
        if (isFringeEnabled) {
          fringeCheck = f.fringeDisplay === 'show';
        } else {
          fringeCheck = f.fringeDisplay === 'hide';
        }
      }

      const blockList = FIELD_VARIANT_MAP[variant];
      const variantCheck = blockList.includes(f.label) === false;

      return fringeCheck && variantCheck;
    })
    .map(f => ({
      label: canadianLabel(f.label),
      dataLoc: f.dataLoc,
      renderFunc: f.renderFunc,
    }));
  if (isRegionCanada()) {
    subFields = subFields.filter(f => f.label !== 'Work Schedule');
  }
  return subFields;
};

export const getEmpDealMemoFields = (fringeEnabledDeal = false) => {
  const empFields = [
    'Loan-Out',
    'Studio Hourly Rate',
    'Studio Hourly Rate',
    'Studio Guar Days',
    'Studio Guar Hours',
    'Distant Hourly Rate',
    'Distant Guar Hours',
    'Distant Guar Days',
    'Epi/Pgm Rate', //other rate
    'Epi/Pgm Guar Days',
    'Epi/Pgm Guar Hours',
    'Scale',
    'Pension Union',
    'Effective Date',
    'Expiration Date',
    'Allowances',
  ];
  const isFringeEnabled = fringeEnabledDeal && isRegionCanada();

  let subFields = fields
    .filter(f => {
      let fringeCheck = true;
      if (f.fringeDisplay) {
        if (isFringeEnabled) {
          fringeCheck = f.fringeDisplay === 'show';
        } else {
          fringeCheck = f.fringeDisplay === 'hide';
        }
      }

      const empCheck = empFields.includes(f.label);

      return fringeCheck && empCheck;
    })
    .map(f => ({
      label: canadianLabel(f.label),
      dataLoc: f.dataLoc,
      renderFunc: f.renderFunc,
    }));
  if (isRegionCanada()) {
    subFields = subFields.filter(f => f.label !== 'Work Schedule');
  }

  return subFields;
};
