import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ViewDocument from 'components/images/ViewDocument';

const useStyles = makeStyles(({ palette }) => ({
  boldText: {
    fontWeight: 'bold',
  },
  padding: {
    padding: '12px 0px',
  },
}));

const DealAllowanceInfo = props => {
  const classes = useStyles();
  const { row } = props;

  const { dealMemoTC = {} } = row?.original || {};
  const dealAllowances = dealMemoTC?.dealMemoAllowances || [];

  const daily = [];
  const weekly = [];
  dealAllowances.forEach(da => {
    const { rate, frequency, allowancesAmount } = da;
    let payCode = da?.payCode1 || da?.payCode2;
    if (!_.isEmpty(payCode)) {
      const allowance = {
        payCode,
        rate: allowancesAmount || rate || 0,
        frequency,
      };
      if (frequency === 'D') {
        daily.push(allowance);
      }
      if (frequency === 'F') {
        weekly.push(allowance);
      }
    }
  });

  const title = (
    <div>
      {daily.length > 0 ? (
        <div>
          <span className={classes.boldText}>Daily Allowance from Deal:</span>
          <ul>
            {daily.map(deal => (
              <li key={`${deal.payCode.name} - ${deal.rate}`}>
                {deal.payCode.name}: ${deal.rate}/day
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className={classes.padding}>
          No Daily Allowances present on the deal memo
        </div>
      )}
      {weekly.length > 0 ? (
        <div>
          <span className={classes.boldText}>Weekly Allowance from Deal:</span>
          <ul>
            {weekly.map(deal => (
              <li key={`${deal.payCode.name} - ${deal.rate}`}>
                {deal.payCode.name}: ${deal.rate}/week
              </li>
            ))}
          </ul>
          <div>
            <span className={classes.boldText}>Note: </span>Weekly Allowances
            will be added on save
          </div>
        </div>
      ) : (
        <div>No Weekly Allowances present on the deal memo</div>
      )}
    </div>
  );

  if (daily.length === 0 && weekly.length === 0) return null;

  return (
    <Tooltip placement="top" title={title}>
      <div>
        <ViewDocument />
      </div>
    </Tooltip>
  );
};

DealAllowanceInfo.propTypes = { row: PropTypes.object.isRequired };

export default DealAllowanceInfo;
