import React from 'react';
import {
  Paper,
  TableRow,
  TableCell,
  Typography,
  LinearProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Tooltip,
  Chip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import moment from 'moment';
import { FaCheck as ExemptIcon, FaTimes as RemoveIcon } from 'react-icons/fa';
//components
import { TableList } from 'components/Shared/TableList';
import Notice from 'components/Shared/Text/Notice';

const useStyles = makeStyles(({ palette }) => ({
  GroupTimecard: {
    width: '100%',
    overflowX: 'auto',
  },
  status: {
    textTransform: 'uppercase',
  },
  smallIcon: {
    width: 16,
    height: 16,
  },
  header: {
    marginBottom: 20,
  },
  small: {
    width: 48,
    height: 48,
    padding: 8,
  },
  startDates: {
    display: 'flex',
    flexDirection: 'column',
  },
  date: {
    display: 'flex',
    fontSize: '1rem',
    lineHeight: 1.5,
    height: 42,
    alignItems: 'center',
  },
  occCode: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  roundingSub: {
    fontSize: 12,
    padding: 10,
    width: 24,
  },
  roundingMsg: {
    fontSize: 16,
    lineHeight: '22px',
    padding: 8,
    fontWeight: 400,
    color: palette.error.main,
  },
  label: {
    marginRight: 0,
  },
  asterisk: {
    fontSize: 23,
    padding: 5,
    color: palette.warning.main,
  },
  chip: {
    height: 24,
    color: palette.secondary.contrastText,
    backgroundColor: palette.secondary.light,
    margin: 5,
  },
  tableList: {
    maxHeight: '50vh',
  },
}));

export default function GroupTimecards({
  teamTimecardUsers = {},
  occupationValue = [],
  onChangeOccupationValue = null,
  onRemove = null,
  loading = true,
  weekEndingdate,
  hasSameRounding,
  groupRounding,
}) {
  const classes = useStyles();
  const startOfWeek = moment(weekEndingdate)
    .subtract(6, 'days')
    .format('YYYY-MM-DD');
  const partialDealMemoWarning =
    'Deal is valid for only part of this week ending.';

  const wonkyDateWarning =
    'A start date prior to the year 2000 may result from missing or incorrect information on the deal memo. Contact your payroll coordinator or Plus Support if this appears incorrect.';

  function Remove(key) {
    onRemove(key);
  }

  function handleChange(event, timecard) {
    let newValue = [].concat(occupationValue);
    const split = _.split(event.target.value, '_');
    if (split && split.length >= 3) {
      const memo = _.find(
        timecard.user.dealMemos,
        memo => memo.id === split[1] && memo.occupationCode.id === split[2],
      );
      newValue.splice(
        split[3],
        1,
        split[0] +
          '_' +
          memo.id +
          '_' +
          memo.occupationCode.id +
          '_' +
          split[3],
      );

      onChangeOccupationValue(newValue);
    }
  }
  function getIsExempt(timecard, occupationValue) {
    const split = _.split(occupationValue, '_');

    const memo = _.find(
      timecard.user.dealMemos,
      memo => memo.id === split[1] && memo.occupationCode.id === split[2],
    );
    return (memo && memo.exempt) || false;
  }

  const existingTimecardUsers = _.filter(
    teamTimecardUsers,
    user => user.status !== 'incomplete' && user.status !== 'blank',
  );

  const multiTimecardWarning = (
    <section>
      <Typography>
        This will result in additional timecards for the following employees:{' '}
      </Typography>
      {existingTimecardUsers.map(user => (
        <Chip
          key={user.key}
          className={classes.chip}
          display={'inline'}
          label={user.fullName}
        />
      ))}
    </section>
  );

  const getRoundingFromMemoId = memoId => {
    const result = groupRounding.find(r => r.id === memoId);
    const roundTo = result?.roundTo ?? '';
    return roundTo;
  };

  const contentRows = _.map(teamTimecardUsers, (timecard, timeCardIndex) => (
    <TableRow hover key={timecard.key}>
      <TableCell>{timecard.fullName}</TableCell>
      <TableCell>
        <RadioGroup
          name="occupationCodes"
          value={occupationValue[timeCardIndex]}
          onChange={event => handleChange(event, timecard)}
        >
          {_.map(timecard.user.dealMemos, memo => {
            const validMemo =
              moment(memo.startsOn).isSameOrBefore(weekEndingdate) &&
              moment(memo.endsOn).isSameOrAfter(startOfWeek);

            const partialDealMemo =
              moment(memo.startsOn).isAfter(startOfWeek) ||
              moment(memo.endsOn).isBefore(weekEndingdate);

            return validMemo ? (
              <div className={classes.occCode} key={memo.id}>
                <FormControlLabel
                  className={classes.label}
                  value={
                    timecard.user.id +
                    '_' +
                    memo.id +
                    '_' +
                    memo.occupationCode.id +
                    '_' +
                    timeCardIndex
                  }
                  control={<Radio />}
                  label={`${memo.occupationCode.description}-${memo.code}`}
                />
                {partialDealMemo && (
                  <Tooltip title={partialDealMemoWarning} placement="top">
                    <span className={classes.asterisk}>*</span>
                  </Tooltip>
                )}

                <span className={classes.roundingSub}>
                  {!hasSameRounding && `(${getRoundingFromMemoId(memo.id)})`}
                </span>
              </div>
            ) : (
              ''
            );
          })}
        </RadioGroup>
      </TableCell>
      <TableCell className={classes.status}>
        <div className={classes.startDates}>
          {_.map(timecard.user.dealMemos, memo => {
            const validMemo =
              moment(memo.startsOn).isSameOrBefore(weekEndingdate) &&
              moment(memo.endsOn).isSameOrAfter(startOfWeek);
            return validMemo ? (
              <div className={classes.date} key={memo.id}>
                {moment(memo.startsOn).format('l')}
                {moment(memo.startsOn).isBefore(
                  moment('2000-01-01', 'YYYY-DD-MM'),
                ) && (
                  <Tooltip title={wonkyDateWarning} placement="top">
                    <span className={classes.asterisk}>**</span>
                  </Tooltip>
                )}
              </div>
            ) : (
              ''
            );
          })}
        </div>
      </TableCell>

      <TableCell className={classes.status}>{timecard.status}</TableCell>
      <TableCell>
        {getIsExempt(timecard, occupationValue[timeCardIndex]) && (
          <ExemptIcon />
        )}
      </TableCell>
      <TableCell>
        <IconButton
          className={classes.small}
          onClick={() => Remove(timecard.key)}
          size="large"
        >
          <RemoveIcon className={classes.smallIcon} />
        </IconButton>
      </TableCell>
    </TableRow>
  ));

  const headers = [
    {
      columnId: 'employee',
      label: 'Employee',
      sortable: false,
      compact: true,
      align: 'center',
    },
    {
      columnId: 'occupation',
      label: `Occupation${hasSameRounding ? '' : '   (Rounding)'}`,
      sortable: false,
      compact: true,
      align: 'center',
    },
    {
      columnId: 'startDate',
      label: 'Start Date',
      sortable: false,
      compact: true,
      align: 'center',
    },
    {
      columnId: 'status',
      label: 'Status',
      sortable: false,
      compact: true,
      align: 'center',
    },
    {
      columnId: 'exempt',
      label: 'Exempt',
      sortable: false,
      compact: true,
      align: 'center',
    },
    {
      columnId: 'remove',
      label: 'Remove',
      sortable: false,
      compact: true,
      align: 'center',
    },
  ];

  return (
    <Paper className={classes.GroupTimecard}>
      {existingTimecardUsers.length > 0 && (
        <Notice type="warning" className={classes.header}>
          {multiTimecardWarning}
        </Notice>
      )}
      {!hasSameRounding && (
        <Notice type="error" className={classes.roundingMsg}>
          <span className={classes.errorText}>
            Ensure all occupations selected have the same time rounding value.
          </span>
        </Notice>
      )}
      {loading && <LinearProgress />}
      <TableList
        hover
        headers={headers}
        overflow
        isAlternateRowColor={true}
        className={classes.tableList}
      >
        {contentRows}
      </TableList>
    </Paper>
  );
}
