import _ from 'lodash';
import { createSelector } from 'reselect';

export const getLoadingReviews = state =>
  _.get(state, 'reviews.loading', false);

export const getBatchesLoading = state =>
  _.get(state, 'reviews.loading.batches', false);
export const getCountLoading = state =>
  _.get(state, 'reviews.loading.count', {});

export const getLoadingTimecards = state =>
  _.get(state, 'reviews.loadingTimecards', false);

export const getTimecardLoadingByBatchID = state =>
  _.get(state, 'reviews.batchLoading', {});

export const getDownloadingByBatchId = (state, batchId) =>
  batchId && typeof Number(batchId) === 'number'
    ? _.get(state, `reviews.batchDownloading.${batchId}`, false)
    : false;

export const getLoadingByBatchId = (state, batchId) =>
  batchId && typeof Number(batchId) === 'number'
    ? _.get(state, `reviews.batchLoading.${batchId}`, false)
    : false;

export const reviews = state => _.get(state, `reviews`, {});
export const reviewBatchesByReviewType = reviewType => state =>
  _.get(state, `reviews.${reviewType}.batches`, {});
export const getOpenReviewBatches = state =>
  _.get(state, 'reviews.openBatchList', []);

export const reviewFromURL = reviewType => id => state =>
  _.get(state, `reviews.${reviewType}.batches.${id}.fromURI`, {});

export const getLoadingComments = state =>
  _.get(state, 'reviews.loadingComments', false);
export const getComments = state => _.get(state, 'reviews.comments', []);

export const getLoading = createSelector(
  [getLoadingReviews, getLoadingTimecards],
  (reviewsLoadingObj, timecardsLoading) => {
    let reviewsLoading = false;
    for (const key in reviewsLoadingObj) {
      if (reviewsLoadingObj.hasOwnProperty(key)) {
        const element = reviewsLoadingObj[key];
        if (typeof element === 'boolean') {
          reviewsLoading = reviewsLoading || element;
        } else {
          //storing counts as object
          for (const subKey in element) {
            if (element.hasOwnProperty(subKey)) {
              const subElement = element[subKey];
              reviewsLoading = reviewsLoading || subElement;
            }
          }
        }
      }
    }

    return reviewsLoading || timecardsLoading;
  },
);

export const getSelectedApprovals = state =>
  _.get(state, 'reviews.selectedApprovalFlows', []);

export const getCurrentApprovalFlow = state =>
  _.get(state, 'reviews.currentApprovalFlow', {});

export const isProcessingApproval = state =>
  _.get(state, 'reviews.processingApproval', false);

export const isShowingPaid = state =>
  _.get(state, 'reviews.isShowingPaid', false);
