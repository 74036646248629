import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material';

const Bell = ({ className }) => {
  const theme = useTheme();
  const { palette } = theme;

  const primary = palette.primary.main;
  const highlight = palette.primary['+7'];
  const background = palette.primary['+9'];

  return (
    <svg
      className={className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="118"
      height="118"
      viewBox="0 0 118 118"
    >
      <g>
        <circle fill={background} cx="59.2" cy="59.2" r="59" />
      </g>
      <g>
        <ellipse fill={highlight} cx="60.4" cy="94.6" rx="21.3" ry="3.7" />
      </g>
      <g>
        <path
          fill={primary}
          d="M86.5,73.9l-7.8-12.8c-2.1-3.4-3-7.4-2.7-11.3l0.7-8.1c0.2-2.6-0.6-5.1-2.3-7.2c-1.8-2.1-4.3-3.3-7.1-3.5
   c-0.3,0-1,0-1.8,0c-0.1,0-0.3-0.1-0.4,0c-0.1,0-0.5,0-0.9,0c-0.1-2.4-2.3-5-5-5c-2.8,0-5,2.7-5,5c-1.4,0-2.8,0.1-3.3,0.1
   c-5.3,0.5-9.3,5.3-8.8,10.6l0.7,8.2c0.4,4-0.6,7.9-2.6,11.3L32.2,74c-1.1,1.7-1.1,3.8-0.1,5.6c0.8,1.4,2.1,2.4,3.7,2.8
   c0.2,0.1,0.3,0.2,0.5,0.2h0.9h44.2h1c0.2,0,0.4-0.1,0.6-0.2c1.5-0.4,2.8-1.4,3.6-2.8C87.6,77.8,87.6,75.7,86.5,73.9z M84.9,78.6
   c-0.7,1.2-2,2-3.4,2H37.2c-1.4,0-2.7-0.7-3.4-1.9c-0.6-1.1-0.6-2.4,0.1-3.5l7.9-12.9c2.3-3.8,3.3-8.1,2.9-12.5L44,41.4
   c-0.4-4.2,2.8-8,7-8.4c0.7-0.1,4.2-0.1,5.5-0.1c0.1,0,0.1,0,0.2,0c0.5,0,1-0.4,1-1c0-0.6-0.4-1-1-1c-0.1,0-0.3,0-0.5,0
   c0.1-1.4,1.5-3,3-3c1.6,0,3,1.6,3,3c-0.2,0-0.4,0-0.4,0c-0.5,0-1,0.5-1,1c0,0.7,0.4,0.9,1,1v0c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0,0.5,0
   l0.8,0c1.5,0,3.5,0,3.9,0.1c2.2,0.2,4.3,1.2,5.7,2.8c1.4,1.6,2,3.6,1.8,5.7L74,49.6c-0.4,4.4,0.6,8.8,2.9,12.6L84.8,75
   C85.5,76.1,85.5,77.4,84.9,78.6z"
        />
        <path
          fill={primary}
          d="M53.1,36.7c-0.1,0-3,1.1-4.5,4.4c-1.3,2.9-1.1,6.5,0.7,10.8c0,0,0,0.1,0.1,0.2c1.2,2.6,1.2,5.7-0.2,8.3
   l-7.8,15.5c-0.2,0.5-0.1,1.1,0.4,1.3c0.1,0.1,0.3,0.1,0.5,0.1c0.4,0,0.7-0.2,0.9-0.5L51,61.4c1.6-3.2,1.7-6.9,0.2-10.1
   c-0.1-0.1-0.1-0.2-0.1-0.2c-1.5-3.7-1.7-6.7-0.7-9.1c1.1-2.5,3.3-3.3,3.4-3.4c0.5-0.2,0.8-0.8,0.6-1.3
   C54.2,36.8,53.6,36.6,53.1,36.7z"
        />
        <path
          fill={primary}
          d="M59.2,89c3.2,0,5.7-2.6,5.7-5.7H53.4C53.4,86.4,56,89,59.2,89z"
        />
      </g>
    </svg>
  );
};

Bell.propTypes = { className: PropTypes.string };

export default Bell;
