import { buildUrl } from '../api';

const URL_MASTER_LIST = '/projects/departments';
const URL_DEPARTMENTS = '/projects/:projectId/departments';
const URL_DEPARTMENT_STATS =
  '/projects/:projectId/departments/:departmentId/stats';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async list({ projectId }) {
    const url = buildUrl(URL_DEPARTMENTS, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async masterList() {
    const rsp = await clientV2.get(URL_MASTER_LIST);
    return rsp.data;
  },

  async stats({ projectId, departmentId }) {
    const url = buildUrl(URL_DEPARTMENT_STATS, {
      projectId,
      departmentId,
    });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
});
