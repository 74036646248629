import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPagination } from 'selectors/pagination';
import * as actions from 'actions/pagination';

// eslint-disable-next-line import/no-anonymous-default-export
export default grid => {
  const mapStateToProps = state => ({
    pagination: getPagination(grid)(state),
  });

  const mapDispatchToProps = dispatch => ({
    onInitGrid: () => dispatch(actions.initialize({ grid })),
    onDestroyGrid: () => dispatch(actions.remove({ grid })),
    onNavigate: page => dispatch(actions.navigate({ grid, page })),
    onChangePageSize: pageSize =>
      dispatch(actions.changeSize({ grid, pageSize })),
  });

  return BaseComponent => {
    class ComponentWithGridPagination extends Component {
      static displayName = `withGridPagination(${BaseComponent.displayName})`;

      static propTypes = {
        onInitGrid: PropTypes.func.isRequired,
        onDestroyGrid: PropTypes.func.isRequired,
      };

      componentDidMount() {
        this.props.onInitGrid(grid);
      }

      componentWillUnmount() {
        this.props.onDestroyGrid(grid);
      }

      render() {
        return <BaseComponent {...this.props} />;
      }
    }

    return connect(
      mapStateToProps,
      mapDispatchToProps,
    )(ComponentWithGridPagination);
  };
};
