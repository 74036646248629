import React, { useState } from 'react';
import { connect } from 'react-redux';
import pluralize from 'pluralize';
import { compose } from 'utils/helperFunctions';
import makeStyles from '@mui/styles/makeStyles';
import { Typography, LinearProgress } from '@mui/material';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { TextField } from 'components/Shared/redux';

import { ConfirmModal } from 'components/Shared/Modals';
// actions
import { bulkSubmitFromTimecard } from 'actions/searchTimecards';

// selectors
import { getSelectedApprovals, getSubmitting } from 'selectors/searchTimecards';
import { INPUT_FIELD_CHARACTER_LIMIT } from 'components/Shared/constants';
const commentsLimit = INPUT_FIELD_CHARACTER_LIMIT.comments;

const mapStateToProps = state => ({
  selected: getSelectedApprovals(state),
  loading: getSubmitting(state),
});

const mapDispatch = {
  onSubmitFromTimecard: bulkSubmitFromTimecard,
};

const useStyles = makeStyles(theme => ({
  contentBlock: {
    maxWidth: 500,
  },
  fieldWidth: {
    width: 496,
  },
}));

function RejectApprovalFlow({
  selected,
  loading,
  onSubmitFromTimecard,
  onModalHide,
}) {
  const classes = useStyles();

  const [comment, setComment] = useState('');

  const content = (
    <div className={classes.contentBlock}>
      <Typography color="inherit">
        <b>{pluralize('timecard', selected.length, true)} </b>
        {' is being Rejected'}
      </Typography>
      <br />
      <TextField
        placeholder="Please add rejection comment below"
        className={classes.fieldWidth}
        label="Comment"
        variant="outlined"
        multiline
        rows="4"
        maxLength={commentsLimit}
        onChange={e => setComment(e.target.value)}
        autoFocus
      />
      {loading && <LinearProgress />}
    </div>
  );

  const onSubmit = (values, dispatch) => {
    onSubmitFromTimecard({
      comment,
      status: 'rejected',
      timecardEntryHeaderIds: selected,
    });
  };

  return (
    <ConfirmModal
      content={content}
      title={'Required: Add a Rejection Comment'}
      buttonText="Submit"
      buttonType="primary"
      submitDisabled={loading || !comment}
      onSubmit={onSubmit}
      hasSubmitAction
      onModalHide={onModalHide}
    />
  );
}

export default compose(
  withModalDialog({ dialog: 'RejectApprovalFlow', maxWidth: 'md' }),
  connect(mapStateToProps, mapDispatch),
)(RejectApprovalFlow);
