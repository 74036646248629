import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// components
import { Card, CardHeader, CardContent } from 'components/Shared/Card';
import ReportRow from 'components/Employees/Reports/OnDemand';
import { MissingRequiredFields } from './Modals';
// selectors
import { getUserReports, getLoading } from 'selectors/reports';
//actions
import { runReport, runHotHostReport } from 'actions/reports';
import { LinearProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { TableList } from 'components/Shared/TableList';
// utils
import { compose } from 'utils/helperFunctions';

const style = theme => ({
  root: {
    width: '100%',
  },
  content: {
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
  },
});

const mapStateToProps = (state, { match }) => ({
  reports: getUserReports(state),
  isLoading: getLoading(state),
});

const mapDispatch = (dispatch, { match }) => ({
  onRunReport: report => {
    dispatch(runReport({ report }));
  },
  onRunHotCostReport: report => {
    dispatch(runHotHostReport({ report }));
  },
});

const OnDemand = ({
  classes,
  reports,
  onRunReport,
  onRunHotCostReport,
  isLoading,
}) => {
  const reportRows = _.map(reports, report => (
    <ReportRow
      key={report.key}
      report={report}
      onRunReport={onRunReport}
      onRunHotCostReport={onRunHotCostReport}
    />
  ));

  const headers = [
    {
      columnId: 'reportName',
      label: 'Report Name',
      sortable: true,
      compact: true,
    },
    {
      columnId: 'dateRange',
      label: 'Date Range',
      sortable: false,
      compact: true,
    },
    { columnId: 'actions', label: 'Action', sortable: false, compact: true },
  ];
  return (
    <>
      <Card className={classes.root}>
        <CardHeader plain>{isLoading && <LinearProgress />}​​​​</CardHeader>
        <CardContent className={classes.content}>
          <TableList headers={headers}>{reportRows}</TableList>
        </CardContent>
      </Card>
      <MissingRequiredFields />
    </>
  );
};

export default compose(
  connect(mapStateToProps, mapDispatch),
  withStyles(style),
)(OnDemand);
