import PropTypes from 'prop-types';

export const FLAG_DESCRIPTIONS = {
  api_auth_token: 'Enable Token Authentication on API',
  splits: 'Enable day splits on timecard tables',
  reports: 'Enable reports tab',
};

export const flagProps = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  enabled: PropTypes.bool,
});

export const flagsProps = PropTypes.arrayOf(flagProps);
