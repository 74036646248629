import Switch from '@mui/material/Switch';
import { createComponent } from 'utils/redux';

export default createComponent(
  Switch,
  ({ input: { onChange, value, ...inputProps }, checked, meta, ...props }) => ({
    ...inputProps,
    ...props,
    onChange,
    checked: !!value,
  }),
);
