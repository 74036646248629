import React from 'react';
import { InvoiceTemplate } from 'containers/Admin/Projects/InvoiceTemplate';
import {
  DefaultLayout,
  Header,
  ProjectHeader,
  AlertHeader,
} from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';
import { InvoiceTemplate as SubNav } from 'containers/Nav/Pages';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    admin
    authenticated
    key="/admin/projects/templates"
    path="/admin/projects/:projectId/templates"
    layout={DefaultLayout}
    headers={[Header, ProjectHeader, AlertHeader, SubNav]}
    component={InvoiceTemplate}
    title="Invoice Templates"
  />
);
