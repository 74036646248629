import createAction from './createAction';

export const sort = createAction('sort/projects/ProjectAllowances');
export const loading = createAction('loading/projects/ProjectAllowances');
export const fetch = createAction('fetch/projects/ProjectAllowances');
export const store = createAction('store/projects/ProjectAllowances');
export const edit = createAction('edit/projects/ProjectAllowances');
export const update = createAction('update/projects/ProjectAllowances');
export const storeDayTypes = createAction('storeDayTypes/ProjectAllowances');

export const reset = createAction('reset/projects/ProjectAllowances');
