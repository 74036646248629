import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatMessage, REQUIRED, INVALID_EMAIL } from 'utils/messages';
import validator from 'utils/validator';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import {
  getCorrectEmailProjects,
  getIncorrectEmailProjects,
  getLoading,
} from 'selectors/reports';
import * as actions from 'actions/reports';
import ProjectsList from './ProjectsList';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 30,
    overflowX: 'auto',
  },
  empty: {
    textAlign: 'center',
  },
  tableRoot: {
    width: '100%',
    marginTop: 16,
    overflowX: 'auto',
  },
  mergeButton: {
    textAlign: 'right',
  },
}));

const mapState = state => ({
  incorrectEmailProjects: getIncorrectEmailProjects(state),
  correctEmailProjects: getCorrectEmailProjects(state),
  loading: getLoading(state),
});

const mapDispatch = dispatch => ({
  onSearchAllProjects: (emailType, email) => {
    dispatch(actions.fetchUsersAllProjects({ email, emailType }));
  },
  clearEmailProjectsStore: () => {
    dispatch(actions.storeIncorrectEmailProjects({ projects: [] }));
    dispatch(actions.storeCorrectEmailProjects({ projects: [] }));
  },
  mergeEmailProjects: (incorrectEmail, correctEmail) => {
    dispatch(
      actions.mergeEmailProjects({
        incorrectEmail,
        correctEmail,
      }),
    );
  },
});

const EmailChange = props => {
  const {
    incorrectEmailProjects,
    correctEmailProjects,
    onSearchAllProjects,
    clearEmailProjectsStore,
    mergeEmailProjects,
    loading,
  } = props;

  const classes = useStyles();
  const [correctEmail, setCorrectEmail] = useState('');
  const [incorrectEmail, setIncorrectEmail] = useState('');
  const [incorrectEmailExpand, setIncorrectEmailExpand] = useState(true);
  const [correctEmailExpand, setCorrectEmailExpand] = useState(true);

  useEffect(() => {
    return () => {
      clearEmailProjectsStore();
    };
  }, [clearEmailProjectsStore]);

  const validate = email => {
    const errors = {
      error: false,
      message: null,
    };
    if (email === '') {
      return errors;
    } else if (!email) {
      errors.message = formatMessage(REQUIRED);
      errors.error = true;
    } else if (!validator.isEmail(email)) {
      errors.message = formatMessage(INVALID_EMAIL);
      errors.error = true;
    }
    return errors;
  };

  const inputProps = {
    style: {
      background: '#F4F4F4',
      paddingTop: 12,
    },
  };

  return (
    <div>
      <Grid container spacing={10}>
        <Grid item xs={12} md={5}>
          <Grid container>
            <Grid item xs={10}>
              <TextField
                error={validate(incorrectEmail).error}
                variant="filled"
                size="small"
                fullWidth
                placeholder="Incorrect email: jdkljl@somewhere.cas"
                value={incorrectEmail}
                inputProps={inputProps}
                onChange={e => {
                  setIncorrectEmail(e.target.value);
                }}
                helperText={validate(incorrectEmail).message}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.searchButton}
                disabled={
                  validate(incorrectEmail).error || incorrectEmail === ''
                }
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  onSearchAllProjects('incorrect', incorrectEmail);
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          <Grid container>
            <Grid item xs={10}>
              <TextField
                error={validate(correctEmail).error}
                variant="filled"
                size="small"
                value={correctEmail}
                placeholder="Correct email: something@somewhere.cas"
                fullWidth
                inputProps={inputProps}
                onChange={e => {
                  setCorrectEmail(e.target.value);
                }}
                helperText={validate(correctEmail).message}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.searchButton}
                disabled={validate(correctEmail).error || correctEmail === ''}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  onSearchAllProjects('correct', correctEmail);
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2} className={classes.mergeButton}>
          <Button
            className={classes.inputButton}
            variant="contained"
            color="primary"
            size="small"
            disabled={
              !(incorrectEmailProjects.length && correctEmailProjects.length) ||
              loading
            }
            onClick={() => mergeEmailProjects(incorrectEmail, correctEmail)}
          >
            Merge
          </Button>
        </Grid>
      </Grid>
      <Accordion expanded={incorrectEmailExpand} className={classes.root}>
        <AccordionSummary
          id="incorrectEmailExpand"
          className={classes.panelTop}
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setIncorrectEmailExpand(!incorrectEmailExpand)}
        >
          <Typography className={classes.heading}>Incorrect</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.tableRoot}>
            <ProjectsList projects={incorrectEmailProjects} />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={correctEmailExpand} className={classes.root}>
        <AccordionSummary
          id="correctEmailExpand"
          className={classes.panelTop}
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setCorrectEmailExpand(!correctEmailExpand)}
        >
          <Typography className={classes.heading}>Correct</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.tableRoot}>
            <ProjectsList projects={correctEmailProjects} />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default compose(connect(mapState, mapDispatch))(EmailChange);
