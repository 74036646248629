import React from 'react';
import { TableRow, TableCell, Checkbox } from '@mui/material';

//Components
import { AutoComplete } from 'components/Shared/AutoComplete';

//actions
import { generateTimes } from 'utils/weekUtils';

export default function NotificationRow({
  member,
  preference,
  onSelectIndividual,
}) {
  // Delivery Time
  const deliveryTimeRows = generateTimes(60);

  //Delivery Type
  const deliveryTypeRows = [
    { label: 'Email', value: 'email' },
    { label: 'Text', value: 'text' },
    { label: 'Both', value: 'both' },
  ];

  let enableDeliveryOptions = preference && preference.schedulable === true;
  return (
    <TableRow key={preference.type} hover>
      <TableCell>
        <Checkbox
          checked={preference.enabled}
          color="primary"
          onChange={e =>
            onSelectIndividual(
              e.target.checked,
              preference.description,
              'selectedSingle',
              preference.enabled,
            )
          }
        />
      </TableCell>
      <TableCell>{preference && preference.description}</TableCell>
      {enableDeliveryOptions && (
        <TableCell>
          <AutoComplete
            list={deliveryTimeRows}
            name={`${member}.scheduleTime`}
            disabled={deliveryTimeRows.length === 0}
            isRequired={true}
          />
        </TableCell>
      )}
      {!enableDeliveryOptions && <TableCell>{'Immediately'}</TableCell>}
      {enableDeliveryOptions && <TableCell>{'Email'}</TableCell>}
      {!enableDeliveryOptions && (
        <TableCell>
          <AutoComplete
            list={deliveryTypeRows}
            name={`${member}.deliveryType`}
            disabled={!preference.enabled}
            isRequired={true}
          />
        </TableCell>
      )}
    </TableRow>
  );
}
