import React from 'react';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'redux-form';
import { TextField } from 'components/Shared/redux';
import { IconButton } from '@mui/material';
import { AutoComplete } from 'components/Shared/AutoComplete';
import DeleteIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginBottom: 20,
  },
  field: {
    marginRight: 16,
    flexGrow: 1,
  },
  emailField: {
    marginRight: 16,
    flexGrow: 2,
    width: 320,
  },
  inputsContainer: {
    display: 'flex',
  },
  departmentSelect: {
    width: '320px',
    marginTop: '-3px',
  },
  actionContainer: {
    display: 'inline-flex',
    padding: 0,
  },
}));

const InviteRow = props => {
  const {
    invite,
    // index,
    addNext,
    rowValues,
    // fields,
    removeUser,
    disableRemove,
    canDelete = true,
    canAddNext,
    hasSelected,
    inviteError,
    departments,
  } = props;

  const classes = useStyles();
  const isDisabled = rowValues && rowValues?.status === 'deleted';
  const getErrorText = field => {
    return inviteError && inviteError[field]
      ? _.startCase(inviteError[field])
      : null;
  };

  return (
    <div className={classes.root}>
      <div className={classes.inputsContainer}>
        {hasSelected ? (
          <>
            <Field
              className={classes.field}
              component={TextField}
              label="Full Name"
              name={`${invite}.name`}
              disabled
            />
            <Field
              className={classes.emailField}
              component={TextField}
              label="Email"
              name={`${invite}.email`}
              disabled={isDisabled}
              error={!!getErrorText('email')}
              helperText={getErrorText('email')}
            />
            <div className={classes.departmentSelect}>
              <AutoComplete
                name={`${invite}.departmentId`}
                label="Department"
                list={departments}
                fieldLabel={'name'}
                fieldValue={'id'}
                disabled={isDisabled}
              />
            </div>
          </>
        ) : (
          <>
            <Field
              className={classes.field}
              component={TextField}
              label="First Name"
              name={`${invite}.firstName`}
            />
            <Field
              className={classes.field}
              component={TextField}
              label="Last Name"
              name={`${invite}.lastName`}
            />
            <Field
              className={classes.emailField}
              component={TextField}
              label="Email"
              name={`${invite}.email`}
            />
          </>
        )}
      </div>

      <div className={classes.actionContainer}>
        {canDelete && (
          <IconButton
            disableRipple
            disabled={disableRemove}
            onClick={removeUser}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {canAddNext && (
          <IconButton disableRipple onClick={addNext}>
            <AddIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default InviteRow;
