import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Drawer, IconButton } from '@mui/material';
import { FaBars as Bars } from 'react-icons/fa';

import { withStyleSheet } from 'shared/theme';
import MenuToggler from './MenuToggler';

const style = ({ palette }) => ({
  root: {
    backgroundImage: palette.dashboard,
    width: '85%',
    'z-index': 999,
    top: 0,
  },
  iconButton: {
    color: palette.common.white,
  },
});

class MenuDrawer extends Component {
  static propTypes = {
    anchor: PropTypes.string,
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    menuOpen: PropTypes.bool,
    onMenuShow: PropTypes.func.isRequired,
    onMenuHide: PropTypes.func.isRequired,
  };

  static defaultProps = {
    anchor: 'right',
    menuOpen: false,
  };

  render() {
    const { anchor, classes, children, onMenuHide, onMenuShow, menuOpen } =
      this.props;

    return (
      <div>
        <MenuToggler>
          <IconButton
            disableRipple
            variant="outlined"
            onClick={onMenuShow}
            size="large"
          >
            <Bars className={classes.iconButton} />
          </IconButton>
        </MenuToggler>
        <Drawer
          classes={{ paper: classes.root }}
          anchor={anchor}
          open={menuOpen}
          onClose={onMenuHide}
        >
          {children}
        </Drawer>
      </div>
    );
  }
}

export default withStyleSheet('MenuDrawer', style)(MenuDrawer);
