import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Paper,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import makeStyles from '@mui/styles/makeStyles';

import Input from '@mui/material/Input';
import clsx from 'clsx';

//components
import { TableListInvoiceTemplate } from 'components/Shared/TableList';
import INVOICE_TEMPLATE_HEADERS from './invoiceTemplateHeadersData';
import InvoiceTemplateTableRow from './InvoiceTemplateTableRow';
import InvoiceTemplateAutoComplete from './InvoiceTemplateAutoComplete';
import InvoiceTemplateAutoCompleteCheckboxes from './InvoiceTemplateAutoCompleteCheckboxes';
import { formatAllowedChars, isRegionCanada } from 'utils/helperFunctions';

import { INPUT_FIELD_CHARACTER_LIMIT } from 'components/Shared/constants';
import { useDidMount } from 'utils/customHooks';
const TEMPLATE_NAME_LENGTH = INPUT_FIELD_CHARACTER_LIMIT.templateName;

export const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
    marginTop: 20,
    overflowX: 'auto',
  },
  empty: {
    textAlign: 'center',
  },
  row: {
    height: '64px',
    color: 'black',
    '& > *': {
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  actionColumn: {
    overflow: 'visible',
    padding: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  actionContainer: {
    display: 'inline-flex',
    padding: 0,
  },
  iconButton: {
    padding: 8,
    fontSize: '1.2em',
    display: 'inline',
    verticalAlign: 'bottom',
    cursor: 'pointer',
  },
  regTimesCircle: {
    fontSize: '1rem',
  },
  removeIcon: {
    color: palette.error.light,
    fontSize: '18px',
  },
  faCheck: {
    color: palette.primary.main,
    fontWeight: 600,
    fontSize: '20px',
  },
  checkmarkDisabled: {
    color: palette.action.disabled,
  },
  errorText: {
    color: palette.error.main,
    fontSize: '0.75rem',
  },
  inputBorderError: {
    '&::after': {
      borderBottom: `solid 2px ${palette.error.main} !important`,
    },
  },
}));

const InvoiceTemplate = props => {
  const classes = useStyles();
  const [viewOnly, setViewOnly] = useState(true);
  const [editTemplate, setEditTemplate] = useState({});
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [uniqueDepartments, setUniqueDepartments] = useState([]);
  const [errorText, setErrorText] = useState('');

  const {
    templates,
    departments,
    producersStartDayWeek,
    deleteTemplate,
    saveTemplateData,
    systemLocations,
    saveInvoiceTemplateOption,
    templateOption,
    storeUpdatedTemplates,
    currentProject,
    locationFuncs,
    locationTypes,
  } = props;

  const {
    countryOptions,
    stateOptions,
    cityOptions,
    // countyOptions,
    // subdivisionsOptions,
  } = locationFuncs;

  let country = countryOptions.filter(
    c => c.name === currentProject?.region || c.code === currentProject?.region,
  )[0];
  country = country?.code === 'US' ? null : country;
  const canadaRegion = isRegionCanada();
  const [templateHeaders, setTemplateHeaders] = useState(
    INVOICE_TEMPLATE_HEADERS,
  );
  useDidMount(() => {
    if (canadaRegion) {
      const newHeaders = templateHeaders.map(field => {
        if (field.columnId === 'locationType' && !field.hidden) {
          return { ...field, hidden: true };
        }
        return field;
      });
      setTemplateHeaders(newHeaders);
    }
  });
  useEffect(() => {
    if (templateOption === 'new') {
      let updatedTemplates = [...templates];
      let newTemp = {
        country: null,
        htgCountryId: null,
        city: null,
        departments: [],
        htgCityId: null,
        htgStateId: null,
        name: null,
        producersStartDayOfWeek: null,
        separateBatches: false,
        state: null,
        locationTypes: null,
        systemLocation: null,
        option: 'add',
      };
      updatedTemplates.unshift(newTemp);
      let selectedDeptIds = [];
      for (let i = 0; i < templates.length; i++) {
        let templateObj = templates[i];
        if (templateObj.departments.length > 0) {
          for (let j = 0; j < templateObj.departments.length; j++) {
            selectedDeptIds.push(templateObj.departments[j]);
          }
        }
      }
      let uniqueDepts = [];

      if (departments.length > 0) {
        for (let i = 0; i < departments.length; i++) {
          let dept = departments[i];
          if (newTemp.departments.length > 0) {
            for (let j = 0; j < newTemp.departments.length; j++) {
              if (dept.id === newTemp.departments[j]) {
                uniqueDepts.push(dept);
              }
            }
          }
          if (selectedDeptIds.includes(dept.id)) {
            continue;
          } else {
            uniqueDepts.push(dept);
          }
        }
      }
      setEditTemplate(newTemp);
      setViewOnly(false);
      setEditRowIndex(0);
      setUniqueDepartments(uniqueDepts);
      storeUpdatedTemplates(updatedTemplates);
      saveInvoiceTemplateOption('edit');
    }
  }, [
    templateOption,
    templates,
    storeUpdatedTemplates,
    departments,
    saveInvoiceTemplateOption,
    // country,
  ]);

  const removeOption = id => {
    let edited = editTemplate;
    let depts = [...edited.departments];

    if (depts.length > 0) {
      const index = depts.findIndex(x => x === id);
      if (index > -1) {
        depts.splice(index, 1);
        edited = { ...edited, departments: depts };
      }
    }
    setEditTemplate(edited);
  };
  const cancelEdit = () => {
    const updated = [...templates];
    const arr = updated.filter(obj => obj?.option !== 'add');
    setViewOnly(true);
    setEditRowIndex(null);
    storeUpdatedTemplates(arr);
    saveInvoiceTemplateOption('');
    setErrorText('');
  };

  const handleOnBlur = e => {
    let edited = editTemplate;
    edited = { ...edited, name: e?.target?.value.trim() };
    setEditTemplate(edited);
  };

  const handleChange = (e, value, field) => {
    let edited = editTemplate;
    if (field === 'name') {
      let filteredTemplates = templates.filter(temp => temp.id !== value);

      let filterObj = filteredTemplates.filter(
        obj => obj.name.toLowerCase() === e?.target?.value.toLowerCase(),
      );
      if (filterObj.length > 0) {
        setErrorText('Name must be unique');
      } else {
        setErrorText('');
      }
      const tempName = formatAllowedChars(e.target.value);
      if (tempName.length <= TEMPLATE_NAME_LENGTH) {
        edited = { ...edited, name: tempName };
      }
    } else if (field === 'htgCountryId') {
      edited = {
        ...edited,
        htgCountryId: value?.value ? value?.id : null,
        htgStateId: null,

        htgCityId: null,
        htgCountyId: null,
        htgSubdivisionId: null,
        city: null,
        state: null,
        county: null,
        subdivision: null,
        country: value,
      };
    } else if (field === 'htgStateId') {
      edited = {
        ...edited,
        htgStateId: value?.value || null,
        htgCityId: null,
        htgCountyId: null,
        htgSubdivisionId: null,
        city: null,
        county: null,
        subdivision: null,
        state: value,
      };
    } else if (field === 'htgCountyId') {
      edited = { ...edited, htgCountyId: value?.value || null, county: value };
    } else if (field === 'htgSubdivisionId') {
      edited = {
        ...edited,
        htgSubdivisionId: value?.value || null,
        subdivision: value,
      };
    } else if (field === 'htgCityId') {
      edited = { ...edited, htgCityId: value?.value || null, city: value };
    } else if (field === 'producersStartDayOfWeek') {
      edited = { ...edited, producersStartDayOfWeek: value?.code || null };
    } else if (field === 'systemLocation') {
      edited = { ...edited, systemLocation: value || null };
    } else if (field === 'separateBatches') {
      edited = { ...edited, separateBatches: e.target.checked };
    } else if (field === 'locationType') {
      edited = { ...edited, locationType: value || null };
    }
    setEditTemplate(edited);
  };

  const handleChangeMultiSelectDepts = (e, values) => {
    let depts = [];
    if (values.length > 0) {
      for (let i = 0; i < values.length; i++) {
        depts.push(values[i].id);
      }
    }
    let edited = editTemplate;
    edited = { ...edited, departments: depts };
    setEditTemplate(edited);
  };

  const editBatchTemplate = (idx, tempData) => {
    let selectedDeptIds = [];
    for (let i = 0; i < templates.length; i++) {
      let templateObj = templates[i];
      if (templateObj.departments.length > 0) {
        for (let j = 0; j < templateObj.departments.length; j++) {
          selectedDeptIds.push(templateObj.departments[j]);
        }
      }
    }
    let uniqueDepts = [];

    if (departments.length > 0) {
      for (let i = 0; i < departments.length; i++) {
        let dept = departments[i];
        if (tempData.departments.length > 0) {
          for (let j = 0; j < tempData.departments.length; j++) {
            if (dept.id === tempData.departments[j]) {
              uniqueDepts.push(dept);
            }
          }
        }
        if (selectedDeptIds.includes(dept.id)) {
          continue;
        } else {
          uniqueDepts.push(dept);
        }
      }
    }
    saveInvoiceTemplateOption('edit');
    setUniqueDepartments(uniqueDepts);
    setViewOnly(false);
    setEditRowIndex(idx);
    setEditTemplate(tempData);
  };

  let contentRows = null;
  if (templates && templates.length <= 0) {
    contentRows = (
      <TableRow>
        <TableCell colSpan={4} className={classes.empty}>
          <Typography variant="h6">{'No batch templates found'}</Typography>
        </TableCell>
      </TableRow>
    );
  } else {
    contentRows = templates.map((template, index) => {
      return !viewOnly && editRowIndex === index ? (
        <TableRow key={template.id} className={classes.row}>
          <TableCell>
            <Input
              className={clsx({ [classes.inputBorderError]: errorText })}
              value={editTemplate?.name || ''}
              placeholder="Name *"
              onChange={e => handleChange(e, editTemplate.id, 'name')}
              onBlur={handleOnBlur}
              required
            />
            <br />
            <span className={classes.errorText}>{errorText || ''}</span>
          </TableCell>
          <TableCell>
            <InvoiceTemplateAutoCompleteCheckboxes
              uniqueDepartments={uniqueDepartments}
              editTemplate={editTemplate}
              departments={departments}
              removeOption={removeOption}
              handleChangeMultiSelectDepts={handleChangeMultiSelectDepts}
            />
          </TableCell>
          <TableCell>
            <Checkbox
              checked={editTemplate.separateBatches}
              onChange={e =>
                handleChange(e, editTemplate.id, 'separateBatches')
              }
            />
          </TableCell>
          {!canadaRegion && (
            <TableCell>
              <InvoiceTemplateAutoComplete
                field="locationType"
                editTemplate={editTemplate}
                handleChange={handleChange}
                options={locationTypes}
                locationFuncs={locationFuncs}
                showLoadingMsg
              />
            </TableCell>
          )}
          {/* <TableCell>
            <InvoiceTemplateAutoComplete
              field="htgCountryId"
              editTemplate={editTemplate}
              handleChange={handleChange}
              options={countryOptions}
              locationFuncs={locationFuncs}
              country={country}
              showLoadingMsg
            />
          </TableCell> */}
          <TableCell>
            <InvoiceTemplateAutoComplete
              field="htgStateId"
              editTemplate={editTemplate}
              handleChange={handleChange}
              options={stateOptions}
              locationFuncs={locationFuncs}
              countryId={editTemplate?.country?.id || country?.id}
              showLoadingMsg
            />
          </TableCell>
          {/* <TableCell>
            <InvoiceTemplateAutoComplete
              field="htgCountyId"
              editTemplate={editTemplate}
              handleChange={handleChange}
              options={countyOptions}
              locationFuncs={locationFuncs}
              stateId={editTemplate?.state?.id}
              showLoadingMsg
            />
          </TableCell> */}
          {/* <TableCell>
            <InvoiceTemplateAutoComplete
              field="htgSubdivisionId"
              editTemplate={editTemplate}
              handleChange={handleChange}
              options={subdivisionsOptions}
              locationFuncs={locationFuncs}
              stateId={editTemplate?.state?.id}
              showLoadingMsg
            />
          </TableCell> */}
          <TableCell>
            <InvoiceTemplateAutoComplete
              field="htgCityId"
              editTemplate={editTemplate}
              handleChange={handleChange}
              options={cityOptions}
              locationFuncs={locationFuncs}
              stateId={editTemplate?.state?.id}
              showLoadingMsg
            />
          </TableCell>
          <TableCell>
            <InvoiceTemplateAutoComplete
              field="producersStartDayOfWeek"
              options={producersStartDayWeek}
              editTemplate={editTemplate}
              handleChange={handleChange}
              locationFuncs={locationFuncs}
            />
          </TableCell>
          <TableCell>
            <InvoiceTemplateAutoComplete
              field="systemLocation"
              options={systemLocations}
              editTemplate={editTemplate}
              handleChange={handleChange}
              locationFuncs={locationFuncs}
            />
          </TableCell>
          <TableCell className={classes.actionColumn}>
            <div className={classes.actionContainer}>
              <IconButton
                disabled={
                  !editTemplate?.name ||
                  !editTemplate?.producersStartDayOfWeek ||
                  !!errorText
                }
                className={classes.iconButton}
                onClick={() => saveTemplateData(editTemplate)}
                size="large"
              >
                <span
                  className={clsx(classes.faCheck, {
                    [classes.checkmarkDisabled]:
                      !editTemplate?.name ||
                      !editTemplate?.producersStartDayOfWeek ||
                      !!errorText,
                  })}
                >
                  &#10003;
                </span>
              </IconButton>
              <IconButton
                className={classes.iconButton}
                onClick={cancelEdit}
                size="large"
              >
                <span className={classes.removeIcon}>X</span>
              </IconButton>
            </div>
          </TableCell>
        </TableRow>
      ) : (
        <InvoiceTemplateTableRow
          templateOption={templateOption}
          key={template?.id}
          departments={departments}
          template={template}
          index={index}
          editBatchTemplate={editBatchTemplate}
          deleteTemplate={deleteTemplate}
          canadaRegion={canadaRegion}
        />
      );
    });
  }

  return (
    <Paper className={classes.root}>
      <TableListInvoiceTemplate headers={templateHeaders}>
        {contentRows}
      </TableListInvoiceTemplate>
    </Paper>
  );
};
export default InvoiceTemplate;
