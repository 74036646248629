import React, { useState } from 'react';

import { Button } from '@mui/material/';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';

//components
import withSearchInvoicesFilter from './withSearchInvoicesFilter';
import FilterDropdown from './FilterDropdown';

const useStyles = makeStyles(({ palette }) => {
  return {
    root: {
      width: 273,
      marginLeft: 24,
      marginTop: 24,
    },
    controls: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 15,
    },
    filters: {
      width: 253,
      maxHeight: '67vh',
      overflowY: 'auto',
      overflowX: 'hidden',
      // boxShadow: '0px 2px 8px 2px rgb(82 82 82 / 10%)',
    },
    filter: {
      width: 253,
      overflowX: 'hidden',
      borderTop: `1px ${palette.primary['+7']} solid`,
      borderCollapse: 'collapse',
      '&:last-child': {
        borderBottom: 0,
      },
    },
    filterBtns: {
      color: palette.info.light,
      margin: 0,
      paddingLeft: 0,
    },
    title: {
      color: palette.gray.main,
      fontSize: 24,
    },
    subTitle: {
      color: palette.gray.main,
      fontSize: 14,
      marginBottom: 24,
      marginTop: 4,
    },
  };
});

const IdFilter = withSearchInvoicesFilter({
  filterName: 'invoiceId',
})(FilterDropdown);

const WeekendingFilter = withSearchInvoicesFilter({
  filterName: 'invoiceWeekendingDate',
})(FilterDropdown);

// Mapping of Status to sent value: as of 03/23/2021
//   { '0': 'Incomplete' },
//   { '1': 'Submitted for Approval' },
//   { '2': 'Ready for Review' },
//   { '3': 'Submitted to C&C' },
//   { '4': 'Processed' },
//   { '5': 'Rejected' },
const DescriptionFilter = withSearchInvoicesFilter({
  filterName: 'invoiceDescription',
})(FilterDropdown);

const TypeFilter = withSearchInvoicesFilter({
  filterName: 'invoiceType',
})(FilterDropdown);

const EditsSentFilter = withSearchInvoicesFilter({
  filterName: 'invoiceEditsSent',
})(FilterDropdown);

const FutureReleaseDateFilter = withSearchInvoicesFilter({
  filterName: 'futureReleaseDate',
})(FilterDropdown);

const SearchSidebar = props => {
  const { onClearFilters } = props;
  const classes = useStyles();
  const [expandAll, setExpansion] = useState(false);

  function toggleExpansion() {
    setExpansion(!expandAll);
  }

  return (
    <div className={classes.root}>
      <div>
        <section className={clsx('pendo_invoice_label', classes.title)}>
          Invoices
        </section>
        <section className={classes.subTitle}>
          Use the filters below to customize your search results.
        </section>
        <div className={classes.controls}>
          <Button
            variant="text"
            color="primary"
            className={classes.filterBtns}
            onClick={() => {
              setExpansion(false);
              onClearFilters();
            }}
          >
            <span>Clear filters</span>
          </Button>
          <Button
            variant="text"
            color="primary"
            className={classes.filterBtns}
            onClick={toggleExpansion}
          >
            <span>{expandAll ? 'Collapse' : 'Expand'} All</span>
          </Button>
        </div>
        <main className={classes.filters}>
          <section className={classes.filter}>
            <IdFilter
              canSelectAll
              label={`ID`}
              enableSearch
              expand={expandAll}
              variant={'searchTimecards'}
            />
          </section>
          <section className={classes.filter}>
            <WeekendingFilter
              label={`Week Ending`}
              enableSearch
              canSelectAll
              expand={expandAll}
              variant={'searchTimecards'}
            />
          </section>
          <section className={classes.filter}>
            <TypeFilter
              label={`Type`}
              canSelectAll
              enableSearch
              expand={expandAll}
              variant={'searchTimecards'}
            />
          </section>
          <section className={classes.filter}>
            <DescriptionFilter
              label={`Description`}
              enableSearch
              canSelectAll
              expand={expandAll}
              variant={'searchTimecards'}
            />
          </section>

          <section className={classes.filter}>
            <EditsSentFilter
              label={`Edits Last Sent`}
              canSelectAll
              enableSearch
              expand={expandAll}
              variant={'searchTimecards'}
            />
          </section>

          <section className={classes.filter}>
            <FutureReleaseDateFilter
              label={`Future Release Date`}
              canSelectAll
              enableSearch
              expand={expandAll}
              variant={'searchTimecards'}
            />
          </section>
        </main>
      </div>
    </div>
  );
};

export default SearchSidebar;
