import React from 'react';
// import NotFound from 'components/NotFound';
import { NavTo } from 'containers/Employees/Timecards';

import {
  FullWidthLayout,
  Header,
  ProjectHeader,
  AlertHeader,
} from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    authenticated
    key="/projects/navto/timecard"
    id="/projects/navto/timecard"
    path="/projects/:projectId/navto/timecard"
    layout={FullWidthLayout}
    headers={[Header, ProjectHeader, AlertHeader]}
    component={NavTo}
  />
);
