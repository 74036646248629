export const defaultTimecard = {
  startsOn: '',
  endsOn: '',
  htgStateId: '', // hash id
  state: {},
  htgCityId: '', // hash id
  city: {},
  dealMemo: {},
  union: {},
  user: {},
  createdBy: {},
  status: 'incomplete',
  approvalLevel: null,
  batchId: null,
  totalAllowances: 0,
  activeApprovalFlowId: null,
  projectId: null,
  //department /me call
  departmentId: null,
  departmentName: '',
  rejectionWorkflow: 'standard',
  previouslyRejected: null,
  userAccountCode: '',
  esignature: null,
  // me
  entryHeaderId: '', // hash id
  meals: 1,
  freeFields: null,
  isExempt: false,
  createdDate: null,
  emergencyType: null,
  days: [],
  notes: [],
  allowances: [],
};

export const defaultDay = {
  id: null,
  htgDayTypeId: null,
  date: '',
  call: null,
  meal1In: null,
  meal1Out: null,
  meal2In: null,
  meal2Out: null,
  meal3In: null,
  meal3Out: null,
  wrap: null,
  rerate: null,
  accountCode: null,
  episode: null,
  location: null,
  insurance: null,
  series: null,
  set: null,
  freeFields1: null,
  freeFields2: null,
  freeFields3: null,
  freeFields4: null,
  onProduction: null,
  locationType: null,
  htgLocationTypeId: null,
  htgStateId: null,
  state: null,
  htgCityId: null,
  city: null,
  splits: [],
  dayType: null,
  workSubdivision: null,
  occupationCode: null,
};

export const defaultAllowance = {};

export const defaultNotes = {};
