import React from 'react';
import { Typography } from '@mui/material';
import { connect } from 'react-redux';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
// actions
import * as actions from 'actions/wtc';
import { hide as hideModal } from 'actions/modalDialog';
//selectors
import { getActionType } from 'selectors/wtc';

class ProducerWWAlert extends React.Component {
  render() {
    const { actionType, onSaveTimecard, onApprovalWithoutChange, onModalHide } =
      this.props;

    const title = <div>{"Changing Producer's Week"}</div>;

    const content = (
      <div>
        <Typography color="inherit">
          {
            'Changing Producer’s Week will update the Producer’s Week for the current timecard and the batch, and any new timecards added to the batch.'
          }{' '}
          <br />
        </Typography>
      </div>
    );

    return (
      <ConfirmModal
        title={title}
        content={content}
        buttonText="Proceed"
        buttonType="default"
        cancelText="Cancel"
        hasSubmitAction={true}
        onModalHide={onModalHide}
        onSubmit={() => {
          if (actionType === 'save') {
            onSaveTimecard();
          } else if (actionType === 'submit') {
            onApprovalWithoutChange();
            onModalHide();
          }
        }}
      />
    );
  }
}

const mapState = state => ({
  actionType: getActionType(state),
});

const mapDispatch = (dispatch, { match }) => ({
  onSaveTimecard: () => {
    dispatch(hideModal({ dialog: 'ProducerWWWarning' }));
    dispatch(actions.saveTimecard());
  },
  onModalHide: () => {
    dispatch(hideModal({ dialog: 'ProducerWWWarning' }));
  },
});

export default withModalDialog({
  dialog: 'ProducerWWWarning',
})(connect(mapState, mapDispatch)(ProducerWWAlert));
