import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';

const ConfirmDeleteBtn = props => {
  const {
    delay = 1000,
    onClick: onDelete,
    isDeleting,
    disabled: isDisabled,
    ...rest
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRender = {}; //used to measure when comp re-renders this will be a new obj

  const [btnState, setBtnState] = React.useState('initial'); // initial, delay, confirm,deleting
  const [progress, setProgress] = React.useState(0);

  let timeoutRef = React.useRef(null);
  let intervalRef = React.useRef(null);

  const reset = React.useCallback(() => {
    clearTimeout(timeoutRef.current);
    clearInterval(intervalRef.current);
    setProgress(0);
  }, []);

  React.useEffect(() => {
    if (btnState === 'delay') {
      intervalRef.current = setInterval(() => {
        setProgress(prev => prev + 200);
      }, 200);
      timeoutRef.current = setTimeout(() => {
        reset();
        setBtnState('confirm');
      }, delay);

      return () => {
        clearTimeout(timeoutRef.current);
        clearInterval(intervalRef.current);
      };
    } else if (btnState === 'confirm') {
      if (renderCountRef.current === 0) {
        renderCountRef.current = 1;
      }
    } else if (btnState === 'initial') {
      renderCountRef.current = 0;
      reset();
    }
  }, [delay, onDelete, reset, btnState]);

  const renderCountRef = React.useRef(0);
  React.useEffect(() => {
    //reset the state when the component renders the 2nd time after
    //going to confirm status
    if (btnState === 'confirm') {
      if (renderCountRef.current === 2) {
        setBtnState('initial');
        reset();
        renderCountRef.current = 0;
      } else if (renderCountRef.current === 1) {
        renderCountRef.current = 2;
      }
    }
  }, [btnState, reset, onRender]);

  const handleDelete = React.useCallback(() => {
    if (btnState === 'confirm') {
      setBtnState(isDeleting === undefined ? 'initial' : 'deleting');
      reset();
      onDelete();
    }
  }, [isDeleting, onDelete, reset, btnState]);

  const normalize = value => (value * 100) / delay;

  let variant, startIcon, disabled, onClick, text;
  switch (btnState) {
    case 'initial':
      variant = 'outlined';
      startIcon = <DeleteIcon />;
      onClick = () => setBtnState('delay');
      text = 'Delete';
      break;
    case 'delay':
      variant = 'contained';
      startIcon = (
        <CircularProgress
          sx={{ color: 'error.contrastText' }}
          size={16}
          variant="determinate"
          value={normalize(progress)}
        />
      );
      disabled = true;
      text = 'Confirm?';
      break;
    case 'confirm':
      variant = 'contained';
      startIcon = <DeleteIcon />;
      disabled = false;
      onClick = handleDelete;
      text = 'Confirm?';
      break;
    case 'deleting':
      variant = 'contained';
      startIcon = (
        <CircularProgress
          sx={{ color: 'error.contrastText' }}
          size={16}
          variant="indeterminate"
        />
      );
      disabled = true;
      text = 'Deleting...';
      break;

    default:
      break;
  }

  return (
    <Button
      color="error"
      variant={variant}
      disabled={disabled || isDisabled}
      startIcon={startIcon}
      onClick={onClick}
      {...rest}
    >
      {text}
    </Button>
  );
};

const propShouldBeUndefined = (props, propName, componentName) => {
  const data = props[propName];

  if (data !== undefined) {
    return new Error(
      `Undefined ${propName} should be undefined on ${componentName} to work properly`,
    );
  }
};

ConfirmDeleteBtn.propTypes = {
  variant: propShouldBeUndefined,
  startIcon: propShouldBeUndefined,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  delay: PropTypes.number,
  isDeleting: PropTypes.bool,
};

export default ConfirmDeleteBtn;
