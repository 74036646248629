import React, { useContext, useLayoutEffect } from 'react'; // forwardRef
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { EditableRowsContext as BulkEditEditableContext } from '../../../containers/Employees/Reviews/BulkEdit/BulkEditContext';
import { CrewListContext } from 'feature/CrewList/CrewListContext';

// import { copyToAll } from 'utils/bulkEditUtils';

const style = theme => ({
  copyToAllBtn: {
    borderRadius: 10,
    padding: '4px',
    backgroundColor: theme.palette.primary.main,
    border: '1px solid',
    zIndex: 5,
    '&:hover': {
      backgroundColor: theme.palette.gray['+8'],
    },
  },

  copyToAllIcon: {
    color: theme.palette.primary.contrastText,
  },
});

const CopyToAll = props => {
  const {
    classes,
    variant = 'bulkEdit',
    value,
    columnId,
    //bulkEdit props
    isDropDown = false,
    onBlur,
    rowCount,
    triggerCTA = false,
    //dtsProps
    copyToAll: copyToAllProp,
    editableCounts: editableCountsProp,
    containerRef: containerRefProp,
  } = props;

  const contextInUse =
    variant === 'crewList' ? CrewListContext : BulkEditEditableContext;

  const context = useContext(contextInUse);

  let { editableCounts, showErrors, containerRef } = context
    ? context
    : variant === 'dts'
    ? {
        editableCounts: editableCountsProp,
        containerRef: containerRefProp,
      }
    : {
        editableCounts: undefined,
        showErrors: false,
        copyToAll: undefined,
        containerRef: undefined,
      };

  const ctaRef = React.useRef(null);
  const copyToAll = variant === 'bulkEdit' ? context.copyToAll : copyToAllProp;

  /** BULK EDIT CTA TRIGGER */
  // Lightweight way to trigger CTA for bulk without passing func to input comp
  // Fires CopyToAll when triggerCTA goes from false to truthy (can be object)
  const prevTriggerCTA = React.useRef(triggerCTA);
  const didTriggerCTA = React.useRef(false);

  React.useEffect(() => {
    if (
      didTriggerCTA.current === false &&
      prevTriggerCTA.current === false &&
      triggerCTA
    ) {
      didTriggerCTA.current = true;
    }

    prevTriggerCTA.current = triggerCTA;
  }, [triggerCTA]);

  React.useEffect(() => {
    if (didTriggerCTA.current && variant === 'bulkEdit') {
      didTriggerCTA.current = false;

      const ctaVal = triggerCTA === true ? value : triggerCTA;

      copyToAll(columnId, ctaVal, showErrors);
    }
  }, [columnId, copyToAll, showErrors, triggerCTA, value, variant]);

  /**END BULK EDIT CTA TRIGGER */

  if (variant === 'crewList') {
    editableCounts = rowCount;
  }

  useLayoutEffect(() => {
    if (ctaRef && containerRef) {
      const tableBtm = containerRef.current.getBoundingClientRect().bottom;
      const ctaBtm = ctaRef.current.getBoundingClientRect().bottom;

      const distance = tableBtm - ctaBtm;
      if (distance <= 8) {
        // containerRef.current.scrollBy({ top: 45, left: 0, behavior: 'smooth' });
        ctaRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  }, [containerRef]);
  return (
    <main>
      <Tooltip
        title={
          <div>
            Copy to All {editableCounts ? ` ${editableCounts}` : ''} Rows
            <br />
            {variant !== 'crewList' && <span>(Ctrl + Shift + A)</span>}
          </div>
        }
      >
        <IconButton
          ref={ctaRef}
          id="CopyToAllBtn"
          tabIndex={-1}
          color="primary"
          variant="contained"
          className={classes.copyToAllBtn}
          onMouseDown={e => {
            if (isDropDown && variant === 'bulkEdit') {
              onBlur();
              clearTimeout(window.TimeoutId);
            }
            if (copyToAll) {
              if (variant === 'crewList' || variant === 'dts') {
                copyToAll();
              } else {
                copyToAll(columnId, value, showErrors);
              }
            }
          }}
          //Tablets
          onTouchStart={e => {
            e.preventDefault();
            if (isDropDown && variant === 'bulkEdit') {
              onBlur();
              clearTimeout(window.TimeoutId);
            }
            if (copyToAll) {
              if (variant === 'crewList' || variant === 'dts') {
                copyToAll();
              } else {
                copyToAll(columnId, value, showErrors);
              }
            }
          }}
        >
          <FileCopyIcon className={classes.copyToAllIcon} fontSize="small" />{' '}
        </IconButton>
      </Tooltip>
    </main>
  );
};

CopyToAll.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['bulkEdit', 'dts', 'crewList']),
  columnId: PropTypes.string,
  isDropDown: PropTypes.bool,
  onBlur: PropTypes.func,
  rowCount: PropTypes.number,
  triggerCTA: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  copyToAll: PropTypes.func,
  editableCounts: PropTypes.number,
  containerRef: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default withStyles(style)(CopyToAll);
