import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import moment from 'moment';
import {
  Button,
  Box,
  Tooltip,
  Typography,
  CircularProgress,
  LinearProgress,
  styled,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { projectProps } from 'components/props/projects';

//redux
import * as actions from 'actions/digitalEdits';

import * as sel from 'selectors/digitalEdits';
import { getCurrentProject } from 'selectors/project';

import DataGridTable from 'feature/DigitalEdits/AceTable/DataGridTable';
import AceCell from 'feature/DigitalEdits/AceTable/AceCell';
import AceEditCell from 'feature/DigitalEdits/AceTable/AceEditCell';
import AceSelectCell from 'feature/DigitalEdits/AceTable/AceSelectCell';

import JumpToTitle from '../JumpToTitle';

import { useGridApiRef, gridDateComparator } from '@mui/x-data-grid-pro';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import AceValidator from './AceValidator';

import {
  DATE_TABLE,
  RecordBreakdownHeaders,
  isRowReadOnly,
  getChildAlloNumber,
  initUnsavedChangesRef,
  applyUnsavedChangesRefToData,
  getCurrentRow,
  createErrorMessage,
  diffCurrency,
  NEW_ALLOWANCE_TAG,
  getTcHeadersFromRecordId,
} from 'feature/DigitalEdits/digitalEditsUtils';
import { updateUnsavedChanges, db } from './DataGridTableUtils';

import { useWillUnmount, useDidMount } from 'utils/customHooks';

const useStyles = makeStyles(({ palette }) => ({
  AceTable: {
    marginTop: 12,
    width: 'fit-content',
    padding: 18,
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
  },

  editableCell: {
    backgroundColor: palette.misc.cellHighlight,
  },
  allowanceChildCell: {
    backgroundColor: palette.misc.cellHighlightLight,
  },
  errorCell: {
    backgroundColor: `${palette.error.main}`,
  },
  disabledRow: {
    '& .MuiInput-root': {
      color: palette.text.disabled,
    },
    '& .MuiIconButton-root': {
      color: palette.text.disabled,
    },
    color: palette.text.disabled,
    pointerEvents: 'none',
  },
}));

const MsgBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '3px',
  marginLeft: '16px',
}));

const WarningMsgBox = styled(MsgBox)(({ theme }) => ({
  color: theme.palette.warning.main,
}));

const ErrorMsgBox = styled(MsgBox)(({ theme }) => ({
  color: theme.palette.error.main,
}));
const TABLE_NAME = 'Daily Breakdown';

const mapState = (state, ownProps) => {
  const { recordId } = ownProps;

  return {
    acEditRecord: sel.getAcEditRecord(state),
    episodes: sel.getEpisodes(state),
    unsavedEdits: sel.getUnsavedRecordEdits(state, recordId),
    batchesOpened: sel.getBatchesOpened(state),
    editFieldsLoading: sel.getEditFieldsLoading(state),
    savingACE: sel.getSavingACE(state),
    hasPendingTimecard: sel.getHasPendingTimecards(state, recordId),
    hasUnsavedError: sel.getHasUnsavedErrors(state, recordId),
    hasTimeoutError: sel.getHasTimeoutErrors(state, recordId),
    hasErrors: sel.getHasErrors(state, recordId),
    currentProject: getCurrentProject(state),
    hideEmptyCols: sel.getHideEmptyCols(state),
    viewEnabled: sel.getDataCardVisible(state, TABLE_NAME),
    triggerSplitRevert: sel.getTriggerSplitRevert(state),
  };
};

const mapDispatch = dispatch => ({
  onEditFields: recordId => {
    dispatch(actions.editAcFields({ recordId }));
  },
  onClearEditRecord: () =>
    dispatch(actions.setAcEditRecord({ acEditRecord: '' })),
  onSaveACE: ({ recordId, data }) =>
    dispatch(actions.saveACE({ data, recordId })),
  onStoreUnsavedEdits: ({ unsavedChangesRef, recordId }) => {
    const edits = unsavedChangesRef?.current;
    dispatch(
      actions.storeUnsavedEdits({
        unsavedEdits: JSON.stringify(edits),
        recordId,
      }),
    );
  },
});

const AceTable = props => {
  const classes = useStyles();
  const apiRef = useGridApiRef();
  let {
    distributions,
    acEditRecord,
    unsavedEdits,
    onClearEditRecord,
    onEditFields,
    onSaveACE,
    onStoreUnsavedEdits,
    recordId,
    episodes,
    invoiceStatus,
    batchesOpened,
    editFieldsLoading,
    savingACE,
    hasPendingTimecard,
    currentProject,
    hasUnsavedError,
    hasTimeoutError,
    hasErrors,
    hideEmptyCols,
    viewEnabled,
    triggerSplitRevert,
  } = props;

  let { episodeRequired } = currentProject;

  episodeRequired =
    episodeRequired === 'Y'
      ? true
      : episodeRequired === 'N'
      ? false
      : typeof episodeRequired === 'boolean'
      ? !!episodeRequired
      : false;

  const canEditFields = invoiceStatus === 'W' || invoiceStatus === 'E';

  const [editable, setEditable] = React.useState(
    batchesOpened && acEditRecord === recordId,
  );

  React.useEffect(() => {
    if (batchesOpened && acEditRecord === recordId && !editable) {
      setEditable(true);
    } else if (editable) {
      if (!batchesOpened || acEditRecord !== recordId) setEditable(false);
    }
  }, [acEditRecord, batchesOpened, editable, recordId]);

  const Validator = React.useMemo(() => {
    const v = new AceValidator(currentProject);
    return v;
  }, [currentProject]);

  // data is the state of the table when comp was mounted.
  // Could be in progress edit.
  // use unsavedChangesRef to get proper current state of the table data.
  const [data, setData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const unsavedChangesRef = React.useRef({});

  const [validationErrors, setValidationErrors] = React.useState(null);

  const validate = React.useCallback(() => {
    Validator.validate(unsavedChangesRef, data);
    const errors = unsavedChangesRef?.current?.errors || {};

    if (_.isEmpty(errors) === false) {
      setValidationErrors(createErrorMessage(errors));
    } else {
      setValidationErrors(null);
    }
  }, [Validator, data]);

  useDidMount(() => {
    if (unsavedEdits) {
      const unsavedChanges = JSON.parse(unsavedEdits);
      unsavedChangesRef.current = unsavedChanges;
      if (editable) {
        validate();
      }
    }
  });

  useWillUnmount(() => {
    onStoreUnsavedEdits({ unsavedChangesRef, recordId });
  });

  // keep unsavedChangesRef.current up to date with unsavedEdits
  // unsaved edits only gets updated when the user clicks save or the component unmounts
  const unsavedEditsChangedRef = React.useRef(false);
  React.useEffect(() => {
    unsavedEditsChangedRef.current = true;
  }, [unsavedEdits]);

  //This handles resetting the unsavedChangesRef when the save is successful
  React.useEffect(() => {
    if (unsavedEditsChangedRef.current === true) {
      db('UPDATE UNSAVED FROM REDUX');
      unsavedEditsChangedRef.current = false;
      const unsavedChanges = unsavedEdits ? JSON.parse(unsavedEdits) : {};
      unsavedChangesRef.current = unsavedChanges;
      validate();
    }
  }, [unsavedEdits, validate]);

  const handleSave = React.useCallback(
    e => {
      db('handleSave');
      db(unsavedChangesRef.current);
      e.stopPropagation();

      const unsaved = unsavedChangesRef.current.unsaved || {};
      validate();
      const errors = unsavedChangesRef.current?.errors || {};
      if (_.isEmpty(errors) === false) {
        console.error('Cannot save with validation errors');
        return;
      }

      if (_.isEmpty(unsaved) === false) {
        db('unsaved: ', unsaved);
        onStoreUnsavedEdits({ unsavedChangesRef, recordId });

        setData(prevData => {
          db('prevData: ', prevData);
          const newData = prevData.slice();
          const newUnsaved = [];
          const savedDates = {};
          Object.keys(unsaved).forEach(id => {
            let isDuplicateRow = false;
            const dataRow = newData.find(r => r.id === id);
            const { earnedDate, ccTimecardNumber } = dataRow;
            const primaryRowKey = `${earnedDate}-${ccTimecardNumber}`;
            //only need to save one row per date,excluding allowances
            if (!dataRow.isAllowance) {
              if (savedDates[primaryRowKey]) {
                isDuplicateRow = true;
              } else {
                savedDates[primaryRowKey] = true;
              }
            }

            const row = {
              earnedDate: dataRow.earnedDate,
              ccTimecardNumber: dataRow.ccTimecardNumber,
              timecardEntryHeaderId: dataRow.timecardEntryHeaderId,
              isDuplicateRow,
              isAllowance: dataRow.isAllowance,
              id,
            };
            if (dataRow.isAllowance) {
              row.htgTimecardDistributionId = dataRow.htgTimecardDistributionId;
            }
            Object.keys(unsaved[id]).forEach(field => {
              dataRow[field] = unsaved[id][field];
              row[field] = unsaved[id][field];
            });

            newUnsaved.push(row);
          });
          db('newUnsaved: ', newUnsaved);
          onSaveACE({ data: newUnsaved, recordId });

          return newData;
        });
      }
    },
    [onSaveACE, onStoreUnsavedEdits, recordId, validate],
  );

  const handleRevert = React.useCallback(
    e => {
      e.stopPropagation();
      setData(d => {
        let newData = _.cloneDeep(d);
        //remove child rows
        newData = newData.filter(r => !r.isChildRow);
        const original = unsavedChangesRef?.current?.original;
        if (original) {
          Object.keys(original).forEach(id => {
            if (id.includes(NEW_ALLOWANCE_TAG)) return;
            const originalRow = original[id];
            const dataRow = newData.find(r => r.id === id);
            Object.keys(originalRow).forEach(field => {
              dataRow[field] = originalRow[field];
            });
          });
        }

        unsavedChangesRef.current = {};
        return newData;
      });
      onClearEditRecord();
      validate();
    },
    [onClearEditRecord, validate],
  );

  const revertSplits = React.useCallback(
    headerId => {
      setData(d => {
        let newData = _.cloneDeep(d);
        //remove child rows
        newData = newData.filter(
          r => !(r.isChildRow && r.timecardEntryHeaderId === headerId),
        );
        const original = unsavedChangesRef?.current?.original;
        const unsaved = unsavedChangesRef?.current?.unsaved;
        if (original) {
          Object.keys(original).forEach(id => {
            const originalRow = original[id];
            if (
              id.includes(NEW_ALLOWANCE_TAG) &&
              originalRow.timecardEntryHeaderId === headerId
            ) {
              delete unsaved[id];
              delete original[id];
              return;
            }

            const dataRow = newData.find(r => r.id === id);
            if (
              dataRow.isAllowance &&
              dataRow.timecardEntryHeaderId === headerId
            ) {
              delete unsaved[id];
              Object.keys(originalRow).forEach(field => {
                dataRow[field] = originalRow[field];
              });
            }
          });
        }

        return newData;
      });
      validate();
    },
    [validate],
  );

  const triggerSplitRevertRef = React.useRef(false);

  React.useEffect(() => {
    const headerIds = getTcHeadersFromRecordId(recordId);

    if (triggerSplitRevert && headerIds.includes(triggerSplitRevert)) {
      triggerSplitRevertRef.current = true;
    }
  }, [recordId, triggerSplitRevert]);

  React.useEffect(() => {
    if (triggerSplitRevertRef.current) {
      triggerSplitRevertRef.current = false;
      revertSplits(triggerSplitRevert);
    }
  }, [revertSplits, triggerSplitRevert]);

  React.useEffect(() => {
    if (editable) {
      validate();
    }
  }, [data, editable, validate]);

  React.useEffect(() => {
    db('DATA DATA DATA update');
    const data = distributions.slice();

    data.sort((a, b) => {
      const aDate = new Date(a.earnedDate || 1999999999999);
      const bDate = new Date(b.earnedDate || 1999999999999);

      if (aDate < bDate) {
        return -1;
      }
      if (aDate > bDate) {
        return 1;
      }
      if (a.isAllowance && b.isAllowance) {
        if (a.paycode < b.paycode) {
          return -1;
        } else if (a.paycode < b.paycode) {
          return 1;
        }
      }
      return 0;
    });

    const idCounts = {};
    const ccIds = [];
    const headerIds = [];
    const seenDates = {};
    const parsedData = data.map((d, i) => {
      const { episode, earnedDate, ccTimecardNumber, isAllowance } = d;

      const primaryRowKey = `${earnedDate}-${ccTimecardNumber}`;

      let fullEpisode;
      if (typeof episode !== 'string' && episode !== null) {
        db('Episode not a string');
        fullEpisode = episode;
      } else {
        fullEpisode = episodes.find(e => e.code === episode) || null;
      }

      // create unique row id based on tcHeader+ccTimecardNumber
      // this allows us to delete rows by ccTimecardNumber and have
      // unsaved changes persist properly
      if (!ccIds.includes(ccTimecardNumber)) {
        ccIds.push(ccTimecardNumber);
      }
      if (!headerIds.includes(d.timecardEntryHeaderId)) {
        headerIds.push(d.timecardEntryHeaderId);
      }

      const ccIndex = ccIds.findIndex(cc => cc === ccTimecardNumber);
      const headerIndex = headerIds.findIndex(
        header => header === d.timecardEntryHeaderId,
      );

      const headerPlusCC = `h${headerIndex}cc${ccIndex}`;
      if (idCounts[headerPlusCC] === undefined) {
        idCounts[headerPlusCC] = 0;
      } else {
        idCounts[headerPlusCC] += 1;
      }
      const count = idCounts[headerPlusCC];

      const rowId = `${count}_${headerPlusCC}_ACE`;

      let isPrimaryRow = false;

      if (!isAllowance && !seenDates[primaryRowKey] && !isRowReadOnly(d)) {
        isPrimaryRow = true;
      }

      const parsed = {
        id: rowId,
        ...d,
        episode: fullEpisode,
        isPrimaryRow,
        isAllowance,
        isEditable: isPrimaryRow || isAllowance,
        subRowIds: [],
      };

      if (isPrimaryRow) {
        seenDates[primaryRowKey] = parsed.subRowIds;
      } else if (!isRowReadOnly(d) && !isAllowance) {
        const parent_subRowIds = seenDates[primaryRowKey];
        parent_subRowIds.push(rowId);
      }

      return parsed;
    });

    if (unsavedChangesRef.current) {
      db('applying unsavedChangesRef.current to data');
      db(unsavedChangesRef.current);
    }
    applyUnsavedChangesRefToData(unsavedChangesRef, parsedData);

    setData(parsedData);
  }, [distributions, episodes]);

  const isCellEditable = React.useCallback(
    params => {
      const api = apiRef.current;
      const { id, colDef } = params;
      if (!api) return false;

      if (api.editable === false) return false;

      const { unsavedChangesRef } = api;

      const { editable: columnEditable, allowanceEditable } = colDef;

      let row = getCurrentRow(id, unsavedChangesRef);
      if (_.isEmpty(row)) {
        row = api.getRow(id);
      }
      const { parentRowId, subRowIds, isAllowance, isPrimaryRow } = row;

      const isInAllowanceGroup = !!(!!parentRowId || subRowIds?.length > 0);

      if (isAllowance && allowanceEditable) {
        if (isInAllowanceGroup) {
          return true;
        }
        return false;
      } else {
        if (allowanceEditable) {
          return false;
        }
        if (!isPrimaryRow && !isAllowance) return false;
      }
      return columnEditable;
    },
    [apiRef],
  );

  React.useEffect(() => {
    const initColumns = RecordBreakdownHeaders.map((header, i) => {
      const colDef = {
        field: header.accessor,
        headerName: header.label,
        headerClassName:
          editable && header.editable ? classes.editableCell : '',
        cellClassName: params => {
          let className = '';
          const {
            api,
            api: { editable: tableEditable, unsavedChangesRef },
            row: { isEditable, isChildRow },
            colDef,
            id,
            field,
          } = params;

          const cellEditable = isCellEditable({
            colDef,
            id,
            api,
          });

          if (tableEditable && cellEditable && isEditable) {
            if (isChildRow) {
              className = clsx(className, classes.allowanceChildCell);
            } else {
              className = clsx(className, classes.editableCell);
            }
          }

          const errors = unsavedChangesRef?.current?.errors || {};

          if (isEditable && tableEditable && errors[id] && errors[id][field]) {
            className = clsx(className, classes.errorCell);
          }

          return className;
        },
        editable: !!header?.editable || !!header?.allowanceEditable,
        allowanceEditable: !!header?.allowanceEditable,
        sortable: false,
        width:
          header.accessor === 'accountCode' || header.accessor === 'amount'
            ? 0
            : header.width || 50,
        minWidth: editable && header.editMinWidth ? header.editMinWidth : 0,
        type: header.type || 'string',
        align: header.isCurrency ? 'right' : 'left',
        renderEditCell:
          header.type === 'auto-complete'
            ? params => (
                <AceSelectCell
                  episodeOptions={episodes}
                  episodeRequired={episodeRequired}
                  {...params}
                />
              )
            : params => <AceEditCell {...params} />,
        renderCell:
          header.type === 'auto-complete' && editable
            ? params => (
                <AceSelectCell
                  episodeOptions={episodes}
                  episodeRequired={episodeRequired}
                  readOnly={true}
                  {...params}
                />
              )
            : params => <AceCell {...params} />,

        valueFormatter: header.isDate
          ? (value, row, colDef, apiRef) => {
              if (value == null) {
                return '';
              }
              const date = moment(value);
              const day = DATE_TABLE[date.format('dddd')];

              return (
                <div style={{ display: 'flex' }}>
                  <span style={{ width: '22px' }}>{day}</span>
                  {date.format('MM/DD')}
                </div>
              );
            }
          : header.isCurrency
          ? (value, row, colDef, apiRef) => {
              const maximumFractionDigits = header.maxDecimal || 2;

              const { paycode, isAllowance } = row;
              const { field } = colDef;

              if (value == null) {
                return '';
              }

              if ((isAllowance || paycode === 'MP') && field === 'baseRate') {
                if (!value || value === '0') {
                  return '';
                }
              }

              return Number(value).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits,
              });
            }
          : header.accessor === 'episode'
          ? (value, row, colDef, apiRef) => {
              if (value) {
                return value?.code || '';
              }
              return '';
            }
          : header.accessor === 'units'
          ? (value, row, colDef, apiRef) => {
              const { isAllowance, paycode } = row;
              if (isAllowance || paycode === 'MP') {
                if (!value || value === '0') {
                  return '';
                }
              }
            }
          : undefined,
        pinnable: false,
      };

      if (header.accessor === 'earnedDate') {
        colDef.sortComparator = gridDateComparator;
      }

      return colDef;
    });

    setColumns(initColumns);
  }, [
    classes.allowanceChildCell,
    classes.editableCell,
    classes.errorCell,
    editable,
    episodeRequired,
    episodes,
    isCellEditable,
  ]);

  const handleClick = React.useCallback(
    (params, e, { api }) => {
      const {
        id,
        field,
        cellMode,
        colDef,
        row: { isEditable = true },
      } = params;

      const cellEditable = isCellEditable({
        colDef,
        id,
        api,
      });

      if (isEditable && editable && cellEditable && cellMode === 'view') {
        api.startCellEditMode({ id, field });
      }
    },
    [editable, isCellEditable],
  );

  const getRowClassName = React.useCallback(
    params => {
      const {
        row: { isEditable },
      } = params;

      if (hasPendingTimecard || (!isEditable && editable)) {
        return classes.disabledRow;
      }
    },
    [classes.disabledRow, editable, hasPendingTimecard],
  );

  const resizeColumns = React.useCallback(() => {
    if (apiRef.current?.autosizeColumns) {
      setTimeout(() => {
        ReactDOM.flushSync(() => {
          apiRef.current?.autosizeColumns({ includeOutliers: true });
        });
      }, 250);
    }
  }, [apiRef]);

  React.useEffect(() => {
    resizeColumns();
  }, [data, editable, resizeColumns]);

  const dataGridProps = React.useMemo(() => {
    return {
      disableColumnMenu: true,
      getRowClassName,
      onCellClick: handleClick,
      onCellDoubleClick: handleClick,
      isCellEditable: isCellEditable,
      onColumnHeaderDoubleClick: resizeColumns,
    };
  }, [getRowClassName, handleClick, isCellEditable, resizeColumns]);

  const ctaFilter = React.useCallback(r => !isRowReadOnly(r), []);

  const [hasUnsaved, setHasUnsaved] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (
        !hasUnsaved &&
        _.isEmpty(unsavedChangesRef.current.unsaved) === false
      ) {
        setHasUnsaved(true);
      }
      if (hasUnsaved && _.isEmpty(unsavedChangesRef.current.unsaved)) {
        setHasUnsaved(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [hasUnsaved]);

  let editToolTip = { disabled: false, title: '' };

  if (acEditRecord && acEditRecord !== recordId) {
    editToolTip = {
      disabled: true,
      title: (
        <JumpToTitle acEditRecord={acEditRecord} action={'edit this record.'} />
      ),
    };
  }

  const update = React.useCallback(
    ({ id, field, value, cta = false }) => {
      db('Update', id, field, value);
      if (apiRef.current.editable) {
        let row = _.cloneDeep(apiRef.current.getRow(id));
        let subRows = [];

        const { subRowIds, isAllowance } = row;

        if (cta) {
          const rowModels = apiRef.current.getRowModels();
          subRows = Array.from(rowModels.values()).filter(
            r => ctaFilter(r) && r.id !== id,
          );
        } else if (subRowIds?.length > 0 && !isAllowance) {
          row.subRowIds.forEach(sr => {
            const subRow = _.cloneDeep(apiRef.current.getRow(sr));
            subRows.push(subRow);
          });
        }

        const values = {};
        if (field === 'amount') value = Number(value);
        values[field] = _.cloneDeep(value);

        //Add cascading changes here
        if (field === 'episode') {
          if (value?.series) {
            values.series = value.series;
          }
          if (value?.location) {
            values.location = value.location;
          }
        }

        //update unsaved changes ref
        updateUnsavedChanges({
          unsavedChangesRef,
          values,
          rows: [row, ...subRows],
        });

        const rows = [{ ...row, ...values }];
        if (subRows.length > 0) {
          subRows.forEach(subRow => {
            rows.push({ ...subRow, ...values });
          });
        }
        //update table data
        apiRef.current.setEditCellValue({ id, field, value });
        apiRef.current.updateRows(rows);
        db('Unsaved Changes');
        db(unsavedChangesRef.current);
      }
      validate();
    },
    [apiRef, ctaFilter, validate],
  );

  const splitAllowance = React.useCallback(({ api, id }) => {
    let newRow = getCurrentRow(id, unsavedChangesRef);
    if (_.isEmpty(newRow)) newRow = _.cloneDeep(api.getRow(id));
    delete newRow.originalAllowanceAmount; //only for parent row
    if (!newRow.parentRowId) {
      //if splitting from parent, set parentRowId
      newRow.parentRowId = id;
    }
    newRow.isChildRow = true;
    newRow.subRowIds = [];
    newRow.rowId = crypto.randomUUID();
    newRow.id = `${
      newRow.parentRowId
    }-${NEW_ALLOWANCE_TAG}-${getChildAlloNumber()}`;

    initUnsavedChangesRef(unsavedChangesRef);
    const { unsaved, original } = unsavedChangesRef.current;

    unsaved[newRow.id] = {
      isChildRow: newRow.isChildRow,
      isFreshRow: true,
    };
    original[newRow.id] = _.cloneDeep(newRow);

    if (original[newRow.parentRowId] === undefined) {
      const parentRow = api.getRow(newRow.parentRowId);
      original[newRow.parentRowId] = _.cloneDeep(parentRow);
    }

    if (unsaved[newRow.parentRowId]) {
      //apply any parent changes to child
      const unsavedParent = unsaved[newRow.parentRowId];
      const unsavedRow = unsaved[newRow.id];
      Object.keys(unsavedParent).forEach(field => {
        unsavedRow[field] = unsavedParent[field];
      });
      if (unsavedParent.subRowIds === undefined) unsavedParent.subRowIds = [];
      unsavedRow.subRowIds = [];
    } else {
      unsaved[newRow.parentRowId] = { subRowIds: [] };
    }

    unsaved[newRow.id].rowId = newRow.rowId;
    if (!unsaved[newRow.parentRowId].rowId) {
      unsaved[newRow.parentRowId].rowId = crypto.randomUUID();
    }

    const unsavedParent = unsaved[newRow.parentRowId];
    unsavedParent.subRowIds.push(newRow.id);

    unsaved[newRow.id].amount = 0;
    setData(prevData => {
      const newData = _.cloneDeep(prevData);
      applyUnsavedChangesRefToData(unsavedChangesRef, newData);
      return newData;
    });

    setTimeout(() => {
      api.startCellEditMode({ id: newRow.id, field: 'amount' });
      db(unsavedChangesRef.current);
    }, 500);
  }, []);

  const updateAllowance = React.useCallback(
    ({ api, id, value }) => {
      let row = api.getRow(id);

      if (row.subRowIds.length === 1) {
        const originalAmount = Number(row.originalAllowanceAmount);
        const newAmount = Number(value);
        const diff = diffCurrency(originalAmount, newAmount);
        if (Number.isNaN(diff)) {
          console.warn('updateAllowance: diff is NaN');
          return;
        }
        update({ id: row.subRowIds[0], field: 'amount', value: diff });
      } else if (row.parentRowId) {
        const parentRow = api.getRow(row.parentRowId);
        if (parentRow.subRowIds.length === 1 || row.isFreshRow) {
          let originalAmount = Number(parentRow.originalAllowanceAmount);
          if (row.isFreshRow) {
            update({ id, field: 'isFreshRow', value: false });
            originalAmount = Number(parentRow.amount);
          }

          const newAmount = Number(value);
          const diff = diffCurrency(originalAmount, newAmount);
          if (Number.isNaN(diff)) {
            console.warn('updateAllowance: diff is NaN');
            return;
          }
          update({ id: row.parentRowId, field: 'amount', value: diff });
        }
      }
    },
    [update],
  );

  const deleteAllowance = React.useCallback(
    ({ api, id }) => {
      const row = api.getRow(id);
      const parentRow = api.getRow(row.parentRowId);

      const { unsaved, original } = unsavedChangesRef.current;

      unsaved[parentRow.id].subRowIds = unsaved[parentRow.id].subRowIds.filter(
        sr => sr !== id,
      );

      let hasSubRows = true;
      if (unsaved[parentRow.id].subRowIds.length === 0) {
        const amount = Number(original[parentRow.id].originalAllowanceAmount);

        hasSubRows = false;
        update({ id: row.parentRowId, field: 'amount', value: amount });
        update({ id: row.parentRowId, field: 'rowId', value: '' });

        delete unsaved[row.parentRowId].subRowIds;
        delete unsaved[row.parentRowId].rowId;

        if (_.isEmpty(unsaved[row.parentRowId])) {
          delete unsaved[row.parentRowId];
        }
      }

      delete unsaved[id];
      delete original[id];

      setData(prevData => {
        const newData = prevData.filter(r => r.id !== id);
        applyUnsavedChangesRefToData(unsavedChangesRef, newData);

        if (!hasSubRows) {
          const parentRow = newData.find(r => r.id === row.parentRowId);
          parentRow.amount = parentRow.originalAllowanceAmount;
        }

        return newData;
      });

      validate();
    },
    [update, validate],
  );

  React.useEffect(() => {
    if (apiRef.current?.autosizeColumns) {
      // apiRef.current.editable = editable;
      if (!apiRef.current.update) {
        db('setting apiRef.current.update');
        apiRef.current.update = update;
      }
      apiRef.current.updateAllowance = updateAllowance;

      if (!apiRef.current.splitAllowance) {
        apiRef.current.splitAllowance = splitAllowance;
      }

      apiRef.current.deleteAllowance = deleteAllowance;
    }
  });

  const validationErrorTooltip = React.useMemo(() => {
    if (validationErrors?.length > 1) {
      const tooltipErrors = validationErrors.slice(1);
      return (
        <Box>
          {tooltipErrors.map(error => (
            <Box key={error}>{error}</Box>
          ))}
        </Box>
      );
    }
  }, [validationErrors]);

  const tableRef = React.useRef(null);
  let linearWidth = undefined;
  if (tableRef.current) {
    const row = tableRef.current.closest('.ACE-Table-Row');
    let rowWidth = row?.offsetWidth || 0;

    linearWidth = rowWidth ? `calc(${rowWidth}px - 40px)` : undefined;
  }

  if (!viewEnabled) return null;

  return (
    <Box className={classes.AceTable} ref={tableRef}>
      <Box className={classes.heading}>
        <Typography sx={{ fontWeight: 800 }} variant={'h4'}>
          Daily Breakdown
        </Typography>
        {editable && (
          <>
            <Button
              disabled={!hasUnsaved || savingACE || !!validationErrors}
              onClick={handleSave}
            >
              {savingACE ? <CircularProgress size={20} /> : 'Save'}
            </Button>
            <Button onClick={handleRevert} variant="text" disabled={savingACE}>
              {'Cancel'}
            </Button>
          </>
        )}
        {!editable && canEditFields && (
          <Tooltip title={editToolTip.title}>
            <div>
              <Button
                disabled={
                  editToolTip.disabled ||
                  editFieldsLoading ||
                  hasPendingTimecard
                }
                onClick={e => {
                  onEditFields(recordId);
                }}
              >
                {editToolTip.showSpinner ? (
                  <CircularProgress size={20} />
                ) : (
                  'Edit Fields'
                )}
              </Button>
            </div>
          </Tooltip>
        )}
        {hasPendingTimecard && (
          <WarningMsgBox>
            <ErrorOutlineIcon color={'warning'} />
            Pending timecard calculation
          </WarningMsgBox>
        )}
        {(hasUnsavedError || hasErrors) && !editable && (
          <ErrorMsgBox>
            <ErrorOutlineIcon color={'error'} />
            Update failed due to an error. Please review and try again. If the
            issue persists,
            <br /> add a comment and resubmit the invoice to your payroll
            coordinator for further assistance.
          </ErrorMsgBox>
        )}
        {hasTimeoutError && !editable && !(hasUnsavedError || hasErrors) && (
          <ErrorMsgBox>
            <ReportProblemIcon color={'warning'} />
            Update is taking longer than expected. Refresh the page in a few
            minutes. Contact support if this persists.
          </ErrorMsgBox>
        )}
        {!!validationErrors && editable && (
          <Tooltip title={validationErrorTooltip}>
            <ErrorMsgBox>
              <ErrorOutlineIcon color={'error'} />
              {validationErrors[0]}
              {validationErrors.length > 1 && ` +`}
            </ErrorMsgBox>
          </Tooltip>
        )}
      </Box>
      {hasPendingTimecard && (
        <Box sx={{ position: 'relative' }}>
          <LinearProgress sx={{ position: 'absolute', width: linearWidth }} />
        </Box>
      )}
      <DataGridTable
        rows={data}
        columns={columns}
        unsavedChangesRef={unsavedChangesRef}
        apiRef={apiRef}
        //optional props
        editable={editable}
        dataGridProps={dataGridProps}
        hideEmptyCols={hideEmptyCols}
      />
    </Box>
  );
};

AceTable.propTypes = {
  recordId: PropTypes.string,
  acEditRecord: PropTypes.string,
  onClearEditRecord: PropTypes.func.isRequired,
  onEditFields: PropTypes.func.isRequired,
  onSaveACE: PropTypes.func.isRequired,
  episodes: PropTypes.array,
  distributions: PropTypes.array,
  invoiceStatus: PropTypes.string,
  batchesOpened: PropTypes.bool,
  editFieldsLoading: PropTypes.bool,
  unsavedEdits: PropTypes.string,
  onStoreUnsavedEdits: PropTypes.func.isRequired,
  savingACE: PropTypes.bool,
  hasPendingTimecard: PropTypes.bool,
  currentProject: projectProps,
  hasUnsavedError: PropTypes.bool,
  hideEmptyCols: PropTypes.bool,
  viewEnabled: PropTypes.bool.isRequired,
};

export default compose(connect(mapState, mapDispatch))(AceTable);
