import { produce } from 'immer';
import * as actions from 'actions/events';

const initialState = {
  loading: false,
  list: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.loading}`:
        draft.loading = action.loading;
        break;

      case `${actions.store}`:
        draft.list = action.events;
        break;

      default:
    }
  });
