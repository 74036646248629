import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
//icons
import BulkEditStart from 'components/images/BulkEditStart';
import Max100 from 'components/images/Max100';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FaRegFolderOpen as Folder } from 'react-icons/fa';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    color: palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 100,
    fontStyle: 'normal',
    textAlign: 'center',
  },
  circleBg: {
    width: '263px',
    height: '263px',
    background: palette.primary['+9'],
    borderRadius: ' 50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noCircleBg: {
    width: '263px',
    height: '263px',
    borderRadius: ' 50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  topText: {
    marginTop: 20,
    color: palette.primary.main,
    fontSize: 24,
    fontWeight: '500',
    letterSpacing: 0.16,
  },
  bottomText: {
    marginTop: 20,
    width: 367,
    color: palette.primary.main,
    fontSize: 24,
    fontWeight: '400',
    // fontStyle: 'normal',
    // textAlign: 'center',
    letterSpacing: 0.16,
  },
  folderIcon: {
    height: '163px',
    width: '202px',
    color: palette.primary.main,
    strokeWidth: '2px',
    paddingLeft: 13,
  },
}));

/**
 * EmptyState for Tables

 * @param {string} variant - Options are ['default','noResults','maxResults']
 * @param {string} className - Class name to be applied to root element
 */
const EmptyState = props => {
  const classes = useStyles();

  /**
   * Params for variants MUST HAVE
   *
   * @param {HTMLElement} icon
   * @param {string} topText
   * @param {string} bottomText
   */

  const variants = {
    default: {
      icon: <BulkEditStart />,
      topText: '"Ready for me" timecards are ready.',
      bottomText: 'Select "Apply" to view.',
    },
    maxResults: {
      icon: <Max100 />,
      topText: 'Your selection is over the maximum number allowed',
      topWidth: 385,
      bottomText: 'Use the filters on the left to refine your search selection',
    },
    maxEmployees: {
      icon: <Max100 />,
      topText: 'Your employee selection is over the maximum number allowed',
      topWidth: 385,
      bottomText: 'Use the filters on the left to refine your search selection',
    },
    noResults: {
      icon: <Folder className={classes.folderIcon} />,
      topText: 'No results found',
      bottomText: `Try refining your search to find what you're looking for.`,
    },
    dtsTemplateError: {
      icon: <ErrorOutlineIcon style={{ height: '200px', width: '202px' }} />,
      topText: 'There was an error loading the field template.',
      topWidth: 500,
      bottomText: `Please try refreshing the page, and if the problem persists, contact support.`,
    },
  };

  const { variant = 'default', className } = props;

  const activeVariant = variants[variant];

  return (
    <div className={clsx(classes.root, className)}>
      <div
        className={
          variant === 'default' ? classes.noCircleBg : classes.circleBg
        }
      >
        <div className={classes.icon}>{activeVariant.icon}</div>
      </div>
      <div
        className={classes.topText}
        style={{ width: activeVariant.topWidth || 450 }}
      >
        {activeVariant.topText}
      </div>
      <div className={classes.bottomText}>{activeVariant.bottomText}</div>
    </div>
  );
};

EmptyState.propTypes = {
  variant: PropTypes.oneOf([
    'default',
    'maxResults',
    'maxEmployees',
    'noResults',
    'dtsTemplateError',
  ]),
  className: PropTypes.string,
};

export default EmptyState;
