import React from 'react';
import PropTypes from 'prop-types';

import PopoverMenu from './PopoverMenu';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import MenuButton from './MenuButton';

import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import FormatColorTextIcon from '@mui/icons-material/FormatColorText';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  MenuBar: {
    backgroundColor: palette.grey[100],
    display: 'flex',
    justifyContent: 'space-between',
  },
  subGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  colorBox: {
    height: 30,
    width: 30,
    borderRadius: '5px',
  },
  removeColorBox: {
    border: '1px dashed gray',
  },
}));

const useDividerStyles = makeStyles(({ palette }) => ({
  Divider: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 4px',
    height: '100%',
  },
  DividerBorder: {
    borderLeft: `1.5px solid ${palette.gray['+6']}`,
    height: '80%',
  },
  disabled: {
    backgroundColor: palette.action.disabledBackground,
  },
}));

const Divider = ({ disabled }) => {
  const classes = useDividerStyles();
  return (
    <div className={`${classes.Divider} ${disabled ? classes.disabled : ''}`}>
      <div className={classes.DividerBorder} />
    </div>
  );
};

Divider.propTypes = {
  disabled: PropTypes.bool,
};

//array of basic colors
const colors = [
  { hex: '#ff0000', name: 'Red' },
  { hex: '#ff9900', name: 'Orange' },
  { hex: '#00ff00', name: 'Green' },
  { hex: '#00d5d5', name: 'Cyan' },
  { hex: '#0000ff', name: 'Blue' },
  { hex: '#9900ff', name: 'Purple' },
  { hex: '#ff00ff', name: 'Pink' },
];

const MenuBar = ({ editor, disabled }) => {
  const classes = useStyles();

  if (!editor) {
    return null;
  }

  return (
    <div className={classes.MenuBar}>
      <div className={classes.subGroup}>
        <MenuButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            disabled || !editor.can().chain().focus().toggleBold().run()
          }
          isActive={editor.isActive('bold')}
          tooltip="Bold"
          Icon={FormatBoldIcon}
        />

        <MenuButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            disabled || !editor.can().chain().focus().toggleItalic().run()
          }
          isActive={editor.isActive('italic')}
          tooltip="Italic"
          Icon={FormatItalicIcon}
        />

        <MenuButton
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={
            disabled || !editor.can().chain().focus().toggleUnderline().run()
          }
          isActive={editor.isActive('underline')}
          tooltip="Underline"
          Icon={FormatUnderlinedIcon}
        />

        <MenuButton
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={
            disabled || !editor.can().chain().focus().toggleStrike().run()
          }
          isActive={editor.isActive('strike')}
          tooltip="Strike-Through"
          Icon={StrikethroughSIcon}
        />
        <PopoverMenu
          disabled={disabled}
          Icon={FormatColorTextIcon}
          tooltip={'Text Color'}
          editor={editor}
          currentColor={editor.getAttributes('textStyle')?.color}
          contentList={[
            ...colors.map(color => ({
              content: (
                <div
                  style={{ backgroundColor: color.hex }}
                  className={classes.colorBox}
                />
              ),
              onClick: () => editor.chain().focus().setColor(color.hex).run(),
              key: color.name,
            })),
            {
              content: (
                <div
                  className={classes.colorBox}
                  style={{ border: '1px dashed gray' }}
                />
              ),
              onClick: () => editor.chain().focus().unsetColor().run(),
              key: 'Remove Color',
            },
          ]}
        />
        <Divider disabled={disabled} />
        <MenuButton
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          isActive={editor.isActive('bulletList')}
          tooltip={'Bullet List'}
          disabled={disabled}
          Icon={FormatListBulletedIcon}
        />

        <MenuButton
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          isActive={editor.isActive('orderedList')}
          tooltip={'Ordered List'}
          disabled={disabled}
          Icon={FormatListNumberedIcon}
        />
        <Divider disabled={disabled} />
      </div>
      <div className={classes.subGroup}>
        <MenuButton
          onClick={() => editor.chain().focus().undo().run()}
          disabled={disabled || !editor.can().chain().focus().undo().run()}
          tooltip="Undo"
          Icon={UndoIcon}
        />
        <MenuButton
          onClick={() => editor.chain().focus().redo().run()}
          disabled={disabled || !editor.can().chain().focus().redo().run()}
          tooltip="Redo"
          Icon={RedoIcon}
        />
      </div>
    </div>
  );
};

MenuBar.propTypes = {
  editor: PropTypes.object,
  disabled: PropTypes.bool,
};

export default MenuBar;
