import React from 'react';
import PropTypes from 'prop-types';
import { FullWidthLayout } from 'components/Layout';

const DefaultLayout = ({ children, headers, path }) => (
  <FullWidthLayout headers={headers} path={path}>
    {children}
  </FullWidthLayout>
);

DefaultLayout.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ).isRequired,
  children: PropTypes.node.isRequired,
};

export default DefaultLayout;
