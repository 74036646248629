import React from 'react';
import {
  MaxWidthLayout,
  Header,
  AlertHeader,
  ProjectHeader,
} from 'containers/Layout';

import { EnhancedRoute } from 'containers/RoutingSupport';
import DigitalEdits from 'feature/DigitalEdits/DigitalEdits';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <EnhancedRoute
      authenticated
      key="/projects/:projectId/digital-edits/:invoiceId"
      id="/projects/:projectId/digital-edits/:invoiceId"
      path="/projects/:projectId/digital-edits/:invoiceId"
      layout={MaxWidthLayout}
      headers={[Header, ProjectHeader, AlertHeader]}
      component={DigitalEdits}
      isLoadProjectActiveUser={true}
      title="Digital Edits"
    />
  );
};
