// actions/MODAL_DIALOG.js
import createAction from './createAction';

export const show = createAction('show/modal');

export const hide = createAction('hide/modal');
export const hideAll = createAction('hideAll/modal');
export const initialize = createAction('initialize/modal');
export const remove = createAction('remove/modal');

export const setDmModal = createAction('setDmModal/modal');
