import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Button, Table, TableCell, TableRow, TableBody } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
// decorators
import withModalDialog from 'decorators/withModalDialog';

// components
import { ConfirmModal } from 'components/Shared/Modals';
import { TextField } from 'components/Shared/redux';
import { ERROR_MESSAGES } from 'components/Shared/constants/ErrorMessages';

//actions
import { resendVerificationCode, verifyCode } from 'actions/profile';

const style = () => ({
  tableRow: {
    height: '50px',
    '& > *': {
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 15,
      textAlign: 'center',
    },
  },
});

const mapState = state => ({
  initialValues: {
    code: '',
  },
});

const mapDispatchToProps = dispatch => ({
  onresendVerification: () => {
    dispatch(resendVerificationCode());
  },
});

const onSubmit = (values, dispatch) => {
  const code = values.code;
  dispatch(verifyCode({ code }));
};

class VerificationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(evt) {
    const codeValue = evt.target.value;
    if (codeValue === '' || /^\d+$/.test(codeValue)) {
      this.setState({ code: evt.target.vale });
    }
  }

  static defaultProps = {
    error: undefined,
  };

  render() {
    const { handleSubmit, classes, onresendVerification, error, ...others } =
      this.props;

    const RowContent = (
      <Table>
        <TableBody>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.noborder}>
              <Field
                type="number"
                component={TextField}
                name={'code'}
                props={{ autoComplete: 'off' }}
                required
                maxLength={6}
                onInput={e => {
                  let val = e.target.value;
                  if (val.length > 6) {
                    e.target.value = val.slice(0, 6);
                  }
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.noborder}>
              <Button
                variant="outlined"
                color="primary"
                onClick={onresendVerification}
              >
                Resend Verification
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
    return (
      <form onSubmit={handleSubmit}>
        <ConfirmModal
          title={'Enter 6 Digit Verification code'}
          content={RowContent}
          buttonText="Verify"
          buttonType="primary"
          raisedButton
          footerErrorMsg={
            error && error.verificationCode
              ? ERROR_MESSAGES['invalid_code']
              : null
          }
          {...others}
        />
      </form>
    );
  }
}
export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({
    dialog: 'verificationModal',
    maxWidth: 'md',
    noEscClose: true,
  }),
  connect(mapState, mapDispatchToProps),
  reduxForm({
    form: 'verificationModal',
    onSubmit,
  }),
)(VerificationModal);
