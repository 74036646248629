import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tooltip,
} from '@mui/material';

import { truncateStringToFitLength } from 'utils/helperFunctions';

import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(({ palette }) => ({
  MobileMenuTitle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    width: 240,
    color: palette.text.primary,
    '& > .MuiTypography-root': {
      fontWeight: 'bold',
    },
  },
  icon: {
    minWidth: 0,
    marginRight: 8,
  },
}));

const MobileMenuTitle = props => {
  const classes = useStyles();
  const { icon, text, textLength = Infinity } = props;

  const shortText = truncateStringToFitLength(textLength, text);
  let title = '';
  if (shortText !== text) {
    title = text;
  }

  return (
    <ListSubheader className={classes.MobileMenuTitle} key={text}>
      {icon && <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>}
      <Tooltip title={title}>
        <ListItemText className={classes.text} primary={shortText} />
      </Tooltip>
    </ListSubheader>
  );
};

MobileMenuTitle.propTypes = {
  icon: PropTypes.element,
  text: PropTypes.string.isRequired,
  textLength: PropTypes.number,
};

export default MobileMenuTitle;
