import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProjectSetup from 'components/Admin/Projects/Setup';
import { getProjectDetails as currentProject } from 'selectors/project';

import AccountCodes from './AccountCodes';
import ProjectAllowances from './ProjectAllowances';
import TimeEntrySettings from './TimeEntrySettings';
import ESignature from './ESignature';
import EmailConfig from './EmailConfig';
import StudioPlus from './StudioPlus';
import DtsSettings from './DtsSettings';
import WTCSetting from './WTCSetting';
import { Button, Box, Typography } from '@mui/material';

//action
import { show as showModal } from 'actions/modalDialog';

import CrewMemberTimecardSettings, {
  CREW_MEMBER_DIALOG,
} from './CrewMemberTimecardSettings';

const mapState = (state, { match }) => ({
  project: currentProject(state, { projectId: match.params.projectId }),
});

const mapDispatch = dispatch => ({
  onShowModal: () => dispatch(showModal({ dialog: CREW_MEMBER_DIALOG })),
});

class ProjectSettings extends React.Component {
  static propTypes = {
    project: PropTypes.object.isRequired,
  };

  render() {
    const { project, onShowModal } = this.props;
    return (
      <div style={{ marginBottom: 30, marginTop: 30 }}>
        <ProjectSetup project={project} />
        {project.timePlusEnabled && (
          <>
            <Box
              sx={{
                mb: '20px',
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={onShowModal}
                sx={{ ml: '0px' }}
              >
                Crew Member Timecard Settings
              </Button>
              <Box sx={{ width: '30px' }}></Box>
              <Typography sx={{ mt: '5px' }}>
                Use this to configure the display of the Crew Member's timecard
              </Typography>
            </Box>
            <AccountCodes />
            <ProjectAllowances />
            <TimeEntrySettings />
            <ESignature />
            <EmailConfig />
            <StudioPlus />
            <DtsSettings />
            <WTCSetting />
          </>
        )}
        <CrewMemberTimecardSettings />
      </div>
    );
  }
}

export default connect(mapState, mapDispatch)(ProjectSettings);
