import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField } from '@mui/material';
import { db } from './DataGridTableUtils';
import {
  preventBlanks,
  accountsFieldMaxLength,
  makeMaskFunc,
  validateAccountMask,
} from 'utils/weekUtils';
import { getCurrentProject } from 'selectors/project';
import {
  ACCOUNT_CODE_MAX_LENGTH,
  NO_BLANK_ALLOWED_FIELDS,
} from 'components/Shared/constants/index';

import CopyToAll from './CopyToAll';
import Portal from 'components/Shared/ReactTable/Portal';

import { normalizeAmount } from 'feature/DigitalEdits/digitalEditsUtils';
import { formatAllowedChars } from 'utils/helperFunctions';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  textFieldInput: {
    fontSize: '14px',
  },
}));

const mapState = (state, ownProps) => {
  return { project: getCurrentProject(state) };
};

const AceEditCell = props => {
  const { id, value, formattedValue, api, field, project } = props;
  const classes = useStyles();

  const rowCount = api.getRowsCount();

  const enableCTA = field !== 'amount' && rowCount > 1;

  const [valueState, setValueState] = React.useState(
    value || value === 0 ? value : formattedValue,
  );

  const anchorRef = React.useRef();

  const [ctaAnchor, setCtaAnchor] = React.useState(null);

  React.useEffect(() => {
    if (anchorRef.current && !ctaAnchor) {
      setCtaAnchor(anchorRef.current);
    }
  }, [ctaAnchor]);

  const accountCodeMask = React.useMemo(
    () => validateAccountMask(project && project.accountMask),
    [project],
  );

  const fieldProps = React.useMemo(() => {
    const fieldProps = {
      InputProps: {
        inputProps: {
          maxLength: accountsFieldMaxLength(project, field),
          className: classes.textFieldInput,
        },
      },
    };

    if (field === 'accountCode') {
      fieldProps.InputProps.inputProps.placeholder =
        accountCodeMask !== '~'.repeat(ACCOUNT_CODE_MAX_LENGTH)
          ? accountCodeMask
          : '';
    }
    return fieldProps;
  }, [accountCodeMask, classes.textFieldInput, field, project]);

  const maskingFunc = React.useMemo(
    () => makeMaskFunc(accountCodeMask),
    [accountCodeMask],
  );

  const onChange = React.useCallback(
    event => {
      let newValue = event.target.value || '';
      newValue = `${newValue}`;
      let normalizeFunc = v => formatAllowedChars(v);
      if (field === 'accountCode') {
        normalizeFunc = maskingFunc;
      } else if (NO_BLANK_ALLOWED_FIELDS.includes(field)) {
        normalizeFunc = v => preventBlanks(formatAllowedChars(v));
      } else if (field === 'amount') {
        normalizeFunc = normalizeAmount;
      }
      newValue = normalizeFunc(newValue);
      newValue = newValue.toUpperCase();
      db('onChange', newValue);
      setValueState(newValue);
      if (api.editable) api.update({ id, field, value: newValue });
    },
    [api, field, id, maskingFunc],
  );

  const copyToAll = React.useCallback(() => {
    if (api.editable) api.update({ id, field, value: valueState, cta: true });
  }, [api, field, id, valueState]);

  return (
    <>
      <TextField
        value={valueState || ''}
        onChange={onChange}
        onBlur={() => {
          if (field === 'amount') {
            api.updateAllowance({ id, api, value: valueState });
          }
        }}
        onFocus={e => {
          e.target.select();
        }}
        autoFocus
        onKeyDown={e => {
          if (
            (e.metaKey || e.ctrlKey) &&
            e.shiftKey &&
            e.key.toLowerCase() === 'a' &&
            enableCTA
          ) {
            e.preventDefault();
            copyToAll();
          }
        }}
        {...fieldProps}
      />
      <div ref={anchorRef} />
      {enableCTA && (
        <Portal
          variant="ace"
          targetDom={ctaAnchor}
          parentTableRef={api.rootElementRef}
        >
          <CopyToAll copyToAll={copyToAll} />
        </Portal>
      )}
    </>
  );
};

AceEditCell.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formattedValue: PropTypes.any,
  api: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
};

export default connect(mapState)(AceEditCell);
