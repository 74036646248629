import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Box, Typography, Skeleton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

//actions
import * as actions from 'actions/digitalEdits';
//selectors
import {
  getCurrentInvoiceId,
  getRecords,
  getRecordListLoading,
  getTaxColumnMap,
  getCurrentRecordId,
} from 'selectors/digitalEdits';

//components
import Record from './Record';

import { makeNameFromRecord } from './digitalEditsUtils';
import { useBroadcastChannel } from 'utils/customHooks';

const useStyles = makeStyles(({ palette }) => ({
  RecordList: {
    overflowX: 'scroll',
    overflowY: 'visible',
  },
}));

const mapState = state => ({
  currentInvoiceId: getCurrentInvoiceId(state),
  currentRecordId: getCurrentRecordId(state),
  records: getRecords(state),
  loading: getRecordListLoading(state),
  taxColumnMap: getTaxColumnMap(state),
});

const mapDispatch = dispatch => ({
  onSetCurrentRecordId: currentRecordId => {
    dispatch(actions.setCurrentRecordId({ currentRecordId }));
  },
});

const RecordList = props => {
  const classes = useStyles();
  const {
    onSetCurrentRecordId,
    currentInvoiceId,
    records,
    loading,
    taxColumnMap,
    currentRecordId,
  } = props;

  //Initialize Broadcast Channel to send current TC to comment modal
  //Actual message is sent from Record.js, unless
  const bc = useBroadcastChannel(currentInvoiceId);

  //Send Current TC to comment modal if requested (needed on opening modal)
  React.useEffect(() => {
    if (bc) {
      bc.onmessage = function (ev) {
        if (ev.data.action === 'getTCName' && currentRecordId) {
          const tc = records.find(tc => tc.recordId === currentRecordId);
          if (tc) {
            const currentTCName = makeNameFromRecord(tc);
            bc?.postMessage({ action: 'setTCName', payload: currentTCName });
          }
        } else if (ev.data.action === 'scrollDown') {
          window.scrollBy({ top: window.innerHeight / 4, behavior: 'smooth' });
        } else if (ev.data.action === 'scrollUp') {
          window.scrollBy({ top: -window.innerHeight / 4, behavior: 'smooth' });
        }
      };
    }
  }, [bc, currentRecordId, records]);

  React.useEffect(() => {
    if (!currentRecordId && records.length > 0) {
      onSetCurrentRecordId(records[0].recordId);
    }
  }, [currentRecordId, onSetCurrentRecordId, records]);

  return (
    <Box className={classes.RecordList}>
      {!!currentInvoiceId && (
        <Box>
          {loading && (
            <Box
              sx={{
                height: '300px',
                width: '100%',
                padding: '20px',
              }}
            >
              <Skeleton width="50%" height="40px">
                <Typography>.</Typography>
              </Skeleton>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  height: '250px',
                }}
              >
                <Skeleton variant="rounded" height="100%" width="30%" />
                <Skeleton variant="rounded" height="100%" width="30%" />
                <Skeleton variant="rounded" height="100%" width="30%" />
              </Box>
            </Box>
          )}

          {!loading &&
            records.map((record, index) => (
              <Record
                key={record.recordId}
                index={index}
                record={record}
                onSetCurrentRecordId={onSetCurrentRecordId}
                taxColumnMap={taxColumnMap}
                bc={bc}
              />
            ))}
        </Box>
      )}
    </Box>
  );
};

RecordList.propTypes = {
  onSetCurrentRecordId: PropTypes.func.isRequired,
  currentInvoiceId: PropTypes.string.isRequired,
  records: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  taxColumnMap: PropTypes.object,
  currentRecordId: PropTypes.string,
};

export default compose(connect(mapState, mapDispatch))(RecordList);
