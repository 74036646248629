import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, Box } from '@mui/material';
import DealAllowanceInfo from './DealAllowanceInfo';

const CheckboxCell = props => {
  const {
    disabled,
    baseOnChange,
    baseOnBlur,
    localVal,
    toggleCTA,
    id,
    column: { id: columnId },
    row,
  } = props;

  const isAutoAllowance = columnId === 'isAutoDailyAllowance';

  const hasDailyAllowances =
    row?.original?.dealMemoTC?.dealMemoAllowances?.filter(
      a => a.frequency === 'D' && (a.payCode1 || a.payCode2),
    )?.length > 0;

  let showCheckBox = true;

  const status = row.original.userFriendlyTimecardStatus;
  if (
    isAutoAllowance &&
    (!hasDailyAllowances ||
      (status !== '' && status !== 'Draft' && localVal === null))
  ) {
    showCheckBox = false;
  }

  const boxSX = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  if (isAutoAllowance) {
    boxSX.width = '78px';
  }

  return (
    <Box sx={boxSX}>
      {showCheckBox ? (
        <Checkbox
          id={id}
          sx={{ p: 0 }}
          disabled={disabled || (isAutoAllowance && !hasDailyAllowances)}
          color={'primary'}
          checked={localVal || false}
          onChange={e => {
            baseOnChange(e.target.checked);
            if (window.navigator.userAgent.includes('Safari')) {
              e?.target?.focus();
            }
          }}
          onFocus={() => toggleCTA(true)}
          onBlur={() => {
            toggleCTA(false);
            baseOnBlur();
          }}
        />
      ) : (
        <Box sx={{ width: '24px' }} />
      )}
      {isAutoAllowance && <DealAllowanceInfo row={row} />}
    </Box>
  );
};

CheckboxCell.propTypes = {
  disabled: PropTypes.bool,
  baseOnChange: PropTypes.func.isRequired,
  baseOnBlur: PropTypes.func.isRequired,
  localVal: PropTypes.bool,
  toggleCTA: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  row: PropTypes.object.isRequired,
};

export default CheckboxCell;
