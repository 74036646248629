import PropTypes from 'prop-types';

import { dealMemoProps, projectUserProps } from './profiles';
import { stateProps, cityProps } from './locations';

export const unionProps = PropTypes.shape({
  code: PropTypes.string,
  description: PropTypes.string,
});

export const timecardProps = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startsOn: PropTypes.string,
  endsOn: PropTypes.string,
  htgStateId: PropTypes.string,
  state: stateProps, //
  htgCityId: PropTypes.string,
  city: cityProps, //
  dealMemo: dealMemoProps,
  union: unionProps,
  user: projectUserProps,
  createdBy: projectUserProps,
  status: PropTypes.string,
  approvalLevel: PropTypes.number,
  batchId: PropTypes.number,
  totalAllowances: PropTypes.number,
  activeApprovalFlowId: PropTypes.number,
  projectId: PropTypes.number,
  departmentId: PropTypes.number,
  departmentName: PropTypes.string,
  rejectionWorkflow: PropTypes.string,
  previouslyRejected: PropTypes.bool,
  userAccountCode: PropTypes.string,
});

export const timecardsProps = PropTypes.arrayOf(timecardProps);
