import PropTypes from 'prop-types';

export const projectProps = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  departmentApprove: PropTypes.bool,
  dhGrossEnabled: PropTypes.bool,
  paDeleteTimecards: PropTypes.bool,
  isClosed: PropTypes.bool,
  isDraft: PropTypes.bool,
  isHidden: PropTypes.bool,
  militaryTime: PropTypes.bool,
  productionCompanyName: PropTypes.string,
  rejectionWorkflow: PropTypes.string,
  timeInterval: PropTypes.string,
  esignatureEnabled: PropTypes.bool,
  dtsSettingEnabled: PropTypes.bool,
});

export const projectsProps = PropTypes.arrayOf(projectProps);

export const SETTINGS = {
  accountCode: 'Account Code',
  episode: 'Episode',
  location: 'Location',
  insurance: 'Insurance',
  series: 'Series',
  set: 'Set',
  freeField1: 'Free Field 1',
  freeField2: 'Free Field 2',
  freeField3: 'Free Field 3',
  freeField4: 'Free Field 4',
};

export const settingProps = PropTypes.shape({
  employeeVisible: PropTypes.bool,
  employeeMandatory: PropTypes.bool,
  crewVisible: PropTypes.bool,
  crewMandatory: PropTypes.bool,
});

export const settingsProps = PropTypes.shape({
  accountCode: settingProps,
  episode: settingProps,
  location: settingProps,
  insurance: settingProps,
  series: settingProps,
  set: settingProps,
  freeField1: settingProps,
  freeField2: settingProps,
  freeField3: settingProps,
  freeField4: settingProps,
  departmentApprove: PropTypes.bool,
  accountCodesOpenByDefault: PropTypes.bool,
});
