import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// import AdditionalFields from './AdditionalFields';
import FieldCheckboxDropDown from 'components/Shared/FieldCheckboxDropDown';

let FIELDS = [
  { type: 'ndb', label: 'NDB In', visible: false },
  { type: 'meal', value: 1, label: 'First Meal', visible: true },
  { type: 'lastMan1In', label: 'Last Man In', visible: false, indent: true },
  { type: 'ndm', label: 'NDM', visible: false },
  { type: 'meal', value: 2, label: 'Second Meal', visible: false },
  { type: 'meal', value: 3, label: 'Third Meal', visible: false },
];

const mapState = state => ({});

const mapDispatch = dispatch => ({});

const AdditionalMealFields = props => {
  const {
    timecard,
    change,
    disabled,
    mealsEditable,
    showLastMan1In,
    setShowLastMan1In,
    showNdm,
    setShowNdm,
    showNdb,
    setShowNdb,
    caRegion,
  } = props;

  if (caRegion) {
    FIELDS.map(f => {
      if (f.type === 'ndb') {
        f.label = 'General Call';
      }
      return f;
    });
  }
  const [fieldsList, setFieldsList] = useState(FIELDS);

  const initRef = useRef(false);

  //initialize fieldsList to current state
  useEffect(() => {
    if (initRef.current === false) {
      initRef.current = true;
      const meals = timecard?.meals ?? 1;

      const fields = _.cloneDeep(FIELDS);
      fields.forEach(field => {
        //Initialize Meals
        if (field.type === 'meal') {
          field.visible = field.value <= meals;
          if (mealsEditable === false) {
            field.disabled = true;
          }
          //Initialize LMI
        } else if (field.type === 'lastMan1In') {
          field.visible = showLastMan1In ? true : false;
        } else if (field.type === 'ndm') {
          field.visible = showNdm ? true : false;
        } else if (field.type === 'ndb') {
          field.visible = showNdb ? true : false;
        }
      });
      setFieldsList(fields);
    }
  }, [mealsEditable, showLastMan1In, showNdm, showNdb, timecard]);

  const mealClick = value => {
    const newFieldsList = _.cloneDeep(fieldsList);
    newFieldsList.forEach(field => {
      if (field.type === 'meal') {
        //handle clicking on an already checked box
        if (field.value === value && value > 1) {
          field.visible = !field.visible;
          value = field.visible ? value : value - 1;
        } else {
          field.visible = field.value <= value;
        }
      }
    });
    change('meals', value); //change # of meals in form
    setFieldsList(newFieldsList); //update display
  };

  const lastManClick = () => {
    const newFieldsList = _.cloneDeep(fieldsList);

    newFieldsList.forEach(f => {
      if (f.type === 'lastMan1In') {
        f.visible = !showLastMan1In;
        setShowLastMan1In(!showLastMan1In);
      }
    });

    setFieldsList(newFieldsList);
  };

  const ndmClick = () => {
    const newFieldsList = _.cloneDeep(fieldsList);
    newFieldsList.forEach(f => {
      if (f.type === 'ndm') {
        f.visible = !showNdm;
        setShowNdm(!showNdm);
      }
    });
    setFieldsList(newFieldsList);
  };

  const ndbClick = () => {
    const newFieldsList = _.cloneDeep(fieldsList);
    newFieldsList.forEach(f => {
      if (f.type === 'ndb') {
        f.visible = !showNdb;
        setShowNdb(!showNdb);
      }
    });
    setFieldsList(newFieldsList);
  };

  const onClickField = field => {
    if (field.type === 'meal') {
      mealClick(field.value);
    } else if (field.type === 'lastMan1In') {
      lastManClick();
    } else if (field.type === 'ndm') {
      ndmClick();
    } else if (field.type === 'ndb') {
      ndbClick();
    }
  };

  let showingFields = 0;
  fieldsList.forEach(f => {
    if (f.visible) showingFields++;
  });

  return (
    <FieldCheckboxDropDown
      fieldsList={fieldsList}
      selectedCount={showingFields}
      onClickField={onClickField}
      label="Meal and Additional Fields"
      disabled={disabled}
    />
  );
};

export default connect(mapState, mapDispatch)(AdditionalMealFields);
