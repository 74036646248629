import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getProject } from 'selectors/routeParams';
import { getProjectUser } from 'selectors/session';
import Loader from 'components/Loader';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    marginBottom: 5,
    maxWidth: '80%',
  },
  fullWidth: {
    maxWidth: '98%',
    marginBottom: 5,
  },
  maxWidth: {
    maxWidth: '100%',
    marginBottom: 5,
  },
}));

const mapState = state => ({
  projectId: getProject(state),
  projectUser: getProjectUser(state),
});

const mapDispatch = dispatch => ({});

const FullWidthLayout = props => {
  const classes = useStyles();
  const {
    children,
    fullWidth = false,
    maxWidth = false,
    headers = [],
    showBackBtn,
    path = '',
    projectId,
    projectUser,
  } = props;

  const locationHasProjectId = path.includes(':projectId');
  const isAdminPath = path.includes('/admin');
  const hasProjectId = !!projectId;
  const hasProjectUser = !_.isEmpty(projectUser);

  const showLoader =
    (locationHasProjectId && !hasProjectId) ||
    (locationHasProjectId && !isAdminPath && !hasProjectUser);

  const headersContent = headers.map((Header, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <Header key={i} showBackBtn={showBackBtn} />
  ));

  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item xs={12}>
        {headersContent}
        <Grid
          container
          alignItems="flex-start"
          justifyContent="center"
          className={classes.root}
        >
          <Grid
            item
            xs={12}
            md={10}
            lg={fullWidth || maxWidth ? 12 : 10}
            className={
              maxWidth
                ? classes.maxWidth
                : fullWidth
                ? classes.fullWidth
                : classes.content
            }
          >
            {showLoader ? <Loader fullScreen={true} /> : children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

FullWidthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ),
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.bool,
  showBackBtn: PropTypes.bool,
  projectId: PropTypes.string,
  path: PropTypes.string.isRequired,
  projectUser: PropTypes.object,
};

export default compose(connect(mapState, mapDispatch))(FullWidthLayout);
