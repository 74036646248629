import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';

import { FilesTypography } from './Shared';
import BackgroundLetterAvatars from 'components/Shared/BackgroundLetterAvatars';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeliveryIcon from 'components/images/DeliveryIcon';

import { FILE_COMMENT_CATEGORY_MAP } from 'feature/DigitalEdits/digitalEditsUtils';

import { CircularProgress } from '@mui/material';

const useStyles = makeStyles(({ palette }) => ({
  listItem: {
    display: 'flex',
    padding: '2px',
    alignItems: 'center',
    backgroundColor: 'white',
    border: `1px solid ${palette.gray['+2']}`,
    borderRadius: '2px',
  },
}));

const File = props => {
  const classes = useStyles();
  const {
    file,
    onDownloadFile,
    onDeleteFile,
    index,
    canDelete = false,
    canDownload = false,
  } = props;

  const uploading = file.uploading;
  const deleting = file.deleting;
  const name = file.createdBy || '';
  const time =
    file.createdDate && moment(file.createdDate).format('MM/DD/YYYY - HH:mm');

  const errors = file.errors;
  const hasErrors = !!errors && errors.length > 0;
  const downloading = file.downloading;

  const isDelivery =
    file.category === FILE_COMMENT_CATEGORY_MAP['InvoiceDelivery'];

  return (
    <Box
      className={classes.listItem}
      sx={{
        margin: index === 0 ? '0px' : `${4}px 0px 0px 0px`,
      }}
    >
      <InsertDriveFileIcon
        sx={{
          fontSize: '2rem',
          color: 'gray.primary',
        }}
      />
      <Box
        sx={{
          display: 'block',
          ml: '12px',
        }}
      >
        <Box sx={{ display: 'flex', gap: 1 }}>
          <FilesTypography title={file.fileName || file.name}>
            {file.fileName || file.name}
          </FilesTypography>
          {isDelivery && (
            <Tooltip title="Delivery Method File">
              <DeliveryIcon />
            </Tooltip>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            maxWidth: '400px',
          }}
        >
          <FilesTypography sx={{ width: '80px' }}>
            {file.size || file.fileSize}
          </FilesTypography>
          {hasErrors ? (
            <FilesTypography
              sx={{ color: 'red', ml: '24px' }}
              title={errors[0]}
            >
              {errors[0]}
            </FilesTypography>
          ) : (
            <>
              <Box sx={{ ml: '24px' }}>
                <Tooltip title={name}>
                  <div>
                    <BackgroundLetterAvatars name={name} miniAvatar={true} />
                  </div>
                </Tooltip>
              </Box>
              <FilesTypography sx={{ ml: '24px' }}>{time}</FilesTypography>{' '}
            </>
          )}
        </Box>
      </Box>
      <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
        {uploading ? (
          <CircularProgress size={20} color="secondary" sx={{ mr: '16px' }} />
        ) : (
          <>
            {canDownload && file.documentId && (
              <IconButton
                sx={{ padding: '4px' }}
                onClick={() => {
                  !downloading && onDownloadFile({ file });
                }}
              >
                {downloading ? (
                  <CircularProgress size={20} color="secondary" />
                ) : (
                  <CloudDownloadIcon />
                )}
              </IconButton>
            )}
            {canDelete && (
              <>
                <IconButton
                  onClick={() => {
                    !deleting &&
                      onDeleteFile({
                        documentId: file.documentId,
                        index,
                      });
                  }}
                  sx={{
                    ml: 'auto',
                    color: 'grey.600',
                    padding: deleting ? '6px' : '4px',
                  }}
                >
                  {deleting ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    <CancelOutlinedIcon />
                  )}
                </IconButton>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

File.propTypes = {
  file: PropTypes.object.isRequired,
  onDownloadFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  index: PropTypes.number,
  canDelete: PropTypes.bool,
  canDownload: PropTypes.bool,
};

export default File;
