import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import withRouter from 'decorators/withRouter';
import { push } from 'redux-first-history';
import BaseTimecard from 'components/images/BaseTimecard';
//MUI
import { Tooltip, IconButton } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

//actions
import { setFromURL } from 'actions/timecards';

//helpers
import { getProjectFromURL } from 'utils/helperFunctions';

const useStyles = makeStyles(() => ({
  timecardIcon: {
    marginTop: '3px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const mapDispatchToProps = (dispatch, { location }) => ({
  onNavToWTC: ({ batchWorksightId, timecardEntryHeaderId }) => {
    const projectId = getProjectFromURL(location.pathname);
    dispatch(setFromURL({ fromURI: location.pathname }));
    const toURL = `/projects/${projectId}/review/ready/${batchWorksightId}/wtc?timecardHeaderId=${timecardEntryHeaderId}`;
    dispatch(push(toURL));
  },
});

const NavToWtcIcon = ({
  //functions
  onNavToWTC,
  //data
  timecard,
  batch,
}) => {
  const classes = useStyles();
  const batchWorksightId = batch && batch.worksightId;
  const timecardEntryHeaderId = timecard && timecard.entryHeaderId;

  return (
    <div>
      <Tooltip title="View Coding">
        <IconButton
          onClick={() =>
            onNavToWTC({
              batchWorksightId,
              timecardEntryHeaderId,
            })
          }
        >
          <BaseTimecard className={classes.timecardIcon} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default withRouter(
  compose(connect(null, mapDispatchToProps))(NavToWtcIcon),
);
