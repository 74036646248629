import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import {
  Button,
  IconButton,
  Paper,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material';

import {
  FaAngleDoubleLeft as First,
  FaAngleDoubleRight as Last,
  FaAngleLeft as Previous,
  FaAngleRight as Next,
} from 'react-icons/fa';

const style = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.background.default,
    height: 50,
    padding: [0, 25],
  },
  pageSelection: {
    extend: 'root',
    padding: 0,
  },
  resultSection: {
    extend: 'pageSelection',
    justifyContent: 'flex-end',
    minWidth: 150,
  },
  button: {
    minWidth: 2,
    margin: [0, 2],
    maxWidth: 32,
    borderRadius: 2,
    height: 36,
    color: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: 'rgba(60, 157, 215, 0.12)',
    },
  },
  buttonActive: {
    extend: 'button',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
  buttonDisabled: {
    extend: 'button',
    color: 'rgba(0, 0, 0, 0.26)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.26)',
      backgroundColor: 'none',
    },
  },
  dots: {
    display: 'flex',
    flexDirection: 'row',
  },
  text: {
    fontSize: '0.88em',
  },
});

export const paginationProps = PropTypes.shape({
  activePage: PropTypes.number,
  changePageSize: PropTypes.func,
  navigateTo: PropTypes.func,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
});

const DEFUALT_PAGE_SIZE = 10;
const SHOW_ALL = 'All';

class Pagination extends Component {
  static propTypes = {
    activePage: PropTypes.number,
    classes: PropTypes.object.isRequired,
    changePageSize: PropTypes.func,
    navigateTo: PropTypes.func,
    pageSize: PropTypes.number,
    totalCount: PropTypes.number,
  };

  static defaultProps = {
    activePage: 1,
    changePageSize: undefined,
    navigateTo: undefined,
    pageSize: DEFUALT_PAGE_SIZE,
    totalCount: 0,
  };

  // Putting in local state. As this doesnt need to be in redux state tree.
  state = {
    open: false,
    anchorEl: undefined,
  };

  loadPage = page => () => {
    const { navigateTo } = this.props;

    if (navigateTo) {
      navigateTo(page);
    }
  };

  handlePageSizeSelection = event => {
    const { open } = this.state;
    this.setState({ open: !open, anchorEl: event.currentTarget });
  };

  handlePageSizeChange = (event, option) => {
    const { changePageSize } = this.props;

    this.setState({ open: false });

    if (changePageSize) {
      changePageSize(option);
    }
  };

  renderPageSizeSelection() {
    const { classes, pageSize } = this.props;
    const pageSizeArray = [10, 20, 50, 100, SHOW_ALL];

    return (
      <div className={classes.pageSelection}>
        <Typography align="center" className={classes.text} type="caption">
          {'Rows per page:'}
        </Typography>
        <Button
          aria-haspopup="true"
          aria-controls="pageSizeMenu"
          aria-label={`${pageSize}`}
          className={classes.button}
          onClick={this.handlePageSizeSelection}
        >
          {`${pageSize}`}
        </Button>
        <Menu
          id="pageSizeMenu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onRequestClose={this.handlePageSizeSelection}
        >
          {pageSizeArray.map(option => (
            <MenuItem
              key={option}
              selected={option === pageSize}
              onClick={event => this.handlePageSizeChange(event, option)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }

  renderPages() {
    const { classes, activePage, pageSize: size, totalCount } = this.props;
    const pageSize = size === SHOW_ALL ? totalCount : size;
    const pageCount = Math.ceil(totalCount / pageSize);
    const pagesArray = [];

    // Displaying only 5 pages to control the spacing.
    let startIndex = 1;
    let endIndex = pageCount > 5 ? 5 : pageSize;

    if (activePage > 4) {
      startIndex = activePage - 2;
      endIndex = activePage + 2;
    }

    if (endIndex > pageCount) {
      endIndex = pageCount;
      startIndex = pageCount - 4 <= 0 ? 1 : pageCount - 4;
    }

    for (let page = startIndex; page <= endIndex; ) {
      const isActive = page === activePage;
      pagesArray.push(
        <Button
          key={page}
          className={isActive ? classes.buttonActive : classes.button}
          onClick={this.loadPage(page)}
          color="primary"
        >
          {page}
        </Button>,
      );
      page += 1;
    }

    return pagesArray;
  }

  render() {
    const { activePage, classes, pageSize: size, totalCount } = this.props;

    const pageSize = size === SHOW_ALL ? totalCount : size;
    const pageCount = Math.ceil(totalCount / pageSize);

    const disableFirst = activePage === 1;
    const disableLast = activePage === pageCount;

    const start = Math.min((activePage - 1) * pageSize, totalCount);
    const end = Math.min(start + pageSize - 1, totalCount);

    return (
      <Paper square elevation={0} className={classes.root}>
        {this.renderPageSizeSelection()}
        <div className={classes.dots}>
          <div>
            <IconButton
              className={disableFirst ? classes.buttonDisabled : classes.button}
              onClick={this.loadPage(1)}
              disabled={disableFirst}
              size="large"
            >
              <First />
            </IconButton>

            <IconButton
              className={disableFirst ? classes.buttonDisabled : classes.button}
              onClick={this.loadPage(activePage - 1)}
              disabled={disableFirst}
              size="large"
            >
              <Previous />
            </IconButton>
          </div>
          {this.renderPages()}
          <div>
            <IconButton
              className={disableLast ? classes.buttonDisabled : classes.button}
              onClick={this.loadPage(activePage + 1)}
              disabled={disableLast}
              size="large"
            >
              <Next />
            </IconButton>

            <IconButton
              className={disableLast ? classes.buttonDisabled : classes.button}
              onClick={this.loadPage(pageCount)}
              disabled={disableLast}
              size="large"
            >
              <Last />
            </IconButton>
          </div>
        </div>
        <div className={classes.resultSection}>
          <Typography align="center" className={classes.text} type="caption">
            {`${start} - ${end} of ${totalCount} Results`}
          </Typography>
        </div>
      </Paper>
    );
  }
}

export default withStyleSheet('Pagination', style)(Pagination);
