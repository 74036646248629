import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import { Card, CardContent } from 'components/Shared/Card';
import { Table, TableBody, TableRow, TableCell, Button } from '@mui/material';
import { add as addApprovalFlow } from 'actions/reviewFlows';

const style = () => ({
  root: {
    marginTop: 10,
    display: 'inline-block',
  },
  content: {
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    padding: 0,
  },
  cardContent: {
    display: 'block',
    marginTop: 0,
    marginBottom: 0,
    margin: 0,
  },
  approvalButton: {
    float: 'right',
    marginRight: 10,
    marginTop: 85,
  },
});

const mapDispatchToProps = dispatch => ({
  onAddApprovalFlow: () => {
    dispatch(addApprovalFlow());
  },
});

class DepartmentHead extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    enableToggle: PropTypes.bool.isRequired,
    viewGross: PropTypes.bool,
    paDeleteTimecards: PropTypes.bool,
    onAddApprovalFlow: PropTypes.func.isRequired,
  };

  static defaultProps = {
    viewGross: false,
    paDeleteTimecards: false,
  };

  state = {
    allowViewGross: false,
    allowPaDeleteTimecards: false,
  };

  toggleViewGross(checked) {
    this.setState({ allowViewGross: checked });
    if (this.props.onToggleViewGross) {
      this.props.onToggleViewGross(checked);
    }
  }

  togglePaDeleteTimecards(checked) {
    this.setState({ allowPaDeleteTimecards: checked });
    if (this.props.onTogglePaDeleteTimecards) {
      this.props.onTogglePaDeleteTimecards(checked);
    }
  }

  render() {
    const { classes, enableToggle, onAddApprovalFlow } = this.props;

    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.noBorder}>
              <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                  {enableToggle && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.props.viewGross}
                            onChange={(_, checked) =>
                              this.toggleViewGross(checked)
                            }
                            color="primary"
                          />
                        }
                        label="Allow department heads to view gross"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.props.paDeleteTimecards}
                            onChange={(_, checked) =>
                              this.togglePaDeleteTimecards(checked)
                            }
                            color="primary"
                          />
                        }
                        label="Allow payroll accountants to delete timecards"
                      />
                    </FormGroup>
                  )}
                </CardContent>
              </Card>
            </TableCell>
            <TableCell>
              <Button
                className={classes.approvalButton}
                key="addApprovalFlow"
                color="primary"
                variant="contained"
                size="medium"
                onClick={onAddApprovalFlow}
              >
                {'+ Add Approval Flow'}
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  withStyleSheet('DepartmentHead', style),
)(DepartmentHead);
