import { buildUrl } from '../api';
const URL_TIMECARD_DAYS = '/projects/:projectId/timecards/bulk';
const URL_FILTERS_BY_TYPE = '/projects/:projectId/timecards/bulk/filter';
const URL_TIMECARD_COUNT = '/projects/:projectId/timecards/bulk/count';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async fetch({ projectId, data }) {
    const url = buildUrl(URL_TIMECARD_DAYS, { projectId });
    const rsp = await clientV2.post(url, data);
    return rsp.data;
  },

  async fetchFilters({ projectId, data }) {
    const filterType = data.type;
    const filterName = filterType.replace(/(details\.)|(\.code)/g, '');
    const url = buildUrl(URL_FILTERS_BY_TYPE + `?${filterName}`, {
      projectId,
    });
    const rsp = await clientV2.post(url, data);
    return rsp.data;
  },

  async fetchCount({ projectId, data }) {
    const url = buildUrl(URL_TIMECARD_COUNT, { projectId });
    const rsp = await clientV2.post(url, data);
    return rsp.data;
  },
});
