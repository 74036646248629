import React, { useRef, useState, useEffect, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';

import { getErrors } from 'feature/DTS/dtsUtils.js';

//shared components
import Portal from 'components/Shared/ReactTable/Portal';
import CopyToAll from 'components/Shared/ReactTable/CopyToAll';

//local components
import SelectCell from './SelectCell';
import TimeCell from './TimeCell';
import TextCell from './TextCell';
import CheckboxCell from './CheckboxCell';
import DisplayOnlyCell from './DisplayOnlyCell';

import { baseCellStyles } from 'feature/DTS/dtsUtils';
import { findNextValidInput } from 'utils/reactTableUtils';

// Selectors
import { getIsProjectCompleted } from 'selectors/project';

const useStyles = makeStyles(({ palette }) => ({
  root: baseCellStyles,
  input: {
    fontSize: 12,
    minWidth: 75,
    height: 24,
    padding: 0,
    backgroundColor: palette.background.default,
  },
  disabled: {
    backgroundColor: palette.gray['+9'],
    '&::before': {
      borderBottomStyle: 'solid !important',
    },
  },
  //These styles are used in child cells
  errorIcon: {
    color: palette.error.main,
    fontSize: 14,
    cursor: 'default',
  },
  warnIcon: {
    color: palette.warning.main,
    fontSize: 14,
    cursor: 'default',
  },
  warningColor: {
    '&::after': {
      borderBottomColor: `${palette.warning.main} !important`,
    },
  },
  focusColor: {
    '&::after': {
      borderBottomColor: `${palette.secondary['+3']} !important`,
    },
  },
}));

const mapState = state => ({
  isProjectCompleted: getIsProjectCompleted(state),
});

// keep this for when I can implement this feature properly
// run this saga instead of the standard CTA for locationType
// signature:       onCtaSoloSelect({ data, columnId, val });
// data is the entire table data, will need to pull from BaseCell props.
// const mapDispatch = dispatch => ({
//   onCtaSoloSelect: params => {
//     console.log('Solo Select', params);
//     // dispatch(actions.copyToAllSoloSelect());
//   },
// });

// Default editable cell renderer
const BaseCell = props => {
  const {
    value,
    row,
    row: {
      original,
      index,
      original: { editable, dayType, errors, warnings },
    },
    column,
    column: { id: columnId, type: columnType },
    isProjectCompleted,
    updateDTSData,
    data,
    containerRef,
    editableCounts,
  } = props;

  const rowsCount = data.length;

  //variables
  const missingDayType = !(dayType && dayType.id);
  const disabled = columnId !== 'dayType' && missingDayType;

  const isStatusCell = columnId === 'userFriendlyTimecardStatus';
  const isHoursCell = columnId === 'hoursWorked';
  const isOccCodeCell = columnId === 'occupationCode';
  const isDealMemo = columnId === 'dealMemo';
  const hideCTA = isDealMemo || isOccCodeCell;
  //cck is a 'mask' type, but still needs to be flagged as a dropdown
  const isDropDown =
    columnType === 'auto-complete' || columnId === 'combineCheck';

  const error = getErrors({ columnId, errors, warnings });

  const baseClasses = useStyles();
  //data hooks
  const outerRef = useRef(null);
  const blurRef = useRef(null); //ref to store onBlur timeout

  const [localVal, setLocalVal] = useState(value);
  const [ctaFocus, setCTAFocus] = useState(null);

  const isIPad = () => {
    return (
      /iPad|Macintosh/.test(navigator.userAgent) && 'ontouchend' in document
    );
  };
  const toggleCTA = val => {
    const isIpad = isIPad();
    const elements = document.getElementsByClassName('copyAllButton');
    const outer = outerRef.current;

    if (isIpad) {
      if (elements.length > 0) {
        Array.from(elements).forEach(element => {
          element.style.display = 'none';
        });
      }
      //if the value is true/false, we are toggling the CTA button
      if (val !== null) {
        setCTAFocus(outer);
        const nextElem = outer?.nextElementSibling;
        if (nextElem) {
          const childElem = nextElem?.firstChild;
          if (childElem) {
            //selected element should display the CTA button
            childElem.style.display = 'block';
          }
        }
      }
    } else {
      if (val === true) {
        setCTAFocus(outer);
      } else if (val === false) {
        setCTAFocus(null);
      } else {
        setCTAFocus(curr => (curr ? null : outer));
      }
    }
  };

  const updateTable = useCallback(
    ({ val = localVal, cta = false, unrounded }) => {
      const updateParams = {
        original,
        rowIndex: index,
        columnId,
        newVal: val,
        unrounded,
        cta,
      };
      updateDTSData(updateParams);
    },
    [columnId, index, localVal, original, updateDTSData],
  );

  const clearBlurTimeout = useCallback(() => {
    clearTimeout(blurRef.current);
    blurRef.current = false;
  }, [blurRef]);

  //if value was updated by blur function, pass here
  const baseOnBlur = useCallback(
    (val, options) => {
      clearBlurTimeout();
      const args = { val };
      if (options?.cta) args.cta = true;
      updateTable(args);
    },
    [clearBlurTimeout, updateTable],
  );

  //
  // useEffect Hooks - Must be placed before 1st return statement
  // ------------------------------------------------------------
  // ------------------------------------------------------------

  //keep localVal in sync if table data changes elsewhere
  useEffect(() => {
    if (ctaFocus === null || isDropDown) {
      // prevent updates when cell is in focus
      // prevents update loop from happening with the setTimeout.
      // shouldn't cause issues since table is disabled when table is saving/loading
      setLocalVal(value);
    }
  }, [value, ctaFocus, isDropDown]);

  //update table data if cell has been in focus with no change in value
  useEffect(() => {
    if (!isDropDown) {
      if (blurRef.current) {
        clearBlurTimeout();
      }
      if (ctaFocus) {
        blurRef.current = setTimeout(() => {
          blurRef.current = false;
          baseOnBlur();
        }, 1000);
      }
    }
  }, [localVal, isDropDown, ctaFocus, baseOnBlur, clearBlurTimeout]);

  // non editable display
  if (!editable || isStatusCell || isHoursCell || isProjectCompleted) {
    return <DisplayOnlyCell baseClasses={baseClasses} {...props} />;
  }

  let Cell;
  switch (columnType) {
    case 'mask': //combineCheck and accountCode are the only mask types
      Cell = columnId === 'combineCheck' ? SelectCell : TextCell;
      break;
    case 'auto-complete':
      Cell = SelectCell;
      break;
    case 'time':
      Cell = TimeCell;
      break;
    case 'checkbox':
      Cell = CheckboxCell;
      break;

    default:
      Cell = TextCell;
      break;
  }

  //set local cell value. Any normalization is done in cell
  const baseOnChange = val => {
    setLocalVal(val);
  };

  const copyToAll = () => {
    toggleCTA(null);
    let val = localVal;
    let unrounded;
    if (columnType === 'time') {
      //this serves as a flag that we need to apply individual rounding to this CTA
      unrounded = val;
    }

    updateTable({ cta: true, val, unrounded });
  };

  return (
    <div
      className={baseClasses.root}
      ref={outerRef}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          let nextElement;
          let params = {
            rowIndex: index,
            columnId,
            direction: e.shiftKey ? 'up' : 'down',
            circulating: true,
            rowsCount,
          };
          nextElement = findNextValidInput(params);

          nextElement && nextElement.select();
        }
        if (
          (e.metaKey || e.ctrlKey) &&
          e.shiftKey &&
          e.key.toLowerCase() === 'a'
        ) {
          e.preventDefault();
          copyToAll(columnId, localVal, false);
        }
      }}
    >
      <Cell
        value={value}
        row={row}
        column={column}
        disabled={disabled}
        toggleCTA={toggleCTA}
        hasFocus={!!ctaFocus}
        baseClasses={baseClasses}
        baseOnChange={baseOnChange}
        baseOnBlur={baseOnBlur}
        localVal={localVal}
        error={error}
        id={`${index}.${columnId}`}
        rowsCount={rowsCount}
        containerRef={containerRef}
      />
      {ctaFocus && !hideCTA && (
        <Portal variant="dts" targetDom={ctaFocus}>
          <div className="copyAllButton">
            <CopyToAll
              variant="dts"
              columnId={columnId}
              value={localVal}
              copyToAll={copyToAll}
              containerRef={containerRef}
              editableCounts={editableCounts}
            />
          </div>
        </Portal>
      )}
    </div>
  );
};

BaseCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  row: PropTypes.shape({
    original: PropTypes.object,
    index: PropTypes.number.isRequired,
  }).isRequired,
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
  }).isRequired,
  isProjectCompleted: PropTypes.bool.isRequired,
  updateDTSData: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  containerRef: PropTypes.object.isRequired,
  editableCounts: PropTypes.number,
};

export default compose(connect(mapState))(BaseCell);
