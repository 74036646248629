import React from 'react';
import PropTypes from 'prop-types';
import { FullWidthLayout } from 'components/Layout';

const DefaultLayout = ({ children, headers, showBackBtn, path }) => (
  <FullWidthLayout
    headers={headers}
    fullWidth
    showBackBtn={showBackBtn}
    path={path}
  >
    {children}
  </FullWidthLayout>
);

DefaultLayout.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ).isRequired,
  children: PropTypes.node.isRequired,
  showBackBtn: PropTypes.bool,
  path: PropTypes.string,
};

export default DefaultLayout;
