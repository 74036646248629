import React from 'react';
import { Field } from 'redux-form';
import clsx from 'clsx';
import { TextField } from 'components/Shared/redux';
import { FormControl, Typography, Paper, Tabs, Tab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AutoComplete } from 'components/Shared/AutoComplete';
import moment from 'moment';
import { DATE_FORMAT_DISPLAY } from 'components/Shared/constants';

import { DatePicker } from '@mui/x-date-pickers-pro';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 500,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    marginBottom: 15,
    '& > *': {
      width: '100%',
    },
  },
  dateField: {
    marginRight: theme.spacing(2),
  },
  titleBold: {
    fontWeight: 600,
  },
  radio: {
    maginTop: 25,
    width: '10%',
  },
  tabContent: {
    padding: 24,
    paddingBottom: 0,
  },
}));

function TabContainer(props) {
  return (
    <Typography component="div" className={props.klass}>
      {props.children}
    </Typography>
  );
}

export default function EditScheduledReport({
  reportNames,
  report,
  projects,
  initialValues,
  change,
  formSyncErrors,
  submitFailed,
}) {
  const classes = useStyles();
  let initialTab = 0;
  let frequency = report.frequency;
  if (!frequency) {
    frequency = initialValues.frequency;
  }
  if ((!!report.id || !!initialValues.id) && frequency === 'hourly') {
    initialTab = 1;
  }
  const [tabValue, setTabValue] = React.useState(initialTab);
  // const tomorrow = addDays(new Date(), 1);
  const tomorrow = moment().add(1, 'days');
  const inputFormat = [
    'MM-DD-YYYY',
    'ddd MMM D YYYY HH:mm:ss [PST]',
    'YYYY-MM-DDTHH:mm:ssZ',
  ];

  const inputStartDate = report.startDate
    ? moment(report.startDate, inputFormat)
    : null;

  const inputEndDate = report.endDate
    ? moment(report.endDate, inputFormat)
    : null;

  function onDateChange(name, val) {
    const newValue = val === null ? null : val.format(DATE_FORMAT_DISPLAY);
    change(name, newValue);
  }

  function onChangeRadio(field) {
    if (field === 'endDate') {
      change('endOccurences', null);
      change('endNever', null);
    } else if (field === 'endNever') {
      change('endOccurences', null);
      change('endDate', null);
    } else {
      change('endNever', null);
      change('endDate', null);
    }
  }

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
    newValue === 0
      ? change('frequency', 'daily')
      : change('frequency', 'hourly');
  }

  function renderTabs() {
    const frequencyTabs = [];
    if (!!report.id && frequency === 'daily') {
      frequencyTabs.push(<Tab key="dailyDaily" label="Daily" />);
      frequencyTabs.push(<Tab key="dailyHourly" label="Hourly" disabled />);
    }
    if (!!report.id && frequency === 'hourly') {
      frequencyTabs.push(<Tab key="hourlyDaily" label="Daily" disabled />);
      frequencyTabs.push(<Tab key="hourlyHourly" label="Hourly" />);
    }
    if (!report.id) {
      frequencyTabs.push(<Tab key="Daily" label="Daily" />);
      frequencyTabs.push(<Tab key="Hourly" label="Hourly" />);
    }
    return (
      <Paper elevation={0}>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          {frequencyTabs}
        </Tabs>
        {tabValue === 0 && (
          <TabContainer klass={classes.tabContent}>
            {renderDaily()}
          </TabContainer>
        )}
        {tabValue === 1 && (
          <TabContainer klass={classes.tabContent}>
            {renderHourly()}
          </TabContainer>
        )}
      </Paper>
    );
  }

  function renderDaily() {
    return (
      <Paper elevation={0}>
        <div className={classes.row}>
          <Field
            key="startDateDaily"
            component={DatePicker}
            name="startDate"
            props={{
              onChange: val => onDateChange('startDate', val),
              mask: '__-__-____',
              format: DATE_FORMAT_DISPLAY,
              value: inputStartDate,
              clearable: true,
              label: 'Start Date',
              disabled: !!report.id,
              minDate: tomorrow,
              autoOk: true,
              error: submitFailed && !!formSyncErrors.startDate,
              helperText: submitFailed ? formSyncErrors.startDate : '',
            }}
          />
        </div>
        <div className={classes.row}>
          <Field
            className={classes.field}
            component={TextField}
            InputProps={{ inputProps: { min: 0 } }}
            type="number"
            label="Repeat Every day(s)"
            name="repetition"
          />
        </div>
        <Typography className={classes.titleBold}>End</Typography>
        <div className={classes.row}>
          <Field
            name="end"
            component="input"
            type="radio"
            value="endDate"
            checked={report.end === 'endDate'}
            className={classes.radio}
            onChange={(event, endDate) => onChangeRadio('endDate')}
          />{' '}
          <Field
            key="endDateDaily"
            component={DatePicker}
            name="endDate"
            props={{
              onChange: val => onDateChange('endDate', val),
              mask: '__-__-____',
              format: DATE_FORMAT_DISPLAY,
              value: inputEndDate,
              clearable: true,
              label: 'End Date',
              disabled: report.end !== 'endDate',
              minDate:
                tomorrow > moment(inputStartDate)
                  ? tomorrow
                  : moment(inputStartDate),
              autoOk: true,
              error: submitFailed && !!formSyncErrors.endDate,
              helperText: submitFailed ? formSyncErrors.endDate : '',
              renderInput: props => <TextField {...props} />,
            }}
          />
        </div>
        <div className={classes.row}>
          <Field
            name="end"
            component="input"
            type="radio"
            className={classes.radio}
            value="endNever"
            onChange={(event, endNever) => onChangeRadio('endNever')}
          />
          <div>Never</div>
        </div>
        <div className={classes.row}>
          <Field
            name="end"
            component="input"
            type="radio"
            className={classes.radio}
            value="endOccurences"
            onChange={(event, endOccurences) => onChangeRadio('endOccurences')}
          />{' '}
          <Field
            className={classes.field}
            component={TextField}
            disabled={report.end !== 'endOccurences'}
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            label="After Occurence(s)"
            name="endOccurences"
            props={{
              error: submitFailed && !!formSyncErrors.endOccurences,
              helperText: submitFailed ? formSyncErrors.endOccurences : '',
            }}
          />
        </div>
      </Paper>
    );
  }

  function renderHourly() {
    return (
      <Paper elevation={0}>
        <div className={classes.row}>
          <Field
            key="startDateHourly"
            component={DatePicker}
            name="startDate"
            props={{
              onChange: val => onDateChange('startDate', val),
              mask: '__-__-____',
              format: DATE_FORMAT_DISPLAY,
              value: inputStartDate,
              clearable: true,
              label: 'Start Date',
              disabled: !!report.id,
              minDate: tomorrow,
              autoOk: true,
              error: submitFailed && !!formSyncErrors.startDate,
              helperText: submitFailed ? formSyncErrors.startDate : '',
              renderInput: props => <TextField {...props} />,
            }}
          />
        </div>
        <div className={classes.row}>
          <Field
            className={classes.field}
            component={TextField}
            InputProps={{ inputProps: { min: 0 } }}
            type="number"
            label="Hour Increments"
            name="repetition"
          />
        </div>
        <div className={classes.row}>
          <Field
            key="endDateHourly"
            component={DatePicker}
            name="endDate"
            props={{
              onChange: val => onDateChange('endDate', val),
              mask: '__-__-____',
              format: DATE_FORMAT_DISPLAY,
              value: inputEndDate,
              clearable: true,
              label: 'End Date',
              minDate:
                tomorrow > moment(inputStartDate)
                  ? tomorrow
                  : moment(inputStartDate),
              autoOk: true,
              error: submitFailed && !!formSyncErrors.endDate,
              helperText: submitFailed ? formSyncErrors.endDate : '',
              disabled:
                report.end !== 'endDate' && report.frequency === 'daily',
            }}
          />
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <div className={classes.row}>
        <FormControl
          className={clsx(classes.field, classes.autoCompleteSettings)}
        >
          <AutoComplete
            list={reportNames}
            name="reportType"
            label=" Report Name"
            fieldLabel="name"
            fieldValue="key"
            placeholder="Select Report Name"
          />
        </FormControl>
      </div>
      <div className={classes.row}>
        <Field
          className={classes.field}
          component={TextField}
          normalize={val => val.trim()}
          label="Recipients Email (separate multiple with comma)"
          name="recipientEmails"
        />
      </div>
      <div className={classes.row}>
        <FormControl
          className={clsx(classes.field, classes.autoCompleteSettings)}
        >
          <AutoComplete
            list={projects}
            name="projectIds"
            label="Projects"
            fieldLabel="name"
            fieldValue="id"
            placeholder="Select Project(s)"
            multiSelect
            joinValues
            isDefaultSelected={false}
          />
        </FormControl>
      </div>
      <div className={classes.row}>{renderTabs()}</div>
    </Paper>
  );
}
