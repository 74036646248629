import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
// components
import { AutoComplete } from 'components/Shared/AutoComplete';
import { statesProps, stateProps } from 'components/props/locations';
import { MEALS } from 'components/Shared/constants';
import { isFieldEnabled } from 'utils/weekUtils';
import ReadOnlyField from 'components/Shared/Text/ReadOnlyField';

import AdditionalMealFields from './AdditionalMealFields';

const LocationAndMeals = ({
  classes,
  states,
  editableFields,
  isViewOnly,
  timecard,
  error,
  change,
  showLastMan1In,
  setShowLastMan1In,
  stateOptions,
  cityOptions,
  onFetchStates,
  onFetchCities,
  onResetLoc,
  caRegion,
  showNdm,
  setShowNdm,
  showNdb,
  setShowNdb,
}) => {
  const locationEditable = isFieldEnabled(editableFields, 'location');
  const mealsEditable = isFieldEnabled(editableFields, 'meals');

  const getErrorText = field => {
    return error && error[field] ? _.startCase(error[field].join(' ')) : null;
  };
  const meal = _.find(MEALS, m => m === timecard.meals || 1);

  function handleChangeState(newValue) {
    change(`htgStateId`, newValue && newValue.id);
    change(`htgCityId`, null);
    change(`city`, null);
    change(`htgCountyId`, null);
    change(`workCounty`, null);
    change(`htgSubdivisionId`, null);
    change(`workSubdivision`, null);
    change('subdivision', null);
  }

  function handleChangeCity(newValue) {
    change(`htgCityId`, newValue && newValue.id);
  }

  function loadStatesOnOpen() {
    const countryId = timecard?.htgCountryId;
    onFetchStates({ countryId });
  }

  function loadCitiesOnOpen() {
    const stateId = timecard?.htgStateId;
    if (stateId) onFetchCities({ stateId });
  }
  const stateLabel = caRegion ? 'Work Prov' : 'Work State';
  const statePlaceHolder = caRegion ? 'Select Work Prov' : 'Select Work State';
  return (
    <Grid container>
      <Grid item xs={12} sm={4} className={classes.autoCompleteCol}>
        {isViewOnly && (
          <ReadOnlyField
            title={stateLabel}
            value={timecard?.state?.name || ''}
          />
        )}
        {!isViewOnly && (
          <AutoComplete
            list={stateOptions}
            name="state"
            label={stateLabel}
            klass={classes.autoComplete}
            fieldLabel="name"
            fieldValue="id"
            placeholder={statePlaceHolder}
            disabled={isViewOnly || !locationEditable}
            helperText={getErrorText('htgStateId')}
            error={getErrorText('htgStateId') !== null}
            required
            defaultSort
            objectSelect
            onChange={handleChangeState}
            onMenuOpen={loadStatesOnOpen}
            onMenuClose={() => onResetLoc('workState')}
          />
        )}
      </Grid>
      {!caRegion && (
        <Grid item xs={12} sm={4} className={classes.autoCompleteCol}>
          {isViewOnly && (
            <ReadOnlyField
              title="Work City"
              value={(timecard.city && timecard.city.name) || ''}
            />
          )}
          {!isViewOnly && (
            <AutoComplete
              list={cityOptions || []}
              name="city"
              label="Work City"
              klass={classes.autoComplete}
              fieldLabel="name"
              fieldValue="id"
              placeholder="Select Work City"
              disabled={isViewOnly || !locationEditable}
              helperText={getErrorText('htgCityId')}
              error={getErrorText('htgCityId') !== null}
              required
              defaultSort
              objectSelect
              onMenuOpen={loadCitiesOnOpen}
              onChange={handleChangeCity}
              onMenuClose={() => onResetLoc('workCity')}
            />
          )}
        </Grid>
      )}
      <Grid item xs={12} sm={4} className={classes.autoCompleteCol}>
        {isViewOnly && (
          <ReadOnlyField title="Meals" value={meal ? meal.label : '-'} />
        )}
        {!isViewOnly && (
          <>
            <AdditionalMealFields
              disabled={isViewOnly}
              mealsEditable={mealsEditable}
              timecard={timecard}
              change={change}
              showLastMan1In={showLastMan1In}
              setShowLastMan1In={setShowLastMan1In}
              showNdm={showNdm}
              setShowNdm={setShowNdm}
              showNdb={showNdb}
              setShowNdb={setShowNdb}
              caRegion={caRegion}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

LocationAndMeals.propTypes = {
  classes: PropTypes.object.isRequired,
  states: statesProps,
  currentState: stateProps,
};

export default LocationAndMeals;
