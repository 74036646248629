import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Clock from 'assets/icons/Clock';
import Check from 'assets/icons/Check';
import InfoSquare from 'assets/icons/InfoSquare';
import AlertTriangle from 'assets/icons/AlertTriangle';

const useStyle = makeStyles(({ palette }) => ({
  root: {
    borderRadius: 16,
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '2px 8px 2px 6px',
    fontFamily: 'inherit',
    fontSize: '0.75rem',
    lineHeight: '18px',
    fontWeight: 500,
    textDecoration: 'none',
    ' & .icon': {
      marginRight: 4,
    },
  },
  altWeight: {
    fontWeight: 200,
  },

  primary: {
    // ready for DH, PA, Approver 1, 2, 3, submitted to C&C,
    border: `1px solid ${palette.badge.blue.light}`,
    backgroundColor: palette.badge.blue.background,
    color: palette.badge.blue.dark,
  },
  secondary: {
    // processing
    border: `1px solid ${palette.badge.gray.light}`,
    backgroundColor: palette.badge.gray.background,
    color: palette.badge.gray.dark,
  },
  warning: {
    // incomplete, Ready for me
    border: `1px solid ${palette.badge.orange.light}`,
    backgroundColor: palette.badge.orange.background,
    color: palette.badge.orange.dark,
  },
  error: {
    // Rejected
    border: `1px solid ${palette.badge.red.light}`,
    backgroundColor: palette.badge.red.background,
    color: palette.badge.red.dark,
  },
  tertiary: {
    // paid
    border: `1px solid ${palette.badge.green.light}`,
    backgroundColor: palette.badge.green.background,
    color: palette.badge.green.dark,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
}));

const OutlinedIconBadge = props => {
  const {
    children,
    primary,
    secondary,
    tertiary,
    status,
    warning,
    error,
    uppercase,
    altWeight,
  } = props;
  const classes = useStyle();
  const theme = useTheme();
  const labelCls = clsx(classes.root, {
    [classes.primary]: primary || status === 'primary',
    [classes.uppercase]: uppercase,
    [classes.altWeight]: altWeight,
    [classes.secondary]: secondary || status === 'secondary',
    [classes.tertiary]: tertiary || status === 'tertiary',
    [classes.warning]: warning || status === 'warning',
    [classes.error]: error || status === 'error',
  });

  const stokeColor = React.useMemo(
    () => ({
      primary: theme.palette.badge.blue.main,
      secondary: theme.palette.badge.gray.main,
      warning: theme.palette.badge.orange.main,
      error: theme.palette.badge.red.main,
      tertiary: theme.palette.badge.green.main,
    }),
    [theme],
  );

  const Icon = React.useMemo(() => {
    switch (status) {
      case 'primary':
      case 'secondary':
        return InfoSquare;
      case 'warning':
        return Clock;
      case 'error':
        return AlertTriangle;
      case 'tertiary':
        return Check;
      default:
        return null;
    }
  }, [status]);
  return (
    <Typography className={labelCls}>
      {Icon && (
        <Icon
          color={stokeColor[status] || stokeColor.primary}
          className="icon"
        />
      )}
      {children}
    </Typography>
  );
};

export default OutlinedIconBadge;
