import { all } from 'redux-saga/effects';
import Debug from 'debug';
import Auth from 'providers/Auth';
import history from 'providers/history';

import authenticate from './authentication';
import flags from './flags';
import users from './users';
import masterDepartments from './masterDepartments';
import events from './events';
import locations from './locations';
import projects from './projects';
import settings from './settings';
// import adminReviewFlows from './admin/reviewFlow';
import reviewFlows from './reviewFlows';
import userInfo from './userInfo';
import timecards from './timecards';
import projectAllowances from './projectAllowances';
import profile from './profile';
import reports from './reports';
import reviews from './reviews';
import invitations from './invitations';
import teamTimecards from './teamTimecards';
import departments from './departments';
import wtc from './wtc';
import bulkEdit from './bulkEdit';
import searchTimecards from './searchTimecards';
import dts from './dts';
import searchInvoices from 'feature/SearchInvoices/sagas';
import crewList from 'feature/CrewList/sagas';
import fetchTemplateFlow from './invoiceTemplate';
import moveTimecards from './moveTimecards';
import digitalEdits from './digitalEdits';
import empTimecard from 'feature/EmployeeTimecard/sagas';

const auth = new Auth();
const debug = Debug('sagas');

const createRootSaga = ({ api, cookie }) =>
  function* rootSaga() {
    yield all([
      // please sort alphabetically (really?)
      authenticate({ auth, api, history, debug }),
      bulkEdit({ api, history, debug }),
      crewList({ api, history, debug }),
      departments({ api, history, debug }),
      dts({ api, history, debug }),
      events({ api, history, debug }),
      empTimecard({ api, history, debug }),
      fetchTemplateFlow({ api, history, debug }),
      flags({ api, history, debug }),
      digitalEdits({ api, history, debug }),
      invitations({ api, history, debug }),
      locations({ api, history, debug }),
      masterDepartments({ api, history, debug }),
      moveTimecards({ api, history, debug }),
      projects({ api, history, debug }),
      projectAllowances({ api, history, debug }),
      profile({ api, history, debug }),
      reports({ api, history, debug }),
      reviewFlows({ api, history, debug }),
      reviews({ api, history, debug }),
      searchInvoices({ api, history, debug }),
      searchTimecards({ api, history, debug }),
      settings({ api, history, debug }),
      teamTimecards({ api, history, debug }),
      timecards({ api, history, debug }),
      users({ api, history, debug }),
      userInfo({ api, history, debug }),
      wtc({ api, history, debug }),
    ]);
  };

export default createRootSaga;
