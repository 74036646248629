import React from 'react';

import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

//components
import LoadingSquare from 'containers/Employees/Reviews/Shared/LoadingSquare';

const useStyles = makeStyles(theme => ({
  BulkProgress: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    zIndex: 99,
  },
}));

const BulkProgress = props => {
  const classes = useStyles();
  const { saving } = props;

  if (!saving) return null;

  return (
    <main className={classes.BulkProgress}>
      <LoadingSquare />
    </main>
  );
};

BulkProgress.propTypes = { children: PropTypes.element };

export default BulkProgress;
