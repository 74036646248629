import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import {
  TextField,
  TitleBox,
  ContentBox,
  FooterBox,
  FooterButton,
} from '../Shared/styledComponents';

import * as actions from '../actions';
import { hide as closeModal } from 'actions/modalDialog';

import withModalDialog from 'decorators/withModalDialog';

import { DAY_LEVEL_COMMENT_MODEL } from './modalNames';
import {
  getNewComment,
  getSelectedCommentDay,
  getComments,
} from '../selectors';
import { INPUT_FIELD_CHARACTER_LIMIT } from 'components/Shared/constants';

const mapState = (state, ownProps) => ({
  comment: getNewComment(state),
  selectedCommentDay: getSelectedCommentDay(state),
  comments: getComments(state),
});
const mapDispatch = dispatch => ({
  onSaveDayLevelComments: (comment, date) => {
    dispatch(actions.saveComment({ commentType: 'day', referenceDate: date }));
  },
  onSetNewComment: comment =>
    dispatch(actions.setNewComment({ newComment: comment })),
  onCloseModal: () => {
    dispatch(closeModal({ dialog: DAY_LEVEL_COMMENT_MODEL }));
  },
});

const DayLevelCommentModel = props => {
  const {
    onSaveDayLevelComments,
    onCloseModal,
    comment,
    onSetNewComment,
    selectedCommentDay,
    comments,
  } = props;

  const handleClose = () => {
    onSetNewComment('');
    onCloseModal();
  };
  const dayLevelComments = comments?.filter(p => p.type === 'day');
  const commentObj = dayLevelComments?.find(d =>
    moment(d.referenceDate, 'YYYY-MM-DD').isSame(
      selectedCommentDay?.date,
      'YYYY-MM-DD',
    ),
  );

  const onSave = () => {
    onSaveDayLevelComments(comment, selectedCommentDay.date);
  };
  const commentsLimit = INPUT_FIELD_CHARACTER_LIMIT.comments;
  return (
    <Box sx={{ width: 500 }}>
      <TitleBox>
        <Box>{moment(selectedCommentDay?.date).format('dddd')} Comment</Box>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </TitleBox>
      {!_.isEmpty(commentObj) && (
        <Box
          sx={{
            margin: '10px 25px',
            backgroundColor: 'gray.+9',
            p: '5px',
            borderRadius: ' 8px',
          }}
        >
          <Box>Day Comment</Box>
          <Box>
            <Tooltip title={commentObj?.comment || ''} placement="top">
              <span
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {commentObj?.comment || ''}
              </span>
            </Tooltip>
          </Box>
        </Box>
      )}
      <ContentBox>
        <TextField
          width="Auto"
          multiline
          minRows={5}
          placeholder={'Write a comment here'}
          value={comment}
          autoFocus
          inputProps={{
            maxLength: commentsLimit,
            style: { paddingRight: '7px' },
          }}
          onChange={e => onSetNewComment(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter' && e.ctrlKey && comment.length > 0) {
              onSave();
            }
          }}
        />
      </ContentBox>
      <FooterBox>
        <FooterButton variant="outlined" onClick={handleClose}>
          Cancel
        </FooterButton>
        <FooterButton onClick={onSave} disabled={!comment}>
          {' '}
          Save
        </FooterButton>
      </FooterBox>
    </Box>
  );
};

DayLevelCommentModel.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
};

export default compose(
  connect(mapState, mapDispatch),
  withModalDialog({
    dialog: DAY_LEVEL_COMMENT_MODEL,
    maxWidth: 'md',
    roundedCorners: true,
  }),
)(DayLevelCommentModel);
