import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, getEmployeeSIN } from 'utils/helperFunctions';
import { reduxForm, getFormSyncErrors } from 'redux-form';
import { formatMessage, REQUIRED } from 'utils/messages';
import { INVALID_SSN_FORMAT, INVALID_SIN_FORMAT } from 'utils/messages';
import validator from 'utils/validator';
import _ from 'lodash';

//components
import InvitationComp from 'components/Employees/Profiles/Invitations';
import { LinearProgress } from '@mui/material';

//actions
import * as actions from 'actions/invitations';

//selectors
import { getInvitations, getInvitationsLoading } from 'selectors/invitations';

export const validate = values => {
  const errors = {
    valid: true,
  };

  const { ssn, sin, ssnMask } = values;

  let isSin = true;
  if (ssnMask && ssnMask.includes('-')) {
    isSin = getEmployeeSIN(values);
  }

  if (!isSin && !ssn) {
    errors.ssn = formatMessage(REQUIRED);
  }
  if (ssn && !isSin && !validator.isValidSsn(ssn)) {
    errors.ssn = formatMessage(INVALID_SSN_FORMAT);
  }
  if (isSin && !sin) {
    errors.sin = formatMessage(REQUIRED);
  }
  if (sin && isSin && !validator.isValidSin(sin)) {
    errors.sin = formatMessage(INVALID_SIN_FORMAT);
  }
  return errors;
};

const mapStateToProps = (state, { match }) => ({
  loading: getInvitationsLoading(state),
  invitation: getInvitations(state),
  initialValues: getInvitations(state),
  token: match.params.token,
  synchronousError: getFormSyncErrors('Invitations')(state),
});

const mapDispatchToProps = (dispatch, { match }) => ({
  onFetchData: () => {
    dispatch(actions.fetchInvitations({ token: match.params.token }));
  },
});

const onSubmit = (values, dispatch, { match, location }) => {
  const { sin, ...props } = values;

  let isSin = true;
  if (values?.ssnMask && values.ssnMask.includes('-')) {
    isSin = getEmployeeSIN(values);
  }
  values = {
    ...props,
    ssn: isSin ? values.sin : values.ssn,
  };
  var token = match.params;
  var projectId = values.projectId;
  dispatch(actions.saveInvitations({ values, token, projectId }));
};

class Invitations extends Component {
  componentDidMount() {
    const { onFetchData } = this.props;
    onFetchData();
  }

  render() {
    const { handleSubmit, loading, invitation, ...others } = this.props;
    if (_.isEmpty(invitation) || loading) return <LinearProgress />;
    return (
      <form onSubmit={handleSubmit}>
        <InvitationComp {...others} invitation={invitation} />
      </form>
    );
  }
}

Invitations.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invitation: PropTypes.object.isRequired,
  onFetchData: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'Invitations',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnChange: true,
    validate,
    onSubmit,
  }),
)(Invitations);
