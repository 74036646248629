import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px 4px',
  },
}));

const Cell = props => {
  const classes = useStyles();
  const {
    cell,
    cell: {
      column: { sticky }, //no padding on sticky cells
    },
  } = props;

  return (
    <td {...cell.getCellProps()} className={sticky ? '' : classes.root}>
      {cell.render('Cell')}
    </td>
  );
};

Cell.propTypes = {
  cell: PropTypes.object.isRequired,
};

// return true to use memo version
// return false to re-render comp
// export default memo(Cell, (prevProps, nextProps) => {
//   const prevVal =
//     JSON.stringify(prevProps.cell.value) +
//     JSON.stringify(prevProps.cell.column.id);
//   const nextVal =
//     JSON.stringify(nextProps.cell.value) +
//     JSON.stringify(nextProps.cell.column.id);
//   return prevVal === nextVal;
// });

export default Cell;
