import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import makeStyles from '@mui/styles/makeStyles';

import Table from './Table';
// containers
import UserEdit from './UserEdit';
import Impersonate from './Modals/Impersonate';
import InviteCrew from './Modals/InviteCrew';
import DealMemo from './Modals/DealMemo';
import DeleteCrew from './Modals/DeleteCrew';
// import UserDelete from 'containers/Admin/Projects/Users/UserDelete';
//actions
import * as actions from './actions';
import { show as showModal } from 'actions/modalDialog';
import { fetchDepartments } from 'actions/project';

//selectors
import { getUsers, getLoading, getStatuses, getSort } from './selectors';
import { currentUser, roleIsDH } from 'selectors/session';

import { setSort } from './CrewListUtils';

const mapState = (state, { match }) => ({
  loading: getLoading(state),
  users: getUsers(state),
  statuses: getStatuses(state),
  currentUser: currentUser(state),
  sort: getSort(state),
});

const mapDispatch = (dispatch, { location }) => ({
  onInitFetch: () => {
    dispatch(actions.init());
    dispatch(fetchDepartments());
  },
  onViewDealMemos: (dealMemoId, dealMemoCount) => {
    dispatch(actions.storeDealMemoId({ dealMemoId }));
    dispatch(actions.storeDealMemoCount({ dealMemoCount }));
    dispatch(showModal({ dialog: 'DealMemoDetails' }));
  },
  onEditCrewMember: param => {
    dispatch(actions.storeEditingUser({ editUser: param }));
    dispatch(showModal({ dialog: 'userEdit' }));
  },
  onInviteNonEmployee: params => {
    if (!params) {
      dispatch(showModal({ dialog: 'NonCrewInvite' }));
    } else {
      dispatch(showModal({ dialog: 'NonCrewInvite', modalParams: params }));
    }
  },
  onInviteEmployee: () => {
    // dispatch(showModal({ dialog: 'CrewInvite' }));
  },
  onDeleteEmployees: users => {
    dispatch(actions.editingUsers({ users }));
    dispatch(showModal({ dialog: 'DeleteCrew' }));
  },
  onImpersonate: userId => {
    dispatch(actions.editing({ userId }));
    dispatch(showModal({ dialog: 'impersonate' }));
  },
  onSort: column => {
    const sort = setSort(column);
    if (!!sort) {
      dispatch(actions.storeSort({ sort }));
    }
  },
  onCleanup: () => {
    dispatch(actions.clearUserList());
  },
  onExportUsers: users => {
    dispatch(actions.exportUsers(users));
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: '83vh',
    width: '95%',
  },
  container: {
    marginTop: 10,
  },
}));

const CrewList = props => {
  const {
    users,
    onInitFetch,
    onCleanup,
    onImpersonate,
    currentUser,
    onDeleteAction,
    onEditCrewMember,
    ...others
  } = props;

  const classes = useStyles();

  const componentDidMountRef = useRef(false);
  const componentWillUnmountRef = useRef(false);

  const isHotCostEnabled = !roleIsDH(currentUser.role);

  useEffect(() => {
    if (componentDidMountRef.current === false) {
      onInitFetch();
      componentDidMountRef.current = true;
    }
  }, [onInitFetch]);

  useEffect(() => {
    return () => {
      componentWillUnmountRef.current = true;
    };
  }, []);

  //Unmount Effect
  useEffect(() => {
    return () => {
      if (componentWillUnmountRef.current === true) {
        onCleanup();
      }
    };
  }, [onCleanup]);

  return (
    <div className={classes.container}>
      <Table
        users={users}
        currentUser={currentUser}
        onImpersonate={onImpersonate}
        onEditCrewMember={onEditCrewMember}
        isHotCostEnabled={isHotCostEnabled}
        {...others}
      />
      <UserEdit />
      <Impersonate />
      <DealMemo />
      <InviteCrew users={users} />
      <DeleteCrew />
    </div>
  );
};

export default compose(connect(mapState, mapDispatch))(CrewList);
