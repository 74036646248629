import { all, takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import camelCase from 'camelcase-keys';
import { getProject as project } from 'selectors/routeParams';

import { showAlert } from 'actions/alert';
import * as actions from 'actions/userInfo';
import { setupPendoInfo, delayOnValue } from 'utils/helperFunctions';
import { ROLE_LABELS, IA } from 'components/props/profiles';
import { readableUsers } from 'feature/CrewList/CrewListUtils';

export function* fetchUserInfo(api, projectId) {
  yield put(actions.loading({ loading: true }));
  const data = yield call(api.projects.userInfo, { projectId });
  if (data) {
    let dataArr = [
      { ...data, worksightId: data.workSightId, roles: data.projectRoles },
    ];
    const userArr = readableUsers(dataArr);
    const obj = userArr[0];

    let role = '';
    if (
      data.projectRoles.includes(IA) &&
      obj.readableRole !== ROLE_LABELS[IA]
    ) {
      //multiple roles dividing by |
      role = obj.readableRole + ' | ' + ROLE_LABELS[IA];
    } else {
      role = obj.readableRole;
    }
    //storing User data in session for pendo
    const userObj = {
      role: role,
      email: data?.email || '',
    };
    sessionStorage.setItem('pendo_profile', JSON.stringify(userObj));
    //setting up pendo parameters
    setupPendoInfo();
    const activeUser = camelCase(data, { deep: true });
    yield put(actions.store({ projectId, activeUser }));
  }
  yield put(actions.loading({ loading: false }));
}

function* fetch(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield delayOnValue(project);
    if (projectId) {
      yield call(fetchUserInfo, api, projectId);
    }

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.store({ settings: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
  }
}

function* fetchPermissions(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield delayOnValue(project);
    const permissions = yield call(api.projects.getPermissions, { projectId });
    yield put(actions.storePermissions({ projectId, permissions }));
    yield put(actions.loading({ loading: false }));
  } catch (error) {
    debug(error);
  }
}

function* fetchWorksightId(api, debug, params) {
  try {
    const userId = params.userId;
    const data = yield call(api.users.worksightId, { userId });
    const worksightId = data.worksightId;
    yield put(actions.storeWorksightId({ worksightId }));
  } catch (e) {
    debug(e);
  }
}

export default function* userInfoFlow({ api, debug }) {
  yield all([
    takeEvery(`${actions.fetch}`, fetch, api, debug),
    takeEvery(`${actions.fetchById}`, fetchUserInfo, api, debug),
    takeEvery(`${actions.fetchPermissions}`, fetchPermissions, api, debug),
    takeLatest(`${actions.fetchWorksightId}`, fetchWorksightId, api, debug),
  ]);
}
