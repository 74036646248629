import _ from 'lodash';
// import { createSelector } from 'reselect';

export const getLoading = state =>
  _.get(state, 'invoiceTemplate.loading', false);
export const getTemplates = state =>
  _.get(state, 'invoiceTemplate.templates.list', []);

export const getSystemLocations = state =>
  _.get(state, 'invoiceTemplate.systemLocations', []);
export const getEditingTemplate = state =>
  _.get(state, 'invoiceTemplate.templates.editing', {});

export const getTemplateOption = state =>
  _.get(state, 'invoiceTemplate.invoiceTemplateOption', '');

export const getLocationTypes = state =>
  _.get(state, 'invoiceTemplate.locationTypes', []);
