import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  formatDateTimezone,
  getAbbreviationFromBackendTimezoneName,
} from 'utils/formatDate';
import { Badge } from 'components/Shared/Text';
import { DATE_FORMAT_HOUR_MIN } from 'components/Shared/constants';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
const screenOrientation = _.get(window, 'screen.orientation.type', '');
const useStyles = makeStyles(theme => ({
  content: {
    padding: 20,
    overflow: 'auto',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > *': {
      width: '100%',
      marginBottom: 0,
      marginTop: 0,
      paddingTop: 5,
      paddingBottom: 5,
      border: 0,
    },
  },
  mobileRow: {
    whiteSpace: 'nowrap',
    '& > *': {
      width: '100%',
      marginBottom: 0,
      marginTop: 0,
      paddingTop: 5,
      paddingBottom: 5,
      border: 0,
    },
  },
  blankSlate: {
    fontSize: '2.14285714em',
    background: theme.palette.background.paper,
    color: theme.palette.text.disabled,
    fontWeight: 700,
    fontStyle: 'italic',
    marginTop: 2,
    padding: 10,
    textAlign: 'center',
  },
  subHeading: {
    fontWeight: 500,
  },

  [theme.breakpoints.down('lg')]: {
    content: { padding: 10 },
    wrap: { padding: 0 },
    tableCellAdjust: {
      padding: '5px 16px',
    },
  },
}));
function TimecardHistory(props) {
  const {
    history,
    projectSettings,
    showTitle = true,
    loadingWtcHistory = false,
  } = props;
  const classes = useStyles();

  let historyRows = null;
  const rowStyle =
    screenOrientation === 'landscape-primary' ? classes.row : classes.mobileRow;

  if (history && history.length <= 0) {
    historyRows = (
      <TableRow>
        <TableCell colSpan={7} className={classes.blankSlate}>
          {loadingWtcHistory ? 'Loading history...' : 'No history'}
        </TableCell>
      </TableRow>
    );
  } else {
    historyRows = _.map(history, (event, index) => (
      <TableRow className={rowStyle} key={`${index}-history`}>
        <TableCell className={classes.tableCellAdjust}>
          <Typography color="inherit">
            {event.actor && event.actor.fullName ? event.actor.fullName : ''}
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCellAdjust}>
          <Typography color="inherit">
            {formatDateTimezone(
              event.createdAt,
              DATE_FORMAT_HOUR_MIN,
              projectSettings.timeZone,
            )}
            <b>{` (${getAbbreviationFromBackendTimezoneName(
              projectSettings.timeZone,
            )})`}</b>
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCellAdjust}>
          <Badge status={event.status} uppercase>
            {event.label ? event.label : '--'}
          </Badge>
        </TableCell>
      </TableRow>
    ));
  }

  return (
    <div className={classes.content}>
      {showTitle && (
        <Typography variant="subtitle1" className={classes.subHeading}>
          Timecard History
        </Typography>
      )}
      <Table>
        <TableBody>{historyRows}</TableBody>
      </Table>
    </div>
  );
}

export default TimecardHistory;
