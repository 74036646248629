import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Tooltip } from '@mui/material';
import MenuButton from './MenuButton';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  PopoverMenu: {
    width: '140px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  menuItem: {
    padding: '2px',
    cursor: 'pointer',
  },
}));

const PopoverMenu = props => {
  const classes = useStyles();
  const {
    contentList = [],
    Icon,
    tooltip,
    closeOnSelect = true,
    currentColor,
    disabled,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <MenuButton
        onClick={handleClick}
        tooltip={tooltip}
        color={currentColor}
        Icon={Icon}
        disabled={disabled}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.PopoverMenu}>
          {contentList.map((c, index) => (
            <Tooltip
              key={c.key}
              title={c.key}
              placement={index < 4 ? 'top' : 'bottom'}
            >
              <div
                className={classes.menuItem}
                onClick={() => {
                  c.onClick();
                  if (closeOnSelect) {
                    handleClose();
                  }
                }}
              >
                {c.content}
              </div>
            </Tooltip>
          ))}
        </div>
      </Popover>
    </>
  );
};

PopoverMenu.propTypes = {
  contentList: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node,
      onClick: PropTypes.func,
      key: PropTypes.string,
    }),
  ),
  Icon: PropTypes.elementType,
  tooltip: PropTypes.string,
  closeOnSelect: PropTypes.bool,
  currentColor: PropTypes.string,
  disabled: PropTypes.bool,
};

export default PopoverMenu;
