import React from 'react';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

const style = ({ palette }) => ({
  root: {},
  container: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    flexDirection: 'column',
    margin: '2px 0px',
    width: 14,
  },
  arrow: {
    color: palette.gray['+6'],
    padding: 0,
    margin: '-7px 0px',
    fontSize: 24,
  },
  activeSort: {
    color: palette.primary.main,
  },
});

const SortArrows = props => {
  const { classes, column } = props;
  const { isSortedDesc } = column;

  return (
    <span className={classes.container}>
      <ArrowDropUp
        className={clsx(classes.arrow, {
          [classes.activeSort]: isSortedDesc === false,
        })}
      />
      <ArrowDropDown
        className={clsx(classes.arrow, { [classes.activeSort]: isSortedDesc })}
      />
    </span>
  );
};

export default withStyles(style)(SortArrows);
