import _ from 'lodash';

export const getLoading = state => _.get(state, 'reports.loading', false);
export const getScheduledReports = state =>
  _.get(state, 'reports.scheduledReports', []);

export const getUserAccessReportDetails = state =>
  _.get(state, 'reports.userAccessReportDetails', []);

export const getRejectedLoading = state =>
  _.get(state, 'reports.rejectedLoading', false);

export const getClientsDetails = state =>
  _.get(state, 'reports.allClients', []);

export const getClientsById = state =>
  _.get(state, 'reports.clientDetails', []);

export const getUserAccessClientDetails = state =>
  _.get(state, 'reports.userAccessClientDetails', []);

export const getDBsList = state => _.get(state, 'reports.allDBs', []);
