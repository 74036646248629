import React, { useEffect, useState } from 'react';
import { compose } from 'utils/helperFunctions';
import clsx from 'clsx';
import { Badge, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PeopleIcon from '@mui/icons-material/People';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

// component
import SidebarHeader from './SidebarHeader';
import TimecardCounts from './TimecardCounts';
import FiltersList from './FiltersList';

const style = ({ palette }) => ({
  root: {
    display: 'flex',
    height: 'calc(100vh - 170px)',
    color: palette.gray['+2'],
    backgroundColor: palette.background.default,
    maxWidth: '300px',
    justifyContent: 'flex-start',
  },
  filters: {
    overflowY: 'auto',
  },
  drawerBorder: {
    width: 6,
    backgroundColor: `${palette.primary['+9']}50`,
    boxShadow: `4px 0 8px ${palette.primary.main}40`,
    position: 'relative',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      width: 11,
    },
  },
  drawerIcon: {
    position: 'absolute',
    transition: 'all 0.3s ease-in-out',
    top: '40%',
    backgroundColor: palette.background.paper,
    left: '-15px',
  },
  sidebarClosed: {
    display: 'flex',
    flexDirection: 'column',
    width: 100,
    paddingTop: 50,
  },
  sidebarOpen: {
    width: 285,
    marginLeft: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  widdy: {
    width: 285,
  },
  widdyCollapsed: {
    width: 40,
    display: 'flex',
    transition: 'all 0.3s ease-in-out',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  refineCollapsed: {
    fontSize: 14,
    textAlign: 'center',
  },
  buttoon: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  counts: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 15,
    height: 70,
  },
});

//MAIN Component Func
const Sidebar = props => {
  const {
    classes,
    saving,
    searching,
    onFetch,
    isDirty,
    collapsed,
    setCollapsed,
    // ...others
  } = props;
  const toggleSidebar = () => setCollapsed(!collapsed);
  const [expandAll, setExpansion] = useState(false);
  const [apply, setApply] = useState(false);
  useEffect(() => {
    if (!saving && !isDirty) {
      setApply(false);
    } else if (isDirty || saving) {
      setApply(true);
    }
  }, [isDirty, saving]);

  function toggleExpansion() {
    setExpansion(!expandAll);
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.widdyCollapsed]: collapsed,
        [classes.widdy]: !collapsed,
      })}
    >
      {!collapsed ? (
        <div className={classes.sidebarOpen}>
          <SidebarHeader
            onFetch={onFetch}
            toggleExpansion={toggleExpansion}
            expandAll={expandAll}
            searching={searching}
            apply={apply}
          />
          <div className={classes.counts}>
            <TimecardCounts />
          </div>
          <div className={classes.filters}>
            <FiltersList expandAll={expandAll} saving={saving} />
          </div>
        </div>
      ) : (
        <div className={classes.sidebarClosed}>
          <div className={classes.buttoon}>
            <Badge color={'primary'} className={classes.margin}>
              <PeopleIcon color={'primary'} />
            </Badge>
            <p className={classes.refineCollapsed}>Refine Your Search</p>
          </div>
        </div>
      )}
      <div className={classes.drawerBorder} onClick={toggleSidebar}>
        <IconButton
          className={clsx(classes.drawerIcon, { [classes.rotate]: collapsed })}
          size="small"
          color={'primary'}
        >
          <NavigateBeforeIcon />
        </IconButton>
      </div>
    </div>
  );
};

// Sidebar.propTypes = {
//   batches: PropTypes.array.isRequired,
// };

export default compose(withStyles(style))(Sidebar);
