import PropTypes from 'prop-types';

export const loggedInUserProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  isAccessAdmin: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isInvoiceApproverOnly: PropTypes.bool.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  lastName: PropTypes.string.isRequired,
  oktaId: PropTypes.string,
  roles: PropTypes.array,

  middleName: PropTypes.string,
  birthdate: PropTypes.string,
  ssnMask: PropTypes.string,
  ssn: PropTypes.string,
  alternateIdMask: PropTypes.string,
  alternateId: PropTypes.string,
  impersonating: PropTypes.bool,
  region: PropTypes.string,
  projectId: PropTypes.number,
  projectRoles: PropTypes.array,
});
