class TimeValidator {
  /**
   *
   * @param {number} roundTo - decimal num to round hours to. ie 0.25 = 15 min rounding
   * @param {object} options
   *    @param {bool} options.noRounding - bypass rounding
   *    @param {bool} options.starrable (HTG functionality)
   *    @param {bool} options.enforceType - throw errors if invalid input type
   *
   */

  constructor(roundTo, options) {
    this.roundTo = roundTo || 0.1;
    this.options = options || {};

    if (!this.options.enforceType) {
      this.options.enforceType = false;
    }
  }

  parse(value) {
    const v =
      this.options && this.options.starrable && value === '*'
        ? value
        : this._parse(value);
    if (v === undefined || v === '*') {
      return v;
    }
    if (this.options?.noRounding) {
      return +v.toFixed(2);
    }

    return this.round(v);
  }

  isValid(value) {
    const v = this.parse(value);

    if (value === '*' && this.options?.starrable) return true;

    if (v === undefined) return false;

    if (this.options?.noRounding) return true;

    const rounded = this.round(v);

    return rounded === v;
  }

  _parse(value) {
    if (typeof value !== 'string') {
      if (this.options.enforceType) {
        throw new Error(
          'Invalid input type. Expected string, got ' + typeof value,
        );
      } else {
        return undefined;
      }
    }

    if (!value) {
      return undefined;
    }

    let result = this.parseMilitary(
      value.match(/^([0-9]?[0-9])[:;/]([0-5][0-9])$/),
    );
    if (result !== undefined) {
      return result;
    }

    result = this.parseAMPM(
      value.match(
        /^(0?[1-9]|1[0-2])([:;/]([0-5][0-9]))? *(a\.?m\.?|p\.?m\.?|a|p)?$/i,
      ),
    );
    if (result !== undefined) {
      return result;
    }

    result = this._parseFloat(value.match(/^[0-9]?[0-9]?([.][0-9]?[0-9]?)?$/));
    if (result !== undefined) {
      return result;
    }
    result = this._parseNumber(
      value.match(/^(([0-9]?[0-9])([0-9]{2})) *(a\.?m\.?|p\.?m\.?|a|p)??$/),
    );
    if (result !== undefined) {
      return result;
    }
    return undefined;
  }

  parseMilitary(matchVal) {
    if (!matchVal || matchVal.length < 3) {
      return undefined;
    }
    return parseInt(matchVal[1], 10) + parseInt(matchVal[2], 10) / 60.0;
  }

  parseAMPM(matchVal) {
    if (!matchVal || matchVal.length !== 5) {
      return undefined;
    }

    let hours = parseInt(matchVal[1], 10);
    let minutes = matchVal[3] ? parseInt(matchVal[3], 10) / 60.0 : 0;
    if (matchVal[4] && /p\.?m?\.?/i.test(matchVal[4]) && matchVal[1] !== '12') {
      hours += 12;
    }
    if (matchVal[4] && /a\.?m?\.?/i.test(matchVal[4]) && matchVal[1] === '12') {
      hours -= 12;
    }
    return hours + minutes;
  }

  _parseFloat(matchVal) {
    if (!matchVal || matchVal.length < 1) {
      return undefined;
    }
    return parseFloat(matchVal[0]);
  }

  _parseNumber(matchVal) {
    if (!matchVal || matchVal.length !== 5) {
      return undefined;
    }
    let amPm = '';
    if (matchVal[4]) {
      amPm = matchVal[4];
    }

    const timeResult = `${matchVal[2]}:${matchVal[3]}${amPm}`;
    const pVal = this.parse(timeResult);
    return pVal;
  }

  remainder(v) {
    return Math.floor((v - Math.floor(v)) * 1000 + 0.1) / 1000;
  }

  round(value) {
    let calcValue;
    switch (this.roundTo) {
      case 0:
        return value;
      case 0.25:
        calcValue = this._round(value - 7 / 60.0);
        break;
      default:
        calcValue = this._round(value);
        break;
    }
    return +calcValue.toFixed(2);
  }

  _round(value) {
    if (this.roundTo === 0) {
      return value;
    }
    const remainder = this.remainder(value);
    let remainderMinutes = remainder * 60;
    if (remainder === 0.84) {
      // remainder minute is 50.4 and it could not be able to round to 51 but required to be 1.0
      remainderMinutes = 51;
    } else if (remainder === 0.34) {
      //remainder minute is 20.4 and it could not be able to round to 21 but required to be 0.5
      remainderMinutes = 21;
    } else remainderMinutes = Math.round(remainderMinutes);

    const roundingMinutes = Math.round(this.roundTo * 60);
    let ValueUnits = Math.floor(remainderMinutes / roundingMinutes);

    const roundedMinutes = Math.floor(ValueUnits * roundingMinutes);
    if (remainderMinutes - roundedMinutes > 0.01) {
      ValueUnits += 1;
    }
    remainderMinutes = roundingMinutes * ValueUnits;

    const roundedValue = Math.floor(value) + remainderMinutes / 60.0;
    return roundedValue;
  }

  parseFloatToMilitary(num) {
    if (Number.isNaN(Number(num))) {
      if (this.options.enforceType) {
        throw new Error(
          'Invalid input type. Expected number, got ' + typeof num,
        );
      } else {
        return '';
      }
    }
    if (num !== 0 && !num) return '';

    const hours = Math.floor(num);
    const decMinutes = num - hours;
    const minutes = `${Math.round(decMinutes * 60)}`.padStart(2, '0');
    const hourString = `${hours}`.padStart(2, '0');
    return `${hourString}:${minutes}`;
  }

  parseFloatToAmPm(num) {
    const military = this.parseFloatToMilitary(num);
    if (!military) return '';
    const [hours, min] = military.split(':');

    let numHours = Number(hours);
    let suffix = 'AM';
    if (numHours === 0) {
      numHours = 12;
    } else if (numHours >= 24) {
      //over 24 hr, do not display AMPM
      suffix = '';
    } else if (numHours > 12) {
      suffix = 'PM';
      numHours -= 12;
    } else if (numHours === 12) {
      suffix = 'PM';
    }
    return `${numHours}:${min} ${suffix}`;
  }
}

export default TimeValidator;
