import { createTheme, responsiveFontSizes } from '@mui/material/styles';
// Custom Theme
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';

//components
import accordionSummary from './AccordionSummary';
import dataGridPro from './dataGridPro';
import button from './button';
import tooltip from './tooltip';
import textfield from './textfield';

const v5Theme = {
  typography,
  breakpoints,
  palette,
  components: {
    ...button,
    ...accordionSummary,
    ...tooltip,
    ...textfield,
    ...dataGridPro,
  },
};

let theme = createTheme(v5Theme);

theme = responsiveFontSizes(theme);

// keep below for easy printing of the current palette
// console.log(JSON.stringify(theme.palette));

export default theme;
