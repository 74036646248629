import React, { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import {
  useGlobalFilter,
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useSortBy,
  useAsyncDebounce,
} from 'react-table';
import { compose } from 'utils/helperFunctions';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getEditHostCostFlag, getHiddenColumns } from './selectors';
import Header from './Header';
import {
  saveHotCost,
  storeEditHostCostFlag,
  storeUpdatedHiddenColumns,
  storeEditedFields,
} from './actions';
import {
  TABLE_COLUMNS,
  HOT_COST_COLUMNS,
  PAGE_SIZE,
  canIDelete,
  canIInvite,
  readableUsers,
  resetScrollInTable,
} from './CrewListUtils';
import HeaderRow from './HeaderRow';
import TableBody from './TableBody';
import Pagination from './Pagination';
import { CrewListContext } from './CrewListContext';
import { getHoursPlusEnabled } from 'selectors/project';
import { EMPLOYEE } from 'components/props/profiles';

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: '83vh',
    width: '95%',
  },
  emptyState: {
    margin: 'auto',
  },

  loadingSquare: {
    width: '70vw',
  },
  showTableContainer: {
    marginTop: 20,
    fontSize: 12,
  },
  tableScroll: {
    maxHeight: 'calc((100vh - 320px) * .95)',
    overflowX: 'scroll',
    overflowY: 'scroll',
    transition: 'width 0.3s;',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    backgroundImage:
      'linear-gradient(to right, white, white),linear-gradient(to right, white, white),linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0))',

    backgroundPosition: 'left center, right center, left center, right center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white',
    backgroundSize: '20px 100%, 20px 100%, 10px 100%, 10px 100%',
    backgroundAttachment: 'local, local, scroll, scroll',
  },
  tableBody: {
    position: 'relative',
  },
}));

const mapState = (state, ownProps) => {
  return {
    hiddenColumnsData: getHiddenColumns(state),
    editingHotCost: getEditHostCostFlag(state),
    hoursPlusEnabled: getHoursPlusEnabled(state),
  };
};
const mapDispatch = (dispatch, { location }) => ({
  onSaveHotCosts: data => {
    dispatch(saveHotCost({ formValues: data }));
  },
  setEditingHotCost: value => {
    dispatch(storeEditHostCostFlag({ editHostCostFlag: value }));
  },
  setAddtFieldsHiddenColumns: data => {
    dispatch(storeUpdatedHiddenColumns({ hiddenColumns: data }));
  },
  setEditedHotCostFields: data => {
    dispatch(storeEditedFields({ editedHotCostFields: data }));
  },
});

const CrewListTable = props => {
  const classes = useStyles();
  const {
    users,
    onInviteNonEmployee,
    loading,
    sort,
    onSort,
    currentUser,
    onExportUsers,
    isHotCostEnabled,
    onSaveHotCosts,
    editingHotCost,
    setEditingHotCost,
    hiddenColumnsData,
    setAddtFieldsHiddenColumns,
    hoursPlusEnabled,
    ...others
  } = props;

  const { Provider: CrewListContextProvider } = CrewListContext;
  const overflowContainerRef = useRef(null);

  const memoTableData = useMemo(() => {
    return readableUsers(users);
  }, [users]);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      // fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
      multiSelect: (rows, id, filterValues) => {
        if (filterValues.length === 0) return rows;
        return rows.filter(r => filterValues.includes(r.values[id]));
      },
    }),
    [],
  );

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const onSubmit = async values => {
    onSaveHotCosts(values);
  };

  const reactTable = useTable(
    {
      columns: TABLE_COLUMNS,
      data: memoTableData,
      defaultColumn,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: PAGE_SIZE,
        sortBy: sort.id === null ? [] : [sort],
        hiddenColumns: hiddenColumnsData,
        editingHotCost,
        hoursPlusEnabled,
      },
      useControlledState: state => {
        return useMemo(
          () => ({
            ...state,
            editingHotCost,
            hoursPlusEnabled,
          }),
          // eslint-disable-next-line
          [state, editingHotCost, hoursPlusEnabled],
        );
      },
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    // setSortBy: setSortReactTable,
    setHiddenColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    // pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize, globalFilter, filters, hiddenColumns },
    selectedFlatRows,
    setFilter,
    onEditCrewMember,
  } = reactTable;

  const [deleteBtnVisible, setDeleteBtnVisible] = useState(false);
  const [inviteBtnVisible, setInviteBtnVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState(selectedFlatRows);

  useEffect(() => {
    setSelectedRows(
      selectedFlatRows.filter(row => row.original.role === EMPLOYEE),
    );
  }, [selectedFlatRows]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      if (currentUser.isAdmin) setDeleteBtnVisible(canIDelete(selectedRows));
      setInviteBtnVisible(canIInvite(selectedRows));
    } else {
      setDeleteBtnVisible(false);
      if (currentUser.isAdmin) {
        setInviteBtnVisible(true);
      } else {
        setInviteBtnVisible(false);
      }
    }
  }, [selectedRows, currentUser]);

  useEffect(() => {
    resetScrollInTable(0);
  }, [page]);

  useEffect(() => {
    setAddtFieldsHiddenColumns(hiddenColumns);
  }, [hiddenColumns, setAddtFieldsHiddenColumns]);

  //hide hotcost columns if setting is disabled and any aren't hidden
  useEffect(() => {
    if (
      isHotCostEnabled === false &&
      !HOT_COST_COLUMNS.every(HCC => hiddenColumns.includes(HCC))
    ) {
      const newSet = new Set(hiddenColumns.concat(HOT_COST_COLUMNS));
      const newData = Array.from(newSet);
      setHiddenColumns(newData);
    }
  }, [isHotCostEnabled, hiddenColumns, setHiddenColumns]);

  return (
    <div>
      <CrewListContextProvider
        value={{
          containerRef: overflowContainerRef,
        }}
      >
        <Form
          initialValues={{
            editableCrew: users,
          }}
          onSubmit={onSubmit}
          // validate,
          mutators={{
            ...arrayMutators,
          }}
        >
          {({
            pristine,
            errors,
            handleSubmit,
            reset,
            form,
            values,
            modified,
            dirtyFields,
            submitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Header
                filters={filters}
                setFilter={setFilter}
                preGlobalFilteredRows={preGlobalFilteredRows}
                setGlobalFilter={setGlobalFilter}
                globalFilter={globalFilter}
                useAsyncDebounce={useAsyncDebounce}
                onInviteNonEmployee={onInviteNonEmployee}
                inviteBtnVisible={inviteBtnVisible}
                deleteBtnVisible={deleteBtnVisible}
                selectedRows={selectedRows}
                onExportUsers={onExportUsers}
                currentUser={currentUser}
                users={users}
                editingHotCost={editingHotCost}
                setEditingHotCost={setEditingHotCost}
                hiddenColumns={hiddenColumns}
                setHiddenColumns={setHiddenColumns}
                tableColumns={TABLE_COLUMNS}
                isHotCostEnabled={isHotCostEnabled}
                onSaveHotCosts={onSaveHotCosts}
                pristine={pristine}
                form={form}
                reset={reset}
                modified={modified}
                dirtyFields={dirtyFields}
                submitting={submitting}
                gotoPage={gotoPage}
                pageIndex={pageIndex}
                hoursPlusEnabled={hoursPlusEnabled}
                {...others}
              />
              <div className={classes.tableContainer}>
                {loading && <LinearProgress />}

                <div
                  className={`${classes.tableScroll} crew-scroll`}
                  id="DataTableContainer"
                  ref={overflowContainerRef}
                >
                  <table {...getTableProps()} className={classes.table}>
                    <HeaderRow headerGroups={headerGroups} onSort={onSort} />
                    <tbody
                      {...getTableBodyProps()}
                      className={classes.tableBody}
                    >
                      <TableBody
                        onInviteNonEmployee={onInviteNonEmployee}
                        currentUser={currentUser}
                        onEditCrewMember={onEditCrewMember}
                        editingHotCost={editingHotCost}
                        errors={errors}
                        page={page}
                        pageIndex={pageIndex}
                        prepareRow={prepareRow}
                        form={form}
                        values={values?.editableCrew}
                        hoursPlusEnabled={hoursPlusEnabled}
                        {...others}
                      />
                    </tbody>
                  </table>
                </div>

                <div className={classes.pagination}>
                  <Pagination
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                    page={page}
                    pageCount={pageSize}
                    pageIndex={pageIndex}
                    previousPage={previousPage}
                    rows={rows}
                    editingHotCost={editingHotCost}
                  />
                </div>
              </div>
            </form>
          )}
        </Form>
      </CrewListContextProvider>
    </div>
  );
};

export default compose(connect(mapState, mapDispatch))(CrewListTable);
