import React from 'react';

import { FieldArray } from 'react-final-form-arrays';
import TableRow from './TableRow';

const renderRows = ({
  fields,
  errors = {},
  page,
  prepareRow,
  onInviteNonEmployee,
  currentUser,
  onEditCrewMember,
  editingHotCost,
  form,
  values,
  ...others
}) => {
  return page.map((row, rowIndex) => (
    <TableRow
      prepareRow={prepareRow}
      name={`editableCrew[${row.index}]`}
      rowIndex={rowIndex}
      key={row?.original?.worksightId || row?.original?.email || rowIndex}
      onInviteNonEmployee={onInviteNonEmployee}
      currentUser={currentUser}
      onEditCrewMember={onEditCrewMember}
      editingHotCost={editingHotCost}
      fields={fields}
      form={form}
      values={values[row.index]}
      row={page[rowIndex]}
      page={page}
      {...others}
    />
  ));
};

const TableBody = props => {
  const {
    errors,
    prepareRow,
    onInviteNonEmployee,
    currentUser,
    onEditCrewMember,
    editingHotCost,
    fields,
    page,
    form,
    values,
    ...others
  } = props;

  return (
    <FieldArray
      name="editableCrew"
      component={renderRows}
      errors={errors}
      page={page}
      prepareRow={prepareRow}
      onInviteNonEmployee={onInviteNonEmployee}
      currentUser={currentUser}
      onEditCrewMember={onEditCrewMember}
      editingHotCost={editingHotCost}
      form={form}
      values={values}
      {...others}
    ></FieldArray>
  );
};
export default TableBody;
