import React from 'react';
import PropTypes from 'prop-types';
import { Box, styled } from '@mui/material';

import CalculateIcon from '@mui/icons-material/Calculate';
const IconBubble = styled(Box)(({ theme }) => ({
  height: '30px',
  width: '30px',
  borderRadius: 25,
  backgroundColor: theme.palette.misc.cellHighlight,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const RecalcIconBubble = React.forwardRef((props, ref) => {
  return (
    <IconBubble ref={ref} {...props}>
      <CalculateIcon />
    </IconBubble>
  );
});

RecalcIconBubble.propTypes = { className: PropTypes.string };

export default RecalcIconBubble;
