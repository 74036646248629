import React from 'react';
import {
  TableHead as MuiTableHead,
  TableCell,
  TableRow,
  Checkbox,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TableCellSortable from './TableCellSortable';

const useStyles = makeStyles(({ palette }) => ({
  tableHead: {
    backgroundColor: palette.misc.tableHeader,
    textTransform: 'capitalize;',
    color: palette.text.secondary,
    fontWeight: 700,
    '& > *': {
      height: 50,
      fontWeight: 700,
    },
  },
  tableCell: {
    fontWeight: 700,
  },
  compactCell: {
    padding: 5,
    fontWeight: 500,
    fontSize: 18,
  },
  subText: {
    fontWeight: 400,
    textTransform: 'lowercase',
  },
  customRow: {
    verticalAlign: 'baseline',
    '& > th': {
      paddingBottom: 2,
    },
  },
}));
const TableHeadInvoiceTemplate = props => {
  const classes = useStyles();
  const renderHeaderColumns = () => {
    const {
      columns = [],
      onApplySorting,
      sortParams,
      checkIndeterminate,
      selectAll,
      onSelectAll,
      showCheck,
    } = props;
    const headerRows = columns.map(header => {
      if (header?.hidden) return null;
      let align = header?.align || 'left';
      if (header?.alignRight) align = 'right';
      if (header?.optional) {
        return (
          <TableCell
            className={
              header?.compact ? classes.compactCell : classes.tableCell
            }
            size={header?.compact ? 'small' : 'medium'}
            key={header?.columnId}
            align={align}
            style={{
              lineHeight: '1.2rem',
            }}
          ></TableCell>
        );
      }
      if (header?.sortable && onApplySorting && sortParams) {
        return (
          <TableCellSortable
            className={
              header?.compact ? classes.compactCell : classes.tableCell
            }
            applySorting={onApplySorting}
            columnId={header?.columnId}
            compact
            key={header?.columnId}
            align={align}
            {...sortParams}
          >
            {header?.label}
          </TableCellSortable>
        );
      } else if (header?.isCheck && showCheck) {
        return (
          <TableCell
            padding="checkbox"
            key={`check-all-${header?.columnId}`}
            align="center"
          >
            <Checkbox
              indeterminate={checkIndeterminate}
              checked={selectAll}
              color="primary"
              onChange={e => onSelectAll(e.target.checked)}
              inputProps={{ 'aria-label': 'Select All' }}
            />
          </TableCell>
        );
      }
      return (
        <TableCell
          className={header?.compact ? classes.compactCell : classes.tableCell}
          size={header?.compact ? 'small' : 'medium'}
          key={header?.columnId}
          align={align}
        >
          {header?.label}
        </TableCell>
      );
    });

    return <TableRow className={classes.customRow}>{headerRows}</TableRow>;
  };

  return (
    <MuiTableHead className={classes.tableHead}>
      {renderHeaderColumns()}
    </MuiTableHead>
  );
};
export default TableHeadInvoiceTemplate;
