import React from 'react';
import Events from 'containers/Admin/Projects/Events';
import {
  DefaultLayout,
  Header,
  ProjectHeader,
  AlertHeader,
} from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';
import { AuditLogs as SubNav } from 'containers/Nav/Pages';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    admin
    authenticated
    key="/admin/projects/auditLogs"
    path="/admin/projects/:projectId/audit-logs"
    layout={DefaultLayout}
    headers={[Header, ProjectHeader, AlertHeader, SubNav]}
    component={Events}
    title="Audit Logs"
  />
);
