import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';

import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import {
  Toolbar,
  Box,
  Tooltip,
  Divider,
  Tab as MuiTab,
  Tabs as MuiTabs,
  styled,
  Button,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Arrow from '@mui/icons-material/ArrowForward';
import makeStyles from '@mui/styles/makeStyles';

//actions
import { storeProject } from 'actions/routeParams';
import { show as showModal } from 'actions/modalDialog';
import { fetch as fetchSettings } from 'actions/settings';
import {
  fetchDetails,
  validatingStart,
  // validateStartProject,
  startProjectResult,
} from 'actions/project';

import { useIsMobile, useDidMount } from 'utils/customHooks';

import YearPicker from 'containers/Layout/YearPickerV2';
import { getProjectDetails } from 'selectors/project';
import { truncateStringToFitLength, getNavItems } from 'utils/helperFunctions';
import { currentUser, token } from 'selectors/session';
import { getIsProjectArchived } from 'selectors/project';
import { Link, useRouteMatch } from 'react-router-dom';

import StartProjModal, {
  START_PROJ_MODAL,
} from 'containers/Admin/Projects/Start'; //
const useStyles = makeStyles(({ palette }) => ({
  ProjectHeaderV2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 65,
  },

  selectedOtherBtn: {
    borderBottom: `2px solid ${palette.primary.main}`,
    '&:hover': {
      borderBottom: `2px solid ${palette.primary.main}`,
    },
  },
  projectNameBump: {
    height: '28px',
    width: '7px',
    backgroundColor: palette.gray['+7'],
    content: '',
    borderRadius: 4,
    marginRight: 8,
  },
  overFlowLink: {
    color: palette.primary['+3'],
  },
  selectedOverFlow: {
    color: palette.text.primary,
    fontWeight: 'bold',
  },
  draftProject: {
    backgroundColor: palette.button.yellow.background,
    color: palette.warning.dark,
    marginLeft: 16,
    marginTop: 4,
    padding: '3px 10px',
    borderRadius: '25px',
    fontSize: '14px',
    border: `1px solid ${palette.button.yellow.color}`,
  },
  startProjBtn: {
    color: palette.warning.dark,
    backgroundColor: palette.background.paper,
    borderRadius: 25,
    border: `1px solid ${palette.button.yellow.color}`,
    padding: '0px 10px',
    '&:hover': {
      backgroundColor: palette.background.paper,
    },
  },
}));

const StyledTab = styled(MuiTab)(({ theme }) => ({
  padding: '8px',
  minHeight: '40px',
  textTransform: 'inherit',
  color: theme.palette.text.secondary,
  '&.Mui-selected': {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
}));
const StyledTabs = styled(MuiTabs)(({ theme }) => ({
  minHeight: '44px',
  marginTop: '20px',
  '& .MuiTabs-indicator': {
    transition: 'none',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '&:hover': {
    border: 'inherit',
    backgroundColor: theme.palette.background.paper,
  },
}));

const mapState = state => ({
  isLoggedIn: !!token(state),
  project: getProjectDetails(state),
  currentUser: currentUser(state),
  isProjectArchived: getIsProjectArchived(state),
});

const mapDispatch = dispatch => ({
  onSetProject: projectId => {
    dispatch(storeProject({ projectId }));
  },
  onFetchProject: currentPath => {
    dispatch(fetchDetails({ currentPath }));
  },
  onFetchSettings: () => dispatch(fetchSettings()),
  onStartProject: () => {
    dispatch(validatingStart({ validating: true }));
    dispatch(showModal({ dialog: START_PROJ_MODAL }));
    dispatch(startProjectResult({}));
    // setTimeout(() => {
    //   dispatch(validateStartProject());
    // }, 3000);
  },
});

const ProjectHeaderV2 = props => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const {
    project,
    currentUser,
    isLoggedIn,
    onFetchProject,
    onSetProject,
    onFetchSettings,
    onStartProject,
    isProjectArchived,
  } = props;

  const match = useRouteMatch();

  useDidMount(() => {
    onSetProject(match.params.projectId);
    //only fetch data if we don't have project info
    if (!project || _.isEmpty(project)) onFetchProject(match.path);
    onFetchSettings();
  });

  const { url } = useRouteMatch();

  const [tab, setTab] = React.useState(false);
  const [restIndex, setRestIndex] = React.useState(-1);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDotMenu = e => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updatedProject = { ...project, isProjectArchived };
  const navItemsAll = getNavItems({
    currentUser,
    project: updatedProject,
    isLoggedIn,
  });

  const theme = useTheme();
  const is1440 = useMediaQuery('@media (max-width:1440px)');
  const is1270 = useMediaQuery('@media (max-width:1270px)');
  const is1060 = useMediaQuery('@media (max-width:1060px)');
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  let displayCount;
  if (isMd) {
    displayCount = 1;
  } else if (is1060) {
    displayCount = 4;
  } else if (is1270) {
    displayCount = 6;
  } else if (is1440) {
    displayCount = 8;
  } else {
    displayCount = 10;
  }
  const navItems = navItemsAll.slice(0, displayCount);
  const navRest = navItemsAll.slice(displayCount);
  const showRest = navRest.length > 0;

  React.useEffect(() => {
    const index = navItems.findIndex(n => n.to === url);
    let newTab = index;
    let newRestTab = -1;
    if (index === -1) {
      newTab = false;
      const i = navRest.findIndex(n => n.to === url);
      newRestTab = i;
    }
    setTab(newTab);
    setRestIndex(newRestTab);
  }, [navItems, navRest, url]);

  const isAdminUser = currentUser?.isAdmin;
  const isDraft = project?.isDraft;
  const isTimePlusEnabled = project?.timePlusEnabled;
  const isNotPending = project?.status !== 'P';
  const isStartProjectBtnEnabled =
    isAdminUser && isTimePlusEnabled && isDraft && isNotPending;

  const name = project?.name || '';
  const shortText = truncateStringToFitLength(isMobile ? 26 : 26, name);
  let title = '';
  if (shortText !== name) {
    title = name;
  }

  const projectName = !name ? null : (
    <>
      <Box className={classes.projectNameBump} />
      <Tooltip title={title}>
        <Box
          sx={{ fontSize: '20px', fontWeight: '600', color: 'text.primary' }}
        >
          {shortText}
        </Box>
      </Tooltip>
    </>
  );

  if (isMobile) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: '40px',
          p: '12px',
          alignItems: 'center',
        }}
      >
        {projectName}
      </Box>
    );
  }
  return (
    <Toolbar className={classes.ProjectHeaderV2}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {projectName}
        {name && (
          <Divider
            sx={{ ml: 2, height: '14px', borderWidth: '1px' }}
            variant="middle"
            orientation="vertical"
            flexItem
          />
        )}
        <YearPicker />
        {isDraft && (
          <Box className={classes.draftProject}>
            Draft
            {isStartProjectBtnEnabled && (
              <Button
                className={classes.startProjBtn}
                onClick={() => {
                  onStartProject();
                }}
                endIcon={<Arrow />}
              >
                Start Project
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <StyledTabs value={tab}>
          {navItems.map(n => (
            <StyledTab
              key={n.label}
              label={n.label}
              component={Link}
              to={n.to}
            />
          ))}
        </StyledTabs>
        {showRest && (
          <>
            <StyledButton
              onClick={handleDotMenu}
              variant="text"
              className={clsx({ [classes.selectedOtherBtn]: restIndex !== -1 })}
            >
              <MoreVertIcon />
            </StyledButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {navRest.map((n, i) => (
                <MenuItem key={n.label}>
                  <Link
                    to={n.to}
                    className={clsx(classes.overFlowLink, {
                      [classes.selectedOverFlow]: i === restIndex,
                    })}
                  >
                    {n.label}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </Box>
      <StartProjModal />
    </Toolbar>
  );
};

ProjectHeaderV2.propTypes = {
  project: PropTypes.object,
  currentUser: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  onSetProject: PropTypes.func,
  onFetchProject: PropTypes.func,
  onFetchSettings: PropTypes.func,
  onStartProject: PropTypes.func,
};

export default compose(connect(mapState, mapDispatch))(ProjectHeaderV2);
