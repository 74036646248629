import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'utils/helperFunctions';
//component
import EmailConfig from 'components/Admin/Projects/Settings/EmailConfig';
import { show as showModal } from 'actions/modalDialog';
//actions
import * as actions from 'actions/settings';
//selector
import {
  getCrewInviteTemplates,
  getEmailLoading,
  getRecipients,
} from 'selectors/settings';
import {
  getPayrollCoordinator,
  getBackupCoordinators,
} from 'selectors/project';

const mapState = state => ({
  initialValues: getCrewInviteTemplates(state),
  loading: getEmailLoading(state),
  payrollCoordinator: getPayrollCoordinator(state),
  backupCoordinators: getBackupCoordinators(state),
  recipients: getRecipients(state),
});
const mapDispath = dispatch => ({
  onFetchEmail: () => {
    dispatch(actions.fetchEmailSettings());
    dispatch(actions.fetchRecipients());
  },
  onAddRecipient: () => {
    dispatch(showModal({ dialog: 'addRecipients' }));
  },
  onRemoveRecipient: id => {
    dispatch(actions.removeRecipient({ id }));
  },
});

const onSubmit = (values, dispatch) => {
  dispatch(actions.submitEmailSettings({ values }));
};

class AdminEmailConfig extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    onFetchEmail: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { onFetchEmail } = this.props;
    onFetchEmail();
  }

  render() {
    const {
      handleSubmit,
      payrollCoordinator,
      backupCoordinators,
      onAddRecipient,
      onRemoveRecipient,
      recipients,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <EmailConfig
          {...this.props}
          payrollCoordinator={payrollCoordinator}
          backupCoordinators={backupCoordinators}
          onAddRecipient={onAddRecipient}
          onRemoveRecipient={onRemoveRecipient}
          recipients={recipients}
        />
      </form>
    );
  }
}

export default compose(
  connect(mapState, mapDispath),
  reduxForm({
    form: 'EmailConfig',
    enableReinitialize: true,
    touchOnChange: true,
    onSubmit,
  }),
)(AdminEmailConfig);
