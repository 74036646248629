// SSN - Read only masking - xxx-xx-9999 format.
export const ssnMasking = (value, isShowEmptyMask) => {
  if (value) {
    if (value.length > 9 && (value.includes('x') || value.includes('*'))) {
      return value;
    }

    const mask = 'x';
    const ssn = value.replace(/[^0-9]/g, ''); // (/^([A-Za-z]\d{8})/g, '')

    if (ssn.length >= 6) {
      return `${mask.repeat(3)}-${mask.repeat(2)}-${ssn.substr(5, 4)}`;
    } else if (ssn.length > 3) {
      return `${mask.repeat(3)}-${ssn.substr(3, 3)}`;
    }

    return ssn;
  } else if (isShowEmptyMask) {
    return 'xxx-xx-xxxx';
  }

  return value;
};

// SSN - Input field masking for 999-99-9999 format.
export const ssnFormat = value => {
  if (value) {
    const ssn = value.replace(/[^0-9]/g, '');

    if (ssn.length >= 6) {
      return `${ssn.substr(0, 3)}-${ssn.substr(3, 2)}-${ssn.substr(5, 4)}`;
    } else if (ssn.length > 3) {
      return `${ssn.substr(0, 3)}-${ssn.substr(3, 3)}`;
    }

    return ssn;
  }

  return value;
};

//SSN - last four digits
export const ssnFormatLastFourDigit = value => {
  let ssn = '';
  if (value) {
    if (value.length > 4) {
      const arr = value.includes('-') ? value.split(/-/g) : [];
      ssn = arr[2] ? arr[2] : '';
    } else if (value.length === 4) {
      ssn = value;
    }
  }
  return ssn;
};

//SIN - Read only masking - xxx-xxx-999 format.
export const sinMasking = (value, isShowEmptyMask) => {
  if (value) {
    if (value.length > 9 && (value.includes('x') || value.includes('*'))) {
      return value;
    }

    const mask = 'x';

    const sin = value.replace(/[^0-9]/g, '');

    if (sin.length >= 6) {
      return `${mask.repeat(3)}-${mask.repeat(3)}-${sin.substr(6, 3)}`;
    } else if (sin.length > 3) {
      return `${mask.repeat(3)}-${sin.substr(3, 3)}`;
    }

    return sin;
  } else if (isShowEmptyMask) {
    return 'xxx-xxx-xxx';
  }

  return value;
};
// SIN - Input field masking for 999-999-999 format.
export const sinFormat = value => {
  if (value) {
    const sin = value.replace(/[^0-9]/g, '');

    if (sin.length > 6) {
      return `${sin.substr(0, 3)}-${sin.substr(3, 3)}-${sin.substr(6, 3)}`;
    } else if (sin.length > 3) {
      return `${sin.substr(0, 3)}-${sin.substr(3, 3)}`;
    }

    return sin;
  }

  return value;
};
//SIN - last three digits
export const sinFormatLastThreeDigit = value => {
  let sin = '';
  if (value) {
    if (value.length > 3) {
      const arr = value.includes('-') ? value.split(/-/g) : [];
      sin = arr[2] ? arr[2] : '';
    } else if (value.length === 3) {
      sin = value;
    }
  }
  return sin;
};
