import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Paper, Box, LinearProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import SearchFilterSort from './FilterSortComps/SearchFilterSort';

import {
  getCurrentInvoiceId,
  getRecords,
  getNameListLoading,
  getSortBy,
} from 'selectors/digitalEdits';

import LetterListNav from './LetterListNav';
import SidebarListItem from './SidebarListItem';

const useStyles = makeStyles(({ palette }) => ({
  // Sidebar: {},
  listContainer: { display: 'flex' },
  list: {
    overflow: 'auto',
    background: palette.gray['+9'],
  },
  filterMsg: {
    display: 'flex',
    justifyContent: 'center',
    height: '50%',
    alignItems: 'center',
    textAlign: 'center',
    color: palette.text.disabled,
  },
  tcCount: {
    fontSize: '.8em',
    color: palette.text.disabled,
  },
}));

const mapState = state => ({
  currentInvoiceId: getCurrentInvoiceId(state),
  records: getRecords(state),
  loading: getNameListLoading(state),
  sortBy: getSortBy(state),
});

const Sidebar = props => {
  const classes = useStyles();
  const { currentInvoiceId, records, loading, sortBy } = props;

  const headerRef = React.useRef();
  const listContainerRef = React.useRef();
  const headerHeight = headerRef?.current?.offsetHeight || 0;

  const [showLetterNav, setShowLetterNav] = React.useState(true);

  React.useEffect(() => {
    if (sortBy.field === 'employeeName' && !showLetterNav) {
      setShowLetterNav(true);
    } else if (sortBy.field !== 'employeeName' && showLetterNav) {
      setShowLetterNav(false);
    }
  }, [showLetterNav, sortBy.field]);

  let sectionHeader = '';

  return (
    <Paper className={classes.Sidebar}>
      <Box ref={headerRef} sx={{ paddingLeft: '12px' }}>
        <Typography align="center" sx={{ fontSize: '26px', fontWeight: 600 }}>
          Records
          <span className={classes.tcCount}>({records?.length || 0})</span>
        </Typography>
        <SearchFilterSort />
      </Box>
      {loading && <LinearProgress />}
      {!!currentInvoiceId && !loading ? (
        <Box className={classes.listContainer}>
          <Box
            className={classes.list}
            ref={listContainerRef}
            sx={{
              height: `calc(100vh - ${headerHeight}px)`,
              width: showLetterNav ? '90%' : '100%',
            }}
          >
            {records.map((r, index) => {
              const sortByField =
                sortBy.field === 'employeeName' ? 'firstLetter' : sortBy.field;
              let addSectionHeader = false;
              if (sectionHeader !== _.get(r, sortByField)) {
                addSectionHeader = true;
                sectionHeader = _.get(r, sortByField);
              }
              return (
                <SidebarListItem
                  record={r}
                  key={r.checkSequence}
                  sectionHeader={sectionHeader}
                  addSectionHeader={addSectionHeader}
                  listContainerRef={listContainerRef}
                />
              );
            })}
            {records.length === 0 && (
              <Box className={classes.filterMsg}>
                No records
                <br /> for this filter selection
              </Box>
            )}
          </Box>
          {showLetterNav && (
            <LetterListNav headerHeight={headerHeight} list={records} />
          )}
        </Box>
      ) : (
        <Box
          sx={{ padding: '32px', textAlign: 'center', color: 'text.disabled' }}
        >
          No invoice selected
        </Box>
      )}
    </Paper>
  );
};

Sidebar.propTypes = {
  currentInvoiceId: PropTypes.string.isRequired,
  records: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  sortBy: PropTypes.object.isRequired,
};

export default compose(connect(mapState))(Sidebar);
