import React from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import {
  Divider,
  Button,
  Checkbox,
  Typography,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormGroup,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  LinearProgress,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlSwitch from 'components/Shared/FormControlSwitch';
import { settingsProps } from 'components/props/projects';
import { SETTINGS as FIELDS } from 'components/props/projects';

const style = ({ palette }) => ({
  root: {
    width: '100%',
    marginTop: 30,
  },
  button: {
    margin: 20,
  },
  noPadding: {
    padding: 0,
  },
  heading: {
    fontSize: '1.1em',
    fontFamily: 'inherit',
  },
  toggleColumn: {
    paddingLeft: 10,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  splitSection: {
    float: 'right',
  },
  tableHead: {
    background: palette.misc.tableHeader,
    textTransform: 'uppercase',
    color: palette.text.primary,
    fontWeight: 700,
    '& > *': {
      height: 50,
    },
  },
  center: {
    textAlign: 'center',
  },
  toggleBox: {
    padding: '16px 32px',
    width: '21%',
  },
  toggleSupplementText: {
    fontSize: 14,
  },
  customFormControlLabel: {
    flexDirection: 'row-reverse',
    marginLeft: 0,
  },
  customText: {
    fontSize: '1.1rem',
    fontWeight: 500,
  },
});

const AccountCodes = ({
  classes,
  settings,
  loading,
  flags,
  onUpdate,
  onUpdateSetting,
  onUpdateIndividual,
  onToggleAutoCoding,
}) => {
  const splitsEnabled = (flags.find(f => f.id === 'splits') || {}).enabled;
  return (
    <div style={{ marginBottom: 30 }}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            Account Codes Fields
          </Typography>
        </AccordionSummary>
        <Divider />
        {loading && <LinearProgress />}

        <AccordionDetails className={classes.noPadding}>
          <div style={{ width: '100%' }}>
            <FormGroup className={classes.toggleColumn}>
              <FormControlSwitch
                label="Allow disabling auto coding for individual timecards"
                onChange={() => {
                  onToggleAutoCoding();
                }}
                checked={settings?.autoCodingEnable || false}
              />
              <FormControlSwitch
                checked={settings?.accountCodesOpenByDefault || false}
                onChange={onUpdateIndividual('accountCodesOpenByDefault')}
                label="Account Codes Open by default - Only for Department Head View"
              />
            </FormGroup>
            {splitsEnabled && <Divider />}
            {splitsEnabled && (
              <FormGroup className={classes.splitSection}>
                <div className={classes.splitSection}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings?.splits || false}
                        onChange={onUpdateIndividual('splits')}
                        color="primary"
                      />
                    }
                    label="Enable Splits"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={settings?.splitsProject || false}
                        onChange={onUpdateIndividual('splitsProject')}
                        color="primary"
                      />
                    }
                    label="Show Projects"
                  />
                </div>
              </FormGroup>
            )}
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell />
                  <TableCell className={classes.center} colSpan={2}>
                    Department Head
                  </TableCell>
                  <TableCell className={classes.center} colSpan={2}>
                    Crew Timecard
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Account Code</TableCell>
                  <TableCell className={classes.center}>Visible</TableCell>
                  <TableCell className={classes.center}>Mandatory</TableCell>
                  <TableCell className={classes.center}>Visible</TableCell>
                  <TableCell className={classes.center}>Mandatory</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(FIELDS).map(field => (
                  <TableRow key={field} hover>
                    <TableCell>{FIELDS[field]}</TableCell>
                    <TableCell className={classes.center}>
                      <FormGroup>
                        <Checkbox
                          checked={
                            (settings[field] && settings[field].dhVisible) ||
                            false
                          }
                          color="primary"
                          onChange={onUpdateSetting(field, 'dhVisible')}
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell className={classes.center}>
                      <FormGroup>
                        <Checkbox
                          checked={settings[field]?.dhMandatory || false}
                          color="primary"
                          onChange={onUpdateSetting(field, 'dhMandatory')}
                          disabled={
                            (settings[field] && !settings[field].dhVisible) ||
                            false
                          }
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell className={classes.center}>
                      <FormGroup>
                        <Checkbox
                          checked={settings[field]?.crewVisible || false}
                          onChange={onUpdateSetting(field, 'crewVisible')}
                          color="primary"
                          disabled={
                            (settings[field] && settings[field].dhVisible) ||
                            false
                          }
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell className={classes.center}>
                      <FormGroup>
                        <Checkbox
                          checked={settings[field]?.crewMandatory || false}
                          color="primary"
                          onChange={onUpdateSetting(field, 'crewMandatory')}
                          disabled={
                            !settings[field]?.crewVisible ||
                            settings[field]?.dhMandatory ||
                            false
                          }
                        />
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {loading && <LinearProgress />}
          </div>
        </AccordionDetails>

        <AccordionActions className={classes.noPadding}>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.button}
            onClick={onUpdate}
          >
            Save Settings
          </Button>
        </AccordionActions>
      </Accordion>
    </div>
  );
};

AccountCodes.propTypes = {
  classes: PropTypes.object.isRequired,
  settings: settingsProps.isRequired,
  loading: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpdateSetting: PropTypes.func.isRequired,
};

export default withStyleSheet('SettingsAccountCodes', style)(AccountCodes);
