import React, { useState } from 'react';
import {
  Button,
  Paper,
  TableRow,
  TableCell,
  Typography,
  LinearProgress,
  Divider,
  Checkbox,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import BackIcon from '@mui/icons-material/KeyboardBackspace';
import clsx from 'clsx';
import pluralize from 'pluralize';
import moment from 'moment';
//components
import { TableList } from 'components/Shared/TableList';
import { Badge } from 'components/Shared/Text';
import { statusByRole } from 'components/Shared/constants';
//utils
import { formatDate } from 'utils';
import { currentWorkWeek } from 'utils/weekUtils';
import { weekEndingFormatUTC as format } from 'utils/formatDate';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
    marginTop: 30,
    overflowX: 'auto',
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  header: {
    marginLeft: 20,
    marginTop: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > *': {
      padding: 0,
    },
  },
  occupation: {
    fontSize: 14,
  },
  pendingOccupation: {
    color: palette.text.disabled,
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 10,
  },
  empty: {
    fontSize: '2.14285714em',
    background: palette.background.default,
    color: palette.text.disabled,
    fontWeight: 700,
    fontStyle: 'italic',
    marginTop: 2,
    padding: 10,
    textAlign: 'center',
  },
  selectedUser: {
    fontWeight: 700,
    marginLeft: 20,
    marginBottom: 20,
  },
  iconButton: {
    padding: 8,
    fontSize: '1.2em',
    display: 'inline',
    verticalAlign: 'bottom',
  },
  colorPrimary: {
    color: palette.secondary.light,
  },
}));

const CreateCrewTimecards = ({
  activeUser,
  department = {},
  employees = [],
  loading = true,
  weekEndingdate,
  project,
  weekendingTimecards = [],
  onAddGroupTimecard = null,
  onTakeMeBack = null,
  isProjectCompleted,
  ...others
}) => {
  const classes = useStyles();
  const [selectedUsers, setSelectedUser] = useState([]);
  const isSelected = employee => {
    let match = selectedUsers.filter(user => user.key === employee.key);
    return !!match.length;
  };

  if (loading) return <LinearProgress />;

  const startsOn = format(weekEndingdate);
  const workWeek = currentWorkWeek(project, startsOn);
  const isBefore = dealMemo => {
    // deal memo starts date
    // must be the same time or earlier than
    // current timecard's starts on date
    return moment(format(dealMemo.startsOn)).isSameOrBefore(startsOn);
  };

  let contentRows = null;
  let enablePagination = true;
  function AddGroupTimecard() {
    onAddGroupTimecard(weekEndingdate, selectedUsers);
  }
  function getStatusBadge(timecard) {
    const statuses = statusByRole(activeUser.role);
    const label = statuses.label[timecard.status] || timecard.status;

    return (
      <Badge uppercase status={statuses.badge[timecard.status]}>
        {label}
      </Badge>
    );
  }

  function userSelect(details, event) {
    const selectedUserIndex = selectedUsers.findIndex(
      user => user.key === details.key,
    );
    let newSelected = [].concat(selectedUsers);

    if (selectedUserIndex === -1) {
      newSelected.push(details);
    } else {
      newSelected.splice(selectedUserIndex, 1);
    }
    setSelectedUser(newSelected);
  }

  const disableTimecardCreate = details => {
    const validDMExist = details.dealMemos.some(isBefore);
    const disableUser = project.isClosed || details.dealMemos.length === 0;
    return disableUser || !validDMExist;
  };

  const showTimecardCreateCheckbox = details => {
    if (!details || !details.user) return false;

    return true;
  };

  const handleSelectAllClick = checked => {
    if (checked) {
      const newSelecteds = employees.reduce((result, details) => {
        if (
          !disableTimecardCreate(details) &&
          showTimecardCreateCheckbox(details)
        ) {
          result.push(details);
        }
        return result;
      }, []);

      setSelectedUser(newSelecteds);
      return;
    }
    setSelectedUser([]);
  };

  if (weekendingTimecards && weekendingTimecards.length <= 0) {
    contentRows = (
      <TableRow key={'empty-row-crew'}>
        <TableCell colSpan={8} rowSpan={3} className={classes.empty}>
          <Typography variant="h6">
            {loading ? 'Loading Weekendings...' : 'No crew timecards'}
          </Typography>
        </TableCell>
      </TableRow>
    );
    enablePagination = false;
  } else {
    contentRows = _.map(weekendingTimecards, (details, index) => {
      const isItemSelected = isSelected(details);

      return (
        <TableRow hover key={index}>
          <TableCell>
            {showTimecardCreateCheckbox(details) && (
              <Checkbox
                color="primary"
                checked={isItemSelected}
                disabled={disableTimecardCreate(details)}
                onChange={event => userSelect(details, event)}
              />
            )}
          </TableCell>
          <TableCell>{details.fullName}</TableCell>
          <TableCell>
            {_.map(details.occupationCodes, (code, index) => (
              <Typography
                key={index}
                className={clsx({
                  [classes.occupation]: true,
                  [classes.pendingOccupation]: code === 'Pending',
                })}
              >
                {code}
              </Typography>
            ))}
          </TableCell>
          <TableCell>{getStatusBadge(details)}</TableCell>
        </TableRow>
      );
    });
  }

  const headers = [
    {
      columnId: 'selectAll',
      label: 'Select All',
      sortable: false,
      compact: true,
      isCheck: true,
    },
    {
      columnId: 'fullName',
      label: 'Employee',
      sortable: true,
    },
    {
      columnId: 'occupation',
      label: 'Occupation',
      sortable: false,
    },
    {
      columnId: 'user.worksightStatus',
      label: 'Status',
      sortable: true,
    },
  ];
  const createCrewTcTooltip = isProjectCompleted
    ? 'This project is complete, no new timecards can be created'
    : '';
  return (
    <Paper className={classes.root}>
      <div className={classes.row}>
        <div className={classes.header}>
          <Typography variant="h6" color="inherit">
            {department.name}
          </Typography>
          <Typography>
            {formatDate(weekEndingdate, 'MM-DD-YYYY')} {' | '}{' '}
            {`Production Week ${workWeek.starts[0]}-${workWeek.ends[0]}`}
          </Typography>
        </div>
        <div className={classes.button}>
          <div className={classes.button}>
            <Button variant="outlined" onClick={() => onTakeMeBack(project.id)}>
              <BackIcon className={classes.iconButton} />
              {'TAKE ME BACK'}
            </Button>
          </div>
          <Tooltip title={createCrewTcTooltip} placement="top-start">
            <div className={classes.button}>
              <Button
                color="primary"
                variant="contained"
                disabled={
                  selectedUsers.length <= 0 ||
                  project.isClosed ||
                  isProjectCompleted
                }
                onClick={() => AddGroupTimecard()}
              >
                {`CREATE ${pluralize('TIMECARD', selectedUsers.length)}`}
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>
      <Divider className={classes.divider} />
      <Typography color="inherit" className={classes.selectedUser}>
        {`You have `}
        <span className={classes.colorPrimary}>
          {pluralize('CREW MEMBER', selectedUsers.length, true)}
          {' SELECTED'}
        </span>
      </Typography>
      <TableList
        hover
        enablePagination={enablePagination}
        headers={headers}
        isAlternateRowColor={true}
        onSelectAll={handleSelectAllClick}
        showCheck
        {...others}
      >
        {contentRows}
      </TableList>
    </Paper>
  );
};

export default CreateCrewTimecards;
