import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';

import { Paper, Box, IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ResizeBox from './ResizeBox';
import Comments from './Comments';

import { openModalAfterWindowClose } from 'feature/DigitalEdits/digitalEditsUtils';
import { useResize } from 'utils/customHooks';

import ClearIcon from '@mui/icons-material/Clear';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

//actions
import {
  fetchComments,
  setCommentState,
  lockComment,
  unlockComment,
  storeCommentLocked,
  syncUnsubmittedComment,
} from 'actions/digitalEdits';
//selectors
import {
  getCurrentInvoiceId,
  getCommentState,
  getCommentLockStatus,
  getUnsubmittedComment,
} from 'selectors/digitalEdits';
import { getProject } from 'selectors/routeParams';
import Notifications from './Notifications';

import { useBroadcastChannel } from 'utils/customHooks';

const TITLE_BAR_HEIGHT = 30;

const useStyles = makeStyles(({ palette }) => ({
  CommentModal: {
    zIndex: 1000,
    position: 'fixed',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  titleBar: {
    backgroundColor: palette.primary.main,
    height: TITLE_BAR_HEIGHT,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'move',
  },
}));

const mapState = state => ({
  currentInvoiceId: getCurrentInvoiceId(state),
  projectId: getProject(state),
  commentState: getCommentState(state),
  commentLock: getCommentLockStatus(state),
  unsubmittedComment: getUnsubmittedComment(state),
});

const mapDispatch = dispatch => ({
  onInit: invoiceId => {
    dispatch(fetchComments({ invoiceId }));
    dispatch(lockComment({ invoiceId }));
  },
  onSetCommentState: commentState =>
    dispatch(setCommentState({ commentState })),
  onUnlockComment: () => {
    dispatch(unlockComment());
  },
  onStoreCommentLock: (invoiceId, lockData) =>
    dispatch(storeCommentLocked({ invoiceId, lockData })),
  onSyncComment: newComment => {
    dispatch(syncUnsubmittedComment({ newComment }));
  },
});

const CommentModal = props => {
  const classes = useStyles();
  const {
    currentInvoiceId,
    projectId,
    onInit,
    commentState,
    onSetCommentState,
    onUnlockComment,
    commentLock,
    onStoreCommentLock,
    unsubmittedComment,
    onSyncComment,
  } = props;

  const contentRef = React.useRef();

  React.useEffect(() => {
    if (commentState === 'modal') {
      onInit(currentInvoiceId);
    }
  }, [commentState, currentInvoiceId, onInit]);

  const { width, height, enableResize, isResizing } = useResize({
    minWidth: 660,
    minHeight: 600,
  });

  const horBound = Math.floor(window.innerWidth - width * 1.2);
  const vertBound = Math.floor(window.innerHeight - height * 1.2);
  const [notes, setNotes] = React.useState([]);
  const childRef = React.useRef(null);

  const commentStateRef = React.useRef(commentState);
  React.useEffect(() => {
    commentStateRef.current = commentState;

    if (commentState !== 'modal') {
      return () => {
        setNotes([]);
      };
    }
  }, [commentState]);

  const bc = useBroadcastChannel(currentInvoiceId);

  /**
   * comment insertReply might be triggered when modal/window isn't open.
   * intercept broadcast action here and open modal if needed with separate action
   */
  const postToComment = React.useCallback(
    e => {
      if (commentStateRef.current === 'closed') {
        onSetCommentState('modal');
        setTimeout(() => {
          postToComment(e);
        }, 1000);
      } else {
        if (commentStateRef.current !== 'modal') {
          commentStateRef.current.focus();
        }
        bc.postMessage({
          action: 'insertReplyText',
          payload: e.data.payload,
        });
      }
    },
    [bc, onSetCommentState],
  );

  React.useEffect(() => {
    let timeoutId;
    let intervalId;
    intervalId = setInterval(() => {
      if (bc) {
        bc?.postMessage({ action: 'heartbeat' });
        timeoutId = setTimeout(() => {
          if (commentLock.lockOwner === 'me') {
            console.debug('No heartbeat response, unlocking comment');
            onUnlockComment();
            clearInterval(intervalId);
          }
        }, 1000);
      }
    }, 2000);
    if (bc) {
      bc.onmessage = e => {
        if (e.data.action === 'heartbeat-response') {
          if (commentLock.lockOwner !== e.data.payload.lockOwner) {
            onStoreCommentLock(currentInvoiceId, e.data.payload.lock);
          }
          clearTimeout(timeoutId);
        } else if (e.data.action === 'insertReply') {
          postToComment(e);
        } else if (e.data?.action === 'syncComment') {
          if (e.data.payload.commentText !== unsubmittedComment?.commentText) {
            //TODO - need to handle initial sync case on window load so it doesn't need to fire 2x
            onSyncComment(e.data.payload);
          }
        }
      };
    }

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [
    bc,
    commentLock,
    commentState,
    currentInvoiceId,
    onStoreCommentLock,
    onSyncComment,
    onUnlockComment,
    postToComment,
    unsubmittedComment?.commentText,
  ]);

  if (commentState !== 'modal') return null;

  //TODO - handle case where user resizes browser

  return (
    <Draggable
      bounds={{
        top: -250,
        left: -340,
        right: horBound,
        bottom: vertBound,
      }}
      handle=".digitalEditCommentTitleBar"
      defaultPosition={{
        x: Math.floor(window.innerWidth - width * 1.6),
        y: Math.floor(window.innerHeight - height * 1.5),
      }}
      disabled={isResizing}
    >
      <Paper className={classes.CommentModal} sx={{ height, width }}>
        <Paper
          sx={{
            width,
            height,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
          }}
          ref={contentRef}
        >
          <Box className={clsx('digitalEditCommentTitleBar', classes.titleBar)}>
            <Box
              className={classes.flexCenter}
              sx={{ color: 'primary.contrastText', p: 1, fontWeight: 600 }}
            >
              Comments
            </Box>
            <Box className={classes.flexCenter}>
              <Tooltip title="Pop Out">
                <IconButton
                  className="PENDO_digitalEditsModalPopout"
                  onMouseDown={e => e.stopPropagation()} //prevent dragging on button click
                  onClick={() => {
                    const options = 'height=600,width=800,';
                    childRef.current = window.open(
                      `/projects/${projectId}/invoice-comments/${currentInvoiceId}`,
                      'DigitalEditsCommentWindow',
                      options,
                    );
                    if (
                      (!childRef.current ||
                        childRef.current.closed ||
                        typeof childRef.current.closed == 'undefined') &&
                      !notes.find(n => n.type === 'browser_blocks_popup')
                    ) {
                      const err = [...notes];
                      err.push({
                        variant: 'error',
                        type: 'browser_blocks_popup',
                        title: 'Check Browser Settings',
                        text: 'The pop-up window cannot be opened. Check your browser settings.',
                      });
                      setNotes(err);
                    } else {
                      //TODO - this gets cleared if they hard refresh the comment window
                      //add in update to the comment window to set this on refresh.
                      childRef.current.onCloseInvoiceComments = (
                        commentState = 'closed',
                      ) => {
                        childRef.current.close();
                        if (commentState === 'modal') {
                          // For some reason this doesn't call the autosave before showing the 'unsaved changes' modal
                          // like clicking the X to close the window does
                          // There for its possible to lose changes if they click leave
                          openModalAfterWindowClose(
                            onSetCommentState,
                            childRef,
                          );
                        } else {
                          onSetCommentState(commentState);
                        }
                      };
                      onSetCommentState(childRef.current);
                    }
                  }}
                >
                  <OpenInNewIcon sx={{ color: 'white' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close">
                <IconButton
                  onMouseDown={e => e.stopPropagation()} //prevent dragging on button click
                  onClick={() => {
                    onSetCommentState('closed');
                  }}
                >
                  <ClearIcon sx={{ color: 'white' }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box
            sx={{
              maxHeight: height - TITLE_BAR_HEIGHT,
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
            onKeyDown={e => {
              if (e.key === 'Escape') {
                onSetCommentState('closed');
              }
            }}
          >
            {notes.length > 0 && (
              <Box sx={{ marginTop: '8px', marginBottom: '8px' }}>
                <Notifications variant="error" notes={notes} />
              </Box>
            )}
            <Comments />
          </Box>
          <ResizeBox enableResize={enableResize} />
        </Paper>
      </Paper>
    </Draggable>
  );
};

CommentModal.propTypes = {
  onSetCommentState: PropTypes.func.isRequired,
  currentInvoiceId: PropTypes.string.isRequired,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onInit: PropTypes.func.isRequired,
  commentState: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onUnlockComment: PropTypes.func.isRequired,
  commentLock: PropTypes.object.isRequired,
  onStoreCommentLock: PropTypes.func.isRequired,
};

export default compose(connect(mapState, mapDispatch))(CommentModal);
