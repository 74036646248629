import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Box, Button, Tooltip } from '@mui/material';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import makeStyles from '@mui/styles/makeStyles';
import { checkBtnStatus } from './digitalEditsUtils';

import {
  getInvoice,
  getHasUnsubmitted,
  getCommentState,
  getCurrentInvoiceId,
  getIsUpdatingInvoiceStatus,
  getAcEditRecord,
  getAnyPendingTimecards,
} from 'selectors/digitalEdits';
//Actions
import { setCommentState, updateInvoiceStatus } from 'actions/digitalEdits';
import { show as openModal } from 'actions/modalDialog';

import { commentStateProps } from './DigitalEditProps';

import JumpToTitle from './JumpToTitle';

const mapState = state => ({
  invoice: getInvoice(state),
  hasUnsubmitted: getHasUnsubmitted(state),
  commentState: getCommentState(state),
  currentInvoiceId: getCurrentInvoiceId(state),
  isUpdatingStatus: getIsUpdatingInvoiceStatus(state),
  acEditRecord: getAcEditRecord(state),
  anyPending: getAnyPendingTimecards(state),
});
const mapDispatch = dispatch => ({
  onSetCommentState: commentState =>
    dispatch(setCommentState({ commentState })),
  onOpenModal: params => dispatch(openModal(params)),
  onUpdateInvoiceStatus: invoiceStatus =>
    dispatch(updateInvoiceStatus({ invoiceStatus })),
});

const useStyles = makeStyles(({ palette }) => ({
  actionsBorderBox: {
    border: `1px solid ${palette.grey[200]}`,
    padding: '4px',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'flex-end',
    '& > *:first-child': {
      marginLeft: 0,
    },
  },
  buttonIcon: { marginRight: '4px' },
  noBorder: {
    padding: '0px',
    alignItems: 'center',
    border: 'none',
    marginLeft: 8,
  },
}));

function HeaderActionButtons(props) {
  const classes = useStyles();
  const {
    invoice,
    commentState,
    onSetCommentState,
    isUpdatingStatus,
    onOpenModal,
    hasUnsubmitted,
    currentInvoiceId,
    onUpdateInvoiceStatus,
    noBorder = false,
    acEditRecord,
    anyPending,
  } = props;

  let { showReBtns, revisionsDisabled } = checkBtnStatus(invoice.status);
  let commentButton;
  switch (commentState) {
    case 'closed':
      commentButton = {
        text: hasUnsubmitted ? 'Update Comments' : 'Add Comments',
        action: () => onSetCommentState('modal'),
      };
      break;
    case 'modal':
      commentButton = {
        text: 'Close Comment Modal',
        action: () => onSetCommentState('closed'),
      };
      break;

    default:
      //value will be the child window when window is open
      commentButton = {
        text: 'Close Comment Window',
        action: () => {
          commentState.close();
          onSetCommentState('closed');
        },
      };
      break;
  }

  const revisionActionBtns = (
    <Fragment>
      <Button
        variant="outlined"
        onClick={commentButton.action}
        disabled={isUpdatingStatus}
        startIcon={<AddCommentOutlinedIcon />}
      >
        {commentButton.text}
      </Button>
      <Button
        variant="outlined"
        onClick={() => {
          onOpenModal({ dialog: 'EditUploadFiles' });
        }}
        disabled={isUpdatingStatus}
        startIcon={<NoteAddIcon />}
      >
        Upload
      </Button>
      {showReBtns && (
        <Tooltip
          title={
            acEditRecord ? (
              <JumpToTitle
                acEditRecord={acEditRecord}
                action="resubmit this invoice."
              />
            ) : (
              ''
            )
          }
        >
          <div>
            <Button
              disabled={isUpdatingStatus || !!acEditRecord || anyPending}
              onClick={() => {
                onOpenModal({ dialog: 'ResubmitInvoice' });
              }}
            >
              Resubmit to C&C
            </Button>
          </div>
        </Tooltip>
      )}
    </Fragment>
  );

  const nonRevisionActionBtns = (
    <Fragment>
      <Button
        variant="outlined"
        onClick={() =>
          onOpenModal({
            dialog: 'DigitalEditsCommentsLite',
            modalParams: { readOnly: true, invoiceId: currentInvoiceId },
          })
        }
        disabled={isUpdatingStatus}
        startIcon={<ModeCommentOutlinedIcon />}
      >
        View Comments
      </Button>
      <Button
        variant="outlined"
        onClick={() => {
          onOpenModal({
            dialog: 'EditUploadFiles',
            modalParams: { readOnly: true },
          });
        }}
        disabled={isUpdatingStatus}
        startIcon={<InsertDriveFileIcon />}
      >
        View Uploads
      </Button>

      {showReBtns && (
        <Button
          disabled={revisionsDisabled || isUpdatingStatus}
          onClick={() => onUpdateInvoiceStatus('W')}
        >
          Request Revisions
        </Button>
      )}
    </Fragment>
  );

  const actionBtns =
    invoice.status === 'W' ? revisionActionBtns : nonRevisionActionBtns;

  return (
    <Box
      className={clsx(classes.actionsBorderBox, {
        [classes.noBorder]: noBorder,
      })}
    >
      {actionBtns}
    </Box>
  );
}

HeaderActionButtons.propTypes = {
  invoice: PropTypes.object.isRequired,
  commentState: commentStateProps,
  onSetCommentState: PropTypes.func.isRequired,
  hasUnsubmitted: PropTypes.bool.isRequired,
  isUpdatingStatus: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  currentInvoiceId: PropTypes.string.isRequired,
  onUpdateInvoiceStatus: PropTypes.func.isRequired,
  noBorder: PropTypes.bool,
  acEditRecord: PropTypes.string,
};

export default connect(mapState, mapDispatch)(HeaderActionButtons);
