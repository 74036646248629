import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
//components
import { Input } from '@mui/material';
import { DECIMAL_NUM_REGEX } from '../CrewListUtils';
const HoursCell = props => {
  const {
    baseOnFocus,
    baseOnChange,
    baseOnBlur,
    localVal,
    // error,
    id,
    originalValue,
    field,
    setLocalVal,
    meta,
  } = props;

  useEffect(() => {
    if (originalValue === field && meta.pristine) {
      setLocalVal(originalValue);
    }
  }, [originalValue, field, setLocalVal, meta.pristine]);

  const onChange = e => {
    const t = e.target.value;
    // ignore any input thats not a number or decimal
    if (t.match(DECIMAL_NUM_REGEX) === null) {
      if (t === '') {
        baseOnChange(t);
      } else if (localVal) baseOnChange(localVal);
    } else {
      if (t.includes('.')) {
        const numSplit = t.split('.');
        // do not allow a number greater than 99 before the decimal
        if (numSplit[0].length > 2) {
          baseOnChange(localVal);
        } else {
          // limit to 2 decimal points
          baseOnChange(
            t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 3),
          );
        }
      } else {
        // do not allow a number longer than 2 digits or greater than 99
        if (t.length > 2 || +t > 99) {
          baseOnChange(localVal);
        } else {
          baseOnChange(t);
        }
      }
    }
  };

  let val = localVal ? localVal : localVal === 0 ? localVal : '';

  return (
    <Input
      id={id}
      type={'text'}
      variant="outlined"
      value={val}
      onFocus={baseOnFocus}
      onChange={onChange}
      onBlur={() => {
        baseOnBlur();
      }}
      inputProps={{
        autoComplete: 'off',
      }}
    />
  );
};

HoursCell.propTypes = {
  baseOnFocus: PropTypes.func.isRequired,
  baseOnChange: PropTypes.func.isRequired,
  baseOnBlur: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  originalValue: PropTypes.number,
  field: PropTypes.number,
  setLocalVal: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
};

export default HoursCell;
