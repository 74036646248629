import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { FieldArray } from 'redux-form';
import { timecardProps } from 'components/props/timecard';
import { MEALS } from 'components/Shared/constants';
import WeekDay from './WeekDay';
import { enabledAccountCodes, getTotalHours } from 'utils/weekUtils';

const style = ({ palette }) => ({
  root: {},
  totalHoursCell: {
    paddingRight: 5,
    verticalAlign: 'middle',
    color: palette.primary.main,
    fontWeight: 700,
    fontSize: 16,
  },
  hoursCell: {
    paddingRight: 5,
    verticalAlign: 'middle',
    color: palette.primary.main,
    fontWeight: 700,
    fontSize: 16,
    textAlign: 'center',
  },
  totalHoursRow: {
    backgroundColor: palette.primary.background,
    padding: '10px 5px',
  },
});

class TimecardDaysMobile extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    timecard: timecardProps.isRequired,
    activeDays: PropTypes.object,
    onCopyToAll: PropTypes.func,
    showAccountCodes: PropTypes.bool,
  };

  mealColumns() {
    const { timecard, showLastMan1In, showNdm, showNdb, caRegion } = this.props;
    const mealContent = [];

    if (showNdb) {
      const ndbLabel = caRegion ? 'General Call' : 'NDB In';
      mealContent.push({ columnId: `ndb_in`, label: ndbLabel });
    }
    _.times(timecard.meals || 1, index => {
      const outKey = `meal${index + 1}_out`;
      const inKey = `meal${index + 1}_in`;
      mealContent.push({ columnId: inKey, label: `${MEALS[index].label} Out` });
      mealContent.push({ columnId: outKey, label: `${MEALS[index].label} In` });
      if (showLastMan1In && index === 0) {
        mealContent.push({ columnId: 'lastMan1In', label: `Last Man In` });
      }

      if (showNdm && index === 0) {
        mealContent.push(
          { columnId: `ndm_out`, label: `NDM Out` },
          { columnId: `ndm_in`, label: `NDM In` },
        );
      }
    });

    return mealContent;
  }

  renderDays({
    accountCodesEnabled,
    activeDays,
    colLength,
    editableFields,
    fields,
    states,
    timecard,
    classes,
    onCopyToAll,
    isViewOnly,
    loadDayTypes,
    stateLabel,
    isCityVisible,
    project,
    ...props
  }) {
    let firstDay = true;
    const daysError = (props.error && props.error.days) || [];

    const weekdays = fields.map((member, index) => {
      const day = timecard.days[index];
      const firstDayIndex = firstDay;
      const dayError = daysError[index];
      if (day && day.isActive) {
        firstDay = false;
        return (
          <WeekDay
            index={index}
            stateLabel={stateLabel}
            isCityVisible={isCityVisible}
            key={day.date}
            member={member}
            day={day}
            firstDay={firstDayIndex}
            meals={timecard.meals || 1}
            states={states}
            colLength={colLength}
            even={index % 2 === 0}
            timecard={timecard}
            accountCodesEnabled={accountCodesEnabled}
            onCopyToAll={onCopyToAll}
            viewOnly={isViewOnly}
            editableFields={editableFields}
            dayError={dayError}
            loadDayTypes={loadDayTypes}
            project={project}
            {...props}
          />
        );
      }

      return null;
    });

    return weekdays;
  }

  render() {
    const {
      classes,
      timecard,
      activeDays,
      payrollSettings,
      projectSettings,
      onCopyToAll,
      role = 'employee',
      loadDayTypes,
      isCityVisible,
      caRegion,
      project,
    } = this.props;

    const accountCodesEnabled = enabledAccountCodes(projectSettings, role);
    const stateLabel = caRegion ? 'Work Prov' : 'Work State';
    const headers = [
      { columnId: 'day', label: 'Day' },
      {
        columnId: 'workLocation',
        label: 'Work Location',
      },
      { columnId: 'dayType', label: 'Day Type' },
      { columnId: 'workState', label: stateLabel },
      { columnId: 'workCity', label: 'Work City', hidden: !isCityVisible },
      { columnId: 'call', label: 'Call' },
      ...this.mealColumns(),
      { columnId: 'wrap', label: 'Wrap' },
      { columnId: 'hours', label: 'Hours', alignRight: true },
      { columnId: 'actions', label: 'Actions', align: 'center' },
    ];

    let headerColumns = headers;
    if (caRegion) {
      headerColumns = headers.filter(
        column => column.label !== 'Work Location',
      );
    }

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <FieldArray
            stateLabel={stateLabel}
            isCityVisible={isCityVisible}
            name="days"
            component={this.renderDays}
            activeDays={this.props.activeDays}
            states={this.props.states}
            timecard={timecard}
            colLength={headerColumns.length}
            payrollSettings={payrollSettings}
            projectSettings={projectSettings}
            accountCodesEnabled={accountCodesEnabled}
            onCopyToAll={onCopyToAll}
            rerenderOnEveryChange
            isViewOnly={this.props.isViewOnly}
            editableFields={this.props.editableFields}
            loadDayTypes={loadDayTypes}
            project={project}
            {...this.props}
          />
        </Grid>
        <Grid container className={classes.totalHoursRow}>
          <Grid item xs={1} />
          <Grid
            item
            xs={5}
            className={classes.totalHoursCell}
            align="right"
            colSpan={headerColumns.length - 3}
          >
            Total Hours
          </Grid>
          <Grid item xs={6} className={classes.hoursCell}>
            {getTotalHours(timecard, activeDays)}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(style)(TimecardDaysMobile);
