import React, { useMemo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
  Select,
  MenuItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { isRegionCanada } from 'utils/helperFunctions';
import { deliveryMethodProp } from 'feature/DigitalEdits/DigitalEditProps';

const useStyles = makeStyles(({ palette }) => ({
  InvoiceApproveRadioDropDown: {},
  radioLabel: {
    '& > .MuiRadio-root': {
      padding: '0px 8px',
    },
  },
  labelSelected: {
    color: 'blue',
  },
  labelStyles: {
    color: `${palette.gray['-9']} !important`,
    fontSize: '14px',
    fontWeight: 700,
    '&:focus': {
      color: `${palette.gray['-9']} !important`,
    },
    marginBottom: '8px',
  },
  warningText: {
    color: palette.warning.dark,
    position: 'absolute',
    width: '95%',
    left: '45%',
    fontSize: '0.75rem',
  },
}));

const InvoiceApproveRadioDropDown = props => {
  const classes = useStyles();

  const {
    deliveryMethod,
    setDeliveryMethod,
    deliveryMethodOptions,
    printPriority,
  } = props;

  const deliveryMethodList = useMemo(
    () => deliveryMethodOptions.filter(dm => dm.selectable),
    [deliveryMethodOptions],
  );

  const offListOption = useMemo(
    () =>
      deliveryMethodOptions.find(
        o => o.code === Number(printPriority) && o.code !== 20,
      ) || null,
    [deliveryMethodOptions, printPriority],
  );

  const [deliveryRadio, setDeliveryRadio] = useState(
    offListOption ? 'c&c' : 'default',
  );
  const radioChangedRef = useRef(false);
  useEffect(() => {
    radioChangedRef.current = true;
  }, [deliveryRadio]);

  useEffect(() => {
    if (radioChangedRef.current) {
      if (deliveryRadio === 'default') {
        const printOnSiteOption = deliveryMethodOptions.find(
          o => o.code === 20,
        );
        setDeliveryMethod(printOnSiteOption);
      } else {
        const printAtCCOption = deliveryMethodOptions.find(o => o.code === 16);
        setDeliveryMethod(printAtCCOption);
      }
      radioChangedRef.current = false;
    }
  }, [
    deliveryMethodList,
    deliveryMethodOptions,
    deliveryRadio,
    setDeliveryMethod,
  ]);

  useEffect(() => {
    if (offListOption) {
      setDeliveryMethod(offListOption);
      setDeliveryRadio('c&c');
    }
  }, [offListOption, setDeliveryMethod]);

  if (
    offListOption &&
    !deliveryMethodList.find(o => o.code === offListOption.code)
  ) {
    deliveryMethodList.push(offListOption);
  }

  const deliveryRadioRef = useRef(null);
  const checkLabel = isRegionCanada() ? 'cheque' : 'check';

  return (
    <FormControl>
      <FormLabel className={classes.labelStyles}>
        Where should this {checkLabel} file be printed?
      </FormLabel>
      <RadioGroup
        value={deliveryRadio}
        name="deliveryRadioGroup"
        onChange={(e, newVal, c) => {
          setDeliveryRadio(newVal);
        }}
      >
        <FormControlLabel
          className={clsx('PENDO_digitalEditsPrintOnSite', classes.radioLabel, {
            [classes.labelSelected]: deliveryRadio === 'default',
          })}
          value="default"
          control={<Radio />}
          label="Print On-Site"
        />
        <FormControlLabel
          className={clsx(
            'PENDO_digitalEditsPrintAtCastandCrew',
            classes.radioLabel,
            {
              [classes.labelSelected]: deliveryRadio === 'c&c',
            },
          )}
          value="c&c"
          control={<Radio ref={deliveryRadioRef} />}
          label={
            <Box>
              <Box
                sx={{ display: 'flex', alignItems: 'center' }}
                onClick={() => {
                  if (deliveryRadio === 'default') {
                    deliveryRadioRef.current.click();
                  }
                }}
              >
                Print at Cast & Crew
                <Select
                  variant="standard"
                  sx={{
                    mb: 1,
                    ml: 1,
                    width: '212px',
                  }}
                  className={`PENDO_digitalEditsDeliveryMethod`}
                  displayEmpty
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return <em>Select a delivery method</em>;
                    }
                    return deliveryMethod.label || '';
                  }}
                  disabled={deliveryRadio === 'default'}
                  value={
                    deliveryMethod?.code === 20
                      ? ''
                      : deliveryMethod?.code || ''
                  }
                  onChange={e => {
                    const code = e.target.value;
                    setDeliveryMethod(
                      deliveryMethodList.find(d => d.code === code),
                    );
                  }}
                >
                  {deliveryMethodList.map(o => (
                    <MenuItem
                      key={o.code}
                      value={o.code}
                      className={`PENDO_digitalEditsDeliveryOption${o.label?.replaceAll(
                        ' ',
                        '',
                      )}`}
                    >
                      {o.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          }
        />
      </RadioGroup>
    </FormControl>
  );
};

InvoiceApproveRadioDropDown.propTypes = {
  deliveryMethod: deliveryMethodProp,
  setDeliveryMethod: PropTypes.func.isRequired,
  deliveryMethodOptions: PropTypes.arrayOf(deliveryMethodProp).isRequired,
  printPriority: PropTypes.string.isRequired,
};

export default InvoiceApproveRadioDropDown;
