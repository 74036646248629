import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { formValueSelector } from 'redux-form';
import { Grid } from '@mui/material';
import { createSelector } from 'reselect';
import { getFormValues } from 'selectors/formValues';
import { currentUser } from 'selectors/session';
import { PA, UPM } from 'components/props/profiles';
import {
  convertPaidHours,
  makeTextFilter,
  makeTimecardsFilter,
  WTC_FORM_NAME,
  RESUBMIT_WORKFLOW,
} from 'utils/wtcWeekUtils';

import {
  getCountriesV1,
  getStatesV1,
  getCitiesV1,
  getCountiesV1,
  getSubdivisionsV1,
} from 'selectors/location';

import { makeFilterSelectors } from './shared';

const immutableArray = Object.freeze([]);
const EMPTY_PAID_HOURS = Object.freeze({ empty: true });
export const getCurrentTimecardHeaderId = state =>
  _.get(state, 'wtc.currentTimecardHeaderId');

export const getWorksightId = reviewType => id => state =>
  _.get(state, `reviews.${reviewType}.batches.${id}.details.worksightId`, '');

export const getTimecardsInDrawer = state =>
  _.get(state, 'wtc.timecardsInDrawer', []);

export const getHPlusStatus = state => {
  return formSelector(state, 'hPlusStatus');
};

export const getIsDraft = state => getHPlusStatus(state) === 'draft';

export const getIsEmergencyTC = createSelector(
  [getFormValues(WTC_FORM_NAME)],
  timecard => {
    return !!timecard.emergencyType;
  },
);

export const getTextFilter = state => _.get(state, 'wtc.textFilter', '');
export const getFilters = state => _.get(state, 'wtc.filters');

export const getDealMemos = state => _.get(state, 'wtc.dealMemos', []);
export const getDownloadingReport = state =>
  _.get(state, 'wtc.downloadingReport', false);
//filter for left drawer
export const getFiltersActive = createSelector([getFilters], filters => {
  const activeFilters = {
    anySelected: false,
  };
  for (const filterName in filters) {
    if (Object.hasOwnProperty.call(filters, filterName)) {
      const filterList = filters[filterName];
      activeFilters[filterName] = filterList
        .filter(f => f.selected)
        .map(f => f.value);
      if (activeFilters[filterName].length > 0) {
        activeFilters.anySelected = true;
      }
    }
  }

  return activeFilters;
});
const getFilteredTimecards = createSelector(
  [getTimecardsInDrawer, getTextFilter, getFiltersActive],
  (timecards, textFilter, activeFilters) => {
    const textFilterFunc = makeTextFilter(textFilter);

    let result = timecards.filter(textFilterFunc);
    if (activeFilters.anySelected) {
      const filterFunc = makeTimecardsFilter(activeFilters);
      result = result.filter(filterFunc);
    }
    return result;
  },
);

export const getFilteredCount = createSelector(
  [getFilteredTimecards, getTimecardsInDrawer],
  (filtered, total) => {
    const filteredCount = filtered.length;
    const totalCount = total.length;
    return { total: totalCount, showing: filteredCount };
  },
);

export const getSortBy = state => _.get(state, 'wtc.sortBy');

export const getFilterSortedTimecards = createSelector(
  [getFilteredTimecards, getSortBy],
  (timecards, sortBy) => {
    if (sortBy.sortOrder === 'decSort') {
      return _.chain(timecards)
        .sortBy(sortBy.field, 'employee', 'lastModified')
        .reverse()
        .value();
    } else {
      return _.chain(timecards)
        .sortBy(sortBy.field, 'employee', 'lastModified')
        .value();
    }
  },
);

export const workSchedules = state => _.get(state, 'wtc.workSchedules', []);

export const getTCBreakdown = state => _.get(state, 'wtc.breakdown', {});
export const getComments = state => _.get(state, 'wtc.comments', []);
export const getNextApprovers = state => _.get(state, 'wtc.nextApprovers', []);

export const occupationCodes = state => _.get(state, 'wtc.occupationCodes', []);
export const workLocations = state => _.get(state, 'wtc.workLocations', []);

export const getWorkLocationTypes = createSelector([workLocations], list => {
  return list.slice() || [];
});

export const getOccupationCodes = createSelector([occupationCodes], list => {
  return list.slice() || [];
});

export const getWorkSchedules = createSelector([workSchedules], list => {
  return list.slice() || [];
});

export const getAllowancesTableOrder = state =>
  _.get(state, 'wtc.allowancesTableOrder', {});

export const getTimecard = state => _.get(state, 'wtc.timecard', {});

export const getPaidHours = state => _.get(state, 'wtc.paidHours', {});
// loadings
export const getLoading = state => _.get(state, 'wtc.loading', {});
export const getLoadingDrawerTimecards = state =>
  _.get(state, 'wtc.loadingDrawerTimecards', false);
export const getLoadingTimecard = state =>
  _.get(state, 'wtc.loadingTimeCard', false);
export const getLoadingComments = state =>
  _.get(state, 'wtc.processing.comments', false);
export const getLoadingUndo = state => _.get(state, 'wtc.loadingUndo', false);

export const getLoadingHistory = state =>
  _.get(state, 'wtc.processing.wtcHistory', false);

export const isSavingTimecard = state => _.get(state, `wtc.savingTimecard`);

export const isCurrentUserUPM = state => {
  const activeUser = currentUser(state);
  const userRole = activeUser && activeUser.role;
  return userRole === UPM;
};

export const isCurrentUserPA = state => {
  const activeUser = currentUser(state);
  const userRole = activeUser && activeUser.role;
  return userRole === PA;
};
export const currentUserLevel = state => {
  const activeUser = currentUser(state);
  const userLevel = activeUser && activeUser.level;
  return userLevel;
};

const getApprovingTimecards = state =>
  _.get(state, `wtc.approvingTimecards`, {});

export const isApprovingTimecard = (state, timecardEntryHeaderId) => {
  const approvingTimecards = _.get(state, `wtc.approvingTimecards`, {});
  return !!approvingTimecards[timecardEntryHeaderId];
};

export const isApprovingCurrentTimecard = createSelector(
  [getApprovingTimecards, getCurrentTimecardHeaderId],
  (approvingTimecards, currentHeaderId) => {
    return !!approvingTimecards[currentHeaderId];
  },
);

export const isRejectingTimecard = state =>
  _.get(state, `wtc.rejectingTimecard`, false);

export const loadingChecks = createSelector([getLoading], loadings => {
  let values = Object.values(loadings);

  return values.indexOf(true) >= 0 ? true : false;
});
export const getDMScaleRatesLoading = state =>
  _.get(state, 'wtc.dmScaleRatesLoading');

export const wtcProcessingChecks = state => {
  const processing = _.get(state, 'wtc.processing', {});
  let values = Object.values(processing);
  return values.indexOf(true) >= 0 ? true : false;
};

export const isWTCUpdating = createSelector(
  [
    isApprovingCurrentTimecard,
    isRejectingTimecard,
    getLoadingUndo,
    loadingChecks,
    wtcProcessingChecks,
  ],
  (approving, rejecting, undoing, loading, processing) => {
    // cnslog(
    //   //handy for debugging this
    //   'wtcUpdating:',
    //   saving,
    //   approving,
    //   rejecting,
    //   undoing,
    //   loading,
    //   processing,
    // );
    const group1 = approving || rejecting;
    const group2 = undoing || loading || processing;

    const wtcUpdating = group1 || group2;
    return wtcUpdating;
  },
);

/**
 * Determines if WTC is disabled for PA
 * UPM is not affected by this setting
 */
export const isWTCDisabled = createSelector(
  [isWTCUpdating, currentUser, getHPlusStatus],
  (wtcUpdating, user, hPlusStatus) => {
    const userRole = user?.role;

    let isWTCDisabled = true;
    if (userRole === PA) {
      isWTCDisabled =
        hPlusStatus === 'pending_pa_review' || hPlusStatus === 'draft'
          ? false
          : true;
    }
    return isWTCDisabled || wtcUpdating;
  },
);

export const isAllowanceDisabled = createSelector(
  [isWTCUpdating, isWTCDisabled, getTimecard, currentUser],
  (wtcUpdating, isWTCDisabled, timecard, user) => {
    const userRole = user && user.role;
    const hPlusStatus = timecard && timecard.hPlusStatus;
    let isAllowanceDisabled = true;
    if (userRole === PA) {
      isAllowanceDisabled =
        !isWTCDisabled || hPlusStatus === 'draft' ? false : true; // signed off or draft allowance has to be enabled
    }
    return isAllowanceDisabled || wtcUpdating;
  },
);

export const isSaveDisabledForPA = createSelector(
  [isWTCUpdating, getHPlusStatus, currentUser],
  (wtcUpdating, hPlusStatus, user) => {
    const userRole = user?.role;
    let isSaveDisabledForPA = false;
    if (userRole === PA) {
      if (wtcUpdating) return true;

      isSaveDisabledForPA =
        hPlusStatus === 'pending_pa_review' || hPlusStatus === 'draft'
          ? false
          : true;
    }
    return isSaveDisabledForPA;
  },
);

export const getCurrentApprovers = state =>
  _.get(state, 'wtc.currentApprovers', []);

export const isFieldUPMEnabled = createSelector(
  [isWTCDisabled, getTimecard, currentUser, getCurrentApprovers],
  (isWTCDisabled, timecard, user, approvers) => {
    const userRole = user && user.role;

    if (userRole !== UPM) return isWTCDisabled;

    const hPlusStatus = timecard && timecard.hPlusStatus;

    const upmCanEdit = hPlusStatus === 'pending_upm_review';

    const userId = user && user.id;
    const result = _.find(
      approvers,
      approver => approver.approverId === userId,
    );

    const canEditAndIsApprover = !!result && upmCanEdit;

    //Flipping since a true value disables the fields
    return !canEditAndIsApprover;
  },
);

export const canSubmitTimecard = createSelector(
  [currentUser, getTimecard, getCurrentApprovers],
  (activeUser, timecard, approvers) => {
    const userRole = activeUser && activeUser.role;
    const userId = activeUser && activeUser.id;
    const hPlusStatus = timecard && timecard.hPlusStatus;

    if (userRole === PA && hPlusStatus === 'draft') {
      return false; // This should not be combined with other conditions as there wont be current approvers for DTS
    }

    //UPM needs to use HPlusStatus since all upm levels are 'Approved' for
    const result = _.find(
      approvers,
      approver => approver.approverId === userId,
    );

    if (userRole === UPM && hPlusStatus === 'pending_upm_review') {
      return !!result;
    }

    if (userRole === PA && hPlusStatus === 'pending_pa_review') {
      return !!result;
    }
    return false;
  },
);

export const getCanRenderTimecard = createSelector(
  [loadingChecks, getLoadingTimecard, getTimecard],
  (loadings, loadingTimecard, timecard) => {
    return !loadings && !_.isEmpty(timecard) && !loadingTimecard;
  },
);

export const parsedPaidHour = createSelector(
  [getFormValues(WTC_FORM_NAME), getPaidHours],
  (timecard, paidHours) => {
    if (!_.isEmpty(timecard)) {
      const dayOfWeekIdxs = timecard.details?.map(detail =>
        moment(detail.effectiveDate, 'YYYY-MM-DD').day(),
      );
      return convertPaidHours(paidHours, dayOfWeekIdxs);
    }
    return EMPTY_PAID_HOURS;
  },
  {
    devModeChecks: {
      inputStabilityCheck: 'never',
    },
  },
);

export const getTableFieldOrder = state =>
  _.get(state, 'wtc.tableFieldOrder', {});

export const getCities = state => _.get(state, `wtc.cities`, []);

//dealMemos for dropdown selection
export const getDealMemoOptions = createSelector([getDealMemos], dealMemos => {
  return _.cloneDeep(dealMemos) || [];
});

export const parsedTimecard = createSelector([getTimecard], timecard => {
  return timecard;
});

export const getEmployeeId = state =>
  _.get(state, 'wtc.timecard.employee.id', '');

export const episodes = state => _.get(state, 'wtc.episodes', []);
export const getEpisodes = createSelector([episodes], episodes => {
  return episodes.slice() || [];
});

export const splitHourTypes = state => {
  return _.get(state, 'wtc.splitHourTypes', []);
};

export const getSplitHourTypes = createSelector([splitHourTypes], list => {
  return list.slice() || [];
});

const payCodes = state => _.get(state, 'wtc.payCodes', []);

export const getAllowanceTypes = state =>
  _.get(state, 'wtc.allowanceTypes', []);

export const getPayCodes = createSelector([payCodes], list => {
  return list.slice() || [];
});

export const stateLists = state => _.get(state, 'wtc.stateLists', {});

export const getProspectiveTimecard = state =>
  _.get(state, 'wtc.prospectiveTimecard', {});

export const getTCEntryHeaderId = state =>
  _.get(state, 'wtc.timecard.timecardEntryHeaderId', '');
export const getActionType = state => _.get(state, 'wtc.actionType', '');

export const getProducerWWStatus = state =>
  _.get(state, 'wtc.producerWWChanged', false);
export const getEmployeeWWStatus = state =>
  _.get(state, 'wtc.employeeWWchanged', false);

export const getDayTypes = state => _.get(state, 'wtc.dayTypes', []);

export const getDayTypeOptions = createSelector([getDayTypes], dayTypes => {
  return dayTypes.slice() || [];
});

export const getFullFormValues = state => {
  return getFormValues(WTC_FORM_NAME)(state);
};

export const getDateOptions = createSelector(
  [getFullFormValues],
  formTimecard => {
    const details = formTimecard?.details || immutableArray;
    const dates = details.map(day =>
      moment(day.effectiveDate, 'YYYY-MM-DD').format('YYYY-MM-DDT00:00:00'),
    );
    return dates;
  },
  {
    devModeChecks: {
      inputStabilityCheck: 'never',
    },
  },
);

export const getWeekEnding = state =>
  _.get(state, 'wtc.timecard.weekEndingDate', '');
export const getScaleRateError = state =>
  _.get(state, 'wtc.scaleRateError', {});

export const getScaleLoading = state =>
  _.get(state, 'wtc.loadingScaleRate', { loading: false });
export const getAutoCoding = state => _.get(state, 'wtc.autoCoding', false);

export const getLoadingNewBatch = state =>
  _.get(state, 'wtc.loadingNewBatch', false);
export const getNumTimecardsHidden = state =>
  _.get(state, 'wtc.numTimecardsHidden', null);

export const getIsMultiBatch = state => _.get(state, 'wtc.isMultiBatch');

//expected to be used as title prop on tooltip over batch title
export const getTitleHoverContent = createSelector(
  [
    getNumTimecardsHidden,
    getTimecardsInDrawer,
    getFilteredTimecards,
    getIsMultiBatch,
  ],
  (hiddenCount, timecards, filteredTimecards, isMultiBatch) => {
    if (!isMultiBatch && hiddenCount === null) return '';
    const timecardCount = timecards.length;
    const filteredCount = filteredTimecards.length;
    const totalCount = timecardCount + hiddenCount;
    const items = [{ left: 'Timecards:', right: totalCount }];
    const batchesCount = [
      ...new Set(timecards.map(tc => tc.batchWorksightHtgId)),
    ].length;
    const invoicesCount = [
      ...new Set(
        timecards.map(tc => tc.invoice).filter(invoice => invoice !== null),
      ),
    ].length;
    if (isMultiBatch) {
      items.unshift(
        { left: 'Invoices:', right: invoicesCount },
        { left: 'Batches:', right: batchesCount },
      );
    } else {
      if (hiddenCount > 0) {
        items.push({
          left: 'My workflow:',
          right: `${timecardCount} of ${totalCount}`,
        });
      }
    }
    if (filteredCount !== timecardCount) {
      items.push({
        left: 'Displaying: ',
        right: `${filteredCount} of ${timecardCount}`,
      });
    }
    return (
      <div>
        {items.map(item => (
          <Grid
            key={item.left}
            container
            spacing={2}
            style={{ whiteSpace: 'nowrap' }}
          >
            <Grid item xs={5}>
              {item.left}
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              {item.right}
            </Grid>
          </Grid>
        ))}
      </div>
    );
  },
);

export const getBatchInfo = state => _.get(state, 'wtc.batchInfo');

export const getBulkEditTooltip = createSelector(
  [getIsMultiBatch, getBatchInfo],
  (isMultiBatch, batchInfo) => {
    let bulkTooltip = '';
    if (!batchInfo) {
      //bulk edit disabled
      bulkTooltip = isMultiBatch
        ? 'You’re currently viewing multiple batches. Select a timecard to use bulk edit for its batch.'
        : '';
    } else {
      bulkTooltip = isMultiBatch ? (
        <div>
          Use bulk edit for batch:
          <div>
            {batchInfo.htgBatchNumber} - {batchInfo.name}
          </div>
        </div>
      ) : (
        'Bulk Edit'
      );
    }

    return bulkTooltip;
  },
);

export const getIsMoveDisabled = createSelector([getBatchInfo], batchInfo => {
  return !batchInfo || batchInfo?.lockStatus === 'hardlock';
});

export const getMoveTooltip = createSelector(
  [getIsMultiBatch, getBatchInfo, getIsMoveDisabled],
  (isMultiBatch, batchInfo, isMoveDisabled) => {
    let moveTooltip = '';
    if (isMoveDisabled) {
      moveTooltip =
        batchInfo?.lockStatus === 'hardlock'
          ? 'Cannot move timecards. Batch is hard locked.'
          : isMultiBatch
          ? 'You’re currently viewing multiple batches. Select a timecard to move timecards from its batch.'
          : '';
    } else {
      moveTooltip = isMultiBatch ? (
        <div>
          Move Timecards from batch:
          <div>
            {batchInfo.htgBatchNumber} - {batchInfo.name}
          </div>
        </div>
      ) : (
        'Move Timecards'
      );
    }
    return moveTooltip;
  },
);

//WTC download icon button tooltip title
export const getDownloadIconTooltip = createSelector(
  [
    getNumTimecardsHidden,
    getTimecardsInDrawer,
    getFilteredTimecards,
    getIsMultiBatch,
  ],
  (hiddenCount, timecards, filteredTimecards, isMultiBatch) => {
    const timecardCount = timecards.length;
    const filteredCount = filteredTimecards.length;
    const totalCount = timecardCount + hiddenCount;

    const items = [
      { left: 'Download: ', right: `${filteredCount} of ${timecardCount}` },
    ];

    if (!isMultiBatch) {
      if (hiddenCount === null) return '';
      items.unshift(
        { left: 'Timecards:', right: totalCount },
        { left: 'My workflow:', right: `${timecardCount} of ${totalCount}` },
      );
    }

    return (
      <div>
        {items.map(item => (
          <Grid
            key={item.left}
            container
            spacing={1}
            style={{ whiteSpace: 'nowrap' }}
          >
            <Grid item xs={5}>
              {item.left}
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              {item.right}
            </Grid>
          </Grid>
        ))}
      </div>
    );
  },
);

export const getRounding = state => _.get(state, 'wtc.rounding', {});

//still used by invoiceTemplate
export const getCountriesOptions = createSelector([getCountriesV1], list => {
  const result = list.map(county => {
    const countyRef = _.cloneDeep(county);
    return {
      value: countyRef.id,
      id: countyRef.id,
      label: countyRef.code + ' - ' + countyRef.name,
      code: countyRef.code,
      name: countyRef.name,
      ref: countyRef,
    };
  });
  return result || [];
});

export const getCitiesV2 = createSelector([getCitiesV1], list => {
  return list.slice() || [];
});

//Also used by crew/review/timecards
export const getCityOptions = createSelector([getCitiesV1], list => {
  const result = list.map(city => {
    const cityRef = _.cloneDeep(city);
    return {
      value: cityRef.id,
      id: cityRef.id,
      label: cityRef.code + ' - ' + cityRef.name,
      code: cityRef.code,
      name: cityRef.name,
      ref: cityRef,
      subset: true,
      subsetVal: cityRef.code,
      type: 'city',
    };
  });

  return result || [];
});
export const getCountriesV2 = createSelector([getCountriesV1], list => {
  return list.slice() || [];
});
export const getCountiesV2 = createSelector([getCountiesV1], list => {
  return list.slice() || [];
});

//still referenced in invoiceTemplate
export const getCountyOptions = createSelector([getCountiesV1], list => {
  const result = list.map(county => ({
    value: county.id,
    id: county.id,
    label: county.code + ' - ' + county.name,
    code: county.code,
    name: county.name,
    ref: county,
  }));

  return result || [];
});

const makeStateOptions = state => ({
  value: state.id,
  id: state.id,
  label: state.code + ' - ' + state.name,
  code: state.code,
  name: state.name,
  ref: _.cloneDeep(state),
  type: 'state',
});

export const getShowFringeInfo = createSelector(
  [getTimecard, getDealMemos],
  (timecard, dealMemos) => {
    const dealMemo = dealMemos.find(dm => dm.id === timecard?.dealMemo?.id);
    const guars = dealMemo?.guarantees;
    if (guars && Array.isArray(guars)) {
      return guars.some(g => g.grossIncludesVACFGN === true);
    }
    return false;
  },
);

export const getStatesV2 = createSelector([getStatesV1], list => {
  return list.slice() || [];
});

//Also used by crew/review/timecards
export const getStateOptions = createSelector([getStatesV1], list => {
  const result = list.map(makeStateOptions);
  return result || [];
});

export const getSubdivisionOptions = createSelector(
  [getSubdivisionsV1],
  list => {
    return list.slice() || [];
  },
);

export const {
  getCustomFilter,
  getListByFilterName,
  getFilteredDropdown,
  getSelectedCount,
} = makeFilterSelectors('wtc');

export const getWTCTimecardHeaderIds = state =>
  _.get(state, 'wtc.wtcTimecardHeaderIds');

export const getCurrentBatchWorksightId = state =>
  _.get(state, 'wtc.currentBatchWorksightId');

const formSelector = formValueSelector(WTC_FORM_NAME);

export const getCanEditWorkTimes = state =>
  _.get(state, 'wtc.canEditWorkTimes', false);

export const getHasWorkTimeChanges = state =>
  _.get(state, 'wtc.hasWorkTimeChanges', false);

export const getShowForceResubmitModal = createSelector(
  [getTimecard, getHasWorkTimeChanges, getIsDraft],
  (initialTimecard, hasWorkTimeChanges, isDraft) => {
    if (!!initialTimecard.emergencyType) return false;

    if (isDraft) return false;

    if (
      initialTimecard.workflowAction !== RESUBMIT_WORKFLOW &&
      hasWorkTimeChanges
    ) {
      return true;
    }
    return false;
  },
  {
    devModeChecks: {
      inputStabilityCheck: 'never',
    },
  },
);

export const getResubmitComment = state =>
  _.get(state, 'wtc.resubmitComment', '');

export const getPrevChangeComments = state =>
  _.get(state, 'wtc.prevChangeComments');

export const getPendingCalculation = state =>
  _.get(state, 'wtc.pendingCalculation');

export const isTimecardEmpty = createSelector(
  [getFullFormValues],
  formTimecard => {
    const { details, allowances } = formTimecard;
    const isTCDaysNonEmpty = details?.some(detail => detail?.dayType);
    const isTCAllowanceNonEmpty = allowances?.length > 0;
    const isTimecardEmpty = !isTCDaysNonEmpty && !isTCAllowanceNonEmpty;

    return isTimecardEmpty;
  },
);

export const getStoreId = state => _.get(state, 'wtc.storeId');
