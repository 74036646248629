import React from 'react';
import { Create } from 'containers/Employees/CrewTimecard';

import {
  FullWidthLayout,
  Header,
  ProjectHeader,
  AlertHeader,
} from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';
import { CrewTimecardWeek as SubNav } from 'containers/Nav/Pages';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    authenticated
    key="/projects/crew/timecards/weekEndings/department"
    id="/projects/crew/timecards/weekEndings/department"
    path="/projects/:projectId/timecards/week-ending/:weekEndingdate/department/:departmentId"
    layout={FullWidthLayout}
    headers={[Header, ProjectHeader, AlertHeader, SubNav]}
    component={Create}
    title="Create Crew Timecard"
  />
);
