import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import {
  Typography,
  TableRow,
  TableCell,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers-pro';

import { getMaxWeekEnding } from 'feature/DTS/dtsUtils';
import { DATE_FORMAT_DISPLAY } from 'components/Shared/constants';

export const useStyles = makeStyles(theme => ({
  dateField: {
    marginRight: theme.spacing(2),
  },
  runButton: {
    width: '100%',
  },
  row: {
    height: '90px',
  },
  checkbox: {
    marginLeft: '10px',
    verticalAlign: 'bottom',
    paddingTop: '10px',
  },
}));

export default function ReportRow(props) {
  const classes = useStyles();
  const { report, onRunReport, onRunHotCostReport } = props;
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isSplitsIncluded, setIsSplitsIncluded] = useState(false);

  const isSingleDate = !('to' in report) && 'from' in report;
  const isDisabled = isSingleDate
    ? report.requiresTimes && fromDate === null
    : report.requiresTimes && (fromDate === null || toDate === null);
  const idEndDisabled = report.requiresTimes && fromDate === null;
  const isHotCost = report.key === 'hot-cost';

  const hotCostMaxDate = useMemo(() => {
    return isHotCost ? getMaxWeekEnding() : null;
  }, [isHotCost]);

  function runReport() {
    if (isHotCost) {
      onRunHotCostReport({
        ...report,
        from: fromDate,
        isSplitsIncluded,
      });
    } else {
      onRunReport({
        ...report,
        from: fromDate,
        to: toDate,
      });
    }
  }

  return (
    <TableRow className={classes.row}>
      <TableCell>
        <Typography color="inherit">{report.name}</Typography>
      </TableCell>
      <TableCell>
        {report.requiresTimes && (
          <DatePicker
            margin="normal"
            id="mui-pickers-date"
            label={isHotCost ? 'Day' : 'Start'}
            clearable
            mask={'__-__-____'}
            format={DATE_FORMAT_DISPLAY}
            value={fromDate}
            onChange={setFromDate}
            maxDate={isHotCost ? hotCostMaxDate : undefined}
            slotProps={{
              textField: { className: classes.dateField },
            }}
          />
        )}
        {isHotCost && (
          <FormControlLabel
            className={classes.checkbox}
            label="Include Splits"
            control={
              <Checkbox
                label="Include Splits"
                checked={isSplitsIncluded}
                onChange={e => setIsSplitsIncluded(e.target.checked)}
              />
            }
          />
        )}
        {report.requiresTimes && !isSingleDate && (
          <DatePicker
            margin="normal"
            id="mui-pickers-date"
            label="End"
            clearable
            mask={'__-__-____'}
            format={DATE_FORMAT_DISPLAY}
            value={toDate}
            onChange={setToDate}
            disabled={idEndDisabled}
            minDate={fromDate ? fromDate : moment('1900-01-01')}
            slotProps={{
              textField: { className: classes.dateField },
            }}
          />
        )}
      </TableCell>
      <TableCell>
        <Button
          className={clsx(classes.runButton, `PENDO_${report.key}`)}
          disabled={isDisabled}
          onClick={runReport}
          color="primary"
          variant="contained"
        >
          Run
        </Button>
      </TableCell>
    </TableRow>
  );
}
