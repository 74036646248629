import React from 'react';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';

const ToggleCheckbox = props => {
  const {
    showCheckboxes,
    selectedTCS,
    canSelect,
    approvalFlowId,
    timecardEntryHeaderId,
    checkboxSelectionHandler,
  } = props;
  return showCheckboxes && canSelect ? (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            id={`${approvalFlowId}`}
            checked={selectedTCS.includes(timecardEntryHeaderId)}
            onChange={e => checkboxSelectionHandler(e, timecardEntryHeaderId)}
          />
        }
      />
    </FormGroup>
  ) : null;
};
export default ToggleCheckbox;
