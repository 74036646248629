import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  Paper,
  Checkbox,
  LinearProgress,
} from '@mui/material';
import { withStyleSheet } from 'shared/theme';
import { flagsProps, FLAG_DESCRIPTIONS } from 'components/props/flags';
import { TableList } from 'components/Shared/TableList';
import FlagButtons from './Buttons';

const style = () => ({
  root: {
    width: '100%',
    marginTop: 30,
    overflowX: 'auto',
    height: '81vh',
  },
  empty: {
    textAlign: 'center',
  },
  row: {
    height: '64px',
  },
  title: {
    marginLeft: 20,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
});

class FeatureFlags extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    flags: flagsProps.isRequired,
    loading: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onUpdateEnabled: PropTypes.func.isRequired,
  };

  static flagDescription(flag) {
    return FLAG_DESCRIPTIONS[flag.id] || 'Enable feature flag';
  }

  render() {
    const { classes, flags, loading, onUpdate, onUpdateEnabled } = this.props;
    let contentRows = null;

    if (flags && flags.length <= 0) {
      contentRows = (
        <TableRow>
          <TableCell colSpan={3} className={classes.empty}>
            {loading || 'No Feature Flags Found'}
          </TableCell>
        </TableRow>
      );
    } else {
      contentRows = flags.map(flag => {
        return (
          <TableRow hover key={flag.id} className={classes.row}>
            <TableCell className={classes.capitalize}>
              {/* unSnakecase flag name */}
              {flag.name.replace(/_/g, ' ')}
            </TableCell>
            <TableCell sx={{ width: '400px', textAlign: 'center' }}>
              <Checkbox
                checked={flag.enabled}
                color="primary"
                onChange={onUpdateEnabled(flag.id)}
              />
            </TableCell>
            <TableCell>{FeatureFlags.flagDescription(flag)}</TableCell>
          </TableRow>
        );
      });
    }

    const headers = [
      { columnId: 'name', label: 'Name', compact: true },
      { columnId: 'enabled', label: 'Enabled', compact: true },
      { columnId: 'description', label: 'Description', compact: true },
    ];

    return (
      <Paper className={classes.root}>
        <h4 className={classes.title}>Feature Flags</h4>
        {loading && <LinearProgress />}
        <TableList headers={headers}>{contentRows}</TableList>
        <FlagButtons onUpdate={onUpdate} loading={loading} />
      </Paper>
    );
  }
}

export default withStyleSheet('FeatureFlags', style)(FeatureFlags);
