//wtcTableFieldOrder are not going to use in WTC Grid
//Now WTC Grid fields are loading dynamically from API
//just keeping as it is for future reference if any
export const wtcTableFieldOrder = {
  splitButton: {
    position: 1,
    columnId: 'splitButton',
    label: '',
    group: 'special',
    visible: true,
    type: 'buttons',
    additionalField: false,
  },
  dayInfo: {
    position: 1,
    columnId: 'dayInfo',
    label: '',
    group: 'special',
    type: 'buttons',
    visible: true,
    additionalField: false,
  },
  locationType: {
    position: 2,
    columnId: 'locationType',
    label: 'Work Loc',
    type: 'auto-complete',
    visible: true,
    additionalField: false,
  },
  onProduction: {
    position: 3,
    columnId: 'onProduction',
    label: 'On Prod',
    type: 'checkbox',
    visible: true,
    additionalField: false,
  },
  dayType: {
    position: 4,
    columnId: 'dayType',
    label: 'Day Type',
    type: 'auto-complete',
    visible: true,
    additionalField: false,
  },
  'Hotel-Set': {
    position: 5,
    columnId: 'Hotel-Set',
    label: 'Hotel-Set',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: true,
  },
  callTime: {
    position: 6,
    columnId: 'callTime',
    label: 'Call Time',
    group: 'time',
    type: 'text',
    visible: true,
    additionalField: false,
  },
  'Start Travel': {
    position: 7,
    columnId: 'Start Travel',
    label: 'Start Travel',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: false,
  },
  'Start Travel To': {
    position: 8,
    columnId: 'Start Travel To',
    label: 'Start Travel To',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: false,
  },
  'Mkup/Wrd In': {
    position: 9,
    columnId: 'Mkup/Wrd In',
    label: 'Mkup/Wrd In',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: false,
  },
  'Mkup/Wrd Out': {
    position: 10,
    columnId: 'Mkup/Wrd Out',
    label: 'Mkup/Wrd Out',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: false,
  },
  'On Set': {
    position: 11,
    columnId: 'On Set',
    label: 'On Set',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: false,
  },
  ndb: {
    position: 12,
    columnId: 'ndb',
    label: 'NDB',
    type: 'checkbox',
    visible: false,
    additionalField: true,
  },
  ndbOut: {
    position: 13,
    columnId: 'ndbOut',
    label: 'NDB Out',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: true,
  },
  ndbIn: {
    position: 14,
    columnId: 'ndbIn',
    label: 'NDB In',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: true,
  },
  meal1Out: {
    position: 15,
    columnId: 'meal1Out',
    label: 'Meal 1 Out',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: true,
  },
  meal1In: {
    position: 16,
    columnId: 'meal1In',
    label: 'Meal 1 In',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: true,
  },
  lastMan1In: {
    position: 17,
    columnId: 'lastMan1In',
    label: 'Last Man In',
    group: 'time',
    type: 'text',
    visible: false,
    skipValidation: false,
    additionalField: true,
  },
  ndm: {
    position: 18,
    columnId: 'ndm',
    label: 'NDM',
    type: 'checkbox',
    visible: false,
    group: 'time',
    additionalField: true,
  },
  ndmOut: {
    position: 19,
    columnId: 'ndmOut',
    label: 'NDM Out',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: true,
  },
  ndmIn: {
    position: 20,
    columnId: 'ndmIn',
    label: 'NDM In',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: true,
  },
  meal2Out: {
    position: 21,
    columnId: 'meal2Out',
    label: 'Meal 2 Out',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: true,
  },
  meal2In: {
    position: 22,
    columnId: 'meal2In',
    label: 'Meal 2 In',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: true,
  },
  meal3Out: {
    position: 23,
    columnId: 'meal3Out',
    label: 'Meal 3 Out',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: true,
  },
  meal3In: {
    position: 24,
    columnId: 'meal3In',
    label: 'Meal 3 In',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: true,
  },
  wrapTime: {
    position: 25,
    columnId: 'wrapTime',
    label: 'Wrap',
    group: 'time',
    type: 'text',
    visible: true,
    additionalField: false,
  },
  'OnCall/StandBy': {
    position: 26,
    columnId: 'OnCall/StandBy',
    label: 'OnCall/StandBy',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: false,
  },
  'Mkup/Wrd Rem': {
    position: 27,
    columnId: 'Mkup/Wrd Rem',
    label: 'Mkup/Wrd Rem',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: false,
  },
  'Travel From': {
    position: 28,
    columnId: 'Travel From',
    label: 'Travel From',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: false,
  },
  'Travel To': {
    position: 29,
    columnId: 'Travel To',
    label: 'Travel To',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: false,
  },
  'Set-Hotel': {
    position: 30,
    columnId: 'Set-Hotel',
    label: 'Set-Hotel',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: true,
  },
  'Travel Home': {
    position: 31,
    columnId: 'Travel Home',
    label: 'Travel Home',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: false,
  },
  'Pay Hold': {
    position: 32,
    columnId: 'Pay Hold',
    label: 'Pay Hold',
    type: 'checkbox',
    visible: false,
    additionalField: false,
  },
  'Stunt Adj': {
    position: 33,
    columnId: 'Stunt Adj',
    label: 'Stunt Adj',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: false,
  },
  'DGA MVA': {
    position: 34,
    columnId: 'DGA MVA',
    label: 'DGA MVA',
    type: 'checkbox',
    visible: false,
    group: 'time',
    additionalField: true,
  },
  'DGA Prod Fee Prep': {
    position: 35,
    columnId: 'DGA Prod Fee Prep',
    label: 'Prep Prod Fee',
    type: 'checkbox',
    visible: false,
    group: 'time',
    additionalField: true,
  },
  'DGA COA': {
    position: 36,
    columnId: 'DGA COA',
    label: 'DGA CoA',
    type: 'checkbox',
    visible: false,
    group: 'time',
    additionalField: true,
  },
  'WB MP': {
    position: 37,
    columnId: 'WB MP',
    label: 'WB MP',
    type: 'checkbox',
    visible: false,
    group: 'time',
    additionalField: true,
  },
  NoCellAllow: {
    position: 38,
    columnId: 'NoCellAllow',
    label: 'No Cell Allow',
    type: 'checkbox',
    visible: false,
    group: 'time',
    additionalField: true,
  },
  HBOEX15: {
    position: 39,
    columnId: 'HBOEX15',
    label: 'HBOEX15',
    type: 'checkbox',
    visible: false,
    group: 'time',
    additionalField: true,
  },
  HBOEX20: {
    position: 40,
    columnId: 'HBOEX20',
    label: 'HBOEX20',
    type: 'checkbox',
    visible: false,
    group: 'time',
    additionalField: true,
  },
  grace1: {
    position: 41,
    columnId: 'grace1',
    label: 'Grace 1',
    type: 'checkbox',
    visible: false,
    additionalField: true,
  },
  grace2: {
    position: 42,
    columnId: 'grace2',
    label: 'Grace 2',
    type: 'checkbox',
    visible: false,
    additionalField: true,
  },
  wrapProvision: {
    position: 43,
    columnId: 'wrapProvision',
    label: 'Wrap Provision',
    type: 'checkbox',
    visible: false,
    additionalField: true,
  },
  french: {
    position: 44,
    columnId: 'french',
    label: 'French',
    type: 'checkbox',
    visible: false,
    additionalField: true,
  },
  frenchThreshold: {
    position: 45,
    columnId: 'frenchThreshold',
    label: 'French Limit',
    group: 'time',
    type: 'text',
    visible: false,
    additionalField: false,
  },
  rate: {
    position: 46,
    columnId: 'rate',
    label: 'Rate',
    type: 'text',
    visible: true,
    additionalField: false,
  },
  hoursWorked: {
    position: 47,
    columnId: 'hoursWorked',
    label: 'Worked Hours',
    group: 'special',
    type: 'text',
    visible: true,
    skipValidation: true,
    additionalField: false,
  },
  accountCode: {
    position: 80,
    columnId: 'accountCode',
    label: 'Account',
    group: 'meta-codes',
    type: 'text',
    visible: true,
    additionalField: false,
  },
  episode: {
    position: 81,
    columnId: 'episode',
    label: 'Episode',
    group: 'meta-codes',
    type: 'auto-complete',
    visible: true,
    additionalField: false,
  },
  series: {
    position: 82,
    columnId: 'series',
    label: 'Series',
    group: 'meta-codes',
    type: 'text',
    visible: true,
    additionalField: true,
  },
  location: {
    position: 83,
    columnId: 'location',
    label: 'Location',
    group: 'meta-codes',
    type: 'text',
    visible: false,
    additionalField: true,
  },
  set: {
    position: 84,
    columnId: 'set',
    label: 'Set',
    group: 'meta-codes',
    type: 'text',
    visible: true,
    additionalField: false,
  },
  insurance: {
    position: 85,
    columnId: 'insurance',
    label: 'Insurance',
    group: 'meta-codes',
    type: 'text',
    visible: false,
    additionalField: true,
  },
  freeField1: {
    position: 86,
    columnId: 'freeField1',
    label: 'FF1',
    type: 'text',
    group: 'meta-codes',
    visible: true,
    additionalField: false,
  },
  freeField2: {
    position: 87,
    columnId: 'freeField2',
    label: 'FF2',
    group: 'meta-codes',
    type: 'text',
    visible: true,
    additionalField: false,
  },
  freeField3: {
    position: 88,
    columnId: 'freeField3',
    label: 'FF3',
    group: 'meta-codes',
    type: 'text',
    visible: false,
    additionalField: true,
  },
  freeField4: {
    position: 89,
    columnId: 'freeField4',
    label: 'FF4',
    group: 'meta-codes',
    type: 'text',
    visible: false,
    additionalField: true,
  },
  workCountry: {
    position: 90,
    columnId: 'workCountry',
    label: 'Work Country',
    group: 'meta-codes',
    type: 'auto-complete',
    visible: false,
    additionalField: true,
  },
  workState: {
    position: 91,
    columnId: 'workState',
    label: 'Work State',
    group: 'meta-codes',
    type: 'auto-complete',
    visible: true,
    additionalField: false,
  },
  workCounty: {
    position: 92,
    columnId: 'workCounty',
    label: 'Work County',
    group: 'meta-codes',
    type: 'auto-complete',
    visible: false,
    additionalField: true,
  },
  workCity: {
    position: 93,
    columnId: 'workCity',
    label: 'Work City',
    group: 'meta-codes',
    type: 'auto-complete',
    visible: true,
    additionalField: true,
  },
  workSubdivision: {
    position: 94,
    columnId: 'workSubdivision',
    label: 'Work Loc PSD',
    group: 'meta-codes',
    type: 'auto-complete',
    visible: false,
    additionalField: true,
  },
  combineCheck: {
    position: 95,
    columnId: 'combineCheck',
    label: 'Combine Check',
    group: 'meta-codes',
    type: 'auto-complete',
    visible: false,
    additionalField: true,
  },
  occupationCode: {
    position: 96,
    columnId: 'occupationCode',
    label: 'Occupation',
    group: 'meta-codes',
    type: 'auto-complete',
    visible: true,
    additionalField: false,
  },
  schedule: {
    position: 97,
    columnId: 'schedule',
    label: 'Pay Schedule',
    group: 'meta-codes',
    type: 'auto-complete',
    visible: true,
    additionalField: false,
  },
  noPhoto: {
    position: 98,
    columnId: 'noPhoto',
    label: 'No Photo',
    group: 'meta-codes',
    type: 'checkbox',
    visible: false,
    additionalField: false,
  },
  dealMemo: {
    position: 99,
    columnId: 'dealMemo',
    label: 'Deal Memo ID',
    type: 'auto-complete',
    group: 'meta-codes',
    visible: true,
    additionalField: true,
  },
};

export const defaultAllowancesTableOrder = {
  date: {
    position: 1,
    columnId: 'date',
    path: 'date',
    label: 'Date',
    visible: true,
    type: 'auto-complete',
  },
  locationType: {
    position: 2,
    columnId: 'locationType',
    path: 'locationType',
    label: 'Work Loc',
    visible: true,
    type: 'auto-complete',
  },
  reason: {
    position: 4,
    columnId: 'reason',
    path: 'reason',
    label: 'Pay Type',
    visible: true,
    type: 'auto-complete',
  },
  flagBadge: {
    position: 5,
    columnId: 'flagBadge',
    path: 'special',
    label: '',
    visible: true,
    type: 'text',
  },
  units: {
    position: 6,
    columnId: 'hours',
    path: 'hours',
    label: 'Units',
    visible: true,
    type: 'text',
  },
  rate: {
    position: 7,
    columnId: 'rate',
    path: 'rate',
    label: 'Rate',
    visible: true,
    type: 'text',
  },
  factor: {
    position: 8,
    columnId: 'factor',
    path: 'factor',
    label: 'Factor',
    visible: true,
    type: 'text',
  },
  amount: {
    position: 9,
    columnId: 'amount',
    path: 'amount',
    label: 'Amount',
    visible: true,
    type: 'text',
  },
  combineCheck: {
    position: 10,
    columnId: 'combineCheck',
    path: 'combineCheck',
    label: 'Combine Check',
    visible: true,
    type: 'auto-complete',
  },
  combineCheckCode: {
    position: 11,
    columnId: 'combineCheckCode',
    path: 'combineCheckCode',
    label: 'Live Check',
    visible: false,
    type: 'checkbox',
  },
  accountCode: {
    position: 12,
    columnId: 'accountCode',
    path: 'accountCode',
    label: 'Account',
    visible: true,
    type: 'text',
  },
  episode: {
    position: 13,
    columnId: 'episode',
    path: 'episode',
    label: 'Episode',
    visible: false,
    type: 'auto-complete',
  },
  series: {
    position: 14,
    columnId: 'series',
    path: 'series',
    label: 'Series',
    visible: false,
    type: 'text',
  },
  location: {
    position: 15,
    columnId: 'location',
    path: 'location',
    label: 'Location',
    visible: false,
    type: 'text',
  },
  set: {
    position: 16,
    columnId: 'set',
    path: 'set',
    label: 'Set',
    visible: false,
    type: 'text',
  },
  freeField1: {
    position: 17,
    columnId: 'freeField1',
    path: 'freeField1',
    label: 'FF1',
    visible: false,
    type: 'text',
  },
  freeField2: {
    position: 18,
    columnId: 'freeField2',
    path: 'freeField2',
    label: 'FF2',
    visible: false,
    type: 'text',
  },
  freeField3: {
    position: 19,
    columnId: 'freeField3',
    path: 'freeField3',
    label: 'FF3',
    visible: false,
    type: 'text',
  },
  freeField4: {
    position: 20,
    columnId: 'freeField4',
    path: 'freeField4',
    label: 'FF4',
    visible: false,
    type: 'text',
  },
  insurance: {
    position: 21,
    columnId: 'insurance',
    path: 'insurance',
    label: 'Insurance',
    visible: false,
    type: 'text',
  },
  occupationCode: {
    position: 22,
    columnId: 'occupationCode',
    path: 'occupationCode',
    label: 'Occupation',
    visible: true,
    type: 'auto-complete',
  },
  dealMemo: {
    position: 23,
    columnId: 'dealMemo',
    path: 'dealMemo',
    label: 'Deal Memo ID',
    visible: true,
    type: 'auto-complete',
  },
  workCountry: {
    position: 24,
    columnId: 'workCountry',
    path: 'workCountry',
    label: 'Work Country',
    visible: false,
    type: 'auto-complete',
  },
  workState: {
    position: 25,
    columnId: 'workState',
    path: 'workState',
    label: 'Work State',
    visible: true,
    type: 'auto-complete',
  },
  workCounty: {
    position: 26,
    columnId: 'workCounty',
    path: 'workCounty',
    label: 'Work County',
    visible: false,
    type: 'auto-complete',
  },
  workCity: {
    position: 27,
    columnId: 'workCity',
    path: 'workCity',
    label: 'Work City',
    visible: true,
    type: 'auto-complete',
  },
  workSubdivision: {
    position: 28,
    columnId: 'workSubdivision',
    path: 'workSubdivision',
    label: 'Work Loc PSD',
    visible: false,
    type: 'auto-complete',
  },
  comment: {
    position: 29,
    columnId: 'comment',
    path: 'comment',
    label: 'Supporting Document',
    visible: true,
    type: 'text',
  },
};

//fields for project setting account codes
export const wtcEditableFields = {
  selectAll: {
    columnId: 'selectAll',
    label: 'Select All',
    editable: false,
  },
  divider: { type: 'divider' },
  ndbIn: {
    columnId: 'ndbIn',
    label: 'NDB In',
    editable: true,
  },
  ndmOut: {
    columnId: 'ndmOut',
    label: 'NDM Out',
    editable: true,
  },
  ndmIn: {
    columnId: 'ndmIn',
    label: 'NDM In',
    editable: true,
  },
  lastMan1In: {
    columnId: 'lastMan1In',
    label: 'Last Man In',
    editable: true,
  },
};

//wtc split table fields
export const wtcSplitsFields = [
  'splitButton',
  'dayInfo',
  'locationType',
  'onProduction',
  'dayType',
  'callTime',
  'accountCode',
  'episode',
  'series',
  'location',
  'set',
  'insurance',
  'freeField1',
  'freeField2',
  'freeField3',
  'freeField4',
  'workCountry',
  'workState',
  'workCounty',
  'workCity',
];
