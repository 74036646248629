import React, { useState, useEffect, useRef } from 'react';
import { Button, LinearProgress, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
//components
import { useTable, useSortBy } from 'react-table';
import HeaderRow from './HeaderRow';
import Row from './Row';
import AdditionalFields from './AdditionalFieldsContainer';
import SearchTableFilter from './SearchTableFilter';

//constants + utils
import {
  DEFAULT_HIDDEN_COLUMNS,
  TABLE_COLUMNS,
  IA_TABLE_COLUMNS,
} from './searchUtils';

const useStyles = makeStyles(theme => ({
  root: {
    height: '83vh',
  },
  emptyState: {
    margin: 'auto',
  },
  additionalFields: {
    width: 150,
    margin: '0px 50px 20px 0px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  loadingSquare: {
    width: '70vw',
  },
  showTableContainer: {
    marginTop: 20,
    fontSize: 12,
  },
  tableContainer: {
    maxHeight: 'calc((100vh - 320px) * .95)',
    overflowX: 'scroll',
    overflowY: 'hidden',
    transition: 'width 0.3s;',
  },
  tableScroll: {
    overflowY: 'scroll',
  },

  withFilters: {
    maxWidth: 'calc(99vw - 360px)',
  },
  withoutFilters: {
    maxWidth: 'calc(99vw - 133px)',
  },
  windowWithFilters: {
    width: 'calc(99vw - 360px)',
  },
  windowWithoutFilters: {
    width: 'calc(99vw - 133px)',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    paddingLeft: 23,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '20px 0px',
    justifyContent: 'space-between',
  },
  loadMoreBtn: {
    marginBottom: 4,
    marginLeft: 0,
    width: 115,
    height: 40,
  },
}));

/**
 * React-table for search invoices
 * @param {array} data - Array of invoices, sorted by backend
 * @param {num} totalCount - Total invoices that match current filters
 * @param {bool} loading - Are we loading new data?
 * @param {bool} showFilters - Are filters displayed in the left panel?
 * @param {array} sortBy - saved sort in Redux.  Will be [] for default sort
 *
 * Dispatch Functions
 * @param {func} onSearchFetch - only fetch data, no filter change.
 * @param {func} onLoadMore
 * @param {func} onStoreSort - save current sort in redux if user navigates away from page

 */
const SearchTable = props => {
  const {
    data,
    count,
    totalCount,
    loading,
    showFilters,
    onClickInvoice,
    onLoadMore,
    onStatusFilterSelect,
    onStoreSort,
    onSearchFetch,
    onAllInvoiceFilterSelect,
    sortBy,
    status,
    filterBadgeCountObj,
    hasLimitedIAAccess,
  } = props;
  const classes = useStyles();
  const showCount = data.length;

  const showLoadMore = count < totalCount;
  if (totalCount < count) {
    console.error(
      `Search Error. Data count (${showCount}) should not be more than total: ${totalCount}`,
    );
  }
  const reactTable = useTable(
    {
      columns: hasLimitedIAAccess ? IA_TABLE_COLUMNS : TABLE_COLUMNS,
      data: data,
      initialState: {
        hiddenColumns: DEFAULT_HIDDEN_COLUMNS,
        sortBy,
      },
      disableMultiSort: true,
      manualSortBy: true,
    },
    useSortBy,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setSortBy: setSortReactTable,
    state: { hiddenColumns },
    setHiddenColumns,
  } = reactTable;

  // setSortBy in Redux, then useEffect to watch that and set react-table accordingly
  const setSortBy = newSort => {
    onStoreSort(newSort.slice());
    onSearchFetch();
  };
  useEffect(() => {
    setSortReactTable(sortBy.slice());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  // Fancy scroll behavior!
  // this disabled the scroll on the table until we get to the bottom of the page
  // tricky because of how we're doing table height, so I'm watching for a div at the bottom

  const [canScrollTable, setScrollTable] = useState(false);
  //endTable is at the very bottom of the table, above the 'load more' btn
  const endTable = useRef();

  const observeCallback = entries => {
    entries.forEach(entry => {
      //isIntersection is the same as isVisible/onPage
      if (entry.isIntersecting && !canScrollTable) {
        setScrollTable(true);
      }
    });
  };
  const options = { threshold: 0.5 };
  const observer = new IntersectionObserver(observeCallback, options);

  useEffect(() => {
    if (endTable.current) observer.observe(endTable.current);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (endTable.current) observer.unobserve(endTable.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTable.current]);

  // const invoiceFilterHandler = arg => {};
  return (
    <div
      className={clsx(classes.root, {
        [classes.emptyState]: loading,
      })}
    >
      <div
        className={clsx(classes.showTableContainer, {
          [classes.windowWithFilters]: showFilters,
          [classes.windowWithoutFilters]: !showFilters,
        })}
      >
        <header className={classes.header}>
          <div className={classes.additionalFields}>
            <AdditionalFields
              hasLimitedIAAccess={hasLimitedIAAccess}
              hiddenColumns={hiddenColumns}
              setHiddenColumns={setHiddenColumns}
            />
          </div>
        </header>
        <SearchTableFilter
          status={status}
          totalCount={totalCount}
          onStatusFilterSelect={arr => onStatusFilterSelect(arr)}
          onAllInvoiceFilterSelect={onAllInvoiceFilterSelect}
          // onLoadgetFiltersCount={onLoadgetFiltersCount}
          filterBadgeCountObj={filterBadgeCountObj}
        />
        <main
          className={clsx(classes.tableContainer, {
            [classes.withFilters]: showFilters,
            [classes.withoutFilters]: !showFilters,
            [classes.tableScroll]: canScrollTable,
          })}
        >
          {loading ? (
            <LinearProgress />
          ) : (
            <Box
              sx={{ height: '4px', width: '100%', backgroundColor: 'grey.50' }}
            />
          )}
          <table {...getTableProps()} className={classes.table}>
            <HeaderRow headerGroups={headerGroups} setSortBy={setSortBy} />
            <tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => (
                // <div></div>
                <Row
                  prepareRow={prepareRow}
                  row={row}
                  rowIndex={rowIndex}
                  key={row.original.id}
                  onClickInvoice={onClickInvoice}
                />
              ))}
            </tbody>
          </table>
        </main>
        <footer className={classes.footer}>
          <div>Showing {`${count} out of ${totalCount}`}</div>
          {showLoadMore && (
            <Button
              color="primary"
              variant="outlined"
              className={classes.loadMoreBtn}
              onClick={onLoadMore}
            >
              Load more
            </Button>
          )}
          <div ref={endTable} />
        </footer>
      </div>
    </div>
  );
};

export default SearchTable;
