import React from 'react';
import { compose } from 'utils/helperFunctions';

import { Button } from '@mui/material';

import { connect } from 'react-redux';
import WarningIcon from '@mui/icons-material/Warning';
import makeStyles from '@mui/styles/makeStyles';
// actions
import * as actions from 'actions/reports';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import {
  ModalFooter,
  ModalContent,
  ModalTitle,
} from 'components/Shared/ModalDialog';
// selectors
import { getLoading } from 'selectors/reports';
import { getSessionUser } from 'selectors/session';
import { getModalParams } from 'selectors/modalDialog';
//utils
import { UPM } from 'components/props/profiles';

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
  },
  warningIcon: {
    marginRight: 8,
  },
  content: {
    maxWidth: 450,
    marginBottom: 24,
  },
  breakSpace: {
    width: '100%',
    height: 16,
  },
}));

const modalName = 'MissingRequiredFields';

const mapState = (state, ownProps) => ({
  isLoading: getLoading(state),
  currentUser: getSessionUser(state),
  modalParams: getModalParams(state, modalName),
});
const mapDispatch = (dispatch, ownProps) => ({
  onHideModal: () => {
    dispatch(actions.onMRFCancel());
  },
  onDownloadAnyway: () => {
    dispatch(actions.onMRFRunDownload());
  },
  onGoToDts: () => {
    dispatch(actions.onMRFToDts());
  },
});

function MissingRequiredFields(props) {
  const classes = useStyles();
  const {
    isLoading,
    onHideModal,
    onDownloadAnyway,
    onGoToDts,
    currentUser,
    modalParams,
  } = props;
  const MRFCount = modalParams?.MRFCount || '';
  const isUPM = currentUser.projectRoles.includes(UPM);
  return (
    <div>
      <ModalTitle>
        <span className={classes.title}>
          <WarningIcon className={classes.warningIcon} />
          {'Missing required fields'}
        </span>
      </ModalTitle>
      <ModalContent>
        <div className={classes.content}>
          {`${MRFCount} Timecard(s) are missing values in required fields, which may result in incomplete or incorrect calculation.`}
          <div className={classes.breakSpace} />
          {'Would you like to run the hot cost report anyway?'}
        </div>
      </ModalContent>
      <ModalFooter>
        <Button variant="text" onClick={onHideModal} disabled={isLoading}>
          {'Cancel'}
        </Button>
        <Button
          variant={isUPM ? 'contained' : 'outlined'}
          onClick={onDownloadAnyway}
          disabled={isLoading}
        >
          {'Run Anyway'}
        </Button>
        {!isUPM && (
          <Button onClick={onGoToDts} disabled={isLoading}>
            {'View in Daily Timesheets'}
          </Button>
        )}
      </ModalFooter>
    </div>
  );
}

export default compose(
  connect(mapState, mapDispatch),
  withModalDialog({ dialog: modalName, maxWidth: 'md' }),
)(MissingRequiredFields);
