import React from 'react';
import DepartmentApprovers from 'containers/Admin/Projects/DepartmentApprovers';
import {
  DefaultLayout,
  Header,
  ProjectHeader,
  AlertHeader,
} from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';
import { DepartmentApprovers as SubNav } from 'containers/Nav/Pages';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    admin
    authenticated
    key="/admin/projects/reviewFlows"
    path="/admin/projects/:projectId/review-flows"
    layout={DefaultLayout}
    headers={[Header, ProjectHeader, AlertHeader, SubNav]}
    component={DepartmentApprovers}
    title="Admin Dept Approvers"
  />
);
