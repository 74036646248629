import React from 'react';
import NotFound from 'components/NotFound';
import { EnhancedRoute } from 'containers/RoutingSupport';
import { MaxWidthLayout } from 'containers/Layout';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    key="NotFound"
    layout={MaxWidthLayout}
    is404={true}
    component={NotFound}
    title="Not Found"
  />
);
