import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, Tooltip, Box } from '@mui/material';

import InvoiceApproverIcon from '@mui/icons-material/AssignmentTurnedIn';
import EditIcon from '@mui/icons-material/Edit';
import ImpersonateIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';

// import { PersonAddIcon as InviteIcon } from '@mui/icons-material';
import { HOT_COST_COLUMNS } from './CrewListUtils';
import { IA, DH, PA, UPM, EMPLOYEE } from 'components/props/profiles';
import { ROLE_LABELS } from 'components/props/profiles';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import BaseCell from './EditableCells/BaseCell';

const useStyles = makeStyles(theme => ({
  root: {},
  cell: {
    border: '1px #d9ebea solid',
    borderLeft: 'none',
    borderRight: 'none',
    whiteSpace: 'nowrap',
    paddingLeft: 15,
    fontSize: 14,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  statusLabel: {
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    padding: '5px',
    borderRadius: '8px',
    marginLeft: '10px !important',
    marginRight: '10px !important',
    display: 'inline-block',
    marginTop: '5px !important',
    marginBottom: '5px !important',
    fontSize: 12,
  },
  uninvited: {
    background: theme.palette.button.lightRed.background,
    border: `solid 1px ${theme.palette.button.lightRed.background}`,
    color: theme.palette.button.lightRed.color,
  },
  invited: {
    background: theme.palette.button.orange.background,
    border: `solid 1px ${theme.palette.button.orange.background}`,
    color: theme.palette.button.orange.color,
  },
  accepted: {
    background: theme.palette.button.lightGreen.background,
    border: `solid 1px ${theme.palette.button.lightGreen.background}`,
    color: theme.palette.button.lightGreen.color,
  },
  deleted: {
    background: theme.palette.button.gray.background,
    border: `solid 1px ${theme.palette.button.gray.background}`,
    color: theme.palette.button.gray.color,
  },
  occupation: {
    display: 'flex',
  },
  occupationLabel: {
    cursor: 'pointer',
    textDecoration: 'underline',
    maxWidth: 230,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  iconButton: {
    marginRight: 10,
    color: theme.palette.gray.main,
  },
  dealMemoCount: {
    background: theme.palette.primary.background,
    color: theme.palette.primary.main,
    padding: '3px 9px',
    borderRadius: 4,
    textDecoration: 'none',
    marginLeft: 16,
  },
  actionContainer: {
    display: 'flex',
  },
  actionIcon: {
    width: 30,
  },
}));

const Cell = props => {
  const classes = useStyles();
  const {
    cell,
    onImpersonate,
    onEditCrewMember,
    onInviteNonEmployee,
    row,
    onViewDealMemos,
    currentUser,
    onDeleteEmployees,
    editingHotCost,
    cellIndex,
    rowIndex,
    crewIndex,
    field,
    name,
    form,
    values,
    hoursPlusEnabled,
    ...others
  } = props;

  const id = cell.column.id;
  let value = cell.value;

  const status = cell.row.original.status;
  const readableRole = cell.row.original.readableRole;
  const department = cell.row.original.department;
  const worksight = cell.row.original.worksightId;
  const dealMemoCount = cell.row.original.badgeCount;

  const isInvoiceApprover = cell.row.original.roles.includes(IA);
  const isActiveInvoiceApprover = cell.row.original.isActiveInvoiceApprover;

  const isStudioPlusOrigin = cell.row.original.origin === 'studioplus';

  const isSelection = id === 'selection';
  const isActionBts = id === 'actionBtns';
  const isStatus = id === 'status';
  const isEmail = id === 'email';
  const isRole = id === 'role';
  const isOccupation = id === 'occupation';
  const isAdmin = currentUser?.isAdmin;
  const isDepartment = id === 'department';
  const isHotCost = HOT_COST_COLUMNS.includes(id);

  const isApprovalFlowUser =
    readableRole === ROLE_LABELS[DH] ||
    readableRole === ROLE_LABELS[PA] ||
    readableRole === ROLE_LABELS[UPM];

  const showInviteBtn =
    status !== 'accepted' &&
    status !== 'pending' &&
    !isStudioPlusOrigin &&
    !isInvoiceApprover;

  const showEditBtn = !isStudioPlusOrigin && !isInvoiceApprover;
  const showImpersonateBtn = isAdmin && status !== 'notinvited';
  const showDeleteBtn =
    currentUser?.isAdmin &&
    !isApprovalFlowUser &&
    (status === 'invited' || status === 'accepted' || status === 'pending') &&
    !isStudioPlusOrigin &&
    !isInvoiceApprover;

  if (isSelection) {
    if (readableRole === ROLE_LABELS[EMPLOYEE]) {
      value = cell.render('Cell');
    } else {
      value = <div></div>;
    }
  }
  if (isActionBts) {
    value =
      readableRole === 'Payroll Coordinator' ? (
        ''
      ) : (
        <div className={classes.actionContainer}>
          <div className={classes.actionIcon}>
            {showEditBtn && hoursPlusEnabled && (
              <IconButton
                className={clsx(
                  'pendo_admin_edit_user',
                  classes.iconButton,
                  classes.tooltip,
                )}
                data-tooltip="Edit user details"
                onClick={() => onEditCrewMember(row.original)}
                disableRipple
                size="small"
                disabled={editingHotCost}
              >
                <EditIcon />
              </IconButton>
            )}
          </div>
          <div className={classes.actionIcon}>
            {showInviteBtn && hoursPlusEnabled && (
              <IconButton
                disableRipple
                className={clsx(
                  'pendo_admin_invite_user',
                  classes.iconButton,
                  classes.tooltip,
                )}
                data-tooltip="Invite crew member"
                size="small"
                onClick={() =>
                  onInviteNonEmployee({
                    selected: [row.values],
                  })
                }
                disabled={editingHotCost}
              >
                <PersonAddIcon />
              </IconButton>
            )}
          </div>
          <div className={classes.actionIcon}>
            {showImpersonateBtn && (
              <IconButton
                disableRipple
                className={clsx(
                  'pendo_admin_impersonate',
                  classes.iconButton,
                  classes.tooltip,
                )}
                data-tooltip="Impersonate"
                onClick={e => onImpersonate(row.original.id)}
                size="small"
                disabled={editingHotCost}
              >
                <ImpersonateIcon />
              </IconButton>
            )}
          </div>
          <div className={classes.actionIcon}>
            {showDeleteBtn && hoursPlusEnabled && (
              <IconButton
                disableRipple
                className={clsx('pendo_admin_delete_user', classes.iconButton)}
                data-tooltip="Delete this user"
                onClick={() => onDeleteEmployees([row.original.id])} //userID
                disabled={status === 'notinvited' || editingHotCost}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        </div>
      );
  }
  if (isStatus) {
    let status = cell.value;
    if (status === 'notinvited') status = 'uninvited';
    value = (
      <div className={clsx(classes.statusLabel, classes[status])}>{status}</div>
    );
  }
  if (isOccupation) {
    const pendoLabel = 'pendo_view_dealMemos';
    if (dealMemoCount > 1) {
      value = (
        <Tooltip title={cell.value}>
          <div
            className={clsx(classes.occupation, pendoLabel)}
            onClick={() => onViewDealMemos(worksight, dealMemoCount)}
          >
            <div className={clsx(classes.occupationLabel)}> {cell.value}</div>
            <div className={clsx(classes.dealMemoCount)}>{dealMemoCount}</div>
          </div>
        </Tooltip>
      );
    } else {
      value = (
        <Tooltip title={cell.value ? cell.value : ''}>
          <div
            className={clsx(classes.occupationLabel, pendoLabel)}
            onClick={() => onViewDealMemos(worksight, dealMemoCount)}
          >
            {cell.value}
          </div>
        </Tooltip>
      );
    }
  }
  if (isRole) {
    value = (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {readableRole}
        {isActiveInvoiceApprover && (
          <Tooltip title="Invoice Approver">
            <InvoiceApproverIcon sx={{ ml: 1 }} />
          </Tooltip>
        )}
      </Box>
    );
  }
  if (isEmail) {
    value = <p>{cell.value}</p>;
  }
  if (isDepartment) {
    value = <span>{department !== '0' ? department : null}</span>;
  }

  if (isHotCost && worksight !== null) {
    value = (
      <BaseCell
        id={id}
        value={cell.value}
        row={row}
        cellIndex={cellIndex}
        rowIndex={rowIndex}
        change={form.change}
        batch={form.batch}
        crewIndex={crewIndex}
        field={field}
        editingHotCost={editingHotCost}
        name={name}
        formValue={values?.hotCostSettingReport?.[id]}
        {...others}
      />
    );
  }

  return (
    <td
      {...cell.getCellProps()}
      className={clsx(classes.cell, {
        // [classes.numbers]: isCurrency || isNum,
        // [classes.emergency]: isEmergency,
        [classes.capitalize]: !isEmail,
      })}
      style={{
        maxWidth: 275,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {value}
    </td>
  );
};

export default Cell;
