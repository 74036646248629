// Utilties
import axios from 'axios';
import OktaClient from './oktaClient';

// Constants
import settings from 'constants/config/apiServer';

export default class Auth {
  accessToken = OktaClient.accessToken;

  checkAuthentication = token =>
    axios
      .get(`${settings.apiUrl}/me`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
        },
      })
      .catch();

  cncLogout = (noRedirect = false) => {
    OktaClient.logout(`${settings.myCncUrl}/login`);
  };
}
