import React, { useRef, useState, memo, useContext } from 'react';
import { Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
// import _ from 'lodash';
// import { useFormContext } from 'react-hook-form';
import BulkEditContext from './BulkEditContext';
import Input from './Input';
import CustomSelect from 'containers/Employees/Reviews/BulkEdit/Select';

import { BULK_EDIT_TIME_FIELDS } from 'utils/bulkEditUtils';

import { showToolTip } from 'components/Shared/ReactTable/reactTableUtils';

const LEFT_PAD = 16;

const style = ({ palette }) => ({
  disabledCheckbox: {
    marginLeft: 20,
    height: '20px',
  },
  tableBodyCell: {
    overflow: 'hidden',
    padding: `5px 0px 7px ${LEFT_PAD}px`,
    '&:focus': {
      outline: `solid 3px ${palette.info.light}`,
      outlineOffset: '-3px',
    },
  },
  ueVal: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: '15px',
    color: palette.text.disabled,
  },
  nameFields: {
    color: palette.text.primary,
    fontWeight: 400,
    textDecoration: 'underline',
    '&:hover': {
      color: palette.misc.link,
      cursor: 'pointer',
    },
  },
});

//custom styling for tooltip
const useStylesToolTip = makeStyles(theme => ({
  tooltip: {
    fontSize: 14,
  },
}));

const CellData = ({ cell, classes, currentProject }) => {
  const tableDataRef = useRef(null);
  const [focus, setFocusToParent] = useState(null);
  const toolTipClasses = useStylesToolTip();

  const { loadTimecard: onNavToWTC } = useContext(BulkEditContext);

  return (
    <td
      {...cell.getCellProps()}
      className={classes.tableBodyCell}
      tabIndex={-1}
    >
      {cell.render(cellProps => {
        const { column, row } = cellProps;

        const dropdownIds = ['episode', 'locationType', 'dayType', 'schedule'];
        const columnId = column.id;
        const columnWidth = column.width;
        const isCheckbox = column.isCheckbox;

        const isDropDown = dropdownIds.includes(columnId);
        const isNameField = columnId === 'last' || columnId === 'first';

        let batchWorksightId, htgId, timecardEntryHeaderId;

        if (isNameField) {
          batchWorksightId = row.original.batchWorksightId;
          htgId = row.original.htgId;
          timecardEntryHeaderId = row.original.timecardEntryHeaderId;
        }
        const editableStatus =
          typeof column.editable === 'function'
            ? column.editable(row.original)
            : column.editable;

        if (editableStatus) {
          if (isDropDown) {
            return <CustomSelect {...cellProps} />;
          }

          return (
            <Input
              {...cellProps}
              isCheckbox={isCheckbox}
              ref={tableDataRef}
              editable={!editableStatus}
              focus={focus}
              setFocusToParent={setFocusToParent}
              currentProject={currentProject}
            />
          );
        } else if (isCheckbox) {
          return (
            <input
              id={`${row.index}.${columnId}`}
              className={classes.disabledCheckbox}
              type={'checkbox'}
              disabled={true}
              value={cellProps.value || undefined}
            />
          );
        } else {
          const cellDiv = (
            <div
              id={`${row.index}.${columnId}`}
              className={clsx(classes.ueVal, {
                [classes.nameFields]: isNameField,
              })}
              style={{ maxWidth: columnWidth - LEFT_PAD }}
              onClick={() => {
                if (isNameField) {
                  onNavToWTC(timecardEntryHeaderId, batchWorksightId, htgId);
                }
              }}
            >
              {cellProps.value || cellProps.value === 0
                ? cellProps.value
                : undefined}
            </div>
          );

          return columnId === 'batch' || columnId === 'status' ? (
            <Tooltip
              title={
                showToolTip(cellProps.value, columnWidth) ? cellProps.value : ''
              }
              arrow
              enterNextDelay={400}
              enterDelay={1200}
              classes={toolTipClasses}
            >
              {cellDiv}
            </Tooltip>
          ) : (
            cellDiv
          );
        } //fi isEditable/isCheckbox/else?
      })}
    </td>
  );
};

// react memo use guide:
// compare certain property in previous and next props
// if return true -> use memoized version of a component, no need to re-render
// if return false -> re-render the component

// export default withStyles(style)(forwardRef(CellData));

export default memo(withStyles(style)(CellData), (prevProps, nextProps) => {
  const columnId = prevProps.cell.column.id;

  if (prevProps.cell.value === nextProps.cell.value) {
    const prevRow = prevProps.cell.row.values;
    const nextRow = nextProps.cell.row.values;

    const prevStatus = prevRow.status;
    const nextStatus = nextRow.status;

    if (prevStatus !== nextStatus) {
      return false; //status changed, re-render
    }

    if (
      prevStatus === 'Ready for me' &&
      BULK_EDIT_TIME_FIELDS.includes(columnId)
    ) {
      const prevHasError = !!prevRow.validationErrors.find(
        err => err.field === columnId,
      );
      const nextHasError = !!nextRow.validationErrors.find(
        err => err.field === columnId,
      );
      if (prevHasError !== nextHasError) return false; //error change, re-render
    }

    return true; //do not re-render
  }

  return false; //values different, re-render
});
