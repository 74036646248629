import createAction from './createAction';

export const fetchCountriesV1 = createAction('fetch/loc/countriesV1');
export const storeCountriesV1 = createAction('store/loc/countriesV1');
export const fetchStatesV1 = createAction('fetch/loc/statesV1');
export const storeStatesV1 = createAction('store/loc/statesV1');
export const fetchCitiesV1 = createAction('fetch/loc/citiesV1');
export const storeCitiesV1 = createAction('store/loc/citiesV1');
export const fetchCountiesV1 = createAction('fetch/loc/countiesV1');
export const storeCountiesV1 = createAction('store/loc/countiesV1');
export const fetchSubdivisionsV1 = createAction('fetch/loc/subdivisionsV1');
export const storeSubdivisionsV1 = createAction('store/loc/subdivisionsV1');

export const storeCacheStates = createAction('store/loc/cacheStates');
export const storeCacheCities = createAction('store/loc/cacheCities');

export const startCache = createAction('store/loc/startCache');
export const clearCache = createAction('store/loc/clearCache');

export const setLoading = createAction('setLoading/locations');
