import React, { useEffect, useRef } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import validator from 'utils/validator';
import {
  formatMessage,
  REQUIRED,
  NOT_UNIQUE,
  INVALID_EMAIL,
} from 'utils/messages';
import { emailExist } from './CrewListUtils';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import UserEditModal from './Modals/UserEdit';
import ConfirmEmailChange from './Modals/ConfirmEmailChange';
// actions
import * as actions from './actions';
import { fetchDepartments } from 'actions/project';
// selectors
import { getCurrentProject } from 'selectors/project';
import { getSelectedUserEdit, getUsers } from './selectors';
import { getSortedDepartments } from 'selectors/project';

let initialValues = null;
let users = null;
const mapStateToProps = state => ({
  user: getSelectedUserEdit(state),
  project: getCurrentProject(state),
  departments: getSortedDepartments(state),
  initialValues: getSelectedUserEdit(state),
  users: getUsers(state),
});

const mapDispatchToProps = dispatch => ({
  onFetchUser: () => {
    dispatch(fetchDepartments());
  },
  onResendInvitation: () => dispatch(actions.resendInvites()),
});

export const validate = (values, data) => {
  const errors = {
    valid: true,
  };

  const { email } = values;

  if (!email) {
    errors.email = formatMessage(REQUIRED);
    errors.valid = false;
  } else if (!validator.isEmail(email)) {
    errors.email = formatMessage(INVALID_EMAIL);
    errors.valid = false;
  } else if (emailExist(data?.users || [], values)) {
    errors.valid = false;
    errors.email = formatMessage(NOT_UNIQUE);
  }

  return errors;
};

const onSubmit = (values, dispatch) => {
  if (
    values.status === 'notinvited' ||
    values.status === 'invited' ||
    values.status === 'pending'
  ) {
    dispatch(actions.save());
  } else {
    dispatch(actions.confirmEmailChange());
  }
};

const UserEdit = props => {
  const { onFetchUser, departments, user, project } = props;

  const componentDidMountRef = useRef(false);

  useEffect(() => {
    if (componentDidMountRef.current === false) {
      onFetchUser();
      componentDidMountRef.current = true;
    }
  }, [onFetchUser]);

  const { handleSubmit, onResendInvitation } = props;
  return (
    <form onSubmit={handleSubmit}>
      <ConfirmEmailChange />
      <UserEditModal
        user={user}
        onResendInvitation={onResendInvitation}
        departments={departments}
        project={project}
        {...props}
      />
    </form>
  );
};
export default compose(
  withModalDialog({ dialog: 'userEdit' }),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'UserEdit',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnChange: true,
    validate,
    onSubmit,
    initialValues,
    users,
  }),
)(UserEdit);
