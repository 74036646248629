import React from 'react';
import { TimecardHistoryView } from 'containers/Employees/Timecards';

import {
  FullWidthLayout,
  Header,
  ProjectHeader,
  AlertHeader,
} from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    authenticated
    key="/projects/me/timecards"
    id="/projects/me/timecards"
    path="/projects/:projectId/me/timecards"
    layout={FullWidthLayout}
    headers={[Header, ProjectHeader, AlertHeader]}
    component={TimecardHistoryView}
    title="My Timecards"
  />
);
