import React from 'react';
import { compose } from 'utils/helperFunctions';
import validator from 'utils/validator';
import { connect } from 'react-redux';
import _ from 'lodash';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { TextField } from 'components/Shared/redux';
import {
  Button,
  Typography,
  FormControl,
  ListItemText,
  Select,
  MenuItem,
  FilledInput,
  InputLabel,
  Checkbox,
  Box,
  Chip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withModalDialog from 'decorators/withModalDialog';
import { ADMIN_ROLES } from 'components/props/profiles';
import {
  ModalTitle,
  ModalContent,
  ModalFooter,
} from 'components/Shared/ModalDialog';

//actions
import { hide as hideModal } from 'actions/modalDialog';
import { saveAdminUser } from 'actions/reports';

//selectors
import {
  getAdminUserInitialValues,
  getSortedAdminUsers,
} from 'selectors/reports';
import { getSessionUser } from 'selectors/session';

// utilities
import {
  formatMessage,
  NAME_INVALID,
  REQUIRED,
  INVALID_EMAIL,
  NOT_UNIQUE,
} from 'utils/messages';
import { emailUnique } from '../../../feature/CrewList/CrewListUtils';

const modalDialog = 'AddAdminUser';

const mapState = state => {
  const selector = formValueSelector(modalDialog);
  return {
    initialValues: getAdminUserInitialValues(state),
    roles: selector(state, 'roles') || [],
    currentUser: getSessionUser(state),
    isExistingUser: !!getAdminUserInitialValues(state)?.email || null,
    adminUsers: getSortedAdminUsers(state),
  };
};
const mapDispatch = dispatch => {
  return {
    onCancel: () => dispatch(hideModal({ dialog: modalDialog })),
    onChange: (form, field, value) => dispatch(change(form, field, value)),
  };
};
const onSubmit = (values, dispatch) => {
  dispatch(saveAdminUser({ user: values }));
};
const validate = (values, props) => {
  const errors = {};
  const { email, firstName, lastName } = values;
  const { currentUser, adminUsers, isExistingUser } = props;
  if (isExistingUser) {
    const requiredFields = ['firstName', 'lastName'];
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = formatMessage(REQUIRED);
      }
    });
    if (firstName && !validator.isAlphaSpaceOnly(firstName)) {
      errors.firstName = formatMessage(NAME_INVALID);
    }
    if (lastName && !validator.isAlphaSpaceOnly(lastName)) {
      errors.lastName = formatMessage(NAME_INVALID);
    }
  }
  if (!email) {
    errors.email = formatMessage(REQUIRED);
  } else {
    if (!validator.isEmail(email)) {
      errors.email = formatMessage(INVALID_EMAIL);
    } else if (
      !/@(castandcrew\.com|capspayroll\.com|mediaservices\.com)\s*$/.test(email)
    ) {
      errors.email = 'Email domains not matching';
    } else if (email.toLowerCase() === currentUser?.email.toLowerCase()) {
      errors.email = 'You cannot change your own role';
    } else if (!isExistingUser && emailUnique(adminUsers, values)) {
      errors.email = formatMessage(NOT_UNIQUE);
    }
  }
  return errors;
};

const useStyles = makeStyles(({ palette }) => ({
  field: {
    marginRight: '15px',
    width: '300px',
  },
  chip: {
    color: palette.text.secondary,
    backgroundColor: palette.primary['+8'],
  },
}));
const AddAdminModal = props => {
  const {
    handleSubmit,
    roles,
    submitting,
    onCancel,
    onChange,
    isExistingUser,
    pristine,
    invalid,
  } = props;
  const classes = useStyles();
  const roleOptions = _.cloneDeep(ADMIN_ROLES);
  //if edit screen push Role 'None' to the list
  if (isExistingUser) {
    roleOptions.push('None');
  }
  const submitDisabled =
    submitting || pristine || roles.length === 0 || invalid;
  const modalTitle = isExistingUser ? 'Edit Admin User' : 'Add Admin User';
  const buttonText = isExistingUser ? 'Update' : 'Save';
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ModalTitle>
          <Typography variant="h5" sx={{ fontWeight: 500 }}>
            {modalTitle}
          </Typography>
        </ModalTitle>
        <ModalContent>
          {isExistingUser && (
            <>
              <Field
                className={classes.field}
                component={TextField}
                label="First Name"
                name={`firstName`}
              />
              <Field
                className={classes.field}
                component={TextField}
                label="Last Name"
                name={`lastName`}
              />
            </>
          )}
          <Field
            className={classes.field}
            component={TextField}
            label="Email"
            name={`email`}
            disabled={isExistingUser}
          />
          <FormControl
            variant="filled"
            sx={{ m: 1, width: 300, marginTop: '-7px' }}
          >
            <InputLabel>Select an option</InputLabel>
            <Select
              multiple
              value={roles || []}
              onChange={e => onChange(modalDialog, 'roles', e.target.value)}
              input={<FilledInput label="Select an option" />}
              renderValue={roles => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {roles.map(value => (
                    <Chip
                      className={classes.chip}
                      key={value}
                      label={value}
                      size="small"
                    />
                  ))}
                </Box>
              )}
            >
              {roleOptions.map(option => {
                let isCheckboxDisabled = false;
                switch (option) {
                  case 'Admin':
                  case 'Super Admin':
                    //disbaled if role is None or Access Admin
                    isCheckboxDisabled =
                      roles?.includes('None') ||
                      roles?.includes('Access Admin');
                    break;
                  case 'Access Admin':
                    //disbaled if role is None or Super Admin or Admin
                    isCheckboxDisabled =
                      roles?.includes('None') ||
                      roles?.includes('Super Admin') ||
                      roles?.includes('Admin');
                    break;
                  case 'None':
                    //disbaled if role is Super Admin or Admin or Access Admin
                    isCheckboxDisabled =
                      roles?.includes('Super Admin') ||
                      roles?.includes('Admin') ||
                      roles?.includes('Access Admin');
                    break;
                  default:
                }
                return (
                  <MenuItem
                    key={option}
                    value={option}
                    disabled={isCheckboxDisabled}
                  >
                    <Checkbox checked={roles?.includes(option)} />
                    <ListItemText primary={option} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </ModalContent>
        <ModalFooter>
          <Button
            variant="outlined"
            color="primary"
            name="cancel"
            onClick={() => onCancel()}
            disabled={submitting}
          >
            {'Cancel'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={submitDisabled}
          >
            {buttonText}
          </Button>
        </ModalFooter>
      </form>
    </div>
  );
};
export default compose(
  withModalDialog({ dialog: modalDialog, maxWidth: 'xl' }),
  connect(mapState, mapDispatch),
  reduxForm({
    form: modalDialog,
    enableReinitialize: true,
    onSubmit,
    validate,
    touchOnChange: true,
    touchOnBlur: false,
  }),
)(AddAdminModal);
