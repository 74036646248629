import React, { useRef, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useObserveOutside } from 'utils/customHooks';

import TaxDataCards from './TaxDataCards';
import DataCard from './DataCard';
import RecordInfoDataCard from './RecordInfoDataCard';
import AccountCodeSummary from './AccountCodeSummary';

import RecordTitle from './RecordTitle';

import AceTable from 'feature/DigitalEdits/AceTable/AceTable';

import { invoiceStatusProp } from 'feature/DigitalEdits/DigitalEditProps';

import {
  makeRecordData,
  makeNameFromRecord,
} from 'feature/DigitalEdits/digitalEditsUtils';

import {
  getVisibleCardCount,
  getInvoiceStatusCode,
} from 'selectors/digitalEdits';

const TableRow = styled(Box)(({ theme }) => ({
  justifyContent: 'flex-start',
}));
const CardRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  gap: 16,
}));

const useStyles = makeStyles(({ palette }) => ({
  Record: {
    margin: '0px 16px',
    padding: 8,
    position: 'relative',
    borderBottom: `4px solid ${palette.primary['+7']}`,
    marginBottom: 30,
  },
}));

const mapState = state => ({
  viewableFields: getVisibleCardCount(state),
  invoiceStatus: getInvoiceStatusCode(state),
});

const Record = props => {
  const classes = useStyles();

  const {
    index,
    record,
    onSetCurrentRecordId,
    taxColumnMap,
    viewableFields,
    invoiceStatus,
    bc,
  } = props;

  const ref = useRef();
  const innerContentRef = useRef();
  const titleRef = useRef();

  //controls whether or not the record is rendered when near the viewport
  const [shouldRender, setShouldRender] = useState(index === 0);
  useObserveOutside(setShouldRender, ref, null, '500px 0px');

  //set min-height so records take up the same vertical space when rendered as when not
  const checkHeightGrow = React.useCallback(() => {
    const styles = window.getComputedStyle(ref.current);
    if (
      styles &&
      (Number(styles.height.split('px')[0]) >
        Number(styles['min-height'].split('px')[0]) ||
        !styles['min-height'])
    ) {
      ref.current.style.minHeight = styles.height;
    }
  }, []);

  useEffect(() => {
    checkHeightGrow();
  });

  // set min-height back down in case inner container shrinks
  // used to resize record when hiding fields such as account code summary
  const checkHeightShrink = React.useCallback(num => {
    const innerStyles =
      innerContentRef?.current &&
      window.getComputedStyle(innerContentRef?.current);
    const heightDiff =
      Number(ref.current.style.minHeight.split('px')[0]) -
      Number(innerStyles?.height.split('px')[0]);
    if (Math.abs(heightDiff) > 100) {
      ref.current.style.minHeight = innerStyles?.height;
    }
  }, []);

  useEffect(() => {
    checkHeightShrink();
  }, [checkHeightShrink, shouldRender, viewableFields]);

  // set current record id when title is top 35% of the the view port
  const setIsCurrent = React.useCallback(
    val => {
      if (val) {
        onSetCurrentRecordId(record.recordId);
        const currentTCName = makeNameFromRecord(record);
        bc?.postMessage({ action: 'setTCName', payload: currentTCName });
      }
    },
    [bc, onSetCurrentRecordId, record],
  );

  useObserveOutside(setIsCurrent, titleRef, null, '0% 0px -65% 0px');

  const {
    grossNetInfo,
    timecardInfo,
    timecardUnionInfo,
    checkComment,
    loanOut,
    distributions,
    accountCodeSummary,
    taxes,
  } = useMemo(
    () => makeRecordData(record, taxColumnMap),
    [taxColumnMap, record],
  );

  return (
    <Box
      ref={ref}
      className={classes.Record}
      style={{ minHeight: 700 }}
      onClick={() => {
        if (!shouldRender) setShouldRender(true);
      }}
    >
      <Box id={`digital-edits-record-${record.recordId}`} />
      <RecordTitle ref={titleRef} record={record} shouldRender={shouldRender} />
      {shouldRender && (
        <Box ref={innerContentRef}>
          <Box sx={{ display: 'flex' }}>
            <CardRow>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  gap: 1,
                }}
              >
                <DataCard data={grossNetInfo} />
                <DataCard data={loanOut} isAllCurrency={false} />
              </Box>
              <RecordInfoDataCard data={timecardInfo} />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  gap: 1,
                }}
              >
                <DataCard
                  mainCard={true}
                  data={timecardUnionInfo}
                  isAllCurrency={false}
                />
                <DataCard
                  data={checkComment}
                  isAllCurrency={false}
                  noTruncate={true}
                />
              </Box>
            </CardRow>
          </Box>
          <TableRow className="ACE-Table-Row">
            <AceTable
              recordId={record.recordId}
              distributions={distributions}
              invoiceStatus={invoiceStatus}
            />
          </TableRow>
          <TableRow>
            <AccountCodeSummary
              data={accountCodeSummary}
              recordId={record.recordId}
            />
          </TableRow>
          <CardRow>
            <TaxDataCards {...taxes} />
          </CardRow>
        </Box>
      )}
    </Box>
  );
};

Record.propTypes = {
  index: PropTypes.number.isRequired,
  record: PropTypes.object.isRequired,
  onSetCurrentRecordId: PropTypes.func.isRequired,
  taxColumnMap: PropTypes.object.isRequired,
  bc: PropTypes.object,
  viewableFields: PropTypes.number.isRequired,
  invoiceStatus: invoiceStatusProp.isRequired,
};

export default connect(mapState)(Record);
