const URL_SETTINGS = 'me/profile';
const URL_NOTIFICATIONS = 'me/notification_preferences';
const URL_PHONE_NUMBER = 'me/phone_number';
const URL_RESEND_VERIFICATION_CODE = 'me/phone_number/send_verification_code';
const URL_VERIFY_CODE = 'me/phone_number/verify';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  async updateProfileDetails(profileDetailsValues) {
    const rsp = await clientV2.put(URL_SETTINGS, profileDetailsValues);
    return rsp.data;
  },

  async notifications() {
    const rsp = await clientV2.get(URL_NOTIFICATIONS);
    return rsp.data;
  },

  async phoneNumber() {
    const rsp = await clientV2.get(URL_PHONE_NUMBER);
    return rsp.data;
  },

  async deletePhoneNumber() {
    const rsp = await clientV2.delete(URL_PHONE_NUMBER);
    return rsp.data;
  },

  async updateNotifications({ notifications }) {
    const rsp = await clientV2.put(URL_NOTIFICATIONS, notifications);
    return rsp.data;
  },

  async updatePhoneNumber({ phoneNumber }) {
    const rsp = await clientV2.put(URL_PHONE_NUMBER, phoneNumber);
    return rsp.data;
  },

  async resendVerificationCode() {
    const rsp = await clientV2.put(URL_RESEND_VERIFICATION_CODE);
    return rsp.data;
  },
  async verifyCode(code) {
    const rsp = await clientV2.put(URL_VERIFY_CODE, code);
    return rsp.data;
  },
});
