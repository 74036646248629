import React from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import { DialogActions } from '@mui/material';

const style = ({ breakpoints, palette }) => ({
  root: {
    padding: [8, 16],
    borderTop: [1, 'solid', palette.gray['+8']],
    fontFamily: 'inherit',
  },
  [breakpoints.down('md')]: {
    root: {
      '& > *': {
        width: '100%',
      },
    },
  },
});

const ModalFooter = ({ classes, children, ...props }) => (
  <DialogActions className={classes.root} {...props}>
    {children}
  </DialogActions>
);

ModalFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
};

ModalFooter.defaultProps = {
  children: undefined,
};

export default withStyleSheet('ModalFooter', style)(ModalFooter);
