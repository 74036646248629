import { default as Reviews } from './Reviews';
import { default as ApprovalFlows } from './ReviewApprovalFlow';
import { default as ReviewTimecard } from './ReviewTimecard';
import { default as WTC } from './WTC';
import { default as BulkEdit } from './BulkEdit';
import { default as SearchTimecards } from './SearchTimecards';
import { default as WTCMultiBatch } from './WTCMultiBatch';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ApprovalFlows,
  Reviews,
  ReviewTimecard,
  WTC,
  BulkEdit,
  SearchTimecards,
  WTCMultiBatch,
};
