import React, { useEffect } from 'react';
import Notifications from 'components/Employees/Reviews/WTC/Notifications';

const NotificationsContainer = ({
  notifications = [],
  onSetFieldVisibility,
  hasScaleRateError,
  tableFields,
}) => {
  let validations = [];
  let warnings = [];
  let errors = [];
  let infos = [];

  useEffect(() => {
    for (let i = 0; i < notifications.length; i++) {
      const message = notifications[i];
      //show field if we have an error for it
      if (
        message.field &&
        message.field !== '' &&
        tableFields[message.field]?.visible !== true
      ) {
        onSetFieldVisibility(message.field);
      }
    }
  }, [notifications, onSetFieldVisibility, tableFields]);

  for (let i = 0; i < notifications.length; i++) {
    const message = notifications[i];
    //Messages to suppress.  These are errors from HTG that we are handling in WTC already so no need to display them.
    const isHtgValidation = 'id' in message;
    if (
      isHtgValidation &&
      message.level === 'Error' &&
      (message.text?.includes('Timecard is missing') ||
        message.text?.includes('you have not specified') ||
        message.text?.includes('is required'))
    ) {
      continue;
    }
    if (!message.level.length && !message.completed) {
      infos.push(message.text);
    }
    if (message.level === 'Validation') validations.push(message.text);
    if (message.level === 'Warning' && !message.completed) {
      warnings.push(message.text);
    }
    if (message.level === 'Error' && !message.completed) {
      errors.push(message.text);
    }
  }

  if (hasScaleRateError) {
    warnings.push(
      `There was an error with the scale rate lookup. If this persists, contact support.`,
    );
  }

  return (
    <Notifications
      count={warnings.length + errors.length + infos.length}
      validations={validations}
      warnings={warnings}
      errors={errors}
      infos={infos}
    />
  );
};

export default NotificationsContainer;
