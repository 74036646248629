export const ICON_NAMES = {
  project: 'film',
  department_head: 'users',
  payroll_accountant: 'dollar',
  upm: 'user',
};

export const ERRORS_TITLES = {
  project: {
    not_draft: 'Project is not correct',
    departments_missing: 'Departments missing',
    batch_templates_missing: 'Batches missing',
    review_flows_missing: 'Approval Flows missing',
    wtc_template_missing: 'WTC Layout missing',
  },
  department_head: {
    not_assigned: 'Department Head is not assigned',
  },
  payroll_accountant: {
    not_assigned: 'Payroll Accountant is not assigned',
  },
  upm: {
    not_assigned: 'Reviewer is not assigned',
  },
};

export const ERRORS_DESCRIPTIONS = {
  project: {
    not_draft: 'The selected project is already started.',
    departments_missing:
      'To make your project functional you need to define ' +
      'a Department. To do so please go to the ' +
      'Departments & Approvers tab and define them.',
    batch_templates_missing:
      'To make your project functional you need to ' +
      'define a Batch. To do so please go to the ' +
      'Batches tab and define them.',
    review_flows_missing:
      'To make your project functional you need to ' +
      'define an Approval Flow. To do so please go to ' +
      'the Departments & Approvers tab and define them.',
    wtc_template_missing:
      'To make your project functional, you need to ' +
      'select a WTC Layout. To do so, go to the Project Settings tab, ' +
      'WTC accordion and add it there.',
  },
  department_head: {
    not_assigned:
      'To make your project functional you need to assign ' +
      'a Department Head on each Department. To do so please ' +
      'go to the Departments & Approvers tab and define them.',
  },
  payroll_accountant: {
    not_assigned:
      'To make your project functional you need to assign ' +
      'a Payroll Accountant. To do so please go to the ' +
      'Departments & Approvers tab and define an user with ' +
      'Payroll Accountant role.',
  },
};
