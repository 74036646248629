import React, { useState, useEffect, useMemo } from 'react';
import {
  Typography,
  TableRow,
  TableCell,
  Paper,
  LinearProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
//components
import { TableList } from 'components/Shared/TableList';
import { DepartmentSelect } from 'components/Admin/Projects/Shared';
//utils
import { formatDate } from 'utils';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
    marginTop: 30,
    overflowX: 'auto',
  },
  field: {
    marginLeft: 4,
    marginRight: 4,
    marginTop: 8,
    marginBottom: 8,
  },
  empty: {
    fontSize: '2.14285714em',
    background: palette.background.default,
    color: palette.text.disabled,
    fontWeight: 700,
    fontStyle: 'italic',
    marginTop: 2,
    padding: 10,
    textAlign: 'center',
  },

  departmentDiv: {
    maxWidth: 400,
    marginBottom: 15,
    marginLeft: 15,
  },
  tableRow: {
    cursor: 'pointer',
  },
}));

export default function CrewTimecardsList({
  departments = [],
  onFetchWeekendingStats,
  weekendingStats,
  loading,
  projectId,
  onTimecardSelect,
  reviewLevels = [],
  ...others
}) {
  const classes = useStyles();
  const [department, setDepartment] = useState();

  const headers = useMemo(
    () => [
      {
        columnId: 'endsOn',
        label: 'Week Ending',
        sortable: true,
        compact: true,
      },
      {
        columnId: 'name',
        label: 'Department',
        sortable: true,
        compact: true,
      },
      { columnId: 'totalCount', label: 'Total', sortable: true, compact: true },
    ],
    [],
  );

  const [theHeaders, setHeaders] = useState(headers);
  function fetchWeekendingStats(event) {
    onFetchWeekendingStats(event.target.value);
    setDepartment(event.target.value);
  }

  function weekClick(projectId, id) {
    if (!!id) {
      const items = _.split(id, '|');
      onTimecardSelect(projectId, items[0], items[1]);
    }
  }

  let contentRows = null;
  let enablePagination = true;
  if (!department || (weekendingStats && weekendingStats.length <= 0)) {
    contentRows = (
      <TableRow key={'empty-row-crew'}>
        <TableCell colSpan={8} rowSpan={3} className={classes.empty}>
          <Typography variant="h6">
            {loading
              ? 'Loading Weekendings...'
              : 'Please select a department above to view your crew timecards'}
          </Typography>
        </TableCell>
      </TableRow>
    );
    enablePagination = false;
  } else {
    contentRows = _.map(weekendingStats, details => (
      <TableRow
        hover
        key={details.id}
        className={classes.tableRow}
        onClick={() => weekClick(projectId, details.id)}
      >
        {theHeaders.map(column => {
          if (column.columnId === 'endsOn') {
            return (
              <TableCell key={column.columnId}>
                {formatDate(details.endsOn, 'MM-DD-YYYY')}
              </TableCell>
            );
          } else if (column.columnId === 'name') {
            return (
              <TableCell key={column.columnId}>
                {details.department.name}
              </TableCell>
            );
          } else if (column.columnId === 'totalCount') {
            return (
              <TableCell key={column.columnId}>{details.totalCount}</TableCell>
            );
          } else {
            return (
              <TableCell key={column.columnId}>
                {details[_.camelCase(column.columnId)] || 0}
              </TableCell>
            );
          }
        })}
      </TableRow>
    ));
  }

  useEffect(() => {
    if (reviewLevels) {
      const reviewLevelheaders = reviewLevels.map(level => {
        let column = { sortable: true, compact: true };
        if (level.name === 'submitted_to_c_and_c') {
          column.columnId = 'submittedToCncCount';
        } else {
          column.columnId = _.camelCase(level.name + 'Count');
        }
        column.label = level.description;
        return column;
      });
      setHeaders([...headers, ...reviewLevelheaders]);
    }
  }, [headers, reviewLevels]);

  return (
    <Paper className={classes.root}>
      <div className={classes.departmentDiv}>
        <DepartmentSelect
          className={classes.field}
          name="selectDepartments"
          placeholder="Department"
          departments={departments}
          onSelect={value => fetchWeekendingStats(value)}
          plain={true}
        />
      </div>
      {loading && <LinearProgress />}
      <TableList
        hover
        enablePagination={enablePagination}
        headers={theHeaders}
        isAlternateRowColor={false}
        {...others}
      >
        {contentRows}
      </TableList>
    </Paper>
  );
}
