import React from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';

import { Box, Button, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ReplyIcon from '@mui/icons-material/Reply';

import BackgroundLetterAvatars from 'components/Shared/BackgroundLetterAvatars';

import RichTextDisplay from 'components/RichText/RichTextDisplay';
import DeliveryIcon from 'components/images/DeliveryIcon';
import { useBroadcastChannel } from 'utils/customHooks';
import { FILE_COMMENT_CATEGORY_MAP } from 'feature/DigitalEdits/digitalEditsUtils';

const useStyles = makeStyles(({ palette }) => ({
  ExistingComment: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  commentText: {
    overflowWrap: 'anywhere',
    fontSize: 12,
    display: '-webkit-box',
  },
  notExpanded: {
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    overflow: 'hidden',
  },
  avatar: {
    marginRight: 8,
  },
  headerText: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2px 8px 4px 0px',
    alignItems: 'center',
    fontSize: 14,
  },
}));

const ExistingComment = props => {
  const classes = useStyles();
  const { comment, showReply = true } = props;
  const {
    commentText,
    updatedBy,
    updatedDate,
    parentApp,
    containerId: invoiceId,
    category,
  } = comment;

  const isDelivery = category === FILE_COMMENT_CATEGORY_MAP['InvoiceDelivery'];
  const [expanded, setExpanded] = React.useState(false);

  const updatedDateStr = moment(updatedDate, 'YYYY-MM-DDTHH:mm:ss').format(
    'MM/DD/YYYY HH:mm:ss',
  );

  const bc = useBroadcastChannel(invoiceId);

  const [replyDisabled, setReplyDisabled] = React.useState(false);

  React.useEffect(() => {
    if (replyDisabled) {
      setTimeout(() => {
        setReplyDisabled(false);
      }, 1000);
    }
  }, [replyDisabled]);

  return (
    <Box className={classes.ExistingComment}>
      <BackgroundLetterAvatars className={classes.avatar} name={updatedBy} />
      <Box
        sx={{ width: '100%' }}
        onClick={() => {
          setExpanded(v => !v);
        }}
      >
        <Box className={classes.headerText}>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Box sx={{ fontWeight: '800' }}>{updatedBy} </Box>
            <Box>
              Role: {parentApp === 'HTG' ? 'Coordinator' : 'Client'} | Date:
              {updatedDateStr}
            </Box>
          </Box>
          <Box sx={{ height: '20px', width: '20px' }}>
            {isDelivery && (
              <Tooltip title="Delivery Method Comment">
                <DeliveryIcon />
              </Tooltip>
            )}
          </Box>
        </Box>

        <Box
          className={clsx(classes.commentText, {
            [classes.notExpanded]: !expanded,
          })}
        >
          <RichTextDisplay commentText={commentText} />
        </Box>
        {showReply && (
          <Box sx={{ mt: 1 }}>
            <Button
              startIcon={<ReplyIcon sx={{ height: 16, width: 16 }} />}
              variant={'outlined'}
              disabled={replyDisabled}
              sx={{ height: 25, p: 1, ml: 0, fontSize: 12 }}
              onClick={() => {
                setReplyDisabled(true);
                bc.postMessage({
                  action: 'insertReply',
                  payload: commentText,
                });
              }}
            >
              Reply
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

ExistingComment.propTypes = {
  comment: PropTypes.object.isRequired,
  showReply: PropTypes.bool,
};

export default ExistingComment;
