import {
  all,
  spawn,
  takeLatest,
  takeEvery,
  call,
  put,
  select,
  delay,
  take,
  race,
} from 'redux-saga/effects';
import _ from 'lodash';
import { push } from 'redux-first-history';
import pluralize from 'pluralize';

//actions
import * as actions from 'actions/dts';
import { showAlert } from 'actions/alert';
import { setDraftFilters, setSelectAllFlag } from 'actions/searchTimecards';
import { signalRNotification, signalRJobSearchDelete } from 'actions/events';
import { fetchTemplates as getInvoiceTemplates } from 'actions/invoiceTemplate';

import {
  fetchCountriesV1,
  fetchStatesV1,
  fetchCitiesV1,
  fetchCountiesV1,
  fetchSubdivisionsV1,
} from 'actions/location';

//selectors
import * as sel from 'selectors/dts';
import { getProject } from 'selectors/routeParams';
import { userInfoById } from 'selectors/session';

import {
  getCurrentProjectWorkSight,
  getProjectDetails,
  getCurrentDtsTemplate,
} from 'selectors/project';
import { currentUser } from 'selectors/session';
import { getTemplates } from 'selectors/invoiceTemplate';
import { getCurrentProject } from 'selectors/project';
import { getSettings } from 'selectors/settings';
//utils
import {
  OPTIONAL_GROUP_OPTION_IDS,
  MAX_EMPLOYEE_COUNT,
  splitTimecardsToBeDeleted,
  MAX_TIMECARD_DELETE,
  formDtsPayload,
  rateLessFilterParse,
  checkDateEqual,
  DATE_FORMAT_LONG,
  db,
} from 'feature/DTS/dtsUtils';
import { DH } from 'components/props/profiles';
import { copyState } from 'utils/helperFunctions';

import { db as dbSignalR } from 'providers/api/signalrApi';

function* init(api, debug) {
  try {
    yield put(actions.setSaveMetaData({ pending: [] }));

    yield all([
      put(actions.fetchTemplates()),
      put(getInvoiceTemplates()),
      put(actions.fetchFilterOptions()),
      fetchDeptFilter(api, debug),
    ]);
    //Data needs the filter fetch to finish before calling
    yield put(actions.fetchData());
  } catch (e) {
    debug(e);
  }
}

//fetch table data
export function* fetchData(api, debug) {
  try {
    yield put(actions.loading({ loadType: 'data', loading: true }));

    const projectId = yield select(getProject);

    let loading = yield select(sel.getLoadingDetails);

    let count = 0;
    while (loading.filters && count < 50) {
      //Ensure filters have finished loaded before fetching
      yield delay(125);
      count++;
      loading = yield select(sel.getLoadingDetails);
    }

    const effectiveDate = yield select(sel.getEffectiveDate);
    const dtsDate = effectiveDate.format('YYYY-MM-DD');
    const filters = yield select(sel.getAllFilters);

    const data = yield call(api.dts.getData, {
      projectId,
      dtsDate,
      filters,
    });

    if (data.employeeCount > MAX_EMPLOYEE_COUNT) {
      data.timecards = [];
    }
    yield populateLocationsToTimecards(data.timecards, api);

    addDailyAllowance(data.timecards);

    yield put(actions.store({ data }));
    yield delay(50); //let store data propagate - otherwise empty state can flash before load
  } catch (e) {
    debug(e);
    yield put(showAlert());
    yield put(actions.loading({ loadType: 'groupOptions', loading: false }));
  } finally {
    yield put(actions.loading({ loadType: 'data', loading: false }));
  }
}

export function* fetchDeleted(api, debug, params) {
  try {
    const { afterSaveTimecards } = params;
    const rawData = yield select(sel.getRawData);
    const rawTimecards = _.cloneDeep(rawData.timecards);

    yield put(actions.store({}));

    const deletedEmployees = yield select(sel.getDeletedEmployees);

    const effectiveDate = yield select(sel.getEffectiveDate);
    const dtsDate = effectiveDate.format('YYYY-MM-DD');
    const projectId = yield select(getProject);
    const filters = yield select(sel.getAllFilters);

    const empFilter = filters.find(f => f.field === 'employee');
    empFilter.selectAll = false;
    empFilter.values = deletedEmployees;

    const newDraftCandidates = yield call(api.dts.getData, {
      projectId,
      dtsDate,
      filters,
    });
    const draftCandidates = newDraftCandidates.timecards;
    yield populateLocationsToTimecards(draftCandidates, api);

    const timecards = [];

    rawTimecards.forEach(tc => {
      const newTimecard = newDraftCandidates.timecards.find(
        t => t.employee.id === tc.employee.id,
      );
      if (newTimecard) {
        timecards.push(newTimecard);
        return;
      }

      const savedTimecard = afterSaveTimecards.find(
        t => t.timecardEntryHeaderId === tc.timecardEntryHeaderId,
      );
      if (savedTimecard) {
        timecards.push(savedTimecard);
        return;
      }

      timecards.push(tc);
    });

    const data = { timecards, employeeCount: timecards.length };

    yield put(actions.store({ data }));

    yield delay(200);
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(actions.loading({ loadType: 'saving', loading: false }));
    yield put(actions.setDeletedEmployees({ deletedEmployees: [] }));
  }
}

function* populateLocationsToTimecards(timecards, api) {
  // populate state data from redux onto timecard

  const templates = yield select(getTemplates);

  const batchTemplateIdSet = new Set();
  const stateIdSet = new Set();
  const cityIdSet = new Set();
  const subdivisionIdSet = new Set();

  // scan timecard payload for what data to fetch
  for (let i = 0; i < timecards.length; i++) {
    const timecard = timecards[i];

    const { htgStateId, htgCityId, htgSubdivisionId, batchTemplateId } =
      timecard;

    // if the look up is a performance hit,
    // full locations are on the templates and in details of timecards

    if (batchTemplateId) batchTemplateIdSet.add(batchTemplateId);
    if (htgStateId) stateIdSet.add(htgStateId);
    if (htgCityId) cityIdSet.add(htgCityId);
    if (htgSubdivisionId) subdivisionIdSet.add(htgSubdivisionId);
  }

  const stateIds = Array.from(stateIdSet);
  const cityIds = Array.from(cityIdSet);

  const projectId = yield select(getProject);
  const callArray = [
    call(api.locations.statesV1, { projectId }),
    ...stateIds.map(stateId => call(api.locations.citiesV1, { stateId })),
  ];

  if (subdivisionIdSet.size > 0 || true) {
    callArray.push(
      call(api.locations.subdivisionsV1, {
        pageSize: -1,
        search: '',
      }),
    );
  }

  const data = yield all(callArray);
  const allData = data.flat();

  let needToFetchMoreStates = false;
  stateIds.forEach(stateId => {
    if (_.find(allData, d => d.id === stateId) === undefined) {
      needToFetchMoreStates = true;
    }
  });

  cityIds.forEach(cityId => {
    if (!_.find(allData, d => d.id === cityId)) {
      console.error('No city information found for id:', cityId);
    }
  });

  //Currently we only get states (or provinces) from US and Canada.
  if (needToFetchMoreStates) {
    try {
      const countries = yield call(api.locations.countriesV1);
      const countryIds = [];
      let result = countries.find(c => c.code === 'US');
      if (result) countryIds.push(result.id);
      result = countries.find(c => c.code === 'CA');
      if (result) countryIds.push(result.id);

      let moreStates = yield all(
        countryIds.map(countryId =>
          call(api.locations.statesV1, { projectId, countryId }),
        ),
      );
      allData.push(...moreStates.flat());
    } catch (error) {
      //Do nothing, no need to break DTS load since we can't find some state
    }
  }

  //Insert data on to timecards
  for (let i = 0; i < timecards.length; i++) {
    const timecard = timecards[i];

    const { htgStateId, htgCityId, htgSubdivisionId, batchTemplateId } =
      timecard;

    timecard.templateLocType = null;

    if (htgStateId) {
      const state = _.find(allData, d => d.id === htgStateId);

      if (state) {
        timecard.workState = copyState(state);
      }
      if (htgCityId) {
        const city = _.find(allData, d => d.id === htgCityId);
        if (city) {
          timecard.workCity = _.cloneDeep(city);
        }
      }
      if (batchTemplateId) {
        const template = templates.find(temp => temp?.id === batchTemplateId);
        if (template.country) {
          timecard.workCountry = _.cloneDeep(template.country);
        }
        if (template?.locationType?.id) {
          timecard.templateLocType = _.cloneDeep(template.locationType);
        }
      }
    }

    if (htgSubdivisionId) {
      const subdivision = allData.find(sd => sd.id === htgSubdivisionId);
      if (subdivision) timecard.workSubdivision = _.cloneDeep(subdivision);
    }
  }
}

/**
 *  Add daily allowance to timecards: null if not daily allowances, true if not value exists
 */
function addDailyAllowance(timecards) {
  timecards.forEach(tc => {
    const dealMemo = tc.dealMemo;

    const dealAllowances = dealMemo?.dealMemoAllowances || [];
    const dailyAllowance = dealAllowances.filter(
      a => a.frequency === 'D' && (a.payCode1 || a.payCode2),
    );

    const hasDaily = dailyAllowance.length > 0;

    if (Array.isArray(tc.details)) {
      //DraftCandidates value is populated in dtsUtils.js:createDay()
      tc.details.forEach(detail => {
        if (hasDaily) {
          if (Object.keys(detail).includes('isAutoDailyAllowance')) {
            //use existing value
            detail.isAutoDailyAllowance = !!detail.isAutoDailyAllowance;
          } else {
            detail.isAutoDailyAllowance = true;
          }
        } else {
          detail.isAutoDailyAllowance = null;
        }
      });
    }
  });
}

let preSaveTimecards;
let afterSaveTimecards;
export function* save(api, debug, params) {
  try {
    yield put(actions.loading({ loadType: 'saving', loading: true }));

    const data = { timecards: [] };
    const allTimecards = params?.data?.timecards || [];
    const effectiveDate = yield select(sel.getEffectiveDate);
    const dtsDate = effectiveDate.format('YYYY-MM-DD');
    const projectId = yield select(getProject);

    // only save timecard that have been touched
    // This can remove draftCandidate and `newDay` values without dayTypes
    // only existing days with no dayTypes will persist so they can send to the delete api
    data.timecards = allTimecards.filter(t => t.touched === true);

    if (data.timecards.length === 0) {
      yield put(showAlert({ variant: 'info', message: 'No changes to save' }));
      yield put(actions.loading({ loadType: 'saving', loading: false }));
      yield put(actions.setDirty({ isDirty: false }));

      return;
    }

    const project = yield select(getCurrentProject);
    if (project?.region === 'Canada') {
      const params = {};
      const employeeId = data?.timecards[0]?.employee.id;
      yield fetchDealMemos(api, debug, {
        employeeId,
        effectiveDate: effectiveDate.format(DATE_FORMAT_LONG),
      });
      const dealMemos = yield select(sel.getDealMemos);
      const htgContractId = dealMemos[0]?.contract?.id;
      const htgUnionId = dealMemos[0]?.pensionUnion?.id;
      params.options = JSON.stringify({ htgContractId, htgUnionId });
      const locationTypes = yield call(api.dts.fetchSoloOption, {
        columnId: 'locationType',
        params,
      });
      const studioLocationType = locationTypes?.find(type => type.code === 'S');
      data?.timecards.forEach(timecard => {
        if (timecard?.details) {
          timecard?.details.forEach(details => {
            details.locationType = studioLocationType;
          });
        }
      });
    }

    preSaveTimecards = data.timecards;
    afterSaveTimecards = _.cloneDeep(preSaveTimecards);
    const { toEdit, toDelete } = splitTimecardsToBeDeleted(data.timecards);
    data.timecards = toEdit;

    const settings = yield select(getSettings);
    data?.timecards.forEach(timecard => {
      timecard.wtcLayoutName =
        timecard?.wtcLayoutName || settings?.wtcLayout?.label || null;
    });

    const { dtsTimecardAutoAllowances } = settings;

    // Temp prevention of deleting more than 25 timecards at a timecards
    // its not pretty but its simple.
    const deleteCount = toDelete.length;

    if (deleteCount > MAX_TIMECARD_DELETE) {
      const message = [
        `You've attempted to save ${deleteCount} timecards with blank day types,`,
      ];
      message.push(` which would cause the timecards to be deleted.`);
      message.push(' ');
      message.push(
        ` We currently only support deletion of up to ${MAX_TIMECARD_DELETE} timecards at a time.`,
      );
      message.push(
        ` Please change your selection or filters in order to proceed. `,
      );

      yield put(
        showAlert({
          variant: 'error',
          message,
        }),
      );
      yield put(actions.loading({ loadType: 'saving', loading: false }));
      return;
    }
    const draftCandidates = data.timecards.filter(tc => tc.draftCandidate);

    const assignCalls = [null, null];

    if (draftCandidates.length > 0) {
      assignCalls[0] = call(api.dts.assignBatches, {
        projectId,
        dtsDate,
        timecards: draftCandidates,
      });
    }

    if (data.timecards.length > 0) {
      const rateless = data.timecards.filter(
        rateLessFilterParse(effectiveDate),
      );
      assignCalls[1] = call(api.dts.assignRates, {
        projectId,
        dtsDate,
        timecards: rateless,
      });
    }

    const [batched, rated] = yield all(assignCalls);

    if (batched) {
      batched.timecards.forEach(newTc => {
        let idx = data.timecards.findIndex(
          tc => tc.timecardEntryHeaderId === newTc.timecardEntryHeaderId,
        );
        if (idx !== -1) {
          data.timecards[idx] = newTc;
        }

        idx = afterSaveTimecards.findIndex(
          tc => tc.timecardEntryHeaderId === newTc.timecardEntryHeaderId,
        );
        if (idx !== -1) {
          const formerDraftCandidate = _.cloneDeep(newTc);
          delete formerDraftCandidate.draftCandidate;
          delete formerDraftCandidate.status;
          formerDraftCandidate.userFriendlyTimecardStatus = 'Draft';
          afterSaveTimecards[idx] = formerDraftCandidate;
        }
      });
    }

    if (rated) {
      const date = effectiveDate.format(DATE_FORMAT_LONG);

      rated.timecards.forEach(tc => {
        if (rated.errors[tc.timecardEntryHeaderId]) {
          console.error(
            'Error assigning rate for ',
            tc?.employee?.name,
            rated.errors[tc.timecardEntryHeaderId],
          );
          return;
        }
        // these rated timecards won't have batch info, if they were draft candidates
        // so we only take the rate info, not the whole timecard
        let idx = data.timecards.findIndex(
          t => t.timecardEntryHeaderId === tc.timecardEntryHeaderId,
        );
        const tcDay = tc.details.find(d =>
          checkDateEqual(d.effectiveDate, date),
        );
        if (idx !== -1) {
          data.timecards[idx].rate = tcDay.rate;
          const day = data.timecards[idx].details.find(d =>
            checkDateEqual(d.effectiveDate, date),
          );
          if (day) day.rate = tcDay.rate;
        }

        idx = afterSaveTimecards.findIndex(
          t => t.timecardEntryHeaderId === tc.timecardEntryHeaderId,
        );
        if (idx !== -1) {
          afterSaveTimecards[idx].rate = tcDay.rate;
          const day = afterSaveTimecards[idx].details.find(d =>
            checkDateEqual(d.effectiveDate, date),
          );
          if (day) day.rate = tcDay.rate;
        }
      });
    }

    if (dtsTimecardAutoAllowances) {
      const response = yield call(api.dts.assignAllowances, {
        projectId,
        dtsDate,
        timecards: data.timecards,
      });

      response.timecards.forEach(tc => {
        const idx = data.timecards.findIndex(
          t => t.timecardEntryHeaderId === tc.timecardEntryHeaderId,
        );
        if (idx !== -1) {
          data.timecards[idx].allowances = tc.allowances;
        }

        const idx2 = afterSaveTimecards.findIndex(
          t => t.timecardEntryHeaderId === tc.timecardEntryHeaderId,
        );
        if (idx2 !== -1) {
          afterSaveTimecards[idx2].allowances = _.cloneDeep(tc.allowances);
        }
      });
    }

    const timecards = formDtsPayload(data.timecards, effectiveDate);

    timecards.forEach(tc => {
      if (tc.action === 'removeDays') {
        const afterSaveTC = afterSaveTimecards.find(
          afterSaveTC =>
            afterSaveTC.timecardEntryHeaderId === tc.timecardEntryHeaderId,
        );
        const date = effectiveDate.format(DATE_FORMAT_LONG);
        const idxToRemove = afterSaveTC.details.findIndex(detail =>
          checkDateEqual(detail.effectiveDate, date),
        );
        afterSaveTC.details.splice(idxToRemove, 1);
      }
    });

    const pending = timecards
      .map(tc => tc.timecardEntryHeaderId)
      .concat(toDelete.map(tc => tc.timecardEntryHeaderId));

    yield put(actions.setSaveMetaData({ pending }));

    if (deleteCount > 0) {
      yield put(actions.deleteTimecards({ toDelete }));

      const deletedEmployees = [];
      toDelete.forEach(tc => {
        deletedEmployees.push(tc.employee.id);
      });
      yield put(actions.setDeletedEmployees({ deletedEmployees }));
    }

    if (timecards.length > 0) {
      dbSignalR('save', { timecards });
      db('Save Timecards', timecards);
      yield all(
        timecards.map(tc =>
          spawn(() => spawnSaveTC(api.notifications.save, debug, tc)),
        ),
      );

      yield race({
        timeout: call(saveTimeout),
        completed: take(`${actions.setSaveComplete}`),
      });
    }
  } catch (e) {
    yield put(actions.setSaveMetaData({ pending: [] }));
    yield put(actions.loading({ loadType: 'saving', loading: false }));
    debug(e);
    yield put(
      showAlert({ variant: 'error', message: 'Error saving timecards' }),
    );
  }
}

function* spawnSaveTC(apiCall, debug, tc) {
  yield delay(1); //Delay slightly so timecards go in separate WS messages
  try {
    const project = yield select(getCurrentProject);
    const user = yield select(userInfoById(project.id));

    yield call(apiCall, tc.timecardEntryHeaderId, tc, {
      name: user.fullName,
      email: user.email,
      id: user.oktaId,
    });
  } catch (e) {
    debug(e);
  }
}

function* saveTimeout() {
  const SAVE_TIMEOUT = 120; //in seconds
  yield delay(1000 * SAVE_TIMEOUT);
  db('Save Timed Out');
  let saveMetaDataOld = yield select(sel.getSaveMetaData);

  if (saveMetaDataOld.pending.length > 0) {
    const saveMetaData = _.cloneDeep(saveMetaDataOld);
    console.error(`Save timed out after ${SAVE_TIMEOUT} seconds`);
    const pending = saveMetaData.pending;
    saveMetaData.timeout = pending;
    saveMetaData.pending = [];

    yield put(actions.setSaveComplete({ saveMetaData }));
  }

  yield put(actions.loading({ loadType: 'saving', loading: false }));
}

//triggered by signal-R save
export function* tcUpdates(api, debug, params) {
  try {
    const loading = yield select(sel.getLoadingDetails);

    if (loading.saving === false) {
      //Not currently saving, ignore
      return;
    }

    const { timecards = [], requestType = '' } = params;

    if (requestType === 'Save') {
      const success = [];
      const error = [];
      let saveMetaData = yield select(sel.getSaveMetaData);
      timecards.forEach((tc, i) => {
        const pending = saveMetaData.pending;
        if (pending.includes(tc.headerId) === false) {
          //tc not in pending, ignore it - probably a bulk edit message
          return;
        }

        if (tc.newState === 'SaveCompleted' && tc.result === 'Success') {
          success.push(tc.headerId);
        } else if (tc.result === 'Error') {
          //Error saving
          console.error('Save Error:', tc.headerId, '\n', tc.message);
          error.push(tc.headerId);
        }
      });
      const saveUpdate = {};
      if (success.length > 0) {
        saveUpdate.success = success;
      }
      if (error.length > 0) {
        saveUpdate.error = error;
      }
      yield put(actions.setSaveMetaData(saveUpdate));
      yield delay(0);
      let count = 0;
      let metaUpdated = false;
      while (metaUpdated === false && count < 10) {
        saveMetaData = yield select(sel.getSaveMetaData);
        if (success.length > 0 && saveMetaData.success.includes(success[0])) {
          metaUpdated = true;
        }

        if (error.length > 0 && saveMetaData.error.includes(error[0])) {
          metaUpdated = true;
        }

        yield delay(100);
        count++;
      }
      if (saveMetaData.pending.length === 0) {
        yield put(actions.setSaveComplete({ saveMetaData }));
      }
    }
  } catch (e) {
    debug(e);
  } finally {
  }
}

export function* saveComplete(api, debug, params) {
  try {
    const { saveMetaData } = params;
    db('Save Complete', saveMetaData);
    const { success, error, timeout, pending } = saveMetaData;
    if (pending.length > 0) {
      console.error(
        'Cannot complete save, still have pending timecards: ',
        pending,
      );
      throw new Error(
        'Cannot complete save, still have pending timecards: ',
        pending,
      );
    }
    yield put(actions.setSaveMetaData({ saveMetaData }));

    if (error.length > 0 || timeout.length > 0) {
      yield handleSaveFailed(saveMetaData);
    } else {
      yield handleSaveSuccess(success.length);
    }

    if (success.length > 0) {
      yield all(
        success.map(headerId => spawn(() => spawnCalcTC(api, debug, headerId))),
      );
    }

    yield put(actions.setSaveMetaData({ pending: [] }));
  } catch (e) {
    debug(e);
    yield put(actions.loading({ loadType: 'saving', loading: false }));
  }
}

function* spawnCalcTC(api, debug, headerId) {
  try {
    const project = yield select(getCurrentProject);
    const user = yield select(userInfoById(project.id));
    yield call(api.notifications.calculate, headerId, {
      name: user.fullName,
      email: user.email,
      id: user.oktaId,
    });
  } catch (e) {
    debug(e);
  }
}

function* handleSaveSuccess(successCount) {
  let message = `Success! ${pluralize('timecard', successCount, true)} saved.`;

  yield put(showAlert({ variant: 'success', message }));

  const postSaveAction = yield select(sel.getPostSaveAction);

  yield put(actions.setDirty({ isDirty: false }));

  if (typeof postSaveAction === 'function') {
    postSaveAction();
    yield put(actions.setPostSaveAction({ func: null }));
    yield put(actions.loading({ loadType: 'saving', loading: false }));
  } else {
    afterSaveTimecards.forEach(tc => {
      delete tc.touched;
      tc.details.forEach(detail => {
        delete detail.newDay;
      });
    });

    const deletedEmployees = yield select(sel.getDeletedEmployees);

    if (deletedEmployees.length > 0) {
      yield put(actions.fetchDeleted({ afterSaveTimecards }));
    } else {
      const timecards = [];
      const rawData = yield select(sel.getRawData);
      const rawTimecards = _.cloneDeep(rawData.timecards);
      rawTimecards.forEach(tc => {
        const savedTimecard = afterSaveTimecards.find(
          t => t.timecardEntryHeaderId === tc.timecardEntryHeaderId,
        );
        if (savedTimecard) {
          timecards.push(savedTimecard);
          return;
        }

        timecards.push(tc);
      });

      const data = { timecards, employeeCount: timecards.length };

      yield put(actions.store({ data }));
      yield delay(200);
      yield put(actions.loading({ loadType: 'saving', loading: false }));
    }
    yield put(actions.fetchFilterOptions({ skipEmployeeFilter: true }));
  }
}

function* handleSaveFailed(saveMetaData) {
  const { success, error, timeout } = saveMetaData;
  const successCount = success.length;
  const allTimecards = preSaveTimecards;

  const errorCount = error.length;
  const timeoutCount = timeout.length;

  yield put(actions.fetchFilterOptions({ skipEmployeeFilter: true }));

  const message = []; //errors for snackbar
  if (successCount !== 0) {
    message.push(
      `Success! ${pluralize('timecard', successCount, true)} saved.`,
    );
  }

  if (timeoutCount > 0) {
    message.push(
      `${pluralize(
        'timecard',
        timeout.length,
        true,
      )} timed out and might not have saved.`,
    );
  }

  let failedTimecards;
  if (errorCount > 0) {
    message.push(`${pluralize('timecard', errorCount, true)} failed to save.`);
    failedTimecards = allTimecards.filter(tc =>
      error.includes(tc.timecardEntryHeaderId),
    );
  } else if (timeoutCount > 0) {
    failedTimecards = allTimecards.filter(tc =>
      timeout.includes(tc.timecardEntryHeaderId),
    );
  }

  for (let i = 0; i < failedTimecards.length; i++) {
    const errTC = failedTimecards[i];
    if (message.length < 7) {
      message.push(`${errTC.employee.name}`);
    } else if (message.length === 7) {
      if (failedTimecards.length === 7) {
        message.push(`${errTC.employee.name}`);
      } else if (failedTimecards.length > 7) {
        message.push(`and ${failedTimecards.length - 6} more...`);
      }
      break;
    }
  }

  const rawData = {
    timecards: failedTimecards,
    employeeCount: failedTimecards.length,
  };

  yield put(actions.store({ data: rawData }));

  const variant = errorCount > 0 ? 'error' : 'warning';

  yield put(showAlert({ variant, message }));

  yield put(actions.loading({ loadType: 'saving', loading: false }));
  yield put(actions.setDeletedEmployees({ deletedEmployees: [] }));
}

export function* deleteTimecards(api, debug, params) {
  try {
    yield put(actions.loading({ loadType: 'deleting', loading: true }));

    const { toDelete } = params;

    const body = toDelete.map(tc => tc.timecardEntryHeaderId);
    const projectId = yield select(getProject);
    const res = yield call(api.dts.deleteTimecards, { projectId, body });
    const { jobId } = res;

    const deleteJobId = {};
    deleteJobId[jobId] = body;

    yield put(actions.setDeleteJobId({ deleteJobId }));
  } catch (error) {
    debug(error);
    yield put(actions.loading({ loadType: 'deleting', loading: false }));
  }
}

//Triggered by Signal-R delete message
export function* completeDeleteTimecards(api, debug, params) {
  try {
    const { jobId, isSuccessful } = params;
    const deleteJobId = yield select(sel.getDeleteJobId);

    if (deleteJobId[jobId]) {
      let error = [];
      let success = [];

      if (isSuccessful) {
        success = deleteJobId[jobId];
      } else {
        error = deleteJobId[jobId];
      }
      yield put(actions.setSaveMetaData({ success, error }));

      yield delay(0);
      let count = 0;
      let metaUpdated = false;
      let saveMetaData;
      while (metaUpdated === false && count < 10) {
        saveMetaData = yield select(sel.getSaveMetaData);
        if (success.length > 0 && saveMetaData.success.includes(success[0])) {
          metaUpdated = true;
        }

        if (error.length > 0 && saveMetaData.error.includes(error[0])) {
          metaUpdated = true;
        }

        yield delay(100);
        count++;
      }
      if (saveMetaData.pending.length === 0) {
        yield put(actions.setSaveComplete({ saveMetaData }));
      }
      yield put(actions.loading({ loadType: 'deleting', loading: false }));
    }
  } catch (e) {
    debug(e);
    yield put(actions.loading({ loadType: 'deleting', loading: false }));
  }
}

function* fetchFilters(api, debug, params) {
  try {
    yield put(actions.loading({ loadType: 'filters', loading: true }));

    const { skipEmployeeFilter } = params;

    const projectId = yield select(getProject);
    const effectiveDate = yield select(sel.getEffectiveDate);
    const dtsDate = effectiveDate.format('YYYY-MM-DD');

    const filterNames = ['batch', 'set', 'accountCode', 'episode', 'union'];

    if (!skipEmployeeFilter) {
      filterNames.push('employee');
    }

    yield all([
      ...filterNames.map(filterName =>
        call(fetchFilter, api, debug, { projectId, dtsDate, filterName }),
      ),
    ]);
  } catch (e) {
    debug(e);
    yield put(showAlert());
  } finally {
    yield put(actions.loading({ loadType: 'filters', loading: false }));
  }
}

//department filter is unique and only needs to be fetched 1x
export function* fetchDeptFilter(api, debug) {
  try {
    const projectId = yield select(getProject);

    const depFilters = yield call(api.dts.fetchDeptFilter, {
      projectId,
    });

    yield put(
      actions.storeFilterOptions({
        data: depFilters,
        filterName: 'department',
      }),
    );
  } catch (e) {
    debug(e);
    yield put(showAlert());
  }
}

function* fetchFilter(api, debug, params) {
  try {
    const projectId = params.projectId;
    const filterName = params.filterName;
    const dtsDate = params.dtsDate;

    let data = yield call(api.dts.fetchFilters, {
      projectId,
      dtsDate,
      filterName,
    });

    // // dev helper for employee filter
    // if (filterName === 'employee') {
    //   data = data.slice(0, 1);
    // }

    yield put(
      actions.storeFilterOptions({
        data,
        filterName,
      }),
    );
  } catch (e) {
    debug(e);
  }
}

export function* fetchTemplates(api, debug, params) {
  try {
    yield put(actions.loading({ loadType: 'template', loading: true }));

    let templateId = yield select(getCurrentDtsTemplate);
    //this sometimes gets called before the project details is populated
    let triesLeft = 25;
    while (templateId === null && triesLeft > 0) {
      yield delay(30);
      templateId = yield select(getCurrentDtsTemplate);
      triesLeft--;
    }

    const templates = yield call(api.dts.fetchTemplates);

    const currentTemplate = templates.documents.find(t => t.id === templateId);

    if (!currentTemplate) {
      yield put(
        showAlert({
          variant: 'warning',
          message: 'Daily timesheets template not found',
        }),
      );
      throw new Error(
        'Daily timesheets template not found. Is it set in the project settings?',
      );
    }

    const userColumns = yield call(api.dts.fetchTemplateColumns, {
      name: currentTemplate.name,
    });

    //Remove rate for DH based on project settings
    const projectDetails = yield select(getProjectDetails);
    if (projectDetails.dhGrossEnabled === false) {
      const activeUser = yield select(currentUser);
      if (activeUser.role === DH) {
        delete userColumns.rate;
        const removedValue = _.remove(
          currentTemplate.templateFields,
          f => f.name.toLowerCase() === 'rate',
        );
        if (removedValue.length > 1) {
          throw new Error(
            'Template Error: Multiple rate fields found on template',
          );
        }
      }
    }

    const settings = yield select(getSettings);
    const { dtsTimecardAutoAllowances } = settings;

    yield put(
      actions.storeTemplates({
        currentTemplate,
        userColumns,
        templateId,
        dtsTimecardAutoAllowances,
      }),
    );

    yield put(actions.fetchGroupOptions());
  } catch (e) {
    yield put(showAlert());
    yield put(actions.setTemplateError());

    debug(e);
  } finally {
    yield put(actions.loading({ loadType: 'template', loading: false }));
  }
}

//options that will be fetched if they are in the current template
export function* fetchGroupOptions(api, debug, params) {
  try {
    yield put(actions.loading({ loadType: 'groupOptions', loading: true }));

    //options is populated from fields that are in the current template
    const options = yield select(sel.getGroupOptions);
    const optionIds = Object.keys(options).filter(id =>
      OPTIONAL_GROUP_OPTION_IDS.includes(id),
    );

    yield all([...optionIds.map(id => call(fetchGroupOption, api, debug, id))]);
  } catch (e) {
    debug(e);
  } finally {
    yield put(actions.loading({ loadType: 'groupOptions', loading: false }));
  }
}

function* fetchGroupOption(api, debug, columnId) {
  // ensure that all args here are present in the url
  let parentValue = '';
  const projectId = yield select(getProject);

  if (columnId === 'episode') {
    const projectWorkSightId = yield select(getCurrentProjectWorkSight);
    parentValue = projectWorkSightId;
  }
  if (columnId === 'workCountry') {
    yield put(fetchCountriesV1());
  } else {
    const data = yield call(api.dts.fetchDropdownOption, {
      columnId,
      parentValue,
      projectId,
    });
    yield put(actions.storeDdOptions({ data, columnId }));
  }
}

// populate dropdown options that are individualistic to each employee
// groupOptions is called all at once, where as this will be on demand
// For perf enhancement: store each of these in redux based on their args (maybe stringify them and use that for a key)
// so we don't make the same call multiple times.
export function* fetchSoloOptions(api, debug, params) {
  let columnId;
  try {
    yield select(getProject);
    const { original, search = '' } = params;
    columnId = params.columnId;
    yield put(actions.optionsLoading({ columnId, loading: true }));

    let stateId;
    switch (columnId) {
      case 'workState':
        const countryId = original?.workCountry?.id;
        yield put(fetchStatesV1({ countryId }));
        // eslint-disable-next-line no-fallthrough
        break;
      case 'workCity':
        stateId = original?.workState?.id;
        yield put(fetchCitiesV1({ stateId }));
        break;
      case 'workCounty':
        stateId = original?.workState?.id;
        yield put(fetchCountiesV1({ stateId }));
        break;
      case 'workSubdivision':
        stateId = original?.workState?.id;
        yield put(fetchSubdivisionsV1({ search }));
        break;

      default:
        const params = yield getSoloParams(columnId, original);
        params.search = search;
        const data = yield call(api.dts.fetchSoloOption, {
          columnId,
          params,
        });

        yield put(actions.storeDdOptions({ data, columnId }));
        break;
    }
  } catch (e) {
    debug(e);
  } finally {
    yield put(actions.optionsLoading({ columnId, loading: false }));
  }
}

function* fetchDealMemos(api, debug, params) {
  // we need to fetch dealMemos to calc some cascading changes for workLocation,
  // this ensures we won't load the workLocation options untils we've fetched the deals for the user
  yield put(actions.optionsLoading({ columnId: 'dealMemo', loading: true }));

  try {
    const projectWorkSightId = yield select(getCurrentProjectWorkSight);

    const { effectiveDate, employeeId, withPayroll } = params;

    const data = yield call(api.employees.dealMemos, {
      projectId: projectWorkSightId,
      employeeId,
      startDate: effectiveDate,
      endDate: effectiveDate,
      withPayroll,
    });

    yield put(actions.storeDealMemos({ data }));
  } catch (e) {
    debug(e);
  } finally {
    yield put(actions.optionsLoading({ columnId: 'dealMemo', loading: false }));
  }
}

// for each option, get the data we need and return the args that will be consumed by buildURL
function* getSoloParams(columnId, original) {
  const dealMemo = original && original.dealMemo;

  const params = {};

  const project = yield select(getProjectDetails);

  let fetchParams = {
    contractId:
      (dealMemo.contract && dealMemo.contract.id) ||
      (dealMemo.htgContract && dealMemo.htgContract.id) ||
      'NO_CONTRACT',
    htgContractId:
      (dealMemo.htgContract && dealMemo.htgContract.id) ||
      (dealMemo.contract && dealMemo.contract.id) ||
      'NO_CONTRACT',
    htgUnionId: dealMemo && dealMemo.htgUnion && dealMemo.htgUnion.id,
    dbCodeId: project && project.dbCodeId,
    employeeId: original && original.employeeId,
    pensionUnionId:
      dealMemo && dealMemo.pensionUnion && dealMemo.pensionUnion.id,
  };

  if (columnId === 'dayType') {
    params.parentValue = fetchParams.htgContractId;
  } else if (columnId === 'locationType') {
    const htgContractId = fetchParams.htgContractId;
    const htgUnionId = fetchParams.htgUnionId;
    params.options = JSON.stringify({ htgContractId, htgUnionId });
  } else if (columnId === 'occupationCode') {
    params.parentValue = fetchParams.dbCodeId;
    const contract = fetchParams.contractId;
    const union = fetchParams.pensionUnionId;
    params.options = JSON.stringify({ contract, union });
  }

  return params;
}

//navigate to searchTimecards page
function* listTimecards(api, debug, params) {
  try {
    const timecards =
      params.data && params.data.timecards ? params.data.timecards : [];

    const draftTimecards = timecards.filter(tc => {
      const status =
        tc.userFriendlyTimecardStatus &&
        tc.userFriendlyTimecardStatus.toLowerCase();
      const isDraft = status === 'draft';

      return isDraft;
    });

    const effectiveDate = yield select(sel.getEffectiveDate);
    const weekEnding = effectiveDate.clone().endOf('week').format('YYYY-MM-DD');
    const employees = Array.from(
      new Set(draftTimecards.map(tc => tc.employee.id)),
    );
    const projectId = yield select(getProject);

    yield put(setDraftFilters({ employees, weekEnding }));
    yield put(setSelectAllFlag({ selectAllFlag: true }));
    yield put(push(`/projects/${projectId}/review/search-timecards`));
  } catch (e) {
    debug(e);
  }
}

function* copyToAllSoloSelect(api, debug, params) {
  //skeleton for properly implementing solo select CopyToAll
  // const {data,columnId,val} = params;
  //
}

function* fetchRounding(api, debug, params) {
  try {
    const projectId = yield select(getProject);
    const { dealMemoId } = params;
    const dealMemoIds = [dealMemoId];

    const rounding = yield call(api.employees.roundTo, {
      projectId,
      dealMemoIds,
    });

    yield put(actions.setRounding({ rounding }));
  } catch (error) {
    debug(error);
    yield put(showAlert());
  }
}

export default function* dts({ api, debug }) {
  yield all([
    takeLatest(`${actions.init}`, init, api, debug),
    takeLatest(`${actions.fetchData}`, fetchData, api, debug),
    takeLatest(`${actions.save}`, save, api, debug),

    takeEvery(`${signalRNotification}`, tcUpdates, api, debug),
    takeEvery(`${actions.setSaveComplete}`, saveComplete, api, debug),

    takeLatest(`${actions.fetchTemplates}`, fetchTemplates, api, debug),
    takeLatest(`${actions.fetchFilterOptions}`, fetchFilters, api, debug),
    takeLatest(`${actions.fetchGroupOptions}`, fetchGroupOptions, api, debug),
    takeLatest(`${actions.fetchSoloOptions}`, fetchSoloOptions, api, debug),
    takeLatest(`${actions.fetchDealMemos}`, fetchDealMemos, api, debug),
    takeLatest(`${actions.listTimecards}`, listTimecards, api, debug),

    takeLatest(
      `${actions.copyToAllSoloSelect}`,
      copyToAllSoloSelect,
      api,
      debug,
    ),
    takeLatest(`${actions.fetchRounding}`, fetchRounding, api, debug),
    takeEvery(`${actions.deleteTimecards}`, deleteTimecards, api, debug),
    takeEvery(`${signalRJobSearchDelete}`, completeDeleteTimecards, api, debug),
    takeEvery(`${actions.fetchDeleted}`, fetchDeleted, api, debug),
  ]);
}
