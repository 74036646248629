import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FlagsList from 'components/Admin/FeatureFlags';
import * as actions from 'actions/flag';
import { flagsProps } from 'components/props/flags';
import { getFlags, getLoading as isUpdating } from 'selectors/flags';

const mapState = state => ({
  flags: getFlags(state),
  loading: isUpdating(state),
});

const mapDispatch = dispatch => ({
  onFetchData: () => dispatch(actions.fetch()),
  onUpdateFlags: () => dispatch(actions.update()),
  onUpdateEnabled: id => (_, enabled) =>
    dispatch(actions.edit({ id, enabled })),
});

class FeatureFlags extends Component {
  static propTypes = {
    flags: flagsProps.isRequired,
    loading: PropTypes.bool.isRequired,
    onFetchData: PropTypes.func.isRequired,
    onUpdateFlags: PropTypes.func.isRequired,
    onUpdateEnabled: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { onFetchData } = this.props;

    onFetchData();
  }

  render() {
    const { flags, loading, onUpdateFlags, onUpdateEnabled } = this.props;

    return (
      <FlagsList
        flags={flags}
        loading={loading}
        onUpdateEnabled={onUpdateEnabled}
        onUpdate={onUpdateFlags}
      />
    );
  }
}

export default connect(mapState, mapDispatch)(FeatureFlags);
