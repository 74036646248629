import Checkbox from '@mui/material/Checkbox';
import { createComponent } from 'utils/redux';

export default createComponent(
  Checkbox,
  ({
    input: { onChange, value, ...inputProps },
    meta,
    onChange: ignoredOnChange,
    defaultChecked,
    ...props
  }) => ({
    ...inputProps,
    ...props,
    checked: value ? true : false,
    onChange: (event, isInputChecked) => {
      onChange(isInputChecked);
    },
  }),
);
