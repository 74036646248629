import React from 'react';
import { List } from 'containers/Employees/CrewTimecard';

import {
  FullWidthLayout,
  Header,
  ProjectHeader,
  AlertHeader,
} from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';
import { CrewTimecardList as SubNav } from 'containers/Nav/Pages';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    authenticated
    key="/projects/crew/timecards"
    id="/projects/crew/timecards"
    path="/projects/:projectId/timecards"
    layout={FullWidthLayout}
    headers={[Header, ProjectHeader, AlertHeader, SubNav]}
    component={List}
    title="Crew Timecard List"
  />
);
