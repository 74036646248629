import createAction from './createAction';

export const loading = createAction('loading/users');

export const fetch = createAction('fetch/users');
export const store = createAction('store/users');

export const fetchUsersAllProjects = createAction(
  'fetch/users/usersofallprojects',
);
export const storeUsersAllProjects = createAction(
  'store/users/usersofallprojects',
);

export const reset = createAction('reset/users');
