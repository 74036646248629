import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

//components
import FieldCheckboxDropDown from 'components/Shared/FieldCheckboxDropDown';

//Columns that we show in the table, but suppress in this drop down
const suppressedColumns = ['comments', 'emergencyType', 'selection'];

/**
 * Wrapper for additionalFields for Search Timecards
 * @component
 * @param {array} hiddenColumns - hidden columns from react-table
 * @param {func} setHiddenColumns - update hidden columns. Must be NEW array
 */
const AdditionalFieldsContainer = props => {
  const { setHiddenColumns, hiddenColumns, filteredColumns } = props;

  const [additionalFields, setAdditionalFields] = useState({});

  useEffect(() => {
    const activeFields = {};
    filteredColumns.forEach((field, i) => {
      const visible = !hiddenColumns.includes(field.id);
      if (suppressedColumns.includes(field.id)) {
        //suppress these fields from the dropdown
      } else {
        activeFields[field.id] = { ...field, label: field.Header, visible };
      }
    });

    setAdditionalFields(activeFields);
  }, [hiddenColumns, filteredColumns]);

  const onClickField = field => {
    setHiddenColumns(old => {
      const hiddenColumns = old.slice();
      const hiddenIndex = hiddenColumns.indexOf(field.id);
      if (hiddenIndex === -1) {
        hiddenColumns.push(field.id);
      } else {
        hiddenColumns.splice(hiddenIndex, 1);
      }
      return hiddenColumns;
    });
  };

  const selectedCount =
    Object.keys(additionalFields).length - hiddenColumns.length;

  return (
    <FieldCheckboxDropDown
      fieldsList={additionalFields}
      selectedCount={selectedCount}
      onClickField={onClickField}
      label="Additional Fields"
    />
  );
};

AdditionalFieldsContainer.propTypes = {
  setHiddenColumns: PropTypes.func.isRequired,
  hiddenColumns: PropTypes.array.isRequired,
};

export default AdditionalFieldsContainer;
