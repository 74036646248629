import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import SelectComp from './SelectComp';

//selectors
import { getPaySchedulesOptions, getEpisodes } from 'selectors/dts';
import { getCountriesV1 } from 'selectors/location';

//utils
import { cckList } from 'utils/wtcWeekUtils';

const mapState = state => ({
  countries: getCountriesV1(state),
  paySchedules: getPaySchedulesOptions(state),
  episodes: getEpisodes(state),
});

const useStyles = makeStyles(theme => ({
  root: {
    transition: 'all 0.4s ',
    marginTop: 0,
  },
  hide: {
    transform: 'scale(.01)',
    transformOrigin: 'left',
  },
}));

const GroupSelect = props => {
  const { columnId, rowIndex, countries, paySchedules, episodes, setTabIndex } =
    props;
  const classes = useStyles();
  let options,
    emptyMsg = 'No options';

  switch (columnId) {
    case 'workCountry':
      options = countries;
      break;
    case 'combineCheck':
      options = cckList;
      break;
    case 'schedule':
      options = paySchedules;
      break;
    case 'episode':
      options = episodes;
      break;

    default:
      console.warn('unsupported option:', columnId);
      break;
  }

  const [didMount, setDidMount] = useState(false); //used to change CSS for intro animation
  useEffect(() => {
    if (!didMount) {
      setDidMount(true);
    }
  }, [setDidMount, didMount]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.hide]: !didMount,
      })}
    >
      <SelectComp
        {...props}
        options={options}
        emptyMsg={emptyMsg}
        setTabIndex={setTabIndex}
        rowIndex={rowIndex}
      />
    </div>
  );
};

export default compose(connect(mapState))(GroupSelect);
