import React from 'react';
import PropTypes from 'prop-types';
import { Link, Box, Button, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  NotFound: {
    display: 'flex',
    alignContent: 'center',
    height: '98vh',
    padding: 8,
  },
  fourOhFourText: {
    fontSize: 'min(min(42vw,30vh),350px)',
    fontWeight: 700,
    color: palette.primary.main,
  },
  oopsText: {
    fontSize: 'min(min(6vw,6vh),42px)',
    fontWeight: 700,
    color: palette.primary.main,
  },
}));

const NotFound = props => {
  const classes = useStyles();
  const {
    history: { goBack },
  } = props;

  return (
    <Grid container className={classes.NotFound}>
      <Grid item sm={0} lg={3} xl={4} />
      <Grid item sm={12} lg={6} xl={4}>
        <Box className={classes.fourOhFourText}>404</Box>
        <Box className={classes.oopsText}>
          Oops! The link you're trying to access has been modified or is no
          longer available.
        </Box>
        <Box sx={{ mt: 2 }}>
          The URL you entered might contain a typo or be incomplete please
          double check for any typos or spelling errors. If you need further
          assistance please contact our support team at:{' '}
          <Link href="mailto:Plus.Support@CastandCrew.com">
            Plus.Support@CastandCrew.com
          </Link>
        </Box>
        <Box sx={{ mt: '6vh', display: 'flex', gap: '24px', flexWrap: 'wrap' }}>
          <Button onClick={goBack}>
            <ArrowBackIcon sx={{ mr: 2 }} />
            Go Back to Previous Page
          </Button>
          {/* Possible extra nav button? Not in mock but might be desirable in the future
          <Button
            className={classes.dashboardBtn}
            href="https://my.castandcrew.com"
          >
            Go to MyC&C Dashboard
          </Button> */}
        </Box>
      </Grid>
      <Grid item sm={0} lg={3} xl={4} />
    </Grid>
  );
};

NotFound.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }),
};

export default NotFound;
