import React from 'react';
import PropTypes from 'prop-types';

//Need forward ref for tooltip to work
const EditNoteIcon = React.forwardRef((props, ref) => {
  const { className, fill = 'none' } = props;

  return (
    <svg
      className={className}
      ref={ref}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="file-question-03">
        <path
          id="Icon"
          d="M14.5 2.26953V6.40007C14.5 6.96012 14.5 7.24015 14.609 7.45406C14.7049 7.64222 14.8578 7.7952 15.046 7.89108C15.2599 8.00007 15.5399 8.00007 16.1 8.00007H20.2305M10.35 12.0022C10.5262 11.5014 10.8739 11.079 11.3317 10.81C11.7895 10.5409 12.3277 10.4426 12.851 10.5324C13.3743 10.6221 13.849 10.8942 14.1909 11.3004C14.5329 11.7066 14.72 12.2207 14.7192 12.7517C14.7192 14.2506 12.4709 15 12.4709 15M12.5 18H12.51M14.5 2H9.3C7.61984 2 6.77976 2 6.13803 2.32698C5.57354 2.6146 5.1146 3.07354 4.82698 3.63803C4.5 4.27976 4.5 5.11984 4.5 6.8V17.2C4.5 18.8802 4.5 19.7202 4.82698 20.362C5.1146 20.9265 5.57354 21.3854 6.13803 21.673C6.77976 22 7.61984 22 9.3 22H15.7C17.3802 22 18.2202 22 18.862 21.673C19.4265 21.3854 19.8854 20.9265 20.173 20.362C20.5 19.7202 20.5 18.8802 20.5 17.2V8L14.5 2Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
});

EditNoteIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default EditNoteIcon;
