import React from 'react';
import clsx from 'clsx';
import { Typography, AppBar, LinearProgress } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import PropTypes from 'prop-types';

import FadeInScroll from 'components/Shared/FadeInScroll';
import WTCHeaderButtons from './WTCHeaderButtons';

import AdditionalFields from 'components/Employees/Reviews/WTC/AdditionalFields/AdditionalFieldsContainer';
import { weekEndingFormat } from 'utils/formatDate';

import PrintMenu from './WTCPrintMenu';

const style = ({ palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 80,
    color: palette.gray.main,
    backgroundColor: palette.gray.background,
  },
  name: {
    fontSize: 24,
    fontWeight: 300,
    justifyItems: 'end',
    marginLeft: 'auto',
    marginRight: 16,
  },
  infoGroup: {
    marginRight: 56,
  },
  briefInfo: {
    fontSize: 12,
    fontWeight: 500,
  },
  submitButton: {
    marginRight: 8,
  },
  rejectButton: {
    padding: '5px 20px',
    marginRight: 8,
  },
  saveButton: {
    padding: '5px 20px',
    marginRight: 8,
  },
  undoButton: {
    marginLeft: 0,
    marginRight: 8,
  },
  icon: {
    marginRight: 8,
    backgroundColor: palette.background.default,
    border: `3px ${palette.background.default} solid`,
    borderRadius: 12,
    fontSize: 17,
  },
  rightBar: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 16,
  },
  additionalField: {
    width: 184,
  },
  submitTip: {
    fontSize: '.75em',
  },
  deleteButton: {
    padding: '5px 20px',
    marginRight: 8,
    color: palette.error.main,
    border: `1px solid ${palette.error.main}`,
    '&:hover': {
      color: palette.common.white,
      border: `1px solid ${palette.error.main}`,
      backgroundColor: palette.error.main,
    },
  },
  multiBatchTitle: {
    color: palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const StickyHeader = props => {
  const {
    classes,
    timecard,
    canRenderTimecard,
    processing,
    wtcUpdating,
    isMultiBatch,
    loadSingleBatch,
    onForceCommentAction,
    onSaveTimecard,
    onApprovalWithoutChange,
    onSetAutoCoding,
  } = props;
  let name, weekEnding, batchCode, batchName;

  if (canRenderTimecard) {
    name = timecard.employee.name.includes(':')
      ? timecard.employee.name.split(':')[0]
      : timecard.employee.name;
    weekEnding = weekEndingFormat(timecard.weekEndingDate);
    batchCode = timecard.worksightHtgId;
    batchName = timecard.batch.name;
  }

  const timecardElement = document.getElementById('wtcTimecard');

  return (
    // customThreshold should a estimate number of pixels
    // before the static element is blocked and not visible
    <FadeInScroll customThreshold={100} target={timecardElement ?? undefined}>
      <AppBar>
        <div className={classes.root}>
          <Typography className={classes.name}>{name}</Typography>
          <div className={classes.infoGroup}>
            <Typography className={classes.briefInfo}>
              Week Ending: <span>{weekEnding}</span>
            </Typography>
            <Typography
              className={clsx(classes.briefInfo, {
                [classes.multiBatchTitle]: isMultiBatch,
              })}
              onClick={() => (isMultiBatch ? loadSingleBatch() : {})}
            >
              Batch: {`${batchCode} - ${batchName}`}
            </Typography>
          </div>
          <div>
            <WTCHeaderButtons
              timecard={timecard}
              onForceCommentAction={onForceCommentAction}
              onSaveTimecard={onSaveTimecard}
              onApprovalWithoutChange={onApprovalWithoutChange}
              onSetAutoCoding={onSetAutoCoding}
            />
          </div>
          <div className={classes.rightBar}>
            <PrintMenu />
            <div className={classes.additionalField}>
              {canRenderTimecard && <AdditionalFields />}
            </div>
          </div>
        </div>
        {(processing || wtcUpdating) && <LinearProgress />}
      </AppBar>
    </FadeInScroll>
  );
};

StickyHeader.propTypes = {
  timecard: PropTypes.object.isRequired,
};

export default withStyles(style)(StickyHeader);
