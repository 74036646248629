import React from 'react';
import { connect } from 'react-redux';
import { FaPlus as AddIcon } from 'react-icons/fa';
import { PageHeader } from 'components/Nav';
import { NavButton } from 'components/Shared/Nav';
import { storeTemplateOption } from 'actions/invoiceTemplate';
import { getTemplateOption } from 'selectors/invoiceTemplate';

const mapState = state => ({
  templateOption: getTemplateOption(state),
});
const mapDispatchToProps = dispatch => ({
  onAddBatch: value => {
    dispatch(storeTemplateOption({ templateOption: value }));
  },
});

const InvoiceTemplateHeader = props => {
  const { onAddBatch, templateOption } = props;
  const buttons = [
    <NavButton
      key="add_batch"
      label="Add template"
      icon={AddIcon}
      disabled={templateOption === 'edit' || templateOption === 'new'}
      onClick={() => onAddBatch('new')}
    />,
  ];

  return <PageHeader title="" navItems={buttons} />;
};

export default connect(mapState, mapDispatchToProps)(InvoiceTemplateHeader);
