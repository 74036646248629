import React, { useState, useEffect } from 'react';
import SaveIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Clear';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, ClickAwayListener } from '@mui/material';
import { TextField } from 'components/Shared/redux';
import { INPUT_FIELD_CHARACTER_LIMIT } from 'components/Shared/constants';
const commentsLimit = INPUT_FIELD_CHARACTER_LIMIT.comments;

const useStyles = makeStyles(theme => ({
  noteField: {
    display: 'flex',
    alignItems: 'center',
    width: '95%',
  },
}));

export default function Note({
  id,
  isNotesProcessing = false,
  label,
  name = '',
  onSave,
  value = '',
  setNoteEmpty,
}) {
  const classes = useStyles();
  const [note, setNote] = useState(value);
  const [error, setError] = useState(false);
  const fieldId = name || `note-field-${id}`;

  useEffect(() => {
    return function cleanup() {
      //if the day is hidden, adjust state accordingly
      setNoteEmpty(fieldId, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearNoteText = () => {
    setNote('');
    const noteField = document.getElementById(fieldId);
    if (noteField) noteField.value = '';
    setNoteEmpty(fieldId, true);
    setError(false);
  };

  const handleSaveNote = () => {
    onSave(note);
    clearNoteText();
  };

  const handleClearNote = () => {
    clearNoteText();
  };

  const onIsNoteEmpty = value => {
    const isEmpty = value === '';
    setNoteEmpty(fieldId, isEmpty);
  };

  let helperText = '';
  if (note) {
    helperText = 'Comment must be submitted';
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (note) {
          setError(true);
        }
      }}
    >
      <div className={classes.noteField}>
        <TextField
          id={fieldId}
          style={{ margin: 8 }}
          placeholder={
            label ||
            'Enter Timecard Note. For Example: "I had an NDB every day this week"'
          }
          fullWidth
          // autoFocus //causes odd scroll jump when toggling edit mode for DH
          margin="normal"
          defaultValue={value || ''}
          onChange={event => {
            setNote(event.target.value);
            onIsNoteEmpty(event.target.value);
          }}
          inputProps={{ maxLength: commentsLimit }}
          onFocus={() => setError(false)}
          error={error}
          helperText={helperText}
        />
        <IconButton
          size="small"
          onClick={handleSaveNote}
          disabled={isNotesProcessing || note === ''}
        >
          <SaveIcon />
        </IconButton>
        <IconButton
          size="small"
          onClick={handleClearNote}
          disabled={isNotesProcessing}
        >
          <CancelIcon />
        </IconButton>
      </div>
    </ClickAwayListener>
  );
}
