import React from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import moment from 'moment';
import { formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Tooltip, Box } from '@mui/material';

import TimeValidator from 'utils/TimeValidator';

import * as sel from '../selectors';

import TimeCell from '../Shared/Cells/TimeCell';
import TextCell from '../Shared/Cells/TextCell';
import DisplayCell from '../Shared/Cells/DisplayCell';
import SelectCell from '../Shared/Cells/SelectCell';

import { FORM_NAME } from 'feature/EmployeeTimecard/selectors';

const formSelector = formValueSelector(FORM_NAME);

const mapState = state => ({
  columns: sel.getVisibleColumns(state),
  roundTo: sel.getDealRounding(state),
  days: formSelector(state, 'days'),
  isTcEditable: sel.getIsTcEditable(state),
  activeDeal: sel.getActiveDealMemo(state),
});

const mapDispatch = {};

const Day = props => {
  const { member, columns, index, roundTo, days, isTcEditable, activeDeal } =
    props;
  const day = days[index];

  const { date, htgDayTypeId } = day;

  const isDayActive = !!htgDayTypeId;

  const timeV = React.useMemo(() => new TimeValidator(roundTo), [roundTo]);
  const isPartialDealMemo =
    moment(date).isBefore(activeDeal.start) ||
    moment(date).isAfter(activeDeal.end);

  const cells = React.useMemo(() => {
    return columns.map(column => {
      const { type, accessor } = column;
      const name = `${member}.${accessor}`;

      const cellProps = {
        index,
        name,
        member,
        column,
        columns,
        timeV,
        isDayActive,
        isTcEditable,
        isPartialDealMemo,
      };
      let Cell;

      switch (type) {
        case 'autocomplete':
          Cell = SelectCell;
          break;
        case 'time':
          Cell = TimeCell;
          cellProps.timeV = timeV;
          break;
        case 'text':
          Cell = TextCell;
          break;
        default:
          Cell = Field;
          cellProps.component = DisplayCell;
          break;
      }

      const isDayType = accessor === 'dayType';
      const isActions = accessor === 'actions';

      let isToday = false;

      if (accessor === 'date') {
        const value = day[accessor];
        const dateVal = moment(value);
        const today = moment();

        isToday = today.isSame(dateVal, 'day');
      }
      const CellPadding = 16;

      return (
        <TableCell
          key={name}
          sx={{
            width: column.width,
            backgroundColor:
              isDayType || isActions ? 'background.default' : undefined,
            position: isActions ? 'sticky' : undefined,
            right: isActions ? 0 : undefined,
            padding: isActions ? '8px' : undefined,
          }}
        >
          {isToday && (
            <Box sx={{ position: 'relative' }}>
              <Tooltip title="Today" placement="top">
                <Box
                  sx={{
                    backgroundColor: 'primary.+6',
                    width: '12px',
                    height: '72px',
                    position: 'absolute',
                    top: `-${CellPadding}px`,
                    left: `-${CellPadding}px`,
                  }}
                />
              </Tooltip>
            </Box>
          )}
          <Cell {...cellProps} />
        </TableCell>
      );
    });
  }, [
    columns,
    index,
    isDayActive,
    isTcEditable,
    member,
    timeV,
    isPartialDealMemo,
    day,
  ]);

  //TODO for removing days from read only timecard
  // if (!isDayActive) return null;

  return <TableRow key={member}>{cells}</TableRow>;
};

Day.propTypes = {
  member: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  roundTo: PropTypes.number.isRequired,
  days: PropTypes.array.isRequired,
  isTcEditable: PropTypes.bool.isRequired,
  activeDeal: PropTypes.object.isRequired,
};

export default compose(connect(mapState, mapDispatch))(Day);
