import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CreateIcon from '@mui/icons-material/Create';

import { withStyleSheet } from 'shared/theme';
import { Field } from 'redux-form';
import { compose } from 'utils/helperFunctions';
import {
  Divider,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Switch from 'components/Shared/redux/Switch';
import clsx from 'clsx';

const style = theme => ({
  root: {
    width: '100%',
    marginTop: 20,
  },
  switch: {
    paddingLeft: 10,
  },
  saveButton: {
    marginTop: 20,
  },
  space: {
    marginTop: 20,
  },
  content: {
    width: '100%',
  },
  heading: {
    fontSize: '1.1em',
    fontFamily: 'inherit',
  },
  checkboxContent: {
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  radioContent: {
    padding: '10px 0px',
  },
  noBorder: {
    border: 'none',
  },
  borderContent: {
    border: `solid 1px ${theme.palette.gray['+8']}`,
    borderRadius: '3px',
    padding: '10px',
  },
  disableBlock: {
    pointerEvents: 'none',
    opacity: '0.4',
  },
});

class StudioPlus extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
  };

  static defaultProps = {
    invalid: false,
    pristine: true,
    submitting: false,
  };

  editStudioPath(group) {
    const { onEditAction } = this.props;
    if (onEditAction) return onEditAction(group);
    return null;
  }
  handleChange(e) {
    const {
      change,
      showInfoAlert,
      TCStudioEnabled,
      BRStudioEnabled,
      TCStudioFolder,
      BRStudioFolder,
      onSelectSaveBtnDisabled,
    } = this.props;
    if (e?.target?.value === 'New' || e?.target?.value === 'Disabled') {
      change('studioEnabled', e.target.value);
      change('studioBatchEnabled', false);
      change('studioTimecardEnabled', false);
      change('studioBatchPath', null);
      change('studioTimecardPath', null);
      onSelectSaveBtnDisabled(false);
    } else {
      if (
        (TCStudioEnabled && TCStudioFolder) ||
        (BRStudioEnabled && BRStudioFolder)
      ) {
        onSelectSaveBtnDisabled(false);
      } else {
        onSelectSaveBtnDisabled(true);
      }
      showInfoAlert();
    }
  }
  studioBatchEnabled(e) {
    const {
      change,
      onSelectSaveBtnDisabled,
      TCStudioEnabled,
      TCStudioFolder,
      BRStudioFolder,
      pristine,
      BRStudioEnabled,
    } = this.props;
    if (!e.target.checked) {
      change('studioBatchEnabled', false);
      change('studioBatchPath', null);
      if (
        (!e.target.checked && !TCStudioEnabled) ||
        (!TCStudioEnabled && !pristine)
      ) {
        onSelectSaveBtnDisabled(true);
      } else {
        onSelectSaveBtnDisabled(false);
      }
    } else {
      change('studioBatchEnabled', true);
      if (
        (TCStudioEnabled && !TCStudioFolder) ||
        (BRStudioEnabled && !BRStudioFolder) ||
        (!BRStudioEnabled && !TCStudioEnabled) ||
        (TCStudioEnabled &&
          e.target.checked &&
          (!BRStudioFolder || !TCStudioFolder)) ||
        (TCStudioEnabled &&
          e.target.checked &&
          (!BRStudioFolder || TCStudioFolder)) ||
        (TCStudioEnabled &&
          e.target.checked &&
          (BRStudioFolder || !TCStudioFolder))
      ) {
        onSelectSaveBtnDisabled(true);
      } else if (
        ((TCStudioEnabled || e.target.checked) &&
          (BRStudioFolder || TCStudioFolder)) ||
        (TCStudioEnabled && TCStudioFolder) ||
        (e.target.checked && BRStudioFolder)
      ) {
        onSelectSaveBtnDisabled(false);
      }
    }
  }
  studioTimecardEnabled(e) {
    const {
      change,
      onSelectSaveBtnDisabled,
      BRStudioEnabled,
      BRStudioFolder,
      TCStudioEnabled,
      TCStudioFolder,
      pristine,
    } = this.props;
    if (!e.target.checked) {
      change('studioTimecardEnabled', false);
      change('studioTimecardPath', null);
      if (
        (!BRStudioEnabled && !e.target.checked) ||
        (!BRStudioEnabled && !pristine)
      ) {
        onSelectSaveBtnDisabled(true);
      } else {
        onSelectSaveBtnDisabled(false);
      }
    } else {
      change('studioTimecardEnabled', true);
      if (
        (TCStudioEnabled && !TCStudioFolder) ||
        (BRStudioEnabled && !BRStudioFolder) ||
        (!BRStudioEnabled && !TCStudioEnabled) ||
        (e.target.checked &&
          BRStudioEnabled &&
          (!BRStudioFolder || !TCStudioFolder)) ||
        (e.target.checked &&
          BRStudioEnabled &&
          (!BRStudioFolder || TCStudioFolder)) ||
        (e.target.checked &&
          BRStudioEnabled &&
          (BRStudioFolder || !TCStudioFolder))
      ) {
        onSelectSaveBtnDisabled(true);
      } else if (
        ((e.target.checked || BRStudioEnabled) &&
          (BRStudioFolder || TCStudioFolder)) ||
        (e.target.checked && TCStudioFolder) ||
        (BRStudioEnabled && BRStudioFolder)
      ) {
        onSelectSaveBtnDisabled(false);
      }
    }
  }

  render() {
    const {
      classes,
      invalid,
      pristine,
      submitting,
      TCStudioPath,
      BRStudioPath,
      BRStudioEnabled,
      TCStudioEnabled,
      syncErrorsValid,
      studioEnabled,
      saveBtnDisbled,
    } = this.props;
    return (
      <div style={{ marginTop: 30 }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              Studio+ Destination
            </Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails className={classes.noPadding}>
            <div className={classes.content}>
              <div className={classes.radioContent}>
                <Field
                  component="fieldset"
                  name="studioEnabled"
                  className={classes.noBorder}
                >
                  <RadioGroup
                    row
                    aria-label="project"
                    name="studioEnabled"
                    value={studioEnabled || 'Disabled'}
                    onChange={e => this.handleChange(e)}
                  >
                    <FormControlLabel
                      value="Disabled"
                      control={
                        <Radio
                          checked={studioEnabled === 'Disabled'}
                          classes={{
                            root: classes.checkboxContent,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Disabled"
                    />
                    <FormControlLabel
                      value="Legacy"
                      control={
                        <Radio
                          checked={studioEnabled === 'Legacy'}
                          classes={{
                            root: classes.checkboxContent,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Legacy"
                    />
                    <FormControlLabel
                      value="New"
                      control={
                        <Radio
                          checked={studioEnabled === 'New'}
                          classes={{
                            root: classes.checkboxContent,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="New"
                    />
                  </RadioGroup>
                </Field>
              </div>
              <div
                className={clsx(classes.borderContent, {
                  [classes.disableBlock]:
                    studioEnabled === 'New' || studioEnabled === 'Disabled',
                })}
                disabled={
                  studioEnabled === 'New' || studioEnabled === 'Disabled'
                }
              >
                <div>
                  <div>
                    Processed <b>timecards</b> will be sent to the following
                    destination in Studio +
                  </div>
                  <div>
                    <span data-test-id="DocumentDestination-path">
                      {TCStudioPath ? (
                        <span className={classes.destination}>
                          {TCStudioPath}
                        </span>
                      ) : (
                        <span>No destination directory set</span>
                      )}
                    </span>
                    <IconButton
                      disabled={!TCStudioEnabled}
                      aria-label="Select Folder Location"
                      onClick={() => this.editStudioPath('Timecard')}
                    >
                      <CreateIcon />
                    </IconButton>
                  </div>
                </div>
                <div>
                  <div>
                    <Field
                      className={classes.switch}
                      component={Switch}
                      name="studioTimecardEnabled"
                      color="primary"
                      onChange={e => this.studioTimecardEnabled(e)}
                    />
                    Enabled
                  </div>
                </div>
                <Divider style={{ width: '50%' }} />
                <div style={{ marginTop: '5px' }}>
                  <div>
                    Processed <b>batch reports</b> will be sent to the following
                    destination in Studio +
                  </div>
                  <div>
                    <span data-test-id="DocumentDestination-path">
                      {BRStudioPath ? (
                        <span className={classes.destination}>
                          {BRStudioPath}
                        </span>
                      ) : (
                        <span>No destination directory set</span>
                      )}
                    </span>
                    <IconButton
                      disabled={!BRStudioEnabled}
                      aria-label="Select Folder Location"
                      onClick={() => this.editStudioPath('Batch')}
                    >
                      <CreateIcon />
                    </IconButton>
                  </div>
                </div>
                <div>
                  <div>
                    <Field
                      className={classes.switch}
                      component={Switch}
                      name="studioBatchEnabled"
                      color="primary"
                      onChange={e => this.studioBatchEnabled(e)}
                    />
                    Enabled
                  </div>
                </div>
              </div>
              <div className={classes.content}></div>
            </div>
          </AccordionDetails>
          <Divider />
          <AccordionActions className={classes.noPadding}>
            <Button
              disabled={
                pristine ||
                submitting ||
                invalid ||
                syncErrorsValid === false ||
                saveBtnDisbled
              }
              variant="contained"
              color="primary"
              type="submit"
              className={classes.saveButton}
            >
              Save Settings
            </Button>
          </AccordionActions>
        </Accordion>
      </div>
    );
  }
}
export default compose(withStyleSheet('StudioPlus', style))(StudioPlus);
