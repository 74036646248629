import React, { useState, useEffect } from 'react';
import { DH } from 'components/props/profiles';
import { TIMECARD_READY_FOR_ME } from 'components/Shared/constants';
import { Button } from '@mui/material';

const ApproveRejectButtons = props => {
  const { bulkTimecardSubmission, currentUserRole, selectedRows, submitting } =
    props;

  const [showAppRejBtns, setShowAppRejBtns] = useState(false);

  useEffect(() => {
    const readyForReviewSelected = selectedRows.filter(
      row => row.values.status === TIMECARD_READY_FOR_ME,
    ).length;

    const draftsSelected = selectedRows.filter(
      row => row.values.status === 'Draft',
    ).length;

    let showBtns = false;

    if (readyForReviewSelected > 0 && draftsSelected === 0) showBtns = true;

    setShowAppRejBtns(showBtns);
  }, [selectedRows]);

  return currentUserRole === DH && showAppRejBtns ? (
    <div style={{ display: 'flex' }}>
      <span>
        <Button
          onClick={() => bulkTimecardSubmission(selectedRows, 'approve')}
          disabled={submitting}
        >
          Approve
        </Button>
      </span>
      <span>
        <Button
          onClick={() => bulkTimecardSubmission(selectedRows, 'reject')}
          disabled={submitting}
          color="error"
        >
          Reject
        </Button>
      </span>
    </div>
  ) : null;
};
export default ApproveRejectButtons;
