import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';

import ColorPill from 'components/Shared/ColorPill';

const useStyles = makeStyles(({ palette }) => {
  return {
    styleRow: {
      marginLeft: '0px !important',
      marginRight: '0px !important',
      padding: '5px !important',
      fontSize: '12px',
      fontStyle: 'normal',
      border: `solid 1px ${palette.gray['+8']}`,
      lineHeight: '16px',
      letterSpacing: '0.3199999928474426px',
      textAlign: 'center',
    },
  };
});
const SearchTableFilter = props => {
  const {
    onStatusFilterSelect,
    onAllInvoiceFilterSelect,
    filterBadgeCountObj,
    status,
  } = props;

  const classes = useStyles();

  let allInvoiceCount = 0;

  for (const statusName in filterBadgeCountObj) {
    if (Object.hasOwnProperty.call(filterBadgeCountObj, statusName)) {
      const count = filterBadgeCountObj[statusName];
      allInvoiceCount += count;
    }
  }

  const allSelected = status.every(s => s.selected);
  return (
    <div className={classes.styleRow}>
      <ColorPill
        color={'primary'}
        label={'All Invoices'}
        pendoTag={'all_invoices'}
        selected={allSelected}
        onClick={() => onAllInvoiceFilterSelect()}
        count={allInvoiceCount}
      />
      {status.map(s => {
        return (
          <ColorPill
            key={`${s.value}-${s.index}`}
            color={s.pillColor}
            label={s.label}
            pendoTag={s.value}
            selected={s.selected}
            count={filterBadgeCountObj[s.countLabel] || 0}
            onClick={() => onStatusFilterSelect(s.index)}
          />
        );
      })}
    </div>
  );
};

SearchTableFilter.propTypes = {
  onStatusFilterSelect: PropTypes.func.isRequired,
  onAllInvoiceFilterSelect: PropTypes.func.isRequired,
  filterBadgeCountObj: PropTypes.object.isRequired,
  status: PropTypes.array.isRequired,
};

export default SearchTableFilter;
