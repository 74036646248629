import { push } from 'redux-first-history';
import { all, call, put, takeEvery, delay } from 'redux-saga/effects';
import camelCase from 'camelcase-keys';
// actions
import { authReady } from 'actions/appReady';
import { calculate } from 'actions/bulkEdit';
import * as actions from 'actions/authenticate';
import { store as setCurrentUser } from 'actions/session';
import { RESET_APP_ALL } from 'providers/store/index';

export function* authenticate(auth, api, params) {
  try {
    const token = yield call(auth.accessToken);
    const { status, data } = yield call(auth.checkAuthentication, token);
    const isAdmin = u => u.isAdmin || u.isSuperAdmin;
    const hasEmployeeRoleOnly = roles =>
      roles.every(
        r => r.localeCompare('employee', { sensitivity: 'base' }) === 0,
      );
    const isNotEmployee = u =>
      isAdmin(u) || !hasEmployeeRoleOnly(u.projectRoles);
    const canReceiveNotifications = u => isNotEmployee(u);

    if (status === 200 && data) {
      const currentUser = camelCase(data);
      const impersonateObj = {
        isImpersonated: currentUser?.impersonating,
        isCurrentUserAdmin: currentUser?.isAdmin,
      };
      sessionStorage.setItem(
        'pendo_impersonate',
        JSON.stringify(impersonateObj),
      );
      yield put(setCurrentUser({ currentUser }));
      yield put(actions.success());

      if (canReceiveNotifications(currentUser)) {
        yield call(api.notifications.connect);
      }

      yield put(authReady());

      if (params && params.redirectURI) {
        yield delay(500); //minor delay so this redirect happens properly
        yield put(push(params.redirectURI));
      }
    } else if (status === 302 || status === 401) {
      yield put(actions.failure({ error: 'Permission denied' }));
    } else {
      // This is likely due to cors issue from the 302
      yield put(actions.failure({ error: 'Not logged in' }));
    }
  } catch (e) {
    // Catch when user is not okta logged in
    yield put(actions.failure({ error: 'Not Okta logged in' }));
    // eslint-disable-next-line no-console
    console.log('CheckAuthentication saga catch:', e);
  }
}

export function* logout(auth, api) {
  try {
    yield put(calculate());
    yield call(api.session.revokeToken);
    yield call(api.notifications.disconnect);
    yield put(RESET_APP_ALL());
    yield call(auth.cncLogout(true));
  } catch (e) {
    //Sentry.captureException(e);
  }
}

export function* checkAuthentication(auth, api, params) {
  try {
    const token = yield call(auth.accessToken);
    yield call(auth.checkAuthentication, token);
  } catch {}
}

export default function* authenticationFlow({ auth, api, history }) {
  yield all([
    takeEvery(`${actions.authenticate}`, authenticate, auth, api),
    takeEvery(`${actions.logout}`, logout, auth, api),
    takeEvery(`${actions.checkAuthentication}`, checkAuthentication, auth, api),
  ]);
}
