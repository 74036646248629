import createAction from './createAction';

export const loading = createAction('loading/flags');
export const fetch = createAction('fetch/flags');
export const store = createAction('store/flags');
export const update = createAction('update/flags');
export const edit = createAction('edit/flag');
export const editAllowanceTypes = createAction('edit/allowanceTypes');

export const toggleTheme = createAction('toggleTheme/flag');
