import { buildUrl } from '../api';

//move URLs
const URL_DESTINATION_INVOICES =
  '/projects/:projectId/move/batches/:batchId/invoices';
const URL_DESTINATION_BATCHES =
  '/projects/:projectId/move/batches/:batchId/batches';

const URL_MOVE_TIMECARDS = '/projects/:projectId/move';
const URL_MOVE_BATCH_INFO = '/projects/:projectId/move/batches/:batchId';

const moveTimecards = ({ clientV2 }) => ({
  async moveTimecards({ projectId, data }) {
    const URL = URL_MOVE_TIMECARDS;
    const url = buildUrl(URL, { projectId });
    const rsp = await clientV2.post(url, data);
    return rsp.data;
  },
  async fetchBatchInfo({ projectId, batchId }) {
    const URL = URL_MOVE_BATCH_INFO;
    const url = buildUrl(URL, { projectId, batchId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },
  async destinationInvoices({ projectId, batchId }) {
    const URL = URL_DESTINATION_INVOICES;
    const url = buildUrl(URL, { projectId, batchId });
    const response = await clientV2.get(url);
    return response.data;
  },
  async destinationBatches({ projectId, batchId }) {
    const URL = URL_DESTINATION_BATCHES;
    const url = buildUrl(URL, { projectId, batchId });
    const response = await clientV2.get(url);
    return response.data;
  },
});

export default moveTimecards;
