import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'decorators/withRouter';
import { LinearProgress } from '@mui/material';

import { Listing } from 'components/Employees/Reviews/Reviewer';
//actions
import {
  fetchReviewBatches,
  reviewTimecard,
  fetchComments,
  toggleShowPaid,
  fetchTimecardCount,
  fetchApprovalFlows,
  reviewDownloadTimecardsReport,
} from 'actions/reviews';
import {
  fetchWeekendings,
  setFromURL,
  setCurrentTimecard,
} from 'actions/timecards';
import { show as showModal } from 'actions/modalDialog';
import { fetchDetails } from 'actions/project';
//selector
import {
  reviewBatchesByReviewType,
  getLoading,
  getTimecardLoadingByBatchID,
  isShowingPaid,
} from 'selectors/reviews';
import { getWeekendings } from 'selectors/timecards';
import { currentUser } from 'selectors/session';
import AddCommentModal from './Modals/AddComment';
import { getProjectDetails } from 'selectors/project';
import { BATCH_REVIEW_DEFAULT_PAGE_SIZE } from 'components/Shared/constants';
const pageSize = BATCH_REVIEW_DEFAULT_PAGE_SIZE;
const reviewType = 'history';

const mapState = (state, { match }) => ({
  projectId: match.params.projectId,
  projectDetails: getProjectDetails(state),
  batches: reviewBatchesByReviewType(reviewType)(state),
  activeUser: currentUser(state),
  weekendings: getWeekendings(state),
  isLoading: getLoading(state),
  reviewType,
  timecardLoadingByBatch: getTimecardLoadingByBatchID(state),
  showPaid: isShowingPaid(state),
});

const mapDispatch = (dispatch, { location }) => ({
  onFetchData: () => {
    dispatch(fetchReviewBatches({ reviewType }));
    dispatch(fetchWeekendings());
  },
  onViewTimecard: (_batch, record) => {
    dispatch(setFromURL({ fromURI: location.pathname }));
    dispatch(setCurrentTimecard({ timecardId: record.timecardId }));
    dispatch(reviewTimecard({ timecardId: record.timecardId, reviewType }));
  },
  onDownloadTimecardsReport: ({ timecards, batchId, htgId, fetchBatch }) => {
    dispatch(
      reviewDownloadTimecardsReport({
        timecards,
        batchId,
        htgId,
        fetchBatch,
        reviewType,
      }),
    );
  },
  refreshData: (page, size) => {
    dispatch(fetchReviewBatches({ reviewType, page, size }));
  },
  displayComment: record => {
    dispatch(fetchComments({ timecardEntryHeaderId: record.entryHeaderId }));
    dispatch(setCurrentTimecard({ timecardId: record.entryHeaderId }));
    dispatch(
      showModal({
        dialog: 'AddComment',
        modalParams: { status: record.status },
      }),
    );
  },
  getProjectData: () => {
    dispatch(fetchDetails());
  },
  onTogglePaid: () => {
    dispatch(toggleShowPaid());
    dispatch(fetchReviewBatches({ reviewType }));
    dispatch(fetchWeekendings());
    dispatch(fetchTimecardCount({ reviewTypes: ['processed'] }));
  },
  onFetchBatchTimecards: batchId => {
    const reviewBatches = [{ id: batchId }];
    dispatch(fetchApprovalFlows({ reviewType, reviewBatches }));
  },
});

// DH - Ready for Review
class History extends Component {
  state = {
    page: 0,
    pageSize: pageSize,
  };

  componentDidMount() {
    this.props.onFetchData();
    const projectDetails = this.props.projectDetails;
    if (
      !projectDetails ||
      !projectDetails.hasOwnProperty('nextYearProjectId') ||
      !projectDetails.hasOwnProperty('previousYearProjectId')
    ) {
      // Not sure exactly what this was solving, but nextYearProjectId doesn't work like it did pre-YEP21/22
      // leaving this in, but with this debug log.  Reviews page hopefully wont be around much longer.
      console.debug('refetching project data');
      this.props.getProjectData();
    }
  }

  onRefreshPage(page, pageSize) {
    this.setState({
      page,
      pageSize,
    });

    this.props.refreshData(page, pageSize);
  }

  render() {
    const activeUser = this.props.activeUser;

    if (this.props.isLoading) {
      return <LinearProgress />;
    }
    return (
      <React.Fragment>
        <Listing
          {...this.props}
          role={activeUser.role}
          page={this.state.page}
          pageSize={this.state.pageSize}
          onRefreshPage={this.onRefreshPage.bind(this)}
        />
        <AddCommentModal />
      </React.Fragment>
    );
  }
}
export default withRouter(connect(mapState, mapDispatch)(History));
