import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import pluralize from 'pluralize';
import Typography from '@mui/material/Typography';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
import { timecardsProps } from 'components/props/timecard';
// containers
import ExistingTimecard from './ExistingTimecard';
// actions
import { createTimecard, loadingInitialize } from 'actions/timecards';
import { hide as hideMe } from 'actions/modalDialog';
// selectors
import { existingTimecards, getLoading } from 'selectors/timecards';
import { getLoadingDetails } from 'selectors/timecard/common';
import { getFormValues } from 'selectors/formValues';
const formName = 'confirmExisting';

const mapStateToProps = state => ({
  loading: getLoading(state),
  loadingExistingTC: getLoadingDetails(state),
  existingTimecards: existingTimecards('createTimecard')(state),
  timecard: getFormValues('createTimecard')(state),
});

const mapDispatchToProps = dispatch => ({
  onCreateTimecard: () => {
    dispatch(createTimecard());
    dispatch(hideMe({ dialog: 'confirmExisting' }));
    dispatch(loadingInitialize({ loading: true }));
    setTimeout(() => {
      dispatch(loadingInitialize({ loading: false }));
    }, 6000);
  },
});

const onSubmit = (values, dispatch) => {
  dispatch(createTimecard());
  dispatch(loadingInitialize({ loading: true }));
  setTimeout(() => {
    dispatch(loadingInitialize({ loading: false }));
  }, 6000);
};

class ConfirmExisting extends React.Component {
  static propTypes = {
    existingTimecards: timecardsProps.isRequired,
    onCreateTimecard: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { loading, existingTimecards } = this.props;

    if (!loading && existingTimecards.length === 0) {
      this.props.onCreateTimecard();
    }
  }

  renderContent() {
    const { existingTimecards, timecard } = this.props;

    return (
      <div>
        <Typography color="inherit">
          <b>{pluralize('timecard', existingTimecards.length, true)} </b>
          already {pluralize('exist', existingTimecards.length)} for week ending{' '}
          <b> {timecard.weekEnding}</b>. <br />
          Click on one below to <b>finish</b> or
          <b> click the create new timecard button</b>.
        </Typography>
        <div
          style={{ display: 'flex', flexDirection: 'column', paddingTop: 10 }}
        >
          {existingTimecards.map(tc => (
            <ExistingTimecard
              key={tc.timecardId}
              timecardId={tc.timecardId}
              weekEnding={timecard.weekEnding}
            />
          ))}
        </div>
      </div>
    );
  }

  render() {
    const {
      classes,
      onCreateTimecard,
      handleSubmit,
      loadingExistingTC,
      ...others
    } = this.props;

    const title = (
      <Typography variant="h6" color="inherit">
        Heads Up!
      </Typography>
    );

    return (
      <form onSubmit={handleSubmit}>
        <ConfirmModal
          content={this.renderContent()}
          title={title}
          buttonText="Create New Timecard"
          submitDisabled={loadingExistingTC}
          raisedButton
          {...others}
        />
      </form>
    );
  }
}

export default compose(
  withModalDialog({ dialog: 'confirmExisting', maxWidth: 'md' }),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    onSubmit,
  }),
)(ConfirmExisting);
