import { default as List } from './List';
import { default as Create } from './Create';
import { default as TeamTimecard } from './TeamTimecard';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  List,
  Create,
  TeamTimecard,
};
