import _ from 'lodash';
import { setGlobalDevModeChecks } from 'reselect';

/**
 * Reselect has some development-only checks that help you avoid common mistakes.
 * but until we fix them, we'll disable them globally here.
 */
//https://reselect.js.org/api/development-only-stability-checks/
setGlobalDevModeChecks({
  identityFunctionCheck: 'once',
  inputStabilityCheck: 'once',
});

export const getFlagsReady = state => _.get(state, 'appReady.flagsReady');
