import React from 'react';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const styles = ({ palette }) => ({
  errorBox: {
    display: 'flex',
    marginLeft: 24,
  },
  problemIcon: {
    height: '1.4em',
    color: palette.error.main,
  },
  errorText: {
    marginLeft: 8,
    color: palette.error.main,
    fontWeight: 500,
  },
  errorButton: {
    color: palette.info.main,
    fontWeight: 400,
  },
});

const ErrorsBar = props => {
  const {
    classes,
    showErrors,
    setShowErrors,
    // tableData,
    totalErrorsCount,
  } = props;

  if (!totalErrorsCount && !showErrors) return null;
  return (
    <div className={classes.errorBox}>
      <ReportProblemIcon className={classes.problemIcon} />
      <p className={classes.errorText}>
        {totalErrorsCount > 0 ? totalErrorsCount : 0} error(s) have been
        detected
      </p>
      {showErrors ? (
        <Button
          variant="text"
          className={classes.errorButton}
          onClick={() => {
            setShowErrors(false);
          }}
        >
          Show All Timecards Days
        </Button>
      ) : (
        <Button
          variant="text"
          className={classes.errorButton}
          onClick={() => {
            setShowErrors(true);
          }}
        >
          Show Only Errors
        </Button>
      )}
    </div>
  );
  // )}
};

export default withStyles(styles)(ErrorsBar);
