import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, styled } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { accountCodeSummaryHeaders } from 'feature/DigitalEdits/digitalEditsUtils';
import DataTableDigitalEdits from './DataTableDigitalEdits';
import {
  getDataCardVisible,
  getHasPendingTimecards,
} from 'selectors/digitalEdits';

const MsgBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '3px',
  marginLeft: '16px',
  fontSize: '16px',
  fontWeight: 500,
}));

const WarningMsgBox = styled(MsgBox)(({ theme }) => ({
  color: theme.palette.warning.main,
}));

const TABLE_NAME = 'Account Code Summary';
const mapState = (state, ownProps) => ({
  viewEnabled: getDataCardVisible(state, TABLE_NAME),
  hasPendingTimecard: getHasPendingTimecards(state, ownProps.recordId),
});

const AccountCodeSummary = props => {
  const { data, viewEnabled = true, hasPendingTimecard } = props;

  const title = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {TABLE_NAME}
      {hasPendingTimecard && (
        <WarningMsgBox>
          <ErrorOutlineIcon color={'warning'} />
          Pending timecard calculation
        </WarningMsgBox>
      )}
    </Box>
  );

  return (
    <DataTableDigitalEdits
      viewEnabled={viewEnabled}
      headers={accountCodeSummaryHeaders}
      data={data}
      title={title}
      loading={hasPendingTimecard}
    />
  );
};

AccountCodeSummary.propTypes = {
  data: PropTypes.array.isRequired,
  viewEnabled: PropTypes.bool,
  hasPendingTimecard: PropTypes.bool.isRequired,
};

export default connect(mapState)(AccountCodeSummary);
