import { produce } from 'immer';
import * as actions from 'actions/bulkEdit';
import { cloneDeep } from 'lodash';
import {
  BULK_EDIT_TIMECARD_STATUS,
  BULK_EDIT_TIMECARD_WEEKDAY,
  BULK_EDIT_DROPDOWN_FIELDS,
  // BULK_EDIT_TOO_MANY_GROUPS,
  // BULK_EDIT_SORT_ORDER,
} from 'components/Shared/constants';
import {
  parsedSortedDropdown,
  calculateDirtyFields,
  isObjectEmpty,
  ERROR_STATE_COLUMNS,
  BULK_EDIT_TIME_FIELDS,
  TableColumns,
} from 'utils/bulkEditUtils';
import { DEFAULT_STATUS_SORT } from 'components/Employees/Reviews/SearchTimecards/searchUtils';
import TimeValidator from 'utils/TimeValidator';

const initialState = {
  accountCodes: [],
  batches: [],
  batchId: null,
  calculateResults: {
    count: 0,
    successful: [],
    error: [],
  },
  count: 0,
  dirtyFields: {},
  lastSave: false,
  days: [],
  edits: {},
  editableDays: [],
  editedDays: [],
  dayTypes: [],
  departments: [],
  employees: [],
  episode: [], // table dropdown
  episodes: [], // filter dropdown
  episodeDropdown: [],
  filters: {
    accountCodes: [],
    batches: [],
    days: [],
    departments: [],
    employees: [],
    sets: [],
    statuses: [],
  },
  filterSelected: false,
  initialLoad: true,
  loading: false,
  pendingCalculation: false,
  prospectiveTimecard: {},
  saving: false,
  saveResults: {
    count: 0,
    successful: [],
    error: [],
  },
  searching: false,
  searchFilters: {
    accountCodes: '',
    batches: '',
    departments: '',
    employees: '',
    episodes: '',
    sets: '',
  },
  sets: [],
  sortOrder: [],
  status: cloneDeep(BULK_EDIT_TIMECARD_STATUS),
  tableData: [],
  tableDropdown: {
    loading: false,
    dropdownType: '',
    params: {},
    options: [],
  },
  tcBeingSaved: [],
  tcToBeCalculated: [],
  tcBeingCalculated: [],
  tcSelectedCount: 0,
  weekEnding: null,
  weekEndingCount: 0,
  weekEndings: [],
  weekDay: cloneDeep(BULK_EDIT_TIMECARD_WEEKDAY),
  workLocations: [],
  schedule: [],
  resultsWE: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    let selected, columnId;
    switch (action.type) {
      case `${actions.setDirtyFields}`:
        const {
          paths: [timecardEntryHeaderId, date, column],
          value: inputValue,
          fieldToCompare = '',
        } = action;
        columnId = column; // column is columnId

        const columnMeta = TableColumns.find(c => c.id === columnId);

        let isCheckbox = !!columnMeta.isCheckbox;

        const matchedRow = draft.editableDays
          .find(
            timecard =>
              timecardEntryHeaderId === timecard.timecardEntryHeaderId,
          )
          .details.find(weekDate => date === weekDate.effectiveDate);

        const originalValue = isCheckbox
          ? !!matchedRow[column]
          : matchedRow[column];

        const { dirtyFields, touched } = calculateDirtyFields(
          [timecardEntryHeaderId, date, column], // paths
          draft.dirtyFields,
          { originalValue, inputValue, fieldToCompare },
        );
        if (touched) {
          draft.dirtyFields = dirtyFields;
        }

        break;

      case `${actions.copyToAllDirtyFields}`:
        const { value, showErrors, tableData = [] } = action;
        columnId = action.columnId;
        const isDropdown = BULK_EDIT_DROPDOWN_FIELDS.includes(columnId);
        const isTimeField = BULK_EDIT_TIME_FIELDS.includes(columnId);
        const copyToErrorRowsOnly = showErrors;
        const updatedDirtyFields = draft.editableDays.reduce(
          (newDirtyFields, currentTC) => {
            const {
              timecardEntryHeaderId: timecardId,
              details: tcDaysDetail = [],
            } = currentTC;

            newDirtyFields[timecardId] = tcDaysDetail.reduce(
              (weekDayDirtyFields, weekDay) => {
                const { effectiveDate, errors: originErr, dealMemo } = weekDay;
                const rounding = dealMemo.rounding;
                const tv = isTimeField ? new TimeValidator(rounding) : null;
                let matchedTableRow = { errors: [], validationErrors: [] };
                let rowHasValidErrors = false;

                if (copyToErrorRowsOnly) {
                  matchedTableRow = tableData
                    .filter(rowData => {
                      return rowData.timecardEntryHeaderId === timecardId;
                    })
                    .find(day => {
                      return day.effectiveDate === effectiveDate;
                    }) || { errors: [], validationErrors: [] };

                  rowHasValidErrors =
                    originErr.filter(
                      err =>
                        ERROR_STATE_COLUMNS.includes(err.field) &&
                        err.level === 'Error',
                    ).length > 0 ||
                    matchedTableRow.errors.length > 0 ||
                    matchedTableRow.validationErrors.length > 0;
                }

                const originalValue = isDropdown
                  ? weekDay[columnId] && `${weekDay[columnId].id}`
                  : `${weekDay[columnId]}`;
                const inputValue = isDropdown
                  ? value && `${value.id}`
                  : isTimeField
                  ? `${tv.parse(`${value}`) || undefined}`
                  : `${value || undefined}`;

                const currentDirtyFields =
                  draft?.dirtyFields?.[timecardId]?.[effectiveDate] || null;

                if (copyToErrorRowsOnly && !rowHasValidErrors) {
                  if (
                    currentDirtyFields &&
                    isObjectEmpty(currentDirtyFields) === false
                  ) {
                    weekDayDirtyFields[effectiveDate] = {
                      ...currentDirtyFields,
                    };
                  }
                  return weekDayDirtyFields;
                }

                if (inputValue !== originalValue) {
                  if (currentDirtyFields === null) {
                    weekDayDirtyFields[effectiveDate] = {
                      [columnId]: isTimeField ? inputValue : value, // inputValue
                    };
                  } else {
                    weekDayDirtyFields[effectiveDate] = {
                      ...currentDirtyFields,
                      [columnId]: isTimeField ? inputValue : value, // inputValue
                    };
                  }
                } else if (inputValue === originalValue) {
                  if (currentDirtyFields === null) {
                    // do nothing
                  } else {
                    if (
                      currentDirtyFields &&
                      currentDirtyFields.hasOwnProperty(columnId)
                    ) {
                      delete currentDirtyFields[columnId];
                    }
                    if (
                      currentDirtyFields &&
                      isObjectEmpty(currentDirtyFields) === false
                    ) {
                      weekDayDirtyFields[effectiveDate] = {
                        ...currentDirtyFields,
                      };
                    }
                  }
                }

                return weekDayDirtyFields;
              },
              {},
            );
            if (isObjectEmpty(newDirtyFields[timecardId])) {
              delete newDirtyFields[timecardId];
            }
            return newDirtyFields;
          },
          {},
        );
        draft.dirtyFields = updatedDirtyFields;
        break;
      case `${actions.resetDirtyFields}`:
        draft.dirtyFields = {};
        break;
      case `${actions.loading}`:
        draft.loading = action.loading;
        break;

      case `${actions.saving}`:
        draft.saving = action.saving;
        draft.pendingCalculation = true;
        break;
      case `${actions.calculating}`:
        draft.calculating = action.calculating;
        draft.pendingCalculation = false;
        break;

      case `${actions.searching}`:
        draft.searching = action.searching;
        break;

      case `${actions.setInitBatch}`:
        draft.batchId = action.batchId;
        draft.weekEnding = action.weekEnding;
        draft.days = [];
        break;

      case `${actions.store}`:
        draft.days = action.days;
        draft.count = action.count;
        draft.editableDays = action.days.filter(
          timecard => timecard.userFriendlyTimecardStatus === 'Ready for me',
        );
        break;

      case `${actions.storeBatchId}`:
        draft.batchId = action.batchId;
        break;

      case `${actions.storeWeekEnding}`:
        draft.weekEnding = action.weekEnding;
        break;

      case `${actions.storeWeekEndings}`:
        draft.weekEndings = action.weekEndings;
        break;
      case `${actions.storeDepartments}`:
        if (action.departments.length > 0) {
          window.department = action.departments;
          const departmentsList = action.departments
            .sort((option1, option2) => {
              if (option1.name > option2.name) return 1;
              if (option1.name < option2.name) return -1;
              return 0;
            })
            .map((department, i) => ({
              label: department.name,
              value: department.id,
              selected: false,
              index: i,
              tcCount: department.numTimecards,
            }));

          draft.departments = departmentsList;
        }
        break;
      case `${actions.storeBatches}`:
        if (action.batches.length > 0) {
          const batchList = action.batches.map((batch, i) => {
            if (batch.id === draft.batchId) {
              return {
                label: batch.name,
                value: batch.name,
                id: batch.id,
                selected: true,
                index: i,
                tcCount: batch.numTimecards,
              };
            } else {
              return {
                label: batch.name,
                value: batch.name,
                id: batch.id,
                selected: false,
                index: i,
                tcCount: batch.numTimecards,
              };
            }
          });
          draft.batches = batchList;
        } else {
          draft.batches = [];
        }
        break;
      case `${actions.storeDropdown}`:
        const { data, dropdownType, params } = action;

        if (dropdownType === 'schedule' || dropdownType === 'episode') {
          //work schedule and episode
          if (dropdownType === 'episode') {
            draft[dropdownType] = parsedSortedDropdown(data);
          } else {
            draft[dropdownType] = parsedSortedDropdown(data, true);
          }
        } else {
          // Work Loc and DayType
          const options = parsedSortedDropdown(data, true);
          draft.tableDropdown = {
            dropdownType,
            params,
            options,
          };
        }
        break;
      case `${actions.loadingDropdown}`:
        draft.tableDropdown.loading = action.loading;
        break;
      case `${actions.setSearchFilter}`:
        draft.searchFilters[action.filterName] = action.value;
        break;
      /**
       * Handle Set +Select + Select all checkbox for bulk filters
       * Select 1 option:
       * @param {int} action.index - index in filter array of checkbox
       * @param {string} action.listName - name of filter
       *
       * Select All:
       * @param {string} action.listName - name of filter
       *
       * Set value
       * @param {bool} action.value - value of filter
       * @param {string} action.option - name of filter
       */
      case `${actions.onSelect}`:
        //select 1 filter
        if (action.hasOwnProperty('index')) {
          selected = !draft[action.listName][action.index].selected;
          draft[action.listName][action.index].selected = selected;
        } else if (action.option && action.hasOwnProperty('value')) {
          // set specific filter by name
          if (state[action.listName].find(opt => opt.value === action.option)) {
            draft[action.listName].find(
              opt => opt.value === action.option,
            ).selected = action.value;
          }
        } else {
          //select all - de-select all handled elsewhere
          draft[action.listName].forEach(option => {
            if (!option.selected) option.selected = true;
          });
        }
        if (state.filterSelected === false) {
          draft.filterSelected = true;
        }
        break;

      case `${actions.clearFilter}`:
        draft[action.filterName].forEach(option => {
          if (option.selected) option.selected = false;
        });
        draft.filters[action.filterName] = [];
        break;
      case `${actions.storeEmployees}`:
        if (action.users.length > 0) {
          const userList = action.users.map((user, i) => ({
            label: user.name,
            value: user.name,
            id: user.id,
            selected: false,
            index: i,
            tcCount: user.numTimecards,
          }));

          draft.employees = userList;
        }
        break;
      case `${actions.storeAccountCodes}`:
        if (action.accountCodes.length > 0) {
          const accountCodeList = action.accountCodes.map((accountCode, i) => ({
            label: accountCode.name,
            value: accountCode.name,
            id: accountCode.id,
            selected: accountCode.selected || false,
            index: i,
            tcCount: accountCode.numTimecards,
          }));

          draft.accountCodes = accountCodeList;
        }
        break;
      case `${actions.storeEpisodes}`:
        if (action.episodes.length > 0) {
          const episodeList = action.episodes.map((episode, i) => ({
            label: episode.name,
            value: episode.name,
            id: episode.id,
            selected: episode.selected || false,
            index: i,
            tcCount: episode.numTimecards,
          }));

          draft.episodes = episodeList;
        }
        break;
      case `${actions.storeSets}`:
        if (action.sets.length > 0) {
          const setList = action.sets.map((set, i) => ({
            label: set.name,
            value: set.name,
            id: set.id,
            selected: set.selected || false,
            index: i,
            tcCount: set.numTimecards,
          }));

          draft.sets = setList;
        }
        break;
      case `${actions.storeWeekdays}`:
        if (action.weekdays.length > 0) {
          const weekdaysList = action.weekdays.map((weekDay, i) => ({
            label: weekDay.name,
            value: weekDay.name,
            id: weekDay.id,
            selected: weekDay.selected || false,
            index: i,
            tcCount: weekDay.numTimecards,
          }));

          draft.weekDay = weekdaysList;
        }
        break;
      case `${actions.storeStatuses}`:
        if (action.statuses.length > 0) {
          let sortedStatuses = action.statuses.sort((a, b) => {
            return (
              DEFAULT_STATUS_SORT.indexOf(a.name) -
              DEFAULT_STATUS_SORT.indexOf(b.name)
            );
          });
          const statusesList = sortedStatuses.map((status, i) => ({
            label: status.name,
            value: status.name,
            id: status.id,
            selected: status.selected || false,
            index: i,
            tcCount: status.numTimecards,
          }));

          draft.status = statusesList;
        }
        break;
      case `${actions.clearInitBatchId}`:
        draft.batchId = null;
        break;
      case `${actions.storeSortOrder}`:
        draft.sortOrder = action.sortOrder;
        break;
      case `${actions.storeEditedDays}`:
        draft.editedDays = action.editedDays;
        break;
      case `${actions.storeEdits}`:
        draft.edits = action.edits;
        break;
      case `${actions.clearEditedDays}`:
        draft.editedDays = [];
        break;
      case `${actions.clearEdits}`:
        draft.edits = {};
        break;
      case `${actions.storeTcBeingSaved}`:
        draft.tcBeingSaved = action.tcBeingSaved;
        break;
      case `${actions.storeTcBeingCalculated}`:
        draft.tcBeingCalculated = action.tcBeingCalculated;
        break;
      case `${actions.storeTcToBeCalculated}`:
        draft.tcToBeCalculated = action.tcToBeCalculated;
        break;
      case `${actions.clearTcToCalculate}`:
        draft.tcToBeCalculated = [];
        break;
      case `${actions.setInitLoad}`:
        draft.initialLoad = false;
        break;
      case `${actions.storeSaveResults}`:
        draft.saveResults = action.saveResults;
        break;
      case `${actions.storeCalculateResults}`:
        draft.calculateResults = action.calculateResults;
        break;
      case `${actions.clearSaveResults}`:
        draft.saveResults = {
          count: 0,
          successful: [],
          error: [],
        };
        break;
      case `${actions.clearCalculateResults}`:
        draft.calculateResults = {
          count: 0,
          successful: [],
          error: [],
        };
        break;
      case `${actions.setLastSaveAfterFetch}`:
        draft.lastSave = action.clickSave;
        break;
      case `${actions.storeWECount}`:
        draft.weekEndingCount = action.count;
        break;
      case `${actions.storeSearchCount}`:
        draft.tcSelectedCount = action.count;
        break;
      case `${actions.storeResultsWE}`:
        draft.resultsWE = action.weekEnding;
        break;
      case `${actions.resetFilterSelected}`:
        draft.filterSelected = false;
        break;
      case `${actions.filterSelected}`:
        if (state.filterSelected === false) {
          draft.filterSelected = true;
        }
        break;
      case `${actions.setProspectiveTimecard}`:
        draft.prospectiveTimecard = action.prospectiveTimecard;
        break;
      case `${actions.reset}`:
        if (Object.keys(draft).length !== Object.keys(initialState).length) {
          console.warn(
            'Length of draft and initialState are not equal\n',
            'draft',
            Object.keys(draft),
            '\ninitialState',
            Object.keys(initialState),
          );
        }
        for (const key in initialState) {
          if (Object.hasOwnProperty.call(initialState, key)) {
            draft[key] = initialState[key];
          }
        }
        break;
      default:
        break;
    }
  });
