import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// components
import { DepartmentHead } from 'components/Admin/Projects/DepartmentApprovers';
import DepartmentApproveRemove from './Modals/DepartmentApproveRemove';
// actions
import * as modal from 'actions/modalDialog';
import {
  setHeadApproval,
  setHeadViewGross,
  setPaDeleteTimecards,
} from 'actions/reviewFlows';
// selectors
import { getProjectDetails } from 'selectors/project';

const mapStateToProps = state => ({
  enableToggle: getProjectDetails(state).departmentApprove,
  enableViewGross: getProjectDetails(state).dhGrossEnabled,
  enablePaDeleteTimecards: getProjectDetails(state).paDeleteTimecards,
});

const mapDispatchToProps = dispatch => ({
  onConfirmRemove: () =>
    dispatch(modal.show({ dialog: 'departmentHeadApproval' })),
  onEnableApproval: () => dispatch(setHeadApproval({ enabled: true })),
  onToggleViewGross: checked =>
    dispatch(setHeadViewGross({ enabled: checked })),
  onTogglePaDeleteTimecards: checked =>
    dispatch(setPaDeleteTimecards({ enabled: checked })),
  onDisableApproval: () => {
    dispatch(setHeadApproval({ enabled: false }));
    dispatch(modal.hide({ dialog: 'departmentHeadApproval' }));
  },
});

// Separated this out as we need to add the modal dialogue in future.
class DepartmentHeadApproval extends Component {
  static propTypes = {
    enableToggle: PropTypes.bool,
    enableViewGross: PropTypes.bool,
    enablePaDeleteTimecards: PropTypes.bool,
    onDisableApproval: PropTypes.func.isRequired,
    onEnableApproval: PropTypes.func.isRequired,
    onConfirmRemove: PropTypes.func.isRequired,
  };

  static defaultProps = {
    enableToggle: false,
  };

  render() {
    const {
      enableToggle,
      enableViewGross,
      enablePaDeleteTimecards,
      onConfirmRemove,
      onEnableApproval,
      onDisableApproval,
      onToggleViewGross,
      onTogglePaDeleteTimecards,
    } = this.props;

    return (
      <div>
        <DepartmentHead
          enableToggle={enableToggle}
          onToggle={onEnableApproval}
          onConfirmRemove={onConfirmRemove}
          onToggleViewGross={onToggleViewGross}
          onTogglePaDeleteTimecards={onTogglePaDeleteTimecards}
          viewGross={enableViewGross}
          paDeleteTimecards={enablePaDeleteTimecards}
        />
        <DepartmentApproveRemove onToggle={onDisableApproval} />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DepartmentHeadApproval);
