import React from 'react';
import { Checkbox, Box } from '@mui/material';

//Components
import { AutoComplete } from 'components/Shared/AutoComplete';

//actions
import { generateTimes } from 'utils/weekUtils';

export default function NotificationMobileRow(props) {
  const { member, preference, onSelectIndividual } = props;
  // Delivery Time
  const deliveryTimeRows = generateTimes(60);

  //Delivery Type
  const deliveryTypeRows = [
    { label: 'Email', value: 'email' },
    { label: 'Text', value: 'text' },
    { label: 'Both', value: 'both' },
  ];
  let enableDeliveryOptions = !!preference?.schedulable;
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {preference?.description || ''}
        <Checkbox
          checked={preference.enabled}
          color="primary"
          onChange={e =>
            onSelectIndividual(
              e.target.checked,
              preference.description,
              'selectedSingle',
              preference.enabled,
            )
          }
        />
      </Box>
      <Box>
        {enableDeliveryOptions && (
          <Box>
            <AutoComplete
              list={deliveryTimeRows}
              name={`${member}.scheduleTime`}
              disabled={deliveryTimeRows.length === 0}
              isRequired={true}
            />
          </Box>
        )}
        {enableDeliveryOptions && <Box>{'Email'}</Box>}
        {!enableDeliveryOptions && (
          <Box>
            <AutoComplete
              list={deliveryTypeRows}
              name={`${member}.deliveryType`}
              disabled={!preference.enabled}
              isRequired={true}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
