import _ from 'lodash';
import { createSelector } from 'reselect';
import { getUsers as projectUsers } from 'selectors/users';
import { getFormValues } from './formValues';

export const getLoading = state => _.get(state, 'reviewFlows.loading', false);
export const getSaving = state => _.get(state, 'reviewFlows.saving', false);
export const getReviewFlows = state => _.get(state, 'reviewFlows.list', []);
export const getEditing = state => _.get(state, 'reviewFlows.editing', {});
export const getNewFlowId = state =>
  _.get(state, 'reviewFlows.newFlowId', null);
export const getDepartments = (state, reviewFlowId) =>
  _.get(state, `reviewFlows.departments[${reviewFlowId}]`, []);
export const getDepartmentStats = (state, id) =>
  _.get(state, `reviewFlows.departmentStats[${id}]`, {});
export const getSteps = (state, id) =>
  _.get(state, `reviewFlows.steps[${id}]`, []);
export const getStepUsers = (state, id, stepId) =>
  _.get(state, `reviewFlows.users[${id}][${stepId}]`, []);
export const getEditingUser = state =>
  _.get(state, 'reviewFlows.editingUser', {});
export const getDepartmentHeads = state =>
  _.get(state, `reviewFlows.editingDepartment.heads`, []);
export const getAllDepartments = state =>
  _.get(state, `reviewFlows.departments`, {});
export const getAllSteps = state => _.get(state, `reviewFlows.steps`, {});
export const getAllReviewers = state => _.get(state, `reviewFlows.users`, {});

export const getEditingDepartment = state => {
  const editing = _.get(state, 'reviewFlows.editingDepartment', {});
  const department = { ...editing };
  const heads =
    (department.heads &&
      department.heads.map(head => ({
        canSubmitEmergencyTimecard: head.canSubmitEmergencyTimecard,
        id: head.id,
        fullName: head.fullName,
      }))) ||
    [];
  return { ...editing, heads };
};

export const getUsers = createSelector(
  [projectUsers, getStepUsers],
  (userDetails, users) => {
    return users.map(user => ({
      ...userDetails.find(u => u.id === user.id),
      ...user,
    }));
  },
);

export const getEditingReviewers = state => {
  const editing = getEditingUser(state);
  const { reviewFlowId: id, stepId } = editing;
  const reviewers = getUsers(state, id, stepId).map(user => ({
    canSubmitEmergencyTimecard: user.canSubmitEmergencyTimecard,
    id: user.id,
    fullName: user.fullName,
  }));
  return { ...editing, reviewers };
};

export const getEditApprovalFlowForm = state => {
  return getFormValues('EditApprovalFlow')(state);
};

export const getDepartmentsWithHeads = createSelector(
  [getDepartments, projectUsers],
  (departments, project_users) => {
    const users = project_users.reduce((m, u) => {
      m[u.id] = [u.firstName, u.lastName].join(' ');
      return m;
    }, {});
    return departments.map(department => {
      const headNames = department.headApprovalEnabled
        ? department.heads.map(head => users[head.id]).join(', ')
        : '';
      return { ...department, headNames };
    });
  },
);

export const reviewFlowDepartments = state =>
  _.get(state, 'pages.admin.projects.reviewFlows.departments', []);

export const getCurrentApprovers = createSelector(
  [getAllDepartments, getReviewFlows, getAllSteps, getAllReviewers],
  (departments, flows, steps, reviewers) => {
    let deptHeads = [],
      payAccts = [],
      upm1s = [],
      upm2s = [],
      upm3s = [];
    const flowIds = flows.map(flow => flow.id);
    // collect all the dept head ids
    flowIds.forEach(flowId => {
      departments[flowId].forEach(dept => {
        if (dept.headApprovalEnabled) {
          dept.heads.forEach(head => {
            deptHeads.push(head.id);
          });
        }
      });
    });
    // collect all the PA and maybe UPMs
    flowIds.forEach(flowId => {
      let paFlowId, upm1Id, upm2Id, upm3Id;
      // get ids for each step
      steps[flowId].forEach(flowStep => {
        if (flowStep.role === 'payroll_accountant') {
          paFlowId = flowStep.id;
        } else if (flowStep.role === 'upm') {
          switch (flowStep.position) {
            case 1:
              upm1Id = flowStep.id;
              break;
            case 2:
              upm2Id = flowStep.id;
              break;
            case 3:
              upm3Id = flowStep.id;
              break;
            default:
              break;
          }
        }
      });

      // all reviewer id's to the proper arrays
      if (!!paFlowId && reviewers[flowId] && reviewers[flowId][paFlowId]) {
        reviewers[flowId][paFlowId].forEach(paReviewer => {
          payAccts.push(paReviewer.id);
        });
      }
      if (!!upm1Id && reviewers[flowId] && reviewers[flowId][upm1Id]) {
        reviewers[flowId][upm1Id].forEach(upm1Reviewer => {
          upm1s.push(upm1Reviewer.id);
        });
      }
      if (!!upm2Id && reviewers[flowId] && reviewers[flowId][upm2Id]) {
        reviewers[flowId][upm2Id].forEach(upm2Reviewer => {
          upm2s.push(upm2Reviewer.id);
        });
      }
      if (!!upm3Id && reviewers[flowId] && reviewers[flowId][upm3Id]) {
        reviewers[flowId][upm3Id].forEach(upm3Reviewer => {
          upm3s.push(upm3Reviewer.id);
        });
      }
    });
    // these lists of ID's is then used in order to determine which users to displays as
    // options for other review flows.
    // users are further filtered in the editReviewer.js comp
    return {
      deptHeads,
      payAccts,
      upm1s,
      upm2s,
      upm3s,
    };
  },
);

export const getReviewFlowLevels = state =>
  _.get(state, 'reviewFlows.levels', null);

export const getTimecardTemplates = state =>
  _.get(state, 'reviewFlows.timecardTemplateOptions', []);
