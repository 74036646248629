import React from 'react';
import { OnDemand } from 'containers/Employees/Reports';

import {
  DefaultLayout,
  Header,
  ProjectHeader,
  AlertHeader,
} from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';
import { Reports as SubNav } from 'containers/Nav/Pages';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    authenticated
    key="/projects/reports/ondemand"
    id="/projects/reports/ondemand"
    path="/projects/:projectId/reports/ondemand"
    layout={DefaultLayout}
    headers={[Header, ProjectHeader, AlertHeader, SubNav]}
    component={OnDemand}
    title="Reports"
  />
);
