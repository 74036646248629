import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Typography } from '@mui/material';
import { withStyleSheet } from 'shared/theme';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
// actions
import { deleteInvites } from '../actions';

// selectors
import { getEditingUsers } from '../selectors';

const onSubmit = (values, dispatch) => {
  dispatch(deleteInvites());
};

const mapStateToProps = state => ({
  users: getEditingUsers(state),
});

const mapDispatchToProps = dispatch => ({});

const style = () => ({
  content: {
    maxWidth: 500,
  },
});

class DeleteCrew extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { classes, handleSubmit, users } = this.props;
    const peopleCount = users.length;

    const content = (
      <div className={classes.content}>
        <p color="inherit">
          {`Deleted users cannot create, edit, approve, or reject timecards within this project.`}
        </p>
        <Typography color="inherit">
          {`Are you sure you want to delete ${peopleCount} ${
            peopleCount !== 1 ? 'people' : 'person'
          } from the list?`}
        </Typography>
      </div>
    );
    const title = (
      <Typography variant="h6" color="inherit">
        Confirm Deletion
      </Typography>
    );

    return (
      <form onSubmit={handleSubmit}>
        <ConfirmModal
          content={content}
          title={title}
          buttonText="Delete"
          raisedButton={true}
          danger={true}
          {...this.props}
        />
      </form>
    );
  }
}

export default compose(
  withStyleSheet('ConfirmModal', style),
  withModalDialog({ dialog: 'DeleteCrew', maxWidth: 'md' }),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'DeleteCrew',
    onSubmit,
  }),
)(DeleteCrew);
