import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { isDirty } from 'redux-form';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push } from 'redux-first-history';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import EastIcon from '@mui/icons-material/East';

import { show as showModal } from 'actions/modalDialog';

import * as sel from '../selectors';
import { TAB_NAV_MAP, TAB_NAV_MAP_REVERSE } from '../empTimecardUtils';
import {
  TIMECARD_INCOMPLETE,
  TIMECARD_PENDING_EMP_REVIEW,
} from 'components/Shared/constants';
import { UNSAVED_CHANGES_MODAL } from '../Modals/modalNames';

import { formValueSelector } from 'redux-form';
const useStyles = makeStyles(({ palette }) => ({
  overallBorder: {
    border: `solid 1px ${palette.gray['+8']}`,
    margin: '0px 5px 10px 10px',
    padding: '5px',
    paddingLeft: '0px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    borderRadius: '12px',
    paddingRight: '10px',
    cursor: 'pointer',
  },
  warningBorder: {
    border: `solid 1px ${palette.gray['+8']}`,
    padding: '3px',
    marginLeft: '5px',
    borderRadius: '12px',
    marginRight: '5px',
  },
  warningText: {
    marginLeft: '5px',
    display: 'flex',
    alignItems: 'center',
    marginRight: '5px',
  },
}));

const formSelector = formValueSelector(sel.FORM_NAME);
const mapState = state => ({
  allowanceTypes: sel.getAllowanceTypes(state),
  allowances: formSelector(state, 'allowances') || [],
  isFormDirty: isDirty(sel.FORM_NAME)(state),
  newComment: sel.getNewComment(state),
  status: sel.getTimecardStatus(state),
});
const mapDispatch = dispatch => ({
  onNavToAllowanceTab: url => dispatch(push(url)),
  onShowUnsavedModal: ({ oldTabName, newTabName, queryParams }) =>
    dispatch(
      showModal({
        dialog: UNSAVED_CHANGES_MODAL,
        modalParams: { oldTabName, newTabName, queryParams },
      }),
    ),
});

const AttachmentWarning = props => {
  const {
    allowances,
    allowanceTypes,
    onNavToAllowanceTab,
    isFormDirty,
    onShowUnsavedModal,
    newComment,
    status,
  } = props;
  const classes = useStyles();
  const [filesRequiredCount, setFilesRequiredCount] = React.useState(0);

  const location = useLocation();

  const isEditableStatus =
    status === TIMECARD_INCOMPLETE || status === TIMECARD_PENDING_EMP_REVIEW;

  React.useEffect(() => {
    let fileReqAllowances = allowances.map(allowance => {
      const allowanceType = allowanceTypes.find(
        a =>
          a.id === allowance?.htgAllowanceTypeId &&
          allowance.htgAllowanceTypeId &&
          allowance.toDelete !== true,
      );
      return {
        filename: allowance?.filename,
        employeeMandatory: allowanceType?.employeeMandatory,
      };
    });
    const newFileRequiredCount = fileReqAllowances.reduce((acc, allo) => {
      if (allo.employeeMandatory && !allo.filename) acc++;
      return acc;
    }, 0);
    setFilesRequiredCount(newFileRequiredCount);
  }, [allowances, allowanceTypes]);

  const navigate = () => {
    const hasComment = !!newComment;
    let queryParams = '';
    if (filesRequiredCount === 1) queryParams += '&openMissingDoc=true';

    const oldTabName = hasComment ? 'comments' : 'timecard';
    const newTabName = 'allowances';

    const isTimecard = oldTabName === TAB_NAV_MAP_REVERSE[TAB_NAV_MAP.timecard];
    const isComments = oldTabName === TAB_NAV_MAP_REVERSE[TAB_NAV_MAP.comments];

    if ((isFormDirty && isTimecard) || isComments) {
      onShowUnsavedModal({ newTabName, oldTabName, queryParams });
    } else {
      let url = `${location.pathname}?allowances${queryParams}`;
      onNavToAllowanceTab(url);
    }
  };

  if (!isEditableStatus) {
    return null;
  }

  if (filesRequiredCount === 0) {
    return null;
  }
  return (
    <Box sx={{ display: 'inline-block', cursor: 'pointer' }}>
      <Box className={classes.overallBorder} onClick={navigate}>
        <Box className={classes.warningBorder}>
          <Box className={classes.warningText}>
            <FiberManualRecordIcon
              fontSize="small"
              sx={{ color: 'warning.main' }}
            />
            Warning
          </Box>
        </Box>
        Attach the supporting documents for your allowances
        <span style={{ marginLeft: '5px', marginTop: '5px' }}>
          <EastIcon />
        </span>
      </Box>
    </Box>
  );
};

AttachmentWarning.propTypes = {
  allowances: PropTypes.array.isRequired,
  allowanceTypes: PropTypes.array.isRequired,
  onNavToAllowanceTab: PropTypes.func.isRequired,
  onShowUnsavedModal: PropTypes.func.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  newComment: PropTypes.string,
};

export default connect(mapState, mapDispatch)(AttachmentWarning);
