import React from 'react';
import Projects from 'containers/Admin/Projects';
import { Header, AlertHeader, FullWidthLayout } from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';
import { Projects as ProjectsNav } from 'containers/Nav/Pages';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    authenticated
    admin
    key="/admin/projects"
    path="/admin/projects"
    layout={FullWidthLayout}
    headers={[Header, AlertHeader, ProjectsNav]}
    component={Projects}
    title="Admin Projects"
  />
);
