import createAction from './createAction';

export const loading = createAction('invoiceTemplates/loading/batches');
export const fetchTemplates = createAction('invoiceTemplates/fetch/batches');
export const storeTemplates = createAction('invoiceTemplates/store/batches');
export const saveTemplate = createAction('invoiceTemplates/save/batches');
export const removeTemplate = createAction('invoiceTemplates/remove/batches');
export const editingTemplate = createAction('invoiceTemplates/editing/batches');
export const fetchSystemLocations = createAction(
  'invoiceTemplates/fetch/systemLocations',
);
export const storeSystemLocations = createAction(
  'invoiceTemplates/store/batches/systemLocations',
);

export const storeTemplateOption = createAction(
  'invoiceTemplates/create/templateOption',
);
export const fetchLocationTypes = createAction(
  'invoiceTemplates/fetch/locationType',
);
export const storeLocationTypes = createAction(
  'invoiceTemplates/store/locationType',
);
