import React from 'react';
// import clsx from 'clsx';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Button } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import {
  getLoading,
  getWeekEnding,
  // filteredDays,
  // getTimecardCount,
  getSelectedCount,
  getFilterSelected,
  getWECount,
} from 'selectors/bulkEdit';

const style = ({ palette }) => ({
  header: {
    top: 0,
  },
  title: {
    fontSize: 24,
    color: palette.text.secondary,
  },
  collapseAllLink: {
    fontSize: 14,
  },
  btns: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  directions: {
    fontSize: 14,
    color: palette.text.disabled,
  },
});

const mapState = state => ({
  loading: getLoading(state),
  selectedCount: getSelectedCount(state),
  weekEndingCount: getWECount(state),
  filterSelected: getFilterSelected(state),
  weekEnding: getWeekEnding(state),
});

const SidebarHeader = props => {
  const {
    classes,
    onFetch,
    toggleExpansion,
    expandAll,
    searching,
    apply,
    selectedCount,
  } = props;

  const disableBtn = selectedCount > 100 ? true : apply;
  return (
    <div className={classes.header}>
      <p className={classes.title}>Refine Your Search</p>
      <p className={classes.directions}>
        Use the filters below to customize your search results. Select apply to
        view changes.
      </p>
      <div className={classes.btns}>
        <Button
          className={classes.fetchBtn}
          color={'primary'}
          variant="contained"
          onClick={e => {
            e.stopPropagation();
            onFetch();
          }}
          key={'BEFetch'}
          disabled={disableBtn} //start disabled for now
        >
          {searching ? 'Searching' : 'Apply'}
        </Button>
        <Button
          variant="text"
          color="primary"
          className={classes.collapeAllButton}
          onClick={toggleExpansion}
        >
          <span className={classes.backToList}>
            {expandAll ? 'Collapse' : 'Expand'} All
          </span>
        </Button>
      </div>
    </div>
  );
};

export default compose(
  connect(
    mapState,
    // mapDispatch,
  ),
  withStyles(style),
)(SidebarHeader);
