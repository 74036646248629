import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Box, IconButton, LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

//redux
import * as actions from '../actions';
import { hide as closeModal } from 'actions/modalDialog';

import { getModalParams } from 'selectors/modalDialog';
import { getLoading } from '../selectors';

//local components
import {
  TitleBox,
  ContentBox,
  FooterBox,
  FooterButton,
} from '../Shared/styledComponents';

import { NEW_TC_ID } from '../empTimecardUtils';

import withModalDialog from 'decorators/withModalDialog';

import { DEL_TC_FROM_LIST } from './modalNames';

const mapState = state => ({
  modalParams: getModalParams(state, DEL_TC_FROM_LIST),
  deleting: getLoading(state, 'delete'),
});

const mapDispatch = dispatch => ({
  onCloseModal: () => dispatch(closeModal({ dialog: DEL_TC_FROM_LIST })),
  onConfirm: timecardId =>
    dispatch(actions.deleteTimecardFromList({ timecardId })),
});

const DeleteListModal = props => {
  const { onCloseModal, modalParams, onConfirm, deleting } = props;

  const { timecardId } = modalParams;

  const handleClose = () => {
    onCloseModal();
  };

  const handleConfirm = () => {
    onConfirm(timecardId);
  };

  return (
    <Box>
      {deleting && <LinearProgress />}
      <TitleBox>
        <Box>{`Delete Timecard`}</Box>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </TitleBox>
      <ContentBox sx={{ m: 3 }}>
        Are you sure you want to delete this timecard?
      </ContentBox>
      <FooterBox>
        <FooterButton
          variant="outlined"
          disabled={deleting}
          onClick={handleClose}
        >
          No, go back
        </FooterButton>
        <FooterButton
          className="PENDO_delete_timecard"
          disabled={deleting}
          onClick={handleConfirm}
        >
          Yes, Delete
        </FooterButton>
      </FooterBox>
    </Box>
  );
};

DeleteListModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  modalParams: PropTypes.shape({
    timecardId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.oneOf([NEW_TC_ID]),
    ]),
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired,
};

export default compose(
  withModalDialog({
    dialog: DEL_TC_FROM_LIST,
    maxWidth: 'md',
    roundedCorners: true,
  }),
  connect(mapState, mapDispatch),
)(DeleteListModal);
