import React from 'react';
import {
  FullWidthLayout,
  Header,
  AlertHeader,
  ProjectHeader,
} from 'containers/Layout';

import { EnhancedRoute } from 'containers/RoutingSupport';
import Timecard from 'feature/EmployeeTimecard/Timecard';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    authenticated
    key="/projects/me/timecard/:timecardId"
    id="/projects/me/timecard/:timecardId"
    path="/projects/:projectId/me/timecards/:timecardId"
    layout={FullWidthLayout}
    headers={[Header, ProjectHeader, AlertHeader]}
    component={Timecard}
    title="Timecard"
  />
);
