import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
//components
import { NavBar } from 'components/Shared/Nav';
//container
import NavItem from 'containers/Shared/NavItem';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';

//selectors
import { getIsProjectArchived } from 'selectors/project';

const style = () => ({
  root: {
    width: '100%',
    height: 64,
    lineHeight: '62px',
    textAlign: 'right',
  },
});
const mapStateToProps = (state, ownProps) => {
  return {
    isProjectArchived: getIsProjectArchived(state),
  };
};
class ProjectNav extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    isAdminUser: PropTypes.bool,
  };

  static defaultProps = {
    isAdminUser: false,
  };

  render() {
    const {
      classes,
      isAdminUser,
      project,
      isReviewer,
      isCrewTimecardRole,
      activeReviewTab,
      seeMyTimecards,
      isPA,
      isDH,
      isProjectArchived,
      isUPM,
      isIA,
    } = this.props;
    const tabItems = [];
    const reviewsSecondaryTabs = [
      `/projects/${project.id}/me/review`,
      `/projects/${project.id}/me/review/open`,
      `/projects/${project.id}/me/review/complete`,
      `/projects/${project.id}/me/review/batches/ready`,
      `/projects/${project.id}/me/review/batches/pending`,
      `/projects/${project.id}/me/review/batches/history`,
    ];

    if (isAdminUser) {
      const isHoursPlusEnabled = project.timePlusEnabled;
      tabItems.push(
        <NavItem
          secondary
          showIndicator
          key={`/admin/projects/${project.id}/settings`}
          linkTo={`/admin/projects/${project.id}/settings`}
          navLabel="Project settings"
        />,
        <NavItem
          secondary
          showIndicator
          key={`/admin/projects/${project.id}/crew-list`}
          linkTo={`/admin/projects/${project.id}/crew-list`}
          navLabel="Crew list"
        />,
        isHoursPlusEnabled && (
          <NavItem
            secondary
            showIndicator
            key={`/admin/projects/${project.id}/review-flows`}
            linkTo={`/admin/projects/${project.id}/review-flows`}
            navLabel="Departments & approvers"
          />
        ),
        isHoursPlusEnabled && (
          <NavItem
            secondary
            showIndicator
            key={`/admin/projects/${project.id}/templates`}
            linkTo={`/admin/projects/${project.id}/templates`}
            navLabel="Templates"
          />
        ),
        <NavItem
          secondary
          showIndicator
          key={`/admin/projects/${project.id}/audit-logs`}
          linkTo={`/admin/projects/${project.id}/audit-logs`}
          navLabel="Audit log"
        />,
      );
      if (isIA) {
        tabItems.push(
          <NavItem
            key={`/projects/${project.id}/search-invoices`}
            linkTo={`/projects/${project.id}/search-invoices`}
            secondary
            showIndicator
            navLabel="Invoices"
          />,
        );
      }
    } else {
      if (seeMyTimecards) {
        tabItems.push(
          <NavItem
            secondary
            showIndicator
            key={`/projects/${project.id}/me/timecards`}
            linkTo={`/projects/${project.id}/me/timecards`}
            secondaryLinks={[`/projects/${project.id}/me/timecards`]}
            navLabel="My timecards"
          />,
        );
      }
      if (isReviewer) {
        if (isCrewTimecardRole && !isProjectArchived) {
          if (project.dtsSettingEnabled && !isProjectArchived) {
            tabItems.push(
              <NavItem
                key={`/projects/${project.id}/daily-timesheets`}
                linkTo={`/projects/${project.id}/daily-timesheets`}
                secondary
                showIndicator
                navLabel="Daily Timesheets"
              />,
            );
          }
          if (!isProjectArchived) {
            tabItems.push(
              <NavItem
                secondary
                showIndicator
                key={`/projects/${project.id}/timecards`}
                linkTo={`/projects/${project.id}/timecards`}
                secondaryLinks={[`/projects/${project.id}/timecards`]}
                navLabel="Crew timecards"
              />,
            );
          }
        }
        tabItems.push(
          <NavItem
            secondary
            showIndicator
            key={`/projects/${project.id}/review/search-timecards`}
            linkTo={`/projects/${project.id}/review/search-timecards`}
            secondaryLinks={[`/projects/${project.id}/review/search-timecards`]}
            navLabel="Timecards"
          />,
        );
        if (isPA && !isProjectArchived) {
          tabItems.push(
            <NavItem
              secondary
              showIndicator
              key={`/projects/${project.id}/review/bulk-edit`}
              linkTo={`/projects/${project.id}/review/bulk-edit`}
              secondaryLinks={[`/projects/${project.id}/review/bulk-edit`]}
              navLabel="Bulk edit"
            />,
          );
        }
        if ((isDH || isPA || isUPM) && !isProjectArchived) {
          tabItems.push(
            <NavItem
              secondary
              showIndicator
              key={`/projects/${project.id}/me/review`}
              linkTo={activeReviewTab}
              secondaryLinks={reviewsSecondaryTabs}
              navLabel="Reviews"
            />,
          );
        }
        if (!isDH || (isDH && isIA)) {
          tabItems.push(
            <NavItem
              key={`/projects/${project.id}/search-invoices`}
              linkTo={`/projects/${project.id}/search-invoices`}
              secondary
              showIndicator
              navLabel="Invoices"
            />,
          );
        }
        tabItems.push(
          <NavItem
            secondary
            showIndicator
            key={`/projects/${project.id}/reports/ondemand`}
            linkTo={`/projects/${project.id}/reports/ondemand`}
            secondaryLinks={[
              `/projects/${project.id}/reports/ondemand`,
              `/projects/${project.id}/reports/schedule`,
            ]}
            navLabel="Reports"
          />,
        );
        if (!isProjectArchived) {
          tabItems.push(
            <NavItem
              secondary
              showIndicator
              key={`/projects/${project.id}/crew-list`}
              linkTo={`/projects/${project.id}/crew-list`}
              navLabel="Crew list"
            />,
          );
        }
      } else if (isIA) {
        tabItems.push(
          <NavItem
            key={`/projects/${project.id}/search-invoices`}
            linkTo={`/projects/${project.id}/search-invoices`}
            secondary
            showIndicator
            navLabel="Invoices"
          />,
        );
      }
    }

    return (
      <div className={classes.root}>
        <NavBar isProjectArchived={isProjectArchived}>{tabItems}</NavBar>
      </div>
    );
  }
}
export default compose(
  connect(mapStateToProps),
  withStyleSheet('SubNavHeader', style),
)(ProjectNav);
