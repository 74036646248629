import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { compose } from 'utils/helperFunctions';
import { Field } from 'redux-form';

import { Button, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import PropTypes from 'prop-types';

// import DayFilter from './DayFilter';
import MuiSelectWrapper from 'components/Shared/AutoComplete/MuiSelectWrapper';
// import bulkEdit from 'reducers/bulkEdit';
// import { getWeek } from 'date-fns';
import { BULK_EDIT_MAX_RESULTS } from 'components/Shared/constants';
import ClearConfirmModal from './ClearConfirmModal';
import CalculateConfirmModal from './CalculateConfirmModal';

const style = ({ palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 80,
    color: palette.gray['+2'],
    backgroundColor: palette.background.paper,
    width: '100%',
    justifyContent: 'flex-start',
  },
  btnContainer: {
    margin: '0 10px',
  },
  btn: {
    width: 113,
    height: 40,
  },
  clearBtn: {
    height: 40,
  },
  backToList: {
    padding: 0,
    marginRight: 35,
  },
  backToListButton: {},
  batchSelect: {
    width: 350,
    margin: '0px 0px 25px 20px',
  },

  dayFilter: {
    width: 184,
  },
  rightBar: {},
});

// const getListFromBatches = (batches, reviewBatchId) => {
//   return _.map(batches, batch => {
//     return {
//       label: batch.name,
//       value: batch.id,
//     };
//   });
// };

const getListFromWeekendings = (weeks, reviewBatchId) => {
  return _.map(weeks, week => {
    return {
      label: week.id,
      value: week.id,
    };
  });
};

// const getCurrentBatchIDFromWorksight = (batches, worksightId) => {
//   const currentBatch = _.find(batches, batch => {
//     return batch.worksightId === worksightId;
//   });

//   return currentBatch && currentBatch.id;
// };

//MAIN Component Func
const Header = props => {
  const {
    classes,
    //--data
    // batches,
    // reviewBatchId,
    weekEndings,
    tcCount,
    //--functions
    clearChanges,
    // change,
    // match,
    onBulkLeave,
    // onFetch,
    onSave,
    onCalc,
    onWeekendingChange,
    showClearChangesModal,
    showCalculateModal,
    //--flags
    saving,
    calculating,
    isDirty,
    //isCalculated,
    tcToBeCaluclated: tcToBeCalculated,
    // reviewBatchId,
    // ...others
  } = props;
  const weekList = getListFromWeekendings(weekEndings);

  function changeWeekending(weekEnding) {
    onWeekendingChange(weekEnding);
  }

  const [uncalculated, setUncalculated] = useState(false);
  const [saveBtn, setSaveBtn] = useState(false);
  useEffect(() => {
    setSaveBtn(false);
  }, [saving]);
  // let uncalculated = false;
  useEffect(() => {
    if (isDirty === true) {
      setUncalculated(true);
    }
    if (tcToBeCalculated.length === 0 && isDirty === false) {
      setUncalculated(true);
    } else if (tcToBeCalculated.length > 0 && isDirty === false) {
      setUncalculated(false);
    }
  }, [isDirty, tcToBeCalculated.length]);

  //state calculations
  const saveBtnTxt = saving ? 'Saving' : 'Save';
  const calcBtnTxt = calculating ? 'Calculating' : 'Calculate';

  const overMax = tcCount > BULK_EDIT_MAX_RESULTS;
  //btn logic defined in: HOUR-2955

  const saveBtnDisabled = saving || overMax || !isDirty || saveBtn;

  const calcBtnDisabled = saving || calculating || overMax || uncalculated;
  const clearBtnDisabled = saveBtnDisabled;
  //prevent changing batch with un-saved changes
  const batchSelectDisabled = saving || isDirty;

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.backToList}>
          <Button
            variant="text"
            color="primary"
            className={classes.backToListButton}
            onClick={onBulkLeave}
          >
            <ChevronLeftIcon />
            <span>Back to List</span>
          </Button>
        </div>
        <div className={classes.btnContainer}>
          <Tooltip
            arrow
            title="Save your work. This will not cause timecards to be recalculated."
          >
            <div>
              <Button
                className={classes.btn}
                color={'primary'}
                variant="contained"
                onClick={e => {
                  e.stopPropagation();
                  setSaveBtn(true);
                  onSave();
                }}
                key={'save'}
                disabled={saveBtnDisabled}
              >
                {saveBtnTxt}
              </Button>
            </div>
          </Tooltip>
        </div>
        <div className={classes.btnContainer}>
          <Tooltip
            arrow
            title="Save your work first. If calculating many timecards, this could take a few minutes."
          >
            <div>
              <Button
                className={classes.btn}
                color={'primary'}
                variant="outlined"
                onClick={e => {
                  e.stopPropagation();
                  showCalculateModal();
                }}
                key={'calc'}
                disabled={calcBtnDisabled}
              >
                {calcBtnTxt}
              </Button>
            </div>
          </Tooltip>
        </div>
        <div className={classes.btnContainer}>
          <Button
            className={classes.clearBtn}
            color={'primary'}
            variant="outlined"
            onClick={e => {
              e.stopPropagation();
              showClearChangesModal();
            }}
            key={'BEFetch'}
            disabled={clearBtnDisabled}
          >
            Clear All Changes
          </Button>
        </div>
        <div className={classes.batchSelect}>
          <Field
            component={MuiSelectWrapper}
            name={`weekEnding`}
            options={weekList}
            label={'Select Week Ending'}
            onChange={changeWeekending}
            disabled={batchSelectDisabled}
          />
        </div>
      </div>
      <ClearConfirmModal clearChanges={clearChanges} />
      <CalculateConfirmModal onCalc={onCalc} calculating={calculating} />
    </div>
  );
};

Header.propTypes = {
  batches: PropTypes.array.isRequired,
};

export default compose(withStyles(style))(Header);
