import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';

import {
  Box,
  TextField,
  InputLabel,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import * as actions from '../actions';
import * as sel from '../selectors';
import { getProjectDetails } from 'selectors/project';

import Comment from './Comment';
import {
  INPUT_FIELD_CHARACTER_LIMIT,
  TIMECARD_INCOMPLETE,
  TIMECARD_PENDING_EMP_REVIEW,
} from 'components/Shared/constants';
import { TabHeader, TabPaper } from './styledComponents';
import AttachmentWarning from './AttachmentWarning';

const commentsLimit = INPUT_FIELD_CHARACTER_LIMIT.comments;

const useStyles = makeStyles(({ palette }) => ({
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      paddingRight: '8px',
    },
    '& .MuiInputBase-input': {
      padding: '14px',
    },
  },
}));

const mapState = state => ({
  comments: sel.getComments(state),
  loading: sel.getCommentsLoading(state),
  project: getProjectDetails(state),
  newComment: sel.getNewComment(state),
  disabledBtns: sel.getDisabledBtns(state),
  status: sel.getTimecardStatus(state),
});

const mapDispatch = dispatch => ({
  onSaveComment: () => {
    dispatch(actions.saveComment());
  },
  onSetNewComment: comment => {
    dispatch(actions.setNewComment({ newComment: comment }));
  },
});

const CommentTab = props => {
  const classes = useStyles();
  const {
    comments,
    onSaveComment,
    loading,
    project,
    onSetNewComment,
    newComment,
    disabledBtns,
    status,
  } = props;

  const { disableActions } = disabledBtns;

  const [focus, setFocus] = React.useState(false);

  const handleAddComment = () => {
    if (newComment) {
      onSaveComment();
    }
  };

  const submitDisabled = !newComment || disableActions;

  const showComment =
    status === TIMECARD_INCOMPLETE || status === TIMECARD_PENDING_EMP_REVIEW;

  let helperText = '';
  if (newComment) {
    helperText = 'Comment must be submitted. ';
  }

  const commentCount = focus ? `  ${newComment.length}/${commentsLimit}` : '';
  return (
    <TabPaper>
      <TabHeader>
        <Box sx={{ fontWeight: 'bold' }}>Comments and Comments History</Box>
      </TabHeader>
      <AttachmentWarning />
      {showComment && (
        <Box sx={{ p: 3 }}>
          <InputLabel>Timecard Comment {commentCount}</InputLabel>
          <TextField
            className={classes.textField}
            value={newComment}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onChange={e => onSetNewComment(e.target.value)}
            variant={'outlined'}
            sx={{ width: '100%' }}
            disabled={disableActions}
            onKeyDown={e => {
              if (e.key === 'Enter' && !submitDisabled) {
                handleAddComment();
              }
            }}
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ p: 0 }}
                  onClick={handleAddComment}
                  disabled={submitDisabled}
                >
                  <ArrowUpwardIcon
                    sx={{
                      backgroundColor: submitDisabled
                        ? 'gray.+8'
                        : 'primary.main',
                      color: submitDisabled
                        ? 'gray.+6'
                        : 'primary.contrastText',
                      height: '24px',
                      width: '24px',
                      p: '6px',
                      borderRadius: ' 10px',
                    }}
                  />
                </IconButton>
              ),
            }}
            helperText={helperText}
            inputProps={{ maxLength: commentsLimit }}
          />
        </Box>
      )}
      <Box sx={{ p: 3 }}>
        {comments.map(c => (
          <Comment key={c.id} comment={c} project={project} />
        ))}
        {loading && <LinearProgress />}
      </Box>
    </TabPaper>
  );
};

CommentTab.propTypes = {
  comments: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  onSaveComment: PropTypes.func.isRequired,
  onSetNewComment: PropTypes.func.isRequired,
  newComment: PropTypes.string.isRequired,
  disabledBtns: PropTypes.object.isRequired,
};

export default compose(connect(mapState, mapDispatch))(CommentTab);
