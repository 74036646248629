// import './wdyr';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';

// Custom Theme
import ThemeProviderHPlus from 'shared/theme/ThemeProvider';
import AuthenticationProvider from 'contextProviders/Authentication';

import ErrorBoundary from 'shared/errorBoundary/ErrorBoundary';
import IdleTimer from 'shared/IdleTimer';
// App root
import App from 'containers/App';

// Custom providers
import { configureAPI, configureCookie, configureStore } from 'providers';
import SessionTimeout from './containers/Shared/SessionTimeout';
import BulkEditTimeout from './containers/Shared/BulkEditTimeout';
import SnackBar from './containers/Shared/SnackBar';
const api = configureAPI();
const cookie = configureCookie();
const initialState = {};

const [store, history] = configureStore({
  initialState,
  api,
  cookie,
});

global.myCookie = cookie;

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <AuthenticationProvider>
      <ThemeProviderHPlus>
        <ErrorBoundary>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <App history={history} />
            <SessionTimeout />
            <BulkEditTimeout />
            <SnackBar />
            <IdleTimer />
          </LocalizationProvider>
        </ErrorBoundary>
      </ThemeProviderHPlus>
    </AuthenticationProvider>
    {/* </React.StrictMode> */}
  </Provider>,
);
