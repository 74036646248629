import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { PaginationCounts } from './CrewListUtils';

const useStyles = makeStyles(({ palette }) => ({
  // root: {
  //   width: '100%',
  //   display: 'flex',
  //   flexDirection: 'column',
  // },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    marginTop: 10,
  },
  pagButton: {
    backgroundColor: palette.background.default,
    borderColor: palette.background.default,
    color: palette.primary.main,
    borderStyle: 'none',
  },
  pagLocation: {
    marginTop: 10,
  },
}));
const Pagination = props => {
  const {
    canNextPage,
    canPreviousPage,
    crewTotal,
    gotoPage,
    nextPage,
    // page,
    pageCount,
    pageIndex,
    previousPage,
    rows,
    editingHotCost,
  } = props;

  const classes = useStyles();

  const [pagStats, setPagStats] = useState(
    PaginationCounts(pageCount, rows.length, pageIndex + 1),
  );

  useEffect(() => {
    setPagStats(PaginationCounts(pageCount, rows.length, pageIndex + 1));
  }, [crewTotal, pageCount, pageIndex, rows]);

  return (
    <div className={classes.pagination}>
      <span className={classes.pagLocation}>
        {`${pagStats.from} - ${pagStats.to} out of ${pagStats.total}`}
      </span>
      <Button
        variant="text"
        className={classes.pagButton}
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage || editingHotCost}
      >
        {'|<'}
      </Button>{' '}
      <Button
        variant="text"
        className={classes.pagButton}
        onClick={() => previousPage()}
        disabled={!canPreviousPage || editingHotCost}
      >
        {'<'}
      </Button>{' '}
      <Button
        variant="text"
        className={classes.pagButton}
        onClick={() => nextPage()}
        disabled={!canNextPage || editingHotCost}
      >
        {'>'}
      </Button>
      <Button
        variant="text"
        className={classes.pagButton}
        onClick={() => gotoPage(pagStats.lastPage - 1)}
        disabled={!canNextPage || editingHotCost}
      >
        {'>|'}
      </Button>
    </div>
  );
};

export default Pagination;
