import signalrApi from './signalrApi';
import { call } from 'redux-saga/effects';
import _cloneDeep from 'lodash/cloneDeep';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ endpoint, tokenProvider }) => {
  const signalr = signalrApi({
    endpoint,
    tokenProvider,
  });

  return {
    connect: function* () {
      yield call(signalr.connect);
    },
    disconnect: function* () {
      yield call(signalr.disconnect);
    },
    save: function* (headerId, timecard, requestedBy) {
      const action = timecard?.action || 'editDays';

      const payload = _cloneDeep(timecard);
      delete payload.action;

      yield call(signalr.invoke, 'Save', {
        headerId,
        payload,
        requestedBy,
        action,
      });
    },
    calculate: function* (headerId, requestedBy) {
      yield call(signalr.invoke, 'Calculate', {
        headerId,
        requestedBy,
      });
    },
  };
};
