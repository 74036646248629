import { produce } from 'immer';
import * as actions from 'actions/gridFiltering';

const initialState = {};

export const defaultFiltering = {
  filter: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.initialize}`:
        if (!draft[action.grid]) {
          draft[action.grid] = defaultFiltering;
        }
        break;

      case `${actions.remove}`:
        delete draft[action.grid];
        break;

      case `${actions.applyFilter}`:
        draft[action.grid].filter = action.filter;
        break;

      default:
    }
  });
