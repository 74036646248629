import React from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import { Field } from 'redux-form';
import { MenuItem } from '@mui/material';
import { Select as SelectField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Select } from 'components/Shared/redux';
import {
  departmentProps,
  departmentsProps,
} from 'components/props/departments';

const style = () => ({
  root: {},
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > *': {
      width: '100%',
      marginBottom: 5,
      marginTop: 5,
    },
  },
  selectRow: {},
});

class DepartmentSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selected: '' };
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    fieldClass: PropTypes.string,
    departments: departmentsProps,
    name: PropTypes.string,
    selectDept: departmentProps,
    onSelect: PropTypes.func,
    placeholder: PropTypes.string,
    plain: PropTypes.bool,
  };

  static defaultProps = {
    departments: [],
    name: 'department',
    onSelect: undefined,
    placeholder: 'Department',
    plain: false,
    fieldClass: '',
  };

  onSelect(value) {
    if (this.props.plain) {
      this.setState({ selected: value.target.value });
    }
    if (this.props.onSelect) {
      this.props.onSelect(value);
    }
  }

  renderMenuOptions() {
    const { departments } = this.props;

    const menuOptions = departments.map(department => (
      <MenuItem key={department.id} value={department.id}>
        {department.name}
      </MenuItem>
    ));

    return menuOptions;
  }

  render() {
    const {
      classes,
      fieldClass,
      name,
      selectDept,
      placeholder,
      plain,
      disabled = false,
    } = this.props;
    const value = (selectDept && parseInt(selectDept.id, 0)) || '';
    const inputProps = { id: `select-${name}` };
    if (value && value.length > 0) {
      inputProps.value = value;
    }
    let departmentSelect = null;
    const menuOptions = this.renderMenuOptions();
    if (plain) {
      departmentSelect = (
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor={`select-${name}`}>{placeholder}</InputLabel>
          <SelectField
            label={placeholder}
            name={name}
            inputProps={inputProps}
            disabled={menuOptions.length === 0 || disabled}
            onChange={value => this.onSelect(value)}
            value={this.state.selected}
            variant="standard"
          >
            {menuOptions}
          </SelectField>
        </FormControl>
      );
    } else {
      departmentSelect = ( //Think this is only used on the invite list that is now deprecated.  check after Aug '22 if this is needed
        <FormControl className={fieldClass}>
          <InputLabel htmlFor={`select-${name}`}>{placeholder}</InputLabel>
          <Field
            component={Select}
            name={name}
            label={placeholder}
            placeholder={placeholder}
            disabled={menuOptions.length === 0 || disabled}
            inputProps={inputProps}
            onChange={value => this.onSelect(value)}
            variant="standard"
          >
            {menuOptions}
          </Field>
        </FormControl>
      );
    }

    return <div className={classes.row}>{departmentSelect}</div>;
  }
}

export default withStyleSheet('DepartmentSelect', style)(DepartmentSelect);
