import React from 'react';
import InputBase from '@mui/material/InputBase';
import { alpha } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import SearchIcon from '@mui/icons-material/Search';

const style = ({ palette, breakpoints, shape, transitions }) => ({
  root: {
    width: '100%',
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  search: {
    position: 'relative',
    borderRadius: shape.borderRadius,
    backgroundColor: alpha(palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    height: 'inherit',
    [breakpoints.up('sm')]: {
      marginLeft: 8,
      width: 'auto',
      '&:focus': {
        marginBottom: 0,
      },
    },
  },
  searchIcon: {
    width: 72,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: 8,
    paddingRight: 8,
    paddingBottom: 8,
    paddingLeft: 80,
    transition: transitions.create('width'),
    width: '100%',
    border: 1,
    [breakpoints.up('sm')]: {
      width: 'inherit',
      '&:focus': {
        marginBottom: 0,
      },
    },
  },
  [breakpoints.down('md')]: {
    searchIcon: {
      width: 40,
      color: palette.text.secondary,
    },
    inputInput: {
      paddingLeft: 48,
    },
  },
});

// disable chrome autocomplete for search
/**
 * This is the code I tested on some online CC forms and out
 * of everything this is what worked.  Chrome has been
 * removing the ability to programmatically prevent autocomplete
 * with every update.
 */
const inputProps = {
  type: 'search',
  autoComplete: 'off',
};

const SearchBar = ({
  classes,
  onFilter,
  onFieldFocus,
  onFieldBlur,
  placeholder,
  autoFocus,
}) => (
  <div className={classes.search}>
    <div className={classes.searchIcon}>
      <SearchIcon />
    </div>
    <InputBase
      placeholder={placeholder || 'Search...'}
      classes={{
        root: classes.inputRoot,
        input: classes.inputInput,
      }}
      inputProps={inputProps}
      fullWidth
      onChange={onFilter}
      onFocus={onFieldFocus}
      onBlur={onFieldBlur}
      autoFocus={autoFocus}
    />
  </div>
);

export default withStyles(style)(SearchBar);
