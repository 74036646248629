import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import * as actions from 'actions/departments';
import { storeDepartments } from 'actions/bulkEdit';
// selectors

import { getProject as currentProject } from 'selectors/routeParams';

export function* fetchDepartments(api, debug, params) {
  const { bulkEdit } = params;
  try {
    const projectId = yield select(currentProject);
    const departments = yield call(api.departments.list, { projectId });
    if (bulkEdit) yield put(storeDepartments({ departments }));
    yield put(actions.store({ departments }));
  } catch (e) {
    debug(e);
  }
}

export default function* departmentsFlow({ api, debug }) {
  yield all([takeEvery(`${actions.fetch}`, fetchDepartments, api, debug)]);
}
