import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

import { getReasonDisabled } from 'selectors/userInfo';

export const MISSING_WORKSIGHT_ACCOUNT = 'MISSING_WORKSIGHT_ACCOUNT';
export const MISSING_DEPARTMENT = 'MISSING_DEPARTMENT';

const UNASSIGNED_WORKSIGHT_ACCOUNT =
  'The account must finish being processed through payroll before a timecard can be created';
const UNASSIGNED_DEPARTMENT_MESSAGE =
  'A department must be assigned before a timecard can be created';

const mapStateToProps = state => ({
  reason: getReasonDisabled(state),
});

const style = theme => ({
  root: {
    padding: 25,
    flexGrow: 1,
  },
  content: {
    '& > *': {
      fontFamily: 'inherit',
    },
    display: 'flex',
    marginTop: 16,
    marginBottom: 16,
    margin: 0,
  },
});

const InactiveUser = ({ classes, reason = 'missingWorkSightAccount' }) => (
  <Paper className={classes.root} elevation={2}>
    <Typography variant="subtitle2">
      {reason === MISSING_WORKSIGHT_ACCOUNT
        ? UNASSIGNED_WORKSIGHT_ACCOUNT
        : UNASSIGNED_DEPARTMENT_MESSAGE}
    </Typography>
  </Paper>
);

InactiveUser.propTypes = {
  classes: PropTypes.object.isRequired,
  reason: PropTypes.oneOf([MISSING_WORKSIGHT_ACCOUNT, MISSING_DEPARTMENT]),
};

export default connect(mapStateToProps, null)(withStyles(style)(InactiveUser));
