import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Paper,
  Button,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import withModalDialog from 'decorators/withModalDialog';
import { hide as hideModal, show as showModal } from 'actions/modalDialog';

import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import EditIcon from '@mui/icons-material/Edit';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import RefreshIcon from '@mui/icons-material/Refresh';

import RichText from 'components/RichText/RichText';

import LastSaved from '../Comments/LastSaved';
import UploadedFiles from '../UploadFilesComps/UploadedFiles';
import { Notice } from 'components/Shared/Text';
//actions
import {
  fetchComments,
  fetchInvoiceFiles,
  setCommentState,
  saveComment,
  resubmitInvoice,
  lockComment,
  unlockComment,
} from 'actions/digitalEdits';
//selectors
import {
  getUnsubmittedComment,
  getUploadedFiles,
  getIsLoadingComments,
  getCommentState,
  getIsSavingComment,
  getCommentLockStatus,
  getCurrentInvoiceId,
} from 'selectors/digitalEdits';

import {
  useDidMount,
  useAutoSave,
  useWillUnmount,
  useBroadcastChannel,
} from 'utils/customHooks';

const MODAL_NAME = 'ResubmitInvoice';

const useStyles = makeStyles(({ palette }) => ({
  ResubmitInvoiceModal: {
    maxHeight: '75vh',
    maxWidth: 940,
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '97%',
  },
  textareaContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  textareaBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  textarea: {
    maxHeight: '70%',
    maxWidth: 900,
    minWidth: 400,
    minHeight: 200,
    height: 200,
    width: 625,
    fontFamily: 'Inter',
    border: `1px solid ${palette.gray['+7']}`,
    margin: '0 20px',
    color: palette.text.secondary,
  },
  editableTextarea: {
    color: palette.text.primary,
    '&:focus': {
      outline: 'none !important',
      border: `2px solid ${palette.primary.main}`,
    },
  },
  textareaFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '96%',
  },
  modifyFilesText: {
    color: palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '96%',
    padding: 8,
  },
}));

const mapState = state => ({
  unsubmittedComment: getUnsubmittedComment(state),
  loadingComments: getIsLoadingComments(state),
  files: getUploadedFiles(state),
  commentState: getCommentState(state),
  savingComment: getIsSavingComment(state),
  commentLock: getCommentLockStatus(state),
  currentInvoiceId: getCurrentInvoiceId(state),
});

const mapDispatch = dispatch => ({
  onCloseModal: () => dispatch(hideModal({ dialog: MODAL_NAME })),
  onOpenUploadModal: () => {
    dispatch(hideModal({ dialog: MODAL_NAME }));
    dispatch(showModal({ dialog: 'EditUploadFiles' }));
  },
  onSetCommentState: commentState =>
    dispatch(setCommentState({ commentState })),
  onInit: () => {
    dispatch(fetchComments());
    dispatch(fetchInvoiceFiles());
    dispatch(lockComment());
  },
  onSaveComment: args => dispatch(saveComment(args)),
  onResubmitInvoice: commentText => dispatch(resubmitInvoice({ commentText })),
  onUnlockComment: () => dispatch(unlockComment()),
  onRefreshLock: () => {
    dispatch(lockComment());
    dispatch(fetchComments());
  },
});

const ResubmitInvoiceModal = props => {
  const classes = useStyles();
  const {
    unsubmittedComment,
    onCloseModal,
    onOpenUploadModal,
    onInit,
    files = [],
    commentState,
    onSetCommentState,
    savingComment,
    loadingComments,
    onSaveComment,
    onResubmitInvoice,
    commentLock,
    onUnlockComment,
    onRefreshLock,
    currentInvoiceId,
  } = props;

  const updatedDate = unsubmittedComment.updatedDate || null;

  const [commentText, setCommentText] = useState(''); //richtext json string
  const [plainText, setPlainText] = useState(); //plaintext

  const [readOnly, setReadOnly] = useState(true);

  const [showEditMsg, setShowEditMsg] = useState(false);

  const commentLockBySomeoneElse = commentLock.lockOwner === 'other';

  const resubmitDisabled =
    plainText === '' || !commentText || savingComment || loadingComments;

  const SaveComment = useCallback(() => {
    if (commentText) {
      onSaveComment({
        commentText,
        commentId: unsubmittedComment.commentId,
        plainText,
      });
      return true;
    } else {
      return false;
    }
  }, [commentText, onSaveComment, plainText, unsubmittedComment.commentId]);

  const initLastSave = updatedDate ? new Date(updatedDate) : null;

  const { setLastChangeTime, onAutoSave, hasUnsavedChanges } = useAutoSave(
    SaveComment,
    initLastSave,
  );

  useEffect(() => {
    setCommentText(unsubmittedComment.commentText || '');
    if (!unsubmittedComment.commentText) {
      setReadOnly(false);
    }
  }, [unsubmittedComment]);

  useDidMount(() => {
    onInit();
  });

  useWillUnmount(() => {
    if (hasUnsavedChanges) onAutoSave();
    onUnlockComment();
  });

  const bc = useBroadcastChannel(currentInvoiceId);

  React.useEffect(() => {
    if (bc) {
      bc.onmessage = ev => {
        if (ev.data?.action === 'heartbeat') {
          if (commentLock.lockOwner === 'me') {
            bc.postMessage({
              action: 'heartbeat-response',
              payload: commentLock,
            });
          }
        }
      };
    }
  }, [bc, commentLock, currentInvoiceId]);

  return (
    <Paper className={classes.ResubmitInvoiceModal}>
      <Box className={classes.header}>
        Resubmit
        <IconButton
          className={'PENDO_digitalEditsResubmitModalClose'}
          sx={{ position: 'relative', bottom: '20px', left: '40px' }}
          onClick={onCloseModal}
        >
          <CloseIcon sx={{ color: 'gray.primary' }} />
        </IconButton>
      </Box>
      <Box className={classes.textareaContainer}>
        <Typography sx={{ width: '100%' }}>
          Enter any additional instructions for your coordinator.
        </Typography>
        <Box
          className={classes.textareaBox}
          onDoubleClick={() => {
            if (showEditMsg) {
              setReadOnly(false);
            } else {
              setShowEditMsg(true);
              setTimeout(() => {
                setShowEditMsg(false);
              }, 2000);
            }
          }}
        >
          <RichText
            className={clsx(classes.textarea, {
              [classes.editableTextarea]: !readOnly,
            })}
            editable={!readOnly}
            disabled={loadingComments}
            value={commentText || ''}
            onChange={(newVal, newPlainText) => {
              setLastChangeTime(new Date());
              setCommentText(newVal);
              setPlainText(newPlainText.trim());
            }}
          />

          {commentLockBySomeoneElse ? (
            <Box sx={{ width: '620px' }}>
              <Notice type="warning">
                <Box sx={{ display: 'flex' }}>
                  <Box>
                    <Tooltip title="They will need to close the comment window before it can be edited by someone else.">
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: '1.1em',
                        }}
                      >
                        This comment is currently being edited by: <br />
                        {commentLock.lock.lockedBy}
                      </Typography>
                    </Tooltip>
                    You can still resubmit, but any updates they make might be
                    lost.
                  </Box>
                  <IconButton onClick={onRefreshLock}>
                    <RefreshIcon />
                  </IconButton>
                </Box>
              </Notice>
            </Box>
          ) : (
            <Box className={classes.textareaFooter}>
              {readOnly && showEditMsg && (
                <Typography>Double-Click again to edit comment</Typography>
              )}
              {!readOnly && (
                <LastSaved
                  commentText={commentText}
                  savingComment={savingComment}
                />
              )}
              {readOnly && (
                <Tooltip title="Edit Comment">
                  <IconButton onClick={() => setReadOnly(false)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Launch Comment Modal">
                <IconButton
                  onClick={() => {
                    if (commentState === 'closed') {
                      onSetCommentState('modal');
                    } else if (commentState?.onCloseInvoiceComments) {
                      commentState.focus();
                    }
                    onCloseModal();
                  }}
                >
                  <LaunchIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <Box>
          {files.length > 0 ? (
            <React.Fragment>
              The following files will be sent to your coordinator
              <UploadedFiles files={files} />
            </React.Fragment>
          ) : (
            <Box>No files added</Box>
          )}

          <Box
            className={clsx(
              'PENDO_digitalEditsModifyFiles',
              classes.modifyFilesText,
            )}
            onClick={onOpenUploadModal}
          >
            <NoteAddIcon /> Modify Files
          </Box>
        </Box>
      </Box>

      <Box className={classes.footer}>
        <Button
          variant="outlined"
          onClick={onCloseModal}
          className={'PENDO_digitalEditsResubmitModalBtnClose'}
        >
          Close
        </Button>
        <Tooltip
          placement="top"
          title={resubmitDisabled ? 'Comment required to resubmit' : ''}
        >
          <div>
            <Button
              className={'PENDO_digitalEditsResubmitModalBtnResubmit'}
              disabled={resubmitDisabled}
              onClick={() => {
                let resubmitComment = '';
                if (hasUnsavedChanges) {
                  resubmitComment = commentText;
                }
                onResubmitInvoice(resubmitComment);
                onCloseModal();
              }}
            >
              Resubmit
            </Button>
          </div>
        </Tooltip>
      </Box>
    </Paper>
  );
};

ResubmitInvoiceModal.propTypes = {
  unsubmittedComment: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onOpenUploadModal: PropTypes.func.isRequired,
  onInit: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  commentState: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  onSetCommentState: PropTypes.func.isRequired,
  savingComment: PropTypes.bool.isRequired,
  loadingComments: PropTypes.bool.isRequired,
  onSaveComment: PropTypes.func.isRequired,
  onResubmitInvoice: PropTypes.func.isRequired,
  commentLock: PropTypes.object.isRequired,
  onUnlockComment: PropTypes.func.isRequired,
  onRefreshLock: PropTypes.func.isRequired,
  currentInvoiceId: PropTypes.string.isRequired,
};

export default compose(
  withModalDialog({ dialog: MODAL_NAME, maxWidth: 'md' }),
  connect(mapState, mapDispatch),
)(ResubmitInvoiceModal);
