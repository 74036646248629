import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@mui/material';
import { isRegionCanada } from 'utils/helperFunctions';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  fringeTip: {
    padding: 8,
    display: 'grid',
    gridTemplateColumns: '10px 2fr 10px 1fr 10px',
    gridTemplateRows: 'auto auto 16px auto',
    '&  p': {
      fontSize: 14,
    },
  },
  fringeCol1Row1: { gridColumn: '2', gridRow: '1', textAlign: 'right' },
  fringeCol2Row1: { gridColumn: '4', gridRow: '1' },
  fringeCol1Row2: { gridColumn: '2', gridRow: '2', textAlign: 'right' },
  fringeCol2Row2: { gridColumn: '4', gridRow: '2' },
  fringeRow3: { gridColumn: '2 / span 4', gridRow: '4', whiteSpace: 'nowrap' },
}));

const FringeTip = ({ fringeData }) => {
  const classes = useStyles();

  const hasFringeData = fringeData?.length > 0;

  if (hasFringeData && isRegionCanada(localStorage.getItem('region'))) {
    const vacPayLabel = fringeData[0][0];
    const vacPayValue = fringeData[0][1];
    const laborLabel = fringeData[1][0];
    const laborValue = fringeData[1][1];

    const fringeTipContent = (
      <article className={classes.fringeTip}>
        <section className={classes.fringeCol1Row1}>
          <Typography>{vacPayLabel}:</Typography>
        </section>
        <section className={classes.fringeCol2Row1}>
          <Typography>${vacPayValue}</Typography>
        </section>
        <section className={classes.fringeCol1Row2}>
          <Typography>{laborLabel}:</Typography>
        </section>
        <section className={classes.fringeCol2Row2}>
          <Typography>${laborValue}</Typography>
        </section>
        <section className={classes.fringeRow3}>
          <Typography>Exact value will be reflected on your edit</Typography>
        </section>
      </article>
    );

    return (
      <Tooltip title={fringeTipContent} placement="right">
        <InfoOutlinedIcon style={{ fontSize: 18 }} />
      </Tooltip>
    );
  }
  return null;
};

FringeTip.propTypes = { fringeData: PropTypes.array };

export default FringeTip;
