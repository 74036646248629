import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
// components
import { userInfoProps } from 'components/props/profiles';
//container
import { Ready, History, Pending, Open, Complete } from '.';
//selectors
import { currentUser } from 'selectors/session';
//decorator
const mapState = (state, { match }) => ({
  projectId: match.params.projectId,
  activeUser: currentUser(state),
});

const mapDispatch = (dispatch, { match }) => ({
  onFetchData: () => {},
});

class Reviews extends Component {
  static propTypes = {
    activeUser: userInfoProps.isRequired,
    projectId: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.onFetchData();
  }

  renderReviewContainer(location) {
    const url = _.split(location.pathname, '/');
    const urlSuffix = url[url.length - 1];
    switch (urlSuffix) {
      case 'ready':
        return <Ready />;
      case 'history':
        return <History />;
      case 'pending':
        return <Pending />;
      case 'open':
        return <Open />;
      case 'complete':
        return <Complete />;
      default:
        break;
    }
  }

  render() {
    const { location } = this.props;
    return <div>{this.renderReviewContainer(location)}</div>;
  }
}
export default compose(connect(mapState, mapDispatch))(Reviews);
