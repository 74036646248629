import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';

function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function stringAvatar(name) {
  name = name.trim()?.replace(/  +/g, ' ');

  let firstLetter, lastLetter;
  if (name.includes(',')) {
    firstLetter = name.split(',')[1].trim()[0];
    lastLetter = name.split(',')[0].trim()[0];
  } else if (name.includes(' ')) {
    firstLetter = name.split(' ')[0].trim()[0];
    lastLetter = name.split(' ')[1].trim()[0];
  } else {
    firstLetter = name[0];
    lastLetter = '';
  }

  const initials =
    `${firstLetter?.toUpperCase()}${lastLetter?.toUpperCase()}` || '';

  return {
    styles: {
      bgcolor: stringToColor(name),
      textTransform: 'uppercase',
      height: '32px',
      width: '32px',
    },
    children: initials,
  };
}

const BackgroundLetterAvatars = props => {
  const { name = '', sx = {}, miniAvatar = false, ...rest } = props;

  if (miniAvatar) {
    let warnStr = '';
    if (Object.hasOwnProperty.call(sx, 'height')) {
      warnStr += 'height ';
    }
    if (Object.hasOwnProperty.call(sx, 'width')) {
      warnStr += 'width ';
    }
    if (Object.hasOwnProperty.call(sx, 'fontSize')) {
      warnStr += 'fontSize ';
    }

    if (warnStr) {
      console.warn(
        `You're passing SX values for ${warnStr}, these will be overwritten since miniAvatar is set to true`,
      );
    }

    sx.height = '20px';
    sx.width = '20px';
    sx.fontSize = 10;
  }

  if (!name || typeof name !== 'string') return null;

  const { styles, children } = stringAvatar(name);

  return <Avatar sx={{ ...styles, ...sx }} children={children} {...rest} />;
};

BackgroundLetterAvatars.propTypes = {
  name: PropTypes.string.isRequired,
  miniAvatar: PropTypes.bool,
  sx: PropTypes.object,
};

export default BackgroundLetterAvatars;
