import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

//actions
import * as actions from 'actions/reports';
import { show as showModal } from 'actions/modalDialog';

//selectors
import { getLoading, getSortedAdminUsers } from 'selectors/reports';
import { getSessionUser } from 'selectors/session';

//components
import AccessAdminComp from 'components/Admin/AccessAdmin/AccessAdmin';

const mapDispatch = dispatch => ({
  onFetchAdminUsers: () => dispatch(actions.fetchAdminUsers()),
  onSortAdminUsers: (order, property) =>
    dispatch(actions.sortAdminUsers({ order, property })),
  onAddEditAdminUser: user => {
    if (user && !_.isEmpty(user)) {
      dispatch(actions.editAdminUser({ user }));
    } else {
      dispatch(actions.editAdminUser({ user: {} }));
    }
    dispatch(showModal({ dialog: 'AddAdminUser' }));
  },
  onRunAdminUsersReport: report => {
    dispatch(actions.runAdminUsersReport({ report }));
  },
});
const mapState = state => ({
  loading: getLoading(state),
  adminUsers: getSortedAdminUsers(state),
  currentUser: getSessionUser(state),
});

const AccessAdmin = props => {
  const {
    loading,
    onFetchAdminUsers,
    adminUsers,
    onSortAdminUsers,
    onAddEditAdminUser,
    currentUser,
    onRunAdminUsersReport,
  } = props;

  useEffect(() => {
    onFetchAdminUsers();
  }, [onFetchAdminUsers]);

  return (
    <div style={{ marginTop: 20 }}>
      <AccessAdminComp
        loading={loading}
        adminUsers={adminUsers}
        onSortAdminUsers={onSortAdminUsers}
        onAddEditAdminUser={onAddEditAdminUser}
        currentUser={currentUser}
        onRunAdminUsersReport={onRunAdminUsersReport}
      />
    </div>
  );
};

export default connect(mapState, mapDispatch)(AccessAdmin);
