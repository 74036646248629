import _ from 'lodash';
import { createSelector } from 'reselect';

export const getProjectAllowances = state =>
  _.get(state, 'projectAllowances.list', []);
export const getProjectAllowancesLoading = state =>
  _.get(state, 'projectAllowances.loading', false);

export const getDayTypes = state =>
  _.get(state, 'projectAllowances.dayTypes', []);

export const getProjectAllowance = createSelector(
  [state => getProjectAllowances(state), (state, index) => index],
  (projectAllowances, index) => {
    return projectAllowances[index] || {};
  },
);
