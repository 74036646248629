import React from 'react';
import { Typography } from '@mui/material';
import { connect } from 'react-redux';
// decorators
import withModalDialog from 'decorators/withModalDialog';
// components
import { ConfirmModal } from 'components/Shared/Modals';
// actions
import { hide as hideModal } from 'actions/modalDialog';
//selectors
import { getProspectiveTimecard } from 'selectors/bulkEdit';
import { getProject as project } from 'selectors/routeParams';

class TakeMeBackBulkEdit extends React.Component {
  render() {
    const { prospectiveTimecard, onBackToList, onNavToWTC, onModalHide } =
      this.props;

    const {
      timecardEntryHeaderId = null,
      batchWorksightId = null,
      batchHtgId = null,
      actionType,
    } = prospectiveTimecard;
    const title = <div>{'Unsaved Changes'}</div>;

    const content = (
      <div>
        <Typography color="inherit">
          {'Your new changes will be lost.'} <br />
          {'Are you sure you want to leave this page?'}
        </Typography>
      </div>
    );

    return (
      <ConfirmModal
        title={title}
        content={content}
        buttonText="Leave this Page"
        buttonType="default"
        cancelText="Stay on this Page"
        hasSubmitAction={true}
        onModalHide={onModalHide}
        onSubmit={() => {
          if (actionType === 'backToList') {
            onBackToList();
          } else if (actionType === 'loadTimecard') {
            onNavToWTC(timecardEntryHeaderId, batchWorksightId, batchHtgId);
          } else {
            // backup action ?
            onBackToList();
          }
        }}
      />
    );
  }
}

const mapState = state => ({
  prospectiveTimecard: getProspectiveTimecard(state),
  projectId: project(state),
});

const mapDispatch = (dispatch, { match }) => ({
  onModalHide: () => {
    dispatch(hideModal({ dialog: 'TakeMeBackBulkEdit' }));
  },
});

export default withModalDialog({
  dialog: 'TakeMeBackBulkEdit',
})(connect(mapState, mapDispatch)(TakeMeBackBulkEdit));
