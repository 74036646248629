import { OktaAuth } from '@okta/okta-auth-js';
import apiServer, { okta as Config } from 'constants/config/apiServer';

const client = new OktaAuth({
  issuer: Config.issuer,
  clientId: Config.clientId,
  redirectUri: Config.redirect,
  pkce: false,
  tokenManager: {
    secure: true,
    storage: 'sessionStorage',
  },
});

async function getToken(attempts = 1) {
  try {
    var { accessToken } = await client.tokenManager.getTokens();

    if (accessToken) {
      return accessToken.accessToken;
    }
  } catch (err) {
    console.error(err);
  }

  if (attempts > 7) {
    await redirectToLogin();
  }

  await new Promise(res => setTimeout(res, 250));
  return await getToken(++attempts);
}

async function signout(logoutUrl) {
  try {
    await client.signOut({
      revokeAccessToken: true,
    });
  } catch (err) {}

  client.tokenManager.clear();
  window.location.assign(logoutUrl);
}

async function redirectToLogin() {
  await signout(`${apiServer.myCncUrl}/login?fromURI=${window.location.href}`);
}

async function setupToken() {
  try {
    var response = await client.token.getWithoutPrompt({
      responseType: ['token'],
    });

    if (response && response.tokens) {
      client.tokenManager.setTokens(response.tokens);
    } else {
      await redirectToLogin();
    }
  } catch (error) {
    console.error(error);
    await redirectToLogin();
  }
}

(async () => {
  client.start();
  await setupToken();
})();

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async accessToken() {
    return await getToken();
  },
  async logout(redirectURL) {
    return await signout(redirectURL);
  },
};
