import React from 'react';
import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { ssnFormatLastFourDigit } from '../../../utils';
// components
import {
  ModalFooter,
  ModalContent,
  ModalTitle,
} from 'components/Shared/ModalDialog';

//components
import ErrorMessage from 'components/ErrorMessage';
import UserEditActions from './UserEditActions';
import UserEditFields from './UserEditFields';

const useStyles = makeStyles(theme => ({
  errorMessage: {
    align: 'center',
    padding: 16,
    margin: [16, 0],
    boxShadow: 'none',
  },
  titleName: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '26px',
    color: theme.palette.gray['-5'],
  },
}));

const UserEdit = props => {
  const {
    user = {},
    pristine,
    reset,
    invalid,
    submitting,
    onModalHide,
    onResendInvitation,
    departments,
    error,
    project,
  } = props;
  const classes = useStyles();
  const fullName = user.fullName?.toUpperCase() || '';
  return (
    <div>
      <ModalTitle>
        <Typography variant="h6" key="fullName" color="inherit">
          <div className={classes.titleName}>
            {fullName} - {ssnFormatLastFourDigit(user?.ssn)}
          </div>
        </Typography>
      </ModalTitle>

      <ModalContent className={classes.content}>
        {error && (
          <ErrorMessage className={classes.errorMessage}>{error}</ErrorMessage>
        )}
        <UserEditFields
          user={user}
          onResendInvitation={onResendInvitation}
          departments={departments}
          project={project}
        />
      </ModalContent>

      <ModalFooter>
        <UserEditActions
          pristine={pristine}
          reset={reset}
          submitting={submitting}
          invalid={invalid}
          onCancel={onModalHide}
        />
      </ModalFooter>
    </div>
  );
};
export default UserEdit;
