import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, formValueSelector } from 'redux-form';
import clsx from 'clsx';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Paper, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  NEW_TC_ID,
  getDealMemoDisplay,
} from 'feature/EmployeeTimecard/empTimecardUtils';
import WarningIcon from '@mui/icons-material/Warning';

import { showAlert } from 'actions/alert';

import { TIMECARD_INCOMPLETE } from 'components/Shared/constants';
import { FORM_NAME, getAllowanceTypes, getIsTcEditable } from '../selectors';

const formSelector = formValueSelector(FORM_NAME);

const useStyles = makeStyles(({ palette }) => ({
  DealMemoCard: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    borderRadius: 25,
    gap: 8,
    padding: 12,
  },
  isCurrent: {
    backgroundColor: palette.primary.table,
  },
  validDealForSwitch: {
    cursor: 'pointer',
  },
  loanOut: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  isDisabled: {
    backgroundColor: palette.gray['+7'],
    color: palette.text.disabled,
  },
  disabledBox: {
    color: palette.text.disabled,
    width: '100%',
    fontWeight: 'bold',
    borderTop: '1px solid',
    borderColor: palette.gray[600],
    paddingTop: 16,
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',
  },
}));

const dealWarning = (
  <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
    <Box>This Deal Memo belongs to a different invoice type.</Box>
    <Box>
      If you need a timecard using this Deal Memo, create a new timecard.
    </Box>
  </Box>
);

const mapState = state => ({
  timecard: getFormValues(FORM_NAME)(state),
  tcStatus: formSelector(state, 'status'),
  previouslyRejected: !!formSelector(state, 'previouslyRejected'),
  allowanceTypes: getAllowanceTypes(state),
  timecardId: formSelector(state, 'id'),
  isTcEditable: getIsTcEditable(state),
});

const mapDispatch = dispatch => ({
  onShowWarning: () =>
    dispatch(
      showAlert({
        message: dealWarning,
      }),
    ),
});

const DMField = props => {
  let { label, value } = props;
  const isAllo = label === 'Allowances';

  //ensure effective date is shown at the start of line
  const isEffective = label === 'Effective Date';

  return (
    <>
      {isEffective && <Box sx={{ width: '100%' }} />}
      <Box sx={{ width: isAllo ? '95%' : '45%' }}>
        <Box sx={{ color: 'text.disabled' }}>{label}</Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: isAllo ? 'column' : 'row',
          }}
        >
          {value}
        </Box>
      </Box>
    </>
  );
};

DMField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

const DealMemoCard = props => {
  const classes = useStyles();
  const {
    isCurrent,
    isDisabled,
    deal,
    onCheckCompatibility,
    timecard,
    tcStatus,
    previouslyRejected,
    onShowWarning,
    allowanceTypes,
    isDiffInvoice,
    timecardId,
    isTcEditable,
  } = props;

  const isTcIncomplete =
    tcStatus === TIMECARD_INCOMPLETE && !previouslyRejected;

  const dealDisplay = getDealMemoDisplay(deal, allowanceTypes);

  const canSwitchToDeal =
    !isCurrent && isTcIncomplete && !isDisabled && isTcEditable;

  return (
    <Paper
      className={clsx(classes.DealMemoCard, {
        [classes.validDealForSwitch]: canSwitchToDeal,
        [classes.isDisabled]: isDisabled,
        [classes.isCurrent]: isCurrent,
      })}
      onClick={() => {
        if (canSwitchToDeal) {
          onCheckCompatibility({ dealMemo: deal, timecard });
        } else if (isDiffInvoice) {
          onShowWarning();
        }
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Box className={classes.loanOut}>
          <Box>{dealDisplay.loanOut} </Box>
          {isCurrent && <CheckCircleIcon color="primary" />}
        </Box>
        <Box className={classes.title}>{dealDisplay.title}</Box>
      </Box>
      {dealDisplay.fields.map((f, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <DMField key={f.label + i} label={f.label} value={f.value} />
      ))}
      {isDiffInvoice && timecardId !== NEW_TC_ID && (
        <Box className={classes.disabledBox}>
          <WarningIcon sx={{ mr: 1, color: 'warning.main' }} />
          {dealWarning}
        </Box>
      )}
    </Paper>
  );
};

DealMemoCard.propTypes = {
  isCurrent: PropTypes.bool.isRequired,
  deal: PropTypes.object.isRequired,
  onCheckCompatibility: PropTypes.func.isRequired,
  timecard: PropTypes.object.isRequired,
  tcStatus: PropTypes.string.isRequired,
  previouslyRejected: PropTypes.bool.isRequired,
  onShowWarning: PropTypes.func.isRequired,
  allowanceTypes: PropTypes.array.isRequired,
  isDiffInvoice: PropTypes.bool.isRequired,
  timecardId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isTcEditable: PropTypes.bool.isRequired,
};

export default compose(connect(mapState, mapDispatch))(DealMemoCard);
