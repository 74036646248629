import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Box } from '@mui/material';

const TooltipText = props => {
  const { text, textProps = {}, tooltipProps = {}, sx } = props;

  const { sx: textSx, suppressWarning, ...restTextProps } = textProps;

  const [title, setTitle] = React.useState('');
  const titleRef = React.useRef(null);
  React.useEffect(() => {
    if (titleRef.current) {
      const elem = titleRef.current;
      if (elem.offsetWidth < elem.scrollWidth) {
        setTitle(text);
      }
    }
  }, [text]);

  if (sx) {
    console.error(
      'TooltipText: sx prop not supported. Use textProps.sx for text:',
      text,
    );
  }

  if (
    (textProps.sx?.textOverflow ||
      textProps.sx?.hidden ||
      textProps.sx?.nowrap) &&
    !suppressWarning
  ) {
    console.warn(
      'TooltipText: textProps.sx may break the tooltip text for text:',
      text,
    );
  }

  return (
    <Tooltip title={title} {...tooltipProps}>
      <Box
        ref={titleRef}
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          ...textSx,
        }}
        {...restTextProps}
      >
        {text}
      </Box>
    </Tooltip>
  );
};

TooltipText.propTypes = {
  text: PropTypes.string,
  textProps: PropTypes.object,
  tooltipProps: PropTypes.object,
  sx: PropTypes.object,
};

export default TooltipText;
