import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Box, LinearProgress, styled, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Header from './Header';
import TabNav from './TabNav';

const useStyles = makeStyles(({ palette }) => ({}));

const TimecardBox = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  padding: '12px',
  backgroundColor: theme.palette.gray['+9'],
}));

const MainPaper = styled(Paper)(({ theme }) => ({
  padding: '12px',
  borderRadius: '12px',
}));

const DesktopEmpTimecard = props => {
  const classes = useStyles();
  const { loading } = props;

  return (
    <TimecardBox className={classes.DesktopEmpTimecard}>
      {loading && <LinearProgress />}
      <MainPaper>
        <Header />
        <Divider />
        <TabNav />
      </MainPaper>
    </TimecardBox>
  );
};

DesktopEmpTimecard.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default DesktopEmpTimecard;
