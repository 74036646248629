import React from 'react';
import AccessAdmin from 'containers/Admin/AccessAdmin';
import { DefaultLayout, Header } from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    authenticated
    key="/admin/accessadmin"
    path="/admin/accessadmin"
    layout={DefaultLayout}
    headers={[Header]}
    component={AccessAdmin}
    title="Access Admin"
  />
);
