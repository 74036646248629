import React from 'react';
import {
  MaxWidthLayout,
  Header,
  AlertHeader,
  ProjectHeader,
} from 'containers/Layout';

import { EnhancedRoute } from 'containers/RoutingSupport';
import { Reviews as SubNav } from 'containers/Nav/Pages';
import { Reviews } from 'containers/Employees/Reviews';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <EnhancedRoute
      authenticated
      key="/projects/reviews"
      id="/projects/reviews"
      path="/projects/:projectId/me/review"
      layout={MaxWidthLayout}
      headers={[Header, ProjectHeader, AlertHeader, SubNav]}
      component={Reviews}
      isLoadProjectActiveUser={true}
      title="Reviews"
    />
  );
};
