import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import moment from 'moment';
// components
import { ConfirmModal } from 'components/Shared/Modals';
// decorator
import withModalDialog from 'decorators/withModalDialog';
// selector
import { getCurrentTimecard } from 'selectors/timecard/common';
// actions
import { hide as hideModal } from 'actions/modalDialog';
import { setCurrentTimecard, onSubmit } from 'actions/timecards';

class ConfirmCopyPrevious extends React.Component {
  render() {
    const { timecardEnd, timecardId, onSubmitTimeCard, onModalHide } =
      this.props;
    const content = (
      <div>
        <Typography>
          {`The times on this timecard are identical to the times you entered for the week ending ${moment(
            timecardEnd,
          ).format(
            'YYYY-MM-DD',
          )}. Are you sure you want to approve this timecard?`}
        </Typography>
      </div>
    );
    return (
      <ConfirmModal
        title={'Identical Times Found'}
        content={content}
        buttonText="Yes, Approve"
        buttonType="secondary"
        hasSubmitAction={true}
        onModalHide={onModalHide}
        onSubmit={() => onSubmitTimeCard(timecardId)}
      />
    );
  }
}

const mapState = state => ({
  timecardId: getCurrentTimecard(state),
});

const mapDispatch = dispatch => ({
  onSubmitTimeCard: timecardId => {
    dispatch(hideModal({ dialog: 'ConfirmCopyPrevious' }));
    dispatch(setCurrentTimecard({ timecardId }));
    dispatch(onSubmit());
  },
  onModalHide: () => {
    dispatch(hideModal({ dialog: 'ConfirmCopyPrevious' }));
  },
});

export default withModalDialog({
  dialog: 'ConfirmCopyPrevious',
})(connect(mapState, mapDispatch)(ConfirmCopyPrevious));
