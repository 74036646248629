import React from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Divider,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { projectProps } from 'components/props/projects';

//actions
import { toggleHide } from 'actions/project';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '30px 0',
    paddingBottom: 20,
  },
  tableRow: {
    height: 20,
  },
  tableCell: {
    padding: '0px 20px',
  },
  bold: {
    fontWeight: 800,
    textAlign: 'right',
  },
  content: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  body: {
    marginBottom: 20,
  },
}));

const mapDispatch = {
  onToggleHide: () => toggleHide(),
};

const ProjectSetup = props => {
  const { project, onToggleHide } = props;
  const classes = useStyles();
  const { producer, client, productionCompany, isHidden = false } = project;

  return (
    <Paper className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.content}
      >
        <Grid item>
          <h4>Settings</h4>
        </Grid>
        <Grid item>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  aria-label="Hide Project"
                  onClick={onToggleHide}
                  checked={isHidden}
                />
              }
              labelPlacement="start"
              label="Hide Project"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="flex-start"
        className={classes.content}
      >
        <Grid item>
          <Table>
            <TableBody>
              <TableRow className={classes.tableRow}>
                <TableCell className={clsx(classes.tableCell, classes.bold)}>
                  Client
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {client?.code}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {client?.name}
                </TableCell>
              </TableRow>
              <TableRow className={classes.tableRow} selected>
                <TableCell className={clsx(classes.tableCell, classes.bold)}>
                  Producer
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {producer?.code}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {producer?.name}
                </TableCell>
              </TableRow>
              <TableRow className={classes.tableRow}>
                <TableCell className={clsx(classes.tableCell, classes.bold)}>
                  Prod Co
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {productionCompany?.code}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {productionCompany?.name}
                </TableCell>
              </TableRow>
              <TableRow className={classes.tableRow} selected>
                <TableCell className={clsx(classes.tableCell, classes.bold)}>
                  Project
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {project.code}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {project.name}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item>
          <Table>
            <TableBody>
              <TableRow className={classes.tableRow}>
                <TableCell className={clsx(classes.tableCell, classes.bold)}>
                  DB Code
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {project.dbCode}
                </TableCell>
              </TableRow>
              <TableRow className={classes.tableRow} selected>
                <TableCell className={clsx(classes.tableCell, classes.bold)}>
                  Production Type
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {project.productionType && project.productionType.name}
                </TableCell>
              </TableRow>
              <TableRow className={classes.tableRow}>
                <TableCell className={clsx(classes.tableCell, classes.bold)}>
                  Media Type
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {project.mediaType && project.mediaType.name}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Paper>
  );
};

ProjectSetup.propTypes = {
  project: projectProps.isRequired,
  onToggleHide: PropTypes.func.isRequired,
};

export default connect(null, mapDispatch)(ProjectSetup);
