import { produce } from 'immer';
import * as actions from 'actions/reviewFlows';
import { DEFAULT_STATUS_SORT } from 'components/Employees/Reviews/SearchTimecards/searchUtils';

const defaultDepartment = {
  associationId: null,
  headApprovalEnabled: false,
  masterId: null,
  name: '',
  rejectionWorkflow: 'standard',
};

const initialState = {
  loading: false,
  saving: false,
  editing: null,
  editingUser: null,
  editingDepartment: null,
  list: [],
  steps: {},
  departments: {},
  departmentStats: {},
  users: {},
  current: {
    dhGrossEnabled: false,
    paDeleteTimecards: false,
    departmentApprove: false,
  },
  newFlowId: null,
  levels: null,
  timecardTemplateOptions: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${actions.loading}`:
        draft.loading = action.loading;
        break;

      case `${actions.saving}`:
        draft.saving = action.saving;
        break;

      case `${actions.store}`:
        draft.list = action.reviewFlows || [];
        break;

      case `${actions.editing}`:
        draft.editing = draft.list.find(i => i.id === action.id);
        break;

      case `${actions.storeSteps}`:
        draft.steps[action.id] = action.steps || [];
        break;

      case `${actions.storeStepUsers}`:
        if (!draft.users[action.id]) {
          draft.users[action.id] = {};
        }
        draft.users[action.id][action.stepId] = action.users || [];
        break;

      case `${actions.storeDepartments}`:
        draft.departments[action.id] = action.departments || [];
        break;

      case `${actions.setNewFlowId}`:
        draft.newFlowId = action.id;
        break;

      case `${actions.editingStepUser}`:
        draft.editingUser = action;
        break;

      case `${actions.editingDepartment}`:
        const department = draft.departments[action.reviewFlowId].find(
          department => department.id === action.departmentId,
        );
        draft.editingDepartment = {
          ...action,
          ...(department || defaultDepartment),
        };
        break;

      case `${actions.storeDeptStats}`:
        draft.departmentStats = {
          [action.departmentId]: action.stats,
        };
        break;

      case `${actions.setHeadViewGross}`:
        draft.current.dhGrossEnabled = action.enabled;
        break;

      case `${actions.setPaDeleteTimecards}`:
        draft.current.paDeleteTimecards = action.enabled;
        break;
      case `${actions.storeReviewLevels}`:
        const sortedLevels = action.levels.sort((a, b) => {
          return (
            DEFAULT_STATUS_SORT.indexOf(a.description) -
            DEFAULT_STATUS_SORT.indexOf(b.description)
          );
        });
        draft.levels = sortedLevels;
        break;
      case `${actions.storeTimecardTemplates}`:
        const data = action.data;
        const options = data.map(t => ({ label: t.name, value: t.id }));
        draft.timecardTemplateOptions = options;
        break;

      default:
    }
  });
