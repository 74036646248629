import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import {
  NO_BLANK_ALLOWED_FIELDS,
  ACCOUNT_CODE_MAX_LENGTH,
} from 'components/Shared/constants/index';
import {
  preventBlanks,
  makeMaskFunc,
  validateAccountMask,
} from 'utils/weekUtils';

import { getCurrentProject } from 'selectors/project';

import TextEntryField from './TextEntryField';

const mapState = (state, ownProps) => ({
  project: getCurrentProject(state),
});

const mapDispatch = {};

const TextCell = props => {
  const {
    name,
    column: { accessor },
    isDayActive,
    project,
    isTcEditable,
    isPartialDealMemo,
  } = props;

  const accountCodeMask = React.useMemo(
    () => validateAccountMask(project && project.accountMask),
    [project],
  );
  const maskingFunc = React.useMemo(
    () => makeMaskFunc(accountCodeMask),
    [accountCodeMask],
  );

  const fieldProps = React.useMemo(() => {
    const fieldProps = { isDayActive, isTcEditable };
    if (accessor === 'accountCode') {
      fieldProps.InputProps = { inputProps: {} };

      fieldProps.InputProps.inputProps.placeholder =
        accountCodeMask !== '~'.repeat(ACCOUNT_CODE_MAX_LENGTH)
          ? accountCodeMask
          : '';
      fieldProps.normalize = value => maskingFunc(value);
    }

    if (NO_BLANK_ALLOWED_FIELDS.includes(accessor)) {
      fieldProps.normalize = value => preventBlanks(value);
    }

    return fieldProps;
  }, [accessor, accountCodeMask, isDayActive, isTcEditable, maskingFunc]);

  return (
    <Field
      name={name}
      component={TextEntryField}
      accessor={accessor}
      disabled={!isDayActive || isPartialDealMemo}
      {...fieldProps}
    />
  );
};

TextCell.propTypes = {
  name: PropTypes.string.isRequired,
  column: PropTypes.object.isRequired,
  isDayActive: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  isTcEditable: PropTypes.bool.isRequired,
  isPartialDealMemo: PropTypes.bool.isRequired,
};

export default compose(connect(mapState, mapDispatch))(TextCell);
