import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { FormControlLabel, Checkbox, Input, FormControl } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
const newStyle = ({ palette }) => ({
  root: {},
  scrollableOptions: {
    overflowX: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
  },
  searchInput: { marginBottom: 8 },
  searchIcon: {
    height: 16,
    width: 16,
  },
  searchInputElm: {
    fontSize: 14,
  },
  clearFilterIcon: {
    color: palette.gray['+6'],
    cursor: 'pointer',
    height: 16,
    width: 16,
  },
  CheckBox: {
    color: `${palette.primary.main} !important`, //need imp to override MUI styling
    padding: '1px 5px 1px 1px',
    alignSelf: 'flex-start',
  },
  childCheckBox: {
    width: `100%`,
    marginLeft: 0,
    padding: '4px 0px',
    '&:hover': {
      backgroundColor: palette.action.hover,
      color: palette.gray.main,
    },
  },
  optionText: {
    fontSize: 14,
    lineHeight: 1.2,
  },
  selected: {
    color: palette.primary.main,
    backgroundColor: palette.primary['+8'],
  },
  unselected: {
    color: palette.gray.main,
    backgroundColor: 'inherit',
  },
  lastYear: {
    marginTop: 40,
    fontSize: 10,
  },
});

function FilterOption(props) {
  const {
    classes,
    enableSearch,
    expanded,
    variant,
    setSearchFilter,
    searchFilter,
    canSelectAll,
    selectAllChecked,
    selectAllClicked,
    dropdownSelection,
    label,
    onSelect,
  } = props;

  const isSearchOrDtsVariant =
    variant === 'searchTimecards' || variant === 'dts';

  return (
    <div>
      {enableSearch && expanded && (
        <div className={classes.searchInput}>
          <FormControl>
            <Input
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              }
              endAdornment={
                isSearchOrDtsVariant && (
                  <InputAdornment position="end">
                    <ClearIcon
                      className={classes.clearFilterIcon}
                      onClick={() => setSearchFilter({ target: { value: '' } })}
                    />
                  </InputAdornment>
                )
              }
              onChange={setSearchFilter}
              value={searchFilter}
              placeholder="Search"
              className={classes.searchInputElm}
              autoFocus
            />
          </FormControl>
        </div>
      )}
      <div className={classes.scrollableOptions}>
        {canSelectAll && dropdownSelection.length > 1 && (
          <FormControlLabel
            className={clsx(classes.childCheckBox, {
              [classes.selected]: selectAllChecked,
              [classes.unselected]: !selectAllChecked,
            })}
            classes={{ label: classes.optionText }}
            control={
              <Checkbox
                className={classes.CheckBox}
                size="small"
                checked={selectAllChecked}
                onClick={() => {
                  selectAllClicked();
                }}
              />
            }
            label="Select all"
          />
        )}
        {_.map(dropdownSelection, (option, i) => {
          const displayLabel = `${option.label} ${
            option.tcCount > 0 ? `(${option.tcCount})` : ''
          }`;

          const isChecked = option.selected ? true : false;
          return (
            <FormControlLabel
              key={`${option.value}${i}`}
              className={clsx(classes.childCheckBox, {
                [classes.selected]: isChecked,
                [classes.unselected]: !isChecked,
              })}
              classes={{ label: classes.optionText }}
              id={`${label}${i}`}
              control={
                <Checkbox
                  className={clsx(classes.CheckBox)}
                  size="small"
                  onClick={() => {
                    onSelect({ index: option.index });
                  }}
                  checked={isChecked}
                />
              }
              label={displayLabel}
            />
          );
        })}
      </div>
    </div>
  );
}

export default withStyles(newStyle)(FilterOption);
