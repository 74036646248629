import React from 'react';
import { useTheme } from '@mui/material';
const CommentIcon = () => {
  const theme = useTheme();
  const { palette } = theme;

  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4998 5.75C10.4998 8.09721 8.59702 10 6.24981 10C5.71142 10 5.19642 9.89989 4.72239 9.71727C4.63572 9.68388 4.59239 9.66718 4.55793 9.65924C4.52403 9.65143 4.4995 9.64816 4.46474 9.64684C4.4294 9.64548 4.39063 9.64949 4.31309 9.65751L1.75258 9.92219C1.50846 9.94742 1.3864 9.96004 1.3144 9.91612C1.25169 9.87786 1.20897 9.81396 1.1976 9.74139C1.18455 9.65806 1.24287 9.5501 1.35953 9.33418L2.17736 7.82041C2.24471 7.69574 2.27839 7.63341 2.29364 7.57347C2.3087 7.51429 2.31234 7.4716 2.30753 7.41071C2.30265 7.34906 2.2756 7.26881 2.2215 7.10831C2.07774 6.6818 1.99981 6.225 1.99981 5.75C1.99981 3.40279 3.9026 1.5 6.24981 1.5C8.59702 1.5 10.4998 3.40279 10.4998 5.75Z"
        stroke={palette.primary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default CommentIcon;
