import React from 'react';
import UsersAllProjects from 'containers/Admin/UsersAllProjects';
import { DefaultLayout, Header } from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    admin
    authenticated
    key="/admin/users-all-projects"
    id="/admin/users-all-projects"
    path="/admin/users-all-projects"
    layout={DefaultLayout}
    headers={[Header]}
    component={UsersAllProjects}
    title="Users All Projects"
  />
);
