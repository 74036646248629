import React from 'react';
import { connect } from 'react-redux';
import withRouter from 'decorators/withRouter';

//components
import ProjectAllowancesComponent from 'components/Admin/Projects/Settings/ProjectAllowances';

//actions
import * as actions from 'actions/projectAllowances';
import { edit as projectSettingsEdit } from 'actions/settings';

//selectors
import {
  getProjectAllowances,
  getProjectAllowancesLoading,
} from 'selectors/projectAllowances';
import { getSettings } from 'selectors/settings';

const mapState = state => ({
  projectAllowancesList: getProjectAllowances(state),
  loading: getProjectAllowancesLoading(state),
  settings: getSettings(state),
});

const mapDispatch = (dispatch, ownProps) => ({
  onFetchData: () => {
    dispatch(actions.fetch());
  },
  onUpdateProjectAllowance: (id, field) => (name, value) => {
    dispatch(actions.edit({ id, field, value, name }));
  },
  selectAllProjectAllowance: (id, field, value) => {
    dispatch(actions.edit({ id, field, value }));
  },
  onUpdate: () => {
    dispatch(actions.update());
  },
  onUpdateIndividual: (value, field) => {
    dispatch(
      projectSettingsEdit({
        projectId: ownProps.match.params.projectId,
        field,
        value,
      }),
    );
  },
  sortProjectAllowance: (sortKey, sortOrder, sortBoolean) => {
    dispatch(actions.sort({ sortKey, sortOrder, sortBoolean }));
  },
});

class ProjectAllowances extends React.Component {
  constructor() {
    super();
    this.state = {
      allVisible: false,
      allMandatory: false,
    };
  }

  componentDidMount() {
    const { onFetchData, sortProjectAllowance } = this.props;
    onFetchData();
    sortProjectAllowance('description', 'asc', false);
  }

  selectAll = key => {
    const {
      selectAllProjectAllowance,
      projectAllowancesList: projectAllowances,
    } = this.props;
    // Select or deselect all the 'employeeVisible values'
    if (key === 'allVisible') {
      projectAllowances.forEach(item =>
        selectAllProjectAllowance(
          item.id,
          'employeeVisible',
          !this.state.allVisible,
        ),
      );
      this.setState({
        allVisible: !this.state.allVisible,
      });
    }
    // select or deselect all the 'employeeMandatory' values that have 'employeeVisible' set to true
    if (key === 'allMandatory') {
      projectAllowances.forEach(item =>
        item && item.employeeVisible
          ? selectAllProjectAllowance(
              item.id,
              'employeeMandatory',
              !this.state.allMandatory,
            )
          : item,
      );
      this.setState({
        allMandatory: !this.state.allMandatory,
      });
    }
  };

  sortProjectAllowances = (sortKey, sortOrder, sortBoolean) => {
    const { sortProjectAllowance } = this.props;
    sortProjectAllowance(sortKey, sortOrder, sortBoolean);
  };

  render() {
    return (
      <ProjectAllowancesComponent
        selectAll={this.selectAll}
        sortProjectAllowances={this.sortProjectAllowances}
        {...this.props}
      />
    );
  }
}

export default withRouter(connect(mapState, mapDispatch)(ProjectAllowances));
