import React from 'react';
import PropTypes from 'prop-types';
import { FullWidthLayout } from 'components/Layout';

const MaxWidthLayout = ({ children, headers, path }) => (
  <FullWidthLayout headers={headers} maxWidth path={path}>
    {children}
  </FullWidthLayout>
);

MaxWidthLayout.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ).isRequired,
  children: PropTypes.node.isRequired,
};

export default MaxWidthLayout;
