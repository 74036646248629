import React from 'react';
import { Schedule } from 'containers/Employees/Reports';
import {
  FullWidthLayout,
  Header,
  ProjectHeader,
  AlertHeader,
} from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';
import { Reports as SubNav } from 'containers/Nav/Pages';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    authenticated
    key="/projects/reports/schedule"
    id="/projects/reports/schedule"
    path="/projects/:projectId/reports/schedule"
    layout={FullWidthLayout}
    headers={[Header, ProjectHeader, AlertHeader, SubNav]}
    component={Schedule}
    title="Reports"
  />
);
