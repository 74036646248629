// import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';

import { fetchRedirectInfo } from 'actions/digitalEdits';

import { useDidMount } from 'utils/customHooks';

const mapDispatch = dispatch => ({
  onInit: token => dispatch(fetchRedirectInfo({ token })),
});

const Redirect = props => {
  const { onInit, match } = props;

  const token = match?.params?.token;
  useDidMount(() => onInit(token));

  return null;
};

Redirect.propTypes = {
  match: PropTypes.object.isRequired,
  onInit: PropTypes.func.isRequired,
};

export default compose(connect(null, mapDispatch))(Redirect);
