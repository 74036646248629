import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'components/images/logos/hours-logo-white.svg';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    verticalAlign: 'middle',
    fontFamily: 'inherit',
  },

  logoIcon: {
    width: 140,
    lineHeight: 64,
  },

  [breakpoints.down('md')]: {
    root: {
      textAlign: 'center',
    },
  },

  [breakpoints.up('md')]: {
    root: {
      paddingLeft: 12,
      textAlign: 'left',
    },
  },
}));

const Brand = ({ isInvoiceApproverOnly }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        PENDO_logo_hidden: isInvoiceApproverOnly,
        PENDO_logo_visible: !isInvoiceApproverOnly,
      })}
    >
      {!isInvoiceApproverOnly && (
        <Link to="/">
          <Logo className={classes.logoIcon} />
        </Link>
      )}
    </div>
  );
};

Brand.propTypes = {
  isInvoiceApproverOnly: PropTypes.bool,
};

export default Brand;
