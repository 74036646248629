const oldBlue = {
  '+10': '#FFFFFF',
  '+9': '#EBF1F7',
  '+8': '#D6E3EF',
  '+7': '#C2D5E6',
  '+6': '#9EBFDC',
  '+3': '#6BA3D8',
  '+2': '#4A91D4',
  '+0': '#3372AD',
  primary: '#3372AD',
  '-3': '#105D98',
  '-5': '#00477D',
  '-7': '#0F2234',
  '-9': '#000000',
  background: '#F9FBFD',
  table: '#F9FDFF',
};
const blue = {
  '+10': '#FFFFFF',
  '+9': '#d9e3fe',
  '+8': '#becffe',
  '+7': '#86a6fd',
  '+6': '#4d7bfd',
  '+3': '#1a55fd',
  '+2': '#2e1eff',
  '+0': '#0000FF',
  primary: '#0000FF',
  '-3': '#0000B3',
  '-5': '#000080',
  '-7': '#00004C',
  '-9': '#000000',
  background: '#f2f5f7',
  table: '#e2f1ff',
};

const gray = {
  '+10': '#FFFFFF',
  '+9': '#F4F4F4',
  '+8': '#E0E0E0',
  '+7': '#C6C6C6',
  '+6': '#A8A8A8',
  '+3': '#8D8D8D',
  '+2': '#6F6F6F',
  '+0': '#525252',
  primary: '#525252',
  '-3': '#393939',
  '-5': '#262626',
  '-7': '#161616',
  '-9': '#000000',
  background: '#F2F5F7',
  table: '#F5FCFB',
};

const shadow = {
  modal: '0px 12px 32px 4px rgba(82, 82, 82, 0.56)',
  interactive: {
    default:
      '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
    hover:
      '0px 0px 1px rgba(82, 82, 82, 0.1), 0px 4px 8px rgba(82, 82, 82, 0.1), 0px 2px 5px rgba(82, 82, 82, 0.1)',
    focus:
      '0px 0px 1px rgba(82, 82, 82, 0.1), 0px 3px 4px rgba(82, 82, 82, 0.1), 0px 2px 5px rgba(82, 82, 82, 0.1)',
    active:
      '0px 0px 4px rgba(82, 82, 82, 0.1), 0px 2px 6px rgba(82, 82, 82, 0.1), 0px 2px 5px rgba(82, 82, 82, 0.1)',
    disabled: '0px 0px 1px rgba(82, 82, 82, 0.4)',
  },
  container: {
    default:
      '0px 1px 4px rgba(82, 82, 82, 0.1), 0px 2px 8px 2px rgba(82, 82, 82, 0.1)',
    hover:
      '0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 8px 2px rgba(82, 82, 82, 0.15)',
  },
};

const misc = {
  link: '#0000ff',
  tableHeader: gray['+9'],
  transparent: 'rgb(0, 0, 0, 0)',
  cellHighlight: '#FFDF81',
  cellHighlightLight: '#FFF8E1',
  headerBottom: '#00004C',
  headerTop: '#0000B3',
  errorBackground: '#ffefcd',
};

const accent = {
  orange: {
    '+4': '#FDEBDD',
    '+3': '#FBCDAB',
    '+2': '#FAB989',
    '+1': '#F9A970',
    '+0': '#F89B57',
    primary: '#F89B57',
    '-1': '#DE8B4E',
    '-2': '#AD6C3C',
    '-3': '#FF6D00',
  },
  rose: {
    '+4': '#FADEEC',
    '+3': '#F3ADCF',
    '+2': '#EE8CBC',
    '+1': '#EB73AE',
    '+0': '#E85BA0',
    primary: '#E85BA0',
    '-1': '#D0518F',
    '-2': '#A13F6F',
  },
  lavender: {
    '+4': '#F1DEFA',
    '+3': '#DEADF3',
    '+2': '#D08CEE',
    '+1': '#CB81ED',
    '+0': '#BD5BE8',
    primary: '#BD5BE8',
    '-1': '#A951D0',
    '-2': '#833FA1',
  },
};

const alert = {
  green: {
    '+3': '#EFFFF4',
    powder: '#EFFFF4',
    '+2': '#ACDCC0',
    light: '#ACDCC0',
    '+1': '#95CAAB',
    hover: '#95CAAB',
    '+0': '#5AB982',
    primary: '#5AB982',
    '-1': '#3E815A',
    dark: '#3E815A',
  },
  yellow: {
    '+3': '#FFF6DA',
    powder: '#FFF6DA',
    '+2': '#FFEAB6',
    light: '#FFEAB6',
    '+1': '#FFCD76',
    hover: '#FFCD76',
    '+0': '#FFB649',
    primary: '#FFB649',
    '-1': '#B77024',
    dark: '#B77024',
  },
  red: {
    '+3': '#FFF0F1',
    powder: '#FFF0F1',
    '+2': '#F9AAAA',
    light: '#F9AAAA',
    '+1': '#E58E8E',
    hover: '#E58E8E',
    '+0': '#F45656',
    primary: '#F45656',
    '-1': '#AA3C3C',
    dark: '#AA3C3C',
  },
};

const button = {
  oldBlue: {
    '+2': '#3D79B1',
    hover: '#3D79B1',
    '+0': '#3372AD',
    primary: '#3372AD',
    '-3': '#285B8A',
    focus: '#285B8A',
    '-5': '#1E4467',
    active: '#1E4467',
    '-7': '#959DA6',
    disabled: '#959DA6',
  },
  blue: {
    '+3': '#1a55fd',
    hover: '#1a55fd',
    '+0': '#0000ff',
    primary: '#0000ff',
    '-3': '#0000b3',
    focus: '#0000b3',
    '-5': '#000080',
    active: '#000080',
    '-7': '#9a9faf',
    disabled: '#9a9faf',
  },
  red: {
    '+3': '#F78080',
    hover: '#F78080',
    '+0': '#F45656',
    primary: '#F45656',
    '-3': '#C14648',
    focus: '#C14648',
    '-5': '#903536',
    active: '#903536',
    '-7': '#B19B9B',
    disabled: '#B19B9B',
  },
};

const badge = {
  green: {
    background: '#ECFDF3',
    light: '#ABEFC6',
    main: '#17B26A',
    dark: '#067647',
  },
  orange: {
    background: '#FFFAEB',
    light: '#FEDF89',
    main: '#F79009',
    dark: '#B54708',
  },
  blue: {
    background: '#F0F9FF',
    light: '#B9E6FE',
    main: '#0BA5EC',
    dark: '#026AA2',
  },
  red: {
    background: '#FEF3F2',
    light: '#FECDCA',
    main: '#F04438',
    dark: '#B42318',
  },
  gray: {
    background: '#F9FAFB',
    light: '#EAECF0',
    main: '#667085',
    dark: '#344054',
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
const cncUI = {
  white: '#FFFFFF',
  black: '#000000',
  primary: {
    blue,
    gray,
    oldBlue,
  },
  shadow,
  misc,
  accent,
  alert,
  button,
  badge,
};

cncUI.default = cncUI.primary.blue;
cncUI.button.default = cncUI.button.blue;

export default cncUI;
