import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

//redux
import * as actions from '../actions';
import { hide as closeModal } from 'actions/modalDialog';

import { getModalParams } from 'selectors/modalDialog';

//local components
import {
  TitleBox,
  ContentBox,
  FooterBox,
  FooterButton,
} from '../Shared/styledComponents';

import withModalDialog from 'decorators/withModalDialog';

import { CONFIRM_MODAL } from '../Modals/modalNames';

const mapState = (state, ownProps) => ({
  modalParams: getModalParams(state, CONFIRM_MODAL),
});

const mapDispatch = dispatch => ({
  onCloseModal: () => dispatch(closeModal({ dialog: CONFIRM_MODAL })),
  onUpdateAdditionalFields: fields =>
    dispatch(actions.updateAdditionalFields({ fields })),
});

const ConfirmModal = props => {
  const { onCloseModal, modalParams } = props;

  const [params] = React.useState(modalParams);

  const {
    actionText = '',
    onConfirm = () => {},
    onCancel = () => {},
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    content,
  } = params;

  const handleClose = () => {
    onCancel();
    onCloseModal();
  };

  const handleConfirm = () => {
    onCloseModal();
    onConfirm();
  };

  return (
    <Box>
      <TitleBox>
        <Box>{`${actionText}`}</Box>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </TitleBox>
      <ContentBox>{content}</ContentBox>
      <FooterBox>
        <FooterButton variant="outlined" onClick={handleClose}>
          {cancelText}
        </FooterButton>
        <FooterButton onClick={handleConfirm}>{confirmText}</FooterButton>
      </FooterBox>
    </Box>
  );
};

ConfirmModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  modalParams: PropTypes.shape({
    content: PropTypes.string,
    actionText: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
  }),
};

export default compose(
  withModalDialog({
    dialog: CONFIRM_MODAL,
    maxWidth: 'md',
    roundedCorners: true,
  }),
  connect(mapState, mapDispatch),
)(ConfirmModal);
