import React from 'react';
import { Field } from 'redux-form';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import { AutoComplete } from 'components/Shared/AutoComplete';
import { TextField } from 'components/Shared/redux';
import {
  makeMaskFunc,
  preventBlanks,
  accountsFieldMaxLength,
} from 'utils/weekUtils';
import makeStyles from '@mui/styles/makeStyles';
import ReadOnlyField from 'components/Shared/Text/ReadOnlyField';

const useStyles = makeStyles(_theme => ({
  tableCell: {
    paddingRight: 20,
    verticalAlign: 'top',
  },
  inputHeight: {
    '& div': {
      height: 36,
    },
  },
}));

export default function AccountCodes(props) {
  const {
    accountCodesEnabled,
    day,
    episodes,
    member,
    payrollSettings,
    disableAccountCodes,
    viewOnly,
    dayError,
    project,
    change,
  } = props;
  const classes = useStyles();

  const episodeList = _.map(episodes, epi => ({
    label: epi.name || epi.code,
    value: epi.id,
  }));

  const getErrorText = field => {
    return dayError && dayError[field]
      ? _.startCase(dayError[field].join(' '))
      : null;
  };

  const getEpisodeName = id => {
    const episode = _.find(episodes, e => e.id === id);
    return episode ? episode.name || episode.code : '-';
  };

  const handleEpisodeChanges = (id, val, prevVal, name) => {
    const episode = _.find(episodes, e => e.id === id);
    if (episode?.series) {
      change(`${member}.series`, episode.series);
    }
    if (episode?.location) {
      change(`${member}.location`, episode.location);
    }
  };

  const accountCodeContent = _.map(accountCodesEnabled, (name, index) => {
    let field = null;
    const helperText = getErrorText(name);
    if (viewOnly) {
      const value =
        name === 'episode' ? getEpisodeName(day[`${name}`]) : day[`${name}`];
      field = (
        <ReadOnlyField
          title={_.startCase(_.toLower(name))}
          value={value || '-'}
        />
      );
    } else {
      if (name === 'episode') {
        field = (
          <AutoComplete
            list={episodeList}
            name={`${member}.episode`}
            placeholder="Episode"
            disabled={disableAccountCodes}
            helperText={helperText}
            error={helperText ? true : false}
            onChange={handleEpisodeChanges}
          />
        );
      } else if (name === 'accountCode') {
        field = (
          <Field
            className={classes.inputHeight}
            component={TextField}
            placeholder="Account Code"
            name={`${member}.accountCode`}
            disabled={disableAccountCodes}
            normalize={makeMaskFunc(payrollSettings.accountCodeMask)}
            helperText={helperText}
            error={helperText ? true : false}
            fullWidth
          />
        );
      } else {
        field = (
          <Field
            className={classes.inputHeight}
            component={TextField}
            placeholder={name}
            name={`${member}.${name}`}
            disabled={disableAccountCodes}
            inputProps={{ maxLength: accountsFieldMaxLength(project, name) }}
            helperText={helperText}
            error={helperText ? true : false}
            fullWidth
            normalize={value =>
              preventBlanks(value, accountsFieldMaxLength(project, name))
            }
          />
        );
      }
    }
    return (
      <Grid
        item
        xs={12}
        md={2}
        key={`${day.date}-${name}`}
        className={classes.tableCell}
      >
        {field}
      </Grid>
    );
  });

  return (
    <Grid container className={props.rowClass}>
      {accountCodeContent}
    </Grid>
  );
}
