import React from 'react';
import { Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(({ breakpoints, palette }) => ({
  root: {
    padding: 10,
  },
  button: {
    fontWeight: 400,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 8,
    marginRight: 8,

    '&:last-child': {
      marginRight: 4,
    },
  },
  saveButton: {
    fontWeight: 400,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 8,
    marginRight: 8,
    '&:last-child': {
      marginRight: 4,
    },
  },
  [breakpoints.down('md')]: {
    root: {
      display: 'flex',
      flexDirection: 'column-reverse',
      marginTop: 2,
    },
    button: {
      marginTop: 4,
      marginBottom: 4,
      marginLeft: 2,
      marginRight: 2,
      width: '100%',
    },
  },
}));

const UserEditActions = props => {
  const { pristine, submitting, onCancel, invalid } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        disabled={submitting}
        variant="outlined"
        color="primary"
        name="cancel"
        onClick={onCancel}
      >
        {'Cancel'}
      </Button>
      <Button
        className={classes.saveButton}
        disabled={pristine || submitting || invalid}
        color="primary"
        variant="contained"
        type="submit"
      >
        {'Save'}
      </Button>
    </div>
  );
};
export default UserEditActions;
