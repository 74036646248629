import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import * as actions from './actions';
import { getStatuses, getUsers } from './selectors';

const useStyles = makeStyles(theme => ({
  statusRow: {
    // width: '100%',
    display: 'flex',
    marginLeft: '0px !important',
    marginRight: '0px !important',
    padding: '5px !important',
    fontSize: '12px',
    fontStyle: 'normal',
    lineHeight: '16px',
    letterSpacing: '0.3199999928474426px',
    textAlign: 'center',
  },
  statusBtn: {
    paddingLeft: '15px !important',
    paddingRight: '15px !important',
    cursor: 'pointer',
    padding: '5px',
    borderRadius: '8px',
    marginLeft: '3px !important',
    marginRight: '10px !important',
    display: 'inline-block',
    marginTop: '5px !important',
    marginBottom: '5px !important',
  },
  disabledState: {
    cursor: 'auto !important',
  },
  inviteBtn: {
    margin: '16px 0',
  },
  countStatus: {
    height: 48,
  },
  allCrewMembers: {
    background: theme.palette.button.primary.background,
    border: `solid 1px ${theme.palette.button.primary.background}`,
    color: theme.palette.button.primary.color,
  },
  uninvited: {
    background: theme.palette.button.lightRed.background,
    border: `solid 1px ${theme.palette.button.lightRed.background}`,
    color: theme.palette.button.lightRed.color,
  },
  invited: {
    background: theme.palette.button.orange.background,
    border: `solid 1px ${theme.palette.button.orange.background}`,
    color: theme.palette.button.orange.color,
  },
  accepted: {
    background: theme.palette.button.lightGreen.background,
    border: `solid 1px ${theme.palette.button.lightGreen.background}`,
    color: theme.palette.button.lightGreen.color,
  },
  deleted: {
    background: theme.palette.button.gray.background,
    border: `solid 1px ${theme.palette.button.gray.background}`,
    color: theme.palette.button.gray.color,
  },
  deselect: {
    background: theme.palette.button.deselect.background,
    color: theme.palette.button.deselect.color,
  },
}));

const mapState = (state, { match }) => ({
  statuses: getStatuses(state),
  users: getUsers(state),
});

const mapDispatch = (dispatch, { location }) => ({
  onSelectStatus: ({ filterName, index }) => {
    dispatch(actions.selectStatus({ filterName, index }));
  },
  onSelectAllStatus: ({ filterName }) => {
    dispatch(actions.selectAllStatus({ filterName }));
  },
});
function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

const CrewStatus = props => {
  const classes = useStyles();
  const {
    statuses,
    filters,
    setFilter,
    onSelectStatus,
    onSelectAllStatus,
    users,
    globalFilter,
    setGlobalFilter,
    editingHotCost,
    gotoPage,
    pageIndex,
  } = props;

  const [allcrewCount, setallcrewCount] = useState(0);
  const [searchTextRef, setSearchTextRef] = useState(globalFilter);
  useEffect(() => {
    setallcrewCount(
      statuses.reduce((total, curr) => {
        if (!curr.name) {
          // to handle pending until we get to draft status
          return total + 0;
        }
        return total + curr.count;
      }, 0),
    );
  }, [setallcrewCount, statuses]);
  useEffect(() => {
    let statusFilter = filters.find(status => status.id === 'status') || {};
    const selectedStatuses = statuses
      .filter(status => status.selected === true)
      .map(status => status.key);
    let filterMatch =
      !_.isEmpty(statusFilter) &&
      statusFilter?.value.length === selectedStatuses.length &&
      statusFilter?.value.every(function (status) {
        return selectedStatuses.includes(status);
      });
    if (selectedStatuses.length !== 4 && !filterMatch) {
      setFilter('status', selectedStatuses);
    }
    if (
      statusFilter?.value?.length === 3 &&
      selectedStatuses.length === 4 &&
      !filterMatch
    ) {
      setFilter('status', selectedStatuses);
    }
  }, [statuses, filters, setFilter]);

  const handleStatusClick = (status, index) => {
    if (status.key === 'all') {
      onSelectAllStatus({ filterName: 'status' });
      setFilter('status', []);
    } else {
      onSelectStatus({ filterName: 'status', index });
    }
    if (pageIndex !== 0) {
      gotoPage(0);
    }
  };
  useEffect(() => {
    let selectedStatuses = statuses
      .filter(status => status.selected === true)
      .map(status => status.key);

    if (selectedStatuses.length > 0 && selectedStatuses.length < 4) {
      setFilter('status', selectedStatuses);
    }
    if (searchTextRef) {
      setGlobalFilter(searchTextRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    setSearchTextRef(globalFilter);
  }, [globalFilter]);

  return (
    <div className={classes.statusRow}>
      <div
        className={clsx(classes.statusBtn, 'pendo_status_btn_all', {
          [classes.disabledState]: editingHotCost,
          [classes.allCrewMembers]:
            statuses[0].selected &&
            statuses[1].selected &&
            statuses[2].selected &&
            statuses[3].selected,
          [classes.deselect]: !(
            statuses[0].selected &&
            statuses[1].selected &&
            statuses[2].selected &&
            statuses[3].selected
          ),
        })}
        onClick={() => !editingHotCost && handleStatusClick({ key: 'all' })}
      >
        All crewmembers ({allcrewCount > 0 ? allcrewCount : '0'})
      </div>
      {statuses.map((status, i) => {
        if (!status.name) {
          return null;
        }
        return (
          <div
            className={clsx(
              classes.statusBtn,
              `pendo_status_btn_${status.name}`,
              {
                [classes.disabledState]: editingHotCost,
                [classes[camelize(status.name)]]: status.selected,
                [classes.deselect]: !status.selected,
              },
            )}
            onClick={() => {
              !editingHotCost && handleStatusClick(status, i);
            }}
            key={status.name}
          >
            {`${status.name} (${status.count ? status.count : '0'})`}
          </div>
        );
      })}
    </div>
  );
};

// export default CrewStatus;
export default compose(connect(mapState, mapDispatch))(CrewStatus);
