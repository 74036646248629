const globalStyle = ({ palette, typography, breakpoints }) => ({
  '@global': {
    // '@font-face':[ //how to add custom font files
    //   {
    //     fontFamily: 'Inter',
    //     src: `url("/fonts/Inter-Regular.woff2") format("woff2"),
    //         url("/fonts/Inter-Regular.woff")  format("woff")`, // full path: public/fonts/Inter-Regular.woff2
    //   },
    // ],

    body: {
      background: palette.background.default,
      fontFamily: typography.fontFamily,
      margin: 0,
      padding: 0,
      color: palette.getDefaultColor(),
      '-webkit-font-smoothing': 'antialiased',
      '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
      fontSize: '14px/18px',
    },
    a: {
      textDecoration: 'none',
      color: palette.getDefaultColor(),
    },
    '.pendo-resource-center-badge-notification-bubble': {
      backgroundColor: `${palette.badge.green.main} !important`,
      top: '-2px !important',
      left: '18px !important',
      width: '14px !important',
      height: '14px !important',
      borderRadius: '16px !important',
      border: '1px solid #fff !important',
      [breakpoints.down('sm')]: {
        top: '6px !important',
        left: '30px !important',
      },
    },
    '.pendo-notification-bubble-unread-count': {
      fontSize: '10px !important',
      fontWeight: '500 !important',
      right: '-7px !important',
    },
  },
});

export default globalStyle;
