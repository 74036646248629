import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import CaretsUp from 'assets/icons/CaretsUp';
import CaretsDown from 'assets/icons/CaretsDown';
import Carets from 'assets/icons/Carets';
import { SORTABLE_COLUMNS } from './CrewListUtils';

const useStyles = makeStyles(({ palette }) => ({
  root: {},
  tableHeaderCell: {
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: palette.misc.tableHeader,
    textAlign: 'left',
    padding: '10px 15px',
    height: 16,
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: 16,
    borderBottom: `1px solid ${palette.primary.main}`,
  },
  tableHeaderRow: {},
  cell: {
    cursor: 'pointer',
  },

  endColumn: {
    width: '100%',
  },
  noPadding: {
    padding: 0,
  },
  title: { display: 'flex', height: '1em' },
  carrot: {
    color: palette.primary.main,
    height: '0.8em',
  },
}));

const HeaderRow = props => {
  const { headerGroups, onSort } = props;
  const classes = useStyles();
  return (
    <thead className={classes.root}>
      {headerGroups.map(headerGroup => (
        <tr
          className={classes.tableHeaderRow}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map(column => {
            if (SORTABLE_COLUMNS.includes(column.id)) {
              return (
                <th
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({ title: undefined }),
                  )}
                  className={clsx(classes.tableHeaderCell, {
                    [classes.endColumn]: column.id === 'comments',
                  })}
                  key={column.id}
                >
                  {column.id === 'selection'
                    ? column.render('Header')
                    : column.render(() => (
                        <span
                          className={classes.cell}
                          id={column.id}
                          onClick={() => {
                            onSort(column);
                          }}
                        >
                          <span className={classes.title}>
                            {column.Header}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <CaretsDown className={classes.carrot} />
                              ) : (
                                <CaretsUp className={classes.carrot} />
                              )
                            ) : (
                              <Carets className={classes.carrot} />
                            )}
                          </span>
                        </span>
                      ))}
                </th>
              );
            } else {
              return (
                <th
                  className={clsx(classes.tableHeaderCell, {
                    [classes.endColumn]: column.id === 'comments',
                  })}
                  key={column.id}
                >
                  {column.id === 'selection'
                    ? column.render('Header')
                    : column.render(() => (
                        <span className={classes.cell} id={column.id}>
                          <span className={classes.title}>{column.Header}</span>
                        </span>
                      ))}
                </th>
              );
            }
          })}
        </tr>
      ))}
    </thead>
  );
};

export default HeaderRow;
