import React from 'react';
import {
  MaxWidthLayout,
  Header,
  AlertHeader,
  ProjectHeader,
} from 'containers/Layout';

import { EnhancedRoute } from 'containers/RoutingSupport';
import { Timecard } from 'containers/Employees/Timecards';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    authenticated
    key="/projects/me/timecardv1"
    id="/projects/me/timecardv1"
    path="/projects/:projectId/me/timecardsv1/:timecardId"
    layout={MaxWidthLayout}
    headers={[Header, ProjectHeader, AlertHeader]}
    component={Timecard}
  />
);
