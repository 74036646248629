import React from 'react';
import { PropTypes } from 'prop-types';
import { ModalContent, ModalTitle } from 'components/Shared/ModalDialog';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import { Box, LinearProgress, Tooltip, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ClearIcon from '@mui/icons-material/Clear';

// decorators
import withModalDialog from 'decorators/withModalDialog';
//actions
import { hide as hideModal } from 'actions/modalDialog';
import * as actions from 'actions/digitalEdits';
//selectors
import {
  getExistingComments,
  getIsLoadingComments,
} from 'selectors/digitalEdits';
import { getModalParams } from 'selectors/modalDialog';

//components
import ExistingComment from './ExistingComment';
import Comments from './Comments';

import {
  useDidMount,
  useWillUnmount,
  useBroadcastChannel,
} from 'utils/customHooks';

const MODAL_NAME = 'DigitalEditsCommentsLite';

const useStyles = makeStyles(({ palette }) => ({
  contentBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 18,
    fontWeight: 500,
    margin: 12,
  },
  readOnly: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  noCommentBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: palette.text.disabled,
  },
}));

const mapState = state => ({
  comments: getExistingComments(state),
  loading: getIsLoadingComments(state),
  modalParams: getModalParams(state, MODAL_NAME),
});

const mapDispatch = (dispatch, { searchInvoicePage }) => ({
  onCloseModal: () => {
    dispatch(hideModal({ dialog: MODAL_NAME }));
  },
  onInit: invoiceId => {
    if (searchInvoicePage) {
      //set currentInvoiceId while modal is open
      dispatch(actions.setCurrentInvoiceId({ currentInvoiceId: invoiceId }));
    }
    dispatch(actions.fetchComments({ invoiceId }));
    dispatch(actions.lockComment({ invoiceId }));
  },
  onUnmount: () => {
    if (searchInvoicePage) {
      // delay clearing the comments and currentInvoiceId to allow the
      // comments component to save the last comment and unlock the comments
      setTimeout(() => {
        dispatch(actions.storeComments({ comments: [] }));
        dispatch(actions.setCurrentInvoiceId({ currentInvoiceId: '' }));
      }, 450);
    }
  },
});

const DigitalEditsCommentsLite = props => {
  const { onCloseModal, loading, comments, onInit, modalParams, onUnmount } =
    props;
  const classes = useStyles();

  const { readOnly, invoiceId } = modalParams;

  useDidMount(() => {
    onInit(invoiceId);
  });

  useWillUnmount(() => {
    onUnmount();
  });

  const bc = useBroadcastChannel(invoiceId);

  React.useEffect(() => {
    if (bc) {
      bc.onmessage = e => {
        if (e.data.action === 'insertReply') {
          bc.postMessage({
            action: 'insertReplyText',
            payload: e.data.payload,
          });
        }
      };
    }
  }, [bc]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 2 }}>
      {readOnly && (
        <React.Fragment>
          <ModalTitle fullWidth>
            {loading && <LinearProgress />}
            <Box className={classes.readOnly}>
              Comments
              <Tooltip title="Close">
                <IconButton onClick={onCloseModal}>
                  <ClearIcon sx={{ color: 'text.disabled', float: 'right' }} />
                </IconButton>
              </Tooltip>
            </Box>
          </ModalTitle>

          <ModalContent shrinkTop>
            <Box sx={{ height: 450, width: 700, overflow: 'auto' }}>
              {comments.map(comment => (
                <ExistingComment
                  key={comment.commentId}
                  comment={comment}
                  showReply={false}
                />
              ))}
              {comments.length === 0 && (
                <Box className={classes.noCommentBox}>No comments</Box>
              )}
            </Box>
          </ModalContent>
        </React.Fragment>
      )}

      {readOnly === false && (
        <React.Fragment>
          <Box className={classes.contentBox}>
            Comments
            <Tooltip title="Close">
              <IconButton onClick={onCloseModal}>
                <ClearIcon sx={{ color: 'text.disabled', float: 'right' }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ height: 550, width: 700, mb: 2 }}>
            <Comments />
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

DigitalEditsCommentsLite.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  comments: PropTypes.array.isRequired,
  onInit: PropTypes.func.isRequired,
  modalParams: PropTypes.object.isRequired,
  onUnmount: PropTypes.func.isRequired,
};

export default compose(
  withModalDialog({ dialog: MODAL_NAME, maxWidth: 'lg' }),
  connect(mapState, mapDispatch),
)(DigitalEditsCommentsLite);
