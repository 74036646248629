import createAction from './createAction';

export const loading = createAction('loading/reviews');
export const reset = createAction('reset/reviews');
export const loadingCounts = createAction('loadingCounts/reviews');
export const loadingTimecards = createAction('loading/reviews/timecards');
export const loadingReviewBatchesTimecards = createAction(
  'loading/reviews/btaches/timecards',
);

export const fetchTimecardCount = createAction('fetchTimecardCount/reviews');
export const storeTimecardCount = createAction('storeTimecardCount/reviews');
export const fetchReviewBatchesOnly = createAction(
  'fetchReviewBatchesOnly/reviews',
);

export const storeReviewBatchesOnly = createAction(
  'storeReviewBatchesOnly/reviews',
);
export const fetchReviewBatches = createAction('fetchReviewBatches/reviews');
export const storeReviewBatches = createAction('storeReviewBatches/reviews');

export const fetchTimecardsByBatchId = createAction(
  'fetchTimecardsByBatchId/reviews',
);
export const storeBatchLoadingByID = createAction(
  'storeBatchLoadingByID/reviews',
);
export const storeBatchDownloadingByID = createAction(
  'storeBatchDownloadingByID/reviews',
);
export const storeTimecardsBatches = createAction(
  'storeTimecardsBatches/reviews',
);
export const storeBatchTimecards = createAction('storeBatchTimecards/reviews');

export const fetchApprovalFlows = createAction('reviews/fetchApprovalFlows');
export const storeApprovalFlows = createAction('reviews/storeApprovalFlows');
export const setReviewFromURL = createAction('setReviewFromURL/reviews');

export const reviewDownloadTimecardsReport = createAction(
  'reviews/reviewDownloadTimecardsReport',
);
export const reviewTimecard = createAction('reviews/viewTimecard');
export const addModalComment = createAction('reviews/addModalComment');
export const fetchComments = createAction('reviews/fetchComments');
export const storeComments = createAction('reviews/storeComments');
export const clearLocalComments = createAction('reviews/clearLocalComments');
export const loadingComments = createAction('reviews/loadingComments');
export const setCurrentApprovalFlow = createAction(
  'reviews/setCurrentApprovalFlow',
);
export const loadApprovalFlow = createAction('reviews/loadApprovalFlow');
export const storeApprovalFlow = createAction('reviews/store/approvalFlow');

export const reviewApprovalFlow = createAction('reviews/review/approvalFlow');
export const reviewBatch = createAction('reviews/review/batch');

export const setSelectedFlows = createAction(
  'reviews/setSelectedApprlovaFlows',
);
export const processingApproval = createAction('reviews/processingApproval');
export const submitAllAsRejected = createAction('reviews/submitAllAsRejected');
export const setBatchLockStatus = createAction('reviews/setBatchLockStatus');
export const updatingBatchLockStatus = createAction(
  'updating/reviews/batchLockStatus',
);
export const updateBatchLockStatus = createAction(
  'update/reviews/batchLockStatus',
);
export const toggleShowPaid = createAction('reviews/toggleShowPaid');
export const EditTimecardApprovalFlow = createAction(
  'reviews/EditTimecardapprovalFlow',
);
