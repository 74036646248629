import React, { useState, useEffect } from 'react';
import SearchBar from './SearchBar';
import { Button } from '@mui/material';
import CrewStatus from './CrewStatus';
import makeStyles from '@mui/styles/makeStyles';
import AdditionalFields from './AdditionalFieldsContainer';
import { ALWAYS_HIDDEN_COLUMNS } from './CrewListUtils';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  deleteBtn: {
    color: palette.error.main,
    border: `1px solid ${palette.error.main}`,
  },
  searchBar: {
    flexGrow: 7,
    marginTop: 8,
    marginLeft: 15,
    height: 40,
    boxShadow: `inset 0px -1px 0px ${palette.gray['+3']}`,
  },
  actionBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: '0 0 16px 0',
    display: 'flex',
  },
  exportBtn: {
    margin: '0 0 16px 0',
    display: 'flex',
  },
  countStatus: {
    display: 'flex',
    height: 60,
    alignItems: 'center',
  },
  topBar: {
    display: 'flex',
    width: '98vw',
  },
  hotCostBtns: {
    flexGrow: 0,
    paddingTop: 9,
  },
  addyFields: {
    flexGrow: 1,
    marginLeft: 15,
  },
}));
const CrewListHeader = props => {
  const {
    useAsyncDebounce,
    preGlobalFilteredRows,
    setGlobalFilter,
    globalFilter,
    onInviteNonEmployee,
    onDeleteEmployees,
    filters,
    selectedRows = [],
    setFilter,
    deleteBtnVisible,
    inviteBtnVisible,
    onExportUsers,
    currentUser,
    editingHotCost,
    setEditingHotCost,
    hiddenColumns,
    setHiddenColumns,
    tableColumns,
    isHotCostEnabled,
    setEditedHotCostFields,
    pristine,
    dirtyFields,
    form,
    submitting,
    gotoPage,
    pageIndex,
    hoursPlusEnabled,
  } = props;
  const classes = useStyles();
  const [btnDisabled, setBtnDisabled] = useState(false);
  useEffect(() => {
    setEditedHotCostFields(dirtyFields);
  }, [dirtyFields, setEditedHotCostFields]);

  return (
    <div className={classes.root}>
      <div className={classes.actionBtnContainer}>
        <div className={classes.actionBtn}>
          {inviteBtnVisible && hoursPlusEnabled && (
            <Button
              color={'primary'}
              variant="contained"
              onClick={() => {
                onInviteNonEmployee(
                  selectedRows.length > 0
                    ? {
                        selected: selectedRows.map(selected => selected.values),
                      }
                    : null,
                );
              }}
              disabled={editingHotCost}
            >
              {selectedRows.length > 0
                ? `Invite selected users`
                : `Invite new non-employees`}
            </Button>
          )}

          {deleteBtnVisible && (
            <Button
              align="left"
              variant="outlined"
              onClick={() => {
                onDeleteEmployees(
                  selectedRows.map(selected => selected.values.id),
                );
              }}
              disabled={
                selectedRows.length === 0 ||
                (selectedRows.length > 0 && editingHotCost)
              }
              className={classes.deleteBtn}
            >
              {`Delete`}
            </Button>
          )}
        </div>
        <div className={classes.exportBtn}>
          {currentUser?.isAdmin && hoursPlusEnabled && (
            <Button
              onClick={() => {
                onExportUsers({
                  selected: selectedRows.map(
                    selected => selected.values.worksightId,
                  ),
                });
              }}
              disabled={
                selectedRows.length === 0 ||
                (selectedRows.length > 0 && editingHotCost)
              }
              color="primary"
              align="right"
              variant="outlined"
            >
              {`Resync`}
            </Button>
          )}
        </div>
      </div>
      <div className={classes.topBar}>
        {isHotCostEnabled && hoursPlusEnabled && (
          <div className={classes.hotCostBtns}>
            {!editingHotCost ? (
              <Button
                color={'primary'}
                variant="contained"
                onClick={() => {
                  setEditingHotCost(!editingHotCost);
                  setBtnDisabled(false);
                  setHiddenColumns(ALWAYS_HIDDEN_COLUMNS);
                }}
              >
                Edit Hot Cost Budgets
              </Button>
            ) : (
              <>
                <Button
                  color={'primary'}
                  variant="contained"
                  type="submit"
                  disabled={btnDisabled || pristine || submitting}
                >
                  Save
                </Button>
                <Button
                  color={'primary'}
                  variant="outlined"
                  onClick={() => {
                    setEditingHotCost(!editingHotCost);
                    form.reset();
                  }}
                  disabled={btnDisabled}
                >
                  Cancel
                </Button>
                <Button
                  color={'primary'}
                  variant="text"
                  onClick={() => {
                    form.reset();
                  }}
                  disabled={btnDisabled}
                >
                  Clear all changes
                </Button>
              </>
            )}
          </div>
        )}

        <div className={classes.searchBar}>
          <SearchBar
            useAsyncDebounce={useAsyncDebounce}
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
            autoFocus={true}
            editingHotCost={editingHotCost}
            gotoPage={gotoPage}
            pageIndex={pageIndex}
          />
        </div>
        {isHotCostEnabled && (
          <div className={classes.addyFields}>
            <AdditionalFields
              hiddenColumns={hiddenColumns}
              setHiddenColumns={setHiddenColumns}
              tableColumns={tableColumns}
            />
          </div>
        )}
      </div>

      <div className={classes.countStatus}>
        <CrewStatus
          setFilter={setFilter}
          filters={filters}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
          editingHotCost={editingHotCost}
          gotoPage={gotoPage}
          pageIndex={pageIndex}
        />
      </div>
    </div>
  );
};

export default CrewListHeader;
