import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import { Box, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { getIsMobile, getIsTcEditable } from '../selectors';

const useStyles = makeStyles(({ palette }) => ({
  StickyFooter: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    height: '52px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    borderTop: `1px solid ${palette.grey[300]}`,
    backgroundColor: palette.grey[50],
  },
  link: {
    color: palette.text.primary,
    textDecorationColor: palette.text.primary,
  },
}));

const mapState = state => ({
  isMobile: getIsMobile(state),
  isTcEditable: getIsTcEditable(state),
});

const mapDispatch = dispatch => ({});

const StickyFooter = props => {
  const classes = useStyles();
  const { isMobile, isTcEditable } = props;

  if (!isTcEditable) return null;

  return (
    <Box className={classes.StickyFooter}>
      {!isMobile && (
        <>
          <Box sx={{ fontWeight: '700' }}>
            This page requests personal information.
          </Box>
          If you have questions, please review the
        </>
      )}
      <Link
        className={classes.link}
        href="https://www.castandcrew.com/privacy-policy/"
        target="_blank"
      >
        CA Notice at Collection.
      </Link>
    </Box>
  );
};

StickyFooter.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isTcEditable: PropTypes.bool.isRequired,
};

export default compose(connect(mapState, mapDispatch))(StickyFooter);
