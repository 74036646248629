import React from 'react';
import { PageHeader } from 'components/Nav';
import StickyHeader from 'components/Nav/StickyHeader';
import { connect } from 'react-redux';
import FadeInScroll from 'components/Shared/FadeInScroll';

import { fetch, fetchProjectSearch } from 'actions/project';
import { currentUser } from 'selectors/session';

const mapStateToProps = state => ({
  activeUser: currentUser(state),
});

const mapDispatch = dispatch => ({
  onApplyFiltering: search => {
    if (search.length >= 3) dispatch(fetchProjectSearch({ search }));
    else if (search.length === 0) {
      dispatch(fetch());
    }
  },
});

class ProjectHeader extends React.Component {
  render() {
    return (
      <>
        <PageHeader
          title="Projects"
          searchBarPlaceHolder="Search Projects by ID, Name, Production Company or Coordinator Email"
          showSearch
          autoFocus
          onApplyFiltering={this.props.onApplyFiltering}
          {...this.props}
        />
        <FadeInScroll customThreshold={260}>
          <StickyHeader
            title="Projects"
            searchBarPlaceHolder="Search Projects by ID, Name, Production Company or Coordinator Email"
            showSearch
            {...this.props}
          />
        </FadeInScroll>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatch)(ProjectHeader);
