import React, { useState, useEffect } from 'react';

import { CircularProgress, Backdrop } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    color: 'black',
    backgroundColor: 'rgba(244, 244, 244, 0.5)',
    zIndex: 5001,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    fontSize: 14,
    fontWeight: 700,
  },
}));

let intervalTracker;

const SavingBackdrop = props => {
  const { draftUpdates, totalTimecards } = props;
  const classes = useStyles();

  const [showBackDrop, setShowBackDrop] = useState(false);
  const [savePercent, setSavePercent] = useState(0);

  const calcSavePercent = (totalTimecards, draftUpdates, showBackDrop) => {
    const ratio = draftUpdates / totalTimecards;
    const percent = Math.round(ratio * 100);
    const calcVal = isNaN(percent) ? 100 : percent;
    return calcVal;
  };

  useEffect(() => {
    return () => {
      clearInterval(intervalTracker);
    };
  }, []);

  const upBy1 = () => {
    setSavePercent(val => {
      if (val < 97) return Math.min(97, val + 1);
      return val;
    });
  };

  useEffect(() => {
    if (totalTimecards > 0) {
      if (!showBackDrop) {
        intervalTracker = setInterval(upBy1, 500);
        setShowBackDrop(true);
      }
      setSavePercent(calcSavePercent(totalTimecards, draftUpdates));
    } else {
      setSavePercent(100);
      setShowBackDrop(false);
      clearInterval(intervalTracker);
    }
  }, [totalTimecards, draftUpdates, showBackDrop]);

  return (
    <Backdrop className={classes.backdrop} open={!!showBackDrop}>
      <CircularProgress />
      Saving...{savePercent}% completed.
    </Backdrop>
  );
};

export default SavingBackdrop;
