const mapError = props => {
  const {
    hasHelperText = true,
    meta: { error, warning, dirty, touched } = {},
    input,
    showErrorUntouched,
    ...others
  } = props;

  let hasError = error || warning;
  if (!showErrorUntouched) {
    //default behavior is to show error only if a field is touched or dirty.  Removing that for WTC so errors show right away.
    hasError = hasError && (touched || dirty);
  }

  const errorProps = hasError
    ? {
        ...input,
        ...others,
        error: Boolean(error || warning),
      }
    : { ...input, ...others };

  if (hasError && hasHelperText) {
    errorProps.helperText = error || warning;
  }

  return errorProps;
};

export default mapError;
