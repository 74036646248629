const duplicateActionCheck = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default (name, defaultPayload = {}) => {
  if (process.env.NODE_ENV !== 'production') {
    if (duplicateActionCheck[name]) {
      console.error(
        `Error - Multiple actions with name:"${name}" created. Each action name should be unique`,
      );
    } else {
      duplicateActionCheck[name] = true;
    }
  }

  const action = (payload = {}) => {
    const data = { type: action.typeName };
    const mergedPayload = { ...defaultPayload, ...payload };
    if (mergedPayload.type) {
      console.error(
        `Error - Action payload cannot contain a property named "type"`,
      );
    }
    for (let key in mergedPayload) {
      data[key] = payload[key];
    }
    return data;
  };
  action.typeName = name;
  action.toString = () => name;
  return action;
};
