import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'utils/helperFunctions';
import { connect } from 'react-redux';
import {
  getFormSyncErrors,
  formValueSelector,
  isPristine,
  getFormValues,
} from 'redux-form';
import clsx from 'clsx';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

//Actions
import { undo, approveTimecard, setActionType } from 'actions/wtc';
import { show as showModal } from 'actions/modalDialog';

//selectors
import { getSettings } from 'selectors/settings';
import {
  canSubmitTimecard,
  isWTCUpdating,
  getHasWorkTimeChanges,
  isCurrentUserUPM,
  isCurrentUserPA,
  isFieldUPMEnabled,
  isSaveDisabledForPA,
  isTimecardEmpty,
  getAutoCoding,
  getNextApprovers,
  getShowForceResubmitModal,
  getProducerWWStatus,
  getEmployeeWWStatus,
  isSavingTimecard,
  getPendingCalculation,
  getCanRenderTimecard,
} from 'selectors/wtc';

//Icons
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import UpdateIcon from '@mui/icons-material/Update';
import CheckCircle from '@mui/icons-material/CheckCircle';

import { RECALL_BUTTON_TC_STATUS } from 'utils/weekUtils';

//constants
import { WTC_PA_DELETABLE_TIMECARD_STATUSES } from 'components/Shared/constants/index';

//Utils
import { WTC_FORM_NAME, RESUBMIT_WORKFLOW } from 'utils/wtcWeekUtils';

const useStyles = makeStyles(({ palette }) => ({
  WTCHeaderButtons: {},
  subtitleBold: {
    paddingLeft: 5,
    fontWeight: 500,
  },
  btnGroupWithSubtitle: {
    marginLeft: 10,
  },
  btnSubtitle: {
    padding: '12px 10px 0px',
    height: 32,
  },

  buttonGroup: {
    display: 'flex',
    marginTop: 13,
  },
  deleteButton: {
    whiteSpace: 'nowrap',
    color: palette.error.main,
    border: `1px solid ${palette.error.main}`,
    '&:hover': {
      color: palette.common.white,
      border: `1px solid ${palette.error.main}`,
      backgroundColor: palette.error.main,
    },
  },
  headerButton: {
    maxHeight: 40,
    marginRight: 8,
    padding: '5px 8px',
  },
  icon: {
    marginRight: 8,
    backgroundColor: `${palette.primary.background}`,
    border: `3px ${palette.primary.background} solid`,
    borderRadius: 12,
    fontSize: 17,
  },
  submitButton: {
    whiteSpace: 'nowrap',
    height: '-webkit-fill-available',
  },
  submitContainerBox: {
    flexGrow: 1,
  },
  submitTip: {
    fontSize: '.75em',
  },
  undoButton: {
    marginLeft: 0,
    marginRight: 8,
  },
}));

const selector = formValueSelector(WTC_FORM_NAME);

const mapState = state => ({
  autoCoding: getAutoCoding(state),
  employeeWWStatus: getEmployeeWWStatus(state),
  formValues: getFormValues(WTC_FORM_NAME)(state),
  formSyncErrors: getFormSyncErrors(WTC_FORM_NAME)(state),
  hasWorkTimeChanges: getHasWorkTimeChanges(state),
  isUPM: isCurrentUserUPM(state),
  isPA: isCurrentUserPA(state),
  isTimecardEmpty: isTimecardEmpty(state),
  nextApprovers: getNextApprovers(state),
  pristine: isPristine(WTC_FORM_NAME)(state),
  producerWWStatus: getProducerWWStatus(state),
  saveDisabledForPA: isSaveDisabledForPA(state),
  settings: getSettings(state),
  showForceResubmitModal: getShowForceResubmitModal(state),
  userCanSubmitTimecard: canSubmitTimecard(state),
  upmEnabled: isFieldUPMEnabled(state),
  workflowAction: selector(state, 'workflowAction'),
  wtcUpdating: isWTCUpdating(state),
  pendingCalculation: getPendingCalculation(state),
  saving: isSavingTimecard(state),
  canRenderTimecard: getCanRenderTimecard(state),
});

const mapDispatch = dispatch => ({
  onDelete: () => {
    const dialog = 'DeleteTimecardWTC';
    dispatch(showModal({ dialog }));
  },
  onRevert: () => {
    dispatch(undo());
  },

  onShowProducerWWAlert: action => {
    dispatch(setActionType({ actionType: action }));
    dispatch(showModal({ dialog: 'ProducerWWWarning', maxWidth: 'md' }));
  },
  onShowEmployeeWWAlert: action => {
    dispatch(setActionType({ actionType: action }));
    dispatch(showModal({ dialog: 'EmployeeWWWarning', maxWidth: 'md' }));
  },
  onApprovalWithoutChange: () => dispatch(approveTimecard()),
});

const WTCHeaderButtons = props => {
  const classes = useStyles();
  const {
    onForceCommentAction,
    onSaveTimecard,
    onSetAutoCoding,
    //
    //mappedProps
    //
    autoCoding,
    employeeWWStatus,
    formSyncErrors,
    isUPM,
    isPA,
    isTimecardEmpty,
    nextApprovers,
    pristine,
    producerWWStatus,
    saveDisabledForPA,
    settings,
    showForceResubmitModal,
    userCanSubmitTimecard,
    upmEnabled,
    wtcUpdating,
    //dispatched
    onDelete,
    onRevert,
    onShowEmployeeWWAlert,
    onShowProducerWWAlert,
    onApprovalWithoutChange,
    isMediumScreen = false,
    pendingCalculation,
    saving,
    workflowAction,
    canRenderTimecard,
    formValues,
  } = props;

  //local state to disable button immediately if redux state is not updated yet
  const [localSaving, setLocalSaving] = React.useState(false);
  const [showSaveSuccess, setShowSaveSuccess] = React.useState(false);
  const saveBtnRef = React.useRef(null);

  React.useEffect(() => {
    if (localSaving) {
      setTimeout(() => setLocalSaving(false), 850);
    }
  }, [localSaving]);

  React.useEffect(() => {
    if (showSaveSuccess) {
      setTimeout(() => setShowSaveSuccess(false), 1500);
    }
  }, [showSaveSuccess]);

  const savingRef = React.useRef(saving);

  React.useEffect(() => {
    if (savingRef.current && !(localSaving || saving)) {
      setShowSaveSuccess(true);
    }
    savingRef.current = saving || localSaving;

    if (savingRef.current) {
      //prevent shrinking when text changes
      const currentWidth = saveBtnRef.current.offsetWidth;
      saveBtnRef.current.style.minWidth = `${currentWidth}px`;
    }
  }, [localSaving, saving]);

  const [hasClickedSave, setHasClickedSave] = React.useState(false);
  React.useEffect(() => {
    if (saving) setHasClickedSave(true);
  }, [saving]);
  React.useEffect(() => {
    if (!canRenderTimecard) setHasClickedSave(false);
  }, [canRenderTimecard]);

  const showCalculatingIcon = pendingCalculation && hasClickedSave;

  const onReject = useCallback(() => {
    onForceCommentAction('reject');
  }, [onForceCommentAction]);

  const onResubmit = useCallback(() => {
    onForceCommentAction('resubmit');
  }, [onForceCommentAction]);

  const onRecall = useCallback(() => {
    onForceCommentAction('recall');
  }, [onForceCommentAction]);

  const onSaveOrSubmit = useCallback(
    action => {
      if (!producerWWStatus && !employeeWWStatus) {
        if (action === 'submit') {
          onApprovalWithoutChange();
        } else if (action === 'save') {
          if (isUPM && !pristine) {
            onForceCommentAction('save');
          } else if (isPA && showForceResubmitModal) {
            onForceCommentAction('resubmitSave');
          } else {
            onSaveTimecard();
            setLocalSaving(true);
          }
        } else if (action === 'autoCode') {
          if (isUPM) {
            onForceCommentAction('autoCode');
          } else {
            onSetAutoCoding(autoCoding, formValues.details.length);
          }
        }
      }
      if (producerWWStatus) {
        onShowProducerWWAlert(action);
      } else if (employeeWWStatus) {
        onShowEmployeeWWAlert(action);
      }
    },
    [
      autoCoding,
      employeeWWStatus,
      isPA,
      isUPM,
      onForceCommentAction,
      onSaveTimecard,
      onSetAutoCoding,
      onShowEmployeeWWAlert,
      onShowProducerWWAlert,
      onApprovalWithoutChange,
      pristine,
      producerWWStatus,
      showForceResubmitModal,
      formValues?.details?.length,
    ],
  );

  //
  //********************  SHOW / HIDE button logic   *******************
  //
  let showSubmitBtn = false;
  let showResubmitBtn = false;
  let showRecallBtn = false;
  let showRejectBtn = false;
  let showAutoCodingBtn = false;
  let showSaveBtn = false;
  let showDeleteBtn = false;
  let showRevertBtn = false;

  const isEmergencyTc = !!formValues.emergencyType;
  const isResubmitWorkFlow = workflowAction === RESUBMIT_WORKFLOW;

  const hPlusStatus = formValues.hPlusStatus;

  if (isPA) {
    switch (hPlusStatus) {
      case 'draft':
        showSaveBtn = true;
        showRevertBtn = true;
        break;

      case 'pending_pa_review':
        showResubmitBtn = isResubmitWorkFlow && !isEmergencyTc;
        showSubmitBtn = !showResubmitBtn;
        showRejectBtn =
          formValues.emergencyType === null && !isResubmitWorkFlow;
        showSaveBtn = true;
        showAutoCodingBtn = true;
        showRevertBtn = true;
        break;
      case 'pending_upm_review':
      case 'sent_to_cnc':
        showRecallBtn = true;
        break;
      case 'incomplete':
      case 'pending_employee_review':
      case 'pending_dh_review':
      case 'processed':
      case 'paid':
        //status that don't show any buttons for PA
        break;
      default:
        break;
    }

    showDeleteBtn =
      WTC_PA_DELETABLE_TIMECARD_STATUSES.includes(formValues.hPlusStatus) &&
      (settings?.paDeleteTimecards || formValues?.emergencyType);
  } else if (isUPM) {
    if (userCanSubmitTimecard) {
      showSubmitBtn = true;
      showRejectBtn = true;
      showAutoCodingBtn = settings.autoCodingEnable;
      showSaveBtn = true;
      showRevertBtn = true;
    }
  } else {
    //ERROR - only UPM and PA should be on WTC
  }

  //
  //**************    ENABLE / DISABLE LOGIC  *******************
  //

  const hasTCErrors = (formValues.errors || []).some(
    e => e && e.level === 'Error',
  );

  const isRecallBtnEnabled =
    isPA &&
    RECALL_BUTTON_TC_STATUS.includes(formValues.hPlusStatus) &&
    ((formValues.invoice?.invoiceType === 'D' &&
      formValues.batchStatus?.toLowerCase() !== 'closed') ||
      !formValues.invoice);

  const formHasErrors = formSyncErrors.valid === false;

  const rejectDisabled = wtcUpdating || userCanSubmitTimecard === false;

  const resubmitDisabled =
    !pristine || formValues.hPlusStatus !== 'pending_pa_review';

  const saveBtnDisabled = isUPM
    ? wtcUpdating || rejectDisabled || pristine || upmEnabled || isTimecardEmpty
    : saveDisabledForPA || isTimecardEmpty;

  const autoCodeBtnDisabled = wtcUpdating || rejectDisabled || upmEnabled;

  const submitDisabled =
    rejectDisabled ||
    wtcUpdating ||
    formHasErrors ||
    hasTCErrors ||
    !pristine ||
    pendingCalculation;

  //MISC logic
  const resubmitTitle = resubmitDisabled
    ? 'The timecard must be saved before it can be resubmitted'
    : '';

  const makeNextApproverText = useCallback(
    (str, index = 0) => (
      <Typography
        key={index}
        variant="caption"
        className={classes.subtitleBold}
      >
        {str}
      </Typography>
    ),
    [classes.subtitleBold],
  );

  const submitTo = useMemo(
    () =>
      nextApprovers && nextApprovers.length > 0
        ? nextApprovers.map((approver, index) => {
            let str = `${approver.lastName}, ${approver.firstName}`;
            if (index !== nextApprovers.length - 1) str += ' / ';
            return makeNextApproverText(str, index);
          })
        : makeNextApproverText('C&C'),
    [makeNextApproverText, nextApprovers],
  );
  const submitApprovalTitle =
    submitDisabled && (!pristine || pendingCalculation)
      ? 'Timecard must be re-calculated before approval'
      : submitDisabled && (formHasErrors || hasTCErrors)
      ? 'Timecard has errors'
      : '';
  return (
    <Box className={classes.WTCHeaderButtons}>
      <div className={classes.btnGroupWithSubtitle}>
        <div className={classes.buttonGroup}>
          {showSubmitBtn && (
            <Tooltip title={submitApprovalTitle}>
              <Box className={classes.submitContainerBox}>
                <Button
                  variant="contained"
                  color="primary"
                  className={clsx(classes.headerButton, classes.submitButton)}
                  disabled={submitDisabled}
                  onClick={() => onSaveOrSubmit('submit')}
                >
                  {isMediumScreen ? 'Submit' : 'Submit for Approval'}
                </Button>
              </Box>
            </Tooltip>
          )}
          {showResubmitBtn && (
            <Tooltip title={resubmitTitle}>
              <Box className={classes.submitContainerBox}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={resubmitDisabled}
                  className={clsx(classes.headerButton, classes.submitButton)}
                  onClick={() => onResubmit()}
                >
                  {isMediumScreen ? 'Resubmit' : 'Resubmit to Employee'}
                </Button>
              </Box>
            </Tooltip>
          )}
          {showRecallBtn && (
            <Tooltip
              placement="bottom-start"
              title="This feature allows you to return the timecard back to your queue."
            >
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  className={clsx(classes.headerButton, classes.submitButton)}
                  disabled={!isRecallBtnEnabled}
                  onClick={() => onRecall()}
                >
                  Recall
                </Button>
              </span>
            </Tooltip>
          )}
          {showRejectBtn && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.headerButton}
              disabled={rejectDisabled || !pristine}
              onClick={() => onReject()}
            >
              Reject
            </Button>
          )}
          {showAutoCodingBtn && (
            <Button
              variant="outlined"
              color="primary"
              key="autoCode"
              disabled={
                autoCodeBtnDisabled ||
                (formValues.details && formValues.details.length === 0)
              }
              className={classes.headerButton}
              onClick={() => onSaveOrSubmit('autoCode')}
            >
              {isMediumScreen
                ? 'Auto-Coding'
                : autoCoding
                ? 'Enable Auto-Coding'
                : 'Disable Auto-Coding'}
            </Button>
          )}
          {showSaveBtn && (
            <Button
              ref={saveBtnRef}
              variant="outlined"
              color="primary"
              key="save"
              disabled={saveBtnDisabled || localSaving}
              className={classes.headerButton}
              onClick={() => onSaveOrSubmit('save')}
            >
              {showSaveSuccess ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Saved <CheckCircle sx={{ color: 'success.main' }} />
                </Box>
              ) : isMediumScreen ? (
                'Save/Calc'
              ) : (
                'Save/Calculate'
              )}
            </Button>
          )}

          {showDeleteBtn && (
            <Tooltip
              title="This timecard can be deleted based on its current status. Deleting timecards should only be used as a last resort."
              placement="top"
            >
              <Button
                variant="outlined"
                color="primary"
                key="delete"
                className={clsx(classes.headerButton, classes.deleteButton)}
                onClick={() => onDelete()}
              >
                <DeleteIcon /> {!isMediumScreen && 'Delete'}
              </Button>
            </Tooltip>
          )}
          {showRevertBtn && (
            <Button
              variant="text"
              color="primary"
              className={classes.undoButton}
              onClick={() => onRevert()}
              disabled={saveBtnDisabled}
            >
              <ReplayIcon className={classes.icon} fontSize="small" />
              {!isMediumScreen && <span>Revert</span>}
            </Button>
          )}
        </div>
        <div className={classes.btnSubtitle}>
          {showCalculatingIcon ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <UpdateIcon
                sx={{ height: 16, width: 16, color: 'primary.main' }}
              />
              <Typography key={'sub'} variant="caption">
                Calculating timecard...{' '}
              </Typography>
            </Box>
          ) : (
            !submitDisabled && (
              <div>
                <Typography key={'sub'} variant="caption">
                  Submitting to:
                </Typography>
                {submitTo}
              </div>
            )
          )}
        </div>
      </div>
    </Box>
  );
};

WTCHeaderButtons.propTypes = {
  timecard: PropTypes.object,
  onForceCommentAction: PropTypes.func,
  onSaveTimecard: PropTypes.func,
  onSetAutoCoding: PropTypes.func,
  //mappedProps
  autoCoding: PropTypes.bool.isRequired,
  employeeWWStatus: PropTypes.bool.isRequired,
  formSyncErrors: PropTypes.object.isRequired,
  isUPM: PropTypes.bool.isRequired,
  isPA: PropTypes.bool.isRequired,
  isTimecardEmpty: PropTypes.bool.isRequired,
  nextApprovers: PropTypes.array.isRequired,
  pristine: PropTypes.bool.isRequired,
  producerWWStatus: PropTypes.bool.isRequired,
  saveDisabledForPA: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired,
  showForceResubmitModal: PropTypes.bool.isRequired,
  userCanSubmitTimecard: PropTypes.bool.isRequired,
  upmEnabled: PropTypes.bool.isRequired,
  wtcUpdating: PropTypes.bool.isRequired,
  //dispatched
  onDelete: PropTypes.func.isRequired,
  onRevert: PropTypes.func.isRequired,
  onShowEmployeeWWAlert: PropTypes.func.isRequired,
  onShowProducerWWAlert: PropTypes.func.isRequired,
  onApprovalWithoutChange: PropTypes.func.isRequired,
  isMediumScreen: PropTypes.bool,
  pendingCalculation: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  workflowAction: PropTypes.string,
};

// export default WTCHeaderButtons;

export default compose(connect(mapState, mapDispatch))(WTCHeaderButtons);
