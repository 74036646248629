import React, { Component } from 'react';
import { connect } from 'react-redux';

//selectors
import { getDropDownOptions } from 'selectors/project';

// actions
import { onSelect, fetchFilteredProjects } from 'actions/project';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ filterName }) => {
  const mapState = state => ({
    dropdownSelection: getDropDownOptions(filterName)(state),
  });
  const mapDispatch = dispatch => {
    return {
      onSelect: ({ index }) => {
        dispatch(onSelect({ index, filterName }));
        dispatch(fetchFilteredProjects({ index, filterName }));
      },
    };
  };
  return BaseComponent => {
    class withProjectListFilter extends Component {
      render() {
        return <BaseComponent {...this.props} variant="projectListFilter" />;
      }
    }
    return connect(mapState, mapDispatch)(withProjectListFilter);
  };
};
