import React from 'react';
import {
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
  TableRow,
  TableCell,
  Table,
  TableBody,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { weekEndingFormat } from 'utils/formatDate';
import EditIcon from '@mui/icons-material/Edit';

//components
import TableHeader from './TableHeader';
import AddAdminModal from './AddAdminModal';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: '1.1em',
  },
  panelBorderTop: {
    borderTop: `1px solid ${palette.gray['+8']}`,
  },
  buttonText: {
    marginLeft: '5px',
    marginTop: '2px',
  },
  buttonsContent: {
    display: 'flex',
    justifyContent: 'right',
  },
  tableContent: {
    marginTop: '20px',
    height: 525,
    overflowY: 'auto',
  },
  evenRow: {
    backgroundColor: palette.primary.table,
  },
  row: {
    '&>td': {
      fontSize: '0.95rem',
    },
  },
  disabledRow: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
}));
const AccessAdmin = props => {
  const {
    loading,
    adminUsers = [],
    onSortAdminUsers,
    onAddEditAdminUser,
    currentUser,
    onRunAdminUsersReport,
  } = props;
  const [expand, setExpand] = React.useState(true);
  const classes = useStyles();
  const headers = [
    {
      columnId: 'fullName',
      label: 'Name',
      sortable: true,
    },
    {
      columnId: 'roles',
      label: 'Role',
      sortable: true,
    },
    {
      columnId: 'date',
      label: 'Date Added',
      sortable: true,
    },
    {
      columnId: 'email',
      label: 'Email Address',
      sortable: true,
    },
    {
      columnId: 'actions',
      label: '',
      sortable: false,
    },
  ];

  const editButtonHandler = (isCurrentUser, row) => {
    if (!isCurrentUser) {
      onAddEditAdminUser(row);
    }
  };

  const contentRows =
    adminUsers?.length === 0 ? (
      <TableRow>
        <TableCell colSpan={5}>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            No results found
          </Typography>
        </TableCell>
      </TableRow>
    ) : (
      adminUsers?.map((row, index) => {
        const isCurrentUser =
          currentUser?.email.toLowerCase() === row.email.toLowerCase();
        return (
          <TableRow
            key={`${row.email}`}
            className={clsx(classes.row, {
              [classes.evenRow]: index % 2 === 0,
              [classes.disabledRow]: isCurrentUser,
            })}
          >
            <TableCell>{row.fullName}</TableCell>
            <TableCell>{row.roles}</TableCell>
            <TableCell>{weekEndingFormat(row.dateAdded)}</TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell sx={{ width: 200 }}>
              <EditIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => editButtonHandler(isCurrentUser, row)}
              />
            </TableCell>
          </TableRow>
        );
      })
    );
  return (
    <React.Fragment>
      <Accordion expanded={expand} className={classes.root}>
        <AccordionSummary
          className={classes.panelBorderTop}
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setExpand(!expand)}
        >
          <Typography className={classes.heading}>Admin Management</Typography>
        </AccordionSummary>
        {loading && <LinearProgress />}
        <AccordionDetails>
          <div className={classes.bodyContent}>
            <div className={classes.buttonsContent}>
              <Button variant="contained" onClick={() => onAddEditAdminUser()}>
                <PersonAddIcon />
                <span className={classes.buttonText}>Add New Admin User</span>
              </Button>
              <Button
                variant="outlined"
                onClick={() => onRunAdminUsersReport()}
              >
                <CloudDownloadIcon />
                <span className={classes.buttonText}>Export Admins</span>
              </Button>
            </div>

            <div className={classes.tableContent}>
              <Table className={classes.table}>
                <TableHeader
                  columns={headers}
                  onSortAdminUsers={onSortAdminUsers}
                />
                <TableBody>{contentRows}</TableBody>
              </Table>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <AddAdminModal />
    </React.Fragment>
  );
};

export default AccessAdmin;
