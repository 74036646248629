import Root from './Root';
import Admin from './Admin';
import NotFound from './NotFound';
import Projects from './Projects';
import Profiles from './Profiles';
import Allowance from './Allowance';
import Project from './Project';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Root,
  Admin,
  Projects,
  Profiles,
  Allowance,
  Project,
  // this needs to be last
  NotFound,
};
