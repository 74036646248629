import React, { Component } from 'react';
import { connect } from 'react-redux';

//selectors
import {
  getFilteredDropdown,
  getCustomFilter,
  getSelectedCount,
} from 'selectors/digitalEdits';
// actions
import {
  setSearchFilter,
  onSelectFilter,
  onSelectAllFilters,
} from 'actions/digitalEdits';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ filterName }) => {
  const mapState = state => {
    return {
      searchFilter: getCustomFilter(state, filterName),
      dropdownSelection: getFilteredDropdown(state, filterName),
      selectedCount: getSelectedCount(state, filterName),
    };
  };

  const mapDispatch = dispatch => {
    return {
      setSearchFilter: e => {
        const value = e.target.value;
        dispatch(setSearchFilter({ value: value, filterName }));
      },
      onSelectAll: () => {
        dispatch(onSelectAllFilters({ filterName }));
      },
      onSelect: ({ index }) => {
        dispatch(onSelectFilter({ index, filterName }));
      },
    };
  };

  return BaseComponent => {
    class withDigitalEditsMenuFilter extends Component {
      static displayName = `withDigitalEditsMenuFilter(${filterName})`;

      render() {
        return <BaseComponent {...this.props} variant="digitalEdits" />;
      }
    }

    return connect(mapState, mapDispatch)(withDigitalEditsMenuFilter);
  };
};
