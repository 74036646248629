import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

export const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
  },
  space: {
    marginBottom: '10px',
  },
  searchBarContainer: {
    background: palette.gray['+9'],
  },
  search: {
    position: 'relative',
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    width: 72,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputInput: {
    paddingTop: 8,
    paddingRight: 8,
    paddingBottom: 8,
    paddingLeft: 80,
    width: '100%',
    color: palette.gray['+3'],
    borderBottom: `2px solid ${palette.gray['+3']}`,
    '&:focus': {
      borderBottom: `2px solid ${palette.primary.main}`,
    },
  },
}));
const inputProps = {
  type: 'search',
  autoComplete: 'off',
};
const SearchFilter = props => {
  const { onApplyFiltering, searchValue } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.searchBarContainer}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            value={searchValue}
            placeholder={'Type to Filter'}
            classes={{ input: classes.inputInput }}
            inputProps={inputProps}
            fullWidth
            onChange={onApplyFiltering}
            autoFocus={false}
          />
        </div>
      </div>
      <div className={classes.space}></div>
    </div>
  );
};
export default SearchFilter;
