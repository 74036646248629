import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  FormControlLabel,
  Checkbox,
  TextField,
  ClickAwayListener,
  Divider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { canadianLabel } from 'utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'flex',
    padding: 0,
    height: '2em',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  container: {
    position: 'relative',
  },
  dropDown: {
    position: 'absolute',
    width: '200px',
    maxHeight: '270px',
    right: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    zIndex: 1000,
    border: `1px solid ${theme.palette.gray.primary}`,
    backgroundColor: theme.palette.gray.background,
    boxShadow: '2px 2px 2px gray',
    padding: 5,

    '&::-webkit-scrollbar': {
      display: 'none',
    },

    transform: 'scale(1, 0)',
    transformOrigin: 'top',
    transition: `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut} 0ms`,
  },
  dropDownOpen: {
    transform: 'scale(1, 1)',
    transformOrigin: 'top',
  },
  underline: {
    '&::after': {
      borderBottom: `2px solid ${theme.palette.primary.main} !important`,
    },
  },
  childCheckBox: {
    width: `100%`,
    paddingLeft: 16,
    textAlign: 'left',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}30 !important`,
      color: theme.palette.primary.main,
    },
  },
  indent: {
    paddingLeft: 40,
  },
  flexDisplay: {
    display: 'flex',
  },
  grayIcons: {
    color: theme.palette.gray.main,
    cursor: 'pointer',
  },
  expandIcons: {
    color: theme.palette.primary.main,
    transition: 'transform 0.2s',
  },
  expand: {
    transform: 'rotate(180deg)',
  },
  dropDownWidth: {
    width: '100%',
  },
}));

/**
 * Display a dropdown of names with checkboxes next to them
 * @param {int} [selectedCount=0]
 * @param {string} [checkedProperty="visible"]  - property for checked or not
 * @param {func} onClickField called when box clicked: Signature: ({obj}field,{bool} newCheckedValue)
 * @param {object/array} fieldsList { fieldId: { see below } } fieldId must match columnId
 * @param {string} fieldsList.fieldId.label Required - label (displayed in capitalize)
 * @param {bool}  fieldsList.fieldId.indent Optional - Indent this field
 * @param {string} fieldsList.fieldId.type Optional - if 'divider' will display a break in the field list
 */

const FieldCheckboxDropDown = props => {
  const {
    fieldsList = [],
    selectedCount = 0,
    onClickField,
    label,
    checkedProperty = 'visible',
    disabled = false,
    pendoClass = '',
    type = null,
    dropDownWidth = false,
  } = props;

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const [filter, setFilter] = useState('');
  const inputRef = useRef(null);
  const onFocus = () => {
    if (!isOpen) {
      setFilter('');
      setIsOpen(true);
    }
  };

  const handleFilterClear = e => {
    e.preventDefault();
    setFilter('');
    inputRef.current.focus();
  };

  const onKeyDown = e => {
    if (e.key === 'Escape') {
      setFilter('');
    }
  };
  const typeLabel = type ? `${type} ` : '';
  useEffect(() => {
    const value = isOpen
      ? filter
      : selectedCount === 0
      ? '' //causes title to zoom down and take up place
      : `${selectedCount} ${typeLabel}selected`;

    setFilter(value);
  }, [filter, isOpen, selectedCount, typeLabel]);

  let filteredList = _.filter(fieldsList, field =>
    field?.label?.toLowerCase().includes(filter?.toLowerCase()),
  );

  if (_.isEmpty(filteredList)) {
    filteredList = [{ type: 'noFieldsMsg', label: 'No fields found' }];
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (isOpen) {
          setFilter('');
          setIsOpen(false);
        }
      }}
    >
      <div className={classes.container}>
        <div className={classes.flexDisplay}>
          <TextField
            fullWidth
            label={label}
            InputProps={{
              classes: {
                underline: classes.underline,
              },
              inputProps: {
                className: clsx(
                  pendoClass === 'wtc' ? 'PENDO_wtc_additionalFields ' : '',
                  classes.input,
                ),
                ref: inputRef,
                autoComplete: 'off',
              },
              placeholder: 'Type to filter',
              endAdornment: (
                <div className={classes.flexDisplay}>
                  {!isOpen && <SearchIcon className={classes.grayIcons} />}
                  {isOpen && (
                    <ClearIcon
                      className={classes.grayIcons}
                      onClick={handleFilterClear}
                    />
                  )}

                  <ExpandMoreIcon
                    className={clsx(classes.expandIcons, {
                      [classes.expand]: isOpen,
                    })}
                  />
                </div>
              ),
            }}
            onFocus={onFocus}
            onChange={e => setFilter(e.target.value)}
            value={filter}
            onKeyDown={onKeyDown}
          />
        </div>

        <div
          className={clsx(classes.dropDown, {
            [classes.dropDownWidth]: dropDownWidth,
            [classes.dropDownOpen]: isOpen,
          })}
        >
          {_.map(filteredList, (field, i) => {
            const indent = field && field.indent;
            const isNoFieldsMsg = !!(field.type === 'noFieldsMsg');
            const pendoClassAdditionalFields =
              pendoClass === 'wtc'
                ? `PENDO_wtc_additionalFieldsOption${field.label?.replaceAll(
                    ' ',
                    '',
                  )}`
                : '';
            if (field?.type === 'divider') {
              return <Divider key={i} />;
            } else if (isNoFieldsMsg) {
              return field.label;
            } else {
              return (
                <FormControlLabel
                  key={i}
                  className={clsx(
                    pendoClassAdditionalFields,
                    classes.childCheckBox,
                    {
                      [classes.indent]: indent,
                    },
                  )}
                  disabled={disabled}
                  control={
                    <Checkbox
                      onClick={e => onClickField(field, e.target.checked)}
                      checked={field[checkedProperty] ? true : false}
                      color="primary"
                      disabled={disabled}
                    />
                  }
                  label={canadianLabel(field.label)}
                />
              );
            }
          })}
        </div>
      </div>
    </ClickAwayListener>
  );
};

FieldCheckboxDropDown.propTypes = {
  fieldsList: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  selectedCount: PropTypes.number,
  onClickField: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  checkedProperty: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FieldCheckboxDropDown;
