import React, { useRef, useState } from 'react';
import { useSingleDoubleClick } from 'utils/customHooks';
import { connect } from 'react-redux';
import { compose } from 'utils/helperFunctions';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import SortIcon from '@mui/icons-material/Sort';
import FilterListIcon from '@mui/icons-material/FilterList';

// actions
import { setTextFilter, clearAllFilters, setSortBy } from 'actions/wtc';

// // selector
import { getTextFilter, getFiltersActive, getSortBy } from 'selectors/wtc';

//components
import Search from './Search';
import Filters from './Filters';
import Sort from './Sort';

import { SORT_BYS } from 'utils/wtcWeekUtils';

const useStyles = makeStyles(theme => ({
  SearchFilterSort: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filerIcons: {
    display: 'flex',
  },
  icon: {
    height: 24,
    width: 24,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 2,
    margin: '10px 5px',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },

  iconRotate: {
    transform: 'scaleY(-1)',
  },
  iconActive: {
    '& path': {
      fill: theme.palette.primary.background,
    },
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

const mapStateToProps = state => ({
  textFilter: getTextFilter(state),
  filtersActive: getFiltersActive(state),
  sortBy: getSortBy(state),
});

const mapDispatchToProps = dispatch => ({
  onSetTextFilter: ({ filter }) => {
    dispatch(setTextFilter({ filter }));
  },

  onClearFilters: () => dispatch(clearAllFilters()),
  onSetSortBy: sortBy => {
    dispatch(setSortBy({ sortBy }));
  },
});

const SearchFilterSort = props => {
  const classes = useStyles();
  const {
    textFilter,
    onSetTextFilter,
    filtersActive,
    sortBy,
    onSetSortBy,
    onClearFilters,
    resetBulkAction,
  } = props;

  const { anySelected } = filtersActive;

  const filterAnchor = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const sortAnchor = useRef(null);
  const [isSortOpen, setIsSortOpen] = useState(false);

  const filterTitle = anySelected
    ? 'Filters enabled. Double Click to clear all.'
    : '';
  const singleFilterClick = () => {
    setIsFilterOpen(true);
  };
  const doubleFilterClick = () => {
    setIsFilterOpen(false);
    onClearFilters();
  };
  const handleClickFilter = useSingleDoubleClick(
    singleFilterClick,
    doubleFilterClick,
  );

  const setSortByWrapper = sortBy => {
    onSetSortBy(sortBy);
    resetBulkAction();
  };

  const singleSortClick = () => {
    setIsSortOpen(true);
  };
  const doubleSortClick = () => {
    setIsSortOpen(false);
    setSortByWrapper(SORT_BYS[0]);
  };

  const handleClickSort = useSingleDoubleClick(
    singleSortClick,
    doubleSortClick,
  );

  const sortTitle =
    sortBy.id !== 0 ? 'Sorting enabled. Double Click to set default' : '';
  return (
    <div className={classes.SearchFilterSort}>
      <Search textFilter={textFilter} setTextFilter={onSetTextFilter} />
      <div className={classes.filerIcons}>
        <Tooltip arrow title={filterTitle}>
          <FilterListIcon
            ref={filterAnchor}
            onClick={handleClickFilter}
            className={clsx('PENDO_wtcDrawerFilter', classes.icon, {
              [classes.iconActive]: anySelected,
            })}
          />
        </Tooltip>

        <Tooltip arrow title={sortTitle}>
          <SortIcon
            ref={sortAnchor}
            onClick={handleClickSort}
            className={clsx('PENDO_wtcDrawerSort', classes.icon, {
              [classes.iconSpaced]: true,
              [classes.iconRotate]: sortBy.sortOrder !== 'decSort',
              [classes.iconActive]: sortBy.id !== 0,
            })}
          />
        </Tooltip>

        {/* Double clicking highlights nearby text. Adding this to catch that offscreen*/}
        {/* Otherwise the name of the first timecard gets highlighted */}
        <span
          className="doubleClickDecoy"
          style={{ position: 'absolute', left: -14 }}
        >
          .
        </span>
      </div>
      {/* Popovers */}
      <Filters
        anchorElRef={filterAnchor}
        isOpen={isFilterOpen}
        setIsOpen={setIsFilterOpen}
        filtersActive={filtersActive}
      />
      <Sort
        anchorElRef={sortAnchor}
        isOpen={isSortOpen}
        setIsOpen={setIsSortOpen}
        sortBy={sortBy}
        onSetSortBy={setSortByWrapper}
        sortOptions={SORT_BYS}
      />
    </div>
  );
};

SearchFilterSort.propTypes = {
  textFilter: PropTypes.string.isRequired,
  onSetTextFilter: PropTypes.func.isRequired,
  filtersActive: PropTypes.object.isRequired,
  sortBy: PropTypes.object.isRequired,
  onSetSortBy: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  resetBulkAction: PropTypes.func.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SearchFilterSort,
);
