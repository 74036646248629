import React from 'react';
import { TeamTimecard } from 'containers/Employees/CrewTimecard';

import {
  FullWidthLayout,
  Header,
  ProjectHeader,
  AlertHeader,
} from 'containers/Layout';
import { EnhancedRoute } from 'containers/RoutingSupport';
import { CrewTimecardWeek as SubNav } from 'containers/Nav/Pages';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <EnhancedRoute
    exact
    authenticated
    key="/projects/crew/timecards/weekEndings/department/team"
    path="/projects/:projectId/timecards/week-ending/:weekEnding/department/:departmentId/team/:id"
    layout={FullWidthLayout}
    headers={[Header, ProjectHeader, AlertHeader, SubNav]}
    component={TeamTimecard}
    title="Crew Timecard"
  />
);
//projects/449/timecards/week-ending/2019-08-24/department/17/team/4
