import React from 'react';
import { TableHead, TableCell, TableRow, Checkbox } from '@mui/material';

import CaretsUp from 'assets/icons/CaretsUp';
import CaretsDown from 'assets/icons/CaretsDown';
import Carets from 'assets/icons/Carets';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette }) => ({
  tableHead: {
    color: palette.text.secondary,
    backgroundColor: palette.primary['+8'],
    fontWeight: 700,
  },
  tableCell: {
    cursor: 'pointer',
    padding: '0px 5px',
    fontSize: 12,
    fontWeight: 500,
    width: '33%',
    color: palette.primary.main,
    borderBottom: `1px solid ${palette.primary.main}`,
    '& > svg': {
      verticalAlign: 'bottom',
    },
  },
  carrot: {
    height: '0.8em',
  },
}));

const TableHeader = props => {
  const classes = useStyles();
  const {
    columns = [],
    onSelectAll,
    timecardIds,
    onRequestSort,
    order,
    orderBy,
  } = props;

  //Determine Select All checkbox State
  let checkIndeterminate;
  let allChecked = true;
  for (const tcHeader in timecardIds) {
    if (Object.hasOwnProperty.call(timecardIds, tcHeader)) {
      const selected = timecardIds[tcHeader];
      if (checkIndeterminate === undefined) {
        checkIndeterminate = selected;
      } else if (checkIndeterminate !== selected) {
        checkIndeterminate = true;
      }

      if (selected === false) allChecked = false;
    }
  }
  if (allChecked) checkIndeterminate = false;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const headerRows = columns.map(column => {
    if (column?.id === 'checkbox') {
      return (
        <TableCell
          className={classes.tableCell}
          padding="checkbox"
          key={`check-all-${column?.id}`}
          align="left"
        >
          <Checkbox
            checked={allChecked}
            indeterminate={checkIndeterminate}
            color="primary"
            onChange={onSelectAll}
            inputProps={{ 'aria-label': 'Select All' }}
          />
        </TableCell>
      );
    } else if (column?.sortable) {
      return (
        <TableCell
          className={classes.tableCell}
          id={column?.id}
          key={column?.id}
          onClick={createSortHandler(column.id)}
        >
          {column.label}
          {orderBy === column.id ? (
            order === 'decSort' ? (
              <CaretsDown className={classes.carrot} />
            ) : order === 'ascSort' ? (
              <CaretsUp className={classes.carrot} />
            ) : (
              <Carets className={classes.carrot} />
            )
          ) : (
            <Carets className={classes.carrot} />
          )}
        </TableCell>
      );
    }
    return (
      <TableCell
        className={classes.tableCell}
        size={'small'}
        key={column?.id}
        align={'left'}
      >
        {column?.label}
      </TableCell>
    );
  });
  return (
    <TableHead className={classes.tableHead}>
      <TableRow>{headerRows}</TableRow>
    </TableHead>
  );
};
export default TableHeader;
