import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip, styled } from '@mui/material';
import { getFormValues } from 'redux-form';

import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form';
import { Button } from '../Shared/styledComponents';

import * as actions from '../actions';
import { show as showModal } from 'actions/modalDialog';
import * as sel from '../selectors';
import { NEW_TC_ID, checkCopyToAllMatch } from '../empTimecardUtils';
import {
  TIMECARD_INCOMPLETE,
  TIMECARD_PENDING_EMP_REVIEW,
} from '../../../components/Shared/constants';

import {
  CONFIRM_EDIT_MODAL,
  SIGNATURE_MODAL,
  DELETE_TC_DIALOG,
  CONFIRM_COPY_TO_ALL_DAYS_MODAL,
} from '../Modals/modalNames';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  ButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: '6px',
    flex: 1,
  },
}));

const MobileActionBtn = styled(Button)(({ theme }) => ({
  padding: '4px 8px',
}));

const mapState = state => ({
  loading: sel.getLoading(state, 'timecard'),
  timecard: getFormValues(sel.FORM_NAME)(state),
  disableBtns: sel.getDisabledBtns(state),
  tcStatus: sel.getTimecardStatus(state),
  isDirty: isDirty(sel.FORM_NAME)(state),
});

const mapDispatch = dispatch => ({
  onShowDeleteModal: () => dispatch(showModal({ dialog: DELETE_TC_DIALOG })),
  onPrintTimecard: () => dispatch(actions.printTimecard()),
  onSubmit: (showCopyToAllModal, isDirty) => {
    if (isDirty && showCopyToAllModal) {
      dispatch(showModal({ dialog: CONFIRM_COPY_TO_ALL_DAYS_MODAL }));
    } else {
      dispatch(showModal({ dialog: SIGNATURE_MODAL }));
    }
  },
  onShowEditModal: () => dispatch(showModal({ dialog: CONFIRM_EDIT_MODAL })),
});
const TimecardActions = props => {
  const {
    timecard,
    loading,
    onShowDeleteModal,
    onPrintTimecard,
    disableBtns,
    onSubmit,
    tcStatus,
    onShowEditModal,
    isDirty,
  } = props;
  const classes = useStyles();

  const { disableOnLoading, disableSubmit, submitTooltip } = disableBtns || {};

  const { id: timecardId } = timecard;
  const [open, setOpen] = React.useState(false);

  const deleteButton = (
    <MobileActionBtn
      disabled={disableOnLoading}
      variant="outlined"
      color="error"
      onClick={onShowDeleteModal}
      tabIndex={-1} //prevent focus after modal close
    >
      Delete
    </MobileActionBtn>
  );

  const handleSubmit = () => {
    const showCopyToAllModal = checkCopyToAllMatch(timecard);
    onSubmit(showCopyToAllModal, isDirty);
  };

  const submitButton = (
    <Tooltip
      title={submitTooltip}
      enterTouchDelay={0}
      leaveTouchDelay={2000}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <Box>
        <MobileActionBtn disabled={disableSubmit} onClick={handleSubmit}>
          {TIMECARD_PENDING_EMP_REVIEW === tcStatus ? 'Approve' : 'Submit'}
        </MobileActionBtn>
      </Box>
    </Tooltip>
  );

  let headerButtons;
  if (tcStatus === TIMECARD_INCOMPLETE) {
    headerButtons = (
      <>
        {timecardId !== NEW_TC_ID && deleteButton}
        {submitButton}
      </>
    );
  } else if (tcStatus === TIMECARD_PENDING_EMP_REVIEW) {
    headerButtons = (
      <>
        <MobileActionBtn
          onClick={onShowEditModal}
          disabled={disableOnLoading}
          variant="outlined"
        >
          Edit
        </MobileActionBtn>
        {submitButton}
      </>
    );
  } else if (!loading) {
    headerButtons = (
      <MobileActionBtn
        color="primary"
        key="print"
        disabled={disableOnLoading}
        onClick={onPrintTimecard}
      >
        {'Print'}
      </MobileActionBtn>
    );
  }

  return <Box className={classes.ButtonsWrapper}>{headerButtons}</Box>;
};

TimecardActions.propTypes = {
  loading: PropTypes.bool.isRequired,
  onShowDeleteModal: PropTypes.func.isRequired,
  onPrintTimecard: PropTypes.func.isRequired,
  timecard: PropTypes.object.isRequired,
  disableBtns: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  tcStatus: PropTypes.string,
  onShowEditModal: PropTypes.func.isRequired,
};

export default connect(mapState, mapDispatch)(TimecardActions);
