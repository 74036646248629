import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
//actions
import {
  fetch as fetchProjects,
  storeDetails,
  setViewingYear,
  fetchPaginationProjects,
  projectSorting,
} from 'actions/project';
import { apply } from 'actions/sorting';
// decorators
import withDataGrid from 'decorators/withDataGrid';
// components
import ProjectList from 'components/Admin/Projects';
// selectors
import { getProjects, getLoading, getProjectsCount } from 'selectors/project';

const grid = 'admin-projects';

const mapStateToProps = (state, ownProps) => {
  const { pagination } = ownProps;
  const totalCount = getProjectsCount(state);
  const gridPagination = {
    ...pagination,
    totalCount:
      pagination && pagination.totalCount !== totalCount
        ? totalCount
        : pagination.totalCount,
    activePage:
      totalCount > pagination.activePage * pagination.pageSize
        ? pagination.activePage
        : 0,
  };
  return {
    pagination: gridPagination,
    projects: getProjects(state),
    loading: getLoading(state),
  };
};

const mapDispatchToProps = (dispatch, { onChangePageSize, pagination }) => ({
  onFetchData: () => {
    dispatch(fetchProjects());
    localStorage.removeItem('region');
    dispatch(storeDetails({ project: {} }));
    dispatch(setViewingYear({ viewingYear: null }));
  },
  addTen: () => {
    onChangePageSize(pagination.pageSize + 10);
  },
  onNavigateToNextPage: page => {
    dispatch(fetchPaginationProjects({ page, pageSize: pagination.pageSize }));
  },
  onRowsChange: pageSize => {
    dispatch(fetchPaginationProjects({ page: 0, pageSize }));
  },
  onProjectListApplySorting: (orderBy, direction) => {
    dispatch(apply({ grid, orderBy, direction }));
    dispatch(projectSorting({ grid, orderBy, direction }));
  },
});

class AdminProjects extends Component {
  static propTypes = {
    onFetchData: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.resetPendoSessionStorage();
    this.props.onFetchData();
  }
  resetPendoSessionStorage = () => {
    sessionStorage.removeItem('pendo_profile');
    sessionStorage.removeItem('pendo_project');
  };
  render() {
    const { history } = this.props;
    if (this.props.loading) return <LinearProgress />;

    const navigate = (id, region) => () => {
      localStorage.setItem('region', region);
      history.push(`/admin/projects/${id}/crew-list`);
    };

    return <ProjectList navigate={navigate} {...this.props} />;
  }
}

export default withDataGrid(grid)(
  connect(mapStateToProps, mapDispatchToProps)(AdminProjects),
);
